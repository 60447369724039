import * as firebase from 'firebase';
import "firebase/messaging";

const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG === "production"
  ? {
    apiKey: "AIzaSyC0yeDBDZ34deV6H8mOljjK0BtQdhMLUto",
    authDomain: "be-valeo.firebaseapp.com",
    databaseURL: "https:be-valeo.firebaseio.com",
    projectId: "be-valeo",
    storageBucket: "be-valeo.appspot.com",
    messagingSenderId: "780231601044",
    appId: "1:780231601044:web:050c64b870850b358b3d81",
    measurementId: "G-90XYDP60QP"
  }
  :
  // {
  //   apiKey: "AIzaSyBxgRMzEzoGWkcjsx1oEPjIWMORtbf0Sjg",
  //   authDomain: "valeo-qa-server.firebaseapp.com",
  //   databaseURL: "https://valeo-qa-server-default-rtdb.europe-west1.firebasedatabase.app",
  //   projectId: "valeo-qa-server",
  //   storageBucket: "valeo-qa-server.appspot.com",
  //   messagingSenderId: "863315392408",
  //   appId: "1:863315392408:web:75c9bcb4afb2f6132d1ded",
  //   measurementId: "G-4DP3M4KDPM"
  // };
  {
    apiKey: "AIzaSyDhtbgUqX0R5tTsgvAMft0QRvUdpQ_9Rz4",
    authDomain: "test-val-51d42.firebaseapp.com",
    projectId: "test-val-51d42",
    storageBucket: "test-val-51d42.appspot.com",
    messagingSenderId: "1028822325508",
    appId: "1:1028822325508:web:2d8fd95146e7ec1e0dd851",
    measurementId: "G-D190MEXQ7P"
  }; /// for testing nurse panel notification
console.log("firebaseConfig::", firebaseConfig);
console.log("process.env.REACT_APP_FIREBASE_CONFIG", process.env.REACT_APP_FIREBASE_CONFIG)
//// PRODUCTION///////////////////////

// const firebaseConfig = {
//   apiKey: "AIzaSyC0yeDBDZ34deV6H8mOljjK0BtQdhMLUto",
//   authDomain: "be-valeo.firebaseapp.com",
//   databaseURL: "https://be-valeo.firebaseio.com",
//   projectId: "be-valeo",
//   storageBucket: "be-valeo.appspot.com",
//   messagingSenderId: "780231601044",
//   appId: "1:780231601044:web:050c64b870850b358b3d81",
//   measurementId: "G-90XYDP60QP"
// };


//////////////////QA SERVER/////////////////////////////
// var firebaseConfig = {
//   apiKey: "AIzaSyBxgRMzEzoGWkcjsx1oEPjIWMORtbf0Sjg",
//   authDomain: "valeo-qa-server.firebaseapp.com",
//   databaseURL: "https://valeo-qa-server-default-rtdb.europe-west1.firebasedatabase.app",
//   projectId: "valeo-qa-server",
//   storageBucket: "valeo-qa-server.appspot.com",
//   messagingSenderId: "863315392408",
//   appId: "1:863315392408:web:75c9bcb4afb2f6132d1ded",
//   measurementId: "G-4DP3M4KDPM"
// };


firebase.initializeApp(firebaseConfig);

// const initializedFirebaseApp = firebase.initializeApp({
//     apiKey: "AIzaSyC0yeDBDZ34deV6H8mOljjK0BtQdhMLUto",
//     authDomain: "be-valeo.firebaseapp.com",
//     databaseURL: "https://be-valeo.firebaseio.com",
//     projectId: "be-valeo",
//     storageBucket: "be-valeo.appspot.com",
//     messagingSenderId: "780231601044",
//     appId: "1:780231601044:web:050c64b870850b358b3d81",
//     measurementId: "G-90XYDP60QP"
// })

// let messaging = null

// if (firebase.messaging.isSupported()) {
//   messaging = firebase.messaging();

// messaging.usePublicVapidKey(
//   "BJfdq68mbU1-z3Aji3uUy5kwTXCSFO4A6mtcUYd0D4AbC7aUhYNiI0ciook22VPVlpvC35jWo84VMqm5pBKlJJ8"
// );
//   messaging = firebase.messaging()
// } else {
//   console.log('no-support :(')
// }
const messaging=firebase.messaging();
messaging.usePublicVapidKey(
  "BB_6DCqZema-UdqEJ_stllaGJGglvjl9KfYdQeddFOUUNjIoq7CKM_kUBC0vqtBMNFXzLt1qXgCNaihfMXZdOEY"
);

messaging.getToken().then((currentToken) => {
  // registration.showNotification("testttt")
  console.log("CurrentToken",currentToken)
  })
  
const databaseRef = firebase.database().ref().child("messages")


export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log(messaging)
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});
export const onGenerateToken = () =>
  new Promise(async(resolve) => {
    console.log(messaging)
    const swRegistration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
messaging.getToken({serviceWorkerRegistration: swRegistration}).then((currentToken) => {
  // registration.showNotification("testttt")
  console.log("CurrentToken",currentToken)
  })
});

export { databaseRef , messaging};