export function isUserSuperAdmin(aclUser) {
  const roles = aclUser?.roles?.map((it) => it.name?.toLowerCase()) ?? [];
  const isUserSuperAdmin = roles.includes("super admin");
  console.log("isUserSuperAdmin::", { aclUser, isUserSuperAdmin });
  return isUserSuperAdmin;
}

export function isAccessDenied(aclUser, pageAccessRights) {
  if (!aclUser) return false;
  if (isUserSuperAdmin(aclUser)) return false;
  return (
    pageAccessRights &&
    pageAccessRights.length &&
    pageAccessRights.includes("no show")
  );
}

export function isUserAdmin(aclUser) {
  const roles = aclUser?.roles?.map((it) => it.name?.toLowerCase()) ?? [];
  const isUserAdmin = roles.includes("admin");
  console.log("isUserAdmin::", { aclUser, isUserAdmin });
  return isUserAdmin;
}