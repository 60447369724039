import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { fetchCountry, fetchCity } from "services/RegionService";
import PhoenixAPI from "utils/PhoenixAPI";
import {SelectingAddress}  from "./SelectingAddress";

function EditCustomerShippingAddress(props) {

  const { shippingAddress, title, isVisible, onHide, onSave, order, userCityAddress=[],} = props;
   const {cityId, countryId} =order ?? {};
 

  const {addressLine1,addressLine2,buildingApt, mapUrl, id:addressId=null} = shippingAddress ?? {};

  
  const [streetName, setStreetName]= useState(addressLine2)
  const [building,setBuilding]=useState(buildingApt)
  const [area,setArea]=useState(addressLine1)
  const [city,setCity]=useState("")
  const [country,setCountry]=useState("");
  const[emailId,setEmailId]=useState("");
  const[orderUUID,setOrderUUID]=useState("");
  const[userName,setUserName]=useState("");
  const [errors, setErrors] = useState({});
  const [location,setLocation] = useState(mapUrl)
  const [selected,setSelected]=useState(null);
  const [selectedAddress,setSelectedAddress]=useState({})
  const [disabledAddress,setDisabledAddress]= useState(false)
  useEffect(() => setStreetName(addressLine2), [addressLine2]);
  useEffect(() => setBuilding(buildingApt), [buildingApt]);
  useEffect(() => setArea(addressLine1), [addressLine1]);
  useEffect(()=>setLocation(mapUrl), [mapUrl]);
  useEffect(()=>setErrors({}),[])
  useEffect(()=>setSelected(null),[])
  useEffect(() => setSelectedAddress({}), []);
  const getCityDetails=async()=>{
    if(cityId && countryId){
      const {city_name}=await fetchCity(cityId);
      const {country_name}=await fetchCountry(countryId)
      setCity(city_name)
      setCountry(country_name)
      const{userDetails,orderUUID}=order??{};
      const{userName,email}=userDetails;
      setEmailId(email);
      setUserName(userName);
      setOrderUUID(orderUUID);
    }
  }

  useEffect(()=>{
    if(order)
      getCityDetails()
  },[order])
  useEffect(()=>{
    if(addressId && userCityAddress&& userCityAddress.length>0){
       setSelected(addressId)
       setDisabledAddress(true)
    }
  },[addressId])

  const validate=()=>{
    const errors={};
    if(!area){
      errors.area="Area is mandatory field";
    }
    if(area && area.length >100){
      errors.area="Area/District can not be more than 100 characters"
    }
    if(!streetName){
      errors.streetName="Street Name is mandatory field";
    }
    if(streetName && streetName.length > 100){
      errors.streetName="Street Name can not be more than 100 characters"
    }
    if(!building){
      errors.building="Building/Apt. is mandatory field";
    }
    if(building && building.length> 100){
      errors.building="Building/Apt. can not be more than 100 characters";
    }

    if(!location){
      errors.location="Location is mandatory field";
    }
    return Object.keys(errors).length === 0 ? null : errors;

  
  }

  const handleSave=()=>{
    const errors = validate();
    if (errors) {
      setErrors(errors ?? {});
      return;
    }

    onSave({
      addressLine1:area,
      addressLine2:streetName,
      buildingApt:building,
      cityId:cityId,
      countryId:countryId,
      mapUrl:location,
      addressId:selected
    });
    setErrors({});
    setSelected(null);
    setDisabledAddress(false)
  }

  const handleSelectedAddress=(selectAddress)=>{
    if(selected && selected == selectAddress.id){
      setSelected(null);
      setStreetName("")
      setBuilding("")
      setArea("")
      setLocation("")
      setDisabledAddress(false)
    }else{
      setSelected(selectAddress.id)
      setStreetName(selectAddress?.addressLine2 ??"")
      setBuilding(selectAddress?.buildingApt??"")
      setArea(selectAddress?.addressLine1??"")
      setLocation(selectAddress?.mapUrl??"")
      setDisabledAddress(true)
    }
  }




  const shippingAddressForm = (
    <>
      {title=="Please add Shipping Address to proceed"?(<>
        <div style={{display:"flex", justifyContent:"space-between"}}><div>{userName}</div>
          <div>{emailId}</div>
          <div>{orderUUID}</div> </div>


      </>)
        :<></>}


    <div className="address-map" style={{padding:"12px"}}>
      {userCityAddress.length>0 && userCityAddress.map((address, index)=>{
        
       return (
        <>
        <div key={index}>
        <SelectingAddress selected={selected} address={address} handleClick={(item)=>handleSelectedAddress(item)}/>
        </div>
       
        </>
       )
      })}
    </div>

      <div className="row">

        <div className="form-group col">
          <label htmlFor="street_name">Street Name*</label>
          <div className="user-input-container">
            <div className="input-group">
              <textarea
                name="street_name"
                id="street_name"
                className={`form-control`}
                style={{ minHeight: "50px" }}
                value={streetName ?? ""}
                onChange={(event) => setStreetName(event.target.value)}
                disabled={disabledAddress}
              ></textarea>

            </div>

          </div>
          <span className="help-block">{errors.streetName}</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="building">Building/Apt.*</label>
          <div className="input-group">
              <textarea
                name="building"
                id="building"
                className={`form-control`}
                style={{minHeight:"50px"}}
                value={building ?? ""}
                onChange={(event) => setBuilding(event.target.value)}
                disabled={disabledAddress}
              ></textarea>

          </div>
          <span className="help-block">{errors.building}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="area">Area/District*</label>
          <div className="user-input-container">
            <div className="input-group">
              <textarea
                name="area"
                id="area"
                className={`form-control`}
                style={{minHeight:"50px"}}
                value={area ?? ""}
                onChange={(event) => setArea(event.target.value)}
                disabled={disabledAddress}
              ></textarea>

            </div>

          </div>
          <span className="help-block">{errors.area}</span>
        </div>
      </div>
      <div className="row">
       
       <div className="form-group col">
         <label htmlFor="location">Location*</label>
         <div className="user-input-container">
           <div className="input-group">
             <textarea
               name="location"
               id="location"
               className={`form-control`}
               style={{minHeight:"50px"}}
               value={location ?? ""}
               onChange={(event) => setLocation(event.target.value)}
               disabled={disabledAddress}
             ></textarea>

           </div>

         </div>
         <span className="help-block">{errors.location}</span>
       </div>
     </div>
          <div className="row">
            <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={country ?? ""}
              type="text"
              id="country"
              name="country"
              readOnly={true}
              className="form-control py-2"
            />
            </div>
            <div className="form-group col-6">
            <label htmlFor="city">City</label>
            <input
              value={city ?? ""}
              type="text"
              id="city"
              name="city"
              readOnly={true}
              className="form-control py-2"
            />
            </div>
          </div>

    </>
  )


  return (
    <Modal show={isVisible} onHide={() => { onHide(); setErrors({}); setSelected(null); setDisabledAddress(false) }}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{title}</p>
        </Modal.Title>
        <div className="user-title"></div>
      </Modal.Header>
      <Modal.Body>
        <>
          {shippingAddressForm}
          <div className="row">
            <div className="form-group col text-right">
              <button
                onClick={()=>{onHide();setErrors({});setSelected(null)}}
                type="button"
                className="btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={(event) => handleSave()}
                type="button"
                className="btn btn-outline-success ml-2"
              >
                Save
              </button>
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}

// EditCustomerShippingAddress.propTypes = {
//   custo: PropTypes.string,
//   title: PropTypes.string,
//   isVisible: PropTypes.bool,
//   onHide: PropTypes.func,
//   onSave: PropTypes.func,
// };

EditCustomerShippingAddress.defaultProps = {
  shippingAddress: null,
  title: "",
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

export default EditCustomerShippingAddress;
