import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectCustomAddonCategory.propTypes = {
  customPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCustomPackageIds: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  countryId: PropTypes.number.isRequired,
};

SelectCustomAddonCategory.defaultProps = {
 
  selectedCustomPackageIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
 
};

function SelectCustomAddonCategory(props) {
  const {
 
    selectedCustomPackageIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
    countryId,
  } = props;

  const [customPackages, setCustomPackages] = useState([]);
  useEffect(() => {
    if (!countryId) {
      console.log("Invalid country id!", { countryId });
      return;
    }

    const url = `custom-addon/category?countryId=${countryId}`;
   
    const fetchCustomPackages = async () => {
      try {
        const { data: custompackages = [] } = await PhoenixAPI.get(url);
        setCustomPackages(custompackages)
      } catch (error) {
        console.log("Error on fetching custom addon categories", error);
      }
    };
    fetchCustomPackages();
  }, [countryId]);
  const findCustomPackage = (id) => customPackages.find((it) => it.id === id);
  
  const options = createOptions(customPackages, showOnlyActive);
  
  const selectedCustomPackages = findCustomPackage(selectedCustomPackageIds)


  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };
  return (
    <Select
      key={`my_unique_select_key__${
        selectedCustomPackages && selectedCustomPackages.id 
      }`} 
      value={selectedCustomPackages &&  createOption(selectedCustomPackages)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={false}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(customPackages, showOnlyActive) {
  if (!customPackages || !customPackages.length) return [];

  const [activeCustomPackages, inactiveCustomPackages] = _.partition(
    customPackages,
    (it) => it.isActive ? it.isActive : false 
  );
  activeCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  if (showOnlyActive) return activeCustomPackages.map(createOption);

  inactiveCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  return [
    {
      label: "Active",
      options: activeCustomPackages.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCustomPackages.map(createOption),
    },
  ];
}

function createOption(customPackage) {
  if (!customPackage) return;
  return {
    key: customPackage.id,
    value: customPackage.value ||customPackage.id,
    label: customPackage.name,
  };
}

export default SelectCustomAddonCategory;
