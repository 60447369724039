import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDateTime } from "utils/commons";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class ClientNotesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: Number(this.props.match.params.userId),
      orderId: Number(this.props.match.params.orderId),
      dependentId:this.props.match.params.dependentId?Number(this.props.match.params.dependentId):null,
      clientNotes: [],
      clientNote: {},
      isNewClientNote: false,
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      showModal: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "User ID",
        selector: "userId",
        width: "100px",
        sortable: true,
      },
      {
        name: "Order ID",
        selector: "orderId",
        width: "100px",
        sortable: true,
      },
      {
        name: "Package Name",
        selector: "packagename",
        wrap: true,
        sortable: true,
      },
      {
        name: "Note",
        selector: "notes",
        wrap: true,
        sortable: true,
      },
      {
        name: "Created At",
        selector: "createdAt",
        wrap: true,
        sortable: true,
        format: (clientNote) =>
          clientNote.createdAt ? formatDateTime(clientNote.createdAt) : "N/A",
      },
    ];
  }

  async componentDidMount() {
    this.fetchClientNotes();
  }

  fetchClientNotes = async () => {
    const { userId , dependentId=null} = this.state;

    if (!userId) {
      console.log("fetchClientNotes:: Invalid user id!", { userId });
      return;
    }
     let url;
     if(dependentId){
      url=`/coach-client-notes/userId/${userId}?dependentId=${dependentId}`;
     }
     else{
      url = `/coach-client-notes/userId/${userId}`
     }

    try {
      this.showLoading();
      // const url = `/coach-client-notes/userId/${userId}`;
      const { data: clientNotes = [] } = await PhoenixAPI.get(url);
      this.setState({ clientNotes });
    } catch (error) {
      console.log("fetchClientNotes:: Error on fetching client notes!", error);
      this.showErrorNotification(
        "Error on fetching client notes! " + error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const clientNote = { ...this.state.clientNote };
    clientNote[input.name] = input.value;
    this.setState({ clientNote });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNew = () => {
    const { userId, orderId, dependentId=null } = this.state;
    if(dependentId){
      const clientNote = { userId, orderId,dependentId  };
      this.setState({ clientNote }, () => this.showModal());
    }
    else{
      const clientNote = { userId, orderId};
    this.setState({ clientNote }, () => this.showModal());
    }
    
  };

  handleSave = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { clientNote } = this.state;
    try {
      await PhoenixAPI.post("/coach-client-notes", clientNote);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchClientNotes();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const errors = {};
    const { notes } = this.state.clientNote;

    if (!notes || notes.trim() === "")
      errors.notes = "Notes is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({ clientNote: {}, errors: {} });
  };

  getFilteredClientNotes = () => {
    const { clientNotes = [] } = this.state;
    return this.getSearchedClientNotes(clientNotes);
  };

  getSearchedClientNotes = (clientNotes) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return clientNotes;

    const newValue = searchTerm.slice(0).trim().toLowerCase();

    return clientNotes.filter((clientNote) => {
      const { id, userId, orderId, packagename, notes } = clientNote;
      return (
        (notes && notes.toLowerCase().indexOf(newValue) !== -1) ||
        (packagename && packagename.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (userId && userId.toString() === newValue) ||
        (orderId && orderId.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { clientNote, errors, searchTerm , dependentId=null} = this.state;

    const clientNotes = this.getFilteredClientNotes();

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="userId">User ID</label>
            <div className="input-group">
              <input
                value={clientNote.userId ?? ""}
                onChange={this.handleChange}
                id="userId"
                name="userId"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.userId ? " has-error" : "")
                }
                readOnly={true}
              />
            </div>
            <span className="help-block">{errors.userId}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="orderId">Order ID</label>
            <div className="input-group">
              <input
                value={clientNote.orderId ?? ""}
                onChange={this.handleChange}
                id="orderId"
                name="orderId"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.orderId ? " has-error" : "")
                }
                readOnly={true}
              />
            </div>
            <span className="help-block">{errors.orderId}</span>
          </div>
        </div>
        {dependentId && <div className="row">
          <div className="form-group col-6">
            <label htmlFor="userId">Dependent ID</label>
            <div className="input-group">
              <input
                value={dependentId}
                onChange={this.handleChange}
                id="dependentId"
                name="dependentId"
                type="text"
                placeholder="dependentId"
                className={
                  "form-control py-2" + (errors.userId ? " has-error" : "")
                }
                readOnly={true}
              />
            </div>
            <span className="help-block">{errors.userId}</span>
          </div>
        </div>}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="notes">Notes</label>
            <div className="input-group">
              <textarea
                value={clientNote.notes ?? ""}
                onChange={this.handleChange}
                id="notes"
                name="notes"
                placeholder="Notes..."
                className={
                  "form-control py-2" + (errors.notes ? " has-error" : "")
                }
              ></textarea>
            </div>
            <span className="help-block">{errors.notes}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <Card.Title>Client Notes</Card.Title>
          <DataTable
            data={clientNotes}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + clientNotes.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_client_notes">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientNotesTable);
