import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import API from "utils/API";
import ActionBar from "react-bootstrap/Dropdown";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import * as moment from 'moment';
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import {ONE_HOUR_SLOTS,ONE_HOUR_HALF_SLOTS,TWO_HOUR_SLOTS, ALL_TIME_SlOTS} from "../../utils/LabTimeSlots.js"


class Labslots extends Component {
    constructor(props) {
        super(props);

        this.state = {
            aclUser: null,
            pageAccessRights: null,
            accessDenied: false,
            isEditable: true,
            months: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            columns: this.createColumns(),
            tableList: [],
            timeZone: "",
            availabilityColumns: this.createAvailabilityColumns(),
            availabilityData: [],
            selectedLab: {},
            allLabsData: [],
            allSlots: [],
            labList: [],
            productsList: [],
            selectedRow: {},
            bloodAnalysisList: [],
            couchPackagesList: [],
            hideHolidays: {},
            holiday: {},
            availability: {},
            showModal: false,
            labListValue: "",
            blood_analysis: [],
            showConfirm: false,
            showDeleteConfirm: false,
            confirmItem: null,
            confirmMessage: "",
            confirmType: null,
            confirmTitle: "",
            successMessage: "",
            minDate: moment(new Date()).format('YYYY-MM-DD'),
            selectedSlot: [],
            deleteIndex: null,
            deleteRow: {},
            timeSlot:{oneHourSlot:false,oneHourHalfSlot:false,twoHourSlot:false},
            showDeleteAllModal:false,
            copySlots:false
        }
        this.confirmDeleteSlot = this.confirmDeleteSlot.bind(this);
        this.hideDeleteConfirm = this.hideDeleteConfirm.bind(this);
    }



    confirmDeleteSlot() {
        let index = this.state.deleteIndex;
        let row = this.state.deleteRow;
        let tableData = [...this.state.tableList];
        let availabilityData = this.state.availability;
        let selectedLab = this.state.selectedLab;
        let tableIndex = this.state.tableList.findIndex(item => item.id == row.id);
        if (tableIndex > -1) {
            let slots = typeof (tableData[tableIndex].slots) == 'string' ? tableData[tableIndex].slots.split(';') : tableData[tableIndex].slots;
            slots.splice(index, 1);
            availabilityData[row.day.toLowerCase()] = slots;
            tableData[tableIndex].slots = slots.join(';');
            selectedLab['availability'] = availabilityData
        }
        this.setState({ tableList: tableData, availability: availabilityData, selectedLab: selectedLab }, () => {
            this.updateProductDetails(this.state.selectedLab);
            this.hideDeleteConfirm();
            this.newSlot();
        });

    }

    showConfirm(row) {
        let confirmMessage, successMessage, confirmTitle, confirmType;
        confirmTitle = "Delete Slot";
        confirmMessage = "Are you sure you want to delete this slot?";
        successMessage = "Slot Deleted Successfully.";
        confirmType = "success";

        if (row.slots.includes(';')) row.slots = row.slots.split(';');
        // const time = row.slots && Array.isArray(row.slots) && row.slots.length ? row.slots[0].split('-') : row.slots.split('-');
        this.setState({
            showConfirm: true,
            confirmItem: row,
            confirmMessage,
            successMessage,
            confirmType,
            confirmTitle,
            selectedRow: row,
            isHoliday: false,
            selectedSlot: row.slots && Array.isArray(row.slots) && row.slots.length ? row.slots : [row.slots]
            // startTime: time && time.length > 1 ? time[0] : '',
            // endTime: time && time.length > 1 ? time[1] : ''
        });
    }


    hideConfirm = () => {

        this.setState({
            showConfirm: false,
            confirmItem: null,
            confirmTitle: "",
            successMessage: "",
            confirmMessage: "",
            confirmType: null,
            selectedRow: null,
        });
    }

    showDeleteConfirm(row, index) {
        let confirmMessage, successMessage, confirmTitle, confirmType;
        confirmTitle = "Delete Slot";
        confirmMessage = "Are you sure you want to delete this slot?";
        successMessage = "Slot Deleted Successfully.";
        confirmType = "success";

        if (row.slots.includes(';')) row.slots = row.slots.split(';');
        // const time = row.slots && Array.isArray(row.slots) && row.slots.length ? row.slots[0].split('-') : row.slots.split('-');
        this.setState({
            showDeleteConfirm: true,
            deleteRow: row,
            deleteIndex: index,
            confirmMessage,
            successMessage,
            confirmType,
            confirmTitle,
            // startTime: time && time.length > 1 ? time[0] : '',
            // endTime: time && time.length > 1 ? time[1] : ''
        });
    }


    hideDeleteConfirm = () => {

        this.setState({
            showDeleteConfirm: false,
            deleteRow: {},
            deleteIndex: null,
            confirmItem: null,
            confirmTitle: "",
            successMessage: "",
            confirmMessage: "",
            confirmType: null,
        });
    }


    addSlots = (data) => {
        const {slots}= data || {};
        if(slots===""){
            this.setState({copySlots:true})
        }
        if(slots && slots){
            const slotsArray = data.slots.split(';').map(slot => slot.trim());
            
            // Slice the array to get only the first four slots

            this.setState({ showModal: true, formHeading: "Add Slot", selectedRow: data, isHoliday: false, timerValue:slotsArray });
        }
        else{
            this.setState({ showModal: true, formHeading: "Add Slot", selectedRow: data, isHoliday: false });  
        }

    //    this.setState({ showModal: true, formHeading: "Add Slot", selectedRow: data, isHoliday: false });  
    }

    deleteSlots = (data) => {
        this.setState({ showModal: true, formHeading: "Delete Slot", selectedRow: data, isHoliday: false });
    }

    newSlot = () => {
        if (this.state.selectedLab && this.state.selectedLab.booking_per_slot >= 1 && this.state.selectedLab.lead_time >= 0 && this.state.selectedLab.adv_booking_days >= 1) {
            let data = this.state.selectedLab || {};
            data["blood_analysis"] = this.state.blood_analysis;
            data["coach_package"] = [this.state.coach_package];
            data["availability"] = this.state.availability;
            data["holiday"] = this.state.holiday;
            data["booking_per_slot"] = this.state.selectedLab.booking_per_slot;
            data["lead_time"] = this.state.selectedLab.lead_time;
            data["adv_booking_days"] = this.state.selectedLab.adv_booking_days;
            data['profile'] = this.state.labListValue;
            debugger
            let url = data.id ? "lab-slot-admin/" + data.id + "/" : "lab-slot-admin/";
            let method = data.id ? 'patch' : 'post';
            API[method](url, data).then(response => {
                this.getLabSlots();
                this.props.showNotificationMessage({
                    notificationMessage: "Lab Slot Availibility Updated Successfully.",
                    successMessage: true,
                    showNotification: true
                });
            }).catch(error => {
                this.props.showNotificationMessage({
                    notificationMessage: "Something went wrong.",
                    successMessage: false,
                    showNotification: true
                });
            }).finally(data => {
                this.getLabSlots();
                this.props.showNotificationMessage({
                    notificationMessage: "Lab Slot Availibility Updated Successfully.",
                    successMessage: true,
                    showNotification: true
                });
                this.setState({ showModal: false })
            })
        } else {
            this.props.showNotificationMessage({
                notificationMessage: "Something went wrong.",
                successMessage: false,
                showNotification: true
            });
        }
    }

    addSlot = () => {
        let { startTime, endTime, selectedLab, selectedRow, isNew, availability, timerValue} = this.state;
        
        // if (startTime === endTime) {
        //     this.props.showNotificationMessage({
        //         notificationMessage: "Start Time and End Time not be same",
        //         successMessage: false,
        //         showNotification: true
        //     });
        //     return;
        // }

        // if (startTime > endTime) {
        //     this.props.showNotificationMessage({
        //         notificationMessage: "Start Time should not greater than End Time.",
        //         successMessage: false,
        //         showNotification: true
        //     });
        //     return;
        // }

        // let b = availability?.[selectedRow?.day?.toLowerCase()]?.map((e) => {
        //     let _startTime = e.substring(0, 5)
        //     let _endTime = e.substring(6, 11)
        //     if (_startTime <= startTime && _endTime > startTime) {
        //         this.props.showNotificationMessage({
        //             notificationMessage: "Slot Already Exits",
        //             successMessage: false,
        //             showNotification: true
        //         });
        //         return false;
        //     }
        // })
        // if (b.includes(false)) return;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);

        if (availability?.[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "Slot Already Exits",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability?.[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        // availability[selectedRow?.day?.toLowerCase()].push(startTime + "-" + endTime);
        availability[selectedRow?.day?.toLowerCase()]=timerValue ??[];
        // const timeSlot={oneHourSlot:false,oneHourHalfSlot:false,twoHourSlot:false}
        this.setState({ availability, showModal: false, startTime: "", endTime: "", timerValue:[]});
        this.formatAvailablity(availability);
    }

    toggleActiveStatus(item) {
        const { successMessage } = this.state;

        if (!item) return;
        this.hideConfirm();
        this.deleteSlot();
    }

    deleteSlotClick = (event, slot) => {
        // event.preventdefault();
        let { selectedRow, availability } = this.state;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);
        const time = slot.trim().split('-');
        const startTime = time[0];
        const endTime = time[1];
        if (startTime === endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time and End Time not be same",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "No Slot found to delete",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        availability[selectedRow?.day?.toLowerCase()].splice(availability[selectedRow?.day?.toLowerCase()].indexOf(startTime + "-" + endTime), 1);
        this.setState({ availability });
        this.hideConfirm();
        this.formatAvailablity(availability);
    }
    deleteSlot = (slot) => {
        let { selectedRow, availability } = this.state;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);
        const time = slot.split('-');
        const startTime = time[0];
        const endTime = time[1];
        if (startTime === endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time and End Time not be same",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "No Slot found to delete",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        availability[selectedRow?.day?.toLowerCase()].splice(availability[selectedRow?.day?.toLowerCase()].indexOf(startTime + "-" + endTime), 1);
        this.setState({ availability });
        this.hideConfirm();
        this.formatAvailablity(availability);
    }

    handleDeleteAll = () => {
        let { selectedRow, availability } = this.state;
        if (availability[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        this.setState({ availability });
        this.hideConfirm();
        this.formatAvailablity(availability);
    }

    handleDeleteAllSlots = () => {
        const { selectedLab = {} } = this.state;
        if (Object.keys(selectedLab).length > 0) {
            let availability = {
                "sunday": [],
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": []
            };
            const updatedLab = { ...selectedLab, availability: availability }
            this.setState({ selectedLab: updatedLab, availability, holiday: {}, showDeleteAllModal:false });
            this.formatAvailablity({});
        }
        else {
            this.props.showNotificationMessage({
                notificationMessage: "Please Select Lab",
                successMessage: false,
                showNotification: true
            });
            return;
        }
    }

    showDeleteAllModal=()=>{
        this.setState({showDeleteAllModal:true})
    }

    hideDeleteAllModal=()=>{
        this.setState({showDeleteAllModal:false}) 
    }


    handleSubmit = () => {
        if (this.state.isHoliday) {
            this.addHoliday();
        } else {
            if (this.state.formHeading == "Add Slot") {
                this.addSlot();
            } else {
                this.deleteSlot();
            }
        }
    }

    handleAddPreviousSlot = () => {
        const { selectedLab, selectedRow, isNew, availability, timerValue } = this.state;
        if (selectedRow.slots === "") {
            if (availability?.[selectedRow?.day?.toLowerCase()]) {
                if (availability["sunday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["sunday"];
                    selectedRow.slots = availability["sunday"].join(";")
                    this.setState({ availability, timerValue: availability["sunday"] });
                    return;
                }
                if (availability["monday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["monday"];
                    selectedRow.slots = availability["monday"].join(";")
                    this.setState({ availability, timerValue: availability["monday"] });
                    return;
                }
                if (availability["tuesday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["tuesday"];
                    selectedRow.slots = availability["tuesday"].join(";")
                    this.setState({ availability, timerValue: availability["tuesday"] });
                    return;
                }
                if (availability["wednesday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["wednesday"];
                    selectedRow.slots = availability["wednesday"].join(";")
                    this.setState({ availability, timerValue: availability["wednesday"] });
                    return;
                }
                if (availability["thursday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["thursday"];
                    selectedRow.slots = availability["thursday"].join(";")
                    this.setState({ availability, timerValue: availability["thursday"] });
                    return;
                }
                if (availability["friday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["friday"];
                    selectedRow.slots = availability["friday"].join(";")
                    this.setState({ availability, timerValue: availability["friday"] });
                    return;
                }
                if (availability["saturday"].length > 0) {
                    availability[selectedRow?.day?.toLowerCase()] = availability["saturday"];
                    selectedRow.slots = availability["saturday"].join(";")
                    this.setState({ availability, timerValue: availability["saturday"] });
                    return;
                }
            }

        }

    }

    addHoliday = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (today <= this.state.holidayDate) {
            let _holiday = this.state.holiday;
            let _holidayDate = this.state.holidayDate;
            let _hideHolidays = this.state.hideHolidays;
            if (!_holidayDate) {
                this.props.showNotificationMessage({
                    notificationMessage: "Please Select Date",
                    successMessage: false,
                    showNotification: true
                });
                return;
            }
            const months = this.state.months;;
            let _date = new Date(_holidayDate);
            let month = months[_date.getMonth()];
            let date = (_date.getDate()).toString();
            if (!_holiday[month]) _holiday[month] = [];
            if (_holiday[month].includes(date)) {
                if (_hideHolidays[month] && _hideHolidays[month][date]) {
                    delete _hideHolidays[month][date];
                    this.setState({ hideHolidays: _hideHolidays });
                    this.formatHoliday(_holiday, _hideHolidays);
                    this.hideModal();
                } else {
                    this.props.showNotificationMessage({
                        notificationMessage: "Holiday Already Exits",
                        successMessage: false,
                        showNotification: true
                    });
                }
                return;
            }
            _holiday[month].push(this.state.holidayDate);
            _holiday[month].sort((a, b) => { return Number(a) - Number(b) });
            this.hideModal();
            this.formatHoliday(_holiday);
            this.setState({ holiday: _holiday })
        } else {
            this.props.showNotificationMessage({
                notificationMessage: "Invalid Date",
                successMessage: false,
                showNotification: true
            });

        }
    }

    deleteHoliday = (row) => {
        let _date = new Date(row.day);
        let _holiday = this.state.holiday;
        const months = this.state.months;;
        let month = months[_date.getMonth()];
        let date = (_date.getDate()).toString();
        if (!_holiday[month]) _holiday[month] = [];
        // if (!_holiday[month].includes(date)) {
        //     this.props.showNotificationMessage({
        //         notificationMessage: "No Date Found",
        //         successMessage: false,
        //         showNotification: true
        //     });
        //     return;
        // }
        _holiday[month].splice(_holiday[month].indexOf(date), 1);
        this.formatHoliday(_holiday);

    }

    hideHoliday = (row) => {
        let _hideHolidays = this.state.hideHolidays;
        let _date = new Date(row.day);
        const months = this.state.months;;
        let month = months[_date.getMonth()];
        let date = (_date.getDate()).toString();
        if (!_hideHolidays[month]) _hideHolidays[month] = {};
        _hideHolidays[month][date] = true;
        this.setState({ hideHolidays: _hideHolidays });
        this.formatHoliday(this.state.holiday, _hideHolidays);
    }

    hideModal = () => {
        const timeSlot={oneHourSlot:false,oneHourHalfSlot:false,twoHourSlot:false}
        this.setState({ showModal: false, timerValue:[], timeSlot, copySlots:false })
    }

    componentDidMount = () => {
        this.fetchAclUser();
        this.getLabSlots();
        this.setDefaultValue();
        this.getLabs();
        // this.getBloodAnalysis();
        this.getCouchPackages();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isEditable !== prevState.isEditable) {
            this.setState({ 
                columns: this.createColumns(),
                availabilityColumns: this.createAvailabilityColumns()
            });
        }
    }

    hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
            return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
    }

    fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
            console.log("fetchAclUser:: Invalid user id!", { userId });
            return;
        }

        try {
            const pageName = Page.LAB_AVAILABILITY_SLOTS;
            const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
            const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
            const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
            const accessDenied = isAccessDenied(aclUser, pageAccessRights);
            this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
                const isEditable = this.hasPageAccessRight("edit");
                this.setState({ isEditable });
            });
        } catch (error) {
            console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
    }
    
    setDefaultValue = () => {
        this.setEmpty();
    }

    setEmpty = () => {
        let availability = {
            "sunday": [],
            "monday": [],
            "tuesday": [],
            "wednesday": [],
            "thursday": [],
            "friday": [],
            "saturday": []
        };
        this.setState({ availability, holiday: {} });
        this.formatAvailablity({});
    }

    formatAvailablity = (availability) => {
        let weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        let _tableList = [];

        for (let i = 0; i < weeks.length; i++) {
            _tableList.push({
                id: i + 1,
                slots: availability[weeks[i].toLowerCase()]?.join('; '),
                day: weeks[i]
            })
        }
        this.setState({ tableList: _tableList });
    }

    formatHoliday = (holiday, hideHolidays) => {
        let _availabilityData = [];
        let _hideHolidays = hideHolidays || this.state.hideHolidays;
        const months = this.state.months;;
        for (let i = 0; i < months.length; i++)
            if (holiday[months[i]]) {
                for (let j = 0; j < holiday[months[i]].length; j++) {
                    if (!(_hideHolidays[months[i]] && _hideHolidays[months[i]][holiday[months[i]][j]])) {
                        let date = holiday[months[i]][j].split('-');
                        _availabilityData.push({
                            id: i,
                            day: months[i] + " " + date[2] + ", " + (new Date(date[0])).getFullYear()
                        })
                    }

                }

            }
        this.setState({ availabilityData: _availabilityData, holidayDate: '' });
    }

    getLabSlots = async () => {
        let url = "lab-slot-admin/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching data", e);
        }
        this.setState({ allLabsData: response?.data || [] })
    }

    getLabs = async () => {
        let url = "lab-profile/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching data", e);
        }
        let hash = {}, _labList = [], _allSlots = response?.data || [];
        for (let i = 0; i < _allSlots.length; i++)
            if (!hash[_allSlots[i].name]) {
                hash[_allSlots[i].name] = true;
                _labList.push({ key: _allSlots[i].name, value: _allSlots[i].id, text: _allSlots[i].name });
            }
        _labList.sort((a, b) => a.key - b.key);
        this.setState({ allSlots: _allSlots, labList: _labList })
    }

    getCouchPackages = async () => {
        let url = "add-on-packages/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching packages", e);
        }

        let array = response?.data || [];
        let list = [];
        for (let i = 0; i < array.length; i++)
            list.push({ key: array[i].addon_name, value: array[i].id, text: array[i].addon_name });

        this.setState({ couchPackagesList: list })
    }

    getBloodAnalysis = async () => {
        let url = "subscription-packages/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching packages", e)
        }

        let array = response?.data || [];
        let list = [];
        for (let i = 0; i < array.length; i++)
            list.push({ key: array[i].subscription_name, value: array[i].id, text: array[i].subscription_name });

        this.setState({ bloodAnalysisList: list })
    }

    handleChange = (data, key) => {
        this.setState({ [key]: data?.target?.value });
    }

    handleLabSelection = (lab) => {
        let _productsLab = this.state.allLabsData.filter(value => { return value.profile == lab.value })
        console.log(_productsLab);
        let _labProfiles = this.state.allSlots.filter(value => { return value.id == lab.value })
        console.log(_labProfiles);
        if (_productsLab.length > 0) {
            let _selectedLab = _productsLab[0];
            this.setState({ selectedLab: _selectedLab });
            this.updateProductDetails(_selectedLab);
        } else {
            this.setState({ selectedLab: {} });
            this.updateProductDetails();
        }
        if (_labProfiles.length > 0) {
            let _selectedLab = _labProfiles[0];
            if (_selectedLab.coach_package && _selectedLab.coach_package.length > 0) {
                this.setState({ coach_package: _selectedLab.coach_package[0] });
            }
            this.setState({ blood_analysis: _selectedLab.blood_analysis, coach_package: _selectedLab.coach_package[0] });
        }

        this.setState({ labListValue: lab.value })

    }

    handleTimerSlots=(lab)=>{
        
        this.setState({timerValue:lab.value})
    }

    handleBloodAnalysis = (value) => {
        this.setState({ blood_analysis: value })
    }

    // handleCoachPackage = (value) => {
    //     this.setState({ coach_package: value.value })
    // }

    updateProductDetails = (product) => {
        let data = product ||
        {
            "time_zone": "Asia/Dubai",
            "availability": {
                "friday": [],
                "monday": [],
                "sunday": [],
                "tuesday": [],
                "saturday": [],
                "thursday": [],
                "wednesday": []
            },
            "holiday": {},
            "booking_per_slot": 0,
            "lead_time": 0,
            "adv_booking_days": 0,
            "lab": 0,
            "blood_analysis": [],
            "coach_package": []
        };
        let availability = data && data.availability && Object.keys(data.availability).length === 0 && data.availability.constructor === Object ? {
            "friday": [],
            "monday": [],
            "sunday": [],
            "tuesday": [],
            "saturday": [],
            "thursday": [],
            "wednesday": []
        } : data.availability;
        let weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        let _tableList = [];

        for (let i = 0; i < weeks.length; i++) {
            _tableList.push({
                id: i + 1,
                slots: availability[weeks[i].toLowerCase()]?.join('; '),
                day: weeks[i]
            })
        }

        let holiday = data?.holiday;
        let _availabilityData = [];
        const months = this.state.months;
        for (let i = 0; i < months.length; i++)
            if (holiday && holiday[months[i]]) {
                for (let j = 0; j < holiday[months[i]].length; j++) {
                    let date = holiday[months[i]][j].split('-');
                    _availabilityData.push({
                        id: i,
                        day: months[i] + " " + date[2] + ", " + (new Date(date[0])).getFullYear()
                    })
                }

            }
        this.setState({ tableList: _tableList, availability: availability, availabilityData: _availabilityData, timeZone: data?.time_zone, isNew: true });
    }

    handleHoliday = () => {
        this.setState({ showModal: true, formHeading: "Add Holiday", isHoliday: true });
    }

    checkMaxLength = (value) => {
        debugger
        if (Number(value) > 100000) {
            return value;
        } else {
            this.setState({

            });
        }
    }

    handleFormSubmit = () => {
        this.newSlot();
    }

    handleSelectedLabValueChange = (key, value) => {
        let _selectedLab = this.state.selectedLab;
        _selectedLab[key] = Number(value);
        this.setState({ selectedLab: _selectedLab });
    }

    createColumns = () => {
        return [
            {
                name: "Days",
                selector: "day",
                sortable: false,
                wrap: true,
            },
            {
                name: "Slots",
                selector: "slots",
                sortable: false,
                wrap: true,
                format: row => {
                    if (row.slots) {
                        let slots = row.slots.split(';');
                        if (slots && slots.length) {
                            return (
                                <>
                                    {slots.map((slot, index) => (
                                        <span className="slot-text">{slot} <i aria-hidden="true" onClick={() => this.showDeleteConfirm(row, index)} className="delete icon"></i></span>
                                    ))}
                                </>
                            )

                        }
                    }
                }
            },
            {
                name: "Actions",
                center: true,
                allowOverflow: true,
                cell: row => (
                    <ActionBar alignRight className="action-menu valeo-dropdown">
                        <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                        </ActionBar.Toggle>
                        <ActionBar.Menu>
                            <ActionBar.Item
                                className="alert"
                                onClick={() => this.addSlots(row)}
                            >
                                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                                Add
                            </ActionBar.Item>
                            <ActionBar.Item
                                className="danger"
                                onClick={() => this.showConfirm(row)}
                            >
                                <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                                Delete
                            </ActionBar.Item>

                        </ActionBar.Menu>
                    </ActionBar>
                )
            }
        ];
    }

    createAvailabilityColumns = () => {
        return [
            {
                name: "Days",
                selector: "day",
                sortable: false,
                wrap: true,
            },
            {
                name: "Actions",
                center: true,
                allowOverflow: true,
                cell: row => (
                    <ActionBar alignRight className="action-menu valeo-dropdown">
                        <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                        </ActionBar.Toggle>
                        <ActionBar.Menu>
                            <ActionBar.Item
                                className="alert"
                                onClick={() => this.hideHoliday(row)}
                            >
                                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                                Hide
                            </ActionBar.Item>
                            <ActionBar.Item
                                className="danger"
                                onClick={() => this.deleteHoliday(row)}
                            >
                                <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                                Delete
                            </ActionBar.Item>

                        </ActionBar.Menu>
                    </ActionBar>
                )
            }
        ];   
    }

    renderTimeSlots=()=>{
     const {timeSlot, timerValue}= this.state || {};
     let array=[];
    //  if(timeSlot && timeSlot.oneHourSlot){
    //    console.log("array", array.concat(ONE_HOUR_SLOTS))
    //      array.concat(ONE_HOUR_SLOTS)
    //  }
    //  else if (timeSlot && timeSlot.oneHourHalfSlot){
      
    //         array.concat(ONE_HOUR_HALF_SLOTS)
    //  }
    //  else if(timeSlot && timeSlot.twoHourSlot){
       
    //     array.concat(TWO_HOUR_SLOTS)
    //  }
     return array
    }

    render() {
        let { tableList, columns, availabilityColumns, availabilityData, timeZone, isHoliday,
            labList, productsList, selectedLab, bloodAnalysisList, couchPackagesList,
            blood_analysis, coach_package, selectedSlot, isEditable,timeSlot, timerValue,
            copySlots
        } = this.state;
        
        const listItems = selectedSlot.map((slot) =>
            <li className="list" key={slot.toString()}>
                <span className="ui label deleteItem">{slot} <i aria-hidden="true" class="delete icon" onClick={(e) => this.deleteSlotClick(e, slot)}></i></span>
            </li>
        );
        labList.sort((a, b) => a.text.toLowerCase() - b.text.toLowerCase())
        const form = (
            <div className="">
                {isHoliday ? <>
                    <div className="input-section form-group col-6">
                        <label>Date *</label>
                        <input
                            type="date"
                            min={this.state.minDate}
                            className={
                                "form-control "
                            }
                            placeholder="Start Time"
                            name="startTime"
                            value={this.state.holidayDate}
                            onChange={(e) => this.handleChange(e, 'holidayDate')}
                        ></input>
                        <span className="help-block">
                        </span>
                    </div>
                </>
                    :
                    <>
                        {/* <div className="input-section form-group col-6">
                            <label>Start Time *</label>
                            <input
                                type="time"
                                className={
                                    "form-control "
                                }
                                placeholder="Start Time"
                                name="startTime"
                                value={this.state.startTime}
                                onChange={(e) => this.handleChange(e, 'startTime')}
                            ></input>
                            <span className="help-block">
                            </span>
                        </div>
                        <div className="input-section form-group col-6">
                            <label>End Time</label>
                            <input
                                type="time"
                                className="form-control"
                                placeholder="End Time"
                                name="endTime"
                                value={this.state.endTime}
                                onChange={(e) => this.handleChange(e, 'endTime')}
                            ></input>
                        </div> */}
                        {/* <div className="row" style={{display:"flex",margin:"auto", justifyContent:"space-between", marginBottom:"10px"}}>
                            <div className="form-check custom-control custom-checkbox ml-2">
                                <input
                                    value={true}
                                    checked={(timeSlot && timeSlot.oneHourSlot) || ""}
                                    onChange={(event, value) => {
                                        this.setState({
                                            timeSlot:{
                                                ...timeSlot,oneHourSlot:event.target.checked,
                                            }
                                        });
                                    }}
                                    id="oneHourSlot"
                                    name="oneHourSlot"
                                    type="checkbox"
                                    className="custom-control-input"
                                    disabled={!isEditable}
                                />
                                <label className="custom-control-label" htmlFor="oneHourSlot">
                                  1 hour slots
                                </label>
                            </div>
                            <div className="form-check custom-control custom-checkbox ml-2">
                                <input
                                    value={true}
                                    checked={(timeSlot && timeSlot.oneHourHalfSlot) || ""}
                                    onChange={(event, value) => {
                                        this.setState({
                                            timeSlot:{
                                                ...timeSlot,oneHourHalfSlot:event.target.checked,
                                            }
                                        });
                                    }}
                                    id="oneHourHalfSlot"
                                    name="oneHourHalfSlot"
                                    type="checkbox"
                                    className="custom-control-input"
                                    disabled={!isEditable}
                                />
                                <label className="custom-control-label" htmlFor="oneHourHalfSlot">
                                  1.5 hour slots
                                </label>
                            </div>
                            <div className="form-check custom-control custom-checkbox ml-2">
                                <input
                                    value={true}
                                    checked={(timeSlot && timeSlot.twoHourSlot) || ""}
                                    onChange={(event, value) => {
                                        this.setState({
                                            timeSlot:{
                                                ...timeSlot,twoHourSlot:event.target.checked,
                                            }
                                        });
                                    }}
                                    id="twoHourSlot"
                                    name="twoHourSlot"
                                    type="checkbox"
                                    className="custom-control-input"
                                    disabled={!isEditable}
                                />
                                <label className="custom-control-label" htmlFor="twoHourSlot">
                                  2 hour slots
                                </label>
                            </div>
                        </div> */}
                        <div className="form-group col">
                             <Dropdown
                                value={this.state.timerValue}
                                className={
                                    "form-control "
                                }
                                placeholder="select slot"
                                onChange={(event, value) => {
                                    this.handleTimerSlots(value)
                                }}
                                  search
                                  fluid
                                 multiple
                                 selection
                                options={ALL_TIME_SlOTS}
                            // onChange={(e,value)=>{console.log(value.value)}}

                            // disabled={this.setState({labListValue:})}
                            /> 
                        </div>
                    </>}
                {copySlots ? <button
                    onClick={(e) => this.handleAddPreviousSlot()}
                    className="btn button button-green"
                >
                    Copy Slots
                </button> : ""}
            </div>
        );

        if (this.state.accessDenied) {
            return <AccessDenied />
        }

        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.formHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{form}</Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={(e) => this.handleSubmit(e)}
                            className="btn button button-green"
                        >
                            Submit
                        </button>
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <form onSubmit={(event) => console.log(event)}>
                    <div className="form-row">
                        <div className=" mt-2" style={{ marginRight: "91px" }}>
                            Select lab:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={this.state.labListValue}
                                className={
                                    "form-control "
                                }
                                placeholder="Lab Name"
                                onChange={(event, value) => {
                                    this.handleLabSelection(value)
                                }}
                                search
                                selection
                                options={labList}
                            // onChange={(e,value)=>{console.log(value.value)}}

                            // disabled={this.setState({labListValue:})}
                            />
                        </div>
                    </div>
                </form>
                {/* <form onSubmit={(event) => console.log("yes-->")}>
                    <div className="form-row">
                        <div className="mt-2 mr-3">
                            Select Product:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={blood_analysis}
                                className={
                                    "form-control "
                                }
                                placeholder="Select Product"
                                onChange={(event, value) => {
                                    this.handleBloodAnalysis(value.value)
                                }}
                                search
                                selection
                                options={bloodAnalysisList}
                                multiple
                            // disabled={}
                            />
                        </div>
                    </div>
                </form> */}

                {/* <form onSubmit={(event) => console.log("yes-->")}>
                    <div className="form-row">
                        <div className="mt-2 mr-3">
                            Select Coach Package:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={coach_package}
                                className={
                                    "form-control "
                                }
                                placeholder="Coach Name"
                                onChange={(event, value) => {
                                    this.handleCoachPackage(value)
                                }}
                                search
                                selection
                                options={couchPackagesList}
                            />
                        </div>
                    </div>
                </form> */}
                <div style={{ display: "flex", gap:"18px" }}>
                    <div className="mr-5">Time zone:</div>
                    <div className="ml-4">{timeZone} Standard Time</div>
                </div>
                <h5 className="mt-4 mb-4">Availability</h5>
                <Card>
                    <DataTable
                        highlightOnHover
                        columns={columns}
                        data={tableList}
                        // defaultSortField="day"
                        // sortIcon={<ArrowDownward></ArrowDownward>}+
                        responsive
                        noHeader={true}
                    />
                </Card>
                  
                  <div className="page-header">
                  <div className="actions">
                        <div className="action-item">
                            <button
                                className="btn btn-danger button ml-2"
                                onClick={this.showDeleteAllModal}
                                disabled={!isEditable}
                            >
                                Delete All Slots
                            </button>
                        </div>
                    </div>
                  </div>

                <div className="page-header">
                    <h5 className="mt-4 mb-4">Holidays</h5>
                    <div className="actions">
                        <div className="action-item">
                            <button
                                className="btn btn-sm button text-button "
                                onClick={() => { this.handleHoliday() }}
                                disabled={!isEditable}
                            >
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                                add new
                            </button>
                        </div>
                    </div>
                </div>

                <Card>
                    <DataTable
                        highlightOnHover
                        columns={availabilityColumns}
                        data={availabilityData}
                        responsive
                        noHeader={true}
                    />
                </Card>

                <div className="form-row">
                    <div className="mt-2" style={{ marginRight: "89px" }}>
                        Booking Per Slot :
                    </div>
                    <div style={{marginLeft:"12px"}}className="form-group input-section input-section-long">
                        <input
                            type="text"
                            pattern="/^-?\d+\.?\d*$/"
                            maxlength="5"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 1 to infinity"
                            name="categoryEn"
                            value={selectedLab?.booking_per_slot}
                            min={1}
                            max={1000000}
                            InputProps={{
                                inputProps: {
                                    max: 1000000, min: 10
                                }
                            }}
                            onKeyUp={(e) => this.checkMaxLength(e.target.value)}
                            onChange={(e) => this.handleSelectedLabValueChange('booking_per_slot', e.target.value)}
                        />
                    </div>
                </div>
                <div className="form-row">
                    <div className="mt-2" style={{ marginRight: "126px" }}>
                        Lead Time :
                    </div>
                    <div style={{marginLeft:"15px"}} className="form-group input-section input-section-long">

                        <input
                            type="text"
                            pattern="/^-?\d+\.?\d*$/"
                            maxlength="5"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 0 to infinity"
                            name="categoryEn"
                            value={selectedLab?.lead_time}
                            min={0}
                            onChange={(e) => this.handleSelectedLabValueChange('lead_time', e.target.value)}
                        />
                    </div>
                    <span className="mt-2 ml-2">Hours</span>
                </div>
                <div className="form-row">
                    <div className="mr-5 mt-2">
                        Advance Booking days :
                    </div>
                    <div style={{marginLeft:"3px"}} className="form-group input-section input-section-long">
                        <input
                            type="text"
                            pattern="/^-?\d+\.?\d*$/"
                            maxlength="5"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 1 to infinity"
                            name="categoryEn"
                            value={selectedLab?.adv_booking_days}
                            min={1}
                            onChange={(e) => this.handleSelectedLabValueChange('adv_booking_days', e.target.value)}
                        />
                    </div>
                    <span className="mt-2 ml-2">Days</span>
                </div>
                <div className="page-header">
                    <h5 className="mt-4 mb-4">&nbsp;</h5>
                    <div className="actions">
                        <div className="action-item">
                            <button
                                onClick={(e) => this.handleFormSubmit(e)}
                                disabled={!isEditable}
                                className="btn button button-green"
                            >
                                Submit
                            </button>
                            <button
                                className="btn btn-secondary button ml-2"
                                onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots')}
                                disabled={!isEditable}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.state.confirmMessage}
                            {listItems}
                            {/* {this.state.selectedSlot && this.state.selectedSlot.map(data => {
                                <span>{data}</span>
                            })} */}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideConfirm}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-danger button"
                            onClick={this.handleDeleteAll}
                        >
                            Delete All
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteConfirm} onHide={this.hideDeleteConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.state.confirmMessage}

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideDeleteConfirm}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary button"
                            onClick={this.confirmDeleteSlot}
                        >
                            Confirm
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showDeleteAllModal} onHide={this.hideDeleteAllModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Delete All Slots</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            Are you sure you want to Delete All Slots for this selected lab?

                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideDeleteAllModal}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary button"
                            onClick={this.handleDeleteAllSlots}
                        >
                            Delete All
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>

        )
    }
}
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: value =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Labslots);