import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";


SelectSuggestedSupplement.propTypes = {

  selectedSuggestedSupplement: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectSuggestedSupplement.defaultTypes = {
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectSuggestedSupplement(props) {
  const {
    supplements,
    selectedSuggestedSupplement,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;
  const [suggestedSupplements, setSuggestedSupplements] = useState([]);



  const findSupplement = (supplementId) => supplements.length > 0 && supplements.find((supplement) => supplement.id === supplementId);


  const options = createOptions(supplements, showOnlyActive);

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };


  const selectedCityObject = findSupplement(selectedSuggestedSupplement);
  const selectedCityOption = selectedCityObject
    ? createOption(selectedCityObject)
    : null;


  return (
    <Select
      key={`my_unique_select_key__${selectedSuggestedSupplement && selectedSuggestedSupplement.id}`}
      value={selectedCityOption}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder="Select Supplements..."
      isDisabled={isDisabled}
    />
  );
}

function createOptions(supplements, showOnlyActive) {
  if (!supplements || !supplements.length) return [];

  const [activeCities, inactiveCities] = _.partition(
    supplements,
    (it) => it.status === "Active" ? true : false
  );


  activeCities.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );

  if (showOnlyActive) return activeCities.map(createOption);

  inactiveCities.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  return [
    {
      label: "Active",
      options: activeCities.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCities.map(createOption),
    },
  ];
}

const createOption = (supplement) => {

  if (supplement.unicommerceAvailableQuantity <= 0 || supplement.unicommerceAvailableQuantity == null || supplement.unicommerceAvailableQuantity == undefined) {
    return supplement
      ? {
        key: supplement.id,
        value: supplement.id,
        label: `${supplement.internalName} (OOS)`,
        unicommerceAvailableQuantity:supplement.unicommerceAvailableQuantity
      }
      : null;
  }
  else {
    return supplement
      ? {
        key: supplement.id,
        value: supplement.id,
        label: supplement.internalName,
        unicommerceAvailableQuantity:supplement.unicommerceAvailableQuantity
      }
      : null;
  }

};

export default SelectSuggestedSupplement;
