import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import Status from "components/Common/Status";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const CATEGORIES_OPTIONS = [
  {
    key: "Condition",
    value: "Condition",
    text: "Condition",
  },
  {
    key: "Supplement",
    value: "Supplement",
    text: "Supplement",
  },
  {
    key: "Medicine",
    value: "Medicine",
    text: "Medicine",
  },
  {
    key: "Blood_Package",
    value: "Blood_Package",
    text: "Blood Biomarker Package",
  },
  {
    key: "Custom_Package",
    value: "Custom_Package",
    text: "Custom Package",
  },
];

const BASE_URL_CONDITIONS = "conditions/";
const BASE_URL_SUPPLEMENTS = "/supplement/admin/v2/country/";
const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_CUSTOM_PACKAGES = "packages/";
const BASE_URL_BLOOD_BIOMARKER_PACKAGES = "/bloods/packages";

class ServiceItemsForm extends Component {
  constructor(props) {
    super(props);

    const { country, items = [], onItemsUpdated = (f) => f } = this.props;

    this.onItemsUpdated = onItemsUpdated;

    this.state = {
      items,
      conditions: [],
      supplements: [],
      medicines: [],
      customPackages: [],
      bloodBiomarkerPackages: [],
      serviceItemOptions: [],
      item: {},
      errors: {},
      editables: {},
      country,
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Category",
        selector: "itemCategory",
        sortable: true,
        wrap: true,
        format: (item) => this.findFormattedCategory(item),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (item) => <Status isActive={item.status === "Active"} />,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (item) => this.renderActionBar(item),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidMount() {
    this.fetchServiceItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { item } = this.state;
    const { item: oldItem } = prevState;
    if (item?.itemCategory !== oldItem?.itemCategory)
      this.createServiceItemOptions();
  }

  findFormattedCategory = (item) => {
    if (!item) return "";

    const option = CATEGORIES_OPTIONS.find(
      (it) => it.value === item.itemCategory
    );
    return option ? option.text : item.itemCategory;
  };

  fetchServiceItems = () => {
    this.fetchConditions();
    this.fetchSupplements();
    this.fetchMedicines();
    this.fetchCustomPackages();
    this.fetchBloodBiomarkerPackages();
  };

  fetchConditions = async () => {
    try {
      const { data: conditions } = await PhoenixAPI.get(BASE_URL_CONDITIONS);
      this.setState({ conditions });
    } catch (error) {
      console.log("Error on fetching conditions", error);
    }
  };

  fetchSupplements = async () => {
    const { country } = this.props;
    try {
      const { data: supplements } = await PhoenixAPI.get(`${BASE_URL_SUPPLEMENTS}${country}`);
      
      this.setState({ supplements });
    } catch (error) {
      console.log("Error on fetching supplements", error);
    }
  };

  fetchMedicines = async () => {
    try {
      const { data: medicines } = await PhoenixAPI.get(BASE_URL_MEDICINES);
      this.setState({ medicines });
    } catch (error) {
      console.log("Error on fetching medicines", error);
    }
  };

  fetchCustomPackages = async () => {
    try {
      const { data: customPackages } = await API.get(BASE_URL_CUSTOM_PACKAGES);
      this.setState({ customPackages });
    } catch (error) {
      console.log("Error on fetching custom packages", error);
    }
  };

  fetchBloodBiomarkerPackages = async () => {
    try {
      const { data: bloodBiomarkerPackages } = await PhoenixAPI.get(
        `${BASE_URL_BLOOD_BIOMARKER_PACKAGES}?country=${this.props.country}`
      );
      this.setState({ bloodBiomarkerPackages });
    } catch (error) {
      console.log("Error on fetching blood biomarker packages", error);
    }
  };

  createServiceItemOptions = () => {
    const {
      item,
      conditions = [],
      supplements = [],
      medicines = [],
      customPackages = [],
      bloodBiomarkerPackages = [],
    } = this.state;
    console.log("Creating service item options");
    switch (item?.itemCategory) {
      case "Condition": {
        const serviceItemOptions = this.createOptions(
          conditions,
          item?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Supplement": {
        const serviceItemOptions = this.createOptions(
          supplements,
          item?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Medicine": {
        const serviceItemOptions = this.createOptions(
          medicines,
          item?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Custom_Package": {
        const serviceItemOptions = this.createOptions(
          customPackages,
          item?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Blood_Package": {
        const serviceItemOptions = this.createOptions(
          bloodBiomarkerPackages,
          item?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      default: {
        console.log("Invalid service item category", item?.itemCategory);
        return;
      }
    }
  };

  createOptions = (items, category) => {
    const itemsForCountry = items.filter(
      (it) => it.country === this.state.country
    );
    const [activeItems, inactiveItems] = _.partition(itemsForCountry, (it) =>
      category === "Blood_Package"
        ? it.is_active ?? false
        : it.status === "Active"
    );
    return [
      {
        label: "Active",
        options: activeItems.map((item) => this.createOption(item, category)),
      },
      {
        label: "Inactive",
        options: inactiveItems.map((item) => this.createOption(item, category)),
      },
    ];
  };

  createOption = (item, category) => {
    let name;
    if (category === "Custom_Package") name = item.name;
    else if (category === "Blood_Package") name = item.subscription_name;
    else name = item.internalName;
    return {
      key: item.id,
      value: name,
      label: name,
    };
  };

  clearForm = () => {
    this.setState({ item: {}, errors: {}, editables: {} });
  };

  handleAddNewServiceItem = () => {
    const item = { status: "Inactive" };
    this.setState({ item }, () => this.showAddEditModal());
  };

  handleChange = ({ currentTarget: input }) => {
    const item = { ...this.state.item };
    item[input.name] = input.value;
    this.setState({ item });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { items = [] } = this.state;
    const item = items.find((it) => it.id === id);
    if (!item) return;

    this.setState({ item }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteItem(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { item = {} } = this.state;
    if (item.id === null || item.id === undefined) this.createItem();
    else this.updateItem(item.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { itemCategory, name, status } = this.state.item || {};

    if (itemCategory === null || itemCategory === undefined)
      errors.itemCategory = "Category cannot be blank";

    if (name === null || name === undefined)
      errors.name = "Name cannot be blank";

    if (status === null || status === undefined)
      errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createItem = () => {
    const { items = [], item } = this.state;
    const ids = items.length > 0 ? items.map((it) => it.id) : null;
    item.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      items.length > 0
        ? items.filter((it) => it.rank).map((it) => it.rank)
        : null;
    item.rank = ranks ? Math.max(...ranks) + 1 : 1;
    item.is_new = true;

    this.setState({ items: [...items, item] }, () => {
      this.showSuccessNotification("Service Item Created Successfully!");
      this.onItemsUpdatedLocally();
    });
  };

  updateItem = (id) => {
    const { items = [], item } = this.state;
    const newItems = items.map((it) => (it.id === id ? item : it));
    this.setState({ items: newItems }, () => {
      this.showSuccessNotification("Service Item Updated Successfully!");
      this.onItemsUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { items = [] } = this.state;
    const newItems = items.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ items: newItems }, () => {
      this.showSuccessNotification("Status Updated Successfully!");
      this.onItemsUpdatedLocally();
    });
  };

  deleteItem = (id) => {
    const { items = [] } = this.state;
    const itemToDelete = items.find((it) => it.id === id) || {};
    const newItems = items
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > itemToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ items: newItems }, () => {
      this.showSuccessNotification("Service Item Deleted Successfully!");
      this.onItemsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { items = [], sortColumn, sortDirection } = this.state;
    const sortedItems = _.sortBy(items, sortColumn, sortDirection);
    const fromItem = { ...sortedItems[fromIndex] };
    const toItem = { ...sortedItems[toIndex] };

    if (fromItem && toItem) {
      const tempRank = fromItem.rank;
      fromItem.rank = toItem.rank;
      toItem.rank = tempRank;

      const newItems = items.map((it) => {
        if (it.id === fromItem.id) return fromItem;
        else if (it.id === toItem.id) return toItem;
        else return it;
      });
      this.setState({ items: newItems }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onItemsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onItemsUpdatedLocally = () => {
    this.clearForm();
    this.onItemsUpdated(
      this.state.items.map((item) => {
        const i = { ...item };
        if (i.is_new) {
          delete i.id;
          delete i.is_new;
        }
        return i;
      })
    );
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  renderActionBar = (item) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(item.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(item.id)}>
          {item.status === "Active" ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {item.status === "Active" ? "Inactive" : "Active"}
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(question.id)}
          onClick={() =>
            this.setState({ questionToDelete: item.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.props.isEditable && this.state.item.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      items = [],
      item = {},
      errors,
      editables,
      sortColumn,
      isAddEditModalVisible,
      isConfirmDeleteModalVisible,
    } = this.state;

    const isNewItem = item.id === null || item.id === undefined;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="itemCategory">Item Category</label>
            <div className="input-group">
              <Dropdown
                value={item?.itemCategory}
                onChange={(event, value) => {
                  this.setState({
                    item: {
                      ...item,
                      itemCategory: value.value,
                    },
                  });
                }}
                id="itemCategory"
                name="itemCategory"
                placeholder="Select Item Category"
                search
                selection
                options={CATEGORIES_OPTIONS}
                disabled={!isNewItem && !editables.itemCategory}
                className={
                  "form-control" + (errors.itemCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("itemCategory")}
            </div>
            <span className="help-block">{errors.itemCategory}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="name">Item Name</label>
            <div className="input-group">
              <Select
                key={`my_unique_select_key__${item && item.name}`}
                value={
                  item && {
                    key: item.itemEntityId,
                    value: item.name,
                    label: item.name,
                  }
                }
                onChange={(option) => {
                  console.log("Selected Item", option);
                  this.setState({
                    item: {
                      ...item,
                      name: option.value,
                      itemEntityId: option.key,
                    },
                  });
                }}
                options={this.state.serviceItemOptions || []}
                styles={selectStyles}
                placeholder="Item Name"
                isDisabled={!isNewItem && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={item?.status}
                onChange={(event, value) => {
                  this.setState({
                    item: {
                      ...item,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewItem && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={items || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={() => this.handleAddNewServiceItem()}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {item.id === null || item.id === undefined
                ? "Add Service Item"
                : "Edit Service Item"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {item.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Service Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the service item?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteItem(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ServiceItemsForm.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  country: PropTypes.number.isRequired,
  onItemsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

ServiceItemsForm.defaultProps = {
  items: [],
  onItemsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItemsForm);
