import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "../../utils/PhoenixAPI";
import { connect } from "react-redux";
import { formatDate } from "../../utils/commons"
import { Page } from "utils/constant";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50];

class PackageOrders extends Component{
    constructor(props){
        super(props);
        const{packageType="",packageId,packageOrderDetails=[],totalRows}=this.props;
         this.state={
          sortColumn:"id",
          sortDirection: "desc",
          totalRows,
          rowsPerPage:DEFAULT_ROWS_PER_PAGE,
          packageOrderDetails,

         }

        this.columns=[
            {
                name:"Child OrderId",
                selector:"id",
                wrap:true,
                sortable:true,
            },
            {
                name:"Date of Purchase",
                selector:"purchase_date",
                wrap:true,
                sortable:true,
                format: (row) => row.purchase_date ? formatDate(row.purchase_date) : "-",
            },
            {
                name:"User Email",
                selector:"user_email",
                wrap:true,
               // sortable:true,
            },
            {
                name:"User Name",
                selector:"username",
                wrap:true,

            },
            {
                name:"Coupon Code",
                selector:"coupon_code",
                wrap:true,
            }
        ];
    }
showSuccessNotification = (notificationMessage) =>
this.showNotification(notificationMessage, true);

showErrorNotification = (notificationMessage) =>
this.showNotification(notificationMessage, false);

showNotification = (notificationMessage, isSuccessMessage) =>
this.props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

   fetchPackageOrders=async (page = 1)=>{
    const { 
        rowsPerPage, 
        sortColumn, 
        sortDirection,
        totalRows

   }=this.state;
   console.log("rows per page",rowsPerPage);
   const{packageType,packageId}=this.props;
   const  sf=sortDirection === "desc" ? `-${sortColumn}` : sortColumn;
   const baseUrl=`package-orders/${packageType}/${packageId}?page=${page}&size=${rowsPerPage}&ordering=${sf}`;
   try{
    this.showLoading();
    
    const {data}= await PhoenixAPI.get(baseUrl);
    console.log("fetchBloodPackageOrders:: Fetched Blood Package order: ", data);
   this.setState({packageOrderDetails:data.content,totalRows:data.totalElements,rowsPerPage:rowsPerPage})
   }
   catch(error){
    console.log("fetchPackageOrders:: Fetched orders: ", error);
    this.showErrorNotification("Error on fetching orders of package! " + error.message);
    return null;
   }
   finally{
    this.hideLoading();
   }
    

   }
    handlePageChange =(page) => {
        
          this.fetchPackageOrders(page)
        
      };

    handleRowsPerPageChange=(newRowsPerPage, page) => {
       console.log("new rows ",newRowsPerPage);
        this.setState({ rowsPerPage: newRowsPerPage }, () => {
         
            this.fetchPackageOrders(page)
          }
        
        );
      }  ;
      handleSort = (column, sortDirection) => {
        console.log(column, sortDirection);
       
        this.setState(
          { sortColumn: column.sortField || column.selector, sortDirection: sortDirection },
          () => {
            
              this.fetchPackageOrders()
            }
          
        );
      };
    

 render(){
    const{packageOrderDetails,rowsPerPage}=this.state;
    return(<>
    <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
            <DataTable
                highlightOnHover
                columns={this.columns}
                data={packageOrderDetails || []}
                
                pagination
                paginationServer
                paginationTotalRows={this.state.totalRows || 0}
                paginationPerPage={rowsPerPage || DEFAULT_ROWS_PER_PAGE}
                paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
                responsive
                sortServer
                
                onSort={this.handleSort}
                defaultSortField="id"
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
                />
            </Card>
          </div >
          </div>
          </div>
    </>

    );

 }


}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PackageOrders);