import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
const BASE_URL_ARTICLE_CATEGORIES = "/health-hub-category";
const BASE_URL_ARTICLES = "/health-hub";
const BASE_URL_AUTHORS = "/health-hub/author";

export const fetchAuthors = async () => {
  try {
    const { data: authors } = await PhoenixAPI.get(BASE_URL_AUTHORS);
    return authors;
  } catch (error) {
    console.error(
      "fetchAuthors:: Error on fetching authors from this URL: ",
      BASE_URL_AUTHORS
    );
    console.error("fetchAuthors:: Error on fetching authors!", error);
    throw error;
  }
};

export const fetchArticleCategories = async () => {
  try {
    const { data: articleCategories } = await PhoenixAPI.get(
      BASE_URL_ARTICLE_CATEGORIES
    );
    return articleCategories;
  } catch (error) {
    console.error(
      "fetchArticleCategories:: Error on fetching article categories from this URL: ",
      BASE_URL_ARTICLE_CATEGORIES
    );
    console.error(
      "fetchArticleCategories:: Error on fetching article categories",
      error
    );
    throw error;
  }
};

export const saveArticleCategory = async (articleCategory) => {
  try {
    const payload = { ...articleCategory };
    delete payload["imageSrc"];

    const formData = new FormData();
    if (typeof articleCategory.image !== "string") {
      delete payload["image"];
      formData.append("image", articleCategory.image);
    }

    formData.append("health_hub_cat", JSON.stringify(payload));

    const apiOptions = {
      method: articleCategory.id ? "PATCH" : "POST",
      url: articleCategory.id
        ? `${BASE_URL_ARTICLE_CATEGORIES}/${articleCategory.id}`
        : BASE_URL_ARTICLE_CATEGORIES,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    return await PhoenixAPI.request(apiOptions);
  } catch (error) {
    console.log(
      "saveArticleCategory:: Error on saving article category",
      error
    );
    throw error;
  }
};

export const fetchArticles = async () => {
  try {
    const { data: articles } = await PhoenixAPI.get(BASE_URL_ARTICLES);
    return articles;
  } catch (error) {
    console.error(
      "fetchArticles:: Error on fetching articles from this URL: ",
      BASE_URL_ARTICLES
    );
    console.error("fetchArticles:: Error on fetching articles", error);
    throw error;
  }
};

export const saveSearchTagDocument=async(response,article,country)=>{
  console.log("check doc", country);
  let dateTime=new Date();
  const dateform = moment(dateTime).format("DD/MM/YYYY");
 
  const payload={
    "docId":article?.searchDocId==null?Date.now():article?.searchDocId,
  
    "itemId": article.id?article.id:response?.data?.id,
    "itemCategory": "Health Hub",
    "productNameH1":article?.articleNameEnglish,
    "descriptionH2":article?.articleDescriptionEnglish,
    "whatsIncluded":"",
    "relatedSymptoms":"",
    "dateAdded":dateform,
    "imageS3Url":Object.keys(article?.primaryImage).length>0?article?.primaryImage:response.data.primaryImage,
    "thumbnailS3Url":"",
    "price":0.0,
    "seoUrl":article?.seoUrl,
    "searchTags":article?.searchTags,
  }
  let url="create/search-document/"+country;
  return await PhoenixAPI.post(url,payload);
}

export const saveArticle = async (article) => {
  try {
    const payload = { ...article };
    delete payload["primaryImageSrc"];

    const formData = new FormData();
    if (typeof article.primaryImage !== "string") {
      delete payload["primaryImage"];
      formData.append("image", article.primaryImage);
    }

    formData.append("health_hub", JSON.stringify(payload));

    const apiOptions = {
      method: article.id ? "PATCH" : "POST",
      url: article.id
        ? `${BASE_URL_ARTICLES}/${article.id}`
        : BASE_URL_ARTICLES,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    return await PhoenixAPI.request(apiOptions);
  } catch (error) {
    console.log("saveArticle:: Error on saving article", error);
    throw error;
  }
};
