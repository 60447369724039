import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Select from "react-select";
import _ from "lodash";
import Status from "components/Common/Status";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const SOLUTION_TYPE_OPTIONS = [
  {
    key: "Star",
    value: "Star",
    text: "Star",
  },
  {
    key: "Primary",
    value: "Primary",
    text: "Primary",
  },
  {
    key: "Addon",
    value: "Addon",
    text: "Add-on",
  },
];
const CATEGORY_TYPE_OPTIONS = [
  {
    key: "Bundle",
    value: "Bundle",
    text: "Bundle",
  },
  {
    key: "Supplement",
    value: "Supplement",
    text: "Supplement",
  },
  {
    key: "Medicine",
    value: "Medicine",
    text: "Medicine",
  },
  {
    key: "Blood_Package",
    value: "Blood_Package",
    text: "Blood Biomarker Package",
  },
  {
    key: "Custom_Package",
    value: "Custom_Package",
    text: "Custom Package",
  },
];

const BASE_URL_BUNDLES = "api/v1/bundles/";
const BASE_URL_SUPPLEMENTS = "/supplement/admin/v2/country/";
const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_CUSTOM_PACKAGES = "packages/";
const BASE_URL_BLOOD_BIOMARKER_PACKAGES = "/bloods/packages";

class ConditionSolutionsForm extends Component {
  constructor(props) {
    super(props);

    const { solutions = [], onSolutionsUpdated = (f) => f } = this.props;

    this.onSolutionsUpdated = onSolutionsUpdated;

    this.state = {
      bundles: [],
      supplements: [],
      medicines: [],
      customPackages: [],
      bloodBiomarkerPackages: [],
      serviceItemOptions: [],
      solutions,
      solution: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Item Category",
        selector: "itemCategory",
        sortable: true,
        wrap: true,
        format: (solution) => <p>{this.getFormattedItemCategory(solution)}</p>,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (solution) => <Status isActive={solution.status === "Active"} />,
      },
      {
        name: "Special Tag (English)",
        selector: "specialTagEnglish",
        sortable: true,
        wrap: true,
        format: (solution) => (
          <div className="text-overflow-ellipsis">
            {solution.specialTagEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Special Tag (Arabic)",
        selector: "specialTagArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (solution) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {solution.specialTagArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Type of Solution",
        selector: "typeOfSolution",
        sortable: true,
        wrap: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (solution) => this.renderActionBar(solution),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidMount() {
    this.fetchServiceItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { solution } = this.state;
    const { solution: oldSolution } = prevState;
    if (solution?.itemCategory !== oldSolution?.itemCategory)
      this.createServiceItemOptions();
  }

  fetchServiceItems = () => {
    this.fetchBundles();
    this.fetchSupplements();
    this.fetchMedicines();
    this.fetchCustomPackages();
    this.fetchBloodBiomarkerPackages();
  };

  fetchBundles = async () => {
    try {
      const { data: bundles } = await PhoenixAPI.get(BASE_URL_BUNDLES);
      this.setState({
        bundles: bundles.map((it) => ({
          id: it.id,
          internalName: it.internalName,
          status: it.status,
          country: it.country,
        })),
      });
    } catch (error) {
      console.log("Error on fetching bundles", error);
    }
  };

  fetchSupplements = async () => {
    
    try {
      const { data: supplements } = await PhoenixAPI.get(`${BASE_URL_SUPPLEMENTS}${this.props.countryId}`);
      this.setState({
        supplements: supplements.map((it) => ({
          id: it.id,
          internalName: it.internalName,
          status: it.status,
          country: it.country,
        })),
      });
    } catch (error) {
      console.log("Error on fetching supplements", error);
    }
  };

  fetchMedicines = async () => {
    try {
      const { data: medicines } = await PhoenixAPI.get(BASE_URL_MEDICINES);
      this.setState({
        medicines: medicines.map((it) => ({
          id: it.id,
          internalName: it.internalName,
          status: it.status,
          country: it.country,
        })),
      });
    } catch (error) {
      console.log("Error on fetching medicines", error);
    }
  };

  fetchCustomPackages = async () => {
    try {
      const { data: customPackages } = await API.get(BASE_URL_CUSTOM_PACKAGES);
      this.setState({ customPackages });
    } catch (error) {
      console.log("Error on fetching custom packages", error);
    }
  };

  fetchBloodBiomarkerPackages = async () => {
    try {
      const { data: bloodBiomarkerPackages } = await PhoenixAPI.get(
         `${BASE_URL_BLOOD_BIOMARKER_PACKAGES}?country=${this.props.countryId}`
      );
      this.setState({ bloodBiomarkerPackages });
    } catch (error) {
      console.log("Error on fetching blood biomarker packages", error);
    }
  };

  getFormattedItemCategory = (solution) => {
    if (!solution) return "";

    const option = CATEGORY_TYPE_OPTIONS.find(
      (it) => it.value === solution.itemCategory
    );
    return option ? option.text : solution.itemCategory;
  };

  createServiceItemOptions = () => {
    const {
      solution,
      bundles = [],
      supplements = [],
      medicines = [],
      customPackages = [],
      bloodBiomarkerPackages = [],
    } = this.state;

    console.log("Creating service item options: ", solution);

    switch (solution?.itemCategory) {
      case "Bundle": {
        const serviceItemOptions = this.createOptions(bundles, "Bundle");
        console.log("Bundle serviceItemOptions", serviceItemOptions);
        this.setState({ serviceItemOptions });
        break;
      }
      case "Supplement": {
        const serviceItemOptions = this.createOptions(
          supplements,
          solution?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Medicine": {
        const serviceItemOptions = this.createOptions(
          medicines,
          solution?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Custom_Package": {
        const serviceItemOptions = this.createOptions(
          customPackages,
          solution?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Blood_Package": {
        const serviceItemOptions = this.createOptions(
          bloodBiomarkerPackages,
          solution?.itemCategory
        );
        this.setState({ serviceItemOptions });
        break;
      }
      default: {
        console.log("Invalid service item category", solution?.packageType);
        return;
      }
    }
  };

  createOptions = (items, category) => {
    const itemsForCountry = items.filter(
      (it) => it.country === this.props.countryId
    );
    const [activeItems, inactiveItems] = _.partition(itemsForCountry, (it) =>
      category === "Blood_Package"
        ? it.is_active ?? false
        : it.status === "Active"
    );
    return [
      {
        label: "Active",
        options: activeItems.map((item) => this.createOption(item, category)),
      },
      {
        label: "Inactive",
        options: inactiveItems.map((item) => this.createOption(item, category)),
      },
    ];
  };

  createOption = (item, category) => {
    let name;
    if (category === "Custom_Package") name = item.name;
    else if (category === "Blood_Package") name = item.subscription_name;
    else name = item.internalName;
    return {
      key: item.id,
      value: name,
      label: name,
    };
  };

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      solution: {},
      serviceItemOptions: [],
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const solution = { ...this.state.solution };
    solution[input.name] = input.value;
    this.setState({ solution });
  };

  handleAddNewSolution = () => {
    const solution = { status: "Inactive" };
    this.setState({ solution }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const { solutions = [] } = this.state;
    const solution = solutions.find((it) => it.id === id);
    if (!solution) return;

    this.setState({ solution }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteSolution(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { solution = {} } = this.state;
    if (solution.id === null || solution.id === undefined)
      this.createSolution();
    else this.updateSolution(solution.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      itemCategory,
      itemEntityId,
      specialTagEnglish,
      specialTagArabic,
      typeOfSolution,
      status,
    } = this.state.solution || {};

    if (!itemCategory) errors.itemCategory = "Item Category cannot be blank";

    if (itemEntityId === null || itemEntityId === undefined)
      errors.itemName = "Item Name cannot be blank";

    if (specialTagEnglish && specialTagEnglish.length > 1000)
      errors.specialTagEnglish =
        "Special Tag (English) is limited to a 1000 characters";

    if (specialTagArabic && specialTagArabic.length > 1000)
      errors.specialTagArabic =
        "Special Tag (Arabic) is limited to a 1000 characters";

    if (!typeOfSolution)
      errors.typeOfSolution = "Type of Solution cannot be blank";

    if (!status) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createSolution = () => {
    const { solutions = [], solution } = this.state;
    const ids = solutions.length > 0 ? solutions.map((it) => it.id) : null;
    solution.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      solutions.length > 0
        ? solutions.filter((it) => it.rank).map((it) => it.rank)
        : null;
    solution.rank = ranks ? Math.max(...ranks) + 1 : 1;
    solution.is_new = true;

    this.setState({ solutions: [...solutions, solution] }, () => {
      this.showSuccessNotification("Solution Created Successfully!");
      this.onSolutionsUpdatedLocally();
    });
  };

  updateSolution = (id) => {
    const { solutions = [], solution } = this.state;
    const newSolutions = solutions.map((it) => (it.id === id ? solution : it));
    this.setState({ solutions: newSolutions }, () => {
      this.showSuccessNotification("Solution Updated Successfully!");
      this.onSolutionsUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { solutions = [] } = this.state;
    const newSolutions = solutions.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ solutions: newSolutions }, () => {
      this.showSuccessNotification("Status Updated Successfully!");
      this.onSolutionsUpdatedLocally();
    });
  };

  deleteSolution = (id) => {
    const { solutions = [] } = this.state;
    const solutionToDelete = solutions.find((it) => it.id === id) || {};
    const newSolutions = solutions
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > solutionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ solutions: newSolutions }, () => {
      this.showSuccessNotification("Solution Deleted Successfully!");
      this.onSolutionsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { solutions = [], sortColumn, sortDirection } = this.state;
    const sortedSolutions = _.sortBy(solutions, sortColumn, sortDirection);
    const fromSolution = { ...sortedSolutions[fromIndex] };
    const toSolution = { ...sortedSolutions[toIndex] };

    if (fromSolution && toSolution) {
      const tempRank = fromSolution.rank;
      fromSolution.rank = toSolution.rank;
      toSolution.rank = tempRank;

      const newSolutions = solutions.map((it) => {
        if (it.id === fromSolution.id) return fromSolution;
        else if (it.id === toSolution.id) return toSolution;
        else return it;
      });
      this.setState({ solutions: newSolutions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onSolutionsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onSolutionsUpdatedLocally = () => {
    this.clearForm();
    this.onSolutionsUpdated(
      this.state.solutions.map((solution) => {
        const s = { ...solution };
        if (s.is_new) {
          delete s.id;
          delete s.is_new;
        }
        return s;
      })
    );
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  renderActionBar = (solution) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(solution.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(solution.id)}>
          {solution.status === "Active" ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {solution.status === "Active" ? "Inactive" : "Active"}
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ solutionToDelete: solution.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.solution.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      solutions = [],
      solution = {},
      serviceItemOptions,
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewSolution = solution.id === null || solution.id === undefined;
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="itemCategory">Item Category*</label>
            <div className="input-group">
              <Dropdown
                value={solution?.itemCategory}
                onChange={(event, value) => {
                  this.setState({
                    solution: {
                      ...solution,
                      itemCategory: value.value,
                    },
                  });
                }}
                id="itemCategory"
                name="itemCategory"
                placeholder="Select Item Category?"
                search
                selection
                options={CATEGORY_TYPE_OPTIONS}
                disabled={!isNewSolution && !editables.itemCategory}
                className={
                  "form-control" + (errors.itemCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("itemCategory")}
            </div>
            <span className="help-block">{errors.itemCategory}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="itemName">Item Name*</label>
            <div className="input-group">
              <Select
                key={`my_unique_select_key__${
                  solution && solution.itemEntityId
                }`}
                id="itemName"
                name="itemName"
                value={
                  solution && {
                    key: solution?.itemEntityId,
                    value: solution?.name,
                    label: solution?.name,
                  }
                }
                onChange={(option) => {
                  console.log("Selected Item", option);
                  this.setState({
                    solution: {
                      ...solution,
                      itemEntityId: option.key,
                      name: option.value,
                    },
                  });
                }}
                options={serviceItemOptions || []}
                styles={selectStyles}
                placeholder="Item Name"
                isDisabled={!isNewSolution && !editables.itemName}
              />
              {this.renderPencilIcon("itemName")}
            </div>
            <span className="help-block">{errors.itemName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specialTagEnglish">Special Tag (English)</label>
            <div className="input-group">
              <textarea
                value={solution.specialTagEnglish}
                onChange={this.handleChange}
                id="specialTagEnglish"
                name="specialTagEnglish"
                placeholder="Special Tag in English"
                readOnly={!isNewSolution && !editables.specialTagEnglish}
                className={
                  "form-control py-2" +
                  (errors.specialTagEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("specialTagEnglish")}
            </div>
            <span className="help-block">{errors.specialTagEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="specialTagArabic">Special Tag (Arabic)</label>
            <div className="input-group">
              <textarea
                value={solution.specialTagArabic}
                onChange={this.handleChange}
                id="specialTagArabic"
                name="specialTagArabic"
                placeholder="Special Tag in Arabic"
                readOnly={!isNewSolution && !editables.specialTagArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.specialTagArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("specialTagArabic")}
            </div>
            <span className="help-block">{errors.specialTagArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="typeOfSolution">Type of Solution*</label>
            <div className="input-group">
              <Dropdown
                value={solution?.typeOfSolution}
                onChange={(event, value) => {
                  this.setState({
                    solution: {
                      ...solution,
                      typeOfSolution: value.value,
                    },
                  });
                }}
                id="typeOfSolution"
                name="typeOfSolution"
                placeholder="Select Type of Solution"
                search
                selection
                options={SOLUTION_TYPE_OPTIONS}
                disabled={!isNewSolution && !editables.typeOfSolution}
                className={
                  "form-control" + (errors.typeOfSolution ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("typeOfSolution")}
            </div>
            <span className="help-block">{errors.typeOfSolution}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={solution?.status}
                onChange={(event, value) => {
                  this.setState({
                    solution: {
                      ...solution,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSolution && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={solutions || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewSolution}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {solution.id === null || solution.id === undefined
                ? "Add Solution Component Item"
                : "Edit Solution Component Item"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {solution.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Solution</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the solution?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteSolution(this.state.solutionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ConditionSolutionsForm.propTypes = {
  countryId: PropTypes.number.isRequired,
  solutions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSolutionsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

ConditionSolutionsForm.defaultProps = {
  solutions: [],
  onSolutionsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionSolutionsForm);
