import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_OTHER_ITEMS = "other-items/";

export const fetchOtherItems = async () => {
  try {
    const { data: otherItems = [] } = await PhoenixAPI.get(
      BASE_URL_OTHER_ITEMS
    );
    return otherItems;
  } catch (error) {
    console.log("Error on fetching otherItems", error);
    throw error;
  }
};

export const saveOtherItem = async (otherItem) => {
  try {
    const payload = { ...otherItem };
    delete payload["image"];
    delete payload["image_src"];
    const formData = new FormData();
    formData.append("otherItems", JSON.stringify(payload));
    formData.append("image", otherItem.image);

    const apiOptions = {
      method: "post",
      url: BASE_URL_OTHER_ITEMS,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating other-item", error);
    throw error;
  }
};

export const updateOtherItem = async (otherItem) => {
  try {
    const payload = { ...otherItem };
    delete payload["image_src"];
    if (typeof otherItem.image !== "string") delete payload["image"];

    const formData = new FormData();
    formData.append("otherItems", JSON.stringify(payload));
    if (typeof otherItem.image !== "string")
      formData.append("image", otherItem.image);

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_OTHER_ITEMS}${otherItem.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating other-item", error);
    throw error;
  }
};

const OtherItemService = {
  fetchOtherItems,
  saveOtherItem,
  updateOtherItem,
};

export default OtherItemService;
