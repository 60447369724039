import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tabs, Tab } from "react-bootstrap";
import "./broadCasting.scss";

class PriorityBasket extends Component {
  constructor(props) {
    super(props);
    const {
      basket,
      handleView = (f) => f,
      homecareServices,
      allBloodPackages,
      allCustomPackages,
      allMiniPackages,
    } = this.props;
    this.handleView = handleView;
    this.state = {
      basket,
      bloodPackages: allBloodPackages,
      customPackages: this.props.allCustomPackages,
      miniPackages: this.props.allMiniPackages,
    };
  }
  componentDidMount() {

  }
  

  
  handleView = (basket) => {
    console.warn("this is the basket",basket)
    this.handleView(basket);
  };
  renderHomeCares = (basket) => {
    const { homecareServices } = this.props;
    const { homecares, basketHomecares} = basket || {};
    const mappedHomecares= basketHomecares&& basketHomecares.map((item)=>{
      return item.homecareId
    })
    
    const selectedHomeCares =
      homecareServices.length > 0 &&
      homecareServices
        .filter((item) => mappedHomecares.includes(item.id))
        .map((item) => item.name);

    return (
      selectedHomeCares.length > 0 &&
      selectedHomeCares.map((item) => {
        return <div className="packages-cont">{item}</div>;
      })
    );
  };
  // renderBloodPackages = (basket) => {
  //   const { allBloodPackages } = this.props;
  //   const { bloodPackages } = basket || {};

  //   const selectedBloodPackages =
  //     allBloodPackages.length > 0 &&
  //     allBloodPackages
  //       .filter((item) => bloodPackages.includes(item.id))
  //       .map((item) => item.internal_name);

  //   return (
  //     selectedBloodPackages.length > 0 &&
  //     selectedBloodPackages.map((item) => {
  //       return <div className="packages-cont">{item}</div>;
  //     })
  //   );
  // };
  // renderCustomPackages = (basket) => {
  //   const { allCustomPackages } = this.props;
  //   const { customPackages } = basket || {};

  //   const selectedCustomPackages =
  //     allCustomPackages.length > 0 &&
  //     allCustomPackages
  //       .filter((item) => customPackages.includes(item.id))
  //       .map((item) => item.name);

  //   return (
  //     selectedCustomPackages.length > 0 &&
  //     selectedCustomPackages.map((item) => {
  //       return <div className="packages-cont">{item}</div>;
  //     })
  //   );
  // };
  // renderMiniPackages = (basket) => {
  //   const { allMiniPackages } = this.props;
  //   const { miniPackages } = basket || {};

  //   const selectedMiniPackages =
  //     allMiniPackages.length > 0 &&
  //     allMiniPackages
  //       .filter((item) => miniPackages.includes(item.id))
  //       .map((item) => item.miniPackageInternalName);

  //   return (
  //     selectedMiniPackages.length > 0 &&
  //     selectedMiniPackages.map((item) => {
  //       return <div className="packages-cont">{item}</div>;
  //     })
  //   );
  // };

  render() {
    const {basket}= this.props
    return (
      <div className="basket-container">
        <Card body>
          <div className="basket-name-cont" style={{}}>
            <div className="basket-name">{basket.basketName}</div>
            <div className="edit-cont">
              <div className="priority">Priority : {basket.priority}</div>
              <div>
                <button
                  className="priority-btn"
                  onClick={() => this.handleView(basket)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>

          <div className="additional-cont">
            <div className="service-name">Home Cares</div>
            <div className="services">{this.renderHomeCares(basket)}</div>
          </div>
          {/* <div className="additional-cont">
            <div className="service-name">Blood Packages</div>
            <div className="services">{this.renderBloodPackages(basket)}</div>
          </div>
          <div className="additional-cont">
            <div className="service-name">Custom Packages</div>
            <div className="services">{this.renderCustomPackages(basket)}</div>
          </div>
          <div className="additional-cont">
            <div className="service-name">Mini Packages</div>
            <div className="services">{this.renderMiniPackages(basket)}</div>
          </div> */}
          <div className="additional-cont">
            <div className="service-name">Countdown Timer : {basket.timer}</div>
          </div>
        </Card>
      </div>
    );
  }
}

export default PriorityBasket;
