import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { CircularProgress } from "@material-ui/core";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { filterList } from "utils/List";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import QuestionType from "components/Common/QuestionType";
import SearchBar from "components/Common/SearchBar";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class LifestyleSurveyResults extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "userId",
        method: "isEmpty",
        validWhen: false,
        message: "Client is required.",
      },
    ]);

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true,
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true,
      },
      {
        name: "Answer Type",
        selector: "survey_answer_type.answer_type",
        sortable: true,
        center: true,
        wrap: true,
        cell: (question) => <QuestionType questionType={question.survey_answer_type.id} />,
      },
      {
        name: "Answer",
        selector: "answer",
        sortable: true,
        wrap: true,
      },
    ];

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      answers: [],
      isLoaded: false,
      loadError: "",
      answerLoading: false,
      answersLoaded: false,
      answersLoadError: "",
      userId:
        this.props.match.params.userId != undefined &&
        this.props.match.params.userId !== ":userId"
          ? this.props.match.params.userId
          : "",
      users: [],
      userListFormatted: [],
      validation: this.validator.valid(),
      disableDropdown: false,
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.loadUserAnswers = this.loadUserAnswers.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
    if (this.state.userId != "") {
      this.loadUserAnswers();
      this.setState({
        disableDropdown: true,
      });
    }
    // this.loadUsers();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.LIFESTYLE_PROFILE_RESULTS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  // loadUsers() {
  //   this.props.toggleLoading({
  //     isLoading: true,
  //   });

  //   API.get("list-users/")
  //     .then((response) => {
  //       let userListFormatted = response.data.map((user) => {
  //         return {
  //           text: user.username + " (" + user.email + ")",
  //           value: user.id,
  //           key: user.id,
  //         };
  //       });

  //       this.props.toggleLoading({
  //         isLoading: false,
  //       });

  //       this.setState({
  //         users: response.data,
  //         userListFormatted: userListFormatted,
  //         isLoaded: true,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again.",
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     });
  // }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let userId = this.state.userId;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.loadUserAnswers();
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields!",
        successMessage: false,
        showNotification: true,
      });
    }
  }
  loadUserAnswers() {
    this.setState({
      answersLoading: true,
      answers: [],
      answersLoaded: false,
      answersLoadError: "",
    });

    API.get("user-answer-lifestyle-question/", {
      params: {
        user_id: this.state.userId,
      },
    })
      .then((response) => {
        let activeList = filterList(response.data, "active");
        for (let item of activeList) {
          if (!item.answer_question.length) item.answer = "-";
          else {
            if (item.survey_answer_type.id === 1) {
              //single select
              item.answer = item.answer_question[0].option[0].is_active
                ? item.answer_question[0].option[0].option
                : item.answer_question[0].option[0].option + " (Archived)";
            } else if (item.survey_answer_type.id === 2) {
              //multi select
              let answerList = "";

              for (let index in item.answer_question[0].option) {
                answerList =
                  answerList +
                  (item.answer_question[0].option[index].is_active
                    ? item.answer_question[0].option[index].option
                    : item.answer_question[0].option[index].option +
                      " (Archived)") +
                  (index < item.answer_question[0].option.length - 1
                    ? ", "
                    : "");
              }
              item.answer = answerList;
            } else if (item.survey_answer_type.id === 3) {
              //plain text
              item.answer = item.answer_question[0].plain_answer;
            } else item.answer = "-";
          }
        }

        this.setState({
          answers: activeList,
          isLoaded: true,
          answersLoading: false,
          answersLoaded: true,
        });
        this.submitted = false;
      })
      .catch((error) => {
        console.log("Error on fetching data", error);
        this.setState({
          answersLoading: false,
          answersLoadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  searchClients = (searchTerm) => {
    if (!searchTerm || searchTerm.trim() === "")
      return;

    this.props.toggleLoading({ isLoading: true, });

    API.get(`list-active-user/?search=${searchTerm ? encodeURIComponent(searchTerm.trim()) : searchTerm.trim()}`)
      .then((response) => {
        const users = response.data ? response.data.results || [] : []; 
        const userListFormatted = users
        .filter((user) => user.email && user.email.includes(searchTerm.trim()))
        .map((user) => {
          return {
            key: user.id,
            value: user.id,
            text: user.email + " (" + user.username + ")",
          };
        });

        this.setState({
          users,
          userListFormatted: userListFormatted,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log("Error on fetching clients", error);
        this.setState({ loadError: "Some error has occured. Please try again." });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      })
      .finally(() => this.props.toggleLoading({ isLoading: false }));
  }

  render() {
    let answerList, form;
    const answers = this.state.answers;
    const isLoaded = this.state.isLoaded;
    const answersLoading = this.state.answersLoading;
    const answersLoaded = this.state.answersLoaded;
    const answersLoadError = this.state.answersLoadError;
    const loadError = this.state.loadError;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      form = (
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group input-section input-section-long">
              <Dropdown
                value={parseInt(this.state.userId)}
                className={
                  "form-control " + (validation.userId.isInvalid && "has-error")
                }
                placeholder="Select client"
                onChange={(event, value) => {
                  this.setState({ userId: value.value });
                }}
                search
                selection
                defaultSelectedLabel={this.state.userId}
                options={this.state.userListFormatted}
                disabled={this.state.disableDropdown ? true : false}
              />
              <span className="help-block">{validation.userId.message}</span>
            </div>
            <div className="form-group">
              <button type="submit" className="btn button button-green">
                View Answers
              </button>
            </div>
          </div>
        </form>
      );
    } else {
      if (loadError) form = <p>{loadError}</p>;
      else form = null;
    }

    if (answersLoaded || answersLoadError) {
      answerList = (
        <Card className="answer-list">
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={answers}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={true}
            title=""
            noDataComponent={
              answersLoadError ? (
                <AlertBox message={answersLoadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      );
    } else if (answersLoading) {
      answerList = (
        <CircularProgress size={30} className="valeo-loader"></CircularProgress>
      );
    } else {
      answerList = <p>Select a client to view the results.</p>;
    }
    
    if (this.state.accessDenied) {
      return <AccessDenied />
    }
    
    return (
      <div className="answers">
        <h6 className="mb-3">Search Client *</h6>
        <div className="row">
          <div className="form-group col-5">
            <SearchBar
              onSubmit={this.searchClients}
              placeholder="Search by Email" />
          </div>
        </div>
        {/* <h5 className="mb-4">Lifestyle Profile Results</h5> */}
        <h6 className="mb-3">Select Client *</h6>
        {form}
        <h6 className="mb-3 mt-4">Survey Answers</h6>
        {answerList}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LifestyleSurveyResults);
