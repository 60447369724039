import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";
import { ArrowDownward } from "@material-ui/icons";
import _ from "lodash";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import Questions from "./Questions";
import { Page } from "utils/constant";
import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL_QUESTIONNAIRES = "questionnaires/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class Questionnaires extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      questionnaires: [],
      rankOptions: [],
      questionnaireOptions: [],
      questionnaire: {},
      selectedQuestionnaire: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isQuestionnaireViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Questionnaire (English)",
        selector: "displayNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Questionnaire (Arabic)",
        selector: "displayNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (questionnaire) => (
          <p style={{ textAlign: "right" }}>
            {questionnaire.displayNameArabic}
          </p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (questionnaire) => (
          <Status isActive={questionnaire.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (questionnaire) => this.renderViewButton(questionnaire.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchQuestionnaires();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.QUESTIONNAIRES
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchQuestionnaires = async () => {
    this.showLoading();
    try {
      const { data: questionnaires = [] } = await PhoenixAPI.get(
        BASE_URL_QUESTIONNAIRES
      );
      const selectedQuestionnaire =
        this.state.selectedQuestionnaire &&
        questionnaires.find(
          (it) => it.id === this.state.selectedQuestionnaire.id
        );
      this.setState({ questionnaires, selectedQuestionnaire }, () =>
        this.createQuestionnaireOptions()
      );
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching questionnaires: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const questionnaire = { ...this.state.questionnaire };
    questionnaire[input.name] = input.value;
    this.setState({ questionnaire });
  };

  handleAddNewQuestionnaire = () => {
    this.setState({ isNewQuestionnaire: true }, () =>
      this.showQuestionnaireViewModal()
    );
  };

  handleViewQuestionnaire = (questionnaireId) => {
    const questionnaire = this.findQuestionnaire(questionnaireId);
    if (!questionnaire) {
      console.log(`Questionnaire with id ${questionnaireId} is not found!`);
      return;
    }

    this.setState({ questionnaire, isNewQuestionnaire: false }, () =>
      this.showQuestionnaireViewModal()
    );
  };

  findQuestionnaire = (questionnaireId) => {
    const { questionnaires = [] } = this.state;
    return questionnaires.find((it) => it.id === questionnaireId);
  };

  showQuestionnaireViewModal = () => {
    const { isNewQuestionnaire } = this.state;
    const rankOptions = this.createRankOptionsForQuestionnaire() || [];

    if (isNewQuestionnaire) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const questionnaire = {
        status: "Inactive",
        rank,
      };
      this.setState({
        questionnaire,
        rankOptions,
        isQuestionnaireViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isQuestionnaireViewModalVisible: true });
    }
  };

  hideQuestionnaireViewModal = () => {
    this.setState({ isQuestionnaireViewModalVisible: false }, () =>
      this.resetForm()
    );
  };

  handleSaveQuestionnaire = async () => {
    const { questionnaire } = this.state;

    const errors = this.validate(questionnaire);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const response = await this.saveQuestionnaire(questionnaire);
      console.log("Response: ", response);
      this.hideQuestionnaireViewModal();
      this.showSuccessNotification(
        questionnaire.id
          ? "Questionnaire Updated Successfully."
          : "Questionnaire Created Successfully."
      );
      this.fetchQuestionnaires();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = (questionnaire) => {
    const errors = {};

    const {
      status,
      internalName,
      displayNameEnglish,
      displayNameArabic,
      rank,
    } = questionnaire;

    if (status === null) errors.status = "Status cannot be blank";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!displayNameEnglish || displayNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (displayNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!displayNameArabic || displayNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (displayNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveQuestionnaire = async (questionnaire) => {
    const payload = { ...questionnaire };
    delete payload.questions;
    return questionnaire.id
      ? await PhoenixAPI.patch(
          `${BASE_URL_QUESTIONNAIRES}${questionnaire.id}`,
          payload
        )
      : await PhoenixAPI.post(BASE_URL_QUESTIONNAIRES, payload);
  };

  createQuestionnaireOptions = () => {
    const { questionnaires = [] } = this.state;
    const [activeQuestionnaires, inactiveQuestionnaires] = _.partition(
      questionnaires,
      (it) => it.status === "Active"
    );
    const questionnaireOptions = [
      {
        label: "Active",
        options: activeQuestionnaires.map(this.createQuestionnaireOption),
      },
      {
        label: "Inactive",
        options: inactiveQuestionnaires.map(this.createQuestionnaireOption),
      },
    ];
    this.setState({ questionnaireOptions });
  };

  createQuestionnaireOption = (questionnaire) => {
    return {
      key: questionnaire.id,
      value: questionnaire.id,
      label: questionnaire.internalName,
    };
  };

  createRankOptionsForQuestionnaire = () => {
    const { questionnaires = [], isNewQuestionnaire } = this.state;
    const ranks = questionnaires.filter((it) => it.rank).map((it) => it.rank);
    return this.createRankOptions(ranks, isNewQuestionnaire);
  };

  createRankOptions = (ranks = [], isNew) => {
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNew ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      questionnaire: {},
      selectedQuestionnaire: null,
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (questionnaireId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewQuestionnaire(questionnaireId);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewQuestionnaire ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  getSearchedQuestionnaires = (questionnaires) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return questionnaires;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return questionnaires.filter((questionnaire) => {
      const { id, internalName, displayNameEnglish, displayNameArabic, rank } =
        questionnaire;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (displayNameEnglish &&
          displayNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (displayNameArabic &&
          displayNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      questionnaires,
      questionnaire,
      selectedQuestionnaire,
      isNewQuestionnaire,
      isQuestionnaireViewModalVisible,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const searchedQuestionnaires =
      this.getSearchedQuestionnaires(questionnaires) ?? [];
    
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={questionnaire?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewQuestionnaire && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={questionnaire?.status}
                onChange={(event, value) => {
                  this.setState({
                    questionnaire: {
                      ...questionnaire,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestionnaire && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="displayNameEnglish">External Name (English)*</label>
            <div className="input-group">
              <input
                value={questionnaire?.displayNameEnglish || ""}
                onChange={this.handleChange}
                id="displayNameEnglish"
                name="displayNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewQuestionnaire && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="displayNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={questionnaire?.displayNameArabic || ""}
                onChange={this.handleChange}
                id="displayNameArabic"
                name="displayNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewQuestionnaire && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={questionnaire?.rank}
                onChange={(event, value) => {
                  this.setState({
                    questionnaire: { ...questionnaire, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestionnaire && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
      </div>
    );

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewQuestionnaire}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Questionnaires</p>
          <DataTable
            data={searchedQuestionnaires || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + searchedQuestionnaires.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <div className="row">
          <div className="form-group col-4">
            <Select
              key={`my_unique_select_key__${
                selectedQuestionnaire && selectedQuestionnaire.id
              }`}
              value={
                selectedQuestionnaire &&
                this.createQuestionnaireOption(selectedQuestionnaire)
              }
              onChange={(event) => {
                const selectedQuestionnaire = event
                  ? this.findQuestionnaire(event.value)
                  : null;
                const questions = selectedQuestionnaire?.questions ?? [];
                this.setState({ selectedQuestionnaire, questions });
              }}
              options={this.state.questionnaireOptions || []}
              style={selectStyles}
              placeholder="Questionnaire"
              isClearable={true}
              searchable
              lazyLoad
            />
          </div>
        </div>
        <Questions
          questionnaire={selectedQuestionnaire}
          onQuestionSaved={() => this.fetchQuestionnaires()}
          isEditable={isEditable}
        />
        <Modal
          size="lg"
          show={isQuestionnaireViewModalVisible}
          onHide={this.hideQuestionnaireViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Questionnaire: </span>
                {questionnaire?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveQuestionnaire}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_questionnaires">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaires);
