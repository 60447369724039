import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

function SelectAgent(props) {
  const { agents, selectedAgentId, onAgentSelect, isDisabled } = props;

  const findAgent = (agentId) => agents.find((agent) => agent.id === agentId);

  const getAgentFullName = (agent) => {
    const { firstName, lastName, userName } = agent;
    return firstName && lastName ? `${firstName} ${lastName}` : userName;
  };

  const createOption = (agent) => {
    if (!agent) return;
    return {
      key: agent.id,
      value: agent.id,
      label: getAgentFullName(agent),
    };
  };

  agents.sort(
    (firstAgent, secondAgent) =>
      compareStrings(firstAgent.firstName, secondAgent.firstName, true) ||
      compareStrings(firstAgent.lastName, secondAgent.lastName, true)
  );

  const agentOptions = agents.map(createOption);
  const selectedAgent = findAgent(selectedAgentId);
  if (selectedAgent)
    console.log(
      `SelectAgent:: agent found with agentId: ${selectedAgentId} agent: ${selectedAgent.name}`
    );
  else
    console.log(
      `SelectAgent:: agent not found with agentId: ${selectedAgentId}`
    );
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedAgentId}`}
      value={createOption(selectedAgent)}
      onChange={(option) => onAgentSelect(option.value)}
      options={agentOptions}
      styles={selectStyles}
      placeholder="Select Agent"
      isDisabled={isDisabled}
    />
  );
}

SelectAgent.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAgentId: PropTypes.number,
  onAgentSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectAgent.defaultProps = {
  agents: [],
  selectedAgentId: "",
  onAgentSelect: (f) => f,
  isDisabled: false,
};

export default SelectAgent;
