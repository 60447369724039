import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_LONGEVITY_CATEGORIES = "longevity-category/";
const BASE_URL_PARAMETER_WEIGHTAGES="/get-longevity-parameter-weightage/longevity-category-id/";
const BASE_URL_PARAMETER_DETAILS="/longevity-parameter/longevity-category-id/";
const BASE_URL_BIOMARKER_NAME="/biomarker-id-name";
const BASE_URL_BIOMARKER_MINIPACKAGE="/api/v1/mini-packages/biomarker-id";
const BASE_URL_BIOMARKER_PARAMETER="/longevity-parameter";
const BASE_URL_PARAMETER_WEIGHTS="/update-longevity-parameter-weightage";
const  BASE_URL_LONGEVITY_CATEGORIES_NEW="longevity-category-page";


export const fetchLongevityCategories = async () => {
    try {
      const { data: categories = [] } = await PhoenixAPI.get(
        BASE_URL_LONGEVITY_CATEGORIES
      );
      return categories;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  };

  export const fetchNewLongevityCategories=async()=>{
    try {
      const { data: categories = [] } = await PhoenixAPI.get(
        BASE_URL_LONGEVITY_CATEGORIES_NEW
      );
      return categories;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  }

  export const fetchParameterWeightages = async (categoryId) => {
    try {
      const { data: weightages = [] } = await PhoenixAPI.get(
        BASE_URL_PARAMETER_WEIGHTAGES+categoryId
      );
      return weightages;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  };

  export const saveCategory= async(category)=>{
    try{
     const payload= {...category};
     const apiOptions = {
      method: "post",
      url: BASE_URL_LONGEVITY_CATEGORIES,
      data:payload,
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
    }
    catch(error){
      console.log("Error on creating category", error);
      throw error;
    }
  }

  export const updateCategory=async(category)=>{
    try{
     const payload = {...category};
     const apiOptions = {
      method: "patch",
      url: `${BASE_URL_LONGEVITY_CATEGORIES}${category.id}`,
      data: payload,
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
    }
    catch(error){
      console.log("Error on updating category", error);
      throw error;
    }
  }


  export const fetchLongevityParameterDetails = async (categoryId) => {
    try {
      const { data: parameters = [] } = await PhoenixAPI.get(
        BASE_URL_PARAMETER_DETAILS+categoryId
      );
      return parameters;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  };

  export const fetchAllParameters = async () => {
    try {
      const { data: parameters = [] } = await PhoenixAPI.get(
        BASE_URL_BIOMARKER_PARAMETER
      );
      return parameters;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  };

  export const fetchBiomarkerName = async (categoryId) => {
    try {
      const { data:biomarkers=[]} = await PhoenixAPI.get(
        BASE_URL_BIOMARKER_NAME
      );
      return biomarkers;
    } catch (error) {
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  };

  export const fetchMiniPackageBiomarkers=async(biomarkerId,countryId)=>{
    try{
      const {data:miniPackageBiomarkers=[]}=await PhoenixAPI.get(
        `${BASE_URL_BIOMARKER_MINIPACKAGE}/${biomarkerId}/country/${countryId}`
      );
      return miniPackageBiomarkers;
    }
    catch(error){
      console.log("Error on fetching Longevity Categories", error);
      throw error;
    }
  }

export const saveParameter=async(parameterDetail)=>{

  try{
    const payload={...parameterDetail};
    delete payload["image"];
    delete payload["image_src"];
    const formData = new FormData();
    formData.append("parameter", JSON.stringify(payload));
    formData.append("icon", parameterDetail.image);
    const apiOptions = {
      method: "post",
      url: BASE_URL_BIOMARKER_PARAMETER,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
  }
  catch(error){
    console.log("Error on creating parameter", error);
    throw error;
  }
}

export const updateParameter=async(parameter)=>{
  try {
    const payload = { ...parameter };
    delete payload["image_src"];
    if (typeof parameter.image !== "string") delete payload["icon"];

    const formData = new FormData();
    formData.append("parameter", JSON.stringify(payload));
    if (typeof parameter.image !== "string")
      formData.append("icon", parameter.image);

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_BIOMARKER_PARAMETER}/${parameter.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on updating parameter biomarker", error);
    throw error;
  }
}

export const saveParameterWeightage=async(parameterWeightage)=>{
  try{
    const payload = parameterWeightage;
    const apiOptions = {
     method: "patch",
     url: BASE_URL_PARAMETER_WEIGHTS,
     data: payload,
   };
   const response = await PhoenixAPI.request(apiOptions);
   return response;
   }
   catch(error){
     console.log("Error on updating category", error);
     throw error;
   }
}

  const LongevityService = {
    fetchLongevityCategories,
    fetchParameterWeightages,
    saveCategory,
    updateCategory,
    fetchLongevityParameterDetails,
    fetchBiomarkerName,
    saveParameter,
    updateParameter,
    saveParameterWeightage,
    fetchAllParameters
  };
  
  export default LongevityService;