import React from "react";
import PropTypes from "prop-types";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";

QuestionType.propTypes = {
  questionType: PropTypes.number.isRequired,
};

const renderSingleSelect = () => (
  <span className="badge badge-blue pt-1" style={{width:"94px"}}>
    <RadioButtonCheckedOutlinedIcon></RadioButtonCheckedOutlinedIcon>
    Single Select
  </span>
);

const renderPlainText = () => (
  <span className="badge badge-secondary pt-1" style={{width:"94px"}}>
    <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
    Plain Text
  </span>
);

const renderMultiSelect = () => (
  <span className="badge badge-skyblue pt-1" style={{width:"94px"}}>
    <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
    Multi Select
  </span>
);

export default function QuestionType({ questionType }) {
  switch (questionType) {
    case 1:
      return renderSingleSelect();
    case 2:
      return renderMultiSelect();
    case 3:
      return renderPlainText();
    case 4:
      return (
        <div>
          {renderSingleSelect()}
          <div className="mt-2"></div>
          {renderPlainText()}
        </div>
      );
    case 5:
      return (
        <div>
          {renderMultiSelect()}
          <div className="mt-2"></div>
          {renderPlainText()}
        </div>
      );
    default: {
      console.log("Unknown question type: ", questionType);
      return "";
    }
  }
}
