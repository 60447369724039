import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import SpellCheckIcon from "@material-ui/icons/Spellcheck";
import AlertBox from "components/AlertBox/AlertBox";
import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import API from "utils/API";
import FormValidator, { isEmpty } from "utils/FormValidator";
import { filterList } from "utils/List";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class SurveyOptionsForm extends Component {
  constructor(props) {
    super(props);

    const isEditable = props.isEditable ?? true;
    this.state = {
      options: null,
      isLoaded: false,
      loadError: "",
      optionEn: "",
      optionAr: "",
      editId: null,
      questionId: this.props.questionId,
      surveyType: this.props.surveyType,
      formHeading: "Create Option",
      questionName: "Survey Question",
      showModal: false,
      showConfirm: false,
      showConfirmDelete: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      statusOptions: [
        {
          text: "Active",
          value: true,
          key: "active",
        },
        {
          text: "Inactive",
          value: false,
          key: "inactive",
        },
      ],
      errors: {},
      editables: {},
      is_active: true,
      surveyAnswerType: null,
    };

    this.columns = [
      {
        name: "",
        cell: (row) => (
          // <a className="drag-handle" href="#">Drag</a>
          <Drag className="drag-handle"></Drag>
        ),
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Option (English)",
        selector: "option",
        sortable: true,
        wrap: true,
      },
      {
        name: "Option (Arabic)",
        selector: "option_ar",
        sortable: true,
        wrap: true,
        right: true,
        format: (option) => (
          <p style={{ textAlign: "right" }}>{option.option_ar}</p>
        ),
      },
      {
        name: "Rank",
        selector: "position_value",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Enable Free Text",
        selector: "free_text",
        sortable: true,
        format: (row) => (row.free_text ? "Yes" : "No"),
        width: "110px",
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        cell: (row) => this.renderStatus(row.is_active),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (option) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={!isEditable}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item onClick={() => this.editOption(option.id)}>
                <EditIcon className="icon-small" />
                Edit
              </ActionBar.Item>
              <ActionBar.Item
                onClick={() => this.handleEnableFreeText(option.id)}
              >
                <SpellCheckIcon />
                Enable Free Text
              </ActionBar.Item>
              <ActionBar.Item
                onClick={() =>
                  this.setState({
                    deleteOptionId: option.id,
                    showConfirmDelete: true,
                  })
                }
              >
                <DeleteIcon className="icon-small" style={{ fill: "red" }} />
                <span style={{ color: "red" }}>Delete</span>
              </ActionBar.Item>
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.columns2 = [
      {
        name: "",
        cell: (row) => (
          // <a className="drag-handle" href="#">Drag</a>
          <Drag className="drag-handle"></Drag>
        ),
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Option (English)",
        selector: "option",
        sortable: true,
        wrap: true,
      },
      {
        name: "Option (Arabic)",
        selector: "option_ar",
        sortable: true,
        wrap: true,
        right: true,
        format: (option) => (
          <p style={{ textAlign: "right" }}>{option.option_ar}</p>
        ),
      },
      {
        name: "Rank",
        selector: "position_value",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        cell: (row) => this.renderStatus(row.is_active),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (option) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={!isEditable}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item onClick={() => this.editOption(option.id)}>
                <EditIcon className="icon-small" />
                Edit
              </ActionBar.Item>
              <ActionBar.Item
                onClick={() =>
                  this.setState({
                    deleteOptionId: option.id,
                    showConfirmDelete: true,
                  })
                }
              >
                <DeleteIcon className="icon-small" style={{ fill: "red" }} />
                <span style={{ color: "red" }}>Delete</span>
              </ActionBar.Item>
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.submitted = false;
    this.finalList = [];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.updateRank = this.updateRank.bind(this);
    this.setFinalList = this.setFinalList.bind(this);

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const results = [...that.finalList];
        const item = results.splice(fromIndex, 1)[0];
        results.splice(toIndex, 0, item);
        if (that.state.searchApplied)
          that.setState({
            results: results,
          });
        else {
          if (that.state.showItems === "Active")
            that.setState({
              activeList: results,
            });
          else if (that.state.showItems === "Archived")
            that.setState({
              archivedList: results,
            });
          else
            that.setState({
              options: results,
            });
        }
        that.updateRank();
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  loadData() {
    this.loadOptions();
  }

  loadOptions() {
    let url;
    const { searchApplied, searchTerm, questionId, surveyType } = this.state;

    if (surveyType === "M")
      url = "medlife-medical-profile-questions/" + questionId + "/";
    else if (surveyType === "L")
      url = "medlife-lifestyle-profile-questions/" + questionId + "/";
    else return;

    this.showLoading();

    API.get(url)
      .then((response) => {
        let newList = response.data.option_question;
        newList.sort((a, b) => (a.position_value > b.position_value ? 1 : -1));

        const activeList = filterList(newList, "active");
        activeList.sort((a, b) => (a.position_value > b.position_value ? 1 : -1));

        const archivedList = filterList(newList, "archived");
        archivedList.sort((a, b) => (a.position_value > b.position_value ? 1 : -1));

        this.setState({
          options: newList,
          activeList,
          archivedList,
          isLoaded: true,
          questionName: response.data.question,
          surveyAnswerType: response.data.survey_answer_type,
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.hideLoading();
      })
      .catch((error) => {
        console.log("Error on fetching options.", error);
        this.hideLoading();
        this.showErrorNotification("Some error has occured. Please try again.");
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
      });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("/medlife-option/" + item.id + "/", {
      is_active: !item.is_active,
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.clearForm();
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  editOption(id) {
    this.clearForm();
    const { options = [] } = this.state;
    const option = options.find((option) => option.id === id);
    if (!option) return;

    this.setState(
      {
        formHeading: "Edit Option",
        optionEn: option.option,
        optionAr: option.option_ar,
        is_active: option.is_active,
        editId: option.id,
      },
      () => this.showModal()
    );
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  hideConfirmDelete = () =>
    this.setState({ deleteOptionId: null, showConfirmDelete: false });

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Option";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Option successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Option";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Option successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Option",
      optionEn: "",
      optionAr: "",
      editId: null,
      errors: {},
      editables: {},
      is_active: true,
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  validate = () => {
    const errors = {};
    const { optionEn, optionAr, is_active } = this.state;
    if (!optionEn || optionEn.trim() === "")
      errors.optionEn = "Option (English) is a required field!";
    else if (optionEn.length > 100)
      errors.optionEn =
        "Option (English) can not take more than 100 characters!";

    if (!optionAr || optionAr.trim() === "")
      errors.optionAr = "Option (Arabic) is a required field!";
    else if (optionAr.length > 100)
      errors.optionAr =
        "Option (Arabic) can not take more than 100 characters!";

    if (is_active === null || is_active === undefined)
      errors.is_active = "Status is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSubmit(event) {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    this.submitted = true;

    if (this.state.editId) this.updateOption();
    else this.createOption();
  }

  handleDelete = (id) => this.deleteOption(id);

  handleEnableFreeText = (id) => {
    this.showLoading();
    API.patch("medlife-option/" + id + "/", { free_text: true })
      .then((response) => {
        this.hideLoading();
        this.showSuccessNotification("Option successfully updated.");
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating option: ", error);
        this.hideLoading();
        this.showErrorNotification(
          "Some error has occured. Please try again. " + error.message
        );
      });
  };

  createOption = () => {
    const { questionId, optionEn, optionAr, is_active } = this.state;
    this.showLoading();
    API.post("medlife-option/", {
      question: questionId,
      option: optionEn,
      option_ar: optionAr,
      is_active,
    })
      .then((response) => {
        this.hideLoading();
        this.clearForm();
        this.showSuccessNotification("Option successfully created.");
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on creating option: ", error);
        this.hideLoading();
        this.showErrorNotification(
          "Some error has occured. Please try again. " + error.message
        );
      });
  };

  updateOption = () => {
    const { optionEn, optionAr, is_active, questionId, editId, position_value } =
      this.state;
    this.showLoading();
    API.patch("medlife-option/" + editId + "/", {
      question: questionId,
      option: optionEn,
      option_ar: optionAr,
      is_active,
      position_value,
    })
      .then((response) => {
        this.hideLoading();
        this.clearForm();
        this.showSuccessNotification("Option successfully updated.");
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating option: ", error);
        this.hideLoading();
        this.showErrorNotification(
          "Some error has occured. Please try again. " + error.message
        );
      });
  };

  deleteOption = (id) => {
    this.showLoading();
    API.delete("medlife-option/" + id + "/")
      .then((response) => {
        this.hideLoading();
        this.showSuccessNotification("Option successfully deleted.");
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on deleting option: ", error);
        this.hideLoading();
        this.showErrorNotification(
          "Some error has occured. Please try again. " + error.message
        );
      });
  };

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  updateRank() {
    const {
      searchApplied,
      results,
      activeList,
      archivedList,
      options,
      showItems,
    } = this.state;
    var data = [];
    if (searchApplied) {
      data = results;
    } else {
      if (showItems === "Active") data = activeList;
      else if (showItems === "Archived") data = archivedList;
      else data = options;
    }
    var rank = {};
    data.map((val, index) => {
      rank[val.id] = index + 1;
    });
    API.post("set-medlife-options-rank/", {
      question: this.state.questionId,
      ranks: rank,
    })
      .then((response) => {
        this.showSuccessNotification("Slider Updated successfully.");
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating rank", error);
        console.log(error);
        this.setState({
          error: "Some error occured. Please try again",
          loading: false,
        });
      });
  }

  setFinalList() {
    const {
      searchApplied,
      showItems,
      activeList,
      archivedList,
      options,
      results,
    } = this.state;
    if (searchApplied) this.finalList = results;
    else {
      if (showItems === "Active") this.finalList = activeList;
      else if (showItems === "Archived") this.finalList = archivedList;
      else this.finalList = options;
    }
    this.finalList = options;
  }

  renderStatus = (isActive) => {
    if (isActive)
      return (
        <span className="badge badge-success pt-1">
          <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
          Active
        </span>
      );
    else
      return (
        <span className="badge badge-warning pt-1">
          <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
          Inactive
        </span>
      );
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && this.state.editId ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      options,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      editId,
      errors,
      editables,
      surveyAnswerType = {},
      isEditable,
    } = this.state;

    if (isLoaded) this.setFinalList();

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="optionEn">Option (English)*</label>
            <div className="input-group">
              <input
                type="text"
                value={this.state.optionEn}
                onChange={this.handleChange}
                id="optionEn"
                name="optionEn"
                placeholder="Option in English"
                disabled={editId && !editables.optionEn}
                className={
                  "form-control py-2" + (errors.optionEn ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("optionEn")}
            </div>
            <span className="help-block">{errors.optionEn}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="optionAr">Option (Arabic)*</label>
            <div className="input-group">
              <input
                type="text"
                value={this.state.optionAr}
                onChange={this.handleChange}
                id="optionAr"
                name="optionAr"
                placeholder="Option in Arabic"
                disabled={editId && !editables.optionAr}
                className={
                  "form-control input-arabic py-2" +
                  (errors.optionAr ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("optionAr")}
            </div>
            <span className="help-block">{errors.optionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="is_active">Status*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_active}
                onChange={(event, value) => {
                  this.setState({ is_active: value.value });
                }}
                id="is_active"
                name="is_active"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={editId && !editables.is_active}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          {/* <div className="form-group col"></div> */}
        </div>
        <div className="row mt-2">
          {editId &&
            <div className="form-group col-2 mr-4">
              <button
                onClick={this.clearForm}
                disabled={!editId}
                className="btn btn-lg btn-outline-secondary px-5"
                style={{ color: "black" }}
              >
                Cancel
              </button>
            </div>
          }
          <div className="form-group col-2">
            <button
              onClick={this.handleSubmit}
              disabled={!isEditable}
              className="btn btn-lg btn-outline-secondary px-5"
              style={{ backgroundColor: "#8FD14F", color: "black" }}
            >
              {editId ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4">
        {form}
        <div className="row">
          <div className="form-group col mt-4">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={
                    (surveyAnswerType && (surveyAnswerType.answer_type === "Single Select" || surveyAnswerType.answer_type === "Multi Select")) 
                      ? this.columns2 : 
                      this.columns
                  }
                  data={this.finalList}
                  pagination
                  paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                  paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                  responsive
                  defaultSortField="is_active"
                  defaultSortAsc={false}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                  noHeader={searchApplied ? false : true}
                  title={
                    searchApplied ? (
                      <p className="table-header">
                        {"Found " +
                          resultCount +
                          " results in '" +
                          showItems +
                          "'"}
                      </p>
                    ) : (
                      ""
                    )
                  }
                  noDataComponent={
                    loadError ? (
                      <AlertBox message={loadError} error={true}></AlertBox>
                    ) : (
                      <AlertBox message="There's nothing here."></AlertBox>
                    )
                  }
                />
              </ReactDragListView>
            </Card>
          </div>
        </div>
        <Modal
          show={this.state.showConfirmDelete}
          onHide={this.hideConfirmDelete}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, you want to delete this option?</Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                this.hideConfirmDelete();
                this.handleDelete(this.state.deleteOptionId);
              }}
              className="btn btn-danger button"
            >
              Yes
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirmDelete}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOptionsForm);
