export const CharLimit = Object.freeze({
    FIVE_THOUSAND_LIMIT:5000,
    TWO_THOUSAND_LIMIT:2000,
    THOUSAND_LIMIT:1000,
     FIVE_HUNDERED_LIMIT:500,
     TWO_FIFTY_FIVE_LIMIT:255,
     TWO_HUNDRED_LIMIT:200,
     HUNDRED_LIMIT:100,
     ONE_HUNDRED_FIFTY_LIMIT:150,
  
     FOURTY_FIVE_LIMIT:45,

  });