import React, { useState } from "react";
import { formatDate } from "utils/commons";

function DraftOrderTransactionInfo(props) {
  const { transactionDetails } = props;
  const [isTransactionInfoVisible, setTransactionInfoVisible] = useState(true);
  const {
    transactionDate,
    serviceReference,
    valeoRequestId,
    merchantRequestId,
    paymentDescription,
    paymentStatusDescription,
    paymentIP,
    mamopayPaymentUrl,
  } = transactionDetails ?? {};
  
  const transactionDetailsTable = (
    <table className="table table-responsive table-borderless">
      <tbody>
        <tr>
          <td>Transaction Date</td>
          <td>{transactionDate ? formatDate(transactionDate) : ""}</td>
        </tr>
        <tr>
          <td>Service Reference</td>
          <td>{serviceReference ?? ""}</td>
        </tr>
        <tr>
          <td>Mamo Payment URL</td>
          <td>{mamopayPaymentUrl ?? ""}</td>
        </tr>
        <tr>
          <td>Valeo Request ID</td>
          <td>{valeoRequestId ?? ""}</td>
        </tr>
        <tr>
          <td>Merchant Request ID</td>
          <td>{merchantRequestId ?? ""}</td>
        </tr>
        <tr>
          <td>Payment Description</td>
          <td>{paymentDescription ?? ""}</td>
        </tr>
        <tr>
          <td>Payment Status Description</td>
          <td>{paymentStatusDescription ?? ""}</td>
        </tr>
        <tr>
          <td>Payment IP</td>
          <td>{paymentIP ?? ""}</td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className="container px-0">
      <div className="row">
        <div className="col">
          <p className="h6">Transaction Information</p>
        </div>
        <div className="col text-right">
          <i
            onClick={() => setTransactionInfoVisible((prevValue) => !prevValue)}
            className={`caret square ${
              isTransactionInfoVisible ? "up" : "down"
            } icon big`}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>
      {isTransactionInfoVisible && (
        <div className="row">
          <div className="col">
            {transactionDetails
              ? transactionDetailsTable
              : "Transaction information not available!"}
          </div>
        </div>
      )}
    </div>
  );
}

export default DraftOrderTransactionInfo;
