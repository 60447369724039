import React, { Component } from "react";
import PhoenixAPI from "utils/PhoenixAPI";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { formatDateTime } from "utils/commons";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const BASE_URL_AUDIT_TRAILS = "audit-trails?orderId=";
const BASE_URL_ORDER_LOGS="order-logs"
class OrderStatusLogsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatusLogs: [],
    };

    this.columns = [
      {
        name: "Admin UserName",
        selector: "username",
        sortable: true,
        sortField: "username",
        wrap: true,
        cell:(order)=>this.renderUserName(order)
      },
      {
        name: "Updation",
        selector: "activity_type",
        sortable: true,
        sortField: "activity_type",
        wrap: true,
      },
      {
        name: "Modified At",
        selector: "last_modified",
        sortable: true,
        sortField: "last_modified",
        wrap: true,
        format: (row) => (row.last_modified ? formatDateTime(row.last_modified) : "-"),
      },
      // {
      //   name: "Modified By",
      //   selector: "modified_by",
      //   sortable: true,
      //   sortField: "modified_by",
      //   wrap: true,
      // },
      // {
      //   name: "Previous Status",
      //   selector: "new_state",
      //   sortable: true,
      //   sortField: "new_state",
      //   wrap: true,
      // },
      {
        name: "Order Status",
        selector: "new_state",
        sortable: true,
        sortField: "new_state",
        wrap: true,
      },
    ];
  }
  componentDidMount() {
    if (this.props.orderId) {
      this.fetchOrderStatusLogs();
    }
  }

  fetchOrderStatusLogs = async () => {
    try {
      const response = await PhoenixAPI.get(
        `${BASE_URL_ORDER_LOGS}/${this.props.orderId}`
      );
      let orderStatusLogs = response && response.data ? response.data : [];

      this.setState({ orderStatusLogs });
    } catch (error) {
      console.log(
        "Error in fetching audit trials from url",
        BASE_URL_AUDIT_TRAILS
      );
      console.log("Error in fetching audit trials:", error);
    }
  };

  renderUserName=(order)=>{
  const {username,modified_by}= order;
    return `${username}`
  }
  render() {
    return (
      <Card body>
        <DataTable
          data={this?.state?.orderStatusLogs ?? []}
          columns={this.columns}
          defaultSortField="last_modified"
          defaultSortAsc={false}
          sortIcon={<ArrowDownward></ArrowDownward>}
          paginationPerPage={DEFAULT_ROWS_PER_PAGE}
          paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          highlightOnHover
          pagination
          responsive
          title={<p>Order Status Logs</p>}
          noDataComponent={
            this.state.loadError ? (
              <AlertBox message={this.state.loadError} error={true}></AlertBox>
            ) : (
              <AlertBox message="There's nothing here."></AlertBox>
            )
          }
        />
      </Card>
    );
  }
}

export default OrderStatusLogsTable;
