import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import CountryDropdown from "components/Common/CountryDropdown";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";

import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Link } from "react-router-dom";
import Select from "react-select";
import Status from "components/Common/Status";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class AdvanceLandingPageHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      landingPages: [],
      landingPage: {},
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "pageName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",
        center: true,
        cell: (landingPage) => this.renderEditButton(landingPage.id),
      },
      {
        name: "Manage",
        center: true,
        cell: (landingPage) => this.renderManageButton(landingPage.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([this.fetchAclUser(), this.fetchCountries()]);
    this.fetchLandingPages();
  }
  //    componentDidUpdate(prevProps,prevState){
  //     const { selectedCountry } = this.state;
  //     if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
  //         this.fetchLandingPages()
  //   }
  // }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = "Advance Landing Pages";
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchLandingPages = async () => {
    this.showLoading();
    try {
      const { data: landingPages = [] } = await PhoenixAPI.get(
        "/advance-landing-page"
      );
      this.setState({ landingPages });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Landing Pages:",
        error.message
      );
      this.setState({ loadError: "Some Error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) => {
    this.setState({ selectedCountry });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const landingPage = { ...this.state.landingPage };
    landingPage[input.name] = input.value;
    this.setState({ landingPage });
  };

  resetForm = () => {
    this.setState({
      landingPage: {},
      errors: {},
      editables: {},
    });
  };

  renderEditButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewLandingPage(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderManageButton = (id) => {
    const { selectedCountry = {} } = this.state;
    return (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#FAE5D3" }}
          to={`/dashboard/cms-advance-landing-pages/all/${id}/${selectedCountry.id}`}
        >
          Manage
        </Link>
      </div>
    );
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewLandingPage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  handleAddNewLandingPage = () => {
    this.setState({ isNewLandingPage: true }, () =>
      this.showLandingPageVieModal()
    );
  };

  handleViewLandingPage = (landingPageId) => {
    const landingPages = this.state.landingPages ?? [];
    const landingPage = landingPages.find((it) => it.id === landingPageId);
    if (!landingPage) {
      console.log(`Landing Page with id ${landingPageId} is not found!`);
      return;
    }
    console.log("Landing Page:", landingPage);
    this.setState({ landingPage, isNewLandingPage: false }, () =>
      this.showLandingPageVieModal()
    );
  };

  updateLandingPage = async (landingPage) => {
    try {
      const response = await PhoenixAPI.post(
        `/advance-landing-page`,
        landingPage
      );
      this.hideLandingPageViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification("Landing Page Updated Successfully.");
      this.fetchLandingPages();
    } catch (error) {
      console.log("Error in saving", error.response);
      const { data } = error && error.response;
      const { message: ErrorMessage = "", statusCode = "" } = data;

      this.showErrorNotification(
        `Error occurred in Saving page: ${ErrorMessage}`
      );
    }
  };

  saveLandingPage = async (landingPage) => {
    try {
      const response = await PhoenixAPI.post(
        "/advance-landing-page",
        landingPage
      );
      this.hideLandingPageViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification("Landing Page Created Successfully.");
      this.fetchLandingPages();
    } catch (error) {
      console.log("Error in saving", error.response);
      const { data } = error && error.response;
      const { message: ErrorMessage = "", statusCode = "" } = data;

      this.showErrorNotification(
        `Error occurred in Saving page: ${ErrorMessage}`
      );
    }
  };
  handlesaveLandingPage = async () => {
    const { landingPage, selectedCountry } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = landingPage.id !== null && landingPage.id !== undefined;

      if (isValidId) {
        this.updateLandingPage(landingPage);
      } else {
        this.saveLandingPage(landingPage);
      }
    } catch (error) {
      console.log("Error in saving page", error);
      this.showErrorNotification("Some error occurred: " + error);
    } finally {
      this.hideLoading();
    }
  };

  getFilteredLandingPages = () => {
    const landingPages = this.getLandingPagesForCurrentCountry();
    return this.getSearchedLandingPages(landingPages);
  };

  getSearchedLandingPages = (landingPages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return landingPages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return landingPages.filter((landingPage) => {
      const { id, pageName } = landingPage;
      return (
        (pageName && pageName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };
  getLandingPagesForCurrentCountry = () => {
    const { landingPages, selectedCountry } = this.state;
    return landingPages && selectedCountry
      ? landingPages.filter(
          (landingPage) => landingPage.countryId === selectedCountry.id
        )
      : [];
  };

  showLandingPageVieModal = () => {
    const { selectedCountry, isNewLandingPage } = this.state;

    if (isNewLandingPage) {
      const landingPage = {
        countryId: selectedCountry?.id,

        isActive: false,
        id: null,
      };
      this.setState({
        landingPage,

        isLandingPageViewModalVisible: true,
      });
    } else {
      this.setState({ isLandingPageViewModalVisible: true });
    }
  };
  hideLandingPageViewModal = () => {
    this.setState({ isLandingPageViewModalVisible: false });
    this.resetForm();
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      isNewLandingPage,
      landingPages,
      landingPage,
    } = this.state;
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    const filteredLandingPages =
      this.getFilteredLandingPages(landingPages) ?? [];

    const formBasicInfo = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Page Name</label>
            <div className="input-group">
              <input
                value={landingPage?.pageName || ""}
                onChange={this.handleChange}
                id="pageName"
                name="pageName"
                type="text"
                placeholder="Page Name"
                className={
                  "form-control py-2" + (errors.pageName ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.pageName}
              />
              {this.renderPencilIcon("pageName")}
            </div>
            <span className="help-block">{errors.pageName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={landingPage.isActive}
                onChange={(event, value) => {
                  this.setState({
                    landingPage: {
                      ...landingPage,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewLandingPage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    const seoForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="urlName">URL Name</label>
            <div className="input-group">
              <input
                value={(landingPage && landingPage.urlName) || ""}
                onChange={this.handleChange}
                id="urlName"
                name="urlName"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.urlName ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.urlName}
              />
              {this.renderPencilIcon("urlName")}
            </div>
            <span className="help-block">{errors.urlName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(landingPage && landingPage.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>{" "}
          <div className="form-group col">
            <label htmlFor="metaTitleAr">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(landingPage && landingPage.metaTitleAr) || ""}
                onChange={this.handleChange}
                id="metaTitleAr"
                name="metaTitleAr"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaTitleAr ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.metaTitleAr}
              />
              {this.renderPencilIcon("metaTitleAr")}
            </div>
            <span className="help-block">{errors.metaTitleAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(landingPage && landingPage.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">
              Meta Description Arabic
            </label>
            <div className="input-group">
              <input
                value={(landingPage && landingPage.metaDescriptionAr) || ""}
                onChange={this.handleChange}
                id="metaDescriptionAr"
                name="metaDescriptionAr"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaDescriptionAr ? " has-error" : "")
                }
                disabled={!isNewLandingPage && !editables.metaDescriptionAr}
              />
              {this.renderPencilIcon("metaDescriptionAr")}
            </div>
            <span className="help-block">{errors.metaDescriptionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="altImageTag">Alt Image Tag</label>
            <div className="input-group">
              <textarea
                value={(landingPage && landingPage.altImageTag) || ""}
                onChange={this.handleChange}
                id="altImageTag"
                name="altImageTag"
                placeholder="Alt Image Tag"
                className={
                  "form-control py-2" + (errors.altImageTag ? " has-error" : "")
                }
                readOnly={!isNewLandingPage && !editables.altImageTag}
              ></textarea>
              {this.renderPencilIcon("altImageTag")}
            </div>
            <span className="help-block">{errors.altImageTag}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="altImageTagAr">Alt Image Tag Arabic</label>
            <div className="input-group">
              <textarea
                value={(landingPage && landingPage.altImageTagAr) || ""}
                onChange={this.handleChange}
                id="altImageTagAr"
                name="altImageTagAr"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.altImageTagAr ? " has-error" : "")
                }
                readOnly={!isNewLandingPage && !editables.altImageTagAr}
              ></textarea>
              {this.renderPencilIcon("altImageTagAr")}
            </div>
            <span className="help-block">{errors.altImageTagAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(landingPage && landingPage.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewLandingPage && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(landingPage && landingPage.keywordsAr) || ""}
                onChange={this.handleChange}
                id="keywordsAr"
                name="keywordsAr"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.keywordsAr ? " has-error" : "")
                }
                readOnly={!isNewLandingPage && !editables.keywordsAr}
              ></textarea>
              {this.renderPencilIcon("keywordsAr")}
            </div>
            <span className="help-block">{errors.keywordsAr}</span>
          </div>
        </div>
      </div>
    );
    return (
      <div className="manage=package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewLandingPage}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New Page
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={filteredLandingPages || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredLandingPages.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isLandingPageViewModalVisible}
          onHide={this.hideLandingPageViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesaveLandingPage}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_landing_pages" justify>
              <Tab eventKey="key_form_basic_information" title="Template">
                {formBasicInfo}
              </Tab>
              <Tab eventKey="key_form_seo" title="Seo">
                {seoForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvanceLandingPageHome);
