import React, { Component } from "react";
import API from "utils/API";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import moment from "moment";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import DoneIcon from "@material-ui/icons/Done";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class CustomSurvey extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "nameEn",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "nameAr",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "userId",
        method: "isEmpty",
        validWhen: false,
        message: "Client is required.",
      },
      {
        field: "rolloutDate",
        method: "isEmpty",
        validWhen: false,
        message: "Date is required.",
      },
      {
        field: "descriptionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Description is required.",
      },
      {
        field: "descriptionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Description is required.",
      },
    ]);

    this.customValidator = {
      rolloutDate: {
        message: null,
      },
    };

    this.state = {
      surveys: null,
      isLoaded: false,
      loadError: "",
      nameEn: "",
      nameAr: "",
      descriptionAr: "",
      descriptionEn: "",
      rolloutDate: "",
      userId: "",
      selectedOrderID: -1,
      userListFormatted: [],
      clientList: [],
      userOrderFormatted: [
        {
          text: 'not attached',
          value: -1,
          key: -1
        },
        {
          text: 'upcoming',
          value: 'upcoming',
          key: 'upcoming'
        }
      ],
      users: [],
      startDate: null,
      formHeading: "Create Assessment",
      editId: null,
      validation: this.validator.valid(),

      showModal: false,
      showMarkModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      showAlert: true,
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
    };

    this.columns = [
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Client",
        sortable: true,
        selector: "user_name",
      },
      {
        name: "Order Attached",
        sortable: true,
        selector: "order"
      },
      {
        name: "Publish Survey",
        center: true,
        sortable: true,
        selector: "is_published",
        format: row => (
          <>
            {row.is_published ? (
              <div className="badge badge-green auto-width no-background">
                <DoneIcon></DoneIcon>
                Published
              </div>
            ) : (
                <div className="badge badge-secondary auto-width no-background">
                  <InfoOutlinedIcon></InfoOutlinedIcon>Not Published
                </div>
              )}
          </>
        ),
      },
      {
        name: "Rollout Date",
        maxWidth: "150px",
        sortable: true,
        selector: "roll_out_date",
        format: (row) =>
          moment(row.roll_out_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: (row) => { console.log(row.is_active); return(
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
                <div className="badge badge-warning">
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
                </div>
              )}
          </>
        )},
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {!row.is_published ? (
                <ActionBar.Item onClick={() => this.showMarkModal(row)}>
                  <DoneAllOutlinedIcon></DoneAllOutlinedIcon>
                  Publish
                </ActionBar.Item>
              ) : (
                  ""
                )}
              <ActionBar.Item
                as={NavLink}
                to={
                  "/dashboard/health-profile/surveys/custom/surveys/questions/" +
                  row.id
                }
              >
                <PostAddOutlinedIcon></PostAddOutlinedIcon>
                Manage Questions
              </ActionBar.Item>
              <ActionBar.Item
                onClick={() => this.editSurvey(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                  <ActionBar.Item
                    className="success"
                    onClick={() => this.showConfirm(row)}
                  >
                    <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                  </ActionBar.Item>
                )}
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideMarkModal = this.hideMarkModal.bind(this);
    this.showMarkModal = this.showMarkModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.markAsComplete = this.markAsComplete.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  componentDidMount() {
    this.loadData();
    this.setState({ startDate: this.getCurrentDate() });
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  loadData() {
    this.loadUsers();
    this.loadClients();
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;

    return today;
  }

  validateDate(date) {
    const startDate = this.state.startDate;
    const formattedDate = moment(date, "YYYY-MM-DD", true);

    if (formattedDate.isValid()) {
      if (formattedDate.isSameOrAfter(startDate)) {
        this.customValidator.rolloutDate.message = null;
        return true;
      } else {
        this.customValidator.rolloutDate.message =
          "Date should not be in the past";
        return false;
      }
    } else {
      this.customValidator.rolloutDate.message = "Invalid date.";
      return false;
    }
  }

  loadOrdersOfUser() {
    // this.state.userId holds the selected clients id
    //fetch orders of just that user
    API.get("order-nutritionist/").then((response) => {
      let result = response.data;
      result = result.filter((item) => item.user.id === this.state.userId)
      let orders = [
        {
          text: 'not attached',
          value: -1,
          key: -1
        },
        {
          text: 'upcoming',
          value: 'upcoming',
          key: 'upcoming'
        }
      ];
      result.map((item) => {
        orders.push({
          text: `order number: ${item.id}`,
          value: item.id,
          key: item.id
        })
      })
      this.setState({
        userOrderFormatted: orders
      })
    })
    .catch((error) => {
      console.log("Error on fetching orders", error);
    });
  }

  loadClients() {
    API.get("nutritionist-clients/")
      .then(response => {
        let userListFormatted = response.data.map(user => {
          return {
            text: user.full_name + " (" + user.email + ")",
            value: user.user_id,
            key: user.user_id
          };
        });

        this.setState(
          {
            clientList: userListFormatted
          }
        );
      })
      .catch(error => {
        console.log("Error on fetching nutritionist clients", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  loadUsers() {
    API.get("list-users/")
      .then((response) => {
        let newList = response.data.sort((a, b) => {
          return (a.username ? a.username : "").localeCompare(b.username);
        });

        let userListFormatted = newList.map((user) => {
          return {
            text: user.username + " (" + user.email + ")",
            value: user.id,
            key: user.id,
          };
        });

        this.setState(
          {
            users: newList,
            userListFormatted: userListFormatted,
          },
          () => {
            this.loadSurveys();
          }
        );
      })
      .catch((error) => {
        console.log("Error on fetching users", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  loadSurveys() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("customized-survey-list/")
      .then((response) => {
        let newList = response.data;

        for (let item of newList) {
          item.user_name = this.getUserName(item.user);
          item.order = item.order ? 'Order id: ' + item.order : 'N/A'
        }

        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");

        this.setState({
          surveys: newList,
          activeList,
          archivedList,
          isLoaded: true,
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching surveys", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  getUserName(id) {
    const users = this.state.users;

    if (users.length > 0 && id !== null) {
      let user = users.find((item) => {
        return item.id === id;
      });

      if (user) return user.username + " (" + user.email + ")";
    }

    return "";
  }

  editSurvey(index, id) {
    let surveys = this.state.surveys;

    this.setState({
      formHeading: "Edit Survey",
      nameEn: surveys[index]["name"],
      nameAr: surveys[index]["name_ar"],
      descriptionEn: surveys[index]["description"],
      descriptionAr: surveys[index]["description_ar"],
      rolloutDate: surveys[index]["roll_out_date"],
      userId: surveys[index]["user"],
      editId: id,
      showAlert: false,
    });

    this.showModal();
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("health-survey/" + item.id + "/", {
      is_active: !item.is_active,
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  markAsComplete(item) {
    if (!item) return;

    this.hideMarkModal();
    this.props.toggleLoading({
      isLoading: true,
    });
    var formData = new FormData();
    API.patch("health-survey/" + item.id + "/", {
      is_published: true
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Survey marked as completed successfully.",
          successMessage: true,
          showNotification: true,
        });
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on marking survey completed", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.customValidator.rolloutDate.message = null;

    this.setState({
      formHeading: "Create Survey",
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      editId: null,
      rolloutDate: "",
      userId: "",
      validation: this.validator.valid(),
      showAlert: true,
    });
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showMarkModal(item) {
    this.setState({
      showMarkModal: true,
      confirmItem: item,
    });
  }

  hideMarkModal() {
    this.setState({
      showMarkModal: false,
      confirmItem: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Survey";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Survey successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Survey";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Survey successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      nameEn,
      nameAr,
      descriptionEn,
      descriptionAr,
      rolloutDate,
      userId,
      editId,
      selectedOrderID
    } = this.state;

    let isValidDate = this.validateDate(rolloutDate);
    let validation = this.validator.validate(this.state);
    if (this.selectedOrderID != -1) {
      // no need rollout date validation
      isValidDate = true;
    }
    this.setState({ validation });
    this.submitted = true;
    debugger;
    if (validation.isValid && isValidDate) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });

      if (editId !== null) {
        //edit
        API.put("health-survey/" + editId + "/", {
          name: nameEn,
          name_ar: nameAr,
          survey_type: "CU",
          description: descriptionEn,
          description_ar: descriptionAr,
          user: userId,
          roll_out_date: rolloutDate,
          order: selectedOrderID == -1 ? null : selectedOrderID
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Survey successfully updated.",
              successMessage: true,
              showNotification: true,
            });
            this.loadData();
          })
          .catch((error) => {
            console.log("Error on updating survey", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      } else {
        API.post("health-survey/", {
          name: nameEn,
          name_ar: nameAr,
          survey_type: "CU",
          description: descriptionEn,
          description_ar: descriptionAr,
          user: userId,
          roll_out_date: rolloutDate,
          order: selectedOrderID == -1 ? null : selectedOrderID
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Survey successfully created.",
              successMessage: true,
              showNotification: true,
            });
            this.loadData();
          })
          .catch((error) => {
            console.log("Error on creating survey", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      }
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.surveys;

    let newList = [];

    for (let item of list) {
      if (
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.name_ar.toLowerCase().indexOf(newValue) !== -1 ||
        item.user_name.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  render() {
    let form,
      finalList = [];
    const {
      surveys,
      isLoaded,
      loadError,
      showAlert,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (this.submitted) this.validateDate(this.state.rolloutDate);

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        if (showItems === "Active") finalList = activeList;
        else if (showItems === "Archived") finalList = archivedList;
        else finalList = surveys;
      }
    }

    form = (
      <>
        <div className="form-row mb-2">
          <div className="form-group col input-section">
            <label>Name (English) *</label>
            <input
              type="text"
              name="nameEn"
              className={
                "form-control " + (validation.nameEn.isInvalid && "has-error")
              }
              placeholder="Name in English"
              onChange={this.handleChange}
              value={this.state.nameEn}
            ></input>
            <span className="help-block">{validation.nameEn.message}</span>
          </div>
          <div className="form-group col input-section">
            <label>Name (Arabic) *</label>
            <input
              type="text"
              name="nameAr"
              className={
                "form-control input-arabic " +
                (validation.nameAr.isInvalid && "has-error")
              }
              placeholder="Name in Arabic"
              onChange={this.handleChange}
              value={this.state.nameAr}
            ></input>
            <span className="help-block">{validation.nameAr.message}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col input-section">
            <label>Description (English) *</label>
            <textarea
              type="text"
              name="descriptionEn"
              className={
                "form-control " +
                (validation.descriptionAr.isInvalid && "has-error")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.descriptionEn}
            ></textarea>
            <span className="help-block">
              {validation.descriptionEn.message}
            </span>
          </div>
          <div className="form-group col input-section">
            <label>Description (Arabic) *</label>
            <textarea
              type="text"
              name="descriptionAr"
              className={
                "form-control input-arabic " +
                (validation.descriptionAr.isInvalid && "has-error")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.descriptionAr}
            ></textarea>
            <span className="help-block">
              {validation.descriptionAr.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-2">
          <div className="form-group col-6 input-section">
            <label>Client *</label>
            <Dropdown
              value={this.state.userId}
              disabled={this.state.editId ? true : false}
              className={
                "form-control " + (validation.userId.isInvalid && "has-error")
              }
              placeholder="Select client"
              onChange={(event, value) => {
                this.loadOrdersOfUser();
                this.setState({ userId: value.value });
              }}
              search
              selection
              options={this.state.clientList}
            />
            <span className="help-block">{validation.userId.message}</span>
          </div>
          <div className="form-group col-6 input-section">
            <label>Attach to order</label>
            <Dropdown
              value={this.state.selectedOrderID}
              disabled={this.state.editId ? true : false}
              className={
                "form-control " + (validation.userId.isInvalid && "has-error")
              }
              placeholder="attach to an order"
              search
              selection
              options={this.state.userOrderFormatted}
              onChange={(event, value) => {
                debugger;
                if (value.value != -1) {
                  var aYearFromNow = new Date();
                  aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
                  this.setState({
                    selectedOrderID: value.value,
                    rolloutDate: moment(aYearFromNow).format("YYYY-MM-DD")
                  })
                } else {
                  debugger;
                  this.setState({
                    selectedOrderID: value.value,
                    rolloutDate: ''
                  })
                }
              }}
            />
            <span className="help-block">{validation.userId.message}</span>
          </div>
          {
            this.state.selectedOrderID == -1 &&
            <div className="form-group col-3 input-section">
              <label>Rollout Date *</label>
              <input
                name="rolloutDate"
                type="date"
                placeholder="Rollout Date"
                min={this.state.startDate}
                className={
                  "form-control " +
                  ((validation.rolloutDate.isInvalid ||
                    this.customValidator.rolloutDate.message) &&
                    "has-error")
                }
                onChange={this.handleChange}
                value={this.state.rolloutDate}
              ></input>
              <span className="help-block">
                {validation.rolloutDate.message
                  ? validation.rolloutDate.message
                  : this.customValidator.rolloutDate.message}
              </span>
            </div>
          }
        </div>
        {showAlert ? (
          <Alert
            variant="warning"
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            dismissible
            className="mt-2"
          >
            You can't change the client once the survey is created.
          </Alert>
        ) : null}
      </>
    );

    return (
      <div className="survey">
        <div className="page-header">
          {/* <h5>Customized health assessments</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>

            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="name"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results in '" + showItems + "'"}
                </p>
              ) : (
                  ""
                )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
            }
          />
        </Card>
        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={(e) => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showMarkModal} onHide={this.hideMarkModal}>
          <Modal.Header closeButton>
            <Modal.Title>Mark As Completed</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to mark this survey as completed?
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.markAsComplete(this.state.confirmItem)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideMarkModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
                <button
                  onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                  className="btn button btn-success"
                >
                  Restore
                </button>
              )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSurvey);
