import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserEmail, getUserId } from "./UserService";

const BASE_URL_CREATE_ORDER = "admin/create-order/";
const BASE_URL_ORDERS = "admin/orders/";
const BASE_URL_ORDER = "admin/cart-orders/";
const OLD_BASE_URL_ORDER = "order-list/";
const BASE_URL_AUDIT_TRAILS = "audit-trails/";
const BASE_URL_SUPPLEMENT_ORDERS="admin/supplement/orders"
const BASE_URL_CHECK_AND_UPDATE_PARENT_ORDER_STATUS =
  "/admin/check-and-update-parent-order-blocking/";
const BASE_QUERY_PARAM_CANCEL_CLEVERTAP="isSendClevertapForCancel";
const OLD_BASE_URL_ORDER_PAST="order-list-past/";
const BASE_URL_SUPPLEMENT_ORDER="admin/supplement/cart-orders/"


export const createOrder = async (payload) => {
  await PhoenixAPI.post(BASE_URL_CREATE_ORDER, payload);
};

export const fetchOrders = async (config) => {
  const {
    page,
    rowsPerPage,
    sortField,
    sortDirection,
    searchApplied,
    searchTerm,
  } = config ?? {};
  const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
  const params = { page, page_size: rowsPerPage, ordering: sf };
  if (searchApplied) params.search = searchTerm;
  // let url = `${BASE_URL_ORDERS}?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;
  // if (searchApplied) url += `&search=${searchTerm}`;

  try {
    // const { data: orders = [] } = await PhoenixAPI.get(BASE_URL_ORDERS, { params });
    const { data } = await PhoenixAPI.get(BASE_URL_ORDERS, { params });
    if (Array.isArray(data)) return { count: data.length, results: data };
    else return data;
  } catch (error) {
    console.log("Error on fetching orders", error);
    throw error;
  }
};

export const fetchOrder = async (orderId) => {
  try {
    const { data: order } = await PhoenixAPI.get(BASE_URL_ORDER + orderId);
    return order;
  } catch (error) {
    console.log("Error on fetching orders", error);
    throw error;
  }
};
export const  fetchSupplementOrder=async(orderId)=>{
  try {
    const { data: order } = await PhoenixAPI.get(BASE_URL_SUPPLEMENT_ORDER + orderId);
    return order;
  } catch (error) {
    console.log("Error on fetching orders", error);
    throw error;
  }

}
 

export const fetchSupplementOrders=async(config)=>{
  const {
    page,
    rowsPerPage,
    sortField,
    sortDirection,
    searchApplied,
    searchTerm,
  } = config ?? {};
  const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
  const params = { page, page_size: rowsPerPage, ordering: sf };
  if (searchApplied) params.search = searchTerm;
  // let url = `${BASE_URL_ORDERS}?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;
  // if (searchApplied) url += `&search=${searchTerm}`;

  try {
    // const { data: orders = [] } = await PhoenixAPI.get(BASE_URL_ORDERS, { params });
    const { data } = await PhoenixAPI.get(BASE_URL_SUPPLEMENT_ORDERS, { params });
    if (Array.isArray(data)) return { count: data.length, results: data };
    else return data;
  } catch (error) {
    console.log("Error on fetching orders", error);
    throw error;
  }

}

export const saveOrder = async (order) => {
  if (order === null) return;

  const config = {
    method: order.id ? "PATCH" : "POST",
    url: order.id ? `${BASE_URL_ORDER}${order.id}` : BASE_URL_ORDER,
    data: order,
  };
  return await PhoenixAPI.request(config);
};

export const cancelParentOrder = async (parentOrderId) => {
  const payload = { orderStatus: "CANCELLED" };
  const config = {
    method: "PATCH",
    url: `${BASE_URL_ORDER}${parentOrderId}/`,
    data: payload,
  };
  return await PhoenixAPI.request(config);
};

export const updateParentOrderStatus = async (parentOrderId, orderStatus) => {
  const payload = { orderStatus: orderStatus };
  const config = {
    method: "PATCH",
    url: `${BASE_URL_ORDER}${parentOrderId}/`,
    data: payload,
  };
  return await PhoenixAPI.request(config);
};

export const updateChildOrder = async (id, payload) => {
  console.log("OrderService:: updateChildOrder:", { id, payload });
  const config = {
    method: "PATCH",
    url: `${OLD_BASE_URL_ORDER}${id}/`,
    data: payload,
  };
  return await API.request(config);
};

export const saveOrderStatusLogs = async (payload) => {
  try{
    if (!payload) {
      console.log("saveOrderStatusLogs:: Invalid payload!", payload);
      return;
    }
  
    if (!payload.modifiedBy)
      payload.modifiedBy = getUserEmail() ?? `Email not available. user id: ${getUserId()}`;
  }
  catch(error){
    console.log("Error on creating order status logs", error);
    throw error;
  }

  
  try {
    const response = await PhoenixAPI.post(BASE_URL_AUDIT_TRAILS, payload);
    console.log(response);
  } catch (error) {
    console.log("Error on creating order status logs", error);
    // throw error;
  }
};

export const checkAndUpdateParentOrderStatus = async (childOrderId,isSendClevertapForCancel=null) => {
  let URL=""
  if (childOrderId === null || childOrderId === undefined) {
    console.log("checkAndUpdateParentOrderStatus:: Invalid id: ", childOrderId);
    return;
  }
  if (isSendClevertapForCancel) {
    URL = `${BASE_URL_CHECK_AND_UPDATE_PARENT_ORDER_STATUS}${Number(
      childOrderId
    )}/?${BASE_QUERY_PARAM_CANCEL_CLEVERTAP}=yes`;
  } else {
    URL = `${BASE_URL_CHECK_AND_UPDATE_PARENT_ORDER_STATUS}${Number(
      childOrderId
    )}/`;
  }
  try {
    await PhoenixAPI.post(
      URL
    );
    console.log(
      `checkAndUpdateParentOrderStatus:: API called successfully for childOrderId: ${childOrderId}`
    );
  } catch (error) {
    console.log(
      `checkAndUpdateParentOrderStatus:: Error on calling API for childOrderId: ${childOrderId}`,
      error
    );
    throw error;
  }
};

export const updateChildPastOrder = async (id, payload) => {
  console.log("OrderService:: updateChilPastdOrder:", { id, payload });
  const config = {
    method: "PATCH",
    url: `${OLD_BASE_URL_ORDER_PAST}${id}/`,
    data: payload,
  };
  return await API.request(config);
};

const OrderService = {
  saveOrder,
  updateChildOrder,
  fetchOrders,
  fetchOrder,
  saveOrderStatusLogs,
  checkAndUpdateParentOrderStatus,
};

export default OrderService;
