import React, { Component } from "react";
import { connect } from "react-redux";
import API from "utils/API";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptions: [],
      isLoaded: false,
      loadError: ""
    };

    this.columns = [
      {
        name: "Package",
        selector: "package.subscription_name",
        sortable: true,
        cell: (row) => {
          return row.package_type ? (
            row.custom_package && row.custom_package.name ? row.custom_package.name : 'NA'

          ) : (
            row.package !== null
              ? row.package.subscription_name
              : row.add_on && row.add_on !== null
                ? row.add_on.addon_name
                : "N/A"
          );
        }
      },
      {
        name: "Client",
        selector: "user.username",
        sortable: true,
        format: row => row.user.username + " (" + row.user.email + ")"
      },
      {
        name: "Frequency",
        selector: "frequency",
        sortable: true,
        format: row => row.frequency + " Months"
      },
      {
        name: "Start Date",
        selector: "subscription_start_date",
        sortable: true,
        format: row => moment(row.subscription_start_date).format("DD/MM/YYYY")
      },
      {
        name: "End Date",
        selector: "subscription_end_date",
        sortable: true,
        format: row => moment(row.subscription_end_date).format("DD/MM/YYYY")
      },
      {
        name: "Next Date",
        selector: "next_subscription_date",
        sortable: true,
        format: row => moment(row.next_subscription_date).format("DD/MM/YYYY")
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <CancelOutlinedIcon></CancelOutlinedIcon>Not Active
              </div>
            )}
          </>
        )
      }
    ];

    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  loadSubscriptions() {
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("order-subscription/")
      .then(response => {
        this.setState({ subscriptions: response.data, isLoaded: true });
        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching order subscription");
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  componentDidMount() {
    this.loadSubscriptions();
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    list = this.state.subscriptions;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.package.subscription_name.toLowerCase().indexOf(newValue) !== -1 ||
        item.package.subscription_name_ar.toLowerCase().indexOf(newValue) !==
          -1 ||
        item.user.username.toLowerCase().indexOf(newValue) !== -1 ||
        item.user.email.toLowerCase().indexOf(newValue) !== -1 ||
        item.subscription_start_date.toLowerCase().indexOf(newValue) !== -1 ||
        moment(item.created_at)
          .format("DD/MM/YYYY")
          .indexOf(newValue) !== -1 ||
        item.subscription_end_date.toLowerCase().indexOf(newValue) !== -1 ||
        moment(item.created_at)
          .format("DD/MM/YYYY")
          .indexOf(newValue) !== -1 ||
        item.next_subscription_date.toLowerCase().indexOf(newValue) !== -1 ||
        moment(item.created_at)
          .format("DD/MM/YYYY")
          .indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  render() {
    let finalList = [];

    const {
      subscriptions,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results
    } = this.state;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else finalList = subscriptions;
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>Package Subscriptions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card>
          {/*
          todo
          */}
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="package.subscription_name"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
