import React, { Component } from "react";
import Search from "components/Search/Search";
import CountryDropdown from "components/Common/CountryDropdown";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL_REFERRAL_CODES = "api/v1/referral-code-settings";

class ReferralCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      referralCodes: [],
      referralCode: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      isReferralCodeViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Code Value",
        selector: "codeValue",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (referralCode) => <Status isActive={referralCode.isActive} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (referralCode) => this.renderViewButton(referralCode.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchReferrals();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.REFERRAL_CODES;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchReferrals = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_REFERRAL_CODES);
      const referralCodes = (response && response.data) || [];
      this.setState({ referralCodes });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Referral Codes: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      referralCode: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewReferralCode(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : null;

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleViewReferralCode = (referralCodeId) => {
    const referralCodes = this.state.referralCodes ?? [];
    const referralCode = referralCodes.find((it) => it.id === referralCodeId);
    if (!referralCode) {
      console.log(`Referral Code with ID ${referralCodeId} is not found!`);
      return;
    }
    this.setState({ referralCode }, () => this.showReferralCodeViewModal());
  };

  showReferralCodeViewModal = () => {
    this.setState({
      isReferralCodeViewModalVisible: true,
    });
  };

  hideReferralCodeViewModal = () => {
    this.setState({ isReferralCodeViewModalVisible: false });
    this.resetForm();
  };

  handlesaveReferralCode = async () => {
    const { selectedCountry, referralCode } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const response = await this.saveReferralCode(referralCode);
      this.hideReferralCodeViewModal();
      this.showSuccessNotification("Referral Code is Updated Successfully");
      this.fetchReferrals();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  saveReferralCode = async (referralCode) => {
    const payload = { ...referralCode };
    delete payload["modifiedAt"];
    delete payload["createdAt"];

    return await PhoenixAPI.patch(
      `${BASE_URL_REFERRAL_CODES}/${referralCode.id}`,
      payload
    );
  };

  validate = () => {
    const { referralCode = {} } = this.state;
    const errors = {};
    const { codeValue, maximumReferralsAllowed } = referralCode;
    let min = 0;
    let max = 1000000;

    if (!codeValue || codeValue === "") {
      errors.codeValue = "Code Value is a required field!";
    } else if (Number.isNaN(Number(codeValue))) {
      errors.codeValue = "Enter a Valid Number";
    } else if (Number(codeValue) < min) {
      errors.codeValue = `Code Value should be greater than ${min}.`;
    }

    if (!maximumReferralsAllowed || maximumReferralsAllowed === "") {
      errors.maximumReferralsAllowed =
        "Maximum Referrals Allowed is  a required field!";
    } else if (Number.isNaN(Number(maximumReferralsAllowed))) {
      errors.maximumReferralsAllowed = "Enter a Valid Number";
    } else if (
      Number(maximumReferralsAllowed) <= min ||
      Number(maximumReferralsAllowed) > max
    ) {
      errors.maximumReferralsAllowed = `maximumReferralsAllowed should not be greater than ${max}`;
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  render() {
    const {
      referralCode,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      errors,
      editables,
      loadError,
      isEditable
    } = this.state;
    let { referralCodes } = this.state;
    referralCodes = referralCodes.filter(
      (it) => it?.country?.id === selectedCountry?.id
    );

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="codeValue">Code Value*</label>
            <div className="input-group user-input-container">
              <input
                value={referralCode?.codeValue || ""}
                onChange={(event) => {
                  this.setState({
                    referralCode: {
                      ...referralCode,
                      codeValue: event.target.value,
                    },
                  });
                }}
                id="codeValue"
                name="codeValue"
                type="number"
                placeholder="Enter Code value"
                className={
                  "form-control py-2" + (errors.codeValue ? " has-error" : "")
                }
                disabled={!editables.codeValue}
              />
              {this.renderPencilIcon("codeValue")}
            </div>
            <span className="help-block">{errors.codeValue}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={referralCode.isActive}
                onChange={(event, value) => {
                  this.setState({
                    referralCode: {
                      ...referralCode,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={(referralCode && referralCode?.country?.name) || ""}
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Currency</label>
            <input
              value={(referralCode && referralCode?.country?.currency) || ""}
              type="text"
              id="currency"
              name="currency"
              disabled={true}
              className={
                "form-control py-2" + (errors.currency ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.currency}</span>
          </div>
        </div>
        <div className="form-content">
          <span>Usage Restrictions</span>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="maximumReferralsAllowed">
                Maximum number of referrals *
              </label>
              <div className="input-group">
                <input
                  value={referralCode.maximumReferralsAllowed || ""}
                  onChange={(event) => {
                    this.setState({
                      referralCode: {
                        ...referralCode,
                        maximumReferralsAllowed: event.target.value,
                      },
                    });
                  }}
                  id="maximumReferralsAllowed"
                  name="maximumReferralsAllowed"
                  type="number"
                  placeholder="Enter Maximum Referrals"
                  className={"form-control py-2"}
                  disabled={!editables.maximumReferralsAllowed}
                />
                {this.renderPencilIcon("maximumReferralsAllowed")}
              </div>
              <span className="help-block">
                {errors.maximumReferralsAllowed}
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-pacakage">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={referralCodes || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + referralCodes.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isReferralCodeViewModalVisible}
          onHide={this.hideReferralCodeViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title"></div>

              <button
                type="submit"
                onClick={this.handlesaveReferralCode}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_detailsform">
              <Tab eventKey="key_detailsform_" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralCodes);
