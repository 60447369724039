import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";

import { ArrowDownward } from "@material-ui/icons";

import { formatDateTime } from "utils/commons";

import Status from "components/Common/Status";

import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import Search from "components/Search/Search";
import moment from "moment";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class PackageLabCost extends Component {
  constructor(props) {
    super(props);

    const {
      selectedPackageCosts = [],
      handleHideAll = (f) => f,
      handleAllSelected = (f) => f,
      packageType = "",
    } = this.props;

    this.handleHideAll = handleHideAll;
    this.handleAllSelected = handleAllSelected;
    this.state = {
      selectedPackageCosts,
      selectedPackageCost: {},
      packageType: "",
      selectedPackageCostsLogs: [],
      searchTerm: null,
      searchApplied: false,
      selectedRows:[],
      errors:{}
    };

    this.columns = [
      {
        name: "UUID",
        selector: "uuid",
        wrap: true,
        sortable: true,
      },
      {
        name: "Package",
        selector: "packageName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (lab) => <Status isActive={lab.status === "Active" ?? false} />,
        sortable: true,
      },
      {
        name: "Cost",
        selector: "cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        minWidth: "170px",
        cell: (lab) => this.renderViewButton(lab.packageId),
      },
    ];

    this.packageCostsColumns = [
      {
        name: "From Date",
        selector: "fromDate",
        wrap: true,
        sortable: true,
      },
      {
        name: "To Date",
        selector: "toDate",
        wrap: true,
        sortable: true,
      },
      {
        name: "Cost",
        selector: "cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Updated By",
        center: true,
        minWidth: "170px",
        cell: (labCostPackage) => this.renderUpdatedBy(labCostPackage),
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedPackageCosts !== nextProps.selectedPackageCosts) {
      this.setState({
        selectedPackageCosts: nextProps.selectedPackageCosts,
        packageType: nextProps.packageType,
      });
    }
  }

  renderUpdatedBy = (labCostPackage) => {
    const updatedTime = labCostPackage.createdAt && labCostPackage.createdAt;

    const formattedDateTime = formatDateTime(updatedTime);
    const userModified = `${
      labCostPackage?.createdByName ?? ""
    }, ${formattedDateTime}`;
    return <>{userModified}</>;
  };
  renderViewButton = (id) => {
    return (
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        Edit
      </button>
    );
  };

  handleChange = ({ selectedRows=[] }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    
    const packageType=this.state.packageType;
    const filteredSelectedItems=selectedRows.filter((item)=>item.packageType===packageType)
    console.log("selectedRows: packageType", filteredSelectedItems)
    this.setState({ selectedRows: filteredSelectedItems},()=>this.handleAllSelected(filteredSelectedItems))
  };

  handleView = async (id) => {
    const selectedPackageCosts = this.state.selectedPackageCosts ?? [];
    const selectedPackageCost = selectedPackageCosts.find(
      (it) => it.packageId === id
    );
    console.log("selectedPackageCosts: ", selectedPackageCost)
    const packageType = this.state.packageType ?? "";
    if (!selectedPackageCost) {
      console.log(`Selected Package with id ${id} is not found!`);
      return;
    }
    console.log(
      "Selected Package with id ${id}",
      selectedPackageCost,
      this.state.packageType
    );
    try {
      const { data: selectedPackageCostsLogs = [] } = await PhoenixAPI.get(
        `lab-cost-log?labId=${selectedPackageCost.labId}&countryId=${selectedPackageCost.countryId}&packageType=${packageType}&packageId=${selectedPackageCost.packageId}`
      );
      this.setState({ selectedPackageCostsLogs });
    } catch (error) {
      console.log("Error occurred in selected Package with id ${id");
    }
    this.setState({ selectedPackageCost }, () =>
      this.showSelectedPackageViewModal()
    );
  };

  showSelectedPackageViewModal = () => {
    this.setState({ isSelectedPackageViewModalVisible: true });
  };

  hideSelectedPackageModal = () => {
    this.setState({
      isSelectedPackageViewModalVisible: false,
      selectedPackageCost: {},
    });
  };

  handleAddNewCost = () => {
    const {selectedPackageCost}= this.state;
    
    this.setState({ isAddCostViewModalVisible: true, selectedPackageCost:{...selectedPackageCost, cost:null, startDate:""} });
  };
  hideSelectedPackageCostModal = () => {
    this.setState(
      {
        isAddCostViewModalVisible: false,
        errors:{}
      },
      () => this.handleHideAll()
    );
  };

  handleChangePackageCost = (event) => {
    const target = event.target;
    const selectedPackageCost = this.state.selectedPackageCost;
    this.setState({
      selectedPackageCost: {
        ...selectedPackageCost,
        [target.name]: target.value,
      },
    });
  };

  validate=()=>{
    const errors = {};
    const {startDate ="",cost=null}= this.state.selectedPackageCost;
    const today = moment().format("YYYY-MM-DD");
    const formattedDate=moment(startDate,"YYYY-MM-DD", true)
    console.log("formattedDateformattedDate", formattedDate)
    if (!startDate === null ||
      startDate === undefined ||
      startDate.toString().trim() === "") {
      errors.startDate="Date is required field"
    }
   
   else if(!formattedDate.isSameOrAfter(today)){
     errors.startDate="Date should not be past date"
    }
    if(  cost === null ||
      cost === undefined ||
      cost.toString().trim() === ""){
      errors.costs="Cost is required field"
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleSubmit = async () => {
    const { selectedPackageCost, packageType = "" } = this.state;
    const createdBy = getUserId();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = {
      labId: selectedPackageCost.labId,
      countryId: selectedPackageCost.countryId,
      createdById: parseInt(createdBy),
      labCosts: [
        {
          packageId: selectedPackageCost.packageId,
          cost: parseInt(selectedPackageCost?.cost ?? 0) ?? 0,
          fromDate: selectedPackageCost.startDate,
          packageType: packageType,
        },
      ],
    };

    try {
      this.showLoading();
      const response = await PhoenixAPI.post("lab-cost-save-update", payload);
      this.showSuccessNotification("Cost Updated Successfully");
      this.hideSelectedPackageCostModal();
      this.hideSelectedPackageModal();
      this.handleHideAll(true);
    } catch (error) {
      console.log("Error in Saving Package Cost", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    }
    finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };
  getFilteredSelectedPackageCosts=()=>{
    const { selectedPackageCosts  } = this.state;
    return this.getSearchedOtherItems(selectedPackageCosts);
  }


  getSearchedOtherItems = (selectedPackageCosts) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return selectedPackageCosts;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return selectedPackageCosts.filter((lab) => {
      const { uuid, packageName  } = lab;
      return (
        (packageName && packageName.toLowerCase().indexOf(newValue) !== -1) ||
        (uuid && uuid.toLowerCase().indexOf(newValue)!== -1)
        
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      selectedPackageCosts = [],
      selectedPackageCost,
      selectedPackageCostsLogs = [],
      searchTerm,
      searchApplied,
      errors
    } = this.state;
    // console.log(
    //   "selectedPackageCostsselectedPackageCosts",
    //   selectedPackageCosts
    // );

    const filteredSelectedPackageCosts = this.getFilteredSelectedPackageCosts(selectedPackageCosts)
    const form = (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.packageCostsColumns}
                data={selectedPackageCostsLogs || []}
                pagination
                responsive
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewCost}
                // disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New Cost
              </button>
            </Card>
          </div>
        </div>
      </div>
    );

    const addPackageCostForm = (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <label>Start Date *</label>
            <input
              name="startDate"
              type="date"
              placeholder="start date"
              min={this.state.startDate}
              // disabled={!editConfirmTime}
              className={"form-control py-2" +
              (errors.startDate ? " has-error" : "")}
              onChange={this.handleChangePackageCost}
              value={this.state.startDate}
            ></input>
                        <span className="help-block">{errors.startDate}</span>
          </div>
          <div className="form-group col">
            <label>Add Cost *</label>
            <input
              type="number"
              name="cost"
              className={"form-control py-2" +
              (errors.costs ? " has-error" : "")}
              placeholder="Add Costs"
              onChange={this.handleChangePackageCost}
              value={this.state.cost}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
            ></input>
             <span className="help-block">{errors.costs}</span>
          </div>
        </div>
      </div>
    );
    return (
      <div className="container mt-4 px-0">
          <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}

              ></Search>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <Card body>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={filteredSelectedPackageCosts || []}
                pagination
                responsive
                sortIcon={<ArrowDownward></ArrowDownward>}
                selectableRows
                onSelectedRowsChange={this.handleChange}
                noHeader={searchApplied ? false : true}
                title={
                  searchApplied ? (
                    <p className="table-header">
                      {"Found " + filteredSelectedPackageCosts.length + " results"}
                    </p>
                  ) : (
                    ""
                  )
                }
              />
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isSelectedPackageViewModalVisible}
          onHide={this.hideSelectedPackageModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <div className="user-title">
              Package Name: {selectedPackageCost?.packageName ?? ""}
            </div>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.isAddCostViewModalVisible}
          onHide={this.hideSelectedPackageCostModal}
        >
          <Modal.Header closeButton>
          <Modal.Title> Package Name: {selectedPackageCost?.packageName ?? ""}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{addPackageCostForm}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideSelectedPackageCostModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageLabCost);
