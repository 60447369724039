import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: -1, value: "NONE", label: "NONE" };

WebsiteCategoriesDropdown.propTypes = {
  websiteCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedWebsiteCategory: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

function WebsiteCategoriesDropdown(props) {
  const {
    websiteCategories = [],
    selectedWebsiteCategory,
    onSelect = (f) => f,
  } = props;

  const activeWebsiteCategories = websiteCategories.filter(
    (it) => it.is_active
  );
  activeWebsiteCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const inactiveWebsiteCategories = websiteCategories.filter(
    (it) => !it.is_active
  );
  inactiveWebsiteCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const options = [
    OPTION_NONE,
    {
      label: "Active",
      options: activeWebsiteCategories.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveWebsiteCategories.map(createOption),
    },
  ];
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const websiteCategory =
    selectedWebsiteCategory &&
    websiteCategories.find((it) => it.id === selectedWebsiteCategory);

  return (
    <Select
      key={"website_categories" + websiteCategories.map(it => it.id).join()}
      value={websiteCategory ? createOption(websiteCategory) : OPTION_NONE}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Website Category..."
      isDisabled={props.disabled}
    />
  );
}

const createOption = (websiteCategory) => ({
  key: websiteCategory.id,
  value: websiteCategory.id,
  label: websiteCategory.name,
});

export default WebsiteCategoriesDropdown;
