import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { fetchSupplementOrders, saveOrder } from "services/OrderService";
import Search from "components/Search/Search";
import API from "utils/API";
import { round } from "utils/commons";
import { fetchAdminUsers } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { getOrderStatusWithColor } from "./OrderUtils";
import CreateOrderViewModal from "./CreateOrderViewModal";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import * as XLSX from 'xlsx';
import { Modal, Tabs, Tab } from "react-bootstrap";
import {
  formatDate,
  formatTime,
  formatDateTime,
  compareStrings,
} from "utils/commons";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_CITIES = "cities/";
const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_COUPONS = "coupon-code/summary";

class SupplementOrdersDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      totalRows: 0,
      orders: [],
      cities: [],
      countries: [],
      coupons: [],
      loadError: null,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      isCreateOrderViewModalVisible: false,
      downloadOption:false,
      csvFormat: false,
      xlsFormat: false,
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortField: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name:"Order ID",
        selector:"orderUUID",
        wrap:true
      },
      {
        name: "Purchased On",
        selector: "createdAt",
        sortField: "created_at",
        wrap: true,
        sortable: true,
        format: (order) =>
          order.createdAt
            ? this.getFormattedPurchasedDate(order.createdAt)
            : "-",
      },
      {
        name: "Customer",
        selector: "userDetails.firstName",
        sortField: "userDetails.firstName",
        wrap: true,
        sortable: false,
        format: (order) => this.getUserName(order.userDetails),
      },
      {
        name: "Total Price",
        selector: "finalPrice",
        sortField: "final_price",
        wrap: true,
        sortable: true,
        format: (order) => this.renderTotalPrice(order),
      },
      {
        name: "Payment Mode",
        selector: "transactionalDetails.paymentMethod",
        wrap: true,
        sortable: false,
        format: (order) => order.transactionDetails?.paymentMethod ?? "",
      },
      {
        name: "Operational Status",
        selector: "orderStatus",
        sortField: "order_status",
        // center: true,
        sortable: true,
        minWidth: "150px",
        format: (order) => this.renderOperationalStatus(order),
      },
      {
        name: "Items",
        selector: "itemsCount",
        sortField: "itemsCount",
        wrap: true,
        sortable: false,
        format: (order) => this.renderNumberOfItems(order.itemsCount),
      },
      {
        name: "Action",
        center: true,
        minWidth: "170px",
        cell: (order) => this.renderViewButton(order),
      },
    ];
  }

  renderViewButton = (order) =>
    order && (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          to={`/dashboard/orders/supplement/${order.id}`}
        >
          View
        </Link>
      </div>
    );

  fetchCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      this.setState({ cities });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching cities",
        error
      );
    }
  };

  fetchCountries = async () => {
    try {
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching countries",
        error
      );
    }
  };

  fetchCoupons = async () => {
    try {
      const { data: coupons = [] } = await PhoenixAPI.get(BASE_URL_COUPONS);
      this.setState({ coupons });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching coupons",
        error
      );
    }
  };

  renderNumberOfItems = (itemsCount) => {
    if (itemsCount === null || itemsCount === undefined) return "";
    return `${itemsCount} ${itemsCount > 1 ? "items" : "item"}`;
  };
  renderTotalPrice = (order) => {
    console.log("renderTotalPrice:: order:", order);
    const { finalPrice } = order ?? {};
    const { currency, finalAmount } = order?.transactionDetails ?? {};
    return `${currency ?? ""} ${
      finalAmount ? round(finalAmount) : finalPrice ? round(finalPrice) : ""
    }`;
  };

  renderOperationalStatus = (order) => {
    const { status, color } = getOrderStatusWithColor(order.orderStatus) ?? {};
    return (
      <span
        className={`ui small ${color ?? "green"} label`}
        style={{ fontWeight: "normal" }}
      >
        {status ?? ""}
      </span>
    );
  };

  getUserName = (user) => {
    if (!user) return "";
    const { username, fullName, firstName, lastName, email } = user;
    const name =
      username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
    return `${name} (${email ?? ""})`;
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    // this.setState({ searchApplied: true, searchTerm });
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    this.setState({ searchApplied: true }, () => this.fetchOrders());
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false }, () =>
      this.fetchOrders()
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  fetchOrders = async (page = 1) => {
    console.log("OrdersDashboard: fetchOrders");
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const config = {
      page,
      rowsPerPage,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm: searchTerm ? searchTerm.trim() : searchTerm,
    };
    this.showLoading();
    try {
      const { count: totalRows, results: orders } =
        (await fetchSupplementOrders(config)) || [];
      this.setState({ totalRows, orders });
    } catch (error) {
      this.showErrorNotification("Error on fetching orders: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CART_ORDERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };
  componentDidMount() {
    this.fetchAclUser();

    this.fetchOrders();
    this.fetchCities();
    this.fetchCountries();
    this.fetchCoupons();
  }

  handleSaveOrder = () => {
    console.log("OrdersDashboard: handleSaveOrder");
    this.setState({ isCreateOrderViewModalVisible: false }, () =>
      window.location.reload(false)
    );
  };

  showCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: true });
  };
  hideCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: false });
  };

  handleCreateOrder = () => {
    this.showCreateOrderViewModal();
  };

  handleSort = (column, sortDirection) => {
    console.log("OrdersDashboard:: handleSort:", { column, sortDirection });
    this.setState(
      {
        sortField: column.sortField || column.selector,
        sortDirection: sortDirection,
      },
      () => this.fetchOrders()
    );
  };

  handlePageChange = (page) => {
    console.log("OrdersDashboard:: handlePageChange:", { page });
    this.fetchOrders(page);
  };

  handleRowsPerPageChange = (newRowsPerPage, page) => {
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      newRowsPerPage,
      page,
    });
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchOrders(page)
    );
  };

  mappingOrdersForDownload=(newOrders)=>{
    const mappingOrders=newOrders.map((order)=>{
      const item={
         "Parent Order ID ":order.orderUUID,
         "Confirmed Date":order.createdAt? this.getFormattedPurchasedDate(order.createdAt): "-",
         "Payment status":order?.transactionDetails?.paymentStatus,
          "Discount":order?.discountAmount??0,
          "Subscription Order":order?.orderType=="subscription"?"Yes":"No",
          "Subscription Discount":order?.transactionDetails?.subscriptionDiscount?round(order?.transactionDetails?.subscriptionDiscount):0,
          "Final Amount Paid":order?.finalPrice??0,
          "Client Name":order?.userDetails?.firstName,
          "Shipping Address":order?.shippingAddress,
          "Phone Number":order?.userDetails?.phoneNumber
          
        }
      return item;
    
    })
    return mappingOrders
  }

  downloadCsv=()=>{
    this.setState({downloadOption:true,csvFormat: true,
      xlsFormat: false})
  }
  hideDownloadOptionModal=()=>{
    this.setState({downloadOption:false,csvFormat: false,
      xlsFormat: false,})
  }

  downloadXls=()=>{
    this.setState({downloadOption:true,csvFormat: false,
      xlsFormat: true})
  }
  jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]);
    csvRows.push(headers.join(','));
  
    // Map over each row
    json.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
  
    // Join rows with newline character
    return csvRows.join('\n');
  };

  fetchAllOrders=async( page = 0,
    sortStatus = "id")=>{

      const {
        rowsPerPage = DEFAULT_ROWS_PER_PAGE,
        
        sortDirection,
        searchApplied,
        searchTerm,
        
      } = this.state;
  
      
      const params = {
        ordering: sortDirection == "desc" ? `-${sortStatus}` : sortStatus,
        };
      
  
      if (searchApplied)
        params.searchTerm = searchTerm ? searchTerm.trim() : searchTerm;
  
      
    try{
      this.showLoading();
      const response=await PhoenixAPI.get("/admin/supplemnt-orders/download", {params});
      const data = response?.data || [];
      return data;
    }
    catch(error){
      console.log("Error in Downloading Orders");
      this.showErrorNotification(
        "Some Error occured in Downloading Orders:",
        error.message
      );
    }
    finally {
      this.hideLoading();
    }
  }
  downloadAllRecords=async()=>{
    const {
      csvFormat = false,
      xlsFormat = false,
    } = this.state;
    const allOrders=await this.fetchAllOrders();
   // console.log("all orders are",allOrders);
    if (!allOrders || !Array.isArray(allOrders) || allOrders.length === 0) {
      console.error("No orders found or data is not in expected format.");
      return;
    }

    const mappedOrders=this.mappingOrdersForDownload(allOrders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  
  }

  downloadSelectedRecords=()=>{
    const {
      csvFormat = false,
      xlsFormat = false,
      orders,
    } = this.state;
    const mappedOrders=this.mappingOrdersForDownload(orders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }


  render() {
    const {
      orders,
      cities,
      countries,
      coupons,
      totalRows,
      rowsPerPage,
      searchTerm,
      searchApplied,
      loadError,
      isCreateOrderViewModalVisible,
      isEditable,
    } = this.state;

    const filteredOrders = orders ?? [];

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleCreateOrder}
                disabled={!isEditable}
                style={{ backgroundColor: "#0CA789", color: "white" }}
                className="btn btn-md new-user"
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
        <div className="" style={{display:"flex", gap:"20px", justifyContent:"flex-start",marginLeft:"0px",marginBottom:"20px"}}>
          <div>
          <button
              onClick={(e) => this.downloadCsv()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download CSV
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadXls()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download Excel
            </button>
          </div>
          </div>
        <Card body>
          <DataTable
            data={filteredOrders || []}
            columns={this.columns}
            sortServer
            onSort={this.handleSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={totalRows ?? 0}
            paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + totalRows + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <CreateOrderViewModal
          cities={cities ?? []}
          countries={countries ?? []}
          coupons={coupons ?? []}
          isVisible={isCreateOrderViewModalVisible}
          onHide={this.hideCreateOrderViewModal}
          onSave={this.handleSaveOrder}
          orderType="Supplement Orders"
        />
      <Modal
          size="sm"
          show={this.state.downloadOption}
          onHide={this.hideDownloadOptionModal}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select an option from below</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{display:"flex", flexDirection:"column", gap:"25px",margin:"auto", alignItems:"center"}}>
          <div>
          <button
              onClick={(e) => this.downloadAllRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download all records
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadSelectedRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download selected records
            </button>
          </div>
          </div>
          </Modal.Body>

        </Modal>

      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplementOrdersDashboard);
