import React, { useState, useEffect } from "react";

import PhoenixAPI from "utils/PhoenixAPI";
import { fetchCities } from "services/RegionService";

import SelectCities from "components/Common/SelectCities";
import SelectCategories from "../HomeCarePortal/SelectCategories";
import PdfPreview from "components/Common/PdfPreview";
import "./professionalForm.scss";
import PhoneInput from "react-phone-input-2";
import { fetchProfessionals } from "./Services";
import Notification from "./Notification";
export default function ProfessionalForm(props) {
  const {
    homecareServiceId,
    isProfessionalFormVisible,
    setIsProfessionalFormVisible,
    setIsNewProfessional,
    setIsShowOrder,
    setProfessionalEdit,
    professionalEdit,
    isNewProfessional,
  } = props;
  console.log("homecareServiceId", homecareServiceId, professionalEdit);
  const [professionalDetails, setProfessionalDetails] =
    useState(isNewProfessional?{}:professionalEdit);
  const [cities, setCities] = useState([]);
  const [imageURL1, setImageURL1] = useState("");
  const [imageURL2, setImageURL2] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [licenseBackImage, setLicenseBackImage] = useState("");
  const [licenseFrontImage, setLicenseFrontImage] = useState("");
  const [licenseImageURL1, setLicenseImageURL1] = useState("");
  const [licenseImageURL2, setLicenseImageURL2] = useState("");
  const [error, setError] = useState({});
  const [editables, setEditables] = useState({});
  const BASE_URL_PROFESSIONALS = "/home-care-professional";
  const TEN_MEGA_BYTE = 10485760;
  const validTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/svg+xml",
  ];
  const errMsg = "Please upload only images, docs or pdf ";
  useEffect(() => {
    fetchCitiesData();
    console.log("new professional", isNewProfessional);
  }, []);

  const handleImageChange = (e, data) => {
    const fileName = e.target.files[0];
    const imageURL = URL.createObjectURL(fileName);
    if (!validTypes.includes(fileName.type)) {
      setIsError(true);
      setMessage(errMsg);
    } else {
      if (data === "frontImage") {
        setFrontImage(fileName);
        setImageURL1(imageURL);
      }
      if (data === "frontLicenseImage") {
        setLicenseFrontImage(fileName);
        setLicenseImageURL1(imageURL);
      }
      if (data === "backLicenseImage") {
        setLicenseBackImage(fileName);
        setLicenseImageURL2(imageURL);
      }
      if (data === "backImage") {
        setBackImage(fileName);
        setImageURL2(imageURL);
      }
    }
  };

  const validate = () => {
    const errorsProfessional = {};
    const {
      professionalName,
      whatsappNumber,
      phoneNumber,
      cities,
      category,
      licenseExpiryDate,
      licenseFront,
      licenseBack,
      profEmiratesFrontId,
      profEmiratesBackId,
    } = professionalDetails;
    let selectedEmiratesFront =
    frontImage!==""?frontImage : professionalDetails.profEmiratesFrontId;
    let selectedEmiratesBack =
     backImage!==""? backImage : professionalDetails.profEmiratesBackId;
    let selectedLicenseFront =
     licenseFrontImage!==""? licenseFrontImage : professionalDetails.licenseFront;
    let selectedLicenseBack =
     licenseBackImage!==""? licenseBackImage : professionalDetails.licenseBack;
    if (!professionalName || professionalName.trim() === "")
      errorsProfessional.professionalName =
        "Professional Name cannot be blank!";
    else if (professionalName.length > 200)
      errorsProfessional.professionalName =
        "Professional Name is limited to a 200 characters!";

    if (!whatsappNumber || whatsappNumber.trim() === "")
      errorsProfessional.whatsappNumber = "WhatsApp Number cannot be blank!";

    if (!phoneNumber || phoneNumber.trim() === "")
      errorsProfessional.phoneNumber = "Phone Number cannot be blank!";

    if (!cities || !cities.length)
      errorsProfessional.cities = "Cities cannot be blank!";

    if (!category || !category.length)
      errorsProfessional.category = "Category cannot be blank!";

    if (!licenseExpiryDate)
      errorsProfessional.licenseExpiryDate =
        "License Expiry Date cannot be blank!";

    if (!selectedLicenseFront)
      errorsProfessional.licenseFront = "License - Front cannot be blank!";

    if (!selectedLicenseBack)
      errorsProfessional.licenseBack = "License - Back cannot be blank!";

    if (!selectedEmiratesFront)
      errorsProfessional.profEmiratesFrontId =
        "Professional Emirates ID - Front cannot be blank!";

    if (!selectedEmiratesBack)
      errorsProfessional.profEmiratesBackId =
        "Professional Emirates ID - Back cannot be blank!";

    setError({ ...errorsProfessional });
    console.log("errors outside", errorsProfessional);
    if (Object.keys(errorsProfessional).length > 0) {
      console.log("errors ", errorsProfessional);
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    const errorsProfessional = validate();

    if (errorsProfessional) {
      setIsError(true);

      console.log("errors", errorsProfessional);
      setMessage("There are some invalid form fields!");
      return;
    }
      console.log("license front",professionalDetails,frontImage);
  
    
    let selectedEmiratesFront =
     frontImage!==""? frontImage : professionalDetails.profEmiratesFrontId;
    let selectedEmiratesBack =
     backImage!==""? backImage : professionalDetails.profEmiratesBackId;
    let selectedLicenseFront =
      licenseFrontImage!==""?licenseFrontImage : professionalDetails.licenseFront;
    let selectedLicenseBack =
      licenseBackImage!==""?licenseBackImage : professionalDetails.licenseBack;

    const payload = { ...professionalDetails };

    if (!professionalDetails.id) {
      console.log("homecare service", homecareServiceId);
      payload.homecareId = homecareServiceId;
    }
    if (isNewProfessional) {
      payload.isEnable = true;
    }

    try {
      const formData = new FormData();
      formData.append("professional", JSON.stringify(payload));
      if (typeof selectedLicenseFront!== "string") {
        formData.append("licenseFront", selectedLicenseFront);
      }
      if (typeof selectedLicenseBack !== "string") {
        formData.append("licenseBack", selectedLicenseBack);
      }
      if (typeof selectedEmiratesFront !== "string") {
        formData.append("profEmiratesFrontId", selectedEmiratesFront);
      }
      if (typeof selectedEmiratesBack !== "string") {
        formData.append("profEmiratesBackId", selectedEmiratesBack);
      }
      const config = {
        method: professionalDetails.id ? "PATCH" : "POST",
        url: professionalDetails.id
          ? `${BASE_URL_PROFESSIONALS}/${professionalDetails.id}`
          : BASE_URL_PROFESSIONALS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      //  this.hideModal();

      setMessage("saved succesfully");
      setIsError(false);
      await fetchProfessionals(homecareServiceId);
      setIsProfessionalFormVisible(false);
      setIsNewProfessional(false);
      setIsShowOrder(true);
    } catch (errormsg) {
      console.log(errormsg);
      setIsError(true);
      setMessage("error saving");
    } finally {
    }
  };

  const fetchCitiesData = async () => {
    try {
      //    this.showLoading();
      const cities = (await fetchCities()) ?? [];
      console.log("cities are", cities);
      setCities(cities);
    } catch (error) {
      // this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      //this.hideLoading();
    }
  };

  const renderFrontIdPreview = (userEmiratesFrontUrl) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = imageURL1 || userEmiratesFrontUrl;
    if (!fileSource) return null;

    const file = frontImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };

  const renderFrontLicensePreview = (licenseFront) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = licenseImageURL1 || licenseFront;
    if (!fileSource) return null;

    const file = frontImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };

  const renderBackLicensePreview = (licenseBack) => {
    // const { back_id: fileSource, modalData } = this.state;
    const fileSource = licenseImageURL2 || licenseBack;
    if (!fileSource) return null;
    const file = backImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };

  const renderBackIdPreview = (userEmiratesBackUrl) => {
    // const { back_id: fileSource, modalData } = this.state;
    const fileSource = imageURL2 || userEmiratesBackUrl;
    console.log("checking", userEmiratesBackUrl, imageURL2);
    if (!fileSource) return null;
    const file = backImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };

  const renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editablesUpdate = { ...editables };
      editablesUpdate[field] = true;
      setEditables(editablesUpdate);
    };

    if (isNewProfessional) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  const handleChange = ({ currentTarget: input }) => {
    const professional = { ...professionalDetails };
    professional[input.name] = input.value;
    setProfessionalDetails(professional);
  };

  const handlePhoneNumberChange = async (fieldName, value, data) => {
    console.log("numberCheck:: ", { value, data });
    const { dialCode } = data;
    if (dialCode && value) {
      const professional = { ...professionalDetails };
      professional[fieldName] = `+${dialCode}${value.slice(dialCode.length)}`;
      setProfessionalDetails(professional);
    }
  };

  const handleBackButton = (data) => {
    if (data === "profession") {
      setIsProfessionalFormVisible(false);
      setIsNewProfessional(false);
      // setIsShowUploadSample(false);
      setIsShowOrder(true);
    }
  };

  return (
    <>
      <div className="professional-form">
        <div className="top-header">
          <div className="header-content">
            <div
              className="content"
              onClick={() => handleBackButton("profession")}
            >
              <div className="lt">&lt;</div>
              <div className="header-title"> Add Professional Details</div>
            </div>
          </div>
        </div>

        <div className="form-col">
          <label htmlFor="" className="label">
            Name Professional <span className="star">*</span>
          </label>
          <div className="form-row-edit">
            <input
              type="text"
              value={professionalDetails.professionalName}
              name="professionalName"
              placeholder="Enter name"
              onChange={(e) => handleChange(e)}
              disabled={!isNewProfessional && !editables.professionalName}
            />
            {renderPencilIcon("professionalName")}
          </div>
          {error.professionalName && (
            <div className="error">{error.professionalName}</div>
          )}
        </div>

        <div className="form-col-two">
          <div className="whatsapp">
            <label htmlFor="" className="label">
              Whatsapp Number <span className="star">*</span>{" "}
            </label>

            <div className="form-row-edit">
              <PhoneInput
                value={professionalDetails.whatsappNumber ?? ""}
                onChange={(value, data) =>
                  handlePhoneNumberChange("whatsappNumber", value, data)
                }
                disabled={!isNewProfessional && !editables.whatsappNumber}
                inputStyle={{
                  paddingTop: "18px",
                  paddingBottom: "18px",
                  width: "100%",
                }}
                /*  className={
                  "form-cotrol user-data-input contact-no" +
                    isNewProfessional || editables.whatsappNumber
                    ? " non-editable"
                    : " editable" + errors.whatsappNumber
                    ? " has-error"
                    : ""
                }*/

                autocompleteSearch
                autoFormat
              />
              {renderPencilIcon("whatsappNumber")}
            </div>
            {error.whatsappNumber && (
              <div className="error">{error.whatsappNumber}</div>
            )}
          </div>

          <div className="whatsapp">
            <label htmlFor="" className="label">
              Phone Number <span className="star">*</span>{" "}
            </label>
            <div className="form-row-edit">
              {" "}
              <PhoneInput
                value={professionalDetails.phoneNumber ?? ""}
                onChange={(value, data) =>
                  handlePhoneNumberChange("phoneNumber", value, data)
                }
                disabled={!isNewProfessional && !editables.phoneNumber}
                inputStyle={{
                  paddingTop: "18px",
                  paddingBottom: "18px",
                  width: "100%",
                }}
                /* className={
                  "form-cotrol user-data-input contact-no" +
                    isNewProfessional || editables.phoneNumber
                    ? " non-editable"
                    : " editable" + errors.phoneNumber
                    ? " has-error"
                    : ""
                }*/
                autocompleteSearch
                autoFormat
              />
              {renderPencilIcon("phoneNumber")}
            </div>
            {error.phoneNumber && (
              <div className="error">{error.phoneNumber}</div>
            )}
          </div>
        </div>

        <div className="form-col">
          <label htmlFor="cities">
            Cities <span className="star">*</span>{" "}
          </label>
          <div className="select-div">
            <div className="form-row-edit">
              {" "}
              <SelectCities
                id="cities"
                name="cities"
                cities={cities ?? []}
                selectedCityIds={professionalDetails.cities ?? []}
                onSelect={(options) => {
                  const newProfessional = {
                    ...professionalDetails,
                    cities: options.map((it) => it.value),
                  };
                  setProfessionalDetails(newProfessional);
                }}
                showOnlyActive={true}
                isDisabled={!isNewProfessional && !editables.cities}
              />
              {renderPencilIcon("cities")}
            </div>
          </div>
          {error.cities && <div className="error">{error.cities}</div>}
        </div>
        <div className="form-col">
          <label htmlFor="Categories">
            Categories <span className="star">*</span>
          </label>
          <div className="select-div">
            <div className="form-row-edit">
              {" "}
              <SelectCategories
                id="category"
                name="category"
                selectedCategories={professionalDetails.category ?? []}
                onSelect={(options) => {
                  const newProfessionalCategory = {
                    ...professionalDetails,
                    category: options.map((it) => it.value),
                  };
                  setProfessionalDetails(newProfessionalCategory);
                }}
                isDisabled={!isNewProfessional && !editables.category}
              />
              {renderPencilIcon("category")}
            </div>
          </div>
          {error.category && <div className="error">{error.category}</div>}
        </div>

        <div className="form-col">
          <div className=" containerlicenseLine">
            <span>License </span>
            <div className="line"></div>
          </div>
          <br />

          <label htmlFor="" className="label">
            License Expiry Date <span className="star">*</span>
          </label>
          <div className="form-row-edit">
            {" "}
            <input
              value={professionalDetails?.licenseExpiryDate ?? ""}
              onChange={handleChange}
              id="licenseExpiryDate"
              name="licenseExpiryDate"
              type="date"
              placeholder="License Expiry Date"
              disabled={!isNewProfessional && !editables.licenseExpiryDate}
            />
            {renderPencilIcon("licenseExpiryDate")}
          </div>

          {error.licenseExpiryDate && (
            <div className="error">{error.licenseExpiryDate}</div>
          )}

          <div className="upload-id">
            <div className="id-front">
              <div className="id-text">
                License Front <span className="star">*</span>
              </div>
              <div className="upload-file-box">
                <input
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  type="file"
                  onChange={(e) => handleImageChange(e, "frontLicenseImage")}
                />
                <div className="choose-fileand-icon">
                  <img
                    className="uplooad-icon"
                    src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                    alt=""
                  />
                  <span className="choose-file">Choose File</span>
                </div>
                {renderFrontLicensePreview(professionalDetails.licenseFront)}
              </div>
              {error.licenseFront && (
                <div className="error">{error.licenseFront}</div>
              )}
            </div>
            <div className="id-front">
              <div className="id-text">
                License Back <span className="star">*</span>
              </div>
              <div className="upload-file-box">
                <input
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  type="file"
                  onChange={(e) => handleImageChange(e, "backLicenseImage")}
                />
                <div className="choose-fileand-icon">
                  <img
                    className="uplooad-icon"
                    src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                    alt=""
                  />
                  <span className="choose-file">Choose File</span>
                </div>
                {renderBackLicensePreview(professionalDetails.licenseBack)}
              </div>
              {error.licenseBack && (
                <div className="error">{error.licenseBack}</div>
              )}
            </div>
          </div>

          <div className="upload-id">
            <div className="id-front">
              <div className="id-text">
                Emirates ID Front <span className="star">*</span>
              </div>
              <div className="upload-file-box">
                <input
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  type="file"
                  onChange={(e) => handleImageChange(e, "frontImage")}
                />
                <div className="choose-fileand-icon">
                  <img
                    className="uplooad-icon"
                    src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                    alt=""
                  />
                  <span className="choose-file">Choose File</span>
                </div>
                {renderFrontIdPreview(professionalDetails.profEmiratesFrontId)}
              </div>
              {error.profEmiratesFrontId && (
                <div className="error">{error.profEmiratesFrontId}</div>
              )}
            </div>
            <div className="id-front">
              <div className="id-text">
                Emirates ID Back <span className="star">*</span>
              </div>
              <div className="upload-file-box">
                <input
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  type="file"
                  onChange={(e) => handleImageChange(e, "backImage")}
                />
                <div className="choose-fileand-icon">
                  <img
                    className="uplooad-icon"
                    src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                    alt=""
                  />
                  <span className="choose-file">Choose File</span>
                </div>
                {renderBackIdPreview(professionalDetails.profEmiratesBackId)}
              </div>
              {error.profEmiratesBackId && (
                <div className="error">{error.profEmiratesBackId}</div>
              )}
            </div>
          </div>
        </div>
        <div className="save-button" onClick={(e) => handleSubmit(e)}>
          Submit
        </div>
      </div>

      {message && (
        <Notification
          setMessage={setMessage}
          message={message}
          isError={isError}
        />
      )}
    </>
  );
}
