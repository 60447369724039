import React from "react";
import { Modal } from "react-bootstrap";

function DeleteDraftOrderViewModal(props) {
    const { isVisible = false, onHide = (f) => f, onCancel = (f) => f } = props;
    return (
      <Modal show={isVisible} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to Delete this order?</Modal.Body>
        <Modal.Footer>
          <button onClick={onCancel} className="btn button btn-danger">
            Delete Order
          </button>
          <button className="btn btn-secondary button" onClick={onHide}>
            Exit
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  export default DeleteDraftOrderViewModal;
