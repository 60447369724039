import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import LocalCafeOutlinedIcon from "@material-ui/icons/LocalCafeOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import ProblematicQuestions from "./ProblematicQuestions";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class ManageTests extends Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required."
      },
      {
        field: "name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required."
      },
      {
        field: "description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required."
      },
      {
        field: "description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required."
      },

      {
        field: "test_type",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Type is required."
      },
      // {
      //   field: "price",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Price is required."
      // },
      // {
      //   field: "price",
      //   method: "isFloat",
      //   validWhen: true,
      //   isRtl: false,
      //   min: 0,
      //   message: "Invalid price."
      // }
    ];
    this.validator = new FormValidator(this.formFields);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      name: "",
      name_ar: "",
      description_ar: "",
      description: "",
      price: "",
      validation: this.validator.valid(),
      testList: [],
      activeOrders: [],
      edit_id: null,
      formHeading: "Create Test",
      testCategortList: null,
      // test_category: 1,
      test_category: [],
      arrTestTypeList: [
        {
          text: "Biomarker Based Test",
          value: "B",
          key: "B"
        },
        {
          text: "Food Intolerance Test",
          value: "F",
          key: "F"
        }
      ],
      test_type: "",

      showModal: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      showAlert: true,
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isLoaded: false,
      loadError: "",
      categoryFilter: "All categories"
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true
      },
      {
        name: "Name (Arabic)",
        sortable: true,
        selector: "name_ar",
        right: true
      },
      {
        name: "Category",
        sortable: true,
        selector: "test_category",
        right: true,
        format: (row) =>
          this.returnCategories(row)
      },
      {
        name: "Type",
        center: true,
        sortable: true,
        selector: "test_type",
        format: row =>
          row.test_type === "B" ? (
            <span className="badge badge-violet badge-wide">
              <AssessmentOutlinedIcon></AssessmentOutlinedIcon>
              Biomarker Based
            </span>
          ) : (
            <span className="badge badge-green badge-wide">
              <LocalCafeOutlinedIcon></LocalCafeOutlinedIcon>Food Intolerance
            </span>
          )
      },
      {
        name: "Price",
        sortable: true,
        selector: "price",
        format: row => row.price === null || row.price === undefined ? "" : "AED " + row.price,
        maxWidth: "100px"
      },

      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {row.test_type === "B" ? (
                <ActionBar.Item
                  onClick={() =>
                    this.manageMarkers(row.id, row.test_type, row.editAllowed)
                  }
                >
                  <AssessmentOutlinedIcon></AssessmentOutlinedIcon>
                  Manage Biomarkers
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  onClick={() =>
                    this.manageMarkers(row.id, row.test_type, row.editAllowed)
                  }
                >
                  <LocalCafeOutlinedIcon></LocalCafeOutlinedIcon>
                  Manage Foods
                </ActionBar.Item>
              )}
              <ActionBar.Item onClick={() => this.editTest(row.index)}>
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.saveTest = this.saveTest.bind(this);
    this.updateTest = this.updateTest.bind(this);
    this.formReset = this.formReset.bind(this);
    this.editTest = this.editTest.bind(this);
    this.manageMarkers = this.manageMarkers.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.BIOMARKER_LIST;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  returnCategories(row) {
    if (this.state.testCategortList) {
      let temp = ''
      row.test_category.map((item2, index) => {
        if (this.state.testCategortList.filter((item3) => item3.key == item2).length) {
          temp = temp + this.state.testCategortList.filter((item3) => item3.key == item2)[0].text;
          if (index != row.test_category.length - 1) {
            temp = temp + ', '
          }
        }
      })
      return temp
    }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  validate = () => {
    const errors = {};
    const { price } = this.state;
    if (price && Number.isNaN(Number(price)))
      errors.price = "Invalid Price!";
    
    return Object.keys(errors).length === 0 ? null : errors;
  }

  saveTest() {
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (!errors && validation.isValid) {
      const {
        name,
        name_ar,
        description,
        description_ar,
        price,
        test_type,
        test_category
      } = this.state;

      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });

      API.post("tests/", {
        name,
        name_ar,
        description_ar,
        description,
        price: price || null,
        test_type,
        test_category
      })
        .then(response => {
          this.loadData();
          this.props.showNotificationMessage({
            notificationMessage: "Test successfully created.",
            successMessage: true,
            showNotification: true
          });
          this.props.toggleLoading({
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on creating test", error);
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });

          this.props.toggleLoading({
            isLoading: false
          });
        });
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }
  updateTest() {
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      const {
        name,
        name_ar,
        description,
        description_ar,
        price,
        edit_id,
        test_type,
        test_category
      } = this.state;

      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });

      API.put("tests/" + edit_id + "/", {
        name,
        name_ar,
        description_ar,
        description,
        price: price || null,
        test_type,
        test_category
      })
        .then(response => {
          this.loadData();
          this.props.showNotificationMessage({
            notificationMessage: "Test successfully updated.",
            successMessage: true,
            showNotification: true
          });
          this.props.toggleLoading({
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on updating test", error);
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });

          this.props.toggleLoading({
            isLoading: false
          });
        });
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }

  hideModal() {
    this.setState({ showModal: false });
    this.formReset();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Test";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Test successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Test";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Test successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  formReset() {
    this.submitted = false;
    this.setState({
      name: "",
      name_ar: "",
      description_ar: "",
      description: "",
      price: "",
      validation: this.validator.valid(),
      edit_id: null,
      test_type: "",
      formHeading: "Create Test",
      showAlert: true,
      errors: {},
      test_category: [],
    });
    this.props.showNotificationMessage({
      notificationMessage: "",
      successMessage: true,
      showNotification: false
    });
  }

  editTest(index) {
    const { testList } = this.state;
    this.setState({
      name: testList[index].name,
      name_ar: testList[index].name_ar,
      description: testList[index].description,
      description_ar: testList[index].description_ar,
      price: testList[index].price,
      edit_id: testList[index].id,
      test_type: testList[index].test_type,
      test_category: testList[index].test_category,
      formHeading: "Edit Test",
      showAlert: false
    });

    this.showModal();
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("tests/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });
        this.loadData();
      })
      .catch(error => {
        console.log("Error on toggling status of test", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  editAllowed(orders, testId, testType) {
    for (let order of orders) {
      for (let item of order.order_items) {
        if (item.test === testId && item.test_type === testType) {
          return false;
        }
      }
    }

    return true;
  }

  manageMarkers(testId, testType, editAllowed) {
    this.props.manageMarkers({ testId: testId, editAllowed: editAllowed });

    if (testType === "B")
      this.props.history.push("/dashboard/tests-and-packages/tests/biomarkers");
    else
      this.props.history.push(
        "/dashboard/tests-and-packages/tests/food-intolerance"
      );
  }

  getTestCategories() {
    API.get("test-category/")
      .then((response3) => {
        let categoryList = response3.data
        categoryList = filterList(categoryList, 'active')
        let temp = []
        categoryList.map((item) => {
          temp.push({
            text: item.name,
            value: item.id,
            key: item.id
          })
        })
        this.setState({
          testCategortList: temp
        })
      }).catch((err) => {
        console.error("Error fetching test categories!", err);
      })
  }

  loadData() {
    let activeOrders = [];
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });

    // API.get("order-list/")
    //   .then(response => {
    //     const orderData = response.data;
    //     for (let order of orderData.results) {
    //       if (order.order_status !== "COMPLETED") activeOrders.push(order);
    //     }
        
       
    //   })
    //   .catch(error => {
    //     console.log("Error fetching order list from the backend!", error);
    //     this.setState({
    //       loadError: "Some error has occured. Please try again"
    //     });

    //     this.props.showNotificationMessage({
    //       notificationMessage: "Some error has occured. Please try again.",
    //       successMessage: false,
    //       showNotification: true
    //     });

    //     this.props.toggleLoading({
    //       isLoading: false
    //     });
    //   });

      this.getTestCategories();

      API.get("tests/")
        .then(response2 => {
          let testList = response2.data;

          for (let test of testList) {
            test.editAllowed = true;
            // this.editAllowed(
            //   activeOrders,
            //   test.id,
            //   test.test_type
            // );
          }

          const activeList = filterList(testList, "active");
          const archivedList = filterList(testList, "archived");

          this.setState({
            testList: testList,
            activeList,
            archivedList,
            isLoaded: true,
            // nextURL: orderData.next
          });

          if (searchApplied) this.handleSearch(searchTerm);

          this.props.toggleLoading({
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error fetching tests from backend!", error);
          this.setState({
            loadError: "Some error has occured. Please try again"
          });

          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });
          this.props.toggleLoading({
            isLoading: false
          });
        });
  }

  componentDidMount() {
    this.fetchAclUser();
    this.props.manageMarkers({ testId: null });
    this.loadData();
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.testList;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.name_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  filterCategory(filter) {
    this.setState({
      categoryFilter: filter
    })
  }

  render() {
    let finalList = [];

    const {
      testList,
      showAlert,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      categoryFilter,
      errors = {},
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        // if (showItems === "Active") {
        //   finalList = activeList;
        //   if (categoryFilter != 'All categories') {
        //     finalList = finalList.filter((item) => item.test_category.includes(categoryFilter));
        //   }
        // }
        // else if (showItems === "Archived") {
        //   finalList = archivedList;
        //   if (categoryFilter != 'All categories') {
        //     finalList = finalList.filter((item) => item.test_category == categoryFilter);
        //   }
        // }
        // else {
        //   //all
        //   finalList = testList;
        //   if (categoryFilter != 'All categories') {
        //     finalList = finalList.filter((item) => item.test_category == categoryFilter);
        //   }
        // }

        finalList = testList;
        if (categoryFilter !== 'All categories') {
          finalList = finalList.filter((item) => item.test_category === categoryFilter);
        }
      }
    }

    const form = (
      <>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Name (English) *</label>

            <input
              type="text"
              name="name"
              className={
                "form-control " + (validation.name.isInvalid && "has-error")
              }
              placeholder="Name in English"
              onChange={this.handleChange}
              value={this.state.name}
            ></input>
            <span className="help-block">{validation.name.message}</span>
          </div>
          <div className="form-group col">
            <label>Name (Arabic) *</label>
            <input
              type="text"
              name="name_ar"
              className={
                "form-control input-arabic " +
                (validation.name_ar.isInvalid && "has-error")
              }
              placeholder="Name in Arabic"
              onChange={this.handleChange}
              value={this.state.name_ar}
            ></input>
            <span className="help-block">{validation.name_ar.message}</span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Description (English) *</label>
            <textarea
              type="text"
              name="description"
              className={
                "form-control " +
                (validation.description.isInvalid && "has-error")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.description}
            ></textarea>
            <span className="help-block">{validation.description.message}</span>
          </div>
          <div className="form-group col">
            <label>Description (Arabic) *</label>
            <textarea
              type="text"
              name="description_ar"
              className={
                "form-control input-arabic " +
                (validation.description_ar.isInvalid && "has-error")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.description_ar}
            ></textarea>
            <span className="help-block">
              {validation.description_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-6">
            <label>Type *</label>
            <Dropdown
              value={this.state.test_type}
              disabled={this.state.edit_id ? true : false}
              className={
                "form-control " +
                (validation.test_type.isInvalid && " has-error")
              }
              placeholder="Select type"
              onChange={(event, value) => {
                this.setState({ test_type: value.value });
              }}
              search
              selection
              options={
                this.state.edit_id
                  ? this.state.arrTestTypeList.filter(
                    option => option.value === this.state.test_type
                  )
                  : this.state.arrTestTypeList
              }
            />
            <span className="help-block">{validation.test_type.message}</span>
          </div>
          <div className="form-group col-6">
            {/* <label>Category *</label> */}
            <label>Category</label>
            <Dropdown
              multiple
              fluid
              value={this.state.test_category}
              // disabled={this.state.edit_id ? true : false}
              className={
                "form-control " 
                // + (validation.test_type.isInvalid && " has-error")
              }
              placeholder="Select type"
              onChange={(event, value) => {
                this.setState({ test_category: value.value });
              }}
              search
              selection
              options={this.state.testCategortList}
            />
            {/* <span className="help-block">{validation.test_type.message}</span> */}
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col-2">
            {/* <label>Price (AED) *</label> */}
            <label>Price (AED)</label>
            <input
              type="number"
              name="price"
              className={"form-control" + (errors.price ? " has-error" : "")}
              onKeyPress={(event) => {
                if (Number.isNaN(Number(this.state.price.toString() + event.key)))
                  event.preventDefault();
              }}
              placeholder="Price"
              onChange={this.handleChange}
              value={this.state.price}
            ></input>
            <span className="help-block">{errors.price}</span>
          </div>
        </div>
        {showAlert ? (
          <Alert
            variant="warning"
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            dismissible
            className="mt-2"
          >
            You can't change the type once the test is created.
          </Alert>
        ) : null}
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
       <div className="manage-test">
        <div className="page-header">
          {/* <h5>Manage Tests</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.categoryFilter === 'All categories' ? 'All Categories' : this.state.testCategortList.filter((item) => item.value == this.state.categoryFilter)[0].text}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={categoryFilter === "All categories" ? "active" : ""}
                    onClick={() => this.filterCategory("All categories")}
                  >
                    {categoryFilter === "All categories" ? <CheckIcon></CheckIcon> : null}All categories
                  </ActionBar.Item>
                  {this.state.testCategortList && this.state.testCategortList.map((item, index) => {
                    return (
                      <ActionBar.Item key={index}
                        className={categoryFilter === item.value ? "active" : ""}
                        onClick={() => this.filterCategory(item.key)}
                      >
                        {categoryFilter === item.value ? <CheckIcon></CheckIcon> : null}
                        {item.text}
                      </ActionBar.Item>
                    )
                  })}
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.showModal}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card>
          {this.state.testCategortList &&
            <DataTable
              highlightOnHover
              columns={this.columns}
              data={finalList}
              // defaultSortField="name"
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + resultCount + " results in '" + showItems + "'"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          }
        </Card>

        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal} className="user-data-modal">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
            <button
              onClick={this.state.edit_id ? this.updateTest : this.saveTest}
              className="btn button button-green"
              style={{ background: "#cee741", color: "black", width: "100px", border: "none", fontWeight: "700" }}
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          
         
            {/* <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button> */}
    
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <ProblematicQuestions isEditable={isEditable}/>
      </>
     
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value }),
    manageMarkers: value => dispatch({ type: "MANAGE_MARKERS", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTests);
