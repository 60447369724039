import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "../../../components/AlertBox/AlertBox";
import CountryDropdown from "../../../components/Common/CountryDropdown";
import { fetchCountries } from "services/RegionService";
import Select from "react-select";
import moment from "moment";
import { getClientByEmailOnCartOrders } from "services/UserService";
import { saveOtherItem, updateOtherItem } from "services/OtherItemService";
import SelectCoach from "../SelectCoach";
import ToggleSwitch from "../../../components/Common/ToggleSwitch";
import { getUserId, fetchCoachUsers } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import API from "utils/API";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../../components/Common/AccessDenied";
import PdfPreview from "components/Common/PdfPreview";
import EditResultsViewModal from "./EditResultsViewModal";
import Pdf from "@material-ui/icons/PictureAsPdf";
import { formatDate } from "utils/commons";
import * as XLSX from 'xlsx';

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_CITIES = "cities/";
const BASE_URL_CREATE_ORDER = "admin/create-order/";

class ClientUploadResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      uploadResults: [],
      uploadPDFFiles: [],
      createOrderResult: null,
      cartItems: [],
      selectedCountry: null,
      selectedClient: null,
      selectedClientAddress: null,
      errors: {},
      editables: {},
      isCreateOrderModal: false,
      isEditOrderModal: false,
      isAppVisibility: false,
      emailSearchTerm: null,
      viewOrderResult: {},
      images: [],
      cities: [],
      savedFiles: [],
      savedOrder: {},
      wellbeingCoaches: [],
      selectedCoachId: null,
      editOrderResult: {},
      editOrderResultFiles: [],
      uploadedStatusResults: [],
      isAccepted: false,
      downloadOption: false,
      csvFormat: false,
      xlsFormat: false,
      uploadPendingFiles: false,
      uploadCompletedFiles: false,
    };

    this.columns = [
      {
        name: "Updated Date",
        selector: "test_date",
        sortable: true,
        format: (row) => (row.test_date ? formatDate(row.test_date) : "-"),
      },
      {
        name: "Client Name",
        selector: "client_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Email Id",
        selector: "email",
        wrap: true,
        sortable: true,
      },
      {
        name: "No of Files",
        selector: "no_of_files",
        wrap: true,
        sortable: true,
      },

      {
        name: "Details",
        center: true,
        cell: (result) => this.renderViewButton(result.user_id),
      },
    ];

    this.uploadCompletedColumns = [
      {
        name: "Client Upload Date",
        selector: "test_date",
        sortable: true,
        format: (row) => (row.test_date ? formatDate(row.test_date) : "-"),
      },
      {
        name: "Client Name",
        selector: "client_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Email Id",
        selector: "email",
        wrap: true,
        sortable: true,
      },
      {
        name: "Order Id",
        selector: "order_id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Result Upload Date / Status",
        selector: "status",
        wrap: true,
        sortable: true,
      },
    ];
  }

  async componentDidMount() {
    this.fetchAclUser();
    await this.fetchCountries();
    this.fetchAppVisibility();
    this.fetchWellbeingCoaches();
    this.fetchResults();
    this.fetchStatusResults();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OTHER_ITEMS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry }, () =>
        this.fetchCities()
      );
    } catch (error) {
      console.log("fetchCountries:: Error on fetching countries!", error);
    }
  };

  fetchCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      const activeCities = cities.filter((item) => item.is_active === true);
      const selectedCountryCities = activeCities.map((it) => {
        const { id: countryId = null } = it.country;
        if (countryId == this.state.selectedCountry.id) {
          return it;
        }
      });
      const filteredCountryCities = selectedCountryCities.filter(
        (it) => it !== undefined || null
      );
      this.setState({ cities: filteredCountryCities });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching cities",
        error
      );
    }
  };

  fetchAppVisibility = async () => {
    const { selectedCountry } = this.state;
    try {
      const { data: appVisibilityDetails = {} } = await PhoenixAPI.get(
        `user-own-tests/api/v2/app-visibility/${selectedCountry.id}`
      );
      console.log(
        "appVisibilityDetailsappVisibilityDetails",
        appVisibilityDetails
      );
      const isAppVisibility =
        appVisibilityDetails.isSelfResultUploadEnabled ?? false;
      this.setState({ isAppVisibility });
    } catch (error) {
      console.log("Error in fetchAppVisibility", error.message);
    }
  };

  fetchWellbeingCoaches = async () => {
    try {
      const wellbeingCoaches = (await fetchCoachUsers()) || [];
      this.setState({ wellbeingCoaches });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching wellbeing coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry, isAppVisibility: false }, () => {
      this.fetchResults();
      this.fetchStatusResults();
      this.fetchAppVisibility();
      this.fetchCities();
    });

  fetchResults = async () => {
    this.showLoading();

    const { selectedCountry } = this.state || {};
    try {
      const { data: uploadResults = [] } =
        (await PhoenixAPI.get(`user-own-tests/api/v2/${selectedCountry.id}`)) ||
        [];

      this.setState({ uploadResults });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching otheritems: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchStatusResults = async () => {
    const { selectedCountry } = this.state || {};
    try {
      this.showLoading();
      const { data: uploadedStatusResults = [] } =
        (await PhoenixAPI.get(
          `user-own-tests/api/v2/status/${selectedCountry.id}`
        )) || [];

      this.setState({ uploadedStatusResults });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching  Uploaded Results Status: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
  handleSearchClientsByEmail = () => this.fetchClientUsersByEmail();

  findClient = (clientId) => {
    const { clients = [] } = this.state;
    return clients.find((client) => client.id === clientId);
  };

  createClientAddressOptions = () => {
    const { selectedClient, selectedCountry } = this.state;
    const user_address = selectedClient?.user_address ?? [];
    const filteredAddress = user_address.filter(
      (item) => item.country === selectedCountry.country_name
    );

    const clientAddresses = filteredAddress ?? [];
    const clientAddressOptions = clientAddresses.map(
      this.createClientAddressOption
    );
    this.setState({ clientAddressOptions });
  };
  createClientAddressOption = (clientAddress) => {
    if (!clientAddress) return;
    const { id, label = null, city = "", country = "" } = clientAddress;
    const showingCity =
      (label && label) || (city && city) || (country && country);
    return {
      key: id,
      value: id,
      label: showingCity,
    };
  };

  handleClientSelected = (selectedClientId) => {
    const selectedClient = this.findClient(selectedClientId);
    this.setState({ selectedClient, selectedClientAddress: null }, () =>
      this.createClientAddressOptions()
    );
  };

  findCity = (cityName) => {
    const { cities = [] } = this.state;
    return cities.find((city) =>
      [city.city_name, city.city_name_ar].includes(cityName)
    );
  };

  fetchCityPackage = async () => {
    const { selectedCity, selectedCountry } = this.state;
    let packageId = null;
    if (selectedCountry.country_code === "AE") {
      packageId = 268;
    } else if (selectedCountry.country_code === "SA") {
      packageId = 245;
    }
    if (!selectedCity) return;
    try {
      this.showLoading();
      const { data: packageDetails = [] } = await API.get(
        `subscription-packages/${packageId}/`
      );
      this.setState({ packageDetails });
      console.log("packageDetailspackageDetails", packageDetails);
    } catch (error) {
      console.log("Error in fetching Package Details", error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleClientAddressSelected = (selectedClientAddressId) => {
    const { selectedClient, cities } = this.state;
    const clientAddresses = selectedClient?.user_address ?? [];
    const selectedClientAddress = clientAddresses.find(
      (it) => it.id === selectedClientAddressId
    );
    const { city: cityName = null } = selectedClientAddress || {};
    const selectedCity = this.findCity(cityName);
    this.setState({ selectedClientAddress, selectedCity }, () => {
      this.fetchCityPackage();
    });
  };

  fetchClientUsersByEmail = async () => {
    const { emailSearchTerm, isEditOrderModal } = this.state;

    try {
      this.showLoading();
      const clients = await getClientByEmailOnCartOrders(emailSearchTerm);

      if (!isEditOrderModal) {
        this.setState({ clients: clients ?? [] }, () =>
          this.createClientOptions()
        );
      } else {
        const selectedClient = clients[0];
        this.setState({ clients: clients ?? [], selectedClient }, () => {
          this.createClientOptions();
          this.createClientAddressOptions();
        });
      }
    } catch (error) {
      console.log("Error on fetching client users by email:", error);
    } finally {
      this.hideLoading();
    }
  };

  createClientOptions = () => {
    const { clients = [] } = this.state;
    const clientOptions = clients.map(this.createClientOption);
    this.setState({ clientOptions });
  };

  createClientOption = (client) => {
    if (!client) return;
    const { username, first_name, last_name, email } = client;
    const name =
      username || `${first_name || ""} ${last_name || ""}`.trim() || "";
    return {
      key: client.id,
      value: client.id,
      label: `${email} (${name})`,
    };
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
      const image = this.createNewImage();
      image.image = imageFile;
      this.readImage(image, imageFile);
      e.target.value = null;
    }
  };

  createNewImage = () => {
    const { images = [] } = this.state;
    const image = {};
    const ids = images.length > 0 ? images.map((it) => it.id) : null;
    image.id = ids ? Math.max(...ids) + 1 : 1;
    return image;
  };

  readImage = (image, imageFile) => {
    const { images } = this.state;
    if (!imageFile) return;
    const reader = new FileReader();
    reader.onloadend = function () {
      const newImage = {
        ...image,
        image_src: reader.result,
      };

      this.setState({ images: [...images, newImage] });
    }.bind(this);
    if (imageFile?.type?.startsWith("image/")) {
      reader.readAsDataURL(imageFile);
    } else if (imageFile?.type === "application/pdf") {
      reader.readAsArrayBuffer(imageFile);
    }
  };

  handleCreatingNewOrder = () => {
    this.setState(
      { isNewUploadResultFile: true, createOrderResult: { uploadDate: "" } },
      () => this.showCreatOrderModal()
    );
  };

  handleViewUploadResult = async (userId) => {
    const { uploadResults } = this.state;
    const { data: uploadPDFFiles = [] } = await PhoenixAPI.get(
      `user-own-tests/api/v2/user-reports/${userId}`
    );
    const clientDetails = uploadResults.filter(
      (item) => item.user_id === userId
    );
    const clientDetail = clientDetails[0];

    const updatedResultFiles = uploadPDFFiles.map((file, index) => {
      return {
        ...file,
        isAccepted: false,
        isRejected: false,
        rejectReason: "",
        index: index,
      };
    });
    this.setState(
      {
        uploadPDFFiles: updatedResultFiles,
        editOrderResult: clientDetail,
        emailSearchTerm: clientDetail.email,
      },
      () => {
        this.showEditModal();
      }
    );
  };

  showCreatOrderModal = () => {
    this.setState({ isCreateOrderModal: true });
  };

  showEditModal = () => {
    const { selectedCountry, isNewUploadResultFile } = this.state;

    this.setState({ isEditOrderModal: true }, () =>
      this.fetchClientUsersByEmail()
    );
  };

  hideCreateModal = () => {
    this.setState({ isCreateOrderModal: false });
    this.resetForm();
  };

  hideEditModal = () => {
    this.setState({ isEditOrderModal: false });
    this.resetForm();
  };

  validate = () => {
    const errors = {};
    const {
      selectedClientAddress,
      createOrderResult,
      editOrderResult,
      selectedClient,
      selectedCoachId,
      isCreateOrderModal,
      isEditOrderModal,
      images,
    } = this.state;
    if (isCreateOrderModal) {
      if (!createOrderResult.uploadDate) {
        errors.uploadDate = "Upload date cannot be blank";
      }
      if (!selectedClient) {
        errors.selectedClient = "Please select a client";
      }
    }
    if (isEditOrderModal) {
      if (!editOrderResult.test_date) {
        errors.test_date = "Upload date cannot be blank";
      }
    }
    if (!selectedClientAddress) {
      errors.selectedClientAddress = "Please Select Client Address";
    }
    if (!selectedCoachId) {
      errors.selectedCoach = "Please Select Coach";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  resetForm = () => {
    this.setState({
      emailSearchTerm: null,
      selectedClient: null,
      selectedClientAddress: null,
      clientOptions: [],
      clientAddressOptions: [],
      cartItems: [],
      createOrderResult: null,
      selectedCoachId: null,
      editOrderResultFiles: [],
      errors: {},
      editOrderResult: null,
      clients: [],
      images: [],
      downloadOption: false,
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewUploadResult(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewUploadResultFile ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderPdfResults = () => {
    const { images } = this.state;
    return images.map((item, index) => {
      const file = item.image;
      const fileSource = item.image_src;
      const isPdfFile =
        file?.type === "application/pdf" ||
        (typeof fileSource === "string" &&
          fileSource.toLowerCase().endsWith(".pdf"));
      const preview = isPdfFile ? (
        <div className="image-area" style={{ marginBottom: "30px" }}>
          <PdfPreview
            pdfFile={isPdfFile ? fileSource : null}
            pdfFileUrl={isPdfFile ? null : fileSource}
          />
          <button
            onClick={(e) => this.handleDeleteImage(item)}
            disabled={false}
            className="remove-image"
            style={{ display: "inline" }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      ) : (
        <div className="image-area" style={{ marginBottom: "30px" }}>
          <img
            id="selected_front_image"
            name="selected_front_image"
            src={fileSource}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
          <button
            onClick={(e) => this.handleDeleteImage(item)}
            disabled={false}
            className="remove-image"
            style={{ display: "inline" }}
          >
            <i className="fa fa-times"></i>
          </button>
        </div>
      );

      return <>{preview}</>;
    });
  };

  handleDeleteImage = (item) => {
    const { images } = this.state;
    const filteredImages = images.filter((it) => it.id !== item.id);
    this.setState({ images: filteredImages });
  };

  handleAcceptedFiles = async () => {
    const {
      savedFiles = [],
      selectedClient,
      selectedCountry,
      isEditOrderModal,
      editOrderResultFiles,
    } = this.state;

    let mappedFiles = [];
    if (!isEditOrderModal) {
      mappedFiles = savedFiles.map((file) => {
        const fileObject = {
          id: file.id,
          userId: file.userId,
          isAccepted: true,
          isRejected: false,
          rejectReason: null,
        };
        return fileObject;
      });
    } else {
      mappedFiles = editOrderResultFiles ?? [];
      // mappedFiles = editOrderResultFiles.map((file) => {
      //   const fileObject = {
      //     id: file.id,
      //     userId: file.userId,
      //     isAccepted: file.isAccepted,
      //     rejectReason: file.rejectReason,
      //   };
      //   return fileObject;
      // });
    }

    try {
      this.showLoading();
      const response = await PhoenixAPI.patch(
        `user-own-tests/api/v2/accept-reports/${selectedCountry.id}/${selectedClient.id}`,
        mappedFiles
      );
      this.setState({ isAccepted: true });
      this.showSuccessNotification("Accepted reports successfully");
      // return response
    } catch (error) {
      console.log("Error in Accepting Files", error.message);
      this.showErrorNotification("Error in Accepting Files", error.messagw);
    } finally {
      this.hideLoading();
    }
  };

  handleSubmitFiles = async () => {
    const formData = new FormData();
    const {
      selectedClient,
      createOrderResult,
      selectedCountry,
      images,
      isAccepted,
    } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    if (images && images.length === 0) {
      this.showErrorNotification("Please Upload atleast one PDF Result !");
      return;
    }
    const details = {
      userId: selectedClient.id,
      testDate: createOrderResult.uploadDate ?? "",
      country: selectedCountry.id,
      notes: "uploadResults",
    };

    if (images && images.length > 0) {
      formData.append("details", JSON.stringify(details));

      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        formData.append("test_results_files", image.image);
      }

      const apiOptions = this.createApiOptions(
        "post",
        "user-own-tests/upload",
        formData
      );
      try {
        this.showLoading();
        const { data: savedFiles = [] } = await PhoenixAPI.request(apiOptions);
        this.setState({ savedFiles }, () => this.handleAcceptedFiles());
        this.showSuccessNotification(
          "Uploaded files are accepted successfully"
        );
        this.hideCreateModal();
        this.fetchResults();
        console.log("Response: ", savedFiles);
      } catch (error) {
        console.log("Error in Uploading Results", error.message);
        this.showLoading("Error occurred in Uploaded files", error.message);
      } finally {
        this.hideLoading();
      }
    }
  };

  createApiOptions = (method, url, formData) => ({
    method,
    url,
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });

  handleAppVisibilityChange = async () => {
    const { isAppVisibility, selectedCountry } = this.state || {};

    try {
      const response = await PhoenixAPI.patch(
        `user-own-tests/self-result-upload-toggled/${selectedCountry.id}/${isAppVisibility}`
      );
    } catch (error) {
      console.log("Error in Updating App Visibility", error.message);
    }
  };

  handleSaveResult = async () => {
    const {
      selectedCountry,
      savedFiles,
      savedOrder,
      selectedCoachId,
      selectedClient,
      isEditOrderModal,
    } = this.state;

    const orderId = savedOrder?.order_id;
    if (!orderId || !selectedCoachId) {
      console.log("Error in Updating Order", orderId);
      return;
    }

    try {
      this.showLoading();
      let url = `user-own-tests/api/v2/assign-order-id/${orderId}/${selectedCoachId}/${selectedCountry.id}/${selectedClient.id}`;
      const response = await PhoenixAPI.patch(url);

      console.log("Response: ", response);
      if (isEditOrderModal) {
        this.hideEditModal();
      } else {
        this.hideCreateModal();
      }
      this.fetchResults();
    } catch (error) {
      console.log("Error in Saving Result", error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleOrderCreate = async () => {
    const orderCreatedById = getUserId();
    const {
      selectedClient,
      selectedClientAddress,
      selectedCountry,
      selectedCity,
      packageDetails,
      isCreateOrderModal,
      isEditOrderModal,
      createOrderResult,
      editOrderResult,
    } = this.state;
    let packageId = null;
    if (selectedCountry.country_code === "AE") {
      packageId = 268;
    } else if (selectedCountry.country_code === "SA") {
      packageId = 245;
    }

    const { internal_name, price } = packageDetails || [];
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const { city, country } = selectedClientAddress ?? {};
    const clientName = selectedClient.username
      ? selectedClient.username
      : `${selectedClient.first_name} ${selectedClient.last_name}`;
    const description = `${clientName}, MULTIPLE_ITEM, No Coupon`;
    const vatPercentage = selectedCountry
      ? Number(selectedCountry.vat_percentage)
      : null;
    let appointmentDate = "";
    if (isCreateOrderModal) {
      let date =
        createOrderResult?.uploadDate ||
        moment().add(1, "day").format("YYYY-MM-DD");
      appointmentDate = moment(date).add(1, "day").format("YYYY-MM-DD");
    } else if (isEditOrderModal) {
      let date =
        editOrderResult?.test_date ||
        moment().add(1, "day").format("YYYY-MM-DD");
      appointmentDate = moment(date).add(1, "day").format("YYYY-MM-DD");
    }
    try {
      this.showLoading();
      const payload = {
        orderFlow: "Current",
        orderType: "one-time",
        userId: selectedClient?.id,
        addressId: selectedClientAddress?.id,
        countryCode: selectedCountry?.country_code,
        countryId: selectedCountry?.id,
        cityId: selectedCity?.id,
        description: description,
        paymentMode: "COD",
        paymentGateway: "COD",
        totalAmount: price,
        cashbackAmount: null,
        finalAmount: price,
        currency: selectedCountry?.currency,
        orderSource: "ADMIN_PANEL",
        orderCategory: "CART",
        couponApplied: false,
        couponCode: null,
        discount: null,
        paymentType: "Regular",
        orderCreatedById: orderCreatedById,
        taxAmount: 4.5,
        shippingAmount: 0,
        cartItems: [
          {
            itemType: "Blood_Package",
            quantity: 1,
            itemId: packageId,
            itemName: internal_name,
            selectedSlot: "06:00-07:00",
            selectedDate: appointmentDate,
            price: price,
            finalPrice: price,
            addOnMiniPackagesPrice: 0,
          },
        ],
        customerCity: city,
        transactionReference: "Upload Results PDF",
        selfResultUpload: true,
        deliveryCharges: 0,
        nurseVisitFee: 0,
        additionalDiscount: 0,
        additionalDicountNote: "",
        isWalletBalanceUsed: false,
        walletAmount: null,
      };
      const { data: savedOrder = {} } = await PhoenixAPI.post(
        BASE_URL_CREATE_ORDER,
        payload
      );
      console.log("saveOrder:", savedOrder);
      this.setState({ savedOrder }, () => this.handleSaveResult());
    } catch (error) {
      console.log("Error in Creating Order", error.message);
      this.showErrorNotification("Error on create order " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleEditOrderCreate = async () => {
    const {
      selectedClient,
      selectedCountry,
      isEditOrderModal,
      editOrderResultFiles,
    } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    if (isEditOrderModal) {
      if (editOrderResultFiles && editOrderResultFiles.length === 0) {
        this.showErrorNotification("Please select atleast one PDF Result !");
        return;
      }
    }
    return Promise.all([this.handleAcceptedFiles(), this.handleOrderCreate()]);
  };

  handleViewResultPdfStatus = (selectedFiles) => {
    const mappedFiles =
      selectedFiles.length > 0 &&
      selectedFiles.map((file) => {
        const obj = {
          userId: file.user_id,
          id: file.user_test_id,
          isAccepted: file?.isRejected ? false : file?.isAccepted || false,
          isRejected: file?.isRejected,
          rejectReason: file?.rejectReason || null,
        };
        return obj;
      });
    this.setState({ editOrderResultFiles: mappedFiles });
  };

  handleDeleteCompleteUpload = async () => {
    const {
      editOrderResultFiles = [],
      selectedCountry,
      editOrderResult,
    } = this.state;
    if (editOrderResultFiles.length === 0) {
      this.showErrorNotification("Please select PDF Results!");
      return;
    }
    if (editOrderResultFiles.length > 0) {
      try {
        this.showLoading();
        const response = await PhoenixAPI.patch(
          `user-own-tests/api/v2/accept-reports/${selectedCountry.id}/${editOrderResult.user_id}`,
          editOrderResultFiles
        );

        this.showSuccessNotification("Deleted successfully");
        this.hideEditModal();
        this.fetchResults();
      } catch (error) {
        console.log("Error in acceptedFiles", error.message);
        this.showErrorNotification(
          "Error on deleting the results" + error.message
        );
      } finally {
        this.hideLoading();
      }
    }
  };

  mappedPendingFiles = (uploadResults) => {
    const mappedFiles =
      uploadResults.length > 0 &&
      uploadResults.map((file) => {
        const obj = {
          UserId: file.user_id,
          Name: file.client_name,
          "No of Files": file.no_of_files,
          "Test Date": formatDate(file.test_date),
        };
        return obj;
      });
    return mappedFiles;
  };

  mappedCompletedFiles = (uploadedStatusResults) => {
    const mappedFiles =
      uploadedStatusResults.length > 0 &&
      uploadedStatusResults.map((file) => {
        const obj = {
          "Client Upload Date": formatDate(file.test_date),
          "Client Name": file.client_name,
          "Email Id": file.email,
          "Order Id": file.order_id,
          "Result Upload Date / Status": file.status,
        };
        return obj;
      });
    return mappedFiles;
  };
  downloadCsv = (dataFiles) => {
  
    if (dataFiles === "uploadPendingFiles") {
      this.setState({
        downloadOption: true,
        csvFormat: true,
        xlsFormat: false,
        uploadPendingFiles: true,
        uploadCompletedFiles: false,
      });
    } else if (dataFiles === "uploadCompletedFiles") {
      this.setState({
        downloadOption: true,
        csvFormat: true,
        xlsFormat: false,
        uploadPendingFiles: false,
        uploadCompletedFiles: true,
      });
    }
  };
  hideDownloadOptionModal = () => {
    this.setState({
      downloadOption: false,
      csvFormat: false,
      xlsFormat: false,
      uploadPendingFiles: false,
      uploadCompletedFiles: false,
    });
  };

  downloadXls = (dataFiles) => {
    if (dataFiles === "uploadPendingFiles") {
      this.setState({
        downloadOption: true,
        csvFormat: false,
        xlsFormat: true,
        uploadPendingFiles: true,
        uploadCompletedFiles: false,
      });
    } else if (dataFiles === "uploadCompletedFiles") {
      this.setState({
        downloadOption: true,
        csvFormat: false,
        xlsFormat: true,
        uploadPendingFiles: false,
        uploadCompletedFiles: true,
      });
    }
  };
  jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]);
    csvRows.push(headers.join(','));
  
    // Map over each row
    json.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
  
    // Join rows with newline character
    return csvRows.join('\n');
  };

  downloadAllRecords = () => {
    const {
      csvFormat = false,
      xlsFormat = false,
      uploadPendingFiles = false,
      uploadCompletedFiles = false,
      uploadResults,
      uploadedStatusResults,
    } = this.state;

    if (csvFormat) {
      if (uploadPendingFiles) {
        const mappedFiles = this.mappedPendingFiles(uploadResults);
        console.log("uploadPendingFiles", mappedFiles);
        if (mappedFiles.length > 0) {
          const csvContent = this.jsonToCsv(mappedFiles);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.hideDownloadOptionModal()
          this.showSuccessNotification("File is Downloaded successfully")
        } else {
          return;
        }
      } else if (uploadCompletedFiles) {
        const mappedFiles = this.mappedCompletedFiles(uploadedStatusResults);
        if (mappedFiles.length > 0) {
          const csvContent = this.jsonToCsv(mappedFiles);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.hideDownloadOptionModal()
          this.showSuccessNotification("File is Downloaded successfully")
        } else {
          return;
        }
      }
    } else if (xlsFormat) {
      if (uploadPendingFiles) {
        const mappedFiles = this.mappedPendingFiles(uploadResults);

    if (mappedFiles.length > 0) {
      const worksheet = XLSX.utils.json_to_sheet(mappedFiles);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
      XLSX.writeFile(workbook, 'data.xlsx');
      this.hideDownloadOptionModal()
      this.showSuccessNotification("File is Downloaded successfully")
    }
    else{
      return;
    }
        
      } else if (uploadCompletedFiles) {
        const mappedFiles = this.mappedCompletedFiles(uploadedStatusResults);
        const worksheet = XLSX.utils.json_to_sheet(mappedFiles);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else{
        return;
      }
      
    }
  };

  downloadSelectedRecords = () => {
    const {
      csvFormat = false,
      xlsFormat = false,
      uploadPendingFiles = false,
      uploadCompletedFiles = false,
      uploadResults,
      uploadedStatusResults,
    } = this.state;
    if (csvFormat) {
      if (uploadPendingFiles) {
        const mappedFiles = this.mappedPendingFiles(uploadResults);
        if (mappedFiles.length > 0) {
          const csvContent = this.jsonToCsv(mappedFiles);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.hideDownloadOptionModal()
          this.showSuccessNotification("File is Downloaded successfully")
        } else {
          return;
        }
      } else if (uploadCompletedFiles) {
        const mappedFiles = this.mappedCompletedFiles(uploadedStatusResults);
        if (mappedFiles.length > 0) {
          const csvContent = this.jsonToCsv(mappedFiles);
          const blob = new Blob([csvContent], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.hideDownloadOptionModal()
          this.showSuccessNotification("File is Downloaded successfully")
        } else {
          return;
        }
      }
    } else if (xlsFormat) {
      if (uploadPendingFiles) {
        const mappedFiles = this.mappedPendingFiles(uploadResults);
        if (mappedFiles.length > 0) {
          const worksheet = XLSX.utils.json_to_sheet(mappedFiles);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'data.xlsx');
          this.hideDownloadOptionModal()
        }
        else{
          return;
        }
      } else if (uploadCompletedFiles) {
        const mappedFiles = this.mappedCompletedFiles(uploadedStatusResults);
        if (mappedFiles.length > 0){
          const worksheet = XLSX.utils.json_to_sheet(mappedFiles);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'data.xlsx');
          this.hideDownloadOptionModal()
        }
        else{
          return;
        }
      }
    }
  };

  handlePageChangeUploadResults = (page) => {
    const {rowsPerPage, uploadResults}= this.state;
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = uploadResults.slice(startIndex, endIndex);
     this.setState({uploadResults:displayedData})
  };

  handleRowsPerPageChangeUploadResults = (rowsPerPage, page) => {
    const {uploadResults}= this.state;
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      rowsPerPage,
      page,
    });
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = uploadResults.slice(startIndex, endIndex);
    this.setState({rowsPerPage,uploadResults:displayedData})
  };

  handlePageChangeCompletedResults = (page) => {
    const {rowsPerPage, uploadedStatusResults}= this.state;
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = uploadedStatusResults.slice(startIndex, endIndex);
     this.setState({uploadedStatusResults:displayedData})
  };

  handleRowsPerPageChangeCompletedResults = (rowsPerPage, page) => {
    const {uploadedStatusResults}= this.state;
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      rowsPerPage,
      page,
    });
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const displayedData = uploadedStatusResults.slice(startIndex, endIndex);
    this.setState({rowsPerPage,uploadedStatusResults:displayedData})
  };

  render() {
    const {
      otheritem,
      isNewUploadResultFile,
      countries,
      selectedCountry,
      selectedClient,
      loadError,
      errors,
      editables,
      isEditable,
      uploadResults,
      emailSearchTerm,
      clientOptions,
      createOrderResult,
      selectedClientAddress,
      clientAddressOptions,
      wellbeingCoaches,
      editOrderResult,
      uploadPDFFiles,
      uploadedStatusResults,
      isAccepted,
    } = this.state;
    const { city, country } = selectedClientAddress ?? {};
    const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

    const noAddressFoundMessage = (
      <p>
        There are no addresses saved for the user account - please go through
        user account management and add an address.
      </p>
    );

    const formAddressInfo = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label>Select Address *</label>
            <Select
              key={`select_client_address_${selectedClient?.id ?? -1}`}
              value={this.createClientAddressOption(selectedClientAddress)}
              onChange={(option) =>
                this.handleClientAddressSelected(option.value)
              }
              options={clientAddressOptions ?? []}
              styles={selectStyles}
              placeholder="Select Address"
            />
            <span className="help-block">{errors.selectedClientAddress}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="city">City</label>
            <input
              value={city ?? ""}
              type="text"
              id="city"
              name="city"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={country ?? ""}
              type="text"
              id="country"
              name="country"
              readOnly={true}
              className="form-control py-2"
            />
          </div>
        </div>
      </>
    );

    const formCreateOrderInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label>Upload Date *</label>
            <input
              value={createOrderResult && createOrderResult.uploadDate}
              onChange={(e) =>
                this.setState({
                  createOrderResult: {
                    ...createOrderResult,
                    uploadDate: e.target.value,
                  },
                })
              }
              type="date"
              id="uploadDate"
              name="uploadDate"
              className={
                "form-control" + (errors.uploadDate ? " has-error" : "")
              }
            />

            <span className="help-block">{errors.uploadDate}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Email *</label>
            <div className="input-group mb-3">
              <input
                value={emailSearchTerm ?? ""}
                onChange={(e) =>
                  this.setState({ emailSearchTerm: e.target.value })
                }
                type="text"
                id="emailSearchTerm"
                name="emailSearchTerm"
                className="form-control"
                placeholder="Search Email"
              />
              <div className="input-group-append">
                <i
                  className="fa fa-search input-group-text"
                  onClick={this.handleSearchClientsByEmail}
                />
              </div>
            </div>
          </div>
          <div className="form-group col">
            <label>Select Email *</label>
            <Select
              key={`select_client_email_${selectedClient?.id ?? -1}`}
              value={this.createClientOption(selectedClient)}
              onChange={(option) => this.handleClientSelected(option.value)}
              options={clientOptions ?? []}
              styles={selectStyles}
              placeholder="Select Email"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="Client Name">Client Name *</label>
            <div className="input-group">
              <input
                value={selectedClient?.username || ""}
                id="client_name"
                name="client_name"
                type="text"
                // onKeyPress={(event) => {
                //   if (!/[0-9]/.test(event.key)) event.preventDefault();
                // }}
                placeholder="Client Name"
                className={"form-control py-2"}
              />
              {/* <span className="help-block">
                {errors.customTestPriceAdditional}
              </span> */}
            </div>
          </div>
        </div>
        {selectedClient
          ? selectedClient.user_address && selectedClient.user_address.length
            ? formAddressInfo
            : noAddressFoundMessage
          : null}
        <div className="row">
          <div className="form-group col">
            <input
              type="file"
              onChange={this.handleSelectImage}
              id={`ImageInput`}
              name="image"
              accept="image/*, application/pdf"
              // disabled={!this.props.isEditable ?? true}
              className="form-control custom-file-input"
            />

            <label
              htmlFor={`ImageInput`}
              className="btn btn-success px-2 py-1"
              style={{ cursor: "pointer" }}
            >
              Upload File
            </label>
          </div>
          <div className="form-group col-6">{this.renderPdfResults()}</div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label>Coach Name *</label>
            <SelectCoach
              coaches={wellbeingCoaches ?? []}
              selectedCoachId={this.state.selectedCoachId}
              onCoachSelect={(coachId) =>
                this.setState({ selectedCoachId: coachId })
              }
            />
            <span className="help-block">{errors.selectedCoach}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <button
              onClick={this.handleSubmitFiles}
              disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Upload Accepted Files
            </button>
          </div>
          <div className="form-group col">
            <button
              onClick={this.handleOrderCreate}
              disabled={!isEditable || !isAccepted}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Create Order For Accepted Files
            </button>
          </div>
        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label>Upload Date *</label>
            <input
              value={editOrderResult && editOrderResult.test_date}
              onChange={(e) =>
                this.setState({
                  editOrderResult: {
                    ...editOrderResult,
                    test_date: e.target.value,
                  },
                })
              }
              type="date"
              id="test_date"
              name="test_date"
              className={
                "form-control" + (errors.test_date ? " has-error" : "")
              }
            />

            <span className="help-block">{errors.test_date}</span>
          </div>
          <div className="form-group col">
            <label>Client Name </label>
            <input
              value={editOrderResult && editOrderResult.client_name}
              type="client_name"
              id="client_name"
              name="client_name"
              className={
                "form-control" + (errors.client_name ? " has-error" : "")
              }
              disabled={true}
            />

            <span className="help-block">{errors.client_name}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Client Email</label>
            <input
              value={editOrderResult && editOrderResult.email}
              type="email"
              id="email"
              name="email"
              className={"form-control" + (errors.email ? " has-error" : "")}
              disabled={true}
            />

            <span className="help-block">{errors.email}</span>
          </div>
          <div className="form-group col">
            <label>No of Files</label>
            <input
              value={editOrderResult && editOrderResult.no_of_files}
              type="no_of_files"
              id="no_of_files"
              name="no_of_files"
              className={
                "form-control" + (errors.no_of_files ? " has-error" : "")
              }
              disabled={true}
            />

            <span className="help-block">{errors.no_of_files}</span>
          </div>
        </div>
        {selectedClient
          ? selectedClient.user_address && selectedClient.user_address.length
            ? formAddressInfo
            : noAddressFoundMessage
          : null}
        <div className="row">
          <div className="form-group col-6">
            <label>Coach Name *</label>
            <SelectCoach
              coaches={wellbeingCoaches ?? []}
              selectedCoachId={this.state.selectedCoachId ?? null}
              onCoachSelect={(coachId) =>
                this.setState({ selectedCoachId: coachId })
              }
            />
            <span className="help-block">{errors.selectedCoach}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">Uploaded Files</div>
          <div className="form-group col">Accept</div>
          <div className="form-group col">Reject</div>
          <div className="form-group col">Reject Reason If any</div>
        </div>
        <div className="row">
          <div className="form-group col">
            <EditResultsViewModal
              uploadPDFFiles={uploadPDFFiles}
              handleViewResultPdfStatus={(items) =>
                this.handleViewResultPdfStatus(items)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <button
              onClick={this.handleEditOrderCreate}
              disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Create Order For Accepted Files
            </button>
          </div>
          <div className="form-group col">
            <button
              onClick={this.handleDeleteCompleteUpload}
              disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Delete Complete Upload
            </button>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item" style={{ gap: "12px" }}>
              APP Visibility
              <div className="" style={{ marginTop: "12px" }}>
                <ToggleSwitch
                  key={this.state.isAppVisibility}
                  id={`toggle-${null}`}
                  toggled={this.state.isAppVisibility ?? false}
                  onToggle={(isToggled) =>
                    this.setState({ isAppVisibility: isToggled }, () =>
                      this.handleAppVisibilityChange()
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="page-header">
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              marginLeft: "60px",
            }}
          >
            <div>
              <button
                onClick={(e) => this.downloadCsv("uploadPendingFiles")}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Download CSV
              </button>
            </div>
            <div>
              <button
                onClick={(e) => this.downloadXls("uploadPendingFiles")}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Download Excel
              </button>
            </div>
          </div>
          <div className="actions">
            <div className="action-item">
              <button
                onClick={this.handleCreatingNewOrder}
                disabled={!isEditable}
                style={{ backgroundColor: "#0CA789", color: "white" }}
                className="btn btn-md new-user"
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
        <Card body className="mt-2">
          <p className="font-weight-bold">Upload Pending</p>
          <DataTable
            data={uploadResults || []}
            columns={this.columns}
            defaultSortField="test_date"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChangeUploadResults}
            onChangeRowsPerPage={this.handleRowsPerPageChangeUploadResults}
            highlightOnHover
            pagination
            responsive
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <div className="page-header">
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "flex-end",
              marginLeft: "60px",
            }}
          >
            <div>
              <button
                onClick={(e) => this.downloadCsv("uploadCompletedFiles")}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Download CSV
              </button>
            </div>
            <div>
              <button
                onClick={(e) => this.downloadXls("uploadCompletedFiles")}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Download Excel
              </button>
            </div>
          </div>
          <div className="actions"></div>
        </div>

        <Card body className="mt-2">
          <p className="font-weight-bold">Upload Completed</p>
          <DataTable
            data={uploadedStatusResults ?? []}
            columns={this.uploadCompletedColumns}
            sortIcon={<ArrowDownward></ArrowDownward>}
            defaultSortField="test_date"
            defaultSortAsc={false}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChangeCompletedResults}
            onChangeRowsPerPage={this.handleRowsPerPageChangeCompletedResults}
            highlightOnHover
            pagination
            responsive
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isCreateOrderModal}
          onHide={this.hideCreateModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>{formCreateOrderInformation}</Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={this.state.isEditOrderModal}
          onHide={this.hideEditModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>{formDescriptions}</Modal.Body>
        </Modal>
        <Modal
          size="sm"
          show={this.state.downloadOption}
          onHide={this.hideDownloadOptionModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select an option from below</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                margin: "auto",
                alignItems: "center",
              }}
            >
              <div>
                <button
                  onClick={(e) => this.downloadAllRecords()}
                  id="show_orders"
                  className="btn px-4"
                  style={{ backgroundColor: "#0CA789", color: "white" }}
                >
                  Download all records
                </button>
              </div>
              <div>
                <button
                  onClick={(e) => this.downloadSelectedRecords()}
                  id="show_orders"
                  className="btn px-4"
                  style={{ backgroundColor: "#0CA789", color: "white" }}
                >
                  Download selected records
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientUploadResults);
