import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import RichTextEditor from "react-rte";
import Status from "components/Common/Status";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import SelectArticleCategory from "./SelectArticleCategory";
import SelectSupplements from "pages/CouponCode/SelectSupplements";
import {
  fetchArticles,
  fetchAuthors,
  fetchArticleCategories,
  saveArticle,
  saveSearchTagDocument,
} from "services/HealthHubService";
import { fetchCountries } from "services/RegionService";
import { getUserId, getUserName } from "services/UserService";
import {
  fetchBloodPackages,
  fetchCustomPackages,
} from "services/productService";
import SelectArticles from "./SelectArticles";
import SelectAuthor from "./SelectAuthor";
import HealthHubImages from "./HealthHubImages";
import SelectBloodPackages from "components/Common/SelectBloodPackages";
import SelectCustomPackages from "components/Common/SelectCustomPackages";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TRENDING_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Yes",
  },
  {
    key: false,
    value: false,
    text: "No",
  },
];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const TWO_MEGA_BYTE = 2_097_152;

class Articles extends Component {
  constructor(props) {
    super(props);

    this.loggedInUserId = Number(getUserId());
    this.loggedInUserName = getUserName();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      articles: [],
      authors: [],
      articleCategories: [],
      bloodPackages: [],
      article: {},
      articleContentEnglishRichTextValue: RichTextEditor.createEmptyValue(),
      articleContentArabicRichTextValue: RichTextEditor.createEmptyValue(),
      users: [],
      countries: [],
      rankOptions: [],
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      selectedCountry: null,
      isNewArticle: false,
      showModal: false,
      isSaving: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "articleNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "articleNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (article) => (
          <p style={{ textAlign: "right" }}>{article.articleNameArabic}</p>
        ),
      },
      {
        name: "Category",
        selector: "healthHubCategoryName",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Author",
        selector: "authorName",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (article) => <Status isActive={article.status === "Active"} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (article) => this.renderViewButton(article.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchAclUser(),
      this.fetchCountries(),
      this.fetchAuthors(),
      this.fetchArticleCategories(),
      this.fetchBloodPackages(),
      this.fetchCustomPackages(),
    ]);
    this.fetchArticles();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.ARTICLES;
      const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
      const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
      const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification("Error on fetching countries!", error.message);
    }
  };

  fetchAuthors = async () => {
    try {
      const authors = (await fetchAuthors()) ?? [];
      this.setState({ authors });
    } catch (error) {
      this.showErrorNotification("Error on fetching authors!", error.message);
    }
  };

  fetchArticleCategories = async () => {
    try {
      this.showLoading();
      const articleCategories = (await fetchArticleCategories()) ?? [];
      articleCategories.forEach((it) => {
        delete it.healthHub;
        return it;
      });
      this.setState({ articleCategories });
    } catch (error) {
      this.showErrorNotification(
        "Error on loading article categories: ",
        error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  fetchBloodPackages = async () => {
    try {
      const bloodPackages = await fetchBloodPackages();
      this.setState({ bloodPackages: bloodPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching blood packages", error);
    }
  };

  fetchCustomPackages = async () => {
    try {
      const customPackages = await fetchCustomPackages();
      this.setState({ customPackages: customPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching custom packages", error);
    }
  };

  fetchArticles = async () => {
    try {
      this.showLoading();
      const articles = (await fetchArticles()) ?? [];
      articles.forEach((article) => {
        const category = this.findArticleCategory(article.healthHubCategory);
        article.healthHubCategoryName = category
          ? category.categoryNameEnglish
          : "";
        const author = this.findAuthor(article.author);
        article.authorName = author
          ? `${author.first_name ?? ""} ${author.last_name ?? ""}`.trim()
          : "";
      });
      this.setState({ articles });
    } catch (error) {
      this.showErrorNotification("Error on loading articles: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const article = { ...this.state.article };
    article[input.name] = input.value;
    this.setState({ article });
  };

  handleArticleContentEnglishRichTextChange = (value) => {
    console.log("handleArticleContentEnglishRichTextChange:: value:", value);
    this.setState({ articleContentEnglishRichTextValue: value });
  };

  handleArticleContentArabicRichTextChange = (value) => {
    console.log("handleArticleContentArabicRichTextChange:: value:", value);
    this.setState({ articleContentArabicRichTextValue: value });
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSelectPrimaryImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const primaryImage = e.target.files[0];
      const article = { ...this.state.article, primaryImage };
      this.setState({ article });
      this.readImage(primaryImage);
    }
  };

  handleImagesUpdated = (images = []) => {
    this.setState({ images });
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const article = {
        ...this.state.article,
        primaryImageSrc: [reader.result],
      };
      this.setState({ article });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleAddNew = () => {
    this.setState({ isNewArticle: true }, () => this.showModal());
  };

  handleView = (id) => {
    const articles = this.state.articles || [];
    const article = articles.find((it) => it.id === id);
    if (article) {
      const articleContentEnglishRichTextValue =
        RichTextEditor.createValueFromString(
          article.articleContentEnglish ?? "",
          "html"
        );
      const articleContentArabicRichTextValue =
        RichTextEditor.createValueFromString(
          article.articleContentArabic ?? "",
          "html"
        );
      this.setState(
        {
          isNewArticle: false,
          article: {
            ...article,
            primaryImageSrc: article.primaryImage,
          },
          articleContentEnglishRichTextValue,
          articleContentArabicRichTextValue,
        },
        () => this.showModal()
      );
    }
  };

  showModal = () => {
    const { isNewArticle, selectedCountry } = this.state;
    const rankOptions = this.createRankOptions();
    if (isNewArticle) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const article = {
        isTrending: false,
        status: "Inactive",
        rank,
        country: selectedCountry.id,
        searchDocId:Date.now(),
        healthHubArticles: [],
        suggestedSupplements: [],
      };
      this.setState({
        article,
        rankOptions,
        showModal: true,
      });
    } else {
      if(this.state.article.searchDocId==null)
        {
      const article={...this.state.article,
        searchDocId:Date.now()
      }
      this.setState({article,rankOptions,showModal: true})
    }
    else{
      this.setState({ rankOptions, showModal: true });
    }
    }
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      this.setState({ isSaving: false });
      return;
    }

    const { article } = this.state;
    article.meta_title_arabic=article.meta_title_arabic||article.metaTitle
    article.meta_description_arabic=article.meta_description_arabic||article.metaDescription
    article.keywords_arabic=article.keywords_arabic||article.keywords
    if (article) {
      const {
        articleContentEnglishRichTextValue,
        articleContentArabicRichTextValue,
      } = this.state;
      try {
        article.articleContentEnglish =
          articleContentEnglishRichTextValue?.toString("html");
        article.articleContentArabic =
          articleContentArabicRichTextValue?.toString("html");
      } catch (error) {
        console.log("handleSave:: Error on setting content", error);
        article.articleContentEnglish = "";
        article.articleContentArabic = "";
      }
    }

    const errors = this.validate(article);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    

    try {
      this.showLoading();
      const response=await saveArticle(article);
      
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      await this.SearchTagDocument(response,article,this.state.selectedCountry.id);
      this.fetchArticles();
    } catch (error) {
      console.log("handleSave:: Error on saving article category", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  SearchTagDocument=async(response,article,country)=>{
    try{
     console.log("response in searchtag",article) 
   await saveSearchTagDocument(response,article,country);
  
    
    }
    catch(error)
    {
      console.log("handleSave::Error on update or create  Article Search doc",error);
      //this.showErrorNotification("Some error occured in document"+error); 
    }
  }

  validate = (article) => {
    const errors = {};
    const {
      author,
      articleNameEnglish,
      articleNameArabic,
      articleDescriptionEnglish,
      articleDescriptionArabic,
      timeToRead,
      healthHubCategory,
      primaryImage,
      rank,
      isTrending,
      status,
      seoUrl,
      metaTitle,
      metaDescription,
      keywords,
      searchTags,
    } = article;

    if (author === null || author === undefined)
      errors.author = "Author is a required field!";

    if (!articleNameEnglish || articleNameEnglish.trim() === "")
      errors.articleNameEnglish = "Name (English) is a required field!";
    else if (articleNameEnglish.length > 100)
      errors.articleNameEnglish =
        "Name (English) is limited to a 100 characters!";

    if (!articleNameArabic)
      errors.articleNameArabic = "Name (Arabic) is a required field!";
    else if (articleNameArabic.length > 100)
      errors.articleNameArabic =
        "Name (Arabic) is limited to a 100 characters!";

    if (!articleDescriptionEnglish)
      errors.articleDescriptionEnglish =
        "Description (English) is a required field!";
    else if (articleDescriptionEnglish.length > 20_000)
      errors.articleDescriptionEnglish =
        "Description (English) is limited to a 20000 characters!";

    if (!articleDescriptionArabic)
      errors.articleDescriptionArabic =
        "Description (Arabic) is a required field!";
    else if (articleDescriptionArabic.length > 20_000)
      errors.articleDescriptionArabic =
        "Description (Arabic) is limited to a 20000 characters!";

    if (!timeToRead) errors.timeToRead = "Time to Read is a required field!";
    else if (timeToRead.length > 100)
      errors.timeToRead = "Time to Read is limited to a 100 characters!";

    if (healthHubCategory == null || healthHubCategory === undefined)
      errors.healthHubCategory = "Category is a required field!";

    if (rank === null || rank === undefined)
      errors.rank = "Rank is a required field!";

    if (isTrending === null || isTrending === undefined)
      errors.isTrending = "Is Trending is a required field!";

    if (status === null || status === undefined)
      errors.status = "Status is a required field!";

    if (!primaryImage)
      errors.primaryImage = "Primary image is a required field!";
    else if (
      primaryImage &&
      primaryImage.size &&
      primaryImage.size > TWO_MEGA_BYTE
    )
      errors.primaryImage = "Primary image should be less than 2 MB!";

    if (!seoUrl || seoUrl.trim() === "")
      errors.seoUrl = "Seo URL is a required field!";
    else if (seoUrl.length > 100)
      errors.seoUrl = "Seo URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > 100)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > 200)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.keywords = "keywords can not contain more than 500 characters!";
    if (searchTags && searchTags.length > 2000)
      errors.searchTags = "tags can not contain more than 2000 characters!";
    return Object.keys(errors).length === 0 ? null : errors;
  };

  createApiOptions = (method, url) => {
    const formData = this.createFormData();
    return {
      method,
      url,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
  };

  createFormData = () => {
    const { isNewArticleCategory, articleCategory, selectedCountry } =
      this.state;
    const formData = new FormData();

    if (isNewArticleCategory)
      formData.append("country", selectedCountry ? selectedCountry.id : null);

    formData.append("name", articleCategory.name);
    formData.append("name_arabic", articleCategory.name_arabic);
    formData.append("rank", articleCategory.rank);
    formData.append("is_active", articleCategory.is_active);

    return formData;
  };

  resetForm = () => {
    this.setState({
      isNewArticle: false,
      isSaving: false,
      article: {},
      errors: {},
      editables: {},
      articleContentEnglishRichTextValue: RichTextEditor.createEmptyValue(),
      articleContentArabicRichTextValue: RichTextEditor.createEmptyValue(),
      images: [],
    });
  };

  createRankOptions = () => {
    const { isNewArticle } = this.state;
    const articles = this.getArticlesForCurrentCountry();
    const ranks = articles.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, articles.length) || 0;
    const until = isNewArticle ? maxRank + 1 : maxRank;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((it) => it.id === id);
  };

  findArticleCategory = (id) => {
    const articleCategories = this.state.articleCategories || [];
    return articleCategories.find((it) => it.id === id);
  };

  findAuthor = (id) => {
    const authors = this.state.authors || [];
    return authors.find((it) => it.id === id);
  };

  getListForSelectingSuggestedArticles = () => {
    const { articles, selectedCountry, isNewArticle, article } = this.state;
    if (articles && selectedCountry) {
      const articlesForCountry = articles.filter(
        (it) => it.country === selectedCountry.id
      );
      return isNewArticle
        ? articlesForCountry
        : articlesForCountry.filter((it) => it.id !== article?.id);
    }
    console.log(
      "getListForSelectingSuggestedArticles:: Inavlid articles or country",
      { articles, selectedCountry }
    );
    return [];
  };

  getFilteredArticles = () => {
    const articlesForCurrentCountry = this.getArticlesForCurrentCountry();
    return this.getSearchedArticles(articlesForCurrentCountry);
  };

  getArticlesForCurrentCountry = () => {
    const { articles, selectedCountry } = this.state;
    return articles && selectedCountry
      ? articles.filter((it) => it.country === selectedCountry.id)
      : [];
  };

  getSearchedArticles = (articles) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return articles;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return articles.filter((article) => {
      const { id, articleNameEnglish, articleNameArabic, rank } = article;
      return (
        (articleNameEnglish &&
          articleNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (articleNameArabic &&
          articleNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    if (this.state.isNewArticle || !this.state.isEditable) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  render() {
    const {
      isNewArticle,
      article,
      articleCategories,
      authors,
      countries,
      bloodPackages,
      customPackages,
      selectedCountry,
      images,
      editables,
      errors,
      searchTerm,
      isEditable,
    } = this.state;

    const bloodPackagesForSelectedCountry =
      bloodPackages && selectedCountry
        ? bloodPackages.filter((it) => it.country === selectedCountry.id)
        : [];
    const customPackagesForSelectedCountry =
      customPackages && selectedCountry
        ? customPackages.filter((it) => it.country === selectedCountry.id)
        : [];

    const articles = this.getFilteredArticles();
    console.log("render:: Articles: ", articles);

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="articleNameEnglish">Name (English)*</label>
            <div className="input-group">
              <input
                value={article.articleNameEnglish}
                onChange={this.handleChange}
                id="articleNameEnglish"
                name="articleNameEnglish"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" +
                  (errors.articleNameEnglish ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.articleNameEnglish}
              />
              {this.renderPencilIcon("articleNameEnglish")}
            </div>
            <span className="help-block">{errors.articleNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="articleNameArabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={article.articleNameArabic}
                onChange={this.handleChange}
                id="articleNameArabic"
                name="articleNameArabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.articleNameArabic ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.articleNameArabic}
              />
              {this.renderPencilIcon("articleNameArabic")}
            </div>
            <span className="help-block">{errors.articleNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="articleDescriptionEnglish">
              Description (English)*
            </label>
            <div className="input-group">
              <textarea
                value={article.articleDescriptionEnglish}
                onChange={this.handleChange}
                id="articleDescriptionEnglish"
                name="articleDescriptionEnglish"
                placeholder="Description in English"
                className={
                  "form-control py-2" +
                  (errors.articleDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewArticle && !editables.articleDescriptionEnglish}
              />
              {this.renderPencilIcon("articleDescriptionEnglish")}
            </div>
            <span className="help-block">
              {errors.articleDescriptionEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="articleDescriptionArabic">
              Description (Arabic)*
            </label>
            <div className="input-group">
              <textarea
                value={article.articleDescriptionArabic}
                onChange={this.handleChange}
                id="articleDescriptionArabic"
                name="articleDescriptionArabic"
                placeholder="Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.articleDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewArticle && !editables.articleDescriptionArabic}
              />
              {this.renderPencilIcon("articleDescriptionArabic")}
            </div>
            <span className="help-block">
              {errors.articleDescriptionArabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="timeToRead">Time to Read*</label>
            <div className="input-group">
              <input
                value={article.timeToRead}
                onChange={this.handleChange}
                id="timeToRead"
                name="timeToRead"
                type="text"
                placeholder="Time to read"
                className={
                  "form-control py-2" + (errors.timeToRead ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.timeToRead}
              />
              {this.renderPencilIcon("timeToRead")}
            </div>
            <span className="help-block">{errors.timeToRead}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="isTrending">Is Trending?*</label>
            <div className="input-group">
              <Dropdown
                value={article.isTrending}
                onChange={(event, value) => {
                  this.setState({
                    article: {
                      ...article,
                      isTrending: value.value,
                    },
                  });
                }}
                id="isTrending"
                name="isTrending"
                placeholder="Is Trending?"
                search
                selection
                options={TRENDING_OPTIONS}
                disabled={!isNewArticle && !editables.isTrending}
                className={
                  "form-control" + (errors.isTrending ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isTrending")}
            </div>
            <span className="help-block">{errors.isTrending}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={article.rank}
                onChange={(event, value) => {
                  this.setState({ article: { ...article, rank: value.value } });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewArticle && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={article.status}
                onChange={(event, value) => {
                  this.setState({
                    article: {
                      ...article,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewArticle && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="author">Author*</label>
            <div className="input-group">
              <SelectAuthor
                id="author"
                name="author"
                authors={authors ?? []}
                selectedAuthorId={article?.author ?? null}
                onSelect={(event) => {
                  const newArticle = {
                    ...article,
                    author: event.value,
                  };
                  this.setState({ article: newArticle });
                }}
                isDisabled={!isNewArticle}
                className={"form-control" + (errors.author ? " has-error" : "")}
              />
            </div>
            <span className="help-block">{errors.author}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="healthHubCategory">Category*</label>
            <div className="input-group">
              <SelectArticleCategory
                id="healthHubCategory"
                name="healthHubCategory"
                categories={
                  articleCategories
                    ? articleCategories.filter(
                      (it) => it.country === selectedCountry?.id
                    )
                    : []
                }
                selectedCategory={
                  article && this.findArticleCategory(article.healthHubCategory)
                }
                onSelect={(event) => {
                  const newArticle = {
                    ...article,
                    healthHubCategory: event.value,
                  };
                  this.setState({ article: newArticle });
                }}
                isDisabled={!isNewArticle && !editables.healthHubCategory}
                className={
                  "form-control" +
                  (errors.healthHubCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("healthHubCategory")}
            </div>
            <span className="help-block">{errors.healthHubCategory}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedBloodPackages">
              Suggested Blood Packages
            </label>
            <div className="input-group user-input-container">
              <SelectBloodPackages
                id="suggestedBloodPackages"
                name="suggestedBloodPackages"
                bloodPackages={bloodPackagesForSelectedCountry}
                selectedBloodPackageIds={article.suggestedBloodPackages ?? []}
                onSelect={(options) => {
                  const newArticle = {
                    ...article,
                    suggestedBloodPackages: options.map((it) => it.value),
                  };
                  this.setState({ article: newArticle });
                }}
                showOnlyActive={true}
                menuPlacement="bottom"
                isDisabled={!isNewArticle && !editables.suggestedBloodPackages}
              />
              {this.renderPencilIcon("suggestedBloodPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="suggestedSupplements">Suggested Supplements</label>
            <div className="input-group user-input-container">
              <SelectSupplements
                id="suggestedSupplements"
                name="suggestedSupplements"
                countryId={selectedCountry?.id}
                selectedSupplementIds={article.suggestedSupplements ?? []}
                onSupplementSelect={(options) => {
                  const newArticle = {
                    ...article,
                    suggestedSupplements: options.map((it) => it.value),
                  };
                  this.setState({ article: newArticle });
                }}
                showOnlyActive={true}
                menuPlacement="bottom"
                isDisabled={!isNewArticle && !editables.supplements}
              />
              {this.renderPencilIcon("supplements")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedCustomPackages">
              Suggested Custom Packages
            </label>
            <div className="input-group user-input-container">
              <SelectCustomPackages
                id="suggestedCustomPackages"
                name="suggestedCustomPackages"
                customPackages={customPackagesForSelectedCountry}
                selectedCustomPackageIds={article.suggestedCustomPackages ?? []}
                onSelect={(options) => {
                  const newArticle = {
                    ...article,
                    suggestedCustomPackages: options.map((it) => it.value),
                  };
                  this.setState({ article: newArticle });
                }}
                showOnlyActive={true}
                menuPlacement="bottom"
                isDisabled={!isNewArticle && !editables.suggestedCustomPackages}
              />
              {this.renderPencilIcon("suggestedCustomPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="healthHubArticles">Related Articles</label>
            <div className="input-group user-input-container">
              <SelectArticles
                id="healthHubArticles"
                name="healthHubArticles"
                articles={this.getListForSelectingSuggestedArticles() ?? []}
                selectedArticleIds={article.healthHubArticles ?? []}
                onArticleSelect={(options) => {
                  const newArticle = {
                    ...article,
                    healthHubArticles: options.map((it) => it.value),
                  };
                  this.setState({ article: newArticle });
                }}
                isDisabled={!isNewArticle && !editables.healthHubArticles}
              />
              {this.renderPencilIcon("healthHubArticles")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="primaryImage">Primary Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectPrimaryImage}
                id="primaryImage"
                name="primaryImage"
                type="file"
                accept="image/*"
                disabled={!isNewArticle && !editables.primaryImage}
                className={
                  "form-control" + (errors.primaryImage ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("primaryImage")}
            </div>
            <span className="help-block">{errors.primaryImage}</span>
          </div>
          <div className="form-group col">
            {article?.primaryImageSrc && (
              <>
                <label htmlFor="selected_package_image">Selected Image</label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={article.primaryImageSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={this.findCountry(article.country)?.country_name ?? ""}
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
      </div>
    );

    const articleContentForm = (
      <>
        <div className="row mx-1 my-1">
          <div className="col">
            <HealthHubImages
              id="english"
              label="Upload Images"
              images={images ?? []}
              onImagesUpdated={this.handleImagesUpdated}
              isEditable={isEditable}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="input-group mt-4 ml-2">
              <RichTextEditor
                value={this.state.articleContentEnglishRichTextValue}
                onChange={this.handleArticleContentEnglishRichTextChange}
                disabled={!isNewArticle && !editables.articleContentEnglish}
              />
              {this.renderPencilIcon("articleContentEnglish")}
            </div>
          </div>
        </div>
      </>
    );

    const articleContentArabicForm = (
      <div className="input-group mt-4 ml-2">
        <RichTextEditor
          value={this.state.articleContentArabicRichTextValue}
          onChange={this.handleArticleContentArabicRichTextChange}
          disabled={!isNewArticle && !editables.articleContentArabic}
        />
        {this.renderPencilIcon("articleContentArabic")}
      </div>
    );

    const seoMarketingForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL*</label>
            <div className="input-group">
              <input
                value={article.seoUrl ?? ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={article.url_name_arabic ?? ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={article.metaTitle ?? ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={article.meta_title_arabic ?? ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={article.metaDescription ?? ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={article.meta_description_arabic ?? ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewArticle && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
       
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={article.keywords ?? ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewArticle && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={article.keywords_arabic ?? ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewArticle && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
      </div>
    );
    const formSearchEngine = (
      <div className="container mt-4">
        <div className="row">

          <div className="form-group col-6">
            <label htmlFor="searchTags">Tags</label>
            <div className="input-group">
              <textarea
                value={(article && article.searchTags) || ""}
                onChange={this.handleChange}
                id="searchTags"
                name="searchTags"
                placeholder="Tags"
                className={
                  "form-control py-2" + (errors.searchTags ? " has-error" : "")
                }
                readOnly={!isNewArticle && !editables.searchTags}
              ></textarea>
              {this.renderPencilIcon("searchTags")}
            </div>
            <span className="help-block">{errors.searchTags}</span>
          </div>
        </div>
      </div>


    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={articles}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + articles.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={(e) => {
                this.setState({ isSaving: true }, this.handleSave);
              }}
              disabled={!isEditable || this.state.isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_article_categories" justify>
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_content_form" title="Content (English)">
                {articleContentForm}
              </Tab>
              <Tab eventKey="key_content_arabic_form" title="Content (Arabic)">
                {articleContentArabicForm}
              </Tab>
              <Tab eventKey="key_seo_marketing_form" title="SEO Marketing">
                {seoMarketingForm}
              </Tab>
              <Tab
                eventKey="key_search_engine"
                title="Search Engine"
              >
                {formSearchEngine}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
