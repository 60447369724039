import React, { Component } from "react";
import API from "utils/API";
import FormValidator from "utils/FormValidator";
import { Dropdown } from "semantic-ui-react";
import FormData from "form-data";
import { connect } from "react-redux";
import { filterList } from "utils/List";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckIcon from "@material-ui/icons/Check";
import AlertBox from "components/AlertBox/AlertBox";
import AddonForm from "../Packages/AddonForm";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState, convertToRaw } from "draft-js";
import DataTable from "react-data-table-component";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import CountryDropdown from "components/Common/CountryDropdown";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import PackageRanking from "../../components/Common/PackageRanking";
import internalCategoryOptions from "utils/InterenalCategory";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class ProductCategory extends Component {
    constructor(props) {
        super(props);
        this.formFields = [
            {
                field: "subscription_name",
                method: "isEmpty",
                validWhen: false,
                isRtl: false,
                message: "Name is required.",
            },
            {
                field: "title_name",
                method: "isEmpty",
                validWhen: false,
                isRtl: false,
                message: "Title is required.",
            },
            {
                field: "subscription_name_ar",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Name is required.",
            },
            {
                field: "subscription_description",
                method: "isEmpty",
                validWhen: false,
                isRtl: false,
                message: "Description is required.",
            },
            {
                field: "subscription_description_ar",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Description is required.",
            },
            // {
            //     field: "location",
            //     method: "isEmpty",
            //     validWhen: false,
            //     isRtl: true,
            //     message: "Location is required.",
            // },
            {
                field: "image",
                type: "image",
                method: "isEmpty",
                validWhen: false,
                args: [{ min: 0 }],
                message: "Image is required.",
            },
            {
                field: "status",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Status is required.",
            },
            {
                field: "subscription_rank",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Rank is required.",
            },
        ];

        this.validator = new FormValidator(this.formFields);
        this.state = {
            aclUser: null,
            pageAccessRights: null,
            accessDenied: false,
            isEditable: true,
            subscription_name: "",
            title_name: "",
            subscription_name_ar: "",
            msg_en: '',
            subscription_description_ar: "",
            msg_ar: '',
            subscription_description: "",
            rankObject: {},
            rankSortable: {},
            image: '',
            imgSrc: '',
            location: '',
            validation: this.validator.valid(),
            arrLocationList: [{
                'text': 'Default',
                'value': '',
                'id': 0
            }],
            productStatus: [
                {
                    text: "Inactive",
                    value: "Inactive",
                    key: "Inactive",
                },
                {
                    text: "Active",
                    value: "Active",
                    key: "Active",
                },
            ],
            
            status: "",
            packages: [],
            arrEditPackage: {},
            formHeading: "Create Product",
            showModal: false,
            confirmItem: null,
            confirmMessage: "",
            confirmType: null,
            confirmTitle: "",
            successMessage: "",
            isLoaded: false,
            loadError: "",
            searchExpanded: false,
            searchTerm: "",
            searchApplied: false,
            results: [],
            resultCount: 0,
            showItems: "Active",
            isCustom: false,
            editPackageId: "",
            is_deleted: false,
            countries: [],
            selectedCountry: null,
            editables: {},
            isNewProduct: false,
            country: null,
            errors: {},
            internal_category: "None",
            packagesRanking:[],
            miniPackageOptions:[
                {
                  text: "YES",
                  value: true,
                  key: "yes",
                },
                {
                  text: "NO",
                  value: false,
                  key: "no",
                },
        
              ],
            is_mini_packages_included:false,
        };

        this.columns = [
            {
              name: "ID",
              selector: "id",
              sortable: true,
              width: "100px",
            },
            {
              name: "Name",
              selector: "name",
              wrap: true,
              sortable: true,
            },
            {
                name: "Title",
                selector: "title",
                wrap: true,
                sortable: true,
            },
            {
              name: "Rank",
              selector: "position_value",
              sortable: true,
              width: "125px",
              center: true,
            },
            {
              name: "Status",
              selector: "status",
              sortable: true,
              maxWidth: "150px",
              center: true,
              cell: (product) => this.renderStatus(product.status),
            },
            {
              name: "View",
              center: true,
              cell: (coachPackage) => this.renderViewButton(coachPackage.id),
            },
          ];

        this.submitted = false;
        this.overviewMessage = "";
        this.overviewArMessage = "";
        this.handleChange = this.handleChange.bind(this);
        this.selecteImage = this.selecteImage.bind(this);
        this.packageOperation = this.packageOperation.bind(this);
        this.formReset = this.formReset.bind(this);
        this.getProductList = this.getProductList.bind(this);
        this.editPackage = this.editPackage.bind(this);
        this.updateList = this.updateList.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideConfirm = this.hideConfirm.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchExit = this.handleSearchExit.bind(this);
        this.setShowItems = this.setShowItems.bind(this);
        this.saveOverviewEng = this.saveOverviewEng.bind(this);
        this.saveOverviewAr = this.saveOverviewAr.bind(this);
    }

    saveOverviewEng(data) {
        this.setState({
            subscription_description: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
            msg_en: stateToHTML(this.event.getCurrentContent()),
        });
    }

    saveOverviewAr(data) {
        this.setState({
            subscription_description_ar: JSON.stringify(
                convertToRaw(this.event_ar.getCurrentContent())
            ),
            msg_ar: stateToHTML(this.event_ar.getCurrentContent()),
        });
    }


    componentWillUnmount() {
        this.props.toggleLoading({
            isLoading: false,
        });
    }
    

    componentDidMount() {
        this.fetchAclUser();
        this.fetchCountries();
        this.location();
        this.updateList();
       
    }


    hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
            return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
    }

    fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
            console.log("fetchAclUser:: Invalid user id!", { userId });
            return;
        }

        try {
            const pageName = Page.PRODUCT_CATEGORIES;
            const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
            const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
            const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
            const accessDenied = isAccessDenied(aclUser, pageAccessRights);
            this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
                const isEditable = this.hasPageAccessRight("edit");
                this.setState({ isEditable });
            });
        } catch (error) {
            console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
    }

    location() {
        API.get("locations/")
            .then((res) => {
                let ary = []
                let data = res.data.length ? res.data : []
                for (let i = 0; i < data.length; i++) {
                    ary.push({ text: data[i]["name"] ? data[i]["name"] : '', value: data[i]["name"] ? data[i]["name"] : '', key: data[i]["id"] ? data[i]["id"] : '' })
                }
                let locationData = [...this.state.arrLocationList, ...ary];
                this.setState({ arrLocationList: locationData })
            })
            .catch((e) => {
                console.log("Error on fetching locations" + e);
            })
    }

    getPackageRanking=async(categoryId)=>{
       const{selectedCountry}=this.state;

        if(categoryId){
        try{

           const {data:allPackages=[]}= await PhoenixAPI.get( `all-packages/category/${categoryId}/country/${selectedCountry.id}`);
           return allPackages;
        }
        catch(error){
            console.log("Error on fetching package ranking" + error);
        }
    }
}


    getProductList() {
        const { searchApplied, searchTerm } = this.state;

        this.props.toggleLoading({
            isLoading: true,
        });
        API.get("products/")
            .then((res) => {
                let data = res.data || [];
                this.props.toggleLoading({
                    isLoading: false,
                });
                
                const ranks = data.filter(p => p.position_value).map(p => p.position_value);
                const minRank = Math.min(...ranks) || 0;
                const maxRank = Math.max(...ranks) || 0;

                const activeList = filterList(data, "Active");
                const archivedList = filterList(data, "Archived");
                console.log(activeList, archivedList)
                let newList = res.data;
                newList.sort((a, b) => {
                    return a.position_value - b.position_value;
                });
                let rankArray = [
                    {
                        text: 0,
                        value: 0,
                        key: 0,
                    }
                ];
                let rankSotable = [];
                let rank = 1;
                newList.map((item) => {
                    if (!item.is_deleted) {
                        rankSotable[rank] = item.id;
                        rankArray.push({
                            text: rank,
                            value: rank,
                            key: rank,
                        });
                        rank = rank + 1;
                    }
                });
                this.setState({
                    rankObject: rankArray,
                    rankSortable: rankSotable,
                    subscription_rank: rank - 1,
                    default_rank: rank - 1,
                    minRank,
                    maxRank,
                });
                this.setState({
                    packages: data,
                    isLoaded: true,
                });
            })
            .catch((e) => {
                console.log("Error on fetching products", e);
                this.props.showNotificationMessage({
                    notificationMessage: "Some error has occured. Please try again.",
                    successMessage: false,
                    showNotification: true,
                });
                this.props.toggleLoading({
                    isLoading: false,
                });
            })
    }

    handleChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    selecteImage(e) {
        if (e.target.files && e.target.files.length) {
            let image = e.target.files[0];
            console.log(image)
            this.setState({ image });
            let reader = new FileReader();
            // var url = reader.readAsDataURL(image);

            reader.onloadend = function () {
                this.setState({
                    imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(image);
        }
    }

    validateFinalResults() {
        let isValid = true;
        const { msg_en, msg_ar } = this.state;
        this.testMessage = null;

        if (msg_en === "<p><br></p>") {
            this.overviewMessage = "Desription is required";
            isValid = false;
        } else {
            this.overviewMessage = null;
        }

        if (msg_ar === "<p><br></p>") {
            this.overviewArMessage = "Description is required";
            isValid = false;
        } else {
            this.overviewArMessage = null;
        }

        return isValid;
    }

    validateName = (name, label) => {
        const MAX_LENGTH = 50;

        if (!name || name.trim() === "") return `${label} is a required field!`;
        else if (!/^[a-zA-Z0-9!@#$%^&*)(+=._-\s]*$/g.test(name))
            return `${label} can only take letters, numbers, symbols, and spaces!`;
        else if (name.length > MAX_LENGTH)
            return `${label} can not take more than ${MAX_LENGTH} characters!`;

        return null;
    };

    validateNameArabic = (name, label) => {
        const MAX_LENGTH = 50;

        if (!name || name.trim() === "") return `${label} is a required field!`;
        else if (name.length > MAX_LENGTH)
            return `${label} can not take more than ${MAX_LENGTH} characters!`;

        return null;
    };

    validateDescription = (description, label) => {
        const MAX_LENGTH = 1000;
        
        if (!description || description.trim() === "")
            return `${label} is a required field!`;
        else if (description.length > MAX_LENGTH)
            return `${label} can not take more than ${MAX_LENGTH} characters!`;
        // else if (JSON.parse(description)["blocks"][0].text === "")
        //     return `${label} is a required field!`;
        // else if (JSON.parse(description)["blocks"][0].text.length > MAX_LENGTH)
        //     return `${label} can not take more than ${MAX_LENGTH} characters!`;

        return null;
    };

    validate = () => {
        const errors = {};
        const {
            subscription_name: name, 
            title_name: titleEnglish, 
            subscription_name_ar: titleArabic,
            subscription_description: descriptionEnglish,
            subscription_description_ar: descriptionArabic,
            status,
            subscription_rank: position_value,
            imgSrc
        } = this.state;

        const nameErrorMessage = this.validateName(name, "Name");
        if (nameErrorMessage) errors.subscription_name = nameErrorMessage;

        const titleEnglishErrorMessage = this.validateName(titleEnglish, "Title (English)");
        if (titleEnglishErrorMessage) errors.title_name = titleEnglishErrorMessage;

        const titleArabicErrorMessage = this.validateNameArabic(titleArabic, "Title (Arabic)");
        if (titleArabicErrorMessage) errors.subscription_name_ar = titleArabicErrorMessage;

        const descriptionEnglishErrorMessage = this.validateDescription(descriptionEnglish, "Description (English)");
        if (descriptionEnglishErrorMessage) errors.subscription_description = descriptionEnglishErrorMessage;

        const descriptionArabicErrorMessage = this.validateDescription(descriptionArabic, "Description (Arabic)");
        if (descriptionArabicErrorMessage) errors.subscription_description_ar = descriptionArabicErrorMessage;

        if (status === null || status === undefined)
            errors.status = "Status is a required field!";
        
        

        if (!imgSrc) errors.image = "Image is a required field!";

        return Object.keys(errors).length === 0 ? null : errors;
    }

    packageOperation(operation) {
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
            });
            return;
        } 
            
        // Validation code remove right now
        const validation = this.validator.validate(this.state);
        this.submitted = true;
        this.setState({ validation });
        if (validation.isValid) {
            // This is commented because now we are not using RTE.
            // let isValid = this.validateFinalResults();
            // if (isValid)
            //     this.callSubscriptionPackageAPI(operation);
            this.callSubscriptionPackageAPI(operation);
        }
    }

    callSubscriptionPackageAPI(operation) {
        console.log("Calling subscription API ", operation);
        let apiOptions = {};
        let formdata = new FormData();
        let {
            image,
            subscription_rank,
            subscription_name,
            subscription_name_ar,
            subscription_description,
            subscription_description_ar,
            status,
            editPackageId,
            location,
            title_name,
            confirmItem,
            msg_ar,
            msg_en,
            internal_category,
            is_mini_packages_included,
            selectedCountry
        } = this.state;
        console.log(image)
        if (operation === "deleted") {
            formdata.append("name", confirmItem.name)
            formdata.append("title", confirmItem.title)
            formdata.append("title_arabic", confirmItem.title_arabic)
            formdata.append("description", subscription_description);
            formdata.append("description_arabic", subscription_description_ar);
            // formdata.append("description", msg_en)
            // formdata.append("description_arabic", msg_ar)
            formdata.append("location", confirmItem.location)
            formdata.append("infographic_image", confirmItem.infographic_image)
            formdata.append("infographic_image_s3", confirmItem.infographic_image)
            formdata.append("position_value", confirmItem.rank)
            formdata.append("status", confirmItem.status)
            // formdata.append("is_deleted", 1)
            formdata.append("remarks", "")
        } else {
            formdata.append("name", subscription_name)
            formdata.append("title", title_name)
            formdata.append("title_arabic", subscription_name_ar)
            formdata.append("description", subscription_description);
            formdata.append("description_arabic", subscription_description_ar);
            // formdata.append("description", msg_en)
            // formdata.append("description_arabic", msg_ar)
            formdata.append("location", location)
            if (image.name) {
                formdata.append("infographic_image", image, image.name);
                formdata.append("infographic_image_s3", image, image.name);
            }
            formdata.append("position_value", subscription_rank)
            formdata.append("status", status)
            // formdata.append("is_deleted", 0)
            formdata.append("remarks", "")
            if (internal_category == "None") {
                formdata.append("internal_category", "");
            } else {
                formdata.append("internal_category", internal_category);
            }
            formdata.append("is_mini_packages_included",is_mini_packages_included)
        }

        this.props.toggleLoading({
            isLoading: true,
        });

        if (operation == "update" || operation === "deleted") {
            apiOptions = {
                method: "patch",
                url: !operation === "deleted" ? "products/" + editPackageId + "/" : "products/" + editPackageId + "/",
                data: formdata,
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
                },
            };
            
            if (this.state.packagesRanking && this.state.packagesRanking.length>0) {
            const payload= this.state.packagesRanking.map((packageRank)=>({"itemType":packageRank.package_type,
            "itemId":packageRank.package_id,
            "rank":packageRank.rank,
            "id":packageRank.package_ranking_id?packageRank.package_ranking_id:null,
            "categoryId":this.state.editPackageId,
            "isActive" : 1,
            "countryId":selectedCountry.id
        }))
        try {
            if (payload && payload.length > 0) {
        PhoenixAPI.patch("/app-product-ranking",payload);
            }
            else {
                // Handle the case when payload is empty
                console.error("Payload is empty or invalid");
            }
        }catch (error) {
            // Handle errors from the API call
            console.error("Error while patching data:", error);
        }

    }    
    } else {
            //create parent details first get its id, and attach each addons
            if (this.state.selectedCountry)
                formdata.append("country", this.state.selectedCountry.id);
            else {
                this.props.showNotificationMessage({
                    notificationMessage: "Country should be selected!",
                    successMessage: false,
                    showNotification: true,
                });
                return;
            }
            apiOptions = {
                method: "post",
                url: "products/",
                data: formdata,
                headers: {
                    accept: "application/json",
                    "Accept-Language": "en-US,en;q=0.8",
                    "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
                },
            };
        }
        API.request(apiOptions)
            .then((response) => {
                this.hideModal();
                if (operation == "add") {
                    this.props.showNotificationMessage({
                        notificationMessage: "Product successfully created.",
                        successMessage: true,
                        showNotification: true,
                    });
                    this.getProductList();
                    this.props.toggleLoading({
                        isLoading: false,
                    });
                } else {
                    this.props.showNotificationMessage({
                        notificationMessage: "Product successfully updated.",
                        successMessage: true,
                        showNotification: true,
                    });
                    this.getProductList();
                    this.props.toggleLoading({
                        isLoading: false,
                    });
                }
            })
            .catch((error) => {
                console.log("Error on saving product", error);
                if (error.response && error.response.data) {
                    const data = error.response.data;
                    if (data.name) {
                        const errors = {...this.state.errors};
                        errors.subscription_name = data.name.join(", ");
                        this.setState({errors});
                    }
                }
                this.props.showNotificationMessage({
                    notificationMessage: "Technical Error occurred while creating/updating product. Please report the issue on #valeo-tech slack channel " + error.message,
                    successMessage: false,
                    showNotification: true,
                });

                this.props.toggleLoading({
                    isLoading: false,
                });
            });
    }

    formReset() {
        this.submitted = false;
        if (document.getElementById("imageInput"))
            document.getElementById("imageInput").value = "";
        this.setState({
            title_name: "",
            subscription_name: "",
            subscription_name_ar: "",
            subscription_description: "",
            subscription_description_ar: "",
            subscription_rank: this.state.default_rank,
            price: "",
            tests: [],
            image: "",
            imgSrc: "",
            gender: "",
            validation: this.validator.valid(),
            edit_id: null,
            editPackageId: null,
            msg_ar: "",
            msg_en: "",
            isNewProduct: false,
            status: "Inactive",
            editables: {},
            errors: {},
            is_mini_packages_included:false,
            packagesRanking:[],

        });
        this.overviewMessage = '';
        this.overviewArMessage = '';
    }

    toggleActiveStatus(item) {
        const { successMessage } = this.state;
        if (!item) return;
        this.hideConfirm();

        this.props.toggleLoading({
            isLoading: true,
        });
        var formData = new FormData();
        formData.append("is_deleted", 1);
        // formData.append("status", item.is_deleted ? 'Active' : 'Archive');
        API.patch("products/" + item.id + "/", formData)
            .then((response) => {
                this.props.toggleLoading({
                    isLoading: false,
                });
                this.props.showNotificationMessage({
                    notificationMessage: successMessage,
                    successMessage: true,
                    showNotification: true,
                });
                this.getProductList();
            })
            .catch((error) => {
                console.log("Error on toggling status of product", error);
                this.props.toggleLoading({
                    isLoading: false,
                });
                this.props.showNotificationMessage({
                    notificationMessage: "Technical Error occurred while creating/updating product. Please report the issue on #valeo-tech slack channel.",
                    successMessage: false,
                    showNotification: true,
                });
            });

    }

    hideConfirm() {
        this.setState({
            showConfirm: false,
            confirmItem: null,
            confirmTitle: "",
            successMessage: "",
            confirmMessage: "",
            confirmType: null,
        });
    }

    showConfirm(confirmItem) {
        let confirmMessage, successMessage, confirmTitle, confirmType;
        if (!confirmItem.is_deleted) {
            confirmTitle = "Delete Package";
            confirmMessage = "Are you sure you want to delete this?";
            successMessage = "Package successfully archived.";
            confirmType = "danger";
        }
        this.setState({
            showConfirm: true,
            confirmItem: confirmItem,
            confirmMessage,
            successMessage,
            confirmType,
            confirmTitle,
        });
    }

    updateList() {
        this.getProductList();
    }
    editPackage=async(index)=> {
        const arrEditPackage = this.state.packages.filter((e) => {
            if (e.id === index)
                return e;
        });
        const packageToEdit = arrEditPackage && arrEditPackage.length > 0 ? arrEditPackage[0] : null;
      const allPackages= await  this.getPackageRanking(index);
        if (packageToEdit) {
            console.log("Selected Product: ", packageToEdit);
            const overview_obj =
                packageToEdit.description != null
                    ? stateFromHTML(packageToEdit.description)
                    : stateFromHTML("")
            const overview_ar_obj = packageToEdit.description_arabic != null
                ? stateFromHTML(packageToEdit.description_arabic)
                : stateFromHTML("")
                
            this.setState(
                {
                    subscription_rank: packageToEdit.position_value,
                    subscription_name: packageToEdit.name,
                    subscription_name_ar: packageToEdit.title_arabic,
                    subscription_description: packageToEdit.description,
                    // subscription_description: JSON.stringify(convertToRaw(overview_obj)),
                    msg_en:
                        packageToEdit.description != null
                            ? packageToEdit.description
                            : stateToHTML(ContentState.createFromText("")),
                    subscription_description_ar: packageToEdit.description_arabic,
                    // subscription_description_ar:JSON.stringify(convertToRaw(overview_ar_obj)),
                    msg_ar: packageToEdit.description_arabic != null
                        ? packageToEdit.description_arabic
                        : stateToHTML(ContentState.createFromText("")),
                    imgSrc: packageToEdit.infographic_image,
                    image: packageToEdit.infographic_image
                    ,
                    formHeading: "Edit/Add Product",
                    title_name: packageToEdit.title,
                    status: packageToEdit.status,
                    location: packageToEdit.location,
                    editPackageId: packageToEdit.id,
                    country: packageToEdit.country,
                    internal_category: packageToEdit.internal_category || "None",
                    is_mini_packages_included:packageToEdit.is_mini_packages_included || false,
                    packagesRanking:allPackages|| [],
                },
                () => this.showModal()
            );
        }
    }


    hideModal() {
        this.formReset();
        this.setState({ showModal: false });
    }

    showModal() {
        const rankOptions = this.createRankOptions();
        this.setState({
            showModal: true,
            rankObject: rankOptions,
        });
    }

    handleCreateNewProduct = () => {
        this.formReset();
        this.setState({
            isNewProduct: true,
            status: "Inactive",
            products: this.state.maxRank + 1,
            internal_category: "None",
        }, () => this.showModal());
    }

    handleSearch(value, products = []) {
        let newValue,
            list = [];

        this.setState({
            searchTerm: value,
        });

        // if (e.key !== "Enter") {
        //   return;
        // }

        newValue = value.slice(0).trim().toLowerCase();

        if (isEmpty(value)) {
            this.setState({
                searchApplied: false,
                searchExpanded: false,
            });

            return;
        }

        const { showItems } = this.state;
        // Filter code just commentted
        // if (showItems === "Active") list = this.state.activeList;
        // else if (showItems === "Archived") list = this.state.archivedList;
        // else
        list = products;

        if (!list) return;

        let newList = [];

        for (let item of list) {
            if (
                item.name.toLowerCase().indexOf(newValue) !== -1 ||
                item.name.toLowerCase().indexOf(newValue) !== -1
            ) {
                newList.push(item);
            }
        }

        this.setState({
            searchExpanded: true,
            searchApplied: true,
            results: newList,
            resultCount: newList.length,
        });
        return newList || [];
    }

    clearSearch() {
        this.setState({
            searchTerm: "",
            searchExpanded: true,
            searchApplied: false,
        });

        this.search.searchInput.focus();
    }

    handleSearchExit(e) {
        const value = e.target.value;

        if (!value.length) {
            this.setState({
                searchExpanded: false,
                searchApplied: false,
            });

            return;
        } else {
            this.setState({
                searchExpanded: true,
            });
        }
    }

    setShowItems(mode) {
        let showItems;
        const { searchApplied, searchTerm } = this.state;

        if (mode === "active") showItems = "Active";
        else if (mode === "archived") showItems = "Archived";
        else showItems = "All";

        this.setState({ showItems: showItems }, () => {
            if (searchApplied) this.handleSearch(searchTerm);
        });
    }

    getCountryName = (id) => {
        const { countries } = this.state;
        const country = countries && countries.find((country) => country.id === id);
        return country ? country.country_name : null;
    };

    fetchCountries = async () => {
        const url = "countries/";
        try {
            const { data: countries } = await API.get(url);
            const defaultCountry = countries
                ? countries.find((country) => country.is_default)
                : null;

            console.log("Countries: ", countries);
            console.log("Default Country: ", defaultCountry);

            this.setState({ countries, selectedCountry: defaultCountry });
        } catch (error) {
            console.error("Error on fetching countries from this URL: ", url);
            console.error("Error on fetching countries", error);
        }
    };

    handleCountrySelection = (country) => 
        this.setState({ selectedCountry: country });

    renderStatus = (status) => {
        if (status === "Active")
            return (
                <div className="badge badge-success pt-1">
                    <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                    Active
                </div>
            );
        else
            return (
                <div className="badge badge-warning pt-1">
                    <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                    Inactive
                </div>
            );
    };

    renderViewButton = (packageId) => (
        <div>
            <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={() => {
                    
                    this.editPackage(packageId);
                 
                }}
            >
                View
            </button>
        </div>
    );

    getFilteredProducts = () => {
        const { packages: products, selectedCountry, searchApplied, searchTerm } = this.state;
        const productsByCountry = selectedCountry
            ? this.getProductsByCountry(products, selectedCountry.id)
            : products;

        return (searchApplied && searchTerm)
            ? this.filterBySearchTerm(searchTerm, productsByCountry)
            : productsByCountry;
    };

    getProductsByCountry = (products, countryId) =>
        products && countryId
            ? products.filter((product) => product.country === countryId)
            : [];

    filterBySearchTerm = (searchTerm, products) => {
        let newValue,
            list = [];

        // if (e.key !== "Enter") {
        //   return;
        // }

        newValue = searchTerm.slice(0).trim().toLowerCase();

        if (isEmpty(searchTerm)) {
            this.setState({
                searchApplied: false,
                searchExpanded: false,
            });

            return;
        }


        list = products;

        if (!list) return;

        let newList = [];

        for (let item of list) {
            if (
                (item.name && (item.name.toLowerCase().indexOf(newValue) !== -1)) ||
                // item.name.toLowerCase().indexOf(newValue) !== -1 || // Duplicate check should be deleted!
                (item.title && (item.title.toLowerCase().indexOf(newValue) !== -1))
            ) {
                newList.push(item);
            }
        }
        return newList || [];
     }
        
    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) =>
        this.state.isEditable && !this.state.isNewProduct ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                className="input-group-append"
                style={{cursor: "pointer"}}
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );

    createRankOptions = () => {
        const { minRank, maxRank, isNewProduct } = this.state;
        if (!minRank || !maxRank) return;

        const until = isNewProduct ? maxRank + 1 : maxRank;
        let rankOptions = [];
        for (let i = minRank; i <= until; i++)
            rankOptions.push({ key: i, value: i, text: i });

        console.log("Rank Options: ", rankOptions);
        return rankOptions;
    }

    handlePackagesUpdated=(allPackages)=>{
        this.setState({packagesRanking:allPackages});
        console.log("packages ranking",allPackages);
    }



    
            
    render() {
        let finalList = [];
        const {
            packages,
            isLoaded,
            searchApplied,
            results,
            resultCount,
            showItems,
            activeList,
            archivedList,
            loadError,
            countries,
            selectedCountry,
            isNewProduct,
            editables,
            errors,
            isEditable,
            packagesRanking
        } = this.state;

        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation;
        console.log(validation, this.submitted)
        if (isLoaded) {
            // if (searchApplied) finalList = results;
            // else {
            //     // Filter code just commentted
            //     // if (showItems === "Active") finalList = activeList;
            //     // else if (showItems === "Archived") finalList = archivedList;
            //     // else
            //     finalList = packages;
            // }
            finalList = this.getFilteredProducts();
            console.log("Final List: ", finalList);
        }

        const detailsForm =
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="subscription_name">Name (Admin Panel Only)*</label>
                        <div className="input-group">
                            <input
                                value={this.state.subscription_name}
                                onChange={this.handleChange}
                                id="subscription_name"
                                name="subscription_name"
                                type="text"
                                size="50"
                                placeholder="Name in English"
                                disabled={!isNewProduct && !editables.subscription_name}
                                className={
                                    "form-control py-2" +
                                    (errors.subscription_name ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("subscription_name")}
                        </div>
                        <span className="help-block">
                            {errors.subscription_name}
                        </span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="status">Status*</label>
                        <div className="input-group">
                            <Dropdown
                                value={
                                    this.state.status ? this.state.status : isNewProduct && "Inactive"
                                }
                                onChange={(event, value) => {
                                    this.setState({ status: value.value });
                                }}
                                id="status"
                                placeholder="Status"
                                search
                                selection
                                options={this.state.productStatus}
                                disabled={!isNewProduct && !editables.status}
                                className={
                                    "form-control" +
                                    (errors.status ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("status")}
                        </div>
                        <span className="help-block">{errors.status}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="rank">Rank*</label>
                        <div className="input-group">
                            <Dropdown
                                value={this.state.subscription_rank}
                                onChange={(event, value) => {
                                    this.setState({ subscription_rank: value.value });
                                }}
                                id="rank"
                                placeholder="rank"
                                search
                                selection
                                options={this.state.rankObject}
                                disabled={!isNewProduct && !editables.subscription_rank}
                                className={
                                    "form-control" +
                                    (errors.rank ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("subscription_rank")}
                        </div>
                        <span className="help-block">{errors.rank}</span>
                    </div>
                    <div className="form-group col">
                        <label>Country</label>
                        <input
                            value={
                                isNewProduct
                                 ? selectedCountry && selectedCountry.country_name
                                 : this.getCountryName(this.state.country)
                            }
                            type="text"
                            disabled={true}
                            className="form-control py-2"
                        />
                    </div>
                    {/* This is commented because in new design we are showing country instead of location dropdown. */}
                    {/* <div className="form-group col">
                        <label>Location*</label>
                        <Dropdown
                            value={this.state.location}
                            onChange={(event, value) => {
                                this.setState({ location: value.value });
                            }}
                            placeholder="Location"
                            search
                            selection
                            options={this.state.arrLocationList}
                            className={
                                "form-control" +
                                (validation.location.isInvalid ? " has-error" : "")
                            }
                        />
                        <span className="help-block">{validation.location.message}</span>
                    </div> */}
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="title_name">Title (English)*</label>
                        <div className="input-group">
                            <input
                                value={this.state.title_name}
                                onChange={this.handleChange}
                                type="text"
                                id="title_name"
                                name="title_name"
                                placeholder="Title in English"
                                disabled={!isNewProduct && !editables.title_name}
                                className={
                                    "form-control py-2" +
                                    (errors.title_name ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("title_name")}
                        </div>
                        <span className="help-block">
                            {errors.title_name}
                        </span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="subscription_name_ar">Title (Arabic)*</label>
                        <div className="input-group">
                        <input
                            value={this.state.subscription_name_ar}
                            onChange={this.handleChange}
                            id="subscription_name_ar"
                            name="subscription_name_ar"
                            type="text"
                            placeholder="Title in Arabic"
                            disabled={!isNewProduct && !editables.subscription_name_ar}
                            className={
                                "form-control input-arabic py-2" +
                                (errors.subscription_name_ar ? " has-error" : "")
                            }
                        />
                        {this.renderPencilIcon("subscription_name_ar")}
                        </div>
                        <span className="help-block">
                            {errors.subscription_name_ar}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="subscription_description">Description (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={this.state.subscription_description ? this.state.subscription_description : ""}
                                onChange={this.handleChange}
                                id="subscription_description"
                                name="subscription_description"
                                placeholder="Description in English"
                                readOnly={!isNewProduct && !editables.subscription_description}
                                className={
                                    "form-control" +
                                    (errors.subscription_description ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("subscription_description")}
                        </div>
                        {/* 
                        <label htmlFor="subscription_description">
                            Description (English) *
                            <span> (click on save icon to save your data)</span>
                        </label>
                        <div className="input-group mb-4">
                            <MUIRichTextEditor
                                defaultValue={this.state.subscription_description ? this.state.subscription_description : ""}
                                onChange={(e) => { this.event = e; }}
                                onSave={this.saveOverviewEng}
                                id="subscription_description"
                                name="subscription_description"
                                label="Description in English"
                                inheritFontSize={true}
                                toolbarButtonSize="small"
                                readOnly={!isNewProduct && !editables.subscription_description}
                                className={
                                    "form-control" +
                                    (errors.subscription_description ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("subscription_description")}
                        </div> */}
                        <span className="help-block">{errors.subscription_description}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="subscription_description_ar">Description (Arabic)*</label>
                        <div className="input-group mb-4">
                            <textarea
                                value={this.state.subscription_description_ar ? this.state.subscription_description_ar : ""}
                                onChange={this.handleChange}
                                id="subscription_description_ar"
                                name="subscription_description_ar"
                                placeholder="Description in Arabic"
                                readOnly={!isNewProduct && !editables.subscription_description_ar}
                                className={
                                    "form-control input-arabic" +
                                    (errors.subscription_description_ar ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("subscription_description_ar")}
                        </div>
                        {/* <label htmlFor="subscription_description_ar">
                            Description (Arabic) *
                            <span> (click on save icon to save your data)</span>
                        </label>
                        <div className="input-group mb-4">
                            <MUIRichTextEditor
                                defaultValue={
                                    this.state.subscription_description_ar ? this.state.subscription_description_ar : ""
                                }
                                onChange={(e) => { this.event_ar = e; }}
                                onSave={this.saveOverviewAr}
                                id="subscription_description_ar"
                                name="subscription_description_ar"
                                label="Description in Arabic"
                                inheritFontSize={true}
                                toolbarButtonSize="small"
                                readOnly={!isNewProduct && !editables.subscription_description_ar}
                                className={
                                    "form-control input-arabic  " +
                                    (errors.subscription_description_ar ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("subscription_description_ar")}
                        </div> */}
                        <span className="help-block">
                            {errors.subscription_description_ar}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <label htmlFor="status">Internal Category</label>
                        <div className="input-group">
                            <Dropdown
                                value={
                                    this.state.internal_category ? this.state.internal_category : "None"
                                }
                                onChange={(event, value) => {
                                    this.setState({ internal_category: value.value });
                                }}
                                id="internal_category"
                                placeholder="internal_category"
                                search
                                selection
                                options={internalCategoryOptions}
                                disabled={!isNewProduct && !editables.internal_category}
                                className={
                                    "form-control" +
                                    (errors.internal_category ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("internal_category")}
                        </div>
                        <span className="help-block">{errors.internal_category}</span>
                    </div>
                    <div className="form-group col-6">
            
            <label htmlFor="is_best_seller"> Does this category have minipackage?</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_mini_packages_included}
                onChange={(event, value) => {
                  this.setState({ is_mini_packages_included: value.value });
                }}
                id="is_mini_packages_included"
                placeholder="minipackage included"
                search
                selection
                options={this.state.miniPackageOptions}
                disabled={!isNewProduct && !editables.is_mini_packages_included}
                className={
                  "form-control" + (errors.is_mini_packages_included ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_mini_packages_included")}
            </div>
              
            
            <span className="help-block">{errors.is_mini_packages_included}</span>
          </div>
                </div>
                <div className="row mb-2">
                    <div className="col separator">
                        <span>Media</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="imageInput">Product Image*</label>
                        <div className="input-group">
                            <input
                                onChange={this.selecteImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewProduct && !editables.image}
                                className={
                                    "form-control" +
                                    (errors.image ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("image")}
                        </div>
                        <span className="help-block">{errors.image}</span>
                    </div>
                    <div className="form-group col">
                        {this.state.imgSrc && (
                            <>
                                <label>Selected Thumbnail Image</label>
                                <div className="form-image-container">
                                    <img
                                        src={this.state.imgSrc}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>

        const rankingForm=(
            <div className="container mt-4">
        <PackageRanking
          packages={packagesRanking || []}
          onPackagesUpdated={this.handlePackagesUpdated}
          isEditable={isEditable}
          type="Mobile"
        />
      </div>
        )

        if (this.state.accessDenied) {
            return <AccessDenied />;
        }
        
        return (
            <div className="manage-package">
                <CountryDropdown
                    countries={countries || []}
                    selectedCountry={selectedCountry}
                    onSelect={this.handleCountrySelection}
                />
                <div className="page-header">
                    {/* <h5 className="mb-4">Manage Products</h5> */}
                    <div className="actions">
                        <div className="action-item">
                            <Search
                                searchExpanded={true}
                                searchTerm={this.state.searchTerm}
                                clearSearch={this.clearSearch}
                                handleSearch={this.handleSearch}
                                handleSearchExit={this.handleSearchExit}
                                ref={(input) => {
                                    this.search = input;
                                }}
                            ></Search>
                        </div>
                        {/* {Filter code just commentted out} */}
                        {/* <div className="action-item select-menu">
                            <ActionBar alignRight className="valeo-dropdown">
                                <ActionBar.Toggle variant="">
                                    {this.state.showItems}
                                </ActionBar.Toggle>
                                <ActionBar.Menu>
                                    <ActionBar.Header>Show</ActionBar.Header>
                                    <ActionBar.Item
                                        className={showItems === "All" ? "active" : ""}
                                        onClick={() => this.setShowItems("all")}
                                    >
                                        {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                                    </ActionBar.Item>
                                    <ActionBar.Item
                                        className={showItems === "Active" ? "active" : ""}
                                        onClick={() => this.setShowItems("active")}
                                    >
                                        {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                                        Active
                                    </ActionBar.Item>
                                    <ActionBar.Item
                                        className={showItems === "Archived" ? "active" : ""}
                                        onClick={() => this.setShowItems("archived")}
                                    >
                                        {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                                        Archived
                                    </ActionBar.Item>
                                </ActionBar.Menu>
                            </ActionBar>
                        </div> */}

                        <div className="action-item ">
                            <button
                                style={{ backgroundColor: "#8FD14F" }}
                                className="btn btn-md new-user"
                                onClick={this.handleCreateNewProduct}
                                disabled={!isEditable}
                            >
                                <i className="fa fa-plus-circle"></i>Add New
                            </button>
                        </div>
                    </div>
                </div>
                <Card body>
                    <DataTable
                        highlightOnHover
                        columns={this.columns}
                        data={finalList}
                        defaultSortField="status"
                        sortIcon={<ArrowDownward></ArrowDownward>}
                        pagination
                        paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                        paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        responsive
                        noHeader={searchApplied ? false : true}
                        title={searchApplied ? (
                            <p className="table-header">
                                {"Found " + finalList.length + " results"}
                            </p>
                        ) : (
                            ""
                        )}
                        noDataComponent={
                            loadError ? (
                                <AlertBox message={loadError} error={true}></AlertBox>
                            ) : (
                                <AlertBox message="There's nothing here."></AlertBox>
                            )
                        }
                    ></DataTable>
                </Card>
                <Modal 
                    size="lg" 
                    show={this.state.showModal} 
                    onHide={this.hideModal}
                    className="user-data-modal"
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                        <div className="user-title"></div>
                        <button
                            onClick={
                                this.state.editPackageId
                                    ? () => this.packageOperation("update")
                                    : () => this.packageOperation("add")
                            }
                            disabled={!isEditable}
                            className="btn modal-save-btn">
                            Save
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="key_details_form">
                            <Nav variant="tabs" className="justify-content-left">
                                <Nav.Item>
                                    <Nav.Link eventKey="key_details_form">Details</Nav.Link>
                                </Nav.Item>
                                {this.state.editPackageId? <Nav.Item>
                                    <Nav.Link eventKey="ranking_form">
                                        Ranking
                                    </Nav.Link>
                                </Nav.Item>:<></>}
                            </Nav>
                            <Row>
                                <Col>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="key_details_form">
                                            {detailsForm}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ranking_form">
                                            {rankingForm}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.confirmMessage}</Modal.Body>
                    <Modal.Footer>
                        {this.state.confirmType === "danger" ? (
                            <button
                                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                                className="btn button btn-danger"
                            >
                                Archive
                            </button>
                        ) : (
                            <button
                                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                                className="btn button btn-success"
                            >
                                Restore
                            </button>
                        )}
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideConfirm}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCategory);
