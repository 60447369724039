import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "utils/commons";

function PaymentDetailsForm({ paymentDetails }) {
  return (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="transaction_date">Transaction Date</label>
          <input
            value={
              paymentDetails.transaction_date
                ? formatDate(paymentDetails.transaction_date)
                : ""
            }
            id="transaction_date"
            name="transaction_date"
            type="text"
            disabled
            className="form-control"
          />
        </div>
        <div className="form-group col">
          <label htmlFor="payment_ip">Payment IP</label>
          <input
            value={paymentDetails.payment_ip ?? ""}
            id="payment_ip"
            name="payment_ip"
            type="text"
            disabled
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="valeo_request_id">Valeo Request ID</label>
          <input
            value={paymentDetails.valeo_request_id ?? ""}
            id="valeo_request_id"
            name="valeo_request_id"
            type="text"
            disabled
            className="form-control"
          />
        </div>
        <div className="form-group col">
          <label htmlFor="merchant_request_id">Merchant Request ID</label>
          <input
            value={paymentDetails.merchant_request_id ?? ""}
            id="merchant_request_id"
            name="merchant_request_id"
            type="text"
            disabled
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="payment_method">Payment Method</label>
          <input
            value={paymentDetails.payment_method ?? ""}
            id="payment_method"
            name="payment_method"
            type="text"
            disabled
            className="form-control"
          />
        </div>
        <div className="form-group col">
          <label htmlFor="payment_status">Payment Status</label>
          <input
            value={paymentDetails.payment_status ?? ""}
            id="payment_status"
            name="payment_status"
            type="text"
            disabled
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="payment_description">Payment Description</label>
          <input
            value={paymentDetails.payment_description ?? ""}
            id="payment_description"
            name="payment_description"
            type="text"
            disabled
            className="form-control"
          />
        </div>
        <div className="form-group col">
          <label htmlFor="payment_status_desc">
            Payment Status Description
          </label>
          <input
            value={paymentDetails.payment_status_desc ?? ""}
            id="payment_status_desc"
            name="payment_status_desc"
            type="text"
            disabled
            className="form-control"
          />
        </div>
      </div>
    </>
  );
}

PaymentDetailsForm.propTypes = {
  paymentDetails: PropTypes.object.isRequired,
};

PaymentDetailsForm.defaultProps = {
  paymentDetails: {},
};

export default PaymentDetailsForm;
