import React, { Component } from 'react';
import { Card, Modal, Tab, Row, Col, Nav, Tabs } from 'react-bootstrap';
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import Select from 'react-select';
import _, { iteratee } from "lodash";
import { formatDate, compareStrings } from 'utils/commons';
import Logs from 'components/Logs/Logs';
import SelectCoachConsultationPackages from "../../components/Common/SelectCoachConsultationPackages"

const BASE_URL_PACKAGES_EXCLUSION = "coupon-code/exclusion/";



class CouponExclusionForm extends Component {
    constructor(props) {
        super(props);
        const { bloodBiomarkerPackages,
            healthCoachPackages,
            customPackages,
            allMedicines,
            allSupplements,
            allBundles,
            selectedCountry,
            isEditable } = this.props;
        this.state = {
            showCouponExclusionModal: false,
            editExclusionPackages: false,
            exclusionBloodPackages: [],
            exclusionCustomPackages: [],
            exclusionBundles: [],
            exclusionHealthPackages: [],
            exclusionSupplements: [],
            exclusionMedicines: [],
            editExclusionBloodPackages: false,
            editExclusionCustomPackages: false,
            editExclusionBundles: false,
            editExclusionHealthPackages: false,
            editExclusionSupplements: false,
            editExclusionMedicines: false,
            exclusionPackageOptions: [],
            exclusionCustomPackageOptions: [],
            exclusionAddonOptions: [],
            exclusionMedicinesOptions: [],
            exclusionSupplementOptions: [],
            exclusionBundleOptions: [],
            pageIdForLogs: null,
            exclusionCoachConsultationPackages: [],
            editExclusionCoachConsultationPackages: false,
        };

        this.handleExclusionPackageChange = this.handleExclusionPackageChange.bind(this);
        this.handleExclusionCustomPackageChange = this.handleExclusionCustomPackageChange.bind(this);
        this.handleExclusionAddonChange = this.handleExclusionAddonChange.bind(this);
        this.handleCouponExclusionSubmit = this.handleCouponExclusionSubmit.bind(this);
        this.showCouponExclusionModal = this.showCouponExclusionModal.bind(this);
        this.hideCouponExclusionModal = this.hideCouponExclusionModal.bind(this);
        this.handleExclusionBundleChange = this.handleExclusionBundleChange.bind(this);
        this.handleExclusionMedicineChange = this.handleExclusionMedicineChange.bind(this);
        this.handleExclusionSupplementChange = this.handleExclusionSupplementChange.bind(this);
    }

    handleEditExclusion = async () => {
        await this.fetchExclusionPackages();

        this.createPackagesOptions();
        this.setState({ editCouponExclusion: true });
        this.showCouponExclusionModal();

    }

    hideCouponExclusionModal() {
        this.setState({
            showCouponExclusionModal: false,
            exclusionBloodPackages: [],
            exclusionCustomPackages: [],
            exclusionHealthPackages: [],
            exclusionBundles: [],
            exclusionMedicines: [],
            exclusionSupplements: [],
            editExclusionPackages: false,
            editExclusionBloodPackages: false,
            editExclusionCustomPackages: false,
            editExclusionHealthPackages: false,
            editExclusionBundles: false,
            editExclusionMedicines: false,
            editExclusionSupplements: false,
            pageIdForLogs: null,
            exclusionCoachConsultationPackages: [],
            editExclusionCoachConsultationPackages: false,
        })

    }

    showCouponExclusionModal() {
        const { bloodBiomarkerPackages, healthCoachPackages, customPackages, allMedicines, allSupplements, allBundles, selectedCountry, allCoachConsultationsPackages } = this.props;
        const { exclusionBloodPackages, exclusionBundles, exclusionCustomPackages, exclusionMedicines, exclusionSupplements, exclusionHealthPackages, all_bundles, all_medicines, all_supplements, exclusionCoachConsultationPackages } = this.state;

        this.setState({
            pageIdForLogs: selectedCountry.id
        })

        let bloodpackage = [];
        let custompackage = [];
        let addon = [];
        let medicinesExclusion = [];
        let bundleExclusion = [];
        let supplementsExclusion = [];
        bloodpackage = bloodBiomarkerPackages.filter(ele => exclusionBloodPackages.find(val => ele.id === val)).map(ele => ({
            label: ele.internal_name,
            value: ele.id
        }));;

        custompackage = customPackages.filter(ele => exclusionCustomPackages.find(val => ele.id === val)).map(ele => ({
            label: ele.name,
            value: ele.id
        }));
        addon = healthCoachPackages.filter(ele => exclusionHealthPackages.find(val => ele.id === val)).map(ele => ({
            label: ele.addon_name,
            value: ele.id
        }));

        medicinesExclusion = allMedicines.filter(ele => exclusionMedicines.find(val => ele.id === val)).map(ele => ({
            label: ele.internalName,
            value: ele.id
        }));

        supplementsExclusion = allSupplements.filter(ele => exclusionSupplements.find(val => ele.id === val)).map(ele => ({
            label: ele.internalName,
            value: ele.id
        }));

        bundleExclusion = allBundles.filter(ele => exclusionBundles.find(val => ele.id === val)).map(ele => ({
            label: ele.internalName,
            value: ele.id
        }));


        const editExclusionPackagesFlag =
            exclusionBloodPackages.length > 0 ||
            exclusionCustomPackages.length > 0 ||
            exclusionBundles.length > 0 ||
            exclusionHealthPackages.length > 0 ||
            exclusionSupplements.length > 0 ||
            exclusionMedicines.length > 0 
           

        this.setState({
            showCouponExclusionModal: true,
            exclusionBloodPackages: bloodpackage,
            exclusionCustomPackages: custompackage,
            exclusionHealthPackages: addon,
            exclusionBundles: bundleExclusion,
            exclusionMedicines: medicinesExclusion,
            exclusionSupplements: supplementsExclusion,
            editExclusionPackages: editExclusionPackagesFlag,
        })

    }





    createPackagesOptions = () => {
        const exclusionPackageOptions = this.createBloodBiomarkerPackageOptions();
        const exclusionCustomPackageOptions = this.createCustomPackageOptions();
        const exclusionSupplementOptions = this.createSupplementOptions();
        const exclusionAddonOptions = this.createHealthCoachPackageOptions();
        const exclusionMedicinesOptions = this.createMedicineOptions();
        const exclusionBundleOptions = this.createBundleOptions();
        this.setState({ exclusionPackageOptions, exclusionCustomPackageOptions, exclusionAddonOptions, exclusionMedicinesOptions, exclusionSupplementOptions, exclusionBundleOptions });
    }

    createBloodBiomarkerPackageOptions = () => {
        const { bloodBiomarkerPackages, selectedCountry } = this.props;

        const packagesForCrrentCountry = (bloodBiomarkerPackages && selectedCountry)
            ? bloodBiomarkerPackages.filter(p => p.country === selectedCountry.id)
            : [];


        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.is_active);
        activePackages.sort((first, second) =>
            compareStrings(first.internal_name, second.internal_name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internal_name, second.internal_name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internal_name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }

    createCustomPackageOptions = () => {
        const { customPackages = [], selectedCountry } = this.props;

        const packagesForCrrentCountry = (customPackages && selectedCountry)
            ? customPackages.filter(p => p.country === selectedCountry.id)
            : [];
        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.name, second.name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.name, second.name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            "label": p.name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }

    createHealthCoachPackageOptions = () => {
        const { healthCoachPackages, selectedCountry } = this.props;

        const packagesForCrrentCountry = (healthCoachPackages && selectedCountry)
            ? healthCoachPackages.filter(p => p.country === selectedCountry.id)
            : [];
        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.is_active);
        activePackages.sort((first, second) =>
            compareStrings(first.addon_name, second.addon_name, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.addon_name, second.addon_name, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            "label": p.addon_name,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];
    }

    createBundleOptions = () => {
        const { allBundles: bundles = [], selectedCountry } = this.props;
        const packagesForCrrentCountry = (bundles && selectedCountry)
            ? bundles.filter(p => p.country === selectedCountry.id)
            : [];


        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];


    }

    createMedicineOptions = () => {
        const { allMedicines: medicines = [], selectedCountry } = this.props;
        const packagesForCrrentCountry = (medicines && selectedCountry)
            ? medicines.filter(p => p.country === selectedCountry.id)
            : [];


        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];

    }

    createSupplementOptions = () => {

        const { allSupplements: supplements = [], selectedCountry } = this.props;
        const packagesForCrrentCountry = supplements;

        const [activePackages, inactivePackages] = _.partition(packagesForCrrentCountry, (it) => it.status === "Active");
        activePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        inactivePackages.sort((first, second) =>
            compareStrings(first.internalName, second.internalName, true)
        );
        const createOption = (p) => ({
            "key": p.id,
            "value": p.id,
            // "label": p.subscription_name,
            "label": p.internalName,
        });
        return [
            {
                label: "Active",
                options: activePackages.map(createOption)
            },
            {
                label: "Inactive",
                options: inactivePackages.map(createOption)
            }
        ];


    }


    handleExclusionAddonChange(event) {
        this.setState({
            exclusionHealthPackages: event
        })
    }

    handleExclusionPackageChange(event) {
        this.setState({
            exclusionBloodPackages: event
        })
    }
    handleExclusionCustomPackageChange(event) {
        console.log("event is", event);
        this.setState({
            exclusionCustomPackages: event
        })
    }

    handleExclusionBundleChange(event) {
        this.setState({
            exclusionBundles: event
        })
    }

    handleExclusionMedicineChange(event) {
        this.setState({
            exclusionMedicines: event
        })
    }

    handleExclusionSupplementChange(event) {
        this.setState({
            exclusionSupplements: event
        })
    }
    makeEditable = (key) => this.setState({ [key]: true });

    handleAllExclusiveActive = (packageType) => {
        const { selectedCountry, bloodBiomarkerPackages, healthCoachPackages, customPackages, allMedicines, allSupplements, allBundles,allCoachConsultationsPackages } = this.props;
        if (packageType === "bloodbiomarker") {


            const bloodbiomarkerIds =
                bloodBiomarkerPackages.filter(it => it.country === selectedCountry?.id)
                    .filter(it => it.is_active === true)
                    // .map(it => ({ value: it.id, label: it.subscription_name }))
                    .map(it => ({ value: it.id, label: it.internal_name }))
            this.setState({ exclusionBloodPackages: bloodbiomarkerIds });
        }
        else if (packageType === "addons") {


            const addonIds = healthCoachPackages
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.is_active === true)
                .map(it => ({ value: it.id, label: it.addon_name }))
            this.setState({ exclusionHealthPackages: addonIds });
        }

        else if (packageType === "custom") {

            const customPackageIds = customPackages
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active")
                .map(it => ({ value: it.id, label: it.name }));
            this.setState({ exclusionCustomPackages: customPackageIds });

        }
        else if (packageType === "bundles") {

            const allActiveBundleIds = allBundles
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active")
                .map(it => ({ value: it.id, label: it.internalName }));
            this.setState({ exclusionBundles: allActiveBundleIds });
        }
        else if (packageType === "medicines") {

            const allActiveMedicineIds = allMedicines
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active")
                .map(it => ({ value: it.id, label: it.internalName }));
            this.setState({ exclusionMedicines: allActiveMedicineIds });
        }
        else if (packageType === "supplements") {

            const allActiveSupplementIds = allSupplements
                .filter(it => it.country === selectedCountry?.id)
                .filter(it => it.status === "Active")
                .map(it => ({ value: it.id, label: it.internalName }));
            this.setState({ exclusionSupplements: allActiveSupplementIds });
        }
        else if(packageType === "coachConsultationPackages"){
           const allCoachConsultationsPackageIds=allCoachConsultationsPackages.filter(it => it.countryId === selectedCountry?.id)
           .filter(it => it.isActive === true).map((it)=>it.id)
           this.setState({ exclusionCoachConsultationPackages: allCoachConsultationsPackageIds });
        }
    }
    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    fetchExclusionPackages = async () => {
        const { selectedCountry } = this.props;
        console.log("blood package");
        if (!selectedCountry || !selectedCountry.id) {
            console.log("fetchSupplementsV2:: Cannot fetch supplements! Invalid country");
            return;
        }
        try {
            this.showLoading();
            const url = `${BASE_URL_PACKAGES_EXCLUSION}country/${selectedCountry.id}`
            const { data: all_packages_exclusion } = await PhoenixAPI.get(url);
            const {
                bloodPackages = [],
                coachPackages = [],
                customPackages = [],
                bundles = [],
                supplements = [],
                medicines = [],
                coachConsultationPackages= []

            } = all_packages_exclusion;
            console.log("coachConsulationPackagescoachConsulationPackages", all_packages_exclusion)
            this.setState({
                exclusionBloodPackages: bloodPackages,
                exclusionCustomPackages: customPackages,
                exclusionBundles: bundles,
                exclusionHealthPackages: coachPackages, // Assuming miniPackages corresponds to exclusionHealthPackages
                exclusionSupplements: supplements,
                exclusionMedicines: medicines,
                exclusionCoachConsultationPackages: coachConsultationPackages
            });
        }
        catch (error) {
            console.log("Error on fetching supplements!", error);
        } finally {
            this.hideLoading();
        }

    }

    handleCouponExclusionSubmit() {
        const { selectedCountry } = this.props;
        
        let bloodPackagesFinal = [];
        let addonsFinal = [];
        let customPackagesFinal = [];
        let bundlesFinal = [];
        let medicinesFinal = [];
        let supplementsFinal = [];

        for (let i = 0; i < this.state.exclusionBloodPackages.length; i++)
            bloodPackagesFinal.push(this.state.exclusionBloodPackages[i].value);

        for (let i = 0; i < this.state.exclusionHealthPackages.length; i++)
            addonsFinal.push(this.state.exclusionHealthPackages[i].value);

        for (let i = 0; i < this.state.exclusionCustomPackages.length; i++)
            customPackagesFinal.push(this.state.exclusionCustomPackages[i].value);

        for (let i = 0; i < this.state.exclusionMedicines.length; i++)
            medicinesFinal.push(this.state.exclusionMedicines[i].value);

        for (let i = 0; i < this.state.exclusionBundles.length; i++)
            bundlesFinal.push(this.state.exclusionBundles[i].value);

        for (let i = 0; i < this.state.exclusionSupplements.length; i++)
            supplementsFinal.push(this.state.exclusionSupplements[i].value);




        const payload = {
            "bloodPackages": bloodPackagesFinal,
            "coachPackages": addonsFinal,
            "customPackages": customPackagesFinal,
            "bundles": bundlesFinal,
            "supplements": supplementsFinal,
            "medicines": medicinesFinal,
            "coachConsultationPackages":this.state.exclusionCoachConsultationPackages??[]
        }
        const apiOptions = {
            method: "patch",
            url: `coupon-code/exclusion/${selectedCountry.id}`,
            data: payload,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                // "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
            }
        }
        const notificationMessage = "Updated Successfully";
        PhoenixAPI.request(apiOptions)
            .then(response => {
                this.props.showNotificationMessage({
                    notificationMessage,
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
                this.hideCouponExclusionModal();
            }).catch(error => {
                console.log("Error on saving coupon: ", error);
                this.props.toggleLoading({ isLoading: false });
                this.props.showNotificationMessage({
                    notificationMessage: "Something went wrong. Please try again later",
                    successMessage: false,
                    showNotification: true
                });
            });

    }

    render() {

        const selectStyles = {
            container: base => ({
                ...base,
                flex: 1,
            }),
        };

        const couponExclusionListForm = (<><div className="row">
            <div className="form-group col">
                <label>Blood Biomarker Packages</label>
                <div className="input-group user-input-container">
                    <Select
                        isMulti
                        name="packages"
                        className={
                            (this.state.editExclusionPackages ? "non-editable " : "editable ")

                        }
                        isDisabled={this.state.editExclusionBloodPackages || !this.state.editExclusionPackages ? false : true}
                        value={this.state.exclusionBloodPackages}
                        onChange={this.handleExclusionPackageChange}
                        options={this.state.exclusionPackageOptions}
                        styles={selectStyles}
                    />

                    {this.state.editExclusionPackages && (
                        <div className="input-group-append" onClick={() => this.makeEditable("editExclusionBloodPackages")}>
                            <i className="input-group-text fa fa-pencil"></i>
                        </div>
                    )}
                </div>
            </div>
            <div className="form-group col">
                <label></label><br />
                <button
                    type="button"
                    onClick={(e) => this.handleAllExclusiveActive("bloodbiomarker")}
                    className={
                        "form-control btn btn-info mt-2 py-1"
                    }
                //  disabled={!isEditable || (!newCoupon && this.state.couponCategory === "Cashback")}
                >
                    Add All Active Blood Biomarker Packages
                </button>
            </div>
        </div>
            <div className="row">
                <div className="form-group col">
                    <label>Custom Packages</label>
                    <div className="input-group user-input-container">
                        <Select
                            isMulti
                            name="custom_package"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")

                            }
                            isDisabled={this.state.editExclusionCustomPackages || !this.state.editExclusionPackages ? false : true}
                            value={this.state.exclusionCustomPackages}
                            onChange={this.handleExclusionCustomPackageChange}
                            options={this.state.exclusionCustomPackageOptions}
                            styles={selectStyles}
                        />

                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editExclusionCustomPackages")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("custom")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }
                    // disabled={}
                    >
                        Add All Active custom Packages
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <label>Health Coach Packages</label>
                    <div className="input-group user-input-container">
                        <Select
                            isMulti
                            name="addons"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")
                                // + (validation.addons.isInvalid && "has-error")
                            }
                            isDisabled={this.state.editExclusiveAddons || !this.state.editExclusionPackages ? false : true}
                            value={this.state.exclusionHealthPackages}
                            onChange={this.handleExclusionAddonChange}
                            options={this.state.exclusionAddonOptions}
                            styles={selectStyles}
                        />
                        {/* <span className="help-block">{validation.addons.message}</span> */}
                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editExclusiveAddons")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("addons")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }
                    //disabled={!isEditable || (!newCoupon && this.state.couponCategory === "Cashback")}
                    >
                        Add All Active Health Coach Packages
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <label htmlFor="bundles">Bundles</label>
                    <div className="input-group user-input-container">
                        <Select
                            isMulti
                            name="bundles"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")
                                // + (validation.addons.isInvalid && "has-error")
                            }
                            isDisabled={this.state.editBundles || !this.state.editExclusionPackages ? false : true}
                            value={this.state.exclusionBundles}
                            onChange={this.handleExclusionBundleChange}
                            options={this.state.exclusionBundleOptions}
                            styles={selectStyles}
                        />
                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editBundles")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("bundles")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }
                    // disabled={!isEditable || (!newCoupon && this.state.couponCategory === "Cashback")}
                    >
                        Add All Active Bundles
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <label htmlFor="supplements">Supplements</label>
                    <div className="input-group user-input-container">
                        <Select
                            isMulti
                            name="supplements"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")
                                // + (validation.addons.isInvalid && "has-error")
                            }
                            isDisabled={this.state.editExclusionSupplements || !this.state.editExclusionPackages ? false : true}
                            value={this.state.exclusionSupplements}
                            onChange={this.handleExclusionSupplementChange}
                            options={this.state.exclusionSupplementOptions}
                            styles={selectStyles}
                        />
                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editExclusionSupplements")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("supplements")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }
                    //  disabled={!isEditable || (!newCoupon && this.state.couponCategory === "Cashback")}
                    >
                        Add All Active Supplements
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <label htmlFor="medicines">Medicines</label>
                    <div className="input-group user-input-container">
                        <Select
                            isMulti
                            name="bundles"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")
                                // + (validation.addons.isInvalid && "has-error")
                            }
                            isDisabled={this.state.editExclusionMedicines || !this.state.editExclusionPackages ? false : true}
                            value={this.state.exclusionMedicines}
                            onChange={this.handleExclusionMedicineChange}
                            options={this.state.exclusionMedicinesOptions}
                            styles={selectStyles}
                        />
                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editExclusionMedicines")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("medicines")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }

                    >
                        Add All Active Medicines
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="form-group col">
                    <label htmlFor="coachConsultationPackages">Coach Consultation Packages</label>
                    <div className="input-group user-input-container">
                        <SelectCoachConsultationPackages
                            countryId={this.props.selectedCountry?.id}
                            selectedCoachConsultationIds={this.state.exclusionCoachConsultationPackages ?? []}
                            onCoachConsultationSelect={(options) => this.setState({ exclusionCoachConsultationPackages: options.map(it => it.value) })}
                            isDisabled={this.state.editExclusionCoachConsultationPackages || !this.state.editExclusionPackages ? false : true}
                            id="coachConsultationPackages"
                            name="coachConsultationPackages"
                            className={
                                (this.state.editExclusionPackages ? "non-editable " : "editable ")
                            }
                            couponCodePage={false}
                        />
                        {this.state.editExclusionPackages && (
                            <div className="input-group-append" onClick={() => this.makeEditable("editExclusionCoachConsultationPackages")}>
                                <i className="input-group-text fa fa-pencil"></i>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-group col">
                    <label></label><br />
                    <button
                        type="button"
                        onClick={(e) => this.handleAllExclusiveActive("coachConsultationPackages")}
                        className={
                            "form-control btn btn-info mt-2 py-1"
                        }
                    >
                        Add All Active Coach Consultation Packages
                    </button>
                </div>
            </div>
        </>);
        return (<>
            Coupon Exclusion List  &nbsp;&nbsp; <button style={{ backgroundColor: "#8FD14F" }} className="btn px-5" onClick={this.handleEditExclusion} disabled={!this.props.isEditable}>
                Edit
            </button>

            <Modal size="lg" show={this.state.showCouponExclusionModal} onHide={this.hideCouponExclusionModal} className="user-data-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <button type="submit" onClick={this.handleCouponExclusionSubmit} disabled={!this.props.isEditable} className="btn modal-save-btn">
                            Save
                        </button>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* {couponExclusionListForm} */}
                    <Tabs style={{ marginBottom: '12px' }} >
                        <Tab eventKey="key_form_details" title="Details" >
                            {couponExclusionListForm}
                        </Tab>
                        {this.state.pageIdForLogs != null ?
                            <Tab eventKey="logs-execution-request" title="Logs" >
                                <Logs pageId={this.state.pageIdForLogs} pageType="EXCLUSION_REQUEST" />
                            </Tab>
                            : ""}
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>

        )
    }

}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CouponExclusionForm);