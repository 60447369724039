import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import FAQForm from "components/Common/FAQForm";
import BundleItemsForm from "./BundleItemsForm";
import { fetchCountries } from "services/RegionService";
import { fetchBundles, fetchBundlesSummaryByCountry, fetchBundleById, saveBundle, updateBundle } from "services/BundleService";
import SubscriptionForm from "components/Common/SubscriptionForm";
import KeyHighlightsForm from "./KeyHighlightsForm";
import HowItWorksImages from "./HowItWorksImages";
import ToggleSwitch from "components/Common/ToggleSwitch";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];



class Bundles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      bundles: [],
      rankOptions: [],
      bundle: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isBundleViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
      pricingTypeDropdown: "",
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name : "UID",
        selector: "bundleUid",
        width: "150px",
        wrap:true,
        sortable: true,

      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (bundle) => (
          <p style={{ textAlign: "right" }}>{bundle.externalNameArabic}</p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (bundle) => <Status isActive={bundle.status === "Active"} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (bundle) => this.renderViewButton(bundle.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.fetchBundles();
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.BUNDLES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries", error);
    }
  };

  fetchBundles = async () => {
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchBundles:: Invalid country!", { selectedCountry });
      return;
    }

    try {
      this.showLoading();
      const { data: bundles = [] } = await fetchBundlesSummaryByCountry(countryId);
      this.setState({ bundles });
    } catch (error) {
      this.showErrorNotification("Error on fetching bundles: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };
  
  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const bundle = { ...this.state.bundle, image };
      this.setState({ bundle });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const bundle = {
        ...this.state.bundle,
        image_src: [reader.result],
      };
      this.setState({ bundle });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const bundle = { ...this.state.bundle };
    const { name, value } = input;
    if (numberInputs.includes(name))
      bundle[name] =
        value && value.toString().trim() !== "" ? Number(value) : value;
    else bundle[name] = value;
    this.setState({ bundle });
  };

  handleQuestionsUpdated = (questions = []) => {
    const bundle = { ...this.state.bundle, bundleFaqs: questions };
    this.setState({ bundle });
  };

  handleBundleItemsUpdated = (bundleItems = []) => {
    const bundle = { ...this.state.bundle, bundleItems };
    this.setState({ bundle });
  };

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    const bundle = {
      ...this.state.bundle,
      subscriptionItems: subscriptionItems,
    };
    this.setState({ bundle });
  };

  handleKeyHighlightsUpdated = (keyHighlights = []) => {
    const bundle = { ...this.state.bundle, keyHighlights };
    this.setState({ bundle });
  };

  handleHowItWorksImagesEnglishUpdated = (howItWorksImagesEnglish = []) => {
    const bundle = { ...this.state.bundle, howItWorksImagesEnglish };
    this.setState({ bundle });
  };

  handleHowItWorksImagesArabicUpdated = (howItWorksImagesArabic = []) => {
    const bundle = { ...this.state.bundle, howItWorksImagesArabic };
    this.setState({ bundle });
  };

  handleAddNewBundle = () => {
    this.setState({ isNewBundle: true }, () => this.showBundleViewModal());
  };

  handleViewBundle = async (bundleId) => {
    try {
      this.showLoading();
      const { data: bundle } = await fetchBundleById(bundleId);
      if (!bundle) {
        console.log(`Bundle with id ${bundleId} is not found!`);
        return;
      }

      bundle.image_src = bundle.image;
      if (bundle.currency === null || bundle.currency === undefined) {
        bundle.currency = this.state.selectedCountry?.currency;
      }
      if (bundle.internalCategory === null || bundle.internalCategory === undefined) {
        bundle.internalCategory = "None";
      }
      const monthlyPricing = bundle.subscriptionItems ?? [];

      this.setState({ bundle, monthlyPricing, isNewBundle: false }, () =>
        this.showBundleViewModal()
      );
    } catch (error) {
      console.log("handleViewMedicine:: Error on fetching medicine by id!", error);
      this.showErrorNotification("Error on fetching medicine by id! " + error.message ?? "");
    } finally {
      this.hideLoading();
    }
  };

  showBundleViewModal = () => {
    const { selectedCountry, isNewBundle } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewBundle) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const bundle = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
        internalCategory: "None",
      };
      this.setState({
        bundle,
        rankOptions,
        isBundleViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isBundleViewModalVisible: true });
    }
  };

  hideBundleViewModal = () => {
    this.setState({ isBundleViewModalVisible: false });
    this.resetForm();
  };

  handleSaveBundle = async () => {
    const { selectedCountry, bundle } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    } else if (!bundle.enableOneTime && !bundle.enableSubscription) {
      this.showErrorNotification(
        "At least one type of pricing should be enabled!"
      );
      return;
    }

    try {
      this.showLoading();
      const isValidId = bundle.id !== null && bundle.id !== undefined;
      if(bundle.internalCategory=="None"){
        bundle.internalCategory=null;
      }
      const response = isValidId
        ? await updateBundle(bundle)
        : await saveBundle(bundle);
      console.log("Response: ", response);
      this.hideBundleViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Bundle Updated Successfully."
          : "Bundle Created Successfully."
      );
      this.fetchBundles();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { bundle = {} } = this.state;
    const errors = {};

    const {
      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      country,

      shortDescriptionEnglish,
      shortDescriptionArabic,
      longDescriptionEnglish,
      longDescriptionArabic,
      keyHighlightsEnglish,
      keyHighlightsArabic,
      disclaimerEnglish,
      disclaimerArabic,
      image,

      retailPrice,
      finalPrice,
      discountType,
      discountValue,
      subscriptionItems,
      zohoAccountId,
      internalCategory,
    } = bundle;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";
    if(zohoAccountId && zohoAccountId.length>45)
      errors.zohoAccountId="zoho AccountId is limited to 45 characters";
    // Validate Descriptions Section
    if (shortDescriptionEnglish && shortDescriptionEnglish.length > 1000)
      errors.shortDescriptionEnglish =
        "Short Description (English) is limited to a 1000 characters";

    if (shortDescriptionArabic && shortDescriptionArabic.length > 1000)
      errors.shortDescriptionArabic =
        "Short Description (Arabic) is limited to a 1000 characters";

    if (longDescriptionEnglish && longDescriptionEnglish.length > 20000)
      errors.longDescriptionEnglish =
        "Long Description (English) is limited to a 20000 characters";

    if (longDescriptionArabic && longDescriptionArabic.length > 20000)
      errors.longDescriptionArabic =
        "Long Description (Arabic) is limited to a 20000 characters";

    if (keyHighlightsEnglish && keyHighlightsEnglish.length > 5000)
      errors.keyHighlightsEnglish =
        "Key Highlights (English) is limited to a 5000 characters";

    if (keyHighlightsArabic && keyHighlightsArabic.length > 5000)
      errors.keyHighlightsArabic =
        "Key Highlights (Arabic) is limited to a 5000 characters";

    if (disclaimerEnglish && disclaimerEnglish.length > 5000)
      errors.disclaimerEnglish =
        "Disclaimer (English) is limited to a 5000 characters";

    if (disclaimerArabic && disclaimerArabic.length > 5000)
      errors.disclaimerArabic =
        "Disclaimer (Arabic) is limited to a 5000 characters";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    // Validate Pricing Section
    if (bundle.enableOneTime) {
      if (retailPrice === null || retailPrice === undefined)
        errors.retailPrice = "Retail Price cannot be blank";
      else if (Number.isNaN(Number(retailPrice)))
        errors.retailPrice = "Retail Price should be a valid number";
      else if (!this.isInBetween(Number(retailPrice), 0, 99999999))
        errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";

      if (finalPrice === null || finalPrice === undefined)
        errors.finalPrice = "Final Price cannot be blank";
      else if (Number.isNaN(Number(finalPrice)))
        errors.finalPrice = "Final Price should be a valid number";
      else if (!this.isInBetween(Number(finalPrice), 0, 99999999))
        errors.finalPrice = "Final Price should be bewteen 0 to 99999999";

      if (!discountType) errors.discountType = "Discount Type cannot be blank";

      if (discountValue === null || discountValue === undefined)
        errors.discountValue = "Discount Value cannot be blank";
      else if (Number.isNaN(Number(discountValue)))
        errors.discountValue = "Discount Value should be a valid number";
      else if (!this.isInBetween(Number(discountValue), 0, 99999999))
        errors.discountValue = "Discount Value should be bewteen 0 to 99999999";
    }

    if (bundle.enableSubscription) {
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscriptionItems = "At least one active item is required!";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewBundle } = this.state;
    const bundles = this.getBundlesForCurrentCountry();
    const ranks = bundles.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewBundle ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      bundle: {},
      errors: {},
      editables: {},
      pricingTypeDropdown: "",
      monthlyPricing: [],
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewBundle(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewBundle ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredBundles = () => {
    const bundles = this.getBundlesForCurrentCountry();
    return this.getSearchedBundles(bundles);
  };

  getBundlesForCurrentCountry = () => {
    const { bundles, selectedCountry } = this.state;
    return bundles && selectedCountry
      ? bundles.filter((p) => p.country === selectedCountry.id)
      : [];
  };

  getSearchedBundles = (bundles) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return bundles;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return bundles.filter((bundle) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
      } = bundle;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      bundles,
      bundle,
      isNewBundle,
      isBundleViewModalVisible,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredBundles = this.getFilteredBundles(bundles) ?? [];
    
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={bundle?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.status}
                onChange={(event, value) => {
                  this.setState({
                    bundle: {
                      ...bundle,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewBundle && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={bundle?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={bundle?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.rank}
                onChange={(event, value) => {
                  this.setState({
                    bundle: { ...bundle, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewBundle && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewBundle
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(bundle?.country)?.country_name
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="zohoAccountId">
            Zoho Account ID
            </label>
            <div className="input-group">
              <input
                value={bundle?.zohoAccountId || ""}
                onChange={this.handleChange}
                id="zohoAccountId"
                name="zohoAccountId"
                type="number"
                maxLength="45"
                placeholder="Zoho Account ID"
                className={
                  "form-control py-2" +
                  (errors.zohoAccountId ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewBundle && !editables.zohoAccountId}
              />
              {this.renderPencilIcon("zohoAccountId")}
            </div>
            <span className="help-block">{errors.zohoAccountId}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.internalCategory}
                onChange={(event, value) => {
                  this.setState({
                    bundle: { ...bundle, internalCategory: value.value },
                  });
                }}
                id="internalCategory"
                placeholder="internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewBundle && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="shortDescriptionEnglish">
              Short Description (English)
            </label>
            <div className="input-group">
              <input
                value={bundle.shortDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="shortDescriptionEnglish"
                name="shortDescriptionEnglish"
                type="text"
                placeholder="Short Description in English"
                className={
                  "form-control py-2" +
                  (errors.shortDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.shortDescriptionEnglish}
              />
              {this.renderPencilIcon("shortDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.shortDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="shortDescriptionArabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <input
                value={bundle.shortDescriptionArabic || ""}
                onChange={this.handleChange}
                id="shortDescriptionArabic"
                name="shortDescriptionArabic"
                type="text"
                placeholder="Short Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.shortDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.shortDescriptionArabic}
              />
              {this.renderPencilIcon("shortDescriptionArabic")}
            </div>
            <span className="help-block">{errors.shortDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="longDescriptionEnglish">
              Long Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={bundle.longDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="longDescriptionEnglish"
                name="longDescriptionEnglish"
                placeholder="Long Description in English"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.longDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("longDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.longDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="longDescriptionArabic">
              Long Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={bundle.longDescriptionArabic || ""}
                onChange={this.handleChange}
                id="longDescriptionArabic"
                name="longDescriptionArabic"
                placeholder="Long Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.longDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("longDescriptionArabic")}
            </div>
            <span className="help-block">{errors.longDescriptionArabic}</span>
          </div>
        </div>
        {/* No need to show now, because we are showing a new table
         <div className="row">
          <div className="form-group col">
            <label htmlFor="keyHighlightsEnglish">
              Key Highlights (English)
            </label>
            <div className="input-group">
              <textarea
                value={bundle.keyHighlightsEnglish || ""}
                onChange={this.handleChange}
                id="keyHighlightsEnglish"
                name="keyHighlightsEnglish"
                placeholder="Key Highlights in English"
                className={
                  "form-control py-2" +
                  (errors.keyHighlightsEnglish ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.keyHighlightsEnglish}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsEnglish")}
            </div>
            <span className="help-block">{errors.keyHighlightsEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyHighlightsArabic">Key Highlights (Arabic)</label>
            <div className="input-group">
              <textarea
                value={bundle.keyHighlightsArabic || ""}
                onChange={this.handleChange}
                id="keyHighlightsArabic"
                name="keyHighlightsArabic"
                placeholder="Key Highlights in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keyHighlightsArabic ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.keyHighlightsArabic}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsArabic")}
            </div>
            <span className="help-block">{errors.keyHighlightsArabic}</span>
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="disclaimerEnglish">Disclaimer (English)</label>
            <div className="input-group">
              <textarea
                value={bundle.disclaimerEnglish || ""}
                onChange={this.handleChange}
                id="disclaimerEnglish"
                name="disclaimerEnglish"
                placeholder="Disclaimer in English"
                className={
                  "form-control py-2" +
                  (errors.disclaimerEnglish ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.disclaimerEnglish}
              ></textarea>
              {this.renderPencilIcon("disclaimerEnglish")}
            </div>
            <span className="help-block">{errors.disclaimerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="disclaimerArabic">Disclaimer (Arabic)</label>
            <div className="input-group">
              <textarea
                value={bundle.disclaimerArabic || ""}
                onChange={this.handleChange}
                id="disclaimerArabic"
                name="disclaimerArabic"
                placeholder="Disclaimer in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.disclaimerArabic ? " has-error" : "")
                }
                readOnly={!isNewBundle && !editables.disclaimerArabic}
              ></textarea>
              {this.renderPencilIcon("disclaimerArabic")}
            </div>
            <span className="help-block">{errors.disclaimerArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <KeyHighlightsForm
              keyHighlights={bundle?.keyHighlights ?? []}
              onKeyHighlightsUpdated={this.handleKeyHighlightsUpdated}
              isEditable={isEditable}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
          <HowItWorksImages
              id="english"
              label="How it Works Images (English)"
              images={bundle?.howItWorksImagesEnglish ?? []}
              onImagesUpdated={this.handleHowItWorksImagesEnglishUpdated}
              isEditable={isEditable}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <HowItWorksImages
              id="arabic"
              label="How it Works Images (Arabic)"
              images={bundle?.howItWorksImagesArabic ?? []}
              onImagesUpdated={this.handleHowItWorksImagesArabicUpdated}
              isEditable={isEditable}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewBundle && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {bundle?.image_src && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={bundle.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    const formPricingOneTimeContent = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Retail Price*</label>
            <div className="input-group">
              <input
                value={bundle.retailPrice ?? ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder="Retail Price"
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.retailPrice}
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="finalPrice">Final Price*</label>
            <div className="input-group">
              <input
                value={bundle.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="number"
                placeholder="Final Price"
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.finalPrice}
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.discountType}
                onChange={(event, value) => {
                  this.setState({
                    bundle: {
                      ...bundle,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewBundle && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountValue">Discount Value*</label>
            <div className="input-group">
              <input
                value={bundle.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                className={
                  "form-control py-2" +
                  (errors.discountValue ? " has-error" : "")
                }
                disabled={!isNewBundle && !editables.discountValue}
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
      </>
    );

    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={bundle.enableOneTime ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  bundle: { ...bundle, enableOneTime: isToggled },
                })
              }
            />
          </div>
        </div>
        {bundle.enableOneTime && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscriptionItems}</span>
        <SubscriptionForm
          subscriptions={this.state.monthlyPricing || []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableSubscription"
              toggled={bundle.enableSubscription ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  bundle: { ...bundle, enableSubscription: isToggled },
                })
              }
            />
          </div>
        </div>
        {bundle.enableSubscription && formPricingSubscriptionContent}
      </>
    );

    const formPricing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="currency">Currency</label>
            <div className="input-group">
              <input
                value={bundle?.currency}
                id="currency"
                name="currency"
                type="text"
                className={
                  "form-control py-2" + (errors.currency ? " has-error" : "")
                }
                disabled={true}
              />
            </div>
            <span className="help-block">{errors.currency}</span>
          </div>
        </div>
        {formPricingOneTime}
        {formPricingSubscription}
      </div>
    );

    const formPackagesAndProducts = (
      <div className="container mt-4">
        <BundleItemsForm
          country={selectedCountry?.id}
          bundleItems={bundle?.bundleItems || []}
          onBundleItemsUpdated={this.handleBundleItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formFaq = (
      <div className="container mt-4">
        <FAQForm
          questions={bundle?.bundleFaqs || []}
          onQuestionUpdated={this.handleQuestionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewBundle}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredBundles || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredBundles.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isBundleViewModalVisible}
          onHide={this.hideBundleViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Bundle: </span>
                {bundle?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveBundle}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_bundles">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_form_descriptions" title="Descriptions">
                {formDescriptions}
              </Tab>
              <Tab eventKey="key_form_pricing" title="Pricing">
                {formPricing}
              </Tab>
              <Tab
                eventKey="key_form_packages_and_products"
                title="Packages & Products"
              >
                {formPackagesAndProducts}
              </Tab>
              <Tab eventKey="key_form_faq" title="FAQ Table">
                {formFaq}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Bundles);
