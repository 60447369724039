import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class CorporatePartners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      corporatePartners: [],
      corporatePartner: {},
      errors: {},
      editables: {},
      rankOptions: [],
      isSaving: false,
      isNewCorporatePartner: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Partner Name",
        selector: "partnerName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Promotion Header",
        selector: "promotionHeader",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (row) => <Status isActive={row.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (row) => this.renderViewButton(row.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchCorporatePartners();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  fetchCorporatePartners = async () => {
    try {
      this.showLoading();
      const { data: corporatePartners = [] } = await PhoenixAPI.get(
        "/corporate-partner"
      );
      this.setState({ corporatePartners });
    } catch (error) {
      console.log(
        "fetchCorporatePartners:: Error on fetching corporate partners!",
        error
      );
      this.showErrorNotification(
        "Error on fetching corporate partners! " + error.message ?? ""
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const corporatePartner = { ...this.state.corporatePartner };
    corporatePartner[input.name] = input.value;
    this.setState({ corporatePartner });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
      const corporatePartner = {
        ...this.state.corporatePartner,
        partnerLogo: imageFile,
      };
      this.setState({ corporatePartner });

      this.readImage(imageFile);
    }
  };

  readImage = (imageFile) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const corporatePartner = {
        ...this.state.corporatePartner,
        partnerLogoSrc: [reader.result],
      };
      this.setState({ corporatePartner });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNew = () => {
    this.setState({ isNewCorporatePartner: true }, () =>
      this.showCorporatePartnerViewModal()
    );
  };

  handleView = (id) => {
    const corporatePartners = this.state.corporatePartners || [];
    const corporatePartner = corporatePartners.find((it) => it.id === id);
    if (corporatePartner) {
      corporatePartner.partnerLogoSrc = corporatePartner.partnerLogo ?? null;
      this.setState({ corporatePartner, isNewCorporatePartner: false }, () =>
        this.showCorporatePartnerViewModal()
      );
    }
  };

  showCorporatePartnerViewModal = () => {
    const { country } = this.props;
    const { isNewCorporatePartner } = this.state;
    const rankOptions = this.getRankOptions() || [];

    if (isNewCorporatePartner) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const corporatePartner = {
        country: country?.id,
        rank,
        isActive: false,
      };
      this.setState({ corporatePartner, rankOptions }, () => this.showModal());
    } else {
      this.setState({ rankOptions }, () => this.showModal());
    }
  };

  getRankOptions = () => {
    const { isNewCorporatePartner } = this.state;
    const corporatePartnersForCurrentCountry =
      this.getCorporatePartnersForCurrentCountry();
    const ranks = corporatePartnersForCurrentCountry
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks);
    const maxRank = Math.max(
      corporatePartnersForCurrentCountry.length,
      ...ranks
    );
    const until = isNewCorporatePartner ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    return rankOptions;
  };

  handleSave = async () => {
    if (!this.props.country) {
      this.showErrorNotification("Please select a country!");
      this.setState({ isSaving: false });
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    const { corporatePartner } = this.state;
    const payload = { ...corporatePartner };
    delete payload.partnerLogoSrc;
    if (typeof payload.partnerLogo !== "string") {
      delete payload.partnerLogo;
    }
    try {
      this.showLoading();
      const formData = new FormData();
      formData.append("partner", JSON.stringify(payload));
      if (typeof corporatePartner.partnerLogo !== "string") {
        formData.append("partnerLogo", corporatePartner.partnerLogo);
      }
      const config = {
        method: corporatePartner.id ? "PATCH" : "POST",
        url: corporatePartner.id
          ? `/update-corporate-partner/${corporatePartner.id}`
          : "/create-corporate-partner",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchCorporatePartners();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  validate = () => {
    const errors = {};
    const {
      partnerName,
      partnerNameArabic,
      promotionHeader,
      promotionHeaderArabic,
      promotionDescription,
      promotionDescriptionArabic,
      partnerLogo,
      isActive: status,
    } = this.state.corporatePartner ?? {};

    if (!partnerName || partnerName.trim() === "")
      errors.partnerName = "Partner Name (English) cannot be blank!";
    else if (partnerName.length > 200)
      errors.partnerName = "Name (English) is limited to a 200 characters!";

    if (!partnerNameArabic || partnerNameArabic.trim() === "")
      errors.partnerNameArabic = "Partner Name (Arabic) cannot be blank!";
    else if (partnerNameArabic.length > 200)
      errors.partnerNameArabic =
        "Partner Name (Arabic) is limited to a 200 characters!";

    // if (!promotionHeader || promotionHeader.trim() === "")
    //   errors.promotionHeader = "Promotion Header (English) cannot be blank!";
    // else if (promotionHeader.length > 500)
    //   errors.promotionHeader =
    //     "Promotion Header (English) is limited to a 500 characters!";

    // if (!promotionHeaderArabic || promotionHeaderArabic.trim() === "")
    //   errors.promotionHeaderArabic =
    //     "Promotion Header (Arabic) cannot be blank!";
    // else if (promotionHeaderArabic.length > 500)
    //   errors.promotionHeaderArabic =
    //     "Promotion Header (Arabic) is limited to a 500 characters!";

    if (promotionDescription && promotionDescription.length > 1000)
      errors.promotionDescription =
        "Promotion Description (English) is limited to a 1000 characters!";

    if (promotionDescriptionArabic && promotionDescriptionArabic.length > 1000)
      errors.promotionDescriptionArabic =
        "Promotion Description (Arabic) is limited to a 1000 characters!";

    if (!partnerLogo) errors.partnerLogo = "Partner Logo cannot be blank";

    if (status === null || status === undefined)
      errors.status = "Status cannot be blank!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      corporatePartner: {},
      rankOptions: [],
      isSaving: false,
      isNewCorporatePartner: false,
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        onClick={() => this.handleView(id)}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.props.isEditable || this.state.isNewCorporatePartner) return null;
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  getFilteredCorporatePartners = () => {
    return this.getSearchedCorporatePartners(
      this.getCorporatePartnersForCurrentCountry()
    );
  };

  getCorporatePartnersForCurrentCountry = () => {
    const { corporatePartners } = this.state;
    const { country } = this.props;

    if (country && corporatePartners && corporatePartners.length) {
      const corporatePartnersForCountry = corporatePartners.filter(
        (it) => it.country === country.id
      );
      return corporatePartnersForCountry;
    }

    return [];
  };

  getSearchedCorporatePartners = (corporatePartners) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return corporatePartners;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return corporatePartners.filter((corporatePartner) => {
      const { id, partnerName, promotionHeader } = corporatePartner;
      return (
        (id && id.toString().includes(newValue)) ||
        (partnerName && partnerName.toLowerCase().indexOf(newValue) !== -1) ||
        (promotionHeader &&
          promotionHeader.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  render() {
    const {
      corporatePartner,
      isNewCorporatePartner,
      searchTerm,
      editables,
      errors,
      isSaving,
    } = this.state;
    const { isEditable = false } = this.props;
    const filteredCorporatePartners = this.getFilteredCorporatePartners();

    const detailsForm = (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="partnerName">Partner Name (English)*</label>
            <div className="input-group">
              <input
                value={corporatePartner.partnerName}
                onChange={this.handleChange}
                id="partnerName"
                name="partnerName"
                type="text"
                placeholder="Partner Name in English"
                className={
                  "form-control py-2" + (errors.partnerName ? " has-error" : "")
                }
                disabled={!isNewCorporatePartner && !editables.partnerName}
              />
              {this.renderPencilIcon("partnerName")}
            </div>
            <span className="help-block">{errors.partnerName}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="partnerNameArabic">Partner Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={corporatePartner.partnerNameArabic}
                onChange={this.handleChange}
                id="partnerNameArabic"
                name="partnerNameArabic"
                type="text"
                placeholder="Partner Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.partnerNameArabic ? " has-error" : "")
                }
                disabled={
                  !isNewCorporatePartner && !editables.partnerNameArabic
                }
              />
              {this.renderPencilIcon("partnerNameArabic")}
            </div>
            <span className="help-block">{errors.partnerNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="promotionHeader">Promotion Header (English)</label>
            <div className="input-group">
              <textarea
                value={corporatePartner.promotionHeader}
                onChange={this.handleChange}
                id="promotionHeader"
                name="promotionHeader"
                placeholder="Promotion Header in English"
                className={
                  "form-control py-2" +
                  (errors.promotionHeader ? " has-error" : "")
                }
                readOnly={!isNewCorporatePartner && !editables.promotionHeader}
              ></textarea>
              {this.renderPencilIcon("promotionHeader")}
            </div>
            <span className="help-block">{errors.promotionHeader}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="promotionHeaderArabic">
              Promotion Header (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={corporatePartner.promotionHeaderArabic}
                onChange={this.handleChange}
                id="promotionHeaderArabic"
                name="promotionHeaderArabic"
                placeholder="Promotion Header in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.promotionHeaderArabic ? " has-error" : "")
                }
                readOnly={
                  !isNewCorporatePartner && !editables.promotionHeaderArabic
                }
              ></textarea>
              {this.renderPencilIcon("promotionHeaderArabic")}
            </div>
            <span className="help-block">{errors.promotionHeaderArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="promotionDescription">
              Promotion Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={corporatePartner.promotionDescription}
                onChange={this.handleChange}
                id="promotionDescription"
                name="promotionDescription"
                placeholder="Promotion Description in English"
                className={
                  "form-control py-2" +
                  (errors.promotionDescription ? " has-error" : "")
                }
                readOnly={
                  !isNewCorporatePartner && !editables.promotionDescription
                }
              ></textarea>
              {this.renderPencilIcon("promotionDescription")}
            </div>
            <span className="help-block">{errors.promotionDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="promotionDescriptionArabic">
              Promotion Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={corporatePartner.promotionDescriptionArabic}
                onChange={this.handleChange}
                id="promotionDescriptionArabic"
                name="promotionDescriptionArabic"
                placeholder="Promotion Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.promotionDescriptionArabic ? " has-error" : "")
                }
                readOnly={
                  !isNewCorporatePartner &&
                  !editables.promotionDescriptionArabic
                }
              ></textarea>
              {this.renderPencilIcon("promotionDescriptionArabic")}
            </div>
            <span className="help-block">
              {errors.promotionDescriptionArabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={corporatePartner?.rank}
                onChange={(event, value) => {
                  this.setState({
                    corporatePartner: {
                      ...corporatePartner,
                      rank: value.value,
                    },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewCorporatePartner && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={corporatePartner.isActive}
                onChange={(event, value) => {
                  this.setState({
                    corporatePartner: {
                      ...corporatePartner,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCorporatePartner && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="partnerLogo">Partner Logo*</label>
            <div className="input-group">
              <input
                type="file"
                onChange={this.handleSelectImage}
                id="partnerLogo"
                name="partnerLogo"
                accept="image/*"
                disabled={!isNewCorporatePartner && !editables.partnerLogo}
                className={
                  "form-control" + (errors.partnerLogo ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("partnerLogo")}
            </div>
            <span className="help-block">{errors.partnerLogo}</span>
          </div>
          {corporatePartner?.partnerLogoSrc && (
            <div className="form-group col">
              <label htmlFor="selectedPartnerLogo">Selected Partner Logo</label>
              <div>
                <img
                  id="selectedPartnerLogo"
                  name="selectedPartnerLogo"
                  src={corporatePartner.partnerLogoSrc}
                  alt="Selected"
                  className="img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "200px",
                    objectFit: "cover",
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="partnerWebsite">Partner Website</label>
            <div className="input-group">
              <input
                value={corporatePartner.partnerWebsite}
                onChange={this.handleChange}
                id="partnerWebsite"
                name="partnerWebsite"
                type="text"
                placeholder="Partner Website"
                className={
                  "form-control py-2" +
                  (errors.partnerWebsite ? " has-error" : "")
                }
                disabled={!isNewCorporatePartner && !editables.partnerWebsite}
              />
              {this.renderPencilIcon("partnerWebsite")}
            </div>
            <span className="help-block">{errors.partnerWebsite}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Corporate Partners</p>
          <DataTable
            data={filteredCorporatePartners}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCorporatePartners.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Partner: </span>
                {corporatePartner?.partnerName ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              type="button"
              onClick={(event) =>
                this.setState({ isSaving: true }, this.handleSave)
              }
              disabled={!isEditable || isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CorporatePartners);
