import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import AlertBox from "components/AlertBox/AlertBox";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import Search from "components/Search/Search";
import SelectAgent from "pages/Orders/SelectAgent";
import { formatDateTime } from "utils/commons";
import Select from "react-select";
import PdfPreview from "components/Common/PdfPreview";
import CustomLoader from "components/CustomLoader/CustomLoader";
import "../NewOrdersRevamp/NewOrdersRevamp.scss";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_HOMECARE_SERVICES = "home-care-service/";

const priorityOptions = [
  {
    key: 1,
    value: "Low",
    label: "Low",
  },
  {
    key: 2,
    value: "Medium",
    label: "Medium",
  },
  {
    key: 3,
    value: "High",
    label: "High",
  },
];
const REQUEST_OPTIONS = [
  {
    key: "Open",
    value: "Open",
    label: "Open",
  },
  {
    key: "In Progress",
    value: "In Progress",
    label: "In Progress",
  },
  {
    key: "Resolved",
    value: "Resolved",
    label: "Resolved",
  },
];
class ViewAllMyTickets extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      selectedOption: null,
      agents: [],
      orders: [],
      cities: [],
      countries: [],
      coupons: [],
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      isCreateOrderViewModalVisible: false,
      allTickets: [],

      ticket: {},

      homeCareServices: [],

      selectedBucket: "",

      errors: {},

      isLoading: false,
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      selectedHomecareService: null,
      ticketHistory: [],
      bucketList: [],
      images: [],
      isCustomLoader:false,
    };
  }
  componentDidMount() {
    this.fetchAclUser();
    this.fetchAgents();
    this.fetchAllTickets();
    this.fetchBuckets();
    this.fetchHomecareServices();
  }

  ticketsColumns = () => {
    return [
      {
        name: "Homecare",
        selector: "homecare_name",
        sortField: "homecare_name",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Ticket Id",
        selector: "ticket_uuid",
        sortField: "ticket_uuid",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Category",
        selector: "ticket_category",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Title",
        selector: "title",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Created At",
        selector: "homecare_name",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
        format: (row) => {
          return formatDateTime(row.created_at);
        },
      },
      {
        name: "Status",
        selector: "ticket_status",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Agent Assigned",
        selector: "agent_assigned",
        sortField: "agentId",
        wrap: true,
        sortable: false,
        minWidth: "200px",
        center: true,
        cell: (ticket) => this.renderAgentDropdown(ticket),
      },
      {
        name: "Assign Priorty",
        selector: "resolution_priority",
        sortField: "resolution_priority",
        wrap: true,
        sortable: false,
        minWidth: "200px",
        center: true,
        cell: (ticket) => this.renderPriorityDropdown(ticket),
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (ticket) => this.renderViewButton(ticket.id),
      },
    ];
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CART_ORDERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchAgents = async () => {
    try {
      const { data: agents = [] } = await PhoenixAPI.get(
        "/admin/operations-users"
      );
      const operationUsers = agents.map((user) => {
        return {
          email: user.email,
          firstName: user.fname,
          lastName: user.lname,
          id: user.id,
        };
      });
      this.setState({ agents: operationUsers });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
    }
  };

  fetchAllTickets = async (page = 0, ticketStatus = "UNASSIGNED", agentId) => {
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      selectedHomecareService,
      searchApplied,
      searchTerm,
    } = this.state;
    const params = {
      page,
      page_size: rowsPerPage,
      ticketStatus,
      homecareId: selectedHomecareService?.value ?? null,
      agentId: agentId,
    };

    if (searchApplied)
      params.searchTerm = searchTerm ? searchTerm.trim() : searchTerm;

    try {
      this.showLoading();
      const { data } = await PhoenixAPI.get(`admin/all-tickets`, {
        params,
      });
      const { content } = data ?? [];
      this.setState({ allTickets: content, selectedBucket: ticketStatus }, () =>
        this.fetchBuckets()
      );
    } catch (error) {
      console.log("Error in Fetching  Orders");
      this.showErrorNotification(
        "Some Error occured in fetching orders:",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
  fetchBuckets = async () => {
    try {
      this.showLoading();
      const { data = [] } = await PhoenixAPI.get(`admin/ticket/status-count`);

      this.setState({ bucketList: data });
    } catch (error) {
      console.log("Error in Fetching bucket count");
      this.showErrorNotification(
        "Some Error occured in fetching bucketing response",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };
  handleAgentSelect = async (order, agentId) => {
    const { selectedBucket = "" } = this.state;
    const page = 0;
    try {
      this.showLoading();
      const payload = {
        assigned_agent_id: agentId,
      };
      const response = await PhoenixAPI.patch(
        `admin/ticket/${order.id}`,
        payload
      );
      this.fetchAllTickets(page, selectedBucket);
    } catch (error) {
      console.log("Error in saving  agent", error.message);
    } finally {
      this.hideLoading();
    }
  };
  fetchHomecareServices = async () => {
    try {
      this.showLoading();
      const { data: services = [] } = await PhoenixAPI.get(
        BASE_URL_HOMECARE_SERVICES
      );
      const activeHomecareServices = services.filter(
        (item) => item.isActive === true
      );
      const homecareServiceOptions = activeHomecareServices.map((service) => {
        return {
          key: service.id,
          value: service.id,
          label: service.name,
        };
      });

      this.setState({ homecareServices: homecareServiceOptions });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
  renderAgentDropdown = (order) => {
    const { order_status } = order || {};
    return (
      <SelectAgent
        agents={this.state.agents ?? []}
        selectedAgentId={order.assigned_agent_id}
        onAgentSelect={(agent_assigned) =>
          this.handleAgentSelect(order, agent_assigned)
        }
        isDisabled={!this.state.isEditable}
      />
    );
  };

  handleAssignPriority = async (order, priority) => {
    const { selectedBucket = "" } = this.state;
    const page = 0;
    try {
      this.showLoading();
      const payload = {
        resolutionPriority: priority,
      };
      const response = await PhoenixAPI.patch(
        `admin/ticket/${order.id}`,
        payload
      );
      this.fetchAllTickets(page, selectedBucket);
    } catch (error) {
      console.log("Error in saving  resolution priority", error.message);
    } finally {
      this.hideLoading();
    }
  };
  renderPriorityDropdown = (ticket) => {
    const { resolution_priority } = ticket || {};
    const selectedPriority = priorityOptions.find(
      (priority) => priority.value === resolution_priority
    );
    const titleOption = (
      <option key={-1} value={-1} hidden={true}>
        {"Assign Priority"}
      </option>
    );

    const priorities = priorityOptions.map((priority) => {
      return (
        <option key={priority.id} value={priority.value}>
          {priority.label}
        </option>
      );
    });

    return (
      <select
        value={resolution_priority ?? ""}
        onChange={(event) =>
          this.handleAssignPriority(ticket, event.target.value)
        }
        name="select_priorities"
        className="form-control"
        disabled={!this.state.isEditable}
      >
        {!selectedPriority && titleOption}
        {priorities}
      </select>
    );
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewTicket(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleViewTicket = async (ticketId) => {
    const allTickets = this.state.allTickets ?? [];
    const ticket = allTickets.find((item) => item.id === ticketId);
    console.log("handleViewTickethandleViewTicket", ticket);
    if (!ticket) {
      console.log("No ticket is found in the list of tickets");
      return;
    }
    try {
      this.showLoading();
      const { data = {} } = await PhoenixAPI.get(`ticket/${ticket.id}`);
      const { latestTicket = {}, ticketHistory = [] } = data ?? {};
      console.log("latestTicket", latestTicket, ticketHistory);
      const selectedOption = {
        key: ticket.ticket_status,
        value: ticket.ticket_status,
        label: ticket.ticket_status,
      };
      const images = latestTicket.adminHomecareRemarksMediaS3Url ?? [];
      this.setState({
        ticket: latestTicket,
        isShowTicketModal: true,
        ticketHistory,
        selectedOption,
        images,
      });
    } catch (error) {
      console.log("Error in fetching ticket", error.message);
      this.showErrorNotification("Error on fetching ticket: ", error.message);
    } finally {
      this.hideLoading();
    }
  };
  validate = () => {
    const { ticket } = this.state;
    const errors = {};
    const { adminHomecareRemarks, adminInternalRemarks } = ticket;
    if (!adminHomecareRemarks || adminHomecareRemarks.trim() === "") {
      errors.adminHomecareRemarks = "Remarks is required";
    } else if (adminHomecareRemarks && adminHomecareRemarks.length > 1000)
      errors.adminHomecareRemarks = "Remarks is limited to a 1000 characters";

    if (!adminInternalRemarks || adminInternalRemarks.trim() === "") {
      errors.adminInternalRemarks = "Internal Remarks is required";
    } else if (adminInternalRemarks && adminInternalRemarks.length > 1000)
      errors.adminInternalRemarks =
        "Internal Remarks is limited to a 1000 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };
  handlesaveTicket = async () => {
    const { ticket, images } = this.state ?? {};
    if (ticket.ticketStatus === "Resolved") {
      const errors = this.validate();
      this.setState({ errors: errors || {} });
      if (errors) {
        this.showErrorNotification("There are some invalid form fields!");

        return;
      }
    }
    const utcTime = new Date().toISOString();
    const payload = {
      ticketStatus: ticket.ticketStatus,
      assigned_agent_id: ticket.assigned_agent_id ?? null,
      resolutionPriority: ticket.resolutionPriority ?? "",
      adminHomecareRemarks: ticket.adminHomecareRemarks ?? "",
      adminHomecareRemarksMediaS3Url: images ?? "",
      adminInternalRemarks: ticket.adminInternalRemarks ?? "",
      resolvedAt:
        ticket.ticketStatus === "Resolved" ? utcTime : ticket.resolvedAt,
    };
    try {
      this.setState({isCustomLoader: true});
      const response = await PhoenixAPI.patch(
        `admin/ticket/${ticket.id}`,
        payload
      );
      this.showSuccessNotification("Ticket is Updated Successfully");
      this.hideTicketModal();
      this.fetchAllTickets();
    } catch (error) {
      console.log("Error in updating ticket", error.message);
      this.showErrorNotification("Error in updating  ticket", error.message);
    } finally {
      this.setState({isCustomLoader: false});
    }
  };

  hideTicketModal = () => {
    this.setState({ isShowTicketModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({ ticket: {}, errors: {}, images: [] });
  };
  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    // this.setState({ searchApplied: true, searchTerm });
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    const page = 0;
    const ticketStatus = "ALL";
    this.setState({ searchApplied: true, allTickets: [] }, () =>
      this.fetchAllTickets(page, ticketStatus)
    );
  };

  handleSearchClear = () => {
    const page = 0;
    const ticketStatus = "UNASSIGNED";
    this.setState(
      { searchTerm: "", searchApplied: false, allTickets: [] },
      () => {
        this.fetchAllTickets(page, ticketStatus);
      }
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleViewMyOrders = async () => {
    const userId = getUserId();
    this.setState({ userId });
    const page = 0;
    const ticketStatus = "ALL";
    await this.fetchAllTickets(page, ticketStatus, userId);
  };

  handleBucket = async (item) => {
    let { status = "" } = item || {};

    const page = 0;
    const ticketStatus = status;
    this.setState({ allTickets: [], selectedBucket: status });
    await this.fetchAllTickets(page, ticketStatus);
  };
  renderBucketList = () => {
    const { selectedBucket, bucketList } = this.state;
    return (
      <div className="bucket-container">
        {bucketList.map((item, index) => {
          return (
            <div className="bucket-contentDiv" key={index}>
              <div
                className={`bucket-content-main ${
                  selectedBucket === item.status ? "selectedBucket" : ""
                }`}
                onClick={() => this.handleBucket(item)}
              >
                <div className="bucket-count">{item.count}</div>
                <div
                  className={`bucket-name ${
                    selectedBucket === item.status ? "selectedBucket" : ""
                  }`}
                >
                  {item.displayName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  handleChange = ({ currentTarget: input }) => {
    const { ticket } = this.state;
    ticket[input.name] = input.value;
    this.setState({ ticket });
  };
  handleUploadAttachments = async (file) => {
    try {
      this.setState({isCustomLoader:true})
      const formData = new FormData();
      formData.append("media", file);
      const response = await PhoenixAPI.post("/upload-media", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": "multipart/form-data",
        },
      });
      const data = response && response.data;
      return data.message ?? "";
    } catch (error) {
      console.log("Error on uploading attachments", error.message);
    }
    finally {
      this.setState({isCustomLoader:false})
    }
  };
  handleSelectImage = async (e) => {
    const images = this.state.images ?? [];
    const file = e.target.files[0];
    if (file) {
      const uploadedImage = await this.handleUploadAttachments(file);

      this.setState({ images: [...this.state.images, uploadedImage] });
      if (this.fileInputRef.current && this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  };

  getAgentName = (agentId) => {
    const { agents = [] } = this.state;

    const agent = agents.find((item) => item.id === agentId);

    return agent?.firstName + " " + agent?.lastName ?? "Unassigned";
  };
  renderAgentName = () => {
    const { ticket } = this.state ?? {};
    const agentName = ticket?.assigned_agent_id
      ? this.getAgentName(ticket.assigned_agent_id)
      : "Unassigned";
    return <div>{agentName}</div>;
  };
  renderClosableImages = (image) => {
    const isPdfFile =
      image?.type === "application/pdf" ||
      (typeof image === "string" && image.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <div style={{ width: "150px" }}>
        <PdfPreview
          pdfFile={isPdfFile ? image : null}
          pdfFileUrl={isPdfFile ? null : image}
        />
      </div>
    ) : (
      <img src={image} alt="Selected" />
    );
    return <div className="image-area">{preview}</div>;
  };
  downloadFile = (url) => {
   
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      searchTerm,
      totalRows,
      rowsPerPage,
      allTickets,
      searchApplied,
      loadError,
      homecareServices,
      selectedHomecareService,
      ticket,
      errors,
      images,
      selectedOption,
      ticketHistory,
    } = this.state;
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }
    const selectStyles = {
      container: (base) => ({ ...base, flex: 1 }),
    };
    const formTicketModal = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col"></div>
          <div className="form-group col">
            <div className="agent-name-div">
              Agent Assigned : {this.renderAgentName()}
            </div>
            <Select
              key={`my_unique_select_key__${
                selectedOption && selectedOption.id
              }`}
              value={selectedOption ?? null}
              onChange={(option) =>
                this.setState({
                  selectedOption: {
                    key: option.value,
                    value: option.value,
                    label: option.label,
                  },
                  ticket: { ...ticket, ticketStatus: option.value },
                  errors: {},
                })
              }
              options={REQUEST_OPTIONS}
              styles={selectStyles}
              menuPlacement={"bottom"}
              placeholder={"Filter by"}
              isDisabled={!(ticket?.assigned_agent_id ?? false)}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">Homecare : {ticket.homecareName}</div>
        </div>
        <div className="row">
          <div className="form-group col">
            Created At : {formatDateTime(ticket.createdAt)}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            Priority : {ticket?.resolutionPriority ?? ""}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            Category : {ticket.ticketCategory}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">Title : {ticket.title}</div>
        </div>

        <div className="row" style={{ marginTop: "5px" }}>
          <div className="form-group col-6">
            <label htmlFor="description">Description : </label>
            <div className="input-group">
              <textarea
                value={ticket.description || ""}
                onChange={this.handleChange}
                id="description"
                name="description"
                placeholder="Description"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={true}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="description" className="label">
              Images :
            </label>
            <div className="media-scroller snaps-inline">
              {ticket.mediaS3Url &&
                ticket.mediaS3Url.map((item) => {
                  return (
                    <>
                      <div className="upload-file-box">
                        <div className="choose-fileand-icon">
                          <img
                            className="uplooad-icon"
                            src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                            alt=""
                          />
                          <span
                            className="choose-file"
                            onClick={() => this.downloadFile(item)}
                          >
                            Download File
                          </span>
                        </div>
                        {this.renderClosableImages(item)}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col separator"></div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="adminHomecareRemarks">
              Add Remarks <span className="important-asterisk">*</span> :
            </label>
            <div className="input-group">
              <textarea
                value={ticket.adminHomecareRemarks || ""}
                onChange={this.handleChange}
                id="adminHomecareRemarks"
                name="adminHomecareRemarks"
                placeholder="Add Remarks"
                className={
                  "form-control py-2" +
                  (errors.adminHomecareRemarks ? " has-error" : "")
                }
                readOnly={!(ticket?.assigned_agent_id ?? false)}
              ></textarea>
              {/* {this.renderPencilIcon("longDescriptionArabic")} */}
            </div>
            <span className="help-block">{errors.adminHomecareRemarks}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="images" className="label">
              Add Images
            </label>
            <div className="input-group ticket">
              <input
                onChange={(e) => this.handleSelectImage(e)}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                ref={this.fileInputRef}
                className={
                  "form-control py-2" + (errors.images ? " has-error" : "")
                }
                disabled={!(ticket?.assigned_agent_id ?? false)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="media-scroller snaps-inline">
            {images &&
              images.length > 0 &&
              images.map((image, index) => {
                return (
                  <div key={index} className="media-element">
                  {this.renderClosableImages(image, index)}
                </div>
                );
              })}
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="adminInternalRemarks">
              Internal Remarks <span className="important-asterisk">*</span>
            </label>
            <div className="input-group">
              <textarea
                value={ticket.adminInternalRemarks || ""}
                onChange={this.handleChange}
                id="adminInternalRemarks"
                name="adminInternalRemarks"
                placeholder="Add Internal Remarks"
                className={
                  "form-control py-2" +
                  (errors.adminInternalRemarks ? " has-error" : "")
                }
                readOnly={!(ticket?.assigned_agent_id ?? false)}
              ></textarea>
              {/* {this.renderPencilIcon("longDescriptionArabic")} */}
            </div>
            <span className="help-block">{errors.adminInternalRemarks}</span>
          </div>
        </div>
        <div className="" style={{ display: "flex", justifyContent: "center" }}>
          <button
            onClick={this.handlesaveTicket}
            disabled={!(ticket?.assigned_agent_id ?? false)}
            className="btn modal-save-btn"
          >
            Save
          </button>
        </div>
      </div>
    );
    const formTicketHistory = (
      <div className="container mt-4">
        {ticketHistory.length > 0
          ? ticketHistory.map((ticket) => {
              return (
                <>
                  <div className="row">
                    <div className="form-group col">
                      Homecare : {ticket.homecareName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      Created At : {formatDateTime(ticket.createdAt)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      Priority : {ticket?.resolutionPriority ?? ""}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      Category : {ticket.ticketCategory}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">Title : {ticket.title}</div>
                  </div>

                  <div className="row" style={{ marginTop: "5px" }}>
                    <div className="form-group col-6">
                      <label htmlFor="description">Description : </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.description || ""}
                          onChange={this.handleChange}
                          id="description"
                          name="description"
                          placeholder="Description"
                          className={
                            "form-control py-2" +
                            (errors.longDescriptionArabic ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <label htmlFor="description" className="label">
                        Images :
                      </label>
                      <div className="media-scroller snaps-inline">
                        {ticket.mediaS3Url &&
                          ticket.mediaS3Url.map((item) => {
                            return (
                              <>
                                <div className="upload-file-box">
                                  <div className="choose-fileand-icon">
                                    <img
                                      className="uplooad-icon"
                                      src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                      alt=""
                                    />
                                    <span
                                      className="choose-file"
                                      onClick={() => this.downloadFile(item)}
                                    >
                                      Download File
                                    </span>
                                  </div>
                                  {this.renderClosableImages(item)}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="adminHomecareRemarks">
                        Add Remarks{" "}
                        <span className="important-asterisk">*</span> :
                      </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.adminHomecareRemarks || ""}
                          onChange={this.handleChange}
                          id="adminHomecareRemarks"
                          name="adminHomecareRemarks"
                          placeholder="Add Remarks"
                          className={
                            "form-control py-2" +
                            (errors.adminHomecareRemarks ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                        {/* {this.renderPencilIcon("longDescriptionArabic")} */}
                      </div>
                      <span className="help-block">
                        {errors.adminHomecareRemarks}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="images" className="label">
                        Add Images
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="media-scroller snaps-inline">
                      {ticket.adminHomecareRemarksMediaS3Url &&
                        ticket.adminHomecareRemarksMediaS3Url.length > 0 &&
                        ticket.adminHomecareRemarksMediaS3Url.map(
                          (image, index) => {
                            return (
                              <div className="upload-file-box">
                        <div className="choose-fileand-icon">
                          <img
                            className="uplooad-icon"
                            src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                            alt=""
                          />
                          <span
                            className="choose-file"
                            onClick={() => this.downloadFile(image)}
                          >
                            Download File
                          </span>
                        </div>
                        {this.renderClosableImages(image)}
                      </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="adminInternalRemarks">
                        Internal Remarks{" "}
                        <span className="important-asterisk">*</span>
                      </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.adminInternalRemarks || ""}
                          onChange={this.handleChange}
                          id="adminInternalRemarks"
                          name="adminInternalRemarks"
                          placeholder="Add Internal Remarks"
                          className={
                            "form-control py-2" +
                            (errors.adminInternalRemarks ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                        {/* {this.renderPencilIcon("longDescriptionArabic")} */}
                      </div>
                      <span className="help-block">
                        {errors.adminInternalRemarks}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col separator"></div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    );
    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="col-3 ml-0 pl-0">
            <Select
              key={`my_unique_select_key__${
                selectedHomecareService && selectedHomecareService.id
              }`}
              value={selectedHomecareService ?? null}
              onChange={(option) =>
                this.setState(
                  {
                    selectedHomecareService: {
                      key: option.value,
                      value: option.value,
                      label: option.label,
                    },
                  },
                  () => this.fetchAllTickets()
                )
              }
              options={homecareServices}
              styles={selectStyles}
              menuPlacement={"bottom"}
              placeholder={"Select Homecare Service"}
              isDisabled={!this.state.isEditable}
            />
          </div>
        </div>
        {this.renderBucketList()}
        <div className="row mb-1">
          <div className="col">
            <button
              onClick={(e) => this.handleViewMyOrders()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              View My Tickets
            </button>
          </div>
          <div className="ml-auto mr-3">
            <Search
              searchExpanded={true}
              searchTerm={searchTerm}
              handleSearch={this.handleSearch}
              handleSearchEnter={this.handleSearchEnter}
              clearSearch={this.handleSearchClear}
              handleSearchExit={this.handleSearchExit}
              ref={(input) => {
                this.search = input;
              }}
            ></Search>
          </div>
          <div className="action-item" style={{ marginRight: "12px" }}>
            <button
              className="btn button-blue btn-md new-user"
              onClick={this.handleSearchEnter}
            >
              Search
            </button>
          </div>
        </div>
        <Card body>
          <DataTable
            data={allTickets ?? []}
            columns={this.ticketsColumns() || []}
            sortServer
            onSort={this.handleSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={totalRows ?? 0}
            paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            // fixedHeader
            // fixedHeaderScrollHeight="1200px"
            title={
              searchApplied ? (
                <p className="table-header">{"Found " + " results"}</p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isShowTicketModal}
          onHide={this.hideTicketModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  Ticket Id : {ticket.ticketUUID}{" "}
                </span>
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="key_form_ticket_information" justify>
              <Tab
                eventKey="key_form_ticket_information"
                title="Ticket Details"
              >
                {formTicketModal}
              </Tab>
              <Tab eventKey="key_form_ticket_history" title="Ticket History">
                {formTicketHistory}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <CustomLoader show={this.state.isCustomLoader}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAllMyTickets);
