import React from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

ToggleSwitch.propTypes = {
  key: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  toggled: PropTypes.bool,
  disabled: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

ToggleSwitch.defaultProps = {
  id: "toggle-switch",
  label: "",
  toggled: false,
  disabled: false,
  onToggle: (f) => f,
};

function ToggleSwitch(props) {
  const { key, id, label, toggled, disabled, onToggle } = props;
  return (
    <Checkbox
      key={key}
      id={id}
      checked={toggled}
      onChange={(e, data) => onToggle(data.checked)}
      label={label}
      toggle
      disabled={disabled}
    />
  );
}

export default ToggleSwitch;
