import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import "./searchbar.scss";

export default function SearchBar(props) {
  const {
  searchFlag,
  searchValue
  } = props;
  const [inputText, setInputText] = useState("");
  const inputRef = React.createRef();
  const history = useHistory();

  function handleChange(e) {
    
    setInputText(e.target.value)
    searchFlag(true);
    searchValue(e.target.value)
  }
  const handleSearch = async () => {
 
      let inputSearchText = inputText && inputText.trim().toLowerCase();
 
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
    
    //   setSearchFlag(true);
      handleSearch();
    }
  };
  const handleCrossBtn = () => {
   
    setInputText("");
    searchFlag(false);
    searchValue("")
  };

  const redirectPastorders=()=>{
    
     return history.push("/dashboard/nurse/past-orders")
  }

  return (
    <div>
      <div className="search-container-nurse">
      <div className="valeo-logo-img">
        {" "}
        <img
          src="https://d25uasl7utydze.cloudfront.net/assets/valeo_logo%20(1).svg"
          className="mob-image "
        ></img>{" "}
      </div>

      <div className="input-form">
        <input
          ref={inputRef}
          type="search"
          placeholder="Search"
          value={inputText}
          onKeyPress={handleKeyPress}
          onChange={(e) => handleChange(e)}
        />
        <img
          className="search-icon"
          src="https://d25uasl7utydze.cloudfront.net/assets/search.svg"
          alt=""
        />
        {inputText && (
          <img
            className="cross-icon"
            onClick={() => handleCrossBtn()}
            src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg"
            alt=""
          />
        )}
      </div>
      <div className="past-orders-div" onClick={()=>redirectPastorders()}>
        <div className="past-div">Past Orders</div>
       <div className="lt">&gt;</div>
      </div>
    </div>
    </div>
   
  );
}
