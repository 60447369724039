import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import moment from "moment";
import { getUserType } from "services/UserService";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ModalPopup from "components/ModalPopup/ModalPopup";
import { formatDateTime } from "utils/commons";
import Status from "components/Common/Status";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const BASE_URL_ROLES = "roles/";
const ROLE_ADMIN_USER_ID = 5;

class AdminUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      users: null,
      isLoaded: false,
      loadError: "",
      searchExpanded: true,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      userType: getUserType(),
      showModal: false,
      newUser: false,
      modalData: {},
      key: "personal",
      userTransactions: [],
      userEmail: "",
      userName: "",
      userPassword: "",
      label: "",
      address_line1: "",
      address_line2: "",
      city: "",
      building_apt: "",
      firstname: "",
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      keyActiveTab: "key_details_form",
      errors: {},
      editables: {},
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
      roles: [],
      emailErrorMessage:"",
      emailErrorNotify:false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "120px"
      },
      {
        name: "Name",
        // selector: "username",
        selector: "full_name",
        sortable: true,
        wrap: true
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        wrap: true,
        // width: "250px",
      },
      {
        name: "Role",
        selector: "role",
        minWidth: "180px",
        center: true,
        sortable: true,
        sortField: 'role',
        cell: (row) => this.renderRoleDropdown(row),
      },
      {
        name: "Description",
        selector: "description",
        sortable: true,
        wrap: true,
      },
      // {
      //   name: "Creation Date",
      //   selector: "last_login",
      //   sortable: true,
      //   wrap: true,
      //   format: row => row.last_login ? formatDateTime(row.last_login) : "-"
      // },
      // {
      //   name: "Joining Date",
      //   selector: "date_joined",
      //   sortable: true,
      //   wrap: true,
      //   format: (row) => row.date_joined ? formatDateTime(row.date_joined) : "-",
      // },
      // {
      //   name: "Enabled/Disabled",
      //   maxWidth: "150px",
      //   center: true,
      //   sortable: true,
      //   selector: "is_active",
      //   format: (row) =>
      //     <div className="toggle-switch">
      //       <input
      //         type="checkbox"
      //         className="toggle-switch-checkbox"
      //         name={row.id}
      //         checked={row.is_active}
      //         defaultChecked={row.is_active}
      //         value={row.is_active}
      //         onClick={this.toggleActive}
      //         // onchange={this.toggleActive(row)}
      //         id={row.id}
      //       />
      //       <label className="toggle-switch-label" htmlFor={row.id}>
      //         <span className="toggle-switch-inner" data-yes="Active" data-no="Deactive" />
      //         <span className="toggle-switch-switch" />
      //       </label>
      //     </div>
      // },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.is_active} />,
      },
      {
        name: "View",
        center: true,
        cell: (row) => (
          <button
            // className="btn button-blue btn-md"
            className="btn px-5"
            style={{ backgroundColor: "#CEE741" }}
            onClick={(e) => {
              this.showModal(row);
            }}
          >
            View
          </button>
        ),
      },
    ];

    this.transactionColumns = [
      {
        name: "Date",
        selector: "transaction_date",
        sortable: true,
        width: "180px",
      },
      {
        name: "Order ID",
        selector: "order",
        sortable: true,
        width: "180px",
      },
      {
        name: "Package",
        selector: "package",
        sortable: true,
        width: "180px",
      },
      {
        name: "Price",
        selector: "final_amount",
        sortable: true,
        width: "180px",
      },
    ];

    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.onUserPageChange = this.onUserPageChange.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive({ target }) {
    let params = {};
    this.props.toggleLoading({
      isLoading: true,
    });
    params['id'] = target.id;
    params['is_active'] = target.checked;
    API.patch("create-admin-users/" + target.id + '/', params)
      .then((response) => {
        this.loadClients();
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Admin User has been successfully " + (target.checked ? "Deactivated ." : "Activated ."),
          successMessage: true,
          showNotification: true,
        });

      })
      .catch((error) => {
        console.log("Error on toggling status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Something Went Wrong ",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  renderRoleDropdown = (row) => {
    // console.log("row", row)
    const {role_id=null}= row;
    return (
      <select
        className="form-control"
        onChange={(e) => this.assignRole(e.target.value, row)}
        value={role_id??""}
      >
        {this.state.roles_dropdown_list && this.state.roles_dropdown_list.map((status, key) => {
          return (
            <option key={key} value={status.value}>
              {status.text}
            </option>)
        })
        }
      </select>
    )
  }

  async componentDidMount() {
    this.fetchAclUser();
    await this.fetchRoles();
    this.loadClients();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.ADMIN_USERS
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.state != undefined &&
      this.props.location.state != null
    ) {
      if (
        prevProps.location.state.length !== this.props.location.state.length
      ) {
        this.loadClients();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  onUserPageChange() {
    if (this.state.nextURL) {
      let url = this.state.nextURL;
      let nextURL = "";
      let orderResonse = [];

      this.props.toggleLoading({
        isLoading: true,
      });
      API.get(url)
        .then((response) => {
          nextURL =
            response.data && response.data.next ? response.data.next : "";
          orderResonse =
            response.data && response.data.results ? response.data.results : [];
          let allClient = [...this.state.users, ...orderResonse];
          let totalPage = response.data && response.data.count ? response.data.count : 0;
          this.setState({
            users: allClient,
            nextURL,
            nextURL,
            isLoaded: true,
            totalPage: totalPage,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on fetching users", error);
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  loadClients() {
    this.props.toggleLoading({
      isLoading: true,
    });

    let url;
    let orderResonse = [];
    let nextURL;
    // if (this.state.currentURL) {
    //   url = this.state.currentURL
    // } else {
    // url = "list-admin-users/";
    url = "create-admin-users/";

    API.get(url)
      .then((response) => {
        nextURL = response.data && response.data.next ? response.data.next : "";
        orderResonse = response.data || [];
        let totalPage = response.data && response.data.count ? response.data.count : 0;
        this.setState({
          users: orderResonse.map(user => ({
            ...user, 
            full_name: `${user.first_name || ""}${user.last_name ? " " + user.last_name : ""}`
          })),
          nextURL,
          isLoaded: true,
          totalPage: totalPage,
        });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching admin users", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  fetchRoles = async () => {
    try {
      this.showLoading();
      // const response = await API.get(BASE_URL_ROLES)
      const response= await PhoenixAPI.get("/api/v1/acl/roles"); // acl roles API 
      
      const roles = response.data || [];

      let roles_dropdown_list = await roles.map((role) => {
        return {
          text: role.name,
          value: role.id,
          key: role.id,
        }
      })
      roles_dropdown_list.splice(0, 0, { 'text': 'None', 'value': '', 'key': '' })
      this.setState({ roles, roles_dropdown_list: roles_dropdown_list })
      this.hideLoading();
    }
    catch (error) {
      console.error(
        "Error on fetching roles from this URL: ",
        BASE_URL_ROLES
      );
      console.error("Error on fetching roles", error.message);

      this.hideLoading();
      this.showErrorNotification(
        "Error on loading roles: ",
        error.message
      );
    }
  }

  assignRole = (roleId, row) => {
    const id = row.id;
    let formData = new FormData();
    formData.append("role_id", roleId);
    if (!roleId) return;
    this.showLoading();
    API.patch("create-admin-users/" + id + "/", formData)
      .then((response) => {
        this.loadClients();
       
        const data= response && response.data || {};
        this.createPagePermission(data)
        this.hideLoading();
      })
      .catch((error) => {
        console.log("Error on assigning lab", error);
        this.hideLoading();
        this.showErrorNotification("Some error has occured. Please try again.", error.message)
      })
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        // searchExpanded: false
      });

      return;
    }

    list = this.state.users;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        (item.username &&
          item.username.toLowerCase().indexOf(newValue) !== -1) ||
        (item.email && item.email.toLowerCase().indexOf(newValue) !== -1) ||
        (item.phone_no && item.phone_no.indexOf(newValue) !== -1)
      ) {
        newList.push(item);
      }
    }

    this.setState({
      // searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      // searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        // searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        // searchExpanded: true
      });
    }
  }

  createNewUser = () => {
    const modalData = { is_active: false, role_id: ROLE_ADMIN_USER_ID  }
    this.setState({ showModal: true, newUser: true, modalData }, () => {
      this.showModal({});
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      key: "personal",
      newUser: false,
      modalData: {},
      fnameErrorMessage: '',
      emailErrorMessage: '',
      lnameErrorMessage: '',
      passwordErrorMsg: '',
      keyActiveTab: "key_details_form",
      errors: {},
      editables: {},
      emailErrorMessage:"",
      emailErrorNotify:false,
    });
    // this.clearForm();
  };

  showModal = (row) => {
    // console.log({ row });
    if (this.state.newUser) {
      if ($(".modal-backdrop").length > 0 && this.state.showModal) {
        setTimeout(function () {
          $(".modal-backdrop").addClass("modal-backdrop-updated");
        });
      }
    } else {
      const name = row.username;
      const email = row.email;

      if (!row.first_name && !row.last_name && name) {
        const nameArray = name.split(" ");
        row['first_name'] = nameArray && nameArray.length > 0 ? nameArray[0] : "";
        row['last_name'] = nameArray && nameArray.length > 1 ? nameArray[1] : "";
      }

      if (!row.role_id) {
        row["role_id"] =
           ROLE_ADMIN_USER_ID
        
      }
      this.setState(
        {
          showModal: true,
          modalData: row,
          userName: name,
          userEmail: email,
        },
        () => {

          if ($(".modal-backdrop").length > 0 && this.state.showModal) {
            setTimeout(function () {
              $(".modal-backdrop").addClass("modal-backdrop-updated");
            });
          }
        }
      );
    }
  };

  makeEditable = (key) => {
    this.setState({
      [key]: true,
    });
  };

  numberCheck = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null) {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          modalData: {
            ...this.state.modalData,
            phone_no: `+${data.dialCode}` + value.slice(data.dialCode.length),
          }, contactNoErrorMessage: "",
        });
      }
    }
  };

  handleInput = (e, data) => {
    if (data === "password") {
      this.setState({ passwordErrorMsg: "", userPassword: e.target.value });
    }
    if (data === "role_id") {
      this.setState({
        modalData: {
          ...this.state.modalData,
          role_id: e.target.value
        }
      })
    }
    else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [data]: e.target.value,
        },
        emailErrorMessage: "",
        fnameErrorMessage: "",
        lnameErrorMessage: "",
      });
    }
  };

  validate = () => {
    const NAME_PATTERN = /^[a-z0-9]+$/i;
    const EMAIL_PATTERN = new RegExp(/^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/);
    const errors = {};
    const { newUser, userPassword, modalData = {} } = this.state;
    const { first_name, last_name, email, description } = modalData;

    const isValidName = (name ) => name.split(/[ ]+/).every(n => NAME_PATTERN.test(n));

    if (first_name && !isValidName(first_name))
      errors.first_name = "Invalid First Name! (only letters, numbers and space are allowed)";
    else if (first_name && first_name.length > 50)
      errors.first_name = "Invalid First Name! (can not be longer than 50 characters)";

    if (last_name && !isValidName(last_name))
      errors.last_name = "Invalid Last Name! (only letters, numbers and space are allowed)";
    else if (last_name && last_name.length > 50)
      errors.last_name = "Invalid Last Name! (can not be longer than 50 characters)";

    if (!email || email.trim() === "")
      errors.email = "Email is a required field!";
    else if (!EMAIL_PATTERN.test(email))
      errors.email = "Invalid email!"

    if (newUser) {
      if (!userPassword || userPassword.trim() === "")
        errors.password = "Password is a required field!";
      else if (userPassword.length < 8)
        errors.password = "Password has to be 8 characters long and not empty";
    }

    if (description && description.length > 100)
      errors.description = "Invalid Description! (can not be longer than 100 characters)"

    return Object.keys(errors).length === 0 ? null : errors;
  }

  saveUserData = () => {
    // const { modalData, newUser, userPassword } = this.state;

    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) {
      this.setState({emailErrorMessage:"",emailErrorNotify:false})
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    this.saveAdminUser();

    // Duplicate code was there for POST and PATCH request, that's why created a new function saveAdminUser
    // if (newUser) {
    //   let payload;
    //   let pattern = new RegExp(
    //     /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
    //   );

    //   if (modalData.first_name === undefined)
    //     this.setState({
    //       fnameErrorMessage: "Firstname is a required field!",
    //     });
    //   if (modalData.last_name === undefined)
    //     this.setState({
    //       lnameErrorMessage: "Lastname is a required field!",
    //     });
    //   if (userPassword.length < 8)
    //     this.setState({
    //       passwordErrorMsg:
    //         "Password has to be 8 characters long and not empty",
    //     });
    //   if (!pattern.test(modalData.email))
    //     this.setState({
    //       emailErrorMessage: "Invalid email address",
    //     });
    //   if (
    //     modalData.first_name !== undefined &&
    //     modalData.last_name !== undefined &&
    //     modalData.email !== undefined && pattern.test(modalData.email) &&
    //     this.state.userPassword !== "" && this.state.passwordErrorMsg === ""
    //   ) {
    //     payload = {
    //       first_name: modalData.first_name,
    //       last_name: modalData.last_name,
    //       username: modalData.first_name + ' ' + modalData.last_name,
    //       email: modalData.email,
    //       phone_no: modalData.phone_no,
    //       password: this.state.userPassword,
    //       is_active: modalData.is_active,
    //     };
    //     console.log({ payload });
    //     API.post("create-admin-users/", payload)
    //       .then((response) => {
    //         if (response) {
    //           this.hideModal();
    //           this.loadClients();
    //           this.props.showNotificationMessage({
    //             notificationMessage: "User Created Successfully",
    //             successMessage: true,
    //             showNotification: true,
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         console.log({ error });
    //         if (error.response.status !== 200) {
    //           if (error.response.data) {
    //             this.setState({
    //               emailErrorMessage: error.response.data.email && error.response.data.email.join(),
    //               fnameErrorMessage: error.response.data.first_name && error.response.data.first_name.join(),
    //               lnameErrorMessage: error.response.data.last_name && error.response.data.last_name.join(),
    //               passwordErrorMsg: error.response.data.password && error.response.data.password.join()
    //             });
    //             if (error.response.data.user_address && error.response.data.user_address.length) {
    //               this.setState({
    //                 buildingErrorMessage: error.response.data.user_address[0].building_apt && error.response.data.user_address[0].building_apt.join(),
    //                 cityErrorMessage: error.response.data.user_address[0].city && error.response.data.user_address[0].city.join(),
    //                 labelErrorMessage: error.response.data.user_address[0].label && error.response.data.user_address[0].label.join(),
    //               });
    //             }
    //           } 
    //           this.props.showNotificationMessage({
    //             notificationMessage: "Some error has occured. Cause of the error is " + error,
    //             successMessage: false,
    //             showNotification: true,
    //           });
    //         } 
    //       });
    //   }
    // } else {
    //   console.log("Modal:", modalData);
    //   let pattern = new RegExp(
    //     /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
    //   );

    //   if (modalData.first_name === undefined)
    //     this.setState({
    //       fnameErrorMessage: "Firstname is a required field!",
    //     });
    //   if (modalData.last_name === undefined)
    //     this.setState({
    //       lnameErrorMessage: "Lastname is a required field!",
    //     });
    //   if (!pattern.test(modalData.email))
    //     this.setState({
    //       emailErrorMessage: "Invalid email address",
    //     });
    //   if (
    //     modalData.first_name !== undefined &&
    //     modalData.last_name !== undefined &&
    //     modalData.email !== undefined && pattern.test(modalData.email)
    //   ) {
    //     let payload = {
    //       first_name: modalData.first_name,
    //       last_name: modalData.last_name,
    //       username: modalData.first_name + ' ' + modalData.last_name,
    //       email: modalData.email,
    //       phone_no: modalData.phone_no,
    //       is_active: modalData.is_active,
    //     };
    //     API.patch(
    //       "create-admin-users/" + this.state.modalData.id + "/",
    //       payload
    //     )
    //       .then((response) => {
    //         this.hideModal();
    //         this.loadClients();
    //         this.props.showNotificationMessage({
    //           notificationMessage: "User Updated Successfully",
    //           successMessage: true,
    //           showNotification: true,
    //         });
    //       })
    //       .catch((error) => {
    //         this.setState({
    //           loadError: "Some error has occured. Please try again",
    //         });
    //         console.log("error testing", error)
    //         if (error.response) {
    //           if (error.response.status !== undefined) {
    //             if (error.response.status === 400) {
    //               this.props.showNotificationMessage({
    //                 notificationMessage: "User profile may not be null",
    //                 successMessage: false,
    //                 showNotification: true,
    //               });
    //             } else if (error.response.status === 500) {
    //               this.props.showNotificationMessage({
    //                 notificationMessage: "Enter all the personal and profile details of the user",
    //                 successMessage: false,
    //                 showNotification: true,
    //               });
    //             } else {
    //               console.log("Else")
    //             }
    //           }
    //         }
    //       });
    //   }
    // }
  };

  createPagePermission=async(data)=>{
    
     const {id=null, role_id=null}=data || {}
     if(!id || !role_id){
      return;
     }
     const roleId=[];
     roleId.push(role_id)
     const payload={
       "userId":id,
       "roles":roleId,
      
     }
    try {
       const response= await PhoenixAPI.post("/api/v2/create-admin-user", payload);
       console.log("response", response)
    }

    catch(error){
      console.log("Error in creating user page perimission", error.message)
      
    }


  }
  
  creatingCleverTapProfileUser = (data) => {
    console.log("creatingCleverTapProfileUser", data)
    const { id: userId = "", email = "" ,role_id=null } = data || {}
      if(role_id===10){
        // for nurse user
      return (window.clevertap.onUserLogin.push({
        "Site": {
          "Identity": userId,
          "Email": email,
          "MSG-email": false,
          "MSG-push": false,
          "MSG-sms": false,
          "MSG-whatsapp": false,
        },
        "cart_data": []
      }))
      }
  }

  saveAdminUser = () => {
    const { newUser, userPassword, modalData } = this.state;
    const {role_id}=modalData || {};
    

    const payload = {
      first_name: modalData.first_name,
      last_name: modalData.last_name,
      username: modalData.first_name + ' ' + modalData.last_name,
      email: modalData.email,
      phone_no: modalData.phone_no,
      is_active: modalData.is_active,
      description: modalData.description || "",
      role_id: role_id,
    };


   
    if (newUser) payload.password = userPassword;


    const notificationMessage = newUser ? "User Created Successfully" : "User Updated Successfully";
    const apiOptions = {
      method: newUser ? "post" : "patch",
      url: newUser ? "create-admin-users/" : `create-admin-users/${modalData.id}/`,
      data: payload,
    };

    API.request(apiOptions)
      .then((response) => {
        const data= response && response.data;
        const {message, status}=data;
        // if( newUser){
        //   this.creatingCleverTapProfileUser(data)
        // }
        if(status===400){
          this.setState({emailErrorMessage:message,emailErrorNotify:true})
          this.showErrorNotification(message)
        }else{
          this.createPagePermission(data)
          this.hideModal();
          this.loadClients();
          this.setState({emailErrorMessage:message,emailErrorNotify:false})
          this.showSuccessNotification(notificationMessage);
        }
          
      })
      .catch((error) => {
        console.log("Error on saving admin user: ", error);
        this.showErrorNotification("Some error has occured. Cause of the error is " + error);
        if (error.response.status !== 200) {
          if (error.response.data) {
            const errors = {};
            if (error.response.data.email && error.response.data.email.length > 0)
              errors.email = error.response.data.email.join();
            this.setState({ errors });
          }
        }
      });
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.newUser ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    getFilteredUsers=()=>{
      const {users, searchApplied, searchTerm} = this.state;
      if (!searchApplied || !searchTerm) return users;
      const newValue = searchTerm.slice(0).trim().toLowerCase();
      return users.filter((user)=>{
        const {username,email,phone_no} =user;
        return (
          (username &&
            username.toLowerCase().indexOf(newValue) !== -1) ||
          (email && email.toLowerCase().indexOf(newValue) !== -1) ||
          (phone_no && phone_no.indexOf(newValue) !== -1)
        )
      })
    }
    
  render() {
    let finalList = [];
    let transactionList = [];
    let addressArray = [];
    const {
      users,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results,
      modalData,
      userTransactions,
      newUser,
      errors = {},
      editables = {},
      isEditable,
      emailErrorMessage,
      emailErrorNotify,
    } = this.state;
    transactionList = userTransactions;
    if (isLoaded) {
      if (searchApplied) {
        finalList = this.getFilteredUsers(users) ?? [];
      } else {
        finalList = users;
      }
    }

    // const personalForm = (
    //   <>
    //     <div className="form-group-container">
    //       <div className="form-group">
    //         <label>First Name {newUser && "*"}</label>
    //         <div className="user-input-container">
    //           <input
    //             type="text"
    //             name="first-name"
    //             className={`form-control user-data-input ${this.state.editFn || newUser ? `non-editable` : `editable`
    //               } `}
    //             value={modalData.first_name || ""}
    //             onChange={(e) => this.handleInput(e, "first_name")}
    //             readOnly={this.state.editFn || newUser ? false : true}
    //           ></input>
    //           {!newUser && (
    //             <div
    //               className="user-data-edit-icon"
    //               onClick={() => this.makeEditable("editFn")}
    //             >
    //               <i className="fa fa-pencil"></i>
    //             </div>
    //           )}
    //         </div>
    //         <span className="error">{this.state.fnameErrorMessage}</span>
    //       </div>
    //       <div className="form-group">
    //         <label>Last Name {newUser && "*"}</label>
    //         <div className="user-input-container">
    //           <input
    //             type="text"
    //             name="last-name"
    //             className={`form-control user-data-input ${this.state.editLn || newUser ? `non-editable` : `editable`
    //               } `}
    //             value={modalData.last_name || ""}
    //             onChange={(e) => this.handleInput(e, "last_name")}
    //             readOnly={this.state.editLn || newUser ? false : true}
    //           ></input>
    //           {!newUser && (
    //             <div
    //               className="user-data-edit-icon"
    //               onClick={() => this.makeEditable("editLn")}
    //             >
    //               <i className="fa fa-pencil"></i>
    //             </div>
    //           )}
    //         </div>
    //         <span className="error">{this.state.lnameErrorMessage}</span>
    //       </div>
    //     </div>
    //     <div className="form-group-container">
    //       <div className="form-group">
    //         <label>Email {newUser && "*"}</label>
    //         <div className="user-input-container">
    //           <input
    //             type="text"
    //             name="email"
    //             className={`form-control user-data-input ${this.state.editEmail || newUser ? `non-editable` : `editable`
    //               } `}
    //             value={modalData.email || ""}
    //             onChange={(e) => this.handleInput(e, "email")}
    //             readOnly={this.state.editEmail || newUser ? false : true}
    //           ></input>
    //           {!newUser && (
    //             <div
    //               className="user-data-edit-icon"
    //               onClick={() => this.makeEditable("editEmail")}
    //             >
    //               <i className="fa fa-pencil"></i>
    //             </div>
    //           )}
    //         </div>
    //         <span className="error">{this.state.emailErrorMessage}</span>
    //       </div>
    //       <div className="form-group">
    //         <label>Password {newUser && "*"}</label>
    //         <input
    //           type="text"
    //           name="password"
    //           className={`form-control user-data-input ${newUser ? `non-editable` : `editable`
    //             } `}
    //           value={this.state.newUser ? modalData.password : ""}
    //           onChange={(e) => this.handleInput(e, "password")}
    //           readOnly={!this.state.newUser}
    //         ></input>
    //         <span className="error">{this.state.passwordErrorMsg}</span>
    //       </div>
    //     </div>

    //   </>
    // );

    const detailsForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="first_name">First Name</label>
            <div className="input-group">
              <input
                value={modalData.first_name || ""}
                onChange={(e) => this.handleInput(e, "first_name")}
                type="text"
                id="first_name"
                name="first-name"
                className={"form-control py-2" + (errors.first_name ? " has-error" : "")}
                disabled={!newUser && !editables.first_name}
              />
              {this.renderPencilIcon("first_name")}
            </div>
            <span className="help-block">{errors.first_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="last_name">Last Name</label>
            <div className="input-group">
              <input
                value={modalData.last_name || ""}
                onChange={(e) => this.handleInput(e, "last_name")}
                type="text"
                id="last_name"
                name="last-name"
                className={"form-control py-2" + (errors.last_name ? " has-error" : "")}
                disabled={!newUser && !editables.last_name}
              />
              {this.renderPencilIcon("last_name")}
            </div>
            <span className="help-block">{errors.last_name}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="email">Email*</label>
            <div className="input-group">
              <input
                value={modalData.email || ""}
                onChange={(e) => this.handleInput(e, "email")}
                type="text"
                name="email"
                className={"form-control py-2" + (errors.email ? " has-error" : "")}
                disabled={!newUser && !editables.email}
              />
              {this.renderPencilIcon("email")}
            </div>
            {emailErrorNotify? <span className="help-block">{emailErrorMessage}</span>:<span className="help-block">{errors.email}</span>}
          </div>
          <div className="form-group col">
            <label htmlFor="password">Password*</label>
            <input
              value={(this.state.newUser ? modalData.password : "")|| ""}
              onChange={(e) => this.handleInput(e, "password")}
              type="text"
              id="password"
              name="password"
              className={"form-control py-2" + (errors.password ? " has-error" : "")}
              disabled={!newUser}
            />
            <span className="help-block">{errors.password}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={modalData.is_active}
                onChange={(event, value) =>
                  this.setState({ modalData: { ...modalData, is_active: value.value } })
                }
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!newUser && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>

          <div className="form-group col-6">
            <label htmlFor="status">Role</label>
            <div className="input-group">
              <select
                name="role_id"
                className={"form-control"}
                onChange={(e) => this.handleInput(e, "role_id")}
                value={modalData.role_id ??""}
                disabled={!newUser && !editables.role_id}
              >
                {this.state.roles_dropdown_list && this.state.roles_dropdown_list.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.text}
                  </option>
                ))
                }
              </select>
              {this.renderPencilIcon("role_id")}
            </div>
            <span className="help-block">{errors.role_id}</span>
          </div>

        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="description">Description</label>
            <div className="input-group">
              <textarea
                value={modalData.description || ""}
                onChange={(e) => this.handleInput(e, "description")}
                id="description"
                name="description"
                type="text"
                placeholder="Description in English"
                disabled={!newUser && !editables.description}
                className={"form-control" + (errors.description ? " has-error" : "")}
              ></textarea>
              {this.renderPencilIcon("description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
        </div>
      </div>;

    const activityInformationForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="last_login">Last Login</label>
            <input
              value={modalData.last_login ? formatDateTime(modalData.last_login) : ""}
              type="text"
              id="last_login"
              className="form-control py-2"
              disabled />
          </div>
          <div className="form-group col">
            <label htmlFor="date_joined">Date Joined</label>
            <input
              value={modalData.date_joined ? formatDateTime(modalData.date_joined) : ""}
              type="text"
              id="date_joined"
              className="form-control py-2"
              disabled />
          </div>
        </div>
      </div>;

    // const tabData = [
    //   {
    //     tabTitle: 'User Info',
    //     tabForm: personalForm,
    //     eventKey: 'userInfo'
    //   }
    // ]

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>AdminUser</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
              <button
                onClick={this.createNewUser}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                // className="btn button-blue btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>New User
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            // onChangePage={this.onUserPageChange}
            responsive
            paginationTotalRows={this.state.totalPage}
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          // expandableRows={true
          // }
          // expandableRowsComponent={
          //   <DetailedView showReschedule={this.showReschedule} />
          // }
          // expandOnRowClicked
          />
        </Card>

        {/* <ModalPopup 
          showModal={this.state.showModal} 
          tabData={tabData} 
          key={this.state.key} 
          saveUserData={this.saveUserData} 
          hideModal={this.hideModal} 
          title={this.modalData && this.modalData.id ? this.modalData.id : ''} /> */}

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.saveUserData} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs 
              id="tabs_admin_users"
              activeKey={this.state.keyActiveTab}
              onSelect={k => this.setState({ keyActiveTab: k })}
              fill
              justify
            >
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_activity_information_form" title="Activity Information" disabled={newUser}>
                {activityInformationForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUser);
