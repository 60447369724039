import React from "react";
import './CustomLoader.scss'

 const CustomLoader = ({isbackDrop = true , show=false}) => {
if(!show)
  return <></>
  return (
    <>
     {isbackDrop&&<div className="spinner-backdrop" id="spinner-backdrop" />}
      <div className="spinner" id="spinner" />
    </>
  )
}

export default CustomLoader;
