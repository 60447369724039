import PhoenixAPI from "utils/PhoenixAPI";

const CMS_Categories="navigation-menu";

export const fetchAllCategories=async()=>{
    const response=await PhoenixAPI.get(CMS_Categories);
    if(response.status===200){
        return response.data;
    }else{
        return [];
    }
}

