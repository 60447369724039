import React from 'react'

import { useState, useEffect } from 'react';
import PhoenixAPI from "../../utils/PhoenixAPI";
import PdfPreview from 'components/Common/PdfPreview';
import Notification from './Notification';
import { getUserId } from 'services/UserService';
import { useHistory } from "react-router-dom";
import SelectPaymentStatus from 'pages/HomeCarePortal/SelectPaymentStatus';
import Select from "react-select";
import {trackUploadSampleOpenOrderSaveClick, trackUploadSamplePastOrders} from "analytics"
import "./uploadSample.scss"

function UploadSampleDetails(props) {
    const [image, setImage] = useState("");
    const [imageFile, setImageFile] = useState("");
    const [imageURL, setImageURL] = useState("");
    const [inputs, setInputs] = useState({});
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [error, setError] = useState({})
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const validTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/svg+xml'];
    const errMsg = "Please upload only images, docs or pdf ";
    const [orderId, setOrderId]= useState(null)
    const [clientDetails,setClientDetails]= useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
    const nurseUserId= getUserId()
    const paymentStatusOptions = [
        {
            name: "Collected by Cash",
            value: "Collected by Cash",
            label :"Collected by Cash"
        },
        {
            name: "Collected by Card",
            value: "Collected by Card",
            label:"Collected by Card"
        }
    ]
    const userId= getUserId()
    useEffect(()=>{
        const {orderId}=props.match.params;
        setOrderId(orderId)
     },[orderId])

     useEffect(()=>{
  
        if(orderId){
          fetchAllOrders()
        }
      
       },[orderId])

       const fetchAllOrders=async()=>{
        let  url=`nurse/new-orders/${nurseUserId}/order-id/${orderId}`
        try{
         setIsLoading(true)
         const { data: order = {} } =await PhoenixAPI.get(url);
         
           setClientDetails(order)
       }
       catch(error){
         console.log("Error occurred in fetcching orders", error.message)
       }
       finally{
        setIsLoading(false)
       }
        }

    function handleBackButton(data) {
        // if (data === "uploadSampleDetails") {
        //     setIsShowClientLab(false);
        //     setIsShowUploadSample(false);
        //     setIsShowOrder(true);
        // }
        return history.goBack();
    }
    function handleChange(e) {
        const { name, value } = e.target;
        setInputs((prev) => setInputs({ ...prev, [name]: value }))
    }
    const handleTimeDate = (e,inputName) => {
    
        if(inputName==="date"){
            setSelectedDate(e.target.value);
        }else{
            let time=e.target.value;
                time=time.split(":");
            let hr = time[0] % 12 < 10 ? "0" + time[0] % 12 : time[0] % 12;
            let min=time[1];
            let timeString=hr+":"+min
            setSelectedTime(e.target.value);
        }
    };
    const handleImageChange = (e) => {
        const fileName = e.target.files[0];
        const imageURL = URL.createObjectURL(fileName)
        if (!validTypes.includes(fileName.type)) {
            setIsError(true);
            setMessage(errMsg)
        } else {
            setImageURL(imageURL)
            setImage(fileName);
            setImageFile(fileName)
        }
    }
    const  handleSubmit=async(e)=> {
        e.preventDefault();
        const isError = validate();
        if (isError) {
            return;
        }
        const formData = new FormData();
        formData.append('pictureOfSample', image );
        let  payload = {
            orderId: clientDetails.orderId,
            userId:userId,//nurse id 
            deliveryDate:selectedDate,
            deliveredTo:inputs.name,
            temperature: inputs.temperature,
            labReferralCode: inputs.referalcode,
            deliveryTime: selectedTime,
            
            // professionalId:userId,
            // nurseAppointmentStatus:"",
            // expectedHoursOfWork:"",
            // homeCareId:"",
            // homecarePaymentSt    atus:"",

        }
        
        payload=JSON.stringify(payload);
        formData.append("orderSample",payload);
        let homecarestatus=((clientDetails?.paymentMethod=="COD" || clientDetails?.paymentMethod=="COD_MAMOPAY") && !(clientDetails.isMamopayTransactionCompleted)) ?clientDetails.homecarePaymentStatus:null;
        formData.append("homecarePaymentStatus",homecarestatus);
        const deviceId= sessionStorage.getItem("device_id") ??"";
        const userName=localStorage.getItem("userName");
        const nurseId=localStorage.getItem("userId");
        const isPastOrder=sessionStorage.getItem("isPastOrder")
         
       const  trackingData={
         "homecareId":clientDetails?.homecareId??"",
         "deviceId":deviceId,
         "nurseName":userName,
         "nurseId":parseInt(nurseId),
        
       
        }
        try{
            const res = await PhoenixAPI.put(`/nurse/order-sample-info`, formData)
            if(res.status===200){
                setMessage("Details submitted successfully")
                setIsError(false);
                  trackingData["saveSuccessfull"]=true;
                  trackingData["messagePopUp"]="Details submitted successfully";
                  
                 
                  if(isPastOrder){
                    trackUploadSamplePastOrders(trackingData)
                  }
                  else{
                    trackUploadSampleOpenOrderSaveClick(trackingData)
                  }
                return window.location.href="/dashboard/home";
            }else{
                setMessage("Error while uploading Details")
                setIsError(true);
                trackingData["saveSuccessfull"]=false;
                trackingData["messagePopUp"]="Error while uploading Details";
                trackUploadSampleOpenOrderSaveClick(trackingData)
                if(isPastOrder){
                    trackUploadSamplePastOrders(trackingData)
                  }
            }
        }catch(error){
            console.log("Error while uploading  details",error)
            setMessage("Error while uploading Details")
            setIsError(true);
        }


    }
    function validate() {
        let isError = false;
        let error = {}
        if (!selectedDate) {
            error.date = "Delivery Date is required"
            isError = true;
        }
        if (!inputs.name) {
            error.name = "Name is required"
            isError = true;
        }
        if (!selectedTime) {
            error.time = "Delivery Time is required"
            isError = true;
        }
        if (!image) {
            error.image = "Image is required"
            isError = true;
        }
        
        setError({ ...error })
        return isError;
    }
    const renderFrontIdPreview = () => {
        const fileSource = imageURL
        if (!fileSource) return null;

        const file = imageFile;
        const isPdfFile = (file?.type === "application/pdf") ||
            (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

        const preview = isPdfFile
            ? <PdfPreview
                pdfFile={isPdfFile ? fileSource : null}
                pdfFileUrl={isPdfFile ? null : fileSource} />
            : (
                <img className='uploaded-image' src={imageURL} alt="" />
            )

        return (
            <>
                {preview}
            </>
        )
    }
    return (
        <>
        <div className='upload-lab-sample-info'>
            <div className='top-header'>
                <div className='header-content'>
                        <div className='content' onClick={() => handleBackButton("uploadSampleDetails")}>
                        <div className='lt' >&lt;</div>
                        <div className='header-title'>Upload Lab drop-off details</div>
                    </div>
                </div>
            </div>
            <div className='upload-lab-sample-cont'>
                <div className='upload-form'>
                    <div className='form-col'>
                        <div className='del-date'>
                            <label htmlFor="date" className='label'>Delivery Date <span className='star'>*</span></label>
                            <input className={`${error.date ? "error-border" : ""}`} type="date" name="date" value={selectedDate} onChange={(e) => handleTimeDate(e,"date")} />
                            {error.date && <div className='error'>{error.date}</div>}
                        </div>
                            <div className={`del-time`}>
                            <label htmlFor="" className='label'>Delivery Time <span className='star'>*</span></label>
                            <div className='time-box'>
                                    <input type="time" name="hour" className={`hr ${error.time ? "error-border" : ""}`} value={selectedTime} onChange={(e) => handleTimeDate(e,"time")} />
                            </div>
                            {error.time && <div className='error'>{error.time}</div>}
                        </div>
                    </div>
                    <div className='form-col'>
                        <div className='name-receiver'>
                            <label htmlFor="" className='label'>Name of the reciever  <span className='star'>*</span></label>
                            <input className={`${error.name ? "error-border" : ""}`} type="text" name="name" placeholder='Enter name' onChange={(e) => handleChange(e)} />
                            {error.name && <div className='error'>{error.name}</div>}
                        </div>
                    </div>
                    <div className='form-col'>
                        <div className='lab-bar'>
                            <label htmlFor="">Lab Bar/Referral Code</label>
                            <input type="text" name="referalcode" placeholder='Enter code' onChange={(e) => handleChange(e)} />
                        </div>
                        <div className='lab-bar'>
                            <label htmlFor="">Temperature</label>
                            <input type="text" name="temperature" placeholder='Enter Temperature' onChange={(e) => handleChange(e)} />
                        </div>
                    </div>
                   
                    {/* {clientDetails?.paymentMethod=="COD" && <div className="form-col">
            <label htmlFor="homecare_payment_status" className="label">Payment Status*</label>
            <div className="input-group">
              <SelectPaymentStatus
                key={clientDetails.id}
                id={`select-payment-status-${clientDetails.id}`}
                selectedStatus={inputs?.homecarePaymentStatus ?? null}
                onSelect={(selectedStatus) => {
                    setInputs((prev) => setInputs({ ...prev, homecarePaymentStatus: selectedStatus }))
                }}
                className={"paymentStatus"}
             
              />
              
            </div>
            {error.homecarePaymentStatus && <div className='error'>{error.homecarePaymentStatus}</div>}
          </div>} */}
                    <div className='form-col upload-div-main'>
                        <label htmlFor="" className='label'>Picture of sample   <span className='star'>*</span></label>
                        <div className={`upload-div ${error.image ? "error-border" : ""} `} >
                            <input accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg" type="file" onChange={(e) => handleImageChange(e)} />
                            <div className='text' >Upload File / Click photo</div>
                            {renderFrontIdPreview()}
                        </div>
                        {error.image && <div className='error'>{error.image}</div>}
                    </div>
                    <div className='save-button' onClick={(e) => handleSubmit(e)}>
                        Save
                    </div>
                </div>
            </div>
        </div>
         {message && <Notification setMessage={setMessage} message={message} isError={isError} />}
        </>
    )
}

export default UploadSampleDetails