const PERIOD_OPTIONS=[
    {
        "key":"1 Month",
        "value":"1 Month",
        "text":"1 Month",
      
    },
    {
        "key":"2 Months",
        "value":"2 Months",
        "text":"2 Months",
    },
    {
        "key":"3 Months",
        "value":"3 Months",
        "text":"3 Months",
    },
    {
        "key": "4 Months",
        "value": "4 Months",
         "text":"4 Months",
    },
    {
        "key": "5 Months",
        "value": "5 Months",
        "text": "5 Months",

    },
    {
        "key": "6 Months",
        "value": "6 Months",
        "text":  "6 Months",
      
    }
]

export default PERIOD_OPTIONS;