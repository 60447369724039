import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import AccessDenied from "components/Common/AccessDenied";
import DataTable from "react-data-table-component";
import {
  fetchValeoPartners,
  updatePartner,
  savePartner,
} from "services/ValeoPartnersService";
import { Dropdown } from "semantic-ui-react";
import Search from "components/Search/Search";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import AlertBox from "components/AlertBox/AlertBox";
import { ArrowDownward } from "@material-ui/icons";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class ValeoPartners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      partners: [],
      parnter: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isPartnerViewModalVisible: false,
   
    };
    this.columns = [
      {
        name: "Partner Id",
        selector: "id",
        // width:"100px",
        sortable: true,
      },
      {
        name: "Partner Name",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Domain Email",
        selector: "domainEmail",
        sortable: true,
        wrap: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (partner) => <Status isActive={partner.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (partner) => this.renderViewButton(partner.id),
      },
    ];
  }

  componentDidMount() {
    // Promise.all([this.fetchAclUser()]);
    this.fetchAclUser();
    this.fetchValeoPartners();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.VALEO_AMBASSADORS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  // fetchCountries = async () => {
  //   try {
  //     this.showLoading();
  //     const countries = (await fetchCountries()) ?? [];
  //     const selectedCountry = countries.find((country) => country.is_default);
  //     this.setState({ countries, selectedCountry });
  //   } catch (error) {
  //     this.showErrorNotification(
  //       "Error on fetching countries!" + error.message
  //     );
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  // handleCountrySelection = (selectedCountry) =>
  //   this.setState({ selectedCountry });

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
      this.setState({ partners });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNewPartner = () => {
    this.setState({ isNewPartner: true }, () => this.showPartnerViewModal());
  };

  handleViewPartner = (partnerId) => {
    const partners = this.state.partners ?? [];
    const partner = partners.find((it) => it.id === partnerId);
    if (!partner) {
      console.log(`Parnter with id ${partnerId} is not found!`);
      return;
    }

    this.setState({ partner, isNewPartner: false }, () =>
      this.showPartnerViewModal()
    );
  };

  showPartnerViewModal = () => {
    const { isNewPartner } = this.state;

    if (isNewPartner) {
      const partner = {
        isActive: false,
      };
      this.setState({
        partner,

        isPartnerViewModalVisible: true,
      });
    } else {
      this.setState({ isPartnerViewModalVisible: true });
    }
  };

  hidePartnerViewModal = () => {
    this.setState({ isPartnerViewModalVisible: false });
    this.resetForm();
  };

  handlesavePartner = async () => {
    const { partner } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId = partner.id !== null && partner.id !== undefined;

      const response = isValidId
        ? await updatePartner(partner)
        : await savePartner(partner);

      this.hidePartnerViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Partner Updated Successfully."
          : "Partner Created Successfully."
      );
      this.fetchValeoPartners();
    } catch (error) {
      console.log("Error occured in saving valeo partner", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { partner = {} } = this.state;
    const errors = {};
    const { name, isActive, domainEmail } = partner;

    return Object.keys(errors).length === 0 ? null : errors;
  };

  resetForm = () => {
    this.setState({
      partner: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewPartner(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPartner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredPartners = () => {
    const { partners } = this.state;
    return this.getSearchedOtherItems(partners);
  };
  
  handleChange=({currentTarget: input})=>{
    const partner = { ...this.state.partner };
   partner[input.name] = input.value;
   this.setState({ partner });
  }

  getSearchedOtherItems = (partners) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return partners;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return partners.filter((partner) => {
      const { id, name, domainEmail } = partner;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (domainEmail && domainEmail.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      accessDenied,
      partners,
      partner,
      isNewPartner,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredPartners = this.getFilteredPartners(partners) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="partnerName">Partner Name</label>
            <div className="input-group">
              <input
                value={partner?.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Enter Partner Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewPartner && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="domainEmail">Domain Email</label>
            <div className="input-group">
              <input
                value={partner?.domainEmail || ""}
                onChange={this.handleChange}
                id="domainEmail"
                name="domainEmail"
                type="text"
                placeholder="Enter Domain Email"
                className={
                  "form-control py-2" +
                  (errors.domainEmail ? " has-error" : "")
                }
                disabled={!isNewPartner && !editables.domainEmail}
              />
              {this.renderPencilIcon("domainEmail")}
            </div>
            <span className="help-block">{errors.domainEmail}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={partner?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    partner: {
                      ...partner,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPartner && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    if (accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewPartner}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredPartners || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredPartners.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isPartnerViewModalVisible}
          onHide={this.hidePartnerViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Partner Name: </span>
                {partner?.name || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesavePartner}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_otheritems">
              <Tab
                eventKey="key_form_basic_information"
                title="Details"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValeoPartners);
