import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import ImagePreview from "components/Common/ImagePreview";
import PdfPreview from "components/Common/PdfPreview";

const TWO_MEGA_BYTE = 2_097_152;
const TEN_MEGA_BYTE = 5 * TWO_MEGA_BYTE;

function EditDependentIDInfo(props) {
  const { customerIDInfo, isVisible, onHide, onSave } = props;
  const {fullNameId, firstName,lastName, frontId, backId } = customerIDInfo.dependent ?? {};
  
  const [fullNameIdDependent, setFullNameIdDependent] = useState(fullNameId);
  const [frontIdDependent, setFrontIdDependent] = useState( frontId);
  const [backIdDependent, setBackIdDependent] = useState(backId);
  const [frontIdSrc, setFrontIdSrc] = useState(frontId);
  const [backIdSrc, setBackIdSrc] = useState(backId);
  const [errors, setErrors] = useState({});
  const frontIdInputFileRef = useRef(null);
  const backIdInputFileRef = useRef(null);

  useEffect(() => setFullNameIdDependent(fullNameId), [fullNameId]);
  useEffect(() => setFrontIdDependent( frontId), [ frontId]);
  useEffect(() => setFrontIdSrc( frontId), [ frontId]);
  useEffect(() => setBackIdDependent( backId), [ backId]);
  useEffect(() => setBackIdSrc( backId), [ backId]);

  const validate = () => {
    const errors = {};
    if (fullNameId && fullNameId.length > 100)
      errors.fullNameId =
        "Full Name as per ID can not be more than 100 characters";

    if (frontId && frontId.size) {
      if (frontId.type === "application/pdf" && frontId.size > TEN_MEGA_BYTE) {
        errors.frontId = "PDF/File should not be more than 10 MB!";
      } else if (frontId.size > TWO_MEGA_BYTE) {
        errors.frontId = "Image/File should not be more than 2 MB!";
      }
    }

    if (backId && backId.size && backId.size > TWO_MEGA_BYTE) {
      if (backId.type === "application/pdf" && backId.size > TEN_MEGA_BYTE) {
        errors.backId = "PDF/File should not be more than 10 MB!";
      } else if (backId.size > TWO_MEGA_BYTE) {
        errors.backId = "Image/File should not be more than 2 MB!";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  const handleSave = () => {
    const errors = validate();
    if (errors) {
      setErrors(errors ?? {});
      return;
    }
    onSave({
      
      fullNameId: fullNameIdDependent,
      frontId: frontIdDependent,
      backId: backIdDependent,
      childOrderId:props.customerIDInfo.itemOrderId
    });
  };

  const handleDeleteFrontId = () => {
    setFrontIdDependent(null);
    setFrontIdSrc(null);
    frontIdInputFileRef.current.value = null;
  };

  const handleDeleteBackId = () => {
    setBackIdDependent(null);
    setBackIdSrc(null);
    backIdInputFileRef.current.value = null;
  };

  const handleSelectFrontIdImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setFrontIdDependent(file);
      const reader = new FileReader();
      reader.onloadend = function () {
        setFrontIdSrc(reader.result);
      };

      if (file?.type?.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file?.type === "application/pdf") {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const handleSelectBackIdImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      setBackIdDependent(file);
      const reader = new FileReader();
      reader.onloadend = function () {
        setBackIdSrc(reader.result);
      };

      if (file?.type?.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file?.type === "application/pdf") {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  const renderFrontIdPreview = () => {
    if (!frontIdSrc) return null;
    const isPdfFile =
      frontIdDependent?.type === "application/pdf" ||
      (typeof frontIdSrc === "string" && frontIdSrc.toLowerCase().endsWith(".pdf"));

    return isPdfFile ? (
      <PdfPreview
        id="selected_front_pdf"
        pdfFile={isPdfFile ? frontIdSrc : null}
        pdfFileUrl={isPdfFile ? null : frontIdSrc}
      />
    ) : (
      <ImagePreview
        label="Selected Front of ID"
        imageUrl={frontIdSrc}
        showDeleteIcon={false}
        onDeleteImage={handleDeleteFrontId}
      />
    );
  };
  
  const renderBackIdPreview = () => {
    if (!backIdSrc) return null;
    const isPdfFile =
      backIdDependent?.type === "application/pdf" ||
      (typeof backIdSrc === "string" && backIdSrc.toLowerCase().endsWith(".pdf"));

    return isPdfFile ? (
      <PdfPreview
        id="selected_front_pdf"
        pdfFile={isPdfFile ? backIdSrc : null}
        pdfFileUrl={isPdfFile ? null : backIdSrc}
      />
    ) : (
      <ImagePreview
        label="Selected Back of ID"
        imageUrl={backIdSrc}
        showDeleteIcon={false}
        onDeleteImage={handleDeleteBackId}
      />
    );
  };

  const title = customerIDInfo ? "Edit customer ID" : "Add customer ID";
  const form = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="fullNameId">Full Name as per ID</label>
          <input
            value={fullNameIdDependent ?? ""}
            onChange={(event) => setFullNameIdDependent(event.target.value)}
            type="text"
            id="fullNameId"
            name="fullNameId"
            placeholder="Full Name as per ID"
            className="form-control"
          />
          <span className="help-block">{errors.fullNameId}</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="frontId">Front of ID</label>
          <input
            ref={frontIdInputFileRef}
            onChange={handleSelectFrontIdImage}
            type="file"
            id="frontId"
            name="frontId"
            accept="image/*, application/pdf"
            // accept="image/*"
            className={"form-control" + (errors.image ? " has-error" : "")}
          />
          <span className="help-block">{errors.frontId}</span>
        </div>
        <div className="form-group col-6">
          {renderFrontIdPreview()}
          {/* {frontIdSrc && (
            <ImagePreview
              label="Selected Front of ID"
              imageUrl={frontIdSrc}
              showDeleteIcon={false}
              onDeleteImage={handleDeleteFrontId}
            />
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="backId">Back of ID</label>
          <input
            ref={backIdInputFileRef}
            onChange={handleSelectBackIdImage}
            type="file"
            id="backId"
            name="backId"
            accept="image/*, application/pdf"
            className={"form-control" + (errors.backId ? " has-error" : "")}
          />
          <span className="help-block">{errors.backId}</span>
        </div>
        <div className="form-group col-6">
          {renderBackIdPreview()}
          {/* {backIdSrc && (
            <ImagePreview
              label="Selected Back of ID"
              imageUrl={backIdSrc}
              showDeleteIcon={false}
              onDeleteImage={handleDeleteBackId}
            />
          )} */}
        </div>
      </div>
      <div className="row">
        <div className="form-group col text-right">
          <button
            onClick={onHide}
            type="button"
            className="btn btn-outline-secondary"
          >
            Cancel
          </button>
          <button
            onClick={() => handleSave()}
            type="button"
            className="btn btn-outline-success ml-2"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
  return (
    <Modal show={isVisible} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{title}</p>
        </Modal.Title>
        <div className="user-title"></div>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>
  );
}

EditDependentIDInfo.propTypes = {
  customerIDInfo: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

EditDependentIDInfo.defaultProps = {
  customerIDInfo: null,
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

export default EditDependentIDInfo;
