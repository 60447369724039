import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import SelectSuggestedSupplement from "./SelectSuggestedSupplement";
import moment from "moment";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";
import SelectPeriod from "./SelectPeriod";

class CoachSupplementSuggestion extends Component {
  constructor(props) {
    super(props);
   const {suggestedSupplements,countryId=null,suggestions=[], suggestionsUpdate=(f)=>f, isEditable, isPastOrder=false}= this.props || {}
   console.log("isEditable",isEditable, isPastOrder, suggestedSupplements);
     this.suggestionsUpdate=suggestionsUpdate;
    this.state = {
      suggestions,
      suggestion: {},

      isSuggestionViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
      periods: [],
      supplements:suggestedSupplements ?? [],
      // isEditable: true,
      
      countryId,
    };
    this.columns = [
      {
        name: "Supplement Name",
        selector: "supplementName",
        wrap: true,
        sortable: true,
        cell:(p)=>this.renderSupplementName(p)
      },
      {
        name: "Suggested Date",
        selector: "firstSuggestionDate",
        wrap: true,
        sortable: true,
        format: (row) =>
          row.firstSuggestionDate ? formatDate(row.firstSuggestionDate) : "-",
      },
      {
        name: "Doses",
        selector: "dosesEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Period",
        selector: "periodEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Update",
        center: true,
        cell: (p) => this.renderViewButton(p.supplementId),
      },
      {
        name: "Delete",
        center: true,
        cell: (p) => this.renderDeleteButton(p.supplementId),
      },
    ];
  }

  componentDidMount() {
   
    this.fetchPeriods();
  }
  
  componentWillReceiveProps(props){
   
    const {suggestedSupplements,countryId=null,suggestions, isEditable}= this.props || {};

    this.setState({suggestions})
  }


  fetchPeriods = async () => {
    this.showLoading();
    try {
      const { data: periods = [] } = await PhoenixAPI.get(`supplement-periods`);
      
      this.setState({ periods });
    } catch (error) {
      console.log("Error in fetching periods");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement periods",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      suggestion: {},
      errors: {},
      editables: {},
    });
  };

  renderSupplementName=(supplement)=>{
    if(supplement.unicommerceAvailableQuantity<=0 || supplement.unicommerceAvailableQuantity==null || supplement.unicommerceAvailableQuantity==undefined){
      return `${supplement?.supplementName??""} (OOS)`
    }
    else{
      return supplement?.supplementName ??""
    }
  }
  renderViewButton = (supplementId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewSuggestion(supplementId);
        }}
        disabled={!this.props.isEditable}
      >
        Edit
      </button>
    </div>
  );

  renderDeleteButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleDeleteSuggestion(id);
        }}
        disabled={!this.props.isEditable}
      >
        Delete
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.props.isEditable || this.state.isNewSuggestion) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };
 
  

  handleAddSuggestion = () => {
    this.setState({ isNewSuggestion: true }, () =>
      this.showSuggestionViewModal()
    );
  };


  saveSuggestion = async () => {
    const { suggestions = [], suggestion } = this.state;
    const {suggestedSupplements=[]}= this.props || {};
    const ids = suggestions.length > 0 ? suggestions.map((it) => it.id) : null;
    suggestion.id = ids ? Math.max(...ids) + 1 : 1;
    suggestion.is_new = true;
    const filteredSupplement=suggestedSupplements.filter((it)=>it.id===suggestion.supplementId)
    suggestion.supplementName=filteredSupplement.length>0?filteredSupplement[0].internalName:"";
      
      this.setState({ suggestions: [...suggestions, suggestion] }, () => {
      this.showSuccessNotification("Supplement Suggestion Created Successfully!");
      this.onSuggestionUpdatedLocally();
    });
  };

  
  updateSuggestion = async (id) => {
    const { suggestions = [], suggestion } = this.state;
    
    const newSuggestions = suggestions.map((it) => (it.supplementId === id ? suggestion : it));
    
    this.setState({ suggestions: newSuggestions }, () => {
      this.showSuccessNotification("Supplement Suggestion Updated Successfully!");
      this.onSuggestionUpdatedLocally();
    });
  };

  onSuggestionUpdatedLocally = () => {
    // this.clearForm();
    this.suggestionsUpdate(
      this.state.suggestions.map((suggestion) => {
        const q = { ...suggestion };
        if(q.is_new){
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  validate = () => {
    const {suggestion}= this.state;
    const errors = {};
   const {supplementId, periodId}=suggestion || {}
  

     if (supplementId == null) errors.supplementId = "Supplement cannot be blank";
     if(periodId==null) errors.periodId = "Period cannot be blank"

    return Object.keys(errors).length === 0 ? null : errors;
  };
  
  handleSaveSuggestion = async () => {
    const { suggestion, isNewSuggestion} = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload={...suggestion};
    const today = moment().format("YYYY-MM-DD");
    payload["updatedDate"]=today;
    try {
      this.showLoading();
      // const isValidId = suggestion.supplementId !== null && suggestion.supplementId !== undefined;
      const isValidId=isNewSuggestion ?? false
      const response = !isValidId
        ? await this.updateSuggestion(payload.supplementId)
        : await this.saveSuggestion(payload);
    
      this.hideSuggestionViewModal();
    } catch (error) {
      console.log("error occurred on saving suggestion", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleDeleteSuggestion=async(id)=>{
   
   const {suggestions}= this.state || {};
    
   const updatedSuggestions=suggestions.filter((item)=>item.supplementId !==id)

   this.setState({suggestions:updatedSuggestions}, ()=>this.onSuggestionUpdatedLocally())

  }

  handleViewSuggestion = (suggestionId) => {
    const suggestions = this.state.suggestions ?? [];
  
    const suggestion = suggestions.find((it) => it.supplementId === suggestionId);
    if (!suggestion) {
      console.log(`Suggestion id ${suggestionId} is not found!`);
      return;
    }
    
    this.setState({ suggestion, isNewSuggestion: false }, () =>
      this.showSuggestionViewModal()
    );
  };

  showSuggestionViewModal = () => {
    const { isNewSuggestion } = this.state;
    const today = moment().format("YYYY-MM-DD");
   
    if (isNewSuggestion) {
      const suggestion = { updatedDate:today, firstSuggestionDate:today};
      this.setState({
        suggestion,

        isSuggestionViewModalVisible: true,
      });
    } else {
      this.setState({ isSuggestionViewModalVisible: true });
    }
  };

  hideSuggestionViewModal = () => {
    this.setState({ isSuggestionViewModalVisible: false });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const suggestion = { ...this.state.suggestion };
    suggestion[input.name] = input.value;
    this.setState({ suggestion });
  };

  handleDateSelect = ({ target: input }) => {
    const { suggestion } = this.state || {};
    if (input.name === "updatedDate") {
      const newSuggestion = { ...suggestion, updatedDate: input.value };
      this.setState({
        suggestion: newSuggestion,
      });
    }
    if (input.name === "firstSuggestionDate") {
      const newSuggestion = { ...suggestion, firstSuggestionDate: input.value };
      this.setState({
        suggestion: newSuggestion,
      });
    }
  };


  getFilteredSuggestions=(suggestions)=>{
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return suggestions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return suggestions.filter((suggestion) => {
      const {
       
        supplementName,
        periodEng,
        dosesEng,
       
      } = suggestion;
      return (
        (supplementName && supplementName.toLowerCase().indexOf(newValue) !== -1) ||
        (periodEng &&
          periodEng.toLowerCase().indexOf(newValue) !== -1) ||
        (dosesEng && dosesEng.toLowerCase().indexOf(newValue) !== -1) 
      );
    });
  }

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      suggestions,
      suggestion,
      isSuggestionViewModalVisible,
      errors,
      editables,
      loadError,
      searchTerm,
      searchApplied,
      isNewSuggestion,
      
      periods,
    } = this.state;
    const {suggestedSupplements}= this.props || {} 
   
    const filteredSuggestions=this.getFilteredSuggestions(suggestions) ?? [];
    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cityId">Select Supplement *</label>
            <div className="input-group">
              <SelectSuggestedSupplement
                id="supplementId"
                supplements={suggestedSupplements ?? []}
                selectedSuggestedSupplement={suggestion.supplementId ?? null}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      supplementId: value.value,
                      supplement_id:value.value,
                      unicommerceAvailableQuantity:value.unicommerceAvailableQuantity
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.supplementId}
                className={
                  "form-control" + (errors.supplementId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("supplementId")}
            </div>
            <span className="help-block">{errors.supplementId}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="updatedDate">Updated Date</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="date"
                  id="updatedDate"
                  name="updatedDate"
                  className={`form-control py-2 ${
                    editables.updatedDate || isNewSuggestion
                      ? `non-editable`
                      : `editable`
                  } `}
                  value={suggestion.updatedDate}
                  onChange={this.handleDateSelect}
                  disabled={!isNewSuggestion}
                />
                {/* {this.renderPencilIcon("updatedDate")} */}
              </div>
              {/* <span className="error">{this.state.updatedDate}</span> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (English)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesEng) || ""}
                onChange={this.handleChange}
                id="dosesEng"
                name="dosesEng"
                type="text"
                placeholder="Enter Doses in English"
                className={
                  "form-control input py-2" +
                  (errors.dosesEng ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.dosesEng}
              />
              {this.renderPencilIcon("dosesEng")}
            </div>
            <span className="help-block">{errors.dosesEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (Arabic)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesAr) || ""}
                onChange={this.handleChange}
                id="dosesAr"
                name="dosesAr"
                type="text"
                placeholder="Enter Doses in Arabic"
                className={
                  "form-control input py-2 input-arabic" +
                  (errors.dosesAr ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.dosesAr}
              />
              {this.renderPencilIcon("dosesAr")}
            </div>
            <span className="help-block">{errors.dosesAr}</span>
          </div>

         
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="firstSuggestionDate">First Suggestion Date</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="date"
                  id="firstSuggestionDate"
                  name="firstSuggestionDate"
                  className={`form-control py-2 ${
                    editables.firstSuggestionDate || isNewSuggestion
                      ? `non-editable`
                      : `editable`
                  } `}
                  value={suggestion.firstSuggestionDate}
                  onChange={this.handleDateSelect}
                  disabled={!isNewSuggestion}
                />
                {/* {this.renderPencilIcon("firstSuggestionDate")} */}
              </div>
              {/* <span className="error">{this.state.updatedDate}</span> */}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="cityId">Select Period *</label>
            <div className="input-group">
              <SelectPeriod
                id="periodId"
                periods={periods}
                selectedPeriod={suggestion.periodId}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      periodId: value.value,
                      periodEng:value.label
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.periodId}
                className={
                  "form-control" + (errors.periodId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("periodId")}
            </div>
            <span className="help-block">{errors.periodId}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
            <div className="form-group col">
            <Card style={{padding:"10px"}}>
          <p className="p-head">Coach Supplement Suggestion</p>

          <DataTable
            data={suggestions ?? []}
            columns={this.columns}
            // defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            // pagination
            responsive
            // noHeader={this.state.searchApplied ? false : true}

            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
            <button
                onClick={this.handleAddSuggestion}
                disabled={this.props.isPastOrder?!this.props.isEditable:false}
              
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
            </div>
        </div>

     
        <Modal
          size="lg"
          show={this.state.isSuggestionViewModalVisible}
          onHide={this.hideSuggestionViewModal}
          // className="user-data-modal"
        >
          <Modal.Header closeButton>
            {this.props.customerName  &&<Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>UserName: </span>
                {this.props.customerName || ""}
              </p>
            </Modal.Title>}
           
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_key_details_form">
              <Tab eventKey="key_details_form">{detailsForm}</Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
          <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideSuggestionViewModal}
            >
              Cancel
            </button>
          <button
              onClick={this.handleSaveSuggestion}
              disabled={false}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
            {suggestion.supplementId ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachSupplementSuggestion);
