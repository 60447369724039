import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import ManageOptionsForm from "./ManageOptionsForm";
import PhoenixAPI from "utils/PhoenixAPI";
import QuestionType from "components/Common/QuestionType";

const BASE_URL_QUESTIONS = "questions/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const ANSWER_TYPE_OPTIONS = [
  {
    key: "SINGLE_SELECT",
    value: "SINGLE_SELECT",
    text: "Single Select",
  },
  {
    key: "MULTI_SELECT",
    value: "MULTI_SELECT",
    text: "Multi Select",
  },
  {
    key: "PLAIN_TEXT",
    value: "PLAIN_TEXT",
    text: "Plain Text",
  },
  {
    key: "SINGLE_SELECT_PLUS_PLAIN_TEXT",
    value: "SINGLE_SELECT_PLUS_PLAIN_TEXT",
    text: "Single Select + Plain Text",
  },
  {
    key: "MULTI_SELECT_PLUS_PLAIN_TEXT",
    value: "MULTI_SELECT_PLUS_PLAIN_TEXT",
    text: "Multi Select + Plain Text",
  },
];

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rankOptions: [],
      question: {},
      errors: {},
      editables: {},
      searchTerm: null,
      searchApplied: false,
      isQuestionViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Question (English)",
        selector: "questionEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Question (Arabic)",
        selector: "questionArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (question) => (
          <p style={{ textAlign: "right" }}>{question.questionArabic}</p>
        ),
      },
      {
        name: "Answer Type",
        selector: "answerType",
        // wrap: true,
        sortable: true,
        center: true,
        cell: (question) => (
          <QuestionType
            questionType={this.getQuestionType(question.answerType)}
          />
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (question) => <Status isActive={question.status === "Active"} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (question) => this.renderViewButton(question.id),
      },
    ];
  }

  getQuestionType = (answerType) => {
    switch (answerType) {
      case "SINGLE_SELECT":
        return 1;
      case "MULTI_SELECT":
        return 2;
      case "PLAIN_TEXT":
        return 3;
      case "SINGLE_SELECT_PLUS_PLAIN_TEXT":
        return 4;
      case "MULTI_SELECT_PLUS_PLAIN_TEXT":
        return 5;
      default: {
        console.log(" getQuestionType: Invalid answerType", answerType);
        return null;
      }
    }
  };

  findQuestion = (questionId) => {
    const questions = this.props.questionnaire?.questions ?? [];
    return questions.find((it) => it.id === questionId);
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleChangeQuestionFact = ({ currentTarget: input }) => {
    let question = { ...this.state.question };
    let questionFacts = question["questionFacts"] || {};
    if (question && question.questionFacts) {
      questionFacts[input.name] = input.value;
    } else {
      question.questionFacts = {
        [input.name]: input.value,
      };
    }
    this.setState({ question });
  };

  handleOptionsUpdated = (options) => {
    const question = { ...this.state.question, options };
    this.setState({ question });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNewQuestion = () => {
    this.setState({ isNewQuestion: true }, () => this.showQuestionViewModal());
  };

  handleViewQuestion = (questionId) => {
    const question = this.findQuestion(questionId);
    let questionFacts = question?.questionFacts || {};
    questionFacts.image_src = questionFacts?.image || "";
    if (!question) {
      console.log(`Question with id ${questionId} is not found!`);
      return;
    }

    this.setState({ question, isNewQuestion: false }, () =>
      this.showQuestionViewModal()
    );
  };

  showQuestionViewModal = () => {
    const { isNewQuestion } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewQuestion) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const question = {
        status: "Inactive",
        rank,
        enableFact: false,
      };
      this.setState({
        question,
        rankOptions,
        isQuestionViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isQuestionViewModalVisible: true });
    }
  };

  hideQuestionViewModal = () => {
    this.setState({ isQuestionViewModalVisible: false }, () =>
      this.resetForm()
    );
  };

  handleSaveQuestion = () => {
    const { question } = this.state;
    const errors = this.validate(question);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    this.saveQuestion(question);
  };

  validate = (question) => {
    const errors = {};
    const {
      questionEnglish,
      questionArabic,
      descriptionEnglish,
      descriptionArabic,
      answerType,
      rank,
      status,
      enableFact,
    } = question;

    // let titleEnglish = question?.questionFacts?.titleEnglish || "";
    // let titleArabic = question?.questionFacts?.titleArabic || "";

    let descriptionFactEnglish =
      question?.questionFacts?.descriptionEnglish || "";
    let descriptionFactArabic =
      question?.questionFacts?.descriptionArabic || "";

    let image = question?.questionFacts?.image || "";

    if (!questionEnglish || questionEnglish.trim() === "")
      errors.questionEnglish = "Question (English) cannot be blank";
    else if (questionEnglish.length > 1000)
      errors.questionEnglish =
        "Question (English) is limited to a 1000 characters";

    if (!questionArabic || questionArabic.trim() === "")
      errors.questionArabic = "Question (Arabic) cannot be blank";
    else if (questionArabic.length > 1000)
      errors.questionArabic =
        "Question (Arabic) is limited to a 1000 characters";

    if (descriptionEnglish && descriptionEnglish.length > 1000)
      errors.descriptionEnglish =
        "Description (English) is limited to a 1000 characters";

    if (descriptionArabic && descriptionArabic.length > 1000)
      errors.descriptionArabic =
        "Description (Arabic) is limited to a 1000 characters";

    if (!answerType) errors.answerType = "Answer Type cannot be blank";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (!status) errors.status = "Status cannot be blank";

    if (enableFact) {
      if (!descriptionFactEnglish || descriptionFactEnglish.trim() === "")
        errors.descriptionFactEnglish = "Description (English) cannot be blank";
      else if (descriptionFactEnglish.length > 1000)
        errors.descriptionFactEnglish =
          "Description (English) is limited to a 1000 characters";

      if (!descriptionFactArabic || descriptionFactArabic.trim() === "")
        errors.descriptionFactArabic = "Description (Arabic) cannot be blank";
      else if (descriptionFactArabic.length > 1000)
        errors.descriptionFactArabic =
          "Description (Arabic)) is limited to a 1000 characters";
    }

    if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveQuestion = async (question) => {
    const { questionnaire } = this.props;
    try {
      this.showLoading();
      question.questionnaireId = questionnaire.id;

      const response = question.id
        ? await this.updateQuestionEnableFact(question)
        : await this.saveQuestionEnableFact(question);

      console.log("Response: ", response);
      this.showSuccessNotification(
        question.id
          ? "Question Updated Successfully."
          : "Question Created Successfully."
      );
      this.hideQuestionViewModal();
      this.props.onQuestionSaved();
    } catch (error) {
      console.log("Error on saving question", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  saveQuestionEnableFact = async () => {
    let question = this.state.question;
    try {
      const payload = { ...question };

      let questionFacts = payload.questionFacts;
      if (questionFacts === null || questionFacts === undefined) {
        delete payload.questionFacts;
      } else {
        delete questionFacts["image"];
        delete questionFacts["image_src"];
      }

      let options = payload.options;
      if (options === null || options === undefined) {
        options = [];
      } else {
        let newArrOption = options.map((obj) => {
          if (obj.image) {
            return { ...obj, image: obj.image.name };
          } else {
            return { ...obj, image: null };
          }
        });

        let removeImageSrc = newArrOption.map(({ image_src, ...rest }) => {
          return rest;
        });

        payload.options = removeImageSrc;
      }

      const formData = new FormData();
      formData.append("questions", JSON.stringify(payload));

      formData.append("factImage", this.state.factImage);

      let optionImages = options;
      if (optionImages) {
        optionImages.map((it) => {
          if (typeof it.image !== "string")
            formData.append("optionImages", it.image);
        });
      }

      const apiOptions = {
        method: "post",
        url: BASE_URL_QUESTIONS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on creating question", error);
      throw error;
    }
  };

  updateQuestionEnableFact = async () => {
    let question = this.state.question;

    try {
      let payload = { ...question };
      let enableFact = payload.enableFact;
      if (enableFact === null || enableFact === undefined) {
        payload.enableFact = false;
      }

      let questionFacts = payload.questionFacts;
      if (questionFacts === null || questionFacts === undefined) {
        delete payload.questionFacts;
      } else {
        delete questionFacts["image_src"];

        if (typeof question.questionFacts.image !== "string")
          delete payload.questionFacts["image"];
      }
      let options = payload.options;
      if (options === null || options === undefined) {
        options = [];
      } else {
        let newArrOption = options.map((obj) => {
          if (obj.image && typeof obj.image !== "string") {
            return { ...obj, image: obj.image.name };
          } else if (obj.image && typeof obj.image == "string") {
            return { ...obj };
          } else {
            return { ...obj, image: null };
          }
        });

        let removeImageSrc = newArrOption.map((obj) => {
          if (obj.image_src && typeof obj.image_src !== "string") {
            delete obj.image_src;
          }

          return { ...obj };
        });

        payload.options = removeImageSrc;
      }

      const formData = new FormData();
      formData.append("questions", JSON.stringify(payload));

      if (typeof this.state.factImage !== "string")
        formData.append("factImage", this.state.factImage);
      let optionImages = options;
      if (optionImages) {
        optionImages.map((it) => {
          if (typeof it.image !== "string")
            formData.append("optionImages", it.image);
        });
      }

      const apiOptions = {
        method: "patch",
        url: `${BASE_URL_QUESTIONS}${question.id}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on updating question", error);
      throw error;
    }
  };

  createRankOptions = () => {
    const { isNewQuestion } = this.state;
    const questions = this.props.questionnaire?.questions ?? [];
    const ranks = questions.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, questions.length) || 0;
    const until = isNewQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);

    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      question: {},
      errors: {},
      editables: {},
      // factImage: {},
    });
  };

  renderViewButton = (questionId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewQuestion(questionId);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.props.isEditable && !this.state.isNewQuestion ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getSearchedQuestions = (questions = []) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return questions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return questions.filter((question) => {
      const { id, questionEnglish, questionArabic, answerType, rank } =
        question;
      return (
        (questionEnglish &&
          questionEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (questionArabic &&
          questionArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (answerType && answerType.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  selectQuestionFactImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      let question = this.state.question || {};

      let questionFacts = question["questionFacts"] || {};

      questionFacts["image"] = image;
      question["questionFacts"] = questionFacts;

      this.setState({ question, factImage: image }, () =>
        this.readImage(image)
      );
      // this.readImage(image);
    }
  };

  readImage = (image) => {
    let question = this.state.question || {};
    let questionFacts = question["questionFacts"] || {};

    question["questionFacts"] = questionFacts;
    const reader = new FileReader();
    reader.onloadend = function () {
      questionFacts["image_src"] = [reader.result];
      this.setState({
        // image_src: [reader.result]
        question,
      });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  toggleEnableFact = (event) => {
    // console.log("toggleEnableFact", event.target.checked);
    this.setState({
      question: { ...this.state.question, enableFact: event.target.checked },
    });
  };
  
  removeImage = () => {
    let question = this.state.question;
    question.questionFacts.image_src = "";
    question.questionFacts["image"] = null;

    this.setState({ question });
    let factImage = this.state.factImage;
    factImage = null;
    this.setState({ factImage });
  };

  render() {
    const {
      question,
      isNewQuestion,
      isQuestionViewModalVisible,
      searchTerm,
      searchApplied,
      errors,
      editables,
    } = this.state;

    const questions = this.getSearchedQuestions(
      this.props.questionnaire?.questions ?? []
    );
    const isEditable = this.props.isEditable ?? true;

    const formQuestionDetails = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="questionEnglish">Question (English)*</label>
            <div className="input-group">
              <input
                value={question?.questionEnglish || ""}
                onChange={this.handleChange}
                id="questionEnglish"
                name="questionEnglish"
                type="text"
                placeholder="Question in English"
                className={
                  "form-control py-2" +
                  (errors.questionEnglish ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.questionEnglish}
              />
              {this.renderPencilIcon("questionEnglish")}
            </div>
            <span className="help-block">{errors.questionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="questionArabic">Question (Arabic)*</label>
            <div className="input-group">
              <input
                value={question?.questionArabic || ""}
                onChange={this.handleChange}
                id="questionArabic"
                name="questionArabic"
                type="text"
                placeholder="Question in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.questionArabic ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.questionArabic}
              />
              {this.renderPencilIcon("questionArabic")}
            </div>
            <span className="help-block">{errors.questionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="descriptionEnglish">Description (English)</label>
            <div className="input-group">
              <input
                value={question?.descriptionEnglish || ""}
                onChange={this.handleChange}
                id="descriptionEnglish"
                name="descriptionEnglish"
                type="text"
                placeholder="Description in English"
                className={
                  "form-control py-2" +
                  (errors.descriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.descriptionEnglish}
              />
              {this.renderPencilIcon("descriptionEnglish")}
            </div>
            <span className="help-block">{errors.descriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="descriptionArabic">Description (Arabic)</label>
            <div className="input-group">
              <input
                value={question?.descriptionArabic || ""}
                onChange={this.handleChange}
                id="descriptionArabic"
                name="descriptionArabic"
                type="text"
                placeholder="Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.descriptionArabic ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.descriptionArabic}
              />
              {this.renderPencilIcon("descriptionArabic")}
            </div>
            <span className="help-block">{errors.descriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="answerType">Answer Type*</label>
            <div className="input-group">
              <Dropdown
                value={question?.answerType}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      answerType: value.value,
                    },
                  });
                }}
                id="answerType"
                name="answerType"
                placeholder="Answer Type"
                search
                selection
                options={ANSWER_TYPE_OPTIONS}
                disabled={!isNewQuestion}
                // disabled={!isNewQuestion && !editables.answerType}
                className={
                  "form-control" + (errors.answerType ? " has-error" : "")
                }
              />
              {/* {this.renderPencilIcon("answerType")} */}
            </div>
            <span className="help-block">{errors.answerType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={question?.rank}
                onChange={(event, value) => {
                  this.setState({
                    question: { ...question, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="questionnaire">Questionnaire</label>
            <input
              id="questionnaire"
              name="questionnaire"
              type="text"
              value={this.props.questionnaire?.internalName ?? ""}
              className="form-control py-2"
              readOnly
              disabled
            />
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={question?.status}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    const enableFactForm = (
      <>
        <div className="row enable-question-facts">
          <div>Enable Question Facts</div>
          <div className="custom-control custom-switch question-fact">
            <input
              type="checkbox"
              role="switch"
              className="custom-control-input"
              id="enableFact"
              name="enableFact"
              checked={question?.enableFact ?? false}
              value={question?.enableFact ?? false}
              onChange={this.toggleEnableFact}
              disabled={!isEditable}
            />
            <label className="custom-control-label" htmlFor="enableFact">
              {question.enableFact ? "Yes" : "No"}
            </label>
          </div>
        </div>
      </>
    );

    const questionFactsForm = (
      <>
        <div className="row mt-3 mb-3">
          <div className="col separator">
            <span>Post Question Facts</span>
          </div>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
              <label htmlFor="titleEnglish">Title (English)</label>
              <div className="input-group">
                <input
                  value={question?.questionFacts?.titleEnglish || ""}
                  onChange={this.handleChangeQuestionFact}
                  id="titleEnglish"
                  name="titleEnglish"
                  type="text"
                  placeholder="Title in English"
                  className={
                    "form-control py-2" +
                    (errors.titleEnglish ? " has-error" : "")
                  }
                  disabled={!isNewQuestion && !editables.titleEnglish}
                />
                {this.renderPencilIcon("titleEnglish")}
              </div>
              <span className="help-block">{errors.titleEnglish}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="titleArabic">Title (Arabic)</label>
              <div className="input-group">
                <input
                  value={question?.questionFacts?.titleArabic || ""}
                  onChange={this.handleChangeQuestionFact}
                  id="titleArabic"
                  name="titleArabic"
                  type="text"
                  placeholder="Title in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.titleArabic ? " has-error" : "")
                  }
                  disabled={!isNewQuestion && !editables.titleArabic}
                />
                {this.renderPencilIcon("titleArabic")}
              </div>
              <span className="help-block">{errors.titleArabic}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="descriptionFactEnglish">
                Description (English)*
              </label>
              <div className="input-group">
                <input
                  value={question?.questionFacts?.descriptionEnglish || ""}
                  onChange={this.handleChangeQuestionFact}
                  id="descriptionFactEnglish"
                  name="descriptionEnglish"
                  type="text"
                  placeholder="Description in English"
                  className={
                    "form-control py-2" +
                    (errors.descriptionFactEnglish ? " has-error" : "")
                  }
                  disabled={!isNewQuestion && !editables.descriptionFactEnglish}
                />
                {this.renderPencilIcon("descriptionFactEnglish")}
              </div>
              <span className="help-block">
                {errors.descriptionFactEnglish}
              </span>
            </div>
            <div className="form-group col">
              <label htmlFor="descriptionFactArabic">
                Description (Arabic) *
              </label>
              <div className="input-group">
                <input
                  value={question?.questionFacts?.descriptionArabic || ""}
                  onChange={this.handleChangeQuestionFact}
                  id="descriptionFactArabic"
                  name="descriptionArabic"
                  type="text"
                  placeholder="Description in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.descriptionFactArabic ? " has-error" : "")
                  }
                  disabled={!isNewQuestion && !editables.descriptionFactArabic}
                />
                {this.renderPencilIcon("descriptionFactArabic")}
              </div>
              <span className="help-block">{errors.descriptionFactArabic}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">Upload a Image</label>
              <div className="input-group">
                <input
                  onChange={this.selectQuestionFactImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*"
                  disabled={!isNewQuestion && !editables.image}
                  className={
                    "form-control" + (errors.image ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("image")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>
            <div className="form-group col">
              {question?.questionFacts?.image_src && (
                <>
                  <label htmlFor="selected_question_fact_image">
                    Question Facts Image
                  </label>

                  <div className="image-area">
                    <img
                      id="selected_question_fact_image"
                      name="selected_question_fact_image"
                      src={question?.questionFacts?.image_src}
                      className="img-thumbnail mx-auto"
                      alt="Selected"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                    <button
                      className="remove-image"
                      style={{ display: "inline" }}
                      onClick={this.removeImage}
                    >
                      x
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );

    const postQuestionFactsForm = (
      <>
        {enableFactForm}
        {this.state.question.enableFact == true && questionFactsForm}
      </>
    );

    const formQuestionManageOptions = (
      <div className="container">
        <ManageOptionsForm
          answerType={(question && question.answerType) || ""}
          options={question?.options || []}
          onOptionsUpdated={this.handleOptionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.questionnaire || !isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body className="mt-2">
          <p className="font-weight-bold">Manage Questions</p>
          <DataTable
            id="questionsTable"
            data={questions || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + questions.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadErrorQuestions ? (
                <AlertBox
                  message={this.state.loadErrorQuestions}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isQuestionViewModalVisible}
          onHide={this.hideQuestionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Question: </span>
                {question?.questionEnglish || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveQuestion}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_questions" fill justify>
              <Tab eventKey="key_form_question_details" title="Details">
                {formQuestionDetails}
              </Tab>
              <Tab eventKey="key_question_facts_form" title="Question Facts">
                {postQuestionFactsForm}
              </Tab>
              <Tab
                eventKey="key_form_manage_question_options"
                title="Manage Options"
                disabled={question?.answerType === "PLAIN_TEXT"}
              >
                {formQuestionManageOptions}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
