import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

SelectCategories.propTypes = {
  selectedCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectCategories.defaultProps = {
  selectedCategories: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  placeholder: "Select...",
  isDisabled: false,
};

const CATEGORIES = [
  "Blood Test",
  "PCR",
  "IV",
  "Injections",
  "Urine Test",
  "Saliva Test",
  "Physiotherapy"
];

function SelectCategories(props) {
  const {
    selectedCategories,
    onSelect,
    menuPlacement,
    placeholder,
    isMultiSelect,
    isDisabled,
  } = props;

  const findCategory = (category) =>
    CATEGORIES.find((it) => it.id === category) ?? category;

  const options = CATEGORIES.map(createOption);

  const selectedCategoriesOptions = selectedCategories
    .map(findCategory)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedCategories && selectedCategories.join(",")
      }`}
      value={selectedCategoriesOptions}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOption(category) {
  if (!category) return;
  return {
    key: category,
    value: category,
    label: category,
  };
}

export default SelectCategories;
