import React from "react";
import PropTypes from "prop-types";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

RatingBar.propTypes = {
  rating: PropTypes.number.isRequired,
};

const TOTAL_STARS = 5;

function RatingBar(props) {
  const rating =
    props.rating && props.rating > 0
      ? props.rating > TOTAL_STARS ? TOTAL_STARS : props.rating
      : 0;

  const stars = [...Array(TOTAL_STARS)].map((value, index) => {
    return index + 1 <= rating ? (
      <StarIcon style={{ fill: "black", fontSize: "large" }} />
    ) : (
      <StarBorderIcon style={{ fill: "black", fontSize: "large" }} />
    );
  });

  return (
    <div className="rating-container">
      {stars.map((star, index) => (
        <React.Fragment key={index}>{star}</React.Fragment>
      ))}
    </div>
  );
}

export default RatingBar;
