import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import RichTextEditor from "react-rte";
import {
  fetchSupplementCategories,
  saveSupplementCategory,
} from "services/SupplementService";
import Supplements from "./Supplements";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI"
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "components/Common/AccessDenied";
import Logs from "components/Logs/Logs";

import internalCategoryOptions from "utils/InterenalCategory";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];


class SupplementCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      supplementCategories: [],
      rankOptions: [],
      supplementCategory: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isSupplementCategoriesFetched: false,
      isSupplementCategoryViewModalVisible: false,
      categoryNameRichTextValue:RichTextEditor.createEmptyValue(),
      categoryNameArRichTextValue:RichTextEditor.createEmptyValue(),
      secondaryEnglishRichValue:RichTextEditor.createEmptyValue(),
      secondaryArabicRichValue:RichTextEditor.createEmptyValue(),

      pageIdForLogs : null,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name (English)",
        selector: "nameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "nameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (supplementCategory) => (
          <p style={{ textAlign: "right" }}>{supplementCategory.nameArabic}</p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (supplementCategory) => (
          <Status isActive={supplementCategory.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (supplementCategory) =>
          this.renderViewButton(supplementCategory.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchSupplementCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.SUPPLEMENTS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }
  };

  fetchSupplementCategories = async () => {
    this.showLoading();
    try {
      const supplementCategories = (await fetchSupplementCategories()) || []
      this.setState({ supplementCategories });
    } catch (error) {
      console.log("Error on fetching supplement categories", error);
      this.showErrorNotification(
        "Error on fetching supplement categories: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.setState({ isSupplementCategoriesFetched: true });
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const supplementCategory = { ...this.state.supplementCategory };
    supplementCategory[input.name] = input.value;
    this.setState({ supplementCategory });
  };

  handleAddNewSupplementCategory = () => {
    this.setState({ isNewSupplementCategory: true }, () =>
      this.showSupplementCategoryViewModal()
    );
  };

  handleViewSupplementCategory = (supplementCategoryId) => {

    this.setState({
      pageIdForLogs : supplementCategoryId
    })

    const supplementCategories = this.state.supplementCategories ?? [];
    const supplementCategory = supplementCategories.find(
      (it) => it.id === supplementCategoryId
    );
    if (!supplementCategory) {
      console.log(
        `Supplement Category with id ${supplementCategoryId} is not found!`
      );
      return;
    }

    const categoryNameRichTextValue=
      RichTextEditor.createValueFromString(supplementCategory.categoryNameRich ??"","html");
    const categoryNameArRichTextValue=
    RichTextEditor.createValueFromString(supplementCategory.categoryNameArRich??"","html");
    const secondaryEnglishRichValue=
    RichTextEditor.createValueFromString(supplementCategory.secondaryEnglish??"","html");
    const secondaryArabicRichValue=
    RichTextEditor.createValueFromString(supplementCategory.secondaryArabic??"","html");


    this.setState({ supplementCategory, categoryNameRichTextValue,categoryNameArRichTextValue,secondaryEnglishRichValue,secondaryArabicRichValue,isNewSupplementCategory: false }, () =>
      this.showSupplementCategoryViewModal()
    );
  };

  showSupplementCategoryViewModal = () => {
    const { selectedCountry, isNewSupplementCategory } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewSupplementCategory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const supplementCategory = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
        internalCategory: "None",
      };
      this.setState({
        supplementCategory,
        rankOptions,
        isSupplementCategoryViewModalVisible: true,
      });
    } else {
      this.setState({
        rankOptions,
        isSupplementCategoryViewModalVisible: true,
      });
    }
  };

  hideSupplementCategoryViewModal = () => {
    this.setState({ isSupplementCategoryViewModalVisible: false, pageIdForLogs:null });
    this.resetForm();
  };

  handleSaveSupplementCategory = async () => {
    const { selectedCountry, supplementCategory } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    if(supplementCategory){
      const{
     categoryNameRichTextValue,
     categoryNameArRichTextValue,
     secondaryEnglishRichValue,
     secondaryArabicRichValue,

      }=this.state;
     try{
      supplementCategory.categoryNameRich =
        categoryNameRichTextValue?.toString("html");
      supplementCategory.categoryNameArRich =
          categoryNameArRichTextValue?.toString("html");
          supplementCategory.secondaryEnglish =
          secondaryEnglishRichValue?.toString("html");
          supplementCategory.secondaryArabic =
          secondaryArabicRichValue?.toString("html");
     }
    catch(error){
      console.log("handleSave:: Error on setting content", error);
      supplementCategory.categoryNameRich="";
      supplementCategory.categoryNameArRich="";
      supplementCategory.secondaryEnglish="";
      supplementCategory.secondaryArabic="";
    }
  }



    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        supplementCategory.id !== null && supplementCategory.id !== undefined;

      if (supplementCategory.internalCategory == "None") {
        supplementCategory.internalCategory = null
      }
      console.log("supplementCategory", supplementCategory);
      const response = await saveSupplementCategory(supplementCategory);

      this.hideSupplementCategoryViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Supplement Category Updated Successfully."
          : "Supplement Category Created Successfully."
      );
      this.fetchSupplementCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { supplementCategory = {} } = this.state;
    const errors = {};

    const { nameEnglish, nameArabic, rank, status, country,seoUrl,categoryNameRich,categoryNameArRich,secondaryEnglish,secondaryArabic,categoryColorCode,secondaryColorCode } =
      supplementCategory;

    if (!nameEnglish || nameEnglish.trim() === "")
      errors.nameEnglish = "Name (English) cannot be blank";
    else if (nameEnglish.length > 100)
      errors.nameEnglish = "Name (English) is limited to a 100 characters";

    if (!nameArabic || nameArabic.trim() === "")
      errors.nameArabic = "Name (Arabic) cannot be blank";
    else if (nameArabic.length > 100)
      errors.nameArabic = "Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (status === null) errors.status = "Status is a required field!";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    if(!seoUrl || seoUrl.trim()==="")
     errors.seoUrl = "seoUrl cannot be blank";
    if(!categoryColorCode || categoryColorCode.trim()==="")
     errors.categoryColorCode = "categoryColorCode cannot be blank";
    /*if(!secondaryColorCode || secondaryColorCode.trim()==="")
     errors.secondaryColorCode = "secondaryColorCode cannot be blank";*/
    if(categoryNameRich=="<p><br></p>" || !categoryNameRich || categoryNameRich.trim()==="")
      errors.categoryNameRich="CategoryNameRich cannot be blank";
    if(categoryNameArRich=="<p><br></p>" || !categoryNameArRich || categoryNameArRich.trim()==="")
     errors.categoryNameArRich="CategoryNameArRich cannot be blank";
    if(secondaryEnglish=="<p><br></p>" || !secondaryEnglish || secondaryEnglish.trim()==="")
     errors.secondaryEnglish="secondaryEnglish cannot be blank";
    if(secondaryArabic=="<p><br></p>" || !secondaryArabic || secondaryArabic.trim()==="")
     errors.secondaryArabic="secondaryArabic cannot be blank";

   return Object.keys(errors).length === 0 ? null : errors;
  };

  createRankOptions = () => {
    const { isNewSupplementCategory } = this.state;
    const supplementCategories =
      this.getSupplementCategoriesForCurrentCountry();
    const ranks = supplementCategories
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, supplementCategories.length) || 0;
    const until = isNewSupplementCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      supplementCategory: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewSupplementCategory(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleRichText=(value,name)=>{
    this.setState({[name]: value });
  }

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewSupplementCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredSupplementCategories = () => {
    const supplementCategories =
      this.getSupplementCategoriesForCurrentCountry();
    return this.getSearchedSupplementCategories(supplementCategories);
  };

  getSupplementCategoriesForCurrentCountry = () => {
    const { supplementCategories, selectedCountry } = this.state;
    return supplementCategories && selectedCountry
      ? supplementCategories.filter((p) => p.country === selectedCountry.id)
      : [];
  };

  getSearchedSupplementCategories = (supplementCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return supplementCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return supplementCategories.filter((supplementCategory) => {
      const {
        id,
        nameEnglish,
        nameArabic,
        rank,
      } = supplementCategory;
      return (
        (nameEnglish && nameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (nameArabic &&
          nameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      supplementCategories,
      supplementCategory,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isNewSupplementCategory,
      isSupplementCategoriesFetched,
      isEditable,
    } = this.state;

    const filteredSupplementCategories =
      this.getFilteredSupplementCategories(supplementCategories) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
        <div className="form-group col" >
        <label htmlFor="nameArabic">Name (English)*</label>
            <div className="input-group">
              <input
                value={supplementCategory?.nameEnglish || ""}
                onChange={this.handleChange}
                id="nameEnglish"
                name="nameEnglish"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2 " +
                  (errors.nameEnglish ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.nameEnglish}
              />
              {this.renderPencilIcon("nameEnglish")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="nameArabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={supplementCategory?.nameArabic || ""}
                onChange={this.handleChange}
                id="nameArabic"
                name="nameArabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.nameArabic ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.nameArabic}
              />
              {this.renderPencilIcon("nameArabic")}
            </div>
            <span className="help-block">{errors.nameArabic}</span>
          </div>
        </div>

        <div className="row">
        <div className="form-group col" style={{maxWidth:'50%'}}>
          <label >
              Category Name (with RichText)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
                editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.categoryNameRichTextValue}
                onChange={(value)=>this.handleRichText(value,"categoryNameRichTextValue")}
                disabled={!isNewSupplementCategory && !editables.categoryNameRich}
              />
              {this.renderPencilIcon("categoryNameRich")}
            </div>
            <span className="help-block">{errors.categoryNameRich}</span>
          </div>
          <div className="form-group col" style={{maxWidth:'50%'}}>
          <label >
              Category Name (with RichText Arabic)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
                editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.categoryNameArRichTextValue}
                onChange={(value)=>this.handleRichText(value,"categoryNameArRichTextValue")}
                disabled={!isNewSupplementCategory && !editables.categoryNameArRich}
              />
              {this.renderPencilIcon("categoryNameArRich")}
            </div>
            <span className="help-block">{errors.categoryNameArRich}</span>
          </div>
        </div>
       

        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={supplementCategory?.rank}
                onChange={(event, value) => {
                  this.setState({
                    supplementCategory: {
                      ...supplementCategory,
                      rank: value.value,
                    },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewSupplementCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={supplementCategory?.status}
                onChange={(event, value) => {
                  this.setState({
                    supplementCategory: {
                      ...supplementCategory,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSupplementCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewSupplementCategory
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(supplementCategory?.country)?.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={supplementCategory?.internalCategory||"None"}
                onChange={(event, value) => {
                  this.setState({
                    supplementCategory: {
                      ...supplementCategory,
                      internalCategory: value.value,
                    },
                  });
                }}
                id="internalCategory"
                placeholder="internalCategory"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewSupplementCategory && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col" style={{maxWidth:'50%'}}>
        <label>Secondary Name (English)</label>
            <div className="input-group">
            <RichTextEditor
                rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
                editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.secondaryEnglishRichValue}
                onChange={(value)=>this.handleRichText(value,"secondaryEnglishRichValue")}
                disabled={!isNewSupplementCategory && !editables.secondaryEnglish}
              />
              {this.renderPencilIcon("secondaryEnglish")}
            </div>
            <span className="help-block">{errors.secondaryEnglish}</span>
          </div>
          <div className="form-group col" style={{maxWidth:'50%'}} >
            <label >Secondary Name (Arabic)</label>
            <div className="input-group">
            <RichTextEditor
                rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
                editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.secondaryArabicRichValue}
                onChange={(value)=>this.handleRichText(value,"secondaryArabicRichValue")}
                disabled={!isNewSupplementCategory && !editables.secondaryArabic}
              />
              {this.renderPencilIcon("secondaryArabic")}
            </div>
            <span className="help-block">{errors.secondaryArabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6" >
        <label htmlFor="nameArabic">Category Color Code*</label>
            <div className="input-group">
              <input
                value={supplementCategory?.categoryColorCode || ""}
                onChange={this.handleChange}
                id="categoryColorCode"
                name="categoryColorCode"
                type="text"
                placeholder="category color code"
                className={
                  "form-control py-2 " +
                  (errors.categoryColorCode ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.categoryColorCode}
              />
            {this.renderPencilIcon("categoryColorCode")}
            </div>
            <span className="help-block">{errors.categoryColorCode}</span>
          </div>
         {/* <div className="form-group col" >
        <label htmlFor="nameArabic">Secondary Color Code*</label>
            <div className="input-group">
              <input
                value={supplementCategory?.secondaryColorCode || ""}
                onChange={this.handleChange}
                id="secondaryColorCode"
                name="secondaryColorCode"
                type="text"
                placeholder="secondary color code"
                className={
                  "form-control py-2 " +
                  (errors.secondaryColorCode ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.secondaryColorCode}
              />
            {this.renderPencilIcon("secondaryColorCode")}
            </div>
            <span className="help-block">{errors.secondaryColorCode}</span>
       </div>*/}
          </div>
      </div>
       );

    const formSeoInformation=(<div className="container mt-4">
      <div className="row">
          <div className="form-group col-6">
            <label htmlFor="url_name">Seo URL*</label>
            <div className="input-group">
              <input
                value={supplementCategory?.seoUrl}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="URL Name"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="meta_title">Meta Title</label>
            <div className="input-group">
              <input
                value={supplementCategory?.metaTitleEnglish}
                onChange={this.handleChange}
                id="metaTitleEnglish"
                name="metaTitleEnglish"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitleEnglish ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.metaTitleEnglish}
              />
              {this.renderPencilIcon("metaTitleEnglish")}
            </div>
            <span className="help-block">{errors.metaTitleEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing.meta_title_ar">
              Meta Title Arabic
            </label>
            <div className="input-group">
              <input
                value={supplementCategory?.metaTitleArabic}
                onChange={this.handleChange}
                id="metaTitleArabic"
                name="metaTitleArabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaTitleArabic ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.metaTitleArabic}
              />
              {this.renderPencilIcon("metaTitleArabic")}
            </div>
            <span className="help-block">{errors.metaTitleArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="seo_marketing_meta_description">
              Meta Description
            </label>
            <div className="input-group">
              <input
                value={supplementCategory?.metaDescriptionEnglish}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescriptionEnglish"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescriptionEnglish? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.metaDescriptionEnglish}
              />
              {this.renderPencilIcon("metaDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.metaDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing_meta_description_ar">
              Meta Description Arabic
            </label>
            <div className="input-group">
              <input
                value={supplementCategory?.metaDescriptionArabic}
                onChange={this.handleChange}
                id="metaDescriptionArabic"
                name="metaDescriptionArabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewSupplementCategory && !editables.metaDescriptionArabic}
              />
              {this.renderPencilIcon("metaDescriptionArabic")}
            </div>
            <span className="help-block">{errors.metaDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={supplementCategory?.keywordsEnglish}
                onChange={this.handleChange}
                id="keywordsEnglish"
                name="keywordsEnglish"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywordsEnglish ? " has-error" : "")
                }
                readOnly={!isNewSupplementCategory && !editables.keywordsEnglish}
              ></textarea>
              {this.renderPencilIcon("keywordsEnglish")}
            </div>
            <span className="help-block">{errors.keywordsEnglish}</span>
          </div>    <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={supplementCategory?.keywordsArabic}
                onChange={this.handleChange}
                id="keywordsArabic"
                name="keywordsArabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywordsArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplementCategory && !editables.keywordsArabic}
              ></textarea>
              {this.renderPencilIcon("keywordsArabic")}
            </div>
            <span className="help-block">{errors.keywordsArabic}</span>
          </div>
        </div>
        </div>

    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewSupplementCategory}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Supplement Categories</p>
          <DataTable
            data={filteredSupplementCategories || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredSupplementCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isSupplementCategoryViewModalVisible}
          onHide={this.hideSupplementCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Supplement Category:</span>
                {supplementCategory.nameEnglish
                  ? ` ${supplementCategory.nameEnglish}`
                  : ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveSupplementCategory}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_supplement_categories" justify>
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab 
              eventKey="key_form_seo_information"
              title="Seo Information"
              >
                {formSeoInformation}
              </Tab>
              {this.state.pageIdForLogs!=null? 
                <Tab eventKey="logs-supplement-category" title="Logs" >
                    <Logs pageId={this.state.pageIdForLogs} pageType="SUPPLEMENT_CATEGORY" />
                </Tab>
              :""}
            </Tabs>
          </Modal.Body>
        </Modal>
        {isSupplementCategoriesFetched && countries && selectedCountry && (
          <Supplements
            categories={supplementCategories || []}
            countries={countries}
            selectedCountry={selectedCountry}
            isEditable={isEditable}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplementCategories);
