import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { Modal } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Select from "react-select";
import _ from "lodash";
import moment from "moment";
import SelectTimeslot from "./SelectTimeslot";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings, formatDate, formatTimeslot } from "utils/commons";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import ToggleSwitch from "components/Common/ToggleSwitch";
import SelectFamilyMember from "components/Common/SelectFamilyMember";
import SelectCustomTestAddonCreateOrder from "components/Common/SelectCustomTestAddonCreateOrder";
const CATEGORIES_OPTIONS = [
  {
    key: "Blood_Package",
    value: "Blood_Package",
    text: "Blood Biomarker Package",
  },
  {
    key: "Mini_Package",
    value: "Mini_Package",
    text: "Mini Package",
  },
  {
    key: "Custom_Package",
    value: "Custom_Package",
    text: "Custom Package",
  },
  {
    key: "Bundle",
    value: "Bundle",
    text: "Bundle",
  },
  {
    key: "Medicine",
    value: "Medicine",
    text: "Medicine",
  },
  {
    key: "Supplement",
    value: "Supplement",
    text: "Supplement",
  },
];

const Supplemet_Cart_Options=[
  {
    key: "Bundle",
    value: "Bundle",
    text: "Bundle",
  },
  {
    key: "Medicine",
    value: "Medicine",
    text: "Medicine",
  },
  {
    key: "Supplement",
    value: "Supplement",
    text: "Supplement",
  }

]

const BASE_URL_BLOOD_BIOMARKER_PACKAGES = "/bloods/packages/";
const BASE_URL_CUSTOM_PACKAGES = "packages/";
const BASE_URL_BUNDLES = "api/v1/bundles/";
const BASE_URL_SUPPLEMENTS = "/supplement/admin/v2/country";
const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages/active/country/";
const BASE_URL_CITY_CUSTOM_PACKAGES = "custom/packages/";
const BASE_URL_CUSTOM_ADDONS="/custom-package/add-ons";
class CartItemsForm extends Component {
  constructor(props) {
    super(props);

    const { cartItems, onCartItemsUpdated, client,country } = this.props;
    this.onCartItemsUpdated = onCartItemsUpdated;

    this.state = {
      cartItems,
      cartItem: {},
      itemDetails: null,
      subscriptionFrequencies: [], 
      subscriptionFrequencyOptions: [],
      bloodBiomarkerPackages: [],
      coachPackages: [],
      customPackages: [],
      bundles: [],
      supplements: [],
      medicines: [],
      miniPackages: [],
      serviceItemOptions: [],
      bundleToDelete: null,
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
      isAppointModalPopUp:false,
      isDateConfirm:false,
      isPackageAlreadyAdded:false,
      isConfirmAlreadyAdded:false,
      miniPackagesDropdownList: [],
      customAddons: [],
      isCommitedDaysRequired : true,
    };

    this.columns = [
      {
        name: "Type",
        selector: "itemType",
        sortable: true,
        wrap: true,
        format: (cartItem) => <p>{this.getFormattedItemType(cartItem)}</p>,
      },
      {
        name: "Name",
        selector: "itemName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Price",
        selector: "price",
        sortable: true,
        wrap: true,
        format: (cartItem) =>
          `${this.props.country?.currency} ${cartItem.price}`,
      },
      {
        name: "MiniPackage Price",
        selector: "miniPackagePrice",
        sortable: true,
        wrap: true,
        width:"200px",
        format: (cartItem) => this.renderMiniPackagePrice(cartItem),
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
        center: true,
        wrap: true,
      },
      {
        name: "Total Price",
        selector: "totalPrice",
        sortable: true,
        wrap: true,
        format: (cartItem) =>
          `${this.props.country?.currency} ${cartItem.totalPrice}`,
      },
      {
        name: "Appointment",
        selector: "selectedDate",
        sortable: true,
        wrap: true,
        minWidth: "150px",
        format: (cartItem) => {
          const date = cartItem.selectedDate ? formatDate(cartItem.selectedDate) : "N/A";
          const timeslot = cartItem.selectedSlot ? formatTimeslot(cartItem.selectedSlot) : "N/A";
          return <div>
            <span>{date}</span><br/>
            <span>{timeslot}</span><br/>
          </div>
        }
      },
      // {
      //   name: "Appointment Date",
      //   selector: "selectedDate",
      //   sortable: true,
      //   wrap: true,
      //   format: (cartItem) =>
      //     cartItem.selectedDate ? formatDate(cartItem.selectedDate) : "N/A",
      // },
      // {
      //   name: "Appointment Time",
      //   selector: "selectedTime",
      //   sortable: true,
      //   wrap: true,
      //   format: (cartItem) =>
      //     cartItem.selectedSlot ? formatTimeslot(cartItem.selectedSlot) : "N/A",
      // },
      {
        name: "Subscription",
        selector: "isSubscriptionOrder",
        sortable: false,
        wrap: true,
        minWidth: "150px",
        format: (cartItem) =>
          cartItem.isSubscriptionOrder 
            ? this.renderSubscriptionDetails(cartItem.subscriptionDetail) : "N/A",
      },
      {
        name: "Family Member",
        selector: "isFamilyMemberBooking",
        sortable: false,
        wrap: true,
        minWidth: "150px",
        format: (cartItem) =>
          cartItem.isFamilyMemberBooking 
            ? this.renderFamilyMemberDetails(cartItem.familyMemberId) : "N/A",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (cartItem) => this.renderActionBar(cartItem),
      },
    ];
  }

  componentDidMount() {
    this.fetchServiceItems();
  }

  componentDidUpdate(prevProps, prevState) {
    const { cartItem } = this.state;
    const { cartItem: oldCartItem } = prevState;
    if (cartItem?.itemType !== oldCartItem?.itemType)
      this.createServiceItemOptions();
  }

  fetchServiceItems = () => {
    Promise.all([
      this.fetchBloodBiomarkerPackages(),
      this.fetchCustomPackages(),
      this.fetchBundles(),
      this.fetchSupplements(),
      this.fetchMedicines(),
      this.fetchMiniPackages(),
    ]);
  };

  fetchBloodBiomarkerPackages = async () => {
    const {city}=this.props;
    if (!city) {
      console.log("fetchBloodPackages:: Invalid country or city!", {
        city
      });
      return;
    }
    try {
      const { data: bloodBiomarkerPackages } = await PhoenixAPI.get(
        `${BASE_URL_BLOOD_BIOMARKER_PACKAGES}?city=${city.id}`
      );
      this.setState({ bloodBiomarkerPackages });
    } catch (error) {
      console.log("Error on fetching blood biomarker packages", error);
    }
  };

  fetchCustomPackages = async () => {
    const {city}=this.props;
    if (!city) {
      console.log("fetchCustomPackages:: Invalid city!", {
        city
      });
      return;
    }
    try {
      const { data: customPackages } = await PhoenixAPI.get(`${BASE_URL_CITY_CUSTOM_PACKAGES}?city=${city.id}`);
      this.setState({ customPackages });
    } catch (error) {
      console.log("Error on fetching custom packages", error);
    }
  };

  fetchBundles = async () => {
    try {
      const { data: bundles } = await PhoenixAPI.get(BASE_URL_BUNDLES);
      this.setState({ bundles });
    } catch (error) {
      console.log("Error on fetching bundles", error);
    }
  };

  fetchSupplements = async () => {
    const { country } = this.props;
    if (!country ) {
      console.log("fetchMiniPackages:: Invalid country or city!", {
        country,
        
      });
      return;
    }
    try {
      const { data: supplements } = await PhoenixAPI.get(`${BASE_URL_SUPPLEMENTS}/${country.id}`);
      this.setState({ supplements });
    } catch (error) {
      console.log("Error on fetching supplements", error);
    }
  };

  fetchMedicines = async () => {
    try {
      const { data: medicines } = await PhoenixAPI.get(BASE_URL_MEDICINES);
      this.setState({ medicines });
    } catch (error) {
      console.log("Error on fetching medicines", error);
    }
  };

  fetchMiniPackages = async () => {
    const { country, city } = this.props;
    if (!country || !city) {
      console.log("fetchMiniPackages:: Invalid country or city!", {
        country,
        city,
      });
      return;
    }

    try {
      const { data: miniPackages } = await PhoenixAPI.get(
        `${BASE_URL_MINI_PACKAGES}${country.id}?city=${city.id}`
      );
      this.setState({ miniPackages });
    } catch (error) {
      console.log("Error on fetching mini packages!", error);
    }
  };

  getFormattedItemType = (cartItem) => {
    if (!cartItem) return "";
    const option = CATEGORIES_OPTIONS.find(
      (it) => it.value === cartItem.itemType
    );
    return option ? option.text : cartItem.itemType;
  };

  renderMiniPackagePrice=(cartItem)=>{
    if(!cartItem || !cartItem.isBloodMiniPackageBundle) return "N/A" 
    const {miniPackagePrice="",customAddOnPackagesPrice=""}=cartItem ||{}
     return (
      <div>{cartItem.itemType=="Blood_Package"?miniPackagePrice:cartItem.itemType=="Custom_Package"?customAddOnPackagesPrice:""}</div>
     )
  }

  calculateSubscriptionPrice = (cartItem) => {
    const { price, subscriptionDetail } = cartItem;
    const { discountType, discountAmount } = subscriptionDetail ?? {};
    if (price && discountType && discountAmount) {
      if (discountType.toLowerCase() === "fixed") {
        return price - Math.min(price, discountAmount);
      } else {
        return price - Math.min(price, this.percentOf(price, discountAmount));
      }
    }
  }

  percentOf = (amount, percentage) => {
    const result = amount * (percentage / 100);
    return parseFloat(result.toFixed(2));
  }

  createServiceItemOptions = () => {
    const {
      bloodBiomarkerPackages = [],
      customPackages = [],
      bundles = [],
      supplements = [],
      medicines = [],
      cartItem,
    } = this.state;
    console.log("Creating service item options");
    switch (cartItem?.itemType) {
      case "Blood_Package": {
        const serviceItemOptions = this.createOptions(
          bloodBiomarkerPackages,
          cartItem?.itemType
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Custom_Package": {
        const serviceItemOptions = this.createOptions(
          customPackages,
          cartItem?.itemType
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Bundle": {
        const serviceItemOptions = this.createOptions(
          bundles,
          cartItem?.itemType
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Medicine": {
        const serviceItemOptions = this.createOptions(
          medicines,
          cartItem?.itemType
        );
        this.setState({ serviceItemOptions });
        break;
      }
      case "Supplement": {
        const serviceItemOptions = this.createOptions(
          supplements,
          cartItem?.itemType
        );
        this.setState({ serviceItemOptions });
        break;
      }
      default: {
        console.log("Invalid service item category", cartItem?.itemType);
        return;
      }
    }
  };

  createOptions = (items, category) => {
    const { country } = this.props;
    const itemsForCountry = items.filter((it) => it.country === country?.id);
    const activeItems =
      category === "Blood_Package"
        ? itemsForCountry.filter((it) => it.is_active ?? false)
        : itemsForCountry.filter((it) => it.status === "Active");
    activeItems.sort((first, second) => {
      const firstName =
        category === "Custom_Package"
          ? first.name
          : category === "Blood_Package"
          ? first.internal_name
          : first.internalName;
      const secondName =
        category === "Custom_Package"
          ? second.name
          : category === "Blood_Package"
          ? second.internal_name
          : second.internalName;
      return compareStrings(firstName, secondName, true);
    });
    return activeItems.map((item) => this.createOption(item, category));
  };

  createOption = (item, category) => {
    let name;
    if (category === "Custom_Package"){
      name = item.name;
      return{
        key: item.id,
        value: name,
        label: name,
        isSampleCollectionRequired : item.is_require_sample_collection,
        internalCategory : item.internal_category
      }
    } 
    else if (category === "Blood_Package"){
      name = item.internal_name;
    } 
    else{
      name = item.internalName;
    } 
    return {
      key: item.id,
      value: name,
      label: name,
    };
  };

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      cartItem: {},
      cartItemToDelete: null,
      errors: {},
      editables: {},
      miniPackagesDropdownList: [],
      itemDetails: null,
      subscriptionFrequencies: [],
      subscriptionFrequencyOptions: [],
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const { name, value } = input;
    const cartItem = { ...this.state.cartItem };
    if (name === "quantity")
      cartItem[name] =
        value && value.toString().trim() !== "" ? Number(value) : value;
    else cartItem[name] = value;

    this.setState({ cartItem });
  };

  handleAddNew = () => {
    const cartItem = {};
    this.setState({ cartItem }, () => {
      this.showAddEditModal()
    });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { cartItems = [] } = this.state;
    const cartItem = cartItems.find((it) => it.id === id);
    if (!cartItem) return;

    this.setState({ cartItem }, async () => {
      this.fetchPackageDetails();
      this.fetchSubscriptionDetails();
      await this.updateMiniPackagesDropdownList();
      this.showAddEditModal();
    });
  };

  handleDelete = (id) => this.deleteCartItem(id);

  handleSubmit = (event) => {
   // event.preventDefault();
   const { cartItems = [], cartItem,isDateConfirm,isConfirmAlreadyAdded,isPackageAlreadyAdded } = this.state;
    

    console.log("is calling handle submit");
   
    
   
   

    if (cartItem.id === null || cartItem.id === undefined ){
      
       
      
      if(  isConfirmAlreadyAdded  ){
        
        
        this.createCartItem();
        this.hideAlreadyPackageAddedModal();
      }
      
        else{
          console.log("is calling handle submit in else");
      this.createCartItem();
        }
    }
  
    else
    { this.updateCartItem(cartItem.id);}
   
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { itemDetails } = this.state;
    const {
      itemType,
      itemName,
      quantity,
      selectedDate,
      selectedSlot,
      isBloodMiniPackageBundle,
      miniPackages,
      isSubscriptionOrder,
      subscriptionDetail,
      isFamilyMemberBooking,
      familyMemberId,
      committedDaysMax,
      committedDaysMin,
      customPackageAddonIds
    } = this.state.cartItem || {};

    if (!itemType || itemType.trim() === "")
      errors.itemType = "Item Category cannot be blank";

    if (!itemName || itemName.trim() === "")
      errors.itemName = "Item Name cannot be blank";
    else if (
      itemType === "Mini_Package" &&
      (!miniPackages || !miniPackages.length)
    )
      errors.itemName = "At least 1 mini pickage is required";

    if (
      quantity === null ||
      quantity === undefined ||
      quantity.toString().trim() === ""
    )
      errors.quantity = "Quantity cannot be blank";
    else if (quantity && Number.isNaN(Number(quantity)))
      errors.quantity = "Quantity should be a valid number";
    else if (!this.isInBetween(quantity, 1, 99999999))
      errors.quantity = "Quantity should be between 1 and 99999999";

    if (this.isAppointmentRequired()) {
      if (!selectedDate)
        errors.selectedDate = "Appointment Date cannot be blank";
      if (!selectedSlot)
        errors.selectedSlot = "Appointment Time cannot be blank";
    }

    if (isBloodMiniPackageBundle) {
      if(itemType=="Blood_Package"){
        if (!miniPackages || !miniPackages.length) {
        errors.miniPackages = "Please add atleast one mini package";
        }
      }
      if(itemType=="Custom_Package"){
        if(!customPackageAddonIds || !customPackageAddonIds.length){
          errors.customPackageAddons = "Please add atleast one custom package addon";
        }
      }

    }

    if (isSubscriptionOrder && !subscriptionDetail) {
      errors.subscriptionDetail = "Please select subscription frequency";
    }

    if (isFamilyMemberBooking && itemType!=="Mini_Package") {
     
      const { member_applicable, member, is_bundle } = itemDetails;
      if (Boolean(is_bundle)) {
        errors.familyMemberId = "Bundles are not supported.";
      } else if (!familyMemberId) {
        errors.familyMemberId = "Please select family member.";
      }
      // else {
      //   const familyMember = this.findFamilyMemberById(familyMemberId);
      //   const packageGender = member && member.length ? member[0].gender : null;
      //   if (!familyMember || !familyMember.gender) {
      //     errors.familyMemberId = "Select family member.";
      //   } else if (familyMember.gender !== packageGender) {
      //     errors.familyMemberId = `Please add 1 ${packageGender === "M" ? "male" : "female"} member.`; 
      //   }
      // }
    }

    //  validation for MiniPackages with family member
    if (isFamilyMemberBooking && itemType === "Mini_Package") {
      if (!familyMemberId) {
        errors.familyMemberId = "Please select family member.";
      }
    }
    if ((committedDaysMin === null || committedDaysMin === undefined || committedDaysMin.toString().trim() === "") && itemType ==="Custom_Package" && this.state.isCommitedDaysRequired==true ) {
      errors.committedDaysMin = "Please fill days in Committed days to client for results (Min. days)";
    }
    if ((committedDaysMax === null || committedDaysMax === undefined || committedDaysMax.toString().trim() === "") && itemType ==="Custom_Package" && this.state.isCommitedDaysRequired==true ) {
      errors.committedDaysMax = "Please fill days in Committed days to client for result (Max. days)";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => {
    const n = Number(number);
    return n >= start && n <= end;
  };

  createCartItem = async() => {
    const { cartItems = [], cartItem } = this.state;
    const {client,country}= this.props;
    const ids = cartItems.length > 0 ? cartItems.map((it) => it.id) : null;
    cartItem.id = ids ? Math.max(...ids) + 1 : 1;
    cartItem.is_new = true;
    const itemObject = this.findItemObject(
      cartItem?.itemId,
      cartItem?.itemType
    );
    console.log("item object is",itemObject);
    if(cartItem?.itemType=="Blood_Package" || cartItem?.itemType=="Custom_Package" )
    cartItem.isCoddisabled=itemObject.is_eligible_for_cod;
    else if( cartItem?.itemType=="Supplement")
      cartItem.isCoddisabled=itemObject.isEligibleCod;
    else{
      cartItem.isCoddisabled=false;
    }
    const itemPrice =
      cartItem?.itemType === "Mini_Package"
        ? this.findMiniPackageItemPrice(cartItem)
        : await this.findItemPrice(cartItem?.itemType, itemObject,cartItem?.itemId);
    cartItem.price = itemPrice;
    cartItem.totalPrice = itemPrice * cartItem?.quantity;
    if (cartItem.isSubscriptionOrder) {
      const discountedPrice = this.calculateSubscriptionPrice(cartItem);
      cartItem.totalPrice = discountedPrice * cartItem?.quantity;
    } else {
      cartItem.totalPrice = itemPrice * cartItem?.quantity;
    }
    if (cartItem.isBloodMiniPackageBundle) {
      if(cartItem.itemType=="Blood_Package"){
      const miniPackagePrice = this.findMiniPackageItemPrice(cartItem);
      cartItem.miniPackagePrice = miniPackagePrice;
      cartItem.totalPrice += miniPackagePrice * cartItem?.quantity;
      }
      else if(cartItem.itemType=="Custom_Package"){
        const addonPrice = this.findCustomAddonsPrice(cartItem);
        console.log("addonPrice is", addonPrice);
        cartItem.customAddOnPackagesPrice=addonPrice;
        cartItem.totalPrice += addonPrice * cartItem?.quantity;

      }
    }
    const {nationality=""}= client;
    const vatPercentage = country
    ? Number(country.vat_percentage)
    : null;
    let totalVatAmount=0;
  //  if(cartItem?.itemType.includes[""])

    if(nationality==="saudi" && country==="Kingdom of Saudi Arabia"){
      totalVatAmount=0;
    }
    else if (nationality==="expats" && country==="Kingdom of Saudi Arabia"){
      console.log("item type ",cartItem?.itemType,itemObject,itemObject.is_vat_excluded)
       if(cartItem?.itemType=="Blood_Package" || cartItem?.itemType=="Custom_Package" ){
        if(itemObject.is_vat_excluded)
           totalVatAmount=0;
          else{
            totalVatAmount= (cartItem.totalPrice)*(vatPercentage)/100;
          }
       }
       else if(cartItem?.itemType=="Supplement" || cartItem?.itemType=="Mini_Package" ){
           if(itemObject.isVatExcluded)
            totalVatAmount=0;
          else{
           totalVatAmount= (cartItem.totalPrice)*(vatPercentage)/100;
          }
       }
       else{
      totalVatAmount= (cartItem.totalPrice)*(vatPercentage)/100;
       }
    }   // tax amount calculation of nationality 

    else{
      if(cartItem?.itemType=="Blood_Package" || cartItem?.itemType=="Custom_Package" ){
        console.log("item type in else ",cartItem?.itemType,itemObject,itemObject.is_vat_excluded)
        if(itemObject.is_vat_excluded)
          {console.log("case 0");
          totalVatAmount=0;
          }
          else{
            console.log("case 1");
            totalVatAmount= vatPercentage
      ? (cartItem.totalPrice * vatPercentage) / 100
      : null; 
          }
       }
       else if(cartItem?.itemType=="Supplement" || cartItem?.itemType=="Mini_Package" ){
          console.log("item object minipacakge",itemObject);
           if(itemObject?.isVatExcluded)
            totalVatAmount=0;
          else{ totalVatAmount= vatPercentage
           ? (cartItem.totalPrice * vatPercentage) / 100
           : null; 
          }
       }
      else{
        console.log("case not true");
      totalVatAmount= vatPercentage
      ? (cartItem.totalPrice * vatPercentage) / 100
      : null;  
      console.log("total vat amount",totalVatAmount);
      }
    }
    
    cartItem.couponDiscount=0;
    cartItem.walletAmountUsed=0;
    cartItem.vat=totalVatAmount;
    console.log("cartItem is",cartItem);
    this.setState({ cartItems: [...cartItems, cartItem] }, () => {
      this.showSuccessNotification("Cart Item Added Successfully!");
      this.onCartItemsUpdatedLocally();
    });
  };

  updateCartItem = async(id) => {
    const { cartItems = [], cartItem } = this.state;
    const itemObject = this.findItemObject(
      cartItem?.itemId,
      cartItem?.itemType
    );
    if(cartItem?.itemType=="Blood_Package" || cartItem?.itemType=="Custom_Package" )
    cartItem.isCoddisabled=itemObject.is_eligible_for_cod;
   else if( cartItem?.itemType=="Supplement")
    cartItem.isCoddisabled=itemObject.isEligibleCod;
   else{
     cartItem.isCoddisabled=false;
   }
    const itemPrice = cartItem?.itemType === "Mini_Package"
      ? this.findMiniPackageItemPrice(cartItem)
      : await this.findItemPrice(cartItem?.itemType, itemObject,cartItem?.itemId);
    cartItem.price = itemPrice;
    if (cartItem.isSubscriptionOrder) {
      const discountedPrice = this.calculateSubscriptionPrice(cartItem);
      cartItem.totalPrice = discountedPrice * cartItem?.quantity;
    } else {
      cartItem.totalPrice = itemPrice * cartItem?.quantity;
    }
    if (cartItem.isBloodMiniPackageBundle) {
      if(cartItem.itemType=="Blood_Package") {
      const miniPackagePrice = this.findMiniPackageItemPrice(cartItem);
      cartItem.miniPackagePrice = miniPackagePrice;
      cartItem.totalPrice += miniPackagePrice * cartItem?.quantity;
      }
      else if(cartItem.itemType=="Custom_Package"){
        const addonPrice = this.findCustomAddonsPrice(cartItem);
        cartItem.customAddOnPackagesPrice=addonPrice;
        cartItem.totalPrice += addonPrice * cartItem?.quantity;
      }
    }
    else{
      delete cartItem.miniPackagePrice;
      // delete cartItem.miniPackages;  
      cartItem.totalPrice = cartItem.totalPrice;
    }
    const newCartItems = cartItems.map((it) => (it.id === id ? cartItem : it));
    this.setState({ cartItems: newCartItems }, () => {
      this.showSuccessNotification("Cart Item Updated Successfully!");
      this.onCartItemsUpdatedLocally();
    });
  };

  deleteCartItem = (id) => {
    const { cartItems = [] } = this.state;
    const newCartItems = cartItems.filter((it) => it.id !== id);
    this.setState({ cartItems: newCartItems }, () => {
      this.showSuccessNotification("Cart Item Deleted Successfully!");
      this.onCartItemsUpdatedLocally();
    });
  };

  onCartItemsUpdatedLocally = () => {
    this.clearForm();
    this.onCartItemsUpdated(
      this.state.cartItems.map((cartItem) => {
        const c = { ...cartItem };
        if (c.is_new) {
          // delete c.id;
          delete c.is_new;
        }
        return c;
      })
    );
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false, bundleToDelete: null });
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false,isDateConfirm:false });
  };
  showConfirmAppointmentModal=()=>{
  //show Appointment Date modal
    this.setState({isAppointModalPopUp:true});
  }
  hideAppointmentModal=()=>{
   //hide appointment Date modal popup
    this.setState({ isAppointModalPopUp: false,isDateConfirm:true});

  }

  showAlreadyPackageAddedModal=()=>{
    
    this.setState({isPackageAlreadyAdded:true});
  }

  hideAlreadyPackageAddedModal=()=>{
    
    this.setState({isPackageAlreadyAdded: false,isConfirmAlreadyAdded:false});
  }

  cancelAppointmentDate=(Date)=>{
    const{cartItem}=this.state;
    this.setState({ isAppointModalPopUp:false,
      cartItem: {
        ...cartItem,
        selectedDate: null,
        
      },
    })
  }




  findItemObject = (itemId, itemType) => {
    const {
      bloodBiomarkerPackages = [],
      customPackages = [],
      miniPackages = [],
      bundles = [],
      supplements = [],
      medicines = [],
    } = this.state;
    switch (itemType) {
      case "Blood_Package":
        return bloodBiomarkerPackages.find((it) => it.id === itemId);
      case "Custom_Package":
        return customPackages.find((it) => it.id === itemId);
      case "Mini_Package":
        return miniPackages.find((it) => it.id === itemId);
      case "Bundle":
        return bundles.find((it) => it.id === itemId);
      case "Medicine":
        return medicines.find((it) => it.id === itemId);
      case "Supplement":
        return supplements.find((it) => it.id === itemId);
      default: {
        console.log("findItemObject:: invalid itemType:", itemType);
        return null;
      }
    }
  };

  findMiniPackageItemPrice = (cartItem) => {
    if (!cartItem || !cartItem.miniPackages || !cartItem.miniPackages.length)
      return 0;

    let totalPrice = 0;
    for (const id of cartItem.miniPackages) {
      const miniPackage = this.findMiniPackage(id);
      totalPrice += miniPackage?.price ?? 0;
    }
    return totalPrice;
  };

  findCustomAddonsPrice=(cartItem)=>{
    if (!cartItem || !cartItem.customPackageAddonIds || !cartItem.customPackageAddonIds.length)
      return 0;
    let totalPrice = 0;
    for (const id of cartItem.customPackageAddonIds) {
      const customPackage = this.findCustomAddon(id);
      totalPrice += customPackage?.finalPrice?? 0;
    }

    console.log("total price is", totalPrice);
    return totalPrice;
  }

  fetchBloodPackageCities=async(packageId)=>{
    if(!packageId){
     return null;
    }
    try{
    
     const url=`/blood-packages-cities/bloodPackageId/${packageId}`;
     const response= await PhoenixAPI.get(url);
     console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", response);
     return response && response.data;
    }
    catch(error){
     console.log("fetchBloodPackageCities:: Fetched Blood Packages: ", error);
     this.showErrorNotification("Error on fetching BloodPackage items! " + error.message);
     return null;
    }

 }

 fetchCustomPackageCities = async (packageId) => {
  if (!packageId) {
    return null;
  }
  try {
   
    const url = `/custom-packages-cities/customPackageId/${packageId}`;
    const response = await PhoenixAPI.get(url);
    console.log("fetchCustomPackageCities:: Fetched custom Packages: ", response);
    return response && response.data;
  } catch (error) {
    console.log(
      "fetchCustomPackageCities:: Fetched custom Packages: ",
      error
    );
    this.showErrorNotification(
      "Error on fetching Custom Package items! " + error.message
    );
    return null;
  } 
};

fetchPackageDetails = async () => {
  const { cartItem } = this.state;
  if (!cartItem) {
    console.log("fetchPackageDetails:: Invalid cart item", cartItem);
    this.setState({ itemDetails: null });
    return;
  }

  const { itemType, itemId } = cartItem;
  let url;
  switch(itemType.toLowerCase()) {
    case "blood_package": 
      url = `/subscription-packages/${itemId}/`;
      break;  
    case "custom_package": 
      url = `/packages/${itemId}/`;
      break;
    default: url = null;
  }

  if (!url) {
    console.log("fetchPackageDetails:: Invalid url!", url);
    this.setState({ itemDetails: null });
    return;
  }
  
  try {
    const { data: itemDetails } = await API.get(url);
    this.setState({ itemDetails });
  } catch (error) {
    this.setState({ itemDetails: null });
    console.log("fetchPackageDetails:: Error on fetching package details", error);
    this.showErrorNotification("Error on fetching package details! " + error.message);
    return null;
  } 
};

fetchSubscriptionDetails = async () => {
  const { cartItem } = this.state;
  if (!cartItem) {
    console.log("fetchSubscriptionDetails:: Invalid cart item", cartItem);
    this.setState({ subscriptionFrequencies: [], subscriptionFrequencyOptions: [] });
    return;
  }

  const { itemType, itemId } = cartItem;
  let url;
  switch(itemType.toLowerCase()) {
    case "medicine": 
      url = `/medicine/${itemId}`;
      break;
    case "supplement": 
      url = `/supplement/${itemId}`;
      break;
    case "bundle": 
      url = `/api/v1/bundles/${itemId}`;
      break;
    case "custom_package": 
      url = `/subscription-items/Custom_Package/${itemId}`;
      break;
    case "blood_package": 
      url = `/subscription-items/Blood_Package/${itemId}`;
      break;  
    default: url = null;
  }

  if (!url) {
    console.log("fetchSubscriptionDetails:: Invalid url!", url);
    this.setState({ subscriptionFrequencies: [], subscriptionFrequencyOptions: [] });
    return;
  }
  
  try {
    const { data: itemDetails } = await PhoenixAPI.get(url);
    const { enableSubscription, subscriptionItems } = itemDetails ?? {};
    if (enableSubscription && subscriptionItems && subscriptionItems.length) {
      const subscriptionFrequencyOptions = subscriptionItems
        .filter((item) => item.status && item.status.toLowerCase() === "active")
        .sort((first, second) => first.noOfMonths - second.noOfMonths)
        .map((item) => {
          const { id, noOfMonths, discountType, discountAmount ,noOfDays} = item;
          const frequency = noOfMonths?`${noOfMonths} ${noOfMonths > 1 ? "Months" : "Month"}`:`${noOfDays} ${noOfDays>1?"Days":"Day"}`;
          const discount = discountType && discountAmount
            ? discountType.toLowerCase() === "fixed"
              ? `${this.props.country.currency} ${discountAmount} off`
              : `${discountAmount}% off`
            : null;
          return { 
            key: id, 
            value: id, 
            text: `${frequency} ${discount ? "(" + discount + ")" : ""}`.trim() 
          }
        });
      this.setState({ subscriptionFrequencies: subscriptionItems, subscriptionFrequencyOptions });
    }
  } catch (error) {
    this.setState({ subscriptionFrequencies: [], subscriptionFrequencyOptions: [] });
    console.log(
      "fetchSubscriptionDetails:: Error on fetching subscription details: ",
      error
    );
    this.showErrorNotification(
      "Error on fetching subscription details! " + error.message
    );
  } 
};

findSubscriptionFrequencyById = (id) => {
  const { subscriptionFrequencies } = this.state;
  return subscriptionFrequencies && subscriptionFrequencies.length
    ? subscriptionFrequencies.find((it) => it.id === id)
    : null;
}

  findItemPrice = async(itemType, itemObject, itemId) => {
    const { country={}, city={} } = this.props;
    if (itemType === "Blood_Package") {
      const cityPackageDetails= await this.fetchBloodPackageCities(itemId); 
      const cityPrice=cityPackageDetails.filter((it)=>it.cityId===city.id);
      return cityPrice[0]?.finalPrice ?? null;
    }
    if (itemType === "Custom_Package") {
      const cityPackageDetails= await this.fetchCustomPackageCities(itemId); 
      const cityPrice=cityPackageDetails.filter((it)=>it.cityId===city.id);
      return cityPrice[0]?.finalPrice ?? null;
    };
    if (["Bundle", "Medicine", "Supplement"].includes(itemType))
      return itemObject?.finalPrice ?? null;
    console.log("Unknown item type:", itemType);
    return null;
  };

  findMiniPackage = (id) => {
    const miniPackages = this.state.miniPackages ?? [];
    return miniPackages.find((miniPackage) => miniPackage.id === id);
  };

  findCustomAddon=(id)=>{
    const customAddons= this.state.customAddons ?? [];
    return customAddons.find((customAddon) => customAddon.id === id);
  }

  calculateDifference = (endDate) => {
    const startDateMoment = moment();
    const endDateMoment = moment(endDate);
    const today=moment().format("YYYY-MM-DD");
  
   
    const endDateFormat=moment(endDate,"YYYY-MM-DD",true);
   
    if(endDateFormat.isSame(today)){

    return "same";
    }
      
    // Calculate the difference in days
  
    const differenceInDays = endDateMoment.diff(startDateMoment, 'days');
    
    return differenceInDays;
  
  };

  isAppointmentRequired = () => {
    const { cartItem } = this.state;
    const { itemType, itemId } = cartItem ?? {};
    if (["Blood_Package", "Mini_Package"].includes(itemType)) return true;
    if (itemType === "Custom_Package") {
      const itemObject = this.findItemObject(itemId, itemType);
      const { operation_type } = itemObject ?? {};
      return operation_type && operation_type.includes("Home Appointment");
    }
    return false;
  };

  renderSubscriptionDetails = (subscriptionDetail) => {
    const { noOfMonths,noOfDays, discountType, discountAmount } = subscriptionDetail;
    const frequency = noOfMonths?`${noOfMonths} ${noOfMonths > 1 ? "Months" : "Month"}`:`${noOfDays} ${noOfDays > 1 ? "Days" : "Day"}`;
    const discount = discountType && discountAmount
      ? discountType.toLowerCase() === "fixed"
        ? `${this.props.country.currency} ${discountAmount} off`
        : `${discountAmount}% off`
      : null;
    return<div>
      <span>{frequency}</span><br/>
      <span>{discount}</span>
    </div>
  }

  renderFamilyMemberDetails = (familyMemberId) => {
    const familyMember = this.findFamilyMemberById(familyMemberId);
    if (!familyMember) {
      console.log("renderFamilyMemberDetails:: Family member not found with id=", familyMemberId);
      return "";
    }

    const { firstName, lastName, relation } = familyMember;
    const name = `${firstName ?? ""} ${lastName ?? ""}`.trim(); 
    return `${name} ${relation ? "(" + relation + ")" : ""}`;
  }

  renderActionBar = (bundleItem) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(bundleItem.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ bundleToDelete: bundleItem.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.cartItem.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };
  handleConfirmPopup=()=>{
    const { cartItems = [], cartItem,isDateConfirm,isConfirmAlreadyAdded,isPackageAlreadyAdded } = this.state;
    const { itemType, itemId, isFamilyMemberBooking, familyMemberId,selectedDate,selectedSlot } = cartItem ?? {};

    const alreadyAdded =!isConfirmAlreadyAdded? cartItems.find((cartItem) =>
      itemType === "Mini_Package"
        ? cartItem.itemType === itemType
        : itemType === "Blood_Package" || itemType === "Custom_Package" 
          ? cartItem.itemType === itemType && cartItem.itemId === itemId 
            && cartItem.isFamilyMemberBooking === isFamilyMemberBooking 
            && cartItem.familyMemberId === familyMemberId
            && cartItem.selectedDate ===selectedDate && cartItem.selectedSlot===selectedSlot
          : cartItem.itemType === itemType && cartItem.itemId === itemId
    ) :<></>;

    const errors = this.validate();
    
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    else{
      if(this.state.cartItem.selectedDate!==null && this.state.cartItem.selectedDate!==undefined)
      this.setState({isAppointModalPopUp:true});
      else{
         if(alreadyAdded){
          this.setState({isPackageAlreadyAdded:true});
         }

        else{
        this.handleSubmit();
        }
      }
    }

  };

  handleAppointmentConfirmation = () => {

    const { cartItems = [], cartItem,isDateConfirm,isConfirmAlreadyAdded,isPackageAlreadyAdded } = this.state;
    const { itemType, itemId, isFamilyMemberBooking, familyMemberId,selectedDate,selectedSlot } = cartItem ?? {};

    const alreadyAdded = cartItems.find((cartItem) =>
      itemType === "Mini_Package"
        ? cartItem.itemType === itemType
        : itemType === "Blood_Package" || itemType === "Custom_Package" 
          ? cartItem.itemType === itemType && cartItem.itemId === itemId 
            && cartItem.isFamilyMemberBooking === isFamilyMemberBooking 
            && cartItem.familyMemberId === familyMemberId
            && cartItem.selectedDate ===selectedDate && cartItem.selectedSlot===selectedSlot
          : cartItem.itemType === itemType && cartItem.itemId === itemId
    ) 
    this.hideAppointmentModal();
    if(alreadyAdded && alreadyAdded.id!==cartItem.id){

      this.setState({isPackageAlreadyAdded:true,isDateConfirm:true});
       
    }
    else{
      console.log("is date confirm");
    this.setState({ isDateConfirm: true }, () => this.handleSubmit());
    }
  
    
    
  };

  handleAlreadyAddedPackageConfirmation = () => {
        const{cartItem}=this.state;
     this.setState({isConfirmAlreadyAdded:true,cartItem},()=>this.handleSubmit());
     
  };
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  
  // https://api.bevaleo.dev/api/v1/subscription-packages/18/
  findBloodBiomarkerPackageById = async (id) => {
    try {
      this.showLoading();
      const { data: bloodPackage } = await API.get(`subscription-packages/${id}/`);
      return bloodPackage;
    } catch (error) {
      console.log("findBloodBiomarkerPackageById:: Error on fetch!", error);
    } finally {
      this.hideLoading();
    }
  }

  findFamilyMemberById = (id) => {
    const { familyMembers } = this.props;
    return familyMembers && familyMembers.length
      ? familyMembers.find((it) => it.id === id)
      : null;
  }
  findCustomAddonsByCustomId=async(id)=>{
    const{country,city}=this.props;
    try{
      console.log("custom addons",BASE_URL_CUSTOM_ADDONS,country.id,city.id);
      const{data:customAddons}=await PhoenixAPI.get(`${BASE_URL_CUSTOM_ADDONS}/${country.id}/${city.id}/${id}`);
   
     return customAddons;
    }catch(error){

    }

  }


  updateMiniPackagesDropdownList = async () => {
    console.log("updateMiniPackagesDropdownList:: Trying to update mini package dropdown list.");
    
    const { cartItem, miniPackages } = this.state;
    console.log("cart item ",cartItem.itemType,cartItem,);
    console.log("flag value ",!cartItem || cartItem.itemType !== "Blood_Package" || cartItem.itemType !== "Custom_Package" || !cartItem.itemId || !cartItem.isBloodMiniPackageBundle);
    if (!cartItem || (cartItem.itemType !== "Blood_Package" &&   cartItem.itemType!=="Custom_Package") || !cartItem.itemId || !cartItem.isBloodMiniPackageBundle) {
      console.log("updateMiniPackagesDropdownList:: Update not required!", { cartItem });
      return;
    }

    console.log("updateMiniPackagesDropdownList:: Update required", { cartItem });
    
    const bloodPackage = cartItem.itemType=="Custom_Package"?await this.findCustomAddonsByCustomId(cartItem.itemId):await this.findBloodBiomarkerPackageById(cartItem.itemId);
    
    if(cartItem.itemType=="Blood_Package"){
    const bloodPackageTests = bloodPackage && bloodPackage.tests && bloodPackage.tests.length
      ? bloodPackage.tests.map((test) => test.id)
      : [];
    const miniPackagesDropdownList =
      miniPackages && miniPackages.length
        ? bloodPackageTests && bloodPackageTests.length
          ? miniPackages.filter((miniPackage) => {
              const { tests: miniPackageTests } = miniPackage;
              if (miniPackageTests && miniPackageTests.length) {
                return !miniPackageTests.every((it) => bloodPackageTests.includes(it));
              }
              return true;
            })
          : miniPackages
        : [];
        console.log("minipackage dropdown list {}",miniPackagesDropdownList);
        this.setState({ miniPackagesDropdownList });
          }

          else if(cartItem.itemType=="Custom_Package"){
                const customAddons=bloodPackage;
                console.log("Custom addons", customAddons);
          this.setState({customAddons});
          }
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { orderFlow, city } = this.props;
    const {
      cartItems = [],
      cartItem = {},
      errors,
      editables,
      sortColumn,
      isAppointModalPopUp,
     
    } = this.state;
      
    const isNewCartItem = cartItem.id === null || cartItem.id === undefined;

    const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

    

    const formSubscriptionFrequency = (
      <div className="row">
        <div className="form-group col-3">
          <label>Is subscription order?</label>
        </div>
        <div className="form-group col-2">
          <ToggleSwitch
            id="toggle_switch_is_subscription_order"
            toggled={cartItem.isSubscriptionOrder ?? false}
            onToggle={(isToggled) => {
              this.setState({ cartItem: { ...cartItem, isSubscriptionOrder: isToggled, subscriptionDetail: null }});
            }}
            disabled={false}
          />
        </div>
        {cartItem.isSubscriptionOrder && (
          <div className="form-group col-5">
            <label htmlFor="subscriptionDetail">Subscription Frequency</label>
            <div className="input-group">
              <Dropdown
                value={cartItem.subscriptionDetail?.id ?? null}
                onChange={(event, value) => {
                  const subscriptionDetail = this.findSubscriptionFrequencyById(value.value);
                  this.setState({
                    cartItem: { ...cartItem, subscriptionDetail },
                  });
                }}
                id="subscriptionDetail"
                name="subscriptionDetail"
                search
                selection
                options={this.state.subscriptionFrequencyOptions ?? []}
                disabled={!isNewCartItem && !editables.subscriptionDetail}
                className={"form-control" + (errors.subscriptionDetail ? " has-error" : "")}
              />
              {this.renderPencilIcon("subscriptionDetail")}
              <span className="help-block">{errors.subscriptionDetail}</span>
            </div>
          </div>
        )}
      </div>
    );

    const formFamilyMemberBooking = (
      <div className="row">
        <div className="form-group col-3">
          <label>Booking for family member?</label>
        </div>
        <div className="form-group col-2">
          <ToggleSwitch
            id="toggle_switch_family_member_booking"
            toggled={cartItem.isFamilyMemberBooking ?? false}
            onToggle={(isToggled) => {
              this.setState({ cartItem: { ...cartItem, isFamilyMemberBooking: isToggled, familyMemberId: null }});
            }}
            disabled={false}
          />
        </div>
        {cartItem.isFamilyMemberBooking && (
          <div className="form-group col-5">
            <label htmlFor="familyMemberId">Family Member</label>
            <div className="input-group">
              <SelectFamilyMember
                id="familyMemberId"
                name="familyMemberId"
                familyMembers={this.props.familyMembers ?? []}
                selectedFamilyMemberId={cartItem?.familyMemberId ?? null}
                onSelect={(option) => {
                  this.setState({
                    cartItem: {...cartItem, familyMemberId: option.value},
                  });
                }}
                showOnlyActive={true}
                isDisabled={!isNewCartItem && !editables.familyMemberId}
              />
              {this.renderPencilIcon("familyMemberId")}
            </div>
            <span className="help-block">{errors.familyMemberId}</span>
          </div>
        )}
      </div>
    );

    const formBloodMiniPackageBundle = (
      <div className="row">
        <div className="form-group col-3">
          <label>{cartItem?.itemType=="Blood_Package"?"Need Mini Packages":"Need CustomAddons"}</label>
        </div>
        <div className="form-group col-2">
          <ToggleSwitch
            id="toggle_switch_mini_packages"
            toggled={cartItem.isBloodMiniPackageBundle ?? false}
            onToggle={(isToggled) => {
              this.setState(
                { 
                  cartItem: { ...cartItem, isBloodMiniPackageBundle: isToggled }
                }, 
                () => this.updateMiniPackagesDropdownList()
              );
            }}
            disabled={false}
          />
        </div>
        {cartItem.isBloodMiniPackageBundle && cartItem.itemType=="Blood_Package" &&(
          <div className="form-group col-5">
            <label htmlFor="miniPackages">Mini Packages</label>
            <div className="input-group">
              <SelectMiniPackages
                id="miniPackages"
                name="miniPackages"
                miniPackages={this.state.miniPackagesDropdownList ?? []}
                selectedMiniPackageIds={cartItem?.miniPackages ?? []}
                onSelect={(options) => {
                  this.setState({
                    cartItem: {
                      ...cartItem,
                      miniPackages: options.map((it) => it.value),
                    },
                  });
                }}
                menuPlacement="bottom"
                showOnlyActive={true}
                isDisabled={!isNewCartItem && !editables.miniPackages}
              />
              {this.renderPencilIcon("miniPackages")}
              <span className="help-block">{errors.miniPackages}</span>
            </div>
          </div>
        )}

       {cartItem.isBloodMiniPackageBundle && cartItem.itemType=="Custom_Package"  && (
        <div className="form-group col-6">
        <label>Custom Test AddOns</label>
        <div className="input-group">
        <SelectCustomTestAddonCreateOrder
        selectedCustomPackageIds={cartItem?.customPackageAddonIds??[]}
        id="customTestAddons"
        name="customTestAddons"
        packageId={cartItem.itemId}
        cityId={this.props.city.id}
        countryId={this.props.country.id}
       // customPackages={this.state.customAddons??[]}
        onSelect={(options) => {
          this.setState({ cartItem: {
            ...cartItem,
            customPackageAddonIds: options.map((option)=>option.value),
          }
        })}}
        showOnlyActive={true}
        isDisabled={!isNewCartItem && !editables.customTestAddons}
        className={
          "form-control" +
          (errors.custom_addons ? " has-error" : "")
        }
        />
          {this.renderPencilIcon("customTestAddons")}
          <span className="help-block">{errors.customPackageAddons}</span>
        </div>
        </div>
     
       
      )}
      </div>
    );

    const formAppointmentDateTime = (
      <div className="row">
        <div className="form-group col-5">
          <label htmlFor="selectedDate">Appointment Date*</label>
          <div className="input-group">
            <input
              value={cartItem?.selectedDate ?? ""}
              onChange={(e) =>{ 
                this.setState({ 
                  cartItem: {
                    ...cartItem,
                    selectedDate: e.target.value,
                    selectedSlot: null,
                  }
                 
                },)}
              }
              type="date"
              id="selectedDate"
              name="selectedDate"
              min={
                orderFlow === "Current" &&
                moment(new Date()).format("YYYY-MM-DD")
              }
              // max={
              //   orderFlow === "Past" && moment(new Date()).format("YYYY-MM-DD")
              // }
              disabled={!isNewCartItem && !editables.selectedDate}
              className={
                "form-control" + (errors.selectedDate ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("selectedDate")}
          </div>
          <span className="help-block">{errors.selectedDate}</span>
        </div>
        <div className="form-group col-5">
          <label htmlFor="selectedSlot">Appointment Time*</label>
          <div className="input-group">
            <SelectTimeslot
              orderFlow={orderFlow}
              cityId={city?.id}
              packageType={
                cartItem?.itemType ? cartItem?.itemType.toLowerCase() : ""
              }
              packageId={cartItem?.itemId}
              miniPackages={
                cartItem.itemType === "Mini_Package"
                  ? cartItem.miniPackages
                  : null
              }
              date={cartItem?.selectedDate}
              selectedTimeslot={cartItem?.selectedSlot}
              onTimeslotSelect={(timeslot) =>
                this.setState({
                  cartItem: { ...cartItem, selectedSlot: timeslot },
                })
              }
              isDisabled={!isNewCartItem && !editables.selectedSlot}
              className={
                "form-control" + (errors.selectedSlot ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("selectedSlot")}
          </div>
          <span className="help-block">{errors.selectedSlot}</span>
        </div>
      </div>
    );
    const committedDayMinMax = (
      <div className="row"  >
        <div className="form-group col-5" style={{display: this.state.isCommitedDaysRequired? 'block' : 'none' }} >
          <label htmlFor="committedDaysMin">Committed days to client for results (Min. days) {this.state.isCommitedDaysRequired? <span>*</span> : "" } </label>
          <div className="input-group">
            <input
              value={cartItem?.committedDaysMin ?? ""}
              onChange={(e) => {
                let input = e.target.value;
                input = (input == "" ? "" : Number(input));
                this.setState({
                  cartItem: {
                    ...cartItem,
                    committedDaysMin: input,
                    // selectedSlot: null,
                  }

                },)
              }
              }
              type="number"
              id="committedDaysMin"
              name="committedDaysMin"
              // min={
              //   orderFlow === "Current" &&
              //   moment(new Date()).format("YYYY-MM-DD")
              // }
              // max={
              //   orderFlow === "Past" && moment(new Date()).format("YYYY-MM-DD")
              // }
              disabled={!isNewCartItem && !editables.committedDaysMin}
              className={
                "form-control" + (errors.committedDaysMin ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("committedDaysMin")}
          </div>
          <span className="help-block">{errors.committedDaysMin}</span>
        </div>
        <div className="form-group col-5" style={{display: this.state.isCommitedDaysRequired? 'block' : 'none' }} >
          <label htmlFor="committedDaysMax">Committed days to client for result (Max. days) {this.state.isCommitedDaysRequired? <span>*</span> : "" } </label>
          <div className="input-group">
            <input
              value={cartItem?.committedDaysMax ?? ""}
              onChange={(e) => {
                let input = e.target.value;
                input = (input == "" ? "" : Number(input));
                this.setState({
                  cartItem: {
                    ...cartItem,
                    committedDaysMax: input,
                    // selectedSlot: null,
                  }

                },)
              }
              }
              type="number"
              id="committedDaysMax"
              name="committedDaysMax"
              // min={
              //   orderFlow === "Current" &&
              //   moment(new Date()).format("YYYY-MM-DD")
              // }
              // max={
              //   orderFlow === "Past" && moment(new Date()).format("YYYY-MM-DD")
              // }
              disabled={!isNewCartItem && !editables.committedDaysMax}
              className={
                "form-control" + (errors.committedDaysMax ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("committedDaysMax")}
          </div>
          <span className="help-block">{errors.committedDaysMax}</span>
        </div>
      </div>
    );

    const itemDropdown =
      cartItem?.itemType === "Mini_Package" ? (
        <SelectMiniPackages
          id="itemName"
          name="itemName"
          miniPackages={
            this.state.miniPackages && this.state.miniPackages.length
              ? this.state.miniPackages.filter((it) => it.cityId === this.props.city?.id)
              : []
          }
          selectedMiniPackageIds={cartItem?.miniPackages ?? []}
          onSelect={(options) => {
            this.setState({
              cartItem: {
                ...cartItem,
                itemId: null,
                itemName: "Custom Blood Test Package",
                selectedSlot: null,
                miniPackages: options.map((it) => it.value),
                quantity: 1,
              },
            });
          }}
          showOnlyActive={true}
          placeholder="Item Name"
          isDisabled={!isNewCartItem && !editables.itemName}
        />
      ) : (
        <Select
          key={`my_unique_select_key__${cartItem && cartItem.itemName}`}
          id="itemName"
          name="itemName"
          value={
            cartItem && {
              key: cartItem?.itemId,
              value: cartItem?.itemName,
              label: cartItem?.itemName,
            }
          }
          onChange={(option) => {
            console.log("Selected Item", option);
            if(cartItem.itemType=="Custom_Package"){
              if(option.isSampleCollectionRequired==true && option.internalCategory==="Custom orders(with or without review)" ){
                this.setState({
                  isCommitedDaysRequired : true
                })
              }
              else{
                this.setState({
                  isCommitedDaysRequired : false
                })
              }
            }
            this.setState({
              cartItem: {
                ...cartItem,
                itemId: option.key,
                itemName: option.value,
                selectedSlot: null,
              },
            }, () => {
              this.fetchPackageDetails();
              this.fetchSubscriptionDetails();
              this.updateMiniPackagesDropdownList();
            });
          }}
          options={this.state.serviceItemOptions || []}
          styles={selectStyles}
          placeholder="Item Name"
          isDisabled={!isNewCartItem && !editables.itemName}
        />
      );

    const formCartItem = (
      <>
        <div className="row">
          <div className="form-group col-5">
            <label htmlFor="itemType">Item Category*</label>
            <div className="input-group">
              <Dropdown
                value={cartItem?.itemType}
                onChange={(event, value) => {
                  this.setState({
                    cartItem: {
                      itemType: value.value,
                      id: cartItem.id ?? null,
                      quantity: cartItem.quantity ?? null,
                    },
                  });
                }}
                id="itemType"
                name="itemType"
                placeholder="Select Item Category"
                search
                selection
                options={this.props.orderType=="Supplement Orders"?Supplemet_Cart_Options:CATEGORIES_OPTIONS}
                disabled={!isNewCartItem && !editables.itemType}
                className={
                  "form-control" + (errors.itemType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("itemType")}
            </div>
            <span className="help-block">{errors.itemType}</span>
          </div>
          <div className="form-group col-5">
            <label htmlFor="itemName">Item Name*</label>
            <div className="input-group" style={{ zIndex: "101" }}>
              {itemDropdown}
              {this.renderPencilIcon("itemName")}
            </div>
            <span className="help-block">{errors.itemName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="quantity">Quantity*</label>
            <div className="input-group">
              <input
                value={cartItem.quantity ?? ""}
                onChange={this.handleChange}
                id="quantity"
                name="quantity"
                type="number"
                placeholder="Quantity"
                readOnly={
                  cartItem?.itemType === "Mini_Package" ||
                  (!isNewCartItem && !editables.quantity)
                }
                className={
                  "form-control py-2" + (errors.quantity ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("quantity")}
            </div>
            <span className="help-block">{errors.quantity}</span>
          </div>
        </div>
        {this.isAppointmentRequired() && formAppointmentDateTime}
        {cartItem && cartItem.itemType==="Custom_Package" && committedDayMinMax}
        {cartItem && cartItem.itemType !== "Mini_Package" && cartItem.itemId && formSubscriptionFrequency}
        {["Blood_Package", "Custom_Package", "Mini_Package"].includes(cartItem?.itemType) && formFamilyMemberBooking}
        {cartItem && (cartItem.itemType === "Blood_Package" || cartItem.itemType=="Custom_Package") && cartItem?.itemId  && formBloodMiniPackageBundle}
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={cartItems || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {cartItem.id === null || cartItem.id === undefined
                ? "Add Cart Item"
                : "Edit Cart Item"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{formCartItem}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={()=>{this.handleConfirmPopup()}}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {cartItem.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
          <Modal   show={this.state.isAppointModalPopUp} 
         // onHide={this.hideAppointmentModal}
          >
          <Modal.Header >
          <Modal.Title>  Appointment Date Confirmation </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.calculateDifference(this.state.cartItem.selectedDate)=="same"?<>Is it okay the collection date is today?</>:this.calculateDifference(this.state.cartItem.selectedDate)>0?<>Is it okay the collection date is {this.calculateDifference(this.state.cartItem.selectedDate)+1} days from now?</>
         :this.calculateDifference(this.state.cartItem.selectedDate)==0?<>Is it okay the collection date is {this.calculateDifference(this.state.cartItem.selectedDate)+1} day from now?</>:
         this.calculateDifference(this.state.cartItem.selectedDate)==-1?<>Is it okay the collection date is {Math.abs(this.calculateDifference(this.state.cartItem.selectedDate))} day past from now?</>:
         <>Is it okay the collection date is {Math.abs(this.calculateDifference(this.state.cartItem.selectedDate))} days past from now ?</>
              }</Modal.Body>
          <Modal.Footer>
          
            <button
              onClick={()=>this.handleAppointmentConfirmation()}
              className="btn btn-success rounded-pill px-4 py-1"
            >
             Confirm
            </button>
            <button
              className="btn btn-danger rounded-pill px-4 py-1"
              onClick={()=>this.cancelAppointmentDate(cartItem.selectedDate)}
            >
              Cancel
            </button>
          </Modal.Footer>
         
        </Modal>

        <Modal
        show={this.state.isPackageAlreadyAdded}
        >
          <Modal.Header >
          <Modal.Title>  Item is already in cart </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to add it to your cart again?
          </Modal.Body>

          <Modal.Footer>
          
            <button
              onClick={()=>this.handleAlreadyAddedPackageConfirmation()}
              className="btn btn-success rounded-pill px-4 py-1"
            >
             Confirm
            </button>
            <button
              className="btn btn-danger rounded-pill px-4 py-1"
              onClick={()=>this.hideAlreadyPackageAddedModal(cartItem.selectedDate)}
            >
              Cancel
            </button>
          </Modal.Footer>

        </Modal>

        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Cart Item</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the cart item?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteCartItem(this.state.bundleToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

CartItemsForm.propTypes = {
  orderFlow: PropTypes.object.isRequired,
  city: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
  cartItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCartItemsUpdated: PropTypes.func.isRequired,
};

CartItemsForm.defaultProps = {
  cartItems: [],
  onCartItemsUpdated: (f) => f,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItemsForm);
