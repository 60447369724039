import React, { Component } from "react";
import { connect } from "react-redux";
import CountryDropdown from "components/Common/CountryDropdown";
import { fetchCountries } from "services/RegionService";
import AccessDenied from "../../components/Common/AccessDenied";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Dropdown } from "semantic-ui-react";
import AlertBox from "components/AlertBox/AlertBox";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import { getUserId, getUserName } from "services/UserService";
import Search from "components/Search/Search";
import { Page } from "utils/constant";
import Status from "components/Common/Status";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import { getCoachUsers } from "services/SeoLandingService";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import {
  fetchSeoPages,
  saveSeoPage,
  updateSeoPage,
  updateCoach,
} from "services/SeoLandingService";
import DataTable from "react-data-table-component";
import _ from "lodash";
import RichTextEditor from "react-rte";
import SelectCoach from "pages/Orders/SelectCoach";
import FAQForm from "components/Common/FAQForm";
import ValeoSelection from "./ValeoSelection";
import HeaderSection from "./HeaderSection";

import {
  fetchBloodPackages,
  fetchCustomPackages,
} from "services/productService";
import { getDefaultCoach } from "services/SeoLandingService";
import RelatedServicesForm from "components/Common/RelatedServicesForm";
import moment from "moment";
import {CharLimit} from "utils/CharachterLimit";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_SUPPLEMENTS = "/supplement/admin/v2/country/";
const BASE_URL_Packages = "cms-package-id-name";
const TWO_MEGA_BYTE = 2_097_152;
const BASE_URL_COACH_CONSULTATION_PACKAGE = "coach-consultation-package";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
const CATEGORIES_OPTIONS = [
  {
    key: "Supplements",
    value: "supplement",
    text: "Supplements",
  },
  {
    key: "Packages",
    value: "packages",
    text: "Packages",
  },

  {
    key: "Coach Consultation Packages",
    value: "coach_consultation_package",
    text: "Coach Consultation Packages",
  },
];
const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };
class SeoLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      seoPages: [],
      seoPage: {},
      errors: {},
      loadError: null,
      editables: {},
      isNewSeoPage: false,
      searchTerm: null,
      selectedCountry: null,
      showModal: false,
      isSaving: false,
      wellbeingCoaches: [],
      selectedCoachId: null,
      packages: [],
      supplements: [],
      serviceItemOptions: [],
      coachConsultationPackages: [],
      relatedServices: [],
      isGenerate: false,
      // H1headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H1HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H1headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // h1HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H2headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H2HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H2headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // h2HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H3headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H3HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H3headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // h3HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),

      // sectionValue: 1,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Landing Page Name",
        selector: "landingPageName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirect Link",
        selector: "landingPageRedirectionLink",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.isActive} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (seoPage) => this.renderViewButton(seoPage.id),
      },
    ];
  }

  validate = () => {
    const { seoPage = {} } = this.state;
    const errors = {};

    const {
      isActive,
      landingPageName,
      landingPageRedirectionLink,
      landingPageRedirectionLinkAr,
      h1HeaderText,
      h1HeaderTextAr,
      h1HeaderSubText,
      h1HeaderSubTextAr,
      image_src1,
      seoHeaderSection,
      urlName,
      landingPageRedirectionText,
      landingPageRedirectionTextAr,
      isConnectUs,
      isBookConsultation,
      connectTextEnglish,
      connectTextArabic,
      bookConsultationTextEnglish,
      bookConsultationTextArabic
    } = seoPage;

 

    if (!landingPageName || landingPageName.trim()==="")
      errors.landingPageName = "Landing page name cannot be blank";
    else if(landingPageName.length> CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.landingPageName = "Landing page name can not take more than 100 characters!";
    }
    if (!landingPageRedirectionLink || landingPageRedirectionLink.trim()==="")
      errors.landingPageRedirectionLink = "Redirect link cannot be blank";
    else if(landingPageRedirectionLink.length> CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.landingPageRedirectionLink = "Landing page Redirection  link name can not take more than 255 characters!";
    }
    if (!landingPageRedirectionLinkAr || landingPageRedirectionLinkAr.trim()==="")
      errors.landingPageRedirectionLinkAr =
        "Redirection arabic link cannot be blank";
        else if(landingPageRedirectionLinkAr.length> CharLimit.TWO_FIFTY_FIVE_LIMIT){
          errors.landingPageRedirectionLinkAr = "Landing page Redirection  link name can not take more than 255 characters!";
        }
    if (!urlName) errors.urlName = "URL name cannot be blank";
    else if(urlName.length> CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.urlName = "URL name can not take more than 255 characters!";
    }
    if(!landingPageRedirectionText) errors.landingPageRedirectionText="Landing page Redirection Text cannot be blank";
    else if(landingPageRedirectionText.length > CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.landingPageRedirectionText = "Landing page Redirection Text English can not take more than 255 characters!";
    }
    if(!landingPageRedirectionTextAr) errors.landingPageRedirectionTextAr="Landing page Redirection Text Arabic cannot be blank";
    else if(landingPageRedirectionTextAr.length > CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.landingPageRedirectionTextAr = "Landing page Redirection Text Arabic can not take more than 255 characters!";
    }
     if(isConnectUs){
      if (!connectTextEnglish || connectTextEnglish.trim()==="")
      errors.connectTextEnglish = "ConnectText English cannot be blank";
      else if(connectTextEnglish.length>CharLimit.TWO_FIFTY_FIVE_LIMIT){
        errors.connectTextEnglish = "ConnectText English can not take more than 255 characters!";
      }
      if (!connectTextArabic || connectTextArabic.trim()==="")
      errors.connectTextArabic = "ConnectText Arabic name cannot be blank";
     }
     else if(connectTextArabic.length>CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.connectTextArabic = "ConnectText Arabic can not take more than 255 characters!";
     }
   
    if(isBookConsultation){
      if (!bookConsultationTextEnglish || bookConsultationTextEnglish.trim()==="")
      errors.bookConsultationTextEnglish = "BookConsultation Text English English cannot be blank";
     else if(bookConsultationTextEnglish.length>CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.bookConsultationTextEnglish = "BookConsultation Text English can not take more than 255 characters!";
     }
      if (!bookConsultationTextArabic || bookConsultationTextArabic.trim()==="")
      errors.bookConsultationTextArabic = "BookConsultation Text Arabic cannot be blank";
    else if(bookConsultationTextArabic.length>CharLimit.TWO_FIFTY_FIVE_LIMIT){
      errors.bookConsultationTextArabic = "BookConsultation Text Arabic can not take more than 255 characters!";
 
    }
    }
   if(seoHeaderSection && seoHeaderSection.length==0){
    errors.seoHeaderSection = "At least add one header section ";

   }

    return Object.keys(errors).length === 0 ? null : errors;
    

  };

  handleUpdated = (relatedAllService = []) => {
    const seoPage = {
      ...this.state.seoPage,
      relatedServices: relatedAllService,
    };

    this.setState({ seoPage });
  };

  handleFaqsUpdated=(faqs=[])=>{
    const newFaqs= faqs.map((item=>{
      return {
        id:item.id ??null,
        questionEnglish:item.questionEnglish,
        questionArabic:item.questionArabic,
        answerEnglish:item.answerEnglish,
        answerArabic:item.answerArabic,
        isActive:item.status==="Active",
        rank:item.rank
      }
    }))
    const seoPage = {
     ...this.state.seoPage,
      seoFaqs:newFaqs
    }
    this.setState({seoPage})
  }

  mappingSeoFaqs=(faqs=[])=>{
    if(faqs.length>0){
      const mapFaqs=faqs.length>0&&faqs.map((item)=>{
        return {
          id:item.id,
          questionEnglish:item.questionEnglish,
          questionArabic:item.questionArabic,
          answerEnglish:item.answerEnglish,
          answerArabic:item.answerArabic,
         status:item.isActive?"Active":"Inactive",
         rank:item.rank
        }
      })
      return mapFaqs ??[]
    }
 
    else return []
    
  }

  handleValeoSectionUpdated=(valeoSections=[])=>{
    const seoPage = {
      ...this.state.seoPage,
      seoValeoSection
      :valeoSections
     }
     this.setState({seoPage})
  }


  handleHeaderSectionUpdated=(headerSection=[])=>{
    const seoPage = {
     ...this.state.seoPage,
      seoHeaderSection
      :headerSection
     }
     this.setState({seoPage})
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchAclUser(),
      this.fetchCountries(),
      // this.fetchWellbeingCoaches(),
      this.fetchSeoPages(),
    ]);
    this.fetchDefaultCoachDetails();
    // this.getDefaultCoach();
    this.getPackages();
    this.fetchCoachPackages();
    this.fetchSupplements();
    // this.fetchBloodPackages();
    // this.fetchCustomPackages();
  }

  getPackages = async () => {
    try {
      const { data: packages } = await PhoenixAPI.get(BASE_URL_Packages);

      this.setState({ packages: packages ?? [] });
    } catch (error) {
      console.log("packages:: Error on getting packages", error);
      throw error;
    }
  };

  getDefaultCoach = async () => {
    const { selectedCountry } = this.state;
    try {
      
      const selectedDefaultCoach = await getDefaultCoach(selectedCountry.id);
      

      this.setState({
        selectedCoachId: selectedDefaultCoach?.coachId
          ? selectedDefaultCoach?.coachId
          : "",
      });
    } catch (error) {
      console.log("Error on fetching wellbeing coaches", error);
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  fetchSeoPages = async () => {
    try {
      this.showLoading();
      const seoAllPages = (await fetchSeoPages()) ?? [];
      this.setState({ seoPages: seoAllPages });
    } catch (error) {
      this.showErrorNotification("Error on loading articles: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  fetchWellbeingCoaches = async () => {
    try {
      const wellbeingCoaches = (await getCoachUsers()) || [];
      this.setState({ wellbeingCoaches: wellbeingCoaches });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching wellbeing coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { seoPage, selectedCountry } = this.state;
    const { seoPage: oldSeoPage } = prevState;
 
    if (seoPage?.landingPageProductType !== oldSeoPage?.landingPageProductType)
      this.createServiceItemOptions();
    if (selectedCountry !== prevState.selectedCountry) {
      // this.getDefaultCoach();
      this.fetchSupplements();
    }
  }

  createServiceItemOptions = () => {
    const {
      packages = [],
      coachConsultationPackages = [],

      supplements = [],

      seoPage,
    } = this.state;


    switch (seoPage?.landingPageProductType) {
      case "packages": {
        const serviceItemOptions = this.createOptions(packages, "packages");
        this.setState({ serviceItemOptions: serviceItemOptions });

        break;
      }

      case "supplement": {
        const serviceItemOptions = this.createOptions(
          supplements,
          "supplement"
        );
        this.setState({ serviceItemOptions });

        break;
      }
      case "coach_consultation_package": {
        const serviceItemOptions = this.createOptions(
          coachConsultationPackages,
          "coach_consultation_package"
        );
        this.setState({ serviceItemOptions });

        break;
      }
      default: {
        return;
      }
    }
  };

  createOptions = (items, category) => {
    
    const itemsForCountry = items.filter((it) =>
      category == "supplement"
        ? it.country === this.state.seoPage.countryId
        : it.countryId === this.state.seoPage.countryId
    );
    
    const [activeItems, inactiveItems] = _.partition(itemsForCountry, (it) =>
      category === "packages" || category === "coach_consultation_package"
        ? it.isActive
        : it.status == "Active"
    );
    
    return activeItems.map((item) => this.createOption(item, category));
  };

  createOption = (item, category) => {
    let name;

    if (category === "packages") name = item.name;
    // else if (category === "Blood_Package") name = item.subscription_name;
    else name = item.internalName;
    return {
      key: item.id,
      value: item.id,
      label: name,
    };
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchSupplements = async () => {
    const { seoPage, selectedCountry } = this.state;
   
    try {
      const { data: supplements } = await PhoenixAPI.get(
        `${BASE_URL_SUPPLEMENTS}${selectedCountry.id}`
      );
      this.setState({ supplements: supplements });
    } catch (error) {
      console.log("Error on fetching supplements", error);
    }
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.ARTICLES;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification("Error on fetching countries!", error.message);
    }
  };

  handleAddNew = () => {
    this.setState({ isNewSeoPage: true, }, () =>
      this.showModal()
    );
  };

  getSeoPageDetails = async (id) => {
    try {
      this.showLoading();
      const seoDetailsUrl = `v2/seo-landing-page/${id}`;
      const { data: seoPageDetail } = await PhoenixAPI.get(seoDetailsUrl);
      return seoPageDetail;
    } catch (error) {
      this.showErrorNotification("Error on loading articles: ", error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleView = async (id) => {
    // const seoPage = seoPages.find((it) => it.id === id);
    const seoPage = await this.getSeoPageDetails(id);
    //await  this.fetchSupplements(seoPage.countryId);
  
   
    // const sectionValue = this.state.sectionValue;
    // const H1headerRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h1HeaderText ?? "",
    //   "html"
    // );
    // const H2headerRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h2HeaderText ?? "",
    //   "html"
    // );
    // const H3headerRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h3HeaderText ?? "",
    //   "html"
    // );
    // const H1HeaderRichTextArabicValue = RichTextEditor.createValueFromString(
    //   seoPage.h1HeaderTextAr ?? "",
    //   "html"
    // );
    // const H2HeaderRichTextArabicValue = RichTextEditor.createValueFromString(
    //   seoPage.h2HeaderTextAr ?? "",
    //   "html"
    // );
    // const H3HeaderRichTextArabicValue = RichTextEditor.createValueFromString(
    //   seoPage.h3HeaderTextAr ?? "",
    //   "html"
    // );
    // const H1headerSubtextRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h1HeaderSubText ?? "",
    //   "html"
    // );
    // const H2headerSubtextRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h2HeaderSubText ?? "",
    //   "html"
    // );
    // const H3headerSubtextRichTextValue = RichTextEditor.createValueFromString(
    //   seoPage.h3HeaderSubText ?? "",
    //   "html"
    // );
    // const h1HeaderSubtextRichTextArabicValue =
    //   RichTextEditor.createValueFromString(
    //     seoPage.h1HeaderSubTextAr ?? "",
    //     "html"
    //   );
    // const h2HeaderSubtextRichTextArabicValue =
    //   RichTextEditor.createValueFromString(
    //     seoPage.h2HeaderSubTextAr ?? "",
    //     "html"
    //   );
    // const h3HeaderSubtextRichTextArabicValue =
    //   RichTextEditor.createValueFromString(
    //     seoPage.h3HeaderSubTextAr ?? "",
    //     "html"
    //   );
    
    if (seoPage) {
      this.setState(
        {
          isNewSeoPage: false,
          seoPage: {
            ...seoPage,
          },
          // sectionValue,
          // H1headerRichTextValue,
          // H2headerRichTextValue,
          // H3headerRichTextValue,
          // H1HeaderRichTextArabicValue,
          // H2HeaderRichTextArabicValue,
          // H3HeaderRichTextArabicValue,
          // H1headerSubtextRichTextValue,
          // H2headerSubtextRichTextValue,
          // H3headerSubtextRichTextValue,
          // h1HeaderSubtextRichTextArabicValue,
          // h2HeaderSubtextRichTextArabicValue,
          // h3HeaderSubtextRichTextArabicValue,
        },
        () => this.showModal()
      );
    }
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );
  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      this.setState({ isSaving: false });
      return;
    }

    const { seoPage,selectedCountry } = this.state;
    // article.meta_title_arabic=article.meta_title_arabic||article.metaTitle
    //  article.meta_description_arabic=article.meta_description_arabic||article.metaDescription
    //  article.keywords_arabic=article.keywords_arabic||article.keywords
    if (seoPage) {
      const {
        // H1headerRichTextValue,
        // H1HeaderRichTextArabicValue,
        // H1headerSubtextRichTextValue,
        // h1HeaderSubtextRichTextArabicValue,
        // H2headerRichTextValue,
        // H2HeaderRichTextArabicValue,
        // H2headerSubtextRichTextValue,
        // h2HeaderSubtextRichTextArabicValue,
        // H3headerRichTextValue,
        // H3HeaderRichTextArabicValue,
        // H3headerSubtextRichTextValue,
        // h3HeaderSubtextRichTextArabicValue,
      } = this.state;
      try {
        // seoPage.h1HeaderText = H1headerRichTextValue?.toString("html");
        // seoPage.h1HeaderTextAr = H1HeaderRichTextArabicValue?.toString("html");
        // seoPage.h1HeaderSubText =
        //   H1headerSubtextRichTextValue?.toString("html");
        // seoPage.h1HeaderSubTextAr =
        //   h1HeaderSubtextRichTextArabicValue?.toString("html");
        // seoPage.h2HeaderText = H2headerRichTextValue?.toString("html");
        // seoPage.h2HeaderTextAr = H2HeaderRichTextArabicValue?.toString("html");
        // seoPage.h2HeaderSubText =
        //   H2headerSubtextRichTextValue?.toString("html");
        // seoPage.h2HeaderSubTextAr =
        //   h2HeaderSubtextRichTextArabicValue?.toString("html");
        // seoPage.h3HeaderText = H3headerRichTextValue?.toString("html");
        // seoPage.h3HeaderTextAr = H3HeaderRichTextArabicValue?.toString("html");
        // seoPage.h3HeaderSubText =
        //   H3headerSubtextRichTextValue?.toString("html");
        // seoPage.h3HeaderSubTextAr =
        //   h3HeaderSubtextRichTextArabicValue?.toString("html");
      } catch (error) {
        console.log("handleSave:: Error on setting content", error.message);
        // seoPage.h1HeaderText = "";
        // seoPage.h1HeaderTextAr = "";
        // seoPage.h1HeaderSubText = "";
        // seoPage.h1HeaderSubTextAr = "";
        // seoPage.h2HeaderText = "";
        // seoPage.h2HeaderTextAr = "";
        // seoPage.h2HeaderSubText = "";
        // seoPage.h2HeaderSubTextAr = "";
        // seoPage.h3HeaderText = "";
        // seoPage.h3HeaderTextAr = "";
        // seoPage.h3HeaderSubText = "";
        // seoPage.h3HeaderSubTextAr = "";
      }
    }

    const errors = this.validate(seoPage);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    try {
      this.showLoading();
      const dateCheck = Date.now();
      if(seoPage.searchDocId==null){
    
      
      const seoPage={...this.state.seoPage,"searchDocId":dateCheck};
      const response = await saveSeoPage(seoPage);
      this.saveSearchTagDocument(response, seoPage, selectedCountry, dateCheck);
      }
      else{
        const response = await saveSeoPage(seoPage);
        this.saveSearchTagDocument(response, seoPage, selectedCountry, dateCheck);
      }

      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");

      this.fetchSeoPages();
    } catch (error) {
      console.log("handleSave:: Error on saving article category", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    if (this.state.isNewSeoPage || !this.state.isEditable) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let seoPage = { ...this.state.seoPage };
    seoPage[seoName] = input.value;
    this.setState({ seoPage });
  };

  fetchCoachPackages = async () => {
    try {
      const { data: coachPackages = [] } = await PhoenixAPI.get(
        BASE_URL_COACH_CONSULTATION_PACKAGE
      );
      this.setState({ coachConsultationPackages: coachPackages });
    } catch (error) {
      console.log("Error on fetching coach consultation packages", error);
      throw error;
    }
  };

  handleChangeSeoMarketing = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let seoPage = { ...this.state.seoPage };
    if (seoPage) {
      seoPage[seoName] = input.value;
    }

    this.setState({ seoPage });
  };

  handleSelectPrimaryImage = (e, text, htag) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      const seoPage = { ...this.state.seoPage, [htag]: image };
      this.setState({ seoPage });
      this.readImage(image, text);
    }
  };

  createOptionSelect = (selectId, prodtype) => {
    let seoPage = {};
    
    const { packages, supplements, coachConsultationPackages } = this.state;
    if (prodtype == "packages") {
      seoPage = packages.find((it) => it.id == selectId);
    } else if (prodtype == "supplement") {
      seoPage = supplements.find((it) => it.id == selectId);
      
    } else {
      seoPage = coachConsultationPackages.find(
        (it) => it.id == selectId
      );
    }
    return {
      key: seoPage?.id,
      value: seoPage?.id,
      label:
        prodtype == "packages"
          ? seoPage?.name
          : seoPage?.internalName,
    };
  };

  readImage = (image, text) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const seoPage = {
        ...this.state.seoPage,
        [text]: [reader.result],
      };
      this.setState({ seoPage });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  fetchDefaultCoachDetails = async () => {
    try {
      this.showLoading();
      const url = `nutritionist-user-name/`;
      const { data } = await PhoenixAPI.get(url);
      let coachDetails = [
        {
          text: "None",
          value: null,
          key: "None",
          default_coach_id: null,
          is_active: false,
        },
      ];
      data &&
        data
          .filter(
            (item) =>
              item.is_active &&
              item.full_name !== null &&
              item.full_name !== " "
          )
          .map((item) => {
            let coach = {};
            coach["text"] = item.full_name;
            coach["value"] = item.id;
            coach["key"] = item.full_name;
            coach["default_coach_id"] = item.id;
            coach["is_active"] = item.is_active;
            coachDetails.push(coach);
          });
      this.setState({ wellbeingCoaches: coachDetails });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching default coach details ! " + error.message
      );
      return null;
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };
  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  getFilteredSeoPages = () => {
    const seoPages = this.getSeoPagesForCurrentCountry();
    return this.getSearchedSeoPages(seoPages);
  };

  getSeoPagesForCurrentCountry = () => {
    const { seoPages } = this.state;
    
    const { selectedCountry } = this.state;
    return seoPages && selectedCountry
      ? seoPages.filter((p) => p.countryId === selectedCountry.id)
      : [];
  };

  saveSearchTagDocument = (response, seoPage, selectedCountry, datecheck) => {

    console.log("inside doc update", response);
    let dateTime = new Date();
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1; // Months are 0-indexed
    const day = dateTime.getDate();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
    console.log("Selected package", seoPage);
   
    let replacesearchtags = "";
    const dateform = moment(dateTime).format("DD/MM/YYYY");

   if (seoPage?.searchTags !== "" && seoPage?.searchTags !== null && seoPage?.searchTags !== undefined && seoPage?.searchTags !== "undefined" && seoPage?.searchTags !== "null")
      replacesearchtags = seoPage?.searchTags?.replace(/(\r\n|\n)/g, ',');

   const output = seoPage.seoHeaderSection[0]?.headerEnglish?.replace(/<[^>]*>/g, ''); // Removes HTML tags
   const headertext = output?.replace(/&[^;]+;/g, '').replace(/\n/g, ''); 
     // Extracts the first 150 characters
    

   const combinedHeaderSubtext=seoPage.seoHeaderSection
    .filter(headertext => headertext.headerSubEnglish !== null) // Filter out null values
    .map(headertext => headertext.headerSubEnglish.replace(/<[^>]*>/g, '').replace(/&[^;]+;/g, '')) // Remove HTML tags and entities
    .reduce((acc, text) => acc ? `${acc}, ${text}` : text, '') // Combine strings with a comma
    .trim(); 


    const payload = {
      "docId": seoPage?.searchDocId==null?Date.now():seoPage?.searchDocId,

      "itemId": seoPage?.id,
      "itemCategory": "Seo Landing",

      "productNameH1":headertext?.replace(/['":\r\n]/g, ''),
      "descriptionH2":combinedHeaderSubtext?.replace(/['":\r\n]/g, ''),

      "whatsIncluded": "",
      "relatedSymptoms":"",
      "dateAdded": dateform,
      "imageS3Url": seoPage?.seoHeaderSection[0]?.headerImage,
      "thumbnailS3Url": "",
      "price": 0.0,
      "seoUrl": seoPage.urlName,
      "searchTags": replacesearchtags?.replace(/['":\r\n]/g, ''),
    }

    console.log("payload is", payload);
    const countryFinal = seoPage.country ? seoPage?.country : selectedCountry.id;
    let url = "create/search-document/" + countryFinal;
    console.log("url is", url);
    try {
      // const response= seoPage?.es_doc_id?PhoenixAPI.patch(url,payload):PhoenixAPI.post(url,payload);
      const response = PhoenixAPI.post(url, payload);

    }
    catch (error) {
      console.log("Error on update or create  Package CMS search doc", error);
      //this.showErrorNotification("Some error occured in search document"+error); 
    }
  };

  generateSearchTags=async()=>{
    
    const{seoHeaderSection,landingPageName}=this.state.seoPage;
    console.log("seoheader section",seoHeaderSection);
    let replacesearchtags = "";
    let combinedStrings="";
   combinedStrings = seoHeaderSection.length >=1
    ? seoHeaderSection
        .filter(section => section.headerEnglish !== null) // Filter out null headerEnglish
        .map(section => ({
          headerEnglish: section.headerEnglish.replace(/<[^>]*>|&nbsp;|&[^;]+;|\n/g,''),
          headerSubEnglish: section?.headerSubEnglish 
            ? section.headerSubEnglish?.replace(/<[^>]*>|&[^;]+;|\n/g,'') 
            : ''
        }))
        .map(section => {
          let combString = `${section.headerEnglish}`;
          if (section.headerSubEnglish) {
            combString += `,${section.headerSubEnglish}`;
          }
          if (landingPageName) {
            combString += `, ${landingPageName}`;
          }
          return combString;
        })
    : [];
      console.log("combined Strings",combinedStrings);
      

      if (this.state.seoPage?.searchTags !== "" && this.state.seoPage?.searchTags !== null && this.state.seoPage?.searchTags !== undefined)
        replacesearchtags = this.state.seoPage?.searchTags?.replace(/(\r\n|\n)/g, ',');
      const prevSearchTags = replacesearchtags ? replacesearchtags.split(',') : [];
        this.setState({isGenerate:true});
        const payload = [combinedStrings || "",prevSearchTags||""]
          .filter(item => item !== null && item !=="")
          .join(',');
          console.log("payload is",payload);
        try{
          const response = await PhoenixAPI.post("generate-keywords",payload?.replace(/:/g, ''));
          
        const newSearchTags = response.data.message.split(',');
      const combinedSearchTags = Array.from(new Set([...prevSearchTags, ...newSearchTags]));
        const seoPage = {
          ...this.state.seoPage,
          searchTags:combinedSearchTags.join(',')
        };
        this.setState({ seoPage ,isGenerate:false});
        if(response.data.statusCode==200){
          this.showSuccessNotification("Successfully generated")
        }
      }
      catch(error){
        console.log("Error in generating search tags", error.message);
        this.showErrorNotification(
          "Error on fetching supplement by id! " + error.message ?? ""
        );
      }
        
     }

  getSearchedSeoPages = (seoPages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return seoPages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return seoPages.filter((seoPage) => {
      const {
        id,
        landingPageName,

        landingPageRedirectionLink,
      } = seoPage;
      return (
        (landingPageName &&
          landingPageName.toLowerCase().indexOf(newValue) !== -1) ||
        (landingPageRedirectionLink &&
          landingPageRedirectionLink.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  handleAddNewSection = () => {
    this.setState({ sectionValue: this.state.sectionValue + 1 });
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  showModal = () => {
    const { isNewSeoPage, selectedCountry } = this.state;
    // this.fetchSupplements(selectedCountry.id);
    if (isNewSeoPage) {
      const seoPage = {
        // isTrending: false,
        isActive: false,
        seoHeaderSection:[],
        countryId: selectedCountry.id,
      };

      this.setState({
        seoPage,
        // sectionValue: 1,
        showModal: true,
      });
    } else {
      const seoPage = { ...this.state.seoPage };

      this.setState({ seoPage, showModal: true });
    }
  };

  resetForm = () => {
    this.setState({
      seoPage: {},
      errors: {},
      editables: {},
      isSaving: false,
      // H1headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H1headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // H1HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // h1HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H2headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H2headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // H2HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // h2HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // H3headerRichTextValue: RichTextEditor.createEmptyValue(),
      // H3headerSubtextRichTextValue: RichTextEditor.createEmptyValue(),
      // H3HeaderRichTextArabicValue: RichTextEditor.createEmptyValue(),
      // h3HeaderSubtextRichTextArabicValue: RichTextEditor.createEmptyValue(),
    });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    const {
      selectedCountry,
      searchTerm,
      countries,
      seoPages,
      seoPage,
      errors,
      editables,
      isEditable,
      isNewSeoPage,
      wellbeingCoaches,
      sectionValue,
    } = this.state;
    
    const filteredSeoPages = this.getFilteredSeoPages(seoPages) ?? [];
    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="landingpagedname">Landing Page Name*</label>
            <div className="input-group">
              <input
                value={seoPage.landingPageName}
                onChange={this.handleChange}
                id="landingPageName"
                name="landingPageName"
                type="text"
                placeholder="Landing Page Name"
                className={
                  "form-control py-2" +
                  (errors.landingPageName ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.landingPageName}
              />
              {this.renderPencilIcon("landingPageName")}
            </div>
            {<span className="help-block">{errors.landingPageName}</span>}
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={seoPage.isActive}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: {
                      ...seoPage,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSeoPage && !editables.isActive}
                className={
                  "form-control" + (errors.isActive ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="landingpagednae">
              Landing Page Redirection Link(English)*
            </label>
            <div className="input-group">
              <input
                value={seoPage.landingPageRedirectionLink}
                onChange={this.handleChange}
                id="landingPageRedirectionLink"
                name="landingPageRedirectionLink"
                type="text"
                placeholder="Landing Page Redirection Link"
                className={
                  "form-control py-2" +
                  (errors.landingPageRedirectionLink ? " has-error" : "")
                }
                disabled={
                  !isNewSeoPage && !editables.landingPageRedirectionLink
                }
              />
              {this.renderPencilIcon("landingPageRedirectionLink")}
            </div>
            {
              <span className="help-block">
                {errors.landingPageRedirectionLink}
              </span>
            }
          </div>

          <div className="form-group col">
            <label htmlFor="landingpagednae">
              Landing Page Redirection Link(Arabic)*
            </label>
            <div className="input-group">
              <input
                value={seoPage.landingPageRedirectionLinkAr}
                onChange={this.handleChange}
                id="landingPageRedirectionLinkAr"
                name="landingPageRedirectionLinkAr"
                type="text"
                placeholder="Landing Page Redirection Link"
                className={
                  "form-control input-arabic py-2" +
                  (errors.landingPageRedirectionLinkAr ? " has-error" : "")
                }
                disabled={
                  !isNewSeoPage && !editables.landingPageRedirectionLinkAr
                }
              />
              {this.renderPencilIcon("landingPageRedirectionLinkAr")}
            </div>
            {
              <span className="help-block">
                {errors.landingPageRedirectionLinkAr}
              </span>
            }
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="itemCategory">Landing Page Product Type</label>
            <div className="input-group">
              <Dropdown
                value={seoPage?.landingPageProductType}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: {
                      ...seoPage,
                      landingPageProductType: value.value,
                    },
                  });
                }}
                id="itemCategory"
                name="itemCategory"
                placeholder="Select Item Category"
                search
                selection
                options={CATEGORIES_OPTIONS}
                disabled={!isNewSeoPage && !editables.landingPageProductType}
                className={
                  "form-control" + (errors.productType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("landingPageProductType")}
            </div>
            {/* <span className="help-block">{errors.productType}</span>*/}
          </div>
          <div className="form-group col">
            <label htmlFor="name">Landing Page Product</label>
            <div className="input-group">
              <Select
                key={`my_unique_select_key`}
                value={this.createOptionSelect(
                  seoPage.landingPageProductId,
                  seoPage.landingPageProductType
                )}
                onChange={(option) => {
                  
                  this.setState({
                    seoPage: {
                      ...seoPage,
                      landingPageProductId: option.value,
                      //itemEntityId: option.key,
                    },
                  });
                }}
                options={this.state.serviceItemOptions || []}
                styles={selectStyles}
                placeholder="Product Name"
                isDisabled={!isNewSeoPage && !editables.landingPageProductId}
              />
              {this.renderPencilIcon("landingPageProductId")}
            </div>
            {/* <span className="help-block">{errors.productName}</span>*/}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="landingpagedname">Landing Page Redirection Text (English)*</label>
            <div className="input-group">
              <input
                value={seoPage.landingPageRedirectionText}
                onChange={this.handleChange}
                id="landingPageRedirectionText"
                name="landingPageRedirectionText"
                type="text"
                placeholder="Landing Page Redirection Text"
                className={
                  "form-control py-2" +
                  (errors.landingPageRedirectionText ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.landingPageRedirectionText}
              />
              {this.renderPencilIcon("landingPageRedirectionText")}
            </div>
            {<span className="help-block">{errors.landingPageRedirectionText}</span>}
          </div>
          <div className="form-group col">
            <label htmlFor="landingpagedname">Landing Page Redirection Text (Arabic)*</label>
            <div className="input-group">
              <input
                value={seoPage.landingPageRedirectionTextAr}
                onChange={this.handleChange}
                id="landingPageRedirectionTextAr"
                name="landingPageRedirectionTextAr"
                type="text"
                placeholder="Landing Page Redirection Text Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.landingPageRedirectionTextAr ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.landingPageRedirectionTextAr}
              />
              {this.renderPencilIcon("landingPageRedirectionTextAr")}
            </div>
            {<span className="help-block">{errors.landingPageRedirectionTextAr}</span>}
          </div>

          </div>

        <div className="row mt-2">
          <div className="form-group col-6">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isLeadCollection || false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isLeadCollection:event.target.checked}
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires Lead Collection?
               </div> 

          </div>

        <div className="form-group col-6">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isBestsellerRequired || false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isBestsellerRequired:event.target.checked}
                  });
                }}
                id="bestSeller"
                name="bestSeller"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires Bestseller?
               </div> 
            </div>
        </div>
        <div className="row mt-2">
        <div className="form-group col">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isConnectUs ?? false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isConnectUs:event.target.checked}
                  });
                }}
                id="isConnectUs"
                name="isConnectUs"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires "Connect us" Section?
               </div> 
            </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="connectTextEnglish">Connect with us text (English)*</label>
            <div className="input-group">
              <textarea
                value={seoPage.connectTextEnglish}
                onChange={this.handleChange}
                id="connectTextEnglish"
                name="connectTextEnglish"
                type="text"
                placeholder="Please Enter Connect text in English"
                className={
                  "form-control py-2" +
                  (errors.connectTextEnglish ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.connectTextEnglish}
              />
              {this.renderPencilIcon("connectTextEnglish")}
            </div>
            {<span className="help-block">{errors.connectTextEnglish}</span>}
          </div>
          <div className="form-group col">
            <label htmlFor="connectTextArabic">Connect with us text (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={seoPage.connectTextArabic}
                onChange={this.handleChange}
                id="connectTextArabic"
                name="connectTextArabic"
                type="text"
                placeholder="Please Enter Connect text in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.connectTextArabic ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.connectTextArabic}
              />
              {this.renderPencilIcon("connectTextArabic")}
            </div>
            {<span className="help-block">{errors.connectTextArabic}</span>}
          </div>

          </div>

          <div className="row mt-2">
        <div className="form-group col">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isValeoSection ?? false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isValeoSection:event.target.checked}
                  });
                }}
                id="isValeoSection"
                name="isValeoSection"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires "Why Valeo" Section?
               </div> 
            </div>
        </div>
          <div className="row">
            <ValeoSelection 
            questions={seoPage.seoValeoSection}
            onQuestionUpdated={this.handleValeoSectionUpdated}
            isEditable={isEditable} 
          />
          </div>
        <div className="row mt-2">
        <div className="form-group col">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isBookConsultation ?? false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isBookConsultation:event.target.checked}
                  });
                }}
                id="isBookConsultation"
                name="isBookConsultation"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires "Book Consultation" Section?
               </div> 
            </div>
          
        </div>
        <div className="row mt-2">
          <div className="form-group col-6">
            <label>Default Coach Name *</label>
            <Dropdown
              value={seoPage.coachId ?? null}
              onChange={(event, value) => {
                
                this.setState({ seoPage:{...seoPage,coachId:value.value} });
                
              }}
              id="coachId"
              placeholder="None"
              search
              selection
              options={wellbeingCoaches}
              // disabled={!isNewSeoPage&& !editables.selectedCoachId}
              className={
                "form-control" + (errors.coachId ? " has-error" : "")
              }
            />

            <span className="help-block">{errors.coachId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="bookConsultationTextEnglish">Text (English)*</label>
            <div className="input-group">
              <textarea
                value={seoPage.bookConsultationTextEnglish}
                onChange={this.handleChange}
                id="bookConsultationTextEnglish"
                name="bookConsultationTextEnglish"
                type="text"
                placeholder="Please Enter Text in English"
                className={
                  "form-control py-2" +
                  (errors.bookConsultationTextEnglish ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.bookConsultationTextEnglish}
              />
              {this.renderPencilIcon("bookConsultationTextEnglish")}
            </div>
            {<span className="help-block">{errors.bookConsultationTextEnglish}</span>}
          </div>
          <div className="form-group col">
            <label htmlFor="bookConsultationTextArabic">Text (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={seoPage.bookConsultationTextArabic}
                onChange={this.handleChange}
                id="bookConsultationTextArabic"
                name="bookConsultationTextArabic"
                type="text"
                placeholder="Please Enter Text in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.bookConsultationTextArabic? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.bookConsultationTextArabic}
              />
              {this.renderPencilIcon("bookConsultationTextArabic")}
            </div>
            {<span className="help-block">{errors.bookConsultationTextArabic}</span>}
          </div>

          </div>
       </div>
    );

    const seoPageDescriptionForm = (
      // <div className="container mt-4">
      //   {[...Array(this.state.sectionValue)].map((_, index) => (
      //     <>
      //       <div className="row">
      //         <div className="form-group col" style={{ maxWidth: "50%" }}>
      //           <label htmlFor="primaryImage">
      //             H{index + 1} Header Image{index + 1 == 1 ? "*" : ""}
      //           </label>
      //           <div className="input-group">
      //             <input
      //               onChange={(e) =>
      //                 this.handleSelectPrimaryImage(
      //                   e,
      //                   `image_src${index + 1}`,
      //                   `h${index + 1}Image`
      //                 )
      //               }
      //               id="primaryImage"
      //               name={`h${index + 1}image`}
      //               type="file"
      //               accept="image/*"
      //               // disabled={!isNewSeoPage && !editables.image}
      //               /* className={
      //             "form-control" + (errors.primaryImageSrc ? " has-error" : "")
      //           }*/
      //             />
      //             {this.renderPencilIcon(`h${index + 1}image`)}
      //           </div>
      //           <span className="help-block">
      //             {errors[`h${index + 1}Image`]}
      //           </span>
      //         </div>
      //         <div className="form-group col">
      //           {seoPage[`image_src${index + 1}`] && (
      //             <>
      //               <label htmlFor="selected_package_image">
      //                 H{index + 1} image thumbNail
      //               </label>
      //               <div className="form-image-container">
      //                 <img
      //                   id="selected_package_image"
      //                   name="selected_package_image"
      //                   src={seoPage[`image_src${index + 1}`]}
      //                   alt="Selected"
      //                   className="img-thumbnail mx-auto"
      //                   style={{
      //                     width: "100%",
      //                     maxHeight: "200px",
      //                     objectFit: "cover",
      //                   }}
      //                 />
      //               </div>
      //             </>
      //           )}
      //         </div>
      //       </div>
      //       <div className="row">
      //         <div className="form-group col" style={{ maxWidth: "50%" }}>
      //           <label>
      //             H{index + 1} header text (English){index + 1 == 1 ? "*" : ""}
      //           </label>
      //           <div className="input-group">
      //             <RichTextEditor
      //               rootStyle={{
      //                 position: "relative",
      //                 flex: "1 1 auto",
      //                 width: "1%",
      //                 marginBottom: 0,
      //               }}
      //               editorStyle={{ height: "90px", overflow: "hidden" }}
      //               value={this.state[`H${index + 1}headerRichTextValue`]}
      //               onChange={(value) =>
      //                 this.handleRichText(
      //                   value,
      //                   `H${index + 1}headerRichTextValue`
      //                 )
      //               }
      //               disabled={
      //                 !isNewSeoPage && !editables[`h${index + 1}HeaderText`]
      //               }
      //             />
      //             {this.renderPencilIcon(`h${index + 1}HeaderText`)}
      //           </div>
      //           <span className="help-block">
      //             {errors[`h${index + 1}HeaderText`]}
      //           </span>
      //         </div>
      //         <div className="form-group col" style={{ maxWidth: "50%" }}>
      //           <label>
      //             H{index + 1} header text (Arabic){index + 1 == 1 ? "*" : ""}
      //           </label>
      //           <div className="input-group">
      //             <RichTextEditor
      //               rootStyle={{
      //                 position: "relative",
      //                 flex: "1 1 auto",
      //                 width: "1%",
      //                 marginBottom: 0,
      //               }}
      //               editorStyle={{ height: "90px", overflow: "hidden" }}
      //               value={this.state[`H${index + 1}HeaderRichTextArabicValue`]}
      //               onChange={(value) =>
      //                 this.handleRichText(
      //                   value,
      //                   `H${index + 1}HeaderRichTextArabicValue`
      //                 )
      //               }
      //               disabled={
      //                 !isNewSeoPage && !editables[`h${index + 1}HeaderTextAr`]
      //               }
      //             />
      //             {this.renderPencilIcon(`h${index + 1}HeaderTextAr`)}
      //           </div>
      //           <span className="help-block">
      //             {errors[`h${index + 1}HeaderTextAr`]}
      //           </span>
      //         </div>
      //       </div>
      //       <div className="row">
      //         <div className="form-group col" style={{ maxWidth: "50%" }}>
      //           <label>
      //             H{index + 1} header subtext (English)
      //             {index + 1 == 1 ? "*" : ""}
      //           </label>
      //           <div className="input-group">
      //             <RichTextEditor
      //               rootStyle={{
      //                 position: "relative",
      //                 flex: "1 1 auto",
      //                 width: "1%",
      //                 marginBottom: 0,
      //               }}
      //               editorStyle={{ height: "90px", overflow: "hidden" }}
      //               value={
      //                 this.state[`H${index + 1}headerSubtextRichTextValue`]
      //               }
      //               onChange={(value) =>
      //                 this.handleRichText(
      //                   value,
      //                   `H${index + 1}headerSubtextRichTextValue`
      //                 )
      //               }
      //               disabled={
      //                 !isNewSeoPage && !editables[`h${index + 1}HeaderSubText`]
      //               }
      //             />
      //             {this.renderPencilIcon(`h${index + 1}HeaderSubText`)}
      //           </div>
      //           <span className="help-block">
      //             {errors[`h${index + 1}HeaderSubText`]}
      //           </span>
      //         </div>

      //         <div className="form-group col" style={{ maxWidth: "50%" }}>
      //           <label>
      //             H{index + 1} header Subtext (Arabic)
      //             {index + 1 == 1 ? "*" : ""}
      //           </label>
      //           <div className="input-group">
      //             <RichTextEditor
      //               rootStyle={{
      //                 position: "relative",
      //                 flex: "1 1 auto",
      //                 width: "1%",
      //                 marginBottom: 0,
      //               }}
      //               editorStyle={{ height: "90px", overflow: "hidden" }}
      //               value={
      //                 this.state[
      //                   `h${index + 1}HeaderSubtextRichTextArabicValue`
      //                 ]
      //               }
      //               onChange={(value) =>
      //                 this.handleRichText(
      //                   value,
      //                   `h${index + 1}HeaderSubtextRichTextArabicValue`
      //                 )
      //               }
      //               disabled={
      //                 !isNewSeoPage &&
      //                 !editables[`h${index + 1}HeaderSubTextAr`]
      //               }
      //             />
      //             {this.renderPencilIcon(`h${index + 1}HeaderSubTextAr`)}
      //           </div>
      //           <span className="help-block">
      //             {errors[`h${index + 1}HeaderSubTextAr`]}
      //           </span>
      //         </div>
      //       </div>

      //       {this.state.sectionValue < 3 &&
      //       this.state.sectionValue < index + 2 ? (
      //         <button
      //           onClick={this.handleAddNewSection}
      //           className="btn btn-success mt-1"
      //         >
      //           Add New H{index + 2} Section
      //         </button>
      //       ) : (
      //         <></>
      //       )}
      //     </>
      //   ))}
      // </div>
      <div className="container mt-4">

        <HeaderSection
         questions={seoPage?.seoHeaderSection??[]}
         onQuestionUpdated={this.handleHeaderSectionUpdated}
         isEditable={isEditable} 
        
        />
        <span className="help-block">{errors.seoHeaderSection}</span>

      </div>
    );

    const searchTagsForm=(
      <div className="container mt-4">
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="tags">Tags</label>
            <div className="input-group">
              <textarea
                value={seoPage.searchTags !== "null" ? seoPage && seoPage.searchTags : ""}
                onChange={this.handleChange}
                id="searchTags"
                name="searchTags"
                placeholder="tags"
                className={
                  "form-control py-2" + (errors.searchTags ? " has-error" : "")
                }

                readOnly={
                  !isNewSeoPage&& !editables.searchTags
                }
              ></textarea>
              {this.renderPencilIcon("searchTags")}
            </div>
            <span className="help-block">{errors.searchTags}</span>
          </div>
          <div className="form-group col">
                        <label></label><br />
                        <button
                            type="button"
                            onClick={this.generateSearchTags}
                            className={
                                "form-control btn btn-info mt-2 py-1" +
                                (this.state.seoPage.searchTags ? "non-editable " : "editable ")
                            }
                            disabled={this.state.isGenerate}
                        >
                            Generate through AI
                        </button>
                        </div>

         
                      </div>
          <div className="row">          
         <div className="form-group col-6">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isVisibleSearch || false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isVisibleSearch:event.target.checked}
                  });
                }}
                id="isEnableSearch"
                name="isEnableSearch"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Show this Landing Page in Search?
               </div> 

          </div>
          </div>  
        
      </div>
    )

    const seoForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="url_name">URL Name*</label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.urlName) || ""}
                onChange={this.handleChange}
                id="urlName"
                name="urlName"
                type="text"
                placeholder="URL Name"
                className={
                  "form-control py-2" + (errors.urlName ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.urlName}
              />
              {this.renderPencilIcon("urlName")}
            </div>
            <span className="help-block">{errors.urlName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="meta_title">Meta Title</label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.metaTitle) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="meta_title"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing.meta_title_ar">
              Meta Title Arabic
            </label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.metaTitleAr) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="meta_title_ar"
                name="metaTitleAr"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaTitleAr ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.metaTitleAr}
              />
              {this.renderPencilIcon("metaTitleAr")}
            </div>
            <span className="help-block">{errors.metaTitleAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="seo_marketing_meta_description">
              Meta Description
            </label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.metaDescription) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing_meta_description_ar">
              Meta Description Arabic
            </label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.metaDescriptionAr) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="metaDescriptionAr"
                name="metaDescriptionAr"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaDescriptionAr ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.metaDescriptionAr}
              />
              {this.renderPencilIcon("metaDescriptionAr")}
            </div>
            <span className="help-block">{errors.metaDescriptionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="seo_marketing.alt_image">Alt Image Tag</label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.altImageTag) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="altImageTag"
                name="altImageTag"
                type="text"
                placeholder="Alt-Image Tag"
                className={
                  "form-control py-2" + (errors.altImageTag ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.altImageTag}
              />
              {this.renderPencilIcon("altImageTag")}
            </div>
            <span className="help-block">{errors.altImageTag}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="seo_marketing.alt_image_ar">
              Alt Image Tag Arabic
            </label>
            <div className="input-group">
              <input
                value={(seoPage && seoPage.altImageTagAr) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="altImageTagAr"
                name="altImageTagAr"
                type="text"
                placeholder="Alt-Image Tag Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.altImageTagAr ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.altImageTagAr}
              />
              {this.renderPencilIcon("altImageTagAr")}
            </div>
            <span className="help-block">{errors.altImageTagAr}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seo_marketing_keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(seoPage && seoPage.keywords) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="keywords"
                name="keywords"
                type="text"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.keywords}
              />
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="seo_marketing_keywords_ar">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(seoPage && seoPage.keywordsAr) || ""}
                onChange={this.handleChangeSeoMarketing}
                id="keywordsAr"
                name="keywordsAr"
                type="text"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.keywordsAr ? " has-error" : "")
                }
                disabled={!isNewSeoPage && !editables.keywordsAr}
              />
              {this.renderPencilIcon("keywordsAr")}
            </div>
            <span className="help-block">{errors.keywordsAr}</span>
          </div>
        </div>
      </div>
    );

    const relatedServicesForm = (
      <div className="container mt-4">
        <RelatedServicesForm
          relatedServices={seoPage.relatedServices}
          onServicesUpdated={this.handleUpdated}
        />
      </div>
    );
    const formFaq = (
      <div className="container mt-4">
         <div className="row mt-2">
        <div className="form-group col">
          <div className="input-group">
              <input
                value={true}
                checked={seoPage.isFaqSection?? false}
                onChange={(event, value) => {
                  this.setState({
                    seoPage: { ...seoPage, isFaqSection:event.target.checked}
                  });
                }}
                id="isFaqSection"
                name="isFaqSection"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Does this page requires Different FAQ Section?
               </div> 
            </div>
        </div>
        <FAQForm
          questions={seoPage?.seoFaqs?this?.mappingSeoFaqs(seoPage?.seoFaqs??[])??[]: []}
          onQuestionUpdated={this.handleFaqsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={filteredSeoPages}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={true}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredSeoPages.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        {/* <div className="row">
          <div className="form-group col-6">
            <label>Default Coach Name *</label>
            <Dropdown
              value={this.state.selectedCoachId || null}
              onChange={(event, value) => {
                console.log("value", value);
                this.setState({ selectedCoachId: value.value });
                updateCoach(selectedCountry.id, value.value);
              }}
              id={`default_coach_id`}
              placeholder="None"
              search
              selection
              options={wellbeingCoaches}
              // disabled={!isNewSeoPage&& !editables.selectedCoachId}
              className={
                "form-control" + (errors.default_coach_id ? " has-error" : "")
              }
            />

            <span className="help-block">{errors.selectedCoach}</span>
          </div>
        </div> */}

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={(e) => {
                this.setState({ isSaving: true }, this.handleSave);
              }}
              disabled={!isEditable || this.state.isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_article_categories" justify>
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="key_content_form" title="Description">
                {seoPageDescriptionForm}
              </Tab>
              <Tab eventKey="key_content_arabic_form" title="SEO">
                {seoForm}
              </Tab>
              <Tab eventKey="key_seo_marketing_form" title="Related Services">
                {relatedServicesForm}
              </Tab>

              <Tab eventKey="key_form_faq" title="FAQ">
                {formFaq}
              </Tab>
              <Tab eventKey="key_search_form" title="Search Engine">
                {searchTagsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeoLandingPage);
