import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "semantic-ui-css/semantic.min.css";
// import { messaging } from "../src/config/firebase";

//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', async () => {
//         const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
//             updateViaCache: 'none'
//         });
//         messaging.useServiceWorker(registration);
//     });
// }

// Added this for testing purpose.
console.log("ENV_FILE_NAME", process.env.REACT_APP_ENV_FILE_NAME);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
