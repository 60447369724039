import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import CountryDropdown from "components/Common/CountryDropdown";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";

import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Link } from "react-router-dom";
import Select from "react-select";
import Status from "components/Common/Status";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { UploadMediaFile } from "services/UploadMedia";
import FAQForm from "components/Common/FAQForm";
import SelectSupplements from "../CouponCode/SelectSupplements";
import SelectCoachConsultationPackages from "../../components/Common/SelectCoachConsultationPackages";
import SelectPackagesCMS from "../../components/Common/SelectPackagesCMS";
import UspSectionForm from "./UspSectionForm";
import TrustSectionForm from "./TrustSectionForm";
import TestimonialsForm from "./Testimonials";
import ProductListRanking from "./ProductListRanking";
import StepsToFollowSectionForm from "./StepsToFollowSectionForm";
import BenefitsSectionForm from "./BenefitsSectionForm";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const WIDGET_TYPES = [
  {
    key: "HERO_SECTION",
    value: "HERO_SECTION",
    label: "HERO_SECTION",
  },

  {
    key: "USP",
    value: "USP",
    label: "USP",
  },
  {
    key: "TRUST_SECTION",
    value: "TRUST_SECTION",
    label: "TRUST_SECTION",
  },
  {
    key: "PROGRAM_PACKAGE_DETAIL",
    value: "PROGRAM_PACKAGE_DETAIL",
    label: "PROGRAM_PACKAGE_DETAIL",
  },
  {
    key: "STEPS_TO_FOLLOW",
    value: "STEPS_TO_FOLLOW",
    label: "STEPS_TO_FOLLOW",
  },

  {
    key: "BENEFIT",
    value: "BENEFIT",
    label: "BENEFIT",
  },
  {
    key: "CALL_TO_ACTION",
    value: "CALL_TO_ACTION",
    label: "CALL_TO_ACTION",
  },
  {
    key: "PRODUCT_LIST",
    value: "PRODUCT_LIST",
    label: "PRODUCT_LIST",
  },
  {
    key: "FAQ",
    value: "FAQ",
    label: "FAQ",
  },

  {
    key: "CONTACT_US",
    value: "CONTACT_US",
    label: "CONTACT_US",
  },
  {
    key: "CONTENT",
    value: "CONTENT",
    label: "CONTENT",
  },
];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class WidgetsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      widgets: [],
      widget: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isEditable: true,
      supplements: [],
      packages: [],
      coachConsultationPackages: [],
      isAddMiniPackages: false,
      rankings: [],

      uspSection: [],
      trustSection: [],
      testimonialSection: [],
      stepsToFollowSection: [],
      benefitsSection: [],
      isWidgetNewViewModalVisible: false,
      isWidgetHeroSectionModalVisible: false,
      isWidgetUspModalVisible: false,
      isWidgetTrustSectionModal: false,
      isWidgetProgramPackageDetailModalVisible: false,
      isWidgetStepsToFollowModalVisible: false,
      isWidgetBenefitModalVisible: false,
      isWidgetCallToActionModalVisible: false,
      isProductListModalVisible: false,
      isFaqModalVisible: false,
      isContactModalVisible: false,
      isContentModalVisble: false,

      //HERO_SECTION
      heroSectionHeaderTxtEn: RichTextEditor.createEmptyValue(),
      heroSectionHeaderTxtAr: RichTextEditor.createEmptyValue(),
      heroSectionSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      heroSectionSubHeaderTxtAr: RichTextEditor.createEmptyValue(),
      heroSectionCTATxtEn: RichTextEditor.createEmptyValue(),
      heroSectionCTATxtAr: RichTextEditor.createEmptyValue(),

      //Benefits

      benefitsMainTextEn: RichTextEditor.createEmptyValue(),
      benefitsMainTextAr: RichTextEditor.createEmptyValue(),

      //steps to follow
      stepsToFollowHeaderTxtEn: RichTextEditor.createEmptyValue(),
      stepsToFollowHeaderTxtAr: RichTextEditor.createEmptyValue(),

      stepsToFollowCTATxtEn: RichTextEditor.createEmptyValue(),
      stepsToFollowCTATxtAr: RichTextEditor.createEmptyValue(),

      //productList

      productListMainTxtEn: RichTextEditor.createEmptyValue(),
      productListMainTxtAr: RichTextEditor.createEmptyValue(),
      //PROGRAM_PACKAGE_DETAIL

      packageDetailHeaderTxtEn: RichTextEditor.createEmptyValue(),
      packageDetailHeaderTxtAr: RichTextEditor.createEmptyValue(),
      packageDetailSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      packageDetailSubHeaderTxtAr: RichTextEditor.createEmptyValue(),

      //CALL TO ACTION
      callToActionMainTxtEn: RichTextEditor.createEmptyValue(),
      callToActionMainTxtAr: RichTextEditor.createEmptyValue(),
      callToActionCTATxtEn: RichTextEditor.createEmptyValue(),
      callToActionCTATxtAr: RichTextEditor.createEmptyValue(),

      //FAQ
      faqMainTxtEn: RichTextEditor.createEmptyValue(),
      faqMainTxtAr: RichTextEditor.createEmptyValue(),

      //CONTACT US

      contactUsMainHeaderTxtEn: RichTextEditor.createEmptyValue(),
      contactUsMainHeaderTxtAr: RichTextEditor.createEmptyValue(),
      contactUsSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      contactUsSubHeaderTxtAr: RichTextEditor.createEmptyValue(),
      contactUsMainHeader2TxtEn: RichTextEditor.createEmptyValue(),
      contactUsMainHeader2TxtAr: RichTextEditor.createEmptyValue(),

      ///CONTENT
      contentMainTxtEn: RichTextEditor.createEmptyValue(),
      contentMainTxtEn: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Widget Selected",
        selector: "widgetType",
        wrap: true,
        sortable: true,
      },
      {
        name: "Widget Internal Name",
        selector: "widgetInternalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Widget Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",
        center: true,
        cell: (widget) => this.renderViewButton(widget.id),
      },
    ];
  }

  async componentDidMount() {
    const { pageId, countryId } = this.props.match.params;
    await this.fetchAllWidgets(pageId);

    this.fetchCountries();
  }

  fetchAllWidgets = async (pageId) => {
    this.showLoading();
    try {
      const { data: widgets = [] } = await PhoenixAPI.get(
        `/advance-landing-page-widget/advance-landing-id/${pageId}`
      );
      this.setState({ widgets });
    } catch (error) {
      this.showErrorNotification("Error on fetching Widgets: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const { countryId } = this.props.match.params;
      const selectedCountryArr = countries.filter((it) => it.id == countryId);
      this.setState({ countries, selectedCountry: selectedCountryArr[0] });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewWidget(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleNewWidget = () => {
    this.setState({  isNewWidget: true }, () =>
      this.showWidgetViewModal()
    );
  };
  handleViewWidget = async (widgetId) => {
    try {
      const { data: widget = {} } = await PhoenixAPI.get(
        `/advance-landing-page-widget/${widgetId}`
      );
      if (!widget) {
        console.log(`Widget with id ${widgetId} is not found!`);
        return;
      }
      const { widgetType } = widget ?? {};
      if (widgetType === "HERO_SECTION") {
        const { widgetHeroSection } = widget;
        const heroSectionHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetHeroSection.headerTextEn ?? "",
          "html"
        );
        const heroSectionHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetHeroSection.headerTextAr ?? "",
          "html"
        );
        const heroSectionSubHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetHeroSection.subHeaderTextEn ?? "",
          "html"
        );
        const heroSectionSubHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetHeroSection.subHeaderTextAr ?? "",
          "html"
        );
        const heroSectionCTATxtEn = RichTextEditor.createValueFromString(
          widgetHeroSection.ctaTextEn ?? "",
          "html"
        );
        const heroSectionCTATxtAr = RichTextEditor.createValueFromString(
          widgetHeroSection.ctaTextAr ?? "",
          "html"
        );
        widget.content_image_src = widgetHeroSection.image;
        widget.content_image = widgetHeroSection.image;
        this.setState(
          {
            isWidgetHeroSectionModalVisible: true,
            widget,
            isNewWidget: false,
            heroSectionHeaderTxtEn,
            heroSectionHeaderTxtAr,
            heroSectionSubHeaderTxtEn,
            heroSectionSubHeaderTxtAr,
            heroSectionCTATxtEn,
            heroSectionCTATxtAr,
            widgetHeroSection,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "USP") {
        const { widgetUsps } = widget;

        this.setState({
          isWidgetUspModalVisible: true,
          uspSection: widgetUsps,
          widget,
          isNewWidget: false,
          widgetUsps,
        });
      } else if (widgetType === "TRUST_SECTION") {
        const { widgetTrusts = [], widgetTestimonial = [] } = widget;

        this.setState(
          {
            isWidgetTrustSectionModal: true,
            trustSection: widgetTrusts,
            widgetTrusts,
            widget,
            isNewWidget: false,
            testimonialSection: widgetTestimonial,
            widgetTestimonial,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "PROGRAM_PACKAGE_DETAIL") {
        const { widgetProgramPackageDetail = {} } = widget;
        const packageDetailHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetProgramPackageDetail.headerTextEn ?? "",
          "html"
        );
        const packageDetailHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetProgramPackageDetail.headerTextEn ?? "",
          "html"
        );
        const packageDetailSubHeaderTxtEn =
          RichTextEditor.createValueFromString(
            widgetProgramPackageDetail.subHeaderTextEn ?? "",
            "html"
          );
        const packageDetailSubHeaderTxtAr =
          RichTextEditor.createValueFromString(
            widgetProgramPackageDetail.subHeaderTextAr ?? "",
            "html"
          );
        widget.content_image_src = widgetProgramPackageDetail.image;
        widget.content_image = widgetProgramPackageDetail.image;
        this.setState(
          {
            isWidgetProgramPackageDetailModalVisible: true,
            widget,
            isNewWidget: false,
            widgetProgramPackageDetail,
            packageDetailHeaderTxtEn,
            packageDetailHeaderTxtAr,
            packageDetailSubHeaderTxtEn,
            packageDetailSubHeaderTxtAr,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "STEPS_TO_FOLLOW") {
        const { widgetStepToFollow } = widget ?? {};
        const stepsToFollowHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetStepToFollow.headerTextEn ?? "",
          "html"
        );
        const stepsToFollowHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetStepToFollow.headerTextAr ?? "",
          "html"
        );

        const stepsToFollowCTATxtEn = RichTextEditor.createValueFromString(
          widgetStepToFollow.ctaTextEn ?? "",
          "html"
        );
        const stepsToFollowCTATxtAr = RichTextEditor.createValueFromString(
          widgetStepToFollow.ctaTextAr ?? "",
          "html"
        );
        this.setState(
          {
            isWidgetStepsToFollowModalVisible: true,
            stepsToFollowSection:
              widgetStepToFollow?.widgetStepToFollowUsps ?? [],
            widget,
            isNewWidget: false,
            widgetStepToFollow,
            stepsToFollowHeaderTxtEn,
            stepsToFollowHeaderTxtAr,
            stepsToFollowCTATxtEn,
            stepsToFollowCTATxtAr,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "BENEFIT") {
        const { widgetBenefits } = widget;
        const benefitsSection = widgetBenefits?.widgetBenefitsUsps ?? [];
        const benefitsMainTextEn = RichTextEditor.createValueFromString(
          widgetBenefits.mainTextEn ?? "",
          "html"
        );
        const benefitsMainTextAr = RichTextEditor.createValueFromString(
          widgetBenefits.mainTextAr ?? "",
          "html"
        );
        this.setState(
          {
            isWidgetBenefitModalVisible: true,
            benefitsMainTextEn,
            benefitsMainTextAr,
            widgetBenefits,
            isNewWidget: false,
            widget,
            benefitsSection,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "CALL_TO_ACTION") {
        const { widgetCallToAction } = widget;
        const callToActionMainTxtEn = RichTextEditor.createValueFromString(
          widgetCallToAction.mainTextEn ?? "",
          "html"
        );
        const callToActionMainTxtAr = RichTextEditor.createValueFromString(
          widgetCallToAction.mainTextAr ?? "",
          "html"
        );
        const callToActionCTATxtEn = RichTextEditor.createValueFromString(
          widgetCallToAction.ctaTextEn ?? "",
          "html"
        );
        const callToActionCTATxtAr = RichTextEditor.createValueFromString(
          widgetCallToAction.ctaTextAr ?? "",
          "html"
        );

        this.setState(
          {
            isWidgetCallToActionModalVisible: true,
            widget,
            isNewWidget: false,
            callToActionMainTxtEn,
            callToActionMainTxtAr,
            callToActionCTATxtEn,
            callToActionCTATxtAr,
            widgetCallToAction,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "PRODUCT_LIST") {
        const { widgetProductList } = widget;
        const productListMainTxtEn = RichTextEditor.createValueFromString(
          widgetProductList.mainTextEn ?? "",
          "html"
        );
        const productListMainTxtAr = RichTextEditor.createValueFromString(
          widgetProductList.mainTextAr ?? "",
          "html"
        );
        const isAddMiniPackages =
          widgetProductList.isMiniPackageIncluded ?? false;

        this.setState(
          {
            isProductListModalVisible: true,
            productListMainTxtEn,
            productListMainTxtAr,
            widgetProductList,
            widget,
            isNewWidget: false,
            isAddMiniPackages,
            supplements: widgetProductList.supplements ?? [],
            packages: widgetProductList.packages ?? [],
            coachConsultationPackages:
              widgetProductList.coachConsultationPackages ?? [],
            rankings: widgetProductList.rankings ?? [],
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "FAQ") {
        const { widgetFaq = {} } = widget;
        const faqMainTxtEn = RichTextEditor.createValueFromString(
          widgetFaq.mainTextEn ?? "",
          "html"
        );
        const faqMainTxtAr = RichTextEditor.createValueFromString(
          widgetFaq.mainTextAr ?? "",
          "html"
        );
        this.setState(
          {
            isFaqModalVisible: true,
            widget,
            widgetFaq,
            isNewWidget: false,
            faqMainTxtEn,
            faqMainTxtAr,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "CONTACT_US") {
        const { widgetContactUs } = widget;
        const contactUsMainHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetContactUs.mainHeaderEn ?? "",
          "html"
        );
        const contactUsMainHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetContactUs.mainHeaderAr ?? "",
          "html"
        );
        const contactUsSubHeaderTxtEn = RichTextEditor.createValueFromString(
          widgetContactUs.subHeaderEn ?? "",
          "html"
        );
        const contactUsSubHeaderTxtAr = RichTextEditor.createValueFromString(
          widgetContactUs.subHeaderAr ?? "",
          "html"
        );
        const contactUsMainHeader2TxtEn = RichTextEditor.createValueFromString(
          widgetContactUs.mainHeader2En ?? "",
          "html"
        );
        const contactUsMainHeader2TxtAr = RichTextEditor.createValueFromString(
          widgetContactUs.mainHeader2Ar ?? "",
          "html"
        );
        this.setState(
          {
            isContactModalVisible: true,
            widget,
            isNewWidget: false,
            contactUsMainHeaderTxtEn,
            contactUsMainHeaderTxtAr,
            contactUsSubHeaderTxtEn,
            contactUsSubHeaderTxtAr,
            contactUsMainHeader2TxtEn,
            contactUsMainHeader2TxtAr,
            widgetContactUs,
          },
          () => this.showWidgetViewModal()
        );
      } else if (widgetType === "CONTENT") {
        const { widgetContent } = widget;
        const contentMainTxtEn = RichTextEditor.createValueFromString(
          widgetContent.mainTextEn ?? "",
          "html"
        );
        const contentMainTxtAr = RichTextEditor.createValueFromString(
          widgetContent.mainTextAr ?? "",
          "html"
        );
        widget.content_image_src = widgetContent.image;
        widget.content_image = widgetContent.image;
        this.setState(
          {
            widget,
            isNewWidget: false,
            isContentModalVisble: true,
            contentMainTxtEn,
            contentMainTxtAr,
          },
          () => this.showWidgetViewModal()
        );
      }
    } catch (error) {
      console.log("Error in fetching widget details");
      this.showErrorNotification("Error on fetching Widget: ", error.message);
    }
  };

  createRankOptions = () => {
    const { isNewWidget, widgets } = this.state;

    const ranks = widgets.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewWidget ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  showWidgetViewModal = () => {
    const { selectedCountry, isNewWidget } = this.state;
    const rankOptions = this.createRankOptions() || [];
    const { pageId } = this.props.match.params;
    if (isNewWidget) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const widget = {
        isActive: false,
        rank,
        advanceLandingPageId: Number(pageId),
      };
      this.setState({
        widget,
        rankOptions,
        isWidgetNewViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions });
    }
  };

  resetForm = () => {
    this.setState({
      widget: {},
      errors: {},
      editables: {},
      supplements: [],
      packages: [],
      coachConsultationPackages: [],
      rankings: [],
      isAddMiniPackages: false,

      uspSection: [],

      trustSection: [],
      testimonialSection: [],
      //hero section
      heroSectionHeaderTxtEn: RichTextEditor.createEmptyValue(),
      heroSectionHeaderTxtAr: RichTextEditor.createEmptyValue(),
      heroSectionSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      heroSectionSubHeaderTxtAr: RichTextEditor.createEmptyValue(),
      heroSectionCTATxtEn: RichTextEditor.createEmptyValue(),
      heroSectionCTATxtAr: RichTextEditor.createEmptyValue(),

      //steps to follow
      stepsToFollowHeaderTxtEn: RichTextEditor.createEmptyValue(),
      stepsToFollowHeaderTxtAr: RichTextEditor.createEmptyValue(),
      stepsToFollowCTATxtEn: RichTextEditor.createEmptyValue(),
      stepsToFollowCTATxtAr: RichTextEditor.createEmptyValue(),

      // program product package detail
      packageDetailHeaderTxtEn: RichTextEditor.createEmptyValue(),
      packageDetailHeaderTxtAr: RichTextEditor.createEmptyValue(),
      packageDetailSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      packageDetailSubHeaderTxtAr: RichTextEditor.createEmptyValue(),

      //call to action
      callToActionMainTxtEn: RichTextEditor.createEmptyValue(),
      callToActionMainTxtAr: RichTextEditor.createEmptyValue(),
      callToActionCTATxtEn: RichTextEditor.createEmptyValue(),
      callToActionCTATxtAr: RichTextEditor.createEmptyValue(),

      //faq

      faqMainTxtEn: RichTextEditor.createEmptyValue(),
      faqMainTxtAr: RichTextEditor.createEmptyValue(),

      //contact us
      contactUsMainHeaderTxtEn: RichTextEditor.createEmptyValue(),
      contactUsMainHeaderTxtAr: RichTextEditor.createEmptyValue(),
      contactUsSubHeaderTxtEn: RichTextEditor.createEmptyValue(),
      contactUsSubHeaderTxtAr: RichTextEditor.createEmptyValue(),
      contactUsMainHeader2TxtEn: RichTextEditor.createEmptyValue(),
      contactUsMainHeader2TxtAr: RichTextEditor.createEmptyValue(),

      //content section
      contentMainTxtEn: RichTextEditor.createEmptyValue(),
      contentMainTxtAr: RichTextEditor.createEmptyValue(),

      //productlist section

      productListMainTxtEn: RichTextEditor.createEmptyValue(),
      productListMainTxtAr: RichTextEditor.createEmptyValue(),

      //Benefits

      benefitsMainTextEn: RichTextEditor.createEmptyValue(),
      benefitsMainTextAr: RichTextEditor.createEmptyValue(),

      benefitsSection:[]
    });
  };

  hideWidgetNewModal = () => {
    this.setState({ isWidgetNewViewModalVisible: false });
    this.resetForm();
  };

  hideHeroSectionModal = () => {
    this.setState({ isWidgetHeroSectionModalVisible: false });
    this.resetForm();
  };

  hideUspModal = () => {
    this.setState({ isWidgetUspModalVisible: false });
    this.resetForm();
  };

  hideTrustSectionModal = () => {
    this.setState({ isWidgetTrustSectionModal: false });
    this.resetForm();
  };

  hideProgramPackageDetailModal = () => {
    this.setState({ isWidgetProgramPackageDetailModalVisible: false });
    this.resetForm();
  };

  hideStepsToFollowModal = () => {
    this.setState({ isWidgetStepsToFollowModalVisible: false });
    this.resetForm();
  };

  hideBenefitModal = () => {
    this.setState({ isWidgetBenefitModalVisible: false });
    this.resetForm();
  };

  hideCallToActionModal = () => {
    this.setState({ isWidgetCallToActionModalVisible: false });
    this.resetForm();
  };

  hideProductListModal = () => {
    this.setState({ isProductListModalVisible: false });
    this.resetForm();
  };

  hideFaqModal = () => {
    this.setState({ isFaqModalVisible: false });
    this.resetForm();
  };

  hideContactModal = () => {
    this.setState({ isContactModalVisible: false });
    this.resetForm();
  };

  hideContentModal = () => {
    this.setState({ isContentModalVisble: false });
    this.resetForm();
  };

  createWidgetTypeOption = (lab) => {
    return {
      key: lab.value,
      value: lab.value,
      label: lab.label,
    };
  };

  findWidgetType = (widgetType) => {
    console.log(
      "findWidgetType",
      widgetType,
      WIDGET_TYPES.find((item) => item.value == widgetType)
    );
    return WIDGET_TYPES.find((item) => item.value == widgetType);
  };

  handleWidgetTypeChange = (widgetType) => {
    const widget = this.state.widget;
    this.setState({ widget: { ...widget, widgetType: widgetType } });
  };
  handleWidgetInternalNameChange = (widgetInternalName) => {
    const widget = this.state.widget;
    this.setState({
      widget: { ...widget, widgetInternalName: widgetInternalName },
    });
  };

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  };

  handleChangeHeroSection = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let widget = { ...this.state.widget };

    if (widget && widget.widgetHeroSection) {
      widget["widgetHeroSection"][seoName] = input.value;
    } else {
      widget["widgetHeroSection"][seoName] = input.value;
    }

    this.setState({ widget });
  };

  handleChangeContactSection = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let widget = { ...this.state.widget };

    if (widget && widget.widgetContactUs) {
      widget["widgetContactUs"][seoName] = input.value;
    } else {
      widget["widgetContactUs"][seoName] = input.value;
    }

    this.setState({ widget });
  };

  handleChangeProgramPackageDetailSection = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let widget = { ...this.state.widget };

    if (widget && widget.widgetProgramPackageDetail) {
      widget["widgetProgramPackageDetail"][seoName] = input.value;
    } else {
      widget["widgetProgramPackageDetail"][seoName] = input.value;
    }

    this.setState({ widget });
  };

  handleChangeCallToActionSection = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let widget = { ...this.state.widget };

    if (widget && widget.widgetCallToAction) {
      widget["widgetCallToAction"][seoName] = input.value;
    } else {
      widget["widgetCallToAction"][seoName] = input.value;
    }

    this.setState({ widget });
  };

  handleChangeStepsToFollowSection = ({ currentTarget: input }) => {
    const seoName = (input && input.name) || "";
    let widget = { ...this.state.widget };

    if (widget && widget.widgetStepToFollow) {
      widget["widgetStepToFollow"][seoName] = input.value;
    } else {
      widget["widgetStepToFollow"][seoName] = input.value;
    }

    this.setState({ widget });
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleSelectImage = async (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      try {
        const { message: uploadedImage = "" } = await UploadMediaFile(image);

        const widget = {
          ...this.state.widget,
          content_image_src: uploadedImage,
          content_image: uploadedImage,
        };
        this.showSuccessNotification("Image is uploaded successfully");
        this.setState({ widget });
      } catch (error) {
        this.showErrorNotification("Error on uploading image: ", error.message);
      }
    }
  };
  handleQuestionsUpdated = (faqs = []) => {
    const widgetFaq = this.state.widgetFaq;
    const widget = this.state.widget;
    const newFaqs = faqs.map((item) => {
      return {
        id: item.id ?? null,
        questionEn: item.questionEnglish,
        questionAr: item.questionArabic,
        answerEn: item.answerEnglish,
        answerAr: item.answerArabic,
        isActive: item.status === "Active",
        rank: item.rank,
      };
    });
    const newWidget = { ...widgetFaq, widgetFaqQuestionAnswers: newFaqs };

    this.setState({ widgetFaq: newWidget });
  };

  handleSetupWidget = () => {
    const { widget } = this.state;
    const widgetType = widget.widgetType;
    if (widgetType === "HERO_SECTION") {
      this.setState({
        isWidgetHeroSectionModalVisible: true,
        widget: {
          ...widget,
          widgetHeroSection: {
            ctaRedirectEn: "",
            ctaRedirectAr: "",
          },
        },
      });
    } else if (widgetType === "USP") {
      this.setState({
        isWidgetUspModalVisible: true,
        widget: { ...widget, widgetUsps: {} },
      });
    } else if (widgetType === "TRUST_SECTION") {
      this.setState({
        isWidgetTrustSectionModal: true,
        widget: { ...widget, widgetTrusts: {} },
      });
    } else if (widgetType === "PROGRAM_PACKAGE_DETAIL") {
      this.setState({
        isWidgetProgramPackageDetailModalVisible: true,
        widget: { ...widget, widgetProgramPackageDetail: {} },
      });
    } else if (widgetType === "STEPS_TO_FOLLOW") {
      this.setState({
        isWidgetStepsToFollowModalVisible: true,
        widget: { ...widget, widgetStepToFollow: {} },
      });
    } else if (widgetType === "BENEFIT") {
      this.setState({
        isWidgetBenefitModalVisible: true,
        widget: { ...widget, widgetBenefits: {} },
      });
    } else if (widgetType === "CALL_TO_ACTION") {
      this.setState({
        isWidgetCallToActionModalVisible: true,
        widget: { ...widget, widgetCallToAction: {} },
      });
    } else if (widgetType === "PRODUCT_LIST") {
      this.setState({
        isProductListModalVisible: true,
        widget: { ...widget, widgetProductList: {} },
      });
    } else if (widgetType === "FAQ") {
      this.setState({
        isFaqModalVisible: true,
        widget: { ...widget, widgetFaq: {} },
      });
    } else if (widgetType === "CONTACT_US") {
      this.setState({
        isContactModalVisible: true,
        widget: { ...widget, widgetContactUs: {} },
      });
    } else if (widgetType === "CONTENT") {
      this.setState({
        isContentModalVisble: true,
        widget: { ...widget, widgetContent: {} },
      });
    }
    console.log("handleSetupWidget", widget);
  };

  hideAllWidget = () => {
    this.setState(
      {
        isWidgetNewViewModalVisible: false,
        isWidgetHeroSectionModalVisible: false,
        isWidgetUspModalVisible: false,
        isWidgetTrustSectionModal: false,
        isWidgetProgramPackageDetailModalVisible: false,
        isWidgetStepsToFollowModalVisible: false,
        isWidgetBenefitModalVisible: false,
        isWidgetCallToActionModalVisible: false,
        isProductListModalVisible: false,
        isFaqModalVisible: false,
        isContactModalVisible: false,
        isContentModalVisble: false,
      },
      () => this.resetForm()
    );
  };

  handleSaveSection = async (section) => {
    const {
      widget,

      heroSectionHeaderTxtEn,
      heroSectionHeaderTxtAr,
      heroSectionSubHeaderTxtEn,
      heroSectionSubHeaderTxtAr,
      heroSectionCTATxtEn,
      heroSectionCTATxtAr,

      packageDetailHeaderTxtEn,
      packageDetailHeaderTxtAr,
      packageDetailSubHeaderTxtEn,
      packageDetailSubHeaderTxtAr,

      productListMainTxtEn,
      productListMainTxtAr,

      faqMainTxtEn,
      faqMainTxtAr,

      callToActionMainTxtEn,
      callToActionMainTxtAr,
      callToActionCTATxtEn,
      callToActionCTATxtAr,

      contentMainTxtEn,
      contentMainTxtAr,
      contactUsMainHeaderTxtEn,
      contactUsMainHeaderTxtAr,
      contactUsSubHeaderTxtEn,
      contactUsSubHeaderTxtAr,
      contactUsMainHeader2TxtEn,
      contactUsMainHeader2TxtAr,
      widgetFaq,

      stepsToFollowHeaderTxtEn,
      stepsToFollowHeaderTxtAr,
      stepsToFollowCTATxtEn,
      stepsToFollowCTATxtAr,
      benefitsMainTextEn,
      benefitsMainTextAr,
      benefitsSection,
    } = this.state;
    const {
      widgetType = "",
      widgetHeroSection,
      widgetUsps,
      widgetTestimonial,
      widgetProgramPackageDetail,
      widgetStepToFollow,
      widgetBenefits,
      widgetCallToAction,
      widgetProductList,

      widgetContactUs,
      widgetContent,
      widgetProductListResponse,
    } = widget;

    let payload = {};
    if (widgetType === "HERO_SECTION") {
      const heroSectionData = {
        id: widgetHeroSection.id ?? null,
        headerTextEn: heroSectionHeaderTxtEn?.toString("html"),
        headerTextAr: heroSectionHeaderTxtAr?.toString("html"),
        subHeaderTextEn: heroSectionSubHeaderTxtEn?.toString("html"),
        subHeaderTextAr: heroSectionSubHeaderTxtAr?.toString("html"),
        ctaTextEn: heroSectionCTATxtEn?.toString("html"),
        ctaTextAr: heroSectionCTATxtAr?.toString("html"),
        ctaRedirectEn: widgetHeroSection?.ctaRedirectEn ?? "",
        ctaRedirectAr: widgetHeroSection?.ctaRedirectAr ?? "",
        image: widget.content_image ?? "",
      };
      payload = { ...widget, widgetHeroSection: heroSectionData };
    } else if (widgetType === "USP") {
      payload = { ...widget, widgetUsps: this.state.uspSection ?? [] };
    } else if (widgetType === "TRUST_SECTION") {
      payload = {
        ...widget,
        widgetTrusts: this.state.trustSection ?? [],
        widgetTestimonial: this.state.testimonialSection ?? [],
      };
    } else if (widgetType === "PROGRAM_PACKAGE_DETAIL") {
      const packageDetailData = {
        id: widgetProgramPackageDetail.id ?? null,
        headerTextEn: packageDetailHeaderTxtEn?.toString("html"),
        headerTextAr: packageDetailHeaderTxtAr?.toString("html"),
        subHeaderTextEn: packageDetailSubHeaderTxtEn?.toString("html"),
        subHeaderTextAr: packageDetailSubHeaderTxtAr?.toString("html"),
        image: widget.content_image ?? "",
        backgroundColour: widgetProgramPackageDetail.backgroundColour ?? "",
      };
      payload = { ...widget, widgetProgramPackageDetail: packageDetailData };
    } else if (widgetType === "STEPS_TO_FOLLOW") {
      const stepsToFollowData = {
        id: widgetStepToFollow.id ?? null,
        headerTextEn: stepsToFollowHeaderTxtEn?.toString("html"),
        headerTextAr: stepsToFollowHeaderTxtAr?.toString("html"),
        ctaTextEn: stepsToFollowCTATxtEn?.toString("html"),
        ctaTextAr: stepsToFollowCTATxtAr?.toString("html"),
        ctaRedirectEn: widgetStepToFollow.ctaRedirectEn ?? "",
        ctaRedirectAr: widgetStepToFollow.ctaRedirectAr ?? "",
        widgetStepToFollowUsps: this.state.stepsToFollowSection ?? [],
      };
      payload = { ...widget, widgetStepToFollow: stepsToFollowData };
    } else if (widgetType === "BENEFIT") {
      const benefitsData = {
        id: widgetBenefits.id ?? null,
        mainTextEn: benefitsMainTextEn?.toString("html"),
        mainTextAr: benefitsMainTextAr?.toString("html"),
        widgetBenefitsUsps: benefitsSection??[],
      };
      payload = { ...widget, widgetBenefits: benefitsData };
    } else if (widgetType === "CALL_TO_ACTION") {
      const callToActionction = {
        id: widgetCallToAction.id ?? null,
        mainTextEn: callToActionMainTxtEn?.toString("html"),
        mainTextAr: callToActionMainTxtAr?.toString("html"),
        ctaTextEn: callToActionCTATxtEn?.toString("html"),
        ctaTextAr: callToActionCTATxtAr?.toString("html"),
        ctaRedirectEn: widgetCallToAction?.ctaRedirectEn ?? "",
        ctaRedirectAr: widgetCallToAction?.ctaRedirectAr ?? "",
        // image: widget.content_image ?? "",
      };
      payload = { ...widget, widgetCallToAction: callToActionction };
    } else if (widgetType === "PRODUCT_LIST") {
      const productListSection = {
        id: widgetProductList.id ?? null,
        mainTextEn: productListMainTxtEn?.toString("html"),
        mainTextAr: productListMainTxtAr?.toString("html"),
        supplements: this.state.supplements ?? [],
        coachConsultationPackages: this.state.coachConsultationPackages ?? [],
        packages: this.state.packages ?? [],
        isMiniPackageIncluded: this.state.isAddMiniPackages ?? false,
        rankings: this.state.rankings ?? [],
      };
      payload = { ...widget, widgetProductList: productListSection };
    } else if (widgetType === "FAQ") {
      const faqSection = {
        id: widgetFaq.id ?? null,
        mainTextEn: faqMainTxtEn?.toString("html"),
        mainTextAr: faqMainTxtAr?.toString("html"),
        widgetFaqQuestionAnswers:
          this.state.widgetFaq.widgetFaqQuestionAnswers ?? [],
      };
      payload = { ...widget, widgetFaq: faqSection };
    } else if (widgetType === "CONTACT_US") {
      const contactUsSection = {
        id: widgetContactUs.id ?? null,
        mainHeaderEn: contactUsMainHeaderTxtEn?.toString("html"),
        mainHeaderAr: contactUsMainHeaderTxtAr?.toString("html"),
        subHeaderEn: contactUsSubHeaderTxtEn?.toString("html"),
        subHeaderAr: contactUsSubHeaderTxtAr?.toString("html"),
        ctaRedirectionTextEn: widgetContactUs?.ctaRedirectionTextEn ?? "",
        ctaRedirectionTextAr: widgetContactUs?.ctaRedirectionTextAr ?? "",
        whatsappRedirectionLink: widgetContactUs?.whatsappRedirectionLink ?? "",
        whatsappRedirectionText: widgetContactUs?.whatsappRedirectionLink ?? "",
        mainHeader2En: contactUsMainHeader2TxtEn?.toString("html"),
        mainHeader2Ar: contactUsMainHeader2TxtAr?.toString("html"),
      };
      payload = { ...widget, widgetContactUs: contactUsSection };
    } else if (widgetType === "CONTENT") {
      const contentSection = {
        id: widgetContent.id ?? null,
        mainTextEn: contentMainTxtEn?.toString("html"),
        mainTextAr: contentMainTxtAr?.toString("html"),
        image: widget.content_image ?? "",
      };
      payload = { ...widget, widgetContent: contentSection };
    }
    delete payload["content_image_src"];
    delete payload["content_image"];
    try {
      this.showLoading();
      const response = await PhoenixAPI.post(
        "/advance-landing-page-widget",
        payload
      );
      this.hideAllWidget();
      const { pageId } = this.props.match.params;
      if (pageId) {
        this.fetchAllWidgets(pageId);
      }
    } catch (error) {
      console.log("Error in saving widget", error.message);
      this.showErrorNotification("Error saving in widget", error.message);
    } finally {
      this.hideLoading();
    }
    console.log("sectiopnjsd", widget, section, payload);
  };

  handlePackagesUpdated = (allPackages) => {
    this.setState({ rankings: allPackages });
  };

  mappingFaqs = (faqs = []) => {
    const mapFaqs =
      faqs.length > 0 &&
      faqs.map((item) => {
        return {
          id: item.id,
          questionEnglish: item.questionEn,
          questionArabic: item.questionAr,
          answerEnglish: item.answerEn,
          answerArabic: item.answerAr,
          status: item.isActive ? "Active" : "Inactive",
          rank: item.rank,
        };
      });
    return mapFaqs;
  };
  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewWidget ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  handleStepsToFollowSection = (stepsToFollowSection = []) => {
    this.setState({ stepsToFollowSection });
  };

  handleBenefitsSection = (benefitsSection = []) => {
    this.setState({ benefitsSection });
  };
  handleHeaderSectionUpdated = (uspSection = []) => {
    this.setState({ uspSection });
  };

  handleTrustSection = (trustSection = []) => {
    
    this.setState({ trustSection });
  };

  handleTestimonialSection = (testimonialSection = []) => {
    console.log("Test ", testimonialSection);
    this.setState({ testimonialSection });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  getFilteredWidgets=(widgets)=>{
    return this.getSearchedWidgets(widgets);
  }

  getSearchedWidgets=(widgets)=>{
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return widgets;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return widgets.filter((widget) => {
      const {
        id,
        widgetInternalName,
        widgetType,
        rank,
      } = widget;
      return (
        (
          widgetInternalName
           && 
           widgetInternalName
           .toLowerCase().indexOf(newValue) !== -1) ||
        (widgetType &&
          widgetType.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  }
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      pageId,
      countries,
      selectedCountry,
      widgets,
      searchTerm,
      searchApplied,
      loadError,
      isEditable,
      widget,
      errors,
      editables,
      isNewWidget,
      widgetHeroSection,
      widgetProgramPackageDetail,
      widgetCallToAction,
      widgetContactUs,
      widgetFaq,
      widgetProductList,
      uspSection,
      trustSection,
      testimonialSection,
      stepsToFollowSection,
      widgetStepToFollow,
      benefitsSection,
    } = this.state;


    const filteredWidgets= this.getFilteredWidgets(widgets)??[];
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    const addNewWidgetForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col" style={{ zIndex: "1000" }}>
            <label>Select Widget Type</label>
            <div className="">
              <Select
                key={`country_select${widget.id}`}
                value={
                  widget.widgetType
                    ? this.createWidgetTypeOption(
                        this.findWidgetType(widget.widgetType)
                      )
                    : ""
                }
                onChange={(event) => this.handleWidgetTypeChange(event.value)}
                options={WIDGET_TYPES || []}
                styles={selectStyles}
                placeholder="Select Widget Type"
                isDisabled={!isNewWidget}
              />

              <span className="help-block">{errors.widgetType}</span>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">Widget Internal Name </label>
            <div className="input-group">
              <input
                value={widget?.widgetInternalName || ""}
                onChange={(event) => {
                  this.setState({
                    widget: {
                      ...widget,
                      widgetInternalName: event.target.value,
                    },
                  });
                }}
                id="widgetInternalName"
                name="widgetInternalName"
                type="text"
                placeholder="Widget Internal Name"
                className={
                  "form-control py-2" +
                  (errors.widgetInternalName ? " has-error" : "")
                }
                disabled={!isNewWidget && !editables.widgetInternalName}
              />
              {this.renderPencilIcon("widgetInternalName")}
            </div>
            <span className="help-block">{errors.widgetInternalName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={widget?.rank}
                onChange={(event, value) => {
                  this.setState({
                    widget: { ...widget, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewWidget && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={widget.isActive}
                onChange={(event, value) => {
                  this.setState({
                    widget: {
                      ...widget,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewWidget && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div>
          {isNewWidget && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "25px",
              }}
            >
              <button
                onClick={() => this.handleSetupWidget()}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Set Up Widget
              </button>
            </div>
          )}
        </div>
      </div>
    );

    const heroSectionForm = (
      <div>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Header Text (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionHeaderTxtEn")
                  }
                  disabled={!isNewWidget && !editables.heroSectionHeaderTxtEn}
                />
                {this.renderPencilIcon("heroSectionHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Header Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionHeaderTxtAr")
                  }
                  disabled={!isNewWidget && !editables.heroSectionHeaderTxtAr}
                />
                {this.renderPencilIcon("heroSectionHeaderTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header Text (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionSubHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionSubHeaderTxtEn")
                  }
                  disabled={
                    !isNewWidget && !editables.heroSectionSubHeaderTxtEn
                  }
                />
                {this.renderPencilIcon("heroSectionSubHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionSubHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionSubHeaderTxtAr")
                  }
                  disabled={
                    !isNewWidget && !editables.heroSectionSubHeaderTxtAr
                  }
                />
                {this.renderPencilIcon("heroSectionSubHeaderTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText English) </label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionCTATxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionCTATxtEn")
                  }
                  disabled={!isNewWidget && !editables.heroSectionCTATxtEn}
                />
                {this.renderPencilIcon("heroSectionCTATxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.heroSectionCTATxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "heroSectionCTATxtAr")
                  }
                  disabled={!isNewWidget && !editables.heroSectionCTATxtAr}
                />
                {this.renderPencilIcon("heroSectionCTATxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="ctaRedirectEn">
                CTA Redirection Text (English)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetHeroSection &&
                      widget.widgetHeroSection.ctaRedirectEn) ||
                    ""
                  }
                  onChange={this.handleChangeHeroSection}
                  id="ctaRedirectEn"
                  name="ctaRedirectEn"
                  type="text"
                  placeholder="CTA Redirection in English"
                  className={
                    "form-control py-2" +
                    (errors.ctaRedirectEn ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectEn}
                />
                {this.renderPencilIcon("ctaRedirectEn")}
              </div>
              <span className="help-block">{errors.ctaRedirectEn}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="ctaRedirectAr">
                {" "}
                CTA Redirection Text (Arabic)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetHeroSection &&
                      widget.widgetHeroSection.ctaRedirectAr) ||
                    ""
                  }
                  onChange={this.handleChangeHeroSection}
                  id="ctaRedirectAr"
                  name="ctaRedirectAr"
                  type="text"
                  placeholder="CTA Redirection in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.ctaRedirectAr ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectAr}
                />
                {this.renderPencilIcon("ctaRedirectAr")}
              </div>
              <span className="help-block">{errors.ctaRedirectAr}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">Image</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*"
                  disabled={!isNewWidget && !editables.image}
                  className={
                    "form-control" + (errors.image ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("image")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>
            <div className="form-group col">
              {widget.content_image_src && (
                <>
                  <label htmlFor="selected_package_image">Preview</label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={widget.content_image_src}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    const uspForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <UspSectionForm
            usps={uspSection ?? []}
            onUspsUpdated={this.handleHeaderSectionUpdated}
            isEditable={isEditable}
          />
        </div>
      </>
    );

    const trustSectionForm = (
      <>
        {addNewWidgetForm}

        <div className="container mt-4">
          <TrustSectionForm
            trusts={trustSection ?? []}
            onTrustsUpdated={this.handleTrustSection}
            isEditable={isEditable}
          />
        </div>

        <TestimonialsForm
          testimonials={testimonialSection ?? []}
          onTestimonialsUpdated={this.handleTestimonialSection}
          isEditable={isEditable}
        />
      </>
    );

    const programPackageDetailForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.packageDetailHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "packageDetailHeaderTxtEn")
                  }
                  disabled={!isNewWidget && !editables.packageDetailHeaderTxtEn}
                />
                {this.renderPencilIcon("packageDetailHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.packageDetailHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "packageDetailHeaderTxtAr")
                  }
                  disabled={!isNewWidget && !editables.packageDetailHeaderTxtAr}
                />
                {this.renderPencilIcon("packageDetailHeaderTxtAr")}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.packageDetailSubHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "packageDetailSubHeaderTxtEn")
                  }
                  disabled={
                    !isNewWidget && !editables.packageDetailSubHeaderTxtEn
                  }
                />
                {this.renderPencilIcon("packageDetailSubHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.packageDetailSubHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "packageDetailSubHeaderTxtAr")
                  }
                  disabled={
                    !isNewWidget && !editables.packageDetailSubHeaderTxtAr
                  }
                />
                {this.renderPencilIcon("packageDetailSubHeaderTxtAr")}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">Image</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*"
                  disabled={!isNewWidget && !editables.image}
                  className={
                    "form-control" + (errors.image ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("image")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>
            <div className="form-group col">
              {widget.content_image_src && (
                <>
                  <label htmlFor="selected_package_image">Preview</label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={widget.content_image_src}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="backgroundColour">Background Color</label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetProgramPackageDetail &&
                      widget.widgetProgramPackageDetail.backgroundColour) ||
                    ""
                  }
                  onChange={this.handleChangeProgramPackageDetailSection}
                  id="backgroundColour"
                  name="backgroundColour"
                  type="text"
                  placeholder="Background colour"
                  className={
                    "form-control py-2" +
                    (errors.backgroundColour ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.backgroundColour}
                />
                {this.renderPencilIcon("backgroundColour")}
              </div>
              <span className="help-block">{errors.backgroundColour}</span>
            </div>
          </div>
        </div>
      </>
    );

    const stepsToFollowForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.stepsToFollowHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "stepsToFollowHeaderTxtEn")
                  }
                  disabled={!isNewWidget && !editables.stepsToFollowHeaderTxtEn}
                />
                {this.renderPencilIcon("stepsToFollowHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.stepsToFollowHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "stepsToFollowHeaderTxtAr")
                  }
                  disabled={!isNewWidget && !editables.stepsToFollowHeaderTxtAr}
                />
                {this.renderPencilIcon("stepsToFollowHeaderTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText English) </label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.stepsToFollowCTATxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "stepsToFollowCTATxtEn")
                  }
                  disabled={!isNewWidget && !editables.stepsToFollowCTATxtEn}
                />
                {this.renderPencilIcon("stepsToFollowCTATxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.stepsToFollowCTATxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "stepsToFollowCTATxtAr")
                  }
                  disabled={!isNewWidget && !editables.stepsToFollowCTATxtAr}
                />
                {this.renderPencilIcon("stepsToFollowCTATxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="ctaRedirectEn">
                CTA Redirection Text (English)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetStepToFollow &&
                      widget.widgetStepToFollow.ctaRedirectEn) ||
                    ""
                  }
                  onChange={this.handleChangeStepsToFollowSection}
                  id="ctaRedirectEn"
                  name="ctaRedirectEn"
                  type="text"
                  placeholder="CTA Redirection in English"
                  className={
                    "form-control py-2" +
                    (errors.ctaRedirectEn ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectEn}
                />
                {this.renderPencilIcon("ctaRedirectEn")}
              </div>
              <span className="help-block">{errors.ctaRedirectEn}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="ctaRedirectAr">
                {" "}
                CTA Redirection Text (Arabic)*
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetStepToFollow &&
                      widget.widgetStepToFollow.ctaRedirectAr) ||
                    ""
                  }
                  onChange={this.handleChangeStepsToFollowSection}
                  id="ctaRedirectAr"
                  name="ctaRedirectAr"
                  type="text"
                  placeholder="CTA Redirection in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.ctaRedirectAr ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectAr}
                />
                {this.renderPencilIcon("ctaRedirectAr")}
              </div>
              <span className="help-block">{errors.ctaRedirectAr}</span>
            </div>
          </div>
          <StepsToFollowSectionForm
            usps={stepsToFollowSection ?? []}
            onUspsUpdated={this.handleStepsToFollowSection}
            isEditable={isEditable}
          />
        </div>
      </>
    );

    const benefitForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.benefitsMainTextEn}
                  onChange={(value) =>
                    this.handleRichText(value, "benefitsMainTextEn")
                  }
                  disabled={!isNewWidget && !editables.benefitsMainTextEn}
                />
                {this.renderPencilIcon("benefitsMainTextEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.benefitsMainTextAr}
                  onChange={(value) =>
                    this.handleRichText(value, "benefitsMainTextAr")
                  }
                  disabled={!isNewWidget && !editables.benefitsMainTextAr}
                />
                {this.renderPencilIcon("benefitsMainTextAr")}
              </div>
            </div>
          </div>
          <BenefitsSectionForm
            usps={benefitsSection ?? []}
            onUspsUpdated={this.handleBenefitsSection}
            isEditable={isEditable}
          />
        </div>
      </>
    );

    const callToActionForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Text (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.callToActionMainTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "callToActionMainTxtEn")
                  }
                  disabled={!isNewWidget && !editables.callToActionMainTxtEn}
                />
                {this.renderPencilIcon("callToActionMainTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.callToActionMainTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "callToActionMainTxtAr")
                  }
                  disabled={!isNewWidget && !editables.callToActionMainTxtAr}
                />
                {this.renderPencilIcon("callToActionMainTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText English) </label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.callToActionCTATxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "callToActionCTATxtEn")
                  }
                  disabled={!isNewWidget && !editables.callToActionCTATxtEn}
                />
                {this.renderPencilIcon("callToActionCTATxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>CTA Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.callToActionCTATxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "callToActionCTATxtAr")
                  }
                  disabled={!isNewWidget && !editables.callToActionCTATxtAr}
                />
                {this.renderPencilIcon("callToActionCTATxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="ctaRedirectEn">
                CTA Redirection Link (English)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetCallToAction &&
                      widget.widgetCallToAction.ctaRedirectEn) ||
                    ""
                  }
                  onChange={this.handleChangeCallToActionSection}
                  id="ctaRedirectEn"
                  name="ctaRedirectEn"
                  type="text"
                  placeholder="CTA Redirection Link in English"
                  className={
                    "form-control py-2" +
                    (errors.ctaRedirectEn ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectEn}
                />
                {this.renderPencilIcon("ctaRedirectEn")}
              </div>
              <span className="help-block">{errors.ctaRedirectEn}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="ctaRedirectAr">
                {" "}
                CTA Redirection Link (Arabic)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetCallToAction &&
                      widget.widgetCallToAction.ctaRedirectAr) ||
                    ""
                  }
                  onChange={this.handleChangeCallToActionSection}
                  id="ctaRedirectAr"
                  name="ctaRedirectAr"
                  type="text"
                  placeholder="CTA Redirection Link in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.ctaRedirectAr ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectAr}
                />
                {this.renderPencilIcon("ctaRedirectAr")}
              </div>
              <span className="help-block">{errors.ctaRedirectAr}</span>
            </div>
          </div>
        </div>
      </>
    );

    const productListForm = (
      <div>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.productListMainTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "productListMainTxtEn")
                  }
                  disabled={!isNewWidget && !editables.productListMainTxtEn}
                />
                {this.renderPencilIcon("productListMainTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.productListMainTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "productListMainTxtAr")
                  }
                  disabled={!isNewWidget && !editables.productListMainTxtAr}
                />
                {this.renderPencilIcon("productListMainTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label>Select Supplements</label>
              <div className="input-group">
                <SelectSupplements
                  id="suggestedSupplements"
                  name="suggestedSupplements"
                  countryId={selectedCountry?.id}
                  selectedSupplementIds={this.state.supplements ?? []}
                  onSupplementSelect={(options) => {
                    this.setState({
                      supplements: options.map((it) => it.value),
                    });
                  }}
                  showOnlyActive={true}
                  isDisabled={!isNewWidget && !editables.supplements}
                  className={
                    "form-control" + (errors.supplements ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("supplements")}
              </div>
              <span className="help-block">{errors.supplements}</span>
            </div>
            <div className="form-group col">
              <label>Select Coach Consultation Packages</label>
              <div className="input-group">
                <SelectCoachConsultationPackages
                  countryId={selectedCountry?.id}
                  selectedCoachConsultationIds={
                    this.state.coachConsultationPackages ?? []
                  }
                  onCoachConsultationSelect={(options) => {
                    this.setState({
                      coachConsultationPackages: options.map((it) => it.value),
                    });
                  }}
                  showOnlyActive={true}
                  isDisabled={
                    !isNewWidget && !editables.coachConsultationpackages
                  }
                  className={
                    "form-control" +
                    (errors.coachConsultationpackages ? " has-error" : "")
                  }
                  couponCodePage={false}
                />
                {this.renderPencilIcon("coachConsultationpackages")}
              </div>
              <span className="help-block">
                {errors.coachConsultationpackages}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label>Select Packages</label>
              <div className="input-group">
                <SelectPackagesCMS
                countryId={selectedCountry?.id}
                  selectedPackageIds={this.state.packages ?? []}
                  onPackageSelect={(options) => {
                    this.setState({
                      packages: options.map((it) => it.value),
                    });
                  }}
                  showOnlyActive={true}
                  isDisabled={!isNewWidget && !editables.packages}
                  className={
                    "form-control" + (errors.packages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("packages")}
              </div>
              <span className="help-block">{errors.packages}</span>
            </div>
            <div className="form-group col">
              <div className="input-group">
                <input
                  value={true}
                  checked={this.state.isAddMiniPackages}
                  onChange={(event, value) => {
                    this.setState({
                      isAddMiniPackages: event.target.checked,
                    });
                  }}
                  id="isAddMiniPackages"
                  name="isAddMiniPackages"
                  type="checkbox"
                  className="larger-checkbox"
                />
                &nbsp;&nbsp; Add Mini Package
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const rankingForm = (
      <div className="container mt-4">
        <ProductListRanking
          packages={widgetProductList?.rankings ?? []}
          onPackagesUpdated={this.handlePackagesUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const faqForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.faqMainTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "faqMainTxtEn")
                  }
                  disabled={!isNewWidget && !editables.faqMainTxtEn}
                />
                {this.renderPencilIcon("faqMainTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.faqMainTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "faqMainTxtAr")
                  }
                  disabled={!isNewWidget && !editables.faqMainTxtAr}
                />
                {this.renderPencilIcon("faqMainTxtAr")}
              </div>
            </div>
          </div>
          <FAQForm
            questions={
              widgetFaq?.widgetFaqQuestionAnswers
                ? this.mappingFaqs(widgetFaq?.widgetFaqQuestionAnswers ?? [])
                : []
            }
            onQuestionUpdated={this.handleQuestionsUpdated}
            isEditable={isEditable}
          />
        </div>
      </>
    );

    const contactForm = (
      <>
        {addNewWidgetForm}
        <div classname="container mt-4">
          <div className="row mt-2">
            <div className="col separator">
              <span>Left Panel</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsMainHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsMainHeaderTxtEn")
                  }
                  disabled={!isNewWidget && !editables.contactUsMainHeaderTxtEn}
                />
                {this.renderPencilIcon("contactUsMainHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsMainHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsMainHeaderTxtAr")
                  }
                  disabled={!isNewWidget && !editables.contactUsMainHeaderTxtAr}
                />
                {this.renderPencilIcon("contactUsMainHeaderTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsSubHeaderTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsSubHeaderTxtEn")
                  }
                  disabled={!isNewWidget && !editables.contactUsSubHeaderTxtEn}
                />
                {this.renderPencilIcon("contactUsSubHeaderTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Sub Header (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsSubHeaderTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsSubHeaderTxtAr")
                  }
                  disabled={!isNewWidget && !editables.contactUsSubHeaderTxtAr}
                />
                {this.renderPencilIcon("contactUsSubHeaderTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="ctaRedirectionTextEn">
                CTA Redirection Text (English)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetContactUs &&
                      widget.widgetContactUs.ctaRedirectionTextEn) ||
                    ""
                  }
                  onChange={this.handleChangeContactSection}
                  id="ctaRedirectionTextEn"
                  name="ctaRedirectionTextEn"
                  type="text"
                  placeholder="CTA Redirection in English"
                  className={
                    "form-control py-2" +
                    (errors.ctaRedirectionTextEn ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectionTextEn}
                />
                {this.renderPencilIcon("ctaRedirectionTextEn")}
              </div>
              <span className="help-block">{errors.ctaRedirectionTextEn}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="ctaRedirectionTextAr">
                {" "}
                CTA Redirection Text (Arabic)
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetContactUs &&
                      widget.widgetContactUs.ctaRedirectionTextAr) ||
                    ""
                  }
                  onChange={this.handleChangeContactSection}
                  id="ctaRedirectionTextAr"
                  name="ctaRedirectionTextAr"
                  type="text"
                  placeholder="CTA Redirection in Arabic"
                  className={
                    "form-control py-2 input-arabic" +
                    (errors.ctaRedirectionTextAr ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.ctaRedirectionTextAr}
                />
                {this.renderPencilIcon("ctaRedirectionTextAr")}
              </div>
              <span className="help-block">{errors.ctaRedirectionTextAr}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="whatsappRedirectionLink">
                Whatsapp Redirection Link
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetContactUs &&
                      widget.widgetContactUs.whatsappRedirectionLink) ||
                    ""
                  }
                  onChange={this.handleChangeContactSection}
                  id="whatsappRedirectionLink"
                  name="whatsappRedirectionLink"
                  type="text"
                  placeholder="Whatsapp Redirection Link"
                  className={
                    "form-control py-2" +
                    (errors.whatsappRedirectionLink ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.whatsappRedirectionLink}
                />
                {this.renderPencilIcon("whatsappRedirectionLink")}
              </div>
              <span className="help-block">
                {errors.whatsappRedirectionLink}
              </span>
            </div>
            <div className="form-group col">
              <label htmlFor="whatsappRedirectionText">
                Whatsapp Redirection Text
              </label>
              <div className="input-group">
                <input
                  value={
                    (widget.widgetContactUs &&
                      widget.widgetContactUs.whatsappRedirectionText) ||
                    ""
                  }
                  onChange={this.handleChangeContactSection}
                  id="whatsappRedirectionText"
                  name="whatsappRedirectionText"
                  type="text"
                  placeholder="CTA Redirection in English"
                  className={
                    "form-control py-2" +
                    (errors.whatsappRedirectionText ? " has-error" : "")
                  }
                  disabled={!isNewWidget && !editables.whatsappRedirectionText}
                />
                {this.renderPencilIcon("whatsappRedirectionText")}
              </div>
              <span className="help-block">
                {errors.whatsappRedirectionText}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col separator">
              <span>Right Panel</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header 2(with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsMainHeader2TxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsMainHeader2TxtEn")
                  }
                  disabled={
                    !isNewWidget && !editables.contactUsMainHeader2TxtEn
                  }
                />
                {this.renderPencilIcon("contactUsMainHeader2TxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Header 2 (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contactUsMainHeader2TxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "contactUsMainHeader2TxtAr")
                  }
                  disabled={
                    !isNewWidget && !editables.contactUsMainHeader2TxtAr
                  }
                />
                {this.renderPencilIcon("contactUsMainHeader2TxtAr")}
              </div>
            </div>
          </div>
        </div>
      </>
    );

    const contentForm = (
      <>
        {addNewWidgetForm}
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Text (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contentMainTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "contentMainTxtEn")
                  }
                  disabled={!isNewWidget && !editables.contentMainTxtEn}
                />
                {this.renderPencilIcon("contentMainTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Main Text (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.contentMainTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "contentMainTxtAr")
                  }
                  disabled={!isNewWidget && !editables.contentMainTxtAr}
                />
                {this.renderPencilIcon("contentMainTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">Image</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*"
                  disabled={!isNewWidget && !editables.image}
                  className={
                    "form-control" + (errors.image ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("image")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>
            <div className="form-group col">
              {widget.content_image_src && (
                <>
                  <label htmlFor="selected_package_image">Preview</label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={widget.content_image_src}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div className="manage=package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleNewWidget}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New Widget
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredWidgets || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredWidgets.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isWidgetNewViewModalVisible}
          onHide={this.hideWidgetNewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>{addNewWidgetForm}</Modal.Body>
        </Modal>

        {/* Hero section */}

        <Modal
          size="lg"
          show={this.state.isWidgetHeroSectionModalVisible}
          onHide={this.hideHeroSectionModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("HERO_SECTION")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{heroSectionForm}</Modal.Body>
        </Modal>

        {/* Usp Section */}

        <Modal
          size="lg"
          show={this.state.isWidgetUspModalVisible}
          onHide={this.hideUspModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("USP")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{uspForm}</Modal.Body>
        </Modal>

        {/* Trust section Modal*/}

        <Modal
          size="lg"
          show={this.state.isWidgetTrustSectionModal}
          onHide={this.hideTrustSectionModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("TRUST_SECTION")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{trustSectionForm}</Modal.Body>
        </Modal>

        {/*Program Package Detail  */}

        <Modal
          size="lg"
          show={this.state.isWidgetProgramPackageDetailModalVisible}
          onHide={this.hideProgramPackageDetailModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("PROGRAM_PACKAGE_DETAIL")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{programPackageDetailForm}</Modal.Body>
        </Modal>
        {/*Steps to Follow */}
        <Modal
          size="lg"
          show={this.state.isWidgetStepsToFollowModalVisible}
          onHide={this.hideStepsToFollowModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("STEPS_TO_FOLLOW")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{stepsToFollowForm}</Modal.Body>
        </Modal>

        {/*  Benefit*/}
        <Modal
          size="lg"
          show={this.state.isWidgetBenefitModalVisible}
          onHide={this.hideBenefitModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("BENEFIT")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{benefitForm}</Modal.Body>
        </Modal>

        {/* Call to Action*/}

        <Modal
          size="lg"
          show={this.state.isWidgetCallToActionModalVisible}
          onHide={this.hideCallToActionModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("CALL_TO_ACTION")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{callToActionForm}</Modal.Body>
        </Modal>

        {/* Product list */}
        <Modal
          size="lg"
          show={this.state.isProductListModalVisible}
          onHide={this.hideProductListModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("PRODUCT_LIST")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_productList" justify>
              <Tab eventKey="key_form_productlist" title="Details">
                {productListForm}
              </Tab>
              <Tab eventKey="key_form_ranking" title="Ranking">
                {rankingForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>

        {/* Faq Modal*/}
        <Modal
          size="lg"
          show={this.state.isFaqModalVisible}
          onHide={this.hideFaqModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("FAQ")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{faqForm}</Modal.Body>
        </Modal>

        {/* Contact */}
        <Modal
          size="lg"
          show={this.state.isContactModalVisible}
          onHide={this.hideContactModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("CONTACT_US")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{contactForm}</Modal.Body>
        </Modal>
        {/* Content  */}
        <Modal
          size="lg"
          show={this.state.isContentModalVisble}
          onHide={this.hideContentModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{widget.widgetType}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={() => this.handleSaveSection("CONTENT")}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{contentForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WidgetsList);
