import React from "react";
import "./notification.scss";
import { useEffect } from "react";
const Notification = ({ setMessage, message, isError }) => {
  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 2000);
  }, []);
  return (
    <>
      {message && (
        <div className="notification-main">
          <div
            className="notification-cont"
            style={{ background: isError ? "#F77077" : "#49A948" }}
          >
            <div className="notification-txt">{message}</div>
            <img
              src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon%20(2).svg"
              alt=""
              onClick={() => setMessage("")}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Notification;
