
import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import moment from "moment";
import { fetchCountry, fetchCity } from "../../../../services/RegionService";
import { fetchOrder } from "../services/OrderService";

import {
  getUserEmail,
  fetchClientUser,
} from "../../../../services/UserService";
import { getOrderStatusWithColor } from "pages/Orders/OrderUtils";
import { getUserId } from "../../../../services/UserService";
import SelectTimeslot from "pages/Orders/SelectTimeslot";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin
} from "../../../../services/aclService";
import AccessDenied from "../../../../components/Common/AccessDenied";
import DraftOrderPaymentInfo from "./DraftOrderPaymentInfo";
import DraftOrderTransactionInfo from "./DraftOrderTransactionInfo";
import EditCustomerNotes from "pages/Orders/EditCustomerNotes";
import EditCartOrderViewModal from "pages/Orders/EditCartOrderViewModal";
import DeleteDraftOrderViewModal from "../components/DeleteDraftOrderViewModal";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import OrderNotesCard from "../../../Orders/OrderNotesCard";
import EditOrderNotes from "pages/Orders/EditOrderNotes";
import EditCustomerShippingAddress from "pages/Orders/EditCustomerShippingAddress";
const BASE_URL_UPDATE_CART_ORDER_ADDRESS="/admin/update-cart-order-address";
const IMAGE_BASE_URL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_IMAGE_URL_QA
    : process.env.REACT_APP_BASE_IMAGE_URL_PROD;
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages";
const BASE_URL_CUSTOMER_NOTES = "/order-client-notes";
const BASE_URL_MOVE_TO_CART_ORDERS = "/draft-order-to-cart-order";
const BASE_URL_CANCEL_ORDER = "/admin/cancel-draft-cart-order";
const BASE_URL_CREATE_MAMOPAY_LINK = "admin/create-mamopay-link";
const BASE_URL_DRAFT_ORDER_UPDATE="/admin/update-draft-order";
const BASE_URL_UPDATE_CONFIRMDATE="/confirm-date-time-draft-item"

class DraftOrderDashboardDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      isEditCustomerNotesViewModalVisible: false,
      isConfirmDeleteDraftOrderModalVisible:false,
      isEditCartOrderViewModalVisible:false,
      customerNotesForModal: null,
      order: null,
      country: null,
      city: null,
      customerIDInfo: {},
      collapsibles: {},
      mamoPaymentUrl:"",
      isEditOrderNotesViewModalVisible:false,
      orderNotesForModal: null,
      isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:null,
      paymentMethod:null,
      paymentGateway:null

    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchOrder();
    this.fetchMiniPackages();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  showEditOrderNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditOrderNotesViewModalVisible: true,
      orderNotesForModal: order?.orderNotes ?? null,
    });
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CART_ORDERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountry = async (countryId) => {
    // console.log("fetchCountry:: countryId:", countryId);
    if (countryId === null || countryId === undefined) return null;
    return await fetchCountry(countryId);
  };

  fetchCity = async (cityId) => {
    // console.log("fetchCountry:: countryId:", cityId);
    if (cityId === null || cityId === undefined) return null;
    return await fetchCity(cityId);
  };

  fetchCustomerIDInfo = async (customerId) => {
    // console.log("fetchCustomerIDInfo:: customerId:", customerId);
    if (customerId === null || customerId === undefined) return null;
    try {
      return await fetchClientUser(customerId);
    } catch (error) {
      return null;
    }
  };

  handleHideEditOrderNotesViewModal = () =>
    this.setState({
      isEditOrderNotesViewModalVisible: false,
      orderNotesForModal: null,
    });
  fetchOrder = async () => {
    const { orderId } = this.props.match.params;
    // console.log("fetchOrder:: orderId:", orderId);
    this.showLoading();
    try {
      const order = await fetchOrder(orderId);
      const country = await this.fetchCountry(
        order?.transactionDetails?.orderRequestPayload?.countryId
      );
      const city = await this.fetchCity(
        order?.transactionDetails?.orderRequestPayload?.cityId
      );

      const customerIDInfo = await this.fetchCustomerIDInfo(order?.userId);
       const cityId=city?.id??null;
       const {user_address=[]}=customerIDInfo??{};
       const userCityAddress =user_address&&  user_address?.filter((item)=>item.fk_city===cityId)
      
       const mappedCityAddress =userCityAddress.map((item)=>{
        return {...item,addressLine1:item.address_line1, buildingApt:item.building_apt,addressLine2:item.address_line2, mapUrl:item.map_url, cityId:cityId}
       })

      this.setState({ order, country, city, customerIDInfo , userCityAddress:mappedCityAddress});
    } catch (error) {
      this.showErrorNotification("Error on fetching order: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };


  fetchMiniPackages = async () => {
    try {
      this.showLoading();
      const { data: miniPackages = [] } = await PhoenixAPI.get(
        BASE_URL_MINI_PACKAGES
      );
      this.setState({ miniPackages });
    } catch (error) {
      console.log(
        "fetchMiniPackages:: Error on fetching mini packages!",
        error
      );
    } finally {
      this.hideLoading();
    }
  };

  handleSaveCustomerNote = (customerNote) =>
    this.saveCustomerNote(customerNote);
  handleSaveOrderNotes = (orderNotes) => this.saveOrderNotes(orderNotes);

  saveOrderNotes=async(orderNotes)=>{
    const { order } = this.state;
    const { id = ""} = order ?? {};
    if (!order) {
      console.log("saveCustomerNote:: Invalid order:", order);
      return;
    }
    const payload = {
      orderNotes: orderNotes,

    };

    try {
      const response=await PhoenixAPI.patch(`${BASE_URL_DRAFT_ORDER_UPDATE}/${id}`, payload);

      this.handleHideEditOrderNotesViewModal();
      this.showSuccessNotification("Order Note saved successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Customer Note: ",
        error.message
      );
    }
  }

  saveCustomerNote = async (customerNote) => {
    const { order } = this.state;
    const { userId } = order ?? {};
    if (!order) {
      console.log("saveCustomerNote:: Invalid order:", order);
      return;
    }
    console.log("saveCustomerNote::", { userId, customerNote });
    const payload = {
      clientNotes: customerNote,
    };
    const url = `${BASE_URL_CUSTOMER_NOTES}/${userId}`;
    try {
      const response = await PhoenixAPI.patch(url, payload);

      this.handleHideEditCustomerNotesViewModal();
      this.showSuccessNotification("Customer Note saved successfully.");
      this.fetchOrder();
    } catch (error) {
      this.showErrorNotification(
        "Error on saving Customer Note: ",
        error.message
      );
    }
  };

  handleCollapsibleChange = (key) => {
    const collapsibles = {};
    if (this.state.collapsibles[key] === undefined) collapsibles[key] = true;
    this.setState({ collapsibles });
  };

  handleHideEditCartOrderViewModal = () =>
    this.setState({
      cartItem: null,
      cartItemDetails: null,
      isEditCartOrderViewModalVisible: false,
    });
  handleHideEditCustomerNotesViewModal = () =>
    this.setState({
      isEditCustomerNotesViewModalVisible: false,
      customerNotesForModal: null,
    });

  getCountryId = () => {
    const { order } = this.state;
    const { addressDetails } = order ?? {};
    return addressDetails?.countryId
      ? Number(addressDetails?.countryId)
      : order?.countryId;
  };
  showEditCartOrderViewModal = (cartItem, cartItemDetails) =>
    this.setState({
      cartItem,
      cartItemDetails,
      isEditCartOrderViewModalVisible: true,
    });

  getCityId = () => {
    const { order } = this.state;
    const { addressDetails } = order ?? {};
    return addressDetails?.cityId ?? order?.cityId;
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };
  handleHideEditCustomerShippingAddressViewModal=()=>{
    this.setState({
      isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:null,
    })
  }

  handleSaveShippingAddress=async(shippingAddress)=>{
    const { order,paymentGateway,paymentMethod } = this.state;
    const { id = "",orderNotes } = this.state.order ?? {};
    if (!order) {
      console.log("saveCustomerShipping Address:: Invalid order:", order);
      return;
    }
    const {userId}=order ?? {}
    console.log("saveShipping Address::", { order, shippingAddress })
    const {addressLine1,addressLine2,buildingApt,cityId,countryId, mapUrl, addressId}=shippingAddress ?? {};
    const shippingAddressModal={
      userId:userId,
      addressLine1:addressLine1,
      addressLine2:addressLine2,
      buildingApt:buildingApt,
      cityId:cityId,
      countryId:countryId,
      defaultAddress:false,
      mapUrl:mapUrl,
      id:addressId
    }
    try {

      const payload = {
        paymentGateway: paymentGateway,
        paymentMethod: paymentMethod,
        orderNotes:orderNotes,
        address:shippingAddressModal
      };
      this.showLoading();
      const response = await PhoenixAPI.patch(
        `${BASE_URL_MOVE_TO_CART_ORDERS}/${id}`,
        payload
      );

      this.handleHideEditCustomerShippingAddressViewModal();
      this.showSuccessNotification("Shipping Address saved successfully.");
      this.showSuccessNotification("Successfully Moved in Cart Order");
      this.props.history.goBack();

    } catch (error) {
      const {message:errorMessage=""}= error.response.data || {}
      console.log("Error on Moving to orders: ", error);
      this.showErrorNotification(`Error on Moving to cart orders: ${errorMessage}`);
    } finally {
      this.hideLoading();
    }


  }





  handleSavePaymentDetails = async (paymentDetails) => {
    const { id = "", orderNotes, cityId, userId } = this.state.order ?? {};
    const { paymentGateway = "", paymentMethod = "" } = paymentDetails ?? {};
    if(paymentGateway==="COD"){
      
      
     this.setState({paymentMethod:paymentMethod,paymentGateway:paymentGateway,isEditCustomerShippingAddressViewModalVisible:true,shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null}});
    }

    else {
      return;
    }

  };

  handleSaveMamo=async(paymentDetails)=>{
    const { id = "",orderNotes} = this.state.order ?? {};
    console.log("order is", this.state.order);
    const { paymentGateway = "", paymentMethod = "" } = paymentDetails ?? {};
    const {mamoPaymentUrl}= this.state;
    if(paymentGateway==="MAMOPAY" && !mamoPaymentUrl){
      this.showErrorNotification("Please Generate MamoPay Link");
      return;
    }
    this.showLoading();

    const payload = {
      paymentGateway: paymentGateway,
      paymentMode: paymentMethod,
    };
    try{
      const response=await PhoenixAPI.patch(`${BASE_URL_DRAFT_ORDER_UPDATE}/${id}`, payload)
      console.log("response:", response)
      this.showSuccessNotification("Draft Order Saved Successfully");
      this.props.history.goBack();
    }
    catch(error){
      console.log("Error on saving draft orders: ", error.message);
      this.showErrorNotification("Error on saving orders: ", error.message);
    }
    finally{
      this.hideLoading();
    }
  }

  generateMamoPayLink= async()=>{
    const {order}= this.state;
    const paymentDetails=this.getPaymentDetails();
    const { finalAmount = 0, walletAmount=0,couponCode="", couponApplied=false, currency } =paymentDetails
    const {userName}= order.userDetails ?? {};
    const user_id= order.userId ?? "";
    const order_id= order.id ?? "";
    const totalAmountToPaid= finalAmount - Number(walletAmount);
    if(totalAmountToPaid===0){
      this.showErrorNotification(`Your Amount is ${totalAmountToPaid} Mamopay Link is not Required`);
      return;
    }

    let payload = {
      "name":userName,
      "description": "Payment for your recent order",
      "active": true,
      "returnUrl": "https://bevaleo.dev/checkout/payment-success/",
      "amount": totalAmountToPaid,
      "amountCurrency": currency ?? "",
      "enableMessage": false,
      "enableTips": false,
      "enableCustomerDetails": false,
      "custom_data":{
        "user_id":user_id,
        "draft_order_id":order_id
      }
    }

    try {
      this.showLoading();
      const response = await PhoenixAPI.post(BASE_URL_CREATE_MAMOPAY_LINK, payload);

      const data = response && response.data;
      const {paymentUrl}= data || {}
      this.setState({mamoPaymentUrl:paymentUrl})
      this.showSuccessNotification("Generated Mamo Payment Link Successfully")
    } catch (error) {
      console.log("Error on Generating MamoPay Link", error);
      this.showErrorNotification("Error on Generating MamoPay Link" + error.message);
    }
    finally {
      this.hideLoading();
    }

  }

  getPaymentDetails = () => {
    const { order, country } = this.state;
    // console.log("getPaymentInformation:: order:", order);
    if (!order || !order.transactionDetails) return null;
    const { couponDetails, transactionDetails } = order;
    const { orderRequestPayload } = transactionDetails;

    // console.log("getPaymentInformation::", {
    //   transactionDetails,
    //   orderRequestPayload,
    // });
    const { cartItems = [] } = orderRequestPayload ?? {};
    const numberOfItems = cartItems
      .map((cartItem) => cartItem.quantity)
      .reduce((total, current) => total + current, 0);
    return {
      transactionId: transactionDetails.transactionId,
      paymentMethod: transactionDetails?.paymentMethod,
      paymentGateway: transactionDetails?.paymentGateway,
      paymentStatus: transactionDetails?.paymentStatus,
      couponApplied: transactionDetails.couponApplied,
      couponCode: orderRequestPayload.couponCode,
      couponDetails,
      discountValue: transactionDetails.discountValue,
      currency: country ? country.currency : transactionDetails.currency,
      numberOfItems,
      totalAmount: transactionDetails.totalAmount,
      taxPercentage: country ? parseFloat(country?.vat_percentage) : null,
      taxAmount: transactionDetails.taxAmount,
      subscriptionDiscount:transactionDetails?.subscriptionDiscount,
      finalAmount: transactionDetails.finalAmount,
      isWalletBalanceUsed: transactionDetails.isWalletBalanceUsed,
      walletAmount: transactionDetails?.walletAmount,
      deliveryCharges: transactionDetails?.deliveryCharges,
      additionalDiscount:transactionDetails?.additionalDiscount,
      cashbackAmount:transactionDetails?.cashbackAmount,
      nurseVisitFee:transactionDetails?.nurseVisitFee,
    };
  };

  getTransactionDetails = () => {
    const { order } = this.state;
    // console.log("getTransactionDetails:: order:", order);
    if (!order || !order.transactionDetails) return null;

    const { transactionDetails } = order;
    const { orderRequestPayload } = transactionDetails;
    // console.log("getTransactionDetails::", {
    //   transactionDetails,
    //   orderRequestPayload,
    // });
    return {
      transactionDate: transactionDetails.createdAt,
      serviceReference: transactionDetails.transactionReference,
      valeoRequestId: transactionDetails.valeoRequestId,
      merchantRequestId: transactionDetails.merchantRequestId,
      paymentDescription: transactionDetails?.paymentDescription,
      paymentStatusDescription: transactionDetails.paymentStatusDesc,
      paymentIP: transactionDetails.paymentIp,
      mamopayPaymentUrl: transactionDetails.mamopayPaymentUrl,
    };
  };

  deleteOrder=async()=>{
    const {order}=this.state;
    const {id=""}=order ??{};
    try{
      const response= await PhoenixAPI.patch(`${BASE_URL_CANCEL_ORDER}/${id}`)
      this.showSuccessNotification("Order cancelled successfully.")
      this.props.history.goBack();
    }
    catch(error){
      console.log("Error on cancelling order", error);
      this.showErrorNotification(
        "Error on cancelling order: " + error.message
      );
    }
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getCurrency = () => {
    const { order, country } = this.state;
    // console.log("getCurrency::", { order, country });
    return order?.transactionDetails?.currency ?? country?.currency;
  };

  renderCartItemsCard = () => {
    const { order } = this.state;
    // console.log("renderCartItemsCard:: order: ", order);
    if (!order || !order.items || !order.items.length) return null;

    const { items = [], orderItems = [] } = order;
    const cartItemsCard = items.map((childOrder, index) => {
      const cartItem = orderItems.find(
        (orderItem) => orderItem.orderId === childOrder.itemOrderId
      );
      return this.renderCartItemCard(index, cartItem, childOrder);
    });

    const orderStatusWithColor = getOrderStatusWithColor(order.orderStatus);
    const { status, color } = orderStatusWithColor ?? {};
    const operationStatus = (
      <span className={`ui small ${color} tag label mb-4`}>{status}</span>
    );
    return (
      <Card>
        <Card.Body>
          {operationStatus}
          {cartItemsCard}
        </Card.Body>
      </Card>
    );
  };

  renderCartItemCard = (index, cartItem, cartItemDetails) => {
    const { collapsibles = {} } = this.state;
    // console.log("renderCartItemCard::", { index, cartItem, cartItemDetails });
    const { quantity, finalPrice } = cartItem ?? {};
    const { imageUrl, itemNameEnglish } = cartItemDetails ?? {};
    const correctImageUrl =
      imageUrl && !imageUrl.startsWith("http")
        ? IMAGE_BASE_URL + imageUrl
        : imageUrl;
    // console.log("renderCartItemCard:: imageURLS:", {
    //   imageUrl,
    //   correctImageUrl,
    // });
    const currency = this.getCurrency() ?? "";
    return (
      <Card key={cartItem?.id ?? index}>
        <Card.Body>
          <div className="row">
            <div className="col-2">
              <div>
                <img
                  src={correctImageUrl ?? ""}
                  alt="Cart Item Pic"
                  className="item img-thumbnail mx-auto"
                  style={{
                    width: "100%",
                    maxHeight: "50px",
                    objectFit: "cover",
                  }}
                />
                <div className="floating ui label">{quantity ?? ""}</div>
              </div>
            </div>
            <div className="col ml-auto">{itemNameEnglish}</div>
            <div className="col-3">{`${currency} ${finalPrice} x ${quantity}`}</div>
            <div className="col-2">{`${currency} ${
              finalPrice * quantity
              }`}</div>
            <div className="col text-right mr-1 pr-0">
              <i
                onClick={() =>
                  this.handleCollapsibleChange(cartItemDetails.itemOrderId)
                }
                className={`caret square ${
                  collapsibles[cartItemDetails.itemOrderId] ? "up" : "down"
                  } icon big`}
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          </div>
          {collapsibles[cartItemDetails.itemOrderId]
            ? this.renderCartItemDetails(cartItem, cartItemDetails)
            : null}
        </Card.Body>
      </Card>
    );
  };

  renderCartItemDetails = (cartItem, cartItemDetails) => {
    console.log("sanndwe", cartItem, cartItemDetails)
    const { orderId, itemType, itemId } = cartItem ?? {};
    const {
      operationType,
      orderType,
      dependent, biomarkerMiniPackages=[]
    } = cartItemDetails;
    const isBloodPackage = itemType === "Blood_Package";
    const isMiniPackage = itemType === "Mini_Package";
    const isCustomPackage = itemType === "Custom_Package";
    const isHomeAppointmentRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("Home Appointment");
    const isCoachNoteRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("Requires Coach Note");
    const isPDFDownloadRequired =
      isCustomPackage &&
      operationType &&
      operationType.includes("PDF Download");
    //const condition = itemType === "Blood_Package" || (itemType === "Custom_Package" && operationType && operationType.includes("Home Appointment"));
    const condition =
      isBloodPackage ||
      isMiniPackage ||
      isHomeAppointmentRequired ||
      isCoachNoteRequired ||
      isPDFDownloadRequired;

    const commonOrderDetails = (
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="orderId">Order ID</label>
          <input
            value={orderId ?? ""}
            type="text"
            id="orderId"
            name="orderId"
            readOnly={true}
            className="form-control py-2"
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="orderType">Order Type</label>
          <input
            value={orderType ?? ""}
            type="text"
            id="orderType"
            name="orderType"
            readOnly={true}
            className="form-control py-2"
          />
        </div>
      </div>
    );

    const dependentDetails = (
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="dependentName">Customer Name</label>
          <input
            value={dependent ? `${dependent.firstName ?? ""} ${dependent.lastName}`.trim() : ""}
            type="text"
            id="dependentName"
            name="dependentName"
            readOnly={true}
            className="form-control py-2" />
        </div>
        <div className="form-group col-6">
          <label htmlFor="relation">Relation</label>
          <input
            value={dependent?.relation ?? ""}
            type="text"
            id="relation"
            name="relation"
            readOnly={true}
            className="form-control py-2" />
        </div>
      </div>
    );

    const selectedBiomarkerPackages=(
      <>
        <div className="row">
          <div className="form-group col-6">
            <label>Mini Packages</label>
            <SelectMiniPackages
              miniPackages={this.state.miniPackages ?? []}
              selectedMiniPackageIds={
                isMiniPackage
                  ? cartItemDetails?.miniPackages ?? []
                  : (biomarkerMiniPackages && biomarkerMiniPackages.length>0) && biomarkerMiniPackages.map((it) => it.id)
              }
              isDisabled={true}
            />
          </div>
        </div>
      </>
    )

    if (!condition) {
      return <>
        {commonOrderDetails}</>;
    } else {
      const {
        bookedSlotDate,
        bookedSlotTime,
        specimenCollectionDate,
        specimenCollectionTime,
        isAttachmentUploaded,
        isResultUploaded,
        confirmDate,
        confirmTime,
        nutritionistId,
      } = cartItemDetails ?? {};

      const countryId = this.getCountryId();
      const cityId = this.getCityId();
      // console.log("renderCartItemDetails:: region:", { countryId, cityId });
      const shouldShowAppointmentDateTimeForm =
        isBloodPackage ||
        isMiniPackage ||
        (isCustomPackage &&
          operationType &&
          operationType.includes("Home Appointment"));
      const appointmentDateTimeForm = (
        <div className="row">
          <div className="form-group col">
            <label htmlFor="bookedSlotDate">Appointment Date</label>
            <input
              value={bookedSlotDate ?? specimenCollectionDate ?? ""}
              type="date"
              id="bookedSlotDate"
              name="bookedSlotDate"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="bookedSlotTime">Appointment Time</label>
            <SelectTimeslot
              cityId={cityId}
              packageType={itemType ? itemType.toLowerCase() : ""}
              packageId={itemId}
              date={bookedSlotDate ?? null}
              selectedTimeslot={
                bookedSlotTime ?? specimenCollectionTime ?? null
              }
              isDisabled={true}
            />
          </div>
        </div>
      );
      const confirmedDateTimeForm = (
        <div className="row">
          <div className="form-group col">
            <label>Confirmed Date</label>
            <input
              value={confirmDate ?? ""}
              type="date"
              id="confirmDate"
              name="confirmDate"
              readOnly={true}
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label>Confirmed Time</label>
            <input
              value={confirmTime ?? ""}
              type="time"
              id="confirmTime"
              name="confirmTime"
              readOnly={true}
              className="form-control"
            />
          </div>
        </div>
      );

      return (
        <><div className="form-group col text-right">
          <span
            onClick={(event) =>
              this.showEditCartOrderViewModal(
                cartItem,
                cartItemDetails
              )
            }
            style={{ cursor: "pointer", color: "#2D9BF0" }}
          >
            Edit
          </span>
        </div>
          {commonOrderDetails}
          {dependent && dependentDetails}
          {shouldShowAppointmentDateTimeForm && appointmentDateTimeForm}
          {shouldShowAppointmentDateTimeForm && confirmedDateTimeForm}
          {(biomarkerMiniPackages && biomarkerMiniPackages.length>0) && selectedBiomarkerPackages }
        </>
      );
    }
  };

  renderEditButton = (onClick) => {
    return this.state.isEditable ? (
      <span onClick={onClick} style={{ cursor: "pointer", color: "#2D9BF0" }}>
        Edit
      </span>
    ) : null;
  };

  showEditCustomerNotesViewModal = () => {
    const { order } = this.state;
    this.setState({
      isEditCustomerNotesViewModalVisible: true,
      customerNotesForModal: order?.userDetails?.clientNote ?? null,
    });
  };

  renderCustomerNotesCard = () => {
    const { order } = this.state;
    const { clientNote: customerNote } = order?.userDetails ?? {};
    const customerNoteView = customerNote ? (
      <p>{customerNote}</p>
    ) : (
      <span className="placeholder">No notes for the customer!</span>
    );
    return (
      <>
        <div className="row mt-2">
          <div className="col">
            <p className="h6">Customer Notes</p>
          </div>
          <div className="col text-right">
            {this.renderEditButton(this.showEditCustomerNotesViewModal)}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">{customerNoteView}</div>
        </div>
      </>
    );
  };

  renderCustomerInfoCard = () => {
    const { order } = this.state;
    const { city_name } = this.state.city ?? {};
    const { country_name } = this.state.country ?? {};

    const { userName, firstName, lastName, email, phoneNumber } =
      order?.userDetails ?? {};
    const {
      addressLine1,
      addressLine2,
      buildingApt,
      city,
      country,
      phoneNo,
      mapUrl,
      lat,
      lng,
    } = order?.addressDetails ?? {};
    const fullName =
      firstName && lastName ? `${firstName} ${lastName}` : userName;
    const customerInfo = (
      <div>
        <p className="h6">Customer Information</p>
        <p className="ml-2">
          {fullName}
          <br />
          {email}
          <br />
          {phoneNumber}
          <br />
        </p>
      </div>
    );

    const countryCityInfo = (
      <div>
        <p className="h6">City & Country</p>
        <p className="ml-2">
          {city_name}
          <br />
          {country_name}
          <br />
        </p>
      </div>
    );
    const customerNotes = this.renderCustomerNotesCard();
    return (
      <Card className="mb-0 pb-0">
        <Card.Body className="mb-0 pb-0">
          {customerInfo}
          <div className="ui divider"></div>
          {countryCityInfo}
          <div className="ui divider"></div>
          {customerNotes}
          <div className="ui divider"></div>
        </Card.Body>
      </Card>
    );
  };

  renderPaymentInfoCard = (order,mamoPaymentUrl=null) => (
    <Card>
      <Card.Body>
        <DraftOrderPaymentInfo
          paymentDetails={this.getPaymentDetails()}
          onSave={(paymentDetails) =>
            this.handleSavePaymentDetails(paymentDetails)
          }
          orderDetails={order}
          generateLink={()=>this.generateMamoPayLink()}
          mamoPaymentUrl={mamoPaymentUrl}
          onSaveMamo={(paymentDetails)=>this.handleSaveMamo(paymentDetails)}
        />
        <div className="ui divider"></div>
        <DraftOrderTransactionInfo
          transactionDetails={this.getTransactionDetails()}
        />
      </Card.Body>
    </Card>
  );

  handleSaveCartOrder=async(orderDetails, updatedDetails)=>{
    console.log("OrderDashboardDetails:: handleSaveCartOrder", {
      orderDetails,
      updatedDetails,
    });

    const{ confirmedDate,
      confirmedTime,
      bookedSlotDate,
      bookedSlotTime}=updatedDetails;
    orderDetails.confirmDate=confirmedDate;
    const [hours, minutes] = confirmedTime.split(':');
    const hoursNum = parseInt(hours, 10);
    const formattedTime = `${hoursNum}:${minutes}`;
    orderDetails.confirmTime=confirmedTime;
    const mappedConfirmTime=hours+":"+minutes
    const payload=
    {
      "itemId":orderDetails.itemOrderId,
      "confirmDate":confirmedDate,
      "confirmTime":mappedConfirmTime,
    }


    try {
      const response = await PhoenixAPI.patch(BASE_URL_UPDATE_CONFIRMDATE, payload);

      this.handleHideEditCartOrderViewModal();
      this.showSuccessNotification("Confirmation Date and Time saved successfully.");

    } catch (error) {
      this.showErrorNotification(
        "Error on saving Confirmation Date and Time: ",
        error.message
      );
    }


  }

  renderDeleteOrderButton=()=>(
    <button
      onClick={(e)=>this.setState({isConfirmDeleteDraftOrderModalVisible:true})}
      className="btn button btn-danger"
    >
      Delete Order
    </button>
  )


  render() {
    const { order, cartItem,isEditable,isEditOrderNotesViewModalVisible,isEditCustomerShippingAddressViewModalVisible,shippingAddressModal,
      cartItemDetails, isEditCustomerNotesViewModalVisible,isEditCartOrderViewModalVisible, isConfirmDeleteDraftOrderModalVisible, mamoPaymentUrl, userCityAddress } = this.state;
    const { orderStatus } = order ?? {};
    // console.log("cartItemDetails",cartItemDetails);
    if (order === null || order === undefined) {
      // console.log("render:: Invalid order:", order);
      return null;
    }

    const { userDetails } = order ?? {};
    const { transactionDetails } = order ?? {};
    const paymentInfo = {
      subtotal: transactionDetails.totalAmount,
      total: transactionDetails.finalAmount,
    };

    const cartItemsCard = this.renderCartItemsCard();

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-1">
            <i
              onClick={() => this.props.history.goBack()}
              className="caret square left icon big"
              style={{ fontSize: "40px", cursor: "pointer" }}
            ></i>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-8">
            {cartItemsCard}
            {this.renderPaymentInfoCard(order,mamoPaymentUrl)}
          </div>
          <div className="col-4"><OrderNotesCard
            orderNotes={order.orderNotes}
            onEdit={this.showEditOrderNotesViewModal}
            isEditable={isEditable}
          />
            {this.renderCustomerInfoCard()}
            <div className="col mt-4 pl-0">
              {this.renderDeleteOrderButton()}
            </div>
          </div>

        </div>

        <DeleteDraftOrderViewModal
          isVisible={isConfirmDeleteDraftOrderModalVisible}
          onHide={()=>this.setState({isConfirmDeleteDraftOrderModalVisible:false})}
          onCancel={this.deleteOrder}

        />
        <EditCartOrderViewModal
          order={cartItem}
          orderDetails={cartItemDetails}
          countryId={this.getCountryId()}
          cityId={this.getCityId()}
          isPaymentPendingOrder={true}
          onSave={(orderDetails, updatedDetails) => {
            this.setState(
              { isSaving: true },
              () => this.handleSaveCartOrder(orderDetails, updatedDetails)
            )
          }}
          // onSave={this.handleSaveCartOrder}
          onHide={this.handleHideEditCartOrderViewModal}
          isVisible={isEditCartOrderViewModalVisible}

          isSaving={this.state.isSaving ?? false}
          familyMembers={[]}
          isDraftOrder={false}

        />
        <EditOrderNotes
          title={order.orderNotes ? "Edit Order Note" : "Add Order Note"}
          orderNotes={this.state.orderNotesForModal}
          onHide={this.handleHideEditOrderNotesViewModal}
          onSave={this.handleSaveOrderNotes}
          isVisible={isEditOrderNotesViewModalVisible}
        />

        <EditCustomerNotes
          title={
            userDetails.clientNote ? "Edit Customer Note" : "Add Customer Note"
          }
          customerNotes={this.state.customerNotesForModal}
          onHide={this.handleHideEditCustomerNotesViewModal}
          onSave={this.handleSaveCustomerNote}
          isVisible={isEditCustomerNotesViewModalVisible}
        />
          <EditCustomerShippingAddress
        title={
          "Please add Shipping Address to proceed"
        }
         shippingAddress={shippingAddressModal ?? null}
         onHide={this.handleHideEditCustomerShippingAddressViewModal}
         onSave={this.handleSaveShippingAddress}
         isVisible={isEditCustomerShippingAddressViewModalVisible}
         order={order}
         userCityAddress={userCityAddress??[]}
        />

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DraftOrderDashboardDetails);
