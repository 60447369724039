import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import Rating from "components/Rating/Rating";
import { filterList } from "utils/List";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class NutritionistRatings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: null,
      nutritionists: [],
      isLoaded: false,
      loadError: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0
    };

    this.columns = [
      {
        name: "Name",
        selector: "name",
        sortable: true
      },
      {
        name: "Email",
        selector: "email",
        sortable: true
      },
      {
        name: "Rating",
        selector: "rating",
        sortable: true,
        format: row => <Rating rating={row.rating}></Rating>
      }
    ];

    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  componentDidMount() {
    this.loadNutritionists();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  loadNutritionists() {
    API.get("nutritionist/")
      .then(response => {
        const activeList = filterList(response.data.results, "active");
        this.setState({ nutritionists: activeList }, () => this.loadReviews());
      })
      .catch((error) => {
        console.log("Error on fetching nutritionist", error);
      });
  }

  loadReviews() {
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("review-average-nutritionist/")
      .then(response => {
        let newList = [];

        for (let review of response.data) {
          const details = this.getNutritionistDetails(review.nutritionist);

          if (details) {
            review.name = details.name;
            review.email = details.email;
            newList.push(review);
          }
        }

        this.setState({ reviews: newList, isLoaded: true });
        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching review average nutritionist", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  getNutritionistDetails(id) {
    const nutritionists = this.state.nutritionists;

    if (nutritionists.length > 0) {
      let nutritionist = nutritionists.find(item => {
        return item.id === id;
      });

      if (nutritionist)
        return { name: nutritionist.username, email: nutritionist.email };
    }

    return null;
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    list = this.state.reviews;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.email.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  render() {
    let finalList = [];
    const {
      reviews,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results
    } = this.state;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else finalList = reviews;
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>Nutritionist Ratings</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="name"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NutritionistRatings);
