import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import OpenGoogleMapWithPin from "./OpenMap";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import { bookingStatus } from "./bookingStatus";
import "./order.scss";
import API from "utils/API";
import { NavLink } from "react-router-dom";
import ClientOrderDetails from "./ClientOrderDetails";
import { fetchBookings, fetchProfessionals } from "./Services";
import NurseModal from "./NurseModal";
import Notification from './Notification';
import { trackAssignProfessionalClick } from "analytics";
const BASE_URL_LABS = "/create-laboratory/";


const BASE_URL_BOOKING_DETAILS = "/home-care-order-details";
export default function OrdersCards(props) {
  const {
    startDate,
    endDate,
    homecareServiceId,
    setIsShowClient,
    setIsShowOrder,
    setClientDetail,
    setSearchOrders,
    searchOrders,
    orders,
    setOrders,
    searchFlag=false,
    professionals,
    user = {},
  } = props;

 
  const [isError, setIsError] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [labs, setLabs] = useState([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [changeNurseOrder, setChangeNurseOrder] = useState({});
  const isMountedRef = React.useRef(true);
  const [message, setMessage] = useState("");
  const BASE_URL_BOOKINGS = "/list-home-care-order-details/homecare";

  // useEffect(() => {
  //   isMountedRef.current = true;
  //   // const fetchData = async () => {  // will check why this function is used
  //   //   try {
  //   //     // await fetchLabs();
  //   //     // const ordersAssign =   await fetchBookings(startDate, endDate,homecareServiceId?.homecareServiceId);
  //   //     // console.log("orders",ordersAssign);
  //   //     // setOrders([...ordersAssign]);
       
  //   //   } catch (error) {
  //   //     console.error("Error fetching user:", error);
  //   //   }
  //   // };
  //   // fetchData();

  //   // return () => {
  //   //   isMountedRef.current = false;
  //   // };
  // }, [user]);
  const findLabById = (labId) => {
    const labs = labs ?? [];
    return labs.find((lab) => lab.id === labId);
  };

  const getFullAddress = (order) => {
    if (!order) return "";

    const { address_line1, address_line2, building_apt, city, country } = order;
    const location = [address_line1, address_line2, building_apt, city, country]
      .filter((it) => it ?? false)
      // .join("\r\n");
      .join(", ");

    return location;
  };

  const handleAssignProfessional = async (payload) => {
    if (!user) {
      console.log("handleAssignProfessional:: Invalid user or bookings!");
      // this.showErrorNotification("Invalid user or bookings!");
      return;
    }
    if (isError) {
      setMessage("Invalid Fields");
    } else {
      setMessage("");
      setIsError(false);
      const { id, homecare_service_id } = user.user;
      console.log(
        "in change nurse",
        homecare_service_id,
        user,
        id,
        homecare_service_id
      );
      const booking = orders?.find((it) => it.id === payload.orderId);
      payload.userId = id;
      payload.homeCareId = homecare_service_id;
      payload.nurseAppointmentStatus = bookingStatus.NURSE_APPOINTED.value;
      const deviceId = sessionStorage.getItem("device_id") ?? "";
      const userDetails=user.user??{}
      const trackingData={
        "homecareId":userDetails?.homecare_service_id??"",
        "deviceId":deviceId,
        "homecare":userDetails?.email??"",
        "homecareName":userDetails?.username??"",
         "changeNurseClick":true,
      }
      try {
        if (booking.professional_id) {
          await PhoenixAPI.patch("/assign-nurse", payload);
        } else {
          await PhoenixAPI.post("/assign-nurse", payload);
        }

        setMessage("nurse assigned successfully");
        setIsError(false);

        const ordersAssign = await fetchBookings(
          startDate,
          endDate,
          homecare_service_id
        );
        
        setOrders([...ordersAssign]);
        setIsShowClient(false);

        setIsShowOrder(true);
        trackingData["assign_professional_popup_save successfull"]="Assigned successfully";
        trackAssignProfessionalClick(trackingData)
      } catch (error) {
        setIsError(true);
        setMessage("nurse assigned failed");
        trackingData["assign_professional_popup_save_successfull_error"]="Nurse Assigned failed";
        trackAssignProfessionalClick(trackingData)
        //this.showErrorNotification("Some error occurred: " + error.message);
      } finally {
      }
    }
  };
  const hideModal = () => {
    setIsShowModal(false);
  };

  const fetchBookingDetails = async (orderId) => {
    if (!orderId) {
      console.log("fetchBookingDetails:: Invalid order id!", orderId);
      return null;
    }

    
    const homecare_service_id = homecareServiceId?.homecareServiceId;
    if (!homecare_service_id) {
      console.log(
        "fetchBookingDetails:: Invalid homecare service id!",
        homecare_service_id
      );
      return null;
    }

    try {
     
      // /home-care-order-details/homecare/1/order/7713
      const url = `${BASE_URL_BOOKING_DETAILS}/homecare/${homecare_service_id}/order/${orderId}`;

      const { data: booking } = await PhoenixAPI.get(url);
      
      setClientDetail(booking);
    } catch (error) {
      console.log(
        "fetchBookingDetails:: Error on fetching booking details!",
        error
      );

      /* this.showErrorNotification(
            "Error on fetching booking details: ",
            error.message
          );*/
    } finally {
    }
  };
  const handleChangeNurse = (item) => {
    setChangeNurseOrder(item);
    setIsShowModal(true);
  };
  const handleChangeNurseClick = (e, item) => {
    e.stopPropagation();
    handleChangeNurse(item);
  };

  const fetchLabs = async () => {
    try {
      //   showLoading();
      const { data: labs = [] } = await API.get(BASE_URL_LABS);
      if (isMountedRef.current) {
        setLabs(labs);
      }
    } catch (error) {
      // showErrorNotification("Error on fetching labs!" + error.message);
    } finally {
      // hideLoading();
    }
  };

  const handleCardClick = (item) => {
    fetchBookingDetails(item.id);
    setIsShowClient(true);
    setIsShowOrder(false);
  };

  const renderOrderCards = (orders) => {
    return (
      <>
        {orders && orders.length > 0 ? (
          orders.map((item, index) => {
            const [appointmentDate, appointmentTime] =
              item && item.confirmed_date_time
                ? item.confirmed_date_time.split(" ")
                : [null, null];

            const formattedDate = appointmentDate
              ? formatDate(appointmentDate)
              : "";
            const formattedTimeslot = appointmentTime
              ? formatSingleTimeslot(appointmentTime)
              : "";

            return (
              <div
                className="clients-card"
                onClick={() => {
                  handleCardClick(item);
                }}
              >
                <div className="client-name-date">
                  <div className="name-txt">{item.package_name}</div>
                  <div className="date-time">
                    <span className="date"> {formattedDate}</span>
                    <span id="separator">|</span>
                    <span className="date">{formattedTimeslot}</span>
                  </div>
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "400",
                    lineHeight: "15px",
                  }}
                >
                  {item.user_name}
                </div>
                {item.special_instructions ? (
                  <div className="payment-collection">
                    Special Instructions :{" "}
                    <span className="id">{item.special_instructions}</span>
                  </div>
                ) : (
                  <></>
                )}
                {item.payment_method == "COD"  || item.payment_method === "COD_MAMOPAY"  ? (
                  <div className="payment-collection">
                    Payment to be collected :
                    <span className="id">
                    { 
               item.payment_method === "COD_MAMOPAY" 
                 ? (item.is_mamopay_transaction_completed
                  ? "Paid" 
                  :item.homecare_payment_status!=null?"Paid": `${item.payment_to_be_collected ?? 0}  `) 
                  :item.is_mamopay_transaction_completed?"Paid"
                   : item.homecare_payment_status!=null?"Paid":`${item.payment_to_be_collected ?? 0}  `
                     }
                    </span>
                  </div>
                ) : (
                  <></>
                )}

                <div className="client-location">
                  <div className="location-cont">
                    <div className="location-in-text">
                      <div className="location-header">Address</div>
                      <div className="location-detail">
                        {getFullAddress(item) || "null"}
                      </div>
                      <div
                        className="location-in-map"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="open-map-btn">
                          <OpenGoogleMapWithPin item={item} />
                        </div>
                      </div>
                    </div>
                    {item.id ? (
                      <div className="item-status">
                        <div className="order-id-div">ID : {item.id}</div>
                     
                        {item.nurse_appointment_status == "Completed" ? (
                          <div className="completed-order">Completed</div>
                        ) : item.nurse_appointment_status ==
                          "Nurse Appointed" ? (
                          <div className="assigned-order">Assigned </div>
                        ) : item.order_status == "LAB CONFIRMED" ? (
                          <div className="new-order">New Order</div>
                        ) : (
                          <div className="upcoming-order">UpComing </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                {/*item.order_status == "ORDER CONFIRMED" ? (
                  <>
                    {" "}
                    <div className="location-in-map-accept">
                      <div className="open-map-btn-accept">Accept</div>
                    </div>{" "}
                    &nbsp;&nbsp;
                    <div className="location-in-map-reject">
                      <div className="open-map-btn-reject">Deny</div>
                    </div>
                  </>
                )*/}
                {item.nurse_appointment_status == "Nurse Appointed" ? (
                  <div className="nurse-assign">
                    <div className="nurse-name-txt">
                      <span className="nurse-assign-txt">Nurse assigned:</span>{" "}
                      &nbsp;&nbsp;
                      <span className="professional-name">
                        {" "}
                        {item.professional_name}{" "}
                      </span>
                    </div>
                    <div
                      className="change-nurse"
                      onClick={(e) => handleChangeNurseClick(e, item)}
                    >
                      change nurse
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })
        ) : (
          <></>
        )}{" "}
      </>
    );
  };

  return (
    <>
      <div className="clients-wrapper-home-care">
        {searchFlag ? renderOrderCards(searchOrders) : renderOrderCards(orders)}
        {isShowModal && (
          <NurseModal
            orderId={changeNurseOrder.id}
            nurseData={changeNurseOrder}
            setIsShowModal={setIsShowModal}
            professionals={professionals}
            onSave={handleAssignProfessional}
            nurseModalText={"assign"}
            setIsShowClient={setIsShowClient}
            setMessage={setMessage}
            setIsError={setIsError}
          />
        )}
        {message && (
          <Notification
            setMessage={setMessage}
            message={message}
            isError={isError}
          />
        )}
      </div>
    </>
  );
}
