 export const ONE_HOUR_SLOTS=[
    
    {
       "key": "06:00-07:00",
       "value": "06:00-07:00",
       "text": "06:00-07:00"
     },
     {
       "key": "07:00-8:00",
       "value": "07:00-8:00",
       "text": "07:00-8:00"
     },
     {
       "key": "08:00-09:00",
       "value": "08:00-09:00",
       "text": "08:00-09:00"
     },
     {
       "key": "09:00-10:00",
       "value": "09:00-10:00",
       "text": "09:00-10:00"
     },
     {
       "key": "10:00-11:00",
       "value": "10:00-11:00",
       "text": "10:00-11:00"
     },
     {
       "key": "11:00-12:00",
       "value": "11:00-12:00",
       "text": "11:00-12:00"
     },
     {
       "key": "12:00-13:00",
       "value": "12:00-13:00",
       "text": "12:00-13:00"
     },
     {
       "key": "13:00-14:00",
       "value": "13:00-14:00",
       "text": "13:00-14:00"
     },
     {
       "key": "14:00-15:00",
       "value": "14:00-15:00",
       "text": "14:00-15:00"
     },
     {
       "key": "15:00-16:00",
       "value": "15:00-16:00",
       "text": "15:00-16:00"
     },
     {
       "key": "16:00-17:00",
       "value": "16:00-17:00",
       "text": "16:00-17:00"
     },
     {
       "key": "17:00-18:00",
       "value": "17:00-18:00",
       "text": "17:00-18:00"
     },
     {
       "key": "18:00-19:00",
       "value": "18:00-19:00",
       "text": "18:00-19:00"
     },
     {
       "key": "19:00-20:00",
       "value": "19:00-20:00",
       "text": "19:00-20:00"
     },
     {
       "key": "20:00-21:00",
       "value": "20:00-21:00",
       "text": "20:00-21:00"
     },
     {
       "key": "21:00-22:00",
       "value": "21:00-22:00",
       "text": "21:00-22:00"
     },
     {
       "key": "22:00-23:00",
       "value": "22:00-23:00",
       "text": "22:00-23:00"
     },

  

]

 export const ONE_HOUR_HALF_SLOTS=[
    
    {
       "key": "06:00-07:30",
       "value": "06:00-07:30",
       "text": "06:00-07:30"
     },
     {
       "key": "07:30-09:00",
       "value": "07:30-09:00",
       "text": "07:30-09:00"
     },
     {
       "key": "09:00-10:30",
       "value": "09:00-10:30",
       "text": "09:00-10:30"
     },
     {
       "key": "10:30-12:00",
       "value": "10:30-12:00",
       "text": "10:30-12:00"
     },
     {
       "key": "12:00-13:30",
       "value": "12:00-13:30",
       "text": "12:00-13:30"
     },
     {
       "key": "13:30-15:00",
       "value": "13:30-15:00",
       "text": "13:30-15:00"
     },
     {
       "key": "15:00-16:30",
       "value": "15:00-16:30",
       "text": "15:00-16:30"
     },
     {
       "key": "16:30-18:00",
       "value": "16:30-18:00",
       "text": "16:30-18:00"
     },
     {
       "key": "18:00- 19:30",
       "value": "18:00-19:30",
       "text": "18:00-19:30"
     },
     {
       "key": "19:30-21:00",
       "value": "19:30-21:00",
       "text": "19:30-21:00"
     },
     {
       "key": "21:00-22:30",
       "value": "21:00-22:30",
       "text": "21:00-22:30"
     },
     {
       "key": "22:30-00:00",
       "value": "22:30-00:00",
       "text": "22:30-00:00"
     },

]

export const TWO_HOUR_SLOTS=[
    
    {
       "key": "06:00-08:00",
       "value": "06:00-08:00",
       "text": "06:00-08:00"
     },
     {
       "key": "08:00-10:00",
       "value": "08:00-10:00",
       "text": "08:00-10:00"
     },
     {
       "key": "10:00-12:00",
       "value": "10:00-12:00",
       "text": "10:00-12:00"
     },
     {
       "key": "12:00-14:00",
       "value": "12:00-14:00",
       "text": "12:00-14:00"
     },
     {
       "key": "14:00-16:00",
       "value": "14:00-16:00",
       "text": "14:00-16:00"
     },
     {
       "key": "16:00-18:00",
       "value": "16:00-18:00",
       "text": "16:00-18:00"
     },
     {
       "key": "18:00-20:00",
       "value": "18:00-20:00",
       "text": "18:00-20:00"
     },
     {
       "key": "20:00-22:00",
       "value": "20:00-22:00",
       "text": "20:00-22:00"
     },
     {
       "key": "22:00-00:00",
       "value": "22:00-00:00",
       "text": "22:00-00:00"
     },

]


export const ALL_TIME_SlOTS=[
  
    
    {
       "key": "06:00-07:00",
       "value": "06:00-07:00",
       "text": "06:00-07:00"
     },
     {
       "key": "07:00-8:00",
       "value": "07:00-8:00",
       "text": "07:00-8:00"
     },
     {
       "key": "08:00-09:00",
       "value": "08:00-09:00",
       "text": "08:00-09:00"
     },
     {
       "key": "09:00-10:00",
       "value": "09:00-10:00",
       "text": "09:00-10:00"
     },
     {
       "key": "10:00-11:00",
       "value": "10:00-11:00",
       "text": "10:00-11:00"
     },
     {
       "key": "11:00-12:00",
       "value": "11:00-12:00",
       "text": "11:00-12:00"
     },
     {
      "key": "12:00-1:00",
       "value": "12:00-1:00",
       "text": "12:00-1:00"
     },
     {
      "key": "1:00-2:00",
       "value": "1:00-2:00",
       "text": "1:00-2:00"
     },
     {
       "key": "12:00-13:00",
       "value": "12:00-13:00",
       "text": "12:00-13:00"
     },
     {
       "key": "13:00-14:00",
       "value": "13:00-14:00",
       "text": "13:00-14:00"
     },
     {
       "key": "14:00-15:00",
       "value": "14:00-15:00",
       "text": "14:00-15:00"
     },
     {
       "key": "15:00-16:00",
       "value": "15:00-16:00",
       "text": "15:00-16:00"
     },
     {
       "key": "16:00-17:00",
       "value": "16:00-17:00",
       "text": "16:00-17:00"
     },
     {
       "key": "17:00-18:00",
       "value": "17:00-18:00",
       "text": "17:00-18:00"
     },
     {
       "key": "18:00-19:00",
       "value": "18:00-19:00",
       "text": "18:00-19:00"
     },
     {
       "key": "19:00-20:00",
       "value": "19:00-20:00",
       "text": "19:00-20:00"
     },
     {
       "key": "20:00-21:00",
       "value": "20:00-21:00",
       "text": "20:00-21:00"
     },
     {
       "key": "21:00-22:00",
       "value": "21:00-22:00",
       "text": "21:00-22:00"
     },
     {
       "key": "22:00-23:00",
       "value": "22:00-23:00",
       "text": "22:00-23:00"
     },
     {
      "key": "23:00-00:00",
      "value": "23:00-00:00",
      "text": "23:00-00:00"
     },
     {
      "key": "06:00-07:30",
      "value": "06:00-07:30",
      "text": "06:00-07:30"
    },
    {
      "key": "07:30-09:00",
      "value": "07:30-09:00",
      "text": "07:30-09:00"
    },
    {
      "key": "09:00-10:30",
      "value": "09:00-10:30",
      "text": "09:00-10:30"
    },
    {
      "key": "10:30-12:00",
      "value": "10:30-12:00",
      "text": "10:30-12:00"
    },
    {
      "key": "12:00-13:30",
      "value": "12:00-13:30",
      "text": "12:00-13:30"
    },
    {
      "key": "13:30-15:00",
      "value": "13:30-15:00",
      "text": "13:30-15:00"
    },
    {
      "key": "15:00-16:30",
      "value": "15:00-16:30",
      "text": "15:00-16:30"
    },
    {
      "key": "16:30-18:00",
      "value": "16:30-18:00",
      "text": "16:30-18:00"
    },
    {
      "key": "18:00-19:30",
      "value": "18:00-19:30",
      "text": "18:00-19:30"
    },
    {
      "key": "19:30-21:00",
      "value": "19:30-21:00",
      "text": "19:30-21:00"
    },
    {
      "key": "21:00-22:30",
      "value": "21:00-22:30",
      "text": "21:00-22:30"
    },
    {
      "key": "22:30-00:00",
      "value": "22:30-00:00",
      "text": "22:30-00:00"
    },


  {
       "key": "06:00-08:00",
       "value": "06:00-08:00",
       "text": "06:00-08:00"
     },
     {
       "key": "08:00-10:00",
       "value": "08:00-10:00",
       "text": "08:00-10:00"
     },
     {
       "key": "10:00-12:00",
       "value": "10:00-12:00",
       "text": "10:00-12:00"
     },
     {
       "key": "12:00-14:00",
       "value": "12:00-14:00",
       "text": "12:00-14:00"
     },
     {
       "key": "14:00-16:00",
       "value": "14:00-16:00",
       "text": "14:00-16:00"
     },
     {
       "key": "16:00-18:00",
       "value": "16:00-18:00",
       "text": "16:00-18:00"
     },
     {
       "key": "18:00-20:00",
       "value": "18:00-20:00",
       "text": "18:00-20:00"
     },
     {
       "key": "20:00-22:00",
       "value": "20:00-22:00",
       "text": "20:00-22:00"
     },
     {
       "key": "22:00-00:00",
       "value": "22:00-00:00",
       "text": "22:00-00:00"
     },


]

const TimeSlots={
    ONE_HOUR_SLOTS,
    ONE_HOUR_HALF_SLOTS,
    TWO_HOUR_SLOTS,
    ALL_TIME_SlOTS
}

export default TimeSlots