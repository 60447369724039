import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import _ from "lodash";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class ProblematicAnswerForm extends Component {
  constructor(props) {
    super(props);

    const { answers = [], onAnswersUpdated = (f) => f } = this.props;

    this.onAnswersUpdated = onAnswersUpdated;

    this.state = {
      answers,
      answer: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Answer (English)",
        selector: "answerEnglish",
        sortable: true,
        wrap: true,
        format: (answer) => (
          <div className="text-overflow-ellipsis">
            {answer.answerEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Answer (Arabic)",
        selector: "answerArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (answer) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {answer.answerArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (biomarker) => this.renderActionBar(biomarker),
      },
    ];
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      answer: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const answer = { ...this.state.answer };
    answer[input.name] = input.value;
    this.setState({ answer });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { answers = [] } = this.state;
    const answer = answers.find((it) => it.id === id);
    if (!answer) return;

    this.setState(
      {
        answer,
      },
      () => this.showAddEditModal()
    );
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteQuestion(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { answer = {} } = this.state;
    if (answer.id === null || answer.id === undefined) this.createAnswer();
    else this.updateAnswer(answer.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { answerEnglish, answerArabic } = this.state.answer || {};
    if (!answerEnglish || answerEnglish.trim() === "")
      errors.answerEnglish = "Answer (English) cannot be blank";
    else if (answerEnglish && answerEnglish.length > 200)
      errors.answerEnglish = "Answer (English) is limited to a 200 characters";

    if (!answerArabic || answerArabic.trim() === "")
      errors.answerArabic = "Answer (Arabic) cannot be blank";
    else if (answerArabic && answerArabic.length > 200)
      errors.answerArabic = "Answer (Arabic) is limited to a 200 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createAnswer = () => {
    const { answers = [], answer } = this.state;
    const ids = answers.length > 0 ? answers.map((it) => it.id) : null;
    answer.id = ids ? Math.max(...ids) + 1 : 1;

    answer.is_new = true;

    this.setState({ answers: [...answers, answer] }, () => {
      this.showSuccessNotification("Answer Created Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  updateAnswer = (id) => {
    const { answers = [], answer } = this.state;

    const newAnswers = answers.map((it) => (it.id === id ? answer : it));
    this.setState({ answers: newAnswers }, () => {
      this.showSuccessNotification("Answer Updated Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { answers = [] } = this.state;
    const newAnswers = answers.map((it) =>
      it.id === id
        ? { ...it, isActive: it.isActive === true ? false : true }
        : it
    );
    this.setState({ answers: newAnswers }, () => {
      this.showSuccessNotification("Answer Updated Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  deleteQuestion = (id) => {
    const { biomarkers = [] } = this.state;
    const biomarkerToDelete = biomarkers.find((it) => it.id === id) || {};
    const newBiomarkers = biomarkers
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > biomarkerToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ biomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Answer Deleted Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  onAnswerUpdatedLocally = () => {
    this.clearForm();
    this.onAnswersUpdated(
      this.state.answers.map((answer) => {
        const q = { ...answer };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
    const answer = { isActive: false };
    this.setState({ answer }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (answer) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(answer.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(answer.id)}>
          {answer.isActive ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {answer.isActive ? "InActive" : "Active"}
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.answer.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      answers = [],
      answer = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewAnswer = answer.id === null || answer.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="answerEnglish">Answer (English)*</label>
            <div className="input-group">
              <textarea
                value={answer.answerEnglish}
                onChange={this.handleChange}
                id="answerEnglish"
                name="answerEnglish"
                placeholder="Answer in English"
                disabled={!isNewAnswer && !editables.answerEnglish}
                className={
                  "form-control py-2" +
                  (errors.answerEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerEnglish")}
            </div>
            <span className="help-block">{errors.answerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="answerArabic">Answer (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={answer.answerArabic}
                onChange={this.handleChange}
                id="answerArabic"
                name="answerArabic"
                placeholder="Answer in Arabic"
                disabled={!isNewAnswer && !editables.answerArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.answerArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerArabic")}
            </div>
            <span className="help-block">{errors.answerArabic}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={answer?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    answer: {
                      ...answer,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewAnswer && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={answers || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New Answer
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {answer.id === null || answer.id === undefined
                ? "Add Answer"
                : "Edit Answer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {answer.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove biomarker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the biomarker?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteQuestion(this.state.biomarkerToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblematicAnswerForm);
