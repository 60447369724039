import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Notifications from "@material-ui/icons/Notifications";
import API from "utils/API";
import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { getUserName, getUserType, getUserId } from "services/UserService";
import FormValidator from "utils/FormValidator";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Notification from "assets/img/Notification.svg";
import { messaging, onMessageListener } from "../../config/firebase";
import PhoenixAPI from "../../utils/PhoenixAPI";

class Header extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "old_password",
        method: "isEmpty",
        validWhen: false,
        message: "Old password is required.",
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "New password is required.",
      },
      {
        field: "password",
        method: "isLength",
        min: 8,
        validWhen: true,
        message: "At least 8 characters are required.",
      },
      {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "Confirm password is required.",
      },
      {
        field: "confirm_password",
        method: "equals",
        validWhen: true,
        equalsTo: "password",
        message: "Passwords do not match.",
      },
    ]);

    this.state = {
      old_password: "",
      password: "",
      confirm_password: "",
      validation: this.validator.valid(),
      showModal: false,
      notifications: [],
      dropdownProps: {},
      nextPage: ''
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    let object =
      this.fetchNotifications()
    // if (navigator && navigator.serviceWorker) {
    //   navigator.serviceWorker.addEventListener("message", (event) => {
    //     debugger;
    //     console.log(event)
    //     //fetch notifications again
    //     setTimeout(() => {
    //       this.fetchNotifications()
    //       if (event['data']['firebase-messaging-msg-data']['data']) {
    //         let notificationData = event['data']['firebase-messaging-msg-data']['data'];
    //         if (notificationData.notification_type == 'order') {
    //           this.showToast('order status update')
    //         }
    //       }
    //     }, 2000)
    //   });
    // }
  }

  showToast(message) {
    this.props.showNotificationMessage({
      notificationMessage: message,
      successMessage: true,
      showNotification: true,
      isLoading: false,
    });
  }

  fetchNotifications(pageNo) {
    let url = ''
    let olderNotifications = this.state.notifications;
    if (pageNo) {
      url = 'notifications/?page=' + pageNo
    } else {
      //initial
      url = 'notifications/'
      olderNotifications = [];
    }
    API.get(url)
      .then((response) => {
        this.setState({
          notifications: olderNotifications.concat(response.data.results),
          nextPage: response.data.next
        })
      })
      .catch((error) => {
        console.log("Error on fetching notifications", error);
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  changePassword() {
    const validation = this.validator.validate(this.state);
    const { old_password, password } = this.state;

    this.submitted = true;
    this.setState({ validation });

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });
      API.put("reset-password/", {
        old_password: old_password,
        new_password: password,
      })
        .then((response) => {
          this.clearForm();
          this.props.showNotificationMessage({
            notificationMessage: "Password changed successfully.",
            successMessage: true,
            showNotification: true,
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on updating password", error);
          let message = "";
          if (error.response.status === 400) {
            message = "Old password is incorrect.";
          } else message = "Some error has occured. Please try again.";

          this.props.showNotificationMessage({
            notificationMessage: message,
            successMessage: false,
            showNotification: true,
            isLoading: false,
          });
        });
    }
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      old_password: "",
      password: "",
      confirm_password: "",
      validation: this.validator.valid(),
    });
  }

  dropdownClickHandler(isOpen, e) {
    //fetch more notifications or navigate to specified screen
    if (e) {
      //clicked inside
      if (e.target.getAttribute('id') == 'load-more-btn') {
        //load more btn - dont dismiss dropdown
        //fetch more notifications and append it.
        let pageNo = this.state.nextPage;
        if (pageNo) {
          pageNo = pageNo.split('=').pop()
          this.fetchNotifications(pageNo)
        } else {
          pageNo = null
        }
        this.setState({
          dropdownProps: { show: true }
        })
      } else if (isOpen) {
        //show the dropdrown on click on toggle btn
        this.setState({
          dropdownProps: { show: true }
        })
      } else {
        //redirect to specific page and dismiss dropdown
        this.setState({
          dropdownProps: { show: false }
        })
      }
    } else {
      //clicked outside
      this.setState({
        dropdownProps: { show: false }
      })
    }


  }

  redirectNurseNotifications=()=>{
    return window.location.href="/dashboard/notifications"
   }
   logoutFcmToken=async()=>{
    localStorage.removeItem("8js2jbhca")
    const token = await messaging.getToken();
    const userId=getUserId();
    if(token){
      try{
        const res=await PhoenixAPI.patch(`nurse/nurse-device/logout?fcmToken=${token}&userId=${userId}`)
        console.log("res", res)
       
      }
      catch(error){
       console.log("Error in daving fcm token", error.message)
      }
    }
    
   }
  
  render() {
    moment.updateLocale('en', {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: 'a few seconds',
        ss: '%d seconds',
        m: "a minute",
        mm: "%d minutes",
        h: "an hour",
        hh: "%d hours",
        d: "a day",
        dd: "%d days",
        M: "a month",
        MM: "%d months",
        y: "a year",
        yy: "%d years"
      }
    });
    let userName = getUserName();
    const { menuExpanded, routerName } = this.props;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    const form = (
      <>
        <div className="form-group mb-4">
          <label>Old Password *</label>
          <input
            type="password"
            name="old_password"
            className={
              "form-control " +
              (validation.old_password.isInvalid && "has-error")
            }
            placeholder="Old password"
            onChange={this.handleChange}
            value={this.state.old_password}
          ></input>
          <span className="help-block">{validation.old_password.message}</span>
        </div>
        <div className="form-group">
          <label>New Password *</label>
          <input
            type="password"
            name="password"
            className={
              "form-control " + (validation.password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="New password"
            onChange={this.handleChange}
            value={this.state.password}
          ></input>
          <span className="help-block">{validation.password.message}</span>
        </div>
        <div className="form-group">
          <label>Confirm Password *</label>
          <input
            type="password"
            name="confirm_password"
            className={
              "form-control " +
              (validation.confirm_password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="Confirm password"
            onChange={this.handleChange}
            value={this.state.confirm_password}
          ></input>
          <span className="help-block">
            {validation.confirm_password.message}
          </span>
        </div>
      </>
    );

   
   
    return (
      <>
        <div className={"header " + (menuExpanded ? "" : "wide")}>
          <span className="page_title">{routerName === "New Orders" || routerName==="Completed Orders"? "Nursecare Portal" : routerName} </span>
          <ul className="navbar">
            <li className="nav-item">
              <NavLink
                className="nav-link disabled"
                activeClassName="active"
                to="/dashboard/user-list"
              >
                Dev Build 56
              </NavLink>
            </li>
            <li className="nav-item">
             {getUserType()==="homeCareProfessional"?
             
             <div id="account-action">
             <Notifications className="icon-small" onClick={()=>this.redirectNurseNotifications()}></Notifications>
            </div>:<Dropdown {...this.state.dropdownProps} onToggle={(isOpen, event) => this.dropdownClickHandler(isOpen, event)} className="valeo-dropdown no-icon" alignRight>
                <Dropdown.Toggle variant="secondary" id="account-action">
                  <Notifications className="icon-small"></Notifications>
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ maxHeight: "50vh", minWidth: "400px", overflowY: "scroll" }}>
                  <Dropdown.Header>Notifications</Dropdown.Header>
                  {this.state.notifications.length > 0 && this.state.notifications.map((item) => {
                    return (
                      <div key={item.data.order_id}>
                        {item.data.notification_type == 'order' ? (
                          item.data.order_status == "completed" ? (
                            <Dropdown.Item onClick={() => this.props.history.push({ pathname: "/dashboard/orders/past", state: this.state.notifications })}>
                              <div className="notification">
                                <div className="notification-bell">
                                  <img src={Notification} alt="notification"></img>
                                </div>
                                <div className="notification-detail">
                                  <div>
                                    <p>order id: {item.data.order_id}</p>
                                  </div>
                                  <div>Order status have been changed to {'"' + (item.data.order_status).toLowerCase() + '"'}
                                  </div>
                                  <div>
                                    <p>
                                      {moment(item.created_at).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item onClick={() => this.props.history.push({ pathname: "/dashboard/orders/active", state: this.state.notifications })}>
                              <div className="notification">
                                <div className="notification-bell">
                                  <img src={Notification} alt="notification"></img>
                                </div>
                                <div className="notification-detail">
                                  <div>
                                    <p>order id: {item.data.order_id}</p>
                                  </div>
                                  {
                                    item.data.order_status.toLowerCase() === "created" ? (
                                      <div>A new order has been created</div>
                                    ) : (
                                      <div>Order status have been changed to {'"' + (item.data.order_status).toLowerCase() + '"'}
                                      </div>
                                    )
                                  }
                                  <div>
                                    <p>
                                      {moment(item.created_at).fromNow()}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Dropdown.Item>
                          )
                        ) : (
                          <Dropdown.Item key={item.data.user_id} onClick={() => this.props.history.push({ pathname: "/dashboard/users/clients", state: this.state.notifications })}>
                            <div className="notification">
                              <div className="notification-bell">
                                <img src={Notification} alt="notification"></img>
                              </div>
                              <div className="notification-detail">
                                <div>
                                  <p>user id: {item.data.user_id}</p>
                                </div>
                                <div>
                                  {
                                    item.data.notification_type === 'new_user' && 'New user has been created with id "' +
                                    (item.data.user_email ? item.data.user_email.toLowerCase() : "") + 
                                    '"'
                                  }
                                </div>
                                <div>
                                  <p>
                                    {moment(item.created_at).fromNow()}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>
                        )
                        }
                        <Dropdown.Divider />
                      </div>
                    )
                  })}
                  {
                    this.state.nextPage &&
                    <Dropdown.Item id='load-more-btn' style={{ color: 'blue', textDecoration: 'underline' }}> See more </Dropdown.Item>
                  }
                  {
                    this.state.notifications.length == 0 &&
                    <Dropdown.Item> There are no new notifications</Dropdown.Item>
                  }
                </Dropdown.Menu>
              </Dropdown>}
            </li>
            <li className="nav-item">
              <Dropdown className="valeo-dropdown no-icon" alignRight>
                <Dropdown.Toggle variant="secondary" id="account-action">
                  {userName}
                  <AccountCircleIcon className="icon"></AccountCircleIcon>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Header>My Account</Dropdown.Header>
                  {getUserType() == 'nutritionist' &&
                    <Dropdown.Item onClick={() => this.props.history.push("/dashboard/users/nutritionists/details")}>
                      My Profile
                    </Dropdown.Item>
                  }

                  <Dropdown.Item onClick={this.showModal}>
                    Change Password
                  </Dropdown.Item>
                  <Dropdown.Item href="/admin/login" onClick={()=>this.logoutFcmToken()}>Log Out</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.changePassword()}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
