import React, { useEffect, useState } from "react";
import _ from "lodash";
import API from "utils/API";

const BASE_URL_GENERIC_SURVEY_RESULTS = "generic-survey-answer-admin/";
const COLOR_LIGHT_GREY = "#D3D3D3";
const COLOR_LIGHT_YELLOW = "#FFFFE0";
const COLOR_YELLOW = "#FFFF00";
const COLOR_ORANGE = "#FFA500";
const COLOR_RED = "#FF0000";

function GenericSurveyResultsCard({ order, genericSurveyAnswers }) {
  const [results, setResults] = useState([]);

  // useEffect(() => fetchGenericSurveyResults(order), [order]);

  useEffect(()=>{
    if(genericSurveyAnswers && genericSurveyAnswers.length>0){
      const filtered = genericSurveyAnswers.filter(
        (it) => it.answer_id ?? false
      );
      console.log("genericSurveyResults", filtered)
      setResults(
        _.orderBy(
          filtered,
          ["point_scale", "category_name", "question"],
          ["desc", "asc", "asc"]
        )
      );
    }
  },[order])

  const getBackgroundColor = (pointScale) => {
    switch (pointScale) {
      case 0:
        return COLOR_LIGHT_GREY;
      case 1:
        return COLOR_LIGHT_YELLOW;
      case 2:
        return COLOR_YELLOW;
      case 3:
        return COLOR_ORANGE;
      case 4:
        return COLOR_RED;
      default:
        return "";
    }
  };

  const fetchGenericSurveyResults = (order) => {
    const orderId = order?.id;
    const userId = order?.user?.id;
    const surveyId = order?.survey?.id;
    if (!orderId || !userId || !surveyId) {
      console.log(
        "GenericSurveyResultsCard:: Can not fetch generic survey results, invalid input data",
        {
          orderId,
          userId,
          surveyId,
        }
      );
      return;
    }

    console.log("GenericSurveyResultsCard:: fetchGenericSurveyResults: ", {
      orderId,
      userId,
      surveyId,
    });
    const url = `${BASE_URL_GENERIC_SURVEY_RESULTS}?user=${userId}&survey=${surveyId}&order=${orderId}`;
    API.get(url)
      .then((response) => {
        const { data: genericSurveyResults = [] } = response;
        const filtered = genericSurveyResults.filter(
          (it) => it.answer_id ?? false
        );
        console.log("genericSurveyResults", filtered)
        setResults(
          _.orderBy(
            filtered,
            ["point_scale", "category_name", "question"],
            ["desc", "asc", "asc"]
          )
        );
      })
      .catch((error) =>
        console.log(
          "Error on fetching generic survey results from: ",
          url,
          error
        )
      );
  };

  const renderedSymptoms = (
    <div
      className="description"
      style={{
        maxHeight: "50vh",
        overflowX: "auto",
        overflowY: "auto",
        whiteSpace: "nowrap",
      }}
    >
      <table className="ui celled table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Category</th>
            <th>Answer</th>
          </tr>
        </thead>
        <tbody>
          {results.map((it) => (
            <>
              <tr key={it.id}>
                <td data-label="question">{it.question || ""}</td>
                <td data-label="category">{it.category_name || ""}</td>
                <td
                  data-label="answer"
                  style={{
                    backgroundColor: getBackgroundColor(it.point_scale),
                  }}
                >
                  {it.answer ?? ""}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );

  console.log("Results: ", results);

  return (
    <div
      className="ui raised card"
      style={{ minWidth: "100%", maxWidth: "100%" }}
    >
      <div className="content">
        <div className="font-weight-bold mb-2">
          Symptoms (sorted from most severe)
        </div>
        {results && results.length ? renderedSymptoms : "No symptoms found!"}
      </div>
    </div>
  );
}

export default GenericSurveyResultsCard;
