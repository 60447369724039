import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import { CircularProgress } from "@material-ui/core";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class CustomSurveyResults extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "surveyId",
        method: "isEmpty",
        validWhen: false,
        message: "Survey is required."
      }
    ]);

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Category",
        maxWidth: "250px",
        sortable: true,
        selector: "category_name"
      },
      {
        name: "Answer",
        selector: "answer",
        sortable: true,
        maxWidth: "300px"
      },
      {
        name: "Point Scale",
        selector: "point_scale",
        sortable: true,
        maxWidth: "120px",
        center: true
      }
    ];

    this.state = {
      answers: [],
      isLoaded: false,
      loadError: "",
      answersLoading: false,
      answersLoaded: false,
      answersLoadError: "",
      users: [],
      userId: "",
      surveyId: "",
      surveys: [],
      validation: this.validator.valid()
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  loadData() {
    this.loadUsers();
  }

  loadUsers() {
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("list-users/")
      .then(response => {
        this.setState(
          {
            users: response.data
          },
          () => {
            this.loadSurveys();
          }
        );
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  loadSurveys() {
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("customized-survey-list/")
      .then(response => {
        const activeList = filterList(response.data, "active");

        let surveyListFormatted = activeList.map(survey => {
          return {
            text: survey.name + " (" + this.getUserName(survey.user) + ")",
            value: survey.id,
            key: survey.id,
            user: survey.user
          };
        });

        this.props.toggleLoading({
          isLoading: false
        });
        this.setState({
          surveys: surveyListFormatted,
          isLoaded: true
        });
      })
      .catch(error => {
        console.log("Error on fetching surbeys", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  getUserId(surveyId) {
    const surveys = this.state.surveys;

    if (surveys.length > 0 && surveyId !== null) {
      let survey = surveys.find(item => {
        return item.key === surveyId;
      });

      return survey.user;
    }

    return null;
  }

  getUserName(id) {
    const users = this.state.users;

    if (users.length > 0 && id !== null) {
      let user = users.find(item => {
        return item.id === id;
      });

      if (user) return user.username;
    }

    return "";
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let surveyId = this.state.surveyId;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      let userId = this.getUserId(surveyId);

      this.setState({
        answersLoading: true,
        answers: [],
        answersLoaded: false,
        answersLoadError: ""
      });

      API.get("customised-survey-answer-admin/", {
        params: {
          user: userId,
          survey: surveyId
        }
      })
        .then(response => {
          const activeList = filterList(response.data, "active");

          for (let item of activeList) {
            if (!item.answer) item.answer = "-";
            if (!item.point_scale) item.point_scale = "-";
          }

          this.setState({
            answers: activeList,
            isLoaded: true,
            answersLoading: false,
            answersLoaded: true
          });
          this.submitted = false;
        })
        .catch(error => {
          console.log("Error on fetching data", error);
          this.setState({
            answersLoading: false,
            answersLoadError: "Some error has occured. Please try again"
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });
        });
    }
  }

  render() {
    let answerList, form;
    const answers = this.state.answers;
    const isLoaded = this.state.isLoaded;
    const answersLoading = this.state.answersLoading;
    const answersLoaded = this.state.answersLoaded;
    const loadError = this.state.loadError;
    const answersLoadError = this.state.answersLoadError;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      form = (
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group input-section input-section-long">
              <Dropdown
                className={
                  "form-control " +
                  (validation.surveyId.isInvalid && "has-error")
                }
                options={this.state.surveys}
                search
                selection
                value={this.state.surveyId}
                placeholder="Select survey"
                onChange={(event, value) => {
                  this.setState({ surveyId: value.value });
                }}
              />
              <span className="help-block">{validation.surveyId.message}</span>
            </div>
            <div className="form-group">
              <button type="submit" className="btn button button-green">
                View Answers
              </button>
            </div>
          </div>
        </form>
      );
    } else {
      if (loadError) form = <p>{loadError}</p>;
      else form = null;
    }

    if (answersLoaded || answersLoadError) {
      answerList = (
        <Card className="answer-list">
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={answers}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={true}
            title=""
            noDataComponent={
              answersLoadError ? (
                <AlertBox message={answersLoadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      );
    } else if (answersLoading) {
      answerList = (
        <CircularProgress size={30} className="valeo-loader"></CircularProgress>
      );
    } else {
      answerList = <p>Select a survey to view the results.</p>;
    }

    return (
      <div className="answers">
        <h5 className="mb-4">Customized Health Assessment Results</h5>
        <h6 className="mb-3">Select Health Assessment *</h6>
        {form}
        <h6 className="mb-3 mt-4">Health Assessment Answers</h6>
        {answerList}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSurveyResults);
