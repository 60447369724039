import React, { useState } from "react";

function SearchBar({ placeholder = "Search...", onSubmit = (f) => f }) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(searchTerm);
      }}
    >
      <div class="input-group mb-3">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-control"
          placeholder="Search by Email"
        />
        <div class="input-group-append">
          <i
            className="fa fa-search input-group-text"
            onClick={() => onSubmit(searchTerm)}
          />
        </div>
      </div>
    </form>
  );
}

export default SearchBar;
