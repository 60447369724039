export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const MANAGE_MARKERS = "MANAGE_MARKERS";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT";
export const SHOW_MESSAGES = "SHOW_MESSAGES";

export const Page = Object.freeze({
  BUNDLES: "Bundles",
  CONDITION_CATEGORIES: "Conditions/Treatment Plans",
});
