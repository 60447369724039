import React from "react";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
const BASE_URL_PROFESSIONALS = "/home-care-professional";
const BASE_URL_BOOKINGS = "/list-home-care-order-details/homecare";
export async function fetchProfessionals(homecareServiceId) {
  // this.showLoading();
  // const { user } = this.state;
  if (!homecareServiceId.homecareServiceId) {
    //  this.showErrorNotification("Invalid user or homecare service id!");
    return;
  }

  try {
    const url = `${BASE_URL_PROFESSIONALS}/homecareId/${homecareServiceId}`;
    const { data: professionals = [] } = await PhoenixAPI.get(url);
    // setProfessionals([...professionals] );
    return professionals;
  } catch (error) {
    console.log("Error on fetching professionals!", error);
    // this.showErrorNotification(
    // "Error on fetching professionals: ",
    //error.message
    //);
  } finally {
    //this.hideLoading();
  }
}

export const fetchBookings = async (startDate, endDate, homecareServiceId) => {
  console.log("get orders", homecareServiceId);
  const url = `${BASE_URL_BOOKINGS}/${homecareServiceId}`;
  const params = {};

  if (startDate) params.fromDate = moment(startDate).format("YYYY-MM-DD");
  if (endDate) params.toDate = moment(endDate).format("YYYY-MM-DD");
  try {
    const { data: bookings = [] } = await PhoenixAPI.get(url, { params });
    console.log("bookings in services", bookings);
    return bookings;
  } catch (error) {
    console.log("Error on fetching medicines", error);
    throw error;
  }
};
