import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { Card } from "react-bootstrap";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { formatDate } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_COUPON_TRANSACTIONS = "coupon-transaction/";
const COLUMNS = [
  {
    name: "Date",
    selector: "date",
    sortable: true,
    maxWidth: "150px",
    format: (transaction) =>
      transaction.date ? formatDate(transaction.date) : "",
  },
  {
    name: "Cart Order ID",
    selector: "cartOrderId",
    sortable: true,
    format: (transaction) => transaction.cartOrderId ?? "",
    wrap: true,
  },
  {
    name: "Order ID",
    selector: "orderId",
    sortable: true,
    wrap: true,
  },
  {
    name: "Package",
    selector: "packages",
    sortable: true,
    wrap: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
    wrap: true,
    format: (transaction) => transaction.email ?? "",
  },
];
const DEFAULT_ROWS_PER_PAGE = 20;
const ROWS_PER_PAGE_OPTIONS = [20, 25, 50, 100, 200];

function TransactionsTable({ couponId }) {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (couponId === null || couponId === undefined) return;
      const url = `${BASE_URL_COUPON_TRANSACTIONS}${couponId}`;
      try {
        const { data: transactions } = await PhoenixAPI.get(url);
        setTransactions(transactions ?? []);
      } catch (error) {
        console.log(
          "Error on fetching coupon transactions from this URL",
          url,
          error
        );
      }
    };
    fetchTransactions();
  }, [couponId]);

  return (
    <Card>
      <DataTable
        highlightOnHover
        columns={COLUMNS}
        data={transactions || []}
        // pagination
        // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
        // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}                
        responsive
        defaultSortField="date"
        defaultSortAsc={false}
        sortIcon={<ArrowDownward></ArrowDownward>}
      />
    </Card>
  );
}

TransactionsTable.propTypes = {
  couponId: PropTypes.number.isRequired,
};

export default TransactionsTable;
