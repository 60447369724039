import React, { Component } from "react";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import _ from "lodash";
import { filterList } from "utils/List";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";


const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_CITIES = "cities/";
const BASE_URL_BLOOD_PACKAGES = "active-packages/";
const BASE_URL_CUSTOM_PACKAGES = "packages/";
const BASE_URL_ADDON_PACKAGES = "user-add-on-packages/";
const BASE_URL_SLOT_AVAILABILITY = "slot-availability/";

class LabDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      cities: [],
      selectedCity: null,
      packages: [],
      subscription_packages: [],
      add_on_packages: [],
      custom_packages: [],
      packageType: null,
      labslotData: {},
      selectedDate: new Date().toISOString().substr(0, 10),
      slotResults: [],
    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.getCountry();
    this.getCities();
    this.loadPackages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.createCityOptions();
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.LAB_AVAILABILITY_DASHBOARD;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  createCountryOptions = () => {
    const { countries = [] } = this.state;
    const isActiveCountry = (country) => (country ? country.is_active : false);
    const isInactiveCountry = (country) =>
      country ? !country.is_active : false;
    let activeCountries = countries && countries.filter(isActiveCountry);

    let inactiveCountries = countries && countries.filter(isInactiveCountry);

    const countryOptions = [
      {
        label: "Active",
        options: activeCountries.map(this.createCountryOption),
      },
      {
        label: "Inactive",
        options: inactiveCountries.map(this.createCountryOption),
      },
    ];
    this.setState({ countryOptions });
  };
  createCountryOption = (country) => {
    return {
      key: country.id,
      value: country.id,
      label: country.country_name,
    };
  };

  findCountry = (countryId) => {
    const { countries } = this.state;
    return countries.find((it) => it.id === countryId);
  };

  createCityOptions = () => {
    const { cities = [], selectedCountry } = this.state;
    if (!selectedCountry)
      return;

    const isActiveCity = (city) => (city ? city.is_active : false);
    const isInactiveCity = (city) => (city ? !city.is_active : false);

    const citiesForSelectedCountry = cities.filter(it => it.country?.id === selectedCountry.id);
    let activeCities = citiesForSelectedCountry && citiesForSelectedCountry.filter(isActiveCity);
    let inactiveCities = citiesForSelectedCountry && citiesForSelectedCountry.filter(isInactiveCity);

    const cityOptions = [
      {
        label: "Active",
        options: activeCities.map(this.createCityOption),
      },
      {
        label: "Inactive",
        options: inactiveCities.map(this.createCityOption),
      },
    ];
    this.setState({ cityOptions, selectedCity: null });
  };

  createCityOption = (city) => {
    return {
      key: city.id,
      value: city.id,
      label: city.city_name,
    };
  };

  findCity = (cityId) => {
    const { cities } = this.state;
    return cities.find((it) => it.id === cityId);
  };

  getCountry = async () => {
    try {
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      const selectedCountry =
        this.state.selectedCountry &&
        countries.find((it) => it.id === this.state.selectedCountry.id);
      this.setState(
        { countries, selectedCountry },
        () => this.createCountryOptions(),
        this.updatePackagesListForSelectedCountry()
      );
    } catch (error) {
      console.log("Error on fetching countries", error.message);
    }
  };

  getCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      const selectedCity =
        this.state.selectedCity &&
        cities.find((it) => it.id === this.selectedCity.id);
      this.setState({ cities, selectedCity }, () => this.createCityOptions());
    } catch (error) {
      console.log("Error on fetching cities:", error.message);
    }
  };

  updatePackagesListForSelectedCountry = () => {
    const {
      customPackages = [],
      addOn_packages = [],
      subscription_package = [],
      miniPackages = [],
      selectedCountry,
    } = this.state;

    const countryFilter = (p) =>
      p && selectedCountry ? p.country === selectedCountry.id : false;

    const custom_packages = customPackages
      .filter((it) => it.status === "Active")
      .filter(countryFilter)
      .map((it) => ({
        label: it.name,
        value: "custom" + it.id,
        id: it.id,
        type: "custom",
      }));

    const add_on_packages = addOn_packages
      .filter((it) => it.is_active)
      .filter(countryFilter)
      .map((it) => ({
        label: it.addon_name,
        value: "add-ons" + it.id,
        id: it.id,
        type: "add-ons",
      }));

    const subscription_packages = subscription_package
      .filter((it) => it.is_active)
      .filter(countryFilter)
      .map((it) => ({
        label: it.subscription_name,
        value: "packages" + it.id,
        id: it.id,
        type: "packages",
      }));

    const mini_packages = miniPackages
      .filter((it) => it.isActive ?? false)
      .filter(it => selectedCountry ? it.countryId === selectedCountry.id : false)
      .map((it) => ({
        label: it.name,
        value: "MiniPackage" + it.id,
        id: it.id,
        type: "MiniPackage",
      }));

    this.setState({ custom_packages, add_on_packages, subscription_packages, mini_packages });
  };

  loadPackages() {
    let packages = [];
    let addons = [];
    // API.get("subscription-packages/")
    API.get(BASE_URL_BLOOD_PACKAGES)
      .then((response) => {
        let products = [];
        const data = filterList(response.data, "active");
        data.map((val) =>
          products.push({
            label: val.subscription_name,
            value: "packages" + val.id,
            id: val.id,
            type: "packages",
          })
        );
        let packages = [...this.state.packages, ...products];
        this.setState(
          {
            // subscription_packages: products,
            packages: packages,
            subscription_package: response.data,
          },
          () => this.updatePackagesListForSelectedCountry()
        );
      })
      .catch((error) => {
        console.log("Error on fetching active packages", error);
      });
    // API.get("add-on-packages/")
    API.get(BASE_URL_ADDON_PACKAGES)
      .then((response) => {
        let products = [];
        const data = filterList(
          response.data ? response.data.addon_plans : [],
          "active"
        );
        addons = response.data ? response.data.addon_plans : [];
        data.map((val) =>
          products.push({
            label: val.addon_name,
            value: "add-ons" + val.id,
            id: val.id,
            type: "add-ons",
          })
        );
        let packages = [...this.state.packages, ...products];
        this.setState(
          {
            // add_on_packages: products,
            packages: packages,
            addOn_packages: response.data ? response.data.addon_plans : [],
          },
          () => this.updatePackagesListForSelectedCountry()
        );
      })
      .catch((error) => {
        console.log("Error on fetching packages", error);
      });

    API.get(BASE_URL_CUSTOM_PACKAGES)
      .then((response) => {
        let products = [];
        const data = response.data;
        filterList(response.data, "active");
        data.map((val) =>
          products.push({
            label: val.name,
            value: "custom" + val.id,
            id: val.id,
            type: "custom",
          })
        );
        let packages = [...this.state.packages, ...products];
        this.setState(
          {
            // custom_packages: products,
            packages: packages,
            customPackages: response.data,
          },
          () => this.updatePackagesListForSelectedCountry()
        );
      })
      .catch((error) => {
        console.log("Error on fecthing packages", error);
      });

      PhoenixAPI.get("/api/v1/mini-packages")
      .then((response) => {
        let products = [];
        let data = response.data;
        data = data.filter(it => it.isActive ?? false);
        data.map((val) =>
          products.push({
            label: val.name,
            value: "MiniPackage" + val.id,
            id: val.id,
            type: "MiniPackage",
          })
        );
        let packages = [...this.state.packages, ...products];
        this.setState(
          {
            packages: packages,
            miniPackages: response.data,
          },
          () => this.updatePackagesListForSelectedCountry()
        );
      })
      .catch((error) => {
        console.log("Error on fecthing mini packages", error);
      });
  }

  handleSelectWeek = (e) => {
    let selectedDate = e.target.value;
    this.setState({ selectedDate });
  };

  handlePackage = (e) => {
    let index = this.state.packages.findIndex(
      (it) => it.value === e.target.value
    );
    let packagess = this.state.packages[index];
    let type = packagess.type;
    this.setState({ packageType: type });
    let id = packagess.id;
    if (type === "packages") {
      let index = this.state.subscription_package.findIndex(
        (it) => it.id === id
      );
      this.setState({
        labslotData: {
          package: this.state.subscription_package[index],
          package_id: type + id,
          package_type: type,
          packageId: id,
        },
      });
    } else if (type === "add-ons") {
      let index = this.state.addOn_packages.findIndex((it) => it.id == id);
      this.setState({
        labslotData: {
          ...this.state.labslotData,
          add_on: this.state.addOn_packages[index],
          package_id: type + id,
          package_type: type,
          packageId: id,
        },
      });
    } else if (type === "custom") {
      let index = this.state.customPackages.findIndex((user) => user.id == id);
      this.setState({
        labslotData: {
          ...this.state.labslotData,
          custom_package: this.state.customPackages[index],
          package_id: type + id,
          package_type: type,
          packageId: id,
        },
      });
    } else if (type === "MiniPackage") {
      const miniPackages = this.state.miniPackages ?? [];
      let index = miniPackages.findIndex((it) => it.id === id);
      this.setState({
        labslotData: {
          ...this.state.labslotData,
          mini_package: miniPackages[index],
          package_id: type + id,
          package_type: type,
          packageId: id,
        },
      });
    }
  };

  handleSave = () => {
    this.showLoading();
    const {
      selectedCountry,
      selectedCity,
      selectedDate,
      labslotData,
      packageType,
    } = this.state;

    let countryId = selectedCountry && selectedCountry.id;
    let cityId = selectedCity && selectedCity.id;
    let date = selectedDate;
    let packageName = packageType ?? "";
    let packageId = labslotData && labslotData.packageId;
    // console.log("packageId", packageId);
    if (packageName === "packages") {
      packageName = "Blood";
    }
    if (packageName === "add-ons") {
      packageName = "Health";
    }

    if (packageName === "custom") {
      packageName = "Custom";
    }

    let payload = {
      countryId: countryId,
      cityId: cityId,
      packageName: packageName,
      packageId: packageId,
      date: date,
    };
    PhoenixAPI.post(BASE_URL_SLOT_AVAILABILITY, payload)
      .then((response) => {
        let data = (response && response.data) ?? [];
        this.hideLoading();
        const results = [];
        data.forEach((daySlots) => {
          daySlots.forEach((daySlot) => {
            const result = results.find((it) => it.timeslot === daySlot.slot);
            if (result) {
              result[daySlot.date] = {
                overall: daySlot.overall,
                available: daySlot.available,
              };
            } else {
              results.push({
                timeslot: daySlot.slot,
                [daySlot.date]: {
                  overall: daySlot.overall,
                  available: daySlot.available,
                },
              });
            }
          });
        });
        //created this variable to show notification
        const filteredResults =
          results && results.length
            ? results.filter((it) => it.timeslot ?? false)
            : [];
        if (filteredResults.length === 0) {
          this.showErrorNotification(
            "There are no Available Lab Slots for this Date"
          );
        }

        this.setState({ slotResults: results }, () => {
          this.createTableColumns();
        });
      })
      .catch((error) => {
        console.log("Error on getting lab slots", error, error.message);
        this.showErrorNotification("Error in fetching Lab Slots");
      });
  };

  getSlotDates = (slotResults = []) => {
    console.log("getSlotDates: slotResults", slotResults);
    const dates = new Set();
    slotResults.forEach((slotResult) => {
      const keys = slotResult ? Object.keys(slotResult) : [];
      keys.filter((k) => k !== "timeslot").forEach((k) => dates.add(k));
    });
    const datesArray = Array.from(dates);
    console.log("getSlotDates: datesArray: ", datesArray);
    return datesArray;
  };

  rangeOfDates = (startDate, endDate, isEndInclusive = true) => {
    console.log("start and end date in fun",startDate,endDate);
    let currentDate = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    let endDateFormatted = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    if (!currentDate || !endDateFormatted) return [];
   
    let result = [];
    
    while (moment(currentDate).diff(endDateFormatted, "days") <= 0) {
     
      result.push(currentDate);
      currentDate = moment(currentDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
    }
   
    return result;
  };

  createTableColumns = () => {
    const { slotResults = [] } = this.state;

    const dates = this.getSlotDates(slotResults);
   
    const dateObjects = dates.map((date) => moment(date));
     
    const minDate = Math.min(...dateObjects);
    const maxDate = Math.max(...dateObjects);
    const startDate = moment(minDate).format("YYYY-MM-DD");
    const endDate = moment(maxDate).format("YYYY-MM-DD");

    const dateRange = this.rangeOfDates(startDate, endDate);
   
    while (dateRange.length < 7) {
      dateRange.push(
        moment(dateRange[dateRange.length - 1])
          .add(1, "days")
          .format("YYYY-MM-DD")
      );
    }
   
    let columns = [];
    const max = Math.max(...slotResults.map((it) => Object.keys(it).length));

    const d = slotResults.find((it) => Object.keys(it).length === max);
    const dKeys = d ? Object.keys(d) : [];
    const dNew = d ? { timeslot: d.timeSlot } : null;
  

    if (dNew) {
      dateRange.forEach(
        (date) => (dNew[date] = dKeys.includes(date) ? d[date] : {})
      );
      const dates = Object.keys(dNew);
     
      columns = dates.map((it) => {
        if (it === "timeslot") {
          return {
            name: "Time Slot",
            selector: "timeslot",
            sortable: true,
            format: (data) => this.getFormattedTimeSlot(data.timeslot),
            width: "200px",
          };
        }  if(it !=="timeslot") {
          return {
            name: this.getFormattedDate(it),
            selector: `${it}.available`,
            sortable: true,
            format: (data) => {
             
              return data && data[it] && data[it].available && data[it].overall ? (

                <div
                  style={
                    data[it].available === data[it].overall
                      ? { color: "green" }
                      : data[it].available <= 0
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {data[it].available}/{data[it].overall}
                </div>
              ) : (
                "N/A"
              );
            },
          };
        }
      });
      this.setState({ columns: columns ?? [] });
    }
  };

  getFormattedDate = (date) => {
    return moment(date).format("dddd (MMM DD, YYYY)") ?? "";
  };

  getFormattedTimeSlot = (timeSlot) => {
    const FORMAT_24_HOUR = "HH:mm";
    const FORMAT_12_HOUR = "hh:mm A";

    try {
      const [startTime24HourFormat, endTime24HourFormat] = timeSlot.split("-");
      const startTime12HourFormat = moment(
        startTime24HourFormat,
        FORMAT_24_HOUR
      ).format(FORMAT_12_HOUR);
      const endTime12HourFormat = moment(
        endTime24HourFormat,
        FORMAT_24_HOUR
      ).format(FORMAT_12_HOUR);
      return `${startTime12HourFormat} to ${endTime12HourFormat}`;
    } catch (error) {
      console.log("Error formatting timeslot: ", timeSlot, ", error", error);
      return null;
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let {
      slotResults = [],
      selectedCountry,
      selectedCity,
      subscription_packages,
      add_on_packages,
      custom_packages,
      mini_packages,
      labslotData,
    } = this.state;

    const filteredSlotResults = slotResults.filter(
      (it) => it.timeslot !== null && it.timeslot !== undefined
    );

    const packageData = {
      data: [
        {
          packageName: "Blood Biomarker Packages",
          childerns: subscription_packages,
        },
        {
          packageName: "Custom packages",
          childerns: custom_packages,
        },
        {
          packageName: "Health Coach Packages",
          childerns: add_on_packages,
        },
        {
          packageName: "Mini Packages",
          childerns: mini_packages,
        },
      ],
    };

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
        <div className="row">
          <div className="form-group col-4">
            <Select
              key={`my_unique_select_key__${
                selectedCountry && selectedCountry.id
              }`}
              value={
                selectedCountry && this.createCountryOption(selectedCountry)
              }
              onChange={(event) => {
                const selectedCountry = event
                  ? this.findCountry(event.value)
                  : null;
                this.setState({ selectedCountry }, () =>
                  this.updatePackagesListForSelectedCountry()
                );
              }}
              options={this.state.countryOptions || []}
              style={selectStyles}
              placeholder="Country"
              isClearable={true}
              searchable
              lazyLoad
            />
          </div>

          <div className="form-group col-4">
            <Select
              key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
              value={selectedCity && this.createCityOption(selectedCity)}
              onChange={(event) => {
                const selectedCity = event ? this.findCity(event.value) : null;
                this.setState({ selectedCity });
              }}
              options={this.state.cityOptions || []}
              style={selectStyles}
              placeholder="City"
              isClearable={true}
              searchable
              lazyLoad
            />
          </div>
          <div className="form-group col-4">
            <select
              name="packages"
              className="py-2 form-control user-data-input"
              onChange={(e) => this.handlePackage(e)}
              value={labslotData.package_id ? labslotData.package_id : ""}
              style={{ height: "37px" }}
            >
              <option key={-1} value={-1} hidden>
                Package
              </option>
              {packageData.data &&
                packageData.data.length &&
                packageData.data.map(({ packageName, childerns }, index) => (
                  <optgroup label={packageName} key={index}>
                    {childerns &&
                      childerns.length &&
                      childerns.map((status, id) => (
                        <option
                          key={status.type + status.value}
                          value={status.value}
                          name={status.type}
                        >
                          {status.label}
                        </option>
                      ))}
                  </optgroup>
                ))}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-4">
            <label>Week</label>
            <div className="input-group user-input-container">
              <input
                type="date"
                name="week"
                onChange={(e) => this.handleSelectWeek(e)}
                className="form-control user-data-input py-3"
                defaultValue={new Date().toISOString().substr(0, 10)}
              />
            </div>
          </div>
        </div>
        <button
          className="btn px-4 mt-1 mb-3"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => this.handleSave()}
        >
          Show Available Lab Slots{" "}
        </button>
        <Card body>
          <DataTable
            data={filteredSlotResults ?? []}
            columns={this.state.columns ?? []}
            defaultSortField="id"
            defaultSortAsc={false}
            highlightOnHover
            responsive
            title={
              <p className="table-header">{`Found ${filteredSlotResults.length} results.`}</p>
            }
          />
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabDashboard);
