import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class FoodItemsTable extends Component {
  constructor(props) {
    super(props);
    const {
      foodItems = [],
      foodCategory = "",
      userType = "",
      isPastOrder = false,
      removeFoodItem=(f)=>f,
      addScore=(f)=>f,
      customerPriorities=(f)=>f,
      highLimit=null,
      mediumLimit=null,
      lowLimit=null
    } = this.props || {};
    
    this.addScore=addScore;
    this.removeFoodItem = removeFoodItem;
    this.customerPriorities=customerPriorities;
    this.state = {
      foodItems,
      isEditable: true,
      foodCategory,
      userType,
      isPastOrder,
    };

  
  }

  handleAddScore=(event,item)=>{
    const target= event.target;
    this.addScore(target.value,item)
  }
  handleRemoveFooditem=(event,item)=>{
    const target=event.target
    this.removeFoodItem(target.checked,item)
  }

  handleCustomerPriority=(e,item)=>{
    this.customerPriorities(e,item)
  }

  returnRange = (item) => {
    
    const { flag = "", result } = item || {};
    
    if (flag === "HIGH") {
      return (
        <div className="food-rating">
          <div className="high"></div>
          <div className="high"></div>
          <div className="high"></div>
        </div>
      );
    } else if (flag === "MEDIUM") {
      return (
        <div className="food-rating">
          <div className="medium"></div>
          <div className="medium"></div>
        </div>
      );
    } else if (flag === "LOW") {
      return (
        <div className="food-rating">
          <div className="low"></div>
        </div>
      );
    } else {
      return "null";
    }
  };
  render() {
    const { foodItems, foodCategory, userType, isPastOrder } = this.state;


    return (
      <div className="manage-package">
        <Card body>
          {foodItems && foodItems.length > 0 ? (
            <table className="foodItem-table">
              <thead className="foodItem-head">
                <tr>
                  <th>Food Name</th>
                  <th>Category Name</th>
                  <th>Score</th>

                  <th style={{ textAlign: "center" }}>Unit</th>
                  <th style={{ textAlign: "center" }}>Rating</th>
                  {userType==="lab"?<th style={{ textAlign: "center" }}>{isPastOrder?"Customer Priority":"Remove for Client on App"}</th>:<th style={{ textAlign: "center" }}>{isPastOrder?"Customer Priority":userType==="coach"? "Customer Priority":"Remove for Client on App"}</th>}
                </tr>
              </thead>
              <tbody className="foodItem-body">
                {foodItems ? (
                  foodItems.map((item) => (
                    
                    <tr key={item.categoryId} className="foodItem-row">
                      <td>{item.foodName ?? ""}</td>
                      <td>{foodCategory}</td>
                      {/* <td>{item.result ?? "Range not found"}</td> */}
                      <td>
                      <input
                         
                          key={item.id}
                          value={item.result ??""}
                          className="form-control"

                          readOnly={userType!=="lab"
                              ? true
                              : false
                          } 
                          type="number"
                          name="marker-result"
                          onChange={userType!=="lab" || isPastOrder?"":(e) =>
                            this.handleAddScore(e,item)
                          }
                          step="any"
                          min={0}
                          placeholder="Score"
                          disabled={isPastOrder}
                          
                        ></input>
                      </td>
                      <td>{item.unit ?? "NA"}</td>
                      <td>{this.returnRange(item)}</td>
                     {userType==="coach"?
                     
                     <td style={{ "text-align": "center" }}>
                       <input
                          type="checkbox"
                          key={item.id}
                          checked={item.customerPriority}
                          className="larger-checkbox"

        
                          onChange={isPastOrder?"":(e) =>
                            this.handleCustomerPriority(e,item)
                          }
                          disabled={isPastOrder}
                        />
                      </td>
                     
                     :<td style={{ "text-align": "center" }}>
                       <input
                          type="checkbox"
                          key={item.id}
                          checked={item.isDelete}
                          className="larger-checkbox"

                          readOnly={userType!=="lab"
                              ? true
                              : false
                          }
                          onChange={userType!=="lab" || isPastOrder?"":(e) =>
                            this.handleRemoveFooditem(e,item)
                          }
                          disabled={isPastOrder}
                        />
                      </td>}
                    </tr>
                  ))
                ) : (
                  <span className="help-block ml-4 mt-2 mb-3"></span>
                )}
              </tbody>
            </table>
          ) : (
            <AlertBox message="There's nothing here."></AlertBox>
          )}
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodItemsTable);
