import React, { Component } from "react";
import Search from "components/Search/Search";
import CountryDropdown from "components/Common/CountryDropdown";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL_HOW_DO_YOU_HEAR = "how-know-about-us";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class HowDoYouHear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      options: [],
      option: {},
      rankOptions: [],
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      isOptionViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Name",
        selector: "nameEnglish",
        sortable: true,
        wrap: true,
      },
      {
        name: "Name (Arabic)",
        selector: "nameArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (option) => (
          <p style={{ textAlign: "right" }}>{option.nameArabic}</p>
        ),
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.status === "Active" ? true : false} />,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        sortable: true,
        center: true,
        width: "100px",
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (referralCode) => this.renderViewButton(referralCode.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchHowDoYouHear();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HOW_DO_YOU_HEAR_ABOUT_US;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchHowDoYouHear = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_HOW_DO_YOU_HEAR);
      const options = (response && response.data) || [];
      this.setState({ options });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching How Do You Hear About Us: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleChange = ({ currentTarget: input }) => {
    const option = { ...this.state.option };
    option[input.name] = input.value;
    this.setState({ option });
  };

  createRankOptions = () => {
    const { isNewOption } = this.state;
    const options = this.getOptionsForCurrentCountry();
    const ranks = options.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewOption ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    // console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  handleAddNewOption = () => {
    this.setState({ isNewOption: true }, () => this.showOptionViewModal());
  };

  resetForm = () => {
    this.setState({
      option: {},
      errors: {},
      editables: {},
    });
  };

  handlesaveOption = async () => {
    const { selectedCountry, option } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = option.id !== null && option.id !== undefined;
      const response = isValidId
        ? await this.updateOption(option)
        : await this.saveOption(option);
      this.hideOptionViewModal();
      console.log("Response:", response);
      this.showSuccessNotification(
        isValidId
          ? "How Do You Hear About Us Updated Successfully."
          : "How Do You Hear About Us Created Successfully."
      );
      this.fetchHowDoYouHear();
    } catch (error) {
      this.showErrorNotification("Some error occurred:" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  updateOption = async (option) => {
    const payload = { ...option };
    try {
      const response = await PhoenixAPI.patch(
        `${BASE_URL_HOW_DO_YOU_HEAR}/${option.id}`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error on updating How Do You Hear About Us", error.message);
      this.showErrorNotification("Error in updating How Do You Hear About Us");
    }
  };

  saveOption = async (option) => {
    const payload = { ...option };
    try {
      const response = await PhoenixAPI.post(BASE_URL_HOW_DO_YOU_HEAR, payload);
      return response;
    } catch (error) {
      console.log("Error on creating How Do You Hear About Us", error);
      this.showErrorNotification("Error in creating How Do You Hear About Us");
    }
  };

  validate = () => {
    const { option = {} } = this.state;
    const errors = {};
    const { nameEnglish, nameArabic, rank, status } = option;

    if (status === null) errors.status = "Status is a required field!";

    if (!nameEnglish || nameEnglish.trim() === "")
      errors.nameEnglish = "Name (English) cannot be blank";
    else if (nameEnglish.length > 100)
      errors.nameEnglish = "Name (English) is limited to a 100 characters";

    if (!nameArabic || nameArabic.trim() === "")
      errors.nameArabic = "Name (Arabic) cannot be blank";
    else if (nameArabic.length > 100)
      errors.nameArabic = "Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewOption(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewOption ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleViewOption = (optionId) => {
    const options = this.state.options ?? [];
    const option = options.find((it) => it.id === optionId);
    if (!option) {
      console.log(`How Do Hear About Us with ID ${optionId} is not found!`);
      return;
    }
    this.setState({ option, isNewOption: false }, () =>
      this.showOptionViewModal()
    );
  };

  showOptionViewModal = () => {
    const { selectedCountry, isNewOption } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewOption) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const option = {
        countryId: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
      };
      this.setState({
        option,
        rankOptions,
        isOptionViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isOptionViewModalVisible: true });
    }
  };

  hideOptionViewModal = () => {
    this.setState({ isOptionViewModalVisible: false });
    this.resetForm();
  };

  getFilteredOptions = () => {
    const options = this.getOptionsForCurrentCountry();
    return this.getSearchedOptions(options);
  };

  getOptionsForCurrentCountry = () => {
    const { options, selectedCountry } = this.state;
    return options && selectedCountry
      ? options.filter((option) => option.countryId === selectedCountry.id)
      : [];
  };

  getSearchedOptions = (options) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return options;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return options.filter((option) => {
      const { id, nameEnglish, nameArabic, rank } = option;
      return (
        (nameEnglish && nameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (nameArabic && nameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      options,
      option,
      isNewOption,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      errors,
      editables,
      loadError,
      isEditable,
    } = this.state;

    const filteredOptions = this.getFilteredOptions(options) ?? [];

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="nameEnglish">Name (English)*</label>
            <div className="input-group user-input-container">
              <input
                value={option?.nameEnglish || ""}
                onChange={this.handleChange}
                id="nameEnglish"
                name="nameEnglish"
                type="text"
                placeholder="Enter name in English"
                className={
                  "form-control py-2" + (errors.nameEnglish ? " has-error" : "")
                }
                disabled={!isNewOption && !editables.nameEnglish}
              />
              {this.renderPencilIcon("nameEnglish")}
            </div>
            <span className="help-block">{errors.nameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="nameArabic">Name (Arabic)*</label>
            <div className="input-group user-input-container">
              <input
                value={option?.nameArabic || ""}
                onChange={this.handleChange}
                id="nameArabic"
                name="nameArabic"
                type="text"
                placeholder="Enter name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.nameArabic ? " has-error" : "")
                }
                disabled={!isNewOption && !editables.nameArabic}
              />
              {this.renderPencilIcon("nameArabic")}
            </div>
            <span className="help-block">{errors.nameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={option?.rank}
                onChange={(event, value) => {
                  this.setState({
                    option: { ...option, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewOption && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={option?.status}
                onChange={(event, value) => {
                  this.setState({
                    option: {
                      ...option,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewOption && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewOption
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(option?.countryId)?.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
      </div>
    );
    
    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm || ""}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewOption}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredOptions || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredOptions.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isOptionViewModalVisible}
          onHide={this.hideOptionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title"></div>

              <button
                type="submit"
                onClick={this.handlesaveOption}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_detailsform">
              <Tab eventKey="key_detailsform_" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HowDoYouHear);
