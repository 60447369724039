import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getUserId, getUserEmail,getUserName } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "../../utils/PhoenixAPI";
import "../HomecareMwebPortal/index.scss";
import { Link } from "react-router-dom";
import "./notification.scss";
import moment from "moment";
import Notification from "../../pages/HomecareMwebPortal/Notification"
import  UnResponsedNotification  from "components/Common/UnResponsedNotification";
import CustomLoader from "components/CustomLoader/CustomLoader";
import { trackNurseNotificationClick } from "analytics";

function NursePanelNotifications(props) {
  const [nurseTime, setNurseTime] = useState("");
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [orders, setOrders] = useState([]);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isNoNotification, setisNoNotification] = useState(false)

  console.log("getUerId: NursePanelNotifications", getUserId());

  const fetchNotifications = async () => {
    const nurseUserId = getUserId();
    try {
      setIsLoading(true)
      const { data = [] } = await PhoenixAPI.get(
        `nurse/not-responded-notifications/${nurseUserId}`
      );
      
      setOrders(data);
      if(data && data.length === 0) {
       setisNoNotification(true)
      }
      
    } catch (error) {
      console.log("Error occurred in fethcing notifications ", error.message)
    }
    finally{
      setIsLoading(false)
    }
  };
  useEffect(() => {
 
    fetchNotifications();
  }, []);

  const handleGoBack = () => {
    return (window.location.href = "admin/dashboard/home");
  };


  const handleSave =async (payload) => {
    console.log("Payload:", payload);
    const deviceId= sessionStorage.getItem("device_id")
   
    const trackingData={
      "nurseName":getUserName(),
      "nurseEmail":getUserEmail(),
      "nurseId":getUserId(),
      "deviceId":deviceId,
      "optionSelected":payload.selectedOption ??""
    }
     trackNurseNotificationClick(trackingData)
    try{
      const res= await PhoenixAPI.patch("nurse/on-time-response", payload)
      setIsError(false);
      setMessage("Response Saved Succesfully")
      fetchNotifications()
    }
    catch(error){
      setIsError(true);
      setMessage("Error occurred in Saving Response", error.message)
      console.log("Error in saving response", error.message)
    }
  };
  return (
    <>
     <div className="nurse-panel-main">
      <div className="top-header">
        <div className="header-content">
          <div className="content" onClick={() => handleGoBack()}>
            <div className="lt">&lt;</div>
            <div className="header-title">Notifications</div>
          </div>
        </div>
      </div>
      {!isNoNotification && <div className="new-bookings">New Bookings</div>}

      {orders &&
        orders.map((item, index) => {
          return (
            <div key={index}>
              <UnResponsedNotification client={item} handleSaveNurseResponse={(item)=>handleSave(item)}/>
            </div>
          );
        })}
       
    </div>
    {isNoNotification && <div className="no-notification-container">
      <div>
        <img
          src="https://d25uasl7utydze.cloudfront.net/assets/no-notification.svg"
      /></div>
      <div className="no-notification-txt">No Notifications yet</div>
    </div>}
    {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
    <CustomLoader show={isLoading}/>
    </>
   
  );
}

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NursePanelNotifications);
