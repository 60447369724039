import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import Card from "react-bootstrap/Card";
import Select from "react-select";
import _, { max, wrap } from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import API from "utils/API";
import { connect } from "react-redux";
import * as XLSX from 'xlsx';

const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_CITIES = "cities/";
const BASE_URL_LABSLOT_INFO="slot-availability";


class LabSlotsPackages extends Component {
    constructor(props) {
      super(props);
      this.state = {
        aclUser: null,
        pageAccessRights: null,
        accessDenied: false,
        isEditable: true,
        countries: [],
        selectedCountry: null,
        cities: [],
        selectedCity: null,
        packageType: null,
        slotResults: [],
        columns:[],
        downloadOption:false,
        
        xlsFormat: false,
        PACKAGE_TYPE_OPTIONS :[{label: "Active",options: [
            {
              key: "Blood_Package",
              value: "Blood_Package",
              label: "Blood Package",
            },
            {
                key:"Custom_Package",
                value: "Custom_Package",
                label: "Custom Package",
            }
        ]}]
      }
    }
      

      componentDidMount() {
        this.fetchAclUser();
        this.getCountry();
        this.getCities();
      }

      componentDidUpdate(prevProps, prevState) {
        const { selectedCountry } = this.state;
        if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
          this.createCityOptions();
        }
      }
    
      hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
          return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
      }
    
      fetchAclUser = async () => {
        const userId = getUserId();
          if (!userId) {
            console.log("fetchAclUser:: Invalid user id!", {userId});
            return;
          }
      
          try {
            const pageName = Page.LAB_AVAILABILITY_DASHBOARD;
            const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
            const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
            const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
            const accessDenied = isAccessDenied(aclUser, pageAccessRights);
            this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
              const isEditable = this.hasPageAccessRight("edit");
              this.setState({ isEditable });
            });
          } catch (error) {
            console.log("fetchAclUser:: Error on fetching acl user!", error);
          }
      }

      findPackageOption=(packageType)=>{
        const { PACKAGE_TYPE_OPTIONS } = this.state;
        return PACKAGE_TYPE_OPTIONS[0].options.find((types)=>types.value==packageType);
      }

      showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);
    
      showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);
    
      showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });
    
      showLoading = () => this.props.toggleLoading({ isLoading: true });
    
      hideLoading = () => this.props.toggleLoading({ isLoading: false });
    
      createCountryOptions = () => {
        const { countries = [] } = this.state;
        const isActiveCountry = (country) => (country ? country.is_active : false);
        const isInactiveCountry = (country) =>
          country ? !country.is_active : false;
        let activeCountries = countries && countries.filter(isActiveCountry);
    
        let inactiveCountries = countries && countries.filter(isInactiveCountry);
    
        const countryOptions = [
          {
            label: "Active",
            options: activeCountries.map(this.createCountryOption),
          },
          {
            label: "Inactive",
            options: inactiveCountries.map(this.createCountryOption),
          },
        ];
        this.setState({ countryOptions });
      };
      createCountryOption = (country) => {
        return {
          key: country.id,
          value: country.id,
          label: country.country_name,
        };
      };
    
      findCountry = (countryId) => {
        const { countries } = this.state;
        return countries.find((it) => it.id === countryId);
      };
    
      createCityOptions = () => {
        const { cities = [], selectedCountry } = this.state;
        if (!selectedCountry)
          return;
    
        const isActiveCity = (city) => (city ? city.is_active : false);
        const isInactiveCity = (city) => (city ? !city.is_active : false);
    
        const citiesForSelectedCountry = cities.filter(it => it.country?.id === selectedCountry.id);
        let activeCities = citiesForSelectedCountry && citiesForSelectedCountry.filter(isActiveCity);
        let inactiveCities = citiesForSelectedCountry && citiesForSelectedCountry.filter(isInactiveCity);
    
        const cityOptions = [
          {
            label: "Active",
            options: activeCities.map(this.createCityOption),
          },
          {
            label: "Inactive",
            options: inactiveCities.map(this.createCityOption),
          },
        ];
        this.setState({ cityOptions, selectedCity: null });
      };
    
      createCityOption = (city) => {
        return {
          key: city.id,
          value: city.id,
          label: city.city_name,
        };
      };
    
      findCity = (cityId) => {
        const { cities } = this.state;
        return cities.find((it) => it.id === cityId);
      };
    
      getCountry = async () => {
        try {
          const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
          const selectedCountry =
            this.state.selectedCountry &&
            countries.find((it) => it.id === this.state.selectedCountry.id);
          this.setState(
            { countries, selectedCountry },
            () => this.createCountryOptions()
            
          );
        } catch (error) {
          console.log("Error on fetching countries", error.message);
        }
      };
    
      getCities = async () => {
        try {
          const { data: cities = [] } = await API.get(BASE_URL_CITIES);
          const selectedCity =
            this.state.selectedCity &&
            cities.find((it) => it.id === this.selectedCity.id);
          this.setState({ cities, selectedCity }, () => this.createCityOptions());
        } catch (error) {
          console.log("Error on fetching cities:", error.message);
        }
      };

      createTableColumns = () => {
        const columns=[
            {
                name:"Package Name",
                selector:"packageName",
                sortable:true,
                minWidth:"250px",
                wrap:true,
            },

            {
                name:"Total Slots Booked",
                selector:"totalSlotsBooked",
                sortable:true,
                wrap:true,
                center: true,
                format: (data) => data.totalSlotsBooked==null?0:data.totalSlotsBooked
            },
            {
                name:"Total UniqueSlots Booked",
                selector:"totalUniqueSlotsBooked",
                sortable:true,
                wrap:true,
                center: true,
                format: (data) => data.totalUniqueSlotsBooked==null?0:data.totalUniqueSlotsBooked
            },
            {
                name:"Total Slots (Today & Tomorrow)",
                selector:"totalSlots",
                sortable:true,
                center: true,
                wrap:true
            },
            {  name:"Nearest Slot",
               selector:"nearestSlot",
               center: true,
                sortable:true,
            },
            {
              name:"Nearest SlotTime",
              selector :"nearestSlotTime",
              minWidth:"200px",
              center: true,
              sortable:true,
              
            },
            {
               name: "Lead Time",
               selector:"leadTime",
                sortable:true,
                minWidth:"200px",
                wrap:true,
                format:(data)=>this.formatLeadTime(data.leadTime)
            },
            {
              name :"First Slot for Next day",
              selector:"tomorrowFirstSlot",
              sortable:true,
              wrap:true,
              center: true,
              minWidth:"200px",
              format:(data)=>data.tomorrowFirstSlot
            },
            {
              name:"Last Slot for Next day",
              selector:"tomorrowLastSlot",
              sortable:true,
              wrap:true,
              center: true,
              minWidth:"200px",
              format:(data)=>data.tomorrowLastSlot
            }
            

        ];
        this.setState({ columns });
    }

    
      
    
      
      jsonToCsv = (json) => {
        const csvRows = [];
        // Get the headers
        const headers = Object.keys(json[0]);
        csvRows.push(headers.join(','));
      
        // Map over each row
        json.forEach(row => {
          const values = headers.map(header => {
            const escaped = ('' + row[header]).replace(/"/g, '\\"');
            return `"${escaped}"`;
          });
          csvRows.push(values.join(','));
        });
      
        // Join rows with newline character
        return csvRows.join('\n');
      };
    

    formatLeadTime=( leadTime)=>{
        if(leadTime==null){
            return "N/A";
        }
        const parts = leadTime.split(': ');
    return parts.length > 1 ? parts[1] : '';
       
    }

      handleSave = () => {
        this.showLoading();
        const {
          selectedCountry,
          selectedCity,
          
          packageType,
        } = this.state;
    
        let countryId = selectedCountry && selectedCountry.id;
        let cityId = selectedCity && selectedCity.id;
        
        let packageName = packageType ?? "";
        
        // console.log("packageId", packageId);
        
    
        let payload = {
          countryId: countryId,
          cityId: cityId,
          type: packageName,
       
        };
        PhoenixAPI.get(`${BASE_URL_LABSLOT_INFO}/country/${countryId}/city/${cityId}/type/${packageName}`)
          .then((response) => {
            let data = (response && response.data) ?? [];
            this.hideLoading();
            const results = data;
            
            
            const filteredResults =
              results && results.length
                ? results
                : [];
            if (filteredResults.length === 0) {
              this.showErrorNotification(
                "There are no Available Lab Slots for this Date"
              );
            }
    
            this.setState({ slotResults: results }, () => {
              this.createTableColumns();
            });
          })
          .catch((error) => {
            console.log("Error on getting lab slots", error, error.message);
            this.showErrorNotification("Error in fetching Lab Slots");
          });
      };

      mappingOrdersForDownload=(orders)=>{
        let mappedOrders=[];
        orders.forEach((order)=>{
            let mappedOrder={};
            mappedOrder["Package Name"]=order.packageName;
            mappedOrder["Total Slots Booked"]=order.totalSlotsBooked ??0;
            mappedOrder["Total UniqueSlots Booked"]=order.totalUniqueSlotsBooked??0;
            mappedOrder["Total Slots (Today & Tomorrow)"]=order.totalSlots??0;
            mappedOrder["Nearest Slot"]=order.nearestSlot;
            mappedOrder["Nearest Slot Time"]=order.nearestSlotTime;
            mappedOrder["Lead Time"]=this.formatLeadTime(order.leadTime);
            mappedOrder["First Slot for Next day"]=order.tomorrowFirstSlot;
            mappedOrder["Last Slot for Next day"]=order.tomorrowLastSlot;
            mappedOrders.push(mappedOrder);
        });
        return mappedOrders;
      }

      
      downloadXls=()=>{
      const{slotResults}  =this.state;
        const mappedOrders=this.mappingOrdersForDownload(slotResults);
        if (mappedOrders.length > 0) {
            const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
            XLSX.writeFile(workbook, 'data.xlsx');
        
            this.showSuccessNotification("File is Downloaded successfully")
          }
      }





      render(){

        let {
            selectedCountry,
            selectedCity,
            slotResults,
            packageType,
            PACKAGE_TYPE_OPTIONS
        }=this.state;

        const selectStyles = {
            container: (base) => ({
              ...base,
              flex: 1,
            }),
          };
      
          if (this.state.accessDenied) {
            return <AccessDenied />
          }

          const customStyles = {
            rows: {
              style: {
                minHeight: "150px", // override the row height
              },
            },
          };

       return(
        <>
        <div className="row">
          <div className="form-group col-4">
            <Select
              key={`my_unique_select_key__${
                selectedCountry && selectedCountry.id
              }`}
              value={
                selectedCountry && this.createCountryOption(selectedCountry)
              }
              onChange={(event) => {
                const selectedCountry = event
                  ? this.findCountry(event.value)
                  : null;
                this.setState({ selectedCountry });
              }}
              options={this.state.countryOptions || []}
              style={selectStyles}
              placeholder="Country"
              isClearable={true}
              searchable
              lazyLoad
            />
          </div>

          <div className="form-group col-4">
            <Select
              key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
              value={selectedCity && this.createCityOption(selectedCity)}
              onChange={(event) => {
                const selectedCity = event ? this.findCity(event.value) : null;
                this.setState({ selectedCity });
              }}
              options={this.state.cityOptions || []}
              style={selectStyles}
              placeholder="City"
              isClearable={true}
              searchable
              lazyLoad
            />
          </div>

          <div className="form-group col-4">
          <Select
              key="package Type"
              value={this.findPackageOption(packageType)}
              onChange={(event) => {
                
                this.setState({ packageType:event.value });
              }}
              options={PACKAGE_TYPE_OPTIONS || []}
              style={selectStyles}
              placeholder="Package type"
              isClearable={true}
              searchable
              lazyLoad
              />

          </div>

          </div>
          <div className="" style={{display:"flex", gap:"20px", justifyContent:"flex-start",marginLeft:"0px",marginBottom:"20px"}}>
          <div>
          <button
          className="btn px-4 mt-1 mb-3"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => this.handleSave()}
        >
          Show Available Lab Slots
        </button>
        </div>
        <div>
        <button
              onClick={(e) => this.downloadXls()}
              id="show_orders"
              className="btn px-4 mt-1 mb-3"
              style={{ backgroundColor: "#0CA789", color:"white" }}
              disabled={slotResults.length==0}
            >
              Download Excel
            </button>
            </div>
            </div>
          <Card body>
          <DataTable
            data={slotResults ?? []}
            columns={this.state.columns ?? []}
            defaultSortField="id"
            defaultSortAsc={false}
            highlightOnHover
            responsive
            title={
              <p className="table-header">{`Found ${slotResults.length} results.`}</p>
            }
            fixedHeader
            fixedHeaderScrollHeight="400px"
            customStyles={customStyles}
            
          />
        </Card>
        
      </> )


      }





    }

    function mapStateToProps(state) {
        return {};
      }
      
      function mapDispatchToProps(dispatch) {
        return {
          showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
          toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
        };
      }
      
      export default connect(mapStateToProps, mapDispatchToProps)(LabSlotsPackages);