import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectTests.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectTests.defaultTypes = {
  tests: [],
  selectedTestIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectTests(props) {
  const { tests, selectedTestIds, onSelect, menuPlacement, isDisabled, placeholder } = props;
  const findTest = (id) => tests.find((test) => test.id === id);

  const activeTests = tests.filter((test) => test.is_active ?? false);

  activeTests.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const testOptions = activeTests.map(createOption);
  const selectedTests = selectedTestIds
    ? selectedTestIds.map(findTest).map(createOption)
    : [];

  return (
    <Select
      key={`my_unique_select_key__${selectedTests && selectedTests.join(",")}`}
      value={selectedTests}
      onChange={onSelect}
      options={testOptions}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
}

const createOption = (test) => {
  if (!test) return;
  return {
    key: test.id,
    value: test.id,
    label: test.name,
  };
};

export default SelectTests;
