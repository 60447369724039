import React, { Component } from "react"
import Search from "components/Search/Search";
import API from "utils/API";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Status from "components/Common/Status";
import FAQCategoriesDropdown from "./FAQCategoriesDropdown"

const BASE_URL_WEBSITE_QUESTIONS_ANSWERS = "frequently-asked-questions/";
const BASE_URL_WEBSITE_FAQ_CATEGORIES = "faq-category/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
    {
        key: true,
        value: true,
        text: "Active",
    },
    {
        key: false,
        value: false,
        text: "Inactive",
    },
]

class QuestionsandAnswers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            categories: [],
            question_answers: [],
            categoriesListed: [],
            question_answer: {},
            errors: {},
            editables: {},
            rankOptions: [],
            minRank: 1,
            maxRank: 0,
            showModal: false,
            loadError: null,
            searchTerm: null,
            categoryId: ""
        };

        this.columns = [
            {
                name: "ID",
                selector: "id",
                width: "100px",
                sortable: true,
            },
            {
                name: "Question",
                selector: "question",
                wrap: true,
                sortable: true,
            },
            {
                name: "Answer",
                selector: "answer",
                wrap: true,
                sortable: true,
                center: true
            },
            {
                name: "Rank",
                selector: "position_value",
                width: "100px",
                sortable: true,
                center: true
            },
            {
                name: "Category",
                selector: "category_name",
                width: "150px",
                sortable: true,
                wrap: true,
                center: true
            },
            {
                name: "Status",
                selector: "is_active",
                maxWidth: "150px",
                center: true,
                cell: (p) => <Status isActive={p.is_active} />,
                sortable: true,
            },
            {
                name: "Actions",
                maxWidth: "100px",
                center: true,
                allowOverflow: true,
                cell: row => (
                    <ActionBar alignRight className="action-menu valeo-dropdown">
                        <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                        </ActionBar.Toggle>
                        <ActionBar.Menu>
                            <ActionBar.Item
                                onClick={() => this.handleView(row.id)}
                            >
                                <EditIcon className="icon-small"></EditIcon>Edit
                            </ActionBar.Item>

                            <ActionBar.Item
                                className="danger"
                                onClick={() => this.handleDeleteData(row.id)}
                            >
                                <DeleteIcon></DeleteIcon>
                                Delete
                            </ActionBar.Item>
                        </ActionBar.Menu>
                    </ActionBar>
                )
            }
        ]
    }

    componentDidMount() {
        this.fetch_faq_categories()
    }

    fetch_faq_categories = async () => {
        const categoryMapper = (category) => ({
            id: category.id,
            category_name: category.name,
            is_active: category.is_active
        })

        try {
            const { data: allCategories } = await API.get(BASE_URL_WEBSITE_FAQ_CATEGORIES)
            const categories = allCategories ? allCategories.map(categoryMapper) : []
            const active_categories = categories.filter((category) => category.is_active)
            const categoriesListed = active_categories.map(category => {
                return {
                    text: category.category_name,
                    value: category.id,
                    key: category.id
                }
            })
            this.setState({
                categories,
                categoriesListed
            }, () => this.fetch_questions_answers())
        }
        catch (error) {
            console.error(
                "Error on fetching FAQ categories from this URL: ",
                BASE_URL_WEBSITE_FAQ_CATEGORIES
            );
            console.error("Error on fetching FAQ categories", error.message);
        }
    }

    fetch_questions_answers = async () => {
        try {
            this.showLoading();
            const response = await API.get(BASE_URL_WEBSITE_QUESTIONS_ANSWERS)
            const question_answers = response.data || [];
            // const activeQuestions= question_answers.filter((it)=>it.is_active)
            const ranks = question_answers.filter((it) => it.position_value).map((it) => it.position_value);
            const minRank = Math.min(...ranks) || 0;
            const maxRank = Math.max(...ranks) || 0;
            for (let item of question_answers) {
                item.category_name = this.getCategoryName(item.category);
            }
            this.setState({ question_answers, minRank, maxRank })
            this.hideLoading()
        }
        catch (error) {
            console.error(
                "Error on fetching FAQ Question & Answers from this URL: ",
                BASE_URL_WEBSITE_QUESTIONS_ANSWERS
            );
            console.error("Error on fetching FAQ Question & Answers", error);

            this.hideLoading();
            this.showErrorNotification(
                "Error on loading FAQ Question & Answers: ",
                error.message
            );
            this.setState({
                loadError: "Some error has occured. Please try again",
            });
        }
    }

    getCategoryName = (id) => {
        const categories = this.state.categories;
        if (categories.length > 0) {
            let category = categories.find(item => {
                return item.id === id;
            });

            if (category) {
                return category.category_name;
            }
        }
        return null;
    }

    handleChange = ({ currentTarget: input }) => {
        const question_answer = { ...this.state.question_answer };
        question_answer[input.name] = input.value;
        this.setState({ question_answer });
    };

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : "",
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                searchApplied: false,
            });
    };

    handleAddNew = () => {
        const question_answer = {
            is_active: false,
            position_value: this.state.maxRank + 1,
        };
        this.setState({ question_answer, isNewquestion_answer: true }, () =>
            this.showModal()
        );
    };

    handleView = (id) => {
        const question_answers = this.state.question_answers || [];
        const question_answer = question_answers.find((it) => it.id === id);
        if (question_answer) {
            const category = this.findCategory(question_answer.category)
            this.setState(
                {
                    isNewquestion_answer: false,
                    question_answer: {
                        ...question_answer,
                    },
                    categoryId: question_answer.category
                },
                () => this.showModal()
            );
        }
    };

    findCategory = (id) => {
        const categories = this.state.categories || [];
        return categories.find((category) => category.id === id)
    }

    showModal = () => {
        const rankOptions = this.createRankOptions();
        this.setState({ rankOptions, showModal: true });
    };

    handleDeleteData = (id) => {
        API.delete("frequently-asked-questions/" + id + "/")
            .then(response => {
                this.fetch_questions_answers();
                this.props.showNotificationMessage({
                    notificationMessage: "Section Deleted successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Some error occurred", error.message);
            })
    }

    hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            editables: {},
            question_answer: {},
            isNewquestion_answer: false,
            categoryId: ""
        });
    };

    createRankOptions = () => {
        let minRank = this.state.minRank
        const { maxRank, isNewquestion_answer } = this.state;
        if (
            minRank === null ||
            minRank === undefined ||
            minRank === "" ||
            maxRank === null ||
            maxRank === "" ||
            maxRank === undefined
        )
            return;
        minRank = Math.min(1, minRank)
        const until = isNewquestion_answer ? maxRank + 1 : maxRank;
        let rankOptions = [];
        for (let i = minRank; i <= until; i++)
            rankOptions.push({ key: i, value: i, text: i });
        if (rankOptions.length === 0)
            rankOptions.push({ key: 1, value: 1, text: 1 });
        return rankOptions;
    };

    handleSave = async () => {
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            return;
        }
        const { question_answer } = this.state;

        const isValidId =
            question_answer.id !== null && question_answer.id !== undefined;

        const apiOptions = isValidId
            ? this.createApiOptions(
                "patch",
                `${BASE_URL_WEBSITE_QUESTIONS_ANSWERS}${question_answer.id}/`
            )
            : this.createApiOptions("post", BASE_URL_WEBSITE_QUESTIONS_ANSWERS);

        try {
            await API.request(apiOptions);
            this.hideLoading();
            this.hideModal();
            this.showSuccessNotification(
                isValidId ? "Updated Successfully." : "Created Successfully."
            );
            this.fetch_questions_answers();
        }
        catch (error) {
            this.hideLoading();
            this.showErrorNotification("Some error occurred: " + error.message);
            console.log("Some error occurred:", error.message)
        }
    };

    validate = () => {
        const errors = {};
        const { question, question_ar, answer, answer_ar, position_value } = this.state.question_answer
        const { categoryId } = this.state

        if (!question || question.trim() === "")
            errors.question = "Question is required field!";
        else if (question.length > 300)
            errors.question = "Question can not take more than 300 characters!";

        if (!question_ar || question_ar.trim() === "")
            errors.question_ar = "Question is required field!"
        else if (question_ar.length > 300)
            errors.question = " Question can not take more than 300 characters!"

        if (!answer || answer.trim() === "")
            errors.answer = "Answer is required field!"
        else if (answer.length > 2000)
            errors.answer = "Answer can not take more than 2000 characters!"

        if (!answer_ar || answer_ar.trim() === "")
            errors.answer_ar = "Answer is required field";
        else if (answer_ar.length > 2000)
            errors.answer_ar = "Answer can not take more than 2000 characters!"

        if (!categoryId)
            errors.categoryId = "category is required field";

        if (position_value === null || position_value === undefined)
            errors.rank = "Rank is a required field!";
        return Object.keys(errors).length === 0 ? null : errors;
    }

    createApiOptions = (method, url) => {
        const formData = this.createFormData();
        return {
            method,
            url,
            data: formData,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
    };

    createFormData = () => {
        const { isNewquestion_answer, question_answer, categoryId } = this.state;

        const formData = new FormData();

        // if (isNewquestion_answer)

        formData.append("question", question_answer.question);
        formData.append("question_ar", question_answer.question_ar);
        formData.append("answer", question_answer.answer);
        formData.append("answer_ar", question_answer.answer_ar);
        formData.append("category", categoryId);
        formData.append("position_value", question_answer.position_value);
        formData.append("is_active", question_answer.is_active)
        return formData;
    };

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) => {
        const isEditable = this.props.isEditable ?? true;
        return isEditable && !this.state.isNewquestion_answer ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );
    }

    getFilteredquestion_answers = () => {
        const { question_answers } = this.state;
        return this.getSearchedquestion_answers(question_answers);
    };

    getSearchedquestion_answers = (question_answers) => {
        const { searchApplied, searchTerm } = this.state;
        if (!searchApplied || !searchTerm) return question_answers;
        const newValue = searchTerm.slice(0).trim().toLowerCase();

        return question_answers.filter((question_answer) => {
            const { id, question, answer, category_name, position_value } = question_answer;
            return (
                (question && question.toLowerCase().indexOf(newValue) !== -1) ||
                (answer && answer.toLowerCase().indexOf(newValue) !== -1) ||
                (id && id.toString() === newValue) ||
                (category_name && category_name.toLowerCase().indexOf(newValue) !== -1) ||
                (position_value && position_value.toString() === newValue)
            );
        });
    };

    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    render() {
        const { searchTerm, editables, errors, isNewquestion_answer, question_answer, categories, categoriesListed } = this.state;
        const finalList = this.getFilteredquestion_answers();
        const { categoryId } = this.state
        const isEditable = this.props.isEditable ?? true;

        const detailsForm = (
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="question">Question (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={question_answer && question_answer.question || ""}
                                onChange={this.handleChange}
                                id="question"
                                name="question"
                                type="text"
                                className={
                                    "form-control py-2" + (errors.question ? " has-error" : "")
                                }
                                disabled={!isNewquestion_answer && !editables.question}
                            />
                            {this.renderPencilIcon("question")}
                        </div>
                        <span className="help-block">{errors.question}</span>
                    </div>

                    <div className="form-group col">
                        <label htmlFor="question_ar">Question (Arabic)*</label>
                        <div className="input-group">
                            <textarea
                                value={question_answer && question_answer.question_ar || ""}
                                type="text"
                                id="question_ar"
                                name="question_ar"
                                onChange={this.handleChange}
                                disabled={!isNewquestion_answer && !editables.question_ar}
                                className={
                                    "form-control py-2" + (errors.question_ar ? " has-error" : "")
                                }
                            ></textarea>
                            {this.renderPencilIcon("question_ar")}
                        </div>
                        <span className="help-block">{errors.question_ar}</span>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="answer(english)">Answer (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={question_answer && question_answer.answer || ""}
                                type="text"
                                id="answer"
                                name="answer"
                                onChange={this.handleChange}
                                disabled={!isNewquestion_answer && !editables.answer}
                                className="form-control py-2"
                            />
                            {this.renderPencilIcon("answer")}
                        </div>
                        <span className="help-block">{errors.answer}</span>
                    </div>

                    <div className="form-group col">
                        <label htmlFor="answer(arabic)">Answer (Arabic)*</label>
                        <div className="input-group">
                            <textarea
                                value={question_answer && question_answer.answer_ar || ""}
                                type="text"
                                id="answer_ar"
                                name="answer_ar"
                                onChange={this.handleChange}
                                disabled={!isNewquestion_answer && !editables.answer_ar}
                                className="form-control py-2"
                            />
                            {this.renderPencilIcon("answer_ar")}
                        </div>
                        <span className="help-block">{errors.answer_ar}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group  col">
                        <label>Category *</label>
                        <div className="input-group">
                            {/* <Dropdown
                            value={this.state.categoryId}
                            className={
                                "form-control py-2" + (errors.categoryId ? " has-error" : "")
                            }
                            placeholder="Select category"
                            onChange={(event, value) => {
                                this.setState({ categoryId: value.value });
                            }}
                            options={categoriesListed}
                            search
                            selection
                        /> */}
                            <FAQCategoriesDropdown
                                FAQCategories={categories}
                                selectedFAQCategory={categoryId}
                                onSelect={(event) => {
                                    this.setState({
                                        categoryId: event.value
                                    });
                                }}
                                disabled={!isNewquestion_answer && !editables.category}
                                className={
                                    "form-control" + (errors.category ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("category")}
                        </div>
                        <span className="help-block">{errors.categoryId}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="rank">Rank</label>
                        <div className="input-group">
                            <Dropdown
                                value={question_answer.position_value}
                                onChange={(event, value) => {
                                    const category = { ...question_answer };
                                    category.position_value = value.value;
                                    this.setState({ question_answer: category });
                                }}
                                id="rank"
                                placeholder="Rank"
                                search
                                selection
                                options={this.state.rankOptions}
                                disabled={!isNewquestion_answer && !editables.rank}
                                className={"form-control" + (errors.rank ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("rank")}
                        </div>
                        <span className="help-block">{errors.rank}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6 ">
                        <label htmlFor="status">Status*</label>
                        <div className="input-group">
                            <Dropdown
                                value={question_answer.is_active}
                                onChange={(event, value) => {
                                    this.setState({
                                        question_answer: {
                                            ...question_answer,
                                            is_active: value.value,
                                        },
                                    });
                                }}
                                id="status"
                                name="status"
                                placeholder="Status"
                                search
                                selection
                                options={STATUS_OPTIONS}
                                disabled={!isNewquestion_answer && !editables.status}
                                className={"form-control" + (errors.status ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("status")}
                        </div>
                        <span className="help-block">{errors.status}</span>
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <div className="question">
                    <div className="page-header">
                        <div className="actions">
                            <div className="action-item">
                                <Search
                                    searchExpanded={true}
                                    searchTerm={searchTerm}
                                    handleSearch={this.handleSearch}
                                    clearSearch={this.handleSearchClear}
                                    handleSearchExit={this.handleSearchExit}
                                    ref={(input) => {
                                        this.search = input;
                                    }}
                                />
                            </div>
                            <div className="action-item">
                                <button
                                    style={{ backgroundColor: "#8FD14F" }}
                                    className="btn btn-md new-user"
                                    onClick={this.handleAddNew}
                                    disabled={!isEditable}
                                >
                                    <i className="fa fa-plus-circle"></i>Add New
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card body>
                        <h3 className="p-head">Question & Answers</h3>
                        <DataTable
                            data={finalList}
                            columns={this.columns}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            highlightOnHover
                            pagination
                            responsive
                            noHeader={this.state.searchApplied ? false : true}
                            title={
                                this.state.searchApplied ? (
                                    <p className="table-header">
                                        {"Found " + finalList.length + " results"}
                                    </p>
                                ) : (
                                    ""
                                )
                            }
                            noDataComponent={
                                this.state.loadError ? (
                                    <AlertBox
                                        message={this.state.loadError}
                                        error={true}
                                    ></AlertBox>
                                ) : (
                                    <AlertBox message="There's nothing here."></AlertBox>
                                )
                            }
                        />
                    </Card>

                    <Modal
                        size="lg"
                        show={this.state.showModal}
                        onHide={this.hideModal}
                        className="user-data-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            <div className="user-title"></div>
                            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs id="tabs_website_categories">
                                <Tab eventKey="key_details_form" title="Edit Question">
                                    {detailsForm}
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionsandAnswers)