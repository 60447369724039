import React, { Component } from "react";
import { connect } from "react-redux";
import icon_notification_success from "../../assets/img/icon_notification_success.png"
import icon_notification_error from "../../assets/img/icon_notification_error.png"
class Notification extends Component {
  constructor(props) {
    super(props);
    this.updateNotification = this.updateNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
  }
  componentDidUpdate() {
    if (this.props.notificationMessage) this.updateNotification();
  }

  closeNotification() {
    this.props.updateNotification({
      notificationMessage: "",
      successMessage: this.props.successMessage,
      showNotification: false
    });
  }

  updateNotification() {
    setTimeout(() => {
      this.props.updateNotification({
        notificationMessage: "",
        successMessage: this.props.successMessage,
        showNotification: false
      });
    }, 10000);
  }
  render() {
    const { menuExpanded } = this.props;

    return (
       <div
        className={
          "notification-top " +
          (this.props.successMessage
            ? " success-message "
            : " error-message ") +
          (this.props.showNotification ? "show-notification " : "") +
          (menuExpanded ? "" : "wide")
        }
      >

        {(this.props.successMessage) ? <> <div className="success-bar"></div>
          <img src={icon_notification_success} /> </> : <> <div className="error-bar"></div>
          <img src={icon_notification_error} /></>}

        <h5>{this.props.notificationMessage}<a className="closeNotification" onClick={this.closeNotification}></a></h5>

      </div>

    );
  }
}
function mapStateToProps(state) {
  return {
    notificationMessage: state.notificationMessage,
    successMessage: state.successMessage,
    showNotification: state.showNotification
  };
}
function mapDispatchToProps(dispatch) {
  return {
    updateNotification: value => dispatch({ type: "SHOW_NOTIFICATION", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
