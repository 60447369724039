import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import ImagePreview from "components/Common/ImagePreview";

const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class KeyHighlightsForm extends Component {
  constructor(props) {
    super(props);

    const { keyHighlights = [], onKeyHighlightsUpdated = (f) => f } =
      this.props;

    this.onKeyHighlightsUpdated = onKeyHighlightsUpdated;

    this.state = {
      keyHighlights,
      keyHighlight: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        width: "50px",
        cell: () => <Drag className="drag-handle"></Drag>,
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Title (English)",
        selector: "titleEnglish",
        sortable: true,
        wrap: true,
        format: (keyHighlight) => (
          <div className="text-overflow-ellipsis">
            {keyHighlight.titleEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Title (Arabic)",
        selector: "titleArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (keyHighlight) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {keyHighlight.titleArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Description (English)",
        selector: "descriptionEnglish",
        sortable: true,
        wrap: true,
        format: (keyHighlight) => (
          <div className="text-overflow-ellipsis">
            {keyHighlight.descriptionEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Description (Arabic)",
        selector: "descriptionArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (keyHighlight) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {keyHighlight.descriptionArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Image",
        selector: "image",
        sortable: true,
        wrap: true,
        maxWidth: "150px",
        cell: (keyHighlight) => this.renderKeyHighlightImage(keyHighlight),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (keyHighlight) => (
          <Status isActive={keyHighlight.status === "Active"} />
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (keyHighlight) => this.renderActionBar(keyHighlight),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
    this.inputFileRef = React.createRef();
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      keyHighlight: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const keyHighlight = { ...this.state.keyHighlight };
    keyHighlight[input.name] = input.value;
    this.setState({ keyHighlight });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { keyHighlights = [] } = this.state;
    const keyHighlight = keyHighlights.find((it) => it.id === id);
    if (!keyHighlight) return;

    if (keyHighlight.image && typeof keyHighlight.image === "string")
      keyHighlight.image_src = keyHighlight.image;
    else this.readImage(keyHighlight.image);

    this.setState({ keyHighlight }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteKeyHighlight(id);

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const keyHighlight = { ...this.state.keyHighlight, image };
      this.setState({ keyHighlight });

      this.readImage(image);
    }
  };

  handleDeleteImage = () => {
    this.setState({
      keyHighlight: {
        ...this.state.keyHighlight,
        image: null,
        image_src: null,
      },
    });
    this.inputFileRef.current.value = null;
  };

  readImage = (image) => {
    if (!image) return;
    const reader = new FileReader();
    reader.onloadend = function () {
      const keyHighlight = {
        ...this.state.keyHighlight,
        image_src: [reader.result],
      };
      this.setState({ keyHighlight });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { keyHighlight = {} } = this.state;
    if (keyHighlight.id === null || keyHighlight.id === undefined)
      this.createKeyHighlight();
    else this.updateKeyHighlight(keyHighlight.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      titleEnglish,
      titleArabic,
      descriptionEnglish,
      descriptionArabic,
      status,
      image,
    } = this.state.keyHighlight || {};

    if (!titleEnglish || titleEnglish.trim() === "")
      errors.titleEnglish = "Title (English) cannot be blank";
    else if (titleEnglish && titleEnglish.length > 100)
      errors.titleEnglish = "Title (English) is limited to a 100 characters";

    if (!titleArabic || titleArabic.trim() === "")
      errors.titleArabic = "Title (Arabic) cannot be blank";
    else if (titleArabic && titleArabic.length > 100)
      errors.titleArabic = "Title (Arabic) is limited to a 100 characters";

    if (!descriptionEnglish || descriptionEnglish.trim() === "")
      errors.descriptionEnglish = "Description (English) cannot be blank";
    else if (descriptionEnglish && descriptionEnglish.length > 200)
      errors.descriptionEnglish =
        "Description (English) is limited to a 200 characters";

    if (!descriptionArabic || descriptionArabic.trim() === "")
      errors.descriptionArabic = "Description (Arabic) cannot be blank";
    else if (descriptionArabic && descriptionArabic.length > 200)
      errors.descriptionArabic =
        "Description (Arabic) is limited to a 200 characters";

    if (!status) errors.status = "Status cannot be blank";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createKeyHighlight = () => {
    const { keyHighlights = [], keyHighlight } = this.state;
    const ids =
      keyHighlights.length > 0 ? keyHighlights.map((it) => it.id) : null;
    keyHighlight.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      keyHighlights.length > 0
        ? keyHighlights.filter((it) => it.rank).map((it) => it.rank)
        : null;
    keyHighlight.rank = ranks ? Math.max(...ranks) + 1 : 1;
    keyHighlight.is_new = true;

    this.setState({ keyHighlights: [...keyHighlights, keyHighlight] }, () => {
      this.showSuccessNotification("Key Highlight Created Successfully!");
      this.onKeyHighlightsUpdatedLocally();
    });
  };

  updateKeyHighlight = (id) => {
    const { keyHighlights = [], keyHighlight } = this.state;
    const newKeyHighlights = keyHighlights.map((it) =>
      it.id === id ? keyHighlight : it
    );
    this.setState({ keyHighlights: newKeyHighlights }, () => {
      this.showSuccessNotification("Key Highlight Updated Successfully!");
      this.onKeyHighlightsUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { keyHighlights = [] } = this.state;
    const newKeyHighlights = keyHighlights.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ keyHighlights: newKeyHighlights }, () => {
      this.showSuccessNotification("Key Highlight Updated Successfully!");
      this.onKeyHighlightsUpdatedLocally();
    });
  };

  deleteKeyHighlight = (id) => {
    const { keyHighlights = [] } = this.state;
    const keyHighlightToDelete = keyHighlights.find((it) => it.id === id) || {};
    const newKeyHighlights = keyHighlights
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > keyHighlightToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ keyHighlights: newKeyHighlights }, () => {
      this.showSuccessNotification("Key Highlight Deleted Successfully!");
      this.onKeyHighlightsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { keyHighlights = [], sortColumn, sortDirection } = this.state;
    const sortedKeyHighlights = _.sortBy(
      keyHighlights,
      sortColumn,
      sortDirection
    );
    const fromKeyHighlight = { ...sortedKeyHighlights[fromIndex] };
    const toKeyHighlight = { ...sortedKeyHighlights[toIndex] };

    if (fromKeyHighlight && toKeyHighlight) {
      const tempRank = fromKeyHighlight.rank;
      fromKeyHighlight.rank = toKeyHighlight.rank;
      toKeyHighlight.rank = tempRank;

      const newKeyHighlights = keyHighlights.map((it) => {
        if (it.id === fromKeyHighlight.id) return fromKeyHighlight;
        else if (it.id === toKeyHighlight.id) return toKeyHighlight;
        else return it;
      });
      this.setState({ keyHighlights: newKeyHighlights }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onKeyHighlightsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onKeyHighlightsUpdatedLocally = () => {
    this.clearForm();
    this.onKeyHighlightsUpdated(
      this.state.keyHighlights.map((keyHighlight) => {
        const k = { ...keyHighlight };
        if (k.is_new) {
          delete k.id;
          delete k.is_new;
        }
        return k;
      })
    );
  };

  handleAddNewKeyHighlight = () => {
    const keyHighlight = { status: "Inactive" };
    this.setState({ keyHighlight }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderKeyHighlightImage = (keyHighlight = {}) => {
    const { image, image_src } = keyHighlight;
    return !image && !image_src ? null : (
      <img
        src={typeof image === "string" ? image : image_src}
        width="100%"
        height={50}
        className="form-image"
        alt="Key Highlights Icon"
      />
    );
  };

  renderActionBar = (keyHighlight) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(keyHighlight.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() => this.handleStatusChange(keyHighlight.id)}
        >
          {keyHighlight.status === "Active" ? (
            <ArchiveOutlinedIcon
              className="icon-small"
              style={{ fill: "red" }}
            />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {keyHighlight.status === "Active" ? (
            <span style={{ color: "red" }}>Inactivate</span>
          ) : (
            "Activate"
          )}
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ questionToDelete: keyHighlight.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.keyHighlight.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      keyHighlights = [],
      keyHighlight = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewKeyHighlight =
      keyHighlight.id === null || keyHighlight.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="titleEnglish">Title (English)*</label>
            <div className="input-group">
              <input
                type="text"
                value={keyHighlight.titleEnglish ?? ""}
                onChange={this.handleChange}
                id="titleEnglish"
                name="titleEnglish"
                placeholder="Title in English"
                disabled={!isNewKeyHighlight && !editables.titleEnglish}
                className={
                  "form-control py-2" +
                  (errors.titleEnglish ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("titleEnglish")}
            </div>
            <span className="help-block">{errors.titleEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="titleArabic">Title (Arabic)*</label>
            <div className="input-group">
              <input
                type="text"
                value={keyHighlight.titleArabic ?? ""}
                onChange={this.handleChange}
                id="titleArabic"
                name="titleArabic"
                placeholder="Title in Arabic"
                disabled={!isNewKeyHighlight && !editables.titleArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.titleArabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("titleArabic")}
            </div>
            <span className="help-block">{errors.titleArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="descriptionEnglish">Description (English)*</label>
            <div className="input-group">
              <textarea
                value={keyHighlight.descriptionEnglish ?? ""}
                onChange={this.handleChange}
                id="descriptionEnglish"
                name="descriptionEnglish"
                placeholder="Description in English"
                readOnly={!isNewKeyHighlight && !editables.descriptionEnglish}
                className={
                  "form-control py-2" +
                  (errors.descriptionEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("descriptionEnglish")}
            </div>
            <span className="help-block">{errors.descriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="descriptionArabic">Description (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={keyHighlight.descriptionArabic ?? ""}
                onChange={this.handleChange}
                id="descriptionArabic"
                name="descriptionArabic"
                placeholder="Description in Arabic"
                readOnly={!isNewKeyHighlight && !editables.descriptionArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.descriptionArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("descriptionArabic")}
            </div>
            <span className="help-block">{errors.descriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={keyHighlight.status}
                onChange={(event, value) => {
                  this.setState({
                    keyHighlight: { ...keyHighlight, status: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewKeyHighlight && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                type="file"
                onChange={this.handleSelectImage}
                ref={this.inputFileRef}
                id="imageInput"
                name="image"
                accept="image/*"
                disabled={!isNewKeyHighlight && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {keyHighlight.image_src && (
              <ImagePreview
                imageUrl={keyHighlight.image_src}
                showDeleteIcon={true}
                onDeleteImage={this.handleDeleteImage}
              />
            )}
          </div>
        </div>
      </>
    );

    return (
      <>
        <div className="row">
          <div className="form-group col">
            <label>Key Highlights</label>
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  columns={this.columns}
                  data={keyHighlights || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  responsive
                  highlightOnHover
                  pagination
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewKeyHighlight}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {keyHighlight.id === null || keyHighlight.id === undefined
                ? "Add Key Highlight"
                : "Edit Key Highlight"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {keyHighlight.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Key Highlight</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the key highlight?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteKeyHighlight(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

KeyHighlightsForm.propTypes = {
  keyHighlights: PropTypes.arrayOf(PropTypes.object).isRequired,
  onKeyHighlightsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

KeyHighlightsForm.defaultProps = {
  keyHighlights: [],
  onKeyHighlightsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(KeyHighlightsForm);
