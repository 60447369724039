import React, { Component } from "react";
import Search from "components/Search/Search";
import PhoenixAPI from "utils/PhoenixAPI";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_CURRENCY_CONVERTER = "converter/";
class ExchangeRates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      country: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isOtherItemViewModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Country",
        selector: "countryName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Country Currency",
        selector: "countryCurrency",
        sortable: true,
        wrap: true,
      },
      {
        name: "Base Currency",
        selector: "baseCurrency",
        sortable: true,
      },
      {
        name: "Exchange Rate",
        selector: "exchangeRate",
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (country) => this.renderViewButton(country.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountryDetails();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.EXCHANGE_RATES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        this.setState({ aclUser, pageAccessRights }, () => {
          const accessDenied = isAccessDenied(aclUser, pageAccessRights);
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ accessDenied, isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountryDetails = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_CURRENCY_CONVERTER);
      const countries = (response && response.data) || [];
      this.setState({ countries });
    } catch (error) {
      console.log("Error on fetching Currency converter", error);
      this.showErrorNotification(
        "Error on fetching Currency converter: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleViewCountry = (countryId) => {
    const countries = this.state.countries ?? [];
    const country = countries.find((it) => it.id === countryId);
    if (!country) {
      console.log(`country with id ${countryId} is not found!`);
      return;
    }
    this.setState({ country, isNewCountry: false }, () =>
      this.showCountryViewModal()
    );
  };

  handleAddNewCountry = () => {
    this.setState({ isNewCountry: true }, () => this.showCountryViewModal());
  };

  showCountryViewModal = () => {
    this.setState({ isCountryViewModalVisible: true });
  };

  hideCountryViewModal = () => {
    this.setState({ isCountryViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      country: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCountry(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["exchangeRate"];
    const country = { ...this.state.country };
    if (numberInputs.includes(input.name))
      country[input.name] = input.value ? Number(input.value) : "";
    else country[input.name] = input.value;
    this.setState({ country });
  };

  handlesaveCountry = async () => {
    const { country } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = country.id !== null && country.id !== undefined;

      const response = isValidId
        ? await this.updateCountry(country)
        : await this.saveCountry(country);

      this.hideCountryViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Exchange Rate of Country Updated Successfully."
          : "Exchange Rate of Country Item Created Successfully."
      );
      this.fetchCountryDetails();
    } catch (error) {
      this.showErrorNotification("Some error occurred:" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  updateCountry = async (country) => {
    const payload = { ...country };
    try {
      const response = await PhoenixAPI.patch(
        `${BASE_URL_CURRENCY_CONVERTER}/${country.id}`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error on updating Exchange Rate Country", error.message);
      this.showErrorNotification("Error in updating Exchange Rate Country");
    }
  };

  saveCountry = async (country) => {
    const payload = { ...country };
    try {
      const response = await PhoenixAPI.post(
        BASE_URL_CURRENCY_CONVERTER,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error on creating  Exchange Rate Country", error);
      this.showErrorNotification("Error in creating Exchange Rate Country");
    }
  };

  getFilteredCountries = () => {
    const { countries, searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return countries;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return countries.filter((country) => {
      const { id, countryName, countryCurrency, baseCurrency, exchangeRate } =
        country;
      return (
        (countryName && countryName.toLowerCase().indexOf(newValue) !== -1) ||
        (countryCurrency &&
          countryCurrency.toLowerCase().indexOf(newValue) !== -1) ||
        (baseCurrency && baseCurrency.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (exchangeRate && exchangeRate.toString() === newValue)
      );
    });
  };

  validate = () => {
    const { country = {} } = this.state;

    const errors = {};
    const { countryName, countryCurrency, baseCurrency, exchangeRate } =
      country;

    if (!countryName || countryName.trim() === "")
      errors.countryName = "Country Name cannot be blank";

    if (!countryCurrency || countryCurrency.trim() === "")
      errors.countryCurrency = "Country Currency cannot be blank";

    if (!baseCurrency || baseCurrency.trim() === "")
      errors.baseCurrency = "Base Currency cannot be blank";

    if (!exchangeRate || exchangeRate.toString().trim() === "")
      errors.exchangeRate = " Exchange Rate cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };
  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewCountry ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      countries,
      country,
      isNewCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      accessDenied
    } = this.state;

    const filteredCountries = this.getFilteredCountries(countries) ?? [];
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="countryName">Country *</label>
            <div className="input-group">
              <input
                value={country?.countryName || ""}
                onChange={this.handleChange}
                id="countryName"
                name="countryName"
                type="text"
                placeholder="Country Name"
                className={
                  "form-control py-2" + (errors.countryName ? " has-error" : "")
                }
                disabled={!isNewCountry && !editables.countryName}
              />
              {this.renderPencilIcon("countryName")}
            </div>
            <span className="help-block">{errors.countryName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="countryCurrency">Country Currency*</label>
            <div className="input-group">
              <input
                value={country?.countryCurrency || ""}
                onChange={this.handleChange}
                id="countryCurrency"
                name="countryCurrency"
                type="text"
                placeholder="Country Currency"
                className={
                  "form-control py-2" +
                  (errors.countryCurrency ? " has-error" : "")
                }
                disabled={!isNewCountry && !editables.countryCurrency}
              />
              {this.renderPencilIcon("countryCurrency")}
            </div>
            <span className="help-block">{errors.countryCurrency}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="baseCurrency">Base Currency *</label>
            <div className="input-group">
              <input
                value={country?.baseCurrency || ""}
                onChange={this.handleChange}
                id="baseCurrency"
                name="baseCurrency"
                type="text"
                placeholder="Base Currency"
                className={
                  "form-control py-2" +
                  (errors.baseCurrency ? " has-error" : "")
                }
                disabled={!isNewCountry && !editables.baseCurrency}
              />
              {this.renderPencilIcon("baseCurrency")}
            </div>
            <span className="help-block">{errors.baseCurrency}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="exchangeRate">Exchange Rate *</label>
            <div className="input-group">
              <input
                value={country?.exchangeRate || ""}
                onChange={this.handleChange}
                id="exchangeRate"
                name="exchangeRate"
                type="number"
                placeholder="Exchange Rate"
                className={
                  "form-control py-2" +
                  (errors.exchangeRate ? " has-error" : "")
                }
                disabled={!isNewCountry && !editables.exchangeRate}
              />
              {this.renderPencilIcon("exchangeRate")}
            </div>
            <span className="help-block">{errors.exchangeRate}</span>
          </div>
        </div>
      </div>
    );

    if (accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewCountry}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredCountries || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCountries.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isCountryViewModalVisible}
          onHide={this.hideCountryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title"></div>
              <button
                type="submit"
                onClick={this.handlesaveCountry}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_countries">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeRates);
