import React from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import StarIcon from "@material-ui/icons/Star";

function Rating(props) {
  const rating = props.rating;

  if (rating > 5 || rating < 0) return null;

  let stars = [];

  for (let i = 1; i <= 5; i++) {
    if (rating === 0)
      stars.push(<StarBorderIcon className="star"></StarBorderIcon>);
    else if (rating === i) stars.push(<StarIcon className="star"></StarIcon>);
    else if (rating > i) stars.push(<StarIcon className="star"></StarIcon>);
    else if (rating > i - 1 && rating < i)
      stars.push(<StarHalfIcon className="star"></StarHalfIcon>);
    else stars.push(<StarBorderIcon className="star grey"></StarBorderIcon>);
  }

  return (
    <div className="rating-container">
      {stars.map((star, index) => (
        <React.Fragment key={index}>{star}</React.Fragment>
      ))}
      <span className="rating-text">({Math.round(rating * 10) / 10})</span>
    </div>
  );
}

export default Rating;
