import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import _ from "lodash";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import FAQForm from "components/Common/FAQForm";
import ConditionCategoriesDropdown from "./ConditionCategoriesDropdown";
import ConditionSolutionsForm from "./ConditionSolutionsForm";
import QuestionnairesForm from "components/Common/QuestionnairesForm";
import SelectArticles from "components/Common/SelectArticles";
import SelectSupplements from "components/Common/SelectSupplements";
import SelectMedicines from "components/Common/SelectMedicines";
import TestimonialsForm from "./TestimonialsForm";
import { getUserId } from "services/UserService";
import { isUserSuperAdmin,isUserAdmin } from "services/aclService";
import { fetchArticles } from "services/HealthHubService";
import { Page } from "utils/constant";
import TreatmentPlanSuggestionsForm from "./TreatmentPlanSuggestionsForm";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const INTERNAL_CATEGORY_OPTIONS = [
  {
    key: "None",
    value: "None",
    text: "None",
  },
  {
    key: "Fasting BT",
    value: "Fasting BT",
    text: "Fasting BT",
  },
  {
    key: "Non Fasting BT",
    value: "Non Fasting BT",
    text: "Non Fasting BT",
  },
  {
    key: "Mini Packages",
    value: "Mini Packages",
    text: "Mini Packages",
  },
  {
    key: "PCR",
    value: "PCR",
    text: "PCR",
  },
  {
    key: "IV",
    value: "IV",
    text: "IV",
  },
  {
    key: "Physio",
    value: "Physio",
    text: "Physio",
  },
  {
    key: "DNA",
    value: "DNA",
    text: "DNA",
  },
  {
    key: "Functional Test",
    value: "Functional Test",
    text: "Functional Test",
  },
  {
    key: "Vaccines",
    value: "Vaccines",
    text: "Vaccines",
  },
  {
    key: "Child shots",
    value: "Child shots",
    text: "Child shots",
  },
  {
    key: "Vaccine shots",
    value: "Vaccine shots",
    text: "Vaccine shots",
  },
  {
    key: "STD",
    value: "STD",
    text: "STD",
  },
  {
    key: "Supplements",
    value: "Supplements",
    text: "Supplements",
  },
  {
    key:"Gift Card",
    value:"Gift Card",
    text:"Gift Card"
  },
  {
    key:"Coach Consultation",
    value:"Coach Consultation",
    text:"Coach Consultation"
  },
  {
    key:"Doctor Consultation",
    value:"Doctor Consultation",
    text:"Doctor Consultation"
  },
  {
    key:"Medicine",
    value:"Medicine",
    text:"Medicine"
  },
  {
    key:"Dummy Order",
    value:"Dummy Order",
    text:"Dummy Order"
  }
];
const TWO_MEGA_BYTE = 2_097_152;
const BASE_URL_CONDITIONS = "conditions/";
const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_SUPPLEMENTS = "/supplement/admin/v2/country/";

class Conditions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      conditions: [],
      rankOptions: [],
      articles: [],
      medicines: [],
      supplements: [],
      condition: {},
      errors: {},
      editables: {},
      searchTerm: null,
      searchApplied: false,
      isConditionViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
      valeoPartners:[],
      valeoPartnerOptions:[],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (condition) => (
          <p style={{ textAlign: "right" }}>{condition.externalNameArabic}</p>
        ),
      },
      {
        name: "Category",
        selector: "conditionCategoryName",
        wrap: true,
        sortable: true,
        center: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (condition) => (
          <Status isActive={condition.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (condition) => this.renderViewButton(condition.id),
        width: "170px",
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchConditions();
    this.fetchArticles();
    this.fetchDependencies();
    this.fetchValeoPartners()
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = prevProps;
    if (selectedCountry && selectedCountry !== this.props.selectedCountry) {
      this.fetchDependencies();
    }
  }

  fetchDependencies = async () => {
    try {
      this.showLoading();
      await Promise.all([
        this.fetchMedicinesForCurrentCountry(),
        this.fetchSupplementsForCurrentCountry(),
      ]);
    } finally {
      this.hideLoading();
    }
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CONDITION_CATEGORIES;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      this.setState({ aclUser, pageAccessRights });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchConditions = async () => {
    try {
      this.showLoading();
      const { data: conditions = [] } = await PhoenixAPI.get(
        BASE_URL_CONDITIONS
      );
      this.setState({
        conditions: conditions.map((condition) => {
          const category = this.findCategory(condition.conditionCategoryId);
          condition.conditionCategoryName = category?.internalName ?? "";
          return condition;
        }),
      });
    } catch (error) {
      console.log("Error on fetching conditons", error);
      this.showErrorNotification(
        "Error on fetching conditions: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
        const partnerOptions=partners.map((it)=>{
          return {key:it.id, value:it.id, label:it.name}
        })
        partnerOptions.push({key:"select", value:"select", label:"Select"})
        this.setState({valeoPartners:partners,valeoPartnerOptions:partnerOptions})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  findCondition = (conditionId) => {
    const { conditions = [] } = this.state;
    return conditions.find((it) => it.id === conditionId);
  };

  findCountry = (countryId) => {
    const { countries = [] } = this.props;
    return countries.find((country) => country.id === countryId);
  };

  findCategory = (categoryId) => {
    const { categories = [] } = this.props;
    return categories.find((it) => it.id === categoryId);
  };

  fetchArticles = async () => {
    try {
      this.showLoading();
      const articles = (await fetchArticles()) ?? [];
      this.setState({ articles });
    } catch (error) {
      console.log("fetchArticles:: Error on fetching articles!", error);
      this.showErrorNotification("Error on fetching articles!", error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchMedicinesForCurrentCountry = async () => {
    const { selectedCountry } = this.props;
    if (!selectedCountry || !selectedCountry.id) {
      console.log("fetchMedicinesForCurrentCountry:: Invalid country!", {
        selectedCountry,
      });
      return;
    }

    try {
      this.showLoading();
      const url = `${BASE_URL_MEDICINES}country/${selectedCountry.id}`;
      const { data: medicines = [] } = await PhoenixAPI.get(url);
      this.setState({ medicines });
    } catch (error) {
      console.log(
        "fetchMedicinesForCurrentCountry:: Error on fetching medicines!",
        error
      );
      this.showErrorNotification("Error on fetching medicines!", error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchSupplementsForCurrentCountry = async () => {
    const { selectedCountry } = this.props;
    if (!selectedCountry || !selectedCountry.id) {
      console.log("fetchSupplementsForCurrentCountry:: Invalid country!", {
        selectedCountry,
      });
      return;
    }

    try {
      this.showLoading();
      const url = `${BASE_URL_SUPPLEMENTS}${this.props.selectedCountry.id}`;
      console.log("url supplement",url)
      const { data: supplements = [] } = await PhoenixAPI.get(url);
      this.setState({ supplements });
    } catch (error) {
      console.log(
        "fetchSupplementsForCurrentCountry:: Error on fetching supplements!",
        error
      );
      this.showErrorNotification(
        "Error on fetching supplements!",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const condition = { ...this.state.condition };
    condition[input.name] = input.value;
    this.setState({ condition });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleQuestionsUpdated = (questions = []) => {
    const condition = { ...this.state.condition, conditionFaqs: questions };
    this.setState({ condition });
  };

  handleQuestionnairesUpdated = (questionnaires) => {
    const condition = {
      ...this.state.condition,
      conditionDiagnostics: questionnaires,
    };
    this.setState({ condition });
  };

  handleSolutionsUpdated = (solutions) => {
    const condition = { ...this.state.condition, solution: solutions };
    this.setState({ condition });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const condition = { ...this.state.condition, image };
      this.setState({ condition });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const condition = { ...this.state.condition, image_src: [reader.result] };
      this.setState({ condition });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleTestimonialsUpdated = (testimonials = []) => {
    const condition = { ...this.state.condition, testimonials };
    this.setState({ condition });
  };

  handleTreatmentPlansUpdated = (treatmentPlanSuggestions = []) => {
    const condition = { ...this.state.condition, treatmentPlanSuggestions };
    this.setState({ condition });
  };

  handleAddNewCondition = () => {
    this.setState({ isNewCondition: true }, () =>
      this.showConditionViewModal()
    );
  };

  handleViewCondition = (conditionId) => {
    const condition = this.findCondition(conditionId);
    const {valeoPartners}= this.state || {}
    if (!condition) {
      console.log(`Condition with id ${conditionId} is not found!`);
      return;
    }

    condition.image_src = condition.image;

    if (condition.currency === null || condition.currency === undefined) {
      condition.currency = this.state.selectedCountry?.currency;
    }
    if (condition.internalCategory === null || condition.internalCategory === undefined) {
      condition.internalCategory ="None";
    }

    const partnerId= condition.partnerId ?? null;
    const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
    const newCondition={...condition,valeoPartnerId: selectedPartner?.id || null,
      valeoPartnerName:selectedPartner?.name || ""}

    this.setState({ condition:newCondition, isNewCondition: false }, () =>
      this.showConditionViewModal()
    );
  };

  showConditionViewModal = () => {
    const { isNewCondition } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewCondition) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const condition = {
        country: this.props.selectedCountry?.id,
        currency: this.props.selectedCountry?.currency,
        status: "Inactive",
        rank,
        partnerId:null,
        internalCategory: "None",
      };
      this.setState({
        condition,
        rankOptions,
        isConditionViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isConditionViewModalVisible: true });
    }
  };

  hideConditionViewModal = () => {
    this.setState({ isConditionViewModalVisible: false }, () =>
      this.resetForm()
    );
  };

  handleSaveCondition = () => {
    const { condition } = this.state;
    condition.meta_title_arabic=condition.meta_title_arabic||condition.metaTitle
    condition.meta_description_arabic=condition.meta_description_arabic||condition.metaDescription
    condition.keywords_arabic=condition.keywords_arabic||condition.keywords
    const errors = this.validate(condition);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    if (condition.internalCategory == "None") {
      condition.internalCategory = null;
    }

    this.saveCondition(condition);
  };

  validate = (condition) => {
    const errors = {};
    const {
      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      country,
      conditionCategoryId,

      shortDescriptionEnglish,
      shortDescriptionArabic,
      longDescriptionEnglish,
      longDescriptionArabic,
      keyHighlightsEnglish,
      keyHighlightsArabic,
      disclaimerEnglish,
      disclaimerArabic,
      image,
      seoUrl,
      metaTitle,
      metaDescription,
      treatmentPlanTitleEnglish,
      treatmentPlanTitleArabic,
      treatmentPlanDescriptionEnglish,
      treatmentPlanDescriptionArabic,
      addOnTitleEnglish,
      addOnTitleArabic,
      addOnDescriptionEnglish,
      addOnDescriptionArabic,
      keywords,
    } = condition;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    if (conditionCategoryId === null || conditionCategoryId === undefined)
      errors.category = "Category cannot be blank";

    // Validate Descriptions Section
    if (shortDescriptionEnglish && shortDescriptionEnglish.length > 1000)
      errors.shortDescriptionEnglish =
        "Short Description (English) is limited to a 1000 characters";

    if (shortDescriptionArabic && shortDescriptionArabic.length > 1000)
      errors.shortDescriptionArabic =
        "Short Description (Arabic) is limited to a 1000 characters";

    if (longDescriptionEnglish && longDescriptionEnglish.length > 20000)
      errors.longDescriptionEnglish =
        "Long Description (English) is limited to a 20000 characters";

    if (longDescriptionArabic && longDescriptionArabic.length > 20000)
      errors.longDescriptionArabic =
        "Long Description (Arabic) is limited to a 20000 characters";

    if (keyHighlightsEnglish && keyHighlightsEnglish.length > 5000)
      errors.keyHighlightsEnglish =
        "Key Highlights (English) is limited to a 5000 characters";

    if (keyHighlightsArabic && keyHighlightsArabic.length > 5000)
      errors.keyHighlightsArabic =
        "Key Highlights (Arabic) is limited to a 5000 characters";

    if (disclaimerEnglish && disclaimerEnglish.length > 5000)
      errors.disclaimerEnglish =
        "Disclaimer (English) is limited to a 5000 characters";

    if (disclaimerArabic && disclaimerArabic.length > 5000)
      errors.disclaimerArabic =
        "Disclaimer (Arabic) is limited to a 5000 characters";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    if (seoUrl && seoUrl.length > 100)
      errors.seoUrl = "URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > 100)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > 200)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.keywords = "keywords can not contain more than 500 characters!";

    if (treatmentPlanTitleEnglish && treatmentPlanTitleEnglish.length > 500)
      errors.treatmentPlanTitleEnglish =
        "Treatment Plan Title (English) is limited to a 500 characters";

    if (treatmentPlanTitleArabic && treatmentPlanTitleArabic.length > 500)
      errors.treatmentPlanTitleArabic =
        "Treatment Plan Title (Arabic) is limited to a 500 characters";

    if (
      treatmentPlanDescriptionEnglish &&
      treatmentPlanDescriptionEnglish.length > 500
    )
      errors.treatmentPlanDescriptionEnglish =
        "Treatment Plan Description (English) is limited to a 500 characters";

    if (
      treatmentPlanDescriptionArabic &&
      treatmentPlanDescriptionArabic.length > 500
    )
      errors.treatmentPlanDescriptionArabic =
        "Treatment Plan Description (Arabic) is limited to a 500 characters";

    if (addOnTitleEnglish && addOnTitleEnglish.length > 500)
      errors.addOnTitleEnglish =
        "Add-on Title (English) is limited to a 500 characters";

    if (addOnTitleArabic && addOnTitleArabic.length > 500)
      errors.addOnTitleArabic =
        "Add-on Title (Arabic) is limited to a 500 characters";

    if (addOnDescriptionEnglish && addOnDescriptionEnglish.length > 500)
      errors.addOnDescriptionEnglish =
        "Add-on Description (English) is limited to a 500 characters";

    if (addOnDescriptionArabic && addOnDescriptionArabic.length > 500)
      errors.addOnDescriptionArabic =
        "Add-on Description (Arabic) is limited to a 500 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveCondition = async (condition) => {
    try {
      this.showLoading();
      const payload = { ...condition };
      delete payload.conditionCategoryName;
      delete payload["image_src"];
      delete payload["valeoPartnerId"];
      delete payload["valeoPartnerName"];
      if (typeof condition.image !== "string") delete payload["image"];

      const formData = new FormData();
      formData.append("condition", JSON.stringify(payload));
      if (typeof condition.image !== "string")
        formData.append("image", condition.image);

      const options = {
        method: condition.id ? "PATCH" : "POST",
        url: condition.id
          ? `${BASE_URL_CONDITIONS}${condition.id}`
          : BASE_URL_CONDITIONS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(options);

      console.log("Response: ", response);
      this.showSuccessNotification(
        condition.id
          ? "Condition Updated Successfully."
          : "Condition Created Successfully."
      );
      this.hideConditionViewModal();
      this.fetchConditions();
    } catch (error) {
      let seoExceptionCode = error.response?.status ?? null;
      if (seoExceptionCode === 500) {
        this.showErrorNotification("seo url already exists: " + error);
      } else {
        this.showErrorNotification("Some error occurred: " + error.message);
      }
    } finally {
      this.hideLoading();
    }
  };

  createRankOptions = () => {
    const { isNewCondition } = this.state;
    const conditions = this.getConditionsForCurrentCountry();
    const ranks = conditions.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, conditions.length) || 0;
    const until = isNewCondition ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });

    return rankOptions;
  };

  resetForm = () => {
    this.setState({ condition: {}, errors: {}, editables: {} });
  };

  renderViewButton = (questionId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCondition(questionId);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.hasPageAccessRight("edit") && !this.state.isNewCondition ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    handleSelectPartner=(partnerId)=>{
  
      const {condition, valeoPartnerOptions, valeoPartners}=this.state || {}
      if(partnerId==="select"){
        const newCondition={...condition, partnerId:null, valeoPartnerId: null,valeoPartnerName:"SELECT"}
        this.setState({condition:newCondition})
      }
      else{
        const selectedId = valeoPartners.find(
          (it) => it.id === partnerId 
        ) ?? {};
      
        const newCondition={...condition, partnerId:selectedId.id ?? null, valeoPartnerId:selectedId.id ?? null,valeoPartnerName:selectedId.name ?? ""}
        this.setState({condition:newCondition})
      }
    }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredConditions = () => {
    const conditions = this.getConditionsForCurrentCountry();
    return this.getSearchedConditions(conditions);
  };

  getConditionsForCurrentCountry = () => {
    const { selectedCountry } = this.props;
    const { conditions = [] } = this.state;
    if (
      !selectedCountry ||
      selectedCountry.id === null ||
      selectedCountry.id === undefined
    ) {
      console.log("Invalid country", selectedCountry);
      return [];
    }
    return conditions.filter(
      (condition) => condition.country === selectedCountry.id
    );
  };

  getSearchedConditions = (conditions = []) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return conditions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return conditions.filter((condition) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
      } = condition;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  createPartnerOption=(supplement)=>{
    return {
     key:supplement.valeoPartnerId,
     value:supplement.valeoPartnerId ,
     label:supplement.valeoPartnerName ,
    }
   }

  render() {
    const {
      condition,
      isNewCondition,
      isConditionViewModalVisible,
      searchTerm,
      searchApplied,
      errors,
      editables,
      articles,
      medicines,
      supplements,
      valeoPartnerOptions
    } = this.state;

    const filteredConditions = this.getFilteredConditions();
    const selectedCountry = this.props.selectedCountry;
    const isEditable = this.hasPageAccessRight("edit");

    const  selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={condition?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={condition?.status}
                onChange={(event, value) => {
                  this.setState({
                    condition: {
                      ...condition,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCondition && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">Name (English)*</label>
            <div className="input-group">
              <input
                value={condition?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={condition?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={condition?.rank}
                onChange={(event, value) => {
                  this.setState({
                    condition: { ...condition, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewCondition && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewCondition
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(condition?.country)?.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="category">Category*</label>
            <div className="input-group">
              <ConditionCategoriesDropdown
                id="category"
                name="category"
                categories={
                  this.props.categories
                    ? this.props.categories.filter(
                        (it) => it.country === this.props.selectedCountry?.id
                      )
                    : []
                }
                selectedCategory={
                  condition && this.findCategory(condition.conditionCategoryId)
                }
                onSelect={(event) => {
                  const newCondition = {
                    ...condition,
                    conditionCategoryId: event.value,
                  };
                  this.setState({ condition: newCondition });
                }}
                isDisabled={!isNewCondition && !editables.category}
                className={
                  "form-control" + (errors.category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("category")}
            </div>
            <span className="help-block">{errors.category}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
                key={`my_unique_select_key__${condition && condition.partnerId}`}
                value={condition && this.createPartnerOption(condition)}
                onChange={(event) => {
                  this.handleSelectPartner(event.value);
                }}
                options={valeoPartnerOptions || []}
                style={selectStyles}
                placeholder="Select Parnter"
                searchable
                lazyLoad
              />
              {/* {this.renderPencilIcon("partner_id")} */}
            
            <span className="help-block">{errors.partner_id}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={condition?.internalCategory || "None"}
                onChange={(event, value) => {
                  this.setState({
                    condition: {
                      ...condition,
                      internalCategory: value.value,
                    },
                  });
                }}
                id="internalCategory"
                name="internalCategory"
                placeholder="None"
                search
                selection
                options={INTERNAL_CATEGORY_OPTIONS}
                disabled={!isNewCondition && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="shortDescriptionEnglish">
              Short Description (English)
            </label>
            <div className="input-group">
              <input
                value={condition.shortDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="shortDescriptionEnglish"
                name="shortDescriptionEnglish"
                type="text"
                placeholder="Short Description in English"
                className={
                  "form-control py-2" +
                  (errors.shortDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.shortDescriptionEnglish}
              />
              {this.renderPencilIcon("shortDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.shortDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="shortDescriptionArabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <input
                value={condition.shortDescriptionArabic || ""}
                onChange={this.handleChange}
                id="shortDescriptionArabic"
                name="shortDescriptionArabic"
                type="text"
                placeholder="Short Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.shortDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.shortDescriptionArabic}
              />
              {this.renderPencilIcon("shortDescriptionArabic")}
            </div>
            <span className="help-block">{errors.shortDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="longDescriptionEnglish">
              Long Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={condition.longDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="longDescriptionEnglish"
                name="longDescriptionEnglish"
                placeholder="Long Description in English"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.longDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("longDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.longDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="longDescriptionArabic">
              Long Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={condition.longDescriptionArabic || ""}
                onChange={this.handleChange}
                id="longDescriptionArabic"
                name="longDescriptionArabic"
                placeholder="Long Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.longDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("longDescriptionArabic")}
            </div>
            <span className="help-block">{errors.longDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keyHighlightsEnglish">
              Key Highlights (English)
            </label>
            <div className="input-group">
              <textarea
                value={condition.keyHighlightsEnglish || ""}
                onChange={this.handleChange}
                id="keyHighlightsEnglish"
                name="keyHighlightsEnglish"
                placeholder="Key Highlights in English"
                className={
                  "form-control py-2" +
                  (errors.keyHighlightsEnglish ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.keyHighlightsEnglish}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsEnglish")}
            </div>
            <span className="help-block">{errors.keyHighlightsEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyHighlightsArabic">Key Highlights (Arabic)</label>
            <div className="input-group">
              <textarea
                value={condition.keyHighlightsArabic || ""}
                onChange={this.handleChange}
                id="keyHighlightsArabic"
                name="keyHighlightsArabic"
                placeholder="Key Highlights in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keyHighlightsArabic ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.keyHighlightsArabic}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsArabic")}
            </div>
            <span className="help-block">{errors.keyHighlightsArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="disclaimerEnglish">Disclaimer (English)</label>
            <div className="input-group">
              <textarea
                value={condition.disclaimerEnglish || ""}
                onChange={this.handleChange}
                id="disclaimerEnglish"
                name="disclaimerEnglish"
                placeholder="Disclaimer in English"
                className={
                  "form-control py-2" +
                  (errors.disclaimerEnglish ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.disclaimerEnglish}
              ></textarea>
              {this.renderPencilIcon("disclaimerEnglish")}
            </div>
            <span className="help-block">{errors.disclaimerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="disclaimerArabic">Disclaimer (Arabic)</label>
            <div className="input-group">
              <textarea
                value={condition.disclaimerArabic || ""}
                onChange={this.handleChange}
                id="disclaimerArabic"
                name="disclaimerArabic"
                placeholder="Disclaimer in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.disclaimerArabic ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.disclaimerArabic}
              ></textarea>
              {this.renderPencilIcon("disclaimerArabic")}
            </div>
            <span className="help-block">{errors.disclaimerArabic}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="packageStartingFromTextEng">Text (English)</label>
            <div className="input-group">
              <input
                value={condition.packageStartingFromTextEng || ""}
                onChange={this.handleChange}
                id="packageStartingFromTextEng"
                name="packageStartingFromTextEng"
                type="text"
                placeholder="Text in English"
                className={
                  "form-control py-2" +
                  (errors.packageStartingFromTextEng ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.packageStartingFromTextEng
                }
              />
              {this.renderPencilIcon("packageStartingFromTextEng")}
            </div>
            <span className="help-block">
              {errors.packageStartingFromTextEng}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="packageStartingFromTextArabic">Text (Arabic)</label>
            <div className="input-group">
              <input
                value={condition.packageStartingFromTextArabic || ""}
                onChange={this.handleChange}
                id="packageStartingFromTextArabic"
                name="packageStartingFromTextArabic"
                type="text"
                placeholder="Text in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.packageStartingFromTextArabic ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.packageStartingFromTextArabic
                }
              />
              {this.renderPencilIcon("packageStartingFromTextArabic")}
            </div>
            <span className="help-block">
              {errors.packageStartingFromTextArabic}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="packageStartingFromPriceEng">Price (English)</label>
            <div className="input-group">
              <input
                value={condition.packageStartingFromPriceEng || ""}
                onChange={this.handleChange}
                id="packageStartingFromPriceEng"
                name="packageStartingFromPriceEng"
                type="text"
                placeholder="Price in English"
                className={
                  "form-control py-2" +
                  (errors.packageStartingFromPriceEng ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.packageStartingFromPriceEng
                }
              />
              {this.renderPencilIcon("packageStartingFromPriceEng")}
            </div>
            <span className="help-block">
              {errors.packageStartingFromPriceEng}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="packageStartingFromPriceArabic">
              Price (Arabic)
            </label>
            <div className="input-group">
              <input
                value={condition.packageStartingFromPriceArabic || ""}
                onChange={this.handleChange}
                id="packageStartingFromPriceArabic"
                name="packageStartingFromPriceArabic"
                type="text"
                placeholder="Price in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.packageStartingFromPriceArabic ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.packageStartingFromPriceArabic
                }
              />
              {this.renderPencilIcon("packageStartingFromPriceArabic")}
            </div>
            <span className="help-block">
              {errors.packageStartingFromPriceArabic}
            </span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewCondition && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {condition?.image_src && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={condition.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Treatment Plan Description</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="treatmentPlanTitleEnglish">
              Treatment Plan Title (English)
            </label>
            <div className="input-group">
              <input
                value={condition.treatmentPlanTitleEnglish || ""}
                onChange={this.handleChange}
                id="treatmentPlanTitleEnglish"
                name="treatmentPlanTitleEnglish"
                type="text"
                placeholder="Treatment Plan Title in English"
                className={
                  "form-control py-2" +
                  (errors.treatmentPlanTitleEnglish ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.treatmentPlanTitleEnglish
                }
              />
              {this.renderPencilIcon("treatmentPlanTitleEnglish")}
            </div>
            <span className="help-block">
              {errors.treatmentPlanTitleEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="treatmentPlanTitleArabic">
              Treatment Plan Title (Arabic)
            </label>
            <div className="input-group">
              <input
                value={condition.treatmentPlanTitleArabic || ""}
                onChange={this.handleChange}
                id="treatmentPlanTitleArabic"
                name="treatmentPlanTitleArabic"
                type="text"
                placeholder="Treatment Plan Title in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.treatmentPlanTitleArabic ? " has-error" : "")
                }
                disabled={
                  !isNewCondition && !editables.treatmentPlanTitleArabic
                }
              />
              {this.renderPencilIcon("treatmentPlanTitleArabic")}
            </div>
            <span className="help-block">
              {errors.treatmentPlanTitleArabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="treatmentPlanDescriptionEnglish">
              Treatment Plan Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={condition.treatmentPlanDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="treatmentPlanDescriptionEnglish"
                name="treatmentPlanDescriptionEnglish"
                placeholder="Treatment Plan Description in English"
                className={
                  "form-control py-2" +
                  (errors.treatmentPlanDescriptionEnglish ? " has-error" : "")
                }
                readOnly={
                  !isNewCondition && !editables.treatmentPlanDescriptionEnglish
                }
              ></textarea>
              {this.renderPencilIcon("treatmentPlanDescriptionEnglish")}
            </div>
            <span className="help-block">
              {errors.treatmentPlanDescriptionEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="treatmentPlanDescriptionArabic">
              Treatment Plan Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={condition.treatmentPlanDescriptionArabic || ""}
                onChange={this.handleChange}
                id="treatmentPlanDescriptionArabic"
                name="treatmentPlanDescriptionArabic"
                placeholder="Treatment Plan Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.treatmentPlanDescriptionArabic ? " has-error" : "")
                }
                readOnly={
                  !isNewCondition && !editables.treatmentPlanDescriptionArabic
                }
              ></textarea>
              {this.renderPencilIcon("treatmentPlanDescriptionArabic")}
            </div>
            <span className="help-block">
              {errors.treatmentPlanDescriptionArabic}
            </span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Add-on Description</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="addOnTitleEnglish">Add-on Title (English)</label>
            <div className="input-group">
              <input
                value={condition.addOnTitleEnglish || ""}
                onChange={this.handleChange}
                id="addOnTitleEnglish"
                name="addOnTitleEnglish"
                type="text"
                placeholder="Add-on Title in English"
                className={
                  "form-control py-2" +
                  (errors.addOnTitleEnglish ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.addOnTitleEnglish}
              />
              {this.renderPencilIcon("addOnTitleEnglish")}
            </div>
            <span className="help-block">{errors.addOnTitleEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="addOnTitleArabic">Add-on Title (Arabic)</label>
            <div className="input-group">
              <input
                value={condition.addOnTitleArabic || ""}
                onChange={this.handleChange}
                id="addOnTitleArabic"
                name="addOnTitleArabic"
                type="text"
                placeholder="Add-on Title in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.addOnTitleArabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.addOnTitleArabic}
              />
              {this.renderPencilIcon("addOnTitleArabic")}
            </div>
            <span className="help-block">{errors.addOnTitleArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="addOnDescriptionEnglish">
              Add-on Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={condition.addOnDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="addOnDescriptionEnglish"
                name="addOnDescriptionEnglish"
                placeholder="Add-on in English"
                className={
                  "form-control py-2" +
                  (errors.addOnDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.addOnDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("addOnDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.addOnDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="addOnDescriptionArabic">
              Add-on Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={condition.addOnDescriptionArabic || ""}
                onChange={this.handleChange}
                id="addOnDescriptionArabic"
                name="addOnDescriptionArabic"
                placeholder="Add-on Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.addOnDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.addOnDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("addOnDescriptionArabic")}
            </div>
            <span className="help-block">{errors.addOnDescriptionArabic}</span>
          </div>
        </div>
      </div>
    );

    const formDiagnosticComponent = (
      <div className="container mt-4">
        <QuestionnairesForm
          questionnaires={condition.conditionDiagnostics || []}
          onQuestionnairesUpdated={this.handleQuestionnairesUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formSolutionsComponent = (
      <div className="container mt-4">
        <ConditionSolutionsForm
          countryId={selectedCountry?.id}
          solutions={condition.solution || []}
          onSolutionsUpdated={this.handleSolutionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formSeoMarketing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL</label>
            <div className="input-group">
              <input
                value={(condition && condition.seoUrl) || ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={(condition && condition.url_name_arabic) || ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(condition && condition.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(condition && condition.meta_title_arabic) || ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(condition && condition.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={(condition && condition.meta_description_arabic) || ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewCondition && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(condition && condition.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(condition && condition.keywords_arabic) || ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewCondition && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
      </div>
    );

    const formFAQTable = (
      <div className="container mt-4">
        <FAQForm
          questions={condition?.conditionFaqs || []}
          onQuestionUpdated={this.handleQuestionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formTreatmentPlanSuggestions=(
      <div className="container mt-4">
        <TreatmentPlanSuggestionsForm
          countryId={selectedCountry?.id ?? null}
          cities={this.props.cities ?? []}
          treatmentPlanSuggestions={condition.treatmentPlanSuggestions ?? []}
          onTreatmentPlanSuggestionsUpdated={this.handleTreatmentPlansUpdated}
          medicines={medicines ?? []}
          supplements={supplements ?? []}
        />
      </div>
    )
    const formSuggestions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedArticles">Suggested Articles</label>
            <div className="input-group user-input-container">
              <SelectArticles
                id="suggestedArticles"
                name="suggestedArticles"
                articles={
                  articles && articles.length && selectedCountry
                    ? articles.filter(
                        (article) => article.country === selectedCountry.id
                      )
                    : []
                }
                selectedArticleIds={condition?.suggestedArticles ?? []}
                onSelect={(options) => {
                  const newCondition = {
                    ...condition,
                    suggestedArticles: options.map((it) => it.value),
                  };
                  this.setState({ condition: newCondition });
                }}
                showOnlyActive={true}
                isDisabled={!isNewCondition && !editables.suggestedArticles}
              />
              {this.renderPencilIcon("suggestedArticles")}
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedSupplements">Suggested Medicines</label>
            <div className="input-group user-input-container">
              <SelectMedicines
                id="suggestedMedicines"
                name="suggestedMedicines"
                medicines={medicines ?? []}
                selectedMedicineIds={condition?.suggestedMedicines ?? []}
                onSelect={(options) => {
                  const newCondition = {
                    ...condition,
                    suggestedMedicines: options.map((it) => it.value),
                  };
                  this.setState({ condition: newCondition });
                }}
                showOnlyActive={true}
                isDisabled={!isNewCondition && !editables.suggestedMedicines}
              />
              {this.renderPencilIcon("suggestedMedicines")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="suggestedSupplements">Suggested Supplements</label>
            <div className="input-group user-input-container">
              <SelectSupplements
                id="suggestedSupplements"
                name="suggestedSupplements"
                supplements={supplements ?? []}
                selectedSupplementIds={condition?.suggestedSupplements ?? []}
                onSupplementSelect={(options) => {
                  const newCondition = {
                    ...condition,
                    suggestedSupplements: options.map((it) => it.value),
                  };
                  this.setState({ condition: newCondition });
                }}
                showOnlyActive={true}
                menuPlacement="bottom"
                isDisabled={!isNewCondition && !editables.suggestedSupplements}
              />
              {this.renderPencilIcon("suggestedSupplements")}
            </div>
          </div>
        </div> */}
        {formTreatmentPlanSuggestions}
      </div>
    );

  
    const formTestimonils = (
      <div className="container mt-4">
        <TestimonialsForm
          countryId={selectedCountry?.id ?? null}
          cities={this.props.cities ?? []}
          testimonials={condition.testimonials ?? []}
          onTestimonialsUpdated={this.handleTestimonialsUpdated}
        />
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewCondition}
                disabled={!this.hasPageAccessRight("edit")}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body className="mt-2">
          <p className="font-weight-bold">Conditions</p>
          <DataTable
            id="conditionsTable"
            data={filteredConditions || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredConditions.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadErrorQuestions ? (
                <AlertBox
                  message={this.state.loadErrorQuestions}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isConditionViewModalVisible}
          onHide={this.hideConditionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Conditions: </span>
                {condition?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveCondition}
              disabled={!this.hasPageAccessRight("edit")}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_questions" fill justify>
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_form_descriptions" title="Descriptions">
                {formDescriptions}
              </Tab>
              <Tab
                eventKey="key_form_diagnostic_component"
                title="Diagnostic Component"
              >
                {formDiagnosticComponent}
              </Tab>
              <Tab
                eventKey="key_form_solutions_component"
                title="Solutions Component"
              >
                {formSolutionsComponent}
              </Tab>
              <Tab eventKey="key_seo_form" title="SEO Marketing">
                {formSeoMarketing}
              </Tab>
              <Tab eventKey="key_form_faq_table" title="FAQ Table">
                {formFAQTable}
              </Tab>
              <Tab eventKey="key_form_suggestions" title="Suggestions">
                {formSuggestions}
              </Tab>
              <Tab eventKey="key_form_testimonials" title="Testimonials">
                {formTestimonils}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Conditions);
