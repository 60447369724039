import React, { useState } from 'react'
import "./skincareSurvey.scss";
import { useEffect } from 'react';
import PhoenixAPI from "utils/PhoenixAPI";
function SkincareSurvey() {

    const [skincareSurveyData, setSkincareSurveyData] = useState([]);
    const [skincareSurveyDummyData, setSkincareSurveyDummyData] = useState([]);
    const [quesAnsData, setQuesAnsData] = useState([]);
    const [particularUserId, setparticularUserId] = useState("");
    const [particularDeviceId, setparticularDeviceId] = useState("")
    const [isShowQuestion, setIsShowQuestion] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    async function fetcthSkincareSurveyData(questionId) {
        try {
            const res = await PhoenixAPI.get(`/treatment-plan-survey-answers?questionnaireId=${questionId}`);
            const data = res && res.data || [];
            setSkincareSurveyData([...data]);
            setSkincareSurveyDummyData([...data]);
            const dummy = [];
            data.length>0 && data.map((item, index) => {
               item.length>0 && item.map((item, index) => {
                    item.isShowQuestionstatus = false;
                })
                dummy.push({ isShowQuestionstatus: false });
            })
            setIsShowQuestion([...dummy]);
        }
        catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        const questionId= process.env.NODE_ENV === "development" ? process.env.REACT_APP_QA_SKIN_CARE_QUESTIONNIARE_ID:process.env.REACT_APP_PRODUCTION_SKIN_CARE_QUESTIONNIARE_ID;
        fetcthSkincareSurveyData(questionId);
    }, [])
   
    function handleQuestionAnswer(user, index) {
        let dummy=[]
            dummy=isShowQuestion.length>0 && isShowQuestion.map((item, ind) => {
                if (ind == index) {
                    if (!item.isShowQuestionstatus) {
                        setQuesAnsData([...user]);
                        setparticularUserId(user[0].user_id);
                        setparticularDeviceId(user[0].device_id);
                    }
                    else {
                        setQuesAnsData([]);
                        setparticularUserId("");
                        setparticularDeviceId("");
                    }
                    return { isShowQuestionstatus: !item.isShowQuestionstatus }
                }
                else {
                    return { isShowQuestionstatus: false }
                }
            })
        
        
        setIsShowQuestion([...dummy]);
    }

    function handleSearch(e) {
        setQuesAnsData([])
        //   if(e.key==="Enter"){
        setSearchInput(e.target.value);
        console.log("object",e.target.value)
        if (e.target.value) {
            const filterData =skincareSurveyDummyData.length>0 && skincareSurveyDummyData.filter((item) => {
                const str =  item[0].user_id +"";
                const str2 = item[0].email && item[0].email.toLowerCase()||"";
                const str3=  item[0].question_id+"";
                const str4=  item[0].plain_text && item[0].plain_text.toLowerCase()||"";
                const str5= item[0].device_id && item[0].device_id.toLowerCase()||"";
                // const str4= item[0].first_name && item[0].first_name.toLowerCase()||"";
                // const str5=item[0].last_name && item[0].last_name.toLowerCase() || "";
                // const userName=str4+str5; 
                return str5.includes(e.target.value.toLowerCase())||str4.includes(e.target.value.toLowerCase())||str2.includes(e.target.value.toLowerCase())||str3.includes(e.target.value)||str.includes(e.target.value);
            })
            setSkincareSurveyData([...filterData]);

        
        }
        else {
            setSkincareSurveyData([...skincareSurveyDummyData])
        }
        //  }
        let dummy;
     dummy =isShowQuestion.length>0 && isShowQuestion.map((item, index) => {
          return { isShowQuestionstatus: false };
    })
      setIsShowQuestion([...dummy]);
    }
    function handleInputRemove() {
        setSearchInput("");
        setSkincareSurveyData([...skincareSurveyDummyData])
       const  dummy =isShowQuestion.length>0 && isShowQuestion.map((item, index) => {
            return { isShowQuestionstatus: false };
      })
      setIsShowQuestion([...dummy])
    }
    return (
        <div className='skincareSurvey-main'>
            <div className='skincareSurvey-left-cont'>
                <div className='skincareSurvey-searchBox'>
                    <input value={searchInput} type="text" placeholder='Search by ID / Email ID' onChange={(e) => handleSearch(e)} />
                    {searchInput ? <div id='cross-sign'><span onClick={() => handleInputRemove()}>&#215;</span> </div> : ""}
                </div>
                {skincareSurveyData.length > 0 ? <div className='user-list-wrapper'>
                    <div className='user-list-wrapper-heading'>
                        <span className='text1'>Sl.no</span>
                        <span className='text2'>User Details</span>
                    </div>
                    <div className='list-container'>
                        <div className='user-card-wrapper'>
                            {skincareSurveyData.length > 0 && skincareSurveyData.map((user, index) => {
                                const { email="", user_id="", first_name="", last_name="",plain_text="",question_id="",device_id="" } = user[0] || {};
                                 const questionIdData=user.length>0 && user.filter((ques)=>{
                                    return ques.question_id==108 || ques.question_id==62
                                 })
                                 console.log("object",questionIdData)
                                return <div className='user-card' onClick={() => handleQuestionAnswer(user, index)} style={{ backgroundColor: isShowQuestion[index] && isShowQuestion[index].isShowQuestionstatus ? "#CEE741" : "" }} >
                                    <div className='si-no'>{index + 1}.</div>
                                    <div className='user-detail'>
                                        <div className='detail-1'>
                                            <p>User ID : <span>{user_id ? user_id : ""}</span></p>
                                            {user_id?"":<p>{device_id?"Device ID :":""} <span style={{fontWeight:"normal"}}>{device_id ?device_id:""}</span></p>}
                                            <p>UserName : {first_name ? first_name : "" + " " + last_name ? last_name : ""}</p>
                                            <p>Email : {email}</p>
                                        </div>
                                        {questionIdData.length>0? <div className='detail-2'>
                                            <span className='text1'>Questionnaire Email</span>
                                         <div className='text2'>
                                                <input type="text" value={ questionIdData.length>0 && questionIdData[0].plain_text} readOnly style={{backgroundColor:isShowQuestion[index] && isShowQuestion[index].isShowQuestionstatus?"#C2D268":"#F2F2F2"}} />
                                            </div> 
                                         
                                        </div>:""}
                                    </div>
                                    <div className='greater-sign'>{isShowQuestion[index] && isShowQuestion[index].isShowQuestionstatus ? <span>&gt;</span> : ""}</div>
                                </div>
                            })}

                        </div>
                    </div>
                </div> :
                    <div className='no-result'>
                        <p>Found 0 results</p>
                        <div>
                            <div className='exlamation-icon'><span >&#161;</span></div>
                            <div className='nothing-txt'>There's nothing here.</div>
                        </div>

                    </div>}

            </div>
            <div className='skincareSurvey-right-cont'>
                {quesAnsData.length > 0 && <div className='ques-ans-cont'>
                   
                    <div className='ques-ans-heading'>
                        <div className='survey-ques-txt'>Survey Questionnaire (Submitted Time : {quesAnsData[0].created_at && quesAnsData[0].created_at.slice(0,10)} {quesAnsData[0].created_at && quesAnsData[0].created_at.slice(11,19)})</div>
                        <div> {particularUserId?"User ID :":"Device ID :"} <span id='id-no' style={{fontWeight:particularDeviceId?"normal":""}}>{particularUserId?particularUserId:particularDeviceId}</span></div>
                    </div>
                    <div className='ques-ans-wrapper'>
                        {quesAnsData.length > 0 && quesAnsData.map((item, index) => {
                            return <div className='ques-ans-box'>
                                <div className='ques'>{index + 1}. {item.question_english}</div>
                                <div className='ans-container' >
                                    {item.options.length>0 && item.options.map((ans) => {
                                        return <>
                                        {ans?<div className='ans' style={{width:item.options.length==1 && !item.plain_text?"99%":""}}>{ans}</div>:""}
                                        </>
                                    })}
                                    {item.plain_text?<div className='ans' style={{width:item.options.length==0 &&item.plain_text ?"99%":""}}>{item.plain_text}</div>:""}
                                </div>
                            </div>
                        })}

                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default SkincareSurvey
