import React from "react";
import { Card } from "react-bootstrap";

function OrderNotesCard(props) {
  const { orderNotes, isEditable = true, onEdit } = props;

  const orderNotesView = orderNotes ? (
    <p>{orderNotes}</p>
  ) : (
    <span className="placeholder">No notes for the order!</span>
  );

  return (
    <Card>
      <Card.Body>
        <div className="row">
          <div className="col-8">
            <p className="h6">OPs Order Notes</p>
          </div>
          {isEditable && (
            <div className="col text-right">
              <span
                onClick={onEdit}
                style={{ cursor: "pointer", color: "#2D9BF0" }}
              >
                Edit
              </span>
            </div>
          )}
        </div>
        <div className="row mt-2">
          <div className="col">{orderNotesView}</div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default OrderNotesCard;
