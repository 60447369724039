import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectSupplements.propTypes = {
  countryId: PropTypes.number.isRequired,
  selectedSupplementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSupplementSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectSupplements.defaultProps = {
  selectedSupplementIds: [],
  onSupplementSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectSupplements(props) {
  const {
    countryId,
    selectedSupplementIds,
    onSupplementSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
    couponType,
  } = props;

  const [supplements, setSupplements] = useState([]);

  useEffect(() => {
    if (!countryId) {
      console.log("Invalid country id!", { countryId });
      return;
    }

    const url = `supplement/summary/?country=${countryId}`;
    const fetchSupplements = async () => {
      try {
        const { data: supplements = [] } = await PhoenixAPI.get(url);
        if(couponType=="couponExclude"){
          setSupplements(supplements);
         }
        else{
        setSupplements(supplements.filter((supplement)=>!supplement.isCouponExcluded));
        }
      } catch (error) {
        console.log("Error on fetching supplements", error);
      }
    };
    fetchSupplements();
  }, [countryId]);

  const findSupplement = (supplementId) => {
    return supplements.find((supplement) => supplement.id === supplementId);
  };

  const [activeSupplements, inactiveSupplements] = _.partition(
    supplements,
    (it) => it.status === "Active"  
  );
  activeSupplements.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  inactiveSupplements.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  const options = showOnlyActive
    ? activeSupplements.map(createOption)
    : [
        {
          label: "Active",
          options: activeSupplements.map(createOption),
        },
        {
          label: "Inactive",
          options: inactiveSupplements.map(createOption),
        },
      ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedSupplements = selectedSupplementIds
    .map(findSupplement)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedSupplementIds && selectedSupplementIds.join(",")
      }`}
      value={selectedSupplements}
      onChange={onSupplementSelect}
      options={options}
      styles={selectStyles}
      // placeholder="Select Supplements"
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (supplement) => {
  if (!supplement) return;
  return {
    key: supplement.id,
    value: supplement.id,
    label: supplement.internalName,
  };
};

export default SelectSupplements;
