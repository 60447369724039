import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward, PhoneEnabledTwoTone } from "@material-ui/icons";
import { formatDateTime } from "utils/commons";
import Status from "components/Common/Status";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import Search from "components/Search/Search";
import moment from "moment";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
class sampleCollectionCharges extends Component {
  constructor(props) {
    super(props);

    const {
      homecareId = null,
      countryId = null,
      cityId = null,
      allPersons = [],
      selectedPerson = null,
      sampleCollectionObject = null,
      handleHideSampleCollection = (f) => f,
    } = this.props;

    this.handleHideSampleCollection = handleHideSampleCollection;
    this.state = {
      sampleCollectionChargesLogs: [],
      selectedPerson: {},
      cityId,
      countryId,
      homecareId,
      startDate: null,
      errors: {},
      sampleCollectionObject,
    };

    this.columns = [
      {
        name: "From Date",
        selector: "fromDate",
        wrap: true,
        sortable: true,
      },
      {
        name: "To Date",
        selector: "toDate",
        wrap: true,
        sortable: true,
      },
      {
        name: "Person 1 ",
        selector: "person1Cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Person 2",
        selector: "person2Cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Person 3",
        selector: "person3Cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Person 4",
        selector: "person4Cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Person 5",
        selector: "person5Cost",
        wrap: true,
        sortable: true,
      },
      {
        name: "Created By",
        center: true,
        selector: "createdByName",
        sortable: true,
        wrap: true,
      },
    ];
  }
  componentDidMount() {
    this.fetchPersonsStatusLogs();
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.sampleCollectionChargesLogs !==
      nextProps.sampleCollectionChargesLogs
    ) {
      this.setState({
        countryId: nextProps.countryId,
        cityId: nextProps.cityId,
        homecareId: nextProps.homecareId,
        allPersons: nextProps.allPersons,
        selectedPerson: nextProps.selectedPerson,
        sampleCollectionObject: nextProps.sampleCollectionObject,
      });
    }
  }

  fetchPersonsStatusLogs = async () => {
    const { homecareId = null, cityId = null } = this.props;
    if (!homecareId || !cityId) {
      console.log("Homecare Id or cityId is not found", homecareId, cityId);
      return;
    }
    try {
      this.showLoading();
      const { data: statusLogs = [] } = await PhoenixAPI.get(
        `homecare-sample-collection-cost-logs?homecareId=${homecareId}&cityId=${cityId}`
      );
      this.setState({ sampleCollectionChargesLogs: statusLogs });
    } catch (error) {
      console.log("Error fetching status logs", error.message);
    } finally {
      this.hideLoading();
    }
  };
  showSelectedPackageViewModal = () => {
    this.setState({ isSelectedPackageViewModalVisible: true });
  };

  hideSelectedPackageModal = () => {
    this.setState({
      isSelectedPackageViewModalVisible: false,
      selectedPerson: {},
    });
  };

  handleAddNewCost = () => {
    const { sampleCollectionObject, selectedPerson } = this.props;
    this.setState({
      isAddCostViewModalVisible: true,
      selectedPerson: {
        ...selectedPerson,
        cost: null,
        startDate: "",
      },
    });
  };

  hideSelectedPackageCostModal = () => {
    this.setState(
      {
        isAddCostViewModalVisible: false,
        errors: {},
      },
      () => this.handleHideSampleCollection(true)
    );
  };

  handleChangePackageCost = (event) => {
    const target = event.target;
    const selectedPerson = this.state.selectedPerson;
    this.setState({
      selectedPerson: {
        ...selectedPerson,
        [target.name]: target.value,
      },
    });
  };

  validate = () => {
    const errors = {};
    const { startDate = "", cost = null } = this.state.selectedPerson;
    const today = moment().format("YYYY-MM-DD");
    const formattedDate = moment(startDate, "YYYY-MM-DD", true);
    console.log("formattedDateformattedDate", formattedDate);
    if (
      !startDate === null ||
      startDate === undefined ||
      startDate.toString().trim() === ""
    ) {
      errors.startDate = "Date is required field";
    } else if (!formattedDate.isSameOrAfter(today)) {
      errors.startDate = "Date should not be past date";
    }
    if (cost === null || cost === undefined || cost.toString().trim() === "") {
      errors.costs = "Cost is required field";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSubmit = async () => {
    const { selectedPerson, sampleCollectionObject } = this.state;
    const createdBy = getUserId();
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = {
      homecareId: selectedPerson.homecareId,
      countryId: selectedPerson.countryId,
      cityId: selectedPerson.cityId,
      createdById: parseInt(createdBy),
      fromDate: selectedPerson.startDate,
    };
    if (selectedPerson.person === 1) {
      payload.person1Cost = parseInt(selectedPerson.cost);
      payload.person2Cost = sampleCollectionObject?.person2Cost ?? 0;
      payload.person3Cost = sampleCollectionObject?.person3Cost ?? 0;
      payload.person4Cost = sampleCollectionObject?.person4Cost ?? 0;
      payload.person5Cost = sampleCollectionObject?.person5Cost ?? 0;
    } else if (selectedPerson.person === 2) {
      payload.person1Cost = sampleCollectionObject?.person1Cost ?? 0;
      payload.person2Cost = parseInt(selectedPerson.cost);
      payload.person3Cost = sampleCollectionObject?.person3Cost ?? 0;
      payload.person4Cost = sampleCollectionObject?.person4Cost ?? 0;
      payload.person5Cost = sampleCollectionObject?.person5Cost ?? 0;
    } else if (selectedPerson.person === 3) {
      payload.person1Cost = sampleCollectionObject?.person1Cost ?? 0;
      payload.person2Cost = sampleCollectionObject?.person2Cost ?? 0;
      payload.person3Cost = parseInt(selectedPerson.cost);
      payload.person4Cost = sampleCollectionObject?.person4Cost ?? 0;
      payload.person5Cost = sampleCollectionObject?.person5Cost ?? 0;
    } else if (selectedPerson.person === 4) {
      payload.person1Cost = sampleCollectionObject?.person1Cost ?? 0;
      payload.person2Cost = sampleCollectionObject?.person2Cost ?? 0;
      payload.person3Cost = sampleCollectionObject?.person3Cost ?? 0;
      payload.person4Cost = parseInt(selectedPerson.cost);
      payload.person5Cost = sampleCollectionObject?.person5Cost ?? 0;
    } else if (selectedPerson.person === 5) {
      payload.person1Cost = sampleCollectionObject?.person1Cost ?? 0;
      payload.person2Cost = sampleCollectionObject?.person2Cost ?? 0;
      payload.person3Cost = sampleCollectionObject?.person3Cost ?? 0;
      payload.person4Cost = sampleCollectionObject?.person4Cost ?? 0;
      payload.person5Cost = parseInt(selectedPerson.cost);
    }

    try {
      this.showLoading();
      const response = await PhoenixAPI.post(
        "homecare-sample-collection-costs",
        payload
      );
      this.showSuccessNotification("Cost Updated Successfully");
      this.hideSelectedPackageCostModal();

      this.handleHideSampleCollection(true);
    } catch (error) {
      console.log("Error in Saving Cost", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      sampleCollectionChargesLogs,
      selectedPerson,
      searchTerm,
      searchApplied,
      errors,
    } = this.state;

    const addPackageCostForm = (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <label>Start Date *</label>
            <input
              name="startDate"
              type="date"
              placeholder="start date"
              min={this.state.startDate}
              // disabled={!editConfirmTime}
              className={
                "form-control py-2" + (errors.startDate ? " has-error" : "")
              }
              onChange={this.handleChangePackageCost}
              value={this.state.startDate}
            ></input>
            <span className="help-block">{errors.startDate}</span>
          </div>
          <div className="form-group col">
            <label>Add Cost *</label>
            <input
              type="number"
              name="cost"
              className={
                "form-control py-2" + (errors.costs ? " has-error" : "")
              }
              placeholder="Add Costs"
              onChange={this.handleChangePackageCost}
              value={this.state.cost}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
            ></input>
            <span className="help-block">{errors.costs}</span>
          </div>
        </div>
      </div>
    );
    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={sampleCollectionChargesLogs || []}
                paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                pagination
                responsive
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewCost}
                // disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New Cost
              </button>
            </Card>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.isAddCostViewModalVisible}
          onHide={this.hideSelectedPackageCostModal}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>{addPackageCostForm}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideSelectedPackageCostModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(sampleCollectionCharges);
