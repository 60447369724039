import React from "react";
import { connect } from "react-redux";
import MUIRichTextEditor from "mui-rte";
import { Modal } from "react-bootstrap";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState, convertToRaw } from "draft-js";
import { Checkbox } from "semantic-ui-react";
import _ from "lodash";
import moment from "moment";
import Range from "components/BiomarkerRange/Range";
import OrderDetailsCard from "./OrderDetailsCard";
import ClientDetailsCard from "./ClientDetailsCard";
import DownloadLabReportCard from "./DownloadLabReportCard";
import HealthProfileSurveyResultCard from "./HealthProfileSurveyResultCard";
import GenericSurveyResultsCard from "./GenericSurveyResultsCard";
import SelectSupplements from "components/Common/SelectSupplements";
import TestResultDetails from "./TestResultDetails";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { fetchCoachCustomerTransactions } from "services/UserService";
import SelectHowDoYouHearAboutUsOptions from "components/Common/SelectHowDoYouHearAboutUsOptions";
import ClientNotesTable from "./ClientNotesTable";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { NavLink } from "react-router-dom";
import { getUserEmail } from "services/UserService";
import { saveOrderStatusLogs } from "services/OrderService";
import SelectBloodPackages from "components/Common/SelectBloodPackages";
import SelectCustomPackages from "components/Common/SelectCustomPackages";
import { Form } from "react-bootstrap";
import SelectPeriod from "./SelectPeriod";
import { formatDate } from "utils/commons";
import SelectPackage from "components/Common/SelectPackage";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import CoachSupplementSuggestion from "./CoachSupplementSuggestion";
import PERIOD_OPTIONS from "utils/SuggestedPeriods";
import { Dropdown } from "semantic-ui-react";
import Card from "react-bootstrap/Card";
import FoodItemsTable from "components/Common/FoodItemsTable";
import ConfirmOrderCancelViewModal from "pages/Orders/ConfirmOrderCancelViewModal";
import { cancelParentOrder, updateChildOrder } from "services/OrderService";

const BASE_URL_ORDER_NUTRITIONIST = "order-nutritionist/";
const BASE_URL_TEST_RESULTS = "coach-user-test-result/";
const BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER =
  "/admin/check-and-complete-parent-order/";
const BASE_URL_HOW_DID_YOU_HEAR_OPTIONS = "/admin/check-and-display-how-did-you-hear-options";
const BASE_URL_NEW_ORDER_NUTRITIONIST= "coach-combine-active-order/";
const BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS="suggested-packages-and-supplements";
const BASE_URL_FOOD_INTOLERANCE_RESULTS="admin/lab/result/food-intolerance-tests"

class DashboardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      orderId: this.props.match.params.orderId,
      order: null,
      gender: null,
      coach_note: null,
      testResults: [],
      testCustomerPriorities: [],
      coachSuggestions: {},
      howDidYouHearDetails: {},
      errors: {},
      editables: {},
      isLoaded: false,
      sortConfig: { field: "test_category", direction: "asc" },
      isDependentOrder: false,
      clientPastOrders:[],
      medicalProfilesurveyQuestions:[],
      genericSurveyAnswers:[],
      labResultFiles:[],
      // periods:[],
      followUpPeriod:null,
      followUpPackage:{isBloodPackage:false, isMiniPackage:false},
      mappedSuggestions:[],
      isFoodIntoleranceTest:false,
      foodIntoleranceResults:[],
      collapsibles:{},

      pastBiomarkerResults:[],
      


      isConfirmOrderCancelViewModalVisible : false,
      cancelNotes:null,
      isNotifyClientCancel : false,
      cancelReason : null,

    };

    this.columns = [
      {
        name: "Name",
        selector: "name",
        // width: "100px",
        wrap: true,
        sortable: true,
      },
      {
        name: "Category",
        selector: "category",
        wrap: true,
        // sortable: true,
        cell: (test) => this.getTestCategories(test),
      },
      {
        name: "Result",
        selector: "result",
        // sortable: true,
        center: true,
        cell: (test) => this.getTestResult(test),
      },
      {
        name: "Unit",
        selector: "unit",
        // sortable: true,
        center: true,
        cell: (test) => this.getTestUnit(test),
      },
      {
        name: "Flag",
        selector: "flag",
        // sortable: true,
        center: true,
        cell: (test) => this.renderFlag(test),
      },
      {
        name: "Range",
        selector: "range",
        // sortable: true,
        minWidth: "300px",
        center:true,
        cell: (test) => this.renderRange(test),
      },
    ];

    this.coachConsultDateRef = React.createRef();
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchOrderDetails();

  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HOME;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchHowDidYouHearDetails = async (userId, countryId) => {
    if (!userId || !countryId) {
      console.log("fetchHowDidYouHearDetails:: Invalid userId or countryId.", {userId, countryId});
      return;
    }

    try {
      const url = `${BASE_URL_HOW_DID_YOU_HEAR_OPTIONS}/userId/${userId}/countryId/${countryId}`;
      const { data: howDidYouHearDetails } = await PhoenixAPI.get(url);
      
      this.setState({ howDidYouHearDetails });
    } catch(error) {
      console.log("fetchHowDidYouHearDetails:: Error on fetching how did you hear details!", error);
      this.showErrorNotification("Error on fetching how did you hear details! " + error.message);
    }
  }

  fetchSupplements = async (suggestedConditions) => {
    const {partnerId, countryId=""}=suggestedConditions || {}
    if (!countryId) {
      console.log("Invalid country!", countryId);
      return [];
    }
    // const url = `supplement/admin/v2/country/${countryId}`;  //old API
    let url=""
    if(partnerId!==null || undefined){
       url = `supplement/partner?country=${countryId}&partnerId=${partnerId}`
    }
    else{
      url = `supplement/partner?country=${countryId}`
    }

    try {
      this.showLoading();
      const { data: supplements = [] } = await PhoenixAPI.get(url);
      return supplements;
    } catch (error) {
      console.log("Error on fetching supplements", error);
      this.showErrorNotification(
        "Error on fetching supplements! " + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchBloodPackages=async(suggestedConditions)=>{
    const {partnerId, countryId=""}=suggestedConditions || {}
   if(!countryId){
    console.log("Invalid country!", countryId);
      return [];
   }
  //  const url = `bloods/packages/?country=${countryId}`; //old API

  let url=""
  if(partnerId!==null || undefined){
     url = `partner-blood-packages?country=${countryId}&partnerId=${partnerId}`
  }
  else{
    url = `partner-blood-packages?country=${countryId}`
  }
   try{
   this.showLoading();
   const { data: bloodPackages = [] } = await PhoenixAPI.get(url);
   return bloodPackages;
   }
   catch(error){
    console.log("Error on fetching blood packages", error);
    this.showErrorNotification(
      "Error on fetching blood packages! " + error.message
    );
   }
   finally {
    this.hideLoading();
  }
  }

  fetchCustomPackages=async(suggestedConditions)=>{
    const {partnerId, countryId=""}=suggestedConditions || {}

    if(!countryId){
     console.log("Invalid country!", countryId);
       return [];
    }
    // const url = `custom/packages/?country=${countryId}`; //old Api
    let url=""
    if(partnerId!==null || undefined){
       url = `partner-custom-packages?country=${countryId}&partnerId=${partnerId}`
    }
    else{
      url = `partner-custom-packages?country=${countryId}`
    }

    try{
    this.showLoading();
    const { data: customPackages = [] } = await PhoenixAPI.get(url);
    return customPackages;
    }
    catch(error){
     console.log("Error on fetching blood packages", error);
     this.showErrorNotification(
       "Error on fetching blood packages! " + error.message
     );
    }
    finally {
     this.hideLoading();
   }
   }

   fetchSuggestedPackageSupplements=async(suggestedConditions)=>{
    const {partnerId, countryId=""}=suggestedConditions || {};
    if(!countryId){
      console.log("Invalid country!", countryId);
        return [];
     }
     let url=""
     if(partnerId!==null || undefined){
        url = `${BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS}?country=${countryId}&partnerId=${partnerId}`
     }
     else{
       url = `${BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS}?country=${countryId}`
     }
     try{
      this.showLoading();
      const { data } = await PhoenixAPI.get(url);
      return data;
      }
      catch(error){
       console.log("Error on fetching suggested pacakges and supplements", error);
       this.showErrorNotification(
         "Error on fetching suggested pacakges and supplements! " + error.message
       );
      }
      finally {
       this.hideLoading();
     }
   }

  fetchFoodIntoleranceResults = async (order) => {

    const { id: orderId = null, user } = order || {}
    const { id: userId = null } = user || {};
    try {
      const { data = {} } = await PhoenixAPI.get(`${BASE_URL_FOOD_INTOLERANCE_RESULTS}/${orderId}/${userId}`);
      const { results = [] , summary=[]} = data || {}
     const filtered= summary.map((item)=>{
      if(item.foodItems.length>0){
        return item
      }
      else{
        return null
      }
     })
    
     const finalFiltered= filtered.filter((it)=>it!==null)


      const finalResults = finalFiltered.map(item => ({
        ...item,
        foodItems:item.foodItems.length>0 && item.foodItems.map(food => ({
          ...food,
          result: food.score,
          flag: food.rating,
          // Set 'result' as the value of 'score'
        }))
      }));

      // Creating output array 'b' by updating each "foodItem" array
      const activeFoodItems = finalResults.map(item => {
        const filteredItems =item.foodItems.length>0 && item.foodItems.filter(food => !food.isDeleted);
        return { ...item, foodItems: filteredItems };
      });
      
      this.setState({ foodIntoleranceResults: activeFoodItems })
    }
    catch (error) {
      console.log("Error in fetching food intolerance results", error.message)
      this.showErrorNotification("Error in fetching food intolerance results")
    }
  }

  fetchOrderDetails = async () => {
    const { orderId } = this.state;

    if (!orderId) console.log("Invalid order id: ", orderId);

    const url = `${BASE_URL_ORDER_NUTRITIONIST}${orderId}/`;
    const newUrl=`${BASE_URL_NEW_ORDER_NUTRITIONIST}${orderId}`
    this.showLoading();
    try {
      // const { data: order = {} } = await API.get(url);  //old URL

      const { data = {} } = await PhoenixAPI.get(newUrl);
     
      const { order = {}, clientPastOrders = [], genericSurveyAnswers = [], labResultFiles = [], medicalProfilesurveyQuestions=[],
      myOrdersResponse={}, optionaAndAnswersHearAboutValeo={}} = data || {}
       
      const countryId = order?.country_id;
      const userId = order?.user?.id;
      const {partner_id=null, orderFollowUpDto={}}=order || {}; 
      // const {partner_id=null, orderFollowUpDto={}}=order || {}; 
      const suggestedConditions={
        partnerId:partner_id,
        countryId:countryId
      }
       const {supplements=[],bloodPackages=[], customPackages=[], miniPackages=[]}=await this.fetchSuggestedPackageSupplements(suggestedConditions)
      // this.fetchHowDidYouHearDetails(userId, countryId);  // deprecated this API Calling
      // const supplements = await this.fetchSupplements(suggestedConditions);
      // const bloodPackages= await this.fetchBloodPackages(suggestedConditions);
      // const customPackages= await this.fetchCustomPackages(suggestedConditions);
      
      const cartOrders = myOrdersResponse?.cartOrders ?? [];
      const customerTransactions= cartOrders.flatMap((it)=>it.items) ?? [];
      let { doctors_note, doctors_note_ar } = order;
      const { custom_package = {} } = order;
      const {is_food_intolerance_test=false,is_food_intolerance_smart_report=false}= custom_package || {}
      if(is_food_intolerance_smart_report){
        this.fetchFoodIntoleranceResults(order)
      }
      if (is_food_intolerance_test || is_food_intolerance_smart_report) {
        doctors_note = `<p><br>Food intolerance results can initially appear complex, yet they are simple to understand. Ideally, the results are far better discussed together with a Valeo coach. That can improve your understanding of what the results mean and what the implications are for you, and perhaps your family. It also gives Valeo a better understanding of your background and what compelled you to conduct the test, so we can give you the best support possible.<br>
        Having said that, if you are unable to meet us, there are some simple steps to take, to resolve the issue. Remove all food foods that are marked red or yellow, presented in your result, for a period of 6 weeks. Focus and enjoy all the foods marked green that are presented in your result, there are a lot of them. Discover new foods, go on a food adventure.<br>
        During those 6 weeks please add Marine Collagen and Colon Cleanse, as advised on the packaging. Use the code COACH20 for discount at checkout. The reason for this is to help your digestive system to recover from consistently eating foods that you are reacting to. Collagen and colon cleanse can improve the digestive environment and have many other, long-term benefits.<br>
        At the end of 6 weeks, you can attempt to add back certain foods from your red and yellow list. If you do this, only add one food at a time and then wait 3 days before considering another. If you react to them, then continue to exclude them for a further 6 weeks.<br>
        At all times, the Valeo team can support you. Feel free to reach out to our customer service team.<br>
        DISCLAIMER<br>
        The information and other content provided in this analysis should not be considered or used as a substitute for medical advice, diagnosis or treatment. If you have a medical concern, you should consult with your health care provider or seek other professional medical treatment immediately.`

        doctors_note_ar = `<p><br>يمكن أن تبدو نتائج اختبار عدم تحمل الطعام معقدة في البداية، ومع ذلك فهي سهلة الفهم. يفضل ان تتم مناقشة النتائج مع أحد مدربي الصحة في فاليو. والذي سيساعدك على فهم ما تعنيه النتائج وما هي الخطوات المترتبة عليك. وذلك بعد ان تعطيهم فهما
        أفضل لحالتك الصحية وما السبب الذي دفعك لإجراء الاختبار
        وفي حال عدم تمكنك من مقابلتنا، هناك بعض الخطوات البسيطة التي يجب اتخاذها لحل المشكلة.
        قم بإزالة جميع الأطعمة التي تم تمييزها باللون الأحمر أو الأصفر، والمقدمة في النتيجة، لمدة 6 أسابيع الى ٣ أشهر
        ركز واستمتع بجميع الأطعمة التي تم تمييزها باللون الأخضر والتي تم تقديمها في نتيجتك، فهناك الكثير منها. اكتشف أطعمة جديدة، انطلق في مغامرة طعام.
        خلال تلك الفترة٬ يرجى إضافة الكولاجين البحري والبروبيوتيك. (الروابط) كما نصح.
        والسبب في ذلك هو مساعدة الجهاز الهضمي على التعافي من تناول الأطعمة التي تتفاعل معها باستمرار. يمكن للكولاجين والبروبيوتيك تحسين وظائف الجهاز الهضمي.
        في نهاية الفترة، يمكنك محاولة إضافة بعض الأطعمة من قائمتك الحمراء والصفراء. إذا قمت بذلك ، أضف طعامًا واحدًا فقط في كل مرة ثم انتظر 3 أيام قبل التفكير في نوع آخر. وراقب وجود اي تتفاعل معهم ، فاستمر في استبعادهم لمدة 6 أسابيع أخرى.
        في جميع الأوقات، يمكن لفريق Valeo دعمك. ما عليك سوى الاتصال بفريق خدمة العملاء لدينا.<br>
        تنصل <br>
        لا يقصد استخدام المعلومات و المحتويات الطبية  الواردة في هذا التحليل كمشورة طبية  للتشخيص أو العلاج أو لاستبدال التوجيهات من أخصائي طبي. الرجاء التواصل مع أخصائي الرعاية الصحية الخاص بك إذا كان لديك مشكلة طبية، أو قبل تناول أي أدوية جديدة، أو بدء برنامج للياقة البدنية، أو تغيير نظامك الغذائي.`
      }
      const overview_obj = doctors_note
        ? stateFromHTML(doctors_note)
        : stateFromHTML("<br><br><br>DISCLAIMER <br>The information and other content provided in this analysis should not be considered or used as a substitute for medical advice, diagnosis or treatment. If you have a medical concern, you should consult with your health care provider or seek other professional medical treatment immediately.");
      const overview_ar_obj = doctors_note_ar
        ? stateFromHTML(doctors_note_ar)
        : stateFromHTML("<br><br><br>تنصل <br>لا يقصد استخدام المعلومات و المحتويات الطبية  الواردة في هذا التحليل كمشورة طبية  للتشخيص أو العلاج أو لاستبدال التوجيهات من أخصائي طبي. الرجاء التواصل مع أخصائي الرعاية الصحية الخاص بك إذا كان لديك مشكلة طبية، أو قبل تناول أي أدوية جديدة، أو بدء برنامج للياقة البدنية، أو تغيير نظامك الغذائي.");
      const coachSuggestions = {
        is_coach_consult_done: order.is_coach_consult_done,
        coach_consult_date: order.coach_consult_date,
        is_supplement_required: order.is_supplement_required,
        // coach_supplement_suggestion: order.coach_supplement_suggestion
        //   ? order.coach_supplement_suggestion.map((it) => it.supplement_id)
        //   : [],
          is_custom_package_required: order.is_custom_package_required,
          coach_custom_package_suggestions: order.coach_custom_package_suggestions
          ? order.coach_custom_package_suggestions.map((it) => it.custom_package_id)
          : [],
          is_blood_package_required: order.is_blood_package_required,
          coach_blood_package_suggestions: order.coach_blood_package_suggestions
          ? order.coach_blood_package_suggestions.map((it) => it.package_id)
          : [],
           coach_supplement_suggestion: order.coach_supplement_suggestion??[],
      };
      const suggestions=order.coach_supplement_suggestion ??[]
      
      const mappedSuggestions=suggestions.map((suggestion)=>{
        const obj={
          "supplement_id":suggestion.supplementId,
          "updated_date": suggestion.updatedDate,
           "doses_eng":suggestion.dosesEng,
           "first_suggestion_date":suggestion.firstSuggestionDate,
            "period_id":suggestion.periodId,
            // "order_id":parseInt(orderId),
            // "user_id":parseInt(userId),
            "doses_ar":suggestion.dosesAr,
            //  "id":suggestion.id ?? null,
        }
        return obj
       })
      const followUpPackage={
        "isBloodPackage":orderFollowUpDto && orderFollowUpDto.isBloodPackage || false,
        "isMiniPackage":orderFollowUpDto && orderFollowUpDto.isMiniPackage || false,
      }
      this.setState(
        {
          isLoaded: true,
          order,
         howDidYouHearDetails:optionaAndAnswersHearAboutValeo,
          supplements,
          coachSuggestions,
          customPackages,
          bloodPackages,
          miniPackages,
          msg_en: doctors_note ?? stateToHTML(ContentState.createFromText("")),
          msg_ar:
            doctors_note_ar ?? stateToHTML(ContentState.createFromText("")),
          overview: JSON.stringify(convertToRaw(overview_obj)),
          overview_ar: JSON.stringify(convertToRaw(overview_ar_obj)),
          gender: order?.user?.userprofile?.gender,
          // coach_note: order?.user?.coach_note,
          isDependentOrder: Boolean(order?.dependent_id),
          customerTransactions: customerTransactions,
          isCustomerTransactionsLoaded: customerTransactions && customerTransactions.length>0 ? true:false,
          clientPastOrders,
          medicalProfilesurveyQuestions,
          genericSurveyAnswers,
          labResultFiles,
          mappedSuggestions,
          isFoodIntoleranceTest:is_food_intolerance_smart_report,
          followUpPackage,
        },
        // () => this.fetchCustomerTransactions()
      );

      // await this.fetchTestResultsUsingOldAPI(order);
      this.fetchTestResults(order);
      if(!is_food_intolerance_smart_report && !is_food_intolerance_test)
      this.fetchPreviousResult(order);
    } catch (error) {
      console.log("Error on fetching order", error);
    } finally {
      this.hideLoading();
    }
  };

  fetchCustomerTransactions = async () => {
    const { order } = this.state;
    const customerId = order?.user?.id ?? null;
    if (customerId === null) {
      console.log("fetchCustomerTransactions:: Invalid customerId=", {
        customerId,
        order,
      });
      return;
    }

    try {
      this.showLoading();
      const customerTransactions =
        (await fetchCoachCustomerTransactions(customerId)) ?? [];
      this.setState({ customerTransactions });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching customer transactions! " + error.message
      );
    } finally {
      this.hideLoading();
      this.setState({ isCustomerTransactionsLoaded: true });
    }
  };

  /*
  fetchTestResultsUsingOldAPI = async (order) => {
    console.log("DashboardDetail:: fetchTestResultsUsingOldAPI: order:", order);
    if (!order || !order.id) {
      console.log(
        "DashboardDetail:: fetchTestResultsUsingOldAPI: Cannot fetch test results, invalid order",
        order
      );
      return;
    }
    const { id: orderId, order_items = [] } = order;
    await Promise.all(
      order_items.map((orderItem) =>
        this.fetchTestResultsUsingOldAPIHelper(orderId, orderItem)
      )
    );
  };

  fetchTestResultsUsingOldAPIHelper = async (orderId, orderItem) => {
    const { test: testId, test_type } = orderItem;
    const getUrl = (testType) => {
      if (testType === "B") return "biomarker-test-result";
      else if (testType === "F") return "food-intolerance-test-result";
      else {
        console.log(
          "fetchTestResultsUsingOldAPIHelper: Unknown test type:",
          testType
        );
        return null;
      }
    };

    const url = getUrl(test_type);
    if (url === null || url === undefined) {
      console.log("fetchTestResultsUsingOldAPIHelper: invalid url", url);
      return;
    }

    try {
      const { data: testResults = [] } = await API.get(url, {
        params: { order: orderId, test: testId },
      });
      console.log("Fetch test results old API", testResults);
      if (testResults.length > 1) {
        const copyOfData = [...testResults];
        console.log("Test Results:: beforeSort:", copyOfData);
        testResults.sort((first, second) => {
          const firstTestResult =
            first.testresults && first.testresults.length
              ? first.testresults[0]
              : null;
          const secondTestResult =
            second.testresults && second.testresults.length
              ? second.testresults[0]
              : null;
          if (firstTestResult && secondTestResult) {
            const diff = moment(firstTestResult.created_at).diff(
              secondTestResult.created_at
            );
            if (diff > 0) return 1;
            else if (diff < 0) return -1;
            else return 0;
          }
          return 0;
        });
        console.log("Test Results:: afterSort:", testResults);
      }
      const results = testResults
        ? testResults.length > 1
          ? testResults[testResults.length - 1]
          : testResults[0]
        : null;
      const result =
        (results &&
          results.testresults &&
          results.testresults.length &&
          results.testresults[0].result) ??
        null;
      const testResultsOldAPI = { ...this.state.testResultsOldAPI };
      testResultsOldAPI[testId] = result;
      this.setState({ testResultsOldAPI });
    } catch (error) {
      console.log("Error on fetching test results from ", url, error);
      this.showErrorNotification("Error on fetching test results from old API", error);
    }
  };
  */

  fetchTestResults = async (order) => {
    const orderId = order.id;
    const userId = order?.user?.id;
    if (!orderId || !userId) {
      console.log(
        "Cannot fetch test results, invalid orderId or userId",
        { orderId },
        { userId }
      );
      return;
    }

    const url = `${BASE_URL_TEST_RESULTS}?order=${orderId}&user=${userId}&is_non_blood_test_required=yes`;
    try {
      // const testResultsOldAPI = this.state.testResultsOldAPI ?? {};
      // console.log("fetchTestResults: testResultsOldAPI:", testResultsOldAPI);

      const { data: testResults = [] } = await API.get(url);
      console.log("Fetch test results new API", testResults);
      /*
      testResults.forEach((testResult) => {
        if (!testResult.result_details) return;
        const { test, result } = testResult.result_details;
        const testResultFromOldAPI = testResultsOldAPI[test];
        console.log("fetchTestResults: testResultFromNewAPI:", {
          test,
          result,
        });
        console.log("fetchTestResults: testResultFromOldAPI:", {
          test,
          testResultFromOldAPI,
        });
        // Don't compare
        // if (
        //   testResultFromOldAPI !== null &&
        //   testResultFromOldAPI !== undefined &&
        //   testResultFromOldAPI !== result
        // ) {
        //   testResult.result_details.result = testResultFromOldAPI;
        // }
        testResult.result_details.result = testResultFromOldAPI ?? null;
      });
      */
      const testCustomerPriorities = testResults.map((testResult) => ({
        id: testResult.id,
        customer_priority:
          testResult?.result_details?.customer_priority ?? null,
        // testDetailId: testResult?.result_details?.test_detail_id,
        testDetailId: testResult?.result_details?.id,
      }));
      this.setState({ testResults, testCustomerPriorities });
    } catch (error) {
      console.log("Error on fetching test results from ", url, error);
      this.showErrorNotification(
        "Error on fetching test results from new API",
        error
      );
    }
  };



  handleSaveOverview = (data) => {
    this.setState({
      overview: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
      msg_en: stateToHTML(this.event.getCurrentContent()),
    });
  };

  handleSaveOverviewArabic = (data) => {
    this.setState({
      overview_ar: JSON.stringify(
        convertToRaw(this.event_ar.getCurrentContent())
      ),
      msg_ar: stateToHTML(this.event_ar.getCurrentContent()),
    });
  };

  isAllTestsHaveResult = () => {
    const testResults = this.state.testResults || [];
    return testResults.every(
      (testResult) => {
        if(testResult.is_non_blood_biomarker){
        return  testResult?.non_blood_biomarker_result?.result
        }
        else{
         return testResult?.result_details?.result ?? false
        }
        
      }
    );
  };

  saveFoodItemCustomerPriority=async()=>{
    const {foodIntoleranceResults, order}= this.state ;
    const orderId= order.id ?? ""
    const ordersUserId = order.user.id ?? "";
    const userId= getUserId()
    const foodResults= foodIntoleranceResults.reduce((accumulator, category) => {
      accumulator.push(...category.foodItems);
      return accumulator;
  }, []);

  let foodItemWithResults=[]
  foodResults.forEach((item)=>{

  foodItemWithResults.push(item)

})

  const finalSaveResults=foodItemWithResults.map((item)=>{
    const obj={
      resultDate: item.resultDate?item.resultDate:moment(new Date()).format("YYYY-MM-DD"),
      userId: parseInt(ordersUserId),
      orderId: parseInt(orderId),
      foodItemId: item.foodItemId,
      foodCategoryId: item.categoryId,
      uploadedById:parseInt(userId),
      result: item.result,
      resultFlag: item.flag,
      units: item.unit,
      customerPriority: item.customerPriority,
      isDeleted: item.isDeleted,
      id:item.testResultId ?? null
      
    }
    return obj
  })

  try{
    const response=await PhoenixAPI.patch(`admin/results/${orderId}/${ordersUserId}`,finalSaveResults );
    console.log("response", response)
  }

  catch(error){
   console.log("Error in saving results", error.message)
  }
 


  }

  handleSubmit = async (markCompleted = false) => {
    const {
      order,
      msg_en,
      msg_ar,
      // coach_note,
      testCustomerPriorities,
      coachSuggestions,
      mappedSuggestions,
      followUpPackage,
      miniPackages,
      isFoodIntoleranceTest,
    } = this.state;

    const {order_item_type:packageType="", client_didnt_showed_up=false, orderFollowUpDto} = order || {};
   
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const isPaidDoctorConsultation = order?.custom_package?.is_paid_doctor_consultation || false;
    if (!isPaidDoctorConsultation && !this.isAllTestsHaveResult()) {
      this.showErrorNotification("Test results are required!");
      return;
    }

    if (errors) return;
      if(isFoodIntoleranceTest){
        await this.saveFoodItemCustomerPriority()
      }
    const customerPriorities = testCustomerPriorities
      .filter((it) => it.customer_priority !== null)
      .map((it) => ({
        id: it.testDetailId,
        customer_priority: it.customer_priority,
      }));
    try {
      const url = `order-nutritionist/${order.id}/`;
      const payload = {
        doctors_note: msg_en,
        doctors_note_ar: msg_ar,
        // coach_note: coach_note,
        is_result_uploaded: true,
        is_reviewed:markCompleted,
        client_didnt_showed_up:client_didnt_showed_up
      };

      if (customerPriorities && customerPriorities.length)
        payload.test_priorities = customerPriorities;
      if (markCompleted) payload.order_status = "COMPLETED";

      if (coachSuggestions) {
        payload.is_coach_consult_done =
          coachSuggestions.is_coach_consult_done ?? false;
        if (coachSuggestions.is_coach_consult_done) {
          payload.coach_consult_date = coachSuggestions.coach_consult_date;
        }
        payload.is_supplement_required =
        mappedSuggestions.length>0? true:false;
        // payload.coach_supplement_suggestion =
        //   coachSuggestions.coach_supplement_suggestion
        //     ? coachSuggestions.coach_supplement_suggestion.map((it) => ({
        //         supplement_id: it,
        //       }))
        //     : [];
         payload.coach_supplement_suggestion = mappedSuggestions.length>0?mappedSuggestions:[];
            payload.is_custom_package_required =
          coachSuggestions.is_custom_package_required ?? false;
        payload.coach_custom_package_suggestions =
          coachSuggestions.coach_custom_package_suggestions
            ? coachSuggestions.coach_custom_package_suggestions.map((it) => ({
                custom_package_id: it,
              }))
            : [];

            payload.is_blood_package_required =
            coachSuggestions.is_blood_package_required ?? false;
          payload.coach_blood_package_suggestions =
            coachSuggestions.coach_blood_package_suggestions
              ? coachSuggestions.coach_blood_package_suggestions.map((it) => ({
                  package_id: it,
                }))
              : [];

      }
     
        if(orderFollowUpDto !==null || undefined){
         
          const {miniPackages:selectedMiniPackages=[]}= orderFollowUpDto || {};

          let selectedMiniPackageName
          let finalMiniPackage
          if(selectedMiniPackages && selectedMiniPackages.length>0){
            selectedMiniPackageName= miniPackages.length>0 &&miniPackages.filter((filterMini)=>selectedMiniPackages.length>0&&selectedMiniPackages.includes(filterMini.id)).map((mini)=>mini.name);
             finalMiniPackage=miniPackages.length>0 &&miniPackages.filter((selectpackage)=>selectedMiniPackages.length>0&&selectedMiniPackageName.includes(selectpackage.name)).map((mini)=>mini.id);
          }
          
          if (followUpPackage.isBloodPackage) {
            let follow_up = {
              "follow_up": orderFollowUpDto.followUp,
              "is_blood_package": followUpPackage.isBloodPackage ?? false,
              "is_mini_package": followUpPackage.isMiniPackage ?? false,
              "order_follow_up_mini_package": followUpPackage.isMiniPackage ? (finalMiniPackage.length > 0 ? finalMiniPackage : []) : [],
              "blood_package_id": followUpPackage.isBloodPackage ? orderFollowUpDto.bloodPackageId : null,
            }
            payload.order_follow_up = follow_up
          }
          else if (followUpPackage.isMiniPackage) {
            let follow_up = {
              "follow_up": orderFollowUpDto.followUp,
              "is_blood_package": followUpPackage.isBloodPackage ?? false,
              "is_mini_package": followUpPackage.isMiniPackage ?? false,
              "order_follow_up_mini_package": followUpPackage.isMiniPackage ? (finalMiniPackage.length > 0 ? finalMiniPackage : []) : [],
              "blood_package_id": followUpPackage.isBloodPackage ? orderFollowUpDto.bloodPackageId : null,
            }
            payload.order_follow_up = follow_up
          }

          else {
            payload.order_follow_up = null
          }
        }
      
      

      await Promise.all([
        API.patch(url, payload),
        this.saveHowDoYouHearAboutUsDetails(),
      ]);
      this.showSuccessNotification("Saved Successfully!");
      this.resetForm();

      if (markCompleted) {
        await Promise.all([
          // PhoenixAPI.post(
          //   `${BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER}${order.id}/`
          // ),
          this.saveOrderStatusLogs(order.id, order.order_status, "COMPLETED")
        ]);
        // if(packageType==="Blood_Package" || packageType==="Mini_Package"){
        //   this.submitLongevityScore()
        // }
        this.props.history.goBack();
        return;
      }

      
      if(markCompleted){
        this.fetchOrderDetails();
      }
      else{
        window.location.reload()
      }
      
      
    } catch (error) {
      this.showErrorNotification("Some error has occured. Please try again.");
    } finally {
    }
  };

  handleDeletePastOrder = (event) =>{
    event.preventDefault();
    this.setState({isConfirmOrderCancelViewModalVisible:true})
    console.log("this is state",this.state.isConfirmOrderCancelViewModalVisible)
  }

  hideCancelNotesViewModal=()=>{
    this.setState({ isConfirmOrderCancelViewModalVisible: false,cancelNotes:null,isNotifyClientCancel:false,cancelReason:null });
  }

  handleSaveCancelNotes = async(cancelNotes,notifyClient,cancelReasonFinal)=>{
    const userId = getUserId();
    const payload = {
      cancellation_notes : cancelNotes,
      cancellation_reason : cancelReasonFinal,
      is_send_notification : notifyClient,
      order_status: "CANCELLED",
      modified_by: userId
    }
    const orderId = this.state.orderId;
    console.log("maybe this is order id",this.state.orderId)
    // await cancelParentOrder(orderId);
    await updateChildOrder(orderId,payload)
    this.hideCancelNotesViewModal()
  }

  saveOrderStatusLogs = async (orderId, previousValue, newValue) => {
    const userEmail = getUserEmail();

    let payload = {
      modifiedBy: userEmail,
      previousValue,
      newValue,
      orderId,
      event: "ORDER_STATUS_CHANGE",
    };

    try {
      await saveOrderStatusLogs(payload);
    } catch (error) {
      console.log("Error on creating order status logs", error);
      this.showErrorNotification("Error on saving order status logs!", error.message);
    }
  };

  submitLongevityScore = async () => {
    const { order } = this.state;
    const { id: orderId = "", order_item_type="", user, country_id="" } = order || {};
    const { id: userId = "" } = user || {};

    const payload = {
      orderId: orderId,
      userId: userId,
      orderItemType: order_item_type,
      countryId:country_id
    }
    if(order_item_type==="Blood_Package" || order_item_type==="Mini_Package"){
      const url = "/calculate-longevity-score";
      try {
  
        const response = await PhoenixAPI.post(url, payload);
  
        this.showSuccessNotification("Longevity Score is submitted");
      } catch (error) {
        console.log("Error in submitting the Longevity Score to Client", error);
        this.showErrorNotification("Error in submitting the Longevity Score to Client");
      }
    }
    else {
      return
    }
   
  }

  saveHowDoYouHearAboutUsDetails = async () => {
    const { howDidYouHearDetails, order } = this.state;
    const userId = order?.user?.id;
    const cartOrderId = order?.cart_order_id;
    if (!howDidYouHearDetails || !userId || !cartOrderId) {
      console.log("saveHowDoYouHearAboutUsDetails:: Invalid data!", {userId, cartOrderId, howDidYouHearDetails});
      this.showErrorNotification("Cannot save how do you hear details! Invalid data.");
      return;
    }

    const { showOptions, answers, notes } = howDidYouHearDetails;
    if ((!notes || notes.trim() === "") && (!answers || !answers.length)) {
      console.log("saveHowDoYouHearAboutUsDetails:: Invalid data!", {notes, answers});
      return;
    }

    const payload = {
      userId,
      cartOrderId,
      howDoYouHearAboutUsIds: answers ?? null,
      notes: notes ?? null,
    }
    const url = "/hear-about-valeo";
    try {
      if (showOptions) {
        await PhoenixAPI.post(url, payload);
      } else {
        await PhoenixAPI.patch(url, payload);
      }
      this.showSuccessNotification("How do you hear about us saved successfully.");
    } catch(error) {
      console.log("saveHowDoYouHearAboutUsDetails:: Error on saving how do you hear about us details!", error);
      this.showErrorNotification("Error on saving, how do you hear about us details.");
    }
  }

  validate = () => {
    const { msg_en, msg_ar, coachSuggestions , followUpPackage, order} = this.state;
    const {orderFollowUpDto={}}=order || {};
   const {isBloodPackage, isMiniPackage}=followUpPackage || {}
    const {bloodPackageId, miniPackages, followUp}= orderFollowUpDto || {}
    const {
      is_coach_consult_done,
      coach_consult_date,
      is_supplement_required,
      coach_supplement_suggestion,
    } = coachSuggestions ?? {};

    const errors = { ...this.state.errors };
    if (!msg_en || msg_en === "<p><br></p>")
      errors.msg_en = "Overall result is required";
    else delete errors["msg_en"];

    if (!msg_ar || msg_ar === "<p><br></p>")
      errors.msg_ar = "Overall result is required";
    else delete errors["msg_ar"];

    if (is_coach_consult_done && !coach_consult_date)
      errors.coach_consult_date = "Consultation date is a required field!";
    else if (is_coach_consult_done && moment().isBefore(coach_consult_date))
      errors.coach_consult_date = "Consultation date cannot be a future date!";
    else delete errors.coach_consult_date;

    // if (
    //   is_supplement_required &&
    //   (!coach_supplement_suggestion || !coach_supplement_suggestion.length)
    // )
    //   errors.coach_supplement_suggestion = "Atleast 1 supplement is required!";
    // else delete errors.coach_supplement_suggestion;

    if(followUp){
      if (isBloodPackage && !bloodPackageId) {
        errors.followUpBloodPackage = "Please Select Blood Package"
      }
    else if (isMiniPackage && !(miniPackages && miniPackages.length > 0)) {
        errors.followUpMiniPackage = "Please Select MiniPackage"
      }
      else {
        delete errors.followUp
        delete errors.followUpBloodPackage
        delete errors.followUpMiniPackage
      }

    }
    if (isBloodPackage && !bloodPackageId) {
      errors.followUpBloodPackage = "Please Select Blood Package"
      if(followUp){
        delete errors.followUp
      }
    }
    else if (isBloodPackage && bloodPackageId) {
      if (!followUp || followUp.trim() === "") {
        errors.followUp = "Please Select Followup Date"
      }
      else{
        delete errors.followUp
      }
      delete errors.followUpBloodPackage
      delete errors.followUpMiniPackage
    }
    

    if (isMiniPackage && !(miniPackages && miniPackages.length > 0)) {
      errors.followUpMiniPackage = "Please Select MiniPackage"
      if(followUp){
        delete errors.followUp
      }
    }

    else if (isMiniPackage && (miniPackages && miniPackages.length > 0)) {
      if (!followUp || followUp.trim() === "") {
        errors.followUp = "Please Select Followup Date"
      }
      else{
        delete errors.followUp
      
      }
      delete errors.followUpBloodPackage
      delete errors.followUpMiniPackage
    }
   
     console.log("Errores:", errors)
    return Object.keys(errors).length ? errors : null;
  };

  /*
  handleSaveClientNotes = async () => {
    const { order, coach_note } = this.state;
    try {
      const url = `order-nutritionist/${order.id}/`;
      await API.patch(url, {
        coach_note: coach_note,
      });
      this.showSuccessNotification("Saved Successfully!");
      this.resetForm();
      this.fetchOrderDetails();
    } catch (error) {
      this.showErrorNotification("Some error has occured. Please try again.");
    } finally {
    }
  }; 
  */

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      coachSuggestions: {},
      client_notes: "",
      msg_en: "",
      msg_ar: "",
      overview_obj: "",
      overview_ar_obj: "",
      gender: "",
      coach_note: "",
    });
  };

  renderQuestion = (question, isAnswered) => {
    if (isAnswered) {
      const { option: options = [], plain_answer = "" } =
        question.answer_question[0];
      let answer = options.map((it) => it.option).join(", ");
      if (plain_answer)
        answer += answer === "" ? plain_answer : ", " + plain_answer;
      return (
        <p>
          <span>{question.question}</span>
          <br />
          Answer. {answer}
        </p>
      );
    } else {
      return (
        <p>
          <span>{question.question}</span>
        </p>
      );
    }
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName, classNames = "") => (
    <div
      onClick={() => this.setEditable(fieldName)}
      style={{ cursor: "pointer" }}
      className={"input-group-append" + classNames}
    >
      <i className="input-group-text fa fa-pencil"></i>
    </div>
  );

  renderFlag = (test) => {
    const biomarkerStatus = this.findBiomarkerStatus(test);
    const { biomarkers_status = {} } = test || {};
    const { is_biomarker_multiple_ranges, is_show_biomarker,
      problematic_biomarker_answer, problematic_biomarker_question ,optimal_range_start, optimal_range_end} = biomarkers_status || {};
    const isProblematicBiomarker =!is_show_biomarker && is_biomarker_multiple_ranges;
    const showProblematicQuestion= is_biomarker_multiple_ranges
    const optimalRangeStatus=this.findOptimalStatus(test)
    const status = biomarkerStatus
      ? {
          name: biomarkerStatus.status_name,
          style: {
            background: biomarkerStatus.status_color_code,
          },
        }
      : {
          name: "Out of Range",
          style: { background: "#CCCCCC" },
        };
       
      
        
        return(
          <div>
          {!optimalRangeStatus?<div
              className="status px-2 py-2 text-wrap"
              style={{ ...status.style, borderRadius: "5px", minWidth: "100px",filter:isProblematicBiomarker? "blur(6px)":"" }}
            >
              <p style={{ textAlign: "center" }}>{status.name}</p>
            </div>:<div
              className="status px-2 py-2 text-wrap"
              style={{  background:"rgb(0, 122, 255)", borderRadius: "5px", minWidth: "100px",filter:isProblematicBiomarker? "blur(6px)":"" }}
            >
              <p style={{ textAlign: "center",color: "white" }}>Optimal</p>
            </div>}
            {optimalRangeStatus?<div style={{filter:isProblematicBiomarker? "blur(6px)":"" }}>Optimal : {optimal_range_start} - {optimal_range_end}</div>:""}
            {showProblematicQuestion? <div style={{fontSize:"14px",color:"#07141A", fontWeight:"600", marginTop:"10px", textWrap:"wrap", minWidth: "100px"}}>{problematic_biomarker_question}</div>:""}
            
          </div>
        )   
       
  }

  findBiomarkerStatus = (test) => {
    const biomarkersStatus = test?.biomarkers_status?.biomarker_status;
    const testResult = test?.result_details?.result;

    if (testResult && biomarkersStatus && biomarkersStatus.length) {
      const result = biomarkersStatus.find((it) => {
        return (
          testResult < it.range_start ||
          this.isInBetween(
            Number(testResult),
            Number(it.range_start),
            Number(it.range_end)
          )
        );
      });
      return result ? result : biomarkersStatus[biomarkersStatus.length - 1];
    }

    return null;
  };

  isInBetween = (number, rangeStart = 0, rangeEnd = 0) => {
    return number >= rangeStart && number <= rangeEnd;
  };
   
  findOptimalStatus = (test) => {
    const biomarkersStatus = test?.biomarkers_status ??{};
    const testResult = test?.result_details?.result;
    const { is_optimal_range = false, optimal_range_start, optimal_range_end } = biomarkersStatus;
    let val = false;
    if (is_optimal_range) {
      val =

        this.isInBetween(
          Number(testResult),
          Number(optimal_range_start),
          Number(optimal_range_end)

        );
    }
    return val;
  }
  // renderRange = (test) => {
  //   const { biomarkers = [], results = [] } = test;
  //   const result = results && results.length ? results[0] : null;

  //   if (result) {
  //     const biomarker = biomarkers.find(
  //       (biomarker) => biomarker.id === result.biomarker
  //     );
  //     const markers = biomarker?.biomarker_status || [];
  //     return markers && markers.length && result ? (
  //       <div
  //         // className="form-row justify-content-center align-items-center py-4"
  //         style={{ minWidth: "300px" }}
  //       >
  //         <Range
  //           result={Number(result.result)}
  //           biomarker_list={markers.filter(
  //             (it) => it.gender === this.state.gender || it.gender === "B"
  //           )}
  //           val={1}
  //         />
  //       </div>
  //     ) : (
  //       "Out Of Range"
  //     );
  //   } else {
  //     return "Out Of Range";
  //   }
  // };

  renderProblematicAnswer=(problematic_biomarker_answer)=>{
    return(
    <>
    {problematic_biomarker_answer ?<div style={{ backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"16px",textWrap:"wrap", minWidth: "250px",fontStyle:"Italic",fontWeight:"400", fontSize:"12px"}}>{problematic_biomarker_answer}</div>:<div style={{backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"8px",textWrap:"initial", fontStyle:"Italic",fontWeight:"400", fontSize:"12px", color: "#07141A"}}>Dear Coach, the client
       hasn’t answered the question yet,
       the ranges will show once it has been answered.</div>}
    
    </>)
   }

  renderRange = (test) => {
    const biomarkersStatus = test?.biomarkers_status?.biomarker_status;
    const testResult = test?.result_details?.result;
    const { biomarkers_status = {} } = test || {};
    const { is_biomarker_multiple_ranges, is_show_biomarker,
      problematic_biomarker_answer, problematic_biomarker_question,is_optimal_range=false,optimal_range_start=null,optimal_range_end=null } = biomarkers_status || {};
    const isProblematicBiomarker = !is_show_biomarker && is_biomarker_multiple_ranges
    const showProblematicAnswer= is_biomarker_multiple_ranges;
   
      
    if (testResult) {
      return biomarkersStatus ? (
        <div style={{ display:"flex", flexDirection:"column"}}>
         <div
          // className="form-row justify-content-center align-items-center py-4"
          style={{ minWidth: "300px", filter:isProblematicBiomarker? "blur(6px)":"" }}
        >
          <Range
            result={Number(testResult)}
            biomarker_list={biomarkersStatus}
            val={1}
            isOptimalRange={is_optimal_range}
            optimalRangeStart={optimal_range_start}
            optimalRangeEnd={optimal_range_end}
          />
        </div>
          {showProblematicAnswer && this.renderProblematicAnswer(problematic_biomarker_answer)}
         
        </div>
      

      ) : (
        "Out Of Range"
      );
    } else {
      return "Out Of Range";
    }
  };

  renderProblematicRanges = (test) => {
    const { biomarkers_status = {} } = test || {};
    const { is_biomarker_multiple_ranges, is_show_biomarker,
      problematic_biomarker_answer, problematic_biomarker_question } = biomarkers_status || {};
    const isProblematicBiomarker = is_biomarker_multiple_ranges
    if (is_show_biomarker && isProblematicBiomarker) {
      return (

        <div>
          
            <td data-label="flag">{this.renderFlag(test)}</td>
            <td data-label="range">{this.renderRange(test)}</td>

          <div style={{display:"flex",flexDirection:"column", gap:"6px"}}>
          <div style={{fontSize:"14px",color:"#07141A", fontWeight:"600"}}>{problematic_biomarker_question} ?</div>
          {problematic_biomarker_answer ?<div style={{ backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"8px",textWrap:"initial"}}>{problematic_biomarker_answer}</div>:<div style={{backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"8px",textWrap:"initial"}}>Dear Coach, the client
           hasn’t answered the question yet,
           the ranges will show once it has been answered.</div>}
          </div>

        </div>
      )
    }

    else if (isProblematicBiomarker) {
      return (
        <div>
          <div  style={{ filter: "blur(3px)" }}>
            <td data-label="flag">{this.renderFlag(test)}</td>
            <td data-label="range">{this.renderRange(test)}</td>

          </div>
          <div style={{display:"flex",flexDirection:"column", gap:"6px"}}>
          <div style={{fontSize:"14px",color:"#07141A", fontWeight:"600"}}>{problematic_biomarker_question} ?</div>
          {problematic_biomarker_answer ?<div style={{backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"8px",textWrap:"initial"}}>{problematic_biomarker_answer}</div>:<div style={{ backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"8px", textWrap:"initial"}}>Dear Coach, the client hasn’t 
          answered the question yet, 
          the ranges will show once it has been answered.</div>}
          </div>
         
        </div>

      )
    }
    else{
      return(
        
         <div>
            <td data-label="flag">{this.renderFlag(test)}</td>
            <td data-label="range">{this.renderRange(test)}</td>
          </div>
        
      )
    }


  }
  renderTestCustomerPriority = (test) => {
    const testCustomerPriorities = this.state.testCustomerPriorities || [];
    // const customerPriority = test?.result_details?.customer_priority;
    const testCustomerPriority = testCustomerPriorities.find(
      (it) => it.id === test.id
    );
    if (
      testCustomerPriority &&
      testCustomerPriority.customer_priority !== null
    ) {
      return (
        <Checkbox
          key={test.id}
          checked={testCustomerPriority.customer_priority}
          onChange={(e, data) => {
            // const t = {
            //   ...test,
            //   result_details: {
            //     ...test.result_details,
            //     customer_priority: data.checked,
            //   },
            // };
            // const testResults = this.state.testResults || [];
            // const index = testResults.findIndex((it) => it.id === t.id);
            // testResults[index] = t;
            // this.setState({ testResults });
            this.setState({
              testCustomerPriorities: testCustomerPriorities.map((it) =>
                it.id === test.id
                  ? { ...it, customer_priority: data.checked }
                  : it
              ),
            });
          }}
        />
      );
    } else {
      return <Checkbox key={test.id} disabled />;
    }
  };

  fetchPreviousResult= async(order)=>{
    if(!order){
      console.log("order is null");
      return;
    }
    const orderId = order.id;
      const biomarkerPastResultUrl=`previous-results/${orderId}`;
      try{
           const {data:pastBiomarkerResults=[]}=await PhoenixAPI.get(biomarkerPastResultUrl);
           this.setState({pastBiomarkerResults})
      }catch (error) {
      console.log("Error on fetching lab report results from ", biomarkerPastResultUrl, error);
    }
    
  }
  getPreviousResult=(test)=>{
       
            const testPrevResult= this.state.pastBiomarkerResults &&  this.state.pastBiomarkerResults.filter((prevBiomarker)=>prevBiomarker.biomarker_id==test.biomarkers_status?.id);
            if(testPrevResult.length==1)
            return testPrevResult[0].result;
            else
            return "N/A";
  }

  getTestResult = (test) => {
    if(test.is_non_blood_biomarker){
      return test?.non_blood_biomarker_result?.result ?? ""; 
    }
    else{
      return test?.result_details?.result ?? "";
    }
    
  };

  getTestUnit = (test) => {
    return test?.biomarkers_status?.unit ?? "";
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  getClassNamesFor = (field) => {
    const { sortConfig } = this.state;
    if (!sortConfig) return;
    return sortConfig.field === field
      ? sortConfig.direction === "asc"
        ? "ascending"
        : "descending"
      : undefined;
  };

  requestSort = (newSortField) => {
    const { field, direction } = this.state.sortConfig || {};
    let newDirection = "asc";
    if (field === newSortField && direction === "asc") {
      newDirection = "desc";
    }

    this.setState({
      sortConfig: { field: newSortField, direction: newDirection },
    });
  };

  getSortedTestResults = () => {
    const { testResults = [], sortConfig } = this.state;
    const sortedTestResults = testResults.length ? [...testResults] : [];
    if (sortConfig) {
      const { field, direction } = sortConfig;
      if (field === "test_category") {
        return _.orderBy(
          sortedTestResults,
          [
            function (testResult) {
              return testResult.categories && testResult.categories.length
                ? testResult.categories[0]
                : "";
            },
            "test_name",
          ],
          [direction]
        );
      } else {
        return _.orderBy(sortedTestResults, [field], [direction]);
      }
    }
    return sortedTestResults;
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  renderViewButton = (test) => (
    <div>
      <button
        className="btn px-3"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.setState({ selectedTest: test, showModal: true });
        }}
      >
        View
      </button>
    </div>
  );

  handleCoachSuggestions=(suggestions=[])=>{
    
       const today = moment().format("YYYY-MM-DD");
     const mappedSuggestions=suggestions.map((suggestion)=>{
      const obj={
        "supplement_id":suggestion.supplementId,
        "updated_date": today,
         "doses_eng":suggestion.dosesEng,
         "first_suggestion_date":suggestion.firstSuggestionDate ?? today,
          "period_id":suggestion.periodId,
        
          "doses_ar":suggestion.dosesAr,
            // "id":suggestion.id ?? null
          // "period_eng":suggestion.periodEng,
      }
      return obj
     })
     this.setState({mappedSuggestions})
     
  }

  handleFollowUpDate = (period) => {
    const { order } = this.state || {}
    const { orderFollowUpDto = {} } = order || {};
    const newOrderFollowUpDto = { ...orderFollowUpDto, followUp: period.value, followUpDate: period.label }
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto, followUpPeriod: period.value } })
  }

  handleFollowUpBloodPackage = (bloodPackage) => {
    const { order , followUpPackage} = this.state || {}
    const { orderFollowUpDto} = order || {};

    const newOrderFollowUpDto = { ...orderFollowUpDto, bloodPackageId: bloodPackage.value, miniPackages:[]}
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto } })
  }

  handleFollowUpMiniPackages = (selectedMiniPackages) => {
    const { order, miniPackages } = this.state || {}
    const { orderFollowUpDto } = order || {};
    const newOrderFollowUpDto = { ...orderFollowUpDto, miniPackages: selectedMiniPackages.map((it) => it.value), bloodPackageId:null }
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto } })
  }

  renderLastSuggestedPackageName = (orderFollowUpDto) => {

    const { miniPackages = [] } = this.state || {};
    const { lastBloodPackageName, lastMiniPackages } = orderFollowUpDto || {};

    if (lastBloodPackageName) {

      return lastBloodPackageName
    }
    let finalArray = []
    if (lastMiniPackages && lastMiniPackages.length > 0) {

      lastMiniPackages.map(id => {
        const found = miniPackages.find(item => item.id === id);
        finalArray.push(found ? found.name : '')

      });

      let uniqueV = finalArray.filter((item, index) => {
        // Return only the first occurrence of each element
        return finalArray.indexOf(item) === index;
      });

      return uniqueV.join(",")
    }
  }

  handleCollapsibleChange = (key) => {
    const collapsibles = {};
    if (this.state.collapsibles[key] === undefined) collapsibles[key] = true;
    this.setState({ collapsibles });
  };

  handleCustomerPriority=(event,selectedItem)=>{
    const target=event.target.checked
    const {foodIntoleranceResults}= this.state || {};
    const final=foodIntoleranceResults.map(item=>{
     const updatedFoodItems=item.foodItems.map(food=>{
       if(food.testResultId==selectedItem.testResultId){
         food.customerPriority=target
       }
       return food
     })
     return { ...item, foodItems: updatedFoodItems };
    })
     this.setState({foodIntoleranceResults:final})

  }

  renderFoodIntoleranceResults = () => {
    const { collapsibles, foodIntoleranceResults = [], userType, isPastOrder } = this.state;

    return (
      <div style={{marginTop:"30px"}}>
        {foodIntoleranceResults.length > 0 &&
          foodIntoleranceResults.map((item, index) => {
            return (
              <Card>
                <Card.Body>
                  <div className="row" >

                    <div className="col">
                      <div style={{ fontWeight: 500 }}>
                        {item.categoryName}
                      </div>
                    </div>


                    <div className="col">
                      <i
                        onClick={() =>
                          this.handleCollapsibleChange(
                            item.categoryId
                          )
                        }
                        className={`caret square ${collapsibles[false]
                          ? "up"
                          : "down"
                          } icon big`}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  {(
                    <>
                      <FoodItemsTable
                        foodItems={item.foodItems ?? []}
                        foodCategory={item.categoryName}
                        userType={"coach"}
                        isPastOrder={false}
                        customerPriorities={this.handleCustomerPriority}
                      />
                    </>
                  )}
                </Card.Body>
              </Card>
            )
          })

        }
      </div>
    )
  }

  render() {
    const errors = this.state.errors || {};
    const order = this.state.order || {};
    const clientDetails = order ? order.user || {} : {};
    const coachSuggestions = this.state.coachSuggestions ?? {};
    const testResults = this.getSortedTestResults();
    const { overview, overview_ar, supplements, howDidYouHearDetails = {}, isEditable , bloodPackages, customPackages, clientPastOrders, medicalProfilesurveyQuestions,genericSurveyAnswers, labResultFiles,  miniPackages, followUpPackage={}} = this.state;

    const isPaidDoctorConsultation = order?.custom_package?.is_paid_doctor_consultation || false;
    const dependentId= order.dependent_id?? null;

    const {orderFollowUpDto={}}= order || {};

    const {isFoodIntoleranceTest,foodIntoleranceResults}= this.state

    if (!this.state.isLoaded) return "";

    /*
    const clientNotesCard = (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Client Notes</div>
          <div className="description">
            <div className="input-group">
              <textarea
                id="client_notes"
                value={this.state.coach_note || ""}
                onChange={(e) => this.setState({ coach_note: e.target.value })}
                rows="5"
                placeholder="Client Notes"
                className="form-control"
                readOnly={!this.state.editables.client_notes}
              ></textarea>
              {this.renderPencilIcon("client_notes")}
            </div>
          </div>
        </div>
        <div className="extra content">
          <button
            onClick={(e) => this.handleSaveClientNotes()}
            className="btn btn-success px-4 float-right"
          >
            Save
          </button>
        </div>
      </div>
    );
    */

    const testResultsCard = (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Test Results Table</div>
          <div
            className="description table-responsive"
            style={{
              maxHeight: "70vh",
              overflowY: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {testResults && testResults.length ? (
              <table className="result-table">
                <thead className="result-table-head">
                  <tr>
                    <th
                      onClick={() => this.requestSort("test_name")}
                      className={this.getClassNamesFor("test_name")}
                    >
                      Name
                    </th>
                    <th
                      onClick={() => this.requestSort("test_category")}
                      className={this.getClassNamesFor("test_category")}
                    >
                      Category
                    </th>
                    <th style={{ padding: '10px' }}>Result</th>
                   { !this.state.isFoodIntoleranceTest? <th style={{ padding: '10px' }}>Past <br/>Result</th>:<></>}
                    <th className="text-center">Unit</th>
                    <th className="text-center">Flag</th>
                    <th className="text-center">Range</th>
                    <th>
                      Customer
                      <br />
                      Priority
                    </th>
                    <th className="text-center">View</th>
                  </tr>
                </thead>
                <tbody className="result-table-body">
                  {testResults.map((test) => (
                    <>
                      <tr key={test.id} className="result-table-row">
                        <td data-label="name" className="text-wrap">
                          {test.test_name || ""}
                        </td>
                        <td data-label="category" className="text-wrap">
                          {test?.categories?.join(", ")}
                        </td>
                        <td data-label="result" className="text-center">
                          {this.getTestResult(test)}
                        </td>
                       {!this.state.isFoodIntoleranceTest ?<td data-label="previousresult" className="text-center">
                          {this.getPreviousResult(test)}
                        </td>:<></>}
                        <td data-label="unit" className="text-center">
                          {this.getTestUnit(test)}
                        </td>
                      
                        <td data-label="flag">{test.is_non_blood_biomarker?"":this.renderFlag(test)}</td>
                        <td data-label="range">{test.is_non_blood_biomarker?"":this.renderRange(test)}</td>
                        
                        {/* {this.renderProblematicRanges(test)} */}
                      
                        <td
                          data-label="customer_priority"
                          className="text-center"
                        >
                          {test.is_non_blood_biomarker?"":this.renderTestCustomerPriority(test)}
                        </td>
                        <td data-label="view" className="text-center">
                          {test.is_non_blood_biomarker?"":this.renderViewButton(test)}
                        </td>
                       
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            ) : (
              <span>Test results are not avaibale</span>
            )}
          </div>
        </div>
      </div>
    );

    const coachSuggestionCard = (

      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%", zIndex: 5 }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Consultation Details</div>
          <div className="description">
            <div className="" style={{ border: "2px solid black", padding: "20px", marginBottom: "20px" }}>
              <div className="row">
                <div className="col-4">
                  <label
                    htmlFor="is_coach_consult_done"
                    style={{ color: "black" }}
                  >
                    Is consultation done?
                  </label>
                  <br />
                  <Checkbox
                    id="is_coach_consult_done"
                    checked={coachSuggestions.is_coach_consult_done ?? null}
                    onChange={(e, data) => {
                      const newCoachSuggestions = {
                        ...coachSuggestions,
                        is_coach_consult_done: data.checked,
                        coach_consult_date: "",
                      };
                      this.setState(
                        { coachSuggestions: newCoachSuggestions },
                        () => {
                          this.coachConsultDateRef.current = "";
                        }
                      );
                    }}
                    label={coachSuggestions.is_coach_consult_done ? "Yes" : "No"}
                    disabled={isEditable ? order.is_coach_consult_done ?? false : true}
                    toggle
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="coach_consult_date" style={{ color: "black" }}>
                    Consultation Date
                  </label>
                  <input
                    ref={this.coachConsultDateRef}
                    value={coachSuggestions.coach_consult_date ?? null}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      if (moment().isBefore(selectedDate)) {
                        e.target.value = "";
                        return;
                      }
                      const newCoachSuggestions = {
                        ...coachSuggestions,
                        coach_consult_date: selectedDate,
                      };
                      this.setState({ coachSuggestions: newCoachSuggestions });
                    }}
                    max={moment().format("YYYY-MM-DD")}
                    type="date"
                    name="coach_consult_date"
                    id="coach_consult_date"
                    className="form-control"
                    disabled={!isEditable || !coachSuggestions.is_coach_consult_done}
                  />
                  <span className="help-block">{errors.coach_consult_date}</span>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    checked={order.client_didnt_showed_up || false}
                    onChange={(event) => this.setState({
                      order: { ...order, client_didnt_showed_up: event.target.checked }
                    })}
                    id="client_didnt_showed_up"
                    name="client_didnt_showed_up"
                    size="large"
                    label="Client didn't showed up"
                    className="font-weight-bold"
                  />
                </div>
                {/* <div className="row mt-2">
              <div className="col-4">
                <label
                  htmlFor="is_supplement_required"
                  style={{ color: "black" }}
                >
                  Is supplements suggestion required?
                </label>
                <br />
                <Checkbox
                  id="is_supplement_required"
                  checked={coachSuggestions.is_supplement_required ?? null}
                  onChange={(e, data) => {
                    const newCoachSuggestions = {
                      ...coachSuggestions,
                      is_supplement_required: data.checked,
                      coach_supplement_suggestion: [],
                    };
                    this.setState({ coachSuggestions: newCoachSuggestions });
                  }}
                  label={coachSuggestions.is_supplement_required ? "Yes" : "No"}
                  disabled={isEditable ? order.is_supplement_required ?? false : true}
                  toggle
                />
              </div>
              </div> */}
                {/* <div className="col-4">
                <label
                  htmlFor="coach_supplement_suggestion"
                  style={{ color: "black" }}
                >
                  Suggested Supplements
                </label>
                <SelectSupplements
                  id="coach_supplement_suggestion"
                  supplements={supplements ?? []}
                  selectedSupplementIds={
                    coachSuggestions.coach_supplement_suggestion ?? []
                  }
                  onSupplementSelect={(options) => {
                    const newCoachSuggestions = {
                      ...coachSuggestions,
                      coach_supplement_suggestion: options.map(
                        (it) => it.value
                      ),
                    };
                    this.setState({ coachSuggestions: newCoachSuggestions });
                  }}
                  menuPlacement="bottom"
                  showOnlyActive={true}
                  isDisabled={!isEditable || !coachSuggestions.is_supplement_required}
                />
                <span className="help-block">
                  {errors.coach_supplement_suggestion}
                </span>
              </div> */}
              </div>
            </div>

            <div className="font-weight-bold" style={{ padding: "10px" }}> Coach Suggestion Details</div>
            <div className="" style={{ border: "2px solid black", padding: "20px" }}>

              <CoachSupplementSuggestion
                suggestions={coachSuggestions.coach_supplement_suggestion ?? []}
                countryId={order.country_id ?? null}
                suggestedSupplements={supplements}
                suggestionsUpdate={this.handleCoachSuggestions}
                isEditable={isEditable}
                isPastOrder={false}
              />
              <div className="suggestions" style={{ padding: "20px" }}>

                <div className="font-weight-bold">Additional Suggestions</div>
                <div className="row mt-2">
                  <div className="col-4">
                    <label
                      htmlFor="is_supplement_required"
                      style={{ color: "black" }}
                    >
                      Is blood tests suggestion required?
                    </label>
                    <br />
                    <Checkbox
                      id="is_blood_package_required"
                      checked={coachSuggestions.is_blood_package_required ?? null}
                      onChange={(e, data) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          is_blood_package_required: data.checked,
                          coach_blood_package_suggestions: [],
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      label={coachSuggestions.is_blood_package_required ? "Yes" : "No"}
                      disabled={isEditable ? order.is_blood_package_required ?? false : true}
                      toggle
                    />
                  </div>
                  <div className="col-4">
                    <label
                      htmlFor="coach_blood_package_suggestions"
                      style={{ color: "black" }}
                    >
                      Suggested Blood Tests
                    </label>
                    <SelectBloodPackages
                      id="coach_blood_package_suggestions"
                      bloodPackages={bloodPackages ?? []}
                      selectedBloodPackageIds={
                        coachSuggestions.coach_blood_package_suggestions ?? []
                      }
                      onSelect={(options) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          coach_blood_package_suggestions: options.map(
                            (it) => it.value
                          ),
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      menuPlacement="bottom"
                      showOnlyActive={true}
                      isDisabled={!isEditable || !coachSuggestions.is_blood_package_required}
                    />
                    <span className="help-block">
                      {errors.coach_blood_package_suggestion}
                    </span>
                  </div>
                </div>

                <div className="row mt-2">
                  <div className="col-4">
                    <label
                      htmlFor="is_supplement_required"
                      style={{ color: "black" }}
                    >
                      Is custom tests suggestion required?
                    </label>
                    <br />
                    <Checkbox
                      id="is_custom_package_required"
                      checked={coachSuggestions.is_custom_package_required ?? null}
                      onChange={(e, data) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          is_custom_package_required: data.checked,
                          coach_custom_package_suggestions: [],
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      label={coachSuggestions.is_custom_package_required ? "Yes" : "No"}
                      disabled={isEditable ? order.is_custom_package_required ?? false : true}
                      toggle
                    />
                  </div>
                  <div className="col-4">
                    <label
                      htmlFor="coach_supplement_suggestion"
                      style={{ color: "black" }}
                    >
                      Suggested Custom Tests
                    </label>
                    <SelectCustomPackages
                      id="coach_custom_package_suggestions"
                      customPackages={customPackages ?? []}
                      selectedCustomPackageIds={
                        coachSuggestions.coach_custom_package_suggestions ?? []
                      }
                      onSelect={(options) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          coach_custom_package_suggestions: options.map(
                            (it) => it.value
                          ),
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      menuPlacement="bottom"
                      showOnlyActive={true}
                      isDisabled={!isEditable || !coachSuggestions.is_custom_package_required}
                    />
                    <span className="help-block">
                      {errors.coach_custom_package_suggestion}
                    </span>
                  </div>
                </div>


              </div>

            </div>



            <div className="font-weight-bold" style={{ padding: "10px" }}>Follow Up Details</div>
            <div className="suggestions" style={{ border: '2px solid black', padding: "10px" }}>
              <div className="row mt-2">
                <div className="col-4" style={{ paddingTop: "13px" }}>
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Follow Up Date
                  </label>
                </div>
                <div className="col-4">

                  <Dropdown
                    value={orderFollowUpDto && orderFollowUpDto.followUp}
                    onChange={(event, value) => {
                      this.handleFollowUpDate(value)
                    }}
                    id="followUp"
                    name="followUp"
                    placeholder="Period"
                    search
                    selection
                    options={PERIOD_OPTIONS}
                    // disabled={!isNewOtherItem && !editables.status}
                    className={"form-control"}
                  />
                     <span className="help-block">
                      {errors.followUp}
                    </span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Follow Up Package
                  </label>
                </div>


              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Blood Package
                  </label>
                  <br />
                  <Checkbox
                    id="isBloodPackage"
                    checked={followUpPackage.isBloodPackage ?? null}
                    onChange={(e, data) => {
                      const newFollowUpPackage = {
                        ...followUpPackage,
                        isBloodPackage: data.checked,
                        isMiniPackage: false,

                      };
                      this.setState({ followUpPackage: newFollowUpPackage });

                    }}
                    label={followUpPackage.isBloodPackage ? "Yes" : "No"}
                    disabled={isEditable ? followUpPackage.isMiniPackage ?? false : true}
                    toggle
                  />
                </div>
                {followUpPackage.isBloodPackage ? <div className="col-4">
                  <SelectPackage
                    packages={bloodPackages ?? []}
                    selectedPackage={orderFollowUpDto && orderFollowUpDto.bloodPackageId}
                    onSelect={(value) =>
                      this.handleFollowUpBloodPackage(value)
                    }
                    // isDisabled={!isNewSuggestion && !editables.periodId}
                    className={
                      "form-control"
                    }

                  />
                   <span className="help-block">
                      {errors.followUpBloodPackage}
                    </span>
                </div> : ""}
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Mini Package
                  </label>
                  <br />
                  <Checkbox
                    id="isMiniPackage"
                    checked={followUpPackage.isMiniPackage ?? null}
                    onChange={(e, data) => {
                      const newFollowUpPackage = {
                        ...followUpPackage,
                        isMiniPackage: data.checked,
                        isBloodPackage: false,

                      };
                      this.setState({ followUpPackage: newFollowUpPackage });
                    }}
                    label={followUpPackage.isMiniPackage ? "Yes" : "No"}
                    disabled={isEditable ? followUpPackage.isBloodPackage ?? false : true}
                    toggle
                  />
                </div>
                {followUpPackage.isMiniPackage ? <div className="col-4">
                  <SelectMiniPackages

                    miniPackages={miniPackages ?? []}
                    selectedMiniPackageIds={orderFollowUpDto && orderFollowUpDto.miniPackages || []}
                    onSelect={(options) => {
                      this.handleFollowUpMiniPackages(options)
                    }}
                    menuPlacement={"bottom"}
                    placeholder={"Select Mini Packages"}
                    className={
                      "form-control"
                    }
                  />
                   <span className="help-block">
                      {errors.followUpMiniPackage}
                    </span>
                </div> : ""}
              </div>
              <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="last-suggested">
                    <div>Last Suggested FollowUp Date - {orderFollowUpDto && orderFollowUpDto.lastSuggestedDate ? formatDate(orderFollowUpDto.lastSuggestedDate) : "-"}</div>
                    <div>Last Suggested Package - {this.renderLastSuggestedPackageName(orderFollowUpDto)}</div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    );

    const howDoYouHearAboutUsDetailsCard = (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%", zIndex: 5 }}
      >
        <div className="content">
          <div className="description">
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="how_did_you_hear_options" className="font-weight-bold">
                  How did you hear about us
                </label>
                <SelectHowDoYouHearAboutUsOptions
                  id="how_did_you_hear_options"
                  options={howDidYouHearDetails?.options ?? []}
                  selectedOptions={howDidYouHearDetails?.answers ?? []}
                  onSelect={(options) => {
                    const newHowDidYouHearDetails = {
                      ...howDidYouHearDetails,
                      answers: options.map((it) => it.value),
                    };
                    this.setState({ howDidYouHearDetails: newHowDidYouHearDetails });
                  }}
                  menuPlacement="bottom"
                  showOnlyActive={true}
                  isDisabled={!isEditable || howDidYouHearDetails?.showOptions === false}
                />
                <span className="help-block">
                  {errors.howDidYouHearDetails}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="hdydas_notes" className="font-weight-bold">
                  How did you hear about us (Notes)
                </label>
                <textarea
                  value={howDidYouHearDetails?.notes ?? ""}
                  onChange={(event) => {
                    const newHowDidYouHearDetails = { ...howDidYouHearDetails, notes: event.target.value};
                    this.setState({ howDidYouHearDetails: newHowDidYouHearDetails });
                  }}
                  id="hdydas_notes"
                  name="hdydas_notes"
                  placeholder="How did you hear about us (Notes)"
                  readOnly={!isEditable}
                  className="form-control"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const coachReviewCard = (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Coach’s Review</div>
          <div className="description">
            <div className="row">
              <div className="form-group col">
                <label htmlFor="overview">
                  Overall Result (English) *
                  <span>click on save icon to save your data</span>
                </label>
                <MUIRichTextEditor
                  value={overview ?? ""}
                  onChange={(e) => {
                    this.event = e;
                  }}
                  draftEditorProps={
                    {
                     "spellCheck":"true"
                    }
                  }
                  onSave={this.handleSaveOverview}
                  // readOnly={!this.state.editables.overview}
                  name="overview"
                  label="Overall result in English"
                  inlineToolbar={true}
                  readOnly={!isEditable}
                  className="form-control"
                />
                {/* {this.renderPencilIcon("overview", " float-right")} */}
                {<p className="help-block rte-mui">{errors.msg_en}</p>}
              </div>
              <div className="form-group col">
                <label>
                  Overall Result (Arabic) *
                  <span>click on save icon to save your data</span>
                </label>
                <MUIRichTextEditor
                  value={overview_ar ?? ""}
                  onChange={(e) => {
                    this.event_ar = e;
                  }}
                  draftEditorProps={
                    {
                     "spellCheck":"true"
                    }
                  }

                  onSave={this.handleSaveOverviewArabic}
                  // readOnly={!this.state.editables.overview_ar}
                  name="overview_ar"
                  label="Overall result in Arabic"
                  inlineToolbar={true}
                  readOnly={!isEditable}
                  className="form-control"
                />
                {/* {this.renderPencilIcon("overview_ar", " float-right")} */}
                {<p className="help-block rte-mui">{errors.msg_ar}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="ui">
        {/* <div className="row">
          <div className="col">{testResultsCard}</div>
        </div> */}
        <div className="row mb-2">
          <div className="col text-right">
        {dependentId ?  <NavLink to={`/dashboard/client-notes/${clientDetails?.id}/${order?.id}/${dependentId}`} target="_blank">
              <button 
                disabled={!clientDetails?.id || !order?.id} 
                // className="btn button-purple"
                className="btn btn-lg btn-outline-info"
              >
                  Client Notes
              </button>
            </NavLink>:
             <NavLink to={`/dashboard/client-notes/${clientDetails?.id}/${order?.id}`} target="_blank">
             <button 
               disabled={!clientDetails?.id || !order?.id} 
               // className="btn button-purple"
               className="btn btn-lg btn-outline-info"
             >
                 Client Notes
             </button>
           </NavLink>
            
            }
          </div>
        </div>
        <div className="row">
          <div className="col">
            {
              <OrderDetailsCard 
                key="order_details_card"
                order={order}
                customerTransactions={this.state.customerTransactions ?? []}
                isCustomerTransactionsLoaded={
                  this.state.isCustomerTransactionsLoaded
                }
                clientPastOrders={clientPastOrders}
              />
            }
          </div>
          <div className="col">
            {<ClientDetailsCard key="client_details_card" order={order} />}
          </div>
        </div>
        {!isPaidDoctorConsultation && (
          <div className="row mt-2">
            {/* <div className="col">{clientNotesCard}</div> */}
            <div className="col"></div>
            <div className="col">{howDoYouHearAboutUsDetailsCard}</div>
          </div>
        )}
        {!isPaidDoctorConsultation && (
          <div className="row mt-2">
            <div className="col">
              {!this.state.isDependentOrder &&
                <HealthProfileSurveyResultCard
                  key="health_profile_survey_result_card"
                  userId={order?.user?.id}
                  medicalProfilesurveyQuestions={medicalProfilesurveyQuestions}
                />
              }
            </div>
            <div className="col">
              {
                <GenericSurveyResultsCard
                  key="generic_survey_result_card"
                  order={order}
                  genericSurveyAnswers={genericSurveyAnswers}
                />
              }
            </div>
          </div>
        )}
        {(!isPaidDoctorConsultation && !isFoodIntoleranceTest) && (
          <div className="row mt-2">
            <div className="col">{testResultsCard}</div>
          </div>
        )}
        {isFoodIntoleranceTest ? (
         
          this.renderFoodIntoleranceResults()
          
        ):""}
        {!isPaidDoctorConsultation && (
          <div className="row mt-2">
            <div className="col-6">
              {
                <DownloadLabReportCard
                  key="download_report_card"
                  orderId={order?.id}
                  labResultFiles={labResultFiles}
                />
              }
            </div>
          </div>
        )}
        <div className="row mt-2">
          <div className="col">{coachSuggestionCard}</div>
        </div>
        <div className="row mt-2">
          <div className="col">
            {coachReviewCard}
            <button
              onClick={(e) => this.handleSubmit()}
              disabled={!isEditable}
              type="button"
              className="btn px-4 mt-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              Save for Later
            </button>
            <button
              onClick={(e) => this.handleSubmit(true)}
              disabled={!isEditable}
              type="button"
              className="btn btn-success px-4 mt-4 ml-2"
            >
              Submit to Client
            </button>
            <button
              type="button"
              className="btn btn-danger px-4 mt-4 ml-2"
              onClick={(e)=>{this.handleDeletePastOrder(e)}}
            >
              Cancel Order
            </button>
          </div>
        </div>

        <ConfirmOrderCancelViewModal
          cancelNotesFinal = {this.state.cancelNotes}
          isVisible={this.state.isConfirmOrderCancelViewModalVisible}
          onHide = {this.hideCancelNotesViewModal}
          notifyClient = {this.state.isNotifyClientCancel}
          onCancel = {this.handleSaveCancelNotes}
          reason = {this.state.cancelReason}
        />

        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title>{this.state.selectedTest?.test_name}</Modal.Title> */}
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TestResultDetails
              userDetails={order?.user}
              testResult={this.state.selectedTest}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardDetail);
