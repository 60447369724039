import PdfViewer from "components/Common/PdfViewer";
import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import TestResultsTable from "./TestResultsTable";
import "./VerifyTestResultsViewModal.css";

class VerifyTestResultsViewModal extends Component {
  componentDidMount() {
    document.body.style.overflow = "none";
  }

  render() {
    const {
      isVisible,
      onHide,
      tests,
      results,
      testResults,
      parsedLabTestResults,
      viewMode,
      userType,
      result_messages,
      handleChange,
      testCategortList,
      labReportPdfUrl,
      handleSave,
    } = this.props;
    const verificationForm = (
      <div className="container fulscreen">
        <div className="row">
          <div className="col-6 scrollable">
            <TestResultsTable
              tests={tests}
              results={results}
              testResults={testResults}
              parsedLabTestResults={parsedLabTestResults}
              testCategortList={testCategortList}
              viewMode={viewMode}
              userType={userType}
              result_messages={result_messages}
              handleChange={handleChange}
            />
          </div>
          <div className="col-6 scrollable">
            <PdfViewer pdfFileUrl={labReportPdfUrl} />
          </div>
        </div>
      </div>
    );
    return (
      <Modal
        size="xl"
        show={isVisible}
        onHide={onHide}
        className="user-data-modal"
        dialogClassName="modal-dialog-fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify Test Results</Modal.Title>
          <div className="user-title"></div>
          <button onClick={handleSave} className="btn modal-save-btn">
            Save
          </button>
        </Modal.Header>
        <Modal.Body>{verificationForm}</Modal.Body>
      </Modal>
    );
  }
}

export default VerifyTestResultsViewModal;
