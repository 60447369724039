import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import Select from "react-select";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import _, { filter } from "lodash";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectedQuestions from "./SelectedQuestions";
import SelectSuggesstions from "./SelectSuggestions";
import { fetchCountries } from "services/RegionService";
import { fetchBundlesSummaryByCountry } from "services/BundleService";
import SelectOptions from "./SelectOptions";
import {fetchBundleMapping} from "./BundleMappingService"

const BASE_URL_QUESTIONNAIRES = "questionnaires/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class BundleMapping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      questions: [],
      rankOptions: [],
      question: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isQuestionViewModalVisible: false,
      selectedQuestionnaire: null,
      questionnaireOptions: [],
      questionnaires: [],
      selectedQuestionsFormapping: [],
      selectedCountry: null,
      bundles: [],
      mappedAnswers: [],
      addNewMappedAnswers:[],
      allMappingQuestionAnswers: [],
      editView: false,
      optionCheck:[],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Questionaaire",
        selector: "questionnaire",
        center: true,
        wrap: true,
      },
      {
        name: "Questions",
        question: "question",
        center: true,
        wrap: true,
        cell: (row) =>
          this.renderQuestions(row.possibleCombinationsOfUserAnswersList),
      },
      {
        name: "Answers",
        question: "answer",
        center: true,
        wrap: true,
        cell: (row) =>
          this.renderAnswers(row.possibleCombinationsOfUserAnswersList),
      },

      {
        name: "View",
        center: true,
        center: true,
        wrap: true,
        cell: (review) => this.renderViewButton(review.id),
      },
    ];
  }

   componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchQuestionnaires();
    this.fetchBundles();
    this.fetchBundleMapping();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.fetchBundles();
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OTHER_ITEMS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchBundleMapping = async () => {
    this.showLoading();
    try {
      const allMappingQuestionAnswers= (await fetchBundleMapping()) || [];
      this.setState({ allMappingQuestionAnswers });
      //  console.log("data:", data)
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Mapped Question Combinations ",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  renderQuestions = (possibleCombinationsOfUserAnswersList = []) => {
    return (
      <ul className="my-auto" style={{ width: "100%" }}>
        {possibleCombinationsOfUserAnswersList.length > 0 &&
          possibleCombinationsOfUserAnswersList.map((result, index) => (
            <li key={index}>{result.question ?? ""}</li>
          ))}
      </ul>
    );
  };

  renderAnswers = (possibleCombinationsOfUserAnswersList = []) => {
    return (
      <ul className="my-auto" style={{ width: "100%" }}>
        {possibleCombinationsOfUserAnswersList.length > 0 &&
          possibleCombinationsOfUserAnswersList.map((result, index) => (
            <li key={index}>{result.questionOption ?? ""}</li>
          ))}
      </ul>
    );
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleMappedQuestion(id);
        }}
      >
        View
      </button>
    </div>
  );

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  fetchQuestionnaires = async () => {
    const { selectedCountry } = this.state;
    this.showLoading();
    try {
      const { data: questionnaires = [] } = await PhoenixAPI.get(
        BASE_URL_QUESTIONNAIRES
      );
      // const countryBasedQuestionnaires = questionnaires.filter(
      //   (it) => it.countryId === selectedCountry.id
      // );
      const selectedQuestionnaire =
        this.state.selectedQuestionnaire &&
        questionnaires.find(
          (it) => it.id === this.state.selectedQuestionnaire.id
        );
      this.setState(
        { questionnaires: questionnaires, selectedQuestionnaire },
        () => this.createQuestionnaireOptions()
      );
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching questionnaires: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchBundles = async () => {
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchBundles:: Invalid country!", { selectedCountry });
      return;
    }
    this.showLoading();
    try {
      const { data: bundles = [] } = await fetchBundlesSummaryByCountry(
        countryId
      );
      this.setState({ bundles });
    } catch (error) {
      this.showErrorNotification("Error on fetching bundles: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

 
  createQuestionnaireOptions = () => {
    const { questionnaires = [] } = this.state;
    const [activeQuestionnaires, inactiveQuestionnaires] = _.partition(
      questionnaires,
      (it) => it.status === "Active"
    );
    const questionnaireOptions = [
      {
        label: "Active",
        options: activeQuestionnaires.map(this.createQuestionnaireOption),
      },
      {
        label: "Inactive",
        options: inactiveQuestionnaires.map(this.createQuestionnaireOption),
      },
    ];
    this.setState({ questionnaireOptions });
  };

  createQuestionnaireOption = (questionnaire) => {
    return {
      key: questionnaire.id,
      value: questionnaire.id,
      label: questionnaire.internalName,
    };
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNewQuestion = () => {
    this.setState({ isNewQuestion: true }, () => this.showQuestionViewModal());
  };

  findQuestionnaire = (questionnaireId) => {
    const { questionnaires = [] } = this.state;
    return questionnaires.find((it) => it.id === questionnaireId);
  };

  showQuestionViewModal = () => {
    this.setState({
      isQuestionViewModalVisible: true,
    });
  };

  hideQuestionViewModal = () => {
    this.setState({ isQuestionViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  resetForm = () => {
    this.setState({
      questionnaire: {},
      selectedQuestionnaire: null,
      errors: {},
      editables: {},
      selectedQuestionsFormapping: [],
      mappedAnswers: [],
      addNewMappedAnswers:[],
      questions: [],
      question: {},
      suggestions: [],
      editView: false,
      optionCheck:[],
    });
  };

  handleSelectedQuestion = (item) => {
    const { selectedQuestionsFormapping, selectedQuestionnaire } = this.state;

    if (selectedQuestionsFormapping.includes(item)) {
      let filtered = selectedQuestionsFormapping.filter((data) => data != item);
      this.setState({ selectedQuestionsFormapping: filtered });
    } else {
      this.setState({
        selectedQuestionsFormapping: [...selectedQuestionsFormapping, item],
      });
    }
  };

  handleSelectSuggestions = (values) => {
    const suggestions = values.map((item) => ({
      packageId: item.key,
      packageType: item.packageType,
    }));
    this.setState({ suggestions });
  };
  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewOtherItem ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  selectQuestionsForm = () => {
    const { questions = [], isEditable, question, selectedQuestionsFormapping } = this.state;

    return (
      <div>
        {questions.length>0 && questions.map((item, index) => {
          return (
            <div key={index}>
              <SelectedQuestions
                question={item}
                isEditable={isEditable}
                selectedQuestion={(item) => {
                  this.handleSelectedQuestion(item);
                }}
                isEditView={this.state.editView}
                selectedQuestionsFormapping={selectedQuestionsFormapping}
              />
            </div>
          );
        })}
      </div>
    );
  };

  handleSelectOptions = (values, index, item) => {
    const { mappedAnswers = [], addNewMappedAnswers=[]} = this.state;
   
    let k = [];
    
    if(values.length>0){
      values.map((it) => {

        const obj = {
          questionId: it.questionId,
          options: [it.key],
          
        };
  
        k.push(obj);
      });
  
      const mergedResult = {};
  
      // Iterate through the array and merge options based on questionId
      k.forEach((item) => {
        if (!mergedResult[item.questionId]) {
          mergedResult[item.questionId] = {
            questionId: item.questionId,
            options: [],
            status:false,
          };
        }
        mergedResult[item.questionId].options.push(...item.options);
      });
  
      // Convert the mergedResult object back to an array
      const output = Object.values(mergedResult);
  
      if (output.length > 0) {
        const firstObject = output[0];
      
        mappedAnswers[index] = firstObject;
      }

      mappedAnswers.length>0 && mappedAnswers.forEach(itemB => {
        const matchingItemA = addNewMappedAnswers.find(itemA => itemA.questionId === itemB.questionId);
        if (matchingItemA) {
            matchingItemA.options = itemB.options;
        }
    });
    
  
      this.setState({
        mappedAnswers,
        addNewMappedAnswers
      },()=>this.handleOptionCheck());
    }

    else{
      mappedAnswers[index].options=[];
      // const newMapped=[...mappedAnswers,mappedAnswers[index].options=[]]
      // console.log("Values no length:  mappedAnswers[index]", mappedAnswers[index])
        this.setState({mappedAnswers},()=>()=>this.handleOptionCheck())
      // console.log("Values no length:  newMapped", newMapped)
    }
   
  };
  handleOptionCheck=()=>{
    const {selectedQuestionsFormapping=[], mappedAnswers=[]}= this.state || {}
    let optionCheck=[];
    selectedQuestionsFormapping.length>0 && selectedQuestionsFormapping.forEach((questionId)=>{
      const findItem=mappedAnswers.length>0 && mappedAnswers.find((item)=>item.questionId===questionId);
      if(findItem){
        optionCheck.push(findItem)
     
      }
      else{
        const obj={
          "questionId":questionId,
          "options":[],
          "status":false
        }
        optionCheck.push(obj)
       
      }
    })
   this.setState({optionCheck})
  }

  handleQuestionStatus = (value, index) => {
  
    const { mappedAnswers = [],addNewMappedAnswers=[] } = this.state;
  
    const selectedQuestion = mappedAnswers[index];

  
    const selectedIndex = mappedAnswers.length>0 && mappedAnswers.findIndex(
      (item) => item.questionId === selectedQuestion.questionId
    );

    if (selectedIndex !== -1) {
      mappedAnswers[index].status = value.value;
    }

    mappedAnswers.forEach(itemB => {
      const matchingItemA = addNewMappedAnswers.find(itemA => itemA.questionId === itemB.questionId);
      if (matchingItemA) {
          matchingItemA.status =itemB.status ;
      }
  });
   
    this.setState({ mappedAnswers , addNewMappedAnswers}, ()=>this.handleOptionCheck());
  };

  selectCombination = () => {
    const {
      selectedQuestionsFormapping = [],
      questions = [],
      question = {},
      bundles = [],
      mappedAnswers = [],
      editView=false,
      addNewMappedAnswers=[],
      optionCheck=[]
    } = this.state;
    let newQuestions = [];
    
    
   
    if(editView){
      questions.map((it) => {
        mappedAnswers.map((question) => {
          if (it.id === question.questionId) {
            const updatedQuestion = { ...it, status: question.status };
            newQuestions.push(updatedQuestion);
          }
        });
      });
    }
    else{
      
      questions.map((it) => {
        addNewMappedAnswers.map((question) => {
          if (it.id === question.questionId){
            const newItem={...it, status:question.status}
            newQuestions.push(newItem);
          }
        });
      });
    }
    

  let indexFlagArray=[]
    if(optionCheck.length>0){
      indexFlagArray= optionCheck 
    }
    


    return (
      <div className="container mt-4">
        {newQuestions.map((item, index) => {
        
          return (
            <div
              className="row"
              style={{
                border: "1px solid #dedede",
                padding: "10px",
                marginBottom: "15px",
              }}
              key={index}
            >
              <div>
                <div className="form-group col">
                  <div className="input-group">
                    <p>{item.questionEnglish}</p>
                  </div>
                </div>
                <div className="form-group col">
                  <div className="input-group">
                    <SelectOptions
                      id="options"
                      suggestions={item.options ?? []}
                      selectedSuggestedIds={mappedAnswers[index]?.options || []}
                      onSelect={(values) => {
                        
                        // this.setState({
                        //   question: {
                        //     ...question,
                        //      h: index,
                        //   },
                        // },()=>this.handleSelectOptions(values, index, item));
                        // ;
                        this.handleSelectOptions(values, index, item)
                      }}
                      // isDisabled={editView}
                      // isDisabled={!isNewMiniPackage && !editables.tests}
                      // className={"form-control" + (errors.tests ? " has-error" : "")}
                    />
                    {/* {this.renderPencilIcon("tests")} */}
                  </div>
                </div>
              </div>
           
              <div className="form-group col-6">
                <label htmlFor="status">Status</label>
                <div className="input-group">
                  <Dropdown
                    value={item.status ?? false}
                    onChange={(event, value) => {
                      this.handleQuestionStatus(value, index);
                    }}
                    id="status"
                    name="status"
                    placeholder="Status"
                    search
                    selection
                    options={STATUS_OPTIONS}
                    disabled={false}
                    className={"form-control"}
                  />
                  {/* {this.renderPencilIcon("status")} */}
                </div>
                {/* <span className="help-block">{errors.status}</span> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  updateQuestion = async (questionId, payload) => {
    try {
      const response = await PhoenixAPI.patch(
        `admin/update_suggestion/${questionId}`,
        payload
      );
      return response;
    } catch (error) {
      console.log(
        "handle Mapped Questionnaire:: Error on updating questionnaire by id!",
        error
      );
      this.showErrorNotification(
        "Error on updating questionnaire by id! " + error.message ?? ""
      );
    }
  };

  saveQuestion = async (payload) => {
    try {
      const response = await PhoenixAPI.post("admin/add-suggestion/", payload);
      return response;
    } catch (error) {
      console.log(
        "handle Mapped Questionnaire:: Error on creating questionnaire by id!",
        error
      );
      this.showErrorNotification(
        "Error on creating questionnaire by id! " + error.message ?? ""
      );
    }
  };
  saveDeciderQuestion = async (selectedQuestionsFormapping) => {
    console.log("saveDeciderQuestion", selectedQuestionsFormapping);
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch(
        "admin/mark-as-decider",
        selectedQuestionsFormapping
      );
    } catch (error) {
      console.log(
        "Error in Updating bundle mapping combination mark as decider",
        error
      );
      this.showErrorNotification(
        "Error in Updating bundle mapping combination mark as decider",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  validate=()=>{
    const {
      suggestions =[],
      question,
      selectedQuestionnaire,
      selectedQuestionsFormapping,
      mappedAnswers,
      editView
    } = this.state;
    const errors={};
    if(selectedQuestionnaire==null || undefined){
      // this.showErrorNotification("Please select a Questionnaire!");
      // return;
      errors.selectedQuestionnaire="Please select a Questionnaire!"
    }

    if(mappedAnswers.length===0){
    
      // this.showErrorNotification("Please select answers for bundle mapping question");
      // return;
      errors.mappingQuestions="Please select answers for bundle mapping question"
    }
    else if (mappedAnswers.length>0){
    let optionCheck=[]
   
      selectedQuestionsFormapping.forEach((questionId)=>{
        const findItem=mappedAnswers.find((item)=>item.questionId===questionId);
        if(findItem){
          optionCheck.push(findItem)
       
        }
        else{
          const obj={
            "questionId":questionId,
            "options":[],
            "status":false
          }
          optionCheck.push(obj)
         
        }
      })
        
    
      let optionFlag=false;
      optionCheck.forEach((item)=>{
        if(item.options.length===0){
          optionFlag=true
           return 
        }
        else{
          optionFlag=false
        }
      })
  
      if(optionFlag){
        // this.showErrorNotification("Please select answers for bundle mapping question");
        // return;
        errors.mappingQuestions="Please select answers for bundle mapping question"
      }
       
    }

    if(suggestions.length===0){
      // this.showErrorNotification("Please select suggestions for bundle mapping question");
      // return;
      errors.suggestions="Please select suggestions for bundle mapping question"
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }
  handleSaveQuestion = async () => {
    const {
      suggestions =[],
      question,
      selectedQuestionnaire,
      selectedQuestionsFormapping,
      mappedAnswers,
      editView
    } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      const {selectedQuestionnaire="",mappingQuestions="", suggestions=""}= errors || {}
        if(selectedQuestionnaire){
          this.showErrorNotification(selectedQuestionnaire)
          return
        }
        else if(mappingQuestions){
          this.showErrorNotification(mappingQuestions)
          return
        }
        else if(suggestions){
          this.showErrorNotification(suggestions)
          return
        }
    
    }
    
    // Initialize an empty array to hold the output
    let possibleCombinationsOfUserAnswersList = [];

    // Loop through mappedAnswers and create objects combining options with suggestions
    mappedAnswers.forEach((answer) => {
      answer.options.forEach((option) => {
        const suggestionObjects = suggestions.length>0 && suggestions.map((suggestion) => ({
          ...suggestion,
        }));
        const optionObject = {
          questionId: answer.questionId,
          questionOptionId: option,
          status: answer.status ?? false,
          suggestionForUserAnswersList: suggestionObjects,
        };
        possibleCombinationsOfUserAnswersList.push(optionObject);
      });
    });

    const payload = {
      questionnaireId: selectedQuestionnaire.id,
      possibleCombinationsOfUserAnswersList:
        possibleCombinationsOfUserAnswersList,
    };
    try {
      this.showLoading();
      const isValidId = question.id !== null && question.id !== undefined;
      const response = isValidId
        ? await this.updateQuestion(question.id, payload)
        : this.saveQuestion(payload);
      await this.saveDeciderQuestion(selectedQuestionsFormapping);
      this.hideQuestionViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Mapping Bundle Question is Updated Successfully"
          : "Mapping Bundle Question is Created Successfully"
      );
      this.fetchBundleMapping();
      // window.location.reload()
    } catch (error) {
      console.log("Error on creating Mapping Question", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  };

  handleMappedQuestion = async (questionId) => {
    const { questionnaires = [], question = {} } = this.state;
    if (!questionId) {
      return;
    }
    try {
      const { data: mappedQuestion = {} } = await PhoenixAPI.get(
        `admin/suggestion-by-id/${questionId}`
      );

      const {
        questionnaireId = null,
        questionsAndOptions = [],
        suggestion = [],
      } = mappedQuestion || {};
      const selectedQuestionnaire =
        questionnaires.length > 0 &&
        questionnaires.find((it) => it.id === questionnaireId);
      const selectedQuestionsFormapping =
        (questionsAndOptions.length > 0 &&
          questionsAndOptions.map((item) => item.questionId)) ||
        [];

      const questions = selectedQuestionnaire.questions ?? [];

      const selectedQuestionsIds =
        (questionsAndOptions.length > 0 &&
          questionsAndOptions.map((item) => {
            return item.questionId;
          })) ||
        [];

      const selectedFilteredQuestions = questions.length>0 && questions.filter((item) =>
        selectedQuestionsIds.includes(item.id)
      );

      const suggestionForUserAnswersList =
        (suggestion && suggestion.map((item) => item.packageId)) || [];
      const suggestions =
        suggestion &&
        suggestion.map((item) => {
          return {
            packageId: item.packageId,
            packageType: item.packageType,
          };
        });

      const viewQuestion = {
        ...question,
        suggestionForUserAnswersList: suggestionForUserAnswersList,
        id: questionId,
      };
      this.setState(
        {
          selectedQuestionnaire,
          mappedAnswers: questionsAndOptions,
          selectedQuestionsFormapping,
          questions: selectedFilteredQuestions,
          question: viewQuestion,
          suggestions,
          editView: true,
        },
        () => this.showQuestionViewModal()
      );
    } catch (error) {
      console.log(
        "handle Mapped Questionnaire:: Error on fetching questionnaire by id!",
        error
      );
      this.showErrorNotification(
        "Error on fetching questionnaire by id! " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  getFilterdAllMappingQuestions=(allMappingQuestionAnswers)=>{
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return allMappingQuestionAnswers;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return allMappingQuestionAnswers.filter((combination)=>{
    const {
      id,
      questionnaire
    }= combination
    return ( 
      (questionnaire && questionnaire.toLowerCase().indexOf(newValue) !== -1) ||
      
      (id && id.toString() === newValue) 

    )
    })
  }
  proceedToMap=()=>{
    
    const {selectedQuestionsFormapping=[], selectedQuestionnaire=null}= this.state;
    if(selectedQuestionnaire!==null){
      let addNewMappedAnswers=[]
      selectedQuestionsFormapping.length>0 && selectedQuestionsFormapping.map((item)=>{
       const obj={
        "questionId":item,
       
         "status":false,
       }
       addNewMappedAnswers.push(obj)
      })
  
      this.setState({addNewMappedAnswers})
    }
    else{
      this.setState({addNewMappedAnswers:[], selectedQuestionsFormapping:[]})
    }
   
  }
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      isQuestionViewModalVisible,
      selectedQuestionnaire,
      questions,
      selectedQuestionsFormapping,
      bundles,
      question,
      allMappingQuestionAnswers,
      editView
    } = this.state;


    const filteredAllMappingQuestionAnswers=this.getFilterdAllMappingQuestions(allMappingQuestionAnswers) ?? []
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const formBasicInformation = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
              <label>Select Questionnaire</label>
              <Select
                key={`my_unique_select_key__${
                  selectedQuestionnaire && selectedQuestionnaire.id
                }`}
                value={
                  selectedQuestionnaire &&
                  this.createQuestionnaireOption(selectedQuestionnaire)
                }
                onChange={(event) => {
                  const selectedQuestionnaire = event
                    ? this.findQuestionnaire(event.value)
                    : null;
                  const questions = selectedQuestionnaire?.questions ?? [];
                  const activeQuestions = questions.filter(
                    (item) =>
                      item.answerType !== "PLAIN_TEXT" &&
                      item.status === "Active"
                  );
                  this.setState({
                    selectedQuestionnaire,
                    questions: activeQuestions,
                    selectedQuestionsFormapping:[]
                  });
                }}
                
                options={this.state.questionnaireOptions || []}
                style={selectStyles}
                placeholder="Questionnaire"
                isClearable={true}
                searchable
                lazyLoad
                isDisabled={editView}
              />
            </div>
            {(selectedQuestionsFormapping.length>0 && !editView) && <div className="form-group col" >
            <label>Please click Map Combination button to see selected questions</label>
             <div  className="input-group">
             <button className="map-combination" onClick={this.proceedToMap}>Map Combination</button>
             </div>
            
            </div>}
          </div>
        </div>
        {this.selectQuestionsForm()}
      </>
    );

    const suggestionsForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col-4">
              <label htmlFor="suggestion">Suggestion</label>
              <div className="input-group">
                <SelectSuggesstions
                  id="suggesstions"
                  suggestions={bundles ?? []}
                  selectedSuggestedIds={
                    question.suggestionForUserAnswersList ?? []
                  }
                  onSelect={(values) => {
                    this.setState({
                      question: {
                        ...question,
                        suggestionForUserAnswersList: values.map(
                          (it) => it.value
                        ),
                      },
                    });
                    this.handleSelectSuggestions(values);
                  }}
                  // isDisabled={!isNewMiniPackage && !editables.tests}
                  // className={"form-control" + (errors.tests ? " has-error" : "")}
                />
                {/* {this.renderPencilIcon("tests")} */}
              </div>
            </div>
            {/* <div className="form-group col">
              <label htmlFor="status">Status</label>
              <div className="input-group">
                <Dropdown
                  value={question?.status}
                  onChange={(event, value) => {
                    this.setState({
                      question: {
                        ...question,
                        status: value.value,
                      },
                    });
                  }}
                  id="status"
                  name="status"
                  placeholder="Status"
                  search
                  selection
                  options={STATUS_OPTIONS}
                 
                  className={
                    "form-control" + (errors.status ? " has-error" : "")
                  }
                />
                
              </div>
           
            </div> */}
          </div>
        </div>
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <>
        <div className="manage-package">
          <CountryDropdown
            countries={countries || []}
            selectedCountry={selectedCountry}
            onSelect={this.handleCountrySelection}
          />
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>
              <div className="action-item">
                <button
                  onClick={this.handleAddNewQuestion}
                  disabled={!isEditable}
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>
          <Card body>
            <p className="font-weight-bold">Mapping Bundle Question</p>
            <DataTable
              data={filteredAllMappingQuestionAnswers ?? []}
              columns={this.columns ?? []}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noHeader={searchApplied ? false : true}
            />
          </Card>

          <Modal
            size="lg"
            show={isQuestionViewModalVisible}
            onHide={this.hideQuestionViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Questionnaire: </span>
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSaveQuestion}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_questionnaires" justify>
                <Tab
                  eventKey="key_form_select_questions"
                  title="Select Questions"
                >
                  {formBasicInformation}
                </Tab>
                <Tab
                  eventKey="key_form_mapping_questions"
                  title="Map Questions"
                >
                  {selectedQuestionsFormapping.length > 0 &&
                    this.selectCombination()}
                </Tab>
                <Tab eventKey="key_form_suggestions" title="Suggestions">
                  {suggestionsForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BundleMapping);
