import React, { useEffect, useState } from "react";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from 'services/UserService';
import Notification from "pages/HomecareMwebPortal/Notification";
import CustomLoader from "components/CustomLoader/CustomLoader";
import ProfileDetails from "pages/HomecareMwebPanelNew/ProfileDetails";
import SearchBar from "./SearchBar";
import OrderCards from "./OrderCards/OrderCards";
import {trackNurseSearchKeyword, trackNurseNotificationClick} from "analytics"
import "./nursepanelTabs.scss";
export default function NursePanelTabs(props){
      const{
        user,selectedMainTab,setSelectedMainTab,
        selectedTab,setSelectedTab,
        orders,setOrders,
       searchOrders,setSearchOrders,
       nurseUserId
      }=props;
     

    

      const today=moment().format("YYYY-MM-DD");
      const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
      const weekEndDate= moment().add(6, 'day').format('YYYY-MM-DD');
     const [message, setMessage] = useState("");
     const [isError, setIsError] = useState(false);
     const [isLoading, setIsLoading] = useState(false)
     const [searchFlag, setSearchFlag] = useState(false);
     const [searchValue, setSearchValue] = useState("");

      useEffect(()=>{
      let dayFormat=""
      if (selectedTab == "key_order_today"){
        dayFormat= "TODAY"
      }
      else if(selectedTab=="key_order_tomorrow"){
        dayFormat="TOMORROW"
      }
      else if(selectedTab=="key_order_thisweek"){
        dayFormat="THIS WEEK"
      }
      else if(selectedTab=="key_order_all"){
        dayFormat="ALL"
      }
      const today=moment().format("YYYY-MM-DD");
    
      fetchOrders(dayFormat,today);
    },[selectedMainTab, selectedTab])

     useEffect(()=>{
      if(searchFlag){
        const newValue =searchValue&&  searchValue.slice(0).trim().toLowerCase();
        const filter= orders && orders.filter((order)=>{
          const {packageName, orderId, userId,userName,confirmedDateTime}= order || {}
        return(
          (userName && userName.toLowerCase().indexOf(newValue) !== -1) ||
          (packageName && packageName.toLowerCase().indexOf(newValue) !== -1) ||
          (orderId && orderId.toString() === newValue) 
        )
        })
        
        setSearchOrders(filter)
      }
     
    },[searchFlag, searchValue])


 
  

const fetchOrders=async(dayFormat,today)=>{
  let url="";
  if(dayFormat=="ALL"){
    url=`nurse/new-orders/${nurseUserId}`   
  }
  else{
    url=`nurse/new-orders/${nurseUserId}?relativeDate=${dayFormat}&currentDateString=${today}` 
  }

  try{
    setIsLoading(true)
    const { data: orders = [] } =await PhoenixAPI.get(url);
    setOrders(orders);
  }
  catch(error){
    console.log("Error occurred in fetcching orders", error.message)
  }
  finally{
   setIsLoading(false)
  }
}




 

const ProfileForm=(<>
   <ProfileDetails user={user} />
   </>)


const OrdersForm = ({searchFlag=false, searchValue=""}) => {

  const TodayOrdersForm = ({searchFlag=false, searchValue=""}) => {
   
    return (
      <div>
       {" "}
        {Object.keys(user).length > 0 &&
       
          selectedTab == "key_order_today" ? (
          <>
            <OrderCards
              startDate={today}
              endDate={today}
             
            
              
              
              setSearchOrders={setSearchOrders}
              searchOrders={searchOrders}
              setOrders={setOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              orders={orders}
              user={user}
              
            />
          </>
        ) : (
          <>"No Orders Available"</>
        )}
      </div>
    );
  };

  const TomorrowOrderForm = ({searchFlag=false, searchValue=""}) => {
    
    return (
      <>
        {" "}
        {Object.keys(user).length > 0 &&
          selectedTab == "key_order_tomorrow" ? (
          <OrderCards
            setSearchOrders={setSearchOrders}
            orders={orders}
            searchOrders={searchOrders}
            setOrders={setOrders}
            searchFlag={searchFlag}
            searchValue={searchValue}
            user={user}
            
          />
        ) : (
          <>"No Orders Available"</>
        )}
      </>
    );
  };
  const WeekForm = ({searchFlag=false, searchValue=""}) => {
  
    return (
      <>
        {Object.keys(user).length > 0 &&
        
          selectedTab == "key_order_thisweek" ? (
          <OrderCards
          
           
            orders={orders}
          
           
            searchOrders={searchOrders}
            setSearchOrders={setSearchOrders}
            setOrders={setOrders}
            searchFlag={searchFlag}
            user={user}
            searchValue={searchValue}
          />
        ) : (
          <>"No Orders Available"</>
        )}
      </>
    );
  }
  const AllOrdersForm = ({searchFlag=false, searchValue=""}) => {
    return (
      <>
        {Object.keys(user).length > 0 && selectedTab == "key_order_all" ? (
          <OrderCards
            setSearchOrders={setSearchOrders}
            searchOrders={searchOrders}
            searchFlag={searchFlag}
            searchValue={searchValue}
            setOrders={setOrders}
            orders={orders}
            user={user}
           
          />
        ) : (
          <>"No Orders Available"</>
        )}
      </>
    );
  };
  return (
    <>
      {user && Object.keys(user).length > 0 && (
        <div className="nurse-orders-form-tabs">
          <Tabs
            id="orders_days"
            defaultActiveKey="key_order_today"
            activeKey={selectedTab}
            style={{
              backgroundColor: "#ffffff",
              width: "90%",
              display: "flex",
              padding: "0px",
              width: "90%",
              margin: "0 auto",
              justifyContent: "space-between",
             
            }}
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="key_order_today"
              title={<div className="book-all-tabs">Today</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_today" ? (
                <TodayOrdersForm
                searchFlag={searchFlag} searchValue={searchValue}
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              style={{ fontSize: "12px" }}
              eventKey="key_order_tomorrow"
              title={<div className="book-all-tabs">Tomorrow</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_tomorrow" ? (
                <TomorrowOrderForm
                searchFlag={searchFlag} searchValue={searchValue}
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              eventKey="key_order_thisweek"
              title={<div className="book-all-tabs">This week</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_thisweek" ? (
                <WeekForm
                searchFlag={searchFlag} searchValue={searchValue}
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              eventKey="key_order_all"
              title={<div className="book-all-tabs">All</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_all" ? (
                <AllOrdersForm
                searchFlag={searchFlag} searchValue={searchValue}
                />
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );

};

  const handleTabSelect = (key) => {

    if (key == "key_order_today") {

        fetchOrders("TODAY", today)
       
      }
    else  if(key=="key_order_tomorrow"){
      
      fetchOrders("TOMORROW", today)
       
        }

      else  if(key=="key_order_thisweek"){
         
        fetchOrders("THIS WEEK", today)
          }
         else if(key=="key_order_all"){
          
          fetchOrders("ALL","")
            } 
        localStorage.setItem("selectedTab", key)
  setSelectedTab(key);
}

  const handleMainTab = (key) => {
    
    if(key==="notification_form"){
    
      return window.location.href="/dashboard/notifications"
    }
    setSelectedMainTab(key);
  };


const handleSearchFlag=(item)=>{
   setSearchFlag(item);
}

const handleSearchValue=(value)=>{
  const userDetails= user.user ?? {}
  const deviceId= sessionStorage.getItem("device_id") ??"";
  const  trackingData={
    "searchKeyword":value,
    "homecareId":userDetails?.homecare_service_id??"",
    "deviceId":deviceId,
    "nurseName":userDetails?.username??"",
    "nurseId":userDetails?.id??"",
     "deviceId":deviceId
   }
   trackNurseSearchKeyword(trackingData)
   setSearchValue(value);
}




  return (<div>
    
    <SearchBar  searchFlag={(item)=>handleSearchFlag(item)} searchValue={(searchValue)=>handleSearchValue(searchValue)}/>
   
    <div className="main-form-tabs">
    <Tabs id="tabs_bookings"  defaultActiveKey="open_orders_form" onSelect={handleMainTab}
      style={{
        position: 'fixed',
        bottom: '0px',
        textAlign: 'center',
        backgroundColor: '#ffffff',
        width: '100%',
        zIndex: 1000,
        display: 'flex',
        left: '0px',
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
        borderTop: '1px solid #e5e5e5',
        borderBottom: '1px solid #e5e5e5',
        padding: '0px',
        // justifyContent: "space-between"
        justifyContent:"space-evenly",

      }}  >

      <Tab
        eventKey="open_orders_form"
        title={<div className="tabs-main-img-div" style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="open_orders_form"?"https://d25uasl7utydze.cloudfront.net/assets/Group%20627730.svg":"https://d25uasl7utydze.cloudfront.net/assets/Grey%20New%20orders.svg"}`}  alt="Tab Image" />Open Orders</div>}

      >
     <OrdersForm searchFlag={searchFlag} searchValue={searchValue}/>
      </Tab>
      <Tab
        eventKey="notification_form"
        title={<div className="tabs-main-img-div" style={{ fontSize: "12px" }}><img src={`https://d25uasl7utydze.cloudfront.net/assets/notification%20(1).svg`} alt="Tab Image"/>Notification</div>}
        className="tab-name"
      ></Tab>
      <Tab
        eventKey="key_manage_profile"
        title={<div className="tabs-main-img-div" style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="key_manage_profile"?"https://d25uasl7utydze.cloudfront.net/assets/Blue%20profile.svg":"https://d25uasl7utydze.cloudfront.net/assets/mobile_profile.svg"}`} alt="Tab Image" />Profile</div>}

        className="tab-name"
      >{ProfileForm}</Tab>
    </Tabs>
    </div>
    
    {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
    <CustomLoader show={isLoading}/>
    </div>)

}

