import API from "utils/API";

const apiEndPoint = "biomarkers/"
export function getBiomarkList(){
    return API.get(apiEndPoint);
}
export function saveBiomarker(biomarkers){
    return API.post(apiEndPoint,biomarkers);
}
export function updateBiomarker(biomarkers,id){
    return API.put(apiEndPoint+id+'/',biomarkers);
}
export function deleteBiomarker(id){
    return API.delete(apiEndPoint+id+'/');
}