import React, { Component } from "react";
import { Modal } from "react-bootstrap";


export default function BiomarkerRemovedModal(props){
    const { biomarkersList=[],  isVisible,onHide=(f)=>f,onCancelBiomarker=(f)=>f,onOk=(f)=>f}=props;
    return (
        <Modal show={isVisible} onHide={onCancelBiomarker}>
          <Modal.Header closeButton  >
            <Modal.Title>
              <p style={{ fontSize: "1rem" }}>Deleted Biomarkers</p>
            </Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>
            
    
              <div className="row mt-1 ml-1">
              <p>List of biomarkers Are Removed from App</p>
              </div><div>
      <ul>
        {biomarkersList.map((biomarker, index) => (
          <li key={index}>{biomarker}</li>
        ))}
      </ul>
      </div>
      <div className="form-group  col text-right">
            <button
              onClick={()=>{onOk();onHide();}}
                type="button"
                className="btn button btn-success"
              >
                Ok
              </button>  &nbsp;&nbsp;
              <button
              onClick={onCancelBiomarker}
                type="button"
                className="btn button btn-secondary"
              >
              Exit
              </button> 
              
            </div>
              
              
              </Modal.Body>
              
               </Modal>  )
                        


}