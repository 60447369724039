import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import { UploadMediaFile } from "services/UploadMedia";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class ValeoSection extends Component {
  constructor(props) {
    super(props);

    const { questions = [], onQuestionUpdated = (f) => f } = this.props;

    this.onQuestionUpdated = onQuestionUpdated;

    this.state = {
      questions,
      question: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
      rankOptions:[]
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Text (English)",
        selector: "textEnglish",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.textEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Text (Arabic)",
        selector: "textArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {question.textArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (question) => <Status isActive={question.isActive??false} />,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (question) => this.renderActionBar(question),
      },
    ];

   

  }

  clearForm = () => {
    
    this.setState({
      errors: {},
      editables: {},
      question: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleEdit = (id) => {
    
    const { questions = [] } = this.state;
    const question = questions.find((it) => it.id === id);
    if (!question) return;

    this.setState({ question, isNewQuestion:false }, () => this.showAddEditModal());
  };

  

  

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { question = {} } = this.state;
    if (question.id === null || question.id === undefined)
      this.createQuestion();
    else this.updateQuestion(question.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      textEnglish,
      textArabic,
      isActive
    } = this.state.question || {};

    if (!textEnglish || textEnglish.trim() === "")
      errors.textEnglish = "Text (English) cannot be blank";
    else if (textEnglish && textEnglish.length > 1000)
      errors.textEnglish =
        "Text (English) is limited to a 1000 characters";

    if (!textArabic || textArabic.trim() === "")
      errors.textArabic = "Text (Arabic) cannot be blank";
    else if (textArabic && textArabic.length > 1000)
      errors.textArabic =
        "Text (Arabic) is limited to a 1000 characters";


    // if (!isActive) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createQuestion = () => {
    const { questions = [], question } = this.state;
    const ids = questions.length > 0 ? questions.map((it) => it.id) : null;
    question.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      questions.length > 0
        ? questions.filter((it) => it.rank).map((it) => it.rank)
        : null;
    question.rank = ranks ? Math.max(...ranks) + 1 : 1;
    question.is_new = true;

    this.setState({ questions: [...questions, question] }, () => {
      this.showSuccessNotification("Valeo Section Created Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { questions = [], question } = this.state;
    const newQuestions = questions.map((it) => (it.id === id ? question : it));
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("Valeo Section Updated Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };






  onQuestionUpdatedLocally = () => {
    this.clearForm();
    this.onQuestionUpdated(
      this.state.questions.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
    
    this.setState({  isNewQuestion:true }, () => this.showAddEditModal());
  };

  createRankOptions = () => {
    const { isNewQuestion, questions } = this.state;

    const ranks = questions.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  showAddEditModal = () => {
    const { isNewQuestion}= this.state;
    const rankOptions = this.createRankOptions() || [];
    if(isNewQuestion){
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const question = {
        isActive: false,
        rank,
      };
      this.setState({ question, rankOptions,isAddEditModalVisible:true});
    }
    else{
      this.setState({ rankOptions, isAddEditModalVisible: true });
    }
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleSelectImage=async(e)=>{
    const question=this.state.question
    if (e.target.files && e.target.files.length){
        const file=e.target.files[0]
        const {message:imageUrl="", statusCode=""}=await UploadMediaFile(file);
        const newQuestion={...question,textImage:imageUrl}
        this.setState({question:newQuestion})
        console.log("Image", imageUrl)
    }
  }

  renderActionBar = (question) => (
    <div>
    <button
      className="btn px-5"
      style={{ backgroundColor: "#CEE741" }}
      onClick={() => {
        this.handleEdit(question.id);
      }}
    >
      Edit
    </button>
  </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.question.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      questions = [],
      question = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewQuestion = question.id === null || question.id === undefined;

    const form = (
      <>
       <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label htmlFor="imageInput">Header Image</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
    
              />
              {this.renderPencilIcon(`headerimage`)}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {question.textImage && (
              <>
                <label htmlFor="selected_package_image">image thumbNail</label>
                <div className="form-image-container">
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={question.textImage}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="textEnglish">Text (English)*</label>
            <div className="input-group">
              <textarea
                value={question.textEnglish}
                onChange={this.handleChange}
                id="textEnglish"
                name="textEnglish"
                placeholder="Text in English"
                readOnly={!isNewQuestion && !editables.textEnglish}
                className={
                  "form-control py-2" +
                  (errors.textEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("textEnglish")}
            </div>
            <span className="help-block">{errors.textEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="textArabic">Text (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={question.textArabic}
                onChange={this.handleChange}
                id="textArabic"
                name="textArabic"
                placeholder="Text in Arabic"
                readOnly={!isNewQuestion && !editables.textArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.textArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("textArabic")}
            </div>
            <span className="help-block">{errors.textArabic}</span>
          </div>
        </div>
      
        <div className="row">
        <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={question?.rank}
                onChange={(event, value) => {
                  this.setState({
                    question: { ...question, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={question?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    question: { ...question, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
            <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={questions || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {question.id === null || question.id === undefined
                ? "Add Section"
                : "Edit Section"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {question.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        
      </div>
    );
  }
}

ValeoSection.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onQuestionUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

ValeoSection.defaultProps = {
  questions: [],
  onQuestionUpdated: (f) => f,
  isEditable: true
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ValeoSection);
