import moment from "moment";

const TIME_FORMAT_24_HOUR = "HH:mm";
const TIME_FORMAT_12_HOUR = "hh:mm A";
const DEFAULT_DATE_FORMAT = "MMM DD, YYYY";
const DEFAULT_TIME_FORMAT = TIME_FORMAT_12_HOUR;
const DEFAULT_DATE_TIME_FORMAT = "MMM DD, YYYY, hh:mm A";

/**
 * Compares two strings and returns an integer value showing there comparison result.
 * @param {string} first The first string to compare.
 * @param {string} second The second string to compare with first.
 * @param {boolean} ignoreCase The case should be ignored or not, default is false.
 * @return {Number} The result of comparison.
 * @returns Returns -1 if first is less than second, 1 if first is greater than second or 0 if both are same.
 */
export const compareStrings = (first, second, ignoreCase = false) => {
  const f = first && ignoreCase ? first.toLowerCase() : first;
  const s = second && ignoreCase ? second.toLowerCase() : second;
  if (f > s) return 1;
  if (f < s) return -1;
  return 0;
};

export const compareNumbers = (first, second) => {
  if (first > second) return 1;
  if (first < second) return -1;
  return 0;
};

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) =>
  moment(date).format(format);

export const formatTime = (time, format = DEFAULT_TIME_FORMAT) =>
  moment(time).format(format);

export const formatDateTime = (dateTime, format = DEFAULT_DATE_TIME_FORMAT) =>
  moment(dateTime).format(format);

export const formatSingleTimeslot = (timeslot) => {
  // console.log("formatSingleTimeslot: timeslot: ", timeslot);

  try {
    const time12HourFormat = moment(
      timeslot,
      TIME_FORMAT_24_HOUR
    ).format(TIME_FORMAT_12_HOUR);
    return `${time12HourFormat}`;
  } catch (error) {
    console.log("Error formatting timeslot: ", timeslot, ", error", error);
    return "";
  }
};

export const formatTimeslot = (timeslot) => {
  // console.log("formatTimeslot: timeslot: ", timeslot);

  try {
    const [startTime24HourFormat, endTime24HourFormat] = timeslot.split("-");
    const startTime12HourFormat = moment(
      startTime24HourFormat,
      TIME_FORMAT_24_HOUR
    ).format(TIME_FORMAT_12_HOUR);
    const endTime12HourFormat = moment(
      endTime24HourFormat,
      TIME_FORMAT_24_HOUR
    ).format(TIME_FORMAT_12_HOUR);
    return `${startTime12HourFormat} to ${endTime12HourFormat}`;
  } catch (error) {
    console.log("Error formatting timeslot: ", timeslot, ", error", error);
    return "";
  }
};

export const formatTimeslotTwentyFourHourFormat = (timeslot) => {
  // console.log("formatTimeslotTwentyFourHourFormat: timeslot: ", timeslot);

  try {
    const [startTime, endTime] = timeslot.split("to");
    const startTime24 = moment(startTime, "hh:mm A").format("HH:mm");
    const endTime24 = moment(endTime, "hh:mm A").format("HH:mm");
    return `${startTime24}-${endTime24}`;
  } catch (error) {
    console.log("Error formatting timeslot: ", timeslot, ", error", error);
    return "";
  }
};

export const round = (number, numberOfPlacesAfterDecimal = 2) => {
  const factor = Math.pow(10, numberOfPlacesAfterDecimal);
  const result = number * factor;
  return Math.round(result) / factor;
};

export const rangeOfNumbers = (start, end, isEndInclusive = false) => {
  const range = [];
  for (let i = start; isEndInclusive ? i <= end : i < end; i++) {
    range.push(i);
  }
  return range;
};

const CommonUtils = {
  compareStrings,
  compareNumbers,
  formatDate,
  formatTime,
  formatDateTime,
  formatTimeslot,
  formatTimeslotTwentyFourHourFormat,
  round,
  rangeOfNumbers,
};

export default CommonUtils;
