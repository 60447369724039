import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import API from "utils/API";
import ActionBar from "react-bootstrap/Dropdown";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import * as moment from 'moment';

class CoachslotDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            months: ["January", "Febuary", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            columns: [
                {
                    name: "Days",
                    selector: "day",
                    sortable: false,
                    wrap: true,
                },
                {
                    name: "Slots",
                    selector: "slots",
                    sortable: false,
                    wrap: true,
                    cell: row => (
                        row.slots || '-'
                    )
                },
                {
                    name: "Actions",
                    center: true,
                    allowOverflow: true,
                    cell: row => (
                        <ActionBar alignRight className="action-menu valeo-dropdown">
                            <ActionBar.Toggle variant="">
                                <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                            </ActionBar.Toggle>
                            <ActionBar.Menu>
                                <ActionBar.Item
                                    className="alert"
                                    onClick={() => this.addSlots(row)}
                                >
                                    <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                                    Add
                                </ActionBar.Item>
                                <ActionBar.Item
                                    className="danger"
                                    onClick={() => this.showConfirm(row)}
                                >
                                    <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                                    Delete
                                </ActionBar.Item>

                            </ActionBar.Menu>
                        </ActionBar>
                    )
                }
            ],
            tableList: [],
            timeZone: "",
            availabilityColumns: [
                {
                    name: "Days",
                    selector: "day",
                    sortable: false,
                    wrap: true,
                },
                {
                    name: "Actions",
                    center: true,
                    allowOverflow: true,
                    cell: row => (
                        <ActionBar alignRight className="action-menu valeo-dropdown">
                            <ActionBar.Toggle variant="">
                                <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                            </ActionBar.Toggle>
                            <ActionBar.Menu>
                                <ActionBar.Item
                                    className="alert"
                                    onClick={() => this.hideHoliday(row)}
                                >
                                    <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                                    Hide
                                </ActionBar.Item>
                                <ActionBar.Item
                                    className="danger"
                                    onClick={() => this.deleteHoliday(row)}
                                >
                                    <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                                    Delete
                                </ActionBar.Item>

                            </ActionBar.Menu>
                        </ActionBar>
                    )
                }
            ],
            availabilityData: [],
            selectedLab: {},
            allLabsData: [],
            allSlots: [],
            labList: [],
            productsList: [],
            selectedRow: {},
            bloodAnalysisList: [],
            couchPackagesList: [],
            hideHolidays: {},
            holiday: {},
            availability: {},
            showModal: false,
            labListValue: "",
            blood_analysis: [],
            showConfirm: false,
            confirmItem: null,
            confirmMessage: "",
            confirmType: null,
            confirmTitle: "",
            successMessage: "",
            minDate: moment(new Date()).format('YYYY-MM-DD'),
            selectedSlot: []
        }
    }

    showConfirm(row) {
        let confirmMessage, successMessage, confirmTitle, confirmType;
        confirmTitle = "Delete Slot";
        confirmMessage = "Are you sure you want to delete this slot?";
        successMessage = "Slot Deleted Successfully.";
        confirmType = "success";

        if (row.slots.includes(';')) row.slots = row.slots.split(';');
        // const time = row.slots && Array.isArray(row.slots) && row.slots.length ? row.slots[0].split('-') : row.slots.split('-');
        this.setState({
            showConfirm: true,
            confirmItem: row,
            confirmMessage,
            successMessage,
            confirmType,
            confirmTitle,
            selectedRow: row,
            isHoliday: false,
            selectedSlot: row.slots && Array.isArray(row.slots) && row.slots.length ? row.slots : [row.slots]
            // startTime: time && time.length > 1 ? time[0] : '',
            // endTime: time && time.length > 1 ? time[1] : ''
        });
    }


    hideConfirm = () => {

        this.setState({
            showConfirm: false,
            confirmItem: null,
            confirmTitle: "",
            successMessage: "",
            confirmMessage: "",
            confirmType: null,
            selectedRow: null,
        });
    }


    addSlots = (data) => {
        this.setState({ showModal: true, formHeading: "Add Slot", selectedRow: data, isHoliday: false });
    }

    deleteSlots = (data) => {
        this.setState({ showModal: true, formHeading: "Delete Slot", selectedRow: data, isHoliday: false });
    }

    newSlot = () => {
        if (this.state.selectedLab.lead_time >= 0 && this.state.selectedLab.adv_booking_days >= 1) {
            let data = this.state.selectedLab || {};
            data["coach"] = this.state.coach_package;
            data["availability"] = this.state.availability;
            data["holiday"] = this.state.holiday;
            data["lead_time"] = this.state.selectedLab.lead_time;
            data["adv_booking_days"] = this.state.selectedLab.adv_booking_days;
            let url = "coach-slot-selection/"
            let method = "post"
            API[method](url, data).then(response => {
                this.getLabSlots();
                this.props.showNotificationMessage({
                    notificationMessage: "Coach Slot Availibility Updated Successfully.",
                    successMessage: true,
                    showNotification: true
                });
            }).catch(error => {
                this.props.showNotificationMessage({
                    notificationMessage: "Something went wrong.",
                    successMessage: false,
                    showNotification: true
                });
            }).finally(data => {
                this.getLabSlots();
                this.props.showNotificationMessage({
                    notificationMessage: "Coach Slot Availibility Updated Successfully.",
                    successMessage: true,
                    showNotification: true
                });
                this.setState({ showModal: false })
            })
        } else {
            this.props.showNotificationMessage({
                notificationMessage: "Something went wrong.",
                successMessage: false,
                showNotification: true
            });
        }
    }

    addSlot = () => {
        let { startTime, endTime, selectedLab, selectedRow, isNew, availability } = this.state;
        if (startTime === endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time and End Time not be same",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (startTime > endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time should not greater than End Time.",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        let b = availability?.[selectedRow?.day?.toLowerCase()]?.map((e) => {
            let _startTime = e.substring(0, 5)
            let _endTime = e.substring(6, 11)
            if (_startTime <= startTime && _endTime > startTime) {
                this.props.showNotificationMessage({
                    notificationMessage: "Slot Already Exits",
                    successMessage: false,
                    showNotification: true
                });
                return false;
            }
        })
        if (b.includes(false)) return;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);

        if (availability?.[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "Slot Already Exits",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability?.[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        availability[selectedRow?.day?.toLowerCase()].push(startTime + "-" + endTime);
        this.setState({ availability, showModal: false, startTime: "", endTime: "" });
        this.formatAvailablity(availability);
    }

    toggleActiveStatus(item) {
        const { successMessage } = this.state;

        if (!item) return;
        this.hideConfirm();
        this.deleteSlot();
    }

    deleteSlotClick = (event, slot) => {
        // event.preventdefault();
        let { selectedRow, availability } = this.state;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);
        const time = slot.trim().split('-');
        const startTime = time[0];
        const endTime = time[1];
        if (startTime === endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time and End Time not be same",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "No Slot found to delete",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        availability[selectedRow?.day?.toLowerCase()].splice(availability[selectedRow?.day?.toLowerCase()].indexOf(startTime + "-" + endTime), 1);
        this.setState({ availability });
        this.hideConfirm();
        this.formatAvailablity(availability);
    }
    deleteSlot = (slot) => {
        let { selectedRow, availability } = this.state;
        // startTime = Number(startTime?.split(':')[0]);
        // endTime = Number(endTime?.split(':')[0]);
        const time = slot.split('-');
        const startTime = time[0];
        const endTime = time[1];
        if (startTime === endTime) {
            this.props.showNotificationMessage({
                notificationMessage: "Start Time and End Time not be same",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()].includes(startTime + "-" + endTime)) {
            this.props.showNotificationMessage({
                notificationMessage: "No Slot found to delete",
                successMessage: false,
                showNotification: true
            });
            return;
        }

        if (!availability[selectedRow?.day?.toLowerCase()]) {
            availability[selectedRow?.day?.toLowerCase()] = [];
        }
        availability[selectedRow?.day?.toLowerCase()].splice(availability[selectedRow?.day?.toLowerCase()].indexOf(startTime + "-" + endTime), 1);
        this.setState({ availability });
        this.hideConfirm();
        this.formatAvailablity(availability);
    }

    handleSubmit = () => {
        if (this.state.isHoliday) {
            this.addHoliday();
        } else {
            if (this.state.formHeading == "Add Slot") {
                this.addSlot();
            } else {
                this.deleteSlot();
            }
        }
    }

    addHoliday = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
        let yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (today <= this.state.holidayDate) {
            let _holiday = this.state.holiday;
            let _holidayDate = this.state.holidayDate;
            let _hideHolidays = this.state.hideHolidays;
            if (!_holidayDate) {
                this.props.showNotificationMessage({
                    notificationMessage: "Please Select Date",
                    successMessage: false,
                    showNotification: true
                });
                return;
            }
            const months = this.state.months;;
            let _date = new Date(_holidayDate);
            let month = months[_date.getMonth()];
            let date = (_date.getDate()).toString();
            if (!_holiday[month]) _holiday[month] = [];
            if (_holiday[month].includes(date)) {
                if (_hideHolidays[month] && _hideHolidays[month][date]) {
                    delete _hideHolidays[month][date];
                    this.setState({ hideHolidays: _hideHolidays });
                    this.formatHoliday(_holiday, _hideHolidays);
                    this.hideModal();
                } else {
                    this.props.showNotificationMessage({
                        notificationMessage: "Holiday Already Exits",
                        successMessage: false,
                        showNotification: true
                    });
                }
                return;
            }
            _holiday[month].push(date);
            _holiday[month].sort((a, b) => { return Number(a) - Number(b) });
            this.hideModal();
            this.formatHoliday(_holiday);
            this.setState({ holiday: _holiday, holidayDate: "" })
        } else {
            this.props.showNotificationMessage({
                notificationMessage: "Invalid Date",
                successMessage: false,
                showNotification: true
            });

        }
    }

    deleteHoliday = (row) => {
        let _date = new Date(row.day);
        let _holiday = this.state.holiday;
        const months = this.state.months;;
        let month = months[_date.getMonth()];
        let date = (_date.getDate()).toString();
        if (!_holiday[month]) _holiday[month] = [];
        // if (!_holiday[month].includes(date)) {
        //     this.props.showNotificationMessage({
        //         notificationMessage: "No Date Found",
        //         successMessage: false,
        //         showNotification: true
        //     });
        //     return;
        // }
        _holiday[month].splice(_holiday[month].indexOf(date), 1);
        this.formatHoliday(_holiday);

    }

    hideHoliday = (row) => {
        let _hideHolidays = this.state.hideHolidays;
        let _date = new Date(row.day);
        const months = this.state.months;;
        let month = months[_date.getMonth()];
        let date = (_date.getDate()).toString();
        if (!_hideHolidays[month]) _hideHolidays[month] = {};
        _hideHolidays[month][date] = true;
        this.setState({ hideHolidays: _hideHolidays });
        this.formatHoliday(this.state.holiday, _hideHolidays);
    }

    hideModal = () => {
        this.setState({ showModal: false })
    }

    componentDidMount = () => {
        this.getLabSlots();
        this.setDefaultValue();
        // this.getLabs();
        this.getBloodAnalysis();
        // this.handleCoachSelection();
        // this.getCouchPackages();
        this.getUserCoachId();
    }

    getUserCoachId = () => {
        API.get("users/")
            .then((res) => {
                if (res?.data.length) this.setState({ coach_package: res?.data[0].id });
            })
            .catch((error) => {
                console.log("Error on fetching users", error);
            });
    }

    setDefaultValue = () => {
        this.setEmpty();
    }

    setEmpty = () => {
        let availability = {
            "sunday": [],
            "monday": [],
            "tuesday": [],
            "wednesday": [],
            "thursday": [],
            "friday": [],
            "saturday": []
        };
        this.setState({ availability, holiday: {} });
        this.formatAvailablity({});
    }

    formatAvailablity = (availability) => {
        let weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        let _tableList = [];

        for (let i = 0; i < weeks.length; i++) {
            _tableList.push({
                id: i + 1,
                slots: availability[weeks[i].toLowerCase()]?.join('; '),
                day: weeks[i]
            })
        }
        this.setState({ tableList: _tableList });
    }

    formatHoliday = (holiday, hideHolidays) => {
        let _availabilityData = [];
        let _hideHolidays = hideHolidays || this.state.hideHolidays;
        const months = this.state.months;;
        for (let i = 0; i < months.length; i++)
            if (holiday[months[i]]) {
                for (let j = 0; j < holiday[months[i]].length; j++) {
                    if (!(_hideHolidays[months[i]] && _hideHolidays[months[i]][holiday[months[i]][j]]))
                        _availabilityData.push({
                            id: i,
                            day: months[i] + " " + holiday[months[i]][j] + ", " + (new Date()).getFullYear()
                        })
                }

            }
        this.setState({ availabilityData: _availabilityData });
    }

    getLabSlots = async () => {
        let url = "coach-slot-selection/";
        let response = {};
        try {
            response = await API.get(url);
            let data=response?.data;
            let lastObj = data.length - 1;
            let _productsLab =data[lastObj]
            this.setState({ selectedLab: _productsLab });
            this.updateProductDetails(_productsLab);
        } catch (e) {
            console.log("Error on fetching coach slot selection", e);
        }
        this.setState({ allLabsData: response?.data || [] })
    }

    // getLabs = async () => {
    //     let url = "nutritionist/";
    //     let response = {};
    //     try {
    //         response = await API.get(url);
    //     } catch (e) { }
    //     let hash = {}, _labList = [], _allSlots = response?.data || [];
    //     for (let i = 0; i < _allSlots.length; i++)
    //         if (!hash[_allSlots[i].lab_name]) {
    //             hash[_allSlots[i].lab_name] = true;
    //             _labList.push({ key: _allSlots[i].lab_name, value: _allSlots[i].id, text: _allSlots[i].lab_name });
    //         }

    //     this.setState({ allSlots: _allSlots, labList: _labList })
    // }

    // getCouchPackages = async () => {
    //     let url = "add-on-packages/";
    //     let response = {};
    //     try {
    //         response = await API.get(url);
    //     } catch (e) { }

    //     let array = response?.data || [];
    //     let list = [];
    //     for (let i = 0; i < array.length; i++)
    //         list.push({ key: array[i].addon_name, value: array[i].id, text: array[i].addon_name });

    //     this.setState({ couchPackagesList: list })
    // }

    getBloodAnalysis = async () => {
        let url = "subscription-packages/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching packages", e);
        }

        let array = response?.data || [];
        let list = [];
        for (let i = 0; i < array.length; i++)
            list.push({ key: array[i].subscription_name, value: array[i].id, text: array[i].subscription_name });

        this.setState({ bloodAnalysisList: list })
    }

    handleChange = (data, key) => {
        this.setState({ [key]: data?.target?.value });
    }

    // handleCoachSelection = (lab) => {
    //     console.log( this.state.allLabsData)
    //     debugger
    // let _productsLab = this.state.allLabsData.filter(value => { return value.id == lab.value })
    // console.log(_productsLab);
    // let _labProfiles = this.state.allSlots.filter(value => { return value.id == lab.value })
    // console.log(_labProfiles);
    // if (_productsLab.length > 0) {
    //     let _selectedLab = _productsLab[0];
    //     this.setState({ selectedLab: _selectedLab });
    //     this.updateProductDetails(_selectedLab);
    // }
    // if (_labProfiles.length > 0) {
    //     let _selectedLab = _labProfiles[0];
    //     if (_selectedLab.id) {
    //         this.setState({ coach_package: _selectedLab.id });
    //     }
    // this.setState({ blood_analysis: _selectedLab.blood_analysis, coach_package: _selectedLab.coach_package[0] });
    // }

    // this.setState({ labListValue: lab.value })

    // }

    handleBloodAnalysis = (value) => {
        this.setState({ blood_analysis: value })
    }

    // handleCoachPackage = (value) => {
    //     this.setState({ coach_package: value.value })
    // }

    updateProductDetails = (product) => {
        let data = product ||
        {
            "time_zone": "Asia/Dubai",
            "availability": {
                "friday": [],
                "monday": [],
                "sunday": [],
                "tuesday": [],
                "saturday": [],
                "thursday": [],
                "wednesday": []
            },
            "holiday": {},
            "booking_per_slot": 0,
            "lead_time": 0,
            "adv_booking_days": 0,
            "lab": 0,
            "blood_analysis": [],
            "coach_package": []
        };
        let availability = data?.availability;
        let weeks = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        let _tableList = [];

        for (let i = 0; i < weeks.length; i++) {
            _tableList.push({
                id: i + 1,
                slots: availability[weeks[i].toLowerCase()]?.join('; '),
                day: weeks[i]
            })
        }

        let holiday = data?.holiday;
        let _availabilityData = [];
        const months = this.state.months;;
        for (let i = 0; i < months.length; i++)
            if (holiday[months[i]]) {
                for (let j = 0; j < holiday[months[i]].length; j++) {
                    _availabilityData.push({
                        id: i,
                        day: months[i] + " " + holiday[months[i]][j] + ", " + (new Date()).getFullYear()
                    })
                }

            }
        this.setState({ tableList: _tableList, availability: availability, availabilityData: _availabilityData, timeZone: data?.time_zone, isNew: true });
    }

    handleHoliday = () => {
        this.setState({ showModal: true, formHeading: "Add Holiday", isHoliday: true });
    }

    handleFormSubmit = () => {
        this.newSlot();
    }

    handleSelectedLabValueChange = (key, value) => {
        let _selectedLab = this.state.selectedLab;
        _selectedLab[key] = Number(value);
        this.setState({ selectedLab: _selectedLab });
    }

    render() {
        let { tableList, columns, availabilityColumns, availabilityData, timeZone, isHoliday,
            labList, productsList, selectedLab, bloodAnalysisList, couchPackagesList,
            blood_analysis, coach_package, selectedSlot
        } = this.state;

        const listItems = selectedSlot.map((slot) =>
            <li className="list" key={slot.toString()}>
                <span className="ui label deleteItem">{slot} <i aria-hidden="true" class="delete icon" onClick={(e) => this.deleteSlotClick(e, slot)}></i></span>
            </li>
        );
        const form = (
            <div className="form-row">
                {isHoliday ? <>
                    <div className="input-section form-group col-6">
                        <label>Date *</label>
                        <input
                            type="date"
                            min={this.state.minDate}
                            className={
                                "form-control "
                            }
                            placeholder="Start Time"
                            name="startTime"
                            value={this.state.holidayDate}
                            onChange={(e) => this.handleChange(e, 'holidayDate')}
                        ></input>
                        <span className="help-block">
                        </span>
                    </div>
                </>
                    :
                    <>
                        <div className="input-section form-group col-6">
                            <label>Start Time *</label>
                            <input
                                type="time"
                                className={
                                    "form-control "
                                }
                                placeholder="Start Time"
                                name="startTime"
                                value={this.state.startTime}
                                onChange={(e) => this.handleChange(e, 'startTime')}
                            ></input>
                            <span className="help-block">
                            </span>
                        </div>
                        <div className="input-section form-group col-6">
                            <label>End Time</label>
                            <input
                                type="time"
                                className="form-control"
                                placeholder="End Time"
                                name="endTime"
                                value={this.state.endTime}
                                onChange={(e) => this.handleChange(e, 'endTime')}
                            ></input>
                        </div>
                    </>}
            </div>
        );
        return (
            <div>
                <Modal show={this.state.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.formHeading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{form}</Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={(e) => this.handleSubmit(e)}
                            className="btn button button-green"
                        >
                            Submit
                        </button>
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                {/* <form onSubmit={(event) => console.log(event)}>
                    <div className="form-row">
                        <div className=" mt-2" style={{ marginRight: "91px" }}>
                            Select Coach:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={this.state.labListValue}
                                className={
                                    "form-control "
                                }
                                placeholder="Lab Name"
                                onChange={(event, value) => {
                                    this.handleCoachSelection(value)
                                }}
                                search
                                selection
                                options={labList}
                            />
                        </div>
                    </div>
                </form> */}
                {/* <form onSubmit={(event) => console.log("yes-->")}>
                    <div className="form-row">
                        <div className="mt-2 mr-3">
                            Select Product:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={blood_analysis}
                                className={
                                    "form-control "
                                }
                                placeholder="Select Product"
                                onChange={(event, value) => {
                                    this.handleBloodAnalysis(value.value)
                                }}
                                search
                                selection
                                options={bloodAnalysisList}
                                multiple
                            // disabled={}
                            />
                        </div>
                    </div>
                </form> */}

                {/* <form onSubmit={(event) => console.log("yes-->")}>
                    <div className="form-row">
                        <div className="mt-2 mr-3">
                            Select Coach Package:
                        </div>
                        <div className="form-group input-section input-section-long">
                            <Dropdown
                                value={coach_package}
                                className={
                                    "form-control "
                                }
                                placeholder="Coach Name"
                                onChange={(event, value) => {
                                    this.handleCoachPackage(value)
                                }}
                                search
                                selection
                                options={couchPackagesList}
                            />
                        </div>
                    </div>
                </form> */}
                <div style={{ display: "flex" }}>
                    <p className="mr-5">Time zone :</p>
                    <p>{timeZone} Standard Time</p>
                </div>
                <h5 className="mt-4 mb-4">Availability</h5>
                <Card>
                    <DataTable
                        highlightOnHover
                        columns={columns}
                        data={tableList}
                        // defaultSortField="day"
                        // sortIcon={<ArrowDownward></ArrowDownward>}+
                        responsive
                        noHeader={true}
                    />
                </Card>

                <div className="page-header">
                    <h5 className="mt-4 mb-4">Holidays</h5>
                    <div className="actions">
                        <div className="action-item">
                            <button
                                className="btn btn-sm button text-button "
                                onClick={() => { this.handleHoliday() }}
                            >
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                                add new
                            </button>
                        </div>
                    </div>
                </div>

                <Card>
                    <DataTable
                        highlightOnHover
                        columns={availabilityColumns}
                        data={availabilityData}
                        responsive
                        noHeader={true}
                    />
                </Card>

                {/* <div className="form-row">
                    <div className="mt-2" style={{ marginRight: "89px" }}>
                        Booking Per Slot :
                    </div>
                    <div className="form-group input-section input-section-long">
                        <input
                            type="number"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 1 to infinity"
                            name="categoryEn"
                            value={selectedLab?.booking_per_slot}
                            min={1}
                            onChange={(e) => this.handleSelectedLabValueChange('booking_per_slot', e.target.value)}
                        />
                    </div>
                </div> */}
                <div className="form-row">
                    <div className="mt-2" style={{ marginRight: "126px" }}>
                        Lead Time :
                    </div>
                    <div className="form-group input-section input-section-long">

                        <input
                            type="number"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 0 to infinity"
                            name="categoryEn"
                            value={selectedLab?.lead_time}
                            min={0}
                            onChange={(e) => this.handleSelectedLabValueChange('lead_time', e.target.value)}
                        />
                    </div>
                    <span className="mt-2 ml-2">Hours</span>
                </div>
                <div className="form-row">
                    <div className="mr-5 mt-2">
                        Advance Booking days :
                    </div>
                    <div className="form-group input-section input-section-long">
                        <input
                            type="number"
                            className={
                                "form-control "
                                //  + 
                                // (validation.categoryEn.isInvalid && "has-error")
                            }
                            placeholder="Range: 1 to infinity"
                            name="categoryEn"
                            value={selectedLab?.adv_booking_days}
                            min={1}
                            onChange={(e) => this.handleSelectedLabValueChange('adv_booking_days', e.target.value)}
                        />
                    </div>
                    <span className="mt-2 ml-2">Days</span>
                </div>
                <div className="page-header">
                    <h5 className="mt-4 mb-4">&nbsp;</h5>
                    <div className="actions">
                        <div className="action-item">
                            <button
                                onClick={(e) => this.handleFormSubmit(e)}
                                className="btn button button-green"
                            >
                                Submit
                            </button>
                            <button
                                className="btn btn-secondary button ml-2"
                                onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots')}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            {this.state.confirmMessage}
                            {listItems}
                            {/* {this.state.selectedSlot && this.state.selectedSlot.map(data => {
                                <span>{data}</span>
                            })} */}
                        </div>

                    </Modal.Body>
                    <Modal.Footer>

                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideConfirm}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

            </div>

        )
    }
}
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: value =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachslotDetails);