import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectRoles.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRoleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectRoles.defaultProps = {
  roles: [],
  selectedRoleIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectRoles(props) {
  const {
    roles,
    selectedRoleIds,
    onSelect,
    menuPlacement,
    isDisabled,
  } = props;

  const findRole = (roleId) => roles.find((role) => role.id === roleId);

  const options = createOptions(roles);

  const selectedRoles = selectedRoleIds.map(findRole).map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedRoleIds && selectedRoleIds.join(",")
      }`}
      value={selectedRoles}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(roles) {
  if (!roles || !roles.length) return [];
  roles.sort((first, second) => compareStrings(first.name, second.name, true));
  return roles.map(createOption);
}

function createOption(role) {
  if (!role) return;
  return {
    key: role.id,
    value: role.id,
    label: role.name,
  };
}

export default SelectRoles;
