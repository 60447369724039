import React, { useEffect, useState } from "react";

import PhoenixAPI from "utils/PhoenixAPI";
import OpenGoogleMapWithPin from "./OpenMap";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import { bookingStatus } from "./bookingStatus";
import "./order.scss";
import API from "utils/API";
import { fetchBookings, fetchProfessionals } from "./Services";
import NurseModal from "./NurseModal";
import Notification from "./Notification";
import BroadcastTimer from "pages/HomeCarePortal/BroadcastTimer";

export default function UpcomingCards(props) {
  const {
    startDate,
    endDate,
    homecareServiceId,
    setIsShowClient,
    setIsShowOrder,
    setClientDetail,
    setSearchOrders,
    searchOrders,
    orders,
    setOrders,
    searchFlag=false,
    selectedTab,
    handleAcceptOrder,
    handleRejectOrder,
  } = props;

  const [isError, setIsError] = useState(false);
  const [bookings, setBookings] = useState([]);

  const [message, setMessage] = useState("");

  const getFullAddress = (order) => {
    if (!order) return "";

    const { addressLine1, addressLine2, buildingApt, city, country } = order;
    const location = [addressLine1, addressLine2, buildingApt, city, country]
      .filter((it) => it ?? false)
      // .join("\r\n");
      .join(", ");

    return location;
  };

  const handleAccept = (item) => {
    handleAcceptOrder({ item, selectedTab });
  };
  const handleReject = (item) => {
    handleRejectOrder({ item, selectedTab });
  };
  const renderOrderCards = (orders) => {

    return (
      <>
        {orders &&
          orders.map((item, index) => {
            const [appointmentDate, appointmentTime] =
              item && item.confirmedDateTime
                ? item.confirmedDateTime.split(" ")
                : [null, null];

            const formattedDate = appointmentDate
              ? formatDate(appointmentDate)
              : "";
            const formattedTimeslot = appointmentTime
              ? formatSingleTimeslot(appointmentTime)
              : "";

            return (
              <div className="clients-card">
                <div className="client-name-date">
                  <div className="name-txt">{item.packageName}</div>
                  {(formattedDate && formattedTimeslot )&&<div className="date-time">
                    <span className="date"> {formattedDate}</span>
                    <span id="separator">|</span>
                    <span className="date">{formattedTimeslot}</span>
                  </div>}
                </div>
                {item.specialInstructions ? (
                  <div className="payment-collection">
                    Special Instructions :{" "}
                    <span className="id">{item.specialInstructions}</span>
                  </div>
                ) : (
                  <></>
                )}

                <div className="client-location">
                  <div className="location-cont">
                    <div className="location-in-text">
                      <div className="location-header">Address</div>
                      <div className="location-detail">
                        {getFullAddress(item) || "null"}
                      </div>
                      <div
                        className="location-in-map"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="open-map-btn">
                          <OpenGoogleMapWithPin item={item} />
                        </div>
                      </div>
                    </div>
                    {item.orderId ? (
                      <div className="item-status">
                        <div className="order-id-div">ID : {item.orderId}</div>
                      
                        <div className="upcoming-order">UpComing</div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              

                <div
                  className="timer-container"
                 
                >
                  <div className="time-left">Time Left: </div>
                  <BroadcastTimer timer={item.timer ?? null} />
                </div>
                <div
                  className="btn-container"
                
                >
                  <div>
                    <button
                      className="reject-btn"
                    
                      onClick={() => handleReject(item)}
                    >
                      Deny
                    </button>
                  </div>
                  <div>
                    <button
                      className="accept-btn"
                      onClick={() => handleAccept(item)}
                    >
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      <div className="clients-wrapper-home-care">
        {searchFlag ? renderOrderCards(searchOrders) : renderOrderCards(orders)}

        {message && (
          <Notification
            setMessage={setMessage}
            message={message}
            isError={isError}
          />
        )}
      </div>
    </>
  );
}
