import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import Alert from "react-bootstrap/Alert";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import API from "utils/API";

class AddonForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "addon_name",
        method: "isEmpty",
        validWhen: false,
        message: "Name (English) is a required field!"
      },
      {
        field: "addon_name_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Name (Arabic) is a required field!"
      },
      {
        field: "addon_description",
        method: "isEmpty",
        validWhen: false,
        message: "Description (English) is a required field!"
      },
      {
        field: "addon_description_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Description (Arabic) is required!"
      },
      {
        field: "addon_validity",
        method: "isEmpty",
        validWhen: false,
        message: "Validity is a required field!"
      },
      {
        field: "addon_price",
        method: "isEmpty",
        validWhen: false,
        message: "Price is a required field!"
      }
    ]);
    this.state = {
        addon_name: '',  
        addon_name_ar: '',  
        addon_description: '',  
        addon_description_ar: '',  
        addon_validity: '',
        addon_price: '',
        addon_plans: [],
        validation: this.validator.valid(),
        alertMessage: "",
        edit_id: null,
        parent_package: props.editId,
    };
    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.fetchPackages();
  }

  fetchPackages() {
    API.get('add-on-packages/')
      .then(
        (response) => {
          let result = response.data;
          let addon_plans = result.filter((item) => item["is_parent"] === false && item["is_active"] === true);
          this.setState({ addon_plans });
        })
      .catch((error) => {
        console.log("Error on fetching add on packages", error);
      });
  }

  handleChange(event) {
    const target = event.target;
    let value = target.value;
    if (target.type === "number") {
      value = parseFloat(target.value);
    }
    this.setState({ [target.name]: value });
  }

  // editPackage(index){
  //     this.setState({
  //       addon_name: this.state.addon_plans[index].addon_name,  
  //       addon_name_ar: this.state.addon_plans[index].addon_name,  
  //       addon_description: this.state.addon_plans[index].addon_description,  
  //       addon_description_ar: this.state.addon_plans[index].addon_description_ar,  
  //       addon_validity: this.state.addon_plans[index].addon_validity,
  //       addon_price: this.state.addon_plans[index].addon_price,
  //       edit_id: this.state.addon_plans[index].id
  //     })
  // }

  editPackage(id) {
    const plan = this.state.addon_plans.find(plan => plan.id === id);
    this.setState({
      addon_name: plan.addon_name,
      addon_name_ar: plan.addon_name,
      addon_description: plan.addon_description,
      addon_description_ar: plan.addon_description_ar,
      addon_validity: plan.addon_validity,
      addon_price: plan.addon_price,
      edit_id: plan.id
    })
  }

  packageOperation(operation, packageId){
    if(operation === 'add'){
        // debugger;
        const validation = this.validator.validate(this.state);
        this.submitted = true;
        this.setState({ validation });
        if (validation.isValid) {
            let {
                addon_name,
                addon_name_ar,
                addon_description,
                addon_description_ar,
                addon_validity,
                addon_price,
                parent_package,
              } = this.state;
            API.post('add-on-packages/', {
                addon_name : addon_name,
                addon_name_ar : addon_name_ar,
                addon_description : addon_description,
                addon_description_ar : addon_description_ar,
                addon_validity : addon_validity,
                addon_price : addon_price,
                country: this.props.countryId,
                parent_package: parent_package
            }
            ).then((response) => {
                //update the arrAddon
                this.formReset();
            }).catch((error) => {
              console.log("Error on saving add on package", error);
              this.handleAPIError(error);
            });
        }

    }else if(operation === 'update'){
        // debugger
        const validation = this.validator.validate(this.state);
        this.submitted = true;
        this.setState({ validation });
        if (validation.isValid) {
            let {
                addon_name,
                addon_name_ar,
                addon_description,
                addon_description_ar,
                addon_validity,
                addon_price,
                parent_package
              } = this.state;
            API.patch('add-on-packages/' + this.state.edit_id + '/', {
                addon_name : addon_name,
                addon_name_ar : addon_name_ar,
                addon_description : addon_description,
                addon_description_ar : addon_description_ar,
                addon_validity : addon_validity,
                addon_price : addon_price,
                parent_package: parent_package,
            }
            ).then((response) => {
                //update the arrAddon
                this.formReset();
            }).catch((error) => {
              console.log("Error on updating add on package");
              this.handleAPIError(error);
            });
        }

    }else if(operation === 'delete'){
        API.patch('add-on-packages/' + packageId + '/', {
            is_active: false
        }
        ).then((response) => {
            //update the arrAddon
            this.fetchPackages();
        }).catch((error) => {
          console.log("Error on deleting add on package", error);
          this.handleAPIError(error);
        });
    }
  }

  handleAPIError = (error) => {
    if (error.response && error.response.data) {
      const data = error.response.data;
      if (data.addon_validity)
        this.setState({ error_addon_validity: error.response.data.addon_validity.join(", ") });
      if (data.addon_price)
        this.setState({ error_addon_price: error.response.data.addon_price.join(", ") });
    }
    this.props.showErrorMessage({
      notificationMessage: "Some error has occured. Cause of the error is " + error,
      successMessage: false,
      showNotification: true,
    });
  }

  formReset() {
      // debugger;
    this.setState({
        addon_name: '',  
        addon_name_ar: '',  
        addon_description: '',  
        addon_description_ar: '',  
        addon_validity: '',
        addon_price: '',
        edit_id: null,
        alertMessage: "",
        error_addon_validity: "",
        error_addon_price: "",
    }, () => {
        this.fetchPackages();
        this.setState({
            validation: this.validator.valid()
        })
        this.props.showErrorMessage({
            notificationMessage: "",
            successMessage: true,
            showNotification: false
          });
          this.submitted = false;
    });
  }

  getFilteredPlans = () => {
    const allPlans = this.state.addon_plans;
    const countryId = this.props.countryId;

    return (allPlans && countryId) 
      ?  allPlans.filter(plan => plan.country === countryId)
      : [];
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    let { edit_id } = this.state;

    const { arrBiomarkRange } = this.state;
    const viewMode = this.props.viewMode;
    const isEditable = this.props.isEditable ?? true;

    const filteredPlans = this.getFilteredPlans();
    return (
      <div>
        {!viewMode ? (
          <div>
            <div className="form-row">
              <div className="form-group col">
                <label>Name (English) *</label>
                <input
                  type="text"
                  name="addon_name"
                  className={
                    "form-control " +
                    (validation.addon_name.isInvalid && "has-error")
                  }
                  placeholder="Name in English"
                  onChange={this.handleChange}
                  value={this.state.addon_name}
                ></input>
                <span className="help-block">
                  {validation.addon_name.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Name (Arabic) *</label>
                <input
                  type="text"
                  name="addon_name_ar"
                  className={
                    "form-control input-arabic " +
                    (validation.addon_name_ar.isInvalid && "has-error")
                  }
                  placeholder="Name in Arabic"
                  onChange={this.handleChange}
                  value={this.state.addon_name_ar}
                ></input>
                <span className="help-block">
                  {validation.addon_name_ar.message}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Description (English) *</label>
                <input
                  type="text"
                  name="addon_description"
                  className={
                    "form-control " +
                    (validation.addon_description.isInvalid && "has-error")
                  }
                  placeholder="Description (English)"
                  onChange={this.handleChange}
                  value={this.state.addon_description}
                ></input>
                <span className="help-block">
                  {validation.addon_description.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Description (Arabic) *</label>
                <input
                  type="text"
                  name="addon_description_ar"
                  className={
                    "form-control input-arabic " +
                    (validation.addon_description_ar.isInvalid && "has-error")
                  }
                  placeholder="Description (Arabic)"
                  onChange={this.handleChange}
                  value={this.state.addon_description_ar}
                ></input>
                <span className="help-block">
                  {validation.addon_description_ar.message}
                </span>
              </div>
            </div>
            <div className="form-row">
            <div className="form-group col">
                <label>Validity (in months)*</label>
                <input
                  type="text"
                  onKeyPress={event => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  name="addon_validity"
                  className={
                    "form-control " +
                    (validation.addon_validity.isInvalid && "has-error")
                  }
                  placeholder="Validity (in months)"
                  onChange={this.handleChange}
                  value={this.state.addon_validity}
                ></input>
                <span className="help-block">
                  {validation.addon_validity.message || (this.state.error_addon_validity || "")}
                </span>
              </div>
              <div className="form-group col">
                <label>Price*</label>
                <input
                  type="text"
                  onKeyPress={event => {
                    const number = (event.currentTarget.value || "") + event.key; 
                    if (Number.isNaN(Number(number))) event.preventDefault();
                  }}
                  name="addon_price"
                  className={
                    "form-control input-arabic " +
                    (validation.addon_price.isInvalid && "has-error")
                  }
                  placeholder="Price"
                  onChange={this.handleChange}
                  value={this.state.addon_price}
                ></input>
                <span className="help-block">
                  {validation.addon_price.message || (this.state.error_addon_price || "")}
                </span>
              </div>
            <div className="form-group col button-col">
                {edit_id !== null ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.packageOperation('update')}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => this.packageOperation('add')}
                    disabled={!isEditable}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
            {this.state.alertMessage ? (
              <Alert
                dismissible
                onClose={() => {
                  this.setState({ alertMessage: "" });
                }}
                variant="danger"
              >
                {this.state.alertMessage}
              </Alert>
            ) : null}
    
          </div>
        ) : (
          ""
        )}
        {filteredPlans.length ? (
          <div className="row table-row">
            <table className="table table-hover table-bordered table-striped biomarker-table mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Period</th>
                  <th scope="col">Price</th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredPlans.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{value.addon_name}</td>
                      <td>{value.addon_description}</td>
                      <td>{value.addon_validity}</td>
                      <td>{value.addon_price}</td>
                      <td className="text-center">
                        <ActionBar
                          alignRight
                          className="action-menu valeo-dropdown"
                        >
                          <ActionBar.Toggle variant="" disabled={!isEditable}>
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                          </ActionBar.Toggle>
                          <ActionBar.Menu>
                            <ActionBar.Item
                              disabled={viewMode ? true : false}
                              onClick={() => this.editPackage(value.id)}
                            >
                              <EditIcon className="icon-small"></EditIcon>Edit
                            </ActionBar.Item>
                            <ActionBar.Item
                              disabled={viewMode ? true : false}
                              onClick={() => this.packageOperation('delete', value.id)}
                            >
                              <DeleteIcon className="icon-small"></DeleteIcon>Delete
                            </ActionBar.Item>
                          </ActionBar.Menu>
                        </ActionBar>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showErrorMessage: value => dispatch({ type: "SHOW_NOTIFICATION", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddonForm);
