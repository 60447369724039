import React, { Component } from 'react';
import PropTypes from "prop-types";
import Mapmarker from "assets/img/map-marker.svg"

class Range extends Component {
    constructor(props) {
        super(props);

        const { result, biomarker_list, val, isOptimalRange=false, optimalRangeStart=null, optimalRangeEnd=null} = this.props;
        this.state = {
            result: Number(result),
            width: biomarker_list ? (100 / biomarker_list.length) : "",
            biomarker_list: biomarker_list ? this.transformBiomarkers(biomarker_list, result) : "",
            val,
            // progress_marker_list: this.props.biomarker_list.slice(0, this.props.biomarker_list.length),
            // marker_list: [],
            // resultStyle: {},
            fallingIndex: 0,
            pointerWidth: 0,
            isOptimalRange,
            optimalRangeStart,
            optimalRangeEnd
        };
    }

    componentDidMount() {
        this.handleMarker();
        this.getOptimalWidth()
        this.getOptimalPosition()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.result !== this.props.result) {
            const result = Number(this.props.result);
            this.setState({
                result,
                biomarker_list: this.transformBiomarkers(this.state.biomarker_list, result)
            }, () => this.handleMarker());
        }
    }

    transformBiomarkers = (biomarkers = [], testResult) => {
        const highestRange = Math.max(...biomarkers.map(biomarker => biomarker.range_end));
        return biomarkers.map(biomarker => {
            const [rangeStart, rangeEnd] = this.transformRanges(biomarker, testResult, highestRange);
            const updatedBiomarker = {
                ...biomarker,
                updated_range_start: rangeStart,
                updated_range_end: rangeEnd
            };
            return updatedBiomarker;
        })
    }

    transformRanges = (biomarker = {}, testResult, highestRange) => {
        const rangeStartTransformed = biomarker?.range_start ?? 0;
        const rangeEndTransformed = this.getTransaformedEndRange(biomarker, testResult, highestRange);
        return [rangeStartTransformed, rangeEndTransformed];
    }

    getTransaformedEndRange = (biomarker = {}, testResult, highestRange) => {
        const { range_start, range_end } = biomarker;
        const percentOf = (number, percent) => (number + (number * (percent / 100)));
        if (range_end === null || range_end === undefined) {
            return Math.ceil(Math.max(percentOf(range_start, 20), testResult));
        } else {
            return range_end === highestRange && testResult > range_end
                ? Math.ceil(Math.max(percentOf(range_end, 20), testResult))
                : range_end;
        }
    }

    handleMarker = () => {
        const { biomarker_list, width, val, result  } = this.state;
        const additionalWidth = (val === 1) ? 0 : 50;

        biomarker_list && biomarker_list.forEach((biomarker, index) => {
            if (biomarker.updated_range_start === null) {
                if (biomarker.updated_range_end > result) { // place in center
                    this.setState({ fallingIndex: index, pointerWidth: 50 - additionalWidth });
                }
            } else if (biomarker.updated_range_end === null) {
                if (biomarker.updated_range_start < result) { // place in center
                    this.setState({ fallingIndex: index, pointerWidth: 50 - additionalWidth });
                } else if (biomarker.updated_range_start === result) {
                    this.setState({ fallingIndex: index, pointerWidth: -2 - additionalWidth });
                }
            } else if (biomarker.updated_range_start !== null && biomarker.updated_range_end !== null) {
                if (biomarker.updated_range_end === result) {
                    if (val === 1) {
                        this.setState({ fallingIndex: index, pointerWidth: 95 });
                    }
                    else {
                        this.setState({ fallingIndex: index, pointerWidth: additionalWidth });
                    }
                }
                if (biomarker.updated_range_start < result && biomarker.updated_range_end > result) { // place in position
                    this.setState({
                        fallingIndex: index,
                        pointerWidth: ((((result - biomarker_list[index].updated_range_start) / (biomarker_list[index].updated_range_end - biomarker_list[index].updated_range_start)) * 100) - additionalWidth) > 95 ? ((((result - biomarker_list[index].updated_range_start) / (biomarker_list[index].updated_range_end - biomarker_list[index].updated_range_start)) * 100) - additionalWidth) - 5 : ((((result - biomarker_list[index].updated_range_start) / (biomarker_list[index].updated_range_end - biomarker_list[index].updated_range_start)) * 100) - additionalWidth)
                    });
                }
                else if (biomarker.updated_range_start === result) {
                    if (val === 1) {
                        this.setState({ fallingIndex: index, pointerWidth: additionalWidth - 3 });
                    }
                    else {
                        this.setState({ fallingIndex: index, pointerWidth: - additionalWidth });
                    }
                }
            } else {
                //out of reference ranges
                console.log("Out of reference ranges", biomarker.updated_range_start, biomarker.updated_range_end);
                this.setState({ fallingIndex: 99, pointerWidth: 50 });
            }
        });
    }
    getOptimalPosition = () => {

        const { isOptimalRange = false, optimalRangeEnd, optimalRangeStart, val, biomarker_list: biomarkers = [] } = this.state;

        const additionalWidth = (val === 1) ? 0 : 50;

        // biomarkers && biomarkers.forEach((biomarker, index) => {

        //      if(biomarker.range_start<=optimalRangeStart && biomarker.range_end>=optimalRangeEnd){

        //         fillingIndex=index
        //         console.log("index", index, this.state.width);
        //         widthGreen=(index===0?this.state.width:this.state.width*index/(biomarker.range_end-biomarker.range_start) )*optimalRangeStart;
        //      }
        // });
        // return widthGreen
        let finalStart = 0;
        let finalEnd = 0;
        let widthFinal = 0;
        let optimalIndex = 0;
        biomarkers.forEach((item, index) => {
            if (item.range_start <= optimalRangeStart) {
                finalStart = item.range_start;

            }
            if (item.range_end >= optimalRangeEnd) {
                finalEnd = item.range_end
            }
            if (item.range_start === null) {
                if (item.range_end > optimalRangeStart) { // place in center

                    optimalIndex = index;
                    widthFinal = 50 - additionalWidth;
                }
            }
            else if (item.range_end === null) {
                if (item.range_start < optimalRangeStart) { // place in center
                    optimalIndex = index;
                    widthFinal = 50 - additionalWidth;
                } else if (item.range_start === optimalRangeStart) {
                    optimalIndex = index;
                    widthFinal = -2 - additionalWidth;
                }

            }
            else if (item.range_start !== null && item.range_end !== null) {
                if (item.range_end === optimalRangeStart) {
                    if (val === 1) {
                        optimalIndex = index;
                        widthFinal = 95;
                    }
                    else {
                        optimalIndex = index;
                        widthFinal = additionalWidth;
                    }
                }
                if (item.range_start < optimalRangeStart && item.range_end > optimalRangeStart) { // place in position

                    optimalIndex = index;
                    widthFinal = ((((optimalRangeStart - biomarkers[index].range_start) / (biomarkers[index].range_end - biomarkers[index].range_start)) * 100) - additionalWidth) > 95 ? ((((optimalRangeStart - biomarkers[index].range_start) / (biomarkers[index].range_end - biomarkers[index].range_start)) * 100) - additionalWidth) - 5 : ((((optimalRangeStart - biomarkers[index].range_start) / (biomarkers[index].range_end - biomarkers[index].range_start)) * 100) - additionalWidth)

                }
                else if (item.range_start === optimalRangeStart) {
                    if (val === 1) {
                        optimalIndex = index;
                        widthFinal = additionalWidth - 3
                    }
                    else {
                        optimalIndex = index;
                        widthFinal = - additionalWidth;
                    }
                }
            } else {
                //out of reference ranges
                console.log("Out of reference ranges", item.range_start, item.range_end);
                optimalIndex = 99;
                widthFinal = 50;
            }
        })

        // let marginLeft=widthFinal/(finalEnd-finalStart)*optimalRangeStart;
        // console.log("marginLeft:", marginLeft);
        // let marginLeft=optimalIndex===0?widthFinal:(this.state.width*optimalIndex)+widthFinal
        //let optimalWidth=(this.state.width/ (finalEnd-finalStart)) *(optimalRangeEnd-optimalRangeStart)
        //  return marginLeft

        if (widthFinal > this.state.width) {
            this.setState({ optimalRangePosition: widthFinal })
        }
        else if(widthFinal<0){
            if(optimalIndex>0){
                this.setState({ optimalRangePosition: widthFinal + this.state.width*optimalIndex})  
            }
            
 
        }
        else {
            if(optimalIndex===0){
                this.setState({ optimalRangePosition: widthFinal + this.state.width })
            }
            else{
                this.setState({ optimalRangePosition: widthFinal + this.state.width*optimalIndex })
            }

        }

    }

    getOptimalWidth = () => {

        const { isOptimalRange = false, optimalRangeEnd, optimalRangeStart, val, biomarker_list: biomarkers = [] } = this.state;

        const additionalWidth = (val === 1) ? 0 : 50;
        //   let optimalStart=0;
        //   let optimalEnd=0;
        //  let fillingIndex=0;

        // biomarkers && biomarkers.forEach((biomarker, index) => {

        //      if(biomarker.range_start<=optimalRangeStart && biomarker.range_end>=optimalRangeEnd){

        //         fillingIndex=index
        //      }

        // });

        //  const {range_start, range_end} = biomarkers[fillingIndex];

        //  let widthState=0;
        //  if(fillingIndex===0){
        //    widthState= this.state.width
        //  }
        //  else{
        //    widthState= (this.state.width )* fillingIndex
        //  }
        //  const widthGreen=(this.state.width/(range_end-range_start) )*optimalRangeStart;
        //   const widthfinal=(widthState/ (range_end-range_start)) *(optimalRangeEnd-optimalRangeStart)

        // return widthfinal

        let finalStart = 0;
        let finalEnd = 0;
        let widthFinal = 0;
        let optimalIndex = 0;
        biomarkers.forEach((item, index) => {
            if (item.range_start <= optimalRangeStart) {
                finalStart = item.range_start;

            }
            if (item.range_end >= optimalRangeEnd) {
                finalEnd = item.range_end
            }
        })

        let marginLeft = this.state.width / (finalEnd - finalStart) * optimalRangeStart;

        let optimalWidth = (this.state.width) / (optimalRangeEnd - optimalRangeStart)
      
        if(optimalWidth>=this.state.width){
            optimalWidth=20
            this.setState({ optimalRangeWidth:optimalWidth})
        }
        else if(optimalWidth<5){
            this.setState({ optimalRangeWidth:10})
        }
        else{
            this.setState({ optimalRangeWidth: optimalWidth })
        }
       
    }
    renderBiomarkers = (biomarkers) =>{
        const {isOptimalRange=false,optimalRangeEnd,optimalRangeStart}=this.props;
        const {optimalRangeWidth, optimalRangePosition}= this.state;
     return( 
     <>
      <div style={{ width: this.props.width ? this.props.width : '100%', marginTop: '40px', position:"relative"}}>
        {biomarkers.map((biomarker, index) =>{
            return (
            <div>
                {this.renderBiomarker(index, biomarkers.length, biomarker)}
            </div>)
        }
        
        )}
         {isOptimalRange && <div className="" style={{position:"absolute", height:"15px", width:optimalRangeWidth + "%",borderRadius:"99px",boxShadow: "0px 4px 4px 0px #007AFF3B", left:optimalRangePosition + '%' ,background:"#007AFF",border: "2px solid #FFFFFF", top:"0px"}}></div>}
    </div>
     </>)
        
   
    
    } 

    renderBiomarker = (index, lastIndex, biomarker) => {
        const color = biomarker.status_color_code;
        const { biomarker_list, width, pointerWidth } = this.state;
        const { updated_range_start, updated_range_end } = biomarker;

        const style = { height: 15, backgroundColor: color};
        if (index === 0) {
            style.borderTopLeftRadius = "99px";
            style.borderBottomLeftRadius = "99px";
        }
        if (index === lastIndex - 1) {
            style.borderTopRightRadius = "99px";
            style.borderBottomRightRadius = "99px";   
        }

        return (
            <div key={index}
                style={{ width: width + '%', marginTop: index === biomarker_list.length - 1 ? 0 : 0, float: 'left'}}>
                <div style={style} >
                    {index === this.state.fallingIndex &&
                        <img src={Mapmarker} 
                            alt="map-marker" 
                            style={{ fontSize: 12, position: 'relative', left: (pointerWidth - 4) + '%', top: -25, color: 'black' }}
                            />
                    }
                </div>

                {/* Reference marks */}
                {index === (biomarker_list.length - 1) ?
                    <>
                        <p style={{ marginTop: 10, color: "#000", float: 'left' }}>{updated_range_start}</p>
                        {/* <p style={{ marginTop: 10, left: '-10%', color: "#000", float: 'right' }}>{range_end ? range_end : '∞'}</p> */}
                        <p style={{ marginTop: 10, left: '-10%', color: "#000", float: 'right' }}>{updated_range_end}</p>
                    </>
                    :
                    <p style={{ marginTop: 10, left: '0%', color: "#000", float: 'left' }}>{updated_range_start}</p>
                }
            </div>
        )
    }
    
   
    render() {
        
       
        return this.renderBiomarkers(this.state.biomarker_list);
        
        //     const {
        //         biomarker_list,
        //         progress_marker_list
        //     } = this.state;
        //     let statusMaxStyle = {
        //         left: (this.state.progress2 - 3.5) + '%'
        //     }

        //     return (
        //         <>
        //             <div style={{ width: this.props.width ? this.props.width : '100%', marginTop: '40px' }}>
        //                 {biomarker_list && biomarker_list.map((item, index) => {
        //                     let color = item.status_color_code;
        //                     return (
        //                         <div key={index} style={{ width: this.state.width + '%', marginTop: index === biomarker_list.length - 1 ? 0 : 0, float: 'left' }}>
        //                             <div style={{ height: 10, backgroundColor: color }}>
        //                                 {index === this.state.fallingIndex &&
        //                                     <img style={{ fontSize: 12, position: 'relative', left: this.state.pointerWidth + '%', top: -20, color: 'black' }} src={Mapmarker} alt="map-marker"></img>
        //                                 }
        //                             </div>

        //                             {/* Reference marks */}
        //                             {index === (biomarker_list.length - 1) ? <div>
        //                                 <p style={{ marginTop: 10, color: "#000", float: 'left' }}>{item.range_start}</p>
        //                                 <p style={{ marginTop: 10, left: '-10%', color: "#000", float: 'right' }}>{item.range_end ? item.range_end : '∞'}</p>
        //                             </div>
        //                                 :
        //                                 <p style={{ marginTop: 10, left: '0%', color: "#000", float: 'left' }}>{item.range_start !== null ? item.range_start : '0'}</p>}
        //                         </div>

        //                     )
        //                 })
        //                 }
        //             </div>

        //         </>
        //     )
    }
}

Range.propTypes = {
    biomarker_list: PropTypes.arrayOf(PropTypes.object).isRequired,
    result: PropTypes.number.isRequired,
    val: PropTypes.number.isRequired,
};

export default Range;