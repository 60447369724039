import axios from "axios";
import { getUserId, getUserEmail } from "services/UserService";
import history from "./history";
import {
  clearToken,
  getAccessToken,
  refreshAccessToken,
} from "services/TokenService";
const userId= getUserId();

const userEmail = getUserEmail();
const instance = axios.create({
  // baseURL:
  //   process.env.NODE_ENV === "development"
  //     ? process.env.REACT_APP_PHOENIX_QA_URL
  //     : process.env.REACT_APP_PHOENIX_PRODUCTION_URL,
  baseURL: process.env.REACT_APP_API_PHOENIX_URL,
  responseType: "json",
  timeout: 300000,
  headers: {
    'adminloginuserid': userId,
    'adminloginuseremail': userEmail,
  },
});
instance.interceptors.request.use(
  function (config) {
    const token = getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        //access token expired
        //call refresh api
        //set access token
        console.log("access token expired");
        refreshAccessToken();
      } else if (error.response.status === 403) {
        //refresh token expired
        clearToken();
        history.push("/login");
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
