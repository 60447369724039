import React, { Component } from "react";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Data } from "victory";


class FitnessQuestionsAnswers extends Component{
    constructor(props) {
        super(props);
    const {questions=[]}= this.props;
    this.state={
        questions,
    }
        this.columns=[
            {
              name: "Questions",
              selector: "question",
              sortable: true,
            },
            {
                name: "Answers",
              selector: "answer",
              sortable: true, 
              cell:(question)=>this.renderAnswer(question?.answers)
            }
        ]
    }

    renderAnswer=(answers=[])=>{
        
        return(
            <ul className="my-auto" style={{ width: "100%" }}>
            {answers.map((result) => (
              <li>{result}</li>
            ))}
          </ul>
        );
        
    }
    render() {
        const {questions=[]}= this.state;
        return (
            <div className="container mt-4 px-0">
               <div className="row">
                 <div className="form-group col">
                    <Card>
                    <DataTable
                     columns={this.columns}
                     data={questions || []}
                     responsive
                     highlightOnHover
                     sortIcon={<ArrowDownward></ArrowDownward>}
                    />
                    </Card>
                 
                 </div>
               </div>
            </div>
        )
           
        
    }
}

export default FitnessQuestionsAnswers;