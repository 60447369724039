import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectPackagesCMS.propTypes = {
  countryId: PropTypes.number.isRequired,
  selectedPackageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onPackageSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectPackagesCMS.defaultProps = {
  selectedPackageIds: [],
  onPackageSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectPackagesCMS(props) {
  const {
    countryId,
    selectedPackageIds,
    onPackageSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const url = `cms-package-id-name`;
    const fetchPackages = async () => {
      try {
        const { data: packages = [] } = await PhoenixAPI.get(url);
        const packagesForCountry=packages && packages.filter((item)=> item.countryId === countryId)
        console.log("Package countryId", packagesForCountry, countryId)
        setPackages(packagesForCountry);
      } catch (error) {
        console.log("Error on fetching supplements", error);
      }
    };
    fetchPackages();
  }, []);

  const findPackage = (packageId) => {
    return packages.find((packageCms) => packageCms.id === packageId);
  };

  const [activeSupplements, inactiveSupplements] = _.partition(
    packages,
    (it) => it.isActive == true
  );
  activeSupplements.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  inactiveSupplements.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  const options = showOnlyActive
    ? activeSupplements.map(createOption)
    : [
        {
          label: "Active",
          options: activeSupplements.map(createOption),
        },
        {
          label: "Inactive",
          options: inactiveSupplements.map(createOption),
        },
      ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedPackages = selectedPackageIds
    .map(findPackage)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedPackageIds && selectedPackageIds.join(",")
      }`}
      value={selectedPackages}
      onChange={onPackageSelect}
      options={options}
      styles={selectStyles}
      // placeholder="Select Supplements"
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (packageCms) => {
  if (!packageCms) return;
  return {
    key: packageCms.id,
    value: packageCms.id,
    label: packageCms.name,
  };
};

export default SelectPackagesCMS;
