import React, { Component } from "react";
import Search from "components/Search/Search";
import CountryDropdown from "components/Common/CountryDropdown";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL_FOOD_CATEGORIES = "admin/get-all-food-categories";
const TWO_MEGA_BYTE = 2_097_152;
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class FoodCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      categories: [],
      category: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      isCategoryViewModalVisible: false,
      rankOptions: [],
      errors: {},
      editables: {},
      loadError: "",
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Food Category Name",
        selector: "foodCategory",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (category) => <Status isActive={category.isActive} />,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        // width: "100px",
        sortable: true,
        center: true,
      },

      {
        name: "Edit",
        center: true,
        minWidth: "170px",
        cell: (category) => this.renderViewButton(category.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.FOOD_CATEGORIES;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCategories = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_FOOD_CATEGORIES);
      const categories = (response && response.data) || [];
      this.setState({ categories });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching categories: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      category: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCategory(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : null;

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const category = { ...this.state.category, image };
      this.setState({ category });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const category = {
        ...this.state.category,
        image_src: [reader.result],
      };
      this.setState({ category });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleAddNewCategory = () => {
    this.setState({ isNewCategory: true }, () => this.showCategoryViewModal());
  };
  handleViewCategory = (categoryId) => {
    const { selectedCountry } = this.state;
    const categories = this.state.categories ?? [];
    const category = categories.find((it) => it.id === categoryId);
    if (!category) {
      console.log(`Category with ID ${categoryId} is not found!`);
      return;
    }
    category.image_src = category.image;
    category.country = selectedCountry?.id;
    this.setState({ category, isNewCategory: false }, () =>
      this.showCategoryViewModal()
    );
  };

  showCategoryViewModal = () => {
    const { selectedCountry, isNewCategory } = this.state;
    const rankOptions = this.createRankOptions() || [];
    if (isNewCategory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const category = {
        country: selectedCountry?.id,

        isActive: true,
        rank,
      };
      this.setState({
        category,
        rankOptions,
        isCategoryViewModalVisible: true,
      });
    } else {
      this.setState({
        rankOptions,
        isCategoryViewModalVisible: true,
      });
    }
  };

  hideCategoryViewModal = () => {
    this.setState({ isCategoryViewModalVisible: false });
    this.resetForm();
  };

  handleSaveCategory = async () => {
    const { selectedCountry, category } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = category.id !== null && category.id !== undefined;
      const response = isValidId
        ? await this.updateCategory(category)
        : await this.saveCategory(category);

      this.hideCategoryViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Category Updated Successfully."
          : "Category Item Created Successfully."
      );
      this.fetchCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  updateCategory = async (category) => {
    try {
      const payload = { ...category };
      delete payload["image_src"];
      delete payload["id"];
      if (typeof category.image !== "string") delete payload["image"];
      const formData = new FormData();
      formData.append("requestString", JSON.stringify(payload));
      if (typeof category.image !== "string")
        formData.append("image", category.image);
      console.log("BASE_URL_FOOD_CATEGORIES", BASE_URL_FOOD_CATEGORIES);

      const apiOptions = {
        method: "patch",
        url: `admin/food-category/${category.id}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on updating category", error);
      throw error;
    }
  };

  saveCategory = async (category) => {
    try {
      const payload = { ...category };
      delete payload["image"];
      delete payload["image_src"];
      const formData = new FormData();
      formData.append("requestString", JSON.stringify(payload));
      formData.append("image", category.image);

      const apiOptions = {
        method: "post",
        url: "admin/add-food-category",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on creating other-item", error);
      throw error;
    }
  };

  validate = () => {
    const { category = {} } = this.state;
    const errors = {};
    const {foodCategory,foodCategoryAr, image, image_src,isActive}= category;
    if (isActive === null) errors.status = "Status is a required field!";

    if (!foodCategory || foodCategory.trim() === "")
    errors.foodCategory = "Food Category (English) cannot be blank";
  else if (foodCategory.length > 100)
    errors.foodCategory = "Food Category (English) is limited to a 100 characters";

  if (!foodCategoryAr || foodCategoryAr.trim() === "")
    errors.foodCategoryAr = "Food Category (Arabic) cannot be blank";
  else if (foodCategoryAr.length > 100)
    errors.foodCategoryAr = "Food Category (Arabic) is limited to a 100 characters";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  getCategoriesForCurrentCountry = () => {
    const { categories, selectedCountry } = this.state;
    return categories && selectedCountry
      ? categories.filter((category) => category.country === selectedCountry.id)
      : [];
  };

  createRankOptions = () => {
    const { isNewCategory } = this.state;
    const categories = this.getCategoriesForCurrentCountry();
    const ranks = categories.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    
    return rankOptions;
  };

  getFilteredCategories = () => {
    const categories = this.getCategoriesForCurrentCountry();
    return this.getSearchedCategories(categories);
  };

  getCategoriesForCurrentCountry = () => {
    const { categories, selectedCountry } = this.state;
    return categories && selectedCountry
      ? categories.filter((category) => category.country === selectedCountry.id)
      : [];
  };

  getSearchedCategories = (categories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return categories;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return categories.filter((category) => {
      const { id, foodCategory } = category;
      return (
        (foodCategory && foodCategory.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      category,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      errors,
      editables,
      loadError,
      isEditable,
      isNewCategory,
    } = this.state;
    let { categories } = this.state;
    // categories = categories.filter(
    //   (it) => it?.country?.id === selectedCountry?.id
    // );
    const filteredCategories = this.getFilteredCategories(categories);

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="foodCategory">Food Category (English) *</label>
            <div className="input-group user-input-container">
              <input
                value={category.foodCategory || ""}
                onChange={(event) => {
                  this.setState({
                    category: {
                      ...category,
                      foodCategory: event.target.value,
                    },
                  });
                }}
                id="foodCategory"
                name="foodCategory"
                type="text"
                placeholder="Enter Food Category in English"
                className={
                  "form-control py-2" +
                  (errors.foodCategory ? " has-error" : "")
                }
                disabled={!isNewCategory && !editables.foodCategory}
              />
              {this.renderPencilIcon("foodCategory")}
            </div>
            <span className="help-block">{errors.foodCategory}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="foodCategoryAr">Food Category (Arabic) *</label>
            <div className="input-group user-input-container">
              <input
                value={category?.foodCategoryAr || ""}
                onChange={(event) => {
                  this.setState({
                    category: {
                      ...category,
                      foodCategoryAr: event.target.value,
                    },
                  });
                }}
                id="foodCategoryAr"
                name="foodCategoryAr"
                type="text"
                placeholder="Enter Food Category in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.foodCategoryAr ? " has-error" : "")
                }
                disabled={!isNewCategory && !editables.foodCategoryAr}
              />
              {this.renderPencilIcon("foodCategoryAr")}
            </div>
            <span className="help-block">{errors.foodCategoryAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewCategory && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {category?.image_src && (
              <>
                <label htmlFor="selected_package_image">Image Preview</label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={category.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={selectedCountry?.country_name || ""}
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status *</label>
            <div className="input-group">
              <Dropdown
                value={category.isActive}
                onChange={(event, value) => {
                  this.setState({
                    category: {
                      ...category,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={category?.rank}
                onChange={(event, value) => {
                  this.setState({
                    category: { ...category, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-pacakage">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewCategory}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredCategories || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isCategoryViewModalVisible}
          onHide={this.hideCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title"></div>

              <button
                type="submit"
                onClick={this.handleSaveCategory}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_detailsform">
              <Tab eventKey="key_detailsform_" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FoodCategories);
