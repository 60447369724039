import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { filterList } from "utils/List";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { formatDateTime } from "utils/commons";
import Status from "components/Common/Status";
import { Tab, Tabs } from "react-bootstrap";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL = "lab-user";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class Lab extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required."
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Email is required."
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Email not Valid."
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "password",
        method: "isLength",
        min: 8,
        validWhen: true,
        message: "At least 8 characters are required."
      },
      {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "confirm_password",
        method: "equals",
        validWhen: true,
        equalsTo: "password",
        message: "Passwords do not match."
      },
      {
        field: "firstname",
        method: "isEmpty",
        validWhen: false,
        message: "First Name is required."
      },
      {
        field: "lastname",
        method: "isEmpty",
        validWhen: false,
        message: "Last Name is required."
      },
    ]);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      validation: this.validator.valid(),
      list: [],
      isLoaded: false,
      loadError: "",
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      firstname:"",
      lastname:"",
      emailErrorMessage:"",
      emailErrorNotify:false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "100px",
      },
      {
        name: "Name",
        selector: "username",
        sortField: 'username',
        sortable: true
      },
      {
        name: "Email",
        selector: "email",
        sortField: 'email',
        sortable: true
      },
      {
        name: "Status",
        center: true,
        sortable: true,
        sortField: 'is_active',
        selector: "is_active",
        cell: row => <Status isActive={row.is_active}  />,
        // format: row => (
        //   <>
        //     {row.is_active ? (
        //       <div className="badge badge-success">
        //         <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
        //         Active
        //       </div>
        //     ) : (
        //       <div className="badge badge-danger">
        //         <BlockIcon></BlockIcon>Deactivated
        //       </div>
        //     )}
        //   </>
        // )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <BlockIcon></BlockIcon>
                  Deactivate
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                  Activate
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      },
      {
        name: "Joining Date",
        selector: "date_joined",
        sortable: true,
        sortField: 'date_joined',
        format: row => row.date_joined ? formatDateTime(row.date_joined) : '-',
      },
      {
        name: "Last Login",
        selector: "last_login",
        sortField: 'last_login',
        sortable: true,
        format: row => row.last_login ? formatDateTime(row.last_login) : "-",
      }
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.createUser = this.createUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    
    // this.loadUsers = this.loadUsers.bind(this);
    // this.customSort = this.customSort.bind(this);
    // this.setShowItems = this.setShowItems.bind(this);
    // this.onUserPageChange = this.onUserPageChange.bind(this);
  }

  componentDidMount() {
    // this.loadUsers();
    this.fetchAclUser();
    this.fetchLabUsers();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.LAB_USERS
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  // onUserPageChange() {

  //   if (this.state.nextURL) {
  //     let url = this.state.nextURL;
  //     let nextURL = '';
  //     let orderResonse = [];

  //     this.props.toggleLoading({
  //       isLoading: true,
  //     });
  //     API.get(url)
  //       .then(response => {
  //         nextURL = response.data && response.data.next ? response.data.next : '';
  //         orderResonse = response.data && response.data.results ? response.data.results : [];
  //         const activeList = filterList(orderResonse, "active");
  //         const deactivatedList = filterList(orderResonse, "archived");

  //         let allClient = [...this.state.list, ...orderResonse];
  //         let activeallList = [...this.state.activeList, ...activeList];
  //         let deactiveallList = [...this.state.deactiveList, ...deactivatedList]
  //         let totalPage = response.data && response.data.count ? response.data.count : 0;
  //         this.setState({ list: allClient, nextURL: nextURL, activeList: activeallList, deactivatedList: deactiveallList, isLoaded: true, totalPage: totalPage });
  //         this.props.toggleLoading({
  //           isLoading: false
  //         });
  //       })
  //       .catch(error => {
  //         this.setState({
  //           loadError: "Some error has occured. Please try again"
  //         });
  //         this.props.showNotificationMessage({
  //           notificationMessage: "Some error has occured. Please try again.",
  //           successMessage: false,
  //           showNotification: true
  //         });
  //       });

  //   }
  // }

  createUser() {
    const validation = this.validator.validate(this.state);

    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      this.showLoading();
      API.post("lab-user/", {
        email: this.state.email,
        password: this.state.password,
        username: this.state.name,
        first_name:this.state.firstname,
        last_name:this.state.lastname,
        is_staff: 1
      })
        .then(response => {
          // this.loadUsers();
          const data= response && response.data;
          const {message, status}=data;
          if(status===400){
            this.hideLoading()
            this.setState({emailErrorMessage:message,emailErrorNotify:true})
            this.showErrorNotification(message)
          }
          else{
          this.hideModal();
          this.clearForm();
          this.fetchLabUsers();
          this.setState({emailErrorMessage:"",emailErrorNotify:false})
          this.showSuccessNotification("User created successfully.");
          }
          
        })
        .catch(error => {
          console.log("Error on creating lab user", error);
          let message = "";
          if (error.response.status === 400) {
            message = "Account already exists.";
          } else message = "Some error has occured. Please try again.";

          this.showErrorNotification(message);
        })
        .finally(() => this.hideLoading());
    } else {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({emailErrorMessage:"",emailErrorNotify:false})
    }
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      firstname:"",
      lastname:"",
      validation: this.validator.valid(),
      emailErrorMessage:"",
      emailErrorNotify:false
    });
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Deactivate User";
      confirmMessage = "Are you sure you want to deactivate this user?";
      successMessage = "User successfully deactivated.";
      confirmType = "danger";
    } else {
      confirmTitle = "Activate User";
      confirmMessage = "Are you sure you want to activate this user?";
      successMessage = "User successfully activated.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("lab-user/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });

        this.fetchLabUsers();
        // this.loadUsers();
      })
      .catch(error => {
        console.log("Error on updating lab user", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  // loadUsers() {
  //   const { searchApplied, searchTerm } = this.state;
  //   let orderResonse = [];
  //   let nextURL;
  //   this.props.toggleLoading({
  //     isLoading: true
  //   });

  //   let url = "lab-user/";
  //   if (this.state.sortField) {
  //     let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
  //     url = url + "?ordering=" + fieldOrder
  //   }
  //   if (this.state.searchValue && this.state.sortField) {
  //     url = url + "&search=" + this.state.searchValue
  //   }
  //   if (this.state.searchValue && !this.state.sortField) {
  //     url = url + "?search=" + this.state.searchValue
  //   }
  //   API.get(url)
  //     .then(response => {
  //       nextURL = response.data && response.data.next ? response.data.next : '';
  //       orderResonse = response.data && response.data.results ? response.data.results : [];

  //       const activeList = filterList(orderResonse, "active");
  //       const deactivatedList = filterList(orderResonse, "archived");

  //       let totalPage = response.data && response.data.count ? response.data.count : 0;
  //       if (this.state.searchApplied) {
  //         this.setState({
  //           results: orderResonse,
  //           resultCount: totalPage,
  //           nextURL: nextURL,
  //           totalPage: totalPage,
  //           activeList,
  //           deactivatedList,
  //           isLoaded: true
  //         });
  //       } else {
  //         this.setState({
  //           list: orderResonse,
  //           nextURL: nextURL,
  //           totalPage: totalPage,
  //           activeList,
  //           deactivatedList,
  //           isLoaded: true
  //         });
  //       }

  //       this.props.toggleLoading({
  //         isLoading: false
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again"
  //       });

  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //         isLoading: false
  //       });
  //     });
  // }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Deactivated") list = this.state.deactivatedList;
    else list = this.state.list;

    if (!list) return;

    let newList = [];

    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      // this.loadUsers();
      this.fetchLabUsers();
    });
  }


  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
      searchValue: '',
    }, () => {
      // this.loadUsers();
      this.fetchLabUsers();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  // Not needed now.
  // setShowItems(mode) {
  //   let showItems;
  //   const { searchApplied, searchTerm } = this.state;

  //   if (mode === "active") showItems = "Active";
  //   else if (mode === "deactivated") showItems = "Deactivated";
  //   else showItems = "All";

  //   this.setState({ showItems: showItems }, () => {
  //     if (searchApplied) this.handleSearch(searchTerm);
  //   });
  // }

  // customSort(column, sortDirection) {
  //   let sortField = column.sortField;
  //   this.setState({ sortField: sortField, sortDirection: sortDirection }, () => {
  //     this.loadUsers();
  //   })
  // }

  handlePageChange = (page) => this.fetchLabUsers(page);

  handleRowsPerPageChange = (newRowsPerPage, page) =>
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchLabUsers(page)
    );

  handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    this.setState(
      { sortField: column.sortField || column.selector, sortDirection: sortDirection },
      () => this.fetchLabUsers()
    );
  };

  fetchLabUsers = (page = 1) => {
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    let url = `${BASE_URL}/?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;
    if (searchApplied) {
      url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
    }

    this.showLoading();
    API.get(url)
      .then((response) => {
        console.log("Response: ", response);
        if (response.data) {
          const { count: totalRows = 0, results: users = [] } = response.data;
          this.setState({ totalRows, list: users, isLoaded: true });
        }
      })
      .catch((error) => {
        console.log("Error on fetching lab users", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.showErrorNotification("Some error has occured. Please try again.");
      })
      .finally(() => this.hideLoading());
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let finalList = [];

    const users = this.state.list;
    const {
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      deactivatedList,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      // if (searchApplied) finalList = results;
      // else {
      //   if (showItems === "Active") finalList = activeList;
      //   else if (showItems === "Deactivated") finalList = deactivatedList;
      //   else finalList = users;
      // }
      finalList = users;
    }

    const form = (
      <>
        <div className="form-group mt-4 mb-4">
          <label>Name *</label>
          <input
            type="text"
            name="name"
            className={
              "form-control " + (validation.name.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.name}
          ></input>
          <span className="help-block">{validation.name.message}</span>
        </div>
        <div className="form-group mt-4 mb-4">
          <label>First Name *</label>
          <input
            type="text"
            name="firstname"
            className={
              "form-control " + (validation.firstname.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.firstname}
          ></input>
          <span className="help-block">{validation.firstname.message}</span>
        </div>
        <div className="form-group mt-4 mb-4">
          <label>Last Name *</label>
          <input
            type="text"
            name="lastname"
            className={
              "form-control " + (validation.lastname.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.lastname}
          ></input>
          <span className="help-block">{validation.lastname.message}</span>
        </div>
        <div className="form-group mb-4">
          <label>Email *</label>
          <input
            type="text"
            name="email"
            className={
              "form-control " + (validation.email.isInvalid && "has-error")
            }
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
          ></input>
          {this.state.emailErrorNotify ? <span className="help-block">{this.state.emailErrorMessage}</span> : <span className="help-block">{validation.email.message}</span>}
        </div>
        <div className="form-group mb-4">
          <label>Password *</label>
          <input
            type="password"
            name="password"
            className={
              "form-control " + (validation.password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="Password"
            onChange={this.handleChange}
            value={this.state.password}
          ></input>
          <span className="help-block">{validation.password.message}</span>
        </div>
        <div className="form-group">
          <label>Confirm Password *</label>
          <input
            type="password"
            name="confirm_password"
            className={
              "form-control " +
              (validation.confirm_password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="Confirm password"
            onChange={this.handleChange}
            value={this.state.confirm_password}
          ></input>
          <span className="help-block">
            {validation.confirm_password.message}
          </span>
        </div>
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="lab">
        <div className="page-header">
          {/* <h5>Lab Users</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                // searchExpanded={this.state.searchExpanded}
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleSearchEnter}
                className="btn btn-search"
                // className="btn button-blue btn-md new-user"
              >
                Search
              </button>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Deactivated" ? "active" : ""}
                    onClick={() => this.setShowItems("deactivated")}
                  >
                    {showItems === "Deactivated" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Deactivated
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            <div className="action-item">
              <button
                onClick={this.showModal}
                disabled={!isEditable}
                className="btn btn-md new-user"
                style={{ backgroundColor: "#8FD14F" }}
                // className="btn btn-sm button text-button "
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            responsive
            highlightOnHover
            columns={this.columns}
            data={finalList}  
            sortServer
            onSort={this.handleSort}
            // defaultSortField="username"
            // onSort={this.customSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={this.state.totalRows || 0}
            paginationPerPage={this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            // paginationTotalRows={this.state.totalPage}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // onChangePage={this.onUserPageChange}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {`Found ${this.state.totalRows} results`}
                  {/* {"Found " + resultCount + " results in '" + showItems + "'"} */}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal} size="lg" className="user-data-modal">
          <Modal.Header closeButton>
            {/* <Modal.Title>Add Lab User</Modal.Title> */}
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.createUser} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_lab_users">
              <Tab eventKey="key_details_form" title="Details">
                {form}
              </Tab>
            </Tabs>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              onClick={this.createUser}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer> */}
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Activate
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lab);
