import React, { Component, Alert } from "react";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import API from "utils/API";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import FormValidator from "utils/FormValidator";
import { filterList } from "utils/List";
import { isEmpty } from "utils/FormValidator";
import { connect } from "react-redux";
import Search from "components/Search/Search";
import Status from "../../components/Common/Status";
import { Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    text: "Active",
    value: true,
    key: "active",
  },
  {
    text: "Inactive",
    value: false,
    key: "inactive",
  },
];


export class ManageTestCategories extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "name_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
    ]);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      showAlert: false,
      showConfirm: false,
      showModal: false,
      validation: this.validator.valid(),
      name: "",
      name_ar: "",
      is_active: false,
      editId: null,
      formHeading: "Create Test Category",
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isLoaded: false,
      loadError: "",
      categoryFilter: "All categories",
      errors: {},
      editables: {},
      internal_category:"None",
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "100px",
      },
      {
        name: "Name (English)",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      {
        name: "Name (Arabic)",
        sortable: true,
        selector: "name_ar",
        right: true,
      },
      {
        name: "Status",
        selector: "is_active",
        // maxWidth: "150px",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.is_active} />
      },
      {
        name: "View",
        center: true,
        cell: (row) => (
          <button
            onClick={() => this.manageCategory(row)}
            className="btn px-5"
            style={{ backgroundColor: "#CEE741" }}
          >
            View
          </button>
        ),
      },
      // {
      //   name: "Actions",
      //   maxWidth: "100px",
      //   center: true,
      //   allowOverflow: true,
      //   cell: (row) => (
      //     <ActionBar alignRight className="action-menu valeo-dropdown">
      //       <ActionBar.Toggle variant="">
      //         <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      //       </ActionBar.Toggle>
      //       <ActionBar.Menu>
      //         <ActionBar.Item onClick={() => this.manageCategory(row)}>
      //           {/* <AssessmentOutlinedIcon></AssessmentOutlinedIcon> */}
      //           Edit
      //         </ActionBar.Item>
      //         {row.is_active ? (
      //           <ActionBar.Item onClick={() => this.showConfirm(row)}>
      //             {/* <AssessmentOutlinedIcon></AssessmentOutlinedIcon> */}
      //             Archive
      //           </ActionBar.Item>
      //         ) : (
      //           <ActionBar.Item onClick={() => this.showConfirm(row)}>
      //             {/* <AssessmentOutlinedIcon></AssessmentOutlinedIcon> */}
      //             Restore
      //           </ActionBar.Item>
      //         )}
      //       </ActionBar.Menu>
      //     </ActionBar>
      //   ),
      // },
    ];

    this.submitted = false;
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.BIOMARKER_CATEGORIES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  manageCategory(category) {
    this.setState({
      showModal: true,
      editId: category.id,
      name: category.name,
      name_ar: category.name_ar,
      is_active: category.is_active,
      imgSrc: category.image,
      formHeading: "Edit Test Category",
      internal_category:category.internal_category||"None",
    });
  }

  resetForm() {
    this.submitted = false;
    this.setState({
      editId: null,
      name: "",
      name_ar: "",
      is_active: false,
      validation: this.validator.valid(),
      formHeading: "Create Test Category",
      errors: {},
      editables: {},
      image: null,
      imgSrc: null,
    });
  }

  fetchCategories() {
    this.showLoading();
    API.get("test-category/")
      .then((response3) => {
        let categoryList = response3.data;
        const activeList = filterList(categoryList, "active");
        const archivedList = filterList(categoryList, "archived");
        this.setState({
          categoryList,
          activeList,
          archivedList,
          isLoaded: true,
        });
      })
      .catch(error => {
        console.log("Error on fetching biomarker categories: ", error);
      })
      .finally(() => this.hideLoading());
  }

  // updateCategory() {
  //   const validation = this.validator.validate(this.state);
  //   this.submitted = true;
  //   this.setState({ validation });
  //   if (validation.isValid) {
  //     const { name, name_ar, is_active, editId } = this.state;

  //     this.hideModal();
  //     this.showLoading();

  //     API.put("test-category/" + editId + "/", { name, name_ar, is_active })
  //       .then((response) => {
  //         this.fetchCategories();
  //         this.resetForm();
  //         this.showSuccessNotification("Test category successfully updated.");
  //       })
  //       .catch((error) => {
  //         console.log("Error on updating biomarker category: ", error);
  //         this.showErrorNotification("Some error has occured. Please try again.");
  //       })
  //       .finally(() => this.hideLoading());
  //   }
  // }

  // addCategory() {
  //   const validation = this.validator.validate(this.state);
  //   this.submitted = true;
  //   this.setState({ validation });
  //   if (validation.isValid) {
  //     const { name, name_ar, is_active } = this.state;

  //     this.hideModal();
  //     this.showLoading();

  //     API.post("test-category/", { name, name_ar, is_active })
  //       .then((response) => {
  //         this.fetchCategories();
  //         this.resetForm();
  //         this.showSuccessNotification("Test category successfully created.");
  //       })
  //       .catch((error) => {
  //         console.log("Error on adding biomarker category: ", error);
  //         this.showErrorNotification("Some error has occured. Please try again.");
  //       })
  //       .finally(() => this.hideLoading());
  //   }
  // }

  validate = () => {
    const ONE_MEGA_BYTE = 1_048_576;
    const { name, name_ar, is_active, image, imgSrc , internal_category } = this.state;
    const errors = {};

    if (!name || name.trim() === "")
      errors.name = "Name (English) is a required field!";
    else if (name && name.length > 100)
      errors.name = "Invalid Name(English)! can not contain more than 100 characters."

    if (!name_ar || name_ar.trim() === "")
      errors.name_ar = "Name (Arabic) is a required field!";
    else if (name_ar && name_ar.length > 100)
      errors.name_ar = "Invalid Name(Arabic)! can not contain more than 100 characters."

    if (is_active === null || is_active === undefined)
      errors.status = "Status is a required field!";

    if (!image && !imgSrc)
      errors.image = "Image is a required field!";
    else if (image && image.size && image.size > ONE_MEGA_BYTE)
      errors.image = "Image should be less than 1 MB!"

    return Object.keys(errors).length === 0 ? null : errors;
  }

  saveBiomarkerCategory = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { editId, name, name_ar, is_active, image,internal_category="None" } = this.state;
    
    const formData = new FormData();
    formData.append("name", name);
    formData.append("name_ar", name_ar);
    formData.append("is_active", is_active);
    if (image && image.name){
      formData.append("image", image, image.name);
      formData.append("image_s3", image, image.name);
    }
   if(internal_category=="None"){
     formData.append("internal_category","");
   }else{
     formData.append("internal_category", internal_category );
   }
   

    
    const apiOptions = {
      url: editId ? `test-category/${editId}/` : "test-category/",
      method: editId ? "PATCH" : "POST",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const message = editId 
      ? "Biomarker Category Updated Successfully." 
      : "Biomarker Category Created successfully.";

    try {
      await API.request(apiOptions);
      this.showSuccessNotification(message);
      this.hideModal();
      this.fetchCategories();
    } catch (error) {
      console.log("Error on saving biomarker category: ", error);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
    });
  }

  hideModal = () => {
    this.setState({ showModal: false }, () => this.resetForm());
  }

  showModal() {
    this.setState({ showModal: true,internal_category:"None"});
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Test";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Test successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Test";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Test successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;

    // if (showItems === "Active") list = this.state.activeList;
    // else if (showItems === "Archived") list = this.state.archivedList;
    // else list = this.state.testList;

    list = this.state.categoryList || [];

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.id.toString() === newValue ||
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.name_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("test-category/" + item.id + "/", {
      is_active: !item.is_active,
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.fetchCategories();
      })
      .catch((error) => {
        console.log("Error on toggling status of test category", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  handleSelectImage = (e) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const image = files[0];
    this.setState({ image });

    const reader = new FileReader();
    reader.onloadend = function () {
      this.setState({ imgSrc: [reader.result] });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && this.state.editId ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let {
      finalList,
      isLoaded,
      searchApplied,
      results,
      categoryList,
      editId,
      errors,
      editables,
      // showAlert,
      // formHeading,
      // activeList,
      // archivedList,
      // categoryFilter,
      // showItems,
      // isEmpty,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      // if (searchApplied) finalList = results;
      // else {
      //   if (showItems === "Active") {
      //     finalList = activeList;
      //     if (categoryFilter != "All categories") {
      //       finalList = finalList.filter((item) =>
      //         item.test_category.includes(categoryFilter)
      //       );
      //     }
      //   } else if (showItems === "Archived") {
      //     finalList = archivedList;
      //     if (categoryFilter != "All categories") {
      //       finalList = finalList.filter(
      //         (item) => item.test_category == categoryFilter
      //       );
      //     }
      //   } else {
      //     //all
      //     finalList = categoryList;
      //     if (categoryFilter != "All categories") {
      //       finalList = finalList.filter(
      //         (item) => item.test_category == categoryFilter
      //       );
      //     }
      //   }
      // }
      finalList = searchApplied ? results : categoryList;
    }

    const detailsForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name (English)*</label>
            <div className="input-group">
              <input
                value={this.state.name}
                onChange={(e) => this.handleChange(e)}
                type="text"
                id="name"
                name="name"
                placeholder="Name in English"
                disabled={editId && !editables.name}
                className={"form-control py-2" + (errors.name ? " has-error" : "")}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="name_ar">Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.name_ar}
                onChange={(e) => this.handleChange(e)}
                type="text"
                id="name_ar"
                name="name_ar"
                placeholder="Name in Arabic"
                disabled={editId && !editables.name_ar}
                className={"form-control input-arabic py-2" + (errors.name_ar ? " has-error" : "")}
              />
              {this.renderPencilIcon("name_ar")}
            </div>
            <span className="help-block">{errors.name_ar}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_active}
                onChange={(event, value) => this.setState({ is_active: value.value })}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={editId && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          {/* <div className="form-group col"></div> */}
          <div className="form-group col">
            <label htmlFor="status">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={this.state.internal_category||"None"}
                onChange={(event, value) => this.setState({ internal_category: value.value })}
                id="internal_category"
                name="internal_category"
                placeholder="internal_category"
                search
                selection
                options={internalCategoryOptions}
                disabled={editId && !editables.internal_category}
                className={"form-control" + (errors.internal_category ? " has-error" : "")}
              />
              {this.renderPencilIcon("internal_category")}
            </div>
            <span className="help-block">{errors.internal_category}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
          <label htmlFor="image">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="image"
                name="image"
                type="file"
                accept="image/*"
                disabled={editId && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")
              }
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc && (
              <>
                <label htmlFor="selected_thumbnail">Selected Thumbnail Image</label>
                <div>
                  <img
                    id="selected_thumbnail"
                    src={this.state.imgSrc}
                    alt="selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>;

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-test">
        <div className="page-header">
          {/* <h5>Manage Test Categories</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            <div className="action-item">
              <button
                onClick={() => this.showModal()}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                // className="btn btn-sm button text-button "
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            // noDataComponent={
            //   loadError ? (
            //     <AlertBox message={loadError} error={true}></AlertBox>
            //   ) : (
            //     <AlertBox message="There's nothing here."></AlertBox>
            //   )
            // }
          />
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal} size="lg" className="user-data-modal">
          <Modal.Header closeButton>
            {/* <Modal.Title>{formHeading}</Modal.Title> */}
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              // onClick={this.state.editId ? () => this.updateCategory() : () => this.addCategory()} 
              onClick={() => this.saveBiomarkerCategory()} 
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_biomarker_categories">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              className="btn btn-secondary button"
              onClick={
                this.state.editId
                  ? () => this.updateCategory()
                  : () => this.addCategory()
              }
            >
              Submit
            </button>
          </Modal.Footer> */}
        </Modal>
        <Modal show={this.state.showConfirm} onHide={() => this.hideConfirm()}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={() => this.hideConfirm()}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTestCategories);
