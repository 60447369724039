import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: "NONE", value: null, label: "NONE" };

SupplementCategoriesDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategory: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SupplementCategoriesDropdown(props) {
  const {
    categories = [],
    selectedCategory,
    onSelect = (f) => f,
    isDisabled = false,
  } = props;

  const [activeCategories, inactiveCategories] = _.partition(
    categories,
    (it) => it.status === "Active"
  );
  activeCategories.sort((first, second) =>
    compareStrings(first.nameEnglish, second.nameEnglish, true)
  );
  inactiveCategories.sort((first, second) =>
    compareStrings(first.nameEnglish, second.nameEnglish, true)
  );
  const options = [
    {
      label: "NONE",
      options: [OPTION_NONE],
    },
    {
      label: "Active",
      options: activeCategories.map(createCategoryOption),
    },
    {
      label: "Inactive",
      options: inactiveCategories.map(createCategoryOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedCategory && selectedCategory.id}`}
      value={createCategoryOption(selectedCategory)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Category"
      isDisabled={isDisabled}
      menuPlacement="top"
    />
  );
}

const createCategoryOption = (category) => {
  return category
    ? {
        key: category.id,
        value: category.id,
        label: category.nameEnglish,
      }
    : OPTION_NONE;
};

export default SupplementCategoriesDropdown;
