import React, { Component } from "react";
import AccessRights from "./AccessRights";
import Roles from "./Roles";

class RolesAndAccessRights extends Component {
  render() {
    return (
      <>
        <Roles />
        <AccessRights />
      </>
    );
  }
}

export default RolesAndAccessRights;
