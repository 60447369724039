import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import {
  fetchAuthorUsersAndEligibleAuthorUsers,
  saveAuthorUser,
} from "services/UserService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
const AUTHOR_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Yes",
  },
  {
    key: false,
    value: false,
    text: "No",
  },
];
const TWO_MEGA_BYTE = 2_097_152;

class AuthorsAndEligibleAuthors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      users: [],
      user: {},
      errors: {},
      editables: {},
      loadError: null,
      searchTerm: null,
      showModal: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "userName",
        wrap: true,
        sortable: true,
        format: (user) => this.getUserName(user),
      },
      {
        name: "Email",
        selector: "email",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (user) => <Status isActive={user.active ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (user) => this.renderViewButton(user),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchUsers();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.AUTHOR_USERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchUsers = async () => {
    try {
      this.showLoading();
      const users = (await fetchAuthorUsersAndEligibleAuthorUsers()) ?? [];
      this.setState({ users });
    } catch (error) {
      this.showErrorNotification("Error on fetching users: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  getUserName = (user) => {
    if (!user) return "";
    const { username, fullName, firstName, lastName } = user;
    return username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
  };

  handleChange = ({ currentTarget: input }) => {
    const user = { ...this.state.user };
    user[input.name] = input.value;
    this.setState({ user });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const updatedUser = { ...this.state.user, image };
      this.setState({ user: updatedUser });
      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const updatedUser = {
        ...this.state.user,
        imageSrc: [reader.result],
      };
      this.setState({ user: updatedUser });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleView = (id) => {
    const users = this.state.users || [];
    const user = users.find((it) => it.id === id);
    if (user) {
      this.setState({ user: { ...user, imageSrc: user.image } }, () =>
        this.showModal()
      );
    }
  };

  handleSave = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { user } = this.state;
    try {
      await saveAuthorUser(user);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchUsers();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const errors = {};
    const {
      isAuthor,
      specialization,
      description,
      active: status,
      image,
    } = this.state.user;

    if (status === null || status === undefined)
      errors.status = "Status is a required field!";

    if (isAuthor) {
      if (!specialization || specialization.trim() === "")
        errors.specialization = "Specialization is a required field!";
      else if (specialization.length > 50)
        errors.specialization =
          "Specialization can not take more than 50 characters!";

      if (!description || description.trim() === "")
        errors.description = "Description is a required field!";
      else if (description.length > 300)
        errors.description =
          "Description can not take more than 300 characters!";

      if (!image) errors.image = "Image is a required field!";
      else if (image && image.size && image.size > TWO_MEGA_BYTE)
        errors.image = "Image should be less than 2 MB!";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      user: {},
    });
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  renderViewButton = (user) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(user.id);
        }}
      >
        {user.isAuthor ? "View" : "Be a Author"}
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    return this.state.isEditable ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : null;
  };

  getFilteredUsers = () => {
    const { users = [] } = this.state;
    return this.getSearchedUsers(users);
  };

  getSearchedUsers = (users) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return users;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return users.filter((user) => {
      const { id, userName, email } = user;
      return (
        (id && id.toString() === newValue) ||
        (userName && userName.toLowerCase().indexOf(newValue) !== -1) ||
        (email && email.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { user, editables, errors, searchTerm, isEditable } = this.state;

    const filteredUsers = this.getFilteredUsers();

    const authorReletedFieldsForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specialization">Specialization*</label>
            <div className="input-group">
              <input
                value={user.specialization ?? ""}
                onChange={this.handleChange}
                id="specialization"
                name="specialization"
                type="text"
                placeholder="Specialization"
                className={
                  "form-control py-2" +
                  (errors.specialization ? " has-error" : "")
                }
                disabled={!editables.specialization}
              />
              {this.renderPencilIcon("specialization")}
            </div>
            <span className="help-block">{errors.specialization}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="description">Description*</label>
            <div className="input-group">
              <textarea
                value={user.description ?? ""}
                onChange={this.handleChange}
                id="description"
                name="description"
                placeholder="Description"
                className={
                  "form-control py-2" + (errors.description ? " has-error" : "")
                }
                readOnly={!editables.description}
              />
              {this.renderPencilIcon("description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {user?.imageSrc && (
              <>
                <label htmlFor="selected_image">Selected Image</label>
                <div>
                  <img
                    id="selected_image"
                    name="selected_image"
                    src={user.imageSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="userName">Username</label>
            <input
              value={this.getUserName(user)}
              id="userName"
              name="userName"
              type="text"
              className="form-control py-2"
              disabled={true}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="email">Email</label>
            <input
              value={user.email}
              id="email"
              name="email"
              type="text"
              className="form-control py-2"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={user.active}
                onChange={(event, value) => {
                  this.setState({
                    user: {
                      ...user,
                      active: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="isAuthor">Author</label>
            <div className="input-group">
              <Dropdown
                value={user.isAuthor}
                onChange={(event, value) => {
                  this.setState({
                    user: {
                      ...user,
                      isAuthor: value.value,
                    },
                  });
                }}
                id="isAuthor"
                name="isAuthor"
                placeholder="Status"
                search
                selection
                options={AUTHOR_OPTIONS}
                disabled={!editables.isAuthor}
                className={
                  "form-control" + (errors.isAuthor ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isAuthor")}
            </div>
            <span className="help-block">{errors.isAuthor}</span>
          </div>
        </div>
        {user.isAuthor && authorReletedFieldsForm}
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredUsers}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredUsers.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_users">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorsAndEligibleAuthors);
