import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

function SelectLab(props) {
  const { labs, selectedLabId, isDisabled, onLabSelect } = props;
  labs.sort((first, second) => compareStrings(first.name, second.name, true));

  const findLab = (labId) => labs.find((lab) => lab.id === labId);

  const createOption = (lab) => {
    if (!lab) return;
    return {
      key: lab.id,
      value: lab.id,
      label: lab.name,
    };
  };

  const labOptions = labs.map(createOption);
  const selectedLab = findLab(selectedLabId);
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`my_unique_select_key__${selectedLabId}`}
      value={createOption(selectedLab)}
      onChange={(option) => onLabSelect(option.value)}
      options={labOptions}
      styles={selectStyles}
      isDisabled={isDisabled}
      placeholder="Select Lab"
    />
  );
}

SelectLab.propTypes = {
  labs: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedLabId: PropTypes.number,
  isDisabled: PropTypes.bool,
  onLabSelect: PropTypes.func.isRequired,
};

SelectLab.defaultProps = {
  labs: [],
  selectedLabId: "",
  isDisabled: false,
  onLabSelect: (f) => f,
};

export default SelectLab;
