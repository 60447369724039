const ORDER_STATUSES = {
  CREATED: { status: "Order Created", color: "green" },
  PROCESSING: { status: "Processing", color: "orange" },
  PARTIALLY_COMPLETE: { status: "Partially Complete", color: "orange" },
  REPORT_CRECEIVED: { status: "Report Received", color: "orange" },
  UNDER_REVIEW: { status: "Report Received", color: "orange" },
  COACH_ASSIGNED: { status: "Report Received", color: "orange" },
  COMPLETED: { status: "Order Completed", color: "orange" },
  CANCELLED: { status: "Report Received", color: "orange" },
};

export const getOrderStatusWithColor = (orderStatus) => {
  switch (orderStatus) {
    case "CREATED":
      return { status: "Order Created", color: "blue" };
    case "PROCESSING":
      return { status: "Processing", color: "yellow" };
    case "COMPLETED":
      return { status: "Order Completed", color: "green" };
    case "CANCELLED":
      return { status: "Order Cancelled", color: "red" };
    default: {
      console.log(
        "getOrderStatusWithColor:: Unknown orderStatus:",
        orderStatus
      );
      return null;
    }
  }
};
