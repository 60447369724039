import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import EditIcon from "@material-ui/icons/EditOutlined";
import React, { Component } from 'react';
import { Card, Modal } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import { connect } from "react-redux";
import API from "utils/API";
import FormValidator from "utils/FormValidator";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class WhatWeFocus extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: "title",
                method: "isEmpty",
                validWhen: false,
                message: "Title is required.",
            },
            {
                field: "title",
                method: "isLength",
                validWhen: true,
                min: 1,
                max: 50,
                message: "Title should have more than 1 characters and less than 50 characters.",
            },
            {
                field: "title_arabic",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Title is required.",
            },
            {
                field: "title_arabic",
                method: "isLength",
                validWhen: true,
                isRtl: true,
                min: 1,
                max: 50,
                message: "Title should have more than 1 characters and less than 50 characters.",
            },
            {
                field: "desc",
                method: "isEmpty",
                validWhen: false,
                message: "Description is required.",
            },
            {
                field: "desc",
                method: "isLength",
                validWhen: true,
                min: 10,
                max: 500,
                message: "Description should have more than 10 characters and less than 500 characters.",
            },
            {
                field: "desc_arabic",
                method: "isEmpty",
                validWhen: false,
                isRtl: true,
                message: "Description is required",
            },
            {
                field: "desc_arabic",
                method: "isLength",
                validWhen: true,
                isRtl: true,
                min: 10,
                max: 500,
                message: "Description should have more than 10 characters and less than 500 characters.",
            },
            {
                field: "image",
                type: "image",
                method: "isEmpty",
                validWhen: false,
                args: [{ min: 0 }],
                message: "Image is required.",
            },
        ]);

        this.state = {
            title: "",
            title_arabic: "",
            desc: "",
            desc_arabic: "",
            image: "",
            imgSrc: "",
            validation: this.validator.valid(),
            showModal: false,
            data: [],
            disabled: false,
            loading: false,
            editId: null
        };
        this.columns = [
            {
                name: "",
                cell: (row) =>
                    // <a className="drag-handle" href="#">Drag</a>
                    <Drag className="drag-handle"></Drag>,
                width: "50px"
            },
            {
                name: "Id",
                selector: "id",
                width: "100px"
            },
            {
                name: "Title",
                selector: "heading",
                cell: (row) => {
                    return (
                        <p>{row.title}</p>
                    )
                },
            },
            {
                name: "Description",
                cell: (row) => {
                    return (
                        <p>{row.description}</p>
                    )
                },
            },
            {
                name: "Actions",
                cell: (row) => {
                    return (
                        <div>
                            <button className="delete-btn" onClick={() => { this.handleDeleteData(row.id) }}><DeleteIcon></DeleteIcon></button><button className="delete-btn" onClick={() => { this.handleEditData(row) }}><EditIcon></EditIcon></button>
                        </div>
                    )
                },
                width: "200px"
            }
        ]
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.data];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({
                    data
                });
                that.updateRank();
            },
            nodeSelector: ".rdt_TableRow",
            handleSelector: ".drag-handle",
            enableScroll: true
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleCreateData = this.handleCreateData.bind(this);
        this.handleEditData = this.handleEditData.bind(this);
        this.handleDeleteData = this.handleDeleteData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateRank = this.updateRank.bind(this);
        this.submitted = false;
        this.selectImage = this.selectImage.bind(this);
    }

    componentDidUpdate(prevProps) {
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        API.get("what-we-focus/")
            .then(response => {
                let data = response.data;
                data.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)
                if (data) {
                    this.setState({
                        data: data
                    })
                }
                if (data.length === 6) {
                    this.setState({
                        disabled: true
                    })
                }
                else if (data.length < 6) {
                    this.setState({
                        disabled: false
                    })
                }
            })
            .catch(error => {
                console.log("Error on fetching data", error);
            })
    }

    handleCreateData() {
        this.setState({editId: null});
        this.showModal();
    }

    handleEditData(row) {
        this.setState({
            editId: row.id,
            title: row.title,
            title_arabic: row.title_ar,
            desc: row.description,
            desc_arabic: row.description_ar,
            image: row.image,
            imgSrc: row.image,
        });
        this.showModal();
    }

    handleSubmit(e) {
        const validation = this.validator.validate(this.state);
        const { editId } = this.state;

        e.preventDefault();
        this.submitted = true;
        this.setState({ validation });

        if (validation.isValid){
        this.setState({ loading: true });
        const formData = this.createFormData();
        const apiOptions = (editId == null)
            ? this.createAPIOptions("post", "what-we-focus/", formData)
            : this.createAPIOptions("patch", `what-we-focus/${editId}/`, formData);
        
        API.request(apiOptions)
            .then(response => {
                this.hideModal();
                this.fetchData();
                this.props.showNotificationMessage({
                    notificationMessage: "Section Added successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
                this.setState({
                    loading: false
                });
            })
            .catch(error => {
                console.log("Error on saving/updating data", error);
                this.setState({
                    error: "Some error occured. Please try again",
                    loading: false
                });
            })

        }
        else {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
                isLoading: false,
            });;
        }
    }

    createFormData = () => {
        const { title, title_arabic, desc, desc_arabic, image } = this.state;
        const formData = new FormData();
        formData.append("title", title);
        formData.append("title_ar", title_arabic);
        formData.append("description", desc);
        formData.append("description_ar", desc_arabic);
        if (image && image.name)
            formData.append("image", image, image.name);
        
        return formData;
    }

    createAPIOptions = (method, url, formData) => (
        {
            method,
            url,
            data: formData,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
            }
        }
    );

    handleDeleteData(id) {
        API.delete("what-we-focus/" + id + "/")
            .then(response => {
                this.fetchData();
                this.props.showNotificationMessage({
                    notificationMessage: "Section Deleted successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on deleting data", error);
            })
    }
   
    formReset() {
        this.submitted = false;
        this.setState({
            title: "",
            title_arabic: "",
            desc: "",
            desc_arabic: "",
            image: "",
            imgSrc: "",
            validation: this.validator.valid(),
        });
    }
    
    hideModal() {
        this.formReset();
        this.setState({ showModal: false });
    }
    
    showModal() {
        this.setState({
            showModal: true,
        });
    }

    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }
    
    updateRank() {
        var rank = {};
        const { data } = this.state;
        data.map((val, index) => {
            rank[val.id] = index + 1;
        })
        API.post("set-whatwefocus-rank/", rank)
            .then(response => {
                this.props.showNotificationMessage({
                    notificationMessage: "Slider Updated successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on updating rank", error);
                this.setState({
                    error: "Some error occured. Please try again",
                    loading: false
                });
            })
    }

    selectImage(e) {
        if (e.target.files && e.target.files.length) {
            let image = e.target.files[0];
            console.log(image)
            this.setState({ image });
            let reader = new FileReader();
            // var url = reader.readAsDataURL(image);

            reader.onloadend = function () {
                this.setState({
                    imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(image);
        }
    }

    render() {
        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation;

        const detailsForm =
            <form onSubmit={this.handleSubmit}>
                <div className="container">
                    <div className="row">
                        <div className="form-group col">
                            <label htmlFor="">Card Title (English) *</label>
                            <input
                                type="text"
                                name="title"
                                className={"form-control " + (validation.title.isInvalid && "has-error")}
                                placeholder="Title in English"
                                value={this.state.title}
                                onChange={this.handleChange} />
                            <span className="help-block">{validation.title.message}</span>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="">Card Title (Arabic) *</label>
                            <input
                                type="text"
                                name="title_arabic"
                                className={"form-control input-arabic " + (validation.title_arabic.isInvalid && "has-error")}
                                placeholder="Title in Arabic"
                                value={this.state.title_arabic}
                                onChange={this.handleChange} />
                            <span className="help-block">{validation.title_arabic.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label htmlFor="">Card Description (English) *</label>
                            <textarea
                                placeholder="Description in English"
                                name="desc" 
                                id="desc1"
                                className={"form-control " + (validation.desc.isInvalid && "has-error")}
                                value={this.state.desc}
                                onChange={this.handleChange}>
                            </textarea>
                            <span className="help-block">{validation.desc.message}</span>
                        </div>
                        <div className="form-group col">
                            <label htmlFor="">Card Description (Arabic) *</label>
                            <textarea
                                placeholder="Description in Arabic"
                                name="desc_arabic" 
                                id="desc_arabic"
                                className={"form-control input-arabic " + (validation.desc_arabic.isInvalid && "has-error")}
                                value={this.state.desc_arabic}
                                onChange={this.handleChange}>
                            </textarea>
                            <span className="help-block">{validation.desc_arabic.message}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col">
                            <label>Image *</label>
                            <input
                                type="file"
                                id="imageInput"
                                name="image"
                                accept="image/*"
                                className={
                                    "form-control " + (validation.image.isInvalid && "has-error")
                                }
                                onChange={this.selectImage}
                            ></input>
                            <span className="help-block">{validation.image.message}</span>
                        </div>
                        <div className="form-group col">
                            {this.state.imgSrc && (
                                <>
                                    <label><b>Selected Image</b></label>
                                    <div className="form-image-container">
                                        <img
                                            src={this.state.imgSrc}
                                            className="form-image"
                                            alt="selected"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        return (
            <div className="how-it-works">
                <div className="page-header">
                    {/* <h5 className="mb-4">What We Focus Section</h5> */}
                    <div className="actions">
                        <div className="action-item">
                            <button disabled={this.state.disabled} className="btn btn-sm button text-button" onClick={this.handleCreateData}>
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>add new
                            </button>
                        </div>
                    </div>
                </div>
                <Card body>
                    {this.state.data.length < 6 && <p className="p-head">You can add only 6 cards to the Section</p>}
                    {this.state.data.length == 6 && <p className="p-head">You have already added 6 cards to the Section</p>}
                    <ReactDragListView {...this.dragProps}>
                        <DataTable
                            highlightOnHover
                            columns={this.columns}
                            data={this.state.data}
                            pagination
                            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            responsive
                        /></ReactDragListView>
                </Card>
                <Modal size="lg" show={this.state.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {detailsForm}
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn button button-dark" onClick={this.handleSubmit} disabled={this.state.loading ? true : false}>Publish</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WhatWeFocus);
