import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import { fetchCities } from "services/RegionService";

SelectCity.propTypes = {
  countryId: PropTypes.number,
  selectedCity: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectCity.defaultTypes = {
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectCity(props) {
  const { countryId, selectedCity, onSelect, isDisabled } = props;
  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetchCities()
      .then((cities) => setCities(cities))
      .catch((error) => console.log("error on fetcing cities: ", error));
  }, []);

  const citiesForCountry = cities
    ? cities.filter((it) => it.country?.id === countryId)
    : [];
  const [activeCities, inactiveCities] = _.partition(
    citiesForCountry,
    (it) => it.is_active ?? false
  );
  activeCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );
  inactiveCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );
  const options = [
    {
      label: "Active",
      options: activeCities.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCities.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
      value={selectedCity}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select City..."
      isDisabled={isDisabled}
    />
  );
}

const createOption = (city) => {
  return city
    ? {
        key: city.id,
        value: city.id,
        label: city.city_name,
      }
    : null;
};

export default SelectCity;
