import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { IconButton } from "@material-ui/core";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
} from "@material-ui/icons/";

PdfViewer.propTypes = {
  pdfFileUrl: PropTypes.string.isRequired,
};

const MIN_SCALE_LIMIT = 1.0;
const MAX_SCALE_LIMIT = 2.0;
const ZOOM_FACTOR = 0.5;

function PdfViewer({ pdfFileUrl }) {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(MIN_SCALE_LIMIT);
  const file = useMemo(() => ({ url: pdfFileUrl }), [pdfFileUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const zoomIn = () => {
    setScale((prevScale) =>
      prevScale >= MAX_SCALE_LIMIT ? MAX_SCALE_LIMIT : prevScale + ZOOM_FACTOR
    );
  };

  const zoomOut = () => {
    setScale((prevScale) =>
      prevScale <= MIN_SCALE_LIMIT ? MIN_SCALE_LIMIT : prevScale - ZOOM_FACTOR
    );
  };

  const pages = Array.apply(null, Array(numPages))
    .map((x, i) => i + 1)
    .map((page) => <Page pageNumber={page} scale={scale} />);

  return (
    <>
      <div className="row">
        <IconButton
          onClick={(e) => zoomOut()}
          disabled={scale <= MIN_SCALE_LIMIT}
          aria-label="zoom out"
          className="ml-auto"
        >
          <ZoomOutIcon />
        </IconButton>
        <IconButton
          onClick={(e) => zoomIn()}
          disabled={scale >= MAX_SCALE_LIMIT}
          aria-label="zoom in"
        >
          <ZoomInIcon />
        </IconButton>
      </div>
      <div className="row">
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options>
          {pages}
        </Document>
      </div>
    </>
  );
}

export default PdfViewer;
