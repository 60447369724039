import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_MEDICINES = "medicine/";
const BASE_URL_MEDICINES_SUMMARY = "medicine/summary/";

export const fetchMedicines = async () => {
  try {
    const { data: medicines = [] } = await PhoenixAPI.get(BASE_URL_MEDICINES);
    return medicines;
  } catch (error) {
    console.log("Error on fetching medicines", error);
    throw error;
  }
};

export const fetchMedicinesSummary = () => {
  return PhoenixAPI.get(BASE_URL_MEDICINES_SUMMARY);
};

export const fetchMedicinesSummaryByCountry = (countryId) => {
  if (!countryId) {
    console.log(
      "fetchMedicinesSummaryByCountry:: Cannot fetch! Invalid country id",
      { countryId }
    );
    return null;
  }

  return PhoenixAPI.get(BASE_URL_MEDICINES_SUMMARY, {
    params: { country: countryId },
  });
};

export const fetchMedicineById = (medicineId) => {
  if (!medicineId) {
    console.log(
      "fetchMedicineById:: Cannot fetch! Invalid medicine id",
      { medicineId }
    );
    return null;
  }

  return PhoenixAPI.get(`${BASE_URL_MEDICINES}${medicineId}`);
};

export const saveMedicine = async (medicine) => {
  try {
    const payload = { ...medicine };
    delete payload["image"];
    delete payload["image_src"];
    const formData = new FormData();
    formData.append("medicines", JSON.stringify(payload));
    formData.append("image", medicine.image);

    const apiOptions = {
      method: "post",
      url: BASE_URL_MEDICINES,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating medicine", error);
    throw error;
  }
};

export const updateMedicine = async (medicine) => {
  try {
    const payload = { ...medicine };
    delete payload["image_src"];
    if (typeof medicine.image !== "string") delete payload["image"];

    const formData = new FormData();
    formData.append("medicine", JSON.stringify(payload));
    if (typeof medicine.image !== "string")
      formData.append("image", medicine.image);

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_MEDICINES}${medicine.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating medicine", error);
    throw error;
  }
};

const MedicineService = {
  fetchMedicines,
  fetchMedicinesSummary,
  fetchMedicinesSummaryByCountry,
  saveMedicine,
  updateMedicine,
};

export default MedicineService;
