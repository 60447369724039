import React, { Component } from "react";
import HomePage from "./HomePage";
import Inpage from "./Inpage";
import TextBanners from "./TextBanners";
import CountryDropdown from "components/Common/CountryDropdown";
import API from "utils/API";
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import PopupBanners from "./PopupBanners";

const BASE_URL_COUNTRIES = "countries/";

class Banners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
    };
    this.columns = [];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.BANNERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });
    try {
      const { data: allCountries } = await API.get(BASE_URL_COUNTRIES);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      });
    } catch (error) {
      console.error(
        "Error on fetching countries from this URL: ",
        BASE_URL_COUNTRIES
      );
      console.error("Error on fetching countries", error.message);
    }
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  render() {
    const { countries, selectedCountry, accessDenied, isEditable } = this.state;

    if (accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <HomePage countries={countries} selectedCountry={selectedCountry} isEditable={isEditable} />
        <Inpage countries={countries} selectedCountry={selectedCountry} isEditable={isEditable} />
        <TextBanners countries={countries} selectedCountry={selectedCountry} isEditable={isEditable} />
        <PopupBanners countries={countries} selectedCountry={selectedCountry} isEditable={isEditable} />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banners);
