import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";


const BASE_URL_CREATE_DRAFT_ORDER ="admin/create-draft-order/";
const BASE_URL_DRAFT_ORDERS ="admin/draft-cart-orders";
const BASE_URL_DRAFT_ORDER="admin/draft-cart-order/"

export const createOrder = async (payload) => {
    return await PhoenixAPI.post(BASE_URL_CREATE_DRAFT_ORDER, payload);
  };


  export const fetchOrders = async (config) => {
    const {
      page,
      rowsPerPage,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = config ?? {};
    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    const params = { page, page_size: rowsPerPage, ordering: sf };
    if (searchApplied) params.search = searchTerm;
   
    try {
      const { data } = await PhoenixAPI.get(BASE_URL_DRAFT_ORDERS, { params });
      if (Array.isArray(data)) return { count: data.length, results: data };
      else return data;
    } catch (error) {
      console.log("Error on fetching orders", error);
      throw error;
    }
  };

export const fetchOrder = async (orderId) => {
  try {
    const { data: order } = await PhoenixAPI.get(BASE_URL_DRAFT_ORDER + orderId);
    return order;
  } catch (error) {
    console.log("Error on fetching orders", error);
    throw error;
  }
};

  export const saveOrder =async (order)=>{
    if(order === null) return;
    const config = {
      method: order.id ? "PATCH" : "POST",
      url: order.id ? `${BASE_URL_DRAFT_ORDER}${order.id}`:BASE_URL_DRAFT_ORDER,
      data:order,
    };
    return await PhoenixAPI.request(config);
  }

  const OrderService={
    saveOrder,
    fetchOrders,
  }

  export default OrderService;