import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import SelectSuggestedSupplement from "../../pages/CoachManagment/SelectSuggestedSupplement";
import moment from "moment";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";
import SelectPeriod from "../../pages/CoachManagment/SelectPeriod";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class SupplementSuggestion extends Component {
  constructor(props) {
    super(props);
    const {
      suggestedSupplements,
      countryId = null,
      suggestions = [],
      suggestionsUpdate = (f) => f,
      isEditable,
    } = this.props || {};

    this.suggestionsUpdate = suggestionsUpdate;
    this.state = {
      suggestions,
      suggestion: {},

      isSuggestionViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
      periods: [],
      supplements: suggestedSupplements ?? [],
      // isEditable: true,

      countryId,
    };
    this.columns = [
      {
        name: "Supplement Name",
        selector: "supplement_name",
        wrap: true,
        sortable: true,
      },

      {
        name: "Doses English",
        selector: "doses_eng",
        wrap: true,
        sortable: true,
      },

      {
        name: "Doses Arabic",
        selector: "doses_ar",
        wrap: true,
        sortable: true,
      },
      {
        name: "Period",
        selector: "period_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (supplement) => (
          <Status isActive={supplement.is_active ?? false} />
        ),
        sortable: true,
      },
      {
        name: "Update",
        center: true,
        cell: (p) => this.renderViewButton(p.supplement_id),
      },
    ];
  }

  componentDidMount() {
    this.fetchPeriods();
  }

  fetchPeriods = async () => {
    this.showLoading();
    try {
      const { data: periods = [] } = await PhoenixAPI.get(`supplement-periods`);

      this.setState({ periods });
    } catch (error) {
      console.log("Error in fetching periods");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement periods",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      suggestion: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (supplementId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewSuggestion(supplementId);
        }}
        disabled={!this.props.isEditable}
      >
        Edit
      </button>
    </div>
  );

  renderDeleteButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleDeleteSuggestion(id);
        }}
        disabled={!this.props.isEditable}
      >
        Delete
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.props.isEditable || this.state.isNewSuggestion) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  handleAddSuggestion = () => {
    this.setState({ isNewSuggestion: true }, () =>
      this.showSuggestionViewModal()
    );
  };

  saveSuggestion = async () => {
    const { suggestions = [], suggestion } = this.state;
    const { suggestedSupplements = [] } = this.props || {};
    const ids = suggestions.length > 0 ? suggestions.map((it) => it.id) : null;
    suggestion.id = ids ? Math.max(...ids) + 1 : 1;
    suggestion.is_new = true;
    const filteredSupplement = suggestedSupplements.filter(
      (it) => it.id === suggestion.supplement_id
    );
    suggestion.supplement_name =
      filteredSupplement.length > 0 ? filteredSupplement[0].internalName : "";

    this.setState({ suggestions: [...suggestions, suggestion] }, () => {
      this.showSuccessNotification(
        "Supplement Suggestion Created Successfully!"
      );
      this.onSuggestionUpdatedLocally();
    });
  };

  updateSuggestion = async (id) => {
    const { suggestions = [], suggestion } = this.state;

    const newSuggestions = suggestions.map((it) =>
      it.supplement_id === id ? suggestion : it
    );

    this.setState({ suggestions: newSuggestions }, () => {
      this.showSuccessNotification(
        "Supplement Suggestion Updated Successfully!"
      );
      this.onSuggestionUpdatedLocally();
    });
  };

  onSuggestionUpdatedLocally = () => {
    // this.clearForm();
    this.suggestionsUpdate(
      this.state.suggestions.map((suggestion) => {
        const q = { ...suggestion };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  validate = () => {
    const { suggestion } = this.state;
    const errors = {};
    const { supplement_id, period_id } = suggestion || {};

    if (supplement_id == null)
      errors.supplement_id = "Supplement cannot be blank";
    if (period_id == null) errors.period_id = "Period cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSaveSuggestion = async () => {
    const { suggestion, isNewSuggestion } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = { ...suggestion };
    try {
      this.showLoading();
      // const isValidId = suggestion.supplementId !== null && suggestion.supplementId !== undefined;
      const isValidId = isNewSuggestion ?? false;
      const response = !isValidId
        ? await this.updateSuggestion(payload.supplement_id)
        : await this.saveSuggestion(payload);

      this.hideSuggestionViewModal();
    } catch (error) {
      console.log("error occurred on saving suggestion", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleDeleteSuggestion = async (id) => {
    const { suggestions } = this.state || {};

    const updatedSuggestions = suggestions.filter(
      (item) => item.supplement_id !== id
    );

    this.setState({ suggestions: updatedSuggestions }, () =>
      this.onSuggestionUpdatedLocally()
    );
  };

  handleViewSuggestion = (suggestionId) => {
    const suggestions = this.state.suggestions ?? [];

    const suggestion = suggestions.find(
      (it) => it.supplement_id === suggestionId
    );
    if (!suggestion) {
      console.log(`Suggestion id ${suggestionId} is not found!`);
      return;
    }

    this.setState({ suggestion, isNewSuggestion: false }, () =>
      this.showSuggestionViewModal()
    );
  };

  showSuggestionViewModal = () => {
    const { isNewSuggestion } = this.state;

    if (isNewSuggestion) {
      const suggestion = {};
      this.setState({
        suggestion,

        isSuggestionViewModalVisible: true,
      });
    } else {
      this.setState({ isSuggestionViewModalVisible: true });
    }
  };

  hideSuggestionViewModal = () => {
    this.setState({ isSuggestionViewModalVisible: false });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const suggestion = { ...this.state.suggestion };
    suggestion[input.name] = input.value;
    this.setState({ suggestion });
  };

  getFilteredSuggestions = (suggestions) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return suggestions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return suggestions.filter((suggestion) => {
      const { supplementName, periodEng, dosesEng } = suggestion;
      return (
        (supplementName &&
          supplementName.toLowerCase().indexOf(newValue) !== -1) ||
        (periodEng && periodEng.toLowerCase().indexOf(newValue) !== -1) ||
        (dosesEng && dosesEng.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      suggestions,
      suggestion,
      isSuggestionViewModalVisible,
      errors,
      editables,
      loadError,
      searchTerm,
      searchApplied,
      isNewSuggestion,

      periods,
    } = this.state;
    const { suggestedSupplements } = this.props || {};

    const filteredSuggestions = this.getFilteredSuggestions(suggestions) ?? [];
    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cityId">Select Supplement *</label>
            <div className="input-group">
              <SelectSuggestedSupplement
                id="supplementId"
                supplements={suggestedSupplements ?? []}
                selectedSuggestedSupplement={suggestion.supplement_id ?? null}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,

                      supplement_id: value.value,
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.supplementId}
                className={
                  "form-control" + (errors.supplementId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("supplementId")}
            </div>
            <span className="help-block">{errors.supplementId}</span>
          </div>

          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={suggestion?.is_active}
                onChange={(event, value) => {
                  this.setState({
                    suggestion: { ...suggestion, is_active: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSuggestion && !editables.is_active}
                className={
                  "form-control" + (errors.is_active ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.is_active}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (English)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.doses_eng) || ""}
                onChange={this.handleChange}
                id="doses_eng"
                name="doses_eng"
                type="text"
                placeholder="Enter Doses in English"
                className={
                  "form-control input py-2" +
                  (errors.dosesEng ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.doses_eng}
              />
              {this.renderPencilIcon("doses_eng")}
            </div>
            <span className="help-block">{errors.doses_eng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (Arabic)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.doses_ar) || ""}
                onChange={this.handleChange}
                id="doses_ar"
                name="doses_ar"
                type="text"
                placeholder="Enter Doses in Arabic"
                className={
                  "form-control input py-2 input-arabic" +
                  (errors.doses_ar ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.doses_ar}
              />
              {this.renderPencilIcon("doses_ar")}
            </div>
            <span className="help-block">{errors.doses_ar}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="cityId">Select Period *</label>
            <div className="input-group">
              <SelectPeriod
                id="periodId"
                periods={periods}
                selectedPeriod={suggestion.period_id}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      period_id: value.value,
                      period_name: value.label,
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.period_id}
                className={
                  "form-control" + (errors.period_id ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("period_id")}
            </div>
            <span className="help-block">{errors.period_id}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col px-0">
            <Card>
              <DataTable
                data={suggestions ?? []}
                columns={this.columns}
                // defaultSortField="id"
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
                // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                highlightOnHover
                // pagination
                responsive
                // noHeader={this.state.searchApplied ? false : true}

                noDataComponent={
                  this.state.loadError ? (
                    <AlertBox
                      message={this.state.loadError}
                      error={true}
                    ></AlertBox>
                  ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
                }
              />
              <button
                onClick={this.handleAddSuggestion}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>

        <Modal
          size="lg"
          show={this.state.isSuggestionViewModalVisible}
          onHide={this.hideSuggestionViewModal}
          // className="user-data-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>{detailsForm}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideSuggestionViewModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSaveSuggestion}
              disabled={false}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {suggestion.supplement_id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplementSuggestion);
