import { clearToken } from "./TokenService";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_CLIENTS = "list-active-user/";
const BASE_URL_ADMIN_USERS = "admin/users";
const BASE_URL_COACH_USERS = "coach/users";
const BASE_URL_OPERATIONS_USERS = "role-id/2"
const BASE_URL_CUSTOMER_TRANSACTIONS = "admin/customer-orders";
const BASE_URL_AUTHOR_USERS_AND_ELIGIBLE_AUTHOR_USERS = "/health-hub/list-authors/";
const BASE_URL_SEARCH_CLIENT_EMAIL="user-search";
const BASE_URL_COACH_CUSTOMER_TRANSACTIONS="v2/admin/customer-orders"

export const fetchCustomerTransactions = async (customerId) => {
  if (customerId === null || customerId === undefined) {
    console.log("fetchCustomerTransactions:: Invalid customerId=", customerId);
    return null;
  }
  try {
    const { data } = await PhoenixAPI.get(BASE_URL_CUSTOMER_TRANSACTIONS, {
      params: { userId: customerId },
    });
    const cartOrders = data?.cartOrders ?? [];
    return cartOrders.flatMap(cartOrder => cartOrder.items);
  } catch (error) {
    console.log(
      "Error on fetching customer transactions from url:",
      BASE_URL_CUSTOMER_TRANSACTIONS
    );
    console.log("Error on fetching customer transactions ", error.message);
    throw error;
  }
};

export const fetchCoachCustomerTransactions=async(customerId)=>{
  if (customerId === null || customerId === undefined) {
    console.log("fetchCustomerTransactions:: Invalid customerId=", customerId);
    return null;
  }
  try {
    const { data } = await PhoenixAPI.get(BASE_URL_COACH_CUSTOMER_TRANSACTIONS, {
      params: { userId: customerId },
    });
    const cartOrders = data?.cartOrders ?? [];
    return cartOrders.flatMap(cartOrder => cartOrder.items);
  } catch (error) {
    console.log(
      "Error on fetching customer transactions from url:",
      BASE_URL_COACH_CUSTOMER_TRANSACTIONS
    );
    console.log("Error on fetching customer transactions ", error.message);
    throw error;
  }
}

export const fetchClientUsersByEmail = async(emailSearchTerm) => {
  console.log("UserService:: fetchClientUsersByEmail: emailSearchTerm:", emailSearchTerm);
  if (!emailSearchTerm || emailSearchTerm.trim() === "")
      return;
  try {
    const { data } = await API.get(BASE_URL_CLIENTS, {params: {search: emailSearchTerm}});
    console.log("UserService:: fetchClientUsersByEmail: clients:", data)
    return data?.results;
  } catch (error) {
    console.log("UserService:: fetchClientUsersByEmail: Error on fetching clients by email", error);
  }
}

export const getClientByEmailOnCartOrders = async (emailSearchTerm) => {
  console.log("UserService:: getClientByEmailOnCartOrders: emailSearchTerm:", emailSearchTerm);
  if (!emailSearchTerm || emailSearchTerm.trim() === "")
    return;
  try {
    const { data } = await PhoenixAPI.get(BASE_URL_SEARCH_CLIENT_EMAIL, { params: { searchParam: emailSearchTerm } });
    console.log("UserService:: getClientByEmailOnCartOrders: clients:", data)
    return data?.results;
  } catch (error) {
    console.log("UserService:: getClientByEmailOnCartOrders: Error on fetching clients by email", error);
  }
}

export const fetchClientUser = async (clientId) => {
  console.log("UserService:: fetchClientUser:", clientId);
  const url = `${BASE_URL_CLIENTS}${clientId}/`;
  try {
    const { data: client } = await API.get(url);
    return client;
  } catch (error) {
    console.log(`Error on fetching client user from url: ${url}`, error);
    throw error;
  }
};

export const updateClientUserIDInfo = async (clientId, clientIDInfo) => {
  console.log("UserService:: updateClientUser:", { clientId, clientIDInfo });
  const { email, full_name_id, front_id, back_id } = clientIDInfo ?? {};
  const url = `${BASE_URL_CLIENTS}${clientId}/`;

  const formData = new FormData();
  formData.append("email", email);
  formData.append("full_name_id", full_name_id ?? null);
  
  if (front_id && typeof front_id !== "string") {
    formData.append("front_id", front_id);
    formData.append("front_id_s3", front_id);
  }
  if (back_id && typeof back_id !== "string") {
    formData.append("back_id", back_id);
    formData.append("back_id_s3", back_id);
  }

  try {
    await API.patch(url, formData);
  } catch (error) {
    console.log(`Error on updating client user ID Info from url: ${url}`, error);
    throw error;
  }
};

export const fetchAdminUsers = async () => {
  try {
    const { data: adminUsers = [] } = await PhoenixAPI.get(
      BASE_URL_ADMIN_USERS
    );
    return adminUsers;
  } catch (error) {
    console.log("Error on fetching admin users!", error);
    throw error;
  }
};

export const fetchCoachUsers = async () => {
  try {
    const { data: coachUsers = [] } = await PhoenixAPI.get(
      BASE_URL_COACH_USERS
    );
    return coachUsers;
  } catch (error) {
    console.log("Error on fetching coach users!", error);
    throw error;
  }
};

export const fetchOperationsUsers = async () => {
  try {
    const response = await PhoenixAPI.get(
      BASE_URL_OPERATIONS_USERS
    );
    let operationsUsers= response && response.data || [];
    return operationsUsers;
    
  }
  catch (error) {
    console.log("Error on fetching operations users!", error);
    throw error;
  }
}

export const fetchAuthorUsersAndEligibleAuthorUsers = async () => {
  try {
    const { data: users } = await PhoenixAPI.get(
      BASE_URL_AUTHOR_USERS_AND_ELIGIBLE_AUTHOR_USERS
    );
    console.log("fetchAuthorUsersAndEligibleAuthorUsers:: ", {users});
    return users;
  }
  catch (error) {
    console.log("Error on fetching author and elgible author users!", error);
    throw error;
  }
}

export const saveAuthorUser = async (authorUser) => {
  try {
    const payload = { ...authorUser };
    delete payload["imageSrc"];

    const formData = new FormData();
    if (typeof authorUser.image !== "string") {
      delete payload["image"];
      formData.append("image", authorUser.image);
    }

    formData.append("authorUser", JSON.stringify(payload));

    const apiOptions = {
      method: authorUser.id ? "PATCH" : "POST",
      url: authorUser.id
        ? `${BASE_URL_AUTHOR_USERS_AND_ELIGIBLE_AUTHOR_USERS}${authorUser.id}`
        : BASE_URL_AUTHOR_USERS_AND_ELIGIBLE_AUTHOR_USERS,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    return await PhoenixAPI.request(apiOptions);
  } catch (error) {
    console.log(
      "saveArticleCategory:: Error on saving author user!",
      error
    );
    throw error;
  }
};

export function getUserType() {
  const type = localStorage.getItem("8js2jbhca");

  if (type === "k48s7vc54s") return "admin";
  else if (type === "1b8sujsd87b") return "nutritionist";
  else if (type === "y21s4sehbbw") return "crm";
  else if (type === "8sdj23ybnsh") return "lab";
  else if (type === "homecare") return "homecare";
  else if(type==="externalNutritionist") return "externalNutritionist";
  else if(type==="homeCareProfessional") return "homeCareProfessional";
  else return null;
}

export function setUserType(type) {
  if (type === "admin") localStorage.setItem("8js2jbhca", "k48s7vc54s");
  else if (type === "nutritionist")
    localStorage.setItem("8js2jbhca", "1b8sujsd87b");
  else if (type === "crm") localStorage.setItem("8js2jbhca", "y21s4sehbbw");
  else if (type === "lab") localStorage.setItem("8js2jbhca", "8sdj23ybnsh");
  else if (type === "homecare") localStorage.setItem("8js2jbhca", "homecare")
  else if (type==="externalNutritionist") localStorage.setItem("8js2jbhca", "externalNutritionist")
  else if (type==="homeCareProfessional") localStorage.setItem("8js2jbhca", "homeCareProfessional")
  else localStorage.setItem("8js2jbhca", null);
}

export function getUserName() {
  const type = localStorage.getItem("userName");

  if (type) return type;

  return null;
}

export function getUserId() {
  const type = localStorage.getItem("userId");

  if (type) return type;

  return null;
}

export function getUserEmail() {
  const type = localStorage.getItem("userEmail");

  if (type) return type;

  return null;
}

export function setUserId(type) {
  localStorage.setItem("userId", type);
}

export function setUserName(type) {
  localStorage.setItem("userName", type);
}

export function setUserEmail(type) {
  localStorage.setItem("userEmail", type);
}

export function isValidUser() {
  const type = getUserType();

  if (
    type === "k48s7vc54s" ||
    type === "1b8sujsd87b" ||
    type === "y21s4sehbbw" ||
    type === "8sdj23ybnsh"
  ) {
    return true;
  } else return false;
}

export function clearData() {
  clearToken();
  localStorage.removeItem("8js2jbhca");
  localStorage.removeItem("userName");
}

export function setisExternalCoach(isExternalCoach){
   localStorage.setItem("isExternalCoach",isExternalCoach)
}

export function getIsExternalCoach(){
   return localStorage.getItem("isExternalCoach")
}
