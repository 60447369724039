const ITEM_TYPE_OPTIONS = [
  {
    key: "All Orders",
    value: "All Orders",
    text: "All Orders",
    id: 1,
  },
  {
    key: "Blood_Package",
    value: "Blood_Package",
    text: "Blood Package",
    id: 2,
  },
  {
    key: "Bundle",
    value: "Bundle",
    text: "Bundle",
    id: 3,
  },
  {
    key: "Coach_Package",
    value: "Coach_Package",
    text: "Coach Package",
    id: 4,
  },
  {
    key: "Coach_Consultation_Package",
    value: "Coach_Consultation_Package",
    text: "Coach Consultation Package",
    id: 5,
  },
  {
    key: "Custom_Package",
    value: "Custom_Package",
    text: "Custom Package",
    id: 6,
  },
  {
    key: "Medicine",
    value: "Medicine",
    text: "Medicine",
    id: 7,
  },
  {
    key: "Mini_Package",
    value: "Mini_Package",
    text: "Mini Package",
    id: 8,
  },
  {
    key: "Supplement",
    value: "Supplement",
    text: "Supplement",
    id: 9,
  },
];

export default ITEM_TYPE_OPTIONS;
