import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";

const MAX_POINTS_ALLOWED = 10;

class PointsForm extends Component {
  constructor(props) {
    super(props);

    const { points = [], onPointsUpdated = (f) => f } = this.props;

    this.onPointsUpdated = onPointsUpdated;

    this.state = {
      points,
      point: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Points",
        selector: "text_points",
        sortable: true,
        wrap: true,
      },
      {
        name: "Icon",
        selector: "image",
        sortable: true,
        wrap: true,
        maxWidth: "150px",
        cell: (point) => this.renderPointIcon(point),
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (point) => this.renderActionBar(point),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (input) => (input.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      point: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const point = { ...this.state.point };
    point[input.name] = input.value;
    this.setState({ point });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { points = [] } = this.state;
    const point = points.find((it) => it.id === id);
    if (!point) return;

    if (point.image && typeof point.image === "string")
      point.image_src = point.image;
    else this.readImage(point.image);

    this.setState({ point });
  };

  handleDelete = (id) => this.deletePoint(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { point } = this.state;
    if (point.id === null || point.id === undefined) this.createPoint();
    else this.updatePoint(point.id);
  };

  validate = () => {
    const errors = {};
    const { point = {} } = this.state;
    if (!point.text_points || point.text_points.trim() === 0)
      errors.text_points = "Points (English) is a required field!";
    else if (point.text_points.length > 200)
      errors.text_points =
        "Points (English) can not contain more than 200 characters!";

    if (!point.text_points_arabic || point.text_points_arabic.trim() === 0)
      errors.text_points_arabic = "Points (Arabic) is a required field!";
    else if (point.text_points_arabic.length > 200)
      errors.text_points_arabic =
        "Points (Arabic) can not contain more than 200 characters!";

    if (!point.image) errors.image = "Points icon is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createPoint = () => {
    const { points = [], point } = this.state;
    const ids = points.length > 0 ? points.map((it) => it.id) : null;
    point.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      points.length > 0
        ? points.filter((it) => it.rank).map((it) => it.rank)
        : null;
    const rank = ranks ? Math.max(...ranks) + 1 : 1;
    point.rank = rank;
    point.is_new = true;

    this.setState({ points: [...points, point] }, () => {
      this.showSuccessNotification("Point Created Successfully!");
      this.onPointsUpdatedLocally();
    });
  };

  updatePoint = (id) => {
    const { points = [], point } = this.state;
    const newPoints = points.map((it) => (it.id === id ? point : it));
    this.setState({ points: newPoints }, () => {
      this.showSuccessNotification("Point Updated Successfully!");
      this.onPointsUpdatedLocally();
    });
  };

  deletePoint = (id) => {
    const { points = [] } = this.state;
    const pointToDelete = points.find((it) => it.id === id) || {};
    const newPoints = points
      .filter((p) => p.id !== id)
      .map((it) =>
        it.rank > pointToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ points: newPoints }, () => {
      this.showSuccessNotification("Point Deleted Successfully!");
      this.onPointsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { points = [], sortColumn, sortDirection } = this.state;
    const sortedPoints = _.sortBy(points, sortColumn, sortDirection);
    const fromPoint = { ...sortedPoints[fromIndex] };
    const toPoint = { ...sortedPoints[toIndex] };

    if (fromPoint && toPoint) {
      const tempRank = fromPoint.rank;
      fromPoint.rank = toPoint.rank;
      toPoint.rank = tempRank;

      const newPoints = points.map((it) => {
        if (it.id === fromPoint.id) return fromPoint;
        else if (it.id === toPoint.id) return toPoint;
        else return it;
      });
      this.setState({ points: newPoints }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onPointsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onPointsUpdatedLocally = () => {
    this.clearForm();
    this.onPointsUpdated(
      this.state.points.map((point) => {
        const p = { ...point };
        if (p.is_new) {
          delete p.id;
          delete p.is_new;
        }
        delete p.image_src;
        return p;
      })
    );
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const point = { ...this.state.point, image };
      this.setState({ point });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const point = { ...this.state.point, image_src: [reader.result] };
      this.setState({ point });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  renderPointIcon = (point) => (
    <img
      src={typeof point.image === "string" ? point.image : point.image_src}
      className="form-image"
      alt="Points Icon"
      // width={100}
      height={50}
    />
  );

  renderActionBar = (point) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(point.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleDelete(point.id)}>
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => 
    this.props.isEditable && this.state.point.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      points = [],
      point = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewPoint = point.id === null || point.id === undefined;
    const isEditable = this.props.isEditable;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="text_points">Points (English)</label>
            <div className="input-group">
              <textarea
                value={point.text_points}
                onChange={this.handleChange}
                id="text_points"
                name="text_points"
                placeholder="Points in English"
                readOnly={!isNewPoint && !editables.text_points}
                className={
                  "form-control py-2" + (errors.text_points ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("text_points")}
            </div>
            <span className="help-block">{errors.text_points}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="text_points_arabic">Points (Arabic)</label>
            <div className="input-group">
              <textarea
                value={point.text_points_arabic}
                onChange={this.handleChange}
                id="text_points_arabic"
                name="text_points_arabic"
                placeholder="Points in Arabic"
                readOnly={!isNewPoint && !editables.text_points_arabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.text_points_arabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("text_points_arabic")}
            </div>
            <span className="help-block">{errors.text_points_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Points Icon</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewPoint && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {point.image_src && (
              <>
                <label>Selected Points Icon</label>
                <div className="form-image-container">
                  <img
                    src={point.image_src}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row mt-2">
          {point.id && (
            <div className="form-group col-2 mr-4">
              <button
                onClick={this.clearForm}
                disabled={!point.id}
                className="btn btn-lg btn-outline-secondary px-5"
                style={{ color: "black" }}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="form-group col-2">
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-outline-secondary px-5"
              style={{ backgroundColor: "#8FD14F", color: "black" }}
              disabled={!isEditable || (isNewPoint && points.length >= MAX_POINTS_ALLOWED)}
            >
              {point.id ? "Update" : "Add"}
            </button>
          </div>
        </div>
        {isNewPoint && points.length >= MAX_POINTS_ALLOWED && (
          <div className="row mx-auto">
            <p style={{ fontWeight: "bolder" }}>
              You can add only {MAX_POINTS_ALLOWED} points.
            </p>
          </div>
        )}
      </>
    );

    return (
      <div className="container mt-4">
        {form}
        <div className="row">
          <div className="form-group col mt-4">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={points || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

PointsForm.propTypes = {
  points: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPointsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

PointsForm.defaultProps = {
  points: [],
  onPointsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PointsForm);
