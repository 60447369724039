import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_FAMILY_MEMBERS="/multiple-profile/user-id"

export const fetchFamilyMembers=async(userId)=>{
  try{
   const {data:familyMembers=[]}= await PhoenixAPI.get(`${BASE_URL_FAMILY_MEMBERS}/${userId}`);
   return familyMembers
  }
  catch(error){
   console.log("Error in fetching family members", error.message)
  }
}

const FamilyMembersService={
    fetchFamilyMembers
}
export default FamilyMembersService