import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import GoogleMapForm from "./GoogleMapForm";
import { Tab, Tabs } from "react-bootstrap";
import ClientLabDetails from "./ClientLabDetails";
import UploadSampleDetails from "./UploadSampleDetails";
import "./ClientOrderDetails.scss";
import NurseModal from "./NurseModal";
import PhoenixAPI from "utils/PhoenixAPI";
import { bookingStatus, findBookingStatusByValue } from "./bookingStatus";
import { fetchBookings } from "./Services";
import moment from "moment";
import color from "@material-ui/core/colors/amber";
import Notification from "./Notification";
import API from "utils/API";
import { getUserId } from "services/UserService";
export default function ClientOrderDetails(props) {
  const {
    item,
    setIsShowClient,
    setIsShowOrder,
    professionals,
    user,
    orders,
    selectedStartDate,
    setOrders,
    selectedEndDate,
  } = props;
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedProfessional, setSelectedProfessional] = useState();
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
 


 
  const getCoordinates = (booking) => {
    let { lat, lng, map_url } = booking ?? {};

    if (!lat || !lng) {
      // If latitude or longitude are not present then try to extract them from map_url.
      if (map_url) {
        const match = map_url.match(/(?:q=|@)(-?\d+\.\d+),(-?\d+\.\d+)/);

        if (match) {
          lat = Number(match[1]);
          lng = Number(match[2]);
          console.log("getCoordinates: Extracted coordinates: ", { lat, lng });
        } else {
          console.warn(
            "getCoordinates: Failed to extract coordinates from:",
            map_url
          );
        }
      } else {
        console.warn(
          "getCoordinates: No coordinates or map_url provided:",
          booking
        );
      }
    }

    return { lat: lat || null, lng: lng || null };
  };

  const handleBackButton = (data) => {
    if (data === "clientLabDetails") {
      setIsShowClient(false);
      // setIsShowUploadSample(false);
      setIsShowOrder(true);
    }
  };

  const handleAssignProfessional = async (payload) => {
    

    if (!user) {
      console.log("handleAssignProfessional:: Invalid user or bookings!");
      // this.showErrorNotification("Invalid user or bookings!");
      return;
    }
    if (isError) {
      setMessage("Invalid Fields");
    } else {
      setMessage("");
      setIsError(false);

      const { id, homecare_service_id } = user?.user;
      

      const booking = orders?.find((it) => it.id === payload.orderId);
      payload.userId = id;
      payload.homeCareId = homecare_service_id;
      payload.nurseAppointmentStatus = bookingStatus.NURSE_APPOINTED.value;
      try {
        // showLoading();
        if (booking.professional_id) {
          await PhoenixAPI.patch("/assign-nurse", payload);
        } else {
          await PhoenixAPI.post("/assign-nurse", payload);
        }
        const ordersAssign = await fetchBookings(
          selectedStartDate,
          selectedEndDate,
          homecare_service_id
        );
        
        setOrders([...ordersAssign]);

        //   this.showSuccessNotification("Assigned successfully.");
        setMessage("assigned successfully");

        setIsShowClient(false);

        setIsShowOrder(true);

        hideModal();
      } catch (error) {
        //this.showErrorNotification("Some error occurred: " + error.message);
      } finally {
        // hideLoading();
      }
    }
  };
  const hideModal = () => {
    setIsShowModal(false);
  };

  const handleOnCopyLocation = (mapUrl) => {
    if (mapUrl) {
      navigator.clipboard.writeText(mapUrl).then(
        () => setMessage("Copied to clipboard."),
        () => setMessage("Failed to copy!")
      );
    } else {
      setMessage("Invalid google map url!");
    }
  };

  const handleOnGoogleMaps = (mapUrl) => {
    if (mapUrl) {
      window.open(mapUrl, "_blank", "noreferrer");
    } else {
      setIsError(true);
      setMessage("Invalid google map url!");
    }
  };

  const getFullAddress = (order) => {
    if (!order) return "";

    const { address_line1, address_line2, building_apt, city, country } = order;
    const location = [address_line1, address_line2, building_apt, city, country]
      .filter((it) => it ?? false)
      .map((line, index, array) => (
        <div key={index}>
          {line} {index < array.length - 1 ? "," : ""}
        </div>
      ));

    return location;
  };

  const [appointmentDate, appointmentTime] =
    item && item.confirmed_date_time
      ? item.confirmed_date_time.split(" ")
      : [null, null];
  const timeAndLocationForm = (
    <>
      <div className="client-lab-details">
        <div className="client-details-div">
          <div className="client-details">
            <div className="row-details">
              <div className="headings">
                <div className="heading-txt">Appointment Date</div>
                <div className="heading-value">
                  {" "}
                  {moment(appointmentDate).format("DD/MM/YYYY") ?? ""}
                </div>
              </div>
              <div className="headings">
                <div className="heading-txt">Appointment Time</div>
                <div className="heading-value">{appointmentTime ?? ""}</div>
              </div>
            </div>
            {
              <GoogleMapForm
                mapUrl={item?.map_url}
                coordinates={getCoordinates(item)}
                onCopyLocation={handleOnCopyLocation}
                onGoogleMaps={handleOnGoogleMaps}
              />
            }
            <div className="row-details">
              <div className="headings">
                <div className="heading-txt">Full Address</div>
                <div className="heading-value">
                  {getFullAddress(item) ?? ""}
                </div>
              </div>
            </div>
            {item.nurse_appointment_status !== "Nurse Appointed" &&
            item.nurse_appointment_status !== "Completed" &&
            item.nurse_appointment_status == null ? (
              <div className="assign-nurse-client-div"
            
                onClick={() => setIsShowModal(true)}
              >
                Assign Nurse
              </div>
            ) : (
              <></>
            )}
            {isShowModal && (
              <NurseModal
                orderId={item.id}
                nurseData={item}
                setIsShowModal={setIsShowModal}
                professionals={professionals}
                onSave={handleAssignProfessional}
                nurseModalText={"assign"}
                setIsShowClient={setIsShowClient}
                setMessage={setMessage}
                setIsError={setIsError}
              />
            )}
            {message && (
              <Notification
                setMessage={setMessage}
                message={message}
                isError={isError}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );

  const clientLabDetailsForm = (
    <>
      {" "}
      {item && Object.keys(item).length > 0 ? (
        <ClientLabDetails
          clientDetails={item}
          professionals={professionals}
          setIsShowOrder={setIsShowOrder}
          setIsShowClient={setIsShowOrder}
          user={user}
          orders={orders}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setOrders={setOrders}
        />
      ) : (
        <></>
      )}
    </>
  );
  const DropoffInfoForm = (
    <>
      {item && Object.keys(item).length > 0 ? (
        <UploadSampleDetails
          clientDetails={item}
          setIsShowOrder={setIsShowOrder}
          setIsShowClient={setIsShowOrder}
          user={user}
          setOrders={setOrders}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      ) : (
        <></>
      )}
    </>
  );
 

  return (
    <>
      <div className="clients-page">
        <div className="top-header">
          <div className="header-content">
            <div
              className="content"
              onClick={() => handleBackButton("clientLabDetails")}
            >
              <div className="lt">&lt;</div>
              <div className="header-title">
                {" "}
                Client Name : {item && item?.user_name} <br /> 
                (ID: {item && item?.user_id})
              </div>
            </div>
          </div>
        </div>
        {item && Object.keys(item).length > 0 && (
          <div
            className="client-tabs"
            style={{
              paddingTop: "16px",
              boxShadow: "0px 4px 4px 0px #E0E4EE80",
              width: "100%",
            }}
          >
            <Tabs
              id="client_details"
              defaultActiveKey="map_form"
              style={{
                width: "90%",
                flex: "1",
                backgroundColor: "#ffffff",
                border: "8px",
                padding: "0px",
                display: "flex",
                margin: "0 auto",
                justifyContent: "space-between",
              }}
            >
              <Tab
                style={{ flex: "1 !important" }}
                eventKey="map_form"
                title={<div style={{ fontSize: "12px" }}>Time & Location</div>}
              >
                {timeAndLocationForm}
              </Tab>
              <Tab
                style={{ flex: "1 !important" }}
                eventKey="client_lab"
                title={<div style={{ fontSize: "12px" }}>Client & Lab</div>}
              >
                {clientLabDetailsForm}
              </Tab>
              {(item?.is_require_sample_collection??false) || (item?.is_require_sample_collection==null)?
                    <Tab
                style={{ flex: "1 !important" }}
                eventKey="upload_info"
                title={<div style={{ fontSize: "12px" }}>Drop Off Info</div>}
              >
                {DropoffInfoForm}
              </Tab>:<></>}
            </Tabs>
          </div>
        )}
        
      </div>
    </>
  );
}
