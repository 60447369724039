import React ,{Component}from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";

import {fetchNewLongevityCategories} from "services/LongevityService";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class SuggestedArticles extends Component {

    constructor(props) {
        super(props);
        const { suggestedDefaultArticles,isEditable,heading,country,articleOptions} = this.props;
         
        console.log("country",this.props.country);
        this.state = {
            suggestedDefaultArticles,
            suggestedDefaultArticle: {},
            articleOptions,
            errors: {},
            editables: {},
            isArticleViewModal: false,
            articles:[],
            isEditable,
            heading,
            country,

        };
    
        this.columns = [
            {
              name: "ID",
              selector: "id",
              sortable: true,
              width: "50px",
              maxWidth: "100px",
            },
            {
              name: "Suggested Article Name",
              selector: "articleName",
              sortable: true,
              wrap: true,
              format: (defaultPackage) => (
                <div className="text-overflow-ellipsis">
                  {defaultPackage.articleName ?? ""}
                </div>
              ),
            },
            {
                name: "Status",
                selector: "status",
                sortable: true,
                center: true,
                maxWidth: "150px",
                cell: (defaultPackage) => <Status isActive={defaultPackage.isActive ?? false} />,
              },
              {
                name: "Edit",
                center: true,
                cell: (defaultPackage) => this.renderEditButton(defaultPackage.id),
              }
        
        
        ]

    }

    showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });


  validate=()=>{
    const { suggestedDefaultArticle } = this.state;
    const errors = {};
    if (!suggestedDefaultArticle.articleId) {
      errors.articleSuggested = "SuggestedArticle is Required Field ";
    }
   

    return Object.keys(errors).length == 0? null : errors;
  }
    clearForm = () => {
        this.setState({
          errors: {},
          editables: {},
          suggestedDefaultArticle: {},
        });
      };
      showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
    componentDidUpdate(prevProps) {
      if (this.props.suggestedDefaultArticles !== prevProps.suggestedDefaultArticles) {
        this.setState({ suggestedDefaultArticles: this.props.suggestedDefaultArticles });
      }
      if(this.props.country !== prevProps.country){
        this.setState({country:this.props.country});
      }
      if(this.props.articleOptions !== prevProps.articleOptions){
        this.setState({articleOptions:this.props.articleOptions});
      }

    }


      setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
      };
      
     

      
  

      handleAddNewArticle = () => {
        const { suggestedDefaultArticles,articleOptions,articles } = this.state;
        console.log("suggested default articles",suggestedDefaultArticles,articleOptions);
        const filteredArticles = articleOptions.filter(article => !suggestedDefaultArticles.some(suggestedArticle => suggestedArticle.articleId === article.value));
        
        this.setState({ isNewDefaultArticle: true,articles:filteredArticles }, () => this.showArticleViewModal());
      };
      handleViewArticle= (packageId) => {
        const { suggestedDefaultArticles,articleOptions,articles } = this.state;
        const defaultArticles = this.state.suggestedDefaultArticles ?? [];
        const suggestedDefaultArticle= defaultArticles.find((it) => it.id === packageId);
        const filterSuggestedArticles=suggestedDefaultArticles.filter((it) => it.id !==packageId)
        const filteredArticles = articleOptions.filter(article => !filterSuggestedArticles.some(suggestedArticle => suggestedArticle.articleId === article.value));
        
        console.log("suggested Default", suggestedDefaultArticle);

        if (!suggestedDefaultArticle) {
          console.log(`Article with id ${packageId} is not found!`);
          return;
        }
       

        this.setState(
          {
            suggestedDefaultArticle,
            isNewDefaultArticle: false,
            articles:filteredArticles
          },
          () => this.showArticleViewModal()
        );
      };

      renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewDefaultArticle ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    renderEditButton = (id) => (
      <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => {
            this.handleViewArticle(id);
          }}
        >
          Edit
        </button>
      </div>
    );
    
      showArticleViewModal = () => {
        const {country, isNewDefaultArticle } = this.state;
        if (isNewDefaultArticle) {
          const suggestedDefaultArticle= {
            countryId: country,
            isActive: false,
          };
          this.setState({
            suggestedDefaultArticle,
            isArticleViewModal: true,
          });
        } else {
          this.setState({ isArticleViewModal: true });
        }
      };

      createSuggestedDefaultArticle=async(suggestedDefaultArticle)=>{
       const{heading,country}=this.state;
       let postUrl="";
       if(heading=="Food"){
        postUrl="longevity-food-plan-articles";
        
       }
       else if(heading=="Fitness"){
        postUrl="longevity-fitness-plan-articles";

       }
       else if(heading=="Supplement"){
        postUrl="longevity-supplement-plan-articles";
       }
        else{
          postUrl="longevity-sleep-plan-articles"
        } 
        const isValidId = suggestedDefaultArticle.id !== null && suggestedDefaultArticle.id !== undefined;
        
        console.log("article",suggestedDefaultArticle);
        isValidId? await PhoenixAPI.patch(`${postUrl}/${suggestedDefaultArticle.id}`, suggestedDefaultArticle)
        : await PhoenixAPI.post(postUrl, this.state.suggestedDefaultArticle);
        this.fetchLongevityCategories();
      }
    
      updateSuggestedDefaultArticle=(suggestedDefaultArticle)=>{
    
        const postUrl= `longevity-default-custom-package-suggestions/${suggestedDefaultArticle.id}}`;
        PhoenixAPI.patch(postUrl, {suggestedDefaultArticle});
        this.fetchLongevityCategories();
      }
    

      
  
    
      fetchLongevityCategories = async () => {
        const{heading}=this.state;
        this.showLoading();
        try {
          const categories = (await fetchNewLongevityCategories()) || [];
         
         
          this.setState({ 
           suggestedDefaultArticles:heading=="Food"?categories.foodPlans:heading=="Fitness"?categories.fitnessPlans:heading=="Sleep"?categories.sleepPlans:categories.supplementPlans,
           });
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching Longevity Categories:",
            error.message
          );
          this.setState({ loadError: "Some Error has occured. Please try again" });
        } finally {
          this.hideLoading();
        }
      };
      handleSaveDefaultArticle = async () => {
        const {  suggestedDefaultArticle} = this.state;

        try {
          this.showLoading();
          const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
          const isValidId = suggestedDefaultArticle.id !== null && suggestedDefaultArticle.id !== undefined;
          const response =isValidId
            ? await this.createSuggestedDefaultArticle(suggestedDefaultArticle)
            : await this.createSuggestedDefaultArticle(suggestedDefaultArticle);
          this.hideArticleViewModal();
          this.showSuccessNotification(
            isValidId
              ? " Updated Successfully."
              : " Created Successfully."
          );
          this.fetchLongevityCategories();
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching Longevity Categories:",
            error.message
          );
        } finally {
          this.hideLoading();
        }
      };
    
      resetForm = () => {
        this.setState({
          suggestedDefaultArticle: {},
          errors: {},
          editables: {},
        });
      };
    
      
    
      hideArticleViewModal = () => {
        this.setState({ isArticleViewModal: false });
        this.resetForm();
      };
    
      handleChange = ({ currentTarget: input }) => {
        const suggestedDefaultArticle = { ...this.state.suggestedDefaultArticle};
        suggestedDefaultArticle[input.name] = input.value;
        this.setState({ suggestedDefaultArticle });
      };

      
      getFilteredSuggestedPackages=()=>{
        const{suggestedDefaultArticles,country } = this.state;
        console.log("getfiltered",suggestedDefaultArticles,country);
        return suggestedDefaultArticles 
         ? suggestedDefaultArticles.filter((suggestedPackage) => suggestedPackage.countryId === country)
         : [];
         
       }
      

      render() {
    const{suggestedDefaultArticles,suggestedDefaultArticle={},articleOptions,articles,heading,isEditable,isNewDefaultArticle,wellbeingCoaches,errors,editables,customPackages}=this.state;
     console.log("default package",articleOptions,suggestedDefaultArticles);
      const filteredSuggested=this.getFilteredSuggestedPackages();
      console.log("suggested",filteredSuggested);
    const packageForm=(<>
     <div className="container mt-4">
    <div className="row">
      <div className="form-group col-6">
        <label htmlFor="default package">suggested Article</label>
        <div className="input-group">
         <Dropdown 
         value={ suggestedDefaultArticle.articleId||null}
         onChange={(event, value) => {
           console.log("value",value);
            const suggestedDefaultArticle = { ...this.state.suggestedDefaultArticle };
            suggestedDefaultArticle.articleId= value.value;
            
            this.setState({ suggestedDefaultArticle});
            }
           
         }
         id={`articleSuggested`}
         name="articleSuggested"
         placeholder="None"
         search
         selection
         options={articles}
         disabled={!isNewDefaultArticle && !editables.articleSuggested}
         className={
           "form-control" + (errors.articleSuggested ? " has-error" : "")
         }
         
         />
        {this.renderPencilIcon("articleSuggested")}
        </div>
         
        <span className="help-block">{errors.articleSuggested}</span>
  
    </div>
    <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={suggestedDefaultArticle.isActive}
                onChange={(event, value) => {
                  this.setState({
                    suggestedDefaultArticle: {
                      ...suggestedDefaultArticle,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewDefaultArticle && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>


       
    </div>
        
    </div>     
      
      
                  
         
    </>

    );

        return(
            <div>
              <div className="page-header">
          <div className="actions">
              <div className="action-item">
              <button
                onClick={this.handleAddNewArticle}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Article
              </button>
            </div>
            </div>
            </div>
                <Card>
                    <Card.Header>Suggested {heading} Plan Articles</Card.Header>
                    <Card.Body>
                    <DataTable
                highlightOnHover
                columns={this.columns}
                data={filteredSuggested || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }/>
                 
                    </Card.Body>
                </Card>
                <Modal
          size="lg"
          show={this.state.isArticleViewModal}
          onHide={this.hideArticleViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  { suggestedDefaultArticle.id ? "Edit Parent Category" : "Add Parent Category"}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveDefaultArticle}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{packageForm}</Modal.Body>
        </Modal>

            </div>
        )
    }
}
      SuggestedArticles.propTypes = {
        suggestedDefaultArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
        
        isEditable: PropTypes.bool.isRequired,
      };
      
      SuggestedArticles.defaultProps = {
        suggestedDefaultArticles: [],
        
        isEditable: true,
      };
      
      function mapStateToProps(state) {
        return {};
      }
      
      function mapDispatchToProps(dispatch) {
        return {
          showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
          toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
        };
      }
      
      export default connect(mapStateToProps, mapDispatchToProps)(SuggestedArticles);
      



