import React from "react";
import { connect } from "react-redux";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import moment from "moment";
import _ from "lodash";
import RangeVertical from "./RangeVertical";
import API from "utils/API";

const URL = "biomarker-result-history/";

class TrendingAnalysisGraph extends React.Component {
  constructor(props) {
    super(props);

    const { testResult } = this.props;
    const biomarkerStatusList =
      [...testResult?.biomarkers_status?.biomarker_status] ?? [];
    const biomarkerRanges = new Set();
    biomarkerStatusList.forEach((it) => {
      biomarkerRanges.add(it.range_start);
      biomarkerRanges.add(it.range_end);
    });

    this.state = {
      biomarkerStatusList,
      biomarkerRanges,
      testResults: [],
      graphData: [],
    };
  }

  componentDidMount() {
    this.fetchTestResultHistory();
  }

  fetchTestResultHistory = async () => {
    const { testResult, userDetails } = this.props;
    if (!testResult || !userDetails) return;
    try {
      let { data: testResults } = await API.get(URL, {
        params: { biomarker: testResult?.id, user: userDetails.id },
      });
      testResults = testResults && testResults.length ? testResults : [];
      const graphData = testResults.map((it) => ({
        id: it.id,
        x: moment(it.result_date),
        y: parseFloat(it.result),
      }));
      this.setState({ testResults, graphData }, () => {
        this.getDotColors();
      });
    } catch (error) {
      console.log("Error on fetching test details", error);
    }
  };

  getDotColors() {
    const { biomarkerStatusList = [], graphData = [] } = this.state;
    const sortedBiomarkerStatusList = _.sortBy(
      biomarkerStatusList,
      "range_start"
    );
    const maxEndRange = Math.max(
      ...sortedBiomarkerStatusList.map((it) => it.range_end)
    );
    const maxEndRangeIndex = sortedBiomarkerStatusList.findIndex(
      (x) => x.range_end === maxEndRange
    );

    const colorCodes = [];
    sortedBiomarkerStatusList.forEach((biomarkerStatus) => {
      const { range_start, range_end, status_color_code } = biomarkerStatus;
      graphData.forEach((data) => {
        if (
          parseFloat(data.y) >= parseFloat(range_start) &&
          parseFloat(data.y) < parseFloat(range_end)
        ) {
          colorCodes.push(status_color_code);
        } else if (
          parseFloat(maxEndRange) === parseFloat(range_end) &&
          parseFloat(data.y) > parseFloat(maxEndRange)
        ) {
          colorCodes.push(
            sortedBiomarkerStatusList[maxEndRangeIndex].status_color_code
          );
        }
      });
    });

    this.setState({ dotColors: colorCodes.reverse() });
  }

  rangeOfDates = (startDate, endDate, isEndInclusive = true) => {
    if (!startDate || !endDate) return [];

    const result = [];
    const diff = moment(startDate).diff(endDate);
    for (
      let currentDate = moment(startDate);
      isEndInclusive
        ? currentDate.diff(endDate) <= 0
        : currentDate.diff(endDate) < 0;
      diff <= 5
        ? currentDate.add(1, "months")
        : diff <= 12
        ? currentDate.add(3, "months")
        : diff <= 29
        ? currentDate.add(6, "months")
        : currentDate.add(12, "months")
    ) {
      result.push(currentDate.format("YYYY-MM-DD"));
    }
    while (result.length < 5) {
      result.push(
        moment(result[result.length - 1])
          .add(1, "months")
          .format("YYYY-MM-DD")
      );
    }
    return result;
  };

  getUniqueDates = () => {
    const { testResults = [] } = this.state;
    const dates = new Set();
    testResults.forEach((testResult) =>
      dates.add(moment(testResult.result_date))
    );
    return Array.from(dates);
  };

  getXAxisDomain = () => {
    const dates = this.getUniqueDates();
    const minDate = moment(Math.min(...dates));
    const maxDate = moment(Math.max(...dates));
    const minDateString = minDate.set("date", 1).format("YYYY-MM-DD");
    const maxDateString = maxDate.set("date", 1).format("YYYY-MM-DD");
    console.log("minDate: ", minDateString, " and maxDate: ", maxDateString);

    const dateRange = this.rangeOfDates(minDateString, maxDateString);
    console.log("Range of Dates: ", dateRange);
    return dateRange.map((it) => moment(it).toDate());
  };

  getYAxisDomain = () => {
    const percentOf = (number, percent) => number * (percent / 100);
    const getLastValue = (set) => {
      let value;
      for (value of set);
      return value;
    };
    const { biomarkerRanges = [], testResults = [] } = this.state;
    const testResultNumbers = testResults.map((it) => Number(it.result));

    const heighestRange = getLastValue(biomarkerRanges);
    const minTestResult = Math.min(...testResultNumbers);
    const maxTestResult = Math.max(...testResultNumbers);
    const lowestValue = Math.ceil(minTestResult - percentOf(minTestResult, 10));
    const heighestValue =
      heighestRange === null || heighestRange === undefined
        ? Math.ceil(maxTestResult + percentOf(maxTestResult, 10))
        : maxTestResult > heighestRange
        ? maxTestResult
        : Math.ceil(heighestRange + percentOf(heighestRange, 10));

    console.log("getYAxisDomain: ", {
      heighestRange,
      minTestResult,
      maxTestResult,
      lowestValue,
      heighestValue,
    });

    const domain = [...biomarkerRanges];
    domain[0] = lowestValue;
    domain[domain.length - 1] = heighestValue;
    console.log("Domain: ", domain);
    return domain;
  };

  percentOf = (number, percent) => number + number * (percent / 100);

  render() {
    const { testResult } = this.props;
    const {
      testResults = [],
      graphData = [],
      dotColors = [],
      biomarkerStatusList = [],
    } = this.state;

    const biomarkerUnit = testResult?.biomarkers_status?.unit ?? "";
    const sortedGraphData = _.sortBy(graphData, "y");

    const xAxisDomain = this.getXAxisDomain();
    console.log("XAxisDomainMonths: ", xAxisDomain);

    const yAxisDomain = this.getYAxisDomain() ?? [];
    console.log("YAxisDomain", yAxisDomain);

    const graphLine = (
      <VictoryLine
        data={sortedGraphData}
        style={{
          data: { stroke: "#707070" },
          parent: { border: "1px solid #ccc" },
        }}
      />
    );

    const xAxis = (
      <VictoryAxis
        fixLabelOverlap
        tickValues={xAxisDomain ?? []}
        tickFormat={(date) => {
          const d = moment(date);
          return `${d.format("MMM")}\n${d.format("YYYY")}`;
        }}
        style={{
          axis: { stroke: "transparent" },
          grid: {
            stroke: "transparent",
          },
        }}
        offsetX={30}
      />
    );

    const yAxis = (
      <VictoryAxis
        dependentAxis
        domain={yAxisDomain ?? []}
        offsetX={40}
        style={{
          tickLabels: {
            stroke: "#32445B",
            fontSize: 8,
            fontWeight: "bold",
          },
          axis: { stroke: "grey" },
          grid: { stroke: "transparent" },
          border: "2 px solid",
        }}
        axisComponent={
          <RangeVertical biomarkerStatusList={biomarkerStatusList ?? []} />
        }
      />
    );

    const victoryTooltip = (
      <VictoryTooltip
        dy={0}
        constrainToVisibleArea
        centerOffset={{ y: -5 }}
        flyoutStyle={{
          fill: "white",
          strokeWidth: 1,
          strokeFill: "rgba(105, 109, 145, 0.75)",
        }}
      />
    );

    const victoryScatter = (
      <VictoryScatter
        data={sortedGraphData.reverse()}
        labels={({ datum }) => `${datum.y} ${biomarkerUnit}`}
        labelComponent={victoryTooltip}
        size={5}
        active={false}
        style={{
          data: { fill: ({ datum, index }) => dotColors[index] },
          labels: { fontSize: 12, fill: "rgba(105, 109, 145, 0.75)" },
        }}
      />
    );

    const trendingAnalysisGraph = (
      <VictoryChart
        theme={VictoryTheme.material}
        containerComponent={<VictoryVoronoiContainer />}
        height={250}
        style={{ marginTop: "0px", border: "2px solid black" }}
        // animate={{ duration: 1500 }}
      >
        {graphLine}
        {xAxis}
        {yAxis}
        {victoryScatter}
      </VictoryChart>
    );

    return testResults && testResults.length
      ? trendingAnalysisGraph
      : "Biomarker test history not found";
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendingAnalysisGraph);
