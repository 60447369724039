export const bookingStatus = Object.freeze({
  CREATED: { name: "Order Created", value: "Created", position: 1 },
  NURSE_APPOINTED: {
    name: "Assigned to Nurse",
    value: "Nurse Appointed",
    position: 2,
  },
  NURSE_ACKNOWLEDGED: {
    name: "Nurse acknowledgement",
    value: "Nurse Acknowledged",
    position: 3,
  },
  CHANGE_NURSE: {
    name: "Change Nurse",
    value: "Change Nurse",
    position: 4,
  },
  SAMPLE_COLLECTED: {
    name: "Sample Collected",
    value: "Sample Collected",
    position: 5,
  },
  SAMPLE_DELIVERED: {
    // name: "Upload Sample Information", Renamed after Nadin's suggestion.
    name: "Upload Lab Drop-off Information",
    value: "Sample Delivered",
    position: 6,
  },
  COMPLETED: { name: "Order Completed", value: "Completed", position: 7 },
  CANCELLED: { name: "Order Cancelled", value: "CANCELLED", position: 8 },
});

export const bookingStatusList = [
  bookingStatus.CREATED,
  bookingStatus.NURSE_APPOINTED,
  bookingStatus.NURSE_ACKNOWLEDGED,
  bookingStatus.CHANGE_NURSE,
  bookingStatus.SAMPLE_COLLECTED,
  bookingStatus.SAMPLE_DELIVERED,
];

export const bookingStatusUpdatedlist = [
  bookingStatus.CREATED,
  bookingStatus.NURSE_APPOINTED,
  bookingStatus.NURSE_ACKNOWLEDGED,
  bookingStatus.CHANGE_NURSE,
  bookingStatus.SAMPLE_COLLECTED,
  bookingStatus.COMPLETED,
];

export function findBookingStatusByValue(value) {
  if (!value) return null;

  const keys = Object.keys(bookingStatus);
  for (const key of keys) {
    const status = bookingStatus[key];
  	if (value.toLowerCase() === status.value.toLowerCase()) {
      return status;
    }
  }
  return null;
} 