import React, { useEffect, useState } from 'react'
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import PhoenixAPI from "../../../utils/PhoenixAPI";
import PdfPreview from 'components/Common/PdfPreview';
// import Notification from './Notification';
import API from "utils/API";
import { getUserId } from 'services/UserService';
import { useHistory } from "react-router-dom";
import OrderCards from '../OrderCards/OrderCards';
import moment from "moment";
import CustomLoader from 'components/CustomLoader/CustomLoader';
import "./nursepastOrders.scss"
function NursePastOrders({}) {
 
  const [isShowModal, setIsShowModal]=useState(false);
  const [user,setUser]=useState({})
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [searchOrders, setSearchOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState("key_order_yesterday");
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();
 
  const today=moment().format("YYYY-MM-DD");
  const nurseUserId= getUserId()
  const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DD");
  const lastWeek= moment().add(6, 'day').format('YYYY-MM-DD');
  const lastMonth = moment().subtract(1, 'months').format("YYYY-MM-DD");
  
  useEffect(()=>{
    fetchUser();
  
   },[])

  function handleBackButton() {
     return history.goBack();
  }

  useEffect(()=>{
    if(selectedTab){
      fetchOrders("YESTERDAY", today)
    }
  },[])


 const fetchUser = async () => {
  try {
    const response = await API.get("/users/");
    const { data: users = [] } = response;
    
    setUser({ user: users && users.length ? users[0] : null });
  } catch (error) {
    console.log("fetchUser:: Error on fetching users!", error);
    //  showErrorNotification("Error on fetching user!" + error.message);
  } finally {
  }
};

const fetchOrders=async(dayFormat,today)=>{
  let url="";
  if(dayFormat=="ALL"){
    url=`nurse/new-orders/${nurseUserId}`   
  }
  else{
    url=`nurse/new-orders/${nurseUserId}?relativeDate=${dayFormat}&currentDateString=${today}` 
  }

  try{
    setIsLoading(true)
    const { data: orders = [] } =await PhoenixAPI.get(url);
    setOrders(orders);
  }
  catch(error){
    console.log("Error occurred in fetcching orders", error.message)
  }
  finally{
   setIsLoading(false)
  }
}

 const OrdersForm = () => {
  const TodayOrdersForm = () => {
   
    return (
      <div>
       {" "}
        {Object.keys(user).length > 0 &&
       
          selectedTab == "key_order_yesterday" ? (
          <>
            <OrderCards
            
             
    
              
              setSearchOrders={setSearchOrders}
              searchOrders={searchOrders}
              setOrders={setOrders}
              
              orders={orders}
              user={user}
              pastOrder={true}
            />
          </>
        ) : (
          <>"No Orders Available"</>
        )}
      </div>
     
    );
  };

  const TomorrowOrderForm = () => {
    
    return (
      <>
        {" "}
        {Object.keys(user).length > 0 &&
          selectedTab == "key_order_lastweek" ? (
          <OrderCards
            setSearchOrders={setSearchOrders}
            orders={orders}
            searchOrders={searchOrders}
            setOrders={setOrders}
           
            user={user}
            pastOrder={true}
          />
        ) : (
          <>"No Orders Available"</>
        )}
      </>
    );
  };
  const WeekForm = () => {
  
    return (
      <>
        {Object.keys(user).length > 0 &&
        
          selectedTab == "key_order_lastmonth" ? (
          <OrderCards
          
           
            orders={orders}
          
           
            searchOrders={searchOrders}
            setSearchOrders={setSearchOrders}
            setOrders={setOrders}
           
            user={user}
            pastOrder={true}
          />
        ) : (
          <>"No Orders Available"</>
        )}
      </>
    );
  }

  return (
    <>
      {user && Object.keys(user).length > 0 && (
        <div className="nurse-past-orders-form-tabs">
          <Tabs
            id="orders_days"
            defaultActiveKey="key_order_yesterday"
            activeKey={selectedTab}
            style={{
              backgroundColor: "#ffffff",
              width: "90%",
              display: "flex",
              padding: "0px",
              width: "90%",
              margin: "0 auto",
              justifyContent: "space-between",
             
            }}
            onSelect={handleTabSelect}
          >
            <Tab
              eventKey="key_order_yesterday"
              title={<div className="book-all-tabs">Yesterday</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_yesterday" ? (
                <TodayOrdersForm
               
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              style={{ fontSize: "12px" }}
              eventKey="key_order_lastweek"
              title={<div className="book-all-tabs">Last Week</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_lastweek" ? (
                <TomorrowOrderForm
                 
                />
              ) : (
                <></>
              )}
            </Tab>
            <Tab
              eventKey="key_order_lastmonth"
              title={<div className="book-all-tabs">Last Month</div>}
            >
              {Object.keys(user).length > 0 &&
                selectedTab == "key_order_lastmonth" ? (
                <WeekForm
                
                />
              ) : (
                <></>
              )}
            </Tab>
          </Tabs>
        </div>
      )}
    </>
  );

};

const handleTabSelect=(key)=>{
  if (key == "key_order_yesterday") {

    fetchOrders("YESTERDAY", today)
   
  }
else  if(key=="key_order_lastweek"){
  
  fetchOrders("LAST WEEK", today)
   
    }

  else  if(key=="key_order_lastmonth"){
     
    fetchOrders("LAST MONTH", today)
      }
     
  setSelectedTab(key)
}


  return (
    <>
    <div className='nurse-past-details'>
      <div className='top-header'>
        <div className='header-content'>
            <div className='content' onClick={() => handleBackButton()}>
            <div className='lt' >&lt;</div>
            <div className='header-title'>Past Orders</div>
          </div>
        </div>
      </div>
      <div><OrdersForm/></div>
      
    </div>
      {/* {message && <Notification setMessage={setMessage} message={message} isError={isError}  />} */}
      <CustomLoader show={isLoading}/>
    </>
  )
}

export default NursePastOrders
