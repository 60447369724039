import React, { Component } from "react";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import { getUserId } from "../../services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
import { getClientByEmailOnCartOrders } from "services/UserService";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
const BASE_CLIENT_RESET_URL = "nimda-password-reset/";
class ClientPasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      emailResetSearchTerm: null,
      selectedClient: null,
      clientOptions: [],
      resetPassword: null,
      errors: {}
    };
    this.handleChange = this.handleChange.bind(this);



  }

  componentDidMount() {
    this.fetchAclUser();

  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.ClientPasswordReset;;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };
  handleSearchClientsByEmail = () => this.fetchClientUsersByEmail();

  fetchClientUsersByEmail = async () => {
    const { emailResetSearchTerm } = this.state;
    try {
      this.showLoading();
      const clients = await getClientByEmailOnCartOrders(emailResetSearchTerm);
      console.log("CreateOrderViewModal:: fetchClientUsersByEmail", clients);
      this.setState({ clients: clients ?? [] }, () =>
        this.createClientOptions()
      );
    } catch (error) {
      console.log("Error on fetching client users by email:", error);
    } finally {
      this.hideLoading();
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  validateResetForm = () => {
    const { selectedClient, resetPassword } = this.state;
    const errors = {}
    if (!selectedClient) {
      errors.email = "please select email";
    }
    if (!resetPassword) {
      errors.resetPassword = " password is required field";
    }
    else if (!/^[a-zA-Z0-9_@./#&+-]*$/g.test(resetPassword)) {
      errors.resetPassword = "Invalid Password!";
    }
    else if (resetPassword.length < 8) {
      errors.resetPassword = "Invalid Password! (can not be smaller than 8 characters).";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  handleSavePasswordReset = async () => {

    const { selectedClient, resetPassword } = this.state;
    console.log("Selected client and password ", selectedClient, resetPassword);
    const errors = this.validateResetForm();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      await API.patch(`${BASE_CLIENT_RESET_URL}${selectedClient.id}/`, { email: selectedClient.email, password: resetPassword });
      this.hideLoading();
      this.showSuccessNotification("Password Reset Successfully");
      this.hidePasswordResetModal();

    } catch (error) {
      console.log("Error on resetting password", error);
      this.hideLoading();
      this.setState({ error: "Error resetting password, please try again" })
    }
  }

  handlePasswordChange = () => {
    this.showPasswordResetModal();
  }

  showPasswordResetModal = () => {
    console.log("In show resetPassword,emailsearch", this.state.emailResetSearchTerm, this.state.selectedClient, this.state.resetPassword);
    this.setState({ emailResetSearchTerm: null, resetPassword: null, selectedClient: null }, () => this.viewPasswordResetModal())
  }
  viewPasswordResetModal = () => {
    console.log(" In view resetPassword,emailsearch", this.state.emailResetSearchTerm, this.state.selectedClient, this.state.resetPassword);

    const { emailResetSearchTerm, selectedClient, resetPassword } = this.state;
    this.setState({ emailResetSearchTerm, resetPassword, selectedClient, passwordResetModal: true });
  }

  hidePasswordResetModal = () => {

    this.setState({
      emailResetSearchTerm: null,
      selectedClient: null, clientOptions: null,
      resetPassword: null, passwordResetModal: false, errors: {}
    });


  }

  createClientOptions = () => {
    const { clients = [] } = this.state;
    const clientOptions = clients.map(this.createClientOption);
    this.setState({ clientOptions });
  };

  createClientOption = (client) => {
    if (!client) return;
    const { username, first_name, last_name, email } = client;
    const name =
      username || `${first_name || ""} ${last_name || ""}`.trim() || "";
    return {
      key: client.id,
      value: client.id,
      label: `${email} (${name})`,
    };
  };

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  findClient = (clientId) => {
    const { clients = [] } = this.state;
    return clients.find((client) => client.id === clientId);
  };
  handleClientSelected = (selectedClientId) => {
    const selectedClient = this.findClient(selectedClientId);
    console.log("selected client", selectedClient);
    this.setState({ selectedClient });
  }

  render() {
    const { emailResetSearchTerm, selectedClient, resetPassword, clientOptions, passwordResetModal, errors } = this.state;
    const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

    console.log(" In render resetPassword,emailsearch", emailResetSearchTerm, selectedClient, resetPassword);
    const passwordResetForm = (<>
      <div className="row">
        <div className="form-group col">
          <label>Email</label>
          <div className="input-group mb-3">
            <input
              value={emailResetSearchTerm ?? ""}
              onChange={(e) =>
                this.setState({ emailResetSearchTerm: e.target.value })
              }
              type="text"
              id="emailResetSearchTerm"
              name="emailResetSearchTerm"
              className="form-control "
              placeholder="Search Email"
            />
            <div className="input-group-append">
              <i
                className="fa fa-search input-group-text"
                onClick={this.handleSearchClientsByEmail}
              />
            </div>
          </div>
          <span className="help-block">{errors.email}</span>
        </div>
        <div className="form-group col">
          <label>Select Email</label>
          <Select
            key={`select_client_email_${selectedClient?.id ?? -1}`}
            value={this.createClientOption(selectedClient)}
            onChange={(option) => this.handleClientSelected(option.value)}
            options={clientOptions ?? []}
            styles={selectStyles}
            placeholder="Select Email"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label>Password *</label>
          <input
            type="password"
            id="resetPassword"
            name="resetPassword"
            className="form-control "
            placeholder="Enter Password"
            value={resetPassword ?? ""}
            onChange={this.handleChange}
            autocomplete="new-password"
          />
          <span className="help-block">{errors.resetPassword}</span>
        </div>


      </div>


    </>);
    return (
      <div className="manage-package">
        <div> <p> Update Password for Clients </p>
          <div>
            <button
              className="btn px-5"
              style={{ backgroundColor: "#CEE741" }}
              onClick={
                this.handlePasswordChange
              }
            >
              Enter
            </button>
          </div>
        </div>

        <Modal
          size="lg"
          show={passwordResetModal}
          onHide={this.hidePasswordResetModal}
          className="user-data-modal">
          <Modal.Header closeButton>
            <Modal.Title>Password Reset</Modal.Title>
            <button
              onClick={() => { this.handleSavePasswordReset(); }}
              disabled={!this.hasPageAccessRight("edit")}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            {passwordResetForm}
          </Modal.Body>

        </Modal>
      </div>
    )
  }


}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientPasswordReset);