import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { filterList } from "utils/List";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import moment from "moment";

class CRM extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required."
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Email is required."
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Email not Valid."
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "password",
        method: "isLength",
        min: 8,
        validWhen: true,
        message: "At least 8 characters are required."
      },
      {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "confirm_password",
        method: "equals",
        validWhen: true,
        equalsTo: "password",
        message: "Passwords do not match."
      },
    ]);
    this.state = {
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      validation: this.validator.valid(),
      list: [],
      isLoaded: false,
      loadError: "",
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active"
    };

    this.columns = [
      {
        name: "Name",
        selector: "username",
        sortField: 'username',
        sortable: true
      },
      {
        name: "Email",
        selector: "email",
        sortField: 'email',
        sortable: true
      },
      {
        name: "Status",
        center: true,
        sortable: true,
        selector: "is_active",
        sortField: 'is_active',
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-danger">
                <BlockIcon></BlockIcon>Deactivated
              </div>
            )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <BlockIcon></BlockIcon>
                  Deactivate
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                  Activate
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      },
      {
        name: "Joining Date",
        selector: "date_joined",
        sortField: 'date_joined',
        sortable: true,
        format: row => row.date_joined != null ? moment(row.date_joined).format("DD-MM-YYYY, hh:MM A") : '-'
      },
      {
        name: "Last Login",
        selector: "last_login",
        sortField: 'last_login',
        sortable: true,
        format: row => row.last_login != null ? moment(row.last_login).format("DD-MM-YYYY, hh:MM A") : "-"
      }
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.createUser = this.createUser.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.onUserPageChange = this.onUserPageChange.bind(this);
    this.customSort = this.customSort.bind(this);
  }

  componentDidMount() {
    this.loadUsers();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  createUser() {
    const validation = this.validator.validate(this.state);

    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });
      API.post("crm-user/", {
        email: this.state.email,
        password: this.state.password,
        username: this.state.name,
        is_staff: 1
      })
        .then(response => {
          this.clearForm();
          this.loadUsers();
          this.props.showNotificationMessage({
            notificationMessage: "User created successfully.",
            successMessage: true,
            showNotification: true,
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on creating crm user", error);
          let message = "";
          if (error.response.status === 400) {
            message = "Account already exists.";
          } else message = "Some error has occured. Please try again.";

          this.props.showNotificationMessage({
            notificationMessage: message,
            successMessage: false,
            showNotification: true,
            isLoading: false
          });
        });
    }
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      validation: this.validator.valid()
    });
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Deactivate User";
      confirmMessage = "Are you sure you want to deactivate this user?";
      successMessage = "User successfully deactivated.";
      confirmType = "danger";
    } else {
      confirmTitle = "Activate User";
      confirmMessage = "Are you sure you want to activate this user?";
      successMessage = "User successfully activated.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("crm-user/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });

        this.loadUsers();
      })
      .catch(error => {
        console.log("Error on toggling status", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  onUserPageChange() {

    if (this.state.nextURL) {
      let url = this.state.nextURL;
      let nextURL = '';
      let orderResonse = [];

      this.props.toggleLoading({
        isLoading: true,
      });
      API.get(url)
        .then(response => {
          nextURL = response.data && response.data.next ? response.data.next : '';
          orderResonse = response.data && response.data.results ? response.data.results : [];
          const activeList = filterList(orderResonse, "active");
          const deactivatedList = filterList(orderResonse, "archived");

          let allClient = [...this.state.list, ...orderResonse];
          let activeallList = [...this.state.activeList, ...activeList];
          let deactiveallList = [...this.state.deactiveList, ...deactivatedList]
          let totalPage = response.data && response.data.count ? response.data.count : 0;
          this.setState({ list: allClient, nextURL: nextURL, activeList: activeallList, deactivatedList: deactiveallList, isLoaded: true, totalPage: totalPage });
          this.props.toggleLoading({
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on fetching data", error);
          this.setState({
            loadError: "Some error has occured. Please try again"
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });
        });

    }
  }

  loadUsers() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });
    let orderResonse = [];
    let nextURL;

    let url = "crm-user/";
    if (this.state.sortField) {
      let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
      url = url + "?ordering=" + fieldOrder
    }
    if (this.state.searchValue && this.state.sortField) {
      url = url + "&search=" + this.state.searchValue
    }
    if (this.state.searchValue && !this.state.sortField) {
      url = url + "?search=" + this.state.searchValue
    }
    API.get(url)
      .then(response => {
        nextURL = response.data && response.data.next ? response.data.next : '';
        orderResonse = response.data && response.data.results ? response.data.results : [];
        const activeList = filterList(orderResonse, "active");
        const deactivatedList = filterList(orderResonse, "archived");

        let totalPage = response.data && response.data.count ? response.data.count : 0;
        if (this.state.searchApplied) {
          this.setState({
            results: orderResonse,
            resultCount: totalPage,
            nextURL: nextURL,
            totalPage: totalPage,
            activeList,
            deactivatedList,
            isLoaded: true
          });
        } else {
          this.setState({
            list: orderResonse,
            nextURL: nextURL,
            totalPage: totalPage,
            activeList,
            deactivatedList,
            isLoaded: true
          });
        }

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching users", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
          isLoading: false
        });
      });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Deactivated") list = this.state.deactivatedList;
    else list = this.state.list;

    if (!list) return;

    let newList = [];

    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      this.loadUsers();
    });
  }

  customSort(column, sortDirection) {

    let sortField = column.sortField;
    this.setState({ sortField: sortField, sortDirection: sortDirection }, () => {
      this.loadUsers();
    })
  };

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
      searchValue: '',
    }, () => {
      this.loadUsers();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "deactivated") showItems = "Deactivated";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  render() {
    let finalList = [];

    const users = this.state.list;
    const {
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      deactivatedList
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        if (showItems === "Active") finalList = activeList;
        else if (showItems === "Deactivated") finalList = deactivatedList;
        else finalList = users;
      }
    }

    const form = (
      <>
        <div className="form-group mb-4">
          <label>Name *</label>
          <input
            type="text"
            name="name"
            className={
              "form-control " + (validation.name.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.name}
          ></input>
          <span className="help-block">{validation.name.message}</span>
        </div>
        <div className="form-group mb-4">
          <label>Email *</label>
          <input
            type="text"
            name="email"
            className={
              "form-control " + (validation.email.isInvalid && "has-error")
            }
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
          ></input>
          <span className="help-block">{validation.email.message}</span>
        </div>
        <div className="form-group mb-4">
          <label>Password *</label>
          <input
            type="password"
            name="password"
            className={
              "form-control " + (validation.password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="Password"
            onChange={this.handleChange}
            value={this.state.password}
          ></input>
          <span className="help-block">{validation.password.message}</span>
        </div>
        <div className="form-group">
          <label>Confirm Password *</label>
          <input
            type="password"
            name="confirm_password"
            className={
              "form-control " +
              (validation.confirm_password.isInvalid && "has-error")
            }
            autocomplete="new-password"
            placeholder="Confirm password"
            onChange={this.handleChange}
            value={this.state.confirm_password}
          ></input>
          <span className="help-block">
            {validation.confirm_password.message}
          </span>
        </div>
      </>
    );

    return (
      <div className="crm">
        <div className="page-header">
          {/* <h5>CRM Users</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Deactivated" ? "active" : ""}
                    onClick={() => this.setShowItems("deactivated")}
                  >
                    {showItems === "Deactivated" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Deactivated
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>
            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="username"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationTotalRows={this.state.totalPage}
            onSort={this.customSort}
            onChangePage={this.onUserPageChange}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results in '" + showItems + "'"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add CRM User</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.createUser}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Activate
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CRM);
