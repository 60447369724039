import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { paymentStatusList } from "./paymentStatus";

SelectPaymentStatus.propTypes = {
  id: PropTypes.string,
  selectedStatus: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func,
  isDisabled: PropTypes.bool,
};

SelectPaymentStatus.defaultProps = {
  id: "select_payment_status",
  selectedStatus: null,
  className: "",
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectPaymentStatus(props) {
  const { id, selectedStatus, className, onSelect, isDisabled } = props;

  const paymentStatusOptions = paymentStatusList.map((it) => ({
    key: it.value,
    value: it.value,
    text: it.name,
  }));

  return (
    <Dropdown
      id={id}
      value={selectedStatus}
      onChange={(event, value) => onSelect(value.value)}
      placeholder="Payment Status"
      search
      selection
      options={paymentStatusOptions}
      className={className}
      disabled={isDisabled}
    />
  );
}

export default SelectPaymentStatus;
