import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { getUserEmail, getUserName } from "../../services/UserService";
import user_img from "assets/img/head.png";
import FormData from "form-data";
import Rating from "components/Rating/Rating";
import FormValidator from "utils/FormValidator";

class Details extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "fullName",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "specialization",
        method: "isEmpty",
        validWhen: false,
        message: "Specialization number is required.",
      },
      {
        field: "jobTitle",
        method: "isEmpty",
        validWhen: false,
        message: "Job Title is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        message: "Gender is required.",
      },
      {
        field: "phoneNumber",
        method: "isEmpty",
        validWhen: false,
        message: "Phone Number is required.",
      },
      {
        field: "nationality",
        method: "isEmpty",
        validWhen: false,
        message: "Nationality is required.",
      },
      {
        field: "shortBio",
        method: "isEmpty",
        validWhen: false,
        message: "Bio is required.",
      },
      {
        field: "experience",
        method: "isEmpty",
        validWhen: false,
        message: "Experience is required.",
      },
      // {
      //   field: "tests",
      //   type: "array",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Tests are required."
      // }
    ]);

    this.state = {
      fullName: getUserName(),
      phoneNumber: "",
      emailAddress: getUserEmail(),
      specialization: "",
      jobTitle: "",
      nationality: "",
      experience: 0,
      shortBio: "",
      imgSrc: "",
      image: "",
      id: this.props.match.params.profileId
        ? this.props.match.params.profileId
        : "",
      gender: "",
      edit: true,
      avg_rating: "",
      validation: this.validator.valid(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.loadProfile = this.loadProfile.bind(this);
    this.saveProfile = this.saveProfile.bind(this);
    this.submitted = false;
  }

  componentDidMount() {
    // this.loadQuestions();
    this.loadProfile();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  loadProfile() {
    let url = "nutritionist-profile/";
    let edit = true;
    if (this.props.match.params.profileId) {
      url = `nutritionist-profile/${this.props.match.params.profileId}/`;
    }
    API.get(url).then((response) => {
      //prsent
      let result = [];
      if (this.props.match.params.profileId) {
        result = response.data;
        edit = false;
      } else {
        result = response.data[0];
      }
      this.setState({
        fullName: result.username != null ? result.username : "",
        phoneNumber: result.phone_no != null ? result.phone_no : "",
        specialization:
          result.specialization != "" ? result.specialization : "",
        jobTitle: result.job_title != "" ? result.job_title : "",
        nationality: result.nationality != "" ? result.nationality : "",
        experience: result.years_of_exp != null ? result.years_of_exp : "",
        shortBio: result.short_bio != null ? result.short_bio : "",
        imgSrc: result.profile_picture != null ? result.profile_picture : "",
        id: result.id != null ? result.id : "",
        gender: result.gender != null ? result.gender : "",
        edit: edit != null ? edit : "",
        avg_rating:
          result.average_rating != null ? parseFloat(result.average_rating) : 0,
        validation: this.validator.valid(),
        emailAddress: result.email != null ? result.email : getUserEmail(),
      });
    })
    .catch((error) => {
      console.log("Error on fetching Nutritionist Profile", error);
    });
  }

  selectImage(e) {
    e.preventDefault();
    let image = e.target.files[0];
    this.setState({ image });
    let reader = new FileReader();
    // var url = reader.readAsDataURL(image);

    reader.onloadend = () => {
      this.setState({
        imgSrc: reader.result,
      });
    };
    reader.readAsDataURL(image);
  }

  saveProfile(e) {
    e.preventDefault();
    let {
      fullName,
      phoneNumber,
      emailAddress,
      specialization,
      jobTitle,
      nationality,
      experience,
      shortBio,
      image,
      gender,
      id,
    } = this.state;
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      let formdata = new FormData();
      formdata.append("username", fullName);
      formdata.append("job_title", jobTitle);
      formdata.append("phone_no", phoneNumber);
      formdata.append("short_bio", shortBio);
      formdata.append("nationality", nationality);
      formdata.append("years_of_exp", experience);
      formdata.append("specialization", specialization);
      formdata.append("gender", gender);
      if (image.name) {
        formdata.append("profile_picture", image, image.name);
        formdata.append("profile_picture_s3", image, image.name);
      }
      let apiOptions = {
        method: "patch",
        url: `nutritionist-profile/${id}/`,
        data: formdata,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
        },
      };
      API.request(apiOptions)
        .then((response) => {
          this.props.showNotificationMessage({
            notificationMessage: "Saved successfully",
            successMessage: true,
            showNotification: true,
          });
        })
        .catch((err) => {
          console.log("Error on updating nutritionist profile", err);
          this.props.showNotificationMessage({
            notificationMessage: "Error saving",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  handleChange(event) {
    const target = event.target;
    let value = target.value;
    this.setState({ [target.name]: value });
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    const { edit } = this.state;
    return (
      <div className="faq">
        <div className="page-header">
          <h5>
            <button
              onClick={() => this.props.history.goBack()}
              className="btn button btn-sm"
            >
              <ChevronLeft style={{ color: "black" }} />{" "}
            </button>{" "}
            Details
          </h5>
          <div className="actions">
            <div className="action-item">
              {this.state.edit && (
                <button
                  className="btn btn-sm button button-green "
                  onClick={this.saveProfile}
                >
                  SAVE
                </button>
              )}
            </div>
          </div>
        </div>
        <Card>
          <Card.Body>
            <div className="form-row">
              <div className="form-group col-4">
                <div className="img-input-wrapper">
                  {this.state.imgSrc ? (
                    <>
                      <img
                        src={this.state.imgSrc}
                        className="circle-img"
                        alt="selected"
                      />
                    </>
                  ) : (
                    <img src={user_img} className="circle-img" alt="selected" />
                  )}
                  <input type="file" onChange={this.selectImage}></input>
                </div>
              </div>
              <div
                className="form-group col"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h4>{this.state.fullName}</h4>
                <p>
                  {this.state.jobTitle
                    ? this.state.jobTitle
                    : "your job title here"}
                </p>
                <p>{this.state.experience} years experience</p>
                <p>
                  Overall Rating:{" "}
                  <Rating rating={this.state.avg_rating}></Rating>
                </p>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Full name</label>
                <input
                  className={
                    "form-control " +
                    (validation.fullName.isInvalid && "has-error")
                  }
                  disabled={!this.state.edit}
                  name="fullName"
                  onChange={this.handleChange}
                  value={this.state.fullName}
                ></input>
                <span className="help-block">
                  {validation.fullName.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Ph. no.</label>
                <input
                  className={
                    "form-control " +
                    (validation.phoneNumber.isInvalid && "has-error")
                  }
                  disabled={!this.state.edit}
                  type="number"
                  name="phoneNumber"
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                ></input>
                <span className="help-block">
                  {validation.phoneNumber.message}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Email</label>
                <input
                  className="form-control disabled"
                  disabled
                  name="emailAddress"
                  onChange={this.handleChange}
                  value={this.state.emailAddress}
                ></input>
              </div>
              <div className="form-group col">
                <label>Gender</label>
                {this.state.edit ? (
                  <select
                    name="gender"
                    className={
                      "form-control " +
                      (validation.gender.isInvalid && "has-error")
                    }
                    value={this.state.gender}
                    onChange={this.handleChange}
                  >
                    <option defaultValue="" value="" disabled>
                      Select gender
                    </option>
                    <option key={0} value="M">
                      Male
                    </option>
                    <option key={2} value="F">
                      Female
                    </option>
                  </select>
                ) : (
                  <input
                    className="form-control "
                    disabled
                    value={this.state.gender}
                  ></input>
                )}
                <span className="help-block">{validation.gender.message}</span>
              </div>
              <div className="form-group col">
                <label>Nationality</label>
                <input
                  className={
                    "form-control " +
                    (validation.nationality.isInvalid && "has-error")
                  }
                  name="nationality"
                  type="text"
                  disabled={!this.state.edit}
                  onChange={this.handleChange}
                  value={this.state.nationality}
                ></input>
                <span className="help-block">
                  {validation.nationality.message}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Job title</label>
                <input
                  className={
                    "form-control " +
                    (validation.jobTitle.isInvalid && "has-error")
                  }
                  name="jobTitle"
                  disabled={!this.state.edit}
                  onChange={this.handleChange}
                  value={this.state.jobTitle}
                ></input>
                <span className="help-block">
                  {validation.jobTitle.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Specialization</label>
                <input
                  className={
                    "form-control " +
                    (validation.specialization.isInvalid && "has-error")
                  }
                  name="specialization"
                  disabled={!this.state.edit}
                  onChange={this.handleChange}
                  value={this.state.specialization}
                ></input>
                <span className="help-block">
                  {validation.specialization.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Experience (in years)</label>
                <input
                  className={
                    "form-control " +
                    (validation.experience.isInvalid && "has-error")
                  }
                  name="experience"
                  disabled={!this.state.edit}
                  type="number"
                  onChange={this.handleChange}
                  value={this.state.experience}
                ></input>
                <span className="help-block">
                  {validation.experience.message}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Short bio</label>
                <textarea
                  className={
                    "form-control " +
                    (validation.shortBio.isInvalid && "has-error")
                  }
                  type="text"
                  name="shortBio"
                  maxLength="1000"
                  disabled={!this.state.edit}
                  onChange={this.handleChange}
                  value={this.state.shortBio}
                ></textarea>
                <span className="help-block">
                  {validation.shortBio.message}
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
