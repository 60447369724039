import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_BUNDLES = "api/v1/bundles/";
const BASE_URL_BUNDLES_SUMMARY = "api/v1/bundles/summary/";

export const fetchBundles = async () => {
  try {
    const { data: bundles = [] } = await PhoenixAPI.get(BASE_URL_BUNDLES);
    return bundles;
  } catch (error) {
    console.log("Error on fetching bundles", error);
    throw error;
  }
};

export const fetchBundlesSummary = () => {
  return PhoenixAPI.get(BASE_URL_BUNDLES_SUMMARY);
};

export const fetchBundlesSummaryByCountry = (countryId) => {
  if (!countryId) {
    console.log(
      "fetchBundlesSummaryByCountry:: Cannot fetch! Invalid country id",
      { countryId }
    );
    return null;
  }

  return PhoenixAPI.get(BASE_URL_BUNDLES_SUMMARY, {
    params: { country: countryId },
  });
};

export const fetchBundleById = (bundleId) => {
  if (!bundleId) {
    console.log(
      "fetchBundleById:: Cannot fetch! Invalid bundle id",
      { bundleId }
    );
    return null;
  }

  return PhoenixAPI.get(`${BASE_URL_BUNDLES}${bundleId}`);
};

export const saveBundle = async (bundle) => {
  try {
    const payload = { ...bundle };
    delete payload.image;
    delete payload.image_src;

    const keyHighlightImageFiles = getKeyHighlightImageFiles(payload);
    const howItWorksImageEnglishFiles = getHowItWorksImageEnglishFiles(payload);
    const howItWorksImageArabicFiles = getHowItWorksImageArabicFiles(payload);

    const formData = new FormData();
    formData.append("bundle", JSON.stringify(payload));
    formData.append("image", bundle.image);
    keyHighlightImageFiles.forEach((it) =>
      formData.append("keyHighlightImages", it)
    );
    howItWorksImageEnglishFiles.forEach((it) =>
      formData.append("howItWorksImagesEnglish", it)
    );
    howItWorksImageArabicFiles.forEach((it) =>
      formData.append("howItWorksImagesArabic", it)
    );

    const apiOptions = {
      method: "post",
      url: BASE_URL_BUNDLES,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating bundle", error);
    throw error;
  }
};

export const updateBundle = async (bundle) => {
  try {
    const payload = { ...bundle };
    delete payload["image_src"];
    if (typeof bundle.image !== "string") delete payload["image"];

    const keyHighlightImageFiles = getKeyHighlightImageFiles(payload);
    const howItWorksImageEnglishFiles = getHowItWorksImageEnglishFiles(payload);
    const howItWorksImageArabicFiles = getHowItWorksImageArabicFiles(payload);

    const formData = new FormData();
    formData.append("bundle", JSON.stringify(payload));
    if (typeof bundle.image !== "string")
      formData.append("image", bundle.image);

    keyHighlightImageFiles.forEach((it) =>
      formData.append("keyHighlightImages", it)
    );
    howItWorksImageEnglishFiles.forEach((it) =>
      formData.append("howItWorksImagesEnglish", it)
    );
    howItWorksImageArabicFiles.forEach((it) =>
      formData.append("howItWorksImagesArabic", it)
    );

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_BUNDLES}${bundle.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on updating bundle", error);
    throw error;
  }
};

const getKeyHighlightImageFiles = (payload) => {
  const keyHighlightImageFiles = [];
  if (payload.keyHighlights && payload.keyHighlights.length) {
    const newKeyHighlights = payload.keyHighlights.map((it) => {
      const newKeyHighlight = { ...it };
      if (newKeyHighlight.image && typeof newKeyHighlight.image !== "string") {
        keyHighlightImageFiles.push(newKeyHighlight.image);
        newKeyHighlight.image = newKeyHighlight.image.name;
      } else {
        keyHighlightImageFiles.push(null);
      }
      delete newKeyHighlight.image_src;
      return newKeyHighlight;
    });
    payload.keyHighlights = newKeyHighlights;
  }
  return keyHighlightImageFiles;
};

const getHowItWorksImageEnglishFiles = (payload) => {
  const howItWorksImageEnglishFiles = [];
  if (
    payload.howItWorksImagesEnglish &&
    payload.howItWorksImagesEnglish.length
  ) {
    const newHowItWorksImagesEnglish = payload.howItWorksImagesEnglish.map(
      (it) => {
        const newHowItWorksImageEnglish = { ...it };
        if (
          newHowItWorksImageEnglish.image &&
          typeof newHowItWorksImageEnglish.image !== "string"
        ) {
          howItWorksImageEnglishFiles.push(newHowItWorksImageEnglish.image);
          newHowItWorksImageEnglish.image =
            newHowItWorksImageEnglish.image.name;
        } else {
          howItWorksImageEnglishFiles.push(null);
        }
        delete newHowItWorksImageEnglish.image_src;
        return newHowItWorksImageEnglish;
      }
    );
    payload.howItWorksImagesEnglish = newHowItWorksImagesEnglish;
  }
  return howItWorksImageEnglishFiles;
};

const getHowItWorksImageArabicFiles = (payload) => {
  const howItWorksImageArabicFiles = [];
  if (payload.howItWorksImagesArabic && payload.howItWorksImagesArabic.length) {
    const newHowItWorksImagesArabic = payload.howItWorksImagesArabic.map(
      (it) => {
        const newHowItWorksImageArabic = { ...it };
        if (
          newHowItWorksImageArabic.image &&
          typeof newHowItWorksImageArabic.image !== "string"
        ) {
          howItWorksImageArabicFiles.push(newHowItWorksImageArabic.image);
          newHowItWorksImageArabic.image = newHowItWorksImageArabic.image.name;
        } else {
          howItWorksImageArabicFiles.push(null);
        }
        delete newHowItWorksImageArabic.image_src;
        return newHowItWorksImageArabic;
      }
    );
    payload.howItWorksImagesArabic = newHowItWorksImagesArabic;
  }
  return howItWorksImageArabicFiles;
};

const BundleService = {
  fetchBundles,
  saveBundle,
  updateBundle,
};

export default BundleService;
