import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "questionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "questionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "answerEn",
        method: "isEmpty",
        validWhen: false,
        message: "Answer is required.",
      },
      {
        field: "answerAr",
        method: "isEmpty",
        validWhen: false,
        message: "Answer is required.",
      },
    ]);

    this.state = {
      questions: [],
      isLoaded: false,
      loadError: "",
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      answerEn: "",
      answerAr: "",
      editId: null,
      validation: this.validator.valid(),
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
    };

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true,
      },
      {
        name: "Answer",
        selector: "answer",
        sortable: true,
        wrap: true,
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>

              <ActionBar.Item
                className="danger"
                onClick={() => this.showConfirm(row.id)}
              >
                <DeleteOutlineIcon></DeleteOutlineIcon>
                Delete
              </ActionBar.Item>
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.deleteQuestion = this.deleteQuestion.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  componentDidMount() {
    this.loadQuestions();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  loadQuestions() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("frequently-asked-questions/")
      .then((response) => {
        let newList = response.data;

        for (let i = 0; i < newList.length; i++) {
          newList[i].index = i;
        }

        this.setState({ questions: newList, isLoaded: true });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching frequently asked questions", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let questionEn = this.state.questionEn;
    let questionAr = this.state.questionAr;
    let answerEn = this.state.answerEn;
    let answerAr = this.state.answerAr;
    let editId = this.state.editId;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });

      if (editId !== null) {
        //edit
        API.put("frequently-asked-questions/" + editId + "/", {
          question: questionEn,
          question_ar: questionAr,
          answer: answerEn,
          answer_ar: answerAr,
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully updated.",
              successMessage: true,
              showNotification: true,
            });
            this.loadQuestions();
          })
          .catch((error) => {
            console.log("Error on updating frequently asked questions", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      } else {
        //add new
        API.post("frequently-asked-questions/", {
          question: questionEn,
          question_ar: questionAr,
          answer: answerEn,
          answer_ar: answerAr,
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully created.",
              successMessage: true,
              showNotification: true,
            });
            this.loadQuestions();
          })
          .catch((error) => {
            console.log("Error on saving frequently asked questions", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
    }
  }

  editQuestion(index, id) {
    let questions = this.state.questions;
    this.setState({
      formHeading: "Edit Question",
      questionEn: questions[index]["question"],
      questionAr: questions[index]["question_ar"],
      answerEn: questions[index]["answer"],
      answerAr: questions[index]["answer_ar"],
      editId: id,
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
    });
  }

  showConfirm(confirmItem) {
    this.setState({
      showConfirm: true,
      confirmItem,
    });
  }

  deleteQuestion(id) {
    this.hideConfirm();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.delete("frequently-asked-questions/" + id + "/")
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Question successfully deleted.",
          successMessage: true,
          showNotification: true,
        });
        this.clearForm();
        this.loadQuestions();
      })
      .catch((error) => {
        console.log("Error on deleting frequently asked questions", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      answerEn: "",
      answerAr: "",
      editId: null,
      validation: this.validator.valid(),
    });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    list = this.state.questions;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.question.toLowerCase().indexOf(newValue) !== -1 ||
        item.question_ar.toLowerCase().indexOf(newValue) !== -1 ||
        item.answer.toLowerCase().indexOf(newValue) !== -1 ||
        item.answer_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  render() {
    let finalList = [],
      form;
    const {
      questions,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        finalList = questions;
      }
    }
console.log(finalList)
    form = (
      <>
        <div className="form-row mb-2">
          <div className="input-section form-group col">
            <label>Question (English) *</label>
            <input
              type="text"
              className={
                "form-control " +
                (validation.questionEn.isInvalid && "has-error")
              }
              placeholder="Question in English"
              name="questionEn"
              value={this.state.questionEn}
              onChange={this.handleChange}
            ></input>
            <span className="help-block">{validation.questionEn.message}</span>
          </div>
          <div className="input-section form-group col">
            <label>Question (Arabic) *</label>
            <input
              type="text"
              className={
                "form-control input-arabic " +
                (validation.questionAr.isInvalid && "has-error")
              }
              placeholder="Question in Arabic"
              name="questionAr"
              value={this.state.questionAr}
              onChange={this.handleChange}
            ></input>
            <span className="help-block">{validation.questionAr.message}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="input-section form-group col">
            <label>Answer (English) *</label>
            <textarea
              type="text"
              className={
                "form-control " + (validation.answerEn.isInvalid && "has-error")
              }
              placeholder="Answer in English"
              name="answerEn"
              value={this.state.answerEn}
              onChange={this.handleChange}
            ></textarea>
            <span className="help-block">{validation.answerEn.message}</span>
          </div>
          <div className="input-section form-group col">
            <label>Answer (Arabic) *</label>
            <textarea
              type="text"
              className={
                "form-control input-arabic " +
                (validation.answerAr.isInvalid && "has-error")
              }
              placeholder="Answer in Arabic"
              name="answerAr"
              value={this.state.answerAr}
              onChange={this.handleChange}
            ></textarea>
            <span className="help-block">{validation.answerAr.message}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="faq">
        <div className="page-header">
          {/* <h5>Frequently Asked Questions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={(e) => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this?</Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.deleteQuestion(this.state.confirmItem)}
              className="btn button btn-danger"
            >
              Delete
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
