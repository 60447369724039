import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import Rating from "components/Rating/Rating";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class OrderReviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: null,
      isLoaded: false,
      loadError: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0
    };

    this.columns = [
      {
        name: "Order ID",
        selector: "id",
        sortField: 'id',
        sortable: true,
        maxWidth: "120px"
      },
      {
        name: "Client",
        selector: "user.username",
        sortField: 'user__username',
        sortable: true
      },
      {
        name: "Email",
        selector: "user.email",
        sortField: 'user__email',
        sortable: true
      },
      {
        name: "Rating",
        selector: "rating",
        sortField: 'rating',
        sortable: true,
        maxWidth: "180px",
        format: row => (row.order_rating.length != 0 ?
          <Rating rating={row.rating}></Rating>
          :
          <p>N/A</p>
        )
      },
      {
        name: "Comments",
        selector: "comments",
        sortField: 'comments',
        sortable: true,
        wrap: true,
        format: row => (row.comments != "" ?
          <p>{row.comments}</p>
          :
          <p>N/A</p>
        )
      }
    ];

    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.onOrderPageChange = this.onOrderPageChange.bind(this);
    this.customSort = this.customSort.bind(this);
  }

  componentDidMount() {
    this.loadReviews();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  onOrderPageChange() {

    if (this.state.nextURL) {
      let url = this.state.nextURL;
      this.props.toggleLoading({
        isLoading: true,
      });

      API.get(url)
        .then((response) => {
          //filter out upcoming orders for nutritionists
          let allorder = [...this.state.reviews, ...response.data.results];
          this.setState({ reviews: allorder, nextURL: response.data && response.data.next ? response.data.next : '', isLoaded: true });
          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on fetching order reviews.", error);
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  loadReviews() {
    this.props.toggleLoading({
      isLoading: true
    });
    let url = "order-list/";
    if (this.state.sortField) {
      let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
      url = url + "?ordering=" + fieldOrder
    }
    if (this.state.searchValue && this.state.sortField) {
      url = url + "&search=" + this.state.searchValue
    }
    if (this.state.searchValue && !this.state.sortField) {
      url = url + "?search=" + this.state.searchValue
    }
    API.get(url)
      .then(response => {
        this.setState({ reviews: response.data.results, nextURL: response.data && response.data.next ? response.data.next : '', isLoaded: true });
        let totalPage = response.data && response.data.count ? response.data.count : 0;
        if (this.state.searchApplied) {
          this.setState({ results: response.data.results, resultCount: totalPage, isLoaded: true, nextURL: response.data && response.data.next ? response.data.next : '', totalPage: totalPage });
        } else {
          this.setState({ reviews: response.data.results, isLoaded: true, nextURL: response.data && response.data.next ? response.data.next : '', totalPage: totalPage });
        }
        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching reviews", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }
    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    list = this.state.reviews;
    if (!list) return;

    let newList = [];
    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      this.loadReviews();
    });
  }

  customSort(column, sortDirection) {

    let sortField = column.sortField;
    this.setState({ sortField: sortField, sortDirection: sortDirection }, () => {
      this.loadReviews();
    })
    // return orderBy(rows, selector, direction);
  };
  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    }, () => {
      this.loadReviews();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  render() {
    let finalList = [];
    const {
      reviews,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results
    } = this.state;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else finalList = reviews;
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>Order Reviews</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            // defaultSortField="order"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationTotalRows={this.state.totalPage}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onSort={this.customSort}
            onChangePage={this.onOrderPageChange}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderReviews);
