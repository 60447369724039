import {
  SHOW_NOTIFICATION,
  TOGGLE_LOADING,
  MANAGE_MARKERS,
  ADD_MESSAGE,
  SET_ACTIVE_CHAT,
  SHOW_MESSAGES,
} from "./constants";

const initialState = {
  notificationMessage: "",
  successMessage: true,
  showNotification: false,
  isLoading: false,
  testId: null,
  editAllowed: false,
  clients: [],
  activeChatId: 5,
  messages: [],
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.value.notificationMessage,
        successMessage: action.value.successMessage,
        showNotification: action.value.showNotification,
        isLoading: action.value.isLoading ? action.value.isLoading : false,
      };
    case TOGGLE_LOADING:
      return { ...state, isLoading: action.value.isLoading };
    case SHOW_MESSAGES:
      return { ...state, messages: action.value.messages };
    case MANAGE_MARKERS:
      return {
        ...state,
        testId: action.value.testId,
        editAllowed: action.value.editAllowed,
      };
    case ADD_MESSAGE:
      return {
        ...state,
        clients: action.value.clients,
      };
    case SET_ACTIVE_CHAT:
      return {
        ...state,
        activeChatId: action.value.activeChatId,
      };
    default:
      return state;
  }
}

export default rootReducer;
