import React, { useState } from "react";
import moment from "moment";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { connect } from "react-redux";
import "./filter.scss";

export default function UpcomingFilterOrders(props) {
  const [filteredStartDate, setFilteredStartDate] = useState("");
  const [filteredEndDate, setFilteredEndDate] = useState("");
  const BASE_URL_UPCOMING_BOOKINGS = "/homecare-waiting-broadcast/";
  const { homecareServiceId, setOrders, setSelectedTab } = props;
  const [isFocusedStart, setIsFocusedStart] = useState(false);
  const [isFocusedEnd, setIsFocusedEnd] = useState(false);
  // Function to handle input focus
  const handleFocus = () => {
    setIsFocusedStart(true);
  };

  // Function to handle input blur
  const handleBlur = () => {
    setIsFocusedStart(false);
  };

  const handleFocusEnd = () => {
    setIsFocusedEnd(true);
  };
  const handleBlurEnd = () => {
    setIsFocusedEnd(false);
  };
  const handleShowOrders = () => {
    console.log("filtered ", filteredStartDate, filteredEndDate);
    setSelectedTab("key_order_all");
    getOrders(filteredStartDate, filteredEndDate);
  };

  const getOrders = async (startDate, endDate) => {
    console.log("get orders", homecareServiceId, startDate, endDate);
    const url = `${BASE_URL_UPCOMING_BOOKINGS}/${homecareServiceId}`;
    const params = {};

    if (startDate) params.fromDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) params.toDate = moment(endDate).format("YYYY-MM-DD");

    const { data: bookings = [] } = await PhoenixAPI.get(url, { params });
    setOrders([...bookings]);
    console.log("bookings are", bookings);

    /*bookings.forEach((booking) => {
            const { lab_1_id, lab_2_id, nurse_appointment_status } = booking;
            if (!nurse_appointment_status) {
              booking.nurse_appointment_status = bookingStatus.CREATED.value;
            }
             
            const lab1 = findLabById(lab_1_id);
            if (lab1) {
              const { name, contact_number, location } = lab1;
              booking.lab_1_name = [name, contact_number, location]
                .filter((it) => it ?? false)
                .join(", ");
            }
      
            const lab2 = findLabById(lab_2_id);
            if (lab2) {
              const { name, contact_number, location } = lab2;
              booking.lab_2_name = [name, contact_number, location]
                .filter((it) => it ?? false)
                .join(", ");
            }
          });*/
  };

  const handleDateSelect = ({ target: input }) => {
    // console.log("inputDateSelect", input);
    input.classList.add("has-value");
    if (input.name === "selectedStartDate" && filteredEndDate) {
      if (moment(input.value).isAfter(filteredEndDate)) {
        setFilteredStartDate(input.value);
        setFilteredEndDate(input.value);
      }
    } 
    if (input.name == "selectedStartDate"){
      setFilteredStartDate(input.value);
    }
    else if(input.name == "selectedEndDate") {
      setFilteredEndDate(input.value);
    } 
  
  };

  return (
    <div className="date-main-cont">
      <div className="date-container">
        <div className="del-date-filter">
          <input
            value={filteredStartDate}
            onChange={handleDateSelect}
            // min={this.minStartDate}
            // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
            type="date"
            id="selectedStartDate"
            name="selectedStartDate"
            placeholder="dd-mm-yyyy"

            // data-placeholder="DD/MM/YYYY"
            // className={`custom-date-input ${isFocusedStart ? 'focused' : ''}`}
            // onFocus={handleFocus}
            // onBlur={handleBlur}
          />
        </div>
        <div>-</div>
        <div className="del-date-filter">
          <input
            value={filteredEndDate}
            onChange={handleDateSelect}
            min={filteredStartDate ?? null}
            // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
            type="date"
            id="selectedEndDate"
            name="selectedEndDate"
            placeholder="dd-mm-yyyy"
          />
        </div>
      </div>

      <button
        onClick={handleShowOrders}
        // disabled={!isEditable}
        className="showbtn"
      >
        Show Orders
      </button>
    </div>
  );
}
