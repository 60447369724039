import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";

import { getUserEmail, getUserId, getUserName } from "services/UserService";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Notification from "../../pages/HomecareMwebPortal/Notification";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import PdfPreview from "components/Common/PdfPreview";
import "./viewMyTicket.scss";

export default function ReopenTicket(props) {
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homecareUser, setHomecareUser] = useState("");
  const [homecareEmail, setHomecareEmail] = useState("");
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [images, setImages] = useState([]);
  const [ticketId, setTicketId] = useState(null);
  const [ticketDetails,setTicketDetails]=useState({});
  const [homecareDetails, setHomecareDetails] = useState({})
  const fileInputRef = useRef();
  const ticketCategoryOptions = [
    {
      name: "Order",
      value: "Order",
      label: "Order",
    },
    {
      name: "Location Issues",
      value: "Location Issues",
      label: "Location Issues",
    },
    {
      name: "Pricing",
      value: "Pricing",
      label: "Pricing",
    },
    {
      name: "Penalty",
      value: "Penalty",
      label: "Penalty",
    },
    {
      name: "Customer Issues",
      value: "Customer Issues",
      label: "Customer Issues",
    },
    {
      name: "COD orders",
      value: "COD orders",
      label: "COD orders",
    },
    {
      name: "Others",
      value: "Others",
      label: "Others",
    },
  ];

  const getTicketDetails = async (ticketId) => {
    try {
      setIsLoading(true);
      const { data: ticketDetails = [] } = await PhoenixAPI.get(
        `ticket/${ticketId}`
      );
      const { latestTicket, ticketHistory = [] } = ticketDetails ?? {};
      console.log("latestTicket: ", latestTicket);
      const {
        ticketCategory = "",
        mediaS3Url = [],
        title = "",
        description = "",
        orderId = "",
      } = latestTicket ?? {};
      const ticketCategoryOption = {
        name: ticketCategory,
        value: ticketCategory,
        label: ticketCategory,
      };
      const inputs = {
        ticketCategoryOption: ticketCategoryOption,
        title: title,
        orderId: orderId ?? "",
        description: description,
      };
      setTicketDetails(latestTicket)
      setInputs(inputs);
    } catch (error) {
      console.log("Error in view Ticket", error.message);
      setMessage("Error in fetching Ticket", error.message);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  const getHomeCareDetails=async()=>{
    try{
     const  {data:homecareUsers=[]}= await PhoenixAPI.get("homecare-details");
     const userId = getUserId();
     const homecareDetails=homecareUsers.find((item)=>item.homecare_userid==userId) 
     setHomecareDetails(homecareDetails)
     console.log("Response: homecareUsers", homecareDetails)
    }
    catch(error){
      console.log("Error:", error.message)
    }
  }
  useEffect(() => {
    const { ticketId } = props.match.params;
    if (ticketId) {
      getHomeCareDetails()
      getTicketDetails(ticketId);
      setTicketId(ticketId);
      const userEmail = getUserEmail();
      const userId = getUserId();
      const userName = getUserName();
      setHomecareUser(userName);
      setHomecareEmail(userEmail);
    }

    // fetch homecare user details here based on user id
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prev) => setInputs({ ...prev, [name]: value }));
  };

  const validate = () => {
    let isError = false;
    let error = {};
    if (!inputs.ticketCategoryOption) {
      error.ticketCategoryOption = "Category is Required";
      isError = true;
    }
    if (!inputs.title) {
      error.title = "Title is required";
      isError = true;
    }
    if (!inputs.description) {
      error.description = "Description is required";
      isError = true;
    }
    setError({ ...error });
    return isError;
  };
  const handleReopenTicket = async () => {
    const isError = validate();
    if (isError) {
      setIsError(true);
      setMessage("Invalid fields");
      return;
    }
    const { ticketCategoryOption, title, orderId, description } = inputs ?? {};
    const userId = getUserId();

    const payload = {
      ticketCategory: ticketCategoryOption.value,
      title: title,
      orderId: orderId,
      description: description,
      homecareId:homecareDetails.homecare_id,
      homecareName: homecareUser,
      mediaS3Url: images,
      parentTicketId: ticketDetails.isParentTicket?ticketDetails.id:ticketDetails.parentTicketId,
      parentTicketUuid:ticketDetails.isParentTicket?ticketDetails.ticketUUID:ticketDetails.parentTicketUuid,
      reopenCount: ticketDetails.reopenCount
    };
   
  
    try {
      setIsLoading(true);
      const response = await PhoenixAPI.post(
        "homecare/ticket?isReopenTicket=true",
        payload
      );
      setIsError(false);
      setMessage(
        "Thank you for creating ticket. Our team will be in touch with you shortly."
      );
      setInputs({});
      setImages([]);
      setTimeout(() => {
        window.location.href =
          "/dashboard/homecare-mobile-portal/ticket-management";
      }, 2000);
    } catch (error) {
      setIsError(true);
      setMessage("Error on creating ticket");
      console.log("Error on creating ticket", error);
      setIsLoading(false);
      return;
    } finally {
      setIsLoading(false);
    }
  };
  const handleCancel = () => {
    setInputs({});
  };

  const handleBackButton = () => {
    return (window.location.href =
      "/dashboard/homecare-mobile-portal/ticket-management");
  };
  const handleUploadAttachments = async (file) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("media", file);
      const response = await PhoenixAPI.post("/upload-media", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": "multipart/form-data",
        },
      });
      const data = response && response.data;
      return data.message ?? "";
    } catch (error) {
      console.log("Error on uploading attachments", error.message);
    }
    finally{
      setIsLoading(false);
    }
  };
  const handleSelectImage = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uploadedImage = await handleUploadAttachments(file);

      setImages((prev) => [...prev, uploadedImage]);
      if (fileInputRef.current && fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const renderClosableImages = (image, index) => {
    const isPdfFile =
      image?.type === "application/pdf" ||
      (typeof image === "string" && image.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <div style={{ width: "150px" }}>
        <PdfPreview
          pdfFile={isPdfFile ? image : null}
          pdfFileUrl={isPdfFile ? null : image}
        />
      </div>
    ) : (
      <img key={index} src={image} alt="Selected" />
    );
    return <div className="image-area">{preview}</div>;
  };

  return (
    <div>
      {window.innerWidth < 768 && (
        <div className="top-header">
          <div className="header-content">
            <div className="content" onClick={() => handleBackButton()}>
              <div className="lt">&lt;</div>
              <div className="header-title">Reopen Support Ticket</div>
            </div>
          </div>
        </div>
      )}
      <div className="ticket-container">
        <div>Reopen Support Ticket</div>
        <div>{homecareEmail}</div>
        <div>{homecareUser}</div>

        <div className="create-ticket-form">
          <div className="ticket-category-cont">
            <label className="label">
              What do you want to help with ? <span>*</span>
            </label>
            <Select
              value={inputs?.ticketCategoryOption ?? null}
              placeholder="Select a category"
              search
              selection
              className={"ticketCategoryOption"}
              onChange={(selectedOption) => {
                setInputs((prev) =>
                  setInputs({ ...prev, ticketCategoryOption: selectedOption })
                );
              }}
              options={ticketCategoryOptions}
            />
            {error.ticketCategoryOption && (
              <div className="error">{error.ticketCategoryOption}</div>
            )}
          </div>

          <div className="row">
            <div className="form-group col">
              <label htmlFor="title" className="label">
                Title <span>*</span>
              </label>
              <div className="input-group ticket">
                <input
                  type="text"
                  placeholder="Enter Title"
                  value={inputs?.title || ""}
                  name="title"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className={`${error.title ? "error-border" : ""}`}
                />
              </div>

              {error.title && <div className="error">{error.title}</div>}
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="orderId" className="label">
                Order Id (if Applicable)
              </label>
              <div className="input-group ticket">
                <input
                  type="number"
                  placeholder="Enter Order Id"
                  value={inputs?.orderId || ""}
                  name="orderId"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>

              {error.title && <div className="error">{error.orderId}</div>}
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="description" className="label">
                Description <span>*</span>
              </label>
              <div className="input-group ticket">
                <textarea
                  type="text"
                  placeholder="Enter Description"
                  value={inputs?.description || ""}
                  name="description"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  className={`${error.description ? "error-border" : ""}`}
                />
              </div>

              {error.title && <div className="error">{error.description}</div>}
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="description" className="label">
                Attach Files/Images (Optional)
              </label>
              <div className="input-group ticket">
                <input
                  onChange={(e) => handleSelectImage(e)}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  ref={fileInputRef}
                  className={`${error.image ? "error-border" : ""}`}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="media-scroller snaps-inline" style={{gap:"80px"}}>
              {images &&
                images.length > 0 &&
                images.map((image, index) => {
                  return (
                    <div key={index} className="media-element">
                      {renderClosableImages(image, index)}
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="button-save-div">
            <button
              onClick={(e) => handleCancel()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              Cancel
            </button>

            <button
              onClick={(e) => handleReopenTicket()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              Create Support Ticket
            </button>
          </div>
        </div>
        <CustomLoader show={isLoading}/>
        {message && (
          <Notification
            setMessage={setMessage}
            message={message}
            isError={isError}
          />
        )}
      </div>
    </div>
  );
}
