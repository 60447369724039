import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectMedicines.propTypes = {
  medicines: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMedicineIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectMedicines.defaultProps = {
  medicines: [],
  selectedMedicineIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectMedicines(props) {
  const {
    medicines,
    selectedMedicineIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findMedicine = (id) => medicines.find((medicine) => medicine.id === id);

  const options = createOptions(medicines, showOnlyActive);

  const selectedMedicines = selectedMedicineIds
    .map(findMedicine)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedMedicineIds && selectedMedicineIds.join(",")
      }`}
      value={selectedMedicines}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(medicines, showOnlyActive) {
  if (!medicines || !medicines.length) return [];

  const [activeMedicines, inactiveMedicines] = _.partition(
    medicines,
    (it) => it.status === "Active"
  );
  activeMedicines.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );

  if (showOnlyActive) return activeMedicines.map(createOption);

  inactiveMedicines.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  return [
    {
      label: "Active",
      options: activeMedicines.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveMedicines.map(createOption),
    },
  ];
}

function createOption(supplement) {
  if (!supplement) return;
  return {
    key: supplement.id,
    value: supplement.id,
    label: supplement.internalName,
  };
}

export default SelectMedicines;
