
import React, { useState } from "react";
import "./alertModal.scss";
import { useEffect } from "react";

export default function AlertModal({ count=0, biomarkerList = [], biomarkerData = {} , isShowModal, setIsShowModal }) {
  const toggleModal = (e) => {
    if (e.target.className == "alert-overlay") {
      setIsShowModal(false);
      e.stopPropagation();
    }

  };

  return (
    <>
      <div className="alert-modal">
        <div onClick={toggleModal} className="alert-overlay" style={{ overflow: isShowModal ? "hidden" : "" }}>
          <div className="alert-modal-content">
            <div className="alert-modal-header">{count} Biomarkers</div>
            <div className="alert-modal-body">
              {biomarkerList && biomarkerList.length > 0 && biomarkerList.map((key, index) => {
                return (
                  <>
                    <div className="title">{key}</div>
                    <ul className="list-text">
                      {Object.keys(biomarkerData) && Object.keys(biomarkerData).length > 0 && biomarkerData[key] && biomarkerData[key].length>0 && biomarkerData[key].map((item, index) => {
                        return (
                          <li>{item}</li>
                        )
                      })
                      }
                    </ul>
                  </>

                )
              })
              }
            </div>
            <div className="close-modal" onClick={() => setIsShowModal(false)}>
              <img src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg" alt="cross-icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
