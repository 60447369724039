import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import SelectPageCategory from "./SelectPageCategory";
import SelectAccessRights from "./SelectAccessRights";
import SelectPageActions from "./SelectPageActions";
import SelectPage from "./SelectPage";
import Search from "components/Search/Search";
import PhoenixAPI from "utils/PhoenixAPI";

class PagePermissionsForm extends Component {
  constructor(props) {
    super(props);

    const { pagePermissions, onPagePermissionsUpdated} = this.props;
    this.onPagePermissionsUpdated = onPagePermissionsUpdated;

    this.state = {
      pagePermissions,
      pagePermission: {},
      errors: {},
      editables: {},
      sortColumn: "id",
      sortDirection: "desc",
      isConfirmDeleteModalVisible: false,
      searchTerm: null,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Page ID",
        selector: "pageId",
        sortable: true,
      },
      {
        name: "Page Name",
        selector: "pageName",
        // sortable: true,
        wrap: true,
        format: (it) => (
          <div className="text-overflow-ellipsis">
            {this.findPage(it.pageId)?.name ?? ""}
          </div>
        ),
      },
      {
        name: "Access Rights",
        selector: "accessRights",
        // sortable: true,
        wrap: true,
        cell: (it) => this.renderPageAccessRights(it.pageAccessRights),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (it) => this.renderActionBar(it),
      },
    ];
  }

  findAccessRight = (accessRightId) => {
    if (!accessRightId) return null;

    const accessRights = this.props.accessRights ?? [];
    return accessRights.find((accessRight) => accessRight.id === accessRightId);
  };

  findPage = (pageId) => {
    if (!pageId) return null;

    const pages = this.props.pages ?? [];
    return pages.find((page) => page.id === pageId);
  };

  clearForm = () => {
    // Array.from(document.querySelectorAll("input")).forEach(
    //   (it) => (it.value = "")
    // );
    // Array.from(document.querySelectorAll("textarea")).forEach(
    //   (it) => (it.value = "")
    // );
    this.setState({
      errors: {},
      editables: {},
      action: {},
      pagePermission: {},
      pageAccessRightIds: [],
      pageActionIds: [],
    });
  };

  handleSearch = (queryString) => {
    this.setState({ searchApplied: true, searchTerm: queryString ?? "" });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const action = { ...this.state.pagePermission };
    action[input.name] = input.value;
    this.setState({ action });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { pagePermissions = [] } = this.state;
  
    const pagePermission = pagePermissions.find((it) => it.id === id);
    if (!pagePermission) {
      console.log(`handleEdit:: Page permission with id=${id} not found!`);
      return;
    }
      
        const { pageAccessRights, pageActions, pageId } = pagePermission;
        if (pageId) {
          pagePermission.pageCategoryId =
            this.findPage(pageId)?.category?.id ?? null;
        }
        const pageAccessRightIds =
          pageAccessRights && pageAccessRights.length
            ? pageAccessRights
                .filter((it) => it.isActive ?? false)
                .map((it) => it.accessRightId)
            : [];
        const pageActionIds =
          pageActions && pageActions.length
            ? pageActions
                .filter((it) => it.isActive ?? false)
                .map((it) => it.pageActionId)
            : [];
        this.setState({ pagePermission, pageAccessRightIds, pageActionIds }, () =>
          this.showAddEditModal()
        );  
      
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { pagePermissions = [], pagePermission = {} } = this.state;
    if (pagePermission.id === null || pagePermission.id === undefined) {
      const foundPagePermission = pagePermissions.find(
        (it) => it.pageId === pagePermission.pageId
      );
      if (foundPagePermission) {
        this.showErrorNotification("This page is alread added!");
        return;
      }

      this.createPagePermission();
    } else {
      this.updatePagePermission(pagePermission);
    }
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { pageCategoryId, pageId } = this.state.pagePermission || {};

    // if (!pageCategoryId)
    //   errors.pageCategory = "Page category is a required field!";
    if (!pageId) errors.page = "Page is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };


  createPagePermission = async () => {
    const {
      pagePermissions = [],
      pagePermission,
      pageAccessRightIds,
      pageActionIds,
    } = this.state;

  
    const ids =
      pagePermissions.length > 0 ? pagePermissions.map((it) => it.id) : null;
    pagePermission.id = ids ? Math.max(...ids) + 1 : 1;
    pagePermission.pageAccessRights =
      pageAccessRightIds && pageAccessRightIds.length
        ? pageAccessRightIds.map((it) => ({
            accessRightId: it,
            isActive: true,
          }))
        : null;
    pagePermission.pageActions =
      pageActionIds && pageActionIds.length
        ? pageActionIds.map((it) => ({ pageActionId: it, isActive: true }))
        : null;
    pagePermission.is_new = true;

    this.setState(
      { pagePermissions: [...pagePermissions, pagePermission] },
      () => {
        this.showSuccessNotification("Created Successfully!");
        this.onPagePermissionsUpdatedLocally();
      }
    );
  };





  updatePagePermission = async(pagePermission) => {
    const { pagePermissions = [] } = this.state;
  
    this.updatePageAccessRights(pagePermission);
    this.updatePageActions(pagePermission);

    const newPagePermissions = [...pagePermissions];
    newPagePermissions.map((it) =>
      it.id === pagePermission.id ? pagePermission : it
    );
    

    this.setState({ pagePermissions: newPagePermissions }, () => {
      this.showSuccessNotification("Updated Successfully!");
      this.onPagePermissionsUpdatedLocally();
    });
  };

  updatePageAccessRights = (pagePermission) => {
    if (!pagePermission) return;

    const { pageAccessRightIds } = this.state;
    const pageAccessRights = pagePermission.pageAccessRights ?? [];
    if (pageAccessRightIds && pageAccessRightIds.length) {
      pageAccessRights.forEach((it) => {
        if (!pageAccessRightIds.includes(it.accessRightId)) {
          it.isActive = false;
        }
      });
      pageAccessRightIds.forEach((id) => {
        const foundOption = pageAccessRights.find(
          (it) => it.accessRightId === id
        );
        if (!foundOption) {
          pageAccessRights.push({
            accessRightId: id,
            isActive: true,
          });
        } else {
          foundOption.isActive = true;
        }
      });
    } else {
      pageAccessRights.forEach((it) => (it.isActive = false));
    }
  };

  updatePageActions = (pagePermission) => {
    if (!pagePermission) return;

    const { pageActionIds: options } = this.state;
    const pageActions = pagePermission.pageActions ?? [];
    if (!options || !options.length) {
      pageActions.forEach((it) => (it.isActive = false));
    } else {
      pageActions.forEach((it) => {
        if (options && options.length && !options.includes(it.pageActionId)) {
          it.isActive = false;
        }
      });
      options.forEach((option) => {
        const foundOption = pageActions.find(
          (it) => it.pageActionId === option.value
        );
        if (!foundOption) {
          pageActions.push({
            pageActionId: option.value,
            isActive: true,
          });
        }
      });
    }
  };

  onPagePermissionsUpdatedLocally = () => {
    this.clearForm();
    this.onPagePermissionsUpdated(
      this.state.pagePermissions.map((it) => {
        const itCopy = { ...it };
        // if (itCopy.is_new) {
        //   delete itCopy.id;
        //   delete itCopy.is_new;
        // }
        return itCopy;
      })
    );
  };

  handleAddNewPagePermission = () => {
    const pagePermission = {};
    this.setState(
      { pagePermission, pageAccessRightIds: [], pageActionIds: [] },
      () => this.showAddEditModal()
    );
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderPageAccessRights = (pageAccessRights) => {
    console.log("renderPageAccessRights:: ", { pageAccessRights });
    if (!pageAccessRights || !pageAccessRights.length) return "";
    const accessRightNames = pageAccessRights
      .filter((it) => it.isActive ?? false)
      .map((it) => this.findAccessRight(it.accessRightId)?.name ?? "");
    return (
      <div className="text-overflow-ellipsis">{accessRightNames.join()}</div>
    );
  };

  renderActionBar = (pagePermission) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(pagePermission.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.pagePermission.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  getFilteredPagePermissions = () => {
    const pagePermissions = this.props.pagePermissions ?? [];
    return this.getSearchedPagePermissions(pagePermissions);
  };

  getSearchedPagePermissions = (pagePermissions) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return pagePermissions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return pagePermissions.filter((miniPackage) => {
      const { id, pageId, pageName } = miniPackage;
      return (
        (id && id.toString() === newValue) ||
        (pageId && pageId.toString() === newValue) ||
        (pageName && pageName.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  render() {
    // console.log("rendering...");

    const {
      pagePermission = {},
      pageAccessRightIds = [],
      pageActionIds = [],
      errors,
      editables,
      sortColumn,
      searchTerm,
    } = this.state;

    const isNewPagePermission =
      pagePermission.id === null || pagePermission.id === undefined;

    const pagePermissions = this.getFilteredPagePermissions();

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="category">Page Category</label>
            <div className="input-group">
              <SelectPageCategory
                id="pageCategory"
                name="pageCategory"
                categories={this.props.pageCategories ?? []}
                selectedCategoryId={pagePermission?.pageCategoryId ?? null}
                onSelect={(option) => {
                  this.setState({
                    pagePermission: {
                      ...pagePermission,
                      pageCategoryId: option.value,
                      pageId: null,
                    },
                  });
                }}
                isDisabled={!isNewPagePermission && !editables.pageCategory}
                className={
                  "form-control" + (errors.pageCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("pageCategory")}
            </div>
            <span className="help-block">{errors.pageCategory}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="page">Page*</label>
            <div className="input-group">
              <SelectPage
                id="page"
                name="page"
                pages={
                  pagePermission.pageCategoryId
                    ? this.props.pages?.filter(
                        (page) =>
                          page.category?.id === pagePermission.pageCategoryId
                      )
                    : this.props.pages?.filter((page) => !page.category)
                }
                selectedPageId={pagePermission.pageId ?? null}
                onSelect={(option) => {
                  this.setState({
                    pagePermission: {
                      ...pagePermission,
                      pageId: option.value,
                    },
                  });
                }}
                isDisabled={!isNewPagePermission && !editables.page}
                className={"form-control" + (errors.page ? " has-error" : "")}
              />
              {this.renderPencilIcon("page")}
            </div>
            <span className="help-block">{errors.page}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="pageAccessRights">Page Access Rights</label>
            <div className="input-group">
              <SelectAccessRights
                id="pageAccessRights"
                name="pageAccessRights"
                accessRights={this.props.accessRights ?? []}
                selectedAccessRightIds={pageAccessRightIds ?? []}
                onSelect={(options) =>
                  this.setState({
                    pageAccessRightIds: options.map((it) => it.value),
                  })
                }
                isDisabled={!isNewPagePermission && !editables.pageAccessRights}
                className={
                  "form-control" + (errors.pageAccessRights ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("pageAccessRights")}
            </div>
            <span className="help-block">{errors.pageAccessRights}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="pageActions">Page Actions</label>
            <div className="input-group">
              <SelectPageActions
                id="pageActions"
                name="pageActions"
                actions={this.findPage(pagePermission.pageId)?.actions ?? []}
                selectedActionIds={pageActionIds ?? []}
                onSelect={(options) =>
                  this.setState({
                    pageActionIds: options.map((it) => it.value),
                  })
                }
                isDisabled={!isNewPagePermission && !editables.pageActions}
                className={
                  "form-control" + (errors.pageActions ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("pageActions")}
            </div>
            <span className="help-block">{errors.pageActions}</span>
          </div> */}
        </div>
      </>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item mt-4">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                // data={pagePermissions || []}
                data={pagePermissions ?? []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewPagePermission}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {pagePermission.id === null || pagePermission.id === undefined
                ? "Add Page Permission"
                : "Update Page Permission"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {pagePermission.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the question?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteQuestion(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

PagePermissionsForm.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.object).isRequired,
  pageCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagePermissions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPagePermissionsUpdated: PropTypes.func.isRequired,
};

PagePermissionsForm.defaultProps = {
  accessRights: [],
  pageCategories: [],
  pages: [],
  pagePermissions: [],
  onPagePermissionsUpdated: (f) => f,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PagePermissionsForm);
