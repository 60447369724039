import React from "react";
import PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
const BASE_URL_SEOPAGE_SAVE="v2/seo-landing-page";
const BASE_URL_SEO_PAGES="admin-seo-landing-page";
const BASE_URL_COACH_USERS="nutritionist-user-name";
const  BASE_URL_Default_Coach="seo-landing-page-default-coach";
const BASE_URL_Packages="cms-package-id-name";
export const fetchSeoPages = async () => {
    try {
      const { data: seoPages } = await PhoenixAPI.get(BASE_URL_SEO_PAGES);
      return seoPages;
    } catch (error) {
      console.error(
        "fetchAuthors:: Error on fetching authors from this URL: ",
        BASE_URL_SEO_PAGES
      );
      console.error("fetchAuthors:: Error on fetching authors!", error);
      throw error;
    }
  };

export const gettSeoPage=async(seopage)=>{
  try {
    const { data: seoPage } = await PhoenixAPI.get(
      `${BASE_URL_SEOPAGE_SAVE}/${seopage.id}`
    );
    return seoPage;
  } catch (error) {
    console.log("fetchArticle:: Error on fetching article", error);
    throw error;
  }
}

export const updateSeoPage=async(seopage)=>{
  try {
    const payload = {...seopage};
    const response = await PhoenixAPI.patch(
      `${BASE_URL_SEOPAGE_SAVE}/${seopage.id}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("fetchArticle:: Error on fetching article", error);
    throw error;
  }
}

export const updateCoach=async(countryId,coachId)=>{
 const coachusers= await  getDefaultCoach(countryId);
  console.log("coachusers",coachusers);
// const coach=coachusers.find((coach)=>coach.countryId==countryId)
 
 try{
  if(coachusers!==undefined){
 const  payload={coachId:coachId}
  const response = await PhoenixAPI.patch(`${BASE_URL_Default_Coach}/${coachusers.id}`,payload);
  return response;
 }

 else{
  const  payload={coachId:coachId,countryId:countryId}
  const response = await PhoenixAPI.post(`${BASE_URL_Default_Coach}`,payload);
  return response;
 }
}
catch (error) {
  console.log("coach update in seopage", error);
  throw error;

}
}
export const saveSeoPage=async(seopage)=>{

    try {
        const payload = { ...seopage};
        delete payload["image_src1"];
        delete payload["image_src2"];
        delete payload["image_src3"];
        delete payload["landingPageProductName"];
        delete payload["h1Image"];
        delete payload["h2Image"];
        delete payload["h3Image"];
        const formData = new FormData();
        /*if (typeof article.primaryImage !== "string") {
          delete payload["primaryImage"];
          formData.append("image", article.primaryImage);
        }
      
        formData.append("health_hub", JSON.stringify(payload));*/
       formData.append("details",JSON.stringify(payload));
        if(seopage.h1Image && seopage.h1Image.name)
       formData.append("h1Image",seopage.h1Image,seopage.h1Image.name);
       if(seopage.h2Image && seopage.h2Image.name)
       formData.append("h2Image",seopage.h2Image,seopage.h2Image.name);
      if(seopage.h3Image && seopage.h3Image.name)
       formData.append("h3Image",seopage.h3Image,seopage.h3Image.name);
        const apiOptions = {
          method: seopage.id ? "PATCH" : "POST",
          url: seopage.id
            ? `${BASE_URL_SEOPAGE_SAVE}/${seopage.id}`
            : BASE_URL_SEOPAGE_SAVE,
          data: formData,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        };
        return await PhoenixAPI.request(apiOptions);
      } catch (error) {
        console.log("saveArticle:: Error on saving article", error);
        throw error;
      }

}

export const getCoachUsers=async()=>{
  try {
    const { data: coachUsers } = await PhoenixAPI.get(BASE_URL_COACH_USERS);
    return coachUsers;
  } catch (error) {
    console.error(
      "fetchAuthors:: Error on fetching authors from this URL: ",
      BASE_URL_COACH_USERS
    );
    console.error("fetchAuthors:: Error on fetching authors!", error);
    throw error;
  }
}

export const getDefaultCoach=async(countryId)=>{
  try {
    const { data: defaultCoach } = await PhoenixAPI.get(
      BASE_URL_Default_Coach
    );
    
    return defaultCoach.find((coach)=>coach.countryId==countryId) ;
  } catch (error) {
    console.error(
      "fetchAuthors:: Error on fetching authors from this URL: ",
      BASE_URL_COACH_USERS
    );
    console.error("fetchAuthors:: Error on fetching authors!", error);
    throw error;
  }
}


