import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class H2TagsForm extends Component {
  constructor(props) {
    super(props);

    const { tags = [], onTagUpdated = (f) => f } = this.props;

    this.onTagUpdated = onTagUpdated;

    this.state = {
      tags,
      tag: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };



    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Header Title (English)",
        selector: "headerTitleEnglish",
        sortable: true,
        wrap: true,
        format: (tag) => (
          <div className="text-overflow-ellipsis">
            {tag.headerTitleEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Header Title (Arabic)",
        selector: "headerTitleArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (tag) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {tag.headerTitleArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (tag) => <Status isActive={tag.isActive ?? false} />,
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (tag) => this.renderActionBar(tag),
      },
    ];
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      tag: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const tag = { ...this.state.tag };
    tag[input.name] = input.value;
    this.setState({ tag });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { tags = [] } = this.state;
    const tag = tags.find((it) => it.id === id);
    if (!tag) return;

    this.setState({ tag }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { tag = {} } = this.state;
    if (tag.id === null || tag.id === undefined) this.createTag();
    else this.updateTag(tag.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      headerTitleEnglish,
      headerTitleArabic,
      headerDescriptionEnglish,
      headerDescriptionArabic,
      isActive,
    } = this.state.tag || {};

    if (!headerTitleEnglish || headerTitleEnglish.trim() === "")
      errors.headerTitleEnglish = "Header Title (English) cannot be blank";
    else if (headerTitleEnglish && headerTitleEnglish.length > 256)
      errors.headerTitleEnglish =
        "Header Title (English) is limited to a 256 characters";

    if (!headerTitleArabic || headerTitleArabic.trim() === "")
      errors.headerTitleArabic = "Header Title (Arabic) cannot be blank";
    else if (headerTitleArabic && headerTitleArabic.length > 256)
      errors.headerTitleArabic =
        "Header Title (Arabic) is limited to a 256 characters";

   
     if (headerDescriptionEnglish && headerDescriptionEnglish.length > 1000)
      errors.headerDescriptionEnglish =
        "Header Description (English) is limited to a 1000 characters";

   
     if (headerDescriptionArabic && headerDescriptionArabic.length > 1000)
      errors.headerDescriptionArabic =
        "Header Description  (Arabic) is limited to a 1000 characters";

    // if (!isActive) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createTag = () => {
    const { tags = [], tag } = this.state;
    const ids = tags.length > 0 ? tags.map((it) => it.id) : null;
    tags.id = ids ? Math.max(...ids) + 1 : 1;

    tag.is_new = true;

    this.setState({ tags: [...tags, tag] }, () => {
      this.showSuccessNotification("H2 Tag Created Successfully!");
      this.onTagUpdatedLocally();
    });
  };

  updateTag = (id) => {
    const { tags = [], tag } = this.state;
    const updateTags = tags.map((it) => (it.id === id ? tag : it));
    this.setState({ tags: updateTags }, () => {
      this.showSuccessNotification("H2 Tag Updated Successfully!");
      this.onTagUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { tags = [] } = this.state;
    const updateTags = tags.map((it) =>
      it.id === id
        ? { ...it, isActive: it.isActive === true ? false : true }
        : it
    );
    this.setState({ tags: updateTags }, () => {
      this.showSuccessNotification("H2 Tag Updated Successfully!");
      this.onTagUpdatedLocally();
    });
  };

  onTagUpdatedLocally = () => {
    this.clearForm();
    this.onTagUpdated(
      this.state.tags.map((tag) => {
        const q = { ...tag };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewTag = () => {
    const tag = { isActive: false };
    this.setState({ tag }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (tag) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(tag.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(tag.id)}>
          {tag.isActive ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {tag.isActive ? "Inactive" : "Active"}
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.tag.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { tags, tag = {}, errors, editables, sortColumn } = this.state;

    const isNewTag = tag.id === null || tag.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="headerTitleEnglish">Header Title (English)*</label>
            <div className="input-group">
              <input
                value={tag.headerTitleEnglish}
                onChange={this.handleChange}
                id="headerTitleEnglish"
                name="headerTitleEnglish"
                placeholder="Header Title in English"
                readOnly={!isNewTag && !editables.headerTitleEnglish}
                className={
                  "form-control py-2" +
                  (errors.headerTitleEnglish ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("headerTitleEnglish")}
            </div>
            <span className="help-block">{errors.headerTitleEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="headerTitleArabic">Header Title (Arabic)*</label>
            <div className="input-group">
              <input
                value={tag.headerTitleArabic}
                onChange={this.handleChange}
                id="headerTitleArabic"
                name="headerTitleArabic"
                placeholder="Header Title in Arabic"
                readOnly={!isNewTag && !editables.headerTitleArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.headerTitleArabic ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("headerTitleArabic")}
            </div>
            <span className="help-block">{errors.headerTitleArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="headerDescriptionEnglish">
              Header Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={tag.headerDescriptionEnglish}
                onChange={this.handleChange}
                id="headerDescriptionEnglish"
                name="headerDescriptionEnglish"
                placeholder="Header Description in English"
                readOnly={!isNewTag && !editables.headerDescriptionEnglish}
                className={
                  "form-control py-2" +
                  (errors.headerDescriptionEnglish ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("headerDescriptionEnglish")}
            </div>
            <span className="help-block">
              {errors.headerDescriptionEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="headerDescriptionArabic">
              Header Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={tag.headerDescriptionArabic}
                onChange={this.handleChange}
                id="headerDescriptionArabic"
                name="headerDescriptionArabic"
                placeholder="Header Description in Arabic"
                readOnly={!isNewTag && !editables.headerDescriptionArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.headerDescriptionArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("headerDescriptionArabic")}
            </div>
            <span className="help-block">{errors.headerDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={tag?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    tag: { ...tag, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewTag && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="font-weight-bold mt-4 px-4">Header Table</p>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={tags || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewTag}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {tag.id === null || tag.id === undefined
                ? "Add H2 Tag"
                : "Edit H2 Tag"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {tag.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

H2TagsForm.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTagUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

H2TagsForm.defaultProps = {
  tags: [],
  onTagUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(H2TagsForm);
