import React, { useState } from "react";
import "./NurseModal.scss";
import { useEffect } from "react";
import SelectProfessional from "pages/HomeCarePortal/SelectProfessional";
import { connect } from "react-redux";
import Notification from "./Notification";
export default function NurseModal(props) {
  const {
    orderId,
    nurseData,
    isShowModal,
    setIsShowModal,
    nurseModalText,
    professionals,
    onSave,
    setMessage,
    setIsError,
  } = props;
  const { expected_hours_of_work, professional_id } = nurseData ?? {};
  console.log("professionalid", professional_id, professionals);
  const findProfessional = (id) => {
    return professionals.find((p) => p.id === id);
  };
  const [professional, setProfessional] = useState(
    findProfessional(professional_id) ?? null
  );
  const [expectedHoursOfWork, setExpectedHoursOfWork] = useState(
    expected_hours_of_work
  );
  const [errors, setErrors] = useState({});

  const validate = () => {
    const errorsCheck = {};
    if (!professional) {
      errorsCheck.professional = "Name cannot be blank!";
    }

    if (!expectedHoursOfWork) {
      errorsCheck.expectedHoursOfWork =
        "Expected Hours of Work cannot be blank!";
    }

    if (Object.keys(errorsCheck).length > 0) {
      console.log("errors ", errorsCheck);
      setErrors({ ...errorsCheck });
      setIsError(true);
      return true;
    } else {
      setIsError(false);
      setErrors({ ...errorsCheck });
      return false;
    }
  };

  const handleOnSave = () => {
    const errorsModal = validate();
    console.log("handle save calling", errorsModal, errors);
    if (errorsModal) {
      setMessage("Invalid fields are there");
      console.log("Invalid fields are there");
    } else {
      setMessage("");
      const details = {
        orderId,
        professionalId: professional.id,
        expectedHoursOfWork,
      };
      onSave(details);
      setIsShowModal(false);
    }
  };

  return (
    <>
      <div className="alert-modal">
        <div
          className="alert-overlay"
          style={{ overflow: isShowModal ? "hidden" : "" }}
        >
          <div className="alert-modal-content">
            <div className="alert-modal-header">
              {nurseModalText == "assign" ? "Assign Nurse" : "Change Nurse"}
            </div>
            <div className="close-modal" onClick={() => setIsShowModal(false)}>
              <img
                src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg"
                alt="cross-icon"
              />
            </div>
            <div className="alert-modal-body">
              <div className="title">
                Name Professional <span className="star">*</span>
              </div>
              <div>
                <SelectProfessional
                  professionals={professionals ?? []}
                  selectedProfessionalId={professional?.id ?? null}
                  onSelect={(option) => {
                    console.log("Selected option: ", option);
                    setProfessional(findProfessional(option));
                  }}
                />
                {errors.professional && (
                  <div className="error">{errors.professional}</div>
                )}
              </div>
              <br />
              <div className="title">
                Expected hours of work <span className="star">*</span>
              </div>
              <input
                value={expectedHoursOfWork ?? ""}
                onChange={(e) =>
                  setExpectedHoursOfWork(e.target.valueAsNumber ?? null)
                }
                type="number"
                id="expectedHoursOfWork"
                name="expectedHoursOfWork"
                className="form-control py-2"
              />
              {errors.expectedHoursOfWork && (
                <div className="error">{errors.expectedHoursOfWork}</div>
              )}

              <div
                className="save-button"
                onClick={() => {
                  handleOnSave();
                }}
              >
                Save
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
