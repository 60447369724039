import  PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
import {useState} from "react";
import moment from "moment";
const BASE_URL_SUPPLEMENT_CATEGORIES = "supplement-category/";
const BASE_URL_SUPPLEMENTS = "supplement/";
const BASE_URL_SUPPLEMENTS_POST="admin/v2/supplement/";
const BASE_URL_SUPPLEMENTS_SUMMARY = "supplement/summary/";
const BASE_URL_SUPPLEMENTS_DELIVERY_CHARGES="supplement-delivery-charges/";
const BASE_URL_SUPPLEMENTS_DELIVERY_CHARGES_COUNTRY="supplement-delivery-charges/countryId/";
const BASE_URL_SUPPLEMENT_CATEGORIES_All="supplement-category/v2/admin";

export const fetchSupplementCategories = async () => {
  try {
    const { data: supplementCategories = [] } = await PhoenixAPI.get(
      BASE_URL_SUPPLEMENT_CATEGORIES_All 
    );
    return supplementCategories;
  } catch (error) {
    console.log("Error on fetching supplement categories", error);
    throw error;
  }
};

export const saveSupplementCategory = async (supplementCategory) => {
  if (supplementCategory === null) return;

  const config = {
    method: supplementCategory.id ? "PATCH" : "POST",
    url: supplementCategory.id
      ? `${BASE_URL_SUPPLEMENT_CATEGORIES}${supplementCategory.id}`
      : BASE_URL_SUPPLEMENT_CATEGORIES,
    data: supplementCategory,
  };
  return await PhoenixAPI.request(config);
};

export const fetchSupplements = async () => {
  try {
    const { data: supplements = [] } = await PhoenixAPI.get(
      BASE_URL_SUPPLEMENTS
    );
    return supplements;
  } catch (error) {
    console.log("Error on fetching supplements", error);
    throw error;
  }
};

export const fetchSupplementsSummary = () => {
  return PhoenixAPI.get(BASE_URL_SUPPLEMENTS_SUMMARY);
};

export const fetchSupplementsSummaryByCountry = (countryId) => {
  if (!countryId) {
    console.log(
      "fetchSupplementsSummaryByCountry:: Cannot fetch! Invalid country id",
      { countryId }
    );
    return null;
  }

  return PhoenixAPI.get(BASE_URL_SUPPLEMENTS_SUMMARY, {
    params: { country: countryId },
  });
};

export const fetchSupplementById = (supplementId) => {
  if (!supplementId) {
    console.log(
      "fetchSupplementById:: Cannot fetch! Invalid supplement id",
      { supplementId }
    );
    return null;
  }

  return PhoenixAPI.get(`${BASE_URL_SUPPLEMENTS}${supplementId}`);
};

export const saveSupplement = async (supplement) => {
  try {
    const payload = { ...supplement };
   /* delete payload["image"];
    delete payload["image_src"];
    delete payload["valeoPartnerId"];
    delete payload["valeoPartnerName"];*/

   // const otherImageFiles = getOtherImageFiles(payload);
     delete payload["freqBoughtSupps"]
    const formData = new FormData();
    formData.append("supplements", JSON.stringify(payload));
    //formData.append("image", supplement.image);
   // otherImageFiles.forEach((it) => formData.append("supplementsImages", it));

    const apiOptions = {
      method: "post",
      url: BASE_URL_SUPPLEMENTS_POST,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating supplement", error);
    throw error;
  }
};

export const updateSupplement = async (supplement) => {
  try {
    
    /*delete payload["image_src"];
    delete payload["valeoPartnerId"];
    delete payload["valeoPartnerName"];
    if (typeof supplement.image !== "string") delete payload["image"];*/
    
  //  const otherImageFiles = getOtherImageFiles(payload);
    const payload = { ...supplement };
    console.log("payload",payload);
   // delete payload["createdAt"];
   // delete payload["modifiedAt"];
    delete payload["freqBoughtSupps"]
   await new Promise((resolve) => {
    deleteKey(payload, "createdat", "modifiedat");
    resolve();
  });
  
    console.log("payload is",payload);
    const formData = new FormData();
    
    //formData.append("supplements", JSON.stringify(payload));
    /*if (typeof supplement.image !== "string")
      formData.append("image", supplement.image);*/

   // otherImageFiles.forEach((it) => formData.append("supplementsImages", it));

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_SUPPLEMENTS_POST}${supplement.id}`,
      data: payload,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on updating supplement", error);
    throw error;
  }
};

export const saveSearchTagDocument=async(response,supplement)=>{
let dateTime=new Date();
console.log("save search");
const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1; // Months are 0-indexed
  const day = dateTime.getDate();
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  const seconds = dateTime.getSeconds();

  const numericalFormat = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}`;
  const dateform = moment(dateTime).format("DD/MM/YYYY");
  console.log("checking",supplement?.shortDescription)
  const payload={
  "docId":supplement?.searchDocId==null?Date.now():supplement?.searchDocId,

  "itemId":supplement.id? supplement.id:response?.data?.id,
  "itemCategory": "Supplement",
  "productNameH1":supplement?.externalNameEnglish?.replace(/['"]/g, ''),
  "descriptionH2":supplement?.shortDescriptionEnglish?supplement?.shortDescriptionEnglish?.replace(/['"]/g, ''):"",
   "whatsIncluded":"",
  "relatedSymptoms":"",
  "dateAdded":dateform,
  "imageS3Url":Object.keys(supplement?.image).length>0?supplement?.image:response.data.image,
  "thumbnailS3Url":Object.keys(supplement?.image).length>0?supplement?.image:response.data.image,
  "price":supplement?.finalPrice,
  "seoUrl":supplement?.seoUrl,
  "searchTags":supplement?.searchTags?.replace(/['"]/g, ''),
}
let url="create/search-document/"+supplement?.country
 return await PhoenixAPI.post(url,payload);

}

function deleteKey(obj, keyToDelete,keyAnother) {
  Object.keys(obj).forEach(key => {
    if (key.toLowerCase() == keyToDelete  || key.toLowerCase() == keyAnother) {
      console.log("delete",key);
      delete obj[key];
    }
     let value = obj[key];
    if (value instanceof Object) {
      deleteKey(value, keyToDelete,keyAnother);
    }
  });
  
}
export const updateSearchTagDocument=async(supplement)=>{
  let dateTime=new Date();
  const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1; // Months are 0-indexed
    const day = dateTime.getDate();
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    const seconds = dateTime.getSeconds();
  
    const numericalFormat = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}${hours.toString().padStart(2, '0')}${minutes.toString().padStart(2, '0')}${seconds.toString().padStart(2, '0')}`;
    const dateform = moment(dateTime).format("DD/MM/YYYY");
    const payload={
    "docId":supplement?.es_doc_id,
  
    "itemId": supplement?.id,
    "itemCategory": "supplement",
    "productNameH1":supplement?.externalNameEnglish,
    "descriptionH2":supplement?.shortDescription,
    "whatsIncluded":"",
    "relatedSymptoms":"",
    "dateAdded":dateform,
    "imageS3Url":supplement?.image,
    "thumbnailS3Url":supplement?.image_src,
    "price":supplement?.finalPrice,
    "seoUrl":supplement?.seoUrl,
    "searchTags":supplement?.searchTags,
  }
  let url="create/search-document/"+supplement?.country
   return await PhoenixAPI.post(url,payload);
  
  }

const getOtherImageFiles = (payload) => {
  const { supplementsImages } = payload ?? {};
  if (!supplementsImages || !supplementsImages.length) return [];

  const otherImageFiles = [];
  const newSupplementsImages = supplementsImages.map((supplementImage) => {
    const newSupplementImage = { ...supplementImage };
    if (
      newSupplementImage.image &&
      typeof newSupplementImage.image !== "string"
    ) {
      otherImageFiles.push(newSupplementImage.image);
      newSupplementImage.image = newSupplementImage.image.name;
    } else {
      otherImageFiles.push(null);
    }
    delete newSupplementImage.image_src;
    return newSupplementImage;
  });
  payload.supplementsImages = newSupplementsImages;
  return otherImageFiles;
};

export const saveSupplementDeliveryFee=async(deliveryFee)=>{
  if(deliveryFee===null) return;
   const config = {
    method: deliveryFee.id ? "PATCH" : "POST",
    url: deliveryFee.id
      ? `${BASE_URL_SUPPLEMENTS_DELIVERY_CHARGES}${deliveryFee.id}`
      : BASE_URL_SUPPLEMENTS_DELIVERY_CHARGES,
    data: deliveryFee,
  };
  return await PhoenixAPI.request(config);
} 

export const fetchSupplementsDeliveryFeeByCountry = (countryId) => {
  if (!countryId) {
    console.log(
      "fetchSupplementsSummaryByCountry:: Cannot fetch! Invalid country id",
      { countryId }
    );
    return null;
  }

  return PhoenixAPI.get(`${BASE_URL_SUPPLEMENTS_DELIVERY_CHARGES_COUNTRY}${countryId}`)
   
};
export const fetchSupplementIdOrders=async(supplementId)=>{
  if(!supplementId)
{
  return null;
}
const url = `package-orders/Supplement/${supplementId}?page=1&size=25&ordering=-id`;
    return  await PhoenixAPI.get(url);

};
const SupplementService = {
  fetchSupplementCategories,
  saveSupplementCategory,
  fetchSupplements,
  fetchSupplementsSummary,
  fetchSupplementsSummaryByCountry,
  fetchSupplementById,
  saveSupplement,
  saveSearchTagDocument,
  updateSearchTagDocument,
  updateSupplement,
  saveSupplementDeliveryFee,
  fetchSupplementsDeliveryFeeByCountry,
  fetchSupplementIdOrders,
};

export default SupplementService;
