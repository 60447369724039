import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select"; 
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectCustomTestAddonCtreateOrder.propTypes = {

  selectedCustomPackageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  countryId: PropTypes.number.isRequired,
  cityId: PropTypes.number.isRequired,
  packageId: PropTypes.number.isRequired,
};

SelectCustomTestAddonCtreateOrder.defaultProps = {
 
  selectedCustomPackageIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: true,
  isDisabled: false,
 
};
const BASE_URL_CUSTOM_ADDONS="/custom-package/add-ons";

function SelectCustomTestAddonCtreateOrder(props) {
  const {
 
    selectedCustomPackageIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
    countryId,
    cityId,
    packageId,
    
  } = props;

  const [customPackages, setCustomPackages] = useState([]);

   console.log("custom packages",customPackages);
   
  const findCustomPackage = (id) => customPackages.find((it) => it.id === id);
  
  const options = createOptions(customPackages, showOnlyActive);
  useEffect(()=>{
    const findCustomAddonsByCustomId=async()=>{
      
      try{
        console.log("custom addons",BASE_URL_CUSTOM_ADDONS,countryId,cityId);
        const{data:custompackages=[]}= await PhoenixAPI.get(`${BASE_URL_CUSTOM_ADDONS}/${countryId}/${cityId}/${packageId}`);
     
        setCustomPackages(custompackages);
      }catch (error) {
        console.log("Error on fetching supplements", error);
      }
    };
     findCustomAddonsByCustomId();
    
  },[packageId])
  
  
  const selectedCustomPackages = selectedCustomPackageIds
  .map(findCustomPackage)
  .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };
  return (
    <Select
      key={`my_unique_select_key__${
        selectedCustomPackageIds && selectedCustomPackageIds.join(",")
      }`}
      value={selectedCustomPackages}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(customPackages, showOnlyActive) {
  console.log("custom packages", customPackages);
  if (!customPackages || !customPackages.length) return [];
  console.log("custom packages", customPackages);
  
  customPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  if (showOnlyActive) return customPackages.map(createOption);

 
  return [
    {
      label: "Active",
      options: customPackages.map(createOption),
    },
  
  ];
}

function createOption(customPackage) {
  if (!customPackage) return;
  return {
    key: customPackage.id,
    value: customPackage.value ||customPackage.id,
    label: customPackage.name,
  };
}

export default SelectCustomTestAddonCtreateOrder;
