export function filterList(list, type) {
  let newList = [];

  if (type === "active") {
    for (let index in list) {
      if (list[index].is_active) {
        let item = list[index];
        item.index = index;
        newList.push(item);
      }
    }
  } else if (type === "archived") {
    for (let index in list) {
      if (!list[index].is_active) {
        let item = list[index];
        item.index = index;
        newList.push(item);
      }
    }
  } else if (type === "M") {
    for (let index in list) {
      if (list[index].gender === "M") {
        let item = list[index];
        item.index = index;
        newList.push(item);
      }
    }
  } else if (type === "F") {
    for (let index in list) {
      if (list[index].gender === "F") {
        let item = list[index];
        item.index = index;
        newList.push(item);
      }
    }
  }

  return newList;
}

export function getActiveCount(list) {
  let count = 0;

  for (let item of list) {
    if (item.is_active) count++;
  }

  return count;
}
