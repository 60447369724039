import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import CountryDropdown from "components/Common/CountryDropdown";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import {
  fetchLongevityCategories,
  saveCategory,
  updateCategory,
  fetchNewLongevityCategories,
} from "services/LongevityService";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Link } from "react-router-dom";
import Select from "react-select";
import Status from "components/Common/Status";
import SuggestedDefaultPackageForm from "./components/SuggestedDefaultPackageForm";
import SuggestedArticles from "../LongevityScore/components/SuggestedArticles";
import SupplementSugestions from "./components/SupplementSugesstions";
import { fetchSupplementsSummaryByCountry } from "services/SupplementService";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const GenderOptions = [
  {
    key: "M",
    text: "Male",
    value: "M",
  },
  {
    key: "F",
    text: "Female",
    value: "F",
  },
];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class LongevityScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      categories: [],
      category: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isCategoryViewModal: false,
      bloodPackages: [],
      bloodPackagesOptions: [],

      wellbeingCoaches: [],
      suggestedDefaultPackages: [],
      customPackages: [],
      foodPlans: [],
      fitnessPlans: [],
      sleepPlans: [],
      supplementPlans: [],
      articleOptions: [],
      requiredSupplements: [],
      fitnessPlanSupplements: [],
      sleepPlanSupplements: [],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Category",
        selector: "categoryName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Gender",
        selector: "gender",
        wrap: true,
        sortable: true,
      },
      {
        name: "Age Between",
        selector: "age",
        wrap: true,
        sortable: true,
        cell: (category) => this.renderAgeBetween(category),
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",
        center: true,
        cell: (category) => this.renderEditButton(category.id),
      },
      {
        name: "Manage",
        center: true,
        cell: (category) => this.renderManageButton(category.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([this.fetchAclUser(), this.fetchCountries()]);
    this.fetchLongevityCategories();
    this.fetchBloodPackages();
    this.fetchArticles();
    this.fetchSupplements();
  }
  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.fetchLongevityCategories();
      this.fetchArticles();
      this.fetchSupplements();
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OTHER_ITEMS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  createSuggestedDefaultPackage = (suggestedDefaultPackage) => {
    const postUrl = "longevity-default-custom-package-suggestions";
    PhoenixAPI.post(postUrl, { suggestedDefaultPackage });
    this.fetchLongevityCategories();
  };
  updateSuggestedDefaultPackage = (suggestedDefaultPackage) => {
    const postUrl = "longevity-default-custom-package-suggestions";
    PhoenixAPI.patch(postUrl, { suggestedDefaultPackage });
    this.fetchLongevityCategories();
  };

  fetchLongevityCategories = async () => {
    this.showLoading();
    try {
      const categories = (await fetchNewLongevityCategories()) || [];
      console.log("Categoriedss", categories);
      const requiredSupplements = categories?.requiredSupplements ?? [];
      this.setState({
        categories: categories.longevityCategories,
        suggestedDefaultPackages: categories.defaultCustomPackages,
        foodPlans: categories.foodPlans,
        sleepPlans: categories.sleepPlans,
        fitnessPlans: categories.fitnessPlans,
        supplementPlans: categories.supplementPlans,
        requiredSupplements: requiredSupplements ?? [],
        isFitnessPlanSupplementRequired:
          requiredSupplements[0]?.isFitnessPlanSupplementRequired ?? false,
        isSleepPlanSupplementRequired:
          requiredSupplements[0]?.isSleepPlanSupplementRequired ?? false,
        fitnessPlanSupplements: categories?.fitnessPlanSupplements ?? [],
        sleepPlanSupplements: categories?.sleepPlanSupplements ?? [],
      });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Longevity Categories:",
        error.message
      );
      this.setState({ loadError: "Some Error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchBloodPackages = async () => {
    this.showLoading();
    try {
      const { data: bloodPackages = [] } = await PhoenixAPI.get(
        "/bloods/packages"
      );
      const countryBloodPackages =
        bloodPackages.length > 0 &&
        bloodPackages.filter(
          (it) => it.country == this.state.selectedCountry.id
        );
      const activeBloodPackages = countryBloodPackages.filter(
        (it) => it.is_active === true
      );
      const bloodPackagesOptions =
        activeBloodPackages.length > 0 &&
        activeBloodPackages.map((obj) => {
          return {
            ...obj,
            key: obj.subscription_name,
            value: obj.subscription_name,
            label: obj.subscription_name,
          };
        });
      this.setState({ bloodPackagesOptions, bloodPackages });
    } catch (error) {
      console.log(
        "Error in Fetching in Blood Biomarker Packages",
        error.message
      );
      this.showErrorNotification(
        "Error in Fetching in Blood Biomarker Packages",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) => {
    const { bloodPackages } = this.state;
    const countryBloodPackages = bloodPackages.filter(
      (it) => it.country == selectedCountry.id
    );
    const activeBloodPackages = countryBloodPackages.filter(
      (it) => it.is_active === true
    );
    const bloodPackagesOptions =
      activeBloodPackages.length > 0 &&
      activeBloodPackages.map((obj) => {
        return {
          ...obj,
          key: obj.subscription_name,
          value: obj.subscription_name,
          label: obj.subscription_name,
        };
      });
    this.setState({ selectedCountry, bloodPackagesOptions });
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNewCategory = () => {
    this.setState({ isNewCategory: true }, () => this.showCategoryViewModal());
  };
  handleViewCategory = (categoryId) => {
    const categories = this.state.categories ?? [];
    const category = categories.find((it) => it.id === categoryId);
    if (!category) {
      console.log(`Category with id ${categoryId} is not found!`);
      return;
    }
    this.setState(
      {
        category,
        isNewCategory: false,
      },
      () => this.showCategoryViewModal()
    );
  };

  showCategoryViewModal = () => {
    const { selectedCountry, isNewCategory } = this.state;
    if (isNewCategory) {
      const category = {
        country: selectedCountry?.id,
        isActive: false,
      };
      this.setState({
        category,
        isCategoryViewModal: true,
      });
    } else {
      this.setState({ isCategoryViewModal: true });
    }
  };

  handleSaveCategory = async () => {
    const { category } = this.state;
    try {
      this.showLoading();
      const isValidId = category.id !== null && category.id !== undefined;
      const response = isValidId
        ? await updateCategory(category)
        : await saveCategory(category);
      this.hideCategoryViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Category Updated Successfully."
          : "Category Created Successfully."
      );
      this.fetchLongevityCategories();
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Longevity Categories:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  createBloodPackageOption = (category) => {
    return {
      key: category.bloodPackageId,
      value: category.bloodPackageId,
      label: category.bloodPackageName,
    };
  };

  handleBiomarkerChange = (packageName) => {
    const { bloodPackages, bloodPackagesOptions, category } = this.state;
    const selectedId = bloodPackagesOptions.find(
      (it) => it.subscription_name === packageName
    );
    this.setState({
      category: {
        ...category,
        bloodPackageId: selectedId.id,
        bloodPackageName: selectedId.subscription_name,
      },
    });
  };

  hideCategoryViewModal = () => {
    this.setState({ isCategoryViewModal: false });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const category = { ...this.state.category };
    category[input.name] = input.value;
    this.setState({ category });
  };

  resetForm = () => {
    this.setState({
      category: {},
      errors: {},
      editables: {},
    });
  };
  renderAgeBetween = (category) => {
    const { startAge, endAge } = category || {};
    return <p>{`${startAge}-${endAge}`}</p>;
  };

  getFilteredCategories = () => {
    const categories = this.getCategoriesForCurrentCountry();
    return this.getSearchedCategories(categories);
  };

  getCategoriesForCurrentCountry = () => {
    const { categories, selectedCountry } = this.state;
    return categories && selectedCountry
      ? categories.filter((category) => category.country === selectedCountry.id)
      : [];
  };

  getSearchedCategories = (categories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return categories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return categories.filter((category) => {
      const { id, categoryName, gender } = category;
      return (
        (categoryName && categoryName.toLowerCase().indexOf(newValue) !== -1) ||
        (gender && gender.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  renderEditButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCategory(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderManageButton = (id) => {
    const { selectedCountry = {} } = this.state;
    return (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#FAE5D3" }}
          to={`/dashboard/longevity-parameters/all/${id}/${selectedCountry.id}`}
        >
          Manage
        </Link>
      </div>
    );
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleSuggestedDefaultPackageChange = (suggestedDefaultPackage) => {
    if (
      suggestedDefaultPackage.id === null ||
      suggestedDefaultPackage.id === undefined
    ) {
      this.createSuggestedDefaultPackage(suggestedDefaultPackage);
    } else {
      this.updateSuggestedDefaultPackage(suggestedDefaultPackage);
    }
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  fetchDefaultCoachDetails = async () => {
    try {
      this.showLoading();
      const url = `nutritionist-user-name/`;
      const { data = [] } = await PhoenixAPI.get(url);
      let coachDetails = [
        {
          text: "None",
          value: null,
          key: "None",
          default_coach_id: null,
          is_active: false,
        },
      ];
      data.length > 0 &&
        data.map((item) => {
          let coach = {};
          coach["text"] = item.full_name;
          coach["value"] = item.id;
          coach["key"] = item.full_name;
          coach["default_coach_id"] = item.id;
          coach["is_active"] = item.is_active;
          coachDetails.push(coach);
        });
      this.setState({ wellbeingCoaches: coachDetails });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching default coach details ! " + error.message
      );
      return null;
    } finally {
      this.hideLoading();
    }
  };

  fetchCustomPackages = async () => {
    const customPackageUrl = "custom/packages";
    try {
      this.showLoading();
      const { data = [] } = await PhoenixAPI.get(customPackageUrl);

      let customPackage = [{ text: "None", value: null, key: "None" }];
      data.length > 0 &&
        data.map((item) => {
          let packagedetail = {};
          packagedetail["text"] = item.name;
          packagedetail["value"] = item.id;
          packagedetail["key"] = item.id;

          customPackage.push(packagedetail);
        });

      this.setState({ customPackages: customPackage });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching custom packages! " + error.message
      );
      return null;
    } finally {
      this.hideLoading();
    }
  };

  fetchArticles = async () => {
    const { selectedCountry } = this.state;
    console.log("country", selectedCountry.id);
    const articlesUrl = "health-hub";
    const { data = [] } = await PhoenixAPI.get(articlesUrl);
    let articles = [];
    data.length > 0 &&
      data
        .filter(
          (item) =>
            item.status == "Active" &&
            item.country === this.state.selectedCountry?.id
        )
        .map((item) => {
          let articledetail = {};
          articledetail["text"] = item.articleNameEnglish;
          articledetail["value"] = item.id;
          articledetail["key"] = item.id;

          articles.push(articledetail);
        });

    this.setState({ articleOptions: articles });
    console.log("articles", articles);
  };

  fetchSupplements = async () => {
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
      console.log("fetchSupplementsSummaryByCountry", supplements);
      this.setState({ supplements });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleRequiredSupplement = async (event, plan) => {
    let {
      isFitnessPlanSupplementRequired = false,
      isSleepPlanSupplementRequired = false,
      requiredSupplements = [],
    } = this.state;

    if (plan == "fitnessPlan") {
      isFitnessPlanSupplementRequired = event.target.checked ?? false;

      // this.setState({isFitnessPlanSupplementRequired:event.target.checked ?? false})
    } else if (plan == "sleepPlan") {
      isSleepPlanSupplementRequired = event.target.checked ?? false;

      // this.setState({isSleepPlanSupplementRequired:event.target.checked ?? false})
    }
    try {
      this.showLoading();
      const payload = {
        id: requiredSupplements[0].id,
        isSupplementPlanSupplementRequired: false,
        isSleepPlanSupplementRequired: isSleepPlanSupplementRequired,
        isFoodPlanSupplementRequired: false,
        isFitnessPlanSupplementRequired: isFitnessPlanSupplementRequired,
      };
      const response = await PhoenixAPI.patch(
        `/longevity-plans-required-supplement/${requiredSupplements[0].id}`,
        payload
      );
      this.fetchLongevityCategories();
    } catch (error) {
      console.log("Error in saving required-supplement", error.message);
      this.showErrorNotification(
        "Error in saving required-supplement",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleSaveSupplementSuggestion = async () => {
    await this.fetchLongevityCategories();
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      categories,
      category,
      isNewCategory,
      bloodPackagesOptions,
      bloodPackages,
      wellbeingCoaches,
      suggestedDefaultPackages,
      customPackages,
      foodPlans,
      fitnessPlans,
      sleepPlans,
      supplementPlans,
      articleOptions,
      isFitnessPlanSupplementRequired,
      isSleepPlanSupplementRequired,
      fitnessPlanSupplements,
      sleepPlanSupplements,
      supplements,
    } = this.state;

    const filteredCategories = this.getFilteredCategories(categories) ?? [];

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const categoryInfoForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="categoryName">Category Name</label>
              <div className="input-group">
                <input
                  value={category?.categoryName || ""}
                  onChange={this.handleChange}
                  id="categoryName"
                  name="categoryName"
                  type="text"
                  placeholder="Category Name"
                  className={"form-control py-2"}
                  disabled={!isNewCategory && !editables.categoryName}
                />
                {this.renderPencilIcon("categoryName")}
              </div>
              <span className="help-block">{errors.categoryName}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="status">Status</label>
              <div className="input-group">
                <Dropdown
                  value={category.isActive}
                  onChange={(event, value) => {
                    this.setState({
                      category: {
                        ...category,
                        isActive: value.value,
                      },
                    });
                  }}
                  id="status"
                  name="status"
                  placeholder="Status"
                  search
                  selection
                  options={STATUS_OPTIONS}
                  disabled={!isNewCategory && !editables.status}
                  className={
                    "form-control" + (errors.status ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("status")}
              </div>
              <span className="help-block">{errors.status}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="gender">Gender</label>
              <div className="input-group">
                <Dropdown
                  id="gender"
                  name="gender"
                  value={category?.gender}
                  placeholder="Select Gender"
                  onChange={(event, value) => {
                    this.setState({
                      category: {
                        ...category,
                        gender: value.value,
                      },
                    });
                  }}
                  search
                  selection
                  options={GenderOptions || []}
                  disabled={!isNewCategory && !editables.gender}
                  className={
                    "form-control" + (errors.status ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("gender")}
              </div>
              <span className="help-block">{errors.gender}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="ageBetween">Age Between</label>
              <div className="input-group">
                <input
                  value={category?.startAge || ""}
                  onChange={this.handleChange}
                  id="startAge"
                  name="startAge"
                  type="number"
                  placeholder="From"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={category?.endAge || ""}
                  onChange={this.handleChange}
                  id="endAge"
                  name="endAge"
                  type="number"
                  placeholder="To"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  className={"form-control"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <label htmlFor="ageBetween">Color range for Score</label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="redRange">Red Range</label>
              <div className="input-group">
                <input
                  value={category && category.startRedColorRange}
                  onChange={this.handleChange}
                  id="startRedColorRange"
                  name="startRedColorRange"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={category && category.endRedColorRange}
                  onChange={this.handleChange}
                  id="endRedColorRange"
                  name="endRedColorRange"
                  type="number"
                  placeholder="To"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="yellowRange">Yellow Range</label>
              <div className="input-group">
                <input
                  value={category && category.startYellowColorRange}
                  onChange={this.handleChange}
                  id="startYellowColorRange"
                  name="startYellowColorRange"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={category && category.endYellowColorRange}
                  onChange={this.handleChange}
                  id="endYellowColorRange"
                  name="endYellowColorRange"
                  type="number"
                  placeholder="To"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="greenRange">Green Range</label>
              <div className="input-group">
                <input
                  value={category && category.startGreenColorRange}
                  onChange={this.handleChange}
                  id="startGreenColorRange"
                  name="startGreenColorRange"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={category && category.endGreenColorRange}
                  onChange={this.handleChange}
                  id="endGreenColorRange"
                  name="endGreenColorRange"
                  type="number"
                  placeholder="To"
                  className={"form-control "}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="biomarker">Blood Biomarker Package</label>
              <Select
                key={`my_unique_select_key__${category && category.id}`}
                value={category && this.createBloodPackageOption(category)}
                onChange={(event) => {
                  this.handleBiomarkerChange(event.value);
                }}
                options={this.state.bloodPackagesOptions || []}
                style={selectStyles}
                placeholder="Select Biomarker"
                searchable
                lazyLoad
              />
            </div>
          </div>
        </div>
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewCategory}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Category
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Category</p>
          <DataTable
            data={filteredCategories || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <p className="font-weight-bold">Meal Plan</p>
        <SuggestedDefaultPackageForm
          suggestedDefaultPackages={suggestedDefaultPackages}
          wellbeingCoaches={wellbeingCoaches}
          country={selectedCountry?.id}
          isEditable={isEditable}
        />

        <SuggestedArticles
          suggestedDefaultArticles={foodPlans}
          isEditable={isEditable}
          heading={"Food"}
          country={selectedCountry?.id}
          articleOptions={articleOptions}
        />

        <p className="font-weight-bold">Fitness Plan</p>
        <SuggestedArticles
          suggestedDefaultArticles={fitnessPlans}
          isEditable={isEditable}
          heading={"Fitness"}
          country={selectedCountry?.id}
          articleOptions={articleOptions}
        />
        <div className="input-group" style={{ marginLeft: "10px" }}>
          <input
            value={true}
            checked={isFitnessPlanSupplementRequired ?? false}
            onChange={(event, value) => {
              this.handleRequiredSupplement(event, "fitnessPlan");
            }}
            id="isRequired"
            name="isRequired"
            type="checkbox"
            className="larger-checkbox"
          />
          <label style={{ marginLeft: "10px", marginTop: "10px" }}>
            is Default Suggested Supplements Required for Fitness Plan
          </label>
        </div>
        <SupplementSugestions
          suggestedSupplements={supplements ?? []}
          countryId={selectedCountry?.id}
          suggestions={fitnessPlanSupplements ?? []}
          isEditable={true}
          plan={"fitnessPlan"}
          suggestionsUpdate={this.handleSaveSupplementSuggestion}
        />
        <p className="font-weight-bold">Sleep Plan</p>
        <SuggestedArticles
          suggestedDefaultArticles={sleepPlans}
          isEditable={isEditable}
          heading={"Sleep"}
          country={selectedCountry?.id}
          articleOptions={articleOptions}
        />
        <div className="input-group" style={{ marginLeft: "10px" }}>
          <input
            value={true}
            checked={isSleepPlanSupplementRequired ?? false}
            onChange={(event, value) => {
              this.handleRequiredSupplement(event, "sleepPlan");
            }}
            id="isRequired"
            name="isRequired"
            type="checkbox"
            className="larger-checkbox"
          />
          <label style={{ marginLeft: "10px", marginTop: "10px" }}>
            is Default Suggested Supplements Required for Sleep Plan
          </label>
        </div>
        <SupplementSugestions
          suggestedSupplements={supplements ?? []}
          countryId={selectedCountry?.id}
          suggestions={sleepPlanSupplements ?? []}
          isEditable={true}
          plan={"sleepPlan"}
          suggestionsUpdate={this.handleSaveSupplementSuggestion}
        />
        <p className="font-weight-bold">Supplement Plan</p>
        <SuggestedArticles
          suggestedDefaultArticles={supplementPlans}
          isEditable={isEditable}
          heading={"Supplement"}
          country={selectedCountry?.id}
          articleOptions={articleOptions}
        />

        <Modal
          size="lg"
          show={this.state.isCategoryViewModal}
          onHide={this.hideCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  {category.id ? "Edit Parent Category" : "Add Parent Category"}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveCategory}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{categoryInfoForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LongevityScore);
