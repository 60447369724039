import React, { useEffect, useState } from "react";

 export const SelectingAddress=({address={}, handleClick,selected=null})=>{
  const [selectedCard, setSelectedCard] = useState(false);
    const {buildingApt=null,addressLine1=null,addressLine2=null,city=null,country=null, mapUrl=null}= address??{};
        let combinedAddress=[];
        if(buildingApt){
        combinedAddress.push(buildingApt)
        }
        if(addressLine1){
          combinedAddress.push(addressLine1)
        }
        if(addressLine2){
          combinedAddress.push(addressLine2)
        }
        if(city){
          combinedAddress.push(city)
        }
        if(country){
          combinedAddress.push(country)
        }
        if(mapUrl){
          combinedAddress.push(mapUrl)
        }
        const setFinalAddress=combinedAddress?.join(", ")??"";

        const handleSelectAddress=(address)=>{
          setSelectedCard((prevState) => !prevState);
          handleClick(address)
        }
  return (
    <div style={{display:"flex", padding:"2px",gap:"5px"}}>
    <div  onClick={()=>handleSelectAddress(address)}>
    <CheckBox isChecked={address.id ==selected}  isDisable={false}/>
    </div>
    {setFinalAddress}
    </div>
  )
}

const CheckBox = ({ isChecked, isDisable }) => {
    return (
        <>
        <div >
          <input
            checked={isChecked}
            type="checkbox"
          />
         
        </div>
      </>
    );
  };