import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class GeneralSurvey extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "questionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required."
      },
      {
        field: "questionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required."
      },
      {
        field: "categoryId",
        method: "isEmpty",
        validWhen: false,
        message: "Category is required."
      }
    ]);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      questions: null,
      isLoaded: false,
      loadError: "",
      error: "",
      questionEn: "",
      questionAr: "",
      categoryId: "",
      categories: [],
      categoryListFormatted: [],
      formHeading: "Create Question",
      editId: null,
      generalSurveyId: null,
      validation: this.validator.valid(),

      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active"
    };

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true
      },
      {
        name: "Category",
        maxWidth: "150px",
        sortable: true,
        selector: "category_name"
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.columnsReadOnly = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true
      },
      {
        name: "Category",
        maxWidth: "150px",
        sortable: true,
        selector: "category_name"
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={true}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadData();
    this.getGeneralSurveyId();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HEALTH_ASSESSMENT_QUESTIONS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadData() {
    this.loadCategories();
  }

  loadCategories() {
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("survey-category/")
      .then(response => {
        let activeList = response.data.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        activeList = filterList(activeList, "active");

        const categoryListFormatted = activeList.map(category => {
          return {
            text: category.name,
            value: category.id,
            key: category.id
          };
        });

        this.setState(
          {
            categories: response.data,
            categoryListFormatted: categoryListFormatted
          },
          () => {
            this.loadQuestions();
          }
        );
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  loadQuestions() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });

    API.get("generic-questionnaire/")
      .then(response => {
        let newList = response.data;

        for (let item of newList){
          item.category_name = this.getCategoryName(item.category);
          item.is_active = this.ifCategoryActive(item.category, item.is_active);
        }


        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");

        this.setState({
          questions: newList,
          activeList,
          archivedList,
          isLoaded: true
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  getGeneralSurveyId() {
    API.get("generic-survey-list/")
      .then(response => {
        this.setState({ generalSurveyId: response.data[0].id });
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
        this.setState({ generalSurveyId: null });
      });
  }

  getCategoryName(id) {
    const categories = this.state.categories;

    if (categories.length > 0) {
      let category = categories.find(item => {
        return item.id === id;
      });

      if (category) {
        if (category.is_active) return category.name;
        else return category.name + " (Archived)";
      }
    }

    return null;
  }

  ifCategoryActive(id, isActive) {
    const categories = this.state.categories;

    if (categories.length > 0) {
      let category = categories.find(item => {
        return item.id === id;
      });

      if (category) {
        if(isActive){
          if (category.is_active) return true;
          else return false;
        }else{
          return isActive
        }
      }
    }

    return null;
  }

  editQuestion(index, id) {
    let questions = this.state.questions;
    this.setState({
      formHeading: "Edit Question",
      questionEn: questions[index]["question"],
      questionAr: questions[index]["question_ar"],
      categoryId: questions[index]["category"],
      editId: id
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("survey-question/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });
        this.loadData();
      })
      .catch(error => {
        console.log("Error on updating status", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  newFormattedList(list, id) {
    const newList = [...list];
    const item = {
      text: this.getCategoryName(id),
      value: id,
      key: id
    };

    if (newList.find(element => element.key === id)) return newList;

    newList.push(item);
    return newList;
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      editId: null,
      categoryId: "",
      validation: this.validator.valid()
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Question";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Question successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Question";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Question successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const {
      questionEn,
      questionAr,
      categoryId,
      editId,
      generalSurveyId
    } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });

      if (editId !== null) {
        //edit
        API.put("survey-question/" + editId + "/", {
          question: questionEn,
          question_ar: questionAr,
          survey: generalSurveyId,
          category: categoryId
        })
          .then(response => {
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully updated.",
              successMessage: true,
              showNotification: true
            });
            this.loadData();
          })
          .catch(error => {
            console.log("Error on updating question", error);
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      } else {
        if (generalSurveyId) {
          API.post("survey-question/", {
            question: questionEn,
            question_ar: questionAr,
            survey: generalSurveyId,
            category: categoryId
          })
            .then(response => {
              this.props.toggleLoading({
                isLoading: false
              });
              this.props.showNotificationMessage({
                notificationMessage: "Question successfully created.",
                successMessage: true,
                showNotification: true
              });
              this.loadData();
            })
            .catch(error => {
              console.log("Error on creating question", error);
              this.props.toggleLoading({
                isLoading: false
              });
              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true
              });
            });
        } else {
          this.props.toggleLoading({
            isLoading: false
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true
          });
        }
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.questions;

    let newList = [];

    if (!list) return;

    for (let item of list) {
      if (
        item.question.toLowerCase().indexOf(newValue) !== -1 ||
        item.category_name.toLowerCase().indexOf(newValue) !== -1 ||
        item.question_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  render() {
    let form,
      finalList = [];
    const {
      questions,
      editId,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        if (showItems === "Active") finalList = activeList;
        else if (showItems === "Archived") finalList = archivedList;
        else finalList = questions;
      }
    }

    form = (
      <>
        <div className="form-group mb-3">
          <label>Question (English) *</label>
          <input
            type="text"
            name="questionEn"
            className={
              "form-control " + (validation.questionEn.isInvalid && "has-error")
            }
            placeholder="Question in English"
            onChange={this.handleChange}
            value={this.state.questionEn}
          ></input>
          <span className="help-block">{validation.questionEn.message}</span>
        </div>
        <div className="form-group mb-3">
          <label>Question (Arabic) *</label>
          <input
            type="text"
            name="questionAr"
            className={
              "form-control input-arabic " +
              (validation.questionAr.isInvalid && "has-error")
            }
            placeholder="Question in Arabic"
            onChange={this.handleChange}
            value={this.state.questionAr}
          ></input>
          <span className="help-block">{validation.questionAr.message}</span>
        </div>
        <div className="form-group  input-small">
          <label>Category *</label>
          <Dropdown
            value={this.state.categoryId}
            className={
              "form-control " + (validation.categoryId.isInvalid && "has-error")
            }
            placeholder="Select category"
            onChange={(event, value) => {
              this.setState({ categoryId: value.value });
            }}
            search
            selection
            options={
              editId
                ? this.newFormattedList(
                    this.state.categoryListFormatted,
                    this.state.categoryId
                  )
                : this.state.categoryListFormatted
            }
          />
          <span className="help-block">{validation.categoryId.message}</span>
        </div>
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="questions">
        <div className="page-header">
          {/* <h5>General Health Assessment Questions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>

            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
                disabled={!isEditable}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={isEditable ? this.columns : this.columnsReadOnly}
            data={finalList}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results in '" + showItems + "'"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={e => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSurvey);
