const Subscription_TYPE_OPTIONS = [
    {
      key: "Active",
      value: "Active",
      text: "Active",
      id: 1,
    },
    {
      key: "InActive",
      value:"InActive",
      text: "InActive",
      id: 2,
    },
    {
      key: "All",
      value: "All",
      text: "All",
      id:3,
    },
  ];
export default Subscription_TYPE_OPTIONS;