
const CleverTapPushEvent = async (eventName = '', data = {},) => {
    try {
        window.clevertap.event.push(eventName, data)
    } catch (e) {
        console.log('CLEVER TAP ANALYTICS PUSH EVENT VIEW ERROR', e)
    }
}

export const trackBookingsSearchKeyword=(data={})=>CleverTapPushEvent("bookings_search_keyword", data)

export const trackShowOrdersClick = (data = {}) => CleverTapPushEvent("show_order_click", data);

export const trackAcceptingOrderClick=(data={})=>CleverTapPushEvent("accept_upcoming_orders",data);

export const trackDenyingOrderClick=(data={})=>CleverTapPushEvent("deny_upcoming_orders",data);


export const trackSharingLivelocation=(data={})=>CleverTapPushEvent("sharing_live_location",data);

export const trackNurseSearchKeyword=(data={})=>CleverTapPushEvent("nurse-search-keyword", data);

export const trackNurseNotificationClick=(data={})=>CleverTapPushEvent("nurse-click-notification",data);

export const trackProfileClick=(data={})=>CleverTapPushEvent("profile_click", data);

export const trackOpenMapsClick=(data={})=>CleverTapPushEvent("client_open_maps_click", data);

export const trackPhoneClicks=(data={})=>CleverTapPushEvent("phone_no_clicks",data);


export const trackBiomarkersClick=(data={})=>CleverTapPushEvent("biomarkers_click", data);

export const trackUploadSampleOpenOrderSaveClick=(data={})=>CleverTapPushEvent("Id_Save_button_click",data)
export const trackReportIssueClick=(data={})=>CleverTapPushEvent("report_issue_click", data);
export const trackStartButtonClick=(data={})=>CleverTapPushEvent("start_button_click", data)
export const trackClientLabPastordersClicks=(data={})=>CleverTapPushEvent("client_lab_past_orders_click", data);

export const trackUploadSamplePastOrders=(data={})=>CleverTapPushEvent("Upload_sample_past_orders",data);

export const trackClientsLabDetailsOpenOrders=(data={})=>CleverTapPushEvent("client_Lab_details_openorders_click",data)

export const trackAssignProfessionalClick=(data={})=>CleverTapPushEvent("assign_professional_click", data)

export const trackEditClick=(data={})=>CleverTapPushEvent("edit_click",data)
