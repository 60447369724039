import React, { Component } from "react";
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import ParameterDetails from "./ParameterDetails";
import ParameterWeightages from "./ParameterWeightages";
import { fetchCountries } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";

class LongevityParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryId: "",
      countries: [],
      selectedCountry: null,
    };
  }
  componentDidMount() {
    // this.fetchAclUser();
    this.fetchCountries();
    this.fetchCategoryId();
  }

  fetchCategoryId = () => {
    const { categoryId } = this.props.match.params;
    this.setState({ categoryId });
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.BANNERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const { countryId } = this.props.match.params;
      const selectedCountryArr = countries.filter((it) => it.id == countryId);
      this.setState({ countries, selectedCountry: selectedCountryArr[0] });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>{
    this.props.history.goBack();
    this.setState({ selectedCountry });
  }
   

  handleSaveParameter = () => {
    window.location.reload(true);
  };

  render() {
    const { categoryId, countries, selectedCountry } = this.state;

    return (
      <>
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <ParameterDetails
          categoryId={categoryId}
          selectedCountry={selectedCountry}
          onSave={this.handleSaveParameter}
        />
        <ParameterWeightages
          categoryId={categoryId}
          selectedCountry={selectedCountry}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LongevityParameters);
