import React, { Component } from "react";
import DataTable from "react-data-table-component";
import API from "utils/API";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { filterList } from "utils/List";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import FormValidator from "utils/FormValidator";

import { isEmpty } from "utils/FormValidator";
import $ from 'jquery';
import ModalPopup from "components/ModalPopup/ModalPopup";
import timeZones from 'assets/json/timezones';
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import CountryDropdown from "components/Common/CountryDropdown";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class RegionManagment extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: "country_name",
                method: "isEmpty",
                validWhen: false,
                message: "Country Name is required."
            },
        ]);

        this.cityvalidator = new FormValidator([
            {
                field: "city_name",
                method: "isEmpty",
                validWhen: false,
                message: "Country Name is required."
            },
            {
                field: "country",
                method: "isEmpty",
                validWhen: false,
                message: "Country Name is required."
            },
        ]);

        this.state = {
            aclUser: null,
            pageAccessRights: null,
            accessDenied: false,
            isEditable: true,
            showModal: false,
            showCityModal: false,
            blood_analysis: [],
            showConfirm: false,
            confirmItem: null,
            confirmMessage: "",
            confirmType: null,
            country_name: '',
            country_name_ar: '',
            country: '',
            city_name: '',
            city_name_ar: '',
            country_id: '',
            city_id: '',
            position_value:null,
            confirmTitle: "",
            countryList: [],
            countyData: [],
            successMessage: "",
            showCityConfirm: false,
            confirmCityItem: null,
            confirmCityMessage: "",
            confirmCityType: null,
            confirmCityTitle: "",
            successCityMessage: "",
            validation: this.validator.valid(),
            cityvalidation: this.cityvalidator.valid(),
            modalCityData: {},
            modalData: {},
            newUser: false,
            modalCityData: {},
            imgSrc: '',
            flag_icon: '',
            editCity: false,
            editCurrency: false,
            editCurrencyArb: false,
            editEndTime: false,
            editStartTime: false,
            editStatus: false,
            editImage: false,
            editTimeZone: false,
            editvat: false,
            editCountryName: false,
            editCountryNameArabic: false,
            editCountry: false,
            editCountryCode:false,
            country_code:"",
            editCenterLat: false,
            editCenterLng: false,
            editRank:false,
            selectedCountry:null,
            editPartialStatus:false,
        };

        this.columns = [
            {
                name: "Country ID",
                selector: "id",
                sortable: true,
                wrap: true,
            },
            {
                name: "Country",
                selector: "country_name",
                sortable: true,
                wrap: true,
            },
            {
                name: "Country Code",
                selector: "country_code",
                sortable: true,
                wrap: true,
            },
            {
                name: "VAT",
                selector: "vat_percentage",
                sortable: true,
                wrap: true,
            },
            {
                name: "Time Zone",
                selector: "time_zone",
                sortable: true,
                wrap: true,
            },
            {
                name: "Default",
                selector: "is_default",
                sortable: true,
                wrap: true,
                format: (row) =>
                    row.is_default ? 'Yes' : 'No'
            },
            {
                name: "Status",
                selector: "is_active",
                sortable: true,
                wrap: true,
                format: (row) =>
                    row.is_active ? 'Active' : 'Inactive'

            },
            {
                name: "View",
                selector: "id",
                sortable: false,
                format: (row) => <button
                    className="btn button-blue btn-md"
                    onClick={(e) => {
                        this.showModal(row)
                    }}
                >
                    View
                </button>
            }
        ];

        this.CityColumns = [
            {
                name: "City",
                selector: "city_name",
                sortable: true,
                wrap: true,
            },
            {
                name: "Country",
                selector: "country.country_name",
                sortable: true,
                wrap: true,
                format: (row) =>
                    row.country && row.country.country_name ? row.country.country_name : ''
            },
            {
             name:"Rank",
             selector:"position_value",
             sortable:true,
             wrap:true,
             format:(row)=>
                 row.position_value ? row.position_value: ''
            },
            {
                name: "Status",
                selector: "is_active",
                sortable: true,
                wrap: true,
                format: (row) =>
                    row.is_active ? 'Active' : 'Inactive'
            },
            {
                name: "View",
                selector: "id",
                sortable: false,
                format: (row) => <button
                    className="btn button-blue btn-md"
                    onClick={(e) => {
                        this.showCityModal(row)
                    }}
                >
                    View
                </button>
            }
        ];

        this.submitted = false;
        this.citysubmitted = false;
        this.handleChange = this.handleChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.getCountryCityList = this.getCountryCityList.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.hideCityModal = this.hideCityModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.showCityModal = this.showCityModal.bind(this);
        this.showEditModal = this.showEditModal.bind(this);
        this.showCityEditModal = this.showCityEditModal.bind(this);
        this.hideConfirm = this.hideConfirm.bind(this);
        this.showConfirm = this.showConfirm.bind(this);
        this.showCityConfirm = this.showCityConfirm.bind(this);
        this.hideCityConfirm = this.hideCityConfirm.bind(this);
        this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
        this.toggleCityActiveStatus = this.toggleCityActiveStatus.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchExit = this.handleSearchExit.bind(this);
        this.setShowItems = this.setShowItems.bind(this);
        this.createCountry = this.createCountry.bind(this);
        this.createCity = this.createCity.bind(this);
        this.creatNewCountry = this.creatNewCountry.bind(this);
        this.createNewCity = this.createNewCity.bind(this);
        this.selecteImage = this.selecteImage.bind(this);
    }

    showConfirm(confirmItem) {
        let confirmMessage, successMessage, confirmTitle, confirmType;
        if (confirmItem.is_active) {
            confirmTitle = "Deactivate Country";
            confirmMessage = "Are you sure you want to deactivate this Country?";
            successMessage = "Country successfully deactivated.";
            confirmType = "danger";
        } else {
            confirmTitle = "Activate Country";
            confirmMessage = "Are you sure you want to activate this Country?";
            successMessage = "Country successfully activated.";
            confirmType = "success";
        }

        this.setState({
            showConfirm: true,
            confirmItem: confirmItem,
            confirmMessage,
            successMessage,
            confirmType,
            confirmTitle
        });
    }


    hideConfirm = () => {

        this.setState({
            showConfirm: false,
            confirmItem: null,
            confirmTitle: "",
            successMessage: "",
            confirmMessage: "",
            confirmType: null,
            selectedRow: null,
        });
    }


    showCityConfirm(confirmCityItem) {
        let confirmCityMessage, successCityMessage, confirmCityTitle, confirmCityType;
        if (confirmCityItem.is_active) {
            confirmCityTitle = "Deactivate City";
            confirmCityMessage = "Are you sure you want to deactivate this City?";
            successCityMessage = "City successfully deactivated.";
            confirmCityType = "danger";
        } else {
            confirmCityTitle = "Activate City";
            confirmCityMessage = "Are you sure you want to activate this City?";
            successCityMessage = "City successfully activated.";
            confirmCityType = "success";
        }

        this.setState({
            showCityConfirm: true,
            confirmCityItem: confirmCityItem,
            confirmCityMessage,
            successCityMessage,
            confirmCityType,
            confirmCityTitle
        });
    }


    hideCityConfirm = () => {

        this.setState({
            showCityConfirm: false,
            confirmCityItem: null,
            confirmCityTitle: "",
            successCityMessage: "",
            confirmCityMessage: "",
            confirmCityType: null,
            selectedRow: null,
        });
    }

    componentDidMount() {
        this.fetchAclUser();
        this.getCoutry();
    }

    hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
            return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
    }

    fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
            console.log("fetchAclUser:: Invalid user id!", { userId });
            return;
        }

        try {
            const pageName = Page.REGIONS;
            const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
            const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
            const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
            const accessDenied = isAccessDenied(aclUser, pageAccessRights);
            this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
                const isEditable = this.hasPageAccessRight("edit");
                this.setState({ isEditable });
            });
        } catch (error) {
            console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
    }
    
    componentWillUnmount() {
        this.props.toggleLoading({
            isLoading: false
        });
    }

    getCoutry = async () => {

        let url = "countries/";
        let response = {};
        try {
            response = await API.get(url);
        } catch (e) {
            console.log("Error on fetching countries", e)
        }
        const {selectedCountry}=this.state
        const defaultCountry =selectedCountry|| response.data.find((country) => country.is_default);
        let array = response?.data || [];
        let list = [];
        for (let i = 0; i < array.length; i++) {
            list.push({ key: array[i].country_name, value: array[i].id, text: array[i].country_name });
        }
        this.setState({ countryList: list,selectedCountry:defaultCountry, countyData: response.data }, () => {
            this.getCountryCityList(defaultCountry.id);
        })
    }

    handleChange(event) {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    }

    validateCountryName = (countryName) => {
        if (!countryName || countryName.trim() === "") {
            this.setState({ coutryNameErrorMessage: "Country Name is a required field!" });
            return false;
        } else if (!countryName.split(/[ .]+/).every(name => /^[a-zA-Z]*$/g.test(name))) {
            this.setState({ coutryNameErrorMessage: "Country Name is invalid!" });
            return false;
        } else if (countryName.length > 50) {
            this.setState({ coutryNameErrorMessage: "Country Name can only contain 50 characters!" });
            return false;
        } else {
            this.setState({ coutryNameErrorMessage: null, })
            return true;
        }
    }

    validateCountryCode = (countryCode) => {
        if (!countryCode || countryCode.trim() === "") {
            this.setState({ countryCodeErrorMessage: "Country Code is a required field!" });
            return false;
        }
        else if (!countryCode.split(/[ .]+/).every(name => /^[a-zA-Z]*$/g.test(name))) {
            this.setState({ countryCodeErrorMessage: "Country Code is invalid!" });
            return false;
        }
        else if (countryCode.length > 50) {
            this.setState({ countryCodeErrorMessage: "Country Code can only contain 50 characters!" });
            return false;
        }
        else {
            this.setState({ countryCodeErrorMessage: null, })
            return true;
        }
    }

    validateCountryNameArabic = (countryName) => {
        console.log("Validating country name arabic: ", countryName);
        if (!countryName || countryName.trim() === "") {
            this.setState({ coutryNameArabicErrorMessage: "Country Name (Arabic) is a required field!" });
            return false;
        } else if (countryName.length > 200) {
            this.setState({ coutryNameArabicErrorMessage: "Country Name (Arabic) can only contain 200 characters!" });
            return false;
        } else {
            this.setState({ coutryNameArabicErrorMessage: null, })
            return true;
        }
    }

    validateCurrencyNameEnglish = (currency) => {
        if (!currency || currency.trim() === "") {
            this.setState({ currencyErrorMessage: "Currency English is a required field!" });
            return false;
        } else if (currency.length > 10) {
            this.setState({ currencyErrorMessage: "Currency English can only contain 10 characters!" });
            return false;
        } else {
            this.setState({ currencyErrorMessage: null })
            return true;
        }
    }

    validateCurrencyNameArabic = (currency) => {
        if (!currency || currency.trim() === "") {
            this.setState({ currencyArbErrorMessage: "Currency Arabic is a required field!" });
            return false;
        } else if (currency.length > 10) {
            this.setState({ currencyArbErrorMessage: "Currency Arabic can only contain 10 characters!" });
            return false;
        } else {
            this.setState({ currencyArbErrorMessage: null, })
            return true;
        }
    }

    validateVatPercentage = (vatPercentage) => {
        if (!vatPercentage || vatPercentage.trim() === "") {
            this.setState({ vatErrorMessage: "Vat percentage is a required field!" });
            return false;
        }
        const vat = Number(vatPercentage);
        if (Number.isNaN(vat) || !this.isInBetween(vat, 0.00, 100.00) || !/^\d+(\.\d{1,2})?$/.test(vatPercentage)) {
            this.setState({ vatErrorMessage: "Invalid Vat percentage, should be between (0-100 or 0.00-100.00)" });
            return false;
        }
        this.setState({ vatErrorMessage: "", });
        return true;
    }

    validateStatus = (status) => {
        if (status === null || status === undefined) {
            this.setState({ statusErrorMessage: "Status is a required field!" });
            return false;
        }
        this.setState({ statusErrorMessage: "" });
        return true;
    }
    validatePartialStatus = (status) => {
        if (status === null || status === undefined) {
            this.setState({ partialStatusErrorMessage: "Partial status is a required field!" });
            return false;
        }
        this.setState({ partialStatusErrorMessage: "" });
        return true;
    }

    isInBetween = (x, min, max) => x >= min && x <= max;

    createCountry() {
        const { modalData, newUser } = this.state;

        if (this.state.countyData) {
            let countryIndex = this.state.countyData.findIndex(country => country.country_name.toLowerCase() == this.state.country_name.toLocaleLowerCase());
            if (countryIndex > -1 && !this.state.country_id) {
                this.props.showNotificationMessage({
                    notificationMessage: 'Duplicate country found',
                    successMessage: false,
                    showNotification: true,
                    isLoading: false
                });

                return false;
            }
        }
        this.submitted = true;
        const isValidStatus = this.validateStatus(modalData.is_active);
        const isValidPartialStatus = this.validatePartialStatus(modalData.is_partial_show);
        const isValidCountryName = this.validateCountryName(modalData.country_name);
        const isValidCountryNameArabic = this.validateCountryNameArabic(modalData.country_name_ar);
        const isValidCurrencyEnglish = this.validateCurrencyNameEnglish(modalData.currency);
        const isValidCurrencyArabic = this.validateCurrencyNameArabic(modalData.currency_arabic);
        const isValidVatPercentage = this.validateVatPercentage(modalData.vat_percentage);
        const isValidCountryCode = this.validateCountryCode(modalData.country_code);
        console.log("ValidCountryNameArabic: ", isValidCountryNameArabic);
        if (!modalData.time_zone)
            this.setState({
                timeZoneErrorMessage: "Time Zone is a required field!",
            });
        else this.setState({ timeZoneErrorMessage: "", });

        if (!modalData.imgSrc || modalData.imgSrc === "" || this.state.flag_icon === "")       
            this.setState({
                flageIconErrorMessage: "Flag Icon is a required field!",
            });
        if (
            isValidStatus && isValidPartialStatus &&
            modalData.country_name && isValidCountryName &&
            (modalData.country_name_ar && isValidCountryNameArabic) &&
            (modalData.country_code && isValidCountryCode) &&
            modalData.currency && isValidCurrencyEnglish &&
            modalData.currency_arabic && isValidCurrencyArabic &&
            modalData.vat_percentage && isValidVatPercentage &&
            modalData.time_zone &&
            (this.state.flag_icon && modalData.imgSrc && modalData.imgSrc !== '')
        ) {
            this.hideModal();
            this.props.toggleLoading({
                isLoading: true
            });
            let url = this.state.modalData.id ? "countries/" + this.state.modalData.id + "/" : "countries/";
            let method = this.state.modalData.id ? 'patch' : 'post';
            let formdata = new FormData();
            formdata.append("country_name", this.state.modalData.country_name);
            formdata.append("country_name_ar", this.state.modalData.country_name_ar);
            formdata.append("currency", this.state.modalData.currency);
            formdata.append("currency_arabic", this.state.modalData.currency_arabic);
            formdata.append("vat_percentage", this.state.modalData.vat_percentage);
            formdata.append("time_zone", this.state.modalData.time_zone);
            formdata.append("is_active", this.state.modalData.is_active ? this.state.modalData.is_active : false);
            formdata.append("is_default", this.state.modalData.is_default || false);
            formdata.append("country_code", this.state.modalData.country_code || "");
            formdata.append("is_partial_show", this.state.modalData.is_partial_show ? this.state.modalData.is_partial_show : false);
            if (this.state.flag_icon && this.state.flag_icon.name) {
                formdata.append("flag_icon", this.state.flag_icon, this.state.flag_icon.name);
                formdata.append("flag_icon_s3", this.state.flag_icon, this.state.flag_icon.name);
            }

            const message = this.state.modalData.id ? "Country updated successfully." : "Country created successfully."
            API[method](url, formdata)
                .then(response => {
                    this.clearForm();
                    this.getCoutry();
                    this.props.showNotificationMessage({
                        notificationMessage: message,
                        successMessage: true,
                        showNotification: true,
                        isLoading: false
                    });
                })
                .catch(error => {
                    console.log("Error on saving country", error);
                    let message = "";
                    message = "Some error has occured. Please try again.";

                    this.props.showNotificationMessage({
                        notificationMessage: message,
                        successMessage: false,
                        showNotification: true,
                        isLoading: false
                    });
                });
        }
        else {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
                isLoading: false
            });
        }
    }

    createCity() {
        const { modalCityData, newUser } = this.state;
        if (this.state.list) {
            let cityIndex = this.state.list.findIndex(city => city.city_name.toLowerCase() == this.state.city_name.toLocaleLowerCase());
            if (cityIndex > -1 && !this.state.city_id) {
                this.props.showNotificationMessage({
                    notificationMessage: 'Duplicate city found',
                    successMessage: false,
                    showNotification: true,
                    isLoading: false
                });

                return false;
            }
        }

        this.citysubmitted = true;
        if (!modalCityData.city_name)
            this.setState({
                cityNameErrorMessage: "City Name is a required field!",
            });
        if (!modalCityData.position_value)
            this.setState({
                cityRankErrorMessage: "City Rank is a required field!",
            });

        
        if (!modalCityData.city_name_ar)
            this.setState({
                cityNameArabicErrorMessage: "City Name (Arabic) is a required field!",
            });
        else
            this.setState({ cityNameArabicErrorMessage: "", });

        if (!modalCityData.country)
            this.setState({
                countryErrorMessage: "Country is a required field!",
            });
        if (!modalCityData.start_time)
            this.setState({
                startTimeErrorMessage: "Start Time is a required field!",
            });
        if (!modalCityData.end_time)
            this.setState({
                endTimeErrorMessage: "End Time is a required field!",
            });
        if (modalCityData.start_time == modalCityData.end_time)
            this.setState({
                endTimeErrorMessage: "Start Time should not be equal to End Time",
            });
        if (modalCityData.start_time > modalCityData.end_time)
            this.setState({
                endTimeErrorMessage: "Start Time should not be grater than End Time!",
            });

        
        const isValidStatus = this.validateStatus(modalCityData.is_active);
        if (
            isValidStatus &&
            modalCityData.city_name &&
            modalCityData.city_name_ar &&
            modalCityData.country &&
            modalCityData.start_time &&
            modalCityData.end_time &&
            modalCityData.start_time < modalCityData.end_time
        ) {
            this.hideCityModal();
            this.props.toggleLoading({
                isLoading: true
            });
            let url = this.state.modalCityData.id ? "cities/" + this.state.modalCityData.id + "/" : "cities/";
            let method = this.state.modalCityData.id ? 'patch' : 'post';
            API[method](url, {
                city_name: this.state.modalCityData.city_name,
                position_value: this.state.modalCityData.position_value ? Number(this.state.modalCityData.position_value) : null,
                city_name_ar: this.state.modalCityData.city_name_ar,
                is_active: this.state.modalCityData.is_active ? this.state.modalCityData.is_active : false,
                country: this.state.modalCityData.country ? this.state.modalCityData.country.id : '',
                start_time: this.state.modalCityData.start_time,
                end_time: this.state.modalCityData.end_time,
                center_lat: this.state.modalCityData.center_lat ? Number(this.state.modalCityData.center_lat) : null,
                center_lng: this.state.modalCityData.center_lng ? Number(this.state.modalCityData.center_lng) : null,
            })
                .then(response => {
                    this.clearForm();
                    this.getCoutry();
                    this.props.showNotificationMessage({
                        notificationMessage: this.state.modalCityData.id ? "City updated successfully." : "City created successfully.",
                        successMessage: true,
                        showNotification: true,
                        isLoading: false
                    });
                })
                .catch(error => {
                    console.log("Error on saving city", error);
                    let message = "";
                    message = "Some error has occured. Please try again.";

                    this.props.showNotificationMessage({
                        notificationMessage: message,
                        successMessage: false,
                        showNotification: true,
                        isLoading: false
                    });
                });
        }
        else {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
                isLoading: false
            });
        }
    }

    clearForm() {
        this.submitted = false;
        this.citysubmitted = false;
        this.setState({
            country_id: "",
            country_name: "",
            city_id: "",
            city_name: '',
            city_name_ar: '',
            country: "",
            edit_id: null,
            validation: this.validator.valid(),
            cityvalidation: this.cityvalidator.valid(),
            country: '',
            city_name: '',
            country_id: '',
            city_id: '',
            position_value:"",
            modalCityData: {},
            modalData: {},
            newUser: false,
            modalCityData: {},
            imgSrc: '',
            flag_icon: '',
            editCity: false,
            editCityArabic: false,
            editCurrency: false,
            editCurrencyArb: false,
            editEndTime: false,
            editStartTime: false,
            editStatus: false,
            editImage: false,
            editTimeZone: false,
            editvat: false,
            editCountryName: false,
            editCountryNameArabic: false,
            editCountry: false,
            editCountryCode:false,
            country_code:"",
            editCenterLat: false,
            editCenterLng: false,
            editPartialStatus:false,
        });
    }

    hideModal() {
        this.setState({ 
            showModal: false, 
            modalData: {},
            newUser: false, 
            coutryNameErrorMessage: '', 
            coutryNameArabicErrorMessage: '', 
            vatErrorMessage: '', 
            flageIconErrorMessage: '',
            timeZoneErrorMessage: '', 
            currencyArbErrorMessage: '', 
            currencyErrorMessage: '',
            statusErrorMessage: '',
            countryCodeErrorMessage: "",
            partialStatusErrorMessage:"",
        });
        this.clearForm();
    }

    showModal = (row) => {
        console.log("show modal",row)
        row['imgSrc'] = row.flag_icon ? row.flag_icon : '';
        this.setState({
            showModal: true,
            modalData: row,
            imgSrc: row.flag_icon ? row.flag_icon : '',
            flag_icon: row.flag_icon ? row.flag_icon : "",
        }, () => {
            if ($('.modal-backdrop').length > 0 && this.state.showModal) {
                setTimeout(function () {
                    $('.modal-backdrop').addClass('modal-backdrop-updated');
                });
            }
        });
    }

    creatNewCountry = () => {
        this.setState({ showModal: true, newUser: true })
    }

    createNewCity = () => {
        this.setState({ showCityModal: true, newUser: true })
    }

    hideCityModal() {
        this.setState({ 
            showCityModal: false, 
            modalCityData: {}, 
            newUser: false, 
            cityNameErrorMessage: '', 
            cityNameArabicErrorMessage: '', 
            countryErrorMessage: '', 
            startTimeErrorMessage: '',
            endTimeErrorMessage: '',
            statusErrorMessage: '',
            cityRankErrorMessage:"",
         });
        this.clearForm();
    }

    showCityModal = (row) => {
        this.setState({
            showCityModal: true,
            newUser: false,
            modalCityData: row,
        }, () => {
            if ($('.modal-backdrop').length > 0 && this.state.showModal) {
                setTimeout(function () {
                    $('.modal-backdrop').addClass('modal-backdrop-updated');
                });
            }
        });
    }

    showEditModal(data) {
        this.setState({
            country_id: data.id,
            country_name: data.country_name,
            showModal: true
        });
    }

    showCityEditModal(data) {
        this.setState({
            city_id: data.id,
            city_name: data.city_name,
            city_name_ar: data.city_name_ar,
            country: data.country ? data.country.id : '',
            showCityModal: true
        });
    }

    makeEditable = (key) => {
        this.setState({
            [key]: true
        })
    }

    handleTimeZoneInput = (e, data) => {
        this.setState({
            modalData: {
                ...this.state.modalData,
                [data]: e.target.value,
            },
            updatedCountry: {
                ...this.state.updatedCountry,
                [data]: e.target.value,
            }
        });
    }

    handleVatInput = (e, data) => {
        this.setState({
            modalData: {
                ...this.state.modalData,
                [data]: e.target.value,
                // [data]: e.target.value
            },
            updatedCountry: {
                ...this.state.updatedCountry,
                [data]: e.target.value,
                // [data]: e.target.value
            }
        })
    }

    handleInput = (e, data) => {
        this.setState({
            modalData: {
                ...this.state.modalData,
                [data]: e.target.value.replace(/[^\w\s]/gi, ""),
                // [data]: e.target.value
            },
            updatedCountry: {
                ...this.state.updatedCountry,
                [data]: e.target.value.replace(/[^\w\s]/gi, ""),
                // [data]: e.target.value
            }
        });
    }

    handleCityNameInput = ({ currentTarget: input }) => {
        this.setState({
            modalCityData: {
                ...this.state.modalCityData,
                [input.name]: input.value,
            },
            updatedCity: {
                ...this.state.updatedCity,
                [input.name]: input.value,
            }
        });
    }
 
    handleCityInput = (e, data) => {
        if (data === 'country') {
            let countryIndex = this.state.countyData.findIndex(country => country.id == e.target.value);
            if (countryIndex > -1) {
                this.setState({
                    modalCityData: {
                        ...this.state.modalCityData,
                        [data]: this.state.countyData[countryIndex]
                    },
                    updatedCity: {
                        ...this.state.updatedCity,
                        [data]: e.target.value
                    }
                })
            }
        } 
        else if (data === "position_value") {
            this.setState({
                modalCityData: {
                    ...this.state.modalCityData,
                    [data]: e.target.value,
                },
                updatedCity: {
                    ...this.state.updatedCity,
                    [data]: e.target.value,
                }
            });
        }
        else if (data === 'start_time' || data === 'end_time') {
            this.setState({
                modalCityData: {
                    ...this.state.modalCityData,
                    [data]: e.target.value
                },
                updatedCity: {
                    ...this.state.updatedCity,
                    [data]: e.target.value
                }
            }, () => {
                if (this.state.modalCityData.start_time > this.state.modalCityData.end_time) {
                    this.props.showNotificationMessage({
                        notificationMessage: "Start Time should not be grater than End Time!",
                        successMessage: false,
                        showNotification: true
                    });
                    return;
                }
                if (this.state.modalCityData.start_time === this.state.modalCityData.end_time) {
                    this.props.showNotificationMessage({
                        notificationMessage: "Start Time should not be equal to End Time",
                        successMessage: false,
                        showNotification: true
                    });
                    return;
                }
            })
        } else if (data === 'center_lat' || data === 'center_lng') {
            this.setState({
                modalCityData: {
                    ...this.state.modalCityData,
                    [data]: e.target.value,
                },
                updatedCity: {
                    ...this.state.updatedCity,
                    [data]: e.target.value,
                }
            });
        } else {
            this.setState({
                modalCityData: {
                    ...this.state.modalCityData,
                    [data]: e.target.value.replace(/[^\w\s]/gi, ""),
                    // [data]: e.target.value
                },
                updatedCity: {
                    ...this.state.updatedCity,
                    [data]: e.target.value.replace(/[^\w\s]/gi, ""),
                    // [data]: e.target.value
                }
            })
        }

    }

    toggleActiveStatus(item) {
        const { successMessage } = this.state;

        if (!item) return;
        this.hideConfirm();

        this.props.toggleLoading({
            isLoading: true
        });

        API.patch("countries/" + item.id + "/", {
            is_active: !item.is_active
        })
            .then(response => {
                this.props.toggleLoading({
                    isLoading: false
                });
                this.props.showNotificationMessage({
                    notificationMessage: successMessage,
                    successMessage: true,
                    showNotification: true
                });

                this.getCoutry();
            })
            .catch(error => {
                console.log("Error on updating country", error);
                this.props.toggleLoading({
                    isLoading: false
                });
                this.props.showNotificationMessage({
                    notificationMessage: "Some error has occured. Please try again.",
                    successMessage: false,
                    showNotification: true
                });
            });
    }

    toggleCityActiveStatus(item) {
        const { successCityMessage } = this.state;
        if (!item) return;
        this.hideCityConfirm();
        this.props.toggleLoading({
            isLoading: true
        });

        API.patch("cities/" + item.id + "/", {
            is_active: !item.is_active
        })
            .then(response => {
                this.props.toggleLoading({
                    isLoading: false
                });
                this.props.showNotificationMessage({
                    notificationMessage: successCityMessage,
                    successMessage: true,
                    showNotification: true
                });
                this.getCoutry();
            })
            .catch(error => {
                console.log("Error on updating city status", error);
                this.props.toggleLoading({
                    isLoading: false
                });
                this.props.showNotificationMessage({
                    notificationMessage: "Some error has occured. Please try again.",
                    successMessage: false,
                    showNotification: true
                });
            });
    }

    getCountryCityList(countryId) {
        const { searchApplied, searchTerm } = this.state;

        this.props.toggleLoading({
            isLoading: true
        });
        API.get(`cities/?country=${countryId}`)
            .then(response => {
                const cityList =  response && response.data || []
                this.setState({
                    list:cityList,
                    isLoaded: true
                });
                if (searchApplied) this.handleSearch(searchTerm);
                this.props.toggleLoading({
                    isLoading: false
                });
            })
            .catch(error => {
                console.log("Error on fetching cities", error);
                this.setState({
                    loadError: "Some error has occured. Please try again"
                });

                this.props.showNotificationMessage({
                    notificationMessage: "Some error has occured. Please try again.",
                    successMessage: false,
                    showNotification: true,
                    isLoading: false
                });
            });
    }

    handleSearch(value) {
        let newValue,
            list = [];

        this.setState({
            searchTerm: value
        });


        newValue = value
            .slice(0)
            .trim()
            .toLowerCase();

        if (isEmpty(value)) {
            this.setState({
                searchApplied: false,
                searchExpanded: false
            });

            return;
        }

        const { showItems } = this.state;

        if (showItems === "Active") list = this.state.activeList;
        else if (showItems === "Deactivated") list = this.state.deactivatedList;
        else list = this.state.list;

        if (!list) return;

        let newList = [];

        for (let item of list) {
            if (
                item.country_name.toLowerCase().indexOf(newValue) !== -1 ||
                item.city_name.toLowerCase().indexOf(newValue) !== -1
            ) {
                newList.push(item);
            }
        }

        this.setState({
            searchExpanded: true,
            searchApplied: true,
            results: newList,
            resultCount: newList.length
        });
    }

    clearSearch() {
        this.setState({
            searchTerm: "",
            searchExpanded: true,
            searchApplied: false
        });

        this.search.searchInput.focus();
    }

    handleSearchExit(e) {
        const value = e.target.value;

        if (!value.length) {
            this.setState({
                searchExpanded: false,
                searchApplied: false
            });

            return;
        } else {
            this.setState({
                searchExpanded: true
            });
        }
    }

    setShowItems(mode) {
        let showItems;
        const { searchApplied, searchTerm } = this.state;

        if (mode === "active") showItems = "Active";
        else if (mode === "deactivated") showItems = "Deactivated";
        else showItems = "All";

        this.setState({ showItems: showItems }, () => {
            if (searchApplied) this.handleSearch(searchTerm);
        });
    }

    handleCountyChange = (value) => {
        this.setState({ country: value })
    }
    handleCountrySelection = (selectedCountry) =>{
        this.setState({ selectedCountry });
        this.getCountryCityList(selectedCountry.id)
      
    }

    selecteImage(e) {
        if (e.target.files && e.target.files.length) {
            let flag_icon = e.target.files[0];
            this.setState({ flag_icon });
            let reader = new FileReader();

            reader.onloadend = function () {
                this.setState({
                    imgSrc: [reader.result],
                    flageIconErrorMessage: '',
                    modalData: {
                        ...this.state.modalData,
                        imgSrc: [reader.result],
                    }
                });
            }.bind(this);
            reader.readAsDataURL(flag_icon);

        }
    }

    renderPencilIcon = (fieldName) => {
        const { newUser, isEditable } = this.state;
        return isEditable && !newUser
            ? <div
                onClick={() => this.makeEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
            : ""
    }

    render() {
        let finalList = [];
        let cityList = [];
        const nutritionists = this.state.list;
        const {
            isLoaded,
            loadError,
            searchApplied,
            results,
            resultCount,
            showItems,
            countryList,
            countyData,
            deactivatedList,
            modalCityData,
            modalData,
            newUser,
            isEditable,
            selectedCountry,
        } = this.state;

        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation;
        let cityvalidation = this.citysubmitted
            ? this.cityvalidator.validate(this.state)
            : this.state.cityvalidation;

        if (isLoaded) {
            if (searchApplied) finalList = results;
            else {
                finalList = countyData;
            }
        }
        finalList = countyData;
        cityList = nutritionists;

        const countryInfoForm =
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="country_name">Country*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="country_name"
                                name="notes"
                                className={`form-control user-data-input ${(this.state.editCountryName || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.country_name || ""}
                                onChange={(e) => this.handleInput(e, "country_name")}
                                readOnly={(this.state.editCountryName || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCountryName")}
                        </div>
                        <span className="help-block">{this.state.coutryNameErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="country_name_ar">Country (Arabic)*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="country_name_ar"
                                name="country_name_ar"
                                className={`form-control user-data-input input-arabic ${(this.state.editCountryNameArabic || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.country_name_ar || ""}
                                onChange={(e) => this.handleVatInput(e, "country_name_ar")}
                                readOnly={(this.state.editCountryNameArabic || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCountryNameArabic")}
                        </div>
                        <span className="help-block">{this.state.coutryNameArabicErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="time_zone">Time Zone</label>
                        <div className="input-group">
                            <select
                                id="time_zone"
                                name="time_zone"
                                className={`form-control user-data-input py-2 ${(this.state.editTimeZone || newUser) ? `non-editable` : `editable`} `}
                                onChange={(e) => this.handleTimeZoneInput(e, "time_zone")}
                                value={modalData.time_zone ? modalData.time_zone : ''}
                                disabled={(this.state.editTimeZone || newUser) ? false : true}
                            >
                                <option key={-1} value="" hidden={true}>Select</option>
                                {timeZones && Object.keys(timeZones).map((key, index) => (
                                    <option key={index} value={timeZones[key]}>
                                        {timeZones[key]}
                                    </option>
                                ))
                                }
                            </select>
                            {this.renderPencilIcon("editTimeZone")}
                        </div>
                        <span className="help-block">{this.state.timeZoneErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="vat_percentage">VAT(%)</label>
                        <div className="input-group">
                            <input
                                type="number"
                                id="vat_percentage"
                                name="vat_percentage"
                                step={.01}
                                className={`form-control user-data-input ${(this.state.editvat || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.vat_percentage || ""}
                                onChange={(e) => this.handleVatInput(e, "vat_percentage")}
                                readOnly={(this.state.editvat || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editvat")}
                        </div>
                        <span className="help-block">{this.state.vatErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="currency">Currency (english)*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="currency"
                                name="currency"
                                className={`form-control user-data-input ${(this.state.editCurrency || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.currency || ""}
                                onChange={(e) => this.handleInput(e, "currency")}
                                readOnly={(this.state.editCurrency || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCurrency")}
                        </div>
                        <span className="help-block">{this.state.currencyErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="currency_arabic">Currency (arabic)*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="currency_arabic"
                                name="currency_arabic"
                                className={`form-control user-data-input ${(this.state.editCurrencyArb || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.currency_arabic || ""}
                                onChange={(e) => this.handleInput(e, "currency_arabic")}
                                readOnly={(this.state.editCurrencyArb || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCurrencyArb")}
                        </div>
                        <span className="help-block">{this.state.currencyArbErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="status">Status *</label>
                        <div className="input-group">
                            <select
                                id="status"
                                name="status"
                                className={`py-2 form-control user-data-input${(this.state.editStatus || newUser) ? `non-editable` : `editable`} `}
                                onChange={(e) => this.handleInput(e, "is_active")}
                                value={modalData.is_active}
                                disabled={(this.state.editStatus || newUser) ? false : true}
                            >
                                <option key={-1} value={""} hidden={true}>Select</option>
                                <option key={0} value="true">
                                    Active
                                </option>
                                <option key={1} value="false">
                                    Inactive
                                </option>
                            </select>
                            {this.renderPencilIcon("editStatus")}
                        </div>
                        <span className="help-block">{this.state.statusErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="country_code">Country code *</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="country_code"
                                name="country_code"
                                className={`form-control user-data-input ${(this.state.editCountryCode || newUser) ? `non-editable` : `editable`} `}
                                value={modalData.country_code || ""}
                                onChange={(e) => this.handleInput(e, "country_code")}
                                readOnly={(this.state.editCountryCode || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCountryCode")}
                        </div>
                        <span className="help-block">{this.state.countryCodeErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <label htmlFor="is_partial_show">Partial View *</label>
                        <div className="input-group">
                            <select
                                id="is_partial_show"
                                name="is_partial_show"
                                className={`py-2 form-control user-data-input${(this.state.editPartialStatus || newUser) ? `non-editable` : `editable`} `}
                                onChange={(e) => this.handleInput(e, "is_partial_show")}
                                value={modalData.is_partial_show}
                                disabled={(this.state.editPartialStatus || newUser) ? false : true}
                            >
                                <option key={-1} value={""} hidden={true}>Select</option>
                                <option key={0} value="true">
                                    Active
                                </option>
                                <option key={1} value="false">
                                    Inactive
                                </option>
                            </select>
                            {this.renderPencilIcon("editPartialStatus")}
                        </div>
                        <span className="help-block">{this.state.partialStatusErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label>Flag Icon *</label>
                        <div className="input-group">
                            <input
                                type="file"
                                id="imageInput"
                                name="flag_icon"
                                accept="image/*"
                                className={`form-control user-data-input editable`}
                                onChange={this.selecteImage}
                                disabled={(this.state.editImage || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editImage")}
                        </div>
                        <span className="help-block">{this.state.flageIconErrorMessage}</span>
                        {/* <p className="help-block rte-mui">{this.state.flageIconErrorMessage}</p> */}
                    </div>
                    <div className="form-group col">
                        {this.state.imgSrc && (
                            <>
                                <label><b>Selected Image</b></label>
                                <div className="form-image-container">
                                    <img
                                        src={this.state.imgSrc}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>;

        const tabData = [
            {
                tabTitle: 'Country',
                tabForm: countryInfoForm,
                eventKey: 'countryInfo'
            },
        ];

        const cityform = 
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="city_name">City*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="city_name"
                                name="city_name"
                                className={`form-control user-data-input ${(this.state.editCity || newUser) ? `non-editable` : `editable`} `}
                                value={modalCityData.city_name || ""}
                                onChange={this.handleCityNameInput}
                                disabled={(this.state.editCity || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCity")}
                        </div>
                        <span className="help-block">{this.state.cityNameErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="city_name_ar">City (Arabic)*</label>
                        <div className="input-group">
                            <input
                                type="text"
                                id="city_name_ar"
                                name="city_name_ar"
                                className={`form-control user-data-input input-arabic ${(this.state.editCityArabic || newUser) ? `non-editable` : `editable`} `}
                                value={modalCityData.city_name_ar || ""}
                                onChange={this.handleCityNameInput}
                                disabled={(this.state.editCityArabic || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCityArabic")}
                        </div>
                        <span className="help-block">{this.state.cityNameArabicErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="status">Status</label>
                        <div className="input-group">
                            <select
                                id="status"
                                name="status"
                                className={`form-control user-data-input py-2 ${(this.state.editStatus || newUser) ? `non-editable` : `editable`} `}
                                onChange={(e) => this.handleCityInput(e, "is_active")}
                                value={modalCityData.is_active}
                                disabled={(this.state.editStatus || newUser) ? false : true}
                            >
                                <option key={-1} value={""} hidden={true}>Select</option>
                                <option key={0} value={true}>
                                    Active
                                </option>
                                <option key={1} value={false}>
                                    Inactive
                                </option>
                            </select>
                            {this.renderPencilIcon("editStatus")}
                        </div>
                        <span className="help-block">{this.state.statusErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="country">Country *</label>
                        <div className="input-group">
                            <select
                                id="country"
                                name="country"
                                className={`form-control user-data-input py-2 ${(this.state.editCountry || newUser) ? `non-editable` : `editable`} `}
                                onChange={(e) => this.handleCityInput(e, "country")}
                                value={modalCityData.country ? modalCityData.country.id : ''}
                                disabled={(this.state.editCountry || newUser) ? false : true}
                            >
                                <option key={-1} value="" hidden={true}>Select</option>
                                {this.state.countryList && this.state.countryList.map((status, index) => (
                                    <option key={index} value={status.value}>
                                        {status.text}
                                    </option>
                                ))
                                }
                            </select>
                            {this.renderPencilIcon("editCountry")}
                        </div>
                        <span className="help-block">{this.state.countryErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="start_time">Start Time</label>
                        <div className="input-group">
                            <input
                                type="time"
                                id="start_time"
                                name="start_time"
                                className={`form-control user-data-input ${(this.state.editStartTime || newUser) ? `non-editable` : `editable`} ${(!newUser) ? `dob` : ``} ` + (this.state.confirmDateErrorMessage && " has-error")}
                                value={modalCityData.start_time ? modalCityData.start_time : ''}
                                onChange={(e) => this.handleCityInput(e, "start_time")}
                                readOnly={(this.state.editStartTime || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editStartTime")}
                        </div>
                        <span className="help-block">{this.state.startTimeErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="end_time">End Time</label>
                        <div className="input-group">
                            <input
                                type="time"
                                id="end_time"
                                name="end_time"
                                className={`form-control dob user-data-input ${(this.state.editEndTime || newUser) ? `non-editable` : `editable`} ${(!newUser) ? `dob` : ``}` + (this.state.confirmDateErrorMessage && " has-error")}
                                value={modalCityData.end_time ? modalCityData.end_time : ''}
                                onChange={(e) => this.handleCityInput(e, "end_time")}
                                readOnly={(this.state.editEndTime || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editEndTime")}
                        </div>
                        <span className="help-block">{this.state.endTimeErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="center_lat">Center Latitude</label>
                        <div className="input-group">
                            <input
                                type="number"
                                id="center_lat"
                                name="center_lat"
                                className={`form-control user-data-input ${(this.state.editCenterLat || newUser) ? `non-editable` : `editable`} ${(!newUser) ? `dob` : ``} ` + (this.state.confirmDateErrorMessage && " has-error")}
                                value={modalCityData.center_lat ?? ''}
                                onChange={(e) => this.handleCityInput(e, "center_lat")}
                                readOnly={(this.state.editCenterLat || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCenterLat")}
                        </div>
                        <span className="help-block">{this.state.centerLatErrorMessage}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="center_lng">Center Longitude</label>
                        <div className="input-group">
                            <input
                                type="number"
                                id="center_lng"
                                name="center_lng"
                                className={`form-control dob user-data-input ${(this.state.editCenterLng || newUser) ? `non-editable` : `editable`} ${(!newUser) ? `dob` : ``}` + (this.state.confirmDateErrorMessage && " has-error")}
                                value={modalCityData.center_lng ?? ''}
                                onChange={(e) => this.handleCityInput(e, "center_lng")}
                                readOnly={(this.state.editCenterLng || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editCenterLng")}
                        </div>
                        <span className="help-block">{this.state.centerLngErrorMessage}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col-6">
                        <label htmlFor="rank">Rank</label>
                        <div className="input-group">
                            <input
                                type="number"
                                id="rank"
                                name="rank"
                                className={`form-control user-data-input ${(this.state.editRank || newUser) ? `non-editable` : `editable`} `}
                                value={modalCityData.position_value ? modalCityData.position_value :""}
                                onChange={(e)=>this.handleCityInput(e,"position_value")}
                                readOnly={(this.state.editRank || newUser) ? false : true}
                            />
                            {this.renderPencilIcon("editRank")}
                        </div>
                        <span className="help-block">{this.state.cityRankErrorMessage}</span>
                    </div>
                </div>
            </div>;

        const tabCityData = [
            {
                tabTitle: 'City',
                tabForm: cityform,
                eventKey: 'cityInfo'
            },
        ]

        if (this.state.accessDenied) {
            return <AccessDenied />
        }

        return (
            <div className="nutritionist">
                <div className="page-header mb-2">
                    {/* <h5>Region Managment</h5> */}
                </div>
                <div className="page-header">
                    <h5>Country</h5>
                    <div className="actions">
                        {/* <div className="action-item">
                            <Search
                                searchExpanded={this.state.searchExpanded}
                                searchTerm={this.state.searchTerm}
                                clearSearch={this.clearSearch}
                                handleSearch={this.handleSearch}
                                handleSearchExit={this.handleSearchExit}
                                ref={input => {
                                    this.search = input;
                                }}
                            ></Search>
                        </div> */}
                        <div className="action-item">
                            <button
                                className="btn btn-sm button text-button "
                                onClick={this.creatNewCountry}
                                disabled={!isEditable}
                            >
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                                add new
                            </button>
                        </div>
                    </div>
                </div>
                <Card>
                    <DataTable
                        highlightOnHover
                        columns={this.columns}
                        data={finalList}
                        defaultSortField="country_name"
                        sortIcon={<ArrowDownward></ArrowDownward>}
                        pagination
                        paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                        paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        responsive
                        noHeader={searchApplied ? false : true}
                        title={
                            searchApplied ? (
                                <p className="table-header">
                                    {"Found " + resultCount + " results in '" + showItems + "'"}
                                </p>
                            ) : (
                                ""
                            )
                        }
                        noDataComponent={
                            loadError ? (
                                <AlertBox message={loadError} error={true}></AlertBox>
                            ) : (
                                <AlertBox message="There's nothing here."></AlertBox>
                            )
                        }
                    />
                </Card>


                    <CountryDropdown
                        countries={countyData || []}
                        selectedCountry={selectedCountry}
                        onSelect={this.handleCountrySelection}
                    />
                <div className="page-header">
                    <div className="actions">
                        <div className="action-item">
                            <button
                                className="btn btn-sm button text-button "
                                onClick={this.createNewCity}
                                disabled={!isEditable}
                            >
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                                add new
                            </button>
                        </div>
                    </div>
                </div>
                <Card>
                    <DataTable
                        highlightOnHover
                        columns={this.CityColumns}
                        data={cityList}
                        defaultSortField="position_value"
                        sortIcon={<ArrowDownward></ArrowDownward>}
                        pagination
                        paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                        paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        responsive
                        noHeader={searchApplied ? false : true}
                        title={
                            searchApplied ? (
                                <p className="table-header">
                                    {"Found " + resultCount + " results in '" + showItems + "'"}
                                </p>
                            ) : (
                                ""
                            )
                        }
                        noDataComponent={
                            loadError ? (
                                <AlertBox message={loadError} error={true}></AlertBox>
                            ) : (
                                <AlertBox message="There's nothing here."></AlertBox>
                            )
                        }
                    />
                </Card>

                <ModalPopup showModal={this.state.showModal} tabData={tabData} key={this.state.key} saveUserData={this.createCountry} hideModal={this.hideModal} title={this.modalData && this.modalData.id ? this.modalData.id : ''} isEditable={isEditable} />

                <ModalPopup showModal={this.state.showCityModal} tabData={tabCityData} key={this.state.key} saveUserData={this.createCity} hideModal={this.hideCityModal} title={this.modalCityData && this.modalCityData.id ? this.modalCityData.id : ''} isEditable={isEditable} />

                <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.confirmMessage}</Modal.Body>
                    <Modal.Footer>
                        {this.state.confirmType === "danger" ? (
                            <button
                                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                                className="btn button btn-danger"
                            >
                                Deactivate
                            </button>
                        ) : (
                            <button
                                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                                className="btn button btn-success"
                            >
                                Activate
                            </button>
                        )}
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideConfirm}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showCityConfirm} onHide={this.hideCityConfirm}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.confirmCityTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{this.state.confirmCityMessage}</Modal.Body>
                    <Modal.Footer>
                        {this.state.confirmCityType === "danger" ? (
                            <button
                                onClick={() => this.toggleCityActiveStatus(this.state.confirmCityItem)}
                                className="btn button btn-danger"
                            >
                                Deactivate
                            </button>
                        ) : (
                            <button
                                onClick={() => this.toggleCityActiveStatus(this.state.confirmCityItem)}
                                className="btn button btn-success"
                            >
                                Activate
                            </button>
                        )}
                        <button
                            className="btn btn-secondary button"
                            onClick={this.hideConfirm}
                        >
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: value =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegionManagment);
