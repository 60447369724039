import React, { Component } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import FormData from "form-data";
import ModalTab from "components/ModalTab/ModalTab";

class ModalPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: this.props.key,
    };
  }

  render() {
    const isEditable = this.props.isEditable ?? true;
    const isSaving = this.props.isSaving ?? false;
    console.log(this.props.tabData);
    return (

      <div className="user-input-container" >
        <Modal
          show={this.props.showModal}
          onHide={this.props.hideModal}
          id="userDataModal"
          size="lg"
          className="user-data-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title">{this.props.title}</div>
              {this.props.deactivateCoach &&
                <button className="btn btn-md user-data-toggele" onClick={this.props.deactivateCoach}>Deactivate</button>
              }
              <button 
                onClick={this.props.saveUserData}
                disabled={!isEditable || isSaving}
                className="btn btn modal-save-btn"
                // className="btn button-grey btn-md user-data-save"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="controlled-tab-example"
              activeKey={this.state.key}
              onSelect={(k) => this.setState({ key: k })}
              className="mb-3"
              fill justify
            >
              {this.props.tabData &&
                this.props.tabData.map((item, i) => {
                  return (
                    <ModalTab eventKey={item.eventKey} title={item.tabTitle} disabled={item.disabled}>
                      {item.tabForm}
                    </ModalTab>
                  )
                })
              }
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <div className="row mx-3" style={{ width: "100%" }}>
              {this.props.cancelOrder &&
                <button disabled={!isEditable} className="btn btn-md user-data-toggele ml-auto" onClick={this.props.cancelOrder}>
                  Cancel
                </button>
              }
              {this.props.cancelOrderWithoutNotification &&
                <button disabled={!isEditable} className="btn btn-md user-data-toggele" onClick={this.props.cancelOrderWithoutNotification}>
                  Cancel (No Notification)
                </button>
              }
               {
                this.props.cancelRefundOrder && 
                <button disabled={!isEditable} className="btn btn-md user-data-toggele " onClick={this.props.cancelRefundOrder}>
                Refund & Cancel
              </button>
              }
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }

}

export default ModalPopup;
