import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

SelectCoupon.propTypes = {
  coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCouponCode: PropTypes.number,
  onCouponSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectCoupon.defaultProps = {
  coupons: [],
  selectedCouponCode: null,
  onCouponSelect: (f) => f,
  isDisabled: false,
};

function SelectCoupon(props) {
  const {
    coupons,
    selectedCouponCode,
    onCouponSelect,
    isDisabled,
  } = props;

  const findCoupon = (couponCode) =>
    coupons.find((coupon) => coupon.couponCode === couponCode);

  const createCouponOption = (coupon) => {
    if (!coupon) return;
    return {
      key: coupon.id,
      value: coupon.couponCode,
      label: coupon.couponCode,
    };
  };

  // const getFilteredCoupons = () => {
  //   switch (itemType) {
  //     case "Blood_Package":
  //       return coupons.filter((coupon) =>
  //         coupon.bloodPackages?.includes(itemId)
  //       );
  //     case "Coach_Package":
  //       return coupons.filter((coupon) =>
  //         coupon.coachPackages?.includes(itemId)
  //       );
  //     case "Custom_Package":
  //       return coupons.filter((coupon) =>
  //         coupon.customPackages?.includes(itemId)
  //       );
  //     case "Bundle":
  //       return coupons.filter((coupon) => coupon.bundles?.includes(itemId));
  //     case "Medicine":
  //       return coupons.filter((coupon) => coupon.medicines?.includes(itemId));
  //     case "Supplement":
  //       return coupons.filter((coupon) => coupon.supplements?.includes(itemId));
  //     default: {
  //       console.log("Invalid item type: ", itemType);
  //       return [];
  //     }
  //   }
  // };

  // const filteredCoupons = getFilteredCoupons() ?? [];
  const couponOptions = coupons.map(createCouponOption);
  const selectedCoupon = findCoupon(selectedCouponCode);
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`select_coupon_${selectedCouponCode}`}
      value={createCouponOption(selectedCoupon)}
      onChange={(option) => onCouponSelect(option.value)}
      options={couponOptions}
      styles={selectStyles}
      placeholder="Select Coupon"
      isDisabled={isDisabled}
    />
  );
}

export default SelectCoupon;
