import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import FAQForm from "components/Common/FAQForm";
import { fetchCountries } from "services/RegionService";
import {
  fetchOtherItems,
  saveOtherItem,
  updateOtherItem,
} from "services/OtherItemService";
import SubscriptionForm from "components/Common/SubscriptionForm";
import ToggleSwitch from "components/Common/ToggleSwitch";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];

const OTHER_ITEMS_CATEGORY_OPTIONS = [
  {
    key: "Shampoo",
    value: "Shampoo",
    text: "Shampoo",
  },
  {
    key: "Conditioner",
    value: "Conditioner",
    text: "Conditioner",
  },
  {
    key: "Serum",
    value: "Serum",
    text: "Serum",
  },
  {
    key: "Others",
    value: "Others",
    text: "Others",
  },
];

class OtherItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      otheritems: [],
      rankOptions: [],
      otheritem: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isOtherItemViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (otheritem) => (
          <p style={{ textAlign: "right" }}>{otheritem.externalNameArabic}</p>
        ),
      },
      {
        name: "Category",
        selector: "category",
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (otheritem) => (
          <Status isActive={otheritem.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (otheritem) => this.renderViewButton(otheritem.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchOtherItems();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OTHER_ITEMS;
      const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
      const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
      const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  }

  fetchOtherItems = async () => {
    this.showLoading();
    try {
      const otheritems = (await fetchOtherItems()) || [];
      this.setState({ otheritems });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching otheritems: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) { }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const otheritem = { ...this.state.otheritem, image };
      this.setState({ otheritem });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const otheritem = {
        ...this.state.otheritem,
        image_src: [reader.result],
      };
      this.setState({ otheritem });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const otheritem = { ...this.state.otheritem };
    if (numberInputs.includes(input.name))
      otheritem[input.name] = input.value ? Number(input.value) : "";
    else otheritem[input.name] = input.value;
    this.setState({ otheritem });
  };

  handleQuestionsUpdated = (questions = []) => {
    const otheritem = { ...this.state.otheritem, faqs: questions };
    this.setState({ otheritem });
  };

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    const otheritem = {
      ...this.state.otheritem,
      subscriptionItems: subscriptionItems,
    };
    this.setState({ otheritem });
  };

  handleAddNewOtherItem = () => {
    this.setState({ isNewOtherItem: true }, () =>
      this.showOtherItemViewModal()
    );
  };

  handleViewOtherItem = (otheritemId) => {
    const otheritems = this.state.otheritems ?? [];
    const otheritem = otheritems.find((it) => it.id === otheritemId);
    if (!otheritem) {
      console.log(`Other Item with id ${otheritemId} is not found!`);
      return;
    }

    otheritem.image_src = otheritem.image;
    if (otheritem.currency === null || otheritem.currency === undefined) {
      otheritem.currency = this.state.selectedCountry?.currency;
    }
    const monthlyPricing = otheritem.subscriptionItems ?? [];
    this.setState(
      { otheritem, monthlyPricing, isNewOtherItem: false },
      () => this.showOtherItemViewModal()
    );
  };

  showOtherItemViewModal = () => {
    const { selectedCountry, isNewOtherItem } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewOtherItem) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const otheritem = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
      };
      this.setState({
        otheritem,
        rankOptions,
        isOtherItemViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isOtherItemViewModalVisible: true });
    }
  };

  hideOtherItemViewModal = () => {
    this.setState({ isOtherItemViewModalVisible: false });
    this.resetForm();
  };

  handlesaveOtherItem = async () => {
    const { selectedCountry, otheritem } = this.state;
    otheritem.meta_title_arabic=otheritem.meta_title_arabic||otheritem.metaTitle
    otheritem.meta_description_arabic=otheritem.meta_description_arabic||otheritem.metaDescription
    otheritem.keywords_arabic=otheritem.keywords_arabic||otheritem.keywords
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    } else if (!otheritem.enableOneTime && !otheritem.enableSubscription) {
      this.showErrorNotification(
        "At least one type of pricing should be enabled!"
      );
      return;
    }

    try {
      this.showLoading();
      const isValidId = otheritem.id !== null && otheritem.id !== undefined;

      const response = isValidId
        ? await updateOtherItem(otheritem)
        : await saveOtherItem(otheritem);

      this.hideOtherItemViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Other Item Updated Successfully."
          : "Other Item Created Successfully."
      );
      this.fetchOtherItems();
    } catch (error) {
      let seoExceptionCode = error.response.status;
      if (seoExceptionCode === 500) {
        this.showErrorNotification("seo url already exists: " + error)
      }
      else {
        this.showErrorNotification("Some error occurred: " + error.message);
      }
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { otheritem = {} } = this.state;
    const errors = {};

    const {
      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      category,
      country,

      shortDescriptionEnglish,
      shortDescriptionArabic,
      longDescriptionEnglish,
      longDescriptionArabic,
      keyHighlightsEnglish,
      keyHighlightsArabic,
      disclaimerEnglish,
      disclaimerArabic,
      image,

      retailPrice,
      finalPrice,
      discountType,
      discountValue,
      subscriptionItems,
      seoUrl,
      metaTitle,
      metaDescription,
      keywords,
    } = otheritem;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (category == null) errors.category = "Category cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    // Validate Descriptions Section
    if (shortDescriptionEnglish && shortDescriptionEnglish.length > 1000)
      errors.shortDescriptionEnglish =
        "Short Description (English) is limited to a 1000 characters";

    if (shortDescriptionArabic && shortDescriptionArabic.length > 1000)
      errors.shortDescriptionArabic =
        "Short Description (Arabic) is limited to a 1000 characters";

    if (longDescriptionEnglish && longDescriptionEnglish.length > 20000)
      errors.longDescriptionEnglish =
        "Long Description (English) is limited to a 20000 characters";

    if (longDescriptionArabic && longDescriptionArabic.length > 20000)
      errors.longDescriptionArabic =
        "Long Description (Arabic) is limited to a 20000 characters";

    if (keyHighlightsEnglish && keyHighlightsEnglish.length > 5000)
      errors.keyHighlightsEnglish =
        "Key Highlights (English) is limited to a 5000 characters";

    if (keyHighlightsArabic && keyHighlightsArabic.length > 5000)
      errors.keyHighlightsArabic =
        "Key Highlights (Arabic) is limited to a 5000 characters";

    if (disclaimerEnglish && disclaimerEnglish.length > 5000)
      errors.disclaimerEnglish =
        "Disclaimer (English) is limited to a 5000 characters";

    if (disclaimerArabic && disclaimerArabic.length > 5000)
      errors.disclaimerArabic =
        "Disclaimer (Arabic) is limited to a 5000 characters";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    // Validate Pricing Section
    if (otheritem.enableOneTime) {
      if (
        retailPrice === null ||
        retailPrice === undefined ||
        retailPrice.toString().trim() === ""
      )
        errors.retailPrice = "Retail Price cannot be blank";
      else if (Number.isNaN(Number(retailPrice)))
        errors.retailPrice = "Retail Price should be a valid number";
      else if (!this.isInBetween(Number(retailPrice), 0, 99999999))
        errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";

      if (
        finalPrice === null ||
        finalPrice === undefined ||
        finalPrice.toString().trim() === ""
      )
        errors.finalPrice = "Final Price cannot be blank";
      else if (Number.isNaN(Number(finalPrice)))
        errors.finalPrice = "Final Price should be a valid number";
      else if (!this.isInBetween(Number(finalPrice), 0, 99999999))
        errors.finalPrice = "Final Price should be bewteen 0 to 99999999";

      if (!discountType) errors.discountType = "Discount Type cannot be blank";

      if (
        discountValue === null ||
        discountValue === undefined ||
        discountValue.toString().trim() === ""
      )
        errors.discountValue = "Discount Value cannot be blank";
      else if (Number.isNaN(Number(discountValue)))
        errors.discountValue = "Discount Value should be a valid number";
      else if (!this.isInBetween(Number(discountValue), 0, 99999999))
        errors.discountValue = "Discount Value should be bewteen 0 to 99999999";
    }

    if (otheritem.enableSubscription) {
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscriptionItems = "At least one active item is required!";
      }
    }

    if (seoUrl && seoUrl.length > 100)
      errors.seoUrl = "URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > 100)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > 200)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.keywords = "keywords can not contain more than 500 characters!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewOtherItem } = this.state;
    const otheritems = this.getOtherItemsForCurrentCountry();
    const ranks = otheritems.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewOtherItem ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      otheritem: {},
      errors: {},
      editables: {},
      monthlyPricing: [],
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewOtherItem(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewOtherItem ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredOtherItems = () => {
    const otheritems = this.getOtherItemsForCurrentCountry();
    return this.getSearchedOtherItems(otheritems);
  };

  getOtherItemsForCurrentCountry = () => {
    const { otheritems, selectedCountry } = this.state;
    return otheritems && selectedCountry
      ? otheritems.filter(
        (otheritem) => otheritem.country === selectedCountry.id
      )
      : [];
  };

  getSearchedOtherItems = (otheritems) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return otheritems;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return otheritems.filter((otheritem) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
      } = otheritem;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      otheritems,
      otheritem,
      isNewOtherItem,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredOtherItems = this.getFilteredOtherItems(otheritems) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={otheritem?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={otheritem?.status}
                onChange={(event, value) => {
                  this.setState({
                    otheritem: {
                      ...otheritem,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewOtherItem && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={otheritem?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={otheritem?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={otheritem?.rank}
                onChange={(event, value) => {
                  this.setState({
                    otheritem: { ...otheritem, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewOtherItem && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="category">Category*</label>
            <div className="input-group">
              <Dropdown
                value={otheritem?.category}
                onChange={(event, value) => {
                  this.setState({
                    otheritem: { ...otheritem, category: value.value },
                  });
                }}
                id="category"
                name="category"
                placeholder="Category"
                search
                selection
                options={OTHER_ITEMS_CATEGORY_OPTIONS}
                disabled={!isNewOtherItem && !editables.category}
                className={
                  "form-control" + (errors.category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("category")}
            </div>
            <span className="help-block">{errors.category}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewOtherItem
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(otheritem?.country)?.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="shortDescriptionEnglish">
              Short Description (English)
            </label>
            <div className="input-group">
              <input
                value={otheritem.shortDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="shortDescriptionEnglish"
                name="shortDescriptionEnglish"
                type="text"
                placeholder="Short Description in English"
                className={
                  "form-control py-2" +
                  (errors.shortDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.shortDescriptionEnglish}
              />
              {this.renderPencilIcon("shortDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.shortDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="shortDescriptionArabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <input
                value={otheritem.shortDescriptionArabic || ""}
                onChange={this.handleChange}
                id="shortDescriptionArabic"
                name="shortDescriptionArabic"
                type="text"
                placeholder="Short Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.shortDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.shortDescriptionArabic}
              />
              {this.renderPencilIcon("shortDescriptionArabic")}
            </div>
            <span className="help-block">{errors.shortDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="longDescriptionEnglish">
              Long Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={otheritem.longDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="longDescriptionEnglish"
                name="longDescriptionEnglish"
                placeholder="Long Description in English"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.longDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("longDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.longDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="longDescriptionArabic">
              Long Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={otheritem.longDescriptionArabic || ""}
                onChange={this.handleChange}
                id="longDescriptionArabic"
                name="longDescriptionArabic"
                placeholder="Long Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.longDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("longDescriptionArabic")}
            </div>
            <span className="help-block">{errors.longDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keyHighlightsEnglish">
              Key Highlights (English)
            </label>
            <div className="input-group">
              <textarea
                value={otheritem.keyHighlightsEnglish || ""}
                onChange={this.handleChange}
                id="keyHighlightsEnglish"
                name="keyHighlightsEnglish"
                placeholder="Key Highlights in English"
                className={
                  "form-control py-2" +
                  (errors.keyHighlightsEnglish ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.keyHighlightsEnglish}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsEnglish")}
            </div>
            <span className="help-block">{errors.keyHighlightsEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyHighlightsArabic">Key Highlights (Arabic)</label>
            <div className="input-group">
              <textarea
                value={otheritem.keyHighlightsArabic || ""}
                onChange={this.handleChange}
                id="keyHighlightsArabic"
                name="keyHighlightsArabic"
                placeholder="Key Highlights in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keyHighlightsArabic ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.keyHighlightsArabic}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsArabic")}
            </div>
            <span className="help-block">{errors.keyHighlightsArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="disclaimerEnglish">Disclaimer (English)</label>
            <div className="input-group">
              <textarea
                value={otheritem.disclaimerEnglish || ""}
                onChange={this.handleChange}
                id="disclaimerEnglish"
                name="disclaimerEnglish"
                placeholder="Disclaimer in English"
                className={
                  "form-control py-2" +
                  (errors.disclaimerEnglish ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.disclaimerEnglish}
              ></textarea>
              {this.renderPencilIcon("disclaimerEnglish")}
            </div>
            <span className="help-block">{errors.disclaimerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="disclaimerArabic">Disclaimer (Arabic)</label>
            <div className="input-group">
              <textarea
                value={otheritem.disclaimerArabic || ""}
                onChange={this.handleChange}
                id="disclaimerArabic"
                name="disclaimerArabic"
                placeholder="Disclaimer in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.disclaimerArabic ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.disclaimerArabic}
              ></textarea>
              {this.renderPencilIcon("disclaimerArabic")}
            </div>
            <span className="help-block">{errors.disclaimerArabic}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewOtherItem && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {otheritem?.image_src && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={otheritem.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    const formPricingOneTimeContent = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Retail Price*</label>
            <div className="input-group">
              <input
                value={otheritem.retailPrice ?? ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder="Retail Price"
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.retailPrice}
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="finalPrice">Final Price*</label>
            <div className="input-group">
              <input
                value={otheritem.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="number"
                placeholder="Final Price"
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.finalPrice}
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={otheritem?.discountType}
                onChange={(event, value) => {
                  this.setState({
                    otheritem: {
                      ...otheritem,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewOtherItem && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountValue">Discount Value*</label>
            <div className="input-group">
              <input
                value={otheritem.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                className={
                  "form-control py-2" +
                  (errors.discountValue ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.discountValue}
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
      </>
    );

    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={otheritem.enableOneTime ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  otheritem: { ...otheritem, enableOneTime: isToggled },
                })
              }
            />
          </div>
        </div>
        {otheritem.enableOneTime && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscriptionItems}</span>
        <SubscriptionForm
          subscriptions={this.state.monthlyPricing || []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableSubscription"
              toggled={otheritem.enableSubscription ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  otheritem: { ...otheritem, enableSubscription: isToggled },
                })
              }
            />
          </div>
        </div>
        {otheritem.enableSubscription && formPricingSubscriptionContent}
      </>
    );

    const formPricing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="currency">Currency</label>
            <div className="input-group">
              <input
                value={otheritem?.currency || ""}
                id="currency"
                name="currency"
                type="text"
                className={
                  "form-control py-2" + (errors.currency ? " has-error" : "")
                }
                disabled={true}
              />
            </div>
            <span className="help-block">{errors.currency}</span>
          </div>
        </div>
        {formPricingOneTime}
        {formPricingSubscription}
      </div>
    );

    const formSeoMarketing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.seoUrl) || ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="seoUrl_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.url_name_arabic) || ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.seoUrl_arabic}</span>
          </div> */}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>   <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.meta_title_arabic) || ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.metaTitle_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={(otheritem && otheritem.meta_description_arabic) || ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewOtherItem && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(otheritem && otheritem.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(otheritem && otheritem.keywords_arabic) || ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewOtherItem && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
      </div>
    );

    const formFaq = (
      <div className="container mt-4">
        <FAQForm
          questions={otheritem?.faqs || []}
          onQuestionUpdated={this.handleQuestionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewOtherItem}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredOtherItems || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredOtherItems.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isOtherItemViewModalVisible}
          onHide={this.hideOtherItemViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>OtherItem: </span>
                {otheritem?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesaveOtherItem}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_otheritems">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_form_descriptions" title="Descriptions">
                {formDescriptions}
              </Tab>
              <Tab eventKey="key_form_pricing" title="Pricing">
                {formPricing}
              </Tab>
              <Tab eventKey="key_seo_form" title="SEO Marketing">
                {formSeoMarketing}
              </Tab>
              <Tab eventKey="key_form_faq" title="FAQ">
                {formFaq}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherItems);
