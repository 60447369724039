import React from "react";
import GoogleMap from "./GoogleMap";

function GoogleMapForm(props) {
  const {
    mapUrl,
    coordinates,
    onCopyLocation = (f) => f,
    onGoogleMaps = (f) => f,
  } = props;

  const invalidLocationCard = (
    <div className="card mt-2 mb-4">
      <div className="card-body">
        <h5 className="card-title">Invalid location!</h5>
        <p className="card-text">
          Cannot show location in map, because location is either not available
          or invalid.
        </p>
      </div>
    </div>
  );

  const googleMapForm = (
    <>
      <div className="row mt-2">
        <div className="form-group col text-right mb-1">
          <button
            id="btn-copy-location"
            onClick={(e) => onCopyLocation(mapUrl)}
            className="btn btn-sm btn-outline-dark font-weight-bold"
          >
            Copy Location
          </button>
          <button
            id="btn-google-maps"
            onClick={(e) => onGoogleMaps(mapUrl)}
            className="btn btn-sm btn-outline-dark font-weight-bold ml-2"
          >
            Google Maps
          </button>
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <GoogleMap coordinates={coordinates} />
        </div>
      </div>
    </>
  );

  return isValidLocation(coordinates) ? googleMapForm : invalidLocationCard;
}

function isValidLocation(coordinates) {
  if (!coordinates) return false;
  const { lat, lng } = coordinates;
  return lat && lng;
}

export default GoogleMapForm;
