import React from "react";
import Select from "react-select";

function SelectItemType(props) {
  const { ItemTypes, onItemTypeSelect, selectedItemTypeId ,filterText="Filter by Item Type"} = props;
  const createOption = (itemType) => {
    if (!itemType) return;
    return {
      key: itemType.id,
      value: itemType.id,
      label: itemType.text,
    };
  };

  const findItemType = (itemTypeId) =>
    ItemTypes.find((itemType) => itemType.id === selectedItemTypeId);
  const ItemTypeOptions = ItemTypes.map(createOption);
  const selectedItemType = findItemType(selectedItemTypeId);
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      value={createOption(selectedItemType)}
      onChange={(option) => onItemTypeSelect(option.value)}
      options={ItemTypeOptions}
      styles={selectStyles}
      placeholder={filterText}
      //   isDisabled={isDisabled}
    />
  );
}

export default SelectItemType;
