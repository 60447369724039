import React, { Component } from "react";
import { connect } from "react-redux";
import BioMarkForm from "./BioMarkForm";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import { saveBiomarker, updateBiomarker } from "services/BiomarkServices";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import Alert from "react-bootstrap/Alert";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import Select from "react-select";
import { Tabs, Tab } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import ProblematicBiomarkerForm from "./ProblematicBiomarkerForm";


import { fetchSupplementsSummaryByCountry } from "services/SupplementService";



import BiomarkerUnitConversion from "./BiomarkerUnitConversion";
import { ClickAwayListener } from "@material-ui/core";
import BiomarkerMaleSupplementSuggestion from "./BiomarkerMaleSupplementSuggestion";
import BiomarkerFemaleSupplementSuggestion from "./BiomarkerFemaleSupplementSuggestion";


// import MUIRichTextEditor from "mui-rte";
// import { ContentState, convertToRaw, convertFromRaw } from "draft-js";
// import { stateToHTML } from "draft-js-export-html";
// import { stateFromHTML } from "draft-js-import-html";

const BASE_URL_COUNTRIES = "countries/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_PROBLEMATIC_BIOMARKERS="problematic-biomarker-questions/biomarker-id"

const GENDER_OPTIONS = [
  {
    key: "M",
    text: "Male",
    value: "M",
  },
  {
    key: "F",
    text: "Female",
    value: "F",
  },
];


class ManageBioMarkers extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "name_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required.",
      },
      {
        field: "about_test_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Description is required.",
      },
      {
        field: "about_test",
        method: "isEmpty",
        validWhen: false,
        message: "Description is required.",
      },
      {
        field: "test",
        method: "isEmpty",
        validWhen: false,
        message: "Test is required.",
      },
      // Commented because now image is an optional field.
      // {
      //   field: "image",
      //   type: "image",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Image is required.",
      // },
    ]);

    this.state = {
      error: "",
      name: "",
      name_ar: "",
      about_test_ar: "",
      about_test: "",
      cause_test: "",
      cause_test_ar: "",
      control_test: "",
      control_test_ar: "",
      unit: "",
      test: this.props.testId,
      editAllowed: this.props.editAllowed,
      hasSeperate: false,
      arrMaleBioMarkRange: [],
      arrFemaleBioMarkRange: [],
      validation: this.validator.valid(),
      editId: "",
      arrBiomakerList: [],
      arrEditBiomarker: {},
      formHeading: "Create Biomarker",
      testList: [],
      allTests: [],
      testId: this.props.testId,
      testName: "Manage Biomarkers",
      showAlert: !this.props.editAllowed,
      showModal: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isLoaded: false,
      loadError: "",
      viewMode: false,
      image: '',
      imgSrc: '',
      countries: [],
      selectedCountry: null,
      countryOptions: [],
      is_biomarker_multiple_ranges:false,
      is_biomarker_gender_segregation:false,
      problematicBiomarkerDetails:{},
      problematicSubjectOptions:[],
      isOptimalRangeMale:false,
      isOptimalRangeFemale:false,
      unitConversion:[],
      testName:null

    };

    this.columns = [
      {
        name: "Biomarker Name (English)",
        selector: "name",
        sortable: true,
      },
      {
        name: "Biomarker Name (Arabic)",
        selector: "name_ar",
        sortable: true,
        right: true,
      },
      {
        name: "Unit",
        selector: "unit",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Status",
        // maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: (row) => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        ),
      },
      {
        name: "Country",
        selector: "selectedCountry",
        wrap: true,
        minWidth: "220px",
        cell: (order) => this.renderCountryDropdown(order),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {!this.state.editAllowed ? (
                <ActionBar.Item
                  onClick={() => this.editBiomark(row.index, true)}
                >
                  <VisibilityOutlinedIcon className="icon-small"></VisibilityOutlinedIcon>
                  View
                </ActionBar.Item>
              ) : (
                ""
              )}

              <ActionBar.Item onClick={() => this.editBiomark(row.index)}>
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.finalSubmit = false;
    this.submitted = false;
    this.markerMessage = "";
    this.femaleMarkerMessage = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.saveBiomarker = this.saveBiomarker.bind(this);
    this.updateBiomarker = this.updateBiomarker.bind(this);
    this.setBioMark = this.setBioMark.bind(this);
    this.formReset = this.formReset.bind(this);
    this.getBiomarkList = this.getBiomarkList.bind(this);
    this.editBiomark = this.editBiomark.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.selecteImage = this.selecteImage.bind(this);
  }

  selecteImage(e) {
    if (e.target.files && e.target.files.length) {
      let image = e.target.files[0];
      this.setState({ image });
      let reader = new FileReader();
      reader.onloadend = function () {
        this.setState({
          imgSrc: [reader.result],
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  }

  loadData=async()=> {
  await  this.getBiomarkList();
 

  }



  fetchProblematicQuestions=async()=>{
    try{
      const {data:problematic_biomarker_subjects=[]}= await PhoenixAPI.get(`problematic-biomarker-questions-name`);
      const problematicSubjectOptions=problematic_biomarker_subjects.length>0 && problematic_biomarker_subjects.map((option)=>{
          return {
           "key":option.id,
           "value":option.id,
           "text":option.questionEnglish
    }
      })
      this.setState({problematicSubjectOptions})
    }
    catch(error){
      console.log(" Error on fetching Problematic Questions",error.message)
    }
   
  }
  async getBiomarkList() {
    const testId = this.props.testId;
    if (!testId) {
      this.props.history.push("/dashboard/tests-and-packages/tests");
      return;
    }

    const url = "tests/" + testId + "/";
    // const url = "tests/" + testId + `?country=${1}`;
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    await API.get(url,{params:{country:this.state.selectedCountry?.id}})
      .then((response)=> {
        let newList = response.data.biomarkers;
        this.fetchProblematicQuestions();
        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");

        this.setState({
          testName: response.data.name,
          arrBiomakerList: newList,
          activeList,
          archivedList,
          isLoaded: true,
        });

       

        if (searchApplied) this.handleSearch(searchTerm);
        this.hideModal();
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching tests", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  handleUpdated = ( unitConversion= []) => {
    const conversion = {...unitConversion}
      this.setState({ unitConversion:conversion});
  };
  getBiomarkerUnitConversion=async(name)=>{
   const url= "biomarker/unit-conversion/test-name";
   console.log("biomarkername",this.state.name);

  const {data: allConversions = []}=  await PhoenixAPI.get(url,{params:{valeoBiomarkerName:name}});

  this.setState({unitConversion:allConversions},()=>this.renderUnitConversion());
  

  }



  async componentDidMount() {
   await this.loadCountries()
   this.loadData();
   
  }



  loadCountries = async () => {
    try {
      const { data: countries = [] } = await API.get("countries/");
      const activeCountries = countries.filter(country => country.is_active);
      const countryOptions = activeCountries.map(this.createCountryOption);
      const isDefaultCountry = (country) => country.is_default;
      const defaultCountry = activeCountries.find(isDefaultCountry);
      const selectedCountry = defaultCountry
      this.setState({ countries, countryOptions, selectedCountry },()=>this.fetchSupplements());
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }

  };

  createCountryOption = (country) => ({
    key: country.id,
    value: country.id,
    label: country.country_name,
  });

  findCountry = (id) => {
    const { countries = [] } = this.state;
    return countries.find(country => country.id === id);
  }
  fetchSupplements=async()=>{
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      // this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
      this.setState({supplements});
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      // this.hideLoading();
    }
  }
  toggleActiveStatus(item) {
    const {
      name,
      name_ar,
      about_test_ar,
      about_test,
      cause_test,
      cause_test_ar,
      control_test,
      control_test_ar,
      unit,
      test,
      image
    } = item;

    const biomarker_status = [],
      is_active = !item.is_active;

    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();
    this.props.toggleLoading({
      isLoading: true,
    });

    let formdata = new FormData();
    formdata.append("biomarker_status", biomarker_status);
    formdata.append("name", name);
    formdata.append("name_ar", name_ar);
    formdata.append("about_test_ar", about_test_ar);
    formdata.append("about_test", about_test);
    formdata.append("cause_test", cause_test);
    formdata.append("cause_test_ar", cause_test_ar);
    formdata.append("control_test", control_test);
    formdata.append("control_test_ar", control_test_ar);
    formdata.append("unit", unit);
    formdata.append("test", test);
    formdata.append("is_active", is_active);
    if (image && image.name){
      formdata.append("image", image, image.name)
      formdata.append("image_s3", image, image.name)
    }
    updateBiomarker(
      {
        biomarker_status,
        name,
        name_ar,
        about_test_ar,
        about_test,
        cause_test,
        cause_test_ar,
        control_test,
        control_test_ar,
        unit,
        test,
        is_active,
      },
      item.id
    )
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.getBiomarkList();
      })
      .catch((error) => {
        console.log("Error on updating biomarker", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Biomarker";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Biomarker successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Biomarker";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Biomarker successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }



  editBiomark=async(editBiomakerIndex, viewMode) =>{
    const arrEditBiomarker = this.state.arrBiomakerList[editBiomakerIndex];

    const biomarker_supplements= arrEditBiomarker?.biomarker_supplements ??[];
    const maleSuggestedSupplements= biomarker_supplements.length>0?biomarker_supplements.filter((item)=>item.gender==="M") :[];
    const femaleSuggestedSupplements=biomarker_supplements.length>0 ? biomarker_supplements.filter((item)=>item.gender==="F"):[];
    console.log("maleSuggestedSupplements", maleSuggestedSupplements);
    console.log("femaleSuggestedSupplements", femaleSuggestedSupplements);
    const {problematicBiomarkerDetails={}}=this.state
    const testdetails=  await API.get(`/tests/${this.state.testId}`);
    this.setState({testName:testdetails.data.name})
  await  this.getBiomarkerUnitConversion(testdetails.data.name,arrEditBiomarker.unit);
    if (arrEditBiomarker.id) {
      let arrMaleBioMarkRange = arrEditBiomarker.biomarker_status.filter(
        (value) => value.gender === "M"
      );
      let filteredArrMaleBioMarkRange =arrMaleBioMarkRange.filter((item)=>!item.is_optimal_range)

      let arrFemaleBioMarkRange = arrEditBiomarker.biomarker_status.filter(
        (value) => value.gender === "F"
      );
      let filteredArrFemaleBioMarkRange =arrFemaleBioMarkRange.filter((item)=>!item.is_optimal_range)


      // const {data:problematicBiomarkerDetails={}}=  await PhoenixAPI.get(`${BASE_URL_PROBLEMATIC_BIOMARKERS}/${arrEditBiomarker.id}`);

      // if(problematicBiomarkerDetails.id==null || undefined){
      //   delete problematicBiomarkerDetails["id"]
      // }

      let optimalRangeMarkers=arrEditBiomarker.biomarker_status.filter((item)=>item.is_optimal_range==true) ??[];
      let optimalRangeMaleMarkers=optimalRangeMarkers.length>0 ? optimalRangeMarkers.filter((item)=>item.gender==="M"):[];
      let optimalRangeFemaleMarkers=optimalRangeMarkers.length>0?optimalRangeMarkers.filter((item)=>item.gender==="F"):[];
     
      this.setState({
          name: arrEditBiomarker.name,
          name_ar: arrEditBiomarker.name_ar,
          about_test_ar: arrEditBiomarker.about_test_ar,
          about_test: arrEditBiomarker.about_test,
          cause_test: arrEditBiomarker.cause_test,
          cause_test_ar: arrEditBiomarker.cause_test_ar,
          control_test: arrEditBiomarker.control_test,
          control_test_ar: arrEditBiomarker.control_test_ar,
          unit: arrEditBiomarker.unit,
          editId: arrEditBiomarker.id,
          hasSeperate: true,
          arrMaleBioMarkRange:filteredArrMaleBioMarkRange,
          arrFemaleBioMarkRange:filteredArrFemaleBioMarkRange,
          formHeading: viewMode ? arrEditBiomarker.name : "Edit Biomarker",
          viewMode: viewMode ? true : false,
        image: arrEditBiomarker.image ? arrEditBiomarker.image : '',
        imgSrc: arrEditBiomarker.image ? arrEditBiomarker.image : '',
          is_active: arrEditBiomarker.is_active,
          is_biomarker_multiple_ranges:
            arrEditBiomarker.is_biomarker_multiple_ranges,
          is_biomarker_gender_segregation:
            arrEditBiomarker.is_biomarker_gender_segregation,
          problematicBiomarkerDetails: {
            ...problematicBiomarkerDetails,
            problematic_question_id:
              arrEditBiomarker.problematic_question_id ?? null,
          },
          optimalRangeMaleMarkers:optimalRangeMaleMarkers??[],
          optimalRangeFemaleMarkers:optimalRangeFemaleMarkers??[],
          isOptimalRangeMale:optimalRangeMarkers.length>0 ?? false,
          isOptimalRangeFemale:optimalRangeMarkers.length>0 ?? false,
          startingPointMale:(optimalRangeMaleMarkers.length>0 && optimalRangeMaleMarkers[0].range_start)??0,
          endingPointMale:(optimalRangeMaleMarkers.length>0 &&optimalRangeMaleMarkers[0].range_end)??0,
          startingPointFemale:(optimalRangeFemaleMarkers.length>0 && optimalRangeFemaleMarkers[0].range_start )??0,
          endingPointFemale:(optimalRangeFemaleMarkers.length>0 && optimalRangeFemaleMarkers[0].range_end)??0,
          is_male_default_suggested_supplement_required:arrEditBiomarker.is_male_default_suggested_supplement_required??false,
          is_female_default_suggested_supplement_required:arrEditBiomarker.is_male_default_suggested_supplement_required??false,
          maleSuggestedSupplements,
          femaleSuggestedSupplements
        },
        () => { this.getQuestionDetails();}
      );
    } else return;

    this.showModal();
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleCheckBoxChange() {
    const { hasSeperate } = this.state;
    this.setState({
      hasSeperate: !hasSeperate,
    });
  }


  validateMarkers(hasSeperate, markers, femaleMarkers) {
    let isValid = true;

    if (hasSeperate) {
      if (!markers.length) {
        this.markerMessage = "Markers are required.";
        isValid = false;
      } else this.markerMessage = "";

      if (!femaleMarkers.length) {
        this.femaleMarkerMessage = "Markers are required.";
        isValid = false;
      } else this.femaleMarkerMessage = "";
    } else {
      if (!markers.length) {
        this.markerMessage = "Markers are required.";
        isValid = false;
      } else this.markerMessage = "";
    }

    return isValid;
  }

  saveProblematicBiomarkers = async (id) => {
    const { problematicBiomarkerDetails = {} } = this.state;
    const { problematicBiomarkerAnswers = [] } = problematicBiomarkerDetails;
    const problematicAnswers =
      problematicBiomarkerAnswers.length > 0 &&
      problematicBiomarkerAnswers.map((it) => it.problematicBiomarkerStatus);
    const newarr =
      problematicAnswers.length > 0 &&
      problematicAnswers.flat().filter((item) => item);
    const updatedArr = newarr.length>0 && newarr.map((item) => ({
      ...item,
      biomarkerId: id,
    }));
    if(updatedArr.length>0){
      try {
        const response = await PhoenixAPI.patch(
          `/update-problematic-biomarker-status`,
          updatedArr
        );
        return response;
      } catch (error) {
        console.log("some error occured in updating ranges", error.message);
      }
    }
    else{
      return;
    }
    
  };
  saveBiomarker() {
    const { hasSeperate, arrMaleBioMarkRange, arrFemaleBioMarkRange } = this.state;
    const validMarkers = this.validateMarkers(
      hasSeperate,
      arrMaleBioMarkRange,
      arrFemaleBioMarkRange
    );
    this.setState({ finalSubmit: true });

    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid && validMarkers) {
      let {
        name,
        name_ar,
        about_test_ar,
        about_test,
        cause_test,
        cause_test_ar,
        control_test,
        control_test_ar,
        unit,
        test,
        hasSeperate,
        image,
        selectedCountry,
        is_biomarker_multiple_ranges,
        is_biomarker_gender_segregation,
        isOptimalRangeMale,
        isOptimalRangeFemale,
        startingPointMale,
        endingPointMale,
        startingPointFemale,
        endingPointFemale,
      } = this.state;

      let biomarker_status = arrMaleBioMarkRange;
      //if the check box for separte biomark is unticked and no new bio mark is added ,
      // then both value will be different so for making them equal the function s called

      

      if (!hasSeperate) {
        const arrFemaleBioMarkRange = JSON.parse(
          JSON.stringify(biomarker_status)
        );
        arrFemaleBioMarkRange.map((x) => {
          x.gender = "F";
          return x;
        });
        this.setState({
          arrFemaleBioMarkRange,
        });
        biomarker_status = biomarker_status.concat(arrFemaleBioMarkRange);
      } else {
        biomarker_status = biomarker_status.concat(
          this.state.arrFemaleBioMarkRange
        );
      }
      let optimalRangeMaleObject={}
      if(isOptimalRangeMale){
        optimalRangeMaleObject.status_name="optimal range";
        optimalRangeMaleObject.status_name_ar= "optimal range";
        optimalRangeMaleObject.gender= "M";
        optimalRangeMaleObject.status_color_code= "#007AFF";
        optimalRangeMaleObject.range_start =startingPointMale;
        optimalRangeMaleObject.range_end= endingPointMale;
        optimalRangeMaleObject.country= selectedCountry.id;
        optimalRangeMaleObject.is_optimal_range= true;
        biomarker_status.push(optimalRangeMaleObject)
      }
      let optimalRangeFeMaleObject={}
      if(isOptimalRangeFemale){
        optimalRangeFeMaleObject.status_name="optimal range";
        optimalRangeFeMaleObject.status_name_ar= "optimal range";
        optimalRangeFeMaleObject.gender= "F";
        optimalRangeFeMaleObject.status_color_code= "#007AFF";
        optimalRangeFeMaleObject.range_start =startingPointFemale;
        optimalRangeFeMaleObject.range_end= endingPointFemale;
        optimalRangeFeMaleObject.country= selectedCountry.id;
        optimalRangeFeMaleObject.is_optimal_range= true;
        biomarker_status.push(optimalRangeFeMaleObject)
      }
      this.props.toggleLoading({
        isLoading: true,
      });
      let formdata = new FormData();

      // for (let marker of biomarker_status) {
      //   //replace empty string with null for the api
      //   if (!marker.range_start) marker.range_start = null;
      //   if (!marker.range_end) marker.range_end = null;

      // }
       console.log("biomarker status:", biomarker_status)
      for (let i = 0; i < biomarker_status.length; i++) {
        const bioMark = biomarker_status[i];
        if (bioMark.range_start === null || bioMark.range_start === undefined)
          bioMark.range_start = null;
        if (bioMark.range_end === null || bioMark.range_end === undefined)
          bioMark.range_end = null;
        for (let key in bioMark) {
          formdata.append('biomarker_status[' + i + '][' + key + ']', bioMark[key]);
        }
      }
      // formdata.append("biomarker_status", JSON.stringify(biomarker_status));

      formdata.append("name", name);
      formdata.append("name_ar", name_ar);
      formdata.append("about_test_ar", about_test_ar);
      formdata.append("about_test", about_test);
      formdata.append("cause_test", cause_test);
      formdata.append("cause_test_ar", cause_test_ar);
      formdata.append("control_test", control_test);
      formdata.append("control_test_ar", control_test_ar);
      formdata.append("unit", unit);
      formdata.append("test", test);
      formdata.append("is_active", true);
      formdata.append("is_biomarker_multiple_ranges", is_biomarker_multiple_ranges);
      // formdata.append("is_biomarker_gender_segregation", is_biomarker_gender_segregation);

      if (image.name){
        formdata.append("image", image, image.name)
        formdata.append("image_s3", image, image.name)
      }

      saveBiomarker(formdata)
        .then((response) => {
          const {id:biomarkerId=""}= response && response.data || {}
          // this.saveProblematicBiomarkers(biomarkerId)
          this.hideModal();
          this.props.showNotificationMessage({
            notificationMessage: "Bio-marker successfully created.",
            successMessage: true,
            showNotification: true,
          });

          this.loadData();

          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on saving biomarker", error);
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        });
    } else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields!",
        successMessage: false,
        showNotification: true,
      });
    }
  }

  updateProblematicBiomarkers = async () => {
    const { problematicBiomarkerDetails = {} } = this.state;
    const { problematicBiomarkerAnswers = [], biomarkerId = "" } =
      problematicBiomarkerDetails || {};

    const problematicAnswers =
      problematicBiomarkerAnswers.length > 0 &&
      problematicBiomarkerAnswers.map((it) => it.problematicBiomarkerStatus);
    const newarr = problematicAnswers.flat().filter((item) => item);
  if(newarr.length>0){
    try {
      const response = await PhoenixAPI.patch(
        `/update-problematic-biomarker-status`,
        newarr
      );
      return response;
    } catch (error) {
      console.log("some error occured in updating ranges", error.message);
    }
  }
  else{
    return;
  }
    
  };

  updateBiomarker() {
    const validMarkers = this.validateMarkers(
      this.state.hasSeperate,
      this.state.arrMaleBioMarkRange,
      this.state.arrFemaleBioMarkRange
    );

    this.setState({ finalSubmit: true });

    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid && validMarkers) {
      let {
        name,
        name_ar,
        about_test_ar,
        about_test,
        cause_test,
        cause_test_ar,
        control_test,
        control_test_ar,
        unit,
        test,
        hasSeperate,
        editId,
        image,
        is_active,
        selectedCountry,
        is_biomarker_multiple_ranges,
        is_biomarker_gender_segregation,
        problematicBiomarkerDetails,
        isOptimalRangeMale,
        isOptimalRangeFemale,
        startingPointMale,
        endingPointMale,
        startingPointFemale,
        endingPointFemale,
        optimalRangeMaleMarkers,
        optimalRangeFemaleMarkers,
        is_male_default_suggested_supplement_required,
        is_female_default_suggested_supplement_required,
        maleSuggestedSupplements,
        femaleSuggestedSupplements
      } = this.state;
      const { problematic_question_id = "" } =
        problematicBiomarkerDetails || {};
      let biomarker_status = this.state.arrMaleBioMarkRange;
      let biomarker_supplements=[];
      if (maleSuggestedSupplements.length > 0) {
        maleSuggestedSupplements.map((item) => {
      
          const { period_name, supplement_name, ...supplement } = item
          
          biomarker_supplements.push(supplement)
        })
      }
       
      if(femaleSuggestedSupplements.length>0){
         femaleSuggestedSupplements.map((item) => {
          const { period_name, supplement_name, ...supplement } = item
          biomarker_supplements.push(supplement)
        })

      }
      console.log("Biomarkers supplements: " , biomarker_supplements);
       
      if (!hasSeperate) {
        const arrFemaleBioMarkRange = JSON.parse(
          JSON.stringify(biomarker_status)
        );
        arrFemaleBioMarkRange.map((x) => {
          x.gender = "F";
          return x;
        });
        this.setState({
          arrFemaleBioMarkRange,
        });
        biomarker_status = biomarker_status.concat(arrFemaleBioMarkRange);
      } else {
        biomarker_status = biomarker_status.concat(
          this.state.arrFemaleBioMarkRange
        );
      }
      if (!(optimalRangeMaleMarkers.length>0)) {
        let optimalRangeMaleObject = {}
        if (isOptimalRangeMale) {
          optimalRangeMaleObject.status_name = "optimal range";
          optimalRangeMaleObject.status_name_ar = "optimal range";
          optimalRangeMaleObject.gender = "M";
          optimalRangeMaleObject.status_color_code = "#007AFF";
          optimalRangeMaleObject.range_start = startingPointMale;
          optimalRangeMaleObject.range_end = endingPointMale;
          optimalRangeMaleObject.country = selectedCountry.id;
          optimalRangeMaleObject.is_optimal_range = true;
        }
        biomarker_status.push(optimalRangeMaleObject)
      }
      else {
        optimalRangeMaleMarkers[0].range_start = startingPointMale;
        optimalRangeMaleMarkers[0].range_end = endingPointMale;
        biomarker_status.push(optimalRangeMaleMarkers[0])
      }

      if (!(optimalRangeFemaleMarkers.length>0)) {
        let optimalRangeFeMaleObject = {}
        if (isOptimalRangeFemale) {
          optimalRangeFeMaleObject.status_name = "optimal range";
          optimalRangeFeMaleObject.status_name_ar = "optimal range";
          optimalRangeFeMaleObject.gender = "F";
          optimalRangeFeMaleObject.status_color_code = "#007AFF";
          optimalRangeFeMaleObject.range_start = startingPointFemale;
          optimalRangeFeMaleObject.range_end = endingPointFemale;
          optimalRangeFeMaleObject.country = selectedCountry.id;
          optimalRangeFeMaleObject.is_optimal_range = true;
        }
        biomarker_status.push(optimalRangeFeMaleObject)
      }
      else {
        optimalRangeFemaleMarkers[0].range_start = startingPointFemale;
        optimalRangeFemaleMarkers[0].range_end = endingPointFemale;
        biomarker_status.push(optimalRangeFemaleMarkers[0])
      }
   
      let formdata = new FormData();
      // for (let marker of biomarker_status) {
      //   //replace empty string with null for the api
      //   if (marker.range_start === "") marker.range_start = null;
      //   if (marker.range_end === "") marker.range_end = null;
      //   formdata.append("biomarker_status[]", marker);
      // }
      for (let i = 0; i < biomarker_status.length; i++) {
        if (
          biomarker_status[i].range_start === null ||
          biomarker_status[i].range_start === ""
        )
          biomarker_status[i].range_start = 0;
        if (biomarker_status[i].range_end === "")
          biomarker_status[i].range_end = null;
        for (let key in biomarker_status[i]) {
          formdata.append(
            "biomarker_status[" + i + "][" + key + "]",
            biomarker_status[i][key]
          );
        }
      }

      for (let i = 0; i < biomarker_supplements.length; i++){
        for (let key in biomarker_supplements[i]) {
          formdata.append(
            "biomarker_supplements[" + i + "][" + key + "]",
            biomarker_supplements[i][key]
          );
        }
      }
     

      this.props.toggleLoading({
        isLoading: true,
      });
      formdata.append("name", name);
      formdata.append("name_ar", name_ar);
      formdata.append("about_test_ar", about_test_ar);
      formdata.append("about_test", about_test);
      formdata.append("cause_test", cause_test);
      formdata.append("cause_test_ar", cause_test_ar);
      formdata.append("control_test", control_test);
      formdata.append("control_test_ar", control_test_ar);
      formdata.append("unit", unit);
      formdata.append("test", test);
      formdata.append("is_active", is_active);
      formdata.append(
        "is_biomarker_multiple_ranges",
        is_biomarker_multiple_ranges
      );
      if(problematic_question_id && problematic_question_id){
        formdata.append("problematic_question_id", problematic_question_id);
      }
      
      // formdata.append("is_biomarker_gender_segregation", is_biomarker_gender_segregation);

      if (image.name) {
        formdata.append("image", image, image.name);
        formdata.append("image_s3", image, image.name);
      }

      formdata.append("is_male_default_suggested_supplement_required", is_male_default_suggested_supplement_required);
      formdata.append("is_female_default_suggested_supplement_required", is_female_default_suggested_supplement_required);

      updateBiomarker(formdata, editId)
        .then((response) => {
          if (this.state.problematicBiomarkerDetails.id) {
            this.updateProblematicBiomarkers(editId);
          } else {
            this.saveProblematicBiomarkers(editId);
          }
          this.hideModal();
          this.props.showNotificationMessage({
            notificationMessage: "Bio-marker successfully updated.",
            successMessage: true,
            showNotification: true,
          });

          this.loadData();

          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on updating biomarker");
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        });
    } else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields!",
        successMessage: false,
        showNotification: true,
      });
    }
  }

  setBioMark(arrBioMark, strGender) {
    console.log(arrBioMark);
    if (this.state.hasSeperate) {
      if (strGender === "M") {
        this.setState({
          arrMaleBioMarkRange: arrBioMark,
        });
      } else {
        if (strGender === "F") {
          this.setState({
            arrFemaleBioMarkRange: arrBioMark,
          });
        }
      }
    } else {
      const arrFemaleBioMarkRange = JSON.parse(JSON.stringify(arrBioMark));
      arrFemaleBioMarkRange.map((x) => {
        x.gender = "F";
        return x;
      });
      this.setState({
        arrFemaleBioMarkRange,
        arrMaleBioMarkRange: arrBioMark,
      });
    }
    console.log(
      this.state.arrFemaleBioMarkRange,
      this.state.arrMaleBioMarkRange
    );
  }
  formReset() {
    this.setState({
      error: "",
      name: "",
      name_ar: "",
      about_test_ar: "",
      about_test: "",
      cause_test: "",
      cause_test_ar: "",
      control_test: "",
      control_test_ar: "",
      unit: "",
      hasSeperate: false,
      arrMaleBioMarkRange: [],
      arrFemaleBioMarkRange: [],
      validation: this.validator.valid(),
      editId: "",
      finalSubmit: false,
      formHeading: "Create Biomarker",
      image: "",
      imgSrc: "",
      is_biomarker_multiple_ranges: false,
      is_biomarker_gender_segregation: false,
      problematicBiomarkerDetails: {},
      isOptimalRangeMale:false,
      isOptimalRangeFemale:false,
      startingPointMale:"",
      endingPointMale:"",
      startingPointFemale:"",
      endingPointFemale:"",
    });

    this.markerMessage = "";
    this.femaleMarkerMessage = "";
    this.submitted = false;
  }

  hideModal() {
    this.formReset();
    this.setState({ showModal: false });
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.arrBiomakerList;

    let newList = [];

    for (let item of list) {
      if (
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.name_ar.toLowerCase().indexOf(newValue) !== -1 ||
        item.unit.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  // Commented because we have to revert the rich text editor changes.
  // saveRichTextEditorContent = (name, data) => {
  //   const obj = {};
  //   obj[name] = stateToHTML(convertFromRaw(JSON.parse(data)));;
  //   this.setState(obj);
  // }

  // renderRichTextEditor = (name, plaeholder, className) => {
  //   const { viewMode } = this.state;

  //   return <MUIRichTextEditor
  //     name={name}
  //     label={plaeholder}
  //     defaultValue={this.state[name] ?
  //       JSON.stringify(convertToRaw(stateFromHTML(this.state[name]))) : ""}
  //     inlineToolbar={true}
  //     readOnly={viewMode ? true : false}
  //     onChange={(e) => { this.event = e; }}
  //     onSave={data => this.saveRichTextEditorContent(name, data)}
  //     className={className ? `form-control ${className}` : "form-control"}
  //   />
  // }

  renderCountryDropdown = () => {
    const { countries = [] } = this.state;
    const activeCountries = countries.filter((country) => country.is_active);
    const titleOption = (
      <option key={-1} value={-1} hidden={true}>
        Select Country
      </option>
    );
    const countryOptions = activeCountries.map((country) => (
      <option key={country.id} value={country.id}>
        {country.country_name}
      </option>
    ));
    return (
      <select
        value={this.state.selectedCountry?.id ?? ""}
        onChange={(event) =>
          this.handleCountrySelect(Number(event.target.value))
        }
        name="select_countries"
        className="form-control"
      >
        {!this.state.selectedCountry && titleOption}
        {countryOptions}
      </select>
    );
  };

  handleSubmit = () => {
    if (this.state.editId) {
      this.updateBiomarker();
    } else {
      this.saveBiomarker();
    }
  };

  handleCountrySelect = (countryId) => {
    this.setState({ selectedCountry: this.findCountry(countryId) }, () =>
      this.loadData()
    );
  };

  handleBiomarkersUpdated = (biomarkers = []) => {
    const problematicBiomarkerDetails = {
      ...this.state.problematicBiomarkerDetails,
      problematicBiomarkerAnswers: biomarkers,
    };
    this.setState({ problematicBiomarkerDetails });
  };

  // handleChangeProblematicBiomarker=(event)=>{
  //   const {problematicBiomarkerDetails}= this.state
  //   const target= event.target;
  //   this.setState({ problematicBiomarkerDetails:{
  //     ...problematicBiomarkerDetails,[target.name]:target.value
  //   }})
  // }

  getQuestionDetails = async () => {
    const { problematic_question_id = "" } =
      this.state.problematicBiomarkerDetails;
    const { editId = "" , selectedCountry=null} = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    if (!problematic_question_id) {
      // this.props.showNotificationMessage({
      //   notificationMessage:
      //     "Error in fetching Problematic Questions and answers",
      //   successMessage: false,
      //   showNotification: true,
      // });
      return;
    }
    try {
      //  const {data:problematicQuestionDetails={}}= await PhoenixAPI.get(`/problematic-biomarker-questions-answers/${problematic_question_id}`)
      //  console.log("response:", problematicQuestionDetails )
      //  problematicQuestionDetails.biomarkerId=editId;
      const { data: problematicQuestionDetails = {} } = await PhoenixAPI.get(
        `/problematic-biomarker-questions/biomarker-id/${editId}/question-id/${problematic_question_id}/?countryId=${selectedCountry.id}`
      );
      //  console.log("response:", problematicQuestionDetails )
      problematicQuestionDetails.biomarkerId = editId;
      problematicQuestionDetails.problematic_question_id =
        problematic_question_id;
      this.setState(
        { problematicBiomarkerDetails: problematicQuestionDetails },
        () => this.renderProblematicAnswers()
      );
    } catch (error) {
      console.log(
        "Error in fetching Problematic Questions and answers",
        error.message
      );
      this.props.showNotificationMessage({
        notificationMessage:
          "Error in fetching Problematic Questions and answers",
        successMessage: false,
        showNotification: true,
      });
    }
  };

  renderUnitConversion=()=>{
    const{unitConversion}=this.state;
    console.log("unitConversion in render",unitConversion);

    return(
      <BiomarkerUnitConversion 
          biomarkerName={this.state.testName}
          unitConversion={this.state.unitConversion}
          onUnitConversionUpdate={this.handleUpdated}
          toUnit={this.state.unit}
         />
      
    )
  }

  renderProblematicAnswers = () => {
    const {
      selectedCountry,
      problematicBiomarkerDetails,
      is_biomarker_multiple_ranges,
    } = this.state;
    return (
      <>
        {is_biomarker_multiple_ranges &&
          problematicBiomarkerDetails.problematicBiomarkerAnswers && (
            <ProblematicBiomarkerForm
              biomarkers={
                problematicBiomarkerDetails.problematicBiomarkerAnswers || []
              }
              onBiomarkersUpdated={(biomarkers) =>
                this.handleBiomarkersUpdated(biomarkers)
              }
              isEditable={true}
              country={selectedCountry}
              unit={this.state.unit ?? ""}
              problematicQuestionId={
                problematicBiomarkerDetails.problematic_question_id ?? ""
              }
              biomarkerId={this.state.editId ?? ""}
            />
          )}
      </>
    );
  };

  handleMaleSupplementSuggestions=(supplements=[])=>{
    const {selectedCountry} = this.state;
    const mappedMaleSupplementSuggestions=supplements.map((item)=>{
      return {...item,country_id:selectedCountry?.id, gender:"M"}
    })
     this.setState({maleSuggestedSupplements:mappedMaleSupplementSuggestions})
  }
  handleFemaleSupplementSuggestions=(supplements=[])=>{
    const {selectedCountry} = this.state;
    const mappedFemaleSupplementSuggestions=supplements.map((item)=>{
      return {...item,country_id:selectedCountry?.id, gender:"F"}
    })
    this.setState({femaleSuggestedSupplements:mappedFemaleSupplementSuggestions})
  }

  render() {
    // let form,
    let finalList = [];

    const {
      arrBiomakerList,
      showAlert,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      viewMode,
      selectedCountry,
      countries,
      countryOptions,
      problematicBiomarkerDetails,
      is_biomarker_gender_segregation,
      is_biomarker_multiple_ranges,
      problematicSubjectOptions,
      isOptimalRangeMale,
      isOptimalRangeFemale,
      startingPointMale,
      endingPointMale,
      startingPointFemale,
      endingPointFemale,
      errors,
      is_male_default_suggested_supplement_required,
      is_female_default_suggested_supplement_required,
      supplements,
      unitConversion,
    } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (this.state.finalSubmit)
      this.validateMarkers(
        this.state.hasSeperate,
        this.state.arrMaleBioMarkRange,
        this.state.arrFemaleBioMarkRange
      );

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        // if (showItems === "Active") finalList = activeList;
        // else if (showItems === "Archived") finalList = archivedList;
        // else finalList = arrBiomakerList;
        finalList = arrBiomakerList;
      }
    }

    const detailsForm = (
      <div className="manage-bio-markers">
        <div>
          <div className="form-row">
            <div className="form-group col">
              <label>Name (English) *</label>
              <input
                readOnly={viewMode ? true : false}
                type="text"
                name="name"
                className={
                  "form-control " + (validation.name.isInvalid && "has-error")
                }
                placeholder="Name in English"
                onChange={this.handleChange}
                value={this.state.name}
              ></input>
              <span className="help-block">{validation.name.message}</span>
            </div>
            <div className="form-group col">
              <label>Name (Arabic) *</label>
              <input
                readOnly={viewMode ? true : false}
                type="text"
                name="name_ar"
                className={
                  "form-control input-arabic " +
                  (validation.name_ar.isInvalid && "has-error")
                }
                placeholder="Name in Arabic"
                onChange={this.handleChange}
                value={this.state.name_ar}
              ></input>
              <span className="help-block">{validation.name_ar.message}</span>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <label>Description (English) *</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="about_test"
                className={
                  "form-control " +
                  (validation.about_test.isInvalid && "has-error")
                }
                placeholder="Description in English"
                onChange={this.handleChange}
                value={this.state.about_test}
              ></textarea>
              <span className="help-block">
                {validation.about_test.message}
              </span>
            </div>
            <div className="form-group col">
              <label>Description (Arabic) *</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="about_test_ar"
                className={
                  "form-control input-arabic " +
                  (validation.about_test_ar.isInvalid && "has-error")
                }
                placeholder="Description in Arabic"
                onChange={this.handleChange}
                value={this.state.about_test_ar}
              ></textarea>
              <span className="help-block">
                {validation.about_test_ar.message}
              </span>
            </div>
          </div>
          {/* <div className="form-row">
           <div className="form-group col">
           <label htmlFor="country_select">Country *</label>
              <Select
                key={`country_select${selectedCountry ? selectedCountry.id : ""}`}
                value={selectedCountry ? this.createCountryOption(selectedCountry) : ""}
                onChange={(event) => this.setState({ selectedCountry: this.findCountry(event.value) }, () => this.loadData())}
                options={countryOptions}
                styles={selectStyles}
                placeholder="Select Country..."
              />
           </div>
            <div className="form-group col">
              <label>Unit</label>
              <input
                readOnly={viewMode ? true : false}
                type="text"
                name="unit"
                className={
                  "form-control "
                }
                placeholder="Unit"
                onChange={this.handleChange}
                value={this.state.unit}
              ></input>
              {/* <span className="help-block">{validation.unit.message}</span> */}
          {/* </div>
          </div>  */}
          <div className="form-row">
            <div className="form-group col">
              <label>What causes it (English)</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="cause_test"
                className="form-control"
                placeholder="What causes it in English"
                onChange={this.handleChange}
                value={this.state.cause_test}
              ></textarea>
              {/* {this.renderRichTextEditor("cause_test", "What causes it in English")} */}
            </div>
            <div className="form-group col">
              <label>What causes it (Arabic)</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="cause_test_ar"
                className="form-control input-arabic"
                placeholder="What causes it in Arabic"
                onChange={this.handleChange}
                value={this.state.cause_test_ar}
              ></textarea>
              {/* {this.renderRichTextEditor("cause_test_ar", "What causes it in Arabic", "input-arabic")} */}
            </div>
          </div>
          <div className="form-row mb-4">
            <div className="form-group col">
              <label>How to control (English)</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="control_test"
                className="form-control"
                placeholder="How to control in English"
                onChange={this.handleChange}
                value={this.state.control_test}
              ></textarea>
              {/* {this.renderRichTextEditor("control_test", "How to control in English")} */}
            </div>
            <div className="form-group col">
              <label>How to control (Arabic)</label>
              <textarea
                readOnly={viewMode ? true : false}
                type="text"
                name="control_test_ar"
                className="form-control input-arabic"
                placeholder="How to control in Arabic"
                onChange={this.handleChange}
                value={this.state.control_test_ar}
              ></textarea>
              {/* {this.renderRichTextEditor("control_test_ar", "How to control in Arabic", "input-arabic")} */}
            </div>
          </div>
          {/* {!this.state.editId && (
            <div className="form-row">
              <div className="form-group col custom-check-box">
                <input
                  readOnly={viewMode ? true : false}
                  type="checkbox"
                  name="hasSeperate"
                  onChange={this.handleCheckBoxChange}
                  checked={this.state.hasSeperate}
                />
                <label className="text">
                  Separate bio-markers for male and female
                </label>
              </div>
            </div>
          )} */}
          {/* <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className={
                  " bio-markers-box " +
                  (this.markerMessage ? "marker-error" : "")
                }
              >
                <h6>
                  Markers for{" "}
                  {this.state.hasSeperate ? "Male" : "Male and Female"}
                </h6>
                <BioMarkForm
                  key={this.state.editId + "M"}
                  gender={"M"}
                  arrBioMark={this.state.arrMaleBioMarkRange}
                  setBioMark={(arrBioMark) => this.setBioMark(arrBioMark, "M")}
                  unit={this.state.unit}
                  country={this.state.selectedCountry}
                  viewMode={viewMode ? true : false}
                ></BioMarkForm>
              </div>
              <span className="help-block">{this.markerMessage}</span>
            </div>
            {this.state.hasSeperate && (
              <div className="col-lg-6 col-md-6 ">
                <div
                  className={
                    " bio-markers-box " +
                    (this.femaleMarkerMessage ? "marker-error" : "")
                  }
                >
                  <h6>Markers for Female</h6>
                  <BioMarkForm
                    key={this.state.editId + "F"}
                    gender={"F"}
                    arrBioMark={this.state.arrFemaleBioMarkRange}
                    setBioMark={(arrBioMark) =>
                      this.setBioMark(arrBioMark, "F")
                    }
                    unit={this.state.unit}
                    viewMode={viewMode ? true : false}
                    country={this.state.selectedCountry}
                  ></BioMarkForm>
                </div>
                <span className="help-block">{this.femaleMarkerMessage}</span>
              </div>
            )}
          </div> */}
          <div className="row mt-2">
            <div className="col separator">
              <span>Media</span>
            </div>
          </div>
          <div className="form-row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group col ">
                <label>
                  <b>Image</b>
                </label>
                <input
                  type="file"
                  id="imageInput"
                  name="image"
                  accept="image/*"
                  className="form-control "
                  // className={
                  //   "form-control " + (validation.image.isInvalid && "has-error")
                  // }
                  onChange={this.selecteImage}
                />
                {/* <span className="help-block">{validation.image.message}</span> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group col ">
                {this.state.imgSrc && (
                  <>
                    <label>
                      <b>Selected Image</b>
                    </label>
                    <div className="form-image-container">
                      <img
                        src={this.state.imgSrc}
                        className="form-image"
                        alt="selected"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    const rangesForm = (
      <div className="manage-bio-markers">
        <div>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="country_select">Country *</label>
              <Select
                key={`country_select${
                  selectedCountry ? selectedCountry.id : ""
                }`}
                value={
                  selectedCountry
                    ? this.createCountryOption(selectedCountry)
                    : ""
                }
                onChange={(event) =>
                  this.setState(
                    { selectedCountry: this.findCountry(event.value) },
                    () => this.loadData()
                  )
                }
                options={countryOptions}
                styles={selectStyles}
                placeholder="Select Country..."
                isDisabled={true}
              />
            </div>
            <div className="form-group col">
              <label>Unit</label>
              <input
                readOnly={viewMode ? true : false}
                type="text"
                name="unit"
                className={"form-control "}
                placeholder="Unit"
                onChange={this.handleChange}
                value={this.state.unit}
              ></input>
              {/* <span className="help-block">{validation.unit.message}</span> */}
            </div>
          </div>

          {!this.state.editId && (
            <div className="form-row">
              <div className="form-group col custom-check-box">
                <input
                  readOnly={viewMode ? true : false}
                  type="checkbox"
                  name="hasSeperate"
                  onChange={this.handleCheckBoxChange}
                  checked={this.state.hasSeperate}
                />
                <label className="text">
                  Separate bio-markers for male and female
                </label>
              </div>
            </div>
          )}

          {this.state.editId ? (
            <div className="form-row">
              <div className="form-check custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={is_biomarker_multiple_ranges || ""}
                  onChange={(event, value) => {
                    this.setState({
                      is_biomarker_multiple_ranges: event.target.checked,
                    });
                  }}
                  id="is_biomarker_multiple_ranges"
                  name="is_biomarker_multiple_ranges"
                  type="checkbox"
                  className="custom-control-input"
                  // disabled={!isEditable}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_biomarker_multiple_ranges"
                >
                  Does this biomarker require multiple ranges ?
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* {is_biomarker_multiple_ranges ? <div className="form-row">
            <div className="form-check custom-control custom-checkbox ml-2">
              <input
                value={true}
                checked={this.state.is_biomarker_gender_segregation || ""}
                onChange={(event, value) => {
                  this.setState({
                    is_biomarker_gender_segregation: event.target.checked
                  });
                }}
                id="is_biomarker_gender_segregation"
                name="is_biomarker_gender_segregation"
                type="checkbox"
                className="custom-control-input"
              // disabled={!isEditable}
              />
              <label className="custom-control-label" htmlFor="is_biomarker_gender_segregation">
                Does this biomarker require Gender segregation ?
              </label>
            </div>
          </div> : ""} */}

          {/* {is_biomarker_gender_segregation && (
            <div>
              <div className="form-row">
                <div className="form-group col-6">
                  <label htmlFor="status">Gender *</label>
                  <Dropdown
                    value={problematicBiomarkerDetails && problematicBiomarkerDetails.gender}
                    onChange={(event, value) => {
                      this.setState({
                        problematicBiomarkerDetails: {
                          ...problematicBiomarkerDetails, gender: value.value
                        }
                      });
                    }}
                    id="gender"
                    name="gender"
                    placeholder="Gender"
                    search
                    selection
                    options={GENDER_OPTIONS}
                    // disabled={!isNewOtherItem && !editables.status}
                    className={"form-control"}
                  />
                </div>
              </div>


            </div>
          )} */}

          {is_biomarker_multiple_ranges && (
            <div>
              <div className="row">
                <div className="form-group col-6">
                  <label htmlFor="status">Question (English) *</label>
                  <Dropdown
                    value={
                      problematicBiomarkerDetails.problematic_question_id ?? ""
                    }
                    onChange={(event, value) => {
                      this.setState(
                        {
                          problematicBiomarkerDetails: {
                            ...problematicBiomarkerDetails,
                            problematic_question_id: value.value,
                          },
                        },
                        () => this.getQuestionDetails()
                      );
                    }}
                    id="quetionEnglish"
                    name="quetionEnglish"
                    placeholder="select question"
                    search
                    selection
                    options={problematicSubjectOptions}
                    // disabled={!isNewOtherItem && !editables.status}
                    className={"form-control"}
                  />
                </div>
                <div className="form-group col">
                  <label htmlFor="questionArabic">Question (Arabic)*</label>
                  <div className="input-group">
                    <input
                      value={problematicBiomarkerDetails?.questionArabic || ""}
                      // onChange={(event) => this.handleChangeProblematicBiomarker(event)}
                      id="questionArabic"
                      name="questionArabic"
                      type="text"
                      placeholder="Enter Question in Arabic"
                      className={"form-control py-2 input-arabic"}

                      // disabled={!isNewOtherItem && !editables.externalNameArabic}
                    />
                    {/* {this.renderPencilIcon("externalNameArabic")} */}
                  </div>
                  {/* <span className="help-block">{errors.externalNameArabic}</span> */}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group col-6">
                  <label htmlFor="questionArabic">Subject *</label>
                  <div className="input-group">
                    <input
                      value={problematicBiomarkerDetails.subject ?? ""}
                      // onChange={(event) => this.handleChangeProblematicBiomarker(event)}
                      id="subject"
                      name="subject"
                      type="text"
                      placeholder="Enter Subject"
                      className={"form-control py-2"}
                    />
                    {/* {this.renderPencilIcon("externalNameArabic")} */}
                  </div>
                </div>
              </div>

              {/* <div className="form-row">
                <div className="form-check custom-control custom-checkbox ml-2">
                  <input
                    value={problematicBiomarkerDetails && problematicBiomarkerDetails.isShowEveryOrder}
                    checked={problematicBiomarkerDetails && problematicBiomarkerDetails.isShowEveryOrder || ""}
                    onChange={(event, value) => {
                      this.setState({
                        problematicBiomarkerDetails: {
                          ...problematicBiomarkerDetails, isShowEveryOrder: event.target.checked
                        }
                      });
                    }}
                    id="isShowEveryOrder"
                    name="isShowEveryOrder"
                    type="checkbox"
                    className="custom-control-input"
                  // disabled={!isEditable}
                  />
                  <label className="custom-control-label" htmlFor="isShowEveryOrder">
                    should this question pop-up with every order?
                  </label>
                </div>
              </div> */}
            </div>
          )}
          {!is_biomarker_multiple_ranges && (
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div
                  className={
                    " bio-markers-box " +
                    (this.markerMessage ? "marker-error" : "")
                  }
                >
                  <h6>
                    Markers for{" "}
                    {this.state.hasSeperate ? "Male" : "Male and Female"}
                  </h6>
                  <BioMarkForm
                    key={this.state.editId + "M"}
                    gender={"M"}
                    arrBioMark={this.state.arrMaleBioMarkRange}
                    setBioMark={(arrBioMark) =>
                      this.setBioMark(arrBioMark, "M")
                    }
                    unit={this.state.unit}
                    country={this.state.selectedCountry}
                    viewMode={viewMode ? true : false}
                  ></BioMarkForm>
                </div>
                <span className="help-block">{this.markerMessage}</span>
              </div>
              {this.state.hasSeperate && (
                <div className="col-lg-6 col-md-6 ">
                  <div
                    className={
                      " bio-markers-box " +
                      (this.femaleMarkerMessage ? "marker-error" : "")
                    }
                  >
                    <h6>Markers for Female</h6>
                    <BioMarkForm
                      key={this.state.editId + "F"}
                      gender={"F"}
                      arrBioMark={this.state.arrFemaleBioMarkRange}
                      setBioMark={(arrBioMark) =>
                        this.setBioMark(arrBioMark, "F")
                      }
                      unit={this.state.unit}
                      viewMode={viewMode ? true : false}
                      country={this.state.selectedCountry}
                    ></BioMarkForm>
                  </div>
                  <span className="help-block">{this.femaleMarkerMessage}</span>
                </div>
              )}
            </div>
          )}

          {/*         
         {is_biomarker_multiple_ranges && problematicBiomarkerDetails.problematicBiomarkerAnswers && <ProblematicBiomarkerForm
            biomarkers={problematicBiomarkerDetails && problematicBiomarkerDetails.problematicBiomarkerAnswers || []}
            onBiomarkersUpdated={(biomarkers)=>this.handleBiomarkersUpdated(biomarkers)}
            isEditable={true}
            country={selectedCountry}
            unit={this.state.unit}
            problematicQuestionId={problematicBiomarkerDetails.problematic_question_id}
            biomarkerId={this.state.editId}
          />} */}
          {this.renderProblematicAnswers()}
        
        </div>
        {!is_biomarker_multiple_ranges && <>
          <div className="row">
            <div className="form-group col">
              <div className="form-check custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={isOptimalRangeMale ?? false}
                  onChange={(event, value) => {
                    this.setState({
                      isOptimalRangeMale: event.target.checked,
                    });
                  }}
                  id="isOptimalRangeMale"
                  name="isOptimalRangeMale"
                  type="checkbox"
                  className="custom-control-input"
                // disabled={!isEditable}
                />
                <label
                  className="custom-control-label"
                  htmlFor="isOptimalRangeMale"
                >
                  Requires Optimal Range
                </label>
              </div>
            </div>
            <div className="form-group col">
              <div className="form-check custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={isOptimalRangeFemale ?? false}
                  onChange={(event, value) => {
                    this.setState({
                      isOptimalRangeFemale: event.target.checked,
                    });
                  }}
                  id="isOptimalRangeFemale"
                  name="isOptimalRangeFemale"
                  type="checkbox"
                  className="custom-control-input"
                // disabled={!isEditable}
                />
                <label
                  className="custom-control-label"
                  htmlFor="isOptimalRangeFemale"
                >
                  Requires Optimal Range
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="startingPointMale">
                Starting Point
              </label>
              <div className="input-group">
                <input
                  value={startingPointMale ?? ""}
                  onChange={this.handleChange}
                  id="startingPointMale"
                  name="startingPointMale"
                  type="number"
                  placeholder="Starting Point"
                  className={
                    "form-control py-2"
                  }
                 
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="startingPointFemale">  Starting Point </label>
              <div className="input-group">
                <input
                  value={startingPointFemale ?? ""}
                  onChange={this.handleChange}
                  id="startingPointFemale"
                  name="startingPointFemale"
                  type="number"
                  placeholder="Starting Point"
                  className={
                    "form-control py-2"

                  }
                  
                />
              </div>

            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="endingPointMale">
                Ending Point
              </label>
              <div className="input-group">
                <input
                  value={endingPointMale ?? ""}
                  onChange={this.handleChange}
                  id="endingPointMale"
                  name="endingPointMale"
                  type="number"
                  placeholder="Ending Point"
                  className={
                    "form-control py-2"
                  }
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="endingPointFemale"> Ending Point </label>
              <div className="input-group">
                <input
                  value={endingPointFemale ?? ""}
                  onChange={this.handleChange}
                  id="endingPointFemale"
                  name="endingPointFemale"
                  type="number"
                  placeholder="Ending Point"
                  className={
                    "form-control py-2"

                  }
            
                />
              </div>

            </div>
            
          </div>

          <div className="row">
          <div className="form-group col">
              <div className="form-check custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={is_male_default_suggested_supplement_required ?? false}
                  onChange={(event, value) => {
                    this.setState({
                      is_male_default_suggested_supplement_required: event.target.checked,
                    });
                  }}
                  id="is_male_default_suggested_supplement_required"
                  name="is_male_default_suggested_supplement_required"
                  type="checkbox"
                  className="custom-control-input"
                // disabled={!isEditable}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_male_default_suggested_supplement_required"
                >
                   is Default Male Suggested Supplements ?
                </label>
              </div>
            </div>
            <div className="form-group col">
              <div className="form-check custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={is_female_default_suggested_supplement_required ?? false}
                  onChange={(event, value) => {
                    this.setState({
                      is_female_default_suggested_supplement_required: event.target.checked,
                    });
                  }}
                  id="is_female_default_suggested_supplement_required"
                  name="is_female_default_suggested_supplement_required"
                  type="checkbox"
                  className="custom-control-input"
                // disabled={!isEditable}
                />
                <label
                  className="custom-control-label"
                  htmlFor="is_female_default_suggested_supplement_required"
                >
                   is Default Female Suggested Supplements ?
                </label>
              </div>
            </div>
          </div>
          <div className="row" style={{padding:"10px"}}>
            <div className="form-group col px-4">
            <BiomarkerMaleSupplementSuggestion
            suggestions={this.state.maleSuggestedSupplements??[]}
            countryId={selectedCountry?.id ?? null}
            suggestedSupplements={supplements??[]}
            suggestionsUpdate={this.handleMaleSupplementSuggestions}
            isEditable={true}
            
            />
            </div>
             <div className="form-group col">
             <BiomarkerFemaleSupplementSuggestion
            suggestions={this.state.femaleSuggestedSupplements??[]}
            countryId={selectedCountry?.id ?? null}
            suggestedSupplements={supplements??[]}
            suggestionsUpdate={this.handleFemaleSupplementSuggestions}
            isEditable={true}
            /> 
            </div>
          </div>


        </>
       
       }
       
      { this.renderUnitConversion()}
      </div>
    );

    return (
      <div className="manage-bio-markers">
        <div className="page-header">
          <h5>{this.state.testName}</h5>
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}

            <div className="action-item">
              <button
                onClick={this.showModal}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                disabled={this.state.editAllowed ? false : true}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
              {/* <button
                disabled={this.state.editAllowed ? false : true}
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button> */}
            </div>
          </div>
        </div>
        <Card>
          {/* {showAlert ? (
            <Alert
              variant="warning"
              onClose={() => {
                this.setState({ showAlert: false });
              }}
              dismissible
              className="mt-3 mb-3 ml-3"
            >
              You can't add/edit biomarkers while this test is being used by an
              active order.
            </Alert>
          ) : null} */}
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="name"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results in '" + showItems + "'"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal} className="user-data-modal">
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
            {!viewMode ? (
              <div>
                <button
                  onClick={
                    ()=>this.handleSubmit()
                  }
                  className="btn btn-secondary button"
                  style={{ background: "#cee741", color: "black", width: "100px", border: "none", fontWeight: "700" }}

                >
                  Save 
                </button>

                {/* <button
                className="btn btn-secondary button"
                onClick={this.hideModal}
              >
                Cancel
              </button> */}
              </div>
            ) : (
              ""
            )}

          </Modal.Header>
          <Modal.Body>
            <Tabs id="manage_biomarkers" fill justify>
            <Tab eventKey="key_form_biomarker_details" title="Details" style={{paddingTop:"20px"}}>
                {detailsForm}
              </Tab>

              <Tab eventKey="key_form_biomarker_ranges" title="Ranges" style={{paddingTop:"20px"}}>
                {rangesForm}
              </Tab>
            </Tabs>
            
          
          </Modal.Body>
          {/* {!viewMode ? (
            <Modal.Footer>
              <button
                onClick={
                  this.state.editId ? this.updateBiomarker : this.saveBiomarker
                }
                className="btn button button-green"
              >
                Submit
              </button>

              <button
                className="btn btn-secondary button"
                onClick={this.hideModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          ) : (
            ""
          )} */}
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    testId: state.testId,
    editAllowed: state.editAllowed,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageBioMarkers);
