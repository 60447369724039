import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedArticleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onArticleSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectArticles.defaultProps = {
  articles: [],
  selectedArticleIds: [],
  onArticleSelect: (f) => f,
  menuPlacement: "top",
  isDisabled: false,
};

function SelectArticles(props) {
  const {
    articles,
    selectedArticleIds,
    onArticleSelect,
    menuPlacement,
    isDisabled,
  } = props;

  const findArticle = (id) => articles.find((it) => it.id === id);

  const [activeArticles, inactiveArticles] = _.partition(
    articles,
    (it) => it.status === "Active"
  );
  activeArticles.sort((first, second) =>
    compareStrings(first.articleNameEnglish, second.articleNameEnglish, true)
  );
  inactiveArticles.sort((first, second) =>
    compareStrings(first.articleNameEnglish, second.articleNameEnglish, true)
  );
  const options = [
    {
      label: "Active",
      options: activeArticles.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveArticles.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  const selectedSupplements = selectedArticleIds
    .map(findArticle)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedArticleIds && selectedArticleIds.join(",")
      }`}
      value={selectedSupplements}
      onChange={onArticleSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (article) => {
  if (!article) return;
  return {
    key: article.id,
    value: article.id,
    label: article.articleNameEnglish,
  };
};

export default SelectArticles;
