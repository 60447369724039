import React, { Component } from "react";
import routes from "routes";
import { Switch, Route, Redirect } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Header/Header";
import Notification from "../components/Notification/Notification";
import { getUserType } from "../services/UserService";
import Loader from "../components/Loader";
import history from "../utils/history";
import API from "utils/API";
import { messaging, onMessageListener } from "../config/firebase";

import {
  loadClients,
  sendMessage,
  addMessage,
  sendResponse,
  addResponse,
  markAsDelivered,
  markAsFailed,
  sendChatNotification,
  disableChat
} from "services/MessagingService";

class ValeoEnglish extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuExpanded: true,
      clients: [],
      windowScreen:window.innerWidth,
    };
    this.routerName = '';
    this.expandMenu = this.expandMenu.bind(this);
    this.setRouterName = this.setRouterName.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.collapseMenu = this.collapseMenu.bind(this);
  }

  componentDidMount() {  
// calling this method everytime when page is loading this method is shifted to Login page
    // if (messaging != null) {  
    //   messaging.requestPermission()
    //     .then(async function () {
    //       const token = await messaging.getToken();
    //       //register device
    //       API.post("devices/", {
    //         registration_id: token,
    //         type: 'web'
    //       }).then(() => {
    //         console.log('Notifications are live')
    //         onMessageListener()
    //           .then((payload) => {
    //             const { title, body } = payload.data;
    //             console.log(`${title}; ${body}`);
    //           })
    //           .catch((err) => {
    //             console.log(JSON.stringify(err));
    //           });

    //       }).catch((err) => {
    //         console.log('register device failure', err);
    //       })
    //     })
    //     .catch(function (err) {
    //       console.log("Unable to get permission to notify.", err);
    //     });
    // }
    this.getRouterName(routes);



    // navigator.serviceWorker.addEventListener("message", (message) => console.log(message));

    // this.fetchClients();
    // const { clients } = this.state;
    //load clients to redux store with/without messages
    // loadClients(clients);

    //add response to the chat from history
    //will not send notification
    // addResponse(1, {
    //   text: "Hey there",
    //   timestamp: 1466769937914
    // });

    // //send notification to the chat if needed
    // sendChatNotification(1);

    // //add reply to the chat from history
    // addMessage(2, {
    //   text: "Hey there how are you",
    //   timestamp: 1466769937914
    // });

    // //send realtime reply
    // //the message will be in pending state
    // let index = sendMessage(2, "hey");

    // //mark sent message as delivered on successful delivery
    // markAsDelivered(2, index);

    // //mark sent message as failed
    // markAsFailed(2, index);

    // //send realtime response
    // //will show notification on chat and the sidebar
    // sendResponse(2, {
    //   text: "Hey there",
    //   timestamp: 1466769937914
    // });

    //disable chat with a client
    disableChat(1);

    this.forceUpdate();
  }

  getRouterName(routeList) {
    const role = getUserType();
    const found = routeList.some(item => item.name === "HomeCare Mobile Portal");
   
    
    for (let route of routeList) {
      if (!route.hasSubroute) {
        if (route.role && route.role.includes(role)) {
          if (history.location.pathname.indexOf(route.path) > -1) {
            this.setRouterName(route.name);
            // console.log("Router Name",route.name)
          }
        }
      } else {
        for (let subroute of route.subroutes) {
          if (subroute.role && subroute.role.includes(role)) {
            if (history.location.pathname.indexOf(subroute.path) > -1) {
              this.setRouterName(subroute.name);
            }
          }
        }
      }
    }
  }
  // fetchClients() {
  //   API.get("nutritionist-clients/")
  //     .then(response => {
  //       this.setState({
  //         clients: response.data
  //       })
  //       loadClients(response.data)
  //     })
  //     .catch(error => {

  //     });
  // }

  toggleMenu() {
    const { menuExpanded } = this.state;
    this.setState({ menuExpanded: !menuExpanded });
  }

  expandMenu() {
    this.setState({ menuExpanded: true });
  }

  setRouterName(route) {
    this.setState({ routerName: route })
  }
  collapseMenu() {
    this.setState({ menuExpanded: false });
  }

  getRoutes(routeList) {
    const role = getUserType();
    const allowedRoutes = [];

    for (let route of routeList) {
      if (!route.hasSubroute) {
        if (route.role && route.role.includes(role)) {
          allowedRoutes.push(
            <Route
              exact
              path={route.path}
              render={props => <route.component {...props} click={this.getRoutesName} />}
              key={route.path}
            ></Route>
          );
        }
      } else {
        for (let subroute of route.subroutes) {
          if (subroute.role && subroute.role.includes(role)) {
            allowedRoutes.push(
              <Route
                exact
                path={subroute.path}
                render={props => <subroute.component {...props} click={this.getRoutesName} />}
                key={subroute.path}
              ></Route>
            );
          }
        }
      }
    }
    return allowedRoutes;
  }
 


  render() {
    const { menuExpanded,windowScreen } = this.state;
    // console.log("width",window.innerWidth);
    if(getUserType() =="homecare" && window.innerWidth<768){
      // console.log("redirect Link");
    
      return ( 
        <div className="main-container">
               <Switch history={history}>
               {this.getRoutes(routes)}
               <Redirect from="*" to="/dashboard/homecare-mobile-portal" />
               </Switch>

          </div>
          )
     }
     else if(getUserType() =="homeCareProfessional"){
      return ( 
        <div className="main-container">
               <Switch history={history}>
               {this.getRoutes(routes)}
               <Redirect from="*" to="/dashboard/home" />
               </Switch>

          </div>
          )
     }

    else{
    return ( 
      <div className="main-container">
         <Sidebar
          expandMenu={this.expandMenu}
          toggleMenu={this.toggleMenu}
          setRouterName={this.setRouterName}
          menuExpanded={menuExpanded}
          routerName={this.state.routerName}
        ></Sidebar> 
        <Header menuExpanded={menuExpanded} routerName={this.state.routerName}></Header>
        <Notification menuExpanded={menuExpanded}></Notification>
        <div className={"page-view " + (menuExpanded ? "" : "wide")}>
          <Loader menuExpanded={menuExpanded}></Loader>
          <Switch history={history}>
            {this.getRoutes(routes)}
            <Redirect from="/dashboard" to="/dashboard/home" />
            <Redirect from="*" to="/dashboard/home" />
          </Switch>
        </div>
      </div>);}
      
         
       
      
      
  }
}

export default ValeoEnglish;
