import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { Dropdown } from "semantic-ui-react";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import API from "utils/API";
import FormValidator from "utils/FormValidator";
import FormData from "form-data";
import { filterList } from "utils/List";
import ActionBar from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import AlertBox from "components/AlertBox/AlertBox";
import AddonForm from "./AddonForm";

class List extends Component {
  render() {
    const list = this.props.packages;

    return (
      <div>
        {list.length ? (
          <div>
            {this.props.packages.map((value, index) => {
              return (
                <div key={index} className="package-col">
                  <div className="package-model">
                    <div className="image-container">
                      <img
                        src={value.image}
                        alt="Package"
                        className="package-image"
                      ></img>
                      {!value.is_active ? (
                        <span className="package-status">Archived</span>
                      ) : value.is_custom ? (
                        <span className="package-status active">Custom</span>
                      ) : value.is_wellbeing ? (
                        <span className="package-status active">Wellbeing</span>
                      ) : (
                        <span className="package-status active">Normal</span>
                      )}
                    </div>
                    <div className="package-details">
                      <h5 className="package-name">
                        {value.subscription_name}
                      </h5>
                      <p className="description">
                        {value.subscription_description}
                      </p>
                      <div className="action-row">
                        <div className="price-col">
                          {!value.is_custom && (
                            <p className="price-label">
                              AED
                              <span className="price-value">{value.price}</span>
                              {value.is_wellbeing && " onwards"}
                            </p>
                          )}
                        </div>
                        <div className="button-col" role="group">
                          <ActionBar
                            alignRight
                            drop="up"
                            className="action-menu valeo-dropdown dropdown-bordered"
                          >
                            <ActionBar.Toggle variant="">
                              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                            </ActionBar.Toggle>
                            <ActionBar.Menu>
                              <ActionBar.Item
                                onClick={() =>
                                  this.props.editPackage(value.index)
                                }
                              >
                                <EditIcon className="icon-small"></EditIcon>Edit
                              </ActionBar.Item>
                              {value.is_active ? (
                                <ActionBar.Item
                                  className="danger"
                                  onClick={() => this.props.showConfirm(value)}
                                >
                                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                                  Archive
                                </ActionBar.Item>
                              ) : (
                                <ActionBar.Item
                                  className="success"
                                  onClick={() => this.props.showConfirm(value)}
                                >
                                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                                  Restore
                                </ActionBar.Item>
                              )}
                            </ActionBar.Menu>
                          </ActionBar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : this.props.loadError ? (
          <AlertBox message={this.props.loadError} error={true}></AlertBox>
        ) : (
          <AlertBox message="There's nothing here."></AlertBox>
        )}
      </div>
    );
  }
}

class ManagePackages extends Component {
  constructor(props) {
    super(props);
    this.formFields = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "price",
        method: "isEmpty",
        validWhen: false,
        message: "Price is required.",
      },
      {
        field: "price",
        method: "isFloat",
        validWhen: true,
        isRtl: false,
        min: 0,
        message: "Invalid price.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
      {
        field: "tests",
        type: "array",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Tests are required.",
      },
    ];

    this.formFieldsCustom = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
    ];

    this.formFieldsWellbeing = [
      {
        field: "subscription_name",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Name is required.",
      },
      {
        field: "subscription_name_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Name is required.",
      },
      {
        field: "subscription_description",
        method: "isEmpty",
        validWhen: false,
        isRtl: false,
        message: "Description is required.",
      },
      {
        field: "subscription_description_ar",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Description is required.",
      },
      {
        field: "gender",
        method: "isEmpty",
        validWhen: false,
        isRtl: true,
        message: "Gender is required.",
      },
      {
        field: "image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Image is required.",
      },
      // {
      //   field: "addonPlans",
      //   type: "array",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Atleast one plan is required."
      // }
    ];

    this.validator = new FormValidator(this.formFields);
    this.validatorCustom = new FormValidator(this.formFieldsCustom);
    this.validatorwellbeing = new FormValidator(this.formFieldsWellbeing);
    this.state = {
      subscription_name: "",
      subscription_name_ar: "",
      subscription_description_ar: "",
      subscription_description: "",
      price: "",
      tests: [],
      allTests: [],
      rankObject: {},
      rankSortable: {},
      image: "",
      imgSrc: "",
      gender: "",
      isWellbeing: false,
      addonPlans: [],
      validation: this.validator.valid(),
      validationCustom: this.validatorCustom.valid(),
      validationWellbeing: this.validatorwellbeing.valid(),
      testList: [],
      edit_id: null,
      arrGenderList: [
        {
          text: "Both",
          value: "B",
          key: "B",
        },
        {
          text: "Male",
          value: "M",
          key: "M",
        },
        {
          text: "Female",
          value: "F",
          key: "F",
        },
      ],
      packages: [],
      arrEditPackage: {},

      formHeading: "Create Package",
      showModal: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      isLoaded: false,
      loadError: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isCustom: false,
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.selecteImage = this.selecteImage.bind(this);
    // this.savePackage = this.savePackage.bind(this);
    // this.updatePackage = this.updatePackage.bind(this);
    this.packageOperation = this.packageOperation.bind(this);
    this.formReset = this.formReset.bind(this);
    this.loadTestList = this.loadTestList.bind(this);
    this.getPackageList = this.getPackageList.bind(this);
    this.editPackage = this.editPackage.bind(this);
    this.updateList = this.updateList.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    this.loadTestList();
  }

  loadTestList() {
    API.get("tests/")
      .then((response) => {
        const activeList = filterList(response.data, "active");

        let testList = activeList.map((value) => {
          return {
            text: value.name,
            value: value.id.toString(),
            key: value.id,
          };
        });

        this.setState({ testList: testList, allTests: response.data });
        this.getPackageList();
      })
      .catch((error) => {
        console.log("Error on fetching tests", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  getPackageList() {
    //fetch  normal/custom packages, wellbeing packages
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("subscription-packages/")
      .then((response) => {
        let newList = response.data;
        newList.sort((a, b) => {
          return a.position_value - b.position_value;
        });
        let rankArray = [];
        let rankSotable = [];
        let rank = 1;
        newList.map((item) => {
          if (item.is_active) {
            rankSotable[rank] = item.id;
            rankArray.push({
              text: rank,
              value: rank,
              key: rank,
            });
            rank = rank + 1;
          }
        });
        this.setState({
          rankObject: rankArray,
          rankSortable: rankSotable,
        });
        //append wellbeing coach subscription
        API.get("add-on-packages/")
          .then((response) => {
            //if present push addons to package list
            let result = response.data;
            //the heading values are sepearted from other by checking is_parent == true
            let header = result.filter((item) => item["is_parent"]);
            this.setState({
              wellbeingParent_id: header[0].id,
            });
            let addonPlans = result.filter((item) => !item["is_parent"]);
            if (addonPlans.length) {
              addonPlans.sort((a, b) => a.addon_price - b.addon_price);
            }
            if (header.length) {
              newList.push({
                gender: "B",
                id: header[0].id,
                image: header[0].addon_image,
                is_active: header[0].is_active,
                is_wellbeing: true,
                price: addonPlans.length ? addonPlans[0].addon_price : null,
                position_value: null,
                tests: [],
                subscription_description: header[0].addon_description,
                subscription_description_ar: header[0].addon_description_ar,
                subscription_name: header[0].addon_name,
                subscription_name_ar: header[0].addon_name,
                addon_plans: addonPlans,
              });
              const activeList = filterList(newList, "active");
              const archivedList = filterList(newList, "archived");
              this.setState({
                packages: newList,
                activeList,
                archivedList,
                isLoaded: true,
              });
              if (searchApplied) this.handleSearch(searchTerm);

              this.props.toggleLoading({
                isLoading: false,
              });
            }
          })
          .catch((err) => {
            console.log("Error on fetching packages", err);
            const activeList = filterList(newList, "active");
            const archivedList = filterList(newList, "archived");
            this.setState({
              packages: newList,
              activeList,
              archivedList,
              isLoaded: true,
            });
            if (searchApplied) this.handleSearch(searchTerm);

            this.props.toggleLoading({
              isLoading: false,
            });
          });
        const activeList = filterList(newList, "active");
        const archivedList = filterList(newList, "archived");
        this.setState({
          packages: newList,
          activeList,
          archivedList,
          isLoaded: true,
        });
        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching packages", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  getTestName(id) {
    const allTests = this.state.allTests;

    if (allTests.length > 0) {
      let test = allTests.find((item) => {
        return item.id.toString() === id;
      });

      if (test) {
        if (test.is_active) return test.name;
        else return test.name + " (Archived)";
      }
    }
    return null;
  }

  selecteImage(e) {
    let image = e.target.files[0];
    this.setState({ image });
    let reader = new FileReader();
    // var url = reader.readAsDataURL(image);

    reader.onloadend = function () {
      this.setState({
        imgSrc: [reader.result],
      });
    }.bind(this);
  }

  packageOperation(operation) {
    //three types of updates
    //1. updating normal package
    //2.updating custom package
    //3.updating wellbeing package
    if (this.state.isCustom) {
      //custom package updation
      const validationCustom = this.validatorCustom.validate(this.state);
      this.submitted = true;
      this.setState({ validationCustom });
      if (validationCustom.isValid) {
        this.callSubscriptionPackageAPI("custom", operation);
      }
    } else if (this.state.isWellbeing) {
      const validationWellbeing = this.validatorwellbeing.validate(this.state);
      this.submitted = true;
      this.setState({ validationWellbeing });
      if (validationWellbeing.isValid) {
        this.callSubscriptionPackageAPI("wellbeing", operation);
      }
    } else if (!this.state.isCustom && !this.state.isWellbeing) {
      const validation = this.validator.validate(this.state);
      this.submitted = true;
      this.setState({ validation });
      if (validation.isValid) {
        this.callSubscriptionPackageAPI("normal", operation);
      }
    }
  }

  callSubscriptionPackageAPI(packageType, operation) {
    //operations can be add or update
    //packageTypes can be custom/normal
    let apiOptions = {};
    this.hideModal();
    let formdata = new FormData();
    let {
      image,
      subscription_rank,
      subscription_name,
      subscription_name_ar,
      price,
      subscription_description,
      subscription_description_ar,
      tests,
      edit_id,
      wellbeingParent_id,
      gender,
    } = this.state;
    if (operation == "add") {
      formdata.append("is_active", true);
      if (packageType == "custom") {
        formdata.append("is_custom", true);
        // use service cost in field on price
        //service cost fetched and stored in price
        formdata.append("price", price);
      } else if (packageType == "wellbeing") {
        // formdata.append("is_wellbeing", true);
        formdata.append("is_parent", true); // parent data
        formdata.append("addon_price", 999); // parent data (hardcoded) (not shown anywhere)
        formdata.append("addon_validity", 99); // parent data (hardcoded) (not shown anywhere)
      } else {
        formdata.append("is_custom", false);
        formdata.append("is_wellbeing", false);
        formdata.append("position_value", subscription_rank);
      }
    }
    if (image.name) {
      formdata.append("image", image, image.name);
      formdata.append("image_s3", image, image.name);
    }
    if (packageType == "wellbeing") {
      formdata.append("addon_name", subscription_name);
      formdata.append("addon_name_ar", subscription_name_ar);
      formdata.append("addon_description", subscription_description);
      formdata.append("addon_description_ar", subscription_description_ar);
      if (image.name) formdata.append("addon_image", image, image.name);
    } else {
      formdata.append("subscription_name", subscription_name);
      formdata.append("subscription_name_ar", subscription_name_ar);
      formdata.append("subscription_description", subscription_description);
      formdata.append(
        "subscription_description_ar",
        subscription_description_ar
      );
    }
    formdata.append("gender", gender);
    if (packageType == "normal") {
      formdata.append("price", price);
      tests.map((value) => {
        formdata.append("tests", value);
      });
    }
    this.props.toggleLoading({
      isLoading: true,
    });

    if (operation == "update") {
      //updating a wellbeing coach(parent title, description, image) - plans not included
      if (packageType == "wellbeing") {
        apiOptions = {
          method: "patch",
          url: "add-on-packages/" + wellbeingParent_id + "/",
          data: formdata,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
          },
        };
      } else {
        apiOptions = {
          method: "patch",
          url: "subscription-packages/" + edit_id + "/",
          data: formdata,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
          },
        };
      }
    } else if (operation == "add") {
      //create parent details first get its id, and attach each addons
      if (packageType == "wellbeing") {
        apiOptions = {
          method: "post",
          url: "add-on-packages/",
          data: formdata,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
          },
        };
      } else {
        apiOptions = {
          method: "post",
          url: "subscription-packages/",
          data: formdata,
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
            "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
          },
        };
      }
    }
    API.request(apiOptions)
      .then((response) => {
        if (operation == "add") {
          //at this point parent data is created
          //for each packages
          this.props.showNotificationMessage({
            notificationMessage: "Package successfully created.",
            successMessage: true,
            showNotification: true,
          });
          this.loadData();
          this.props.toggleLoading({
            isLoading: false,
          });
        } else if (packageType == "wellbeing") {
          this.props.toggleLoading({
            isLoading: false,
          });
        } else {
          let rankSortable = this.state.rankSortable;
          let from = rankSortable.indexOf(response.data.id);
          let to = this.state.subscription_rank;
          rankSortable.splice(to, 0, rankSortable.splice(from, 1)[0]);
          let rankObject = {};
          rankSortable.map((item, index) => {
            rankObject[item] = index;
          });
          API.post("set-package-rank/", rankObject)
            .then((response) => {
              this.props.showNotificationMessage({
                notificationMessage: "Package successfully updated.",
                successMessage: true,
                showNotification: true,
              });
              this.loadData();
              this.props.toggleLoading({
                isLoading: false,
              });
            })
            .catch((error) => {
              console.log("Error on setting package rank", error);
            });
        }
      })
      .catch((error) => {
        console.log("Error on saving package", error);
        this.props.showNotificationMessage({
          notificationMessage: "We have an ongoing order with this package.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }

  formReset() {
    this.submitted = false;
    this.setState({
      subscription_name: "",
      subscription_name_ar: "",
      subscription_description: "",
      subscription_description_ar: "",
      price: "",
      tests: [],
      image: "",
      imgSrc: "",
      gender: "",
      validation: this.validator.valid(),
      edit_id: null,
    });
    document.getElementById("imageInput").value = "";
  }

  toggleActiveStatus(item) {
    //toggle active status of wellbeing packages
    //toggle active status of normal packages
    const { successMessage } = this.state;
    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });
    var formData = new FormData();
    formData.append("is_active", !item.is_active);
    if (item.is_wellbeing) {
      API.patch("add-on-packages/" + item.id + "/", formData)
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: successMessage,
            successMessage: true,
            showNotification: true,
          });
          this.getPackageList();
        })
        .catch((error) => {
          console.log("Error on saving package", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "We have an ongoing order with this package.",
            successMessage: false,
            showNotification: true,
          });
        });
    } else {
      var formData = new FormData();
      formData.append("is_active", !item.is_active);
      API.patch("subscription-packages/" + item.id + "/", formData)
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: successMessage,
            successMessage: true,
            showNotification: true,
          });
          this.getPackageList();
        })
        .catch((error) => {
          console.log("Error on saving package", error);
          this.props.showNotificationMessage({
            notificationMessage: "We have an ongoing order with this package.",
            successMessage: false,
            showNotification: true,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        });
    }
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;
    if (confirmItem.is_active) {
      confirmTitle = "Archive Package";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Package successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Package";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Package successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  newFormattedList(list, tests) {
    let item;
    const newList = [...list];

    for (let test of tests) {
      item = {
        text: this.getTestName(test),
        value: test,
        key: test,
      };

      if (newList.find((element) => element.key === test)) continue;

      newList.push(item);
    }

    return newList;
  }

  updateList() {
    this.getPackageList();
  }
  editPackage(index) {
    const arrEditPackage = this.state.packages[index];

    //three types of editing options are available
    //1. Normal package edit
    //2. Addon package edit(Wellbeing Coach)
    //3. Custom package edit
    if (arrEditPackage.id) {
      this.setState(
        {
          subscription_rank: arrEditPackage.position_value,
          subscription_name: arrEditPackage.subscription_name,
          subscription_name_ar: arrEditPackage.subscription_name_ar,
          price: arrEditPackage.price,
          subscription_description: arrEditPackage.subscription_description,
          subscription_description_ar:
            arrEditPackage.subscription_description_ar,
          tests: arrEditPackage.tests.map((value) => {
            return value.id.toString();
          }),
          edit_id: arrEditPackage.id,
          imgSrc: arrEditPackage.image,
          image: arrEditPackage.image,
          formHeading: "Edit Package",
          gender: arrEditPackage.gender,
          isWellbeing: arrEditPackage["is_wellbeing"]
            ? arrEditPackage["is_wellbeing"]
            : false,
          addonPlans: arrEditPackage["addon_plans"],
          isCustom: arrEditPackage["is_custom"],
        },
        () => {
          this.setState({
            showModal: true,
          });
        }
      );
    }
  }

  hideModal() {
    this.formReset();
    this.setState({ showModal: false });
  }

  showModal(type) {
    if (type == "normal") {
      this.setState({
        showModal: true,
        isCustom: false,
        isWellbeing: false,
        edit_id: null,
      });
    } else if (type == "custom") {
      //check if there is already an active custom package, if its there show a prompt otherwise create
      if (
        this.state.activeList.filter((item) => item["is_custom"] == true).length
      ) {
        this.props.showNotificationMessage({
          notificationMessage: "There is already a Custom package available.",
          successMessage: false,
          showNotification: true,
        });
      } else {
        // fetch service cost in case of custom
        let serviceCost = 0;
        API.get("service-cost/")
          .then((response) => {
            serviceCost = response.data[0]["price"];
            this.setState({
              price: serviceCost,
            });
          })
          .catch((error) => {
            console.log("Error on fetching service cost", error);
          });

        this.setState({
          showModal: true,
          isCustom: true,
          isWellbeing: false,
        });
      }
    } else if (type == "wellbeing") {
      //check if there is already an active wellbeing package, if its there show a prompt otherwise create
      if (
        this.state.packages.filter((item) => item["is_wellbeing"] == true)
          .length
      ) {
        //user cant create a new package after user archived it, user will be shown a message they can restore it from archived
        if (
          this.state.activeList.filter((item) => item["is_wellbeing"] == true)
            .length
        ) {
          this.props.showNotificationMessage({
            notificationMessage:
              "There is already an active Wellbeing package available.",
            successMessage: false,
            showNotification: true,
          });
        } else {
          this.props.showNotificationMessage({
            notificationMessage:
              "Please restore Wellbeing package in Archive section",
            successMessage: false,
            showNotification: true,
          });
        }
      } else {
        this.setState({
          showModal: true,
          isCustom: false,
          isWellbeing: true,
        });
      }
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.packages;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.subscription_name.toLowerCase().indexOf(newValue) !== -1 ||
        item.subscription_name_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  render() {
    let finalList = [];
    const {
      packages,
      isLoaded,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      loadError,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        if (showItems === "Active") finalList = activeList;
        else if (showItems === "Archived") finalList = archivedList;
        else finalList = packages;
      }
    }

    const form = this.state.isWellbeing ? (
      <>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Name (English) *</label>

            <input
              type="text"
              name="subscription_name"
              className={
                "form-control " +
                (validation.subscription_name.isInvalid && "has-error")
              }
              placeholder="Name in English"
              onChange={this.handleChange}
              value={this.state.subscription_name}
            ></input>
            <span className="help-block">
              {validation.subscription_name.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Name (Arabic) *</label>
            <input
              type="text"
              name="subscription_name_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_name_ar.isInvalid && "has-error")
              }
              placeholder="Name in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_name_ar}
            ></input>
            <span className="help-block">
              {validation.subscription_name_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Description (English) *</label>
            <textarea
              type="text"
              name="subscription_description"
              className={
                "form-control " +
                (validation.subscription_description.isInvalid && "has-error")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.subscription_description}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Description (Arabic) *</label>
            <textarea
              type="text"
              name="subscription_description_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_description_ar.isInvalid &&
                  "has-error")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_description_ar}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col-2">
            <label>Gender *</label>
            <Dropdown
              value={this.state.gender}
              className={
                "form-control " + (validation.gender.isInvalid && "has-error")
              }
              placeholder="Gender"
              onChange={(event, value) => {
                this.setState({ gender: value.value });
              }}
              search
              selection
              options={this.state.arrGenderList}
            />
            <span className="help-block">{validation.gender.message}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col ">
            <label>Image *</label>
            <input
              type="file"
              id="imageInput"
              name="image"
              accept="image/*"
              className={
                "form-control " + (validation.image.isInvalid && "has-error")
              }
              onChange={this.selecteImage}
            ></input>
            <span className="help-block">{validation.image.message}</span>
          </div>
          <div className="form-group col ">
            {this.state.imgSrc && (
              <>
                <label>Selected Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="col-lg-12 col-md-12">
            <div className={" addon-plans-box "}>
              <AddonForm
                editId={this.state.edit_id}
                viewMode={false}
              ></AddonForm>
            </div>
            <span className="help-block">{this.markerMessage}</span>
          </div>
        </div>
      </>
    ) : this.state.isCustom ? (
      <>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Name (English) *</label>

            <input
              type="text"
              name="subscription_name"
              className={
                "form-control " +
                (validation.subscription_name.isInvalid && "has-error")
              }
              placeholder="Name in English"
              onChange={this.handleChange}
              value={this.state.subscription_name}
            ></input>
            <span className="help-block">
              {validation.subscription_name.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Name (Arabic) *</label>
            <input
              type="text"
              name="subscription_name_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_name_ar.isInvalid && "has-error")
              }
              placeholder="Name in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_name_ar}
            ></input>
            <span className="help-block">
              {validation.subscription_name_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Description (English) *</label>
            <textarea
              type="text"
              name="subscription_description"
              className={
                "form-control " +
                (validation.subscription_description.isInvalid && "has-error")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.subscription_description}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Description (Arabic) *</label>
            <textarea
              type="text"
              name="subscription_description_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_description_ar.isInvalid &&
                  "has-error")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_description_ar}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col-2">
            <label>Gender *</label>
            <Dropdown
              value={this.state.gender}
              className={
                "form-control " + (validation.gender.isInvalid && "has-error")
              }
              placeholder="Gender"
              onChange={(event, value) => {
                this.setState({ gender: value.value });
              }}
              search
              selection
              options={this.state.arrGenderList}
            />
            <span className="help-block">{validation.gender.message}</span>
          </div>
          <div className="form-group col-2">
            <label>Service Charge (AED) *</label>
            <p>{this.state.price}</p>
            <span className="help-block">{validation.price.message}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col ">
            <label>Image *</label>
            <input
              type="file"
              id="imageInput"
              name="image"
              accept="image/*"
              className={
                "form-control " + (validation.image.isInvalid && "has-error")
              }
              onChange={this.selecteImage}
            ></input>
            <span className="help-block">{validation.image.message}</span>
          </div>
          <div className="form-group col ">
            {this.state.imgSrc && (
              <>
                <label>Selected Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
          {this.state.edit_id && (
            <div className="form-group col ">
              <label>Rank*</label>
              <Dropdown
                value={this.state.subscription_rank}
                className={
                  "form-control " + (validation.gender.isInvalid && "has-error")
                }
                placeholder="rank"
                onChange={(event, value) => {
                  this.setState({ subscription_rank: value.value });
                }}
                search
                selection
                options={this.state.rankObject}
              />
              {/* <span className="help-block">
                  {validation.subscription_rank.message}
                </span> */}
            </div>
          )}
        </div>
      </>
    ) : (
      <>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Name (English) *</label>

            <input
              type="text"
              name="subscription_name"
              className={
                "form-control " +
                (validation.subscription_name.isInvalid && "has-error")
              }
              placeholder="Name in English"
              onChange={this.handleChange}
              value={this.state.subscription_name}
            ></input>
            <span className="help-block">
              {validation.subscription_name.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Name (Arabic) *</label>
            <input
              type="text"
              name="subscription_name_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_name_ar.isInvalid && "has-error")
              }
              placeholder="Name in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_name_ar}
            ></input>
            <span className="help-block">
              {validation.subscription_name_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Description (English) *</label>
            <textarea
              type="text"
              name="subscription_description"
              className={
                "form-control " +
                (validation.subscription_description.isInvalid && "has-error")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.subscription_description}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description.message}
            </span>
          </div>
          <div className="form-group col">
            <label>Description (Arabic) *</label>
            <textarea
              type="text"
              name="subscription_description_ar"
              className={
                "form-control input-arabic " +
                (validation.subscription_description_ar.isInvalid &&
                  "has-error")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.subscription_description_ar}
            ></textarea>
            <span className="help-block">
              {validation.subscription_description_ar.message}
            </span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col ">
            <label>Tests *</label>
            <Dropdown
              className={
                "form-control " + (validation.tests.isInvalid && "has-error")
              }
              placeholder="Select tests"
              search
              fluid
              multiple
              selection
              options={
                this.state.edit_id
                  ? this.newFormattedList(this.state.testList, this.state.tests)
                  : this.state.testList
              }
              onChange={(event, value) => {
                this.setState({ tests: value.value });
              }}
              value={this.state.tests}
            />
            <span className="help-block">{validation.tests.message}</span>
          </div>
          <div className="form-group col-2">
            <label>Gender *</label>
            <Dropdown
              value={this.state.gender}
              className={
                "form-control " + (validation.gender.isInvalid && "has-error")
              }
              placeholder="Gender"
              onChange={(event, value) => {
                this.setState({ gender: value.value });
              }}
              search
              selection
              options={this.state.arrGenderList}
            />
            <span className="help-block">{validation.gender.message}</span>
          </div>
          <div className="form-group col-2">
            <label>Price (AED) *</label>
            <input
              type="number"
              name="price"
              className={
                "form-control " + (validation.price.isInvalid && "has-error")
              }
              placeholder="Price"
              onChange={this.handleChange}
              value={this.state.price}
            ></input>
            <span className="help-block">{validation.price.message}</span>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col ">
            <label>Image *</label>
            <input
              type="file"
              id="imageInput"
              name="image"
              accept="image/*"
              className={
                "form-control " + (validation.image.isInvalid && "has-error")
              }
              onChange={this.selecteImage}
            ></input>
            <span className="help-block">{validation.image.message}</span>
          </div>
          <div className="form-group col ">
            {this.state.imgSrc && (
              <>
                <label>Selected Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-group col ">
            <label>Rank*</label>
            <Dropdown
              value={this.state.subscription_rank}
              className={
                "form-control " + (validation.gender.isInvalid && "has-error")
              }
              placeholder="rank"
              onChange={(event, value) => {
                this.setState({ subscription_rank: value.value });
              }}
              search
              selection
              options={this.state.rankObject}
            />
            {/* <span className="help-block">
              {validation.subscription_rank.message}
            </span> */}
          </div>
        </div>
      </>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          {/* <h5 className="mb-4">Manage Packages</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>

            <div className="action-item select-menu">
              <ActionBar
                alignRight
                className="valeo-dropdown add-new-package-btn"
              >
                <ActionBar.Toggle variant="">
                  <button className="btn btn-sm button text-button">
                    <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                    add new
                  </button>
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Choose a package type</ActionBar.Header>
                  <ActionBar.Item onClick={() => this.showModal("normal")}>
                    Normal package
                  </ActionBar.Item>
                  <ActionBar.Item onClick={() => this.showModal("custom")}>
                    Custom package
                  </ActionBar.Item>
                  <ActionBar.Item onClick={() => this.showModal("wellbeing")}>
                    Wellbeing package
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
              {/* <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button> */}
            </div>
          </div>
        </div>
        <Card body>
          {searchApplied ? (
            <p className="table-header">
              {"Found " + resultCount + " results in '" + showItems + "'"}
            </p>
          ) : (
            ""
          )}
          <List
            packages={finalList}
            editPackage={(index) => this.editPackage(index)}
            showConfirm={this.showConfirm}
            showModal={this.showModal}
            loadError={loadError}
          />
        </Card>
        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={
                this.state.edit_id
                  ? () => this.packageOperation("update")
                  : () => this.packageOperation("add")
              }
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagePackages);
