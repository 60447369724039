import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Dropdown } from "semantic-ui-react";
import { filterList } from "utils/List";
import CheckIcon from "@material-ui/icons/Check";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class RatingsPanel extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "questionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "questionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "questionType",
        method: "isEmpty",
        validWhen: false,
        message: "Type is required.",
      }
    ]);

    this.state = {
      questions: [],
      isLoaded: false,
      loadError: "",
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      questionType: "S",
      editId: null,
      validation: this.validator.valid(),
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      showItems: "Active",
      testRatingsOptions: [],
      wcRatingsOptions: [],
      testRatingsOptionSelected: null,
      wcRatingsOptionSelected: null,
    };

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true,
      },
      {
        name: "Type",
        selector: "question_type",
        sortable: true,
        wrap: true,
        format: (row) => (
          row.question_type == 'S' ? 'Star' : 'Plain text'
        )
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
                <div className="badge badge-warning">
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
                </div>
              )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>

              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                  <ActionBar.Item
                    className="success"
                    onClick={() => this.showConfirm(row)}
                  >
                    <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                  </ActionBar.Item>
                )}
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.testRatingsOptionSelected = null;
    this.wcRatingsOptionSelected = null;

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);

  }

  componentDidMount() {
    this.loadScenarios();
    this.loadQuestions();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  loadQuestions() {
    API.get('order-rating-questions/').then((response) => {
      const questions = response.data || [];
      const activeList = filterList(questions, "active");
      const archivedList = filterList(questions, "archived");
      this.setState({
        questions: questions,
        activeList,
        archivedList,
        isLoaded: true
      })
    }).catch(error => {
      console.log("Error on fetching questions", error);
    })
  }

  saveScenarios() {
    if (this.testRatingsOptionSelected != this.state.testRatingsOptionSelected) {
      this.props.toggleLoading({
        isLoading: true,
      });
      API.patch(`rating-scenarios/${this.state.testRatingsOptionSelected}/`, {
        is_active: true
      })
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false
          });
          this.props.showNotificationMessage({
            notificationMessage: "Configurations are changed successfully",
            successMessage: true,
            showNotification: true,
          });
          this.loadScenarios()

        })

    }


    if (this.wcRatingsOptionSelected != this.state.wcRatingsOptionSelected) {
      this.props.toggleLoading({
        isLoading: true,
      });
      API.patch(`rating-scenarios/${this.state.wcRatingsOptionSelected}/`, {
        is_active: true
      })
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false
          });
          this.props.showNotificationMessage({
            notificationMessage: "Configurations are changed successfully",
            successMessage: true,
            showNotification: true,
          });
          this.loadScenarios()

        })
    }

  }

  loadScenarios() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("rating-scenarios/")
      .then((response) => {
        let testOptions = response.data.filter((item) => item.scenario_type === "TE") || [];
        const testOption = testOptions ? testOptions.find((item) => item.is_active) : null;
        let testOptionSelected = testOption ? testOption.id : null;
        this.testRatingsOptionSelected = testOptionSelected

        let wellbeingOptions = response.data.filter((item) => item.scenario_type === "WC") || [];
        const wellbeingOption = wellbeingOptions ? wellbeingOptions.find((item) => item.is_active) : null;
        let wellbeingOptionSelected = wellbeingOption ? wellbeingOption.id : null;
        this.wcRatingsOptionSelected = wellbeingOptionSelected
        testOptions = testOptions && testOptions.map(value => {
          return {
            text: value.scenario,
            value: value.id.toString(),
            key: value.id
          };
        });

        wellbeingOptions = wellbeingOptions && wellbeingOptions.map(value => {
          return {
            text: value.scenario,
            value: value.id.toString(),
            key: value.id
          };
        });

        this.setState({ testRatingsOptions: testOptions, wcRatingsOptions: wellbeingOptions }, () => {
          this.setState({
            testRatingsOptionSelected: testOptionSelected && testOptionSelected.toString(),
            wcRatingsOptionSelected: wellbeingOptionSelected && wellbeingOptionSelected.toString()
          })
        });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching rating scenarios", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let questionEn = this.state.questionEn;
    let questionAr = this.state.questionAr;
    let questionType = this.state.questionType;
    let editId = this.state.editId;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });

      if (editId !== null) {
        //edit
        API.patch("order-rating-questions/" + editId + "/", {
          question: questionEn,
          question_ar: questionAr,
          question_type: questionType
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully updated.",
              successMessage: true,
              showNotification: true,
            });
            this.loadScenarios();
            this.loadQuestions();
          })
          .catch((error) => {
            console.log("Error on updating question", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      } else {
        //add new
        API.post("order-rating-questions/", {
          question: questionEn,
          question_ar: questionAr,
          question_type: questionType
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully created.",
              successMessage: true,
              showNotification: true,
            });
            this.loadScenarios();
            this.loadQuestions();
          })
          .catch((error) => {
            console.log("Error on creating question", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
    }
  }

  editQuestion(index, id) {
    let questions = this.state.questions;
    this.setState({
      formHeading: "Edit Question",
      questionEn: questions[index]["question"],
      questionAr: questions[index]["question_ar"],
      questionType: questions[index]["question_type"],
      editId: id,
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Question";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Question successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Question";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Question successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    this.hideConfirm();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("order-rating-questions/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.clearForm();
        this.loadScenarios();
        this.loadQuestions();
      })
      .catch((error) => {
        console.log("Error on toggling question status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      answerEn: "",
      answerAr: "",
      editId: null,
      validation: this.validator.valid(),
    });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    list = this.state.questions;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.question.toLowerCase().indexOf(newValue) !== -1 ||
        item.question_ar.toLowerCase().indexOf(newValue) !== -1 ||
        item.answer.toLowerCase().indexOf(newValue) !== -1 ||
        item.answer_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems });
  }

  render() {
    let finalList = [],
      form;
    const {
      questions,
      loadError,
      showItems,
      activeList,
      archivedList,
      isLoaded
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;


    if (isLoaded) {

      if (showItems === "Active") finalList = activeList;
      else if (showItems === "Archived") finalList = archivedList;
      else finalList = questions;
    }

    form = (
      <>
        <div className="form-group col-12">
          <label>
            Question (English)*
        </label>
          <textarea
            className={
              "form-control " +
              (validation.questionEn.isInvalid && "has-error")
            }
            type="text"
            name="questionEn"
            placeholder="Question in English"
            onChange={this.handleChange}
            value={this.state.questionEn}></textarea>
          <span className="help-block">{validation.questionEn.message}</span>
        </div>
        <div className="form-group col-12">
          <label>
            Question (Arabic)*
        </label>
          <textarea
            className={
              "form-control input-arabic " +
              (validation.questionAr.isInvalid && "has-error")
            }
            type="text"
            name="questionAr"
            placeholder="Question in Arabic"
            onChange={this.handleChange}
            value={this.state.questionAr}></textarea>
          <span className="help-block">{validation.questionAr.message}</span>
        </div>
        <div className="form-group col">
          <label>Question type</label>

          <Dropdown
            //   className={
            //     "form-control " + (validation.tests.isInvalid && "has-error")
            //   }
            placeholder="Choose an option"
            fluid
            selection
            disabled={this.state.editId != null}
            className={
              "form-control " +
              (validation.questionType.isInvalid && "has-error")
            }
            options={
              [{ text: 'star', value: 'S', key: 1 },
              { text: 'plain text', value: 'P', key: 2 }]
            }
            onChange={(event, value) => {
              this.setState({ questionType: value.value });
            }}
            value={this.state.questionType == 'S' ? 'S' : 'P'}
          />
          <span className="help-block">{validation.questionType.message}</span>
        </div>
      </>
    )

    return (
      <div className="faq">
        <div className="page-header">
          {/* <h5 style={{ marginBottom: "1rem" }}>Ratings Configuration</h5> */}
          <div className="actions">
            <div className="action-item">
              <button
                className="btn btn-sm button button-green button-disabled"
                onClick={() => this.saveScenarios()}
                disabled={(this.wcRatingsOptionSelected != this.state.wcRatingsOptionSelected) || (this.testRatingsOptionSelected != this.state.testRatingsOptionSelected) ? false : true}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
        <Card>
          <Card.Body>
            <div className="form-row">

              <div className="form-group col-4">
                <label>Choose how ratings will be for test:</label>
                <Dropdown
                  //   className={
                  //     "form-control " + (validation.tests.isInvalid && "has-error")
                  //   }
                  placeholder="Choose an option"
                  fluid
                  selection
                  options={
                    this.state.testRatingsOptions
                  }
                  onChange={(event, value) => {
                    this.setState({ testRatingsOptionSelected: value.value });
                  }}
                  value={this.state.testRatingsOptionSelected}
                />
              </div>
              <div className="form-group col-4">
                <label>Choose how ratings will be for wellbeing coach:</label>
                <Dropdown
                  //   className={
                  //     "form-control " + (validation.tests.isInvalid && "has-error")
                  //   }
                  placeholder="Choose an option"
                  fluid
                  selection
                  options={
                    this.state.wcRatingsOptions
                  }
                  onChange={(event, value) => {
                    this.setState({ wcRatingsOptionSelected: value.value });
                  }}
                  value={this.state.wcRatingsOptionSelected}
                />
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="page-header">
          {/* <h5>Test Ratings Questions</h5> */}
          <div className="actions">
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>
            <div className="action-item">
              <button
                className="btn btn-sm button text-button"
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
            }
          />
        </Card>
        <Modal size="lg" show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={(e) => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
                <button
                  onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                  className="btn button btn-success"
                >
                  Restore
                </button>
              )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RatingsPanel);
