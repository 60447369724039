import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList, getActiveCount } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import RadioButtonCheckedOutlinedIcon from "@material-ui/icons/RadioButtonCheckedOutlined";
import TextFieldsOutlinedIcon from "@material-ui/icons/TextFieldsOutlined";
import Alert from "react-bootstrap/Alert";
import PostAddOutlinedIcon from "@material-ui/icons/PostAddOutlined";
import { NavLink } from "react-router-dom";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import Drag from "@material-ui/icons/DragIndicatorOutlined"
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class LifestyleSurvey extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "questionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required."
      },
      {
        field: "questionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required."
      },
      {
        field: "answerType",
        method: "isEmpty",
        validWhen: false,
        message: "Answer type is required."
      }
    ]);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      questions: null,
      isLoaded: false,
      loadError: "",
      error: "",
      questionEn: "",
      questionAr: "",
      answerType: "",
      formHeading: "Create Question",
      editId: null,
      validation: this.validator.valid(),

      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      showAlert: true,
      answerTypes: []
    };

    this.columns = [
      {
        name: "",
        cell: (row) =>
          // <a className="drag-handle" href="#">Drag</a>
          <Drag className="drag-handle"></Drag>,
        width: "50px"
      },
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true
      },
      {
        name: "Answer Type",
        selector: "survey_answer_type.answer_type",
        sortable: true,
        center: true,
        wrap: true,
        format: row => (
          <>
            {row.survey_answer_type.id === 4 ? (
              <div>
                <span className="badge badge-blue">
                  <RadioButtonCheckedOutlinedIcon></RadioButtonCheckedOutlinedIcon>
                  Single Select
                </span> + <span className="badge badge-secondary">
                  <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                  Plain Text
                </span>
              </div>
            ) : row.survey_answer_type.id === 5 ? (
              <div>
                <span className="badge badge-skyblue">
                  <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                  Multi Select
                </span> + <span className="badge badge-secondary">
                  <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                  Plain Text
                </span>
              </div>
            ) : row.survey_answer_type.id === 1 ? (
              <div>
                <span className="badge badge-blue">
                  <RadioButtonCheckedOutlinedIcon></RadioButtonCheckedOutlinedIcon>
                  Single Select
                </span>
              </div>
            ) : row.survey_answer_type.id === 2 ? (
              <div>
                <span className="badge badge-skyblue">
                  <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                  Multi Select
                </span>
              </div>
            ) : (
              <span className="badge badge-secondary">
                <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                Plain Text
              </span>
            )}
          </>
        )
      },
      {
        name: "Options",
        maxWidth: "100px",
        cell: row => {
          const count = getActiveCount(row.option_question);
          return count ? count : "None";
        }
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        )
      },

      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {row.survey_answer_type.id === 1 ||
                row.survey_answer_type.id === 2 ? (
                <ActionBar.Item
                  as={NavLink}
                  to={
                    "/dashboard/medical-profile/survey/options/" + row.id + "/L"
                  }
                >
                  <PostAddOutlinedIcon></PostAddOutlinedIcon>
                  Manage Options
                </ActionBar.Item>
              ) : (
                ""
              )}
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.columnsReadOnly = [
      {
        name: "",
        cell: (row) =>
          // <a className="drag-handle" href="#">Drag</a>
          <Drag className="drag-handle"></Drag>,
        width: "50px"
      },
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true
      },
      {
        name: "Answer Type",
        selector: "survey_answer_type.answer_type",
        sortable: true,
        center: true,
        wrap: true,
        format: row => (
          <>
            {row.survey_answer_type.id === 4 ? (
              <div>
                <span className="badge badge-blue">
                  <RadioButtonCheckedOutlinedIcon></RadioButtonCheckedOutlinedIcon>
                  Single Select
                </span> + <span className="badge badge-secondary">
                  <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                  Plain Text
                </span>
              </div>
            ) : row.survey_answer_type.id === 5 ? (
              <div>
                <span className="badge badge-skyblue">
                  <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                  Multi Select
                </span> + <span className="badge badge-secondary">
                  <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                  Plain Text
                </span>
              </div>
            ) : row.survey_answer_type.id === 1 ? (
              <div>
                <span className="badge badge-blue">
                  <RadioButtonCheckedOutlinedIcon></RadioButtonCheckedOutlinedIcon>
                  Single Select
                </span>
              </div>
            ) : row.survey_answer_type.id === 2 ? (
              <div>
                <span className="badge badge-skyblue">
                  <CheckBoxOutlinedIcon></CheckBoxOutlinedIcon>
                  Multi Select
                </span>
              </div>
            ) : (
              <span className="badge badge-secondary">
                <TextFieldsOutlinedIcon></TextFieldsOutlinedIcon>
                Plain Text
              </span>
            )}
          </>
        )
      },
      {
        name: "Options",
        maxWidth: "100px",
        cell: row => {
          const count = getActiveCount(row.option_question);
          return count ? count : "None";
        }
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        )
      },

      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={true}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              {row.survey_answer_type.id === 1 ||
                row.survey_answer_type.id === 2 ? (
                <ActionBar.Item
                  as={NavLink}
                  to={
                    "/dashboard/medical-profile/survey/options/" + row.id + "/L"
                  }
                >
                  <PostAddOutlinedIcon></PostAddOutlinedIcon>
                  Manage Options
                </ActionBar.Item>
              ) : (
                ""
              )}
              <ActionBar.Item
                onClick={() => this.editQuestion(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.submitted = false;
    this.finalList = [];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.updateRank = this.updateRank.bind(this);
    this.setFinalList = this.setFinalList.bind(this);

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const results = [...that.finalList];
        const item = results.splice(fromIndex, 1)[0];
        results.splice(toIndex, 0, item);
        if (that.state.searchApplied) that.setState({
          results: results
        });
        else {
          if (that.state.showItems === "Active") that.setState({
            activeList: results
          });
          else if (that.state.showItems === "Archived") that.setState({
            archivedList: results
          });
          else that.setState({
            questions: results
          });
        }
        that.updateRank();
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true
    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadData();
    let url = "medlife-answer-types/"
    API.get(url)
      .then((res) => {
        this.setState({
          answerTypes: res?.data?.map((a, i) => {
            return {
              text: a.answer_type,
              value: i + 1,
              key: i + 1
            }
          })
        })
      })
      .catch((error) => {
        console.log("Error on fetching answers", error);
      });
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.LIFESTYLE_PROFILE_QUESTIONS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadData() {
    this.loadQuestions();
  }

  loadQuestions() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });

    API.get("medlife-lifestyle-profile-questions/")
      .then(response => {
        let newList = response.data;
        newList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        const activeList = filterList(newList, "active");
        activeList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        const archivedList = filterList(newList, "archived");
        archivedList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        this.setState({
          questions: newList,
          activeList,
          archivedList,
          isLoaded: true
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
      });
  }

  editQuestion(index, id) {
    let questions = this.state.questions;
    this.setState({
      formHeading: "Edit Question",
      questionEn: questions[index]["question"],
      questionAr: questions[index]["question_ar"],
      answerType: questions[index].survey_answer_type.id,
      editId: id,
      showAlert: false
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("medlife-question/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });
        this.loadData();
      })
      .catch(error => {
        console.log("Error on updating question", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      answerType: "",
      editId: null,
      validation: this.validator.valid(),
      showAlert: true
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Question";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Question successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Question";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Question successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    const { questionEn, questionAr, answerType, editId } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });

      if (editId !== null) {
        //edit
        API.patch("medlife-question/" + editId + "/", {
          question: questionEn,
          question_ar: questionAr,
          survey_answer_type: answerType
        })
          .then(response => {
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully updated.",
              successMessage: true,
              showNotification: true
            });
            this.loadData();
          })
          .catch(error => {
            console.log("Error on updating question", error);
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      } else {
        API.post("medlife-question/", {
          question: questionEn,
          question_ar: questionAr,
          survey_answer_type: answerType,
          survey_type: "L"
        })
          .then(response => {
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully created.",
              successMessage: true,
              showNotification: true
            });
            this.loadData();
          })
          .catch(error => {
            console.log("Error on creating question", error);
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.questions;

    let newList = [];

    if (!list) return;

    for (let item of list) {
      if (
        item.question.toLowerCase().indexOf(newValue) !== -1 ||
        item.question_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }
  updateRank() {
    const { searchApplied, results, activeList, archivedList, questions, showItems } = this.state;
    var data = [];
    if (searchApplied) {
      data = results;
    }
    else {
      if (showItems === "Active") data = activeList
      else if (showItems === "Archived") data = archivedList
      else data = questions
    }
    var rank = {};
    data.map((val, index) => {
      rank[val.id] = index + 1;
    })
    API.post("set-lifestyle-questions-rank/", rank)
      .then(response => {
        this.props.showNotificationMessage({
          notificationMessage: "Slider Updated successfully.",
          successMessage: true,
          showNotification: true,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log("Error on updating rank", error);
        this.setState({
          error: "Some error occured. Please try again",
          loading: false
        });
      })
  }

  setFinalList() {
    const { searchApplied, showItems, activeList, archivedList, questions, results } = this.state;
    if (searchApplied) this.finalList = results;
    else {
      if (showItems === "Active") this.finalList = activeList;
      else if (showItems === "Archived") this.finalList = archivedList;
      else this.finalList = questions;
    }
  }

  render() {
    let form;
    const {
      questions,
      editId,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      answerTypes,
      showAlert,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      this.setFinalList();
    }
    form = (
      <>
        <div className="form-group mb-3">
          <label>Question (English) *</label>
          <input
            type="text"
            name="questionEn"
            className={
              "form-control " + (validation.questionEn.isInvalid && "has-error")
            }
            placeholder="Question in English"
            onChange={this.handleChange}
            value={this.state.questionEn}
          ></input>
          <span className="help-block">{validation.questionEn.message}</span>
        </div>
        <div className="form-group mb-4">
          <label>Question (Arabic) *</label>
          <input
            type="text"
            name="questionAr"
            className={
              "form-control input-arabic " +
              (validation.questionAr.isInvalid && "has-error")
            }
            placeholder="Question in Arabic"
            onChange={this.handleChange}
            value={this.state.questionAr}
          ></input>
          <span className="help-block">{validation.questionAr.message}</span>
        </div>
        <div className="form-group mb-3 half-width">
          <label>Answer Type</label>
          <Dropdown
            value={this.state.answerType}
            disabled={editId ? true : false}
            className={
              "form-control " +
              (validation.answerType.isInvalid && " has-error")
            }
            placeholder="Select type"
            onChange={(event, value) => {
              this.setState({ answerType: value.value });
            }}
            search
            selection
            options={answerTypes}
          />
          <span className="help-block">{validation.answerType.message}</span>
        </div>
        {showAlert ? (
          <Alert
            variant="warning"
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            dismissible
            className="mt-2"
          >
            You can't change the type once the question is created.
          </Alert>
        ) : null}
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="questions">
        <div className="page-header">
          {/* <h5>Lifestyle Profile Questions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>

            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
                disabled={!isEditable}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <ReactDragListView {...this.dragProps}>
            <DataTable
              highlightOnHover
              columns={isEditable ? this.columns : this.columnsReadOnly}
              data={this.finalList}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + resultCount + " results in '" + showItems + "'"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </ReactDragListView>
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={e => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LifestyleSurvey);
