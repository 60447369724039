import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: -1, value: null, label: "NONE" };

SelectFoodCategory.propTypes = {
  foodCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFoodCategory: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
};

function SelectFoodCategory(props) {
  const {
    foodCategories = [],
    selectedFoodCategory,
    onSelect = (f) => f,
  } = props;

  const activeFoodCategories = foodCategories.filter((it) => it.is_active);
  activeFoodCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const inactiveFoodCategories = foodCategories.filter((it) => !it.is_active);
  inactiveFoodCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const options = [
    OPTION_NONE,
    {
      label: "Active",
      options: activeFoodCategories.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveFoodCategories.map(createOption),
    },
  ];
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const foodCategory =
    selectedFoodCategory &&
    foodCategories.find((it) => it.id === selectedFoodCategory);

  return (
    <Select
      key={"website_categories" + foodCategories.map((it) => it.id).join()}
      value={foodCategory ? createOption(foodCategory) : OPTION_NONE}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Food Category..."
      isDisabled={props.disabled}
    />
  );
}

const createOption = (foodCategory) => ({
  key: foodCategory.id,
  value: foodCategory.id,
  label: foodCategory.foodCategory,
});

export default SelectFoodCategory;
