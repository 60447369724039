import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import PhoenixAPI from "utils/PhoenixAPI";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import Select from "react-select";
import {
  fetchValeoPartners,
  fetchPartnersBloodPackages,
  fetchPartnersCustomPackages,
  fetchPartnerSupplements,
  fetchPartnerTreatmentPlans,
  fetchPartnerMobileBanners, fetchPartnerCoachConsultationPackages
} from "services/ValeoPartnersService";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class DewaPackagesForm extends Component {
  constructor(props) {
    super(props);

    const {
      packages = [],
      packagesOptions,
      selectedCountry,
      valeoPartner,
      partnerPackageDetails = [],
      serviceType,
    } = this.props;
  
    this.state = {
      partnerPackages: partnerPackageDetails,
      partnerPackage: {},
      errors: {},
      editables: {},
    };
    
    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        wrap: true,
        // width: "50px",
        // maxWidth: "100px",
      },
      {
        name: this.getPackageName(),
        selector: "itemName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (partner) => <Status isActive={partner.isActive ?? false} />,
      },
      {
        name: "View",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (partner) => this.renderActionBar(partner),
      },
    ];
  }

  getPackageName=()=>{
    const {serviceType}=this.props;
    if(serviceType==="bloodPackage"){
      return "Blood Packages"
    }
    else if(serviceType==="customPackage"){
      return "Custom Packages"
    }
   else if(serviceType === "treatmentPlan"){
    return  "Treatment Plans"
   }
   else if(serviceType ==="supplement"){
    return "Supplements"
   }
   else if(serviceType==="banners"){
    return "Banners"
   }
   else if(serviceType==="coachConsultationPackages"){
    return "Coach Consultation packages"
   }
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      partnerPackage: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const partnerPackage = { ...this.state.partnerPackage };
    partnerPackage[input.name] = input.value;
    this.setState({ partnerPackage });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { partnerPackages = [] } = this.state;
    const partnerPackage = partnerPackages.find((it) => it.id === id);
    if (!partnerPackage) return;

    this.setState({ partnerPackage }, () => this.showAddEditModal());
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { partnerPackage = {} } = this.state;
    if (partnerPackage.id === null || partnerPackage.id === undefined)
      this.createPartnerPackage();
    else this.updatePartnerPackage(partnerPackage.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { itemName } = this.state.partnerPackage || {};
    if (!itemName || itemName.trim() === "")
      errors.partner_id = "Package Service cannot be blank";
    // if (!status) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  fetchValeoPartnerPackages = async (packageService) => {
    this.showLoading();
    const { selectedCountry, valeoPartner, serviceType } = this.props;
    const payload = {
      countryId: selectedCountry.id,
      id: valeoPartner.id,
    };
    try {
      if (serviceType === "bloodPackage") {
        const { data: partnerPackages = [] } =
          await (fetchPartnersBloodPackages(payload) ?? []);
        this.setState({ partnerPackages });
      } else if (serviceType === "customPackage") {
        const { data: partnerPackages = [] } =
          await (fetchPartnersCustomPackages(payload) ?? []);

        this.setState({ partnerPackages });
      } else if (serviceType === "treatmentPlan") {
        const { data: partnerPackages = [] } =
          await (fetchPartnerTreatmentPlans(payload) ?? []);
        this.setState({ partnerPackages });
      } else if (serviceType === "supplement") {
        const { data: partnerPackages = [] } = await (fetchPartnerSupplements(
          payload
        ) ?? []);
        this.setState({ partnerPackages });
      }
      else if(serviceType === "banners"){
        const {data:partnerPackages=[]}=await (fetchPartnerMobileBanners(payload)?? [])
        this.setState({partnerPackages})
      }
      else if (serviceType==="coachConsultationPackages"){
        const {data:partnerPackages=[]}=await (fetchPartnerCoachConsultationPackages(payload)?? []);
        this.setState({partnerPackages})
      }
    } catch (error) {
      console.log("Error in Fetching in package services", error.message);
      this.showErrorNotification(
        "Error in Fetching in package services",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };
  createPartnerPackage = async () => {
    const { partnerPackage } = this.state;
    const { selectedCountry, valeoPartner, serviceType } = this.props;
    let packageService = "";
    let payload = {
      valeoPartnerId: valeoPartner.id,
      isActive: partnerPackage.isActive,
      countryId: selectedCountry.id,
    };
    if (serviceType === "bloodPackage") {
      payload["bloodPackageId"] = partnerPackage.itemId;
      packageService = "blood-package";
    } else if (serviceType === "customPackage") {
      payload["customPackageId"] = partnerPackage.itemId;
      packageService = "custom-package";
    } else if (serviceType === "treatmentPlan") {
      payload["conditionId"] = partnerPackage.itemId;
      packageService = "treatment-plan";
    } else if (serviceType === "supplement") {
      payload["supplementId"] = partnerPackage.itemId;
      packageService = "supplement";
    }
    else if(serviceType === "banners"){
      payload["bannerId"]=partnerPackage.itemId;
      packageService ="mobile-banner"
    }
    else if(serviceType === "coachConsultationPackages"){
      payload["coachConsultationPackageId"]=partnerPackage.itemId;
      packageService ="coach-consultation-package"
    }

    this.showLoading();
    try {
      const response = await PhoenixAPI.post(
        `valeo-partner-${packageService}/`,
        payload
      );
      console.log("response:", response);
      this.showSuccessNotification("Package Service Created Successfully.");

      this.fetchValeoPartnerPackages(packageService);
    } catch (error) {
      this.showErrorNotification(
        "Error on updating Blood package: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  updatePartnerPackage = async (id) => {
    const { partnerPackage } = this.state;
    const { selectedCountry, valeoPartner, serviceType } = this.props;
    let packageService = "";
    const payload = {
      valeoPartnerId: valeoPartner.id,
      isActive: partnerPackage.isActive,
      countryId: selectedCountry.id,
    };
    if (serviceType === "bloodPackage") {
      payload["bloodPackageId"] = partnerPackage.itemId;
      packageService = "blood-package";
    } else if (serviceType === "customPackage") {
      payload["customPackageId"] = partnerPackage.itemId;
      packageService = "custom-package";
    } else if (serviceType === "treatmentPlan") {
      payload["conditionId"] = partnerPackage.itemId;
      packageService = "treatment-plan";
    } else if (serviceType === "supplement") {
      payload["supplementId"] = partnerPackage.itemId;
      packageService = "supplement";
    }
     else if(serviceType === "banners"){
      payload["bannerId"]= partnerPackage.itemId;
      packageService= "mobile-banner"
    }
    else if(serviceType === "coachConsultationPackages"){
      payload["coachConsultationPackageId"]=partnerPackage.itemId;
      packageService ="coach-consultation-package"
    }
    this.showLoading();
    try {
      const response = await PhoenixAPI.patch(
        `valeo-partner-${packageService}/${partnerPackage.id}`,
        payload
      );
      console.log("response:", response);
      this.showSuccessNotification("Package Service Updated Successfully.");

      this.fetchValeoPartnerPackages(packageService);
    } catch (error) {
      this.showErrorNotification(
        "Error on updating Blood package: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleAddNewQuestion = () => {
    const partnerPackage = { isActive: false };
    this.setState({ partnerPackage }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (partnerPackage) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(partnerPackage.id)}>
          <EditIcon className="icon-small" />
          View
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.partnerPackage.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  createPackageOption = (partnerPackage) => {
    return {
      key: partnerPackage.itemId,
      value: partnerPackage.itemId,
      label: partnerPackage.itemName,
    };
  };

  handlePackageChange = (packageName) => {
    const { partnerPackage, partnerPackages } = this.state;
    const { packages, packagesOptions, serviceType } = this.props;
    let selectedId = {};
    if (serviceType === "bloodPackage") {
      selectedId = packagesOptions.find(
        (it) => it.subscription_name === packageName
      );
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.subscription_name,
        },
      });
    } else if (serviceType === "customPackage") {
      selectedId = packagesOptions.find((it) => it.name === packageName);
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.name,
        },
      });
    } 
    else if (serviceType ==="banners") {
      selectedId = packagesOptions.find((it) => it.name === packageName);
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.name,
        },
      });
    }
    else if (serviceType === "treatmentPlan") {
      selectedId = packagesOptions.find(
        (it) => it.internalName === packageName
      );
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.internalName,
        },
      });
    }
    else if (serviceType ===  "supplement") {
      selectedId = packagesOptions.find(
        (it) => it.internalName === packageName
      );
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.internalName,
        },
      });
    }
    else if (serviceType ===  "coachConsultationPackages") {
      selectedId = packagesOptions.find(
        (it) => it.externalName === packageName
      );
      this.setState({
        partnerPackage: {
          ...partnerPackage,
          itemId: selectedId.id,
          itemName: selectedId.externalName,
        },
      });
    }
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  updatedOptions = () => {
    const { partnerPackages = [] } = this.state;
    const { packagesOptions = [] } = this.props;
    let updatedOptions =
      packagesOptions.length > 0 &&
      packagesOptions.filter(
        (itemA) => !partnerPackages.some((itemB) => itemB.itemId === itemA.id)
      );
      console.log("Updated Options:",updatedOptions)
    return updatedOptions;
  };
  render() {
    const {
      partnerPackages = [],
      partnerPackage = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewPartnerPackage =
      partnerPackage.id === null || partnerPackage.id === undefined;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const form = (
      <div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="partnerPackage">Package service</label>
            <div>
              <Select
                key={`my_unique_select_key__${
                  partnerPackage && partnerPackage.itemId
                }`}
                value={
                  partnerPackage && this.createPackageOption(partnerPackage)
                }
                onChange={(event) => {
                  this.handlePackageChange(event.value);
                }}
                options={this.updatedOptions() ?? []}
                style={selectStyles}
                placeholder="Select Service"
                searchable
                lazyLoad
                isDisabled={!isNewPartnerPackage && !editables.partnerPackage}
              />
              {/* {this.renderPencilIcon("partnerPackage")} */}
            </div>
            <span className="help-block">{errors.partner_id}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={partnerPackage?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    partnerPackage: {
                      ...partnerPackage,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPartnerPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={partnerPackages || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {partnerPackage.id === null || partnerPackage.id === undefined
                ? "Add Package"
                : "Edit Package"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {partnerPackage.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DewaPackagesForm);
