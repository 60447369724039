import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Card from "react-bootstrap/Card";
import Status from "components/Common/Status";
import StandardPackagesDropdown from "pages/CMS/StandardPackagesDrodown";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class PackageBreaking extends Component {
  constructor(props) {
    super(props);
    const {
      packages = [],
      onPackageUpdated = (f) => f,
      bloodPackages = [],
      customPackages = [],
      membersApplicable,
    } = this.props;

    this.onPackageUpdated = onPackageUpdated;
    this.state = {
      packages,
      bundle: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      bloodPackages,
      customPackages,
      standardPackage: {},
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Product Name",
        selector: "package_name",
        sortable: "true",
        wrap: true,
      },
      {
        name: "Default Member",
        selector: "member_text",
        sortable: true,
        wrap: true,
      },
      {
        name: "Appointment Date",
        selector: "appointment_date_text",
        sortable: true,
        wrap: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (bundle) => <Status isActive={bundle?.is_active ?? false} />,
      },
      {
        name: "Update",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  handleEdit = (id) => {
    const { packages = [] } = this.state;
    const bundle = packages.find((it) => it.id === id);
    if (!bundle) return;
    const packageId = bundle?.package_id ?? null;
    const packageType = bundle?.package_type ?? "";
    const standardPackage =
      packageType === "Custom_Package"
        ? this.findCustomPackage(Number(packageId))
        : this.findBloodBiomarkerPackage(Number(packageId));

    this.setState(
      {
        bundle,
        standardPackage: { ...standardPackage, package_type: packageType },
      },
      () => this.showAddEditModal()
    );
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { bundle = {} } = this.state;
    if (bundle.id === null || bundle.id === undefined)
      this.createQuestion();
    else this.updateQuestion(bundle.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
     package_id, is_active,appointment_date_value, member
    } = this.state.bundle || {};

    if(!package_id){
      errors.standard_package = "Please select a package";
    }

   if(appointment_date_value==null || appointment_date_value==undefined){
    errors.appointment_date_value = "Please select an appointment date";
   }

   if(!member){
    errors.member="Please select member"
   }

    return Object.keys(errors).length === 0 ? null : errors;
  };
  createQuestion = () => {
    const { packages = [], bundle } = this.state;
    const ids = packages.length > 0 ? packages.map((it) => it.id) : null;
    bundle.id = ids ? Math.max(...ids) + 1 : 1;

    bundle.is_new = true;

    this.setState({ packages: [...packages, bundle] }, () => {
      this.showSuccessNotification("Package Created Successfully!");
      this.onPackageUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { packages = [], bundle } = this.state;
    const newQuestions = packages.map((it) => (it.id === id ? bundle : it));
    this.setState({ packages: newQuestions }, () => {
      this.showSuccessNotification("Package Updated Successfully!");
      this.onPackageUpdatedLocally();
    });
  };

  onPackageUpdatedLocally = () => {
    this.onPackageUpdated(
      this.state.packages.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
    const bundle = { is_active: false };
    this.setState({ bundle, standardPackage:{} }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.setState({ isAddEditModalVisible: false , errors:{}, editables:{},bundle:{}});
  };

  findCustomPackage = (id) => {
    const { customPackages = [] } = this.state;
    return customPackages.find((it) => it.id === id);
  };

  findBloodBiomarkerPackage = (id) => {
    const { bloodPackages = [] } = this.state;
    return bloodPackages.find((it) => it.id === id);
  };

  handlePackageSelection = ({ value }) => {
    const [packageId, packageType] = value.split(":");
    const standardPackage =
      packageType === "Custom_Package"
        ? this.findCustomPackage(Number(packageId))
        : this.findBloodBiomarkerPackage(Number(packageId));
    const newbundle = {
      ...this.state.bundle,
      package_id: packageId,
      package_type: packageType,
      package_name:packageType=="Custom_Package"?standardPackage.name:standardPackage.internal_name
    };

    this.setState({
      bundle: newbundle,
      standardPackage: { ...standardPackage, package_type: packageType },
    });
  };

  fetchApplicableMembers = () => {
    const { membersApplicable = [] } = this.props;
    const count = membersApplicable; // Set the count to 5 (for example)
    let i = 1;
    let memberApplicableOptions = [];
    while (i <= count) {
      // Code to execute inside the loop
      const member = {
        key: `Member ${i}`,
        value: i,
        text: `Member ${i}`,
      };

      memberApplicableOptions.push(member);
      i++;
    }
    return memberApplicableOptions;
  };

  fetchAppointmentDates = () => {
    const count = 90; // Set the count to 5 (for example)
    let i = 1;
    let appointmentDateOptions = [
      {
        key: `Appointment Date`,
        value: 0,
        text: `Appointment Date`,
      },
    ];
    while (i <= count) {
      // Code to execute inside the loop

      const member = {
        key: `Appointment Date + ${i}`,
        value: i,
        text: `Appointment Date + ${i}`,
      };

      appointmentDateOptions.push(member);
      i++;
    }
    return appointmentDateOptions;
  };
  renderViewButton = (packageId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(packageId);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.bundle.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  render() {
    const {
      packages = [],
      bundle = {},
      errors,
      editables,
      customPackages,
      bloodPackages,
      standardPackage,
    } = this.state;

    const isNewPackage = bundle.id === null || bundle.id === undefined;
    const packageBreakingForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="standard_package">
              Standard Package Association*
            </label>
            <div className="input-group">
              <StandardPackagesDropdown
                id="standard_package"
                name="standard_package"
                customPackages={customPackages || []}
                bloodBiomarkerPackages={bloodPackages || []}
                selectedPackage={
                  standardPackage && Object.keys(standardPackage).length !== 0
                    ? standardPackage
                    : null
                }
                onSelect={this.handlePackageSelection}
                disabled={!isNewPackage && !editables.standard_package}
                placeholder="Select Standard Package..."
                className={
                  "form-control" + (errors.standard_package ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("standard_package")}
            </div>
            <span className="help-block">{errors.standard_package}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="member">Applicable Member</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.member}
                onChange={(event, value) => {
                  console.log("value:", value);
                  this.setState({
                    bundle: {
                      ...bundle,
                      member: value.value,
                      member_text:`Member ${value.value}`
                    },
                  });
                }}
                id="member"
                name="member"
                placeholder="member"
                search
                selection
                options={this.fetchApplicableMembers()}
                disabled={!isNewPackage && !editables.member}
                className={"form-control" + (errors.member ? " has-error" : "")}
              />
              {this.renderPencilIcon("member")}
            </div>
            <span className="help-block">{errors.member}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="appointment_date_value">Appointment Date</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.appointment_date_value}
                onChange={(event, value) => {
                  this.setState({
                    bundle: {
                      ...bundle,
                      appointment_date_value: value.value,
                      appointment_date_text:`Appointment Date ${value.value}`
                    },
                  });
                }}
                id="appointment_date_value"
                name="appointment_date_value"
                placeholder="Select Appointment Date"
                search
                selection
                options={this.fetchAppointmentDates()}
                disabled={!isNewPackage && !editables.appointment_date_value}
                className={
                  "form-control" +
                  (errors.appointment_date_value ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("appointment_date_value")}
            </div>
            <span className="help-block">{errors.appointment_date_value}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={bundle?.is_active}
                onChange={(event, value) => {
                  this.setState({
                    bundle: {
                      ...bundle,
                      is_active: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );
    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={packages || []}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {bundle.id === null || bundle.id === undefined
                ? "Add Package"
                : "Edit Package"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{packageBreakingForm}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {bundle.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageBreaking);
