import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import PhoenixAPI from "utils/PhoenixAPI";
import PropTypes from "prop-types";
import Delete from "@material-ui/icons/Delete";

class BiomarkerUnitConversion extends Component{
     
    constructor(props) {
      super(props);
      const { unitConversion = [],biomarkerName,toUnit, onUnitConversionUpdate= (f) => f  } = this.props;
     console.log("unit is ",toUnit);
    this.onUnitConversionUpdate = onUnitConversionUpdate;
    console.log("unit conversion",unitConversion)
    this.state={
      unitConversion,
      unitConversionItem: {},
      errors: {},
      editables: {},
      loadError: "",
      toUnit,
      isConfirmDeleteModalVisible: false,
    };

this.columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
      width: "50px",
      maxWidth: "100px",
    },
    {
        name:"From",
        selector: "fromUnits",
        sortable: true,
        width: "100px",
        maxWidth: "100px",
    },
    {
        name:"To",
        selector: "toUnits",
        sortable: true,
        width: "100px",
        maxWidth: "100px",
    },
    {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (unitConversionItem) => this.renderActionBar(unitConversionItem),
      },
];}


clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      unitConversionItem : {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const  unitConversionItem = { ...this.state.unitConversionItem };
    unitConversionItem[input.name] = input.value;
    this.setState({ unitConversionItem});
  };

  handleEdit = (id) => {
    this.clearForm();
    const { unitConversion = [] } = this.state;
    const unitConversionItem= unitConversion.find((it) => it.id === id);
    if (!unitConversionItem) return;

    this.setState({ unitConversionItem }, () => this.showAddEditModal());
  };

  ConfirmDeleteUnit=async(id)=>{
    const{unitConversion=[]}=this.state;
    const deleteURL=`biomarker/unit-conversion/${id}`;
    const response= await PhoenixAPI.delete(deleteURL);
   await  this.getBiomarkerUnitConversion(this.props.biomarkerName);

    this.showSuccessNotification("Unit Conversion Deleted Successfully!");
   
  }


  

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { unitConversionItem = {} } = this.state;
    if (unitConversionItem.id === null || unitConversionItem.id === undefined) this.createUnitConversion();
    else this.updateUnitConversion(unitConversionItem.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};

    const { unitConversionItem = {} ,isNewUnit,unitConversion} = this.state;
    const {
      fromUnits,
      toUnits,
      conversionFactor,
    } = unitConversionItem;

  if(!fromUnits)
  errors.fromUnits="from unit field should not be empty";
 if(isNewUnit){
  const isDuplicate = unitConversion.some(
    (unit) =>  unit.fromUnits.toLowerCase() === fromUnits.toLowerCase()
  );
  if(isDuplicate)
  {
    errors.fromUnits="from units should not be duplicate";
  }
}
if(!isNewUnit){
const finalUnitConversion=unitConversion.filter((unit)=>unit.id!==unitConversionItem.id);
const isDuplicate=finalUnitConversion.some((unit)=>unit.fromUnits.toLowerCase() === fromUnits.toLowerCase());
if(isDuplicate)
  {
    errors.fromUnits="from units should not be duplicate";
  }
}

  if(!toUnits)
  errors.toUnits="to unit field should not be empty";
  if(!conversionFactor)
  errors.conversionFactor="multliplicationFactor field should not be empty";
  return Object.keys(errors).length === 0 ? null : errors;
  }

  createUnitConversion = async() => {
    const { unitConversion = [], unitConversionItem } = this.state;
    const ids = unitConversion.length > 0 ? unitConversion.map((it) => it.id) : null;
    unitConversion.id = ids ? Math.max(...ids) + 1 : 1;

    unitConversionItem.is_new = true;
    const payload={
        
    "fromUnits": unitConversionItem.fromUnits,
    "toUnits":unitConversionItem.toUnits,
    "conversionFactor":unitConversionItem.conversionFactor,
    "valeoBiomarkerName":this.props.biomarkerName
    }
   const response = await PhoenixAPI.post("biomarker/unit-conversion",payload);

   console.log("response is",response);
   await  this.getBiomarkerUnitConversion(this.props.biomarkerName);
    
      this.showSuccessNotification("Unit Conversion Created Successfully!");
      this.onUnitUpdatedLocally();
    
  };

  getBiomarkerUnitConversion=async(name)=>{
    const url= "biomarker/unit-conversion/test-name";
  
   const {data: allConversions = []}=  await PhoenixAPI.get(url,{params:{valeoBiomarkerName:name}});
   this.setState({unitConversion:allConversions});
  }

  updateUnitConversion = async(id) => {
    const { unitConversion= [], unitConversionItem} = this.state;
    const updateUnits =unitConversion.map((it) => (it.id === id ? unitConversionItem : it));
    const payload={"conversionFactor":unitConversionItem.conversionFactor,"fromUnits": unitConversionItem.fromUnits}
   await  PhoenixAPI.patch(`biomarker/unit-conversion/${id}`,payload);
    await  this.getBiomarkerUnitConversion(this.props.biomarkerName);
      this.showSuccessNotification("Unit Conversion Updated Successfully!");
      this.onUnitUpdatedLocally();
    
  };

  

  onUnitUpdatedLocally = () => {
    this.clearForm();
    this.onUnitConversionUpdate(
      this.state.unitConversion.map((unit) => {
        const q = { ...unit };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewUnit = () => {
    if(this.props.toUnit){
    const unitConversionItem = { toUnits:this.props.toUnit };
    this.setState({ unitConversionItem}, () => this.showAddEditModal());
    }
    else{
      const unitConversionItem = {};
    this.setState({ unitConversionItem}, () => this.showAddEditModal());
    }
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (unitConversionItem) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(unitConversionItem.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.ConfirmDeleteUnit(unitConversionItem.id)}>
          <Delete className="icon-small" />
          Delete
        </ActionBar.Item>
        
      </ActionBar.Menu>
    </ActionBar>
  );

  ConfirmDeleteUnit

  renderPencilIcon = (fieldName) =>
    this.state.unitConversionItem.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });



  render(){
    const { unitConversion, unitConversionItem= {}, errors, editables,toUnit, sortColumn } = this.state;

    const isNewUnit =unitConversionItem.id === null || unitConversionItem.id === undefined;

    const form = (
      <>
        <div className="row">
            <div className="form-group col">
            <label htmlFor="conversion_from">From*</label>
            <div className="input-group">
              <input
                value={unitConversionItem.fromUnits ||""}
                onChange={this.handleChange}
                id="fromUnits"
                name="fromUnits"
                placeholder="from Units"
                readOnly={!isNewUnit && !editables.fromUnits}
                className={
                  "form-control py-2" +
                  (errors.fromUnits ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("fromUnits")}
            </div>
            <span className="help-block">{errors.fromUnits}</span>
          </div>
          <div><span>&rarr;</span></div>
          <div className="form-group col">
            <label htmlFor="conversion_from">To</label>
            <div className="input-group">
              <input
                value={unitConversionItem.toUnits}
                onChange={this.handleChange}
                id="toUnits"
                name="toUnits"
                placeholder="to Units"
                readOnly={this.props.toUnit}
                className={
                  "form-control py-2" +
                  (errors.toUnits ? " has-error" : "")
                }
              ></input>
              
            </div>
            <span className="help-block">{errors.toUnits}</span>
          </div>
             </div>   
            <div className="row">
                <div className="form-group col">
                <label htmlFor="conversion_from">Formulae</label>
                <div className="input-group">
                <span>_____ {unitConversionItem.fromUnits ||"fromUnits"}</span>
                </div>
                    </div>
                    <div> <span>&times;</span></div>
                <div className="form-group col">
                <div className="input-group">
                <input
                value={unitConversionItem.conversionFactor}
                onChange={this.handleChange}
                type="number"
            step="any"
            id="conversionFactor"
            name="conversionFactor"
            className={
                "form-control py-2" +
                (errors.conversionFactor ? " has-error" : "")
              }>

            </input>
                
            </div>

            <span className="help-block">{errors.conversionFactor}</span>
            </div>
            </div>
            </>);
    return(<>
    <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="font-weight-bold mt-4 px-4">Conversion Formulae</p>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={unitConversion || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewUnit}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {unitConversionItem.id === null || unitConversionItem.id === undefined
                ? "Add Conversion"
                : "Edit Conversion"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {unitConversionItem.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>

    )
  }

}

BiomarkerUnitConversion.propTypes = {
  unitConversion: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUnitConversionUpdate: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

BiomarkerUnitConversion.defaultProps = {
  unitConversion: [],
  onUnitConversionUpdate: (f) => f,
  isEditable: true,
};

  function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value })
   
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(BiomarkerUnitConversion);