import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import { formatDateTime } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class InterestedPeople extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      users: null,
      isLoaded: false,
      loadError: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "created_at",
      sortDirection: "desc",
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "100px",
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        width: "300px",
      },
      {
        name: "Phone Number",
        selector: "phone_number",
        sortable: true,
        width: "160px",
      },
      {
        name: "Contacted On",
        selector: "created_at",
        sortable: true,
        width: "200px",
        format: (row) => formatDateTime(row.created_at),
      },
      {
        name: "Device Used",
        selector: "device",
        sortable: true,
        format: (row) => (row.device === "" ? "No device present" : row.device),
        wrap: true,
        width: "150px",
      },
      {
        name: "Existing User",
        selector: "is_existing_user",
        sortable: true,
        format: (row) => (row.is_existing_user ? "Yes" : "No"),
        width: "150px",
      },
    ];

    // this.clearSearch = this.clearSearch.bind(this);
    // this.handleSearch = this.handleSearch.bind(this);
    // this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  componentDidMount() {
    // this.loadUsers();
    this.fetchAclUser();
    this.fetchInterestedPeople();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.INTERESTED_PEOPLE;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  // This function is not needed not that's why its commented.
  // loadUsers() {
  //   this.props.toggleLoading({
  //     isLoading: true,
  //   });

  //   API.get("interested-people-list/")
  //     .then((response) => {
  //       this.setState({ users: response.data, isLoaded: true });
  //       this.props.toggleLoading({
  //         isLoading: false,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again",
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     });
  // }

  // handleSearch(value) {
  //   let newValue,
  //     list = [];

  //   this.setState({
  //     searchTerm: value,
  //   });

  //   // if (e.key !== "Enter") {
  //   //   return;
  //   // }

  //   newValue = value.slice(0).trim().toLowerCase();

  //   if (isEmpty(value)) {
  //     this.setState({
  //       searchApplied: false,
  //       searchExpanded: false,
  //     });

  //     return;
  //   }

  //   list = this.state.users;
  //   if (!list) return;

  //   let newList = [];

  //   for (let item of list) {
  //     if (
  //       (item?.name && item?.name?.toLowerCase()?.indexOf(newValue) !== -1) ||
  //       (item?.email && item?.email?.toLowerCase()?.indexOf(newValue) !== -1) ||
  //       (item?.phone_number && item?.phone_number?.indexOf(newValue) !== -1) ||
  //       moment(item?.created_at)
  //         .format("DD-MM-YYYY, hh:MM A")
  //         .indexOf(newValue) !== -1
  //     ) {
  //       newList.push(item);
  //     }
  //   }

  //   this.setState({
  //     searchExpanded: true,
  //     searchApplied: true,
  //     results: newList,
  //     resultCount: newList.length,
  //   });
  // }

  // clearSearch() {
  //   this.setState({
  //     searchTerm: "",
  //     searchExpanded: true,
  //     searchApplied: false,
  //   });

  //   this.search.searchInput.focus();
  // }

  // handleSearchExit(e) {
  //   const value = e.target.value;

  //   if (!value.length) {
  //     this.setState({
  //       searchExpanded: false,
  //       searchApplied: false,
  //     });

  //     return;
  //   } else {
  //     this.setState({
  //       searchExpanded: true,
  //     });
  //   }
  // }

  handleSearch = (value) => {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }
    list = this.state.orders;
    if (!list) return;
    let newList = [];
    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter = () => {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      this.fetchInterestedPeople();
    });
  }

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchValue: '',
      searchExpanded: true,
      searchApplied: false,
    }, () => {
      this.fetchInterestedPeople();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit = (e) => {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }
  
  handlePageChange = (page) => this.fetchInterestedPeople(page);

  handleRowsPerPageChange = (newRowsPerPage, page) =>
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchInterestedPeople(page)
    );

  handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    this.setState(
      { sortField: column.sortField || column.selector, sortDirection: sortDirection },
      () => this.fetchInterestedPeople()
    );
  };

  fetchInterestedPeople = (page = 1) => {
    const { 
      rowsPerPage = DEFAULT_ROWS_PER_PAGE, 
      sortField, 
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    let url = `interested-people-list/?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;
    if (searchApplied) {
      url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
    }      

    this.showLoading();
    API.get(url)
      .then((response) => {
        console.log("Response: ", response);
        if (response.data) {
          const { count: totalRows = 0, results: users = [] } = response.data;
          this.setState({ totalRows, users, isLoaded: true });
        }
        this.hideLoading();
      })
      .catch((error) => {
        this.hideLoading();
        console.log("Error on fetching interested people", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.showErrorNotification("Some error has occured. Please try again.");
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let finalList = [];

    const { users, isLoaded, loadError, searchApplied, resultCount, results } =
      this.state;

    if (isLoaded) {
      // if (searchApplied) finalList = results;
      // else finalList = users;
      finalList = users;
    }

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>Interested People</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                // searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.handleSearchClear}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn btn-search"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            responsive
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="created_at"
            defaultSortAsc={false}
            sortServer
            onSort={this.handleSort}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={this.state.totalRows || 0}
            paginationPerPage={this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InterestedPeople);
