import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/index";
import { hasToken } from "./services/TokenService";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/valeo.scss";
import ValeoEnglish from "./layouts/ValeoEnglish";
import Login from "./pages/Login";
import history from "utils/history";
import { registerServiceWorker } from "./register-sw";
import ForgotPassword from "pages/ForgotPassword";


registerServiceWorker();

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter basename="/admin">
        <Switch>
          <Route
            name="login"
            path="/login/forgot-password"
            render={(props) => <ForgotPassword {...props} />}
          />
          <Route
            name="login"
            path="/login"
            render={(props) => <Login {...props} />}
          />
          <PrivateRoute></PrivateRoute>
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

function PrivateRoute() {
  if (hasToken())
    return (
      <>
        <Route
          name="Dashboard"
          path="/dashboard"
          render={(props) => <ValeoEnglish {...props} />}
        />
        {history.location.pathname === "/admin" || history.location.pathname === "/" ||
        history.location.pathname === "/admin/" ? (
          <Redirect exact from="/" to="/dashboard" />
        ) : (
          ""
        )}
      </>
    );
  else return <Redirect exact from="/" to="/login" />;
}

export default App;
