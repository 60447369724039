import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectCustomPackages.propTypes = {
  customPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCustomPackageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectCustomPackages.defaultProps = {
  customPackages: [],
  selectedCustomPackageIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectCustomPackages(props) {
  const {
    customPackages,
    selectedCustomPackageIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;
  const findCustomPackage = (id) => customPackages.find((it) => it.id === id);
  
  const options = createOptions(customPackages, showOnlyActive);
  
  const selectedCustomPackages = selectedCustomPackageIds
  .map(findCustomPackage)
  .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };
  return (
    <Select
      key={`my_unique_select_key__${
        selectedCustomPackageIds && selectedCustomPackageIds.join(",")
      }`}
      value={selectedCustomPackages}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(customPackages, showOnlyActive) {
  if (!customPackages || !customPackages.length) return [];

  const [activeCustomPackages, inactiveCustomPackages] = _.partition(
    customPackages,
    (it) => it.status ? it.status?.toLowerCase() === "active" : false 
  );
  activeCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  if (showOnlyActive) return activeCustomPackages.map(createOption);

  inactiveCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  return [
    {
      label: "Active",
      options: activeCustomPackages.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCustomPackages.map(createOption),
    },
  ];
}

function createOption(customPackage) {
  if (!customPackage) return;
  return {
    key: customPackage.id,
    value: customPackage.value ||customPackage.id,
    label: customPackage.name,
  };
}

export default SelectCustomPackages;
