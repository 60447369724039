import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_VALEO_PARTNERS = "valeo-partner";
const BASE_URL_VALEO_PARTNERS_BLOOD_PACKAGES="valeo-partner-blood-package/partner-id";
const BASE_URL_VALEO_PARTNERS_CUSTOM_PACKAGES="valeo-partner-custom-package/partner-id";
const BASE_URL_VALEO_PARTNERS_SUPPLEMENTS="valeo-partner-supplement/partner-id";
const BASE_URL_VALEO_PARTNERS_TREATMENT_PLANS="valeo-partner-treatment-plan/partner-id";

const BASE_URL_VALEO_PARTNERS_MOBILE_BANNERS="valeo-partner-mobile-banner/partner-id";

const BASE_URL_VALEO_PARTNERS_COACH_CONSULTATION_PACKAGES="valeo-partner-coach-consultation-package/partner-id"


export const fetchValeoPartners = async () => {
  try {
    const { data: partners = [] } = await PhoenixAPI.get(
      BASE_URL_VALEO_PARTNERS
    );
    return partners;
  } catch (error) {
    console.log("Error on fetching Valeo Partners", error);
    throw error;
  }
};

export const updatePartner = async (partner) => {
  try {
    const payload = { ...partner };
    const response = await PhoenixAPI.patch(
      `${BASE_URL_VALEO_PARTNERS}/${partner.id}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on updating  partner", error);
    throw error;
  }
};

export const savePartner = async (partner) => {
  try {
    const payload = { ...partner };
    const response = await PhoenixAPI.post(
      `${BASE_URL_VALEO_PARTNERS}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on updating  partner", error);
    throw error;
  }
};

export const fetchPartnersBloodPackages=async(partner)=>{
  try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_BLOOD_PACKAGES}/${partner.id}`, {
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
  }
  catch(error){
    console.log("Error on partner blood packages", error);
    throw error;
  }
}


export const fetchPartnersCustomPackages=async(partner)=>{
  try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_CUSTOM_PACKAGES}/${partner.id}`, {
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
  }
  catch(error){
    console.log("Error on partner custom packages", error);
    throw error;
  }
}


export const fetchPartnerSupplements=async(partner)=>{
  try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_SUPPLEMENTS}/${partner.id}`, {
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
  }
  catch(error){
    console.log("Error on partner supplements", error);
    throw error;
  }
}


export const fetchPartnerTreatmentPlans=async(partner)=>{
  try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_TREATMENT_PLANS}/${partner.id}`, {
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
  }
  catch(error){
    console.log("Error on partner supplements", error);
    throw error;
  }
}

export const fetchPartnerMobileBanners=async(partner)=>{
  try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_MOBILE_BANNERS}/${partner.id}`, {
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
  }
  catch(error){
    console.log("Error on partner supplements", error);
    throw error;
  }
}

export const fetchPartnerCoachConsultationPackages=async(partner)=>{
   try{
    const response=PhoenixAPI.get(`${BASE_URL_VALEO_PARTNERS_COACH_CONSULTATION_PACKAGES}/${partner.id}`,{
      params: {
        countryId: partner.countryId,
      },
    })
    return response;
   }

   catch(error){
    console.log("Error on partner supplements", error);
    throw error;
   }
}

