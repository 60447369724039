import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";

const BASE_URL_CUSTOMER_WALLET = "api/v1/wallets/admin/customer";
const BASE_URL_CUSTOMER_WALLET_TRANSACTIONS = "api/v1/wallets/transactions";

const TRANSACTIONS_TYPE_OPTIONS = [
  {
    key: "CREDIT",
    value: "CREDIT",
    text: "CREDIT",
  },
  {
    key: "DEBIT",
    value: "DEBIT",
    text: "DEBIT",
  },
];

const TRANSACTIONS_CATEGORY_OPTIONS = [
  {
    key: "REFERRAL_CREDIT",
    value: "REFERRAL_CREDIT",
    text: "REFERRAL_CREDIT",
  },
  {
    key: "SIGNUP_BY_REFERRAL_BONUS",
    value: "SIGNUP_BY_REFERRAL_BONUS",
    text: "SIGNUP_BY_REFERRAL_BONUS",
  },
  {
    key: "PURCHASE_ORDER",
    value: "PURCHASE_ORDER",
    text: "PURCHASE_ORDER",
  },
  {
    key: "CASHBACK",
    value: "CASHBACK",
    text: "CASHBACK",
  },
  {
    key: "REFUND",
    value: "REFUND",
    text: "REFUND",
  },
];

class WalletTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      referralWalletSettings: {},
      referralCodeSetting: {},
      wallet: {},
      errors: {},
      editables: {},
      transaction: {},
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        wrap: true,
      },
      {
        name: "Transaction Type",
        selector: "transactionType",
        sortable: true,
        wrap: true,
        width: "150px",
      },
      {
        name: "Transaction Category",
        selector: "transactionCategory",
        sortable: true,
        wrap: true,
        width: "150px",
      },
      {
        name: "Created At",
        selector: "createdAt",
        sortable: true,
        wrap: true,
        format: (transaction) =>
          transaction.createdAt
            ? this.getFormattedTransactionDate(transaction.createdAt)
            : "-",
      },
      {
        name: "Reason/Remark",
        selector: "remarks",
        sortable: true,
        wrap: true,
        width: "150px",
      },
      {
        name: "Amount",
        selector: "amount",
        sortable: true,
      },
      {
        name: "Previous Balance",
        selector: "previousBalance",
        sortable: true,
      },
      {
        name: "Current Balance",
        selector: "currentBalance",
        sortable: true,
      },
      {
        name: "Referrer ID",
        selector: "referrerId",
        sortable: true,
      },
      {
        name: "Referee ID",
        selector: "refereeId",
        sortable: true,
      },
      {
        name: "OrderID",
        selector: "orderId",
      },
    ];
  }

  componentDidMount() {
    if (this.props.customerId) {
      this.fetchCustomerWallet();
    }
  }

  getFormattedTransactionDate = (transactionDate) => {
    const date = moment(transactionDate).format("MMM DD, YYYY");
    const time = moment(transactionDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  fetchCustomerWallet = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(
        `${BASE_URL_CUSTOMER_WALLET}/${this.props.customerId}`
      );
      let referralWalletSettings = response && response.data;
      let { referralCodeSetting, wallet } = referralWalletSettings;
      this.setState({ referralWalletSettings, referralCodeSetting, wallet });
      this.hideLoading();
    } catch (error) {
      console.log(
        "Error in fetching referral wallet from url:",
        BASE_URL_CUSTOMER_WALLET
      );
      console.log("Error in fetching referral wallet:", error.message);
      this.showErrorNotification(
        "Error in fetching referral wallet",
        error.message
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["amount", "orderId", "referrerId"];
    const transaction = { ...this.state.transaction };
    if (numberInputs.includes(input.name))
      transaction[input.name] = input.value ? Number(input.value) : null;
    else transaction[input.name] = input.value;
    this.setState({ transaction });
  };

  handleSaveTransaction = async () => {
    const { transaction, wallet } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const response = await this.saveTransaction(transaction);
      let newTransaction = response && response.data;
      this.fetchCustomerWallet();
      this.showSuccessNotification("Transaction created Successfully");
      this.hideAddNewTransactionModal();
    } catch (error) {
      const transactionErrorMessage = error?.response?.data?.message;
      this.showErrorNotification(
        "Some error occurred: " + transactionErrorMessage
      );
    } finally {
      this.hideLoading();
    }
  };

  saveTransaction = async (transaction) => {
    const payload = { ...transaction };
    payload["customerId"] = this.props.customerId;

    return await PhoenixAPI.post(
      `${BASE_URL_CUSTOMER_WALLET_TRANSACTIONS}`,
      payload
    );
  };

  validate = () => {
    let { transaction = {} } = this.state;
    const errors = {};
    const { transactionType, remarks, amount, transactionCategory } =
      transaction;
    if (!transactionType || transactionType === "") {
      errors.transactionType = "Transaction is a required field!";
    }
    if (!transactionCategory || transactionCategory === "") {
      errors.transactionCategory = "Transaction Category is a required field!";
    }
    if (!remarks || remarks === "") {
      errors.remarks = "Reason/Remark is a required field!";
    }
    if (!amount || amount === "") {
      errors.amount = "Amount is a required field!";
    } else if (Number.isNaN(Number(amount))) {
      errors.amount = "Enter a Valid Amount";
    } else if (Number(amount) < 0) {
      errors.amount = "Enter a Valid Amount";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  renderPencilIcon = (fieldName) => {
    return (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  handleAddNewTransaction = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      transaction: {},
    });
  };
  hideAddNewTransactionModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  render() {
    const {
      referralWalletSettings,
      referralCodeSetting,
      wallet,
      errors,
      editables,
      transaction,
    } = this.state;
    const isEditable = this.props.isEditable ?? true;

    const ReferralSettingsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="referralCode">Referral Code</label>
            <input
              value={
                (referralWalletSettings &&
                  referralWalletSettings?.referralCode) ||
                ""
              }
              type="text"
              id="referralCode"
              name="referralCode"
              disabled={true}
              className={"form-control py-2"}
            />
            {/* <span className="help-block">{errors.country}</span> */}
          </div>
          <div className="form-group col">
            <label htmlFor="codeValue">Code Value</label>
            <input
              value={
                (referralCodeSetting && referralCodeSetting?.codeValue) || ""
              }
              type="number"
              id="codeValue"
              name="codeValue"
              disabled={true}
              className={"form-control py-2"}
            />
            {/* <span className="help-block">{errors.currency}</span> */}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="successfulReferrals">Successful Referrals</label>
            <input
              value={referralWalletSettings.successfulReferrals ?? ""}
              type="number"
              id="successfulReferrals"
              name="successfulReferrals"
              disabled={true}
              className={"form-control py-2"}
            />
            {/* <span className="help-block">{errors.country}</span> */}
          </div>
        </div>
        <div className="form-content">
          <span>Wallet Settings</span>
        </div>
        <div className="constainer mt-4">
          <div className="row">
            <div className="form-group col">
              <label htmlFor="walletAmount">Wallet Amount *</label>
              <input
                value={wallet.balance ?? ""}
                type="number"
                id="walletAmount"
                name="walletAmount"
                disabled={true}
                className={"form-control py-2"}
              />
              {/* <span className="help-block">{errors.country}</span> */}
            </div>
            <div className="form-group col">
              <label htmlFor="currency">Currency</label>
              <input
                value={(wallet && wallet?.country?.currency) || ""}
                type="text"
                id="currency"
                name="currency"
                disabled={true}
                className={"form-control py-2"}
              />
              {/* <span className="help-block">{errors.currency}</span> */}
            </div>
          </div>
        </div>
        <div className="form-content">
          <span>Wallet Ledger</span>
        </div>
      </div>
    );

    const walletTransactionsForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
              <label htmlFor="transactionType">Transaction Type *</label>
              <div className="input-group">
                <Dropdown
                  value={transaction?.transaction}
                  onChange={(event, value) => {
                    this.setState({
                      transaction: {
                        ...transaction,
                        transactionType: value.value,
                      },
                    });
                  }}
                  id="transactionType"
                  name="transactionType"
                  placeholder="Enter Transaction"
                  search
                  selection
                  options={TRANSACTIONS_TYPE_OPTIONS}
                  disabled={!editables.transactionType}
                  className={
                    "form-control" +
                    (errors.transactionType ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("transactionType")}
              </div>
              <span className="help-block">{errors.transactionType}</span>
            </div>

            <div className="form-group col">
              <label htmlFor="transactionCategory">
                Transaction Category *
              </label>
              <div className="input-group">
                <Dropdown
                  value={transaction?.transactionCategory}
                  onChange={(event, value) => {
                    this.setState({
                      transaction: {
                        ...transaction,
                        transactionCategory: value.value,
                      },
                    });
                  }}
                  id="transactionCategory"
                  name="transactionCategory"
                  placeholder="Enter Transaction"
                  search
                  selection
                  options={TRANSACTIONS_CATEGORY_OPTIONS}
                  disabled={!editables.transactionCategory}
                  className={
                    "form-control" +
                    (errors.transactionCategory ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("transactionCategory")}
              </div>
              <span className="help-block">{errors.transactionCategory}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="remarks">Reason/Remarks *</label>
              <div className="input-group">
                <textarea
                  value={transaction?.remarks || ""}
                  onChange={this.handleChange}
                  id="remarks"
                  name="remarks"
                  type="text"
                  placeholder="Enter Reason/Remarks"
                  className={
                    "form-control py-2" + (errors.remarks ? " has-error" : "")
                  }
                  disabled={!editables.remarks}
                ></textarea>
                {this.renderPencilIcon("remarks")}
              </div>
              <span className="help-block">{errors.remarks}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="amount">Amount *</label>
              <div className="input-group">
                <input
                  value={transaction.amount ?? ""}
                  onChange={this.handleChange}
                  id="amount"
                  name="amount"
                  type="number"
                  placeholder="Enter Amount"
                  className={
                    "form-control py-2" + (errors.amount ? " has-error" : "")
                  }
                  disabled={!editables.amount}
                />
                {this.renderPencilIcon("amount")}
              </div>
              <span className="help-block">{errors.amount}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="referrerId">Person 2 UserID</label>
              <div className="input-group">
                <input
                  value={transaction.referrerId ?? ""}
                  onChange={this.handleChange}
                  id="referrerId"
                  name="referrerId"
                  type="number"
                  placeholder="Enter Person 2 USER ID"
                  className={
                    "form-control py-2" +
                    (errors.referrerId ? " has-error" : "")
                  }
                  disabled={!editables.referrerId}
                />
                {this.renderPencilIcon("referrerId")}
              </div>
              <span className="help-block">{errors.referrerId}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="orderId">orderID</label>
              <div className="input-group">
                <input
                  value={transaction.orderId ?? ""}
                  onChange={this.handleChange}
                  id="orderId"
                  name="orderId"
                  type="number"
                  placeholder="Enter Order ID"
                  className={
                    "form-control py-2" + (errors.orderId ? " has-error" : "")
                  }
                  disabled={!editables.orderId}
                />
                {this.renderPencilIcon("orderId")}
              </div>
              <span className="help-block">{errors.orderId}</span>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div>{ReferralSettingsForm}</div>
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={wallet?.transactions || []}
                defaultSortField="id"
                pagination
                responsive
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewTransaction}
                className="btn btn-success mt-1"
                disabled={!isEditable}
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddNewTransactionModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Add Transaction"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{walletTransactionsForm}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.handleSaveTransaction}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletTransactions);
