import React from "react";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { formatDate } from "utils/commons";

function extract(text, metric) {
  const h = text ? text.toString().replace(/[^\d.]/g, "") : "";
  return h + " " + metric;
}

function getClientHeight(height, heightMetric) {
  return extract(height, heightMetric);
}

function getClientWeight(weight, weightMetric) {
  return extract(weight, weightMetric);
}

function getClientLocation(order) {
  const address = getClientAddress(order);
  if (address) {
    const { city, country } = address;
    let location = city || "";
    if (country) location = location ? location + ", " + country : country;
    return location;
  }
  return "";
}

function calculateAge(dateOfBirth) {
  if (!dateOfBirth) return "";

  try {
    const diff = moment().diff(dateOfBirth);
    const ageInYears = moment.duration(diff).years();
    return `${ageInYears} ${ageInYears > 1 ? "Years" : "Year"}`;
  } catch (error) {
    console.log("calculateAge:: Error on calculating age!", { dateOfBirth });
    return "";
  }
}

function getClientAddress(order) {
  if (order.address) {
    return order?.user?.user_address?.find((it) => it.id === order.address);
  } else if (
    order.user &&
    order.user.user_address &&
    order.user.user_address.length > 0
  )
    return order.user.user_address.length === 1
      ? order.user.user_address[0]
      : order.user.user_address.find((it) => it.default_address);
  else return null;
}

function getGender(genderType) {
  switch (genderType) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default: {
      console.log(`Unknoen gender type: ${genderType}`);
      return "";
    }
  }
}

function ClientDetailsCard({ order }) {
  const clientDetails = order ? order.user || {} : {};
  const clientProfile = clientDetails ? clientDetails.userprofile || {} : {};
  const { dependent_id, member, longevity_percentile, longevity_score, country_id , is_longevity_expired,previousConsultationDate, nutritionist={},longevityCategoryId} = order ?? {};
  let nutritionistName=nutritionist?.username ?? "";
  const url=`/dashboard/customer-longevity-score/${clientDetails.id}/${country_id}/${longevityCategoryId}`;
  const dependentLongevityUrl=`/dashboard/dependent-longevity-score/${clientDetails.id}/${country_id}/${longevityCategoryId}/${dependent_id}`;
  if (dependent_id) {
    return (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Client Details</div>
          <div className="description">
            <table className="ui celled table">
              <tbody>
                <tr>
                  <td data-label="Id">Member ID</td>
                  <td data-label="id">{member?.id || ""}</td>
                </tr>
                <tr>
                  <td data-label="Name">Name</td>
                  <td data-label="full_name">
                    {getMemberFullName(member)}
                  </td>
                </tr>
                <tr>
                  <td data-label="Email">Email</td>
                  <td data-label="email">{clientDetails.email || ""}</td>
                </tr>
                <tr>
                  <td data-label="Name">Relation</td>
                  <td data-label="relation">
                    {member?.relation ?? ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Date of Birth</td>
                  <td data-label="dob">
                    {member.dob ? formatDate(member.dob) : ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Age</td>
                  <td data-label="dob">
                    {member.dob ? calculateAge(member.dob) : ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Gender</td>
                  <td data-label="gender">
                    {getGender(member?.gender)}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Location</td>
                  <td data-label="location">{getClientLocation(order)}</td>
                </tr>
                 <tr>
                 {longevity_score?(<><td data-label="Name">Longevity Score</td><td data-label="lonevityscore"> 
                  <NavLink  to={dependentLongevityUrl} target="_blank">
                   Longevity Score 
                 </NavLink> </td></>) // removing in case of active orders of coach 
                  :(<></>)}
                </tr>
                {longevity_percentile?
                <tr>
                  <td data-label="Name"> 
                   Longevity Percentile
                  </td>
                  <td data-label="longevity_percentile">{longevity_percentile}%</td>
                </tr>:<></>}
                <tr>
                <td data-label="Name">Last Consultation Date</td>
                <td data-label="location">{previousConsultationDate?formatDate(previousConsultationDate):"N/A"}</td>
                  </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%" }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Client Details</div>
          <div className="description">
            <table className="ui celled table">
              <tbody>
                <tr>
                  <td data-label="Id">ID</td>
                  <td data-label="id">{clientDetails.id || ""}</td>
                </tr>
                <tr>
                  <td data-label="Name">Name</td>
                  <td data-label="full_name">
                    {clientDetails.full_name || ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Email">Email</td>
                  <td data-label="email">{clientDetails.email || ""}</td>
                </tr>
                <tr>
                  <td data-label="Name">Date of Birth</td>
                  <td data-label="dob">
                    {clientProfile.dob ? formatDate(clientProfile.dob) : ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Age</td>
                  <td data-label="dob">
                    {clientProfile.dob ? calculateAge(clientProfile.dob) : ""}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Height</td>
                  <td data-label="height">
                    {getClientHeight(
                      clientProfile.height,
                      clientProfile.height_metric
                    )}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Weight</td>
                  <td data-label="weight">
                    {getClientWeight(
                      clientProfile.weight,
                      clientProfile.weight_metric
                    )}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Gender</td>
                  <td data-label="gender">
                    {getGender(clientProfile?.gender)}
                  </td>
                </tr>
                <tr>
                  <td data-label="Name">Location</td>
                  <td data-label="location">{getClientLocation(order)}</td>
                </tr>
                <tr>
                  <td data-label="Name"> 
                  Feedback Done By
                  </td>
                  <td data-label="nutritionistName">{nutritionistName}</td>
                </tr>
                <tr> 
                {longevity_score?(<><td data-label="Name">Longevity Score</td><td data-label="lonevityscore"> 
                  <NavLink  to={url} target="_blank">
                   Longevity Score 
                 </NavLink> </td></>) //removing null case of not showing in active order page
                 :<></>}
                </tr>

               { longevity_percentile?<tr>
                  <td data-label="Name"> 
                  
                  Longevity Percentile
                  </td>
                  <td data-label="longevity_percentile">{longevity_percentile}%</td>

                </tr>:<></>}
                <tr>
                  <td data-label="Name">Last Consultation Date </td>
                  <td data-label="last_consultation_date">{previousConsultationDate?formatDate(previousConsultationDate):"N/A"}</td>
                </tr>

                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function getMemberFullName(member) {
  const { first_name, last_name } = member ?? "";
  return `${first_name ?? ""} ${last_name ?? ""}`.trim(); 
}

export default ClientDetailsCard;
