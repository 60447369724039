import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId, getUserType} from "./UserService";


export const trackLocation=async(currentLocation)=>{
    const userId= getUserId();
    const userType=getUserType()
    if(userType==="homeCareProfessional"){
        console.log("gertgetUserType", userType)
        const {lat,lng}= currentLocation || {}
        
        let url=`https://maps.google.com/maps?q=${lat},${lng}`
        try{
            const res= await PhoenixAPI.patch(`nurse/location/${userId}/true?liveLocation=${url}`)
            return res
        }
        catch(error){
         console.log("Error in saving live location", error.message)
        }
    }
    else return
 

}