import React from 'react'

function AccessDenied() {
  return (
    <div>
        <h1>Access Denied</h1>
        <h3>You dont have permission to view this page.</h3>
    </div>
  )
}

export default AccessDenied