import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ReactDragListView from 'react-drag-listview/lib/index.js';
import Drag from "@material-ui/icons/DragIndicatorOutlined"

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class SurveyOptions extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "optionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Option is required."
      },
      {
        field: "optionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Option is required."
      }
    ]);

    this.state = {
      options: null,
      isLoaded: false,
      loadError: "",
      optionEn: "",
      optionAr: "",
      editId: null,
      questionId: this.props.match.params.question,
      surveyType: this.props.match.params.type,
      formHeading: "Create Option",
      questionName: "Survey Question",
      validation: this.validator.valid(),

      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active"
    };

    this.columns = [
      {
        name: "",
        cell: (row) =>
          // <a className="drag-handle" href="#">Drag</a>
          <Drag className="drag-handle"></Drag>,
        width: "50px"
      },
      {
        name: "Option",
        selector: "option",
        sortable: true,
        wrap: true
      },
      {
        name: "Option (Arabic)",
        selector: "option_ar",
        sortable: true,
        wrap: true
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: row => (
          <>
            {row.is_active ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
                <div className="badge badge-warning">
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
                </div>
              )}
          </>
        )
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: row => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="">
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item
                onClick={() => this.editOption(row.index, row.id)}
              >
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.is_active ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                  <ActionBar.Item
                    className="success"
                    onClick={() => this.showConfirm(row)}
                  >
                    <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                  </ActionBar.Item>
                )}
            </ActionBar.Menu>
          </ActionBar>
        )
      }
    ];

    this.submitted = false;
    this.finalList = [];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.updateRank = this.updateRank.bind(this);
    this.setFinalList = this.setFinalList.bind(this);

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const results = [...that.finalList];
        const item = results.splice(fromIndex, 1)[0];
        results.splice(toIndex, 0, item);
        if (that.state.searchApplied) that.setState({
          results: results
        });
        else {
          if (that.state.showItems === "Active") that.setState({
            activeList: results
          });
          else if (that.state.showItems === "Archived") that.setState({
            archivedList: results
          });
          else that.setState({
            options: results
          });
        }
        that.updateRank();
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  loadData() {
    this.loadOptions();
  }

  loadOptions() {
    let url;
    const { searchApplied, searchTerm, questionId, surveyType } = this.state;

    if (surveyType === "M")
      url = "medlife-medical-profile-questions/" + questionId + "/";
    else if (surveyType === "L")
      url = "medlife-lifestyle-profile-questions/" + questionId + "/";
    else this.props.history.push("/dashboard/home");

    this.props.toggleLoading({
      isLoading: true
    });

    API.get(url)
      .then(response => {
        let newList = response.data.option_question;
        newList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        const activeList = filterList(newList, "active");
        activeList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        const archivedList = filterList(newList, "archived");
        archivedList.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)

        this.setState({
          options: newList,
          activeList,
          archivedList,
          isLoaded: true,
          questionName: response.data.question
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching questions", error);
        if (error.response && error.response.status === "404")
          this.props.history.push("/dashboard/home");

        this.props.toggleLoading({
          isLoading: false
        });
        this.setState({
          loadError: "Some error has occured. Please try again"
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("/medlife-option/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });
        this.clearForm();
        this.loadData();
      })
      .catch(error => {
        console.log("Error on updating", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  editOption(index, id) {
    let options = this.state.options;

    this.setState({
      formHeading: "Edit Option",
      optionEn: options[index]["option"],
      optionAr: options[index]["option_ar"],
      editId: id
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Option";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Option successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Option";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Option successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Option",
      optionEn: "",
      optionAr: "",
      editId: null,
      validation: this.validator.valid()
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { optionEn, optionAr, questionId, editId } = this.state;

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });
      if (editId !== null) {
        //edit
        API.put("medlife-option/" + editId + "/", {
          option: optionEn,
          option_ar: optionAr,
          question: questionId
        })
          .then(response => {
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Option successfully updated.",
              successMessage: true,
              showNotification: true
            });
            this.loadData();
          })
          .catch(error => {
            console.log("Error on updating option", error);
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      } else {
        API.post("medlife-option/", {
          option: optionEn,
          option_ar: optionAr,
          question: questionId
        })
          .then(response => {
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Option successfully created.",
              successMessage: true,
              showNotification: true
            });
            this.loadData();
          })
          .catch(error => {
            console.log("Error on creating option", error);
            this.props.toggleLoading({
              isLoading: false
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.options;

    let newList = [];

    for (let item of list) {
      if (
        item.option.toLowerCase().indexOf(newValue) !== -1 ||
        item.option_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }
  updateRank() {
    const { searchApplied, results, activeList, archivedList, options, showItems } = this.state;
    var data = [];
    if (searchApplied) {
      data = results;
    }
    else {
      if (showItems === "Active") data = activeList
      else if (showItems === "Archived") data = archivedList
      else data = options
    }
    var rank = {};
    data.map((val, index) => {
      rank[val.id] = index + 1;
    })
    API.post("set-medlife-options-rank/",
      {
        "question": this.state.questionId,
        "ranks": rank
      })
      .then(response => {
        this.props.showNotificationMessage({
          notificationMessage: "Slider Updated successfully.",
          successMessage: true,
          showNotification: true,
          isLoading: false,
        });
      })
      .catch(error => {
        console.log("Error on updating rank", error);
        this.setState({
          error: "Some error occured. Please try again",
          loading: false
        });
      })
  }

  setFinalList() {
    const { searchApplied, showItems, activeList, archivedList, options, results } = this.state;
    if (searchApplied) this.finalList = results;
    else {
      if (showItems === "Active") this.finalList = activeList;
      else if (showItems === "Archived") this.finalList = archivedList;
      else this.finalList = options;
    }
  }

  render() {
    let form;

    const {
      options,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      this.setFinalList();
    }

    form = (
      <>
        <div className="form-group mb-3">
          <label>Option (English) *</label>
          <input
            type="text"
            name="optionEn"
            className={
              "form-control " + (validation.optionEn.isInvalid && "has-error")
            }
            placeholder="Option in English"
            onChange={this.handleChange}
            value={this.state.optionEn}
          ></input>
          <span className="help-block">{validation.optionEn.message}</span>
        </div>
        <div className="form-group mb-3">
          <label>Option (Arabic) *</label>
          <input
            type="text"
            name="optionAr"
            className={
              "form-control input-arabic " +
              (validation.optionAr.isInvalid && "has-error")
            }
            placeholder="Option in Arabic"
            onChange={this.handleChange}
            value={this.state.optionAr}
          ></input>
          <span className="help-block">{validation.optionAr.message}</span>
        </div>
      </>
    );

    return (
      <div className="options">
        <div className="page-header">
          <h5 className="mb-4">{this.state.questionName}</h5>
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>

            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>
        <Card>
          <ReactDragListView {...this.dragProps}>
            <DataTable
              highlightOnHover
              columns={this.columns}
              data={this.finalList}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + resultCount + " results in '" + showItems + "'"}
                  </p>
                ) : (
                    ""
                  )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
              }
            />
          </ReactDragListView>
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={e => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
                <button
                  onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                  className="btn button btn-success"
                >
                  Restore
                </button>
              )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOptions);
