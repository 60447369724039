import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectBundles.propTypes = {
  countryId: PropTypes.number.isRequired,
  selectedBundleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onBundleSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SelectBundles(props) {
  const {
    countryId,
    selectedBundleIds = [],
    onBundleSelect = (f) => f,
    isDisabled = false,
    couponType,
  } = props;

  const [bundles, setBundles] = useState([]);
  const bundleMapper = (bundle) => ({
    id: bundle.id,
    internalName: bundle.internalName,
    status: bundle.status,
  });

  useEffect(() => {
    const url = "api/v1/bundles/";
    const fetchBundles = async () => {
      try {
        const { data: bundles } = await PhoenixAPI.get(url);
        if(couponType=="couponExclude"){
          setBundles(
            bundles
              .filter((bundle) => bundle.country === countryId ).map(bundleMapper));
        }
        else{
        setBundles(
          bundles
            .filter((bundle) => bundle.country === countryId && !bundle.isCouponExcluded)
            .map(bundleMapper)
        );
      }
      } catch (error) {
        console.log("Error on fetching bundles", error);
      }
    };
    fetchBundles();
  }, [countryId]);

  const findBundle = (bundleId) => {
    return bundles.find((bundle) => bundle.id === bundleId);
  };

  const [activeBundles, inactiveBundles] = _.partition(
    bundles,
    (it) => it.status === "Active" 
  );
  activeBundles.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  inactiveBundles.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  const options = [
    {
      label: "Active",
      options: activeBundles.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveBundles.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedBundles = selectedBundleIds.map(findBundle).map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedBundleIds && selectedBundleIds.join(",")
      }`}
      value={selectedBundles}
      onChange={onBundleSelect}
      options={options}
      styles={selectStyles}
      // placeholder="Select Bundles"
      menuPlacement="top"
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (bundle) => {
  if (!bundle) return;
  return {
    key: bundle.id,
    value: bundle.id,
    label: bundle.internalName,
  };
};

export default SelectBundles;
