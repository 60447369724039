export const paymentStatus = Object.freeze({
  COLLECTED_BY_CASH: {
    name: "Collected by Cash",
    value: "Collected by Cash",
    position: 1,
  },
  COLLECTED_BY_CARD: {
    name: "Collected by Card",
    value: "Collected by Card",
    position: 2,
  },
  // CLIENT_PAID_TO_VALEO: {
  //   name: "Client paid to Valeo",
  //   value: "Client paid to Valeo",
  //   position: 3,
  // }
});

export const paymentStatusList = [
  paymentStatus.COLLECTED_BY_CASH,
  paymentStatus.COLLECTED_BY_CARD,
];

export function findPaymentStatusByValue(value) {
  if (!value) return null;

  for (const key of Object.keys(paymentStatus)) {
    const status = paymentStatus[key];
    if (value.toLowerCase() === status.value.toLowerCase()) {
      return status;
    }
  }
  return null;
}
