import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import SpellCheckIcon from "@material-ui/icons/Spellcheck";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import Status from "components/Common/Status";
import { Modal } from "react-bootstrap";

const TWO_MEGA_BYTE = 2_097_152;
class ManageOptionsForm extends Component {
  constructor(props) {
    super(props);

    const { options = [], onOptionsUpdated = (f) => f } = this.props;

    this.onOptionsUpdated = onOptionsUpdated;

    this.state = {
      options,
      option: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Option (English)",
        selector: "optionEnglish",
        sortable: true,
        wrap: true,
      },
      {
        name: "Option (Arabic)",
        selector: "optionArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (option) => (
          <p style={{ textAlign: "right" }}>{option.optionArabic}</p>
        ),
      },
      {
        name: "Dead End?",
        selector: "isDeadEnd",
        sortable: true,
        center: true,
        width: "70px",
        maxWidth: "100px",
        format: (option) => (option.isDeadEnd ? "Yes" : "No"),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (option) => <Status isActive={option.status === "Active"} />,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (question) => this.renderActionBar(question),
      },
    ];

    this.columnsWithEnableFreeText = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Option (English)",
        selector: "optionEnglish",
        sortable: true,
        wrap: true,
      },
      {
        name: "Option (Arabic)",
        selector: "optionArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (option) => (
          <p style={{ textAlign: "right" }}>{option.optionArabic}</p>
        ),
      },
      {
        name: "Enable Free Text",
        selector: "enableFreeText",
        sortable: true,
        center: true,
        width: "80px",
        maxWidth: "100px",
        format: (option) => (option.enableFreeText ? "Yes" : "No"),
      },
      {
        name: "Dead End?",
        selector: "isDeadEnd",
        sortable: true,
        center: true,
        width: "70px",
        maxWidth: "100px",
        format: (option) => (option.isDeadEnd ? "Yes" : "No"),
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (option) => <Status isActive={option.status === "Active"} />,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (question) => this.renderActionBar(question),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      option: {},
    });
  };

  handleSelectOptionImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      let { option } = this.state && this.state;

      option.image = image;
      this.setState({ option });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      let { option } = this.state && this.state;

      option["image_src"] = [reader.result];

      this.setState({ option });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const option = { ...this.state.option };
    option[input.name] = input.value;
    this.setState({ option });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { options = [] } = this.state;
    const option = options.find((it) => it.id === id);
    if (!option) return;

    if (typeof option.image == "string") option.image_src = option.image;
    this.setState({ option }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleToggleFreeText = (id) => {
    const { options = [] } = this.state;
    const optionToChange = options.find((it) => it.id === id);
    if (!optionToChange) return;

    optionToChange.enableFreeText = optionToChange.enableFreeText
      ? false
      : true;

    const newOptions = options.map((it) => {
      if (it.id === id) return { ...optionToChange };
      return optionToChange.enableFreeText
        ? { ...it, enableFreeText: false }
        : it;
    });
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  handleToggleDeadEnd = (id) => {
    const { options = [] } = this.state;
    const newOptions = options.map((it) =>
      it.id === id ? { ...it, isDeadEnd: it.isDeadEnd ? false : true } : it
    );
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  handleDelete = (id) => this.deleteOption(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { option = {} } = this.state;
    if (option.id === null || option.id === undefined) this.createOption();
    else this.updateOption(option.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { optionEnglish, optionArabic, image } = this.state.option;

    if (!optionEnglish || optionEnglish.trim() === "")
      errors.optionEnglish = "Option (English) cannot be blank";
    else if (optionEnglish && optionEnglish.length > 1000)
      errors.optionEnglish = "Option (English) is limited to a 1000 characters";

    if (!optionArabic || optionArabic.trim() === "")
      errors.optionArabic = "Option (Arabic) cannot be blank";
    else if (optionArabic && optionArabic.length > 1000)
      errors.optionArabic = "Option (Arabic) is limited to a 1000 characters";

    if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createOption = () => {
    const { options = [], option } = this.state;
    const ids = options.length > 0 ? options.map((it) => it.id) : null;
    option.id = ids ? Math.max(...ids) + 1 : 1;
    option.status = "Inactive";
    option.enableFreeText = false;

    const ranks =
      options.length > 0
        ? options.filter((it) => it.rank).map((it) => it.rank)
        : null;
    option.rank = ranks ? Math.max(...ranks) + 1 : 1;
    option.is_new = true;

    this.setState({ options: [...options, option] }, () => {
      this.showSuccessNotification("Option Created Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  updateOption = (id) => {
    let { options = [], option } = this.state;

    let enableFreeText = option.enableFreeText;

    if (enableFreeText) {
      option.image = null;
      delete option.image_src;
      this.setState({ option });
    } else {
      option.enableFreeText = false;
    }

    const newOptions = options.map((it) => (it.id === id ? option : it));
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { options = [] } = this.state;
    const newOptions = options.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Status Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  deleteOption = (id) => {
    const { options = [] } = this.state;
    const optionToDelete = options.find((it) => it.id === id) || {};
    const newOptions = options
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > optionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Deleted Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { options = [], sortColumn, sortDirection } = this.state;
    const sortedOptions = _.sortBy(options, sortColumn, sortDirection);
    const fromOption = { ...sortedOptions[fromIndex] };
    const toOption = { ...sortedOptions[toIndex] };

    if (fromOption && toOption) {
      const tempRank = fromOption.rank;
      fromOption.rank = toOption.rank;
      toOption.rank = tempRank;

      const newOptions = options.map((it) => {
        if (it.id === fromOption.id) return fromOption;
        else if (it.id === toOption.id) return toOption;
        else return it;
      });
      this.setState({ options: newOptions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onOptionsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onOptionsUpdatedLocally = () => {
    this.clearForm();
    this.onOptionsUpdated(
      this.state.options.map((option) => {
        const o = { ...option };
        if (o.is_new) {
          delete o.id;
          delete o.is_new;
        }
        return o;
      })
    );
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  renderActionBar = (option) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(option.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(option.id)}>
          {option.status === "Active" ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {option.status === "Active" ? "Inactive" : "Active"}
        </ActionBar.Item>
        {this.shouldEnableFreeTextOptionShown() && (
          <ActionBar.Item onClick={() => this.handleToggleFreeText(option.id)}>
            <SpellCheckIcon />
            {option.enableFreeText ? "Disable Free Text" : "Enable Free text"}
          </ActionBar.Item>
        )}
        <ActionBar.Item onClick={() => this.handleToggleDeadEnd(option.id)}>
          {option.isDeadEnd ? (
            <ArrowForwardOutlinedIcon />
          ) : (
            <CancelOutlinedIcon />
          )}
          {option.isDeadEnd ? "Dead End No" : "Dead End Yes"}
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(question.id)}
          onClick={() =>
            this.setState({ questionToDelete: option.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.option.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  shouldEnableFreeTextOptionShown = () => {
    return (
      this.props.answerType === "SINGLE_SELECT_PLUS_PLAIN_TEXT" ||
      this.props.answerType === "MULTI_SELECT_PLUS_PLAIN_TEXT"
    );
  };

  removeImage = () => {
    let { option } = this.state;
    option["image"] = null;
    option["image_src"] = "";
    this.setState({ option });
  };

  render() {
    const {
      options = [],
      option = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewOption = option.id === null || option.id === undefined;
    const optionId = (option && option.id) || "";
    const enableFreeTextStatus = option && option.enableFreeText ? true : false;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="optionEnglish">Option (English)*</label>
            <div className="input-group">
              <input
                value={(option && option.optionEnglish) || ""}
                onChange={this.handleChange}
                id="optionEnglish"
                name="optionEnglish"
                type="text"
                placeholder="Option in English"
                disabled={!isNewOption && !editables.optionEnglish}
                className={
                  "form-control py-2" +
                  (errors.optionEnglish ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("optionEnglish")}
            </div>
            <span className="help-block">{errors.optionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="optionArabic">Option (Arabic)*</label>
            <div className="input-group">
              <input
                value={(option && option.optionArabic) || ""}
                onChange={this.handleChange}
                id="optionArabic"
                name="optionArabic"
                type="text"
                placeholder="Option in Arabic"
                disabled={!isNewOption && !editables.optionArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.optionArabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("optionArabic")}
            </div>
            <span className="help-block">{errors.optionArabic}</span>
          </div>
        </div>
        {this.shouldEnableFreeTextOptionShown() ? (
          <>
            {enableFreeTextStatus ? (
              ""
            ) : (
              <div className="row">
                <div className="form-group col">
                  <label htmlFor="imageInput">Image</label>
                  <div className="input-group">
                    <input
                      onChange={(e) => this.handleSelectOptionImage(e)}
                      id="imageInput"
                      name="image"
                      type="file"
                      accept="image/*"
                      disabled={!isNewOption && !editables.image}
                      className={
                        "form-control" + (errors.image ? " has-error" : "")
                      }
                    />
                    {this.renderPencilIcon("image")}
                  </div>
                  <span className="help-block">{errors.image}</span>
                </div>
                <div className="form-group col">
                  {option?.image_src && (
                    <div>
                      <label htmlFor="selected_package_image">
                        Selected Option Image
                      </label>
                      <div className="image-area">
                        <img
                          id="selected_package_image"
                          name="selected_package_image"
                          src={option?.image_src}
                          alt="Selected"
                          className="img-thumbnail mx-auto"
                          style={{
                            width: "100%",
                            maxHeight: "200px",
                            objectFit: "cover",
                          }}
                        />
                        <button
                          className="remove-image"
                          style={{ display: "inline" }}
                          onClick={this.removeImage}
                        >
                          x
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="row">
              <div className="form-check col custom-control custom-checkbox ml-3">
                <input
                  value={true}
                  checked={(option && option.enableFreeText) || ""}
                  onChange={(event, value) => {
                    console.log("Enable Free Text", event.target.checked);
                    this.setState({
                      option: {
                        ...option,
                        enableFreeText: event.target.checked,
                      },
                    });
                  }}
                  id="enableFreeText"
                  name="enableFreeText"
                  type="checkbox"
                  className="custom-control-input"
                />
                <label
                  className="custom-control-label"
                  htmlFor="enableFreeText"
                >
                  Enable Free Text
                </label>
              </div>
              <div className="form-check col custom-control custom-checkbox ml-2">
                <input
                  value={true}
                  checked={(option && option.isDeadEnd) || ""}
                  onChange={(event, value) => {
                    this.setState({
                      option: { ...option, isDeadEnd: event.target.checked },
                    });
                  }}
                  id="isDeadEnd"
                  name="isDeadEnd"
                  type="checkbox"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="isDeadEnd">
                  Is Dead End?
                </label>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="imageInput">Image</label>
                <div className="input-group">
                  <input
                    onChange={(e) => this.handleSelectOptionImage(e)}
                    id="imageInput"
                    name="image"
                    type="file"
                    accept="image/*"
                    disabled={!isNewOption && !editables.image}
                    className={
                      "form-control" + (errors.image ? " has-error" : "")
                    }
                  />
                  {this.renderPencilIcon("image")}
                </div>
                <span className="help-block">{errors.image}</span>
              </div>
              <div className="form-group col">
                {option?.image_src && (
                  <>
                    <label htmlFor="selected_package_image">
                      Selected Option Image
                    </label>
                    <div className="image-area">
                      <img
                        id="selected_package_image"
                        name="selected_package_image"
                        src={option.image_src}
                        alt="Selected"
                        className="img-thumbnail mx-auto"
                        style={{
                          width: "100%",
                          maxHeight: "200px",
                          objectFit: "cover",
                        }}
                      />
                      <button
                        className="remove-image"
                        style={{ display: "inline" }}
                        onClick={this.removeImage}
                      >
                        x
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-check col custom-control custom-checkbox ml-3">
                <input
                  value={true}
                  checked={option && option.isDeadEnd}
                  onChange={(event, value) => {
                    this.setState({
                      option: { ...option, isDeadEnd: event.target.checked },
                    });
                  }}
                  id="isDeadEnd"
                  name="isDeadEnd"
                  type="checkbox"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="isDeadEnd">
                  Is Dead End?
                </label>
              </div>
            </div>
          </>
        )}
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={
                    this.shouldEnableFreeTextOptionShown()
                      ? this.columnsWithEnableFreeText
                      : this.columns
                  }
                  data={options || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.showAddEditModal}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {option.id === null || option.id === undefined
                ? "Add Option"
                : "Edit Option"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {option.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the question?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteOption(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ManageOptionsForm.propTypes = {
  answerType: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOptionsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

ManageOptionsForm.defaultProps = {
  options: [],
  onOptionsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageOptionsForm);
