import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
const BASE_URL_NUTRITIONIST_CALENDLY_LINK = "calendly-link";
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
const DEFAULT_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class CoachCalendlyLinks extends Component {
  constructor(props) {
    super(props);

    const { nutritionistId , onCreateCalendly=(f)=>f, links=[]} = this.props;
    this.onCreateCalendly=onCreateCalendly;
    this.state = {
      links,
      link: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "calendly Link",
        selector: "calendlyLink",
        sortable: true,
        center: true,
      },
      {
        name: "Link Subname",
        selector: "eventSubname",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (link) => <Status isActive={link.isActive} />,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        cell: (link) => this.renderActionBar(link.id),
      },
    ];
  }

  componentDidMount() {
    if (this.props.nutritionistId) {
      this.fetchNutritionist();
    } else {
      this.setState({ isNewLink: true });
    }
  }
  fetchNutritionist = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(
        BASE_URL_NUTRITIONIST_CALENDLY_LINK,
        {
          params: { userId: this.props.nutritionistId },
        }
      );
      const data = response && response.data;
      this.setState({ links: data });
      this.hideLoading();
    } catch (error) {
      console.log(
        "Error in fetching Nutritionist from url:",
        BASE_URL_NUTRITIONIST_CALENDLY_LINK
      );
      console.log("Error in fetching  Coach Calendly Links", error.message);
      this.showErrorNotification(
        "Error in fetching Coach Calendly Links",
        error.message
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const link = { ...this.state.link };
    link[input.name] = input.value;
    this.setState({ link });
  };
  updateLink = async (link) => {
    try {
      const payload = { ...link };

      payload["userId"] = this.props.nutritionistId;
      const apiOptions = {
        method: "patch",
        url: `${BASE_URL_NUTRITIONIST_CALENDLY_LINK}/${link.id}`,
        data: payload,
      };

      const response = await PhoenixAPI.request(apiOptions);
      this.fetchNutritionist();
      this.hideAddEditModal();
    } catch (error) {
      this.showErrorNotification(
        "Error in Updating Nutritionist Calendly Link!"
      );
    }
  };

  createLink = async () => {
    const { link } = this.state;
    const { nutritionistId } = this.props;
   
    const payload = { ...link };
    payload["userId"] = nutritionistId;
    let updatedPayload=[]
    updatedPayload.push(payload);
    try {
      const apiOptions = {
        method: "post",
        url: `${BASE_URL_NUTRITIONIST_CALENDLY_LINK}`,
        data: updatedPayload,
      };
      const response = await PhoenixAPI.request(apiOptions);
      this.fetchNutritionist();
      this.hideAddEditModal();
    } catch (error) {
      this.showErrorNotification("Error in Saving Nutritionist Calendly Link!");
    }
  };

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      link: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const link = { ...this.state.link };
    link[input.name] = input.value;
    this.setState({ link });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { links = [] } = this.state;
    const link = links.find((it) => it.id === id);
    if (!link) return;

    this.setState({ link }, () => this.showAddEditModal());
  };

  createCoachCalendlyLink=()=>{
    const {links=[], link}= this.state;
    const ids = links.length > 0 ? links.map((it) => it.id) : null;
    link.id = ids ? Math.max(...ids) + 1 : 1;
    link.is_new = true;
    this.setState({ links: [...links, link] }, () => {
      this.showSuccessNotification ("Coach Calendly Link is Created Successfully!");
      this.onCoachCalendlyLinkUpdatedLocally()
     
    
    });
  }

  updateCoachCalendlyLink=(id)=>{
    const { links = [], link } = this.state;
    const newLinks = links.map((it) => (it.id === id ? link : it));
    this.setState({ links: newLinks }, () => {
      this.showSuccessNotification("Coach Calendly Link is Updated Successfully!");
      this.onCoachCalendlyLinkUpdatedLocally();
    });
  }
  onCoachCalendlyLinkUpdatedLocally=()=>{
    this.clearForm();
    this.onCreateCalendly(this.state.links.map((link)=>{
      const q = {...link };
      if (q.is_new) {
        delete q.id;
        delete q.is_new;
      }
      return q;
    }))
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const {nutritionistId=null} = this.props
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    if(nutritionistId){
  const { link = {} } = this.state;
    if (link.id === null || link.id === undefined) this.createLink();
    else this.updateLink(link);
    }
    else{
      const { link = {} } = this.state;
    if (link.id === null || link.id === undefined) this.createCoachCalendlyLink();
    else this.updateCoachCalendlyLink(link.id);
      this.hideAddEditModal();
    }
    // this.fetchNutritionist();
    // this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { calendlyLink, isNonCalendlyLink, 
      eventSubname, calendlyCoachMail
       } = this.state.link || {};
    
       if (!eventSubname || eventSubname.trim() === "") {
        errors.eventSubname = "Link Name is a required field";
      } else if (eventSubname & (eventSubname.length > 50)) {
        errors.eventSubname = "Link Name can not be more than 50 characters";
      }
  
    if (!calendlyLink || calendlyLink.trim() === "") {
      errors.calendlyLink = "Calendly Link is a required field";
    } else if (calendlyLink & (calendlyLink.length > 255)) {
      errors.calendlyLink = "Calendly Link can not be more than 255 characters";
    }

    if (isNonCalendlyLink) {
      if (calendlyLink.toLowerCase().includes("calendly.com")) {
       
        errors.calendlyLink = "Calendly Link should not contain calendly.com";
      }
    }
    if (!calendlyCoachMail || calendlyCoachMail.trim() === "") {
      errors.calendlyCoachMail = "Coach Mail is a required field";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewQuestion = () => {
    const link = { status: "Inactive" };
    this.setState({ link }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.link.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  render() {
    const { links = [], link = {}, errors, editables, sortColumn } = this.state;

    const isNewLink = link.id === null || link.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label>Link Name *</label>
            <div className="input-group">
              <input
                value={link.eventSubname}
                onChange={(event, value) => {
                  this.setState({
                    link: { ...link, eventSubname: event.target.value },
                  });
                }}
                id="eventSubname"
                name="eventSubname"
                type="input"
                disabled={!isNewLink && !editables.eventSubname}
                className={
                  "form-control" + (errors.eventSubname ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("eventSubname")}
            </div>
            <span className="help-block">{errors.eventSubname}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="calendlyLink">Calendly Link *</label>
            <div className="input-group">
              <textarea
                value={link?.calendlyLink || ""}
                onChange={this.handleChange}
                id="calendlyLink"
                name="calendlyLink"
                type="text"
                placeholder="Enter Calendly Link"
                className={
                  "form-control py-2" +
                  (errors.calendlyLink ? " has-error" : "")
                }
                disabled={!isNewLink && !editables.calendlyLink}
              ></textarea>
              {this.renderPencilIcon("calendlyLink")}
            </div>
            <span className="help-block">{errors.calendlyLink}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="isActive">Is Active*</label>
            <div className="input-group">
              <Dropdown
                value={link?.isActive}
                onChange={(event, value) =>
                  this.setState({
                    link: { ...link, isActive: value.value },
                  })
                }
                id="isActive"
                name="isActive"
                placeholder="Is Active"
                search
                selection
                options={STATUS_OPTIONS || []}
                disabled={!isNewLink && !editables.isActive}
                className={
                  "form-control" + (errors.isActive ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="isDefault">Is Default *</label>
            <div className="input-group">
              <Dropdown
                value={link?.isDefault}
                onChange={(event, value) =>
                  this.setState({
                    link: { ...link, isDefault: value.value },
                  })
                }
                id="isDefault"
                name="isDefault"
                placeholder="isDefault"
                search
                selection
                options={DEFAULT_OPTIONS || []}
                disabled={!isNewLink && !editables.isDefault}
                className={
                  "form-control" + (errors.isDefault ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isDefault")}
            </div>
            <span className="help-block">{errors.isDefault}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <div className="input-group">
              <input
                value={true}
                checked={
                  (link.isNonCalendlyLink && link.isNonCalendlyLink) || false
                }
                onChange={(event, value) => {
                  this.setState({
                    link: { ...link, isNonCalendlyLink: event.target.checked },
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
              &nbsp;&nbsp; is this non calendly link?
            </div>
          </div>
          <div className="form-group col">
            <label>Coach CalendlyMail *</label>
            <div className="input-group">
              <input
                value={link.calendlyCoachMail}
                onChange={(event, value) => {
                  this.setState({
                    link: { ...link, calendlyCoachMail: event.target.value },
                  });
                }}
                id="calendlyCoachMail"
                name="calendlyCoachMail"
                type="input"
                disabled={!isNewLink && !editables.calendlyCoachMail}
                className={
                  "form-control" +
                  (errors.calendlyCoachMail ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("calendlyCoachMail")}
            </div>
            <span className="help-block">{errors.calendlyCoachMail}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={links || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />

              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {link.id === null || link.id === undefined
                ? "Add Calendly Link"
                : "Edit Calendly Link"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {link.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachCalendlyLinks);
