import React, { Component } from "react";
import Search from "components/Search/Search";
import CountryDropdown from "components/Common/CountryDropdown";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectFoodCategory from "./SelectFoodCategory";

const BASE_URL_LIST_OF_FOODS = "admin/get-all-food-items";
const BASE_URL_FOOD_CATEGORIES = "admin/get-all-food-categories";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class ListOfFood extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      foodLists: [],
      food: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      isFoodViewModalVisible: false,
      foodCategories: [],
      errors: {},
      editables: {},
      loadError: "",
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Food Name",
        selector: "foodName",
        sortable: true,
      },
      {
        name: "Food Category",
        selector: "foodCategoryName",
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (food) => <Status isActive={food.isActive} />,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        minWidth: "170px",
        cell: (food) => this.renderViewButton(food.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchListFoods();
    this.fetchFoodCatgories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.LIST_OF_FOOD;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchListFoods = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_LIST_OF_FOODS);
      const foodLists = (response && response.data) || [];
      const newFoodList = foodLists.map((item) => {
        const newItem = {
          country: item.country,
          foodCategory: item.food_category_id,
          foodName: item.food_name,
          foodNameAr: item.food_name_ar,
          isActive: item.is_active,
          id: item.id,
          image: item.image,
          foodCategoryName: item.food_category,
          foodCategoryNameAr: item.food_category_ar,
        };

        return newItem;
      });

      this.setState({ foodLists: newFoodList });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Referral Codes: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchFoodCatgories = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_FOOD_CATEGORIES);
      const foodCategories = (response && response.data) || [];
      this.setState({ foodCategories });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Food Categories: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      food: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewFood(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewFood ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : null;

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const food = { ...this.state.food, image };
      this.setState({ food });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const food = {
        ...this.state.food,
        image_src: [reader.result],
      };
      this.setState({ food });
    }.bind(this);
    reader.readAsDataURL(image);
  };
  handleNewAddFood = () => {
    this.setState({ isNewFood: true }, () => this.showFoodViewModal());
  };

  handleViewFood = (foodId) => {
    const { selectedCountry } = this.state;
    const foodLists = this.state.foodLists ?? [];
    const food = foodLists.find((it) => it.id === foodId);
    if (!food) {
      console.log(`Food with ID ${foodId} is not found!`);
      return;
    }

    food.image_src = food.image;
    food.country = selectedCountry?.id;
    this.setState({ food, isNewFood: false }, () => this.showFoodViewModal());
  };

  showFoodViewModal = () => {
    const { selectedCountry, isNewFood } = this.state;
    if (isNewFood) {
      const food = {
        country: selectedCountry?.id,
        isActive: true,
      };
      this.setState({
        isFoodViewModalVisible: true,
        food,
      });
    }
    this.setState({
      isFoodViewModalVisible: true,
    });
  };

  hideFoodViewModal = () => {
    this.setState({ isFoodViewModalVisible: false });
    this.resetForm();
  };

  handleSaveFood = async () => {
    const { selectedCountry, food } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = food.id !== null && food.id !== undefined;
      const response = isValidId
        ? await this.updateFood(food)
        : await this.saveFood(food);

      this.hideFoodViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Food List Updated Successfully."
          : "Food List Item Created Successfully."
      );
      this.fetchListFoods();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  updateFood = async (food) => {
    try {
      const payload = { ...food };
      delete payload["image_src"];
      delete payload["id"];
      delete payload["foodCategoryName"];
      delete payload["foodCategoryNameAr"];

      if (food.image && typeof food.image !== "string"){
        delete payload["image"];
      } 
      const formData = new FormData();
      formData.append("requestString", JSON.stringify(payload));
      if (food.image && typeof food.image !== "string"){
        formData.append("image", food.image);
      }

      const apiOptions = {
        method: "patch",
        url: `admin/food-item/${food.id}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on updating food list", error);
      throw error;
    }
  };

  saveFood = async (food) => {
    try {
      const payload = { ...food };
      delete payload["image"];
      delete payload["image_src"];
      const formData = new FormData();
      formData.append("requestString", JSON.stringify(payload));
      if (food && food.image){
        formData.append("image", food.image);
      }

      const apiOptions = {
        method: "post",
        url: "admin/add-food-items",
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const response = await PhoenixAPI.request(apiOptions);
      return response;
    } catch (error) {
      console.log("Error on creating other-item", error);
      throw error;
    }
  };

  validate = () => {
    const { food = {} } = this.state;

    const errors = {};

    const { foodName, foodNameAr, foodCategory, isActive } = food;

    if (isActive === null) errors.status = "Status is a required field!";

    if (!foodName || foodName.trim() === "")
      errors.foodName = "Food Name cannot be blank";
    else if (foodName.length > 100)
      errors.foodName = "Food Name (English) is limited to a 100 characters";

    if (!foodNameAr || foodNameAr.trim() === "")
      errors.foodNameAr = "Food Name cannot be blank";
    else if (foodNameAr.length > 100)
      errors.foodNameAr = "Food Name (Arabic) is limited to a 100 characters";

    if (!foodCategory) {
      errors.foodCategory = "Please Select Food Category";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  getFilteredFoodLists = () => {
    const foodLists = this.getFoodListsForCurrentCountry();
    return this.getSearchedFoodLists(foodLists);
  };

  getFoodListsForCurrentCountry = () => {
    const { foodLists, selectedCountry } = this.state;
    return foodLists && selectedCountry
      ? foodLists.filter((food) => food.country === selectedCountry.id)
      : [];
  };

  getSearchedFoodLists = (foodLists) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return foodLists;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return foodLists.filter((food) => {
      const { id, foodCategoryName, foodName } = food;
      return (
        (foodCategoryName &&
          foodCategoryName.toLowerCase().indexOf(newValue) !== -1) ||
        (foodName && foodName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      food,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      errors,
      editables,
      loadError,
      isEditable,
      isNewFood,
      foodCategories,
    } = this.state;
    let { foodLists } = this.state;
    // referralCodes = referralCodes.filter(
    //   (it) => it?.country?.id === selectedCountry?.id
    // );

    const filteredFoodLists = this.getFilteredFoodLists(foodLists);

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="foodName">Food Name (English) *</label>
            <div className="input-group user-input-container">
              <input
                value={food?.foodName || ""}
                onChange={(event) => {
                  this.setState({
                    food: {
                      ...food,
                      foodName: event.target.value,
                    },
                  });
                }}
                id="foodName"
                name="foodName"
                type="text"
                placeholder="Enter Food Name"
                className={
                  "form-control py-2" + (errors.foodName ? " has-error" : "")
                }
                disabled={!isNewFood && !editables.foodName}
              />
              {this.renderPencilIcon("foodName")}
            </div>
            <span className="help-block">{errors.foodName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="foodNameAr">Food Name (Arabic) *</label>
            <div className="input-group user-input-container">
              <input
                value={food?.foodNameAr || ""}
                onChange={(event) => {
                  this.setState({
                    food: {
                      ...food,
                      foodNameAr: event.target.value,
                    },
                  });
                }}
                id="foodNameAr"
                name="foodNameAr"
                type="text"
                placeholder="Enter Food Name"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.foodNameAr ? " has-error" : "")
                }
                disabled={!isNewFood && !editables.foodNameAr}
              />
              {this.renderPencilIcon("foodNameAr")}
            </div>
            <span className="help-block">{errors.foodNameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image </label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewFood && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {food?.image_src && (
              <>
                <label htmlFor="selected_package_image">Image Preview</label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={food.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={selectedCountry?.country_name || ""}
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="foodCategory">Select Food Category *</label>
            <div className="input-group">
              <SelectFoodCategory
                foodCategories={
                  foodCategories
                    ? foodCategories.filter(
                        (it) => it.country === selectedCountry?.id
                      )
                    : []
                }
                selectedFoodCategory={food.foodCategory}
                onSelect={(event) => {
                  this.setState({
                    food: {
                      ...food,
                      foodCategory: event.value,
                    },
                  });
                }}
                disabled={!isNewFood && !editables.foodCategory}
                className={
                  "form-control" + (errors.foodCategory ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("foodCategory")}
            </div>
            <span className="help-block">{errors.foodCategory}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status *</label>
            <div className="input-group">
              <Dropdown
                value={food.isActive}
                onChange={(event, value) => {
                  this.setState({
                    food: {
                      ...food,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewFood && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage=pacakage">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleNewAddFood}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredFoodLists || []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredFoodLists.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isFoodViewModalVisible}
          onHide={this.hideFoodViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="user-title"></div>

              <button
                type="submit"
                onClick={this.handleSaveFood}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_detailsform">
              <Tab eventKey="key_detailsform_" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ListOfFood);
