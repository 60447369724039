import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_UPLOADING_FILE = "/upload-media";

export const UploadMediaFile=async (file)=>{
try{
    const formData = new FormData();

    formData.append("media", file);

    const apiOptions = {
        method: "post",
        url: BASE_URL_UPLOADING_FILE,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
  
      const {data} = await PhoenixAPI.request(apiOptions);
      return data;
}
catch(error){
    console.log("Error on creating other-item", error);
    throw error;
}
}