import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

StandardPackagesDropdown.propTypes = {
  customPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  bloodBiomarkerPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPackage: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

function StandardPackagesDropdown(props) {
  const {
    customPackages = [],
    bloodBiomarkerPackages = [],
    selectedPackage,
    onSelect = (f) => f,
    placeholder = "Select Standard Package...",
  } = props;

  const activeCustomPackages = customPackages.filter(
    (it) => it.status === "Active"
  );
  const inactiveCustomPackages = customPackages.filter(
    (it) => it.status === "Inactive"
  );
  activeCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  inactiveCustomPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const activeBloodBiomarkerPackages = bloodBiomarkerPackages.filter(
    (it) => it.is_active
  );
  const inactiveBloodBiomarkerPackages = bloodBiomarkerPackages.filter(
    (it) => !it.is_active
  );
  activeBloodBiomarkerPackages.sort((first, second) =>
    // compareStrings(first.subscription_name, second.subscription_name, true)
    compareStrings(first.internal_name, second.internal_name, true)
  );
  inactiveBloodBiomarkerPackages.sort((first, second) =>
    // compareStrings(first.subscription_name, second.subscription_name, true)
    compareStrings(first.internal_name, second.internal_name, true)
  );

  const options = [
    {
      label: "Active Custom Packages",
      options: activeCustomPackages.map(createOption),
    },
    {
      label: "Inactive Custom packages",
      options: inactiveCustomPackages.map(createOption),
    },
    {
      label: "Active Blood Biomarker Packages",
      options: activeBloodBiomarkerPackages.map(createOption),
    },
    {
      label: "Inactive Blood Biomarker packages",
      options: inactiveBloodBiomarkerPackages.map(createOption),
    },
  ];
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedPackage && selectedPackage.id}`}
      value={selectedPackage && createOption(selectedPackage)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder={placeholder}
      isDisabled={props.disabled}
    />
  );
}

const createOption = (standardPackage) => {
  return {
    key: standardPackage.id + "" + standardPackage.package_type,
    value: standardPackage.id + ":" + standardPackage.package_type,
    label:
      standardPackage.package_type === "custom" || standardPackage.package_type === "Custom_Package"
        ? standardPackage.name
        // : standardPackage.subscription_name,
        : standardPackage.internal_name,
    package_type: standardPackage.package_type,
  };
};

export default StandardPackagesDropdown;
