import React from "react";
import "./UploadSample.scss";
import { useState } from "react";
import PhoenixAPI from "../../utils/PhoenixAPI";
import PdfPreview from "components/Common/PdfPreview";
import Notification from "./Notification";
import { bookingStatus } from "./bookingStatus";
import { fetchBookings } from "./Services";
import SelectPaymentStatus from "pages/HomeCarePortal/SelectPaymentStatus";
import Select from "react-select";
function UploadSampleDetails({
  userId,
  props,
  user,
  clientDetails = {},
  setIsShowClient,
  setIsShowOrder,
  setOrders = [],
  selectedStartDate,
  selectedEndDate,
}) {
  const [image, setImage] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [inputs, setInputs] = useState({});
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const validTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/svg+xml",
  ];
  const errMsg = "Please upload only images, docs or pdf ";
  const paymentStatusOptions = [
    {
        name: "Collected by Cash",
        value: "Collected by Cash",
        label :"Collected by Cash"
    },
    {
        name: "Collected by Card",
        value: "Collected by Card",
        label:"Collected by Card"
    }
]
  function handleBackButton(data) {
    if (data === "uploadSampleDetails") {
      setIsShowClient(false);
      //setIsShowUploadSample(false);
      setIsShowOrder(true);
    }
  }
  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((prev) => setInputs({ ...prev, [name]: value }));
  }
  const handleTimeDate = (e, inputName) => {
    if (inputName === "date") {
      setSelectedDate(e.target.value);
    } else {
      let time = e.target.value;
      time = time.split(":");
      let hr = time[0] % 12 < 10 ? "0" + (time[0] % 12) : time[0] % 12;
      let min = time[1];
      let timeString = hr + ":" + min;
      setSelectedTime(e.target.value);
    }
  };
  const handleImageChange = (e) => {
    const fileName = e.target.files[0];
    const imageURL = URL.createObjectURL(fileName);
    if (!validTypes.includes(fileName.type)) {
      setIsError(true);
      setMessage(errMsg);
    } else {
      setImageURL(imageURL);
      setImage(fileName);
      setImageFile(fileName);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isError = validate();
    if (isError) {
      setIsError(true);
      setMessage("Invalid fields");
      return;
    }
    const formData = new FormData();
    formData.append("pictureOfSample", image);
    let payload = {
      orderId: clientDetails.id,
      userId: user.id, //nurse id
      deliveryDate: selectedDate,
      deliveredTo: inputs.name,
      temperature: inputs.temperature,
      labReferralCode: inputs.referalcode,
      deliveryTime: selectedTime,
      homrCareId: user.homecare_service_id,
      nurseAppointmentStatus: bookingStatus.COMPLETED.value,
      // professionalId:userId,
      // nurseAppointmentStatus:"",
      // expectedHoursOfWork:"",
      // homeCareId:"",
      // homecarePaymentSt    atus:"",
    };
    payload = JSON.stringify(payload);
    formData.append("orderSample", payload);
    let homecarestatus=clientDetails?.payment_method=="COD" || clientDetails?.payment_method=="COD_MAMOPAY"?inputs?.homecare_payment_status?.value:null;
    
    formData.append("homecarePaymentStatus",homecarestatus);
    try {
      const res = await PhoenixAPI.patch("/order-sample-info", formData);
      if (res.status === 200) {
        setMessage("Details submitted successfully");
        setIsError(false);
        setTimeout(() => {
          window.location.href = "/dashboard/homecare-mobile-portal";
        }, 2000);
        
        // const ordersAssign = await fetchBookings(
        //   selectedStartDate,
        //   selectedEndDate,
        //   user.homecare_service_id
        // );
        // console.log("orders", ordersAssign);
        // setOrders([...ordersAssign]);
        handleBackButton();
      } else {
        setMessage("Error while uploading Details");
        setIsError(true);
      }
    } catch (error) {
      console.log("Error while uploading  details", error);
      setMessage("Error while uploading Details");
      setIsError(true);
    }
  };
  function validate() {
    let isError = false;
    let error = {};
    if (!selectedDate) {
      error.date = "Delivery Date is required";
      isError = true;
    }
    if (!inputs.name) {
      error.name = "Name is required";
      isError = true;
    }
    if (!selectedTime) {
      error.time = "Delivery Time is required";
      isError = true;
    }
    if (!image) {
      error.image = "Image is required";
      isError = true;
    }
    if(clientDetails.payment_method && !(clientDetails.is_mamopay_transaction_completed) && (clientDetails.payment_method=="COD" || clientDetails?.payment_method=="COD_MAMOPAY") ){
      if (!inputs.homecare_payment_status) {
        error.homecarePaymentStatus = "Payment Status is required";
        isError = true;
      }
    }
    

    setError({ ...error });
    return isError;
  }
  const renderFrontIdPreview = () => {
    const fileSource = imageURL;
    if (!fileSource) return null;

    const file = imageFile;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="uploaded-image" src={imageURL} alt="" />
    );

    return <>{preview}</>;
  };

  
  return (
    <>
      <div className="upload-lab-sample-info" >
        <div className="upload-lab-sample-cont">
          <div className="upload-form-home">
            <div className="form-col">
              <div className="del-date">
                <label htmlFor="date" className="label">
                  Delivery Date <span className="star">*</span>
                </label>
                <input
                  className={`${error.date ? "error-border" : ""}`}
                  type="date"
                  name="date"
                  value={selectedDate}
                  onChange={(e) => handleTimeDate(e, "date")}
                />
                {error.date && <div className="error">{error.date}</div>}
              </div>
              <div className={`del-time`}>
                <label htmlFor="" className="label">
                  Delivery Time <span className="star">*</span>
                </label>
                <div className="time-box">
                  <input
                    type="time"
                    name="hour"
                    className={`hr ${error.time ? "error-border" : ""}`}
                    value={selectedTime}
                    onChange={(e) => handleTimeDate(e, "time")}
                  />
                </div>
                {error.time && <div className="error">{error.time}</div>}
              </div>
            </div>
            <div className="form-col">
              <div className="name-receiver-home">
                <label htmlFor="" className="label">
                  Name of the reciever <span className="star">*</span>
                </label>
                <input
                  className={`${error.name ? "error-border" : ""}`}
                  type="text"
                  name="name"
                  placeholder="Enter name"
                  onChange={(e) => handleChange(e)}
                />
                {error.name && <div className="error">{error.name}</div>}
              </div>
            </div>
            <div className="form-col">
              <div className="lab-bar">
                <label htmlFor="">Lab Bar/Referral Code</label>
                <input
                  type="text"
                  name="referalcode"
                  placeholder="Enter code"
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className="lab-bar">
                <label htmlFor="">Temperature</label>
                <input
                  type="text"
                  name="temperature"
                  placeholder="Enter Temperature"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>

            {((clientDetails.payment_method == "COD" ||  clientDetails?.payment_method=="COD_MAMOPAY") && !(clientDetails.is_mamopay_transaction_completed))&& (
              <div>
                
                <div className='payment-status-div'>
                      Payment Status
                      <span className='star'>*</span>
                      </div>
                     <div className=''>
                        <Select
                        key={clientDetails.id}
                        id={`select-payment-status-${clientDetails.id}`}
                        value={inputs?.homecare_payment_status ?? null}
                        placeholder="Payment Status"
                        search
                        selection
                        className={"paymentStatus"}
                        onChange={(selectedStatus) => {
                         
                            setInputs((prev) => setInputs({ ...prev, homecare_payment_status: selectedStatus }))
                        }}
                        options={paymentStatusOptions}
                        />
                         {error.homecarePaymentStatus && <div className='error-homecare-payment'>{error.homecarePaymentStatus}</div>}
                      </div>
                </div>
             
            )}

            <div className="form-col upload-div-main">
              <label htmlFor="" className="label">
                Picture of sample <span className="star">*</span>
              </label>
              <div
                className={`upload-div ${error.image ? "error-border" : ""} `}
              >
                <input
                  accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                  type="file"
                  onChange={(e) => handleImageChange(e)}
                />
                <div className="text">Upload File / Click photo</div>
                {renderFrontIdPreview()}
              </div>
              {error.image && <div className="error">{error.image}</div>}
            </div>
            <div className="save-button" onClick={(e) => handleSubmit(e)}>
              Submit
            </div>
          </div>
        </div>
      </div>
      {message && (
        <Notification
          setMessage={setMessage}
          message={message}
          isError={isError}
        />
      )}
    </>
  );
}

export default UploadSampleDetails;
