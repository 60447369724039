import React from "react";

const LongevityScore = ({ score = 0 , expire = ""}) => {
  const circumference = 2 * Math.PI * 45;
  const progress = (score / 100) * circumference;
  
  let fillColor;
  if(expire){
    fillColor="grey"
  }
  else{
  if (score < 34) {
    fillColor = "red";
  } else if (score < 75) {
    fillColor = "yellow";
  } else {
    fillColor = "green";
  }
}
  return (
    <div
      style={{
        position: "relative",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <svg
        viewBox="0 0 100 100"
        width={expire?"70px":"50px"}
        height={expire?"70px":"50px"}
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#f3f3f3"
          strokeWidth="10"
        />
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke={fillColor}
          strokeWidth="10"
          strokeDasharray={`${progress} ${circumference}`}
          strokeLinecap="round"
          transform="rotate(-90 50 50)"
        />
      </svg>
      <div
        style={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          fontWeight: "bold",
          fontSize: "16px",
          textAlign:"center",
        }}
      >
        {score} <br/>{expire?"(E)":""}
      </div>
    </div>
  );
};

export default LongevityScore;
