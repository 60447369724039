import React, { Component } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import picture from "assets/img/image.svg";
import FormValidator from "utils/FormValidator";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DataTable from "react-data-table-component";
import EditIcon from "@material-ui/icons/EditOutlined"
import Drag from "@material-ui/icons/DragIndicatorOutlined"
import API from "utils/API";
import ReactDragListView from 'react-drag-listview/lib/index.js';

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class Slider extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: "imgSrc",
                method: "isEmpty",
                validWhen: false,
                message: "Image is required",
            },
        ]);

        this.state = {
            imgSrc: "",
            image: "",
            validation: this.validator.valid(),
            showModal: false,
            data: [],
            disabled: false,
            loading: false,
            editId: null,

        };
        this.columns = [
            {
                name: "",
                cell: (row) =>
                    // <a className="drag-handle" href="#">Drag</a>
                    <Drag className="drag-handle"></Drag>,
                width: "50px"
            },
            {
                name: "Id",
                selector: "id",
                center: true,
                width: "100px"
            },
            {
                name: "Image",
                cell: (row) => {
                    return (
                        <img className="table-img" src={row.image}></img>
                    )
                },
                center: true
            },
            {
                name: "Actions",
                cell: (row) => {
                    return (
                        <div>
                            <button className="delete-btn" onClick={() => { this.handleDeleteImage(row.id) }}><DeleteIcon></DeleteIcon></button>
                            <button className="delete-btn" onClick={() => { this.handleEditData(row) }}><EditIcon></EditIcon></button>
                        </div>
                    )
                },
                center: true,
                width: "200px"
            },
        ]
        const that = this;
        this.dragProps = {
            onDragEnd(fromIndex, toIndex) {
                const data = [...that.state.data];
                const item = data.splice(fromIndex, 1)[0];
                data.splice(toIndex, 0, item);
                that.setState({
                    data
                });
                that.updateRank();
            },
            nodeSelector: ".rdt_TableRow",
            handleSelector: ".drag-handle",
            enableScroll: true
        };
        this.selectImage = this.selectImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleDeleteImage = this.handleDeleteImage.bind(this);
        this.handleEditData = this.handleEditData.bind(this);
        this.updateRank = this.updateRank.bind(this);
        this.submitted = false;
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        API.get("hero-slider/")
            .then(response => {
                var data = response.data;
                data.sort((a, b) => (a.position_value > b.position_value) ? 1 : -1)
                if (data) {
                    this.setState({
                        data: data,
                    })
                }
                if (data.length == 10) {
                    this.setState({
                        disabled: true
                    })
                }
                else if (data.length < 4) {
                    this.setState({
                        disabled: false
                    })
                }
            })
            .catch(error => {
                console.log("Error on fetching data", error);
            })
    }

    selectImage(e) {
        e.preventDefault();
        let image = e.target.files[0];
        if (image.type.includes("image/")) {
            this.setState({ image });
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ imgSrc: reader.result })
            };
            reader.readAsDataURL(image)
        }
        else {
            let validation = this.state.validation;
            validation.imgSrc.isInvalid = true;
            validation.imgSrc.isValid = false;
            validation.imgSrc.message = "File should be of image type"
            this.setState({
                validation
            })
        }
    }

    deleteImage(e) {
        e.preventDefault();
        this.setState({ imgSrc: "", image: "" })
    }

    handleEditData(row) {
        this.showModal();
        this.setState({
            imgSrc: row.image,
            editId: row.id,
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const { editId, image } = this.state;
        const validation = this.validator.validate(this.state);
        this.submitted = true;
        this.setState({ validation });
        if (validation.isValid) {
            var apiOptions = {};
            this.setState({ loading: true });
            if (editId !== null) {
                let formData = new FormData();
                if (image.name) formData.append("image", this.state.image);
                apiOptions = {
                    method: "patch",
                    url: "hero-slider/" + editId + "/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            else {
                let formData = new FormData();
                formData.append("image", this.state.image);
                apiOptions = {
                    method: "post",
                    url: "hero-slider/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            API.request(apiOptions)
                .then(response => {
                    this.hideModal();
                    this.fetchData();
                    this.props.showNotificationMessage({
                        notificationMessage: "Slider Added successfully.",
                        successMessage: true,
                        showNotification: true,
                        isLoading: false,
                    });
                    this.setState({
                        loading: false
                    });
                })
                .catch(error => {
                    console.log("Error on adding slider", error);
                    this.setState({
                        error: "Some error occured. Please try again",
                        loading: false
                    });
                })
        }
    }

    handleDeleteImage(id) {
        API.delete("hero-slider/" + id + "/")
            .then(response => {
                this.fetchData();
                this.props.showNotificationMessage({
                    notificationMessage: "Slider Deleted successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on deleting slider", error);
            })
    }

    formReset() {
        this.submitted = false;
        this.setState({
            image: "",
            imgSrc: "",
            validation: this.validator.valid(),
        });
        if (document.getElementById("img-upload")) {
            document.getElementById("img-upload").setAttribute("src", picture)
        }
    }

    hideModal() {
        this.formReset();
        this.setState({ showModal: false });
    }
    showModal() {
        this.setState({
            showModal: true,
        });

    }
    updateRank() {
        var rank = {};
        const { data } = this.state;
        data.map((val, index) => {
            rank[val.id] = index + 1;
        })
        API.post("set-heroslider-rank/", rank)
            .then(response => {
                this.props.showNotificationMessage({
                    notificationMessage: "Slider Updated successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on updating rank", error);
                this.setState({
                    error: "Some error occured. Please try again",
                    loading: false
                });
            })
    }

    render() {
        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation;
        return (
            <div className="slider">
                <div className="page-header">
                    {/* <h5 className="mb-4">Hero Slider Section</h5> */}
                    <div className="actions">
                        <div className="action-item">
                            <button disabled={this.state.disabled} className="btn btn-sm button text-button" onClick={this.showModal}>
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>add new
                            </button>
                        </div>
                    </div>
                </div>
                <Card body>

                    {this.state.data.length < 10 && <p>You can add upto 10 images to the Slider</p>}
                    {this.state.data.length == 10 && <p>You have already added 10 images to the Slider</p>}
                    <ReactDragListView {...this.dragProps}>
                        <DataTable
                            highlightOnHover
                            columns={this.columns}
                            data={this.state.data}
                            pagination
                            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            responsive
                        />
                    </ReactDragListView>
                </Card>
                <Modal size="md" show={this.state.showModal} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="slider-col">
                                    <h6>Slider 1</h6>
                                    <div className='img-input-wrap'>
                                        <div className="img-wrapper">
                                            {this.state.imgSrc ? (
                                                <>
                                                    <img
                                                        id="img-upload"
                                                        className="img-banner"
                                                        src={this.state.imgSrc}
                                                        alt="selected"
                                                    />
                                                </>
                                            ) :
                                                <>
                                                    <img
                                                        src={picture}
                                                        alt="selected"
                                                        className={
                                                            (validation.imgSrc.isInvalid && "has-error")
                                                        }
                                                    />
                                                    <p className="help-block">{validation.imgSrc.message}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <input name="imgSrc" className="img-input" type='file' id="img1" onChange={this.selectImage} />
                        <label htmlFor="img1">Upload</label>
                        <button className="button remove-button" id="i1" onClick={this.deleteImage}>Remove</button>
                        <button className="btn button button-dark" onClick={this.handleSubmit} disabled={this.state.loading ? true : false}>Publish</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
