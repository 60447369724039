import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "../../utils/PhoenixAPI";
import "../HomecareMwebPortal/index.scss";
import { Link } from "react-router-dom";
import "./notifications.scss";
import Notification from "../HomecareMwebPortal/"
import moment from "moment";

function NurseNotifications(props) {
  const [nurseTime, setNurseTime] = useState("");
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const [orders, setOrders] = useState([]);
  const fetchNotifications = async (orderId) => {
    try {
      const { data = [] } = await PhoenixAPI.get(
        `nurse/nurse-notification-details/${orderId}`
      );

      console.log("Datasd", data);
      setOrders(data);
    } catch (error) {}
  };

  useEffect(() => {
    const orderId = props.match.params.orderId;
    if(orderId){
      fetchNotifications(orderId);
    }
    
  }, []);

  const handleGoBack = () => {
    return (window.location.href = "admin/dashboard/home");
  };
  const handleTimeChange = (e) => {
    const message = e.target.value;
    setNurseTime(message);
  };

  const handleSelect = (selectedItem) => {
    if (selectedItem === "yes") {
      setFirst(true);
      setSecond(false);
      setThird(false);
      setSelectedOption("Nurse on time");
    } else if (selectedItem === "running") {
      setFirst(false);
      setSecond(true);
      setThird(false);
      setSelectedOption("Nurse late by 10 mins");
    } else if (selectedItem === "more") {
      setFirst(false);
      setSecond(false);
      setThird(true);
      setSelectedOption("Nurse late by more than 15 mins");
    }
  };

  const getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} | ${time}`;
  };
  const getTime=(confirmTime)=>{
    const date = moment(confirmTime).format("MMM DD, YYYY");
    const time = moment(confirmTime).format("hh:mm A");
    return `${date} at ${time}`;
  }


  const handleSave = async() => {
    const payload={
      "orderId": orders.orderId,
      "selectedOption": selectedOption, 
    }

    if(nurseTime){
      payload.nurseReason=nurseTime
    }

      try{
       const res = await PhoenixAPI.patch("nurse/on-time-response", payload)
       setIsError(false);
       setMessage("Response Saved Succesfully")
       handleGoBack()
      }
      catch(error){
        setIsError(true);
        setMessage("Error occurred in Saving Response", error.message)
        console.log("Error in saving response", error.message)
      }
  };
  return (
    <>
     <div className="nurse-panel-main">
      <div className="top-header">
        <div className="header-content">
          <div className="content" onClick={() => handleGoBack()}>
            <div className="lt">&lt;</div>
            <div className="header-title">Notifications</div>
          </div>
        </div>
      </div>
      <div className="new-bookings">New Bookings</div>

      
      
            <div className="clients-new-booking">
              <div className="client-name-date">
                <div className="name-txt">{orders?.clientName ||""}</div>
                <div className="date-time">
                <span className="month">
                  {getFormattedPurchasedDate(orders?.confirmedTime || "")}
                  </span>
                </div>
              </div>
              <div className="schedule-book">
                {`Your next booking is at ${getTime(orders?.confirmedTime || "")}. Are you on time ?`}
              </div>
              <div
                className={`${first && "selected-answer"} select-answer`}
                onClick={() => handleSelect("yes")}
              >
                Yes
              </div>
              <div
                className={`${second && "selected-answer"} select-answer`}
                onClick={() => handleSelect("running")}
              >
                I’m running 10 minutes late
              </div>
              <div
                className={`${third && "selected-answer"} select-answer`}
                onClick={() => handleSelect("more")}
              >
                It’ll take me more than 15 mins
              </div>
              {third && (
                <>
                  <div className="schedule-book">
                    Mention how much time will it take ?
                  </div>
                  <div className="input-time">
                    <input
                      type="text"
                      value={nurseTime}
                      onChange={(e) => handleTimeChange(e)}
                      placeholder="Mention Time"
                    />
                  </div>
                </>
              )}
              <div className="save-button" onClick={() => handleSave()}>
                Save
              </div>
            </div>
        
    </div>
    {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
    </>
   
  );
}

const mapStateToProps = (state, ownProps) => {
  return state;
};
const mapDispatchToProps = (dispatch) => {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NurseNotifications);
