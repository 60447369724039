import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectMedicines.propTypes = {
  countryId: PropTypes.number.isRequired,
  selectedMedicineIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onMedicineSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SelectMedicines(props) {
  const {
    countryId,
    selectedMedicineIds = [],
    onMedicineSelect = (f) => f,
    isDisabled = false,
    couponType,
  } = props;

  const [medicines, setMedicines] = useState([]);
  const medicineMapper = (medicine) => ({
    id: medicine.id,
    internalName: medicine.internalName,
    status: medicine.status,
  });

  useEffect(() => {
    const url = "medicine/";
    const fetchMedicines = async () => {
      try {
        const { data: medicines } = await PhoenixAPI.get(url);
        if(couponType=="couponExclude")
          {
            setMedicines(
              medicines
                .filter((medicine) => medicine.country === countryId).map(medicineMapper)
              );

          }
        else{
        setMedicines(
          medicines
            .filter((medicine) => medicine.country === countryId  && !medicine.isCouponExcluded)
            .map(medicineMapper)
        );
      }
      } catch (error) {
        console.log("Error on fetching medicines", error);
      }
    };
    fetchMedicines();
  }, [countryId]);

  const findMedicine = (medicineId) => {
    return medicines.find((medicine) => medicine.id === medicineId);
  };

  const [activeMedicines, inactiveMedicines] = _.partition(
    medicines,
    (it) => it.status === "Active"
  );
  activeMedicines.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  inactiveMedicines.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  const options = [
    {
      label: "Active",
      options: activeMedicines.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveMedicines.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedMedicines = selectedMedicineIds
    .map(findMedicine)
    .map(createOption);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedMedicineIds && selectedMedicineIds.join(",")
      }`}
      value={selectedMedicines}
      onChange={onMedicineSelect}
      options={options}
      styles={selectStyles}
      // placeholder="Select Medicines"
      menuPlacement="top"
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (medicine) => {
  if (!medicine) return;
  return {
    key: medicine.id,
    value: medicine.id,
    label: medicine.internalName,
  };
};

export default SelectMedicines;
