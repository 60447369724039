import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class AnswerForm extends Component {
  constructor(props) {
    super(props);

    const { answers = [], onAnswerUpdated = (f) => f } = this.props;

    this.onAnswerUpdated = onAnswerUpdated;

    this.state = {
      answers,
      answer: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      rankOptions: [],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Answer (English)",
        selector: "answerEng",
        sortable: true,
        wrap: true,
        format: (answer) => (
          <div className="text-overflow-ellipsis">{answer.answerEng ?? ""}</div>
        ),
      },
      {
        name: "Answer (Arabic)",
        selector: "answerAr",
        sortable: true,
        wrap: true,
        right: true,
        format: (answer) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {answer.answerAr ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (answer) => <Status isActive={answer.isActive ?? false} />,
      },

      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (answer) => this.renderActionBar(answer),
      },
    ];

  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      answer: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const answer = { ...this.state.answer };
    answer[input.name] = input.value;
    this.setState({ answer });
  };

  handleEdit = (id) => {
    const { answers = [] } = this.state;
    const answer = answers.find((it) => it.id === id);
    if (!answer) return;

    this.setState({ answer, isNewAnswer:false }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteQuestion(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { answer = {} } = this.state;
    if (answer.id === null || answer.id === undefined) this.createQuestion();
    else this.updateQuestion(answer.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { answerEng, answerAr, isActive } = this.state.answer || {};

    if (!answerEng || answerEng.trim() === "")
      errors.answerEng = "Answer (English) cannot be blank";
    else if (answerEng && answerEng.length > 10000)
      errors.answerEng = "Answer (English) is limited to a 10000 characters";

    if (!answerAr || answerAr.trim() === "")
      errors.answerAr = "Answer (Arabic) cannot be blank";
    else if (answerAr && answerAr.length > 10000)
      errors.answerAr = "Answer (Arabic) is limited to a 10000 characters";

    // if (!isActive) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createQuestion = () => {
    const { answers = [], answer } = this.state;
    const ids = answers.length > 0 ? answers.map((it) => it.id) : null;
    answer.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      answers.length > 0
        ? answers.filter((it) => it.rank).map((it) => it.rank)
        : null;
    answer.rank = ranks ? Math.max(...ranks) + 1 : 1;
    answer.is_new = true;

    this.setState({ answers: [...answers, answer] }, () => {
      this.showSuccessNotification("Answer Created Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { answers = [], answer } = this.state;
    const newAnswers = answers.map((it) => (it.id === id ? answer : it));
    this.setState({ answers: newAnswers }, () => {
      this.showSuccessNotification("Answer Updated Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { answers = [] } = this.state;
    const newAnswers = answers.map((it) =>
      it.id === id
        ? { ...it, isActive: it.isActive === true ? false : true }
        : it
    );
    this.setState({ answers: newAnswers }, () => {
      this.showSuccessNotification("Answer Updated Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };

  deleteQuestion = (id) => {
    const { questions = [] } = this.state;
    const questionToDelete = questions.find((it) => it.id === id) || {};
    const newQuestions = questions
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > questionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ questions: newQuestions }, () => {
      this.showSuccessNotification("FAQ Deleted Successfully!");
      this.onAnswerUpdatedLocally();
    });
  };



  onAnswerUpdatedLocally = () => {
    this.clearForm();
    this.onAnswerUpdated(
      this.state.answers.map((answer) => {
        const q = { ...answer };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  createRankOptions = () => {
    const { isNewAnswer, answers } = this.state;
   
    const ranks = answers.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewAnswer ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };
  handleAddNewQuestion = () => {
  
    
    this.setState({  isNewAnswer:true }, () => this.showAddEditModal());
  }

  showAddEditModal = () => {
    const { isNewAnswer } = this.state;
    const rankOptions = this.createRankOptions() || [];
    const rank = Math.max(...rankOptions.map((it) => it.value));
    if(isNewAnswer){
      const answer={
        rank,
        isActive: false 
      }
      this.setState({ answer, rankOptions,isAddEditModalVisible:true});
    }
    else{
      this.setState({ rankOptions,isAddEditModalVisible: true });
    }
   
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (answer) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(answer.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.answer.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      answers = [],
      answer = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewAnswer = answer.id === null || answer.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="answerEng">Answer (English)*</label>
            <div className="input-group">
              <textarea
                value={answer.answerEng}
                onChange={this.handleChange}
                id="answerEng"
                name="answerEng"
                placeholder="Answer in English"
                readOnly={!isNewAnswer && !editables.answerEng}
                className={
                  "form-control py-2" + (errors.answerEng ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerEng")}
            </div>
            <span className="help-block">{errors.answerEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="answerAr">Answer (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={answer.answerAr}
                onChange={this.handleChange}
                id="answerAr"
                name="answerAr"
                placeholder="Answer in Arabic"
                readOnly={!isNewAnswer && !editables.answerAr}
                className={
                  "form-control input-arabic py-2" +
                  (errors.answerAr ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("answerAr")}
            </div>
            <span className="help-block">{errors.answerAr}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={answer?.rank}
                onChange={(event, value) => {
                  this.setState({
                    answer: { ...answer, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewAnswer && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={answer?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    answer: { ...answer, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewAnswer && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={answers || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />            
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {answer.id === null || answer.id === undefined
                ? "Add Answer"
                : "Edit Answer"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {answer.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Question</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the question?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteQuestion(this.state.questionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AnswerForm.propTypes = {
  answers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onAnswerUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

AnswerForm.defaultProps = {
  answers: [],
  onAnswerUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AnswerForm);
