import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import { saveBanner, updateBanner } from "services/BannerService";

const BASE_URL_WEBSITE_BANNERS = "/website/banner";
const BANNER_TYPE = "InPageBanner";
const ONE_FIFTY_KILO_BYTE = 153600;
const INPAGE_BANNER_NAMES = [
  {
    key: "Blood_Test_Master_Banner",
    value: "Blood_Test_Master_Banner",
    text: "Blood_Test_Master_Banner",
  },
  {
    key: "Supplements_Master_Banner",
    value: "Supplements_Master_Banner",
    text: "Supplements_Master_Banner",
  },
  {
    key: "Hairloss_Master_Banner",
    value: "Hairloss_Master_Banner",
    text: "Hairloss_Master_Banner",
  },
  {
    key: "ED_Master_banner",
    value: "ED_Master_banner",
    text: "ED_Master_banner",
  },
  {
    key: "IV_Master_Banner",
    value: "IV_Master_Banner",
    text: "IV_Master_Banner",
  },
];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class InPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      banner: {},
      searchTerm: null,
      searchApplied: false,
      isBannerViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Banner Name",
        selector: "bannerName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirection Link (English)",
        selector: "redirectionLinkEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirection Link (Arabic)",
        selector: "redirectionLinkArabic",
        wrap: true,
        sortable: true,
        right: true,
      },
      {
        name: "Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.status === "Active" ? true : false} />,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchInpageBanners();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState({
        countries: nextProps.countries,
        selectedCountry: nextProps.selectedCountry,
      });
    }
  }

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  fetchInpageBanners = async () => {
    PhoenixAPI.get(`${BASE_URL_WEBSITE_BANNERS}`, {
      params: {
        bannerType: BANNER_TYPE,
      },
    })
      .then((response) => {
        const banners = response.data || [];
        this.setState({ banners });
      })
      .catch((error) => {
        console.log("Error on fetching Home Page Banners", error.message);
        this.showErrorNotification(
          "Some error has occured. Please try again.",
          error.message
        );
      });
  };

  resetForm = () => {
    this.setState({
      banner: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewBanner(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  handleViewBanner = (bannerId) => {
    const banners = this.state.banners ?? [];
    const banner = banners.find((it) => it.id === bannerId);
    if (!banner) {
      console.log(`In Page Banner with ID ${bannerId} is not found!`);
      return;
    }

    banner.desktopImgSrc_english = banner.desktopBannerImageEnglish;
    banner.desktopImgSrc_arabic = banner.desktopBannerImageArabic;
    banner.mobileImgSrc_english = banner.mobileBannerImageEnglish;
    banner.mobileImgSrc_arabic = banner.mobileBannerImageArabic;

    this.setState({ banner, isNewBanner: false }, () =>
      this.showBannerViewModal()
    );
  };

  handleSaveBanner = async () => {
    const { banner } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = banner.id !== null && banner.id !== undefined;
      const response = isValidId
        ? await updateBanner(banner)
        : await saveBanner(banner);

      this.hideBannerViewModal();
      console.log("Response", response);
      this.showSuccessNotification(
        isValidId
          ? "InPage Banner Updated Successfully."
          : "InPage Banner Created Successfully."
      );
      this.fetchInpageBanners();
    } catch (error) {
      console.log("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { banner = {} } = this.state;
    const errors = {};

    const {
      bannerName,
      desktopBannerImageEnglish,
      redirectionLinkEnglish,
      redirectionLinkArabic,
      desktopBannerImageArabic,
      mobileBannerImageEnglish,
      mobileBannerImageArabic,
      rank,
      colorCode,
    } = banner;

    if (!bannerName || bannerName.trim() === "")
      errors.bannerName = "Banner Name cannot be blank";
    else if (bannerName.length > 100)
      errors.bannerName = "Banner Name is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (!colorCode || colorCode.trim() === "")
      errors.colorCode = "Colour Code cannot be blank";
    else if (colorCode.length > 100)
      errors.colorCode = "Colour Code is limited to a 100 characters";

    if (!redirectionLinkEnglish || redirectionLinkEnglish.trim() === "")
      errors.redirectionLinkEnglish =
        "Redirection Link English cannot be blank";
    else if (redirectionLinkEnglish.length > 500)
      errors.redirectionLinkEnglish =
        "Redirection Link English is limited to a 500 characters";

    if (!redirectionLinkArabic || redirectionLinkArabic.trim() === "")
      errors.redirectionLinkArabic = "Redirection Link Arabic cannot be blank";
    else if (redirectionLinkArabic.length > 500)
      errors.redirectionLinkArabic =
        "Redirection Link Arabic is limited to a 500 characters";

    if (!desktopBannerImageEnglish)
      errors.desktopBannerImageEnglish = "Banner Image English cannot be blank";
    else if (
      desktopBannerImageEnglish &&
      desktopBannerImageEnglish.size &&
      desktopBannerImageEnglish.size > ONE_FIFTY_KILO_BYTE
    )
      errors.desktopBannerImageEnglish = "Banner Image English should be less than 150 KB!";

    if (!desktopBannerImageArabic)
      errors.desktopBannerImageArabic = "Banner Image Arabic cannot be blank";
    else if (
      desktopBannerImageArabic &&
      desktopBannerImageArabic.size &&
      desktopBannerImageArabic.size > ONE_FIFTY_KILO_BYTE
    )
      errors.desktopBannerImageArabic = "Banner Image Arabic should be less than 150 KB!";

      
    if (!mobileBannerImageEnglish)
    errors.mobileBannerImageEnglish =
      "Mobile Banner Image English cannot be blank";
  else if (
    mobileBannerImageEnglish &&
    mobileBannerImageEnglish.size &&
    mobileBannerImageEnglish.size > ONE_FIFTY_KILO_BYTE
  )
    errors.mobileBannerImageEnglish =
      "Mobile Banner Image English should be less than 150 KB!";

  if (!mobileBannerImageArabic)
    errors.mobileBannerImageArabic =
      "Mobile Banner Image Arabic cannot be blank";
  else if (
    mobileBannerImageArabic &&
    mobileBannerImageArabic.size &&
    mobileBannerImageArabic.size > ONE_FIFTY_KILO_BYTE
  )
    errors.mobileBannerImageArabic =
      "Mobile Banner Image Arabic should be less than 150 KB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewBanner = () => {
    this.setState({ isNewBanner: true }, () => this.showBannerViewModal());
  };

  createRankOptions = () => {
    const { isNewBanner } = this.state;
    const banners = this.getBannersForCurrentCountry();
    const ranks = banners.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewBanner ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    // console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  showBannerViewModal = () => {
    const { selectedCountry, isNewBanner } = this.state;
    const rankOptions = this.createRankOptions() || [];
    if (isNewBanner) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const banner = {
        country: selectedCountry?.id,
        bannerType: BANNER_TYPE,
        status: "Inactive",
        rank,
      };
      this.setState({
        banner,
        isBannerViewModalVisible: true,
        rankOptions,
      });
    } else {
      this.setState({
        isBannerViewModalVisible: true,
        rankOptions,
      });
    }
  };

  hideBannerViewModal = () => {
    this.setState({ isBannerViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewBanner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  }

  selectDesktopEnglishImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        desktopBannerImageEnglish: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          desktopImgSrc_english: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  selectDesktopArabicImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        desktopBannerImageArabic: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          desktopImgSrc_arabic: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  selectMobileEnglishImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        mobileBannerImageEnglish: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          mobileImgSrc_english: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  selectMobileArabicImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        mobileBannerImageArabic: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          mobileImgSrc_arabic: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const banner = { ...this.state.banner };
    banner[input.name] = input.value;
    this.setState({ banner });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredBanners = () => {
    const banners = this.getBannersForCurrentCountry();
    return this.getSearchedBanners(banners);
  };

  getBannersForCurrentCountry = () => {
    const { banners, selectedCountry } = this.state;
    return banners && selectedCountry
      ? banners.filter((banner) => banner.country === selectedCountry.id)
      : [];
  };

  getSearchedBanners = (banners) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return banners;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return banners.filter((banner) => {
      const { id, bannerName, rank } = banner;
      return (
        (bannerName && bannerName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      banners,
      searchTerm,
      banner,
      searchApplied,
      countries,
      selectedCountry,
      errors,
      editables,
      loadError,
      isNewBanner,
    } = this.state;

    const isEditable = this.props.isEditable ?? true;
    const filteredBanners = this.getFilteredBanners(banners) ?? [];

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          {!isNewBanner && (
            <div className="form-group col">
              <label htmlFor="ID">ID</label>
              <div className="input-group">
                <input
                  value={banner?.id || ""}
                  onChange={this.handleChange}
                  id="ID"
                  name="ID"
                  type="text"
                  placeholder="ID"
                  className={"form-control py-2"}
                  disabled={true}
                />
              </div>
            </div>
          )}
          <div
            className={`form-group col ${
              isNewBanner ? "form-group col-6" : ""
            }`}
          >
            <label htmlFor="internalName">Banner Name*</label>
            <div className="input-group">
              <Dropdown
                value={banner?.bannerName}
                onChange={(event, value) => {
                  this.setState({
                    banner: {
                      ...banner,
                      bannerName: value.value,
                    },
                  });
                }}
                id="bannerName"
                name="bannerName"
                placeholder="Select Banner Name"
                search
                selection
                options={INPAGE_BANNER_NAMES}
                disabled={!isNewBanner && !editables.bannerName}
                className={
                  "form-control" + (errors.bannerName ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("bannerName")}
            </div>
            <span className="help-block">{errors.bannerName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="colorCode">Colour Code *</label>
            <div className="input-group">
              <input
                value={banner?.colorCode || ""}
                onChange={this.handleChange}
                id="colorCode"
                name="colorCode"
                type="text"
                placeholder="Enter Colour Code"
                className={
                  "form-control py-2" + (errors.colorCode ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.colorCode}
              />
              {this.renderPencilIcon("colorCode")}
            </div>
            <span className="help-block">{errors.colorCode}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="bannerType">Banner Type</label>
            <input
              value={isNewBanner ? BANNER_TYPE : banner.bannerType || ""}
              type="text"
              id="bannerType"
              name="bannerType"
              disabled={true}
              className={
                "form-control py-2" + (errors.bannerType ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.bannerType}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={banner?.status}
                onChange={(event, value) => {
                  this.setState({
                    banner: {
                      ...banner,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewBanner && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={banner?.rank}
                onChange={(event, value) => {
                  this.setState({
                    banner: { ...banner, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewBanner && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="redirectionLinkEnglish">
              Redirection Link (English)*
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkEnglish || ""}
                onChange={this.handleChange}
                id="redirectionLinkEnglish"
                name="redirectionLinkEnglish"
                type="text"
                placeholder="Enter Redirection Link in English"
                className={
                  "form-control py-2" +
                  (errors.redirectionLinkEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkEnglish}
              />
              {this.renderPencilIcon("redirectionLinkEnglish")}
            </div>
            <span className="help-block">{errors.redirectionLinkEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="redirectionLinkArabic">
              Redirection Link (Arabic)*
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkArabic || ""}
                onChange={this.handleChange}
                id="redirectionLinkArabic"
                name="redirectionLinkArabic"
                type="text"
                placeholder="Enter Redirection Link in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.redirectionLinkArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkArabic}
              />
              {this.renderPencilIcon("redirectionLinkArabic")}
            </div>
            <span className="help-block">{errors.redirectionLinkArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewBanner
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(banner?.country)?.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Banner Image (English)*</label>
            <div className="input-group">
              <input
                onChange={this.selectDesktopEnglishImage}
                id="imageInput"
                name="desktopBannerImageEnglish"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.desktopBannerImageEnglish}
                className={
                  "form-control" +
                  (errors.desktopBannerImageEnglish ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("desktopBannerImageEnglish")}
            </div>
            <span className="help-block">
              {errors.desktopBannerImageEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="imageInput">Banner Image (Arabic) *</label>
            <div className="input-group">
              <input
                onChange={this.selectDesktopArabicImage}
                id="imageInput"
                name="desktopBannerImageArabic"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.desktopBannerImageArabic}
                className={
                  "form-control" +
                  (errors.desktopBannerImageArabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("desktopBannerImageArabic")}
            </div>
            <span className="help-block">
              {errors.desktopBannerImageArabic}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            {banner?.desktopImgSrc_english && (
              <>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={banner.desktopImgSrc_english}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-group col">
            {banner?.desktopImgSrc_arabic && (
              <>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={banner.desktopImgSrc_arabic}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Mobile Banner Image (English) *</label>
            <div className="input-group">
              <input
                onChange={this.selectMobileEnglishImage}
                id="imageInput"
                name="mobileBannerImageEnglish"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.mobileBannerImageEnglish}
                className={
                  "form-control" +
                  (errors.mobileBannerImageEnglish ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("mobileBannerImageEnglish")}
            </div>
            <span className="help-block">{errors.mobileBannerImageEnglish}</span>
          </div>

          <div className="form-group col">
            <label htmlFor="imageInput">Mobile Banner Image (Arabic) *</label>
            <div className="input-group">
              <input
                onChange={this.selectMobileArabicImage}
                id="imageInput"
                name="mobileBannerImageArabic"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.mobileBannerImageArabic}
                className={
                  "form-control" +
                  (errors.mobileBannerImageArabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("mobileBannerImageArabic")}
            </div>
            <span className="help-block">{errors.mobileBannerImageArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            {banner.mobileImgSrc_english && (
              <>
                <div>
                  <img
                    id="selected_banner_image"
                    name="selected_banner_image"
                    src={banner.mobileImgSrc_english}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-group col">
            {banner.mobileImgSrc_arabic && (
              <>
                <div>
                  <img
                    id="selected_banner_image"
                    name="selected_banner_image"
                    src={banner.mobileImgSrc_arabic}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    return (
      <>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm || ""}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNewBanner}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>

          <Card body>
            <h3 className="p-head">In Page Banner</h3>
            <DataTable
              data={filteredBanners}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              // pagination
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + banners.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.isBannerViewModalVisible}
            onHide={this.hideBannerViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Banner Name: </span>
                  {banner?.bannerName || ""}
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSaveBanner}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_website_categories">
                <Tab eventKey="key_details_form" title="Details">
                  {detailsForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InPage);
