import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { UploadMediaFile } from "services/UploadMedia";



class HeaderSection extends Component {
  constructor(props) {
    super(props);

    const { questions = [], onQuestionUpdated = (f) => f } = this.props;

    this.onQuestionUpdated = onQuestionUpdated;

    this.state = {
      questions,
      question: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      rankOptions: [],
        headerEnglish:RichTextEditor.createEmptyValue(),
        headerArabic:RichTextEditor.createEmptyValue(),
        headerSubEnglish:RichTextEditor.createEmptyValue(),
        headerSubArabic:RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Header Text (English)",
        selector: "headerEnglish",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.headerEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Header Text (Arabic)",
        selector: "headerArabic",
        sortable: true,
        wrap: true,
        // right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            // style={{ textAlign: "right" }}
          >
            {question.headerArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Edit",
       
        center: true,
  
        cell: (question) => this.renderActionBar(question),
      },
    ];
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      question: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleEdit = (id) => {
    const { questions = [] } = this.state;
    const question = questions.find((it) => it.id === id);
    if (!question) return;
    const headerRichTextValueEnglish=RichTextEditor.createValueFromString(question.headerEnglish ?? "","html");
   const  headerRichTextValueArabic=RichTextEditor.createValueFromString(question.headerArabic ?? "","html");
   const  headerRichSubTextValueEnglish=RichTextEditor.createValueFromString(question.headerSubEnglish ?? "","html");
   const  headerRichSubTextValueArabic=RichTextEditor.createValueFromString(question.headerSubArabic ?? "","html");
   
   const newQuestion={...question, headerEnglish:question.headerEnglish, headerArabic:question.headerArabic,headerSubEnglish:question.headerSubEnglish,headerSubArabic:question.headerSubArabic }
    this.setState({ 
        question: newQuestion,
        headerEnglish:headerRichTextValueEnglish,
         headerArabic:headerRichTextValueArabic,
         headerSubEnglish:headerRichSubTextValueEnglish,
         headerSubArabic:headerRichSubTextValueArabic
    }, () => this.showAddEditModal());
  };



  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { question = {} } = this.state;
    if (question.id === null || question.id === undefined)
      this.createQuestion();
    else this.updateQuestion(question.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const {
      headerImage,
      headerEnglish,
      headerArabic,
      headerSubEnglish,
      headerSubArabic
    } = this.state.question || {};



    if (!headerImage) errors.headerImage = "Image cannot be blank";

    if(!headerEnglish) errors.headerEnglish = "Header English cannot be blank";

    if(!headerArabic) errors.headerArabic ="Header Arabic cannot be blank";

    if(!headerSubEnglish) errors.headerSubEnglish = "Header SubText English cannot be blank";
    if(!headerSubArabic) errors.headerSubArabic= "Header SubText Arabic cannot be blank"

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createQuestion = () => {
    const { questions = [], question } = this.state;
    const ids = questions.length > 0 ? questions.map((it) => it.id) : null;
    question.id = ids ? Math.max(...ids) + 1 : 1;

   
    question.is_new = true;
     
    this.setState({ questions: [...questions, question] }, () => {
      this.showSuccessNotification("Header Section Created Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

  updateQuestion = (id) => {
    const { questions = [], question } = this.state;
    const newQuestions = questions.map((it) => (it.id === id ? question : it));
    this.setState({ questions: newQuestions}, () => {
      this.showSuccessNotification("Header Section Updated Successfully!");
      this.onQuestionUpdatedLocally();
    });
  };

 



  onQuestionUpdatedLocally = () => {
    this.clearForm();
    this.onQuestionUpdated(
      this.state.questions.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewQuestion = () => {
   
    this.setState({ 
      isNewQuestion: true,
      headerEnglish:RichTextEditor.createEmptyValue(),
      headerArabic:RichTextEditor.createEmptyValue(),
      headerSubEnglish:RichTextEditor.createEmptyValue(),
      headerSubArabic:RichTextEditor.createEmptyValue(),
    
    }, () => this.showAddEditModal());
  };

  handleSelectImage=async(e)=>{
    const question=this.state.question
    if (e.target.files && e.target.files.length){
        const file=e.target.files[0]
        const {message:imageUrl="", statusCode=""}=await UploadMediaFile(file);
        const newQuestion={...question,headerImage:imageUrl}
        this.setState({question:newQuestion})
        console.log("Image", imageUrl)
    }
  }

  handleRichText = (value, name) => {
   
    const {question}= this.state;
    const newQuestion={...question,[name]:value.toString("html")}
    this.setState({question:newQuestion, [name]:value})
  };



  showAddEditModal = () => {
    const { isNewQuestion } = this.state;
    if (isNewQuestion) {
    
      this.setState({ isAddEditModalVisible: true });
    } else {
      this.setState({  isAddEditModalVisible: true });
    }
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ 
        isAddEditModalVisible: false,
        headerEnglish:RichTextEditor.createEmptyValue(),
        headerArabic:RichTextEditor.createEmptyValue(),
        headerSubEnglish:RichTextEditor.createEmptyValue(),
        headerSubArabic:RichTextEditor.createEmptyValue(),
    });
  };

 

  renderActionBar = (question) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(question.id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.question.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      questions = [],
      question = {},
      errors,
      editables,
      sortColumn,
   
    } = this.state;
 
    const isNewQuestion = question.id === null || question.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label htmlFor="imageInput">Header Image *</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
    
              />
              {this.renderPencilIcon(`headerimage`)}
            </div>
            <span className="help-block">{errors.headerImage}</span>
          </div>
          <div className="form-group col">
            {question.headerImage && (
              <>
                <label htmlFor="selected_package_image">Selected Image</label>
                <div className="form-image-container">
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={question.headerImage}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
              <div className="form-group col" style={{ maxWidth: "50%" }}>
                <label>
                   Header Text (English) *
                </label>
                <div className="input-group">
                  <RichTextEditor
                    rootStyle={{
                      position: "relative",
                      flex: "1 1 auto",
                      width: "1%",
                      marginBottom: 0,
                    }}
                    editorStyle={{ height: "90px", overflow: "hidden" }}
                    value={this.state.headerEnglish}
                    onChange={(value) =>
                      this.handleRichText(
                        value,
                        "headerEnglish"
                      )
                    }
                    disabled={
                      !isNewQuestion && !editables.headerEnglish
                    }
                  />
                  {this.renderPencilIcon("headerEnglish")}
                </div>
                <span className="help-block">
                  {errors.headerEnglish}
                </span>
              </div>
              <div className="form-group col" style={{ maxWidth: "50%" }}>
                <label>
                  Header Text (Arabic) *
                </label>
                <div className="input-group">
                  <RichTextEditor
                    rootStyle={{
                      position: "relative",
                      flex: "1 1 auto",
                      width: "1%",
                      marginBottom: 0,
                    }}
                    editorStyle={{ height: "90px", overflow: "hidden" }}
                    value={this.state.headerArabic}
                    onChange={(value) =>
                      this.handleRichText(
                        value,
                        "headerArabic"
                      )
                    }
                    disabled={
                      !isNewQuestion && !editables.headerArabic
                    }
                  />
                  {this.renderPencilIcon("headerArabic")}
                </div>
                <span className="help-block">
                  {errors.headerArabic}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col" style={{ maxWidth: "50%" }}>
                <label>
                   Header SubText (English) *
                </label>
                <div className="input-group">
                  <RichTextEditor
                    rootStyle={{
                      position: "relative",
                      flex: "1 1 auto",
                      width: "1%",
                      marginBottom: 0,
                    }}
                    editorStyle={{ height: "90px", overflow: "hidden" }}
                    value={this.state.headerSubEnglish}
                    onChange={(value) =>
                      this.handleRichText(
                        value,
                        "headerSubEnglish"
                      )
                    }
                    disabled={
                      !isNewQuestion && !editables.headerSubEnglish
                    }
                  />
                  {this.renderPencilIcon("headerSubEnglish")}
                </div>
                <span className="help-block">
                  {errors.headerSubEnglish}
                </span>
              </div>
              <div className="form-group col" style={{ maxWidth: "50%" }}>
                <label>
                Header SubText (Arabic) *
                </label>
                <div className="input-group">
                  <RichTextEditor
                    rootStyle={{
                      position: "relative",
                      flex: "1 1 auto",
                      width: "1%",
                      marginBottom: 0,
                    }}
                    editorStyle={{ height: "90px", overflow: "hidden" }}
                    value={this.state.headerSubArabic}
                    onChange={(value) =>
                      this.handleRichText(
                        value,
                        "headerSubArabic"
                      )
                    }
                    disabled={
                      !isNewQuestion && !editables.headerSubArabic
                    }
                  />
                  {this.renderPencilIcon(`headerSubArabic`)}
                </div>
                <span className="help-block">
                  {errors.headerSubArabic}
                </span>
              </div>
            </div>

      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={questions || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {question.id === null || question.id === undefined
                ? "Add Header Section"
                : "Edit Header Section"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {question.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
       
      </div>
    );
  }
}

HeaderSection.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onQuestionUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

HeaderSection.defaultProps = {
  questions: [],
  onQuestionUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSection);
