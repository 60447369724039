import React ,{useState,useEffect}from "react";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import cancelReasonOptions from "../../utils/CancelReason";

function ConfirmOrderCancelViewModal(props) {

  const {cancelNotesFinal:notes,isVisible = false, cancelReasonFinal:reason, notifyClient:notify, onHide = (f) => f, onCancel = (f) => f } = props;
  const[notificationClient,setNotificationClient]=useState(notify);
  const[error,setError]=useState("");
  const [cancelReasonFinal, setCancelReasonFinal] = useState(reason);
  const[reasonError, setReasonError]=useState("");
  const [cancelNotesFinal, setCancelNotesFinal] = useState(notes);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  
  useEffect(() => {setCancelNotesFinal(notes);setNotificationClient(notify);} ,[notes,notify,isVisible]);

  useEffect(() => {setCancelReasonFinal(reason)},[reason,isVisible,notify]);
  useEffect(()=>{setReasonError("")},[isVisible]);
  useEffect(()=>{setError("")},[isVisible]);
  
  
  const cancelNotesValidation=(refundvalid,cancelReasonFinal)=>{

    console.log("cancel Reason",cancelReasonFinal);
    

     if(refundvalid=="" || refundvalid==null)
    {
     setError("please enter notes");
    }
     if(cancelReasonFinal=="" || cancelReasonFinal==null){
     setReasonError("please select reason");
     }
    if(refundvalid !=="" && refundvalid!==null && cancelReasonFinal!=="" && cancelReasonFinal!==null)
    {
     setError("");
     setReasonError("");
     return true;
    }
    return false;
   }
   const handleChange=(event)=>{
    console.log("checking");
    setNotificationClient(event.target.checked);
  }

  const handleChangeCancelReason = (event,  {value} ) => {

    console.log("change value",value);
    setCancelReasonFinal(value);
    setDropdownOpen(false); // Close the dropdown
  };
  
  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="row mt-1 ml-1">
            <div >Are you sure you want to cancel the order? Please mention the reasons in the notes below *</div>
            
            <div className="form-group col">
            
            
              <Dropdown
                value={cancelReasonFinal ?? ""}
                onChange={(event,  {value} ) => {

                  console.log("change value",value);
                  setCancelReasonFinal(value);
                  setDropdownOpen(false); }}
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                onClick={() => setDropdownOpen(true)}
                open={dropdownOpen}
                className={
                  "form-control" + (reasonError ? " has-error" : "")
                }
              />
              
            
            <span className="help-block">{reasonError}</span>
            </div>
            </div>
            
            <div className="row mt-1 ml-1">
            <div className="form-group col">
              
              <textarea
                value={cancelNotesFinal ?? ""}
                onChange={(event) => {setCancelNotesFinal(event.target.value);setError("");}}
                id="cancelNotes"
                name="cancelNotes"
                placeholder="Cancel Notes"
                className="form-control py-2"
              ></textarea>
            </div>
          </div>
          <div className="row ml-3 mb-2"><span className="help-block">{error}</span></div>
     

      
      <div className="row">
          <div className="form-group ml-1">
            <div className="custom-control custom-checkbox">
              <input
                checked={notificationClient}
                onChange={handleChange}
                type="checkbox"
              /> &nbsp;
              <label style={{ "textAlign": "center" }}>Notify Client?</label>

            </div>

          </div>  
            <div className="form-group  col text-right">
        <button onClick={(event)=>{if(cancelNotesValidation(cancelNotesFinal,cancelReasonFinal))onCancel(cancelNotesFinal,notificationClient,cancelReasonFinal)} }className="btn button btn-danger">
          Cancel 
        </button> &nbsp;&nbsp;
        <button className="btn btn-secondary button" onClick={onHide}>
          Exit
        </button>
        </div>
        </div>
        </Modal.Body>
    </Modal>
  );
}

export default ConfirmOrderCancelViewModal;
