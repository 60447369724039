import React, { useEffect, useState } from "react";
import { getUserId } from "services/UserService";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import ManageProfessionals from "./ManageProfessionals";
import ClientOrderDetails from "./ClientOrderDetails";
import ProfessionalForm from "./ProfessionalForm";
import TabsShow from "./TabsShow";
import "./index.scss";

function HomecareMwebPanelNew(props) {
  const userId = getUserId();
  const [searchOrders, setSearchOrders] = useState([]);
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState({});
  const [cities, setCities] = useState([]);
  const [labs, setLabs] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [isShowOrder, setIsShowOrder] = useState(false);
  const [isShowClient, setIsShowClient] = useState(false);
  const [clientDetail, setClientDetail] = useState({});
  const [professionals, setProfessionals] = useState([]);
  const [isProfessionalFormVisible, setIsProfessionalFormVisible] = useState(false);
  const [isNewProfessional, setIsNewProfessional] = useState(false);
  const [professionalEdit, setProfessionalEdit] = useState({});
  const [selectedTab, setSelectedTab] = useState("key_order_today");
  const BASE_URL_PROFESSIONALS = "/home-care-professional";
  const [selectedMainTab, setSelectedMainTab] = useState("key_order_form");
  const [upcomingBookings,setUpcomingBookings]=useState([])
  // console.log("isprofesional", isNewProfessional, isProfessionalFormVisible);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUser();
        setIsShowOrder(true);
        const today = moment().format("YYYY-MM-DD");

        // Additional code if needed...
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchData();
  }, []);

  const creatingCleverTapProfileUser=()=>{
    const userId= localStorage.getItem("userId");
    const email = localStorage.getItem("userEmail");
    return (window.clevertap.onUserLogin.push({
     "Site": {
       "Identity": userId,
       "Email": email,
       "MSG-email": false,
       "MSG-push": false,
       "MSG-sms": false,
       "MSG-whatsapp": false,
     },
     "cart_data": []
   }))
   }
   useEffect(()=>{
     creatingCleverTapProfileUser()
   },[])

   useEffect(()=>{
    const tabSelected= localStorage.getItem("selectedTab")
    if(tabSelected){
      setSelectedTab(tabSelected)
    }
    else{
     setSelectedTab("key_order_today")
    }
     
   },[selectedTab])
  const fetchCities = async () => {
    try {
      //   showLoading();
      const cities = (await fetchCities()) ?? [];
      setCities({ cities });
    } catch (error) {
      //  this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      // hideLoading();
    }
  };

  const fetchProfessionals = async (user) => {
    
    const homecare = user?.homecare_service_id;
    
    if (!homecare) {
      // showErrorNotification("Invalid user or homecare service id!");
      return;
    }

    try {
      const url = `${BASE_URL_PROFESSIONALS}/homecareId/${homecare}`;
    
      const { data: professionaldata = [] } = await PhoenixAPI.get(url);
      setProfessionals(professionaldata);
    } catch (error) {
      console.log("Error on fetching professionals!", error);
      /*this.showErrorNotification(
        "Error on fetching professionals: ",
        error.message)*/
    } finally {
      // this.hideLoading();
    }
  };

  const fetchUser = async () => {
    try {
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      // console.log("users are in mwebhomecare", users);
      //console.log("fetchUser:: Response: ", { response });
      setUser({ user: users && users.length ? users[0] : null });
      // console.log("fetch user", users[0]);
      fetchProfessionals(users[0]);
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
      //  showErrorNotification("Error on fetching user!" + error.message);
    } finally {
    }
  };


    return(<div className='homecare-panel-main'>{user && Object.keys(user).length>0 &&     
    isShowOrder?(<>
     
     <TabsShow  selectedMainTab={selectedMainTab}setSelectedMainTab={setSelectedMainTab}  setOrders={setOrders} orders={orders}   setClientDetail={setClientDetail}  homecareServiceId={user.user?.homecare_service_id} user={user} setSearchOrders={setSearchOrders} setIsShowClient={setIsShowClient} setIsShowOrder={setIsShowOrder} searchOrders=
     {searchOrders} setSelectedStartDate={setSelectedStartDate} setProfessionals={setProfessionals} setSelectedEndDate={setSelectedEndDate} selectedStartDate={selectedStartDate} selectedEndDate={selectedEndDate} selectedTab={selectedTab} professionals={professionals}setSelectedTab={setSelectedTab}
     isProfessionalFormVisible={isProfessionalFormVisible} setIsProfessionalFormVisible={setIsProfessionalFormVisible} professionalEdit={professionalEdit} isNewProfessional={isNewProfessional} setIsNewProfessional={setIsNewProfessional} setProfessionalEdit={setProfessionalEdit}
    upcomingBookings={upcomingBookings} setUpcomingBookings={setUpcomingBookings}
     /></>
    ):
   
    isShowClient?(<ClientOrderDetails item={clientDetail} setClientDetail={setClientDetail} setIsShowClient={setIsShowClient} setIsShowOrder={setIsShowOrder} user={user} orders={orders} professionals={professionals} setOrders={setOrders}/>):
    isProfessionalFormVisible?(<ProfessionalForm setIsShowOrder={setIsShowOrder} homecareServiceId={user.user?.homecare_service_id} isProfessionalFormVisible={isProfessionalFormVisible} setIsProfessionalFormVisible={setIsProfessionalFormVisible} professionalEdit={professionalEdit} isNewProfessional={isNewProfessional} setIsNewProfessional={setIsNewProfessional} setProfessionalEdit={setProfessionalEdit}/>)
   
   :<></>
    }</div> )
  } 

 

const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomecareMwebPanelNew);
