import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";


const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class TreatmentPlanSuggestionsForm extends Component {
  constructor(props) {
    super(props);

    const { treatmentPlanSuggestions = [], onTreatmentPlanSuggestionsUpdated = (f) => f, medicines=[], supplements=[], countryId=null } = this.props;

    this.onTreatmentPlanSuggestionsUpdated = onTreatmentPlanSuggestionsUpdated;

    this.state = {
      treatmentPlanSuggestions,
      treatmentPlanSuggestion: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
      medicines,
      supplements,
      countryId
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Medicine",
        selector: "textEng",
        sortable: true,
        wrap: true,
        format: (medicine) => (
          <div className="text-overflow-ellipsis">
            {this.getMedicineName(medicine)}
          </div>
        ),
      },
      {
        name: "Supplement",
        selector: "textArabic",
        sortable: true,
        wrap: true,
        format: (supplement) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {this.getSupplementName(supplement)}
          </div>
        ),
      },
    {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (treatmentPlanSuggestion) => <Status isActive={treatmentPlanSuggestion.status==="Active"} />,
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (treatmentPlanSuggestion) => this.renderActionBar(treatmentPlanSuggestion),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  getMedicineName=(medicine)=>{
    const {medicines=[]}=this.state;
     if(!medicine) return "";

     const option=medicines.find((it)=>it.id===medicine.medicineId)
     return option ? option.internalName :""
     
  }

  getSupplementName=(supplement)=>{
    const {supplements=[]}=this.state;
    if(!supplement) return "";
    const option=supplements.find((it)=>it.id===supplement.supplementId)
    return option ? option.internalName :""
  }
  
  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      treatmentPlanSuggestion: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const treatmentPlanSuggestion = { ...this.state.treatmentPlanSuggestion };
    treatmentPlanSuggestion[input.name] = input.value;
    this.setState({ treatmentPlanSuggestion });
  };

  handleAddNew = () => {
    const treatmentPlanSuggestion = {};
    const {countryId}=this.state;
    console.log("countryId:", countryId);
    treatmentPlanSuggestion["country"]=countryId
    this.setState({treatmentPlanSuggestion}, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
      this.clearForm();
      const treatmentPlanSuggestions = this.state.treatmentPlanSuggestions ?? [];
      const treatmentPlan = treatmentPlanSuggestions.find((it) => it.id === id);
      if (!treatmentPlan) return;
  
      this.setState({treatmentPlanSuggestion:treatmentPlan }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteTreatmentPlanSuggestion(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { treatmentPlanSuggestion = {} } = this.state;
    if (treatmentPlanSuggestion.id === null || treatmentPlanSuggestion.id === undefined){
      this.createTreatmentPlanSuggestion();
    }
      else{
        this.updateTreatmentPlanSuggestion(treatmentPlanSuggestion.id);
      }
   
      
    
    
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { treatmentPlanSuggestion = {} } = this.state;
    const { status } = treatmentPlanSuggestion;

   
    return Object.keys(errors).length === 0 ? null : errors;
  };

  createTreatmentPlanSuggestion = () => {
    const { treatmentPlanSuggestions = [], treatmentPlanSuggestion } = this.state;
    const ids =
      treatmentPlanSuggestions.length > 0 ? treatmentPlanSuggestions.map((it) => it.id) : null;
    treatmentPlanSuggestion.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
     treatmentPlanSuggestions.length > 0
        ? treatmentPlanSuggestions.filter((it) => it.rank).map((it) => it.rank)
        : null;
    treatmentPlanSuggestion.rank = ranks ? Math.max(...ranks) + 1 : 1;
    treatmentPlanSuggestion.is_new = true;

    this.setState({ treatmentPlanSuggestions: [...treatmentPlanSuggestions, treatmentPlanSuggestion] }, () => {
      this.showSuccessNotification("Treatment Plan Suggestion Created Successfully!");
      this.onTreatmentPlanSuggestionsUpdatedLocally();
    });
  };

  updateTreatmentPlanSuggestion = (id) => {
    const { treatmentPlanSuggestions = [], treatmentPlanSuggestion } = this.state;
    const newTreatmentPlanSuggestions = treatmentPlanSuggestions.map((it) =>
      it.id === id ? treatmentPlanSuggestion : it
    );
    this.setState({ treatmentPlanSuggestions: newTreatmentPlanSuggestions }, () => {
      this.showSuccessNotification("Treatment Plan Suggestion Updated Successfully!");
      this.onTreatmentPlanSuggestionsUpdatedLocally();
    });
  };

  deleteTreatmentPlanSuggestion = (id) => {
    const { treatmentPlanSuggestions = [] } = this.state;
    const treatmentPlanSuggestionToDelete = treatmentPlanSuggestions.find((it) => it.id === id) || {};
    if (!treatmentPlanSuggestionToDelete) {
      console.log("deleteTreatmentPlanSuggestion:: Treatment Plan Suggestion not found with id=", id);
      this.showErrorNotification("Treatment Plan Suggestion not found!");
      return;
    }

    const newTreatmentPlanSuggestions = treatmentPlanSuggestions
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > treatmentPlanSuggestionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ treatmentPlanSuggestions: newTreatmentPlanSuggestions }, () => {
      this.showSuccessNotification("Treatment Plan Suggestion Deleted Successfully!");
      this.onTreatmentPlanSuggestionsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { treatmentPlanSuggestions = [], sortColumn, sortDirection } = this.state;
    const sortedTreatmentPlanSuggestions = _.sortBy(
      treatmentPlanSuggestions,
      sortColumn,
      sortDirection
    );
    const fromTreatmentPlanSuggestion = { ...sortedTreatmentPlanSuggestions[fromIndex] };
    const toTreatmentPlanSuggestion = { ...sortedTreatmentPlanSuggestions[toIndex] };

    if (fromTreatmentPlanSuggestion && toTreatmentPlanSuggestion ) {
      const tempRank = fromTreatmentPlanSuggestion.rank;
      fromTreatmentPlanSuggestion.rank = toTreatmentPlanSuggestion.rank;
      toTreatmentPlanSuggestion.rank = tempRank;

      const newTreatmentPlanSuggestions = treatmentPlanSuggestions.map((it) => {
        if (it.id === fromTreatmentPlanSuggestion.id) return fromTreatmentPlanSuggestion;
        else if (it.id === toTreatmentPlanSuggestion.id) return toTreatmentPlanSuggestion;
        else return it;
      });
      this.setState({ treatmentPlanSuggestions: newTreatmentPlanSuggestions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onTreatmentPlanSuggestionsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onTreatmentPlanSuggestionsUpdatedLocally = () => {
    this.clearForm();
    this.onTreatmentPlanSuggestionsUpdated(
      this.state.treatmentPlanSuggestions.map((treatmentPlanSuggestion) => {
        const t = { ...treatmentPlanSuggestion};
        if (t.is_new) {
          delete t.id;
          delete t.is_new;
        }
        return t;
      })
    );
  };

  findCity = (id) => {
    const cities = this.props.cities || [];
    return cities.find((city) => city.id === Number(id));
  };

  renderActionBar = (treatmentPlanSuggestion) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(treatmentPlanSuggestion.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(testimonial.id)}
          onClick={() =>
            this.setState({ treatmentPlanSuggestionToDelete: treatmentPlanSuggestion.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    const isEditable = this.props.isEditable ?? true;
    return isEditable && this.state.treatmentPlanSuggestion.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      treatmentPlanSuggestions = [],
      treatmentPlanSuggestion = {},
      errors,
      editables,
      sortColumn,
      medicines,
      supplements,
    } = this.state;
    const { countryId } = this.props;

    // if (testimonials && testimonials.length) {
    //   testimonials.forEach(testimonial => {
    //     if (!testimonial.cityName) 
    //       testimonial.cityName = this.findCity(testimonial.cityId)?.city_name;
    //   })
    // }
    const isNewTreatmentPlanSuggestion=
      treatmentPlanSuggestion.id === null || treatmentPlanSuggestion.id === undefined;
    const isEditable = this.props.isEditable ?? true;

    const form = (
      <>
      <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedSupplements">Suggested Medicines</label>
            <div className="input-group user-input-container">
            <Dropdown
                value={treatmentPlanSuggestion.medicineId ?? ""}
                onChange={(event, value) => {
                  this.setState({
                    treatmentPlanSuggestion: {
                      ...treatmentPlanSuggestion,
                      medicineId: value.value,
                    },
                  });
                }}
                id="suggestedMedicine"
                name="suggestedMedicine"
                placeholder="Suggested Medicine"
                search
                selection
                options={medicines.map((it)=>{
                  return {key:it.id,value:it.id,text:it.internalName}
                })}
                disabled={treatmentPlanSuggestion.supplementId ? true:(!isNewTreatmentPlanSuggestion && !editables.suggestedMedicine)}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("suggestedMedicine")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="suggestedSupplements">Suggested Supplements</label>
            <div className="input-group user-input-container">
            <Dropdown
                value={treatmentPlanSuggestion.supplementId ?? ""}
                onChange={(event, value) => {
                  this.setState({
                    treatmentPlanSuggestion: {
                      ...treatmentPlanSuggestion,
                      supplementId: value.value,
                    },
                  });
                }}
                id="SuggestedSupplement"
                name="SuggestedSupplement"
                placeholder="Suggested Supplement"
                search
                selection
                options={supplements.map((it)=>{
                  return {key:it.id,value:it.id,text:it.internalName}
                })}
                disabled={treatmentPlanSuggestion.medicineId ? true :(!isNewTreatmentPlanSuggestion && !editables.suggestedSupplement)}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("suggestedSupplement")}
            </div>
          </div>
        </div>
        <div className="row">
       <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={treatmentPlanSuggestion.status ?? ""}
                onChange={(event, value) => {
                  this.setState({
                    treatmentPlanSuggestion: {
                      ...treatmentPlanSuggestion,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewTreatmentPlanSuggestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
        
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={ treatmentPlanSuggestions ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                disabled={!isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {treatmentPlanSuggestion.id === null || treatmentPlanSuggestion.id === undefined
                ? "Add Treatment Plan Suggestion"
                : "Edit Treatment Plan Suggestion"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {treatmentPlanSuggestion.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Treatment Plan Suggestion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteTreatmentPlanSuggestion(this.state.treatmentPlanSuggestionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TreatmentPlanSuggestionsForm.propTypes = {
  treatmentPlanSuggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTreatmentPlanSuggestionsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

TreatmentPlanSuggestionsForm.defaultProps = {
  treatmentPlanSuggestions: [],
  onTreatmentPlanSuggestionsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanSuggestionsForm);
