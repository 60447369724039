import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: "NONE", value: null, label: "NONE" };

SelectPage.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedPageId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectPage.defaultProps = {
  pages: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectPage(props) {
  const {
    pages,
    selectedPageId,
    onSelect,
    menuPlacement,
    isDisabled,
    showOnlyActive,
  } = props;

  const findPage = (id) => pages.find((it) => it.id === id);

  const options = createOptions(pages, showOnlyActive);
  const selectedPage = selectedPageId ? findPage(selectedPageId) : null;
  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedPageId}`}
      value={createSelectedOption(selectedPage)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      isDisabled={isDisabled}
      menuPlacement={menuPlacement}
    />
  );
}

function createOptions(pages, showOnlyActive) {
  if (!pages || !pages.length) return [];

  const [activePages, inactivePages] = _.partition(
    pages,
    (it) => it.isActive ?? false
  );
  activePages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  if (showOnlyActive) return activePages.map(createOption);

  inactivePages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  return [
    {
      label: "Active",
      options: activePages.map(createOption),
    },
    {
      label: "Inactive",
      options: inactivePages.map(createOption),
    },
  ];
}

function createOption(category) {
  if (!category) return;
  return {
    key: category.id,
    value: category.id,
    label: category.name,
  };
}

function createSelectedOption(category) {
  return category
    ? {
        key: category.id,
        value: category.id,
        label: category.name,
      }
    : OPTION_NONE;
}

export default SelectPage;
