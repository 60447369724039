import React, { Component } from "react";
import { connect } from "react-redux";
import PdfPreview from "components/Common/PdfPreview";
import Pdf from "@material-ui/icons/PictureAsPdf";

class EditResultsViewModal extends Component {
  constructor(props) {
    super(props);
    const { uploadPDFFiles = [], handleViewResultPdfStatus = (f) => f } =
      props || {};

    this.handleViewResultPdfStatus = handleViewResultPdfStatus;
    this.state = {
      selectedFiles: [],
      selectedAcceptedFiles: [],
      selectedRejectedFiles: [],
      selectedRejectionReason: [],
    };
  }

  handleSelectAccept = (e, file, index) => {
    const { selectedFiles } = this.state;
    const target = e.target.checked;
    const { uploadPDFFiles = [] } = this.props;
    let tests = [...uploadPDFFiles];
    file.isAccepted = target;
    tests[file.index] = file;
    this.setState({ selectedFiles: tests }, () =>
      this.handleViewResultPdfStatus(tests)
    );
  };

  handleSelectReject = (e, file, index) => {
    const { selectedFiles } = this.state;
    const target = e.target.checked;
    const { uploadPDFFiles = [] } = this.props;
    let tests = [...uploadPDFFiles];
    file.isRejected = target;
    tests[file.index] = file;
    this.setState({ selectedFiles: tests }, () =>
      this.handleViewResultPdfStatus(tests)
    );
  };

  handeRejectReason = (e, file, index) => {
    const { selectedFiles } = this.state;
    const target = e.target.value;
    const { uploadPDFFiles = [] } = this.props;
    let tests = [...uploadPDFFiles];
    file.rejectReason = target;
    tests[file.index] = file;
    this.setState({ selectedFiles: tests }, () =>
      this.handleViewResultPdfStatus(tests)
    );
  };

  renderPdfFiles = () => {
    const { uploadPDFFiles = [] } = this.props || {};
    const {
      isAcceptedFile,
      selectedFiles,
      selectedAcceptedFiles,
      selectedRejectedFiles,
    } = this.state || {};

    return uploadPDFFiles.map((file, index) => {
      const fileSource = file.s3_url;
      const isPdfFile =
        typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf");
      const preview = isPdfFile ? (
        <div className="image-area" style={{ marginBottom: "30px" }}>
          {/* <PdfPreview
            pdfFile={isPdfFile ? fileSource : null}
            pdfFileUrl={isPdfFile ? null : fileSource}
          /> */}
           <form>
                    <div className="form-row">
                      <div className="col-12">
                        <a
                          className="view-pdf"
                          href={fileSource}
                          target="_blank"
                        >
                          <label>
                            <Pdf></Pdf> View Result document
                          </label>
                        </a>
                      </div>
                    </div>
                  </form>
        </div>
      ) : (
        <div className="image-area" style={{ marginBottom: "30px" }}>
          <img
            id="selected_front_image"
            name="selected_front_image"
            src={fileSource}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
      );
      return (
        <div className="row">
          <div className="form-group col" style={{ width: "200px" }}>
            {preview}
          </div>

          <div className="form-group col">
            <input
              value={true}
              checked={
                (selectedFiles && selectedFiles[index]?.isAccepted) || false
              }
              onChange={(event, value) => {
                this.handleSelectAccept(event, file, index);
              }}
              id="isRequired"
              name="isRequired"
              type="checkbox"
              className="larger-checkbox"
              disabled={selectedFiles && selectedFiles[index]?.isRejected}
            />
          </div>
          <div className="form-group col">
            <input
              value={true}
              checked={
                (selectedFiles && selectedFiles[index]?.isRejected) || false
              }
              onChange={(event, value) => {
                this.handleSelectReject(event, file, index);
              }}
              id="isRequired"
              name="isRequired"
              type="checkbox"
              className="larger-checkbox"
              disabled={selectedFiles && selectedFiles[index]?.isAccepted}
            />
          </div>
          <div className="form-group col">
            <textarea
              value={
                (selectedFiles && selectedFiles[index]?.rejectReason) || ""
              }
              type="text"
              id="reject-reason"
              name="reject-reason"
              className={"form-control"}
              onChange={(event) => this.handeRejectReason(event, file, index)}
            />
          </div>
        </div>
      );
    });
  };

  render() {
    const { uploadPDFFiles = [] } = this.props || {};

    return <div className="">{this.renderPdfFiles()}</div>;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditResultsViewModal);
