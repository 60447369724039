import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tabs, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries, fetchCities } from "services/RegionService";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectPackages from "./SelectPackages";
import { fetchBloodPackages, fetchMiniPackages } from "services/productService";
import SelectCities from "components/Common/SelectCities";

const BASE_URL_HOMECARE_SERVICES = "home-care-service/";
const BASE_URL_LABORATORY_LIST = "create-laboratory/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class HomecareServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      searchTerm: null,
      homecareService: {},
      errors: {},
      editables: {},
      isHomecareServiceViewModalVisible: false,

      laboratoryList: [],
      shouldLabAssociationVisible: true,
      selectedLabList: [],
      brandIsChecked : false,

      cities: [],

    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (homecareService) => (
          <Status isActive={homecareService.isActive} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (homecareService) => this.renderViewButton(homecareService.id),
      },
    ];
  }

  componentDidMount() {
    // this.fetchAclUser();
    // this.fetchCountries();
    this.fetchHomecareServices();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState(
        {
          countries: nextProps.countries,
          selectedCountry: nextProps.selectedCountry,
        },
        () => {
          this.fetchCustomPackagesForDropdown();
          this.fetchBloodPackages();
          this.fetchMiniPackages();
          this.fetchCities();
        }
      );
    }
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }
  };

  fetchCities = async () => {
    const { selectedCountry } = this.state;
    console.log("fetchCities", selectedCountry);
    if (!selectedCountry) return;
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      const citiesBasedOnCountry = cities.filter(
        (item) => item?.country?.id == selectedCountry.id
      );

      console.log("fetchCitites:", citiesBasedOnCountry);
      this.setState({ cities: citiesBasedOnCountry });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchHomecareServices = async () => {
    this.showLoading();
    try {
      const { data: homecareServices = [] } = await PhoenixAPI.get(
        BASE_URL_HOMECARE_SERVICES
      );
      this.setState({ homecareServices });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.setState({ isSupplementCategoriesFetched: true });
      this.hideLoading();
    }
  };

  // this function is to fetch all the data of lab association
  fetchLaboratoryList = async () => {
    this.showLoading();
    try {
      const { data: laboratoryList = [] } = await API.get(
        BASE_URL_LABORATORY_LIST
      );
      this.setState({ laboratoryList });
      console.log(laboratoryList);
      console.log("we are in this try");
    } catch (error) {
      console.log("Error on fetching laboratory services", error);
      this.showErrorNotification(
        "Error on fetching laboratory services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.setState({ isSupplementCategoriesFetched: true });
      this.hideLoading();
    }
  };

  // this function will dictate that lab association dropbox should be visible or not
  handleLabAssociationCheckbox = () => {
    if (this.state.shouldLabAssociationVisible) {
      this.setState({ shouldLabAssociationVisible: false });
    } else {
      this.setState({ shouldLabAssociationVisible: true });
    }
    if (this.state.brandIsChecked) {
      this.setState({ brandIsChecked: false });
    } else {
      this.setState({ brandIsChecked: true });
    }
  };

  fetchBloodPackages = async () => {
    const { selectedCountry } = this.state;
    try {
      const bloodPackages = await fetchBloodPackages();
      const activeBloodPackagesForCountry = bloodPackages.filter(
        (item) => item.is_active === true && item.country === selectedCountry.id
      );
      const mappingBloodPackages = activeBloodPackagesForCountry.map((item) => {
        return { id: item.id, name: item.internal_name };
      });
      this.setState({ bloodPackages: mappingBloodPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching blood packages", error);
    }
  };

  fetchMiniPackages = async () => {
    const { selectedCountry } = this.state;
    try {
      const miniPackages = await fetchMiniPackages();

      const activeMiniPackagesForCountry = miniPackages.filter(
        (item) =>
          item.isActive === true && item.countryId === selectedCountry.id
      );
      const mappingMiniPackages = activeMiniPackagesForCountry.map((item) => {
        return { id: item.id, name: item.name };
      });
      this.setState({ miniPackages: mappingMiniPackages ?? [] });
    } catch (error) {
      console.log("Error on fetching mini packages", error);
    }
  };

  fetchCustomPackagesForDropdown = async () => {
    const { selectedCountry } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    try {
      const { data = [] } = await PhoenixAPI.get(
        `custom-packages-category-level/country/${selectedCountry.id}`
      );
      const {
        dnaPackages,
        functionalPackages,
        intoleranceAndAllergyPackages,
        ivPackages,
        otherCustomPackages,
        physiotherapyPackages,
      } = data || {};
      this.setState({
        dnaPackages,
        functionalPackages,
        intoleranceAndAllergyPackages,
        ivPackages,
        otherCustomPackages,
        physiotherapyPackages,
      });
    } catch (error) {
      console.log("Error in fetching custom packages", error.message);
      this.showErrorNotification(
        "Error in fetching custom packages",
        error.message
      );
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  findCountryName = (id) => {
    const countries = this.state.countries || [];
    const countryObject = countries.find((country) => country.id === id);
    return countryObject?.country_name ?? "";
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const homecareService = { ...this.state.homecareService };
    homecareService[input.name] = input.value;
    this.setState({ homecareService });
  };

  handleAddNewHomecareService = () => {
    this.setState({ selectedLabList: [] });
    this.setState({ shouldLabAssociationVisible: true });
    this.setState({ isNewHomecareService: true }, () =>
      this.showHomecareServiceViewModal()
    );
  };

  handleViewHomecareService = (id) => {
    const homecareServices = this.state.homecareServices ?? [];
    const homecareService = homecareServices.find((it) => it.id === id);
    this.fetchLaboratoryList();
    this.setState({
      selectedLabList: {
        ...this.state.selectedLabList,
        selected: homecareService.labs,
      },
    });
    if (homecareService.labs.length > 0) {
      this.setState({
        shouldLabAssociationVisible: false,
        brandIsChecked: true,
      });
    }
    if (!homecareService) {
      console.log(`Homecare Service with id ${id} is not found!`);
      return;
    }

    this.setState({ homecareService, isNewHomecareService: false }, () =>
      this.showHomecareServiceViewModal()
    );
  };

  handleSaveHomecareService = () => {
    const { selectedCountry = null } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    this.saveHomecareService();
  };

  saveHomecareService = async () => {
    const { homecareService } = this.state;
    console.log(this.state.selectedLabList?.selected);
    console.log(homecareService);
    homecareService.labs = this.state.selectedLabList?.selected;
    if (typeof homecareService.country !== "number") {
      homecareService.country = homecareService.country?.id;
    }
    try {
      this.showLoading();
      const isValidId =
        homecareService.id !== null && homecareService.id !== undefined;

      const config = {
        method: isValidId ? "PATCH" : "POST",
        url: isValidId
          ? `${BASE_URL_HOMECARE_SERVICES}${homecareService.id}/`
          : BASE_URL_HOMECARE_SERVICES,
        data: homecareService,
      };
      const response = await await PhoenixAPI.request(config);
      console.log("Response: ", response);

      this.hideHomecareServiceViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Homecare Service Updated Successfully."
          : "Homecare Service Created Successfully."
      );
      this.fetchHomecareServices();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
      this.setState({
        laboratoryList: [],
        shouldLabAssociationVisible: true,
        selectedLabList: [],
      });
    }
  };

  validate = () => {
    const { homecareService = {} } = this.state;
    const errors = {};

    const { name, isActive, countryId, emailForCommunication } =
      homecareService;

    if (!name || name.trim() === "") errors.name = "Name cannot be blank";
    else if (name.length > 100)
      errors.name = "Name is limited to 100 characters";

    if (isActive === null) errors.status = "Status is a required field!";

    if (countryId === null || countryId === undefined)
      errors.country = "Country cannot be blank";
    if (emailForCommunication && emailForCommunication.length > 100) {
      errors.emailForCommunication = "Email is limited to 100 characters";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showHomecareServiceViewModal = () => {
    const { selectedCountry, isNewHomecareService } = this.state;

    if (isNewHomecareService) {
      const homecareService = {
        countryId: selectedCountry?.id,
        is_active: false,
      };
      this.setState({
        homecareService,
        isHomecareServiceViewModalVisible: true,
      });
    } else {
      this.setState({
        isHomecareServiceViewModalVisible: true,
      });
    }
  };

  hideHomecareServiceViewModal = () => {
    this.setState({ isHomecareServiceViewModalVisible: false });
    this.setState({
      laboratoryList: [],
      shouldLabAssociationVisible: true,
      brandIsChecked: false,
      selectedLabList: [],
    });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      homecareService: {},
      errors: {},
      editables: {},
    });
  };

  getFilteredHomecareServices = () => {
    const homecareServices = this.getHomecareServicesForCurrentCountry();
    return this.getSearchedHomecareServices(homecareServices);
  };

  getHomecareServicesForCurrentCountry = () => {
    const { homecareServices, selectedCountry } = this.state;
    return homecareServices && selectedCountry
      ? homecareServices.filter(
          (homecareService) => homecareService.countryId === selectedCountry.id
        )
      : [];
  };

  getSearchedHomecareServices = (homecareServices) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return homecareServices;

    const cleanedSearchTerm = searchTerm.slice(0).trim().toLowerCase();
    return homecareServices.filter((homecareService) => {
      const { id, name } = homecareService;
      return (
        (name && name.toLowerCase().indexOf(cleanedSearchTerm) !== -1) ||
        (id && id.toString() === cleanedSearchTerm)
      );
    });
  };

  handleAllActive = (packageName) => {
    const {
      homecareService,
      bloodPackages,
      dnaPackages,
      functionalPackages,
      intoleranceAndAllergyPackages,
      ivPackages,
      otherCustomPackages,
      physiotherapyPackages,
      miniPackages,
    } = this.state;

    let newHomecareService = {};
    if (packageName === "bloodPackages") {
      const bloodPackagesIds = bloodPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: bloodPackagesIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "ivPackages") {
      const ivPackageIds = ivPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: ivPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "physiotherapyPackages") {
      const physiotherapyPackageIds = physiotherapyPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: physiotherapyPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "dnaPackages") {
      const dnaPackageIds = dnaPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: dnaPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "functionalPackages") {
      const functionalPackageIds = functionalPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: functionalPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "intoleranceAndAllergyPackages") {
      const intoleranceAndAllergyPackageIds = intoleranceAndAllergyPackages.map(
        (it) => it.id
      );
      newHomecareService = {
        ...homecareService,
        [packageName]: intoleranceAndAllergyPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "otherCustomPackages") {
      const otherCustomPackageIds = otherCustomPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: otherCustomPackageIds,
      };

      this.setState({ homecareService: newHomecareService });
    } else if (packageName === "miniPackages") {
      const miniPackagesIds = miniPackages.map((it) => it.id);
      newHomecareService = {
        ...homecareService,
        [packageName]: miniPackagesIds,
      };

      this.setState({ homecareService: newHomecareService });
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewHomecareService(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewHomecareService ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  render() {
    const {
      homecareService,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isNewHomecareService,
      isHomecareServiceViewModalVisible,
      isEditable,
      homecareServices,
      bloodPackages,
      dnaPackages,
      functionalPackages,
      intoleranceAndAllergyPackages,
      ivPackages,
      otherCustomPackages,
      physiotherapyPackages,
      miniPackages,
      cities,
    } = this.state;

    const filteredHomecareServices = this.getFilteredHomecareServices() ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name*</label>
            <div className="input-group">
              <input
                value={homecareService?.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewHomecareService && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={homecareService.isActive}
                onChange={(event, value) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewHomecareService && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewHomecareService
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountryName(homecareService.countryId)
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="emailForCommunication">
              Email For Communication
            </label>
            <div className="input-group">
              <input
                value={homecareService?.emailForCommunication || ""}
                onChange={this.handleChange}
                id="emailForCommunication"
                name="emailForCommunication"
                type="text"
                placeholder="Email for communication"
                className={
                  "form-control py-2" +
                  (errors.email_for_communication ? " has-error" : "")
                }
                disabled={
                  !isNewHomecareService && !editables.email_for_communication
                }
              />
              {this.renderPencilIcon("email_for_communication")}
            </div>
            <span className="help-block">{errors.email_for_communication}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="homecare-associated">
              <input
                type="checkbox"
                checked={this.state.brandIsChecked}
                onChange={this.handleLabAssociationCheckbox}
              />
              Does this HomeCare have a lab associated? <br /> If Yes, Please
              tick and choose lab associated with this HomeCare
            </label>
          </div>
        </div>

        <div
          className="row"
          style={
            this.state.shouldLabAssociationVisible
              ? { display: "none" }
              : { display: "block" }
          }
        >
          <div className="form-group col-6">
            <label htmlFor="dnaPackages">Lab Association*</label>
            <div className="input-group">
              <SelectPackages
                tests={this.state.laboratoryList ?? []}
                selectedTestIds={this.state.selectedLabList?.selected ?? []}
                onSelect={(values) => {
                  this.setState({
                    selectedLabList: {
                      ...this.state.selectedLabList,
                      selected: values.map((it) => it.value),
                    },
                  });
                  console.log(this.state.selectedLabList);
                }}
                isDisabled={!isNewHomecareService && !editables.labAssociation}
                className={
                  "form-control" + (errors.bloodPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("labAssociation")}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="cities">Select Cities*</label>
            <div className="input-group user-input-container">
              <SelectCities
                id="cities"
                name="cities"
                cities={cities ?? []}
                selectedCityIds={homecareService.cities ?? []}
                onSelect={(options) => {
                  const newHomecareService = {
                    ...homecareService,
                    cities: options.map((it) => it.value),
                  };
                  this.setState({ homecareService: newHomecareService });
                }}
                showOnlyActive={true}
                isDisabled={!isNewHomecareService && !editables.cities}
              />
              {this.renderPencilIcon("cities")}
            </div>
            <span className="help-block">{errors.cities}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="dnaPackages">Add Blood Packages</label>
            <div className="input-group">
              <SelectPackages
                tests={bloodPackages ?? []}
                selectedTestIds={homecareService?.bloodPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      bloodPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewHomecareService && !editables.bloodPackages}
                className={"form-control" + (errors.bloodPackages ? " has-error" : "")}
              />
              {this.renderPencilIcon("bloodPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("bloodPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="ivPackages">Add IV Packages</label>
            <div className="input-group">
              <SelectPackages
                tests={ivPackages ?? []}
                selectedTestIds={homecareService?.ivPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      ivPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewHomecareService && !editables.ivPackages}
                className={
                  "form-control" + (errors.ivPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("ivPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("ivPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="physiotherapyPackages">
              Add Physiotherapy Packages
            </label>
            <div className="input-group">
              <SelectPackages
                tests={physiotherapyPackages ?? []}
                selectedTestIds={homecareService?.physiotherapyPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      physiotherapyPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={
                  !isNewHomecareService && !editables.physiotherapyPackages
                }
                className={
                  "form-control" +
                  (errors.physiotherapyPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("physiotherapyPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("physiotherapyPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="dnaPackages">Add DNA Packages</label>
            <div className="input-group">
              <SelectPackages
                tests={dnaPackages ?? []}
                selectedTestIds={homecareService?.dnaPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      dnaPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewHomecareService && !editables.dnaPackages}
                className={
                  "form-control" + (errors.dnaPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("dnaPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("dnaPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="functionalPackages">Add Functional Packages</label>
            <div className="input-group">
              <SelectPackages
                tests={functionalPackages ?? []}
                selectedTestIds={homecareService?.functionalPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      functionalPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={
                  !isNewHomecareService && !editables.functionalPackages
                }
                className={
                  "form-control" +
                  (errors.functionalPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("functionalPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("functionalPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="intoleranceAndAllergyPackages">
              Add Intolerance & Allergy Packages
            </label>
            <div className="input-group">
              <SelectPackages
                tests={intoleranceAndAllergyPackages ?? []}
                selectedTestIds={
                  homecareService?.intoleranceAndAllergyPackages ?? []
                }
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      intoleranceAndAllergyPackages: values.map(
                        (it) => it.value
                      ),
                    },
                  });
                }}
                isDisabled={
                  !isNewHomecareService &&
                  !editables.intoleranceAndAllergyPackages
                }
                className={
                  "form-control" +
                  (errors.intoleranceAndAllergyPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("intoleranceAndAllergyPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) =>
                this.handleAllActive("intoleranceAndAllergyPackages")
              }
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="otherCustomPackages">
              Add Other Custom Packages
            </label>
            <div className="input-group">
              <SelectPackages
                tests={otherCustomPackages ?? []}
                selectedTestIds={homecareService?.otherCustomPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      otherCustomPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={
                  !isNewHomecareService && !editables.otherCustomPackages
                }
                className={
                  "form-control" +
                  (errors.otherCustomPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("otherCustomPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("otherCustomPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="miniPackages">Add Mini Packages</label>
            <div className="input-group">
              <SelectPackages
                tests={miniPackages ?? []}
                selectedTestIds={homecareService?.miniPackages ?? []}
                onSelect={(values) => {
                  this.setState({
                    homecareService: {
                      ...homecareService,
                      miniPackages: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewHomecareService && !editables.miniPackages}
                className={
                  "form-control" + (errors.miniPackages ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("miniPackages")}
            </div>
          </div>
          <div className="form-group col">
            <label></label>
            <br />
            <button
              type="button"
              onClick={(e) => this.handleAllActive("miniPackages")}
              className={"form-control btn btn-info mt-2 py-1"}
              // disabled={!isNewHomecareService}
            >
              Add All Active Packages
            </button>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewHomecareService}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredHomecareServices || []}
            columns={this.columns}
            defaultSortField="isActive"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredHomecareServices.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isHomecareServiceViewModalVisible}
          onHide={this.hideHomecareServiceViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Homecare Service:</span>
                {homecareService.name ? ` ${homecareService.name}` : ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveHomecareService}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_homecare_services">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomecareServices);
