import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";

class PageActions extends Component {
  constructor(props) {
    super(props);

    const { actions = [], onActionsUpdated = (f) => f } = this.props;

    this.onActionsUpdated = onActionsUpdated;

    this.state = {
      actions,
      action: {},
      errors: {},
      editables: {},
      sortColumn: "id",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
        format: (action) => (
          <div className="text-overflow-ellipsis">{action.name ?? ""}</div>
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (action) => this.renderActionBar(action),
      },
    ];
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      action: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const action = { ...this.state.action };
    action[input.name] = input.value;
    this.setState({ action });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { actions = [] } = this.state;
    const action = actions.find((it) => it.id === id);
    if (!action) return;

    this.setState({ action }, () => this.showAddEditModal());
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { action = {} } = this.state;
    if (action.id === null || action.id === undefined) this.createAction();
    else this.updateAction(action.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { name } = this.state.action || {};

    if (!name || name.trim() === "") errors.name = "Name is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createAction = () => {
    const { actions = [], action } = this.state;
    const ids = actions.length > 0 ? actions.map((it) => it.id) : null;
    action.id = ids ? Math.max(...ids) + 1 : 1;
    action.is_new = true;

    this.setState({ actions: [...actions, action] }, () => {
      this.showSuccessNotification("Action Created Successfully!");
      this.onActionUpdatedLocally();
    });
  };

  updateAction = (id) => {
    const { actions = [], action } = this.state;
    const newActions = actions.map((it) => (it.id === id ? action : it));
    this.setState({ actions: newActions }, () => {
      this.showSuccessNotification("Action Updated Successfully!");
      this.onActionUpdatedLocally();
    });
  };

  onActionUpdatedLocally = () => {
    this.clearForm();
    this.onActionsUpdated(
      this.state.actions.map((action) => {
        const a = { ...action };
        if (a.is_new) {
          delete a.id;
          delete a.is_new;
        }
        return a;
      })
    );
  };

  handleAddNewAction = () => {
    const action = {};
    this.setState({ action }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (action) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(action.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.action.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      actions = [],
      action = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewAction = action.id === null || action.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="name">Name*</label>
            <div className="input-group">
              <input
                value={action.name}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                disabled={!isNewAction && !editables.name}
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={actions || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewAction}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {action.id === null || action.id === undefined
                ? "Add Action"
                : "Edit Action"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {action.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

PageActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onActionsUpdated: PropTypes.func.isRequired,
};

PageActions.defaultProps = {
  actions: [],
  onActionsUpdated: (f) => f,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageActions);
