import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert, Modal } from "react-bootstrap";
import SelectProfessional from "./SelectProfessional";

AssignProfessional.propTypes = {
  orderId: PropTypes.number.isRequired,
  professionals: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedProfessionalId: PropTypes.number,
  expectedHoursOfWork: PropTypes.number,
  activeBookingsForCustomer: PropTypes.arrayOf(PropTypes.number),
  isVisible: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

AssignProfessional.defaultProps = {
  professionals: [],
  isVisible: false,
  onSave: (f) => f,
  onHide: (f) => f,
};

function AssignProfessional(props) {
  const {
    orderId,
    professionals,
    booking,
    activeBookingsForCustomer,
    isVisible,
    onSave,
    onHide,
  } = props;
  const { expected_hours_of_work, professional_id } = booking ?? {};

  const findProfessional = (id) => {
    return professionals.find((p) => p.id === id);
  };

  const [professional, setProfessional] = useState(
    findProfessional(professional_id) ?? null
  );
  const [expectedHoursOfWork, setExpectedHoursOfWork] = useState(
    expected_hours_of_work
  );
  const [isAlertVisible, setAlertVisible] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setProfessional(null);
    setExpectedHoursOfWork(null);
  }, [booking]);

  useEffect(() => {
    setProfessional(findProfessional(professional_id));
  }, [professional_id]);

  useEffect(
    () => setExpectedHoursOfWork(expected_hours_of_work),
    [expected_hours_of_work]
  );

  useEffect(() => setAlertVisible(true), [isVisible]);

  let alertMessage = "";
  const activeBookings =
    activeBookingsForCustomer && activeBookingsForCustomer.length
      ? activeBookingsForCustomer.filter((it) => it !== orderId)
      : [];
  if (activeBookings && activeBookings.length) {
    const n = activeBookings.length;
    alertMessage = (
      <>
        <span>{`There ${n > 1 ? "are" : "is"} ${n} more active ${
          n > 1 ? "bookings" : "booking"
        } for this customer.`}</span>
        <br />
        <span>{`Order Id${n > 1 ? "'s" : ""}: ${activeBookings.join(
          ", "
        )}`}</span>
      </>
    );
  }

  const validate = () => {
    const errors = {};
    if (!professional) {
      errors.professional = "Name cannot be blank!";
    }

    if (!expectedHoursOfWork) {
      errors.expectedHoursOfWork = "Expected Hours of Work cannot be blank!";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  const handleOnSave = () => {
    const errors = validate();
    if (errors) {
      setErrors(errors);
      props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
      return;
    }
    const details = {
      orderId,
      professionalId: professional.id,
      expectedHoursOfWork,
    };
    onSave(details);
  };

  const form = (
    <div className="container">
      <div className="row">
        <div className="form-group col">
          <label>Name*</label>
          <SelectProfessional
            professionals={professionals ?? []}
            selectedProfessionalId={professional?.id ?? null}
            onSelect={(option) => {
              console.log("Selected option: ", option);
              setProfessional(findProfessional(option));
            }}
          />
          <span className="help-block">{errors.professional}</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            value={professional?.phoneNumber ?? ""}
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            disabled={true}
            className="form-control py-2"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="expectedHoursOfWork">Expected Hours of Work*</label>
          <input
            value={expectedHoursOfWork ?? ""}
            onChange={(e) =>
              setExpectedHoursOfWork(e.target.valueAsNumber ?? null)
            }
            type="number"
            id="expectedHoursOfWork"
            name="expectedHoursOfWork"
            className="form-control py-2"
          />
          <span className="help-block">{errors.expectedHoursOfWork}</span>
        </div>
      </div>
      {isAlertVisible && alertMessage ? (
        <div className="row">
          <div className="form-group col">
            <Alert
              variant="warning"
              onClose={() => {
                setAlertVisible(false);
              }}
              dismissible
              className="mt-4"
            >
              {alertMessage}
            </Alert>
          </div>
        </div>
      ) : null}
    </div>
  );
  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Assign Professional</Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
      <Modal.Footer>
        <button onClick={onHide} className="btn button btn-danger">
          Cancel
        </button>
        <button
          className="btn btn-success button"
          onClick={(e) => handleOnSave()}
        >
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignProfessional);
