import React, { Component } from "react";
import DataTable from "react-data-table-component";
import { Card, Modal } from "react-bootstrap";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";

const BASE_URL_BEST_RECOMMENDED_DISCOUNTS = "coupon-code/supplement/best-seller-discount/";

const DEFAULT_ROWS_PER_PAGE = 20;
const ROWS_PER_PAGE_OPTIONS = [20, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class BestRecommendedSupplementsDiscounts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      discounts: [],
      discount: {},
      errors: {},
      editables: {},
      isEditable: true,
      isDiscountViewModalVisible: false,
    };
    this.columns = [
      {
        name: "Discount Percentage",
        selector: "discountPercentage",
        sortable: true,
        maxWidth: "150px",
      },
      {
        name: "Status",
        selector: "isActive",
        sortable: true,
        cell: (coupon) => <Status isActive={coupon.isActive} />,
        wrap: true,
      },
      {
        name: "Edit",
        center: true,
        minWidth: "170px",
        cell: (discount) => this.renderViewButton(discount.id),
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState(
        {
          countries: nextProps.countries,
          selectedCountry: nextProps.selectedCountry,
        },
        () => this.fetchBestDiscounts()
      );
    }
  }

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  fetchBestDiscounts = async () => {
    const selectedCountry = this.props.selectedCountry;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    const url = `${BASE_URL_BEST_RECOMMENDED_DISCOUNTS}${selectedCountry.id}`;
    try {
      const { data: bestDiscounts } = await PhoenixAPI.get(url);
      this.setState({ discounts: bestDiscounts });
    } catch (error) {
      console.log("Error on fetching discounts from this URL", url, error);
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["discountPercentage"];
    const discount = { ...this.state.discount };
    if (numberInputs.includes(input.name))
      discount[input.name] = input.value ? Number(input.value) : "";
    else discount[input.name] = input.value;
    this.setState({ discount });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewDiscount(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleViewDiscount = (discountId) => {
    const discounts = this.state.discounts ?? [];
    const discount = discounts.find((it) => it.id === discountId);
    if (!discount) {
      console.log(`Recommended supplement with id ${discountId} is not found!`);
      return;
    }
    this.setState({ discount, isNewOtherItem: false }, () =>
      this.showDiscountViewModal()
    );
  };

  handleAddNewDiscount = () => {
    const {discounts,selectedCountry}= this.state;
      console.log("discountsdiscounts", discounts, selectedCountry)
      const discountsForCountry =discounts && selectedCountry?discounts.find((p)=>p.countryId===selectedCountry.id):null;
    // this.setState({ isNewDiscount: true }, () => this.showDiscountViewModal());
    if (discountsForCountry) {
      const message = discountsForCountry.isActive
        ? "There is already an active Best Recommended Discount available."
        : "Please activate Best Recommended Discount.";
      this.showErrorNotification(message);
    } else {
      this.setState({ isNewDiscount: true }, () => this.showDiscountViewModal());
    }
  };

  showDiscountViewModal = () => {
    const { isNewDiscount } = this.state;
    const { selectedCountry } = this.props;
    if (isNewDiscount) {
      const discount = {
        countryId: selectedCountry.id,
        isActive: false,
      };
      this.setState({
        discount,

        isDiscountViewModalVisible: true,
      });
    } else {
      this.setState({ isDiscountViewModalVisible: true });
    }
  };

  hideDiscountViewModal = () => {
    this.setState({ isDiscountViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      discount: {},
      errors: {},
      editables: {},
    });
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    !this.state.isNewDiscount ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  isInBetween = (number, start, end) => number >= start && number <= end;

  validate = () => {
    const { discount = {} } = this.state;
    const errors = {};
    const { discountPercentage, isActive } = discount;
    if (isActive === null) errors.status = "Status is a required field!";

    if (
      discountPercentage === null ||
      discountPercentage === undefined ||
      discountPercentage.toString().trim() === ""
    ) {
      errors.discountPercentage = "Discount Percentage cannot be blank";
    } else if (Number.isNaN(Number(discountPercentage)))
      errors.discountPercentage =
        "Discount percentage should be a valid number";
    else if (!this.isInBetween(Number(discountPercentage), 0, 99999999))
      errors.discountPercentage =
        "Discount percentage should be bewteen 0 to 99999999";
    return Object.keys(errors).length === 0 ? null : errors;
  };
  createDiscount = async () => {
    const { discount, discounts } = this.state;
    try {
      this.showLoading();
      const response = await PhoenixAPI.post(
        "coupon-code/supplement/best-seller-discount",
        discount
      );
      console.log("response", response);
      this.showSuccessNotification(
        "Best Reommended Supplement Discount Created Successfully"
      );
      this.fetchBestDiscounts();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
      console.log("Error in occured in saving discount :", error.message);
    } finally {
      this.hideLoading();
    }
  };
  updateDiscount = async (id) => {
    const { discount, discounts } = this.state;
    try {
      this.showLoading();
      const response = await PhoenixAPI.patch(
        `coupon-code/supplement/best-seller-discount/`,
        discount
      );
      console.log("response", response);
      this.showSuccessNotification(
        "Best Reommended Supplement Discount Updated Successfully"
      );
      this.fetchBestDiscounts();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
      console.log("Error in occured in saving discount :", error.message);
    } finally {
      this.hideLoading();
    }
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const { discount = {} } = this.state;
    if (discount.id === null || discount.id === undefined)
      this.createDiscount();
    else this.updateDiscount(discount.id);
    this.hideDiscountViewModal();
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      discounts = [],
      discount,
      errors,
      editables,
      isNewDiscount,
      isEditable,
    } = this.state;
    const form = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Discount percentage *</label>
            <div className="input-group">
              <input
                value={discount.discountPercentage ?? ""}
                onChange={this.handleChange}
                id="discountPercentage"
                name="discountPercentage"
                type="number"
                placeholder="Discounted Percentage"
                className={
                  "form-control py-2" +
                  (errors.discountPercentage ? " has-error" : "")
                }
                disabled={!isNewDiscount && !editables.discountPercentage}
              />
              {this.renderPencilIcon("discountPercentage")}
            </div>
            <span className="help-block">{errors.discountPercentage}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={discount.isActive}
                onChange={(event, value) => {
                  this.setState({
                    discount: {
                      ...discount,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewDiscount && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );
    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <button
                onClick={this.handleAddNewDiscount}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <h3 className="p-head">Best Recommended Supplements Discounts</h3>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={discounts || []}
            pagination
            responsive
            sortIcon={<ArrowDownward></ArrowDownward>}
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isDiscountViewModalVisible}
          onHide={this.hideDiscountViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSubmit}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BestRecommendedSupplementsDiscounts);
