import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import _ from "lodash";
const DEFAULT_ROWS_PER_PAGE = 100;
const ROWS_PER_PAGE_OPTIONS = [100, 200,300];
class PackageRanking extends Component {
    constructor(props) {
        super(props);
    
        const { packages = [], onPackagesUpdated= (f) => f ,type=""} = this.props;
        this.onPackagesUpdated = onPackagesUpdated;

        this.state = {
          packages,
          packageDetail: {},
          sortColumn: "rank",
          sortDirection: "asc",
        };
       this.websitePackagesColumn=[

        {   
          name: "",
          width: "50px",
          cell: () => <Drag className="drag-handle"></Drag>,
        },
        {
          name: "Package Name",
          selector: "name",
          sortable: true,
          wrap:true,
        },
        {
          name: "Rank",
          selector: "rank",
          width: "50px",
          maxWidth: "100px",
          center: true,
          sortable: "true",
        }


       ];


        this.columns=[            
              {   
                name: "",
                width: "50px",
                cell: () => <Drag className="drag-handle"></Drag>,
              },
              {
                name: "Package Name",
                selector: "package_name",
                sortable: true,
                wrap:true,
              },
              {
                name: "Rank",
                selector: "rank",
                width: "50px",
                maxWidth: "100px",
                center: true,
                sortable: "true",
              }


            ]
            const updateRank = this.updateRank;
            this.dragProps = {
                onDragEnd(fromIndex, toIndex) {
                  updateRank(fromIndex, toIndex);
                },
                nodeSelector: ".rdt_TableRow",
                handleSelector: ".drag-handle",
                enableScroll: true,
              };  
        }

        updateRank = (fromIndex, toIndex) => {
            if (
              fromIndex === null ||
              fromIndex === undefined ||
              toIndex === null ||
              toIndex === undefined
            )
              return;

              console.log("fromIndex toIndex",fromIndex,toIndex)
        
            const { packages = [], sortColumn, sortDirection } = this.state;
            const sortedPackages = _.sortBy(packages,sortColumn, sortDirection);
            const fromPackage = {...sortedPackages[fromIndex] };
            const toPackage = {...sortedPackages[toIndex] };
             console.log("fromPackage toPackage",fromPackage,toPackage);
             let newPackages=[];
            if (fromPackage && toPackage) {
              const tempRank = fromPackage.rank;
              fromPackage.rank = toPackage.rank;
              toPackage.rank = tempRank;
              if(this.props.type=="WebsiteCategory"){
                newPackages = packages.map((it) => {
                  if (it.name === fromPackage.name) return fromPackage;
                  else if (it.name === toPackage.name) return toPackage;
                  else return it;
                });   
              }
              else{
              newPackages = packages.map((it) => {
                if (it.package_name === fromPackage.package_name) return fromPackage;
                else if (it.package_name === toPackage.package_name) return toPackage;
                else return it;
              });
            }


              
              
              this.setState({ packages: newPackages }, () => {
                this.showSuccessNotification("Rank Updated Successfully!");
                this.onPackagesUpdated(newPackages);
              });
            } else {
              this.showErrorNotification("Rank Updation Failed!");
            }
          };
          showSuccessNotification = (notificationMessage) =>
          this.showNotification(notificationMessage, true);
      
        showErrorNotification = (notificationMessage) =>
          this.showNotification(notificationMessage, false);
      
        showNotification = (notificationMessage, isSuccessMessage) =>
          this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
          });
       
        

        render() {
            const {
              packages = [],
              packageDetail = {},
              sortColumn,
              sortDirection
            }=this.state;
            return (
                <div className="container mt-4 px-0">
                  <div className="row">
                    <div className="form-group col">
                      <Card>
                        <ReactDragListView {...this.dragProps}>
                          <DataTable
                            highlightOnHover
                            columns= {this.props.type=="WebsiteCategory"?this.websitePackagesColumn:this.columns}
                            data={packages || []}
                            onSort={(sortColumn, sortDirection) =>
                              this.setState({
                                sortColumn: sortColumn.selector,
                                sortDirection,
                              })
                            }
                            pagination
                            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            responsive
                            defaultSortField={sortColumn}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                          />
                        </ReactDragListView>
                        </Card>
                        </div>
                        </div>
                        </div>)




    }
}

PackageRanking.propTypes = {
    packages: PropTypes.arrayOf(PropTypes.object).isRequired,
    onPackagesUpdated: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    type:PropTypes.string
  };
  
  PackageRanking.defaultProps = {
    packages: [],
    onPackagesUpdated: (f) => f,
    isEditable: true,
    type:""
  };

  function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PackageRanking);
