import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";

const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class ManageOptionsForm extends Component {
  constructor(props) {
    super(props);

    const { options = [], onOptionsUpdated = (f) => f } = this.props;

    this.onOptionsUpdated = onOptionsUpdated;

    this.state = {
      options,
      option: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "",
        width: "50px",
        cell: () => <Drag className="drag-handle"></Drag>,
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Option(English)",
        selector: "name",
        sortable: true,
        wrap: true,
        format: (option) => (
          <div className="text-overflow-ellipsis">{option.name ?? ""}</div>
        ),
      },
      {
        name: "Option(Arabic)",
        selector: "name_arabic",
        sortable: true,
        wrap: true,
        // right: true,
        style: { textAlign: "right", justifyContent: "end" },
        format: (option) => (
          <div className="text-overflow-ellipsis">
            {option.name_arabic ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        selector: "is_active",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (option) => <Status isActive={option.is_active} />,
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (option) => this.renderActionBar(option),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      option: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const option = { ...this.state.option };
    option[input.name] = input.value;
    this.setState({ option });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { options = [] } = this.state;
    const option = options.find((it) => it.id === id);
    if (!option) {
      console.log(`handleEdit: Option with id ${id} not found.`);
      return;
    }

    this.setState({ option }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteOption(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { option = {} } = this.state;
    if (option.id === null || option.id === undefined) this.createOption();
    else this.updateOption(option.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { name, name_arabic, is_active: status } = this.state.option || {};

    if (!name || name.trim() === "")
      errors.name = "Option (English) cannot be blank";
    else if (name && name.length > 500)
      errors.name = "Option (English) is limited to a 500 characters";

    if (!name_arabic || name_arabic.trim() === "")
      errors.name_arabic = "Option (Arabic) cannot be blank";
    else if (name_arabic && name_arabic.length > 500)
      errors.name_arabic = "Option (Arabic) is limited to a 500 characters";

    if (status === null || status === undefined)
      errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createOption = () => {
    const { options = [], option } = this.state;
    const ids = options.length > 0 ? options.map((it) => it.id) : null;
    option.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      options.length > 0
        ? options.filter((it) => it.rank).map((it) => it.rank)
        : null;
    option.rank = ranks ? Math.max(...ranks) + 1 : 1;
    option.is_new = true;

    this.setState({ options: [...options, option] }, () => {
      this.showSuccessNotification("Option Created Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  updateOption = (id) => {
    const { options = [], option } = this.state;
    const newOptions = options.map((it) => (it.id === id ? option : it));
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { options = [] } = this.state;
    const newOptions = options.map((it) =>
      it.id === id ? { ...it, is_active: !it.is_active } : it
    );
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Updated Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  deleteOption = (id) => {
    const { options = [] } = this.state;
    const optionToDelete = options.find((it) => it.id === id) || {};
    const newOptions = options
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > optionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ options: newOptions }, () => {
      this.showSuccessNotification("Option Deleted Successfully!");
      this.onOptionsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { options = [], sortColumn, sortDirection } = this.state;
    const sortedOptions = _.sortBy(options, sortColumn, sortDirection);
    const fromOption = { ...sortedOptions[fromIndex] };
    const toOption = { ...sortedOptions[toIndex] };

    if (fromOption && toOption) {
      const tempRank = fromOption.rank;
      fromOption.rank = toOption.rank;
      toOption.rank = tempRank;

      const newOptions = options.map((it) => {
        if (it.id === fromOption.id) return fromOption;
        else if (it.id === toOption.id) return toOption;
        else return it;
      });
      this.setState({ options: newOptions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onOptionsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onOptionsUpdatedLocally = () => {
    this.clearForm();
    this.onOptionsUpdated(
      this.state.options.map((option) => {
        const o = { ...option };
        if (o.is_new) {
          delete o.id;
          delete o.is_new;
        }
        return o;
      })
    );
  };

  handleAddNewOption = () => {
    const option = { is_active: false };
    this.setState({ option }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (option) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(option.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item onClick={() => this.handleStatusChange(option.id)}>
          {option.is_active ? (
            <ArchiveOutlinedIcon
              className="icon-small"
              style={{ fill: "red" }}
            />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {option.is_active ? (
            <span style={{ color: "red" }}>Inactivate</span>
          ) : (
            "Activate"
          )}
        </ActionBar.Item>
        {/* Currently we don't need an option to delete an option but maybe in future. */}
        {/* <ActionBar.Item
          // onClick={() => this.handleDelete(option.id)}
          onClick={() =>
            this.setState({ optionToDelete: option.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item> */}
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.option.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      options = [],
      option = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewOption = option.id === null || option.id === undefined;
    console.log("render:: manageoptionsform: isEditable", this.props.isEditable);

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Option (English)*</label>
            <div className="input-group">
              <textarea
                value={option.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                placeholder="Option in English"
                readOnly={!isNewOption && !editables.name}
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="name_arabic">Option (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={option.name_arabic}
                onChange={this.handleChange}
                id="name_arabic"
                name="name_arabic"
                placeholder="Option in Arabic"
                readOnly={!isNewOption && !editables.name_arabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.name_arabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("name_arabic")}
            </div>
            <span className="help-block">{errors.name_}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={option?.is_active}
                onChange={(event, value) => {
                  this.setState({
                    option: { ...option, is_active: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewOption && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={options || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewOption}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {option.id === null || option.id === undefined
                ? "Add Option"
                : "Edit Option"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {option.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to remove the option?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteOption(this.state.optionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ManageOptionsForm.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onOptionsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

ManageOptionsForm.defaultProps = {
  options: [],
  onOptionsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageOptionsForm);
