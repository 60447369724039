import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectSupplements.propTypes = {
  supplements: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSupplementIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSupplementSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectSupplements.defaultProps = {
  supplements: [],
  selectedSupplementIds: [],
  onSupplementSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectSupplements(props) {
  const {
    supplements,
    selectedSupplementIds,
    onSupplementSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findSupplement = (supplementId) =>
    supplements.find((supplement) => supplement.id === supplementId);

  const options = createOptions(supplements, showOnlyActive);

  const selectedSupplements = selectedSupplementIds
    .map(findSupplement)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedSupplementIds && selectedSupplementIds.join(",")
      }`}
      value={selectedSupplements}
      onChange={onSupplementSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(supplements, showOnlyActive) {
  if (!supplements || !supplements.length) return [];

  const [activeSupplements, inactiveSupplements] = _.partition(
    supplements,
    (it) => it.status === "Active"
  );
  activeSupplements.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );

  if (showOnlyActive) return activeSupplements.map(createOption);

  inactiveSupplements.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  return [
    {
      label: "Active",
      options: activeSupplements.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveSupplements.map(createOption),
    },
  ];
}

function createOption(supplement) {
  if (!supplement) return;
  return {
    key: supplement.id,
    value: supplement.id,
    label: supplement.internalName,
  };
}

export default SelectSupplements;
