import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { getUserName, getUserType } from "services/UserService";
import Dashboard from "./CoachManagment/Dashboard";
import team_img from "assets/img/team.svg";
import API from "utils/API";
import { clearData } from "services/UserService";

class Home extends Component {
  componentDidMount() {  
    this.fetchUserDetails();
  }

  fetchUserDetails = async() => {
    console.log("fetchUserDetails:: Fetching user details!");

    try {
      this.showLoading();
      const response = await API.get("/users/");
      console.log("fetchUserDetails:: Response:", response);

      const { data: users } = response;
      console.log("fetchUserDetails:: Fetched user details=", users);
      if (users && users.length) {
        const loggedInUser = users[0];
        if (!loggedInUser || !loggedInUser.is_active) {
          console.log("fetchUserDetails:: User is inactive! logging out. user=", loggedInUser);
          this.logout();
        }
        return;
      }
      console.log("fetchUserDetails:: User not found! logging out. user=", users);
      this.logout();
    } catch (error) {
      console.log("fetchUserDetails:: Error on fetching users!", error);
      if (error?.response?.status === 401) {
        console.log("fetchUserDetails:: Unauthorized user!");
        this.logout();
      }
    } finally {
      this.hideLoading();
    }
  }

  logout = () => {
    if (this && this.props && this.props.history) {
      this.props.history.replace("/login");
    } else {
      window.location.href = '/login'
      // clearData();
    }
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const userName = getUserName();
    const userType = getUserType();
    return userType === "nutritionist" || userType === "externalNutritionist"
      ? (<Dashboard />)
      : (
        <div className="dashboard">
          <div className="welcome">
            <img src={team_img} className="welcome-image" alt="welcome"></img>
            <h1 className="welcome-text">Hi, {userName}</h1>
            <p className="welcome-description">Welcome to your dashboard. </p>
            <NavLink className="valeo-link" to="orders/active">
              <button className="btn button button-green action-button">
                View Active Orders
              </button>
            </NavLink>
          </div>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
