import React from "react";
import { Tab } from "react-bootstrap";

function ModalTab(props) {
  const { eventKey, tabTitle, tabForm, children, disabled } = props;
  console.log(eventKey, tabForm);
  return (
    <Tab eventKey={eventKey} title={tabTitle} disabled={disabled}>
      {this.props.children}
    </Tab>
  );
}

export default ModalTab;
