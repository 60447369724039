import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectTests.propTypes = {
  tests: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedTestIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectTests.defaultTypes = {
    suggestions: [],
  selectedSuggestedIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectTests(props) {
  const { suggestions, selectedSuggestedIds, onSelect, menuPlacement, isDisabled } = props;

  const findTest = (id) => suggestions.find((suggestion) => suggestion.id === id);

  const activeTests = suggestions.filter((suggestion) => suggestion.status==="Active" ?? false);

  activeTests.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const testOptions = activeTests.map(createOption);
  const selectedSuggestions = selectedSuggestedIds
    ? selectedSuggestedIds.map(findTest).map(createOption)
    : [];

  return (
    <Select
      key={`my_unique_select_key__${selectedSuggestions && selectedSuggestions.join(",")}`}
      value={selectedSuggestions}
      onChange={onSelect}
      options={testOptions}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

const createOption = (test) => {
  if (!test) return;
  return {
    key: test.id,
    value: test.id,
    label: test.optionEnglish,
    questionId:test.questionId

  };
};

export default SelectTests;
