import React,{Component} from "react";
import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import AccessDenied from "components/Common/AccessDenied";
import { fetchCountries } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";
import CorporatePage from "./CorporatePage";
import InsurancePage from "./InsurancePage";
class ValeoCorporates extends Component{
      constructor(props) {
        super(props);
        this.state = {
            aclUser: null,
            pageAccessRights: null,
            accessDenied: false,
            isEditable:true,
            countries:[],
            selectedCountry:null
        };
        
      }
      componentDidMount() {
        Promise.all([this.fetchAclUser(), this.fetchCountries()]);
      }
      componentWillUnmount() {
        this.hideLoading();
      }
      hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
          return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
      };
    
      fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
          console.log("fetchAclUser:: Invalid user id!", { userId });
          return;
        }
    
        try {
          const pageName = Page.VALEO_AMBASSADORS;
          const { data: aclUser } = await PhoenixAPI.get(
            `/api/v1/acl/users/userId/${userId}`
          );
          const pagePermission =
            aclUser?.pagePermissions?.find(
              (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
            ) ?? null;
          const pageAccessRights =
            pagePermission?.pageAccessRights
              ?.filter((it) => it.isActive ?? false)
              ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
          const accessDenied = isAccessDenied(aclUser, pageAccessRights);
          this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
            const isEditable = this.hasPageAccessRight("edit");
            this.setState({ isEditable });
          });
        } catch (error) {
          console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
      };
      fetchCountries = async () => {
        try {
          this.showLoading();
          const countries = (await fetchCountries()) ?? [];
          const selectedCountry = countries.find((country) => country.is_default);
          this.setState({ countries, selectedCountry });
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching countries!" + error.message
          );
        } finally {
          this.hideLoading();
        }
      };
    
      handleCountrySelection = (selectedCountry) =>
        this.setState({ selectedCountry });
    
      showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);
    
      showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);
    
      showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });
    
      showLoading = () => this.props.toggleLoading({ isLoading: true });
    
      hideLoading = () => this.props.toggleLoading({ isLoading: false });
      render(){
        const { accessDenied, countries, selectedCountry, isEditable } = this.state;
    if (accessDenied) {
      return <AccessDenied />;
    }
        return(<div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <CorporatePage country={selectedCountry} isEditable={isEditable}/>
        <InsurancePage country={selectedCountry} isEditable={isEditable}/>
      </div>

        )
      }

}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ValeoCorporates);