import React, { Component } from "react";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";

class Search extends Component {
  handleFormSubmit = (e) => {
    e.preventDefault();
    if (this.props.handleSearchEnter) {
      this.props.handleSearchEnter();
    }
  };

  render() {
    const {
      searchExpanded,
      searchTerm,
      clearSearch,
      handleSearch,
      handleSearchExit,
    } = this.props;

    return (
      <form onSubmit={this.handleFormSubmit}>
        <div className="search">
          <input
            value={searchTerm ?? ""}
            onChange={(e) => handleSearch(e.target.value)}
            onBlur={(e) => handleSearchExit(e)}
            type="search"
            placeholder="Search"
            ref={(input) => (this.searchInput = input)}
            className={searchExpanded ? "active" : ""}
          />
          <div className="icons">
            <SearchIcon className="search-icon"></SearchIcon>
            {searchTerm ? (
              <ClearIcon onClick={() => clearSearch()} className="clear-icon" />
            ) : null}
          </div>
        </div>
      </form>
    );
  }
}

export default Search;
