import React, { useEffect, useState } from "react";
import _ from "lodash";
import API from "utils/API";

const BASE_URL_HEALTH_PROFILE_RESULTS = "/";

function HealthProfileSurveyResultCard({ userId, medicalProfilesurveyQuestions }) {
  const [questions, setQuestions] = useState([]);

  // useEffect(() => fetchHealthProfileResultsOfUser(userId), [userId]);

  useEffect(()=>{
   if(medicalProfilesurveyQuestions && medicalProfilesurveyQuestions.length>0){
    const activeQuestions = medicalProfilesurveyQuestions.filter((it) => it.is_active);
    setQuestions(activeQuestions)
   }
  },[userId])

  const fetchHealthProfileResultsOfUser = (userId) => {
    if (userId === null || userId === undefined) {
      console.log(
        "Can not fetch health survey results, invalid userId",
        userId
      );
      return;
    }

    const url = `${BASE_URL_HEALTH_PROFILE_RESULTS}?user_id=${userId}`;
    API.get(url)
      .then((response) => {
        const { data: questions = [] } = response;
        console.log("data:", {BASE_URL_HEALTH_PROFILE_RESULTS,questions})
        const activeQuestions = questions.filter((it) => it.is_active);
        setQuestions(activeQuestions);
      })
      .catch((error) =>
        console.log("Error on fetching health profile results from: ", url)
      );
  };

  const renderQuestion = (question, isAnswered) => {
    if (isAnswered) {
      const { option: options = [], plain_answer = "" } =
        question.answer_question[0];
      let answer = options.map((it) => it.option).join(", ");
      if (plain_answer)
        answer += answer === "" ? plain_answer : ", " + plain_answer;
      return (
        <p>
          <span className="font-weight-bold">{question.question}</span>
          <br />
          Answer. {answer}
        </p>
      );
    } else {
      return (
        <p>
          <span className="font-weight-bold">{question.question}</span>
        </p>
      );
    }
  };

  const [answeredQuestions, unansweredQuestions] = _.partition(
    questions,
    (question) => {
      if (
        question &&
        question.answer_question &&
        question.answer_question.length > 0
      ) {
        const { option, plain_answer } = question.answer_question[0];
        return (
          (option && option.length > 0) || (plain_answer && plain_answer !== "")
        );
      } else {
        return false;
      }
    }
  );

  return (
    <div
      className="ui raised card"
      style={{ minWidth: "100%", maxWidth: "100%" }}
    >
      <div className="content">
        <div className="font-weight-bold mb-2">Health Profile</div>
        <div
          className="description"
          style={{
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          {answeredQuestions.map((question) => renderQuestion(question, true))}
          {unansweredQuestions.map((question) =>
            renderQuestion(question, false)
          )}
        </div>
      </div>
    </div>
  );
}

export default HealthProfileSurveyResultCard;
