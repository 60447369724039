import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";

const STATUS_OPTIONS = [
    {
      key: "Active",
      value: true,
      text: "Active",
    },
    {
      key: "Inactive",
      value: false,
      text: "Inactive",
    },
  ];

  class RelatedServicesForm extends Component {
    constructor(props) {
      super(props);
  
      const { relatedServices = [], onServicesUpdated = (f) => f } = this.props;
    
      this.onServicesUpdated = onServicesUpdated;
  
      this.state = {
        relatedServices,
        relatedService: {},
        errors: {},
        editables: {},
       
        isConfirmDeleteModalVisible: false,
      };
  
  
  
      this.columns = [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          width: "50px",
          maxWidth: "100px",
        },
        {
          name: "Service Name (English)",
          selector: "serviceName",
          sortable: true,
          wrap: true,
          format: (service) => (
            <div className="text-overflow-ellipsis">
              {service.serviceName ?? ""}
            </div>
          ),
        },
        {
          name: "Service Name (Arabic)",
          selector: "serviceNameAr",
          sortable: true,
          wrap: true,
          right: true,
          format: (service) => (
            <div
              className="text-overflow-ellipsis"
              style={{ textAlign: "right" }}
            >
              {service.serviceNameAr ?? ""}
            </div>
          ),
        },
        {
            name:"Redirection Link English",
            selector: "serviceRedirectionLink",
            sortable: true,
            wrap: true,
            format: (service) => (
              <div className="text-overflow-ellipsis" >{service.serviceRedirectionLink??""}</div>),

        },
        {
            name:"Redirection Link Arabic",
            selector: "serviceRedirectionLinkAr",
            sortable: true,
            wrap: true,
            format: (service) => (
              <div className="text-overflow-ellipsis"   style={{ textAlign: "right" }}>{service.serviceRedirectionLinkAr??""}</div>),

        },

        {
          name: "Status",
          selector: "isActive",
          sortable: true,
          center: true,
          maxWidth: "150px",
          cell: (service) => <Status isActive={service.isActive ?? false} />,
        },
        {
          name: "Actions",
          maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: (service) => this.renderActionBar(service),
        },
      ];
    }
   validate= () => {
    const errors = {};
    const {
      serviceName,
      serviceNameAr,
      serviceRedirectionLink,
      serviceRedirectionLinkAr

    }= this.state.relatedService || {};
    if (!serviceName || serviceName.trim() === "") {
      errors.serviceNameEnglish = "Service Name English is Required Field";
    }
    if(!serviceNameAr || serviceNameAr.trim() === "") {
      errors.serviceNameAr= "Service Name Arabic Required Field";
    }
    if (!serviceRedirectionLink || serviceRedirectionLink.trim() === "") {
      errors.serviceRedirectionLink = "Service Redirection Link English is Required Field";
    }
    if(!serviceRedirectionLinkAr || serviceRedirectionLinkAr.trim() === "") {
      errors.serviceRedirectionLinkAr= "Service Redirection Link Arabic Required Field";
    }

    return Object.keys(errors).length == 0? null : errors;
  }
  
  handleEdit = (id) => {
    this.clearForm();
    console.log("edit id is",id);
    const { relatedServices = [] } = this.state;
    const relatedService = relatedServices.find((it) => it.id === id);
    if (!relatedService) return;
      console.log("relatedService",relatedService);
    this.setState({ relatedService }, () => this.showAddEditModal());
  };


    handleSubmit = (event) => {
        event.preventDefault();
    
       const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
          this.showErrorNotification("There are some invalid form fields!");
          return;
        }
    
        const { relatedService = {} } = this.state;
        if (relatedService.id === null || relatedService.id === undefined) this.createRelatedService();
        else this.updateService(relatedService.id);
        this.hideAddEditModal();
      };
      handleStatusChange = (id) => this.toggleStatus(id);
      createRelatedService = () => {
        const { relatedServices = [], relatedService } = this.state;
        const ids = relatedServices.length > 0 ? relatedServices.map((it) => it.id) : null;
        relatedService.id = ids ? Math.max(...ids) + 1 : 1;
    
        relatedService.is_new = true;
    
        this.setState({ relatedServices: [...relatedServices, relatedService] }, () => {
          this.showSuccessNotification("Related Service Created Successfully!");
          this.onServiceUpdatedLocally();
        });
      };
    
      updateService = (id) => {
        const { relatedServices = [], relatedService } = this.state;
        const updateRelatedServices = relatedServices.map((it) => (it.id === id ? relatedService : it));
        this.setState({ relatedServices: updateRelatedServices }, () => {
          this.showSuccessNotification("Related Service Updated Successfully!");
          this.onServiceUpdatedLocally();
        });
      };
    
      renderActionBar = (relatedService) => (
        <ActionBar alignRight className="action-menu valeo-dropdown">
          <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
          </ActionBar.Toggle>
          <ActionBar.Menu>
            <ActionBar.Item onClick={() => this.handleEdit(relatedService.id)}>
              <EditIcon className="icon-small" />
              Edit
            </ActionBar.Item>
            <ActionBar.Item onClick={() => this.handleStatusChange(relatedService.id)}>
              {relatedService.isActive ? (
                <ArchiveOutlinedIcon className="icon-small" />
              ) : (
                <CheckCircleOutlinedIcon className="icon-small" />
              )}
              {relatedService.isActive ? "Inactive" : "Active"}
            </ActionBar.Item>
          </ActionBar.Menu>
        </ActionBar>
      );

      toggleStatus = (id) => {
        const { relatedServices = [] } = this.state;
        const updateServices = relatedServices.map((it) =>
          it.id === id
            ? { ...it, isActive: it.isActive === true ? false : true }
            : it
        );
        this.setState({ relatedServices: updateServices }, () => {
          this.showSuccessNotification("Service Updated Successfully!");
          this.onServiceUpdatedLocally();
        });
      };
    
      onServiceUpdatedLocally = () => {
        this.clearForm();
        this.onServicesUpdated(
          this.state.relatedServices.map((relatedService) => {
            const q = { ...relatedService };
            if (q.is_new) {
              delete q.id;
              delete q.is_new;
            }
            return q;
          })
        );
      };
      renderPencilIcon = (fieldName) =>
    this.state.relatedService.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
    

    handleAddNewTag = () => {
        const relatedService = { isActive: false };
        this.setState({ relatedService }, () => this.showAddEditModal());
      };
    
      showAddEditModal = () => {
        this.setState({ isAddEditModalVisible: true , });
      };
    
      hideAddEditModal = () => {
        this.clearForm();
        this.setState({ isAddEditModalVisible: false });
      };

      clearForm = () => {
        this.setState({
          errors: {},
          editables: {},
          relatedService: {},
        });
      };
    
     
      
      handleChange = ({ currentTarget: input }) => {
        const relatedService = { ...this.state.relatedService };
      relatedService[input.name] = input.value;
        this.setState({ relatedService });
      };
    render(){
        const { relatedServices, relatedService = {}, errors, editables } = this.state;

        const isNewRelatedService = relatedService.id === null || relatedService.id === undefined;
    

     const serviceForm=(<>
         <div className="row">
          <div className="form-group col">
            <label htmlFor="serviceName">Service Name (English) *</label>
            <div className="input-group">
              <input
                value={relatedService?.serviceName}
                onChange={this.handleChange}
                id="serviceName"
                name="serviceName"
                placeholder="Service Name in English"
                readOnly={!isNewRelatedService && !editables.serviceName}
                className={
                  "form-control py-2" +
                  (errors.serviceNameEnglish ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("serviceName")}
            </div>
            <span className="help-block">{errors.serviceNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="serviceNameAr">Service Name (Arabic) *</label>
            <div className="input-group">
              <input
                value={relatedService.serviceNameAr}
                onChange={this.handleChange}
                id="serviceNameAr"
                name="serviceNameAr"
                placeholder="Service Name in Arabic"
                readOnly={!isNewRelatedService && !editables.serviceNameAr}
                className={
                  "form-control input-arabic py-2" +
                  (errors.serviceNameAr ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("serviceNameAr")}
            </div>
            <span className="help-block">{errors.serviceNameAr}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="serviceRedirectionLink">Redirection Link (English) *</label>
            <div className="input-group">
              <input
                value={relatedService.serviceRedirectionLink}
                onChange={this.handleChange}
                id="serviceRedirectionLink"
                name="serviceRedirectionLink"
                placeholder="Redirection Link"
                readOnly={!isNewRelatedService && !editables.serviceRedirectionLink}
                className={
                  "form-control input py-2" +
                  (errors.serviceRedirectionLink ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("serviceRedirectionLink")}
            </div>
            <span className="help-block">{errors.serviceRedirectionLink}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="headerTitleArabic">Redirection Link (Arabic) *</label>
            <div className="input-group">
              <input
                value={relatedService.serviceRedirectionLinkAr}
                onChange={this.handleChange}
                id="serviceRedirectionLinkAr"
                name="serviceRedirectionLinkAr"
                placeholder="Redirection Link"
                readOnly={!isNewRelatedService && !editables.serviceRedirectionLinkAr}
                className={
                  "form-control input-arabic py-2" +
                  (errors.serviceRedirectionLinkAr ? " has-error" : "")
                }
              ></input>
              {this.renderPencilIcon("serviceRedirectionLinkAr")}
            </div>
            <span className="help-block">{errors.serviceRedirectionLinkAr}</span>
          </div>
          </div>
         <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={relatedService?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    relatedService: { ...relatedService, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewRelatedService && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>


        </>);
        return (
          
          <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="font-weight-bold mt-4 px-4">Related Services</p>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={relatedServices || []}
                /*onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }*/
                pagination
                responsive
               // defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewTag}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>

            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {relatedService.id === null || relatedService.id === undefined
                ? "Add Service"
                : "Edit Service"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{serviceForm}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {relatedService.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>

          
          </div>)



    }

}
RelatedServicesForm.propTypes = {
    relatedServices: PropTypes.arrayOf(PropTypes.object).isRequired,
    onServiceUpdated: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
  };
  
  RelatedServicesForm.defaultProps = {
    relatedServices: [],
    onServiceUpdated: (f) => f,
    isEditable: true,
  };

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(RelatedServicesForm);