import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const OPTION_ORDER_CANCELLED = { name: "CANCELLED", value: "Order Cancelled" };
function SelectOrderStatus(props) {
  const { options, value, isDisabled, onSelect } = props;
  if (value === "CANCELLED" && options && !options.find((option) => option.name === "CANCELLED")) {
    options.push(OPTION_ORDER_CANCELLED);
  }

  const findOrderStatus = (orderStatus) =>
    options.find((it) => it.name === orderStatus || it.value === orderStatus);

  const createOrderStatusOption = (orderStatus) => {
    if (!orderStatus) return;
    return {
      key: orderStatus.name,
      value: orderStatus.name,
      label: orderStatus.value,
    };
  };

  const orderStatusOptions = options.map(createOrderStatusOption);
  const selectedOrderStatus = findOrderStatus(value);
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`my_unique_select_key__${value}`}
      value={createOrderStatusOption(selectedOrderStatus)}
      onChange={(option) => onSelect(option.value)}
      options={orderStatusOptions}
      styles={selectStyles}
      placeholder="Order Status"
      isDisabled={isDisabled}
    />
  );
}

SelectOrderStatus.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

SelectOrderStatus.defaultProps = {
  options: [],
  value: "",
  isDisabled: false,
  onSelect: (f) => f,
};

export default SelectOrderStatus;
