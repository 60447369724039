const DEWA_PARTNER_PACKAGES=[
    {
        "packageName":"Blood Biomarker Packages"
    },
    {
        "packageName":"Custom Packages"
    },
    {
        "packageName":"Treatment Plans"
    },
    {
        "packageName":"Supplements" 
    },
    {
        "packageName":"Banners" 
    },
    {
        "packageName":"Coach Consultation Package"
    }
]

export default DEWA_PARTNER_PACKAGES