import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCities, fetchCountries } from "services/RegionService";
import PhoenixAPI from "utils/PhoenixAPI";
import Conditions from "./Conditions";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

const BASE_URL_CONDITON_CATEGORIES = "conditions/category/";

class ConditionCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      conditionCategories: [],
      rankOptions: [],
      conditionCategory: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isConditionCategoryViewModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (conditionCategory) => (
          <p style={{ textAlign: "right" }}>
            {conditionCategory.externalNameArabic}
          </p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (conditionCategory) => (
          <Status isActive={conditionCategory.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (conditionCategory) =>
          this.renderViewButton(conditionCategory.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([
      this.fetchAclUser(), 
      this.fetchCountries(), 
      this.fetchCities()
    ])
    this.fetchCaonditionCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.CONDITION_CATEGORIES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      cities.forEach((city) => {
        city.countryId = city.country?.id;
        delete city.country;
      });
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!", error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }
  };

  fetchCaonditionCategories = async () => {
    this.showLoading();
    try {
      const { data: conditionCategories } = await PhoenixAPI.get(
        BASE_URL_CONDITON_CATEGORIES
      );
      this.setState({ conditionCategories });
    } catch (error) {
      console.log("Error on fetching conditon categories", error);
      this.showErrorNotification(
        "Error on fetching condition categories: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const conditionCategory = { ...this.state.conditionCategory };
    conditionCategory[input.name] = input.value;
    this.setState({ conditionCategory });
  };

  handleAddNewConditionCategory = () => {
    this.setState({ isNewConditionCategory: true }, () =>
      this.showConditionCategoryViewModal()
    );
  };

  handleViewConditionCategory = (conditionCategoryId) => {
    const conditionCategories = this.state.conditionCategories ?? [];
    const conditionCategory = conditionCategories.find(
      (it) => it.id === conditionCategoryId
    );
    if (!conditionCategory) {
      console.log(
        `Condition Category with id ${conditionCategoryId} is not found!`
      );
      return;
    }

    if (conditionCategory.currency === null || conditionCategory.currency === undefined) {
      conditionCategory.currency = this.state.selectedCountry?.currency;
    }
    if (conditionCategory.internalCategory === null || conditionCategory.internalCategory === undefined) {
      conditionCategory.internalCategory ="None";
    }

    this.setState({ conditionCategory, isNewConditionCategory: false }, () =>
      this.showConditionCategoryViewModal()
    );
  };

  showConditionCategoryViewModal = () => {
    const { selectedCountry, isNewConditionCategory } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewConditionCategory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const conditionCategory = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
        internalCategory: "None",
      };
      this.setState({
        conditionCategory,
        rankOptions,
        isConditionCategoryViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isConditionCategoryViewModalVisible: true });
    }
  };

  hideConditionCategoryViewModal = () => {
    this.setState({ isConditionCategoryViewModalVisible: false });
    this.resetForm();
  };

  handleSaveConditionCategory = async () => {
    const { selectedCountry, conditionCategory } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        conditionCategory.id !== null && conditionCategory.id !== undefined;
      if (conditionCategory.internalCategory == "None") {
        conditionCategory.internalCategory = null;
      }
      const response = await this.saveConditionCategory(conditionCategory);

      this.hideConditionCategoryViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Condition Category Updated Successfully."
          : "Condition Category Created Successfully."
      );
      this.fetchCaonditionCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { conditionCategory = {} } = this.state;
    const errors = {};

    const {
      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      country,
    } = conditionCategory;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveConditionCategory = async (conditionCategory) => {
    if (conditionCategory === null) return;

    const config = {
      method: conditionCategory.id ? "PATCH" : "POST",
      url: conditionCategory.id
        ? `${BASE_URL_CONDITON_CATEGORIES}${conditionCategory.id}`
        : BASE_URL_CONDITON_CATEGORIES,
      data: conditionCategory,
    };
    return await PhoenixAPI.request(config);
  };

  createRankOptions = () => {
    const { isNewConditionCategory } = this.state;
    const conditionCategories = this.getConditionCategoriesForCurrentCountry();
    const ranks = conditionCategories
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, conditionCategories.length) || 0;
    const until = isNewConditionCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      conditionCategory: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewConditionCategory(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    return this.state.isEditable && !this.state.isNewConditionCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredConditionCategories = () => {
    const conditionCategories = this.getConditionCategoriesForCurrentCountry();
    return this.getSearchedConditionCategories(conditionCategories);
  };

  getConditionCategoriesForCurrentCountry = () => {
    const { conditionCategories, selectedCountry } = this.state;
    return conditionCategories && selectedCountry
      ? conditionCategories.filter((p) => p.country === selectedCountry.id)
      : [];
  };

  getSearchedConditionCategories = (conditionCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return conditionCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return conditionCategories.filter((conditionCategory) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
      } = conditionCategory;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      conditionCategories,
      conditionCategory,
      isNewConditionCategory,
      countries,
      cities,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
    } = this.state;

    const filteredConditionCategories =
      this.getFilteredConditionCategories(conditionCategories) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={conditionCategory?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewConditionCategory && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={conditionCategory?.status}
                onChange={(event, value) => {
                  this.setState({
                    conditionCategory: {
                      ...conditionCategory,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewConditionCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={conditionCategory?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={
                  !isNewConditionCategory && !editables.externalNameEnglish
                }
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={conditionCategory?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={
                  !isNewConditionCategory && !editables.externalNameArabic
                }
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={conditionCategory?.rank}
                onChange={(event, value) => {
                  this.setState({
                    conditionCategory: {
                      ...conditionCategory,
                      rank: value.value,
                    },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewConditionCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewConditionCategory
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(conditionCategory?.country)?.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={conditionCategory?.internalCategory || "None"}
                onChange={(event, value) => {
                  this.setState({
                    conditionCategory: {
                      ...conditionCategory,
                      internalCategory: value.value,
                    },
                  });
                }}
                id="internalCategory"
                name="internalCategory"
                placeholder="None"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewConditionCategory && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewConditionCategory}
                disabled={!this.hasPageAccessRight("edit")}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Condition Category</p>
          <DataTable
            data={filteredConditionCategories || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredConditionCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isConditionCategoryViewModalVisible}
          onHide={this.hideConditionCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Condition Category: </span>
                {conditionCategory?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveConditionCategory}
              disabled={!this.hasPageAccessRight("edit")}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_condition_categories">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        {conditionCategories && countries && selectedCountry && (
          <Conditions
            categories={conditionCategories || []}
            countries={countries}
            cities={cities}
            selectedCountry={selectedCountry}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConditionCategories);
