import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { ArrowDownward } from "@material-ui/icons";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import SelectRoles from "./SelectRoles";
import PagePermissionsForm from "./PagePermissionsForm";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
class UserPagePermissions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accessRights: [],
      roles: [],
      pageCategories: [],
      pages: [],
      users: [],
      user: {},
      aclUsers: [],
      aclUser: {},
      errors: {},
      editables: {},
      searchTerm: null,
      searchApplied: false,
      isSaving: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "userName",
        wrap: true,
        sortable: true,
        format: (user) => this.getUserName(user),
      },
      {
        name: "Email",
        selector: "email",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (user) => <Status isActive={user.active ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (user) => this.renderViewButton(user),
      },
    ];
  }

  componentDidMount() {
    Promise.all([
      this.fetchUsers(),
      this.fetchAclUsers(),
      this.fetchRoles(),
      this.fetchAccessRights(),
      this.fetchPageCategories(),
      this.fetchPages(),
    ]);
  }

  fetchRoles = async () => {
    try {
      const { data: roles = [] } = await PhoenixAPI.get("/api/v1/acl/roles");
      this.setState({ roles });
    } catch (error) {
      console.log("Error on fetching roles!", error);
    }
  };

  fetchAccessRights = async () => {
    try {
      const { data: accessRights = [] } = await PhoenixAPI.get(
        "/api/v1/acl/access-rights"
      );
      this.setState({ accessRights });
    } catch (error) {
      console.log("Error on fetching access rights!", error);
    }
  };

  fetchPageCategories = async () => {
    try {
      const { data: pageCategories = [] } = await PhoenixAPI.get(
        "/api/v1/acl/pages/categories"
      );
      this.setState({ pageCategories });
    } catch (error) {
      console.log("Error on fetching page categories!", error);
    }
  };

  fetchPages = async () => {
    try {
      const { data: pages = [] } = await PhoenixAPI.get("/api/v1/acl/pages");
      this.setState({ pages });
    } catch (error) {
      console.log("Error on fetching pages!", error);
    }
  };

  fetchUsers = async () => {
    try {
      this.showLoading();
      const { data: users = [] } = await PhoenixAPI.get(
        "/health-hub/v2/list-authors/"
      );
      this.setState({ users });
    } catch (error) {
      this.showErrorNotification("Error on fetching users: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  fetchAclUsers = async () => {
    try {
      this.showLoading();
      const { data: aclUsers = [] } = await PhoenixAPI.get("/api/v2/acl/users");
      this.setState({ aclUsers });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching acl users: ",
        error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  getUserName = (user) => {
    if (!user) return "";
    const { username, fullName, firstName, lastName } = user;
    return username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
  };

  findRoleById = (roleId) => {
    const { roles } = this.state;
    return roles && roles.length
      ? roles.find((role) => role.id === roleId)
      : null;
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleView = (id) => {
    const users = this.state.users ?? [];
    const user = users.find((it) => it.id === id);

    const aclUsers = this.state.aclUsers ?? [];
    let aclUser = aclUsers.find((it) => it.userId === id);
    if (aclUser == null) {
      aclUser = { userId: id };
    }

    if (user) {
      this.setState({ user, aclUser }, () => this.showModal());
    }
  };

  handlePagePermissionsUpdated = (pagePermissions = []) => {
    console.log("handlePagePermissionsUpdated::", { pagePermissions });
    const { aclUser = {} } = this.state;
    this.setState({ aclUser: { ...aclUser, pagePermissions } });
  };

  handleSave = async () => {
    const { aclUser } = this.state;
    if (!aclUser) {
      console.log("handleSave:: Invalid acl user!", { aclUser });
      this.setState({ isSaving: false });
      return;
    }

    try {
      const payload = {
        id: aclUser.id ?? null,
        userId: aclUser.userId ?? null,
        roles: aclUser.roles?.map((it) => it.id) ?? null,
        // pagePermissions: aclUser.pagePermissions ?? null,
        pagePermissions:
          aclUser.pagePermissions && aclUser.pagePermissions.length
            ? aclUser.pagePermissions.map((it) => {
                if (it.is_new) {
                  delete it.id;
                  delete it.is_new;
                }
                return it;
              })
            : null,
      };
      const config = {
        method: aclUser.id ? "PATCH" : "POST",
        url: aclUser.id
          ? `/api/v1/acl/users/${aclUser.id}`
          : "/api/v1/acl/users",
        data: payload,
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchAclUsers();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({ errors: {}, editables: {}, user: {}, aclUser: {}, isSaving: false });
  };

  getFilteredUsers = () => {
    const { users = [] } = this.state;
    return this.getSearchedUsers(users);
  };

  getSearchedUsers = (users) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return users;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return users.filter((user) => {
      const { id, userName, email } = user;
      return (
        (id && id.toString() === newValue) ||
        (userName && userName.toLowerCase().indexOf(newValue) !== -1) ||
        (email && email.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (user) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(user.id);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  render() {
    const {
      accessRights,
      pageCategories,
      pages,
      user,
      aclUser,
      roles,
      editables,
      searchApplied,
      searchTerm,
      loadError,
      isSaving,
    } = this.state;

    const filteredUsers = this.getFilteredUsers();

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="userName">Username</label>
            <input
              value={this.getUserName(user)}
              id="userName"
              name="userName"
              type="text"
              className="form-control py-2"
              disabled={true}
            />
          </div>
          <div className="form-group col">
            <label htmlFor="email">Email</label>
            <input
              value={user.email}
              id="email"
              name="email"
              type="text"
              className="form-control py-2"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="roles">Roles</label>
            <div className="input-group user-input-container">
              <SelectRoles
                id="roles"
                name="roles"
                roles={roles ?? []}
                selectedRoleIds={aclUser?.roles?.map((it) => it.id) ?? []}
                onSelect={(options) => {
                  const newRoles = options.map((option) => ({
                    id: option.value,
                    name: this.findRoleById(option.value)?.name ?? null,
                  }));
                  this.setState({ aclUser: { ...aclUser, roles: newRoles } });
                }}
                isDisabled={true}
              />
              {/* {this.renderPencilIcon("roles")} */}
            </div>
          </div>
        </div>
      </div>
    );
    const pagePermissionsForm = (
      <div>
        <PagePermissionsForm
          accessRights={accessRights}
          pageCategories={pageCategories}
          pages={pages}
          pagePermissions={aclUser.pagePermissions ?? []}
          onPagePermissionsUpdated={this.handlePagePermissionsUpdated}
        />
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>

        <Card body>
          <DataTable
            data={filteredUsers || []}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredUsers.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button 
              onClick={(event) => this.setState({ isSaving: true }, this.handleSave)} 
              disabled={isSaving} 
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_users" justify>
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab eventKey="page_permissions_form" title="Page Permissions">
                {pagePermissionsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserPagePermissions);
