import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { formatDate, compareStrings } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class PromotionalCoupon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotionalCoupons: [],
      promotionalCoupon: {},
      searchTerm: null,
      searchApplied: false,
      isBannerViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "couponCode",
        wrap: true,
        sortable: true,
      },
      {
        name: "Discount Type",
        selector: "discountType",
        wrap: true,
        sortable: true,
      },
      {
        name: "Amount",
        selector: "couponAmount",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        center: true,
        sortable: true,
        maxWidth: "150px",
        cell: (coupon) => <Status isActive={coupon.status} />,
      },
      {
        name: "Expiry",
        selector: "couponExpiryDate",
        wrap: true,
        sortable: true,
        center: true,
        format: (row) =>
          row.couponExpiryDate ? formatDate(row.couponExpiryDate) : "-",
      },
    ];
  }

  componentDidMount() {
    this.fetchPromotionalCoupons();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState(
        {
          countries: nextProps.countries,
          selectedCountry: nextProps.selectedCountry,
        },
        () => this.fetchPromotionalCoupons()
      );
    }
  }

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  fetchPromotionalCoupons = async () => {
    const { selectedCountry = null } = this.state;
    
    if (!selectedCountry) {
      console.log("fetchPromotionalCoupons:: Invalid country!", {
        selectedCountry,
      });
      return;
    }
    PhoenixAPI.get(`coupon-code/promo`, {
      params: {
        country: selectedCountry.id,
      },
    })
      .then((response) => {
        const promotionalCoupons = response.data || [];
        this.setState({ promotionalCoupons });
      })
      .catch((error) => {
        console.log("Error on fetching Home Page Banners", error.message);
        this.showErrorNotification(
          "Some error has occured. Please try again.",
          error.message
        );
      });
  };

  resetForm = () => {
    this.setState({
      banner: {},
      errors: {},
      editables: {},
    });
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewBanner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredBanners = () => {
    const promotionalCoupons = this.state.promotionalCoupons;
    return this.getSearchedBanners(promotionalCoupons);
  };

  getBannersForCurrentCountry = () => {
    const { promotionalCoupons, selectedCountry } = this.state;
    return promotionalCoupons && selectedCountry
      ? promotionalCoupons.filter(
          (banner) => banner.country === selectedCountry.id
        )
      : [];
  };

  getSearchedBanners = (banners) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return banners;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return banners.filter((banner) => {
      const { id, couponCode, discountType, couponAmount } = banner;
      return (
        (couponCode && couponCode.toLowerCase().indexOf(newValue) !== -1) ||
        (discountType && discountType.toLowerCase().indexOf(newValue) !== -1) ||
        (couponAmount && couponAmount.toString() === newValue) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      promotionalCoupons,
      searchTerm,
      banner,
      searchApplied,
      countries,
      selectedCountry,
      errors,
      editables,
      loadError,
      isNewBanner,
    } = this.state;

    const isEditable = this.props.isEditable ?? true;
    const filteredBanners = this.getFilteredBanners(promotionalCoupons) ?? [];

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm || ""}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
          </div>
        </div>

        <Card body>
          <h3 className="p-head">Promotional Coupons</h3>
          <DataTable
            data={filteredBanners}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            // pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredBanners.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalCoupon);
