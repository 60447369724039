import React, { Component } from "react";
import Search from "components/Search/Search";
import PropTypes from "prop-types";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import PhoenixAPI from "utils/PhoenixAPI";
import _ from "lodash";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import PdfPreview from "components/Common/PdfPreview";
import { getUserId } from "services/UserService";
import EditResultsViewModal from "pages/Orders/ClientUploadResults/EditResultsViewModal";
import Pdf from "@material-ui/icons/PictureAsPdf";
const BASE_URL_COACH_PLANS="coach-view-longevity-score-page/user-id/";

const STATUS_OPTIONS = [
    {
      key: "Active",
      value: true,
      text: "Active",
    },
    {
      key: "Inactive",
      value: false,
      text: "Inactive",
    },
  ];

class CoachMeal extends Component {

    constructor(props) {
        super(props);
        const { suggestedMealPlans,isEditable,heading ,country,customerId, dependentId} = this.props;
        this.state = {
            suggestedMealPlans,
            suggestedMealPlan: {},
            isEditable,
            heading,
            images: [],
            isMealViewModal: false,
            editables:{},
            errors:{},
            customerId,
            dependentId,
            country,
            uploadPDFFiles:[],
            updateMealPlan:false,
        };
        this.columns = [
            {
              name: "ID",
              selector: "id",
              sortable: true,
              width: "50px",
              maxWidth: "100px",
            },
            {
              name: "File Name",
              selector: "fileUrl",
              sortable: true,
              wrap: true,
              format: (defaultPackage) => (
                <div className="text-overflow-ellipsis">
                  {defaultPackage.fileUrl ?? ""}
                </div>
              ),
            },
            {
                name: "Status",
                selector: "status",
                sortable: true,
                center: true,
                maxWidth: "150px",
                cell: (defaultPackage) => <Status isActive={defaultPackage.isActive ?? false} />,
              },
              {
                name: "Edit",
                center: true,
                cell: (defaultPackage) => this.renderEditButton(defaultPackage.id),
              }
        
        
        ]


    }

    componentDidUpdate(prevProps) {
      if (this.props.suggestedMealPlans !== prevProps.suggestedMealPlans) {
        this.setState({ suggestedMealPlans: this.props.suggestedMealPlans });
      }
      if(this.props.country !== prevProps.country){
        this.setState({country:this.props.country});
      }

    }
    handleDeleteImage = (item) => {
      const { images } = this.state;
      const filteredImages = images.length>0?images.filter((it) => it.id !== item.id):[];
      this.setState({ images: filteredImages });
    };

    renderPdfResults = () => {
      const { images } = this.state;
      return images.length>0 && images.map((item, index) => {
        const file = item.image;
        const fileSource = item.image_src;
        const isPdfFile =
          file?.type === "application/pdf" ||
          (typeof fileSource === "string" &&
            fileSource.toLowerCase().endsWith(".pdf"));
        const preview = isPdfFile ? (
          <div className="image-area" style={{ marginBottom: "30px" }}>
            <PdfPreview
              pdfFile={isPdfFile ? fileSource : null}
              pdfFileUrl={isPdfFile ? null : fileSource}
            />
            <button
              onClick={(e) => this.handleDeleteImage(item)}
              disabled={false}
              className="remove-image"
              style={{ display: "inline" }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        ) : (
          <div className="image-area" style={{ marginBottom: "30px" }}>
            <img
              id="selected_front_image"
              name="selected_front_image"
              src={fileSource}
              alt="Selected"
              className="img-thumbnail mx-auto"
              style={{
                width: "100%",
                maxHeight: "200px",
                objectFit: "cover",
              }}
            />
            <button
              onClick={(e) => this.handleDeleteImage(item)}
              disabled={false}
              className="remove-image"
              style={{ display: "inline" }}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        );
  
        return <>{preview}</>;
      });
    };
  

    readImage = (image, imageFile) => {
      const { images } = this.state;
      if (!imageFile) return;
      const reader = new FileReader();
      reader.onloadend = function () {
        const newImage = {
          ...image,
          image_src: reader.result,
        };
  
        this.setState({ images: [...images, newImage] });
      }.bind(this);
      if (imageFile?.type?.startsWith("image/")) {
        reader.readAsDataURL(imageFile);
      } else if (imageFile?.type === "application/pdf") {
        reader.readAsArrayBuffer(imageFile);
      }
      
    };
    renderEditButton = (id) => (
      <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => {
            this.handleViewMeal(id);
          }}
        >
          Edit
        </button>
      </div>
    );
   
    createNewImage = () => {
      const { images = [] } = this.state;
      const image = {};
      const ids = images.length > 0 ? images.map((it) => it.id) : null;
      image.id = ids ? Math.max(...ids) + 1 : 1;
      return image;
    };



    handleSelectImage = (e) => {
        if (e.target.files && e.target.files.length) {
          const imageFile = e.target.files[0];
         const image = this.createNewImage();
          image.image = imageFile;
          this.readImage(image, imageFile);
          e.target.value = null;
        }
      };
      setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
      };

      renderPencilIcon = (fieldName) =>
      this.state.isEditable && !this.state.isNewMealPlan ? (
        <div
          onClick={() => this.setEditable(fieldName)}
          style={{ cursor: "pointer" }}
          className="input-group-append"
        >
          <i className="input-group-text fa fa-pencil"></i>
        </div>
      ) : (
        ""
      );

      handleAddNewMealPlan = () => {
        this.setState({ isNewMealPlan: true }, () => this.showMealViewModal());
      };
      handleViewMeal= (packageId) => {
        const defaultArticles = this.state.suggestedMealPlans ?? [];
        
        const suggestedMealPlan= defaultArticles.length>0 && defaultArticles.find((it) => it.id === packageId);
        console.log("suggested Default", suggestedMealPlan);
        if (!suggestedMealPlan) {
          console.log(`Article with id ${packageId} is not found!`);
          return;
        }
        this.setState(
          {
            suggestedMealPlan,
            isNewMealPlan: false,
          },
          () => this.showMealViewModal()
        );
      };

      resetForm = () => {
        this.setState({
          suggestedMealPlan: {},
          errors: {},
          editables: {},
          updateMealPlan:false,
          images:[],
        });
      };
      hideArticleViewModal = () => {
        this.setState({ isMealViewModal: false });
        this.resetForm();
      };
      showMealViewModal = () => {
        const {country, isNewMealPlan ,customerId,dependentId} = this.state;
        const coachId=getUserId();
        if (isNewMealPlan) {
          const suggestedMealPlan= {
            countryId: country,
            isActive: false,
            userId:customerId,
            coachId:coachId,
            dependentId:dependentId
          };
          this.setState({
            suggestedMealPlan,
            isMealViewModal: true,
          });
        } else {
          this.setState({ isMealViewModal: true });
        }
      };

      showSuccessNotification = (notificationMessage) =>
      this.showNotification(notificationMessage, true);
  
    showErrorNotification = (notificationMessage) =>
      this.showNotification(notificationMessage, false);
  
    showNotification = (notificationMessage, isSuccessMessage) =>
      this.props.showNotificationMessage({
        notificationMessage,
        successMessage: isSuccessMessage,
        showNotification: true,
      });

      createSuggestedMealPlan=async(suggestedMealPlan)=>{
        const{images}=this.state;
           try{
            const formData= new FormData();
            formData.append("requestSuggestedMealPlan",JSON.stringify(suggestedMealPlan));
            if(images[0] && images[0].image)
            formData.append("requestFile",images[0].image);
             console.log("formData",formData);
           const response =await PhoenixAPI.post("longevity-coach-meal-plans",formData);
           

              this.showSuccessNotification("Meal plan Added");
           
          await this.fetchCoachSuggestedPlans();

          }
           catch(error){
             this.showErrorNotification(error.message);
           }

      }

      showLoading = () => this.props.toggleLoading({ isLoading: true });

      hideLoading = () => this.props.toggleLoading({ isLoading: false });
      updateSuggestedMealPlan=async(suggestedMealPlan)=>{
        const{images}=this.state;
        console.log("images",images)
           try{
            const formData= new FormData();
            delete suggestedMealPlan.createdAt;
            delete suggestedMealPlan.modifiedAt;
            formData.append("requestSuggestedMealPlan",JSON.stringify(suggestedMealPlan));
            if(images[0] && images[0].image)
            formData.append("requestFile",images[0].image);
          
           const response =await PhoenixAPI.patch(`longevity-coach-meal-plans/${suggestedMealPlan.id}`,formData);
         await  this.fetchCoachSuggestedPlans();
          }
           catch(error){
             this.showErrorNotification(error.message);
           }
    }

      fetchCoachSuggestedPlans=async()=>{
        const { customerId, country,dependentId ,longevityCategoryId} = this.state;
        this.showLoading();
        try {
          const { data: allDetails = {} } = await PhoenixAPI.get(
            BASE_URL_COACH_PLANS + customerId,{params:{countryId:country,longevityCategoryId:longevityCategoryId,dependentId:dependentId?dependentId:null}}
          );
          const{mealPlan}=allDetails;
          
           if(mealPlan!==null ){
           const arrayMeal=[];
           arrayMeal.push(mealPlan);
          this.setState({
            
            suggestedMealPlans:arrayMeal,
            
          });
        }
          else{
            this.setState({
            
              suggestedMealPlans:[]
              
            })
          }
        }
          
          catch (error) {
            this.showErrorNotification(
              "Error on fetching customer longevity score: ",
              error.message
            );
            this.setState({ loadError: "Some error has occured. Please try again" });
          } finally {
            this.hideLoading();
          }
    
    
      }

      handleDeleteMealPlan=async()=>{
        const {suggestedMealPlan} = this.state;
        try{
          this.showLoading();
          

        const response =await PhoenixAPI.delete(`/longevity-coach-meal-plans/${suggestedMealPlan.id}`);
         
        this.hideArticleViewModal();
           this.fetchCoachSuggestedPlans();
        }
        catch(error){
          this.showErrorNotification(error.message);
        }
      }
     
      validate = () => {
        const{images}=this.state;
        const errors = {};
      
       
    
         if (images.length<1) errors.image= "Please upload pdf ";
         
    
        return Object.keys(errors).length === 0 ? null : errors;
      };

      handleSaveMealPlan = async () => {
        const {  suggestedMealPlan} = this.state;
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
          this.showErrorNotification("There are some invalid form fields!");
          return;
        }
        try {
          this.showLoading();
          const isValidId = suggestedMealPlan.id !== null && suggestedMealPlan.id !== undefined;
          const response =!isValidId
            ? await this.createSuggestedMealPlan(suggestedMealPlan)
            : await this.updateSuggestedMealPlan(suggestedMealPlan);
          this.hideArticleViewModal();
          this.showSuccessNotification(
            isValidId
              ? " Updated Successfully."
              : " Created Successfully."
          );
         
        } catch (error) {
          this.showErrorNotification(
            "Error on  saving :",
            error.message
          );
        } finally {
          this.hideLoading();
        }
      };
      handleUpdateMealPlan=()=>{
        this.setState({ updateMealPlan:true})
      }

    render() {
      const{suggestedMealPlans,suggestedMealPlan,updateMealPlan ,errors,isNewMealPlan,isEditable,editables}=this.state;
      const fileSource=suggestedMealPlan?.fileUrl;
      console.log("suggestedMealPlan",suggestedMealPlans);
      const coachForm=(
        <div className="container mt-4">
        <div className="row">
          {isNewMealPlan || updateMealPlan ?<><div className="form-group col">
          <label htmlFor="File Upload">Upload PDF File</label>
          <div className="input-group">
            <input
              type="file"
              onChange={this.handleSelectImage}
              id={`ImageInput`}
              name="image"
              accept="image/*, application/pdf"
              // disabled={!this.props.isEditable ?? true}
              className="form-control custom-file-input"
            />
            </div>

            <label
              htmlFor={`ImageInput`}
              className="btn btn-success px-2 py-1"
              style={{ cursor: "pointer" }}
            >
              Upload File
            </label><br/>
            <span className="help-block">{errors.image}</span>
          </div>
          
          <div className="form-group col-6">{this.renderPdfResults()}</div></>
          :
          <div className="form-group col">
             <label htmlFor="File Upload"> File Name</label>
           <div className="image-area" style={{ marginBottom: "30px" }}>
          {/* <PdfPreview
            pdfFile={isPdfFile ? fileSource : null}
            pdfFileUrl={isPdfFile ? null : fileSource}
          /> */}
           <form>
                    <div className="form-row">
                      <div className="col-12">
                        <a
                          className="view-pdf"
                          href={fileSource}
                          target="_blank"
                        >
                          <label>
                            <Pdf></Pdf> View Result document
                          </label>
                        </a>
                      </div>
                    </div>
                  </form>
        </div>
        <div className="row">
        <button
              onClick={this.handleUpdateMealPlan}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Update
            </button> &nbsp;&nbsp;
            <button
              onClick={this.handleDeleteMealPlan}
              disabled={!isEditable}
              className="btn modal-delete-btn"
            >
              Delete
            </button>
            </div>
          </div>

          }
        </div>

    <div className="row">
      

        <div className="form-group col-6">
        <label htmlFor="default package">Status</label>
        <div className="input-group">
        <Dropdown
                value={suggestedMealPlan.isActive}
                onChange={(event, value) => {
                  this.setState({
                    suggestedMealPlan: {
                      ...suggestedMealPlan,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewMealPlan && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>

        

        </div>
        </div>

      )
        return(<>
         <div >
              <div className="page-header">
          <div className="actions">
              {suggestedMealPlans.length<1?<div className="action-item">
              <button
                onClick={this.handleAddNewMealPlan}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Meal Plan
              </button>
            </div>:<></>}
            </div>
            </div>
                <Card>
                    <Card.Header>Meal Plan</Card.Header>
                    <Card.Body>
                    <DataTable
                highlightOnHover
                columns={this.columns}
                data={suggestedMealPlans || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }/>
                 
                    </Card.Body>
                </Card>
                <Modal
          size="lg"
          show={this.state.isMealViewModal}
          onHide={this.hideArticleViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  { suggestedMealPlan.id ? "Edit Meal plan" : "Add Meal plan"}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveMealPlan}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{coachForm}</Modal.Body>
        </Modal>

            </div>
        </>

        )
    }

}

CoachMeal.propTypes = {
  suggestedMealPlans: PropTypes.arrayOf(PropTypes.object).isRequired,
 
  country:PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

CoachMeal.defaultProps = {
  suggestedMealPlans: [],
  country: null,
  
  isEditable: true,
};


function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CoachMeal);