import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectFamilyMember.propTypes = {
  familyMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedFamilyMemberId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectFamilyMember.defaultProps = {
  familyMembers: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectFamilyMember(props) {
  const {
    familyMembers,
    selectedFamilyMemberId,
    onSelect,
    menuPlacement,
    isDisabled,
  } = props;
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };
  const findFamilyMember = (id) => familyMembers.find((it) => it.id === id);

  const filteredFamilyMembers = familyMembers
    .filter((member) => member.firstName || member.lastName)
    .sort(
      (first, second) =>
        compareStrings(first.firstName, second.firstName, true) ||
        compareStrings(first.lastName, second.lastName, true)
    );
  const options = filteredFamilyMembers.map(createOption);
  const selectedFamilyMember = findFamilyMember(selectedFamilyMemberId);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedFamilyMember && selectedFamilyMember.id
      }`}
      value={createOption(selectedFamilyMember)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isDisabled={isDisabled}
    />
  );
}

const getName = (member) => {
  const { firstName, lastName } = member;
  return `${firstName ?? ""} ${lastName ?? ""}`.trim();
};

const createOption = (member) => {
  return member
    ? {
        key: member.id,
        value: member.id,
        label: `${getName(member)} ${member.relation ? "(" + member.relation + ")" : ""}`,
      }
    : null;
};

export default SelectFamilyMember;
