import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoneInput from "react-phone-input-2";
import { ArrowDownward } from "@material-ui/icons";
import Search from "components/Search/Search";
import AlertBox from "components/AlertBox/AlertBox";
import SelectCities from "components/Common/SelectCities";
import SelectCategories from "./SelectCategories";
import ToggleSwitch from "components/Common/ToggleSwitch";
import { fetchCities } from "services/RegionService";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import PdfPreview from "components/Common/PdfPreview";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TEN_MEGA_BYTE = 10485760;
const BASE_URL_PROFESSIONALS = "/home-care-professional";

class ProfessionalManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      professionals: [],
      professional: {},
      cities: [],
      errors: {},
      editables: {},
      categoryFilter: null,
      loadError: null,
      searchTerm: null,
      isNewProfessional: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Professional Name",
        selector: "professionalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Cities",
        selector: "cityNames",
        wrap: true,
        sortable: false,
      },
      {
        name: "Category",
        selector: "category",
        wrap: true,
        sortable: false,
        format: (professional) =>
          this.getFormattedCategories(professional.category),
      },
      {
        name: "WhatsApp Number",
        selector: "whatsappNumber",
        wrap: true,
        sortable: true,
        format: (professional) => professional.whatsappNumber ?? "",
      },
      {
        name: "Phone Number",
        selector: "phoneNumber",
        wrap: true,
        sortable: true,
        format: (professional) => professional.phoneNumber ?? "",
      },
      {
        name: "Enable/Disable",
        selector: "isEnable",
        maxWidth: "150px",
        center: true,
        sortable: true,
        cell: (professional) => this.renderToggleStatus(professional),
      },
      {
        name: "Action",
        center: true,
        minWidth: "170px",
        cell: (professional) => this.renderViewButton(professional.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([this.fetchUser(), this.fetchCities()]);
    this.fetchProfessionals();
  }

  fetchUser = async () => {
    try {
      this.showLoading();
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      console.log("fetchUser:: Response", { response });
      this.setState({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching user!", error);
      this.showErrorNotification("Error on fetching user!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchProfessionals = async () => {
    this.showLoading();
    const { user } = this.state;
    try {
      const url =
        user && user.homecare_service_id
          ? `${BASE_URL_PROFESSIONALS}/homecareId/${user.homecare_service_id}`
          : BASE_URL_PROFESSIONALS;
      const { data: professionals = [] } = await PhoenixAPI.get(url);
      professionals.forEach((professional) => {
        const { cities } = professional;
        professional.cityNames =
          cities && cities.length
            ? cities.map(this.getCityNameByCityId).join(", ")
            : "";
      });
      this.setState({ professionals });
    } catch (error) {
      console.log("Error on fetching professionals!", error);
      this.showErrorNotification(
        "Error on fetching professionals: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    this.setState({ searchApplied: true, searchTerm: queryString ?? "" });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const professional = { ...this.state.professional };
    professional[input.name] = input.value;
    this.setState({ professional });
  };

  handleSelectFile = ({ currentTarget: input }) => {
    const fieldName = input.name;
    if (input.files && input.files.length) {
      const file = input.files[0];
      const professional = {
        ...this.state.professional,
        [fieldName]: file,
      };
      this.setState({ professional });
      this.readFile(`${fieldName}Src`, file);
    }
  };

  readFile = (fieldName, file) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const professional = {
        ...this.state.professional,
        [fieldName]: reader.result,
      };
      this.setState({ professional });
    }.bind(this);

    if (file?.type?.startsWith("image/")) {
      reader.readAsDataURL(file);
    } else if (file?.type === "application/pdf") {
      reader.readAsArrayBuffer(file);
    }
  };

  handleView = (id) => {
    const professionals = this.state.professionals || [];
    const professional = professionals.find((it) => it.id === id);
    if (professional) {
      professional.licenseFrontSrc = professional.licenseFront ?? null;
      professional.licenseBackSrc = professional.licenseBack ?? null;
      professional.profEmiratesFrontIdSrc =
        professional.profEmiratesFrontId ?? null;
      professional.profEmiratesBackIdSrc =
        professional.profEmiratesBackId ?? null;
      this.setState({ isNewProfessional: false, professional }, () =>
        this.showModal()
      );
    }
  };

  handleAddProfessional = () => {
    const professional = { isEnable: true };
    this.setState({ professional, isNewProfessional: true }, () =>
      this.showModal()
    );
  };

  handlePhoneNumberChange = async (fieldName, value, data) => {
    console.log("numberCheck:: ", { value, data });
    const { dialCode } = data;
    if (dialCode && value) {
      this.setState({
        professional: {
          ...this.state.professional,
          [fieldName]: `+${dialCode}${value.slice(dialCode.length)}`,
        },
      });
    }
  };

  handleSave = async () => {
    const { user } = this.state;
    if (!user || !user.homecare_service_id) {
      console.log("handleSave:: Invalid user or homecare service id", { user });
      this.showErrorNotification("Homecare service id is not found!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { professional } = this.state;
    const payload = { ...professional };
    delete payload.licenseFrontSrc;
    delete payload.licenseBackSrc;
    delete payload.profEmiratesFrontIdSrc;
    delete payload.profEmiratesBackIdSrc;
    if (typeof payload.licenseFront !== "string") {
      delete payload.licenseFront;
    }
    if (typeof payload.licenseBack !== "string") {
      delete payload.licenseBack;
    }
    if (typeof payload.profEmiratesFrontId !== "string") {
      delete payload.profEmiratesFrontId;
    }
    if (typeof payload.profEmiratesBackId !== "string") {
      delete payload.profEmiratesBackId;
    }
    if (!professional.id) {
      payload.homecareId = user.homecare_service_id;
    }

    try {
      const formData = new FormData();
      formData.append("professional", JSON.stringify(payload));
      if (typeof professional.licenseFront !== "string") {
        formData.append("licenseFront", professional.licenseFront);
      }
      if (typeof professional.licenseBack !== "string") {
        formData.append("licenseBack", professional.licenseBack);
      }
      if (typeof professional.profEmiratesFrontId !== "string") {
        formData.append(
          "profEmiratesFrontId",
          professional.profEmiratesFrontId
        );
      }
      if (typeof professional.profEmiratesBackId !== "string") {
        formData.append("profEmiratesBackId", professional.profEmiratesBackId);
      }
      const config = {
        method: professional.id ? "PATCH" : "POST",
        url: professional.id
          ? `${BASE_URL_PROFESSIONALS}/${professional.id}`
          : BASE_URL_PROFESSIONALS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchProfessionals();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleOnToggleStatus = async (professional, isToggled) => {
    try {
      this.showLoading();
      const payload = { ...professional };
      payload.isEnable = isToggled;

      const formData = new FormData();
      formData.append("professional", JSON.stringify(payload));

      const config = {
        method: "PATCH",
        url: `${BASE_URL_PROFESSIONALS}/${professional.id}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification(
        isToggled ? "Enabled Successfully" : "Disabled Successfully"
      );
      this.fetchProfessionals();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const errors = {};
    const {
      professionalName,
      whatsappNumber,
      phoneNumber,
      cities,
      category,
      licenseExpiryDate,
      licenseFront,
      licenseBack,
      profEmiratesFrontId,
      profEmiratesBackId,
    } = this.state.professional;

    if (!professionalName || professionalName.trim() === "")
      errors.professionalName = "Professional Name cannot be blank!";
    else if (professionalName.length > 200)
      errors.professionalName =
        "Professional Name is limited to a 200 characters!";

    if (!whatsappNumber || whatsappNumber.trim() === "")
      errors.whatsappNumber = "WhatsApp Number cannot be blank!";

    if (!phoneNumber || phoneNumber.trim() === "")
      errors.phoneNumber = "Phone Number cannot be blank!";

    if (!cities || !cities.length) errors.cities = "Cities cannot be blank!";

    if (!category || !category.length)
      errors.category = "Category cannot be blank!";

    if (!licenseExpiryDate)
      errors.licenseExpiryDate = "License Expiry Date cannot be blank!";

    if (!licenseFront) errors.licenseFront = "License - Front cannot be blank!";
    else if (
      licenseFront &&
      licenseFront.size &&
      licenseFront.size > TEN_MEGA_BYTE
    )
      errors.licenseFront = "License - Front should be less than 10 MB!";

    if (!licenseBack) errors.licenseBack = "License - Back cannot be blank!";
    else if (
      licenseBack &&
      licenseBack.size &&
      licenseBack.size > TEN_MEGA_BYTE
    )
      errors.licenseBack = "License - Back should be less than 10 MB!";

    if (!profEmiratesFrontId)
      errors.profEmiratesFrontId =
        "Professional Emirates ID - Front cannot be blank!";
    else if (
      profEmiratesFrontId &&
      profEmiratesFrontId.size &&
      profEmiratesFrontId.size > TEN_MEGA_BYTE
    )
      errors.profEmiratesFrontId =
        "Professional Emirates ID - Front should be less than 10 MB!";

    if (!profEmiratesBackId)
      errors.profEmiratesBackId =
        "Professional Emirates ID - Back cannot be blank!";
    else if (
      profEmiratesBackId &&
      profEmiratesBackId.size &&
      profEmiratesBackId.size > TEN_MEGA_BYTE
    )
      errors.profEmiratesBackId =
        "Professional Emirates ID - Back should be less than 10 MB!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      professional: {},
      isNewProfessional: false,
    });
  };

  findCityById = (cityId) => {
    const cities = this.state.cities ?? [];
    return cities.find((city) => city.id === cityId);
  };

  getCityNameByCityId = (cityId) => {
    const city = this.findCityById(cityId);
    if (!city) {
      console.log("getCityNameByCityId:: City not found with id=", cityId);
      return "";
    }
    return city?.city_name ?? "";
  };

  getFormattedCategories = (categories) => {
    return categories && categories.length ? categories.join(", ") : "";
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderToggleStatus = (professional) => {
    if (!professional) return null;
    return (
      <ToggleSwitch
        key={professional.id}
        id={`toggle-${professional.id}`}
        toggled={professional.isEnable ?? false}
        onToggle={(isToggled) =>
          this.handleOnToggleStatus(professional, isToggled)
        }
      />
    );
  };

  renderViewButton = (id) => (
    <div>
      <button
        onClick={() => this.handleView(id)}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        View
      </button>
    </div>
  );

  getFilteredProfessionals = () => {
    const { categoryFilter } = this.state;
    const professionals =
      this.getProfessionalsForCurrentHomeCareService() ?? [];
    const filteredProfessionals = categoryFilter
      ? professionals.filter((professional) => {
          const { category } = professional;
          return (
            category && category.length && category.includes(categoryFilter)
          );
        })
      : professionals;
    return this.getSearchedProfessionals(filteredProfessionals);
  };

  getProfessionalsForCurrentHomeCareService = () => {
    const { user, professionals } = this.state;
    return user && professionals && professionals.length
      ? professionals.filter(
          (professional) => professional.homecareId === user.homecare_service_id
        )
      : [];
  };

  getSearchedProfessionals = (professionals) => {
    if (!professionals || !professionals.length) return [];

    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return professionals;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return professionals.filter((professional) => {
      const {
        id,
        professionalName,
        cityNames,
        category,
        whatsappNumber,
        phoneNumber,
      } = professional;
      return (
        (id && id.toString() === newValue) ||
        (professionalName &&
          professionalName.toLowerCase().indexOf(newValue) !== -1) ||
        (cityNames && cityNames.toLowerCase().indexOf(newValue) !== -1) ||
        (category &&
          category.length &&
          category.find((it) => it.toLowerCase().indexOf(newValue) !== -1)) ||
        (whatsappNumber &&
          whatsappNumber.toLowerCase().indexOf(newValue) !== -1) ||
        (phoneNumber && phoneNumber.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (this.state.isNewProfessional) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  renderDocumentPreview = (fieldName, label) => {
    const { professional } = this.state;
    if (!professional) return null;

    const fieldNameSrc = fieldName + "Src";
    const file = professional[fieldName];
    const fileSource = professional[fieldNameSrc];
    if (!fileSource) return null;

    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <div>
        <img
          id={fieldNameSrc}
          name={fieldNameSrc}
          src={fileSource}
          alt="Selected"
          className="img-thumbnail mx-auto"
          style={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
          }}
        />
      </div>
    );
    return (
      <>
        <label htmlFor={fieldName}>{label}</label>
        {preview}
      </>
    );
  };

  render() {
    const {
      professional,
      cities,
      editables,
      errors,
      categoryFilter,
      searchTerm,
      searchApplied,
      loadError,
      showModal,
      isNewProfessional,
    } = this.state;

    const professionals = this.getFilteredProfessionals();

    const licenseInformationForm = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="licenseExpiryDate">License Expiry Date*</label>
            <div className="input-group">
              <input
                value={professional.licenseExpiryDate ?? ""}
                onChange={this.handleChange}
                id="licenseExpiryDate"
                name="licenseExpiryDate"
                type="date"
                placeholder="License Expiry Date"
                className={
                  "form-control py-2" +
                  (errors.licenseExpiryDate ? " has-error" : "")
                }
                disabled={!isNewProfessional && !editables.licenseExpiryDate}
              />
              {this.renderPencilIcon("licenseExpiryDate")}
            </div>
            <span className="help-block">{errors.licenseExpiryDate}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="licenseFront">License - Front*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectFile}
                id="licenseFront"
                name="licenseFront"
                type="file"
                accept="image/*, application/pdf"
                disabled={!isNewProfessional && !editables.licenseFront}
                className={
                  "form-control" + (errors.licenseFront ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("licenseFront")}
            </div>
            <span className="help-block">{errors.licenseFront}</span>
          </div>
          <div className="form-group col-6">
            {this.renderDocumentPreview(
              "licenseFront",
              "License - Front Preview"
            )}
            {/* {professional?.licenseFrontSrc && (
              <>
                <label htmlFor="licenseFrontSrc">License - Front Preview</label>
                <div>
                  <img
                    id="licenseFrontSrc"
                    name="licenseFrontSrc"
                    src={professional.licenseFrontSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )} */}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="licenseBack">License - Back*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectFile}
                id="licenseBack"
                name="licenseBack"
                type="file"
                accept="image/*, application/pdf"
                disabled={!isNewProfessional && !editables.licenseBack}
                className={
                  "form-control" + (errors.licenseBack ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("licenseBack")}
            </div>
            <span className="help-block">{errors.licenseBack}</span>
          </div>
          <div className="form-group col-6">
            {this.renderDocumentPreview(
              "licenseBack",
              "License - Back Preview"
            )}
            {/* {professional?.licenseBackSrc && (
              <>
                <label htmlFor="licenseBackSrc">License - Back Preview</label>
                <div>
                  <img
                    id="licenseBackSrc"
                    name="licenseBackSrc"
                    src={professional.licenseBackSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )} */}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="profEmiratesFrontId">
              Professional Emirates ID - Front*
            </label>
            <div className="input-group">
              <input
                onChange={this.handleSelectFile}
                id="profEmiratesFrontId"
                name="profEmiratesFrontId"
                type="file"
                accept="image/*, application/pdf"
                disabled={!isNewProfessional && !editables.profEmiratesFrontId}
                className={
                  "form-control" +
                  (errors.profEmiratesFrontId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("profEmiratesFrontId")}
            </div>
            <span className="help-block">{errors.profEmiratesFrontId}</span>
          </div>
          <div className="form-group col-6">
            {this.renderDocumentPreview(
              "profEmiratesFrontId",
              "Professional Emirates ID - Front Preview"
            )}
            {/* {professional?.profEmiratesFrontIdSrc && (
              <>
                <label htmlFor="profEmiratesFrontIdSrc">
                  Professional Emirates ID - Front Preview
                </label>
                <div>
                  <img
                    id="profEmiratesFrontIdSrc"
                    name="profEmiratesFrontIdSrc"
                    src={professional.profEmiratesFrontIdSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )} */}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="profEmiratesBackId">
              Professional Emirates ID - Back*
            </label>
            <div className="input-group">
              <input
                onChange={this.handleSelectFile}
                id="profEmiratesBackId"
                name="profEmiratesBackId"
                type="file"
                accept="image/*, application/pdf"
                disabled={!isNewProfessional && !editables.profEmiratesBackId}
                className={
                  "form-control" +
                  (errors.profEmiratesBackId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("profEmiratesBackId")}
            </div>
            <span className="help-block">{errors.profEmiratesBackId}</span>
          </div>
          <div className="form-group col-6">
            {this.renderDocumentPreview(
              "profEmiratesBackId",
              "Professional Emirates ID - Back Preview"
            )}
            {/* {professional?.profEmiratesBackIdSrc && (
              <>
                <label htmlFor="profEmiratesBackIdSrc">
                  Professional Emirates ID - Back Preview
                </label>
                <div>
                  <img
                    id="profEmiratesBackIdSrc"
                    name="profEmiratesBackIdSrc"
                    src={professional.profEmiratesBackIdSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )} */}
          </div>
        </div>
      </>
    );

    const contactDetailsRow = (
      <div className="form-group-container">
        <div className="form-group pl-0 pr-auto">
          <label htmlFor="whatsappNumber">WhatsApp Number*</label>
          <div className="user-input-container">
            <div
              className={`${
                editables.whatsappNumber || isNewProfessional
                  ? `non-editable-phone`
                  : `editable-phone`
              } `}
            >
              <PhoneInput
                value={professional.whatsappNumber ?? ""}
                onChange={(value, data) =>
                  this.handlePhoneNumberChange("whatsappNumber", value, data)
                }
                disabled={!isNewProfessional && !editables.whatsappNumber}
                inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                className={
                  "form-cotrol user-data-input contact-no" +
                    isNewProfessional || editables.whatsappNumber
                    ? " non-editable"
                    : " editable" + errors.whatsappNumber
                    ? " has-error"
                    : ""
                }
                autocompleteSearch
                autoFormat
              />
              {this.renderPencilIcon("whatsappNumber")}
              <span className="help-block">{errors.whatsappNumber}</span>
            </div>
          </div>
        </div>
        <div className="form-group pl-auto pr-0">
          <label htmlFor="phoneNumber">Phone Number*</label>
          <div className="user-input-container">
            <div
              className={`${
                editables.phoneNumber || isNewProfessional
                  ? `non-editable-phone`
                  : `editable-phone`
              } `}
            >
              <PhoneInput
                value={professional.phoneNumber ?? ""}
                onChange={(value, data) =>
                  this.handlePhoneNumberChange("phoneNumber", value, data)
                }
                disabled={!isNewProfessional && !editables.phoneNumber}
                inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                className={
                  "form-cotrol user-data-input contact-no" +
                    isNewProfessional || editables.phoneNumber
                    ? " non-editable"
                    : " editable" + errors.phoneNumber
                    ? " has-error"
                    : ""
                }
                autocompleteSearch
                autoFormat
              />
              {this.renderPencilIcon("phoneNumber")}
              <span className="help-block">{errors.phoneNumber}</span>
            </div>
          </div>
        </div>
      </div>
    );

    const profileForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="professionalName">Professional Name*</label>
            <div className="input-group">
              <input
                value={professional.professionalName ?? ""}
                onChange={this.handleChange}
                id="professionalName"
                name="professionalName"
                type="text"
                placeholder="Professional Name"
                className={
                  "form-control py-2" +
                  (errors.professionalName ? " has-error" : "")
                }
                disabled={!isNewProfessional && !editables.professionalName}
              />
              {this.renderPencilIcon("professionalName")}
            </div>
            <span className="help-block">{errors.professionalName}</span>
          </div>
        </div>
        {contactDetailsRow}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cities">Cities*</label>
            <div className="input-group user-input-container">
              <SelectCities
                id="cities"
                name="cities"
                cities={cities ?? []}
                selectedCityIds={professional.cities ?? []}
                onSelect={(options) => {
                  const newProfessional = {
                    ...professional,
                    cities: options.map((it) => it.value),
                  };
                  this.setState({ professional: newProfessional });
                }}
                showOnlyActive={true}
                isDisabled={!isNewProfessional && !editables.cities}
              />
              {this.renderPencilIcon("cities")}
            </div>
            <span className="help-block">{errors.cities}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="category">Category*</label>
            <div className="input-group user-input-container">
              <SelectCategories
                id="category"
                name="category"
                selectedCategories={professional.category ?? []}
                onSelect={(options) => {
                  const newProfessional = {
                    ...professional,
                    category: options.map((it) => it.value),
                  };
                  this.setState({ professional: newProfessional });
                }}
                isDisabled={!isNewProfessional && !editables.category}
              />
              {this.renderPencilIcon("category")}
            </div>
            <span className="help-block">{errors.category}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>License Information</span>
          </div>
        </div>
        {licenseInformationForm}
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="col-3 ml-0 pl-0">
            <SelectCategories
              id="categoryFilter"
              name="categoryFilter"
              selectedCategories={categoryFilter ? [categoryFilter] : []}
              onSelect={(options) => {
                const categoryFilter =
                  options && options.length
                    ? options[options.length - 1].value
                    : null;
                this.setState({ categoryFilter });
              }}
              placeholder="Please select a Category"
            />
          </div>
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddProfessional}
              >
                <i className="fa fa-plus-circle"></i>Add Professional
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={professionals}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + professionals.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Name: </span>
                {professional?.professionalName ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.handleSave} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_professionals">
              <Tab eventKey="key_profile_form" title="Profile">
                {profileForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionalManagement);
