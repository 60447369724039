import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_FITNESS_PLANS = "fitness-plan-page";

export const fetchFitnessPlans = async () => {
  try {
    const { data } = await PhoenixAPI.get(BASE_URL_FITNESS_PLANS);
    return data;
  } catch (error) {
    console.log("Error on fetching otherItems", error);
    throw error;
  }
};

export const saveFitnessQuestion = async (fitnessQuestion) => {
  try {
    const payload = { ...fitnessQuestion };
    const response = await PhoenixAPI.post("fitness-questions", payload);
    return response;
  } catch (error) {
    console.log("Error in saving Fitness Question", error.message);
    throw error;
  }
};

export const updateFitnessQuestion = async (fitnessQuestion) => {
  try {
    const { createdAt, modifiedAt, ...updatedFitnessQuestion } =
      fitnessQuestion;
    const payload = { ...updatedFitnessQuestion };
    const response = await PhoenixAPI.post("fitness-questions", payload);
    return response;
  } catch (error) {
    console.log("Error in saving Fitness Question", error.message);
    throw error;
  }
};

const FitnessPlansService = {
  fetchFitnessPlans,
  saveFitnessQuestion,
  updateFitnessQuestion,
};

export default FitnessPlansService;
