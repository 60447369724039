import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
import {
  formatTimeslot,
  formatTimeslotTwentyFourHourFormat,
} from "utils/commons";

const BASE_URL_TIMESLOTS = "get-lab-availability/";
const BASE_URL_ALL_TIMESLOTS = "all-lab-slots/";

SelectTimeslot.propTypes = {
  orderFlow: PropTypes.string,
  cityId: PropTypes.number.isRequired,
  packageId: PropTypes.number.isRequired,
  packageType: PropTypes.string.isRequired,
  miniPackages: PropTypes.arrayOf(PropTypes.number),
  date: PropTypes.string.isRequired,
  selectedTimelot: PropTypes.string,
  isDisabled: PropTypes.bool,
  onTimeslotSelect: PropTypes.func.isRequired,
};

SelectTimeslot.defaultProps = {
  orderFlow: "Current",
  miniPackages: [],
  isDisabled: false,
  onTimeslotSelect: (f) => f,
};

function SelectTimeslot(props) {
  const {
    orderFlow,
    cityId,
    packageId,
    packageType,
    miniPackages,
    date,
    isDisabled,
    onTimeslotSelect,
  } = props;
  const selectedTimeslot = props.selectedTimeslot
    ? formatTimeslot(props.selectedTimeslot)
    : props.selectedTimeslot;

  const [availableDates, setAvailableDates] = useState(null);

  const getDefaultTimeSlots = () =>
    selectedTimeslot ? [selectedTimeslot] : [];

  const appendTimeSlot = (newTimeslots) => {
    const found =
      newTimeslots && newTimeslots.length
        ? newTimeslots.find((timeSlot) => timeSlot === selectedTimeslot) ?? null
        : null;
    console.log("SelectTimeslot:: appendTimeSlot:", {
      selectedTimelot: selectedTimeslot,
      found,
      newTimeslots,
    });
    if (selectedTimeslot && (found === null || found === undefined)) {
      console.log("PreviousTimeslots: ", newTimeslots);
      return [selectedTimeslot, ...newTimeslots];
    }
    return newTimeslots;
  };

  useEffect(() => {
    const fetchAllTimeslots = async () => {
      if (cityId === null || cityId === undefined) return;
      try {
        const { data } = await PhoenixAPI.get(BASE_URL_ALL_TIMESLOTS, {
          params: { city: cityId },
        });
        console.log(
          "SelectTimeslot:: fetchAllTimeslots: availableDates:",
          availableDates
        );
        setAvailableDates(data?.available_dates ?? null);
      } catch (error) {
        console.log("Error fetching all timeslots error: ", error);
      }
    };
    if (orderFlow !== "Current") fetchAllTimeslots();
  }, [cityId]);

  useEffect(() => {
    // https://api.bevaleo.dev/api/v1/get-lab-availability/?cities=1&mini_packages=1,2,3,4
    const fetchTimeslots = async () => {
      if (cityId === null || cityId === undefined) return;
      if (packageType === null || packageType === undefined) return;
      if (packageType === "mini_package" && !miniPackages.length) return;
      if (
        packageType !== "mini_package" &&
        (packageId === null || packageId === undefined)
      )
        return;
      try {
        const params = { cities: cityId };
        if (packageType === "mini_package") {
          params.mini_packages = miniPackages.join();
        } else {
          params[packageType] = packageId;
        }
        const { data } = await API.get(BASE_URL_TIMESLOTS, { params });
        console.log(
          "SelectTimeslot:: fetchTimeslots: availableDates:",
          availableDates
        );
        setAvailableDates(data?.available_dates ?? null);
      } catch (error) {
        console.log("Error fetching timeslots error: ", error);
      }
    };

    if (orderFlow === "Current") fetchTimeslots();
  }, [cityId, packageType, packageId, miniPackages]);

  const compareTimeslots = (first, second) => {
    const firstIn24Hour = formatTimeslotTwentyFourHourFormat(first);
    const secondIn24Hour = formatTimeslotTwentyFourHourFormat(second);
    return firstIn24Hour?.localeCompare(secondIn24Hour);
  };

  let timeslots = [];
  if (availableDates) {
    const today = moment().format("YYYY-MM-DD");
    const foo = date ? date : today;
    const formattedDate =
      orderFlow === "Current"
        ? foo
        : moment(foo)?.format("dddd")?.toLowerCase();
    console.log("SelectTimeslot:: Date:", { orderFlow, formattedDate });
    const details = availableDates[formattedDate];
    if (details && details.is_holiday === false) {
      console.log(
        "SelectTimeslot:: Slots For Date: ",
        formattedDate,
        details.slots
      );
      const newTimeSlots =
        details.slots && details.slots.length > 0
          ? details.slots.map((slot) => formatTimeslot(slot))
          : [];
      timeslots = appendTimeSlot(newTimeSlots);
      timeslots.sort((first, second) => compareTimeslots(first, second));
    } else {
      console.log(
        "SelectTimeslot:: Slots For Date else part: ",
        formattedDate,
        details
      );
    }
  } else {
    timeslots = getDefaultTimeSlots();
  }

  console.log("SelectTimeslot:: timeslots:", { availableDates, timeslots });

  const createOption = (timeslot) => {
    return {
      key: timeslot,
      value: timeslot,
      label: timeslot,
    };
  };

  const timeslotOptions = timeslots.map(createOption);

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
      // zIndex: "105 !important",
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedTimeslot}`}
      value={createOption(selectedTimeslot)}
      onChange={(option) =>
        onTimeslotSelect(formatTimeslotTwentyFourHourFormat(option.value))
      }
      options={timeslotOptions}
      styles={selectStyles}
      placeholder="Select Timeslot"
      isDisabled={isDisabled}
    />
  );
}

export default SelectTimeslot;
