import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import {
  fetchOtherItems,
  saveOtherItem,
  updateOtherItem,
} from "services/OtherItemService";

import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { UploadMediaFile } from "services/UploadMedia";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class Physiotherapist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      physiotherapists: [],
      physiotherapist: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isPhysiotherapistViewModalVisible: false,
    };

    this.columns = [
      {
        name: "Physio Internal ID",
        selector: "id",
        width: "200px",
        sortable: true,
      },
      {
        name: "Physiotherapist Name",
        selector: "nameEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (physiotherapist) => (
          <Status isActive={physiotherapist.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (physiotherapist) => this.renderViewButton(physiotherapist.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchPhysiotherapists();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = "Physiotherapist";
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchPhysiotherapists = async () => {
    this.showLoading();
    try {
      const { data: physiotherapists = [] } =
        (await PhoenixAPI.get("admin/physiotherapist")) || [];
      this.setState({ physiotherapists });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching physiotherapists: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = async (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      try {
        const {message:uploadedImage=""} = await UploadMediaFile(image);

        const physiotherapist = {
          ...this.state.physiotherapist,
          image_src: uploadedImage,
          image: uploadedImage,
        };
        this.showSuccessNotification(
            "Image is uploaded successfully"
          );
        this.setState({ physiotherapist });
      } catch (error) {
        this.showErrorNotification(
          "Error on uploading image: ",
          error.message
        );
      }
    }
  };

 

  handleChange = ({ currentTarget: input }) => {
    const physiotherapist = { ...this.state.physiotherapist };
    physiotherapist[input.name] = input.value;
    this.setState({ physiotherapist });
  };

  handleAddNewPhysiotherapist = () => {
    this.setState({ isNewPhysiotherapist: true }, () =>
      this.showPhysiotherapistViewModal()
    );
  };

  handleViewPhysiotherapist = (physiotherapistId) => {
    const physiotherapists = this.state.physiotherapists ?? [];
    const physiotherapist = physiotherapists.find(
      (it) => it.id === physiotherapistId
    );
    if (!physiotherapist) {
      console.log(`Physiotherapist with id ${physiotherapistId} is not found!`);
      return;
    }

    physiotherapist.image_src = physiotherapist.image;
    if (
      physiotherapist.currency === null ||
      physiotherapist.currency === undefined
    ) {
      physiotherapist.currency = this.state.selectedCountry?.currency;
    }

    this.setState({ physiotherapist, isNewPhysiotherapist: false }, () =>
      this.showPhysiotherapistViewModal()
    );
  };

  showPhysiotherapistViewModal = () => {
    const { selectedCountry, isNewPhysiotherapist } = this.state;

    if (isNewPhysiotherapist) {
      const physiotherapist = {
        countryId: selectedCountry?.id,
        
        isActive: false,
      };
      this.setState({
        physiotherapist,
        isPhysiotherapistViewModalVisible: true,
      });
    } else {
      this.setState({ isPhysiotherapistViewModalVisible: true });
    }
  };

  hidePhysiotherapistViewModal = () => {
    this.setState({ isPhysiotherapistViewModalVisible: false });
    this.resetForm();
  };

  updatePhysiotherapistDetails=async(physiotherapist)=>{
    const payload = { ...physiotherapist };
    delete payload["image_src"];
   try{
     const response = await PhoenixAPI.patch(`physiotherapist/${physiotherapist.id}`, payload);
     return response
   }
   catch(error){
    this.showErrorNotification("Error updating physiotherapist", error.message);
   }
  }

  savePhysiotherapistDetails=async(physiotherapist)=>{
    const payload = {...physiotherapist };
    delete payload["image_src"];
    try{
      const response = await PhoenixAPI.post(`physiotherapist`, payload)
      return response
    }
    catch(error){
     this.showErrorNotification("Error updating physiotherapist", error.message);
    }
  }

  handlesavePhysiotherapist = async () => {
    const { selectedCountry, physiotherapist } = this.state;

    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        physiotherapist.id !== null && physiotherapist.id !== undefined;

      const response = isValidId
        ? await this.updatePhysiotherapistDetails(physiotherapist)
        : await this.savePhysiotherapistDetails(physiotherapist);

      this.hidePhysiotherapistViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Physiotherapist Updated Successfully."
          : "Physiotherapist Created Successfully."
      );
      this.fetchPhysiotherapists();
    } catch (error) {
      console.log("Error in updating fetchPhysiotherapist", error.message);

      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { physiotherapist = {} } = this.state;
    const errors = {};

    const {
      status,
      nameEng,
      nameAr,
      experienceEng,
      experienceAr,
      specializationEng,
      specializationAr,
    } = physiotherapist;

    if (status === null) errors.status = "Status is a required field!";

    if (!nameEng || nameEng.trim() === "")
      errors.nameEng = "Name English cannot be blank";
    else if (nameEng.length > 100)
      errors.nameEng = "Name English is limited to a 100 characters";

    if (!nameAr || nameAr.trim() === "")
      errors.nameAr = "Name Arabic cannot be blank";
    else if (nameAr.length > 100)
      errors.nameAr = "Name Arabic is limited to a 100 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  resetForm = () => {
    this.setState({
      otheritem: {},
      errors: {},
      editables: {},
      monthlyPricing: [],
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewPhysiotherapist(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPhysiotherapist ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredPhysiotherapists = () => {
    const physiotherapists = this.getPhysiotherapistsForCurrentCountry();
    return this.getSearchedPhysiotherapists(physiotherapists);
  };

  getPhysiotherapistsForCurrentCountry = () => {
    const { physiotherapists, selectedCountry } = this.state;
    return physiotherapists && selectedCountry
      ? physiotherapists.filter(
          (physiotherapist) => physiotherapist.countryId === selectedCountry.id
        )
      : [];
  };

  getSearchedPhysiotherapists = (physiotherapists) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return physiotherapists;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return physiotherapists.filter((physiotherapist) => {
      const { id, nameEng, nameAr } = physiotherapist;
      return (
        (nameEng && nameEng.toLowerCase().indexOf(newValue) !== -1) ||
        (nameAr && nameAr.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      physiotherapists,
      physiotherapist,
      isNewPhysiotherapist,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredPhysiotherapists =
      this.getFilteredPhysiotherapists(physiotherapists) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="nameEng">Name in English</label>
            <div className="input-group">
              <input
                value={physiotherapist?.nameEng || ""}
                onChange={this.handleChange}
                id="nameEng"
                name="nameEng"
                type="text"
                placeholder="Name in English"
                className={
                  "form-control py-2" + (errors.nameEng ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.nameEng}
              />
              {this.renderPencilIcon("nameEng")}
            </div>
            <span className="help-block">{errors.nameEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="nameAr">Name in Arabic</label>
            <div className="input-group">
              <input
                value={physiotherapist?.nameAr || ""}
                onChange={this.handleChange}
                id="nameAr"
                name="nameAr"
                type="text"
                placeholder="Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.nameAr ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.nameAr}
              />
              {this.renderPencilIcon("nameAr")}
            </div>
            <span className="help-block">{errors.nameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="experienceEng">Experience in English</label>
            <div className="input-group">
              <input
                value={physiotherapist?.experienceEng || ""}
                onChange={this.handleChange}
                id="experienceEng"
                name="experienceEng"
                type="text"
                placeholder="Experience in English"
                className={
                  "form-control py-2" +
                  (errors.experienceEng ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.experienceEng}
              />
              {this.renderPencilIcon("experienceEng")}
            </div>
            <span className="help-block">{errors.experienceEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="experienceAr">Experience in Arabic</label>
            <div className="input-group">
              <input
                value={physiotherapist?.experienceAr || ""}
                onChange={this.handleChange}
                id="experienceAr"
                name="experienceAr"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.experienceAr ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.experienceAr}
              />
              {this.renderPencilIcon("experienceAr")}
            </div>
            <span className="help-block">{errors.experienceAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specializationEng">Specialization in English</label>
            <div className="input-group">
              <input
                value={physiotherapist?.specializationEng || ""}
                onChange={this.handleChange}
                id="specializationEng"
                name="specializationEng"
                type="text"
                placeholder="Specialization in English"
                className={
                  "form-control py-2" +
                  (errors.experienceEng ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.specializationEng}
              />
              {this.renderPencilIcon("specializationEng")}
            </div>
            <span className="help-block">{errors.specializationEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="specializationAr">Specialization in Arabic</label>
            <div className="input-group">
              <input
                value={physiotherapist?.specializationAr || ""}
                onChange={this.handleChange}
                id="specializationAr"
                name="specializationAr"
                type="text"
                placeholder="Specialization in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.specializationAr ? " has-error" : "")
                }
                disabled={!isNewPhysiotherapist && !editables.specializationAr}
              />
              {this.renderPencilIcon("specializationAr")}
            </div>
            <span className="help-block">{errors.specializationAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Physio Image</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewPhysiotherapist && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {physiotherapist?.image_src && (
              <>
                <label htmlFor="selected_package_image">Selected Image</label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={physiotherapist.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={physiotherapist?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    physiotherapist: {
                      ...physiotherapist,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPhysiotherapist && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewPhysiotherapist}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredPhysiotherapists || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredPhysiotherapists.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isPhysiotherapistViewModalVisible}
          onHide={this.hidePhysiotherapistViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Physiotherapist : </span>
                {physiotherapist?.nameEng || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesavePhysiotherapist}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_physiotherapists">
              <Tab eventKey="key_form_basic_information">
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Physiotherapist);
