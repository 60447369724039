import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectPeriod.propTypes = {
  selectedPeriod: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectPeriod.defaultTypes = {
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectPeriod(props) {
  const {
    periods,
    selectedPeriod,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;
  const [suggestedSupplements, setSuggestedSupplements] = useState([]);

  const findPeriod = (periodId) =>
    periods.find((period) => period.id === periodId);

  const options = createOptions(periods, showOnlyActive);

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedPeriodObject = findPeriod(selectedPeriod);
  const selectedPeriodOption = selectedPeriodObject
    ? createOption(selectedPeriodObject)
    : null;

  return (
    <Select
      key={`my_unique_select_key__${selectedPeriod && selectedPeriod.id}`}
      value={selectedPeriodOption}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder="Select Period..."
      isDisabled={isDisabled}
    />
  );
}

function createOptions(periods) {
  if (!periods || !periods.length) return [];

  return [
    {
      options: periods.map(createOption),
    },
  ];
}

const createOption = (city) => {
  return city
    ? {
        key: city.id,
        value: city.id,
        label: city.periodEng,
      }
    : null;
};

export default SelectPeriod;
