import React, { useEffect, useState } from 'react'
import AlertModal from './AlertModal';
import PhoenixAPI from "../../utils/PhoenixAPI";
import PdfPreview from 'components/Common/PdfPreview';
import Notification from './Notification';
import API from "utils/API";
import { getUserId, getUserName, getUserEmail } from 'services/UserService';
import { useHistory } from "react-router-dom";
import {trackBiomarkersClick, trackPhoneClicks, trackStartButtonClick, trackUploadSamplePastOrders} from "analytics";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import Select from "react-select";
import "./clientLabDetails.scss";
import HeightDropdown from "./HeightDropdown";
import { Alert } from "react-bootstrap";
function ClientLabDetails(props) {
  const [frontImage, setFrontImage] = useState('');
  const [backImage, setBackImage] = useState('');
  const [imageURL1, setImageURL1] = useState('');
  const [isShowModal, setIsShowModal]=useState(false);
  const [imageURL2, setImageURL2] = useState('');
  const [biomarkerData, setBiomarkerData] = useState({});
  const [biomarkerList, setBiomarkerList] = useState([]);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [disableStartTime,setDisableStartTime]=useState(false)
  const [enableEndTime,setEnableEndTime]=useState(false);
  const [orderId, setOrderId]= useState(null)
  const [clientDetails,setClientDetails]= useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [inputs, setInputs] = useState({});
  const [error, setError]= useState({});
  const[height,setHeight]=useState("");
  const [weight,setWeight]=useState("");

  const [isAlertMsgVisible,setIsAlertMsgVisible]= useState(true)

  const heightOptions = Array.from({ length: 101 }, (_, i) => {
    const value = 100 + i;
    return {
      key: value,
      value: value,
      text: value.toString(),
    };
  });

  const weightOptions = Array.from({ length: 101 }, (_, i) => {
    const value = 10 + i;
    return {
      key: value,
      value: value,
      text: value.toString(),
    };
  });
  
  const paymentStatusOptions = [
    {
        name: "Collected by Cash",
        value: "Collected by Cash",
        label :"Collected by Cash"
    },
    {
        name: "Collected by Card",
        value: "Collected by Card",
        label:"Collected by Card"
    }
]

  const validTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'image/svg+xml'];
  const errMsg = "Please upload only images, docs or pdf "
  const history = useHistory();
  
  const nurseUserId= getUserId()
  useEffect(()=>{
    const {orderId}=props.match.params;
    setOrderId(orderId)
 },[orderId])

  function handleBackButton(data) {
    // if (data === "clientLabDetails") {
    //   setIsShowClientLab(false);
    //   setIsShowUploadSample(false);
    //   setIsShowOrder(true);
    // }
    return history.goBack();

  }


  const handleWeightChange = (weight) => {
     setWeight(weight);
  }

  const handleHeightChange = (height) => {
    setHeight(height);
  }

  



  const handleImageChange = (e, data) => {
    const fileName = e.target.files[0];
    const imageURL = URL.createObjectURL(fileName)
    if (!validTypes.includes(fileName.type)) {
      setIsError(true);
      setMessage(errMsg)
    }
    else {
      if(data === "frontImage"){
        setFrontImage(fileName);
        setImageURL1(imageURL)
       }
      else{
        setBackImage(fileName) ;
        setImageURL2(imageURL);
      } 
    }
  }

  const getBiomarkerList=async()=>{
    try{
      const res = await PhoenixAPI.get(`/nurse/biomarkers/${orderId}`);
      if(res.data){
        const keys = Object.keys(res.data) ||[];
        setBiomarkerList([...keys])
        setBiomarkerData({ ...res.data})
      }

    }catch(error){
     console.log("Error while getting biomarkerList data",error);
    }
 }

 useEffect(()=>{
  
  if(orderId){
    fetchAllOrders()
    getBiomarkerList()
    getNurseTime() 
  }

 },[orderId])

  const getNurseTime=async()=>{
    // const {orderId}= clientDetails;
   try{
      const {data:nurseTimeDetails={}}= await PhoenixAPI.get(`nurse/start-end/${orderId}/${nurseUserId}`);
      const {is_start,is_end}=nurseTimeDetails || {};
      if(!is_start && !is_end){
        setDisableStartTime(true)
      }
      else if(is_start && !is_end){
        setDisableStartTime(false)
        setEnableEndTime(true)
      }
      else if(is_start && is_end){
        setDisableStartTime(false)
        setEnableEndTime(false)
      }
   }
   catch(error){
    console.log("Error in fetching Nurse start and end time", error.message)
   }
 }

 

 const fetchAllOrders=async()=>{
 let  url=`nurse/new-orders/${nurseUserId}/order-id/${orderId}`
 try{
  setIsLoading(true)

  const { data: order ={} } =await PhoenixAPI.get(url);
  console.log("OrderId:",order);
  // const filteredOrder=orders.filter((order)=>order.orderId===parseInt(orderId))
    setClientDetails(order)
   const {homecarePaymentStatus=""}= order ??{}
   if(homecarePaymentStatus){
    setInputs((prev) => setInputs({ ...prev, homecarePaymentStatus: { name: homecarePaymentStatus,
      value: homecarePaymentStatus,
      label :homecarePaymentStatus} }))
   }
   console.log("Homecare Status: from fetchAllOrders",homecarePaymentStatus)
  // const { data: orders = [] } =await PhoenixAPI.get(url); // previous API call
  // console.log("OrderId:", orderId);
  // const filteredOrder=orders.filter((order)=>order.orderId===parseInt(orderId))
  // console.log("filteredOrderfilteredOrder", filteredOrder[0]);
  //   setClientDetails(filteredOrder[0])

}
catch(error){
  console.log("Error occurred in fetcching orders", error.message)
}
finally{
 setIsLoading(false)
}
 }

  async function handleSubmit(e) {
    e.preventDefault();
    console.log("front image"+frontImage+" weight"+weight+" height"+height);
    try {
      console.log("Inside try block",height,weight);
      let formData = new FormData();
      let selectedFrontId = frontImage || clientDetails.userEmiratesFrontUrl;
      let selectedBackId = backImage || clientDetails.userEmiratesBackUrl;

     
      
      
      let selectedHeight=height|| clientDetails.height;
      let selectedWeight=weight|| clientDetails.weight;

      
      if (!selectedFrontId && !selectedBackId && !selectedWeight && !selectedHeight) {
        setIsError(true);
        setMessage("Please select atleast height or weight or one image")
        return;
      }
      
      
      if (!frontImage && !backImage &&!height &&!weight && (selectedFrontId || selectedBackId) && (selectedHeight || selectedWeight) ){

        setIsError(false);
        setMessage(" Updated succesfully")
        return ;
      }
      
      formData.append("frontId", selectedFrontId);
      formData.append("backId", selectedBackId);
      formData.append("height",selectedHeight);
      formData.append("weight",selectedWeight);
      console.log("in image chanhge",formData);

      const res = await PhoenixAPI.patch(`/nurse/emirates-id/${clientDetails.orderId}`, formData)
      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
    });
      if(res.status===200){
        setIsError(false);
        setMessage(" Updated succesfully")
      }
      else{
        const errorMessage = (res && res.message) || "Error while updating";
        setIsError(true);
        setMessage(errorMessage)
      }
      
      
    }
    catch (error) {
      const errorMessage = (error && error.response && error.response.data && error.response.data.errorMessage) || "Error while updating";
      setIsError(true);
      setMessage(errorMessage)
    }
  }
  const getDob=(dob)=>{
    let userDob =dob && dob.split("-")||[];
    return (userDob[2] ? userDob[2] : "NA") + "/" + (userDob[1] ? userDob[1] : "NA") + "/" + (userDob[0] ? userDob[0]:"NA");
  }
  const renderFrontIdPreview = (userEmiratesFrontUrl) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = imageURL1 || userEmiratesFrontUrl
    if (!fileSource) return null;

    const file = frontImage;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <img className='user-emirates-img' src={fileSource} alt="" />
      )

    return (
      <>
        {preview}
      </>
    )
  }
  const renderBackIdPreview = (userEmiratesBackUrl) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = imageURL2 || userEmiratesBackUrl
    if (!fileSource) return null;

    const file = backImage;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <img className='user-emirates-img' src={fileSource} alt="" />
      )

    return (
      <>
        {preview}
      </>
    )
  }
  let countOfBiomarker=0;
  if(biomarkerList && biomarkerList.length>0){
      biomarkerList && biomarkerList.length > 0 && biomarkerList.map((key, index) => {
        Object.keys(biomarkerData) && Object.keys(biomarkerData).length > 0 && biomarkerData[key] && biomarkerData[key].length > 0 && biomarkerData[key].map((item, index) => {
          countOfBiomarker++;
      })

      }
      )
  }

const orderCompleteNurse=(orderId,lab1Id,lab2Id,homecareId)=>{
 
try{
  const statusvalue="COMPLETED";
  const userId=getUserId();
  API.patch("order-list/" + orderId + "/", { order_status: statusvalue ,modified_by:userId
  })
}
catch(error){
  console.log("error",error);
}
}


  const postNurseTime=async(time)=>{
    const {orderId,isRequireSampleCollection,lab1Id,lab2Id,homecareId}= clientDetails;
    try{
      const res= await PhoenixAPI.patch(`nurse/start-end/${orderId}/${nurseUserId}?${time}=true`)
      getNurseTime();
      
     if(time=="end" && isRequireSampleCollection!==null && !isRequireSampleCollection )   {
      orderCompleteNurse(orderId,lab1Id,lab2Id,homecareId);
      setTimeout(() => {
        window.location.href ="/dashboard/home"
    }, 2000);
     }  

    }
    catch(error){
      console.log("Error in fetching Nurse start and end time", error.message)
    }
  }

  const handleStart=()=>{
    setDisableStartTime(false)
    postNurseTime("start")
    setEnableEndTime(true) 
    
    const deviceId= sessionStorage.getItem("device_id") ??"";
     const userName=localStorage.getItem("userName");
     const nurseId=localStorage.getItem("userId");
     const [appointmentDate, appointmentTime] =
     clientDetails && clientDetails.confirmedDateTime

       ? clientDetails.confirmedDateTime
       .split(" ")
       : [null, null];

   const formattedDate = appointmentDate
     ? formatDate(appointmentDate)
     : "";
   const formattedTimeslot = appointmentTime
     ? formatSingleTimeslot(appointmentTime)
     : "";
     console.log("clientDetails: ", clientDetails)
    const  trackingData={
      "homecareId":clientDetails?.homecareId ??"",
      "deviceId":deviceId,
      "nurseName":userName,
      "nurseId":parseInt(nurseId),
      "endButton":false,
      "slotDateTime":formattedDate +" "+formattedTimeslot
     }
     trackStartButtonClick(trackingData)
  }
  const validatePaymentStatus=()=>{
    let validatedPaymentStatus=false;
    const {homecarePaymentStatus}= inputs || {}
    const flag= 
    !(clientDetails?.nurseAppointmentStatus==="Completed" ?? false) &&
    ((clientDetails?.paymentMethod=="COD" && !(clientDetails?.isMamopayTransactionCompleted))  ||  (clientDetails?.paymentMethod=="COD_MAMOPAY" && !(clientDetails?.isMamopayTransactionCompleted)))
     console.log("validatePaymentStatus",homecarePaymentStatus)
     if(!homecarePaymentStatus && flag){
      validatedPaymentStatus=true;
     }
     return validatedPaymentStatus
  }
  const handleUploadSampleVerified=()=>{
    const paymentStatus=validatePaymentStatus()
    console.log("paymentStatus",paymentStatus);
      let error={}
      if(paymentStatus){
        error.homecarePaymentStatus="Please Update Payment Status before clicking Upload Sample"
        setIsError(true);
        setError({ ...error })
        setMessage("Please Update Payment Status before clicking Upload Sample");
      
      }
      return paymentStatus

  }

  const handleEnd=()=>{
    const paymentStatus=validatePaymentStatus()
      let error={}
      if(paymentStatus){
        error.homecarePaymentStatus="Please Update Payment Status before clicking End"
        setIsError(true);
        setError({ ...error })
        setMessage("Please Update Payment Status before clicking End")
      }
      else{

        
        postNurseTime("end");
        setIsError(false);
        setError({  })
        setMessage("")
      }
   
    const deviceId= sessionStorage.getItem("device_id") ??"";
    const userName=localStorage.getItem("userName");
    const nurseId=localStorage.getItem("userId");
    const [appointmentDate, appointmentTime] =
    clientDetails && clientDetails.confirmedDateTime

      ? clientDetails.confirmedDateTime
      .split(" ")
      : [null, null];

  const formattedDate = appointmentDate
    ? formatDate(appointmentDate)
    : "";
  const formattedTimeslot = appointmentTime
    ? formatSingleTimeslot(appointmentTime)
    : "";
   const  trackingData={
     "homecareId":clientDetails?.homecareId??"",
     "deviceId":deviceId,
     "nurseName":userName,
     "nurseId":parseInt(nurseId),
     "endButton":true,
     "slotDateTime":formattedDate +" "+formattedTimeslot
    }
    trackStartButtonClick(trackingData)
  }

 const redirectUploadSample=()=>{
    return history.push(`/dashboard/nurse/upload-sample-details/${orderId}`)
  }

  const redirectToReportIssue=()=>{
    return history.push(`/dashboard/nurse/report-issue/${orderId}`)
  }


 const handleBiomarkerForTracking=()=>{
    const deviceId= sessionStorage.getItem("device_id");
    const trackingData={
      "deviceId": deviceId,
       "nurseName":getUserName(),
       "nurseEmail":getUserEmail(),
       "nurseId":getUserId()
    }
    
    trackBiomarkersClick(trackingData)
  }

  const handlePhoneClicks=()=>{
    const deviceId= sessionStorage.getItem("device_id");
    const trackingData={
      "deviceId": deviceId,
       "nurseName":getUserName(),
       "nurseEmail":getUserEmail(),
       "nurseId":getUserId()
    }
    
    trackPhoneClicks(trackingData)
  }

  

  const handleSavaPaymentStatus=async()=>{
    
   let error={};

    
   if(!inputs.homecarePaymentStatus){
    error.homecarePaymentStatus = "Payment Status is required"
     setIsError(true)
     setError({...error })
     return
    }
    else{
      const homecarePaymentStatus= inputs?.homecarePaymentStatus?.value ??""
      const orderId= clientDetails?.orderId??""
      if(homecarePaymentStatus && orderId){
        try{
           const response= await PhoenixAPI.patch(`nurse/payment-status/${orderId}?paymentStatus=${homecarePaymentStatus}`)
           if(response.status===200){
            setIsError(false);
            setMessage("Payment Status is Updated Succesfully");
            fetchAllOrders();
          }
          }
          catch(error){
           console.log("Error in saving Payment Status", error.message)
           setIsError(true)
          setMessage(error.message)
           
          }
      }
   
      setIsError(false)
     setError({})
    }

  }

  return (
    <>
    <div className='client-lab-details-nurse'>
      <div className='top-header'>
        <div className='header-content'>
            <div className='content' onClick={() => handleBackButton()}>
            <div className='lt' >&lt;</div>
            <div className='header-title'>Client & Lab Details</div>
          </div>
        </div>
      </div>
      <div className='client-details-div'>
        <div className='client-details'>
          <div className='client-header-txt'>Client Details</div>
          <div className='client-detal-1'>
              <span className='subttitle'>Client Name</span> <span className="hyphen">-</span> <span className={`${clientDetails?.isShowClientInformation? "name":"name hide-client-info"}`}>{clientDetails?.userName}</span>
          </div>
          <div className='client-detal-1'>
              <span className='subttitle'>Gender</span> <span className="hyphen">-</span> <span className={`${clientDetails?.isShowClientInformation? "gender":"gender hide-client-info"}`}>{clientDetails?.userGender && clientDetails?.userGender=== "F"?"Female":"Male"}</span>
          </div>
          <div className='client-detal-1'>

          <span className='subttitle'>Phone No.</span> <span className="hyphen">-</span> 
              <a className={`${clientDetails?.isShowClientInformation? "mob-no":"mob-no hide-client-info"}`} href={`tel:${clientDetails?.userPhone} `} onClick={()=>handlePhoneClicks()}>
                {clientDetails?.userPhone}
                </a> 
                {clientDetails?.userMaqsamUniqueId?<>
                <span className={`${clientDetails?.isShowClientInformation? "":" hide-client-info"}`}>
                  PIN : {clientDetails?.userMaqsamUniqueId}
                </span>
                 <a className= {`${clientDetails?.isShowClientInformation? "maqsamCall":"maqsamCall hide-client-info"}`} href={`tel:${clientDetails?.userPhone} `} onClick={()=>handlePhoneClicks()}>Call</a></>:""}
          </div>
          <div className='client-detal-1'>
              <span className='subttitle'>Date of Birth</span> <span className="hyphen">-</span> <span className={`${clientDetails?.isShowClientInformation? "dob":"dob hide-client-info"}`}>{getDob(clientDetails?.userDOB)}</span>
          </div>
          {disableStartTime && <div className='save-button' style={{backgroundColor:"#F77077", color:"white"}} onClick={(e)=>handleStart(e)}>Start</div>}
          {enableEndTime &&<div className='save-button' style={{backgroundColor:"#F77077", color:"white"}} onClick={(e)=>handleEnd(e)}>{(!(clientDetails?.isRequireSampleCollection || clientDetails?.isRequireSampleCollection==null))?"Complete Order":"End"}</div>}
        </div>

        <div className="height-width-container">
          <div className="height-container">
          <div className="id-text">
            Height

          </div>

          <div className=''>
                        <HeightDropdown type={"height"} heightWeight={clientDetails?.height} onHeightWeightChange={handleHeightChange}/>
                        
                      </div>

          </div>
          <div className="height-container">

          <div className="id-text">
           Weight 
            
          </div>
          <div className=''>
                        <HeightDropdown type={"weight"} heightWeight={clientDetails?.weight} onHeightWeightChange={handleWeightChange}/>
                        
          </div>
        </div>
       </div>
        <div className='client-upload-id'>
          <div className='id-front'>
            <div className='id-text'>Emirates ID Front</div>
            <div className={`${clientDetails?.isShowClientInformation? "upload-file-box":"upload-file-box hide-client-info"}`}>
              <input accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg" type="file" onChange={(e) => handleImageChange(e, "frontImage")} />
              <div className='choose-fileand-icon'>
                  <img className='uplooad-icon' src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg" alt="" />
                <span className='choose-file'>Choose File</span>
              </div>
                {renderFrontIdPreview(clientDetails?.userEmiratesFrontUrl)}
            </div>
          </div>
          <div className='id-front'>
            <div className='id-text'>Emirates ID Back</div>
            <div className={`${clientDetails?.isShowClientInformation? "upload-file-box":"upload-file-box hide-client-info"}`} >
              <input accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg" type="file" onChange={(e) => handleImageChange(e, "backImage")} />
              <div className='choose-fileand-icon'>
                  <img className='uplooad-icon' src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg" alt="" />
                <span className='choose-file'>Choose File</span>
              </div>
                {renderBackIdPreview(clientDetails?.userEmiratesBackUrl)}
            </div>
          </div>

        </div>
        <div className='save-button' onClick={(e) => handleSubmit(e)}>Save</div>
      </div>


                 {
                 
                 (!(clientDetails?.nurseAppointmentStatus==="Completed" ?? false) &&
                 
                  ((clientDetails?.paymentMethod=="COD" && !(clientDetails?.isMamopayTransactionCompleted))  ||  (clientDetails?.paymentMethod=="COD_MAMOPAY" && !(clientDetails?.isMamopayTransactionCompleted))))
                 
                 &&
                 
                   <div className="payment-status-container">
                   <div className='payment-status-div'>
                      Payment Status
                      <span className='star'>*</span>
                      </div>
                      <div className=''>
                        <Select
                        // key={clientDetails.id}
                        // id={`select-payment-status-${clientDetails.id}`}
                        value={inputs?.homecarePaymentStatus ?? null}
                        placeholder="Payment Status"
                        search
                        selection
                        className={"paymentStatus"}
                        onChange={(selectedStatus) => {
                            
                            setInputs((prev) => setInputs({ ...prev, homecarePaymentStatus: selectedStatus }))
                        }}
                        options={paymentStatusOptions}
                        isDisabled={clientDetails.homecarePaymentStatus!=null}
                        />
                        {error.homecarePaymentStatus && <div className='error-homecare-payment'>{error.homecarePaymentStatus}</div>}
                      </div>
                    {  !(clientDetails.homecarePaymentStatus!=null) ?<div className='submit-button'  onClick={(e) => handleSavaPaymentStatus(e)}>Submit</div>:<></>}
                   </div>}
      <hr style={{ height: "4px", background: "rgba(224, 228, 238, 0.50)" }} />
      <div className='lab-information-div'>
        <div className='lab-title-text'>
            <img className='lab-icon' src="https://d25uasl7utydze.cloudfront.net/assets/labs.svg" alt="" /><span className='lab-info-text'>Lab Information</span>
        </div>
        <div className='lab-info-wrapper'>
          
          {(clientDetails?.lab1Name || clientDetails?.isRequireSampleCollection === false) && 
            <div className='lab-info-card'>
              {clientDetails?.isRequireSampleCollection === false?<></>:
              <div className='lab-no'>
                  <span>Lab</span> <span>1 :</span> <span className='lab-name'>{clientDetails?.lab1Name||""}</span>
              </div>}
              <div className='package-detail'>
                 {clientDetails?.isRequireSampleCollection === false?<></>: <div className='add-on-div'>
                    <span className='add-on-title'> Lab1 Contact: </span><span className='add-on-name'>{clientDetails?.lab1Contact || ""}</span>
                  </div>}
                  <div className='package-txt'>Package Details <span className='no-of-bio' onClick={() => {setIsShowModal(true);handleBiomarkerForTracking()}}>{countOfBiomarker>0?`${countOfBiomarker} Biomarkers`:""}</span></div>
                  <div className='package-name'>{clientDetails?.packageName  || "null"} {clientDetails?.isRecollectionOrder ? "  (recollection)" : ""}</div>

                  {clientDetails.addOns && <div className='add-on-div'>
                    <span className='add-on-title'>Adds ons : </span><span className='add-on-name'>{clientDetails?.addOns || "null"}</span>
                  </div>
                  }
                    {clientDetails.customAddons && <div className='add-on-div'>
                    <span className='add-on-title'>Add ons : </span><span className='add-on-name'>{clientDetails?.customAddons || "null"}</span>
                  </div>
                  }
              </div>
              <div className='sample-detail'>
                <div className='sample-txt'>Sample Details</div>
                  <div className='sample-para-txt'>{clientDetails?.lab1SampleDetails?.split('\n').map((line, index) => (<div key={index}>{line}</div>))}</div>
              </div>
              <div className='special-instruction'>
                <div className='instruction-txt'>Special Instructions</div>
                  <div className='instruction-para-txt'>{clientDetails?.specialInstructions||"null"}</div>
              </div>
              {clientDetails?.isRequireSampleCollection === false?<></>:<div className='lab-location'>
                <div className='lab-location-txt'>
                  Lab Location :
                </div>
                  <div className='lab-location'>{clientDetails?.lab1Location||"null"}</div>
              </div>}
              </div>
           }
            {clientDetails?.lab2Name &&  <div className='lab-info-card'>
              <div className='lab-no'>
                <span>Lab</span> <span>2 :</span> <span className='lab-name'>{clientDetails?.lab2Name || "null"}</span>
              </div>
              <div className='package-detail'>
                <div className='add-on-div'>
                  <span className='add-on-title'> Lab2 Contact: </span><span className='add-on-name'>{clientDetails?.lab2Contact || "null"}</span>
                </div>
                <div className='package-txt'>Package Details <span className='no-of-bio' onClick={() => {setIsShowModal(true);handleBiomarkerForTracking()}}>{countOfBiomarker} Biomarkers</span></div>
                <div className='package-name'>{clientDetails?.packageName || "null"} </div>
                {clientDetails?.addOns && <div className='add-on-div'>
                  <span className='add-on-title'>Adds ons : </span><span className='add-on-name'>{clientDetails?.addOns || "null"}</span>
                </div>
                }
              </div>
              <div className='sample-detail'>
                <div className='sample-txt'>Sample Details</div>
                <div className='sample-para-txt'>{clientDetails?.lab2SampleDetails || "null"}</div>
              </div>
              <div className='special-instruction'>
                <div className='instruction-txt'>Special Instructions</div>
                <div className='instruction-para-txt'>{clientDetails?.specialInstructions || "null"}</div>
              </div>
              <div className='lab-location'>
                <div className='lab-location-txt'>
                  Lab Location
                </div>
                <div className='lab-location'>{clientDetails?.lab2Location || "null"}</div>
              </div>
            </div>
           }
        </div>
      </div>
    </div>
    <div className='report-issue-div'>
      <div className='report-issue' onClick={()=>redirectToReportIssue()}>Report an issue</div>
    

        {!(clientDetails?.nurseAppointmentStatus === "Completed" ?? false) && ((clientDetails?.isRequireSampleCollection || clientDetails?.isRequireSampleCollection == null) ?? false ? <div className='upload-lab-btn' onClick={() => {
          const verifyPayment = handleUploadSampleVerified(); if ((clientDetails?.paymentMethod == "COD") || (clientDetails?.paymentMethod == "COD_MAMOPAY")) {
            if (!(clientDetails?.isMamopayTransactionCompleted)) {
              if (!verifyPayment && clientDetails?.homecarePaymentStatus != null) {
                redirectUploadSample();
              }
            }
            else {
              redirectUploadSample();
            }


          } else { redirectUploadSample(); }
        }}>Upload sample information</div> : <div></div>)}
    </div>
     {isAlertMsgVisible && clientDetails?.multipleOrderMsg && <div className="multi-order-notify">
        <Alert
          variant="warning"
          onClose={() => {
            setIsAlertMsgVisible(false)
          }}
          dismissible
          className="mt-4"
        >
          {clientDetails?.multipleOrderMsg?.split('\n').map((textMsg, index) => (<div key={index}>{textMsg}</div>))}
        </Alert>
      </div>}
 
      {isShowModal && <AlertModal count={countOfBiomarker} biomarkerList={biomarkerList} biomarkerData={biomarkerData} isShowModal={isShowModal } setIsShowModal={setIsShowModal}/>}
      {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
    </>
  )
}

export default ClientLabDetails
