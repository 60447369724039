import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";

function OperationsInternalBasicCard(props) {
  const { cartItemDetails } = props;

  const { notes, refundAmount ,paymentToBeCollected  } = cartItemDetails;

  return (
    <>
      <div className="form-content">
        <span>Operations Internal</span>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="customTestPrice">Refund Amount</label>
            <div className="input-group">
              <input
                value={refundAmount}
                id="refund_amount"
                name="refund_amount"
                type="number"
                placeholder="Refund Amount"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Notes</label>
            <div className="input-group">
              <textarea
                value={notes}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={"form-control"}
                readOnly={true}
              />
            </div>
          </div>

        </div>
      <div className="row">
      <div className="form-group col-6">
            <label htmlFor="paymentToBeCollected">Payment to be collected</label>
            <input
              value={paymentToBeCollected ?? ""}
              id="paymentToBeCollected"
              name="paymentToBeCollected"
              placeholder="Payment to be collected"
              readOnly={true}
              className="form-control"
              type="number"
            />
          </div>
      </div>

      </div>
    </>
  );
}

export default OperationsInternalBasicCard;
