import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import SearchBar from "components/Common/SearchBar";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class OrderSurveyResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      surveys: [],
      isLoaded: false,
      loadError: "",
      surveysLoaded: false,
      surveysLoadError: "",
      userId:
        this.props.match.params.userId !== undefined &&
        this.props.match.params.userId !== ":userId"
          ? this.props.match.params.userId
          : "",
      users: [],
      userListFormatted: [],
      errors: {},
      disableDropdown: false,
    };

    this.columns = [
      {
        name: "Survey Name",
        selector: "surveyName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Date Last Updated",
        selector: "lastUpdated",
        wrap: true,
        sortable: true,
        format: (row) => (row.lastUpdated ? formatDate(row.lastUpdated) : "-"),
      },
      {
        name: "View Results",
        center: true,
        cell: (survey) => this.renderViewButton(survey),
      },
    ];
  }

  renderViewButton = (survey) => {
    return (
      survey && (
        <>
          <div>
            <Link
              className="btn px-5"
              style={{ backgroundColor: "#CEE741" }}
              to={`/dashboard/surveys-results/all/${this.state.userId}/${survey.questionaireId}/${survey.surveyName}/${survey.lastUpdated}`}
            >
              View
            </Link>
          </div>
        </>
      )
    );
  };

  componentDidMount() {
    this.fetchAclUser();
    if (this.state.userId !== "") {
      this.loadUserAnswers();
      this.setState({
        disableDropdown: true,
      });
    }
  }

  conponentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.SURVEY_RESULTS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let userId = this.state.userId;

    const errors = this.validate();
    console.log("errors:", errors);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    this.loadUserAnswers();
  };

  validate = () => {
    const errors = {};
    const { userId } = this.state;
    if (!userId) {
      errors.clientRequired = " Client is Required Field";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  loadUserAnswers = () => {
    this.setState({
      surveysLoading: true,
      surveys: [],
      surveysLoaded: false,
      surveysLoadError: "",
    });

    PhoenixAPI.get("survey-results", {
      params: {
        userId: this.state.userId,
      },
    })
      .then((response) => {
        const allSurveys = response.data || [];
        const { surveys, surveyResults } = { ...allSurveys };
        this.setState({
          surveys: surveys,
          isLoaded: true,
          answerLoading: false,
          answersLoaded: true,
        });
        this.submitted = false;
      })
      .catch((error) => {
        console.log("Error on fetching surveys: ", error);
        this.setState({
          surveysLoading: false,
          surveysLoadError: "Some error has occured. Please try again",
        });
        this.showErrorNotification("Some error has occured. Please try again.");
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  searchClients = (searchTerm) => {
    if (!searchTerm || searchTerm.trim() === "") return;
    this.showLoading();
    API.get(`list-active-user/?search=${searchTerm ? encodeURIComponent(searchTerm.trim()) : searchTerm.trim()}`)
      .then((response) => {
        const users = response.data ? response.data.results : [];
        const userListFormatted = users
          .filter(
            (user) => user.email && user.email.includes(searchTerm.trim())
          )
          .map((user) => {
            return {
              key: user.id,
              value: user.id,
              text: user.email + "(" + user.username + ")",
            };
          });
        this.setState({
          users,
          userListFormatted: userListFormatted,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log("Error on fetching Clients", error);
        this.setState({
          loadError: "Some error has occured. Please try again.",
        });
        this.showErrorNotification("Some error has occured. Please try again.");
      })
      .finally(() => this.hideLoading());
  };
  render() {
    let form, surveyList;
    // const surveys = this.state.surveys;
    // const isLoaded = this.state.isLoaded;
    // const surveysLoading = this.state.surveysLoading;
    // const surveysLoaded = this.state.surveysLoaded;
    const { surveys, isLoaded, errors, surveysLoading, surveysLoaded } =
      this.state;

    const loadError = this.state.loadError;

    if (isLoaded) {
      form = (
        <form onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="form-group input-section input-section-long">
              <Dropdown
                value={parseInt(this.state.userId)}
                className={
                  "form-control " + (errors.clientRequired && "has-error")
                }
                placeholder="Select client"
                onChange={(event, value) => {
                  this.setState({ userId: value.value });
                }}
                search
                selection
                defaultSelectedLabel={this.state.userId}
                options={this.state.userListFormatted}
                disabled={this.state.disableDropdown ? true : false}
              />
              <span className="help-block">{errors.clientRequired}</span>
            </div>
            <div className="form-group">
              <button type="submit" className="btn button button-green">
                View Answers
              </button>
            </div>
          </div>
        </form>
      );
    } else {
      if (loadError) form = <p>{loadError}</p>;
      else form = null;
    }

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
        <div className="answers">
          <h6 className="mb-3">Search Client *</h6>
          <div className="row">
            <div className="form-group col-5">
              <SearchBar
                onSubmit={this.searchClients}
                placeholder="Search by Email"
              />
            </div>
          </div>
          <h6 className="mb-3">Select Client *</h6>
          {form}
          {/* <h6 className="mb-3 mt-4">Survey Answers</h6> */}
          {surveyList}
        </div>
        <Card body>
          <p className="font-weight-bold">Surveys</p>
          <DataTable
            data={surveys || []}
            columns={this.columns}
            // defaultSortField="questionEnglish"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
          />
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSurveyResults);
