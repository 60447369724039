import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectMiniPackages.propTypes = {
  miniPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMiniPackageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  placeholder: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectMiniPackages.defaultProps = {
  miniPackages: [],
  selectedMiniPackageIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  placeholder: "Select...",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectMiniPackages(props) {
  const {
    miniPackages,
    selectedMiniPackageIds,
    onSelect,
    menuPlacement,
    placeholder,
    showOnlyActive,
    isDisabled,
  } = props;
  const findMiniPackage = (id) =>
    miniPackages.find((miniPackage) => miniPackage.id === id);

  const options = createOptions(miniPackages, showOnlyActive);
   var filteredMinipackagename=[];
  
   
   selectedMiniPackageIds.length>0 && selectedMiniPackageIds
    .map(findMiniPackage).filter(function(item){
      if(item!==undefined)
    var i = filteredMinipackagename.findIndex(x => x.name == item.name);
    if(i <= -1){
      filteredMinipackagename.push({...item});
    }
    return null;
  })
  const  selectedMiniPackages=filteredMinipackagename.map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedMiniPackageIds && selectedMiniPackageIds.join(",")
      }`}
      value={selectedMiniPackages}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(miniPackages, showOnlyActive) {
  if (!miniPackages || !miniPackages.length) return [];
  var resArr = [];
miniPackages.filter(function(item){
    var i = resArr.findIndex(x => x.name == item.name);
    if(i <= -1){
      resArr.push({...item});
    }
    return null;
  });

  const [activeMiniPackages, inactiveMiniPackages] = _.partition(
    resArr,
    (it) => it.isActive ?? false
  );
  activeMiniPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  if (showOnlyActive) return activeMiniPackages.map(createOption);

  inactiveMiniPackages.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  return [
    {
      label: "Active",
      options: activeMiniPackages.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveMiniPackages.map(createOption),
    },
  ];
}

function createOption(miniPackage) {
  if (!miniPackage) return;
  return {
    key: miniPackage.id,
    value: miniPackage.id,
    label: miniPackage.name,
  };
}

export default SelectMiniPackages;
