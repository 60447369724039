import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import SelectAgent from "./SelectAgent";
import Search from "components/Search/Search";
import { fetchOrders, saveOrder } from "services/OrderService";
import { fetchAdminUsers } from "services/UserService";
import { getOrderStatusWithColor } from "./OrderUtils";
import CreateOrderViewModal from "./CreateOrderViewModal";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_CITIES = "cities/";
const BASE_URL_COUNTRIES = "countries/";

const BASE_URL_COUPONS = "coupon-code/summary";


class OrdersDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      agents: [],
      orders: [],
      cities: [],
      countries: [],
      coupons: [],
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      isCreateOrderViewModalVisible: false,
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortField: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Order ID",
        selector: "orderUUID",
        sortField: "order_uuid",
        sortable: true,
        wrap: true,
      },
      {
        name: "Purchased On",
        selector: "createdAt",
        sortField: "created_at",
        wrap: true,
        sortable: true,
        format: (order) =>
          order.createdAt
            ? this.getFormattedPurchasedDate(order.createdAt)
            : "-",
      },
      {
        name: "Customer",
        selector: "userDetails.firstName",
        sortField: "userDetails.firstName",
        wrap: true,
        sortable: false,
        format: (order) => this.getUserName(order.userDetails),
      },
      {
        name: "Total Price",
        selector: "finalPrice",
        sortField: "final_price",
        wrap: true,
        sortable: true,
        format: (order) => this.renderTotalPrice(order),
      },
      {
        name: "Payment Mode",
        selector: "transactionalDetails.paymentMethod",
        wrap: true,
        sortable: false,
        format: (order) => order.transactionDetails?.paymentMethod ?? "",
      },
      {
        name: "Operational Status",
        selector: "orderStatus",
        sortField: "order_status",
        // center: true,
        sortable: true,
        minWidth: "150px",
        format: (order) => this.renderOperationalStatus(order),
      },
      {
        name: "Items",
        selector: "itemsCount",
        sortField: "itemsCount",
        wrap: true,
        sortable: false,
        format: (order) => this.renderNumberOfItems(order.itemsCount),
      },
      {
        name: "Agent Assigned",
        selector: "agentId",
        sortField: "agentId",
        wrap: true,
        sortable: false,
        minWidth: "200px",
        cell: (order) => this.renderAgentDropdown(order),
      },
      {
        name: "Action",
        center: true,
        minWidth: "170px",
        cell: (order) => this.renderViewButton(order),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchAgents();
    this.fetchOrders();
    this.fetchCities();
    this.fetchCountries();
    this.fetchCoupons();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.CART_ORDERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchAgents = async () => {
    try {
      const agents = (await fetchAdminUsers()) || [];
      this.setState({ agents });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  fetchOrders = async (page = 1) => {
    console.log("OrdersDashboard: fetchOrders");
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const config = {
      page,
      rowsPerPage,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm: searchTerm ? searchTerm.trim() : searchTerm,
    };
    this.showLoading();
    try {
      const { count: totalRows, results: orders } = (await fetchOrders(config)) || [];
      this.setState({ totalRows, orders });
    } catch (error) {
      this.showErrorNotification("Error on fetching orders: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      this.setState({ cities });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching cities",
        error
      );
    }
  };

  fetchCountries = async () => {
    try {
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching countries",
        error
      );
    }
  };

  fetchCoupons = async () => {
    try {
      const { data: coupons = [] } = await PhoenixAPI.get(BASE_URL_COUPONS);
      this.setState({ coupons });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching coupons",
        error
      );
    }
  };

  handleSort = (column, sortDirection) => {
    console.log("OrdersDashboard:: handleSort:", { column, sortDirection });
    this.setState(
      {
        sortField: column.sortField || column.selector,
        sortDirection: sortDirection,
      },
      () => this.fetchOrders()
    );
  };

  handlePageChange = (page) => {
    console.log("OrdersDashboard:: handlePageChange:", { page });
    this.fetchOrders(page);
  };

  handleRowsPerPageChange = (newRowsPerPage, page) => {
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      newRowsPerPage,
      page,
    });
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchOrders(page)
    );
  };

  handleCreateOrder = () => {
    this.showCreateOrderViewModal();
  };

  handleSaveOrder = () => {
    console.log("OrdersDashboard: handleSaveOrder");
    this.setState({ isCreateOrderViewModalVisible: false }, () =>
      window.location.reload(false)
    );
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    // this.setState({ searchApplied: true, searchTerm });
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    this.setState({ searchApplied: true }, () => this.fetchOrders());
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false }, () =>
      this.fetchOrders()
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAgentSelect = async (order, agentId) => {
    console.log("handleAgentSelect::", { agentId, order });
    try {
      order.agentId = agentId;
      await saveOrder(order);
      this.showSuccessNotification("Agent assigned successfully.");
      this.fetchOrders();
    } catch (error) {
      this.showErrorNotification("Error on assigning agent: ", error.message);
    }
  };

  showCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: true });
  };

  hideCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: false });
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  getUserName = (user) => {
    if (!user) return "";
    const { username, fullName, firstName, lastName, email } = user;
    const name =
      username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
    return `${name} (${email ?? ""})`;
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderOperationalStatus = (order) => {
    const { status, color } = getOrderStatusWithColor(order.orderStatus) ?? {};
    return (
      <span
        className={`ui small ${color ?? "green"} label`}
        style={{ fontWeight: "normal" }}
      >
        {status ?? ""}
      </span>
    );
  };

  renderTotalPrice = (order) => {
    // console.log("renderTotalPrice:: order:", order);
    const { finalPrice } = order ?? {};
    const { currency, finalAmount } = order?.transactionDetails ?? {};
    return `${currency ?? ""} ${finalAmount ?? finalPrice ?? ""}`;
  };

  renderNumberOfItems = (itemsCount) => {
    if (itemsCount === null || itemsCount === undefined) return "";
    return `${itemsCount} ${itemsCount > 1 ? "items" : "item"}`;
  };

  renderAgentDropdown = (order) => (
    <SelectAgent
      agents={this.state.agents ?? []}
      selectedAgentId={order.agentId}
      onAgentSelect={(agentId) => this.handleAgentSelect(order, agentId)}
      isDisabled={order?.orderStatus === "COMPLETED" || !this.state.isEditable}
    />
  );

  renderViewButton = (order) =>
    order && (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          to={`/dashboard/orders/all/${order.id}`}
          target="_blank"
        >
          View
        </Link>
      </div>
    );

  // getFilteredOrders = () => {
  //   const { orders = [] } = this.state;
  //   return this.getSearchedOrders(orders);
  // };

  // getSearchedOrders = (orders) => {
  //   const { searchApplied, searchTerm } = this.state;

  //   if (!searchApplied || !searchTerm) return orders;

  //   const newValue = searchTerm.slice(0).trim().toLowerCase();
  //   return orders.filter((order) => {
  //     const { id, orderUUID, orderStatus, finalPrice } = order;
  //     const { username, fullName, firstName, lastName, email } =
  //       order?.userDetails ?? {};
  //     const name =
  //       username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
  //     const customerName = `${name} (${email ?? ""})`;
  //     return (
  //       (id && id.toString() === newValue) ||
  //       (finalPrice !== null &&
  //         finalPrice !== undefined &&
  //         finalPrice.toString() === newValue) ||
  //       (orderUUID && orderUUID.toString() === newValue) ||
  //       (orderStatus && orderStatus.toLowerCase().indexOf(newValue) !== -1) ||
  //       (customerName && customerName.toLowerCase().indexOf(newValue) !== -1)
  //     );
  //   });
  // };

  render() {
    const {
      orders,
      cities,
      countries,
      coupons,
      totalRows,
      rowsPerPage,
      searchTerm,
      searchApplied,
      loadError,
      isCreateOrderViewModalVisible,
      isEditable
    } = this.state;

    const filteredOrders = orders ?? [];

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleCreateOrder}
                disabled={!isEditable}
                style={{ backgroundColor: "#0CA789", color: "white" }}
                className="btn btn-md new-user"
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredOrders || []}
            columns={this.columns}
            sortServer
            onSort={this.handleSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={totalRows ?? 0}
            paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + totalRows + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <CreateOrderViewModal
          cities={cities ?? []}
          countries={countries ?? []}
          coupons={coupons ?? []}
          isVisible={isCreateOrderViewModalVisible}
          onHide={this.hideCreateOrderViewModal}
          onSave={this.handleSaveOrder}
          orderType="Cart Orders"
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDashboard);
