import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import ManageOptionsForm from "./ManageOptionsForm";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const BASE_URL_ORDER_REVIEW = "order-review-admin/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class Reviews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      reviews: [],
      reviewRankOptions: [],
      review: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isReviewViewModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Star",
        selector: "point_scale",
        width: "100px",
        sortable: true,
      },
      {
        name: "Options",
        selector: "options",
        sortable: true,
        center: true,
        cell: (review) => this.renderReviewOptions(review.options),
      },
      {
        name: "Rank",
        selector: "position_value",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (review) => <Status isActive={review.is_active} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (review) => this.renderViewButton(review.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchReviews();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.REVIEWS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchReviews = async () => {
    this.showLoading();
    try {
      const { data: reviews = [] } = await API.get(BASE_URL_ORDER_REVIEW);
      this.setState({ reviews });
    } catch (error) {
      this.showErrorNotification("Error on fetching reviews: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const review = { ...this.state.review };
    review[input.name] = input.value;
    this.setState({ review });
  };

  handleOptionsUpdated = (options) => {
    const review = { ...this.state.review, options };
    this.setState({ review });
  };

  handleAddNewReview = () => {
    this.setState({ isNewReview: true }, () => this.showReviewViewModal());
  };

  handleViewReview = (reviewId) => {
    const reviews = this.state.reviews ?? [];
    const review = reviews.find((it) => it.id === reviewId);
    if (!review) {
      console.log(`Review with id ${reviewId} is not found!`);
      return;
    }

    this.setState({ review, isNewReview: false }, () =>
      this.showReviewViewModal()
    );
  };

  showReviewViewModal = () => {
    const { reviews = [], isNewReview } = this.state;
    const reviewRankOptions = this.createReviewRankOptions() || [];

    if (isNewReview) {
      const pointScale = Math.max(...reviews.map((it) => it.point_scale));
      const review = {
        point_scale: pointScale ? pointScale + 1 : 1,
        position_value: Math.max(...reviewRankOptions.map((it) => it.value)),
        is_active: false,
      };
      this.setState({
        review,
        reviewRankOptions,
        isReviewViewModalVisible: true,
      });
    } else {
      this.setState({ reviewRankOptions, isReviewViewModalVisible: true });
    }
  };

  hideReviewViewModal = () => {
    this.setState({ isReviewViewModalVisible: false });
    this.resetForm();
  };

  handleSaveReview = async () => {
    const { review } = this.state;
    const errors = this.validate(review);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    this.saveReview(review);
  };

  validate = (review = {}) => {
    const {position_value, is_active: status, options } = review;
    const errors = {};
    if (position_value === null || position_value === undefined)
      errors.rank = "Rank cannot be blank.";

    if (status === null || status === undefined)
      errors.status = "Status cannot be blank";

    if (!options || !options.length) errors.options = "Options are required!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveReview = async (review) => {
    try {
      this.showLoading();
      const isValidId = review.id !== null && review.id !== undefined;
      const options = {
        url: isValidId
          ? `${BASE_URL_ORDER_REVIEW}${review.id}/`
          : BASE_URL_ORDER_REVIEW,
        method: isValidId ? "PATCH" : "POST",
        data: review,
      };

      const response = await API(options);
      console.log("Response: ", response);
      this.hideReviewViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Review Updated Successfully."
          : "Review Created Successfully."
      );
      this.fetchReviews();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({ review: {}, errors: {}, editables: {} });
  };

  renderReviewOptions = (options = []) => {
    const results = options
      .filter((option) => option.is_active ?? false)
      .sort((first, second) => first.position_value - second.position_value)
      .map((option, index) => `${option.name} (Option ${index + 1})`);
    return (
      <ul className="my-auto" style={{ width: "100%" }}>
        {results.map((result) => (
          <li>{result}</li>
        ))}
      </ul>
    );
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewReview(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewReview ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  createReviewRankOptions = () => {
    const { reviews = [], isNewReview } = this.state;
    const ranks = reviews.filter((it) => it.position_value).map((it) => it.position_value);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, reviews.length) || 0;
    const until = isNewReview ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });

    return rankOptions;
  };

  getFilteredReviews = () => {
    const { reviews = [] } = this.state;
    return this.getSearchedReviews(reviews);
  };

  getSearchedReviews = (reviews) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return reviews;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return reviews.filter((review) => {
      const { id, point_scale, position_value, options = [] } = review;
      return (
        (id && id.toString() === newValue) ||
        (position_value &&position_value.toString() === newValue) ||
        (point_scale && point_scale.toString() === newValue) ||
        (options &&
          options.length &&
          options.some(
            (option) =>
              option.name?.toLowerCase()?.indexOf(newValue) !== -1 ||
              option.name_arabic?.indexOf(newValue) !== -1
          ))
      );
    });
  };

  render() {
    const {
      reviews,
      reviewRankOptions,
      review,
      isNewReview,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable
    } = this.state;

    // const filteredReviews = this.getFilteredReviews(reviews) ?? [];
    const filteredReviews = reviews ?? [];

    const formDetails = (
      <div className="container mt-4">
        {/* <div className="row">
          <div className="form-group col-6">
            <label htmlFor="name">Name (Admin Panel Only)</label>
            <div className="input-group">
              <input
                value={review?.name ?? ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name (Admin Panel Only)"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewReview && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
        </div> */}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={review.position_value}
                onChange={(event, value) =>
                  this.setState({ review: { ...review, position_value: value.value } })
                }
                id="rank"
                placeholder="Rank"
                search
                selection
                options={reviewRankOptions}
                disabled={!isNewReview && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={review.is_active}
                onChange={(event, value) =>
                  this.setState({
                    review: { ...review, is_active: value.value },
                  })
                }
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewReview && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );

    const formManageOptions = (
      <div className="container mt-4">
        <ManageOptionsForm
          options={review?.options || []}
          onOptionsUpdated={this.handleOptionsUpdated}
          isEditable={isEditable}
        />
        <span className="help-block">{errors.options}</span>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewReview}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredReviews || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredReviews.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isReviewViewModalVisible}
          onHide={this.hideReviewViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveReview}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_supplements" fill justify>
              <Tab
                eventKey="key_form_details"
                title={`${review.point_scale} Star`}
              >
                {formDetails}
              </Tab>
              <Tab eventKey="key_form_manage_options" title="Manage Options">
                {formManageOptions}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
