import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import SelectAuthor from "pages/HealthHub/SelectAuthor";
import { fetchAuthors } from "services/HealthHubService";
import { fetchArticles } from "services/HealthHubService";
import SelectArticle from "components/Common/SelectArticle";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class Stories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authors: [],
      articles: [],
      stories: [],
      story: {},
      errors: {},
      editables: {},
      rankOptions: [],
      isSaving: false,
      isNewStory: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Author Name",
        selector: "authorName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Specialization",
        selector: "specialization",
        wrap: true,
        sortable: true,
      },
      {
        name: "Promoted Article",
        selector: "promotedArticleName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (row) => <Status isActive={row.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (row) => this.renderViewButton(row.id),
      },
    ];
  }

  async componentDidMount() {
    await Promise.all([this.fetchAuthors(), this.fetchArticles()]);
    this.fetchStories();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  fetchAuthors = async () => {
    try {
      this.showLoading();
      const authors = (await fetchAuthors()) ?? [];
      this.setState({ authors });
    } catch (error) {
      this.showErrorNotification("Error on fetching authors!", error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchArticles = async () => {
    try {
      this.showLoading();
      const articles = (await fetchArticles()) ?? [];
      this.setState({ articles });
    } catch (error) {
      this.showErrorNotification("Error on loading articles: ", error.message);
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    } finally {
      this.hideLoading();
    }
  };

  fetchStories = async () => {
    try {
      this.showLoading();
      const { data: stories = [] } = await PhoenixAPI.get("/corporate-stories");
      stories.forEach((story) => {
        const author = this.findAuthorById(story.authorId);
        if (author) {
          const { first_name, last_name } = author;
          story.authorName = `${first_name ?? ""} ${last_name ?? ""}`.trim();
        } else {
          story.authorName = "";
        }

        const article = this.findArticleById(story.promotedArticleId);
        if (article) {
          story.promotedArticleName = article.articleNameEnglish ?? "";
        } else {
          story.promotedArticleName = "";
        }
      });
      this.setState({ stories });
    } catch (error) {
      console.log("fetchCorporatePartners:: Error on fetching stories!", error);
      this.showErrorNotification(
        "Error on fetching stories! " + error.message ?? ""
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  findAuthorById = (id) => {
    const authors = this.state.authors || [];
    return authors.find((author) => author.id === id);
  };

  findArticleById = (id) => {
    const articles = this.state.articles || [];
    return articles.find((article) => article.id === id);
  };

  handleChange = ({ currentTarget: input }) => {
    const story = { ...this.state.story };
    story[input.name] = input.value;
    this.setState({ story });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNew = () => {
    this.setState({ isNewStory: true }, () => this.showStoryViewModal());
  };

  handleView = (id) => {
    const stories = this.state.stories || [];
    const story = stories.find((it) => it.id === id);
    if (story) {
      this.setState({ story, isNewStory: false }, () =>
        this.showStoryViewModal()
      );
    }
  };

  showStoryViewModal = () => {
    const { country } = this.props;
    const { isNewStory } = this.state;
    const rankOptions = this.getRankOptions() || [];

    if (isNewStory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const story = {
        country: country?.id,
        rank,
        isActive: false,
      };
      this.setState({ story, rankOptions }, () => this.showModal());
    } else {
      this.setState({ rankOptions }, () => this.showModal());
    }
  };

  getRankOptions = () => {
    const { isNewStory } = this.state;
    const storiesForCurrentCountry = this.getStoriesForCurrentCountry();
    const ranks = storiesForCurrentCountry
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks);
    const maxRank = Math.max(storiesForCurrentCountry.length, ...ranks);
    const until = isNewStory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    return rankOptions;
  };

  handleSave = async () => {
    if (!this.props.country) {
      this.showErrorNotification("Please select a country!");
      this.setState({ isSaving: false });
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    const { story } = this.state;
    try {
      this.showLoading();
      const config = {
        method: story.id ? "PATCH" : "POST",
        url: story.id
          ? `/update-corporate-stories/${story.id}`
          : "/create-corporate-stories",
        data: story,
      };
      await PhoenixAPI.request(config);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchStories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  validate = () => {
    const errors = {};
    const {
      authorId,
      specialization,
      promotedArticleId,
      summaryHighlight,
      summaryHighlightArabic,
      rank,
      isActive: status,
    } = this.state.story ?? {};

    if (!authorId) errors.authorId = "Author cannot be blank!";

    if (!specialization || specialization.trim() === "")
      errors.specialization = "Specialization cannot be blank!";

    if (!promotedArticleId)
      errors.promotedArticleId = "Promoted Article cannot be blank!";

    if (!summaryHighlight || summaryHighlight.trim() === "")
      errors.summaryHighlight = "Summary Highlight (English) cannot be blank!";
    else if (summaryHighlight.length > 1000)
      errors.summaryHighlight =
        "Summary Highlight (English) is limited to a 1000 characters!";

    if (!summaryHighlightArabic || summaryHighlightArabic.trim() === "")
      errors.summaryHighlightArabic =
        "Summary Highlight (Arabic) cannot be blank!";
    else if (summaryHighlightArabic && summaryHighlightArabic.length > 1000)
      errors.summaryHighlightArabic =
        "Promotion Description (Arabic) is limited to a 1000 characters!";

    if (!rank) errors.rank = "Rank cannot be blank!";

    if (status === null || status === undefined)
      errors.status = "Status cannot be blank!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      story: {},
      rankOptions: [],
      isSaving: false,
      isNewStory: false,
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        onClick={() => this.handleView(id)}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.props.isEditable || this.state.isNewStory) return null;
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  getFilteredStories = () => {
    return this.getSearchedStories(this.getStoriesForCurrentCountry());
  };

  getStoriesForCurrentCountry = () => {
    const { country } = this.props;
    const { stories } = this.state;

    if (country && stories && stories.length) {
      return stories.filter((it) => it.country === country.id);
    }

    return [];
  };

  getSearchedStories = (stories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return stories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return stories.filter((story) => {
      const { id, authorName, specialization, promotedArticleName } = story;
      return (
        (id && id.toString().includes(newValue)) ||
        (authorName && authorName.toLowerCase().indexOf(newValue) !== -1) ||
        (specialization &&
          specialization.toLowerCase().indexOf(newValue) !== -1) ||
        (promotedArticleName &&
          promotedArticleName.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  render() {
    const {
      authors,
      articles,
      story,
      searchTerm,
      editables,
      errors,
      isNewStory,
      isSaving,
    } = this.state;
    const { isEditable = false } = this.props;
    const filteredStories = this.getFilteredStories();

    const detailsForm = (
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="authorId">Author*</label>
            <div className="input-group">
              <SelectAuthor
                id="authorId"
                name="authorId"
                authors={authors ?? []}
                selectedAuthorId={story.authorId ?? null}
                onSelect={(event) => {
                  this.setState({
                    story: {
                      ...story,
                      authorId: event.value,
                      promotedArticleId: null,
                      specialization:
                        this.findAuthorById(event.value)?.specialization ?? "",
                    },
                  });
                }}
                isDisabled={!isNewStory && !editables.authorId}
                className={
                  "form-control" + (errors.authorId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("authorId")}
            </div>
            <span className="help-block">{errors.authorId}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="specialization">Specialization*</label>
            <div className="input-group">
              <input
                value={story.specialization ?? ""}
                onChange={this.handleChange}
                id="specialization"
                name="specialization"
                type="text"
                placeholder="Specialization"
                className={
                  "form-control py-2" +
                  (errors.specialization ? " has-error" : "")
                }
                disabled={!isNewStory && !editables.specialization}
              />
              {this.renderPencilIcon("specialization")}
            </div>
            <span className="help-block">{errors.specialization}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={story?.rank}
                onChange={(event, value) => {
                  this.setState({ story: { ...story, rank: value.value } });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewStory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={story.isActive || false}
                onChange={(event, value) => {
                  this.setState({ story: { ...story, isActive: value.value } });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewStory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="promotedArticleId">Promoted Article*</label>
            <div className="input-group">
              <SelectArticle
                id="promotedArticleId"
                name="promotedArticleId"
                articles={
                  articles && story.authorId
                    ? articles.filter(
                        (article) => article.author === story.authorId
                      )
                    : []
                }
                selectedArticleId={story.promotedArticleId ?? null}
                onSelect={(event) => {
                  this.setState({
                    story: {
                      ...story,
                      promotedArticleId: event.value,
                    },
                  });
                }}
                showOnlyActive={false}
                isDisabled={!isNewStory && !editables.promotedArticleId}
                className={
                  "form-control" +
                  (errors.promotedArticleId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("promotedArticleId")}
            </div>
            <span className="help-block">{errors.promotedArticleId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="summaryHighlight">
              Summary Highlight (English)*
            </label>
            <div className="input-group">
              <textarea
                value={story.summaryHighlight}
                onChange={this.handleChange}
                id="summaryHighlight"
                name="summaryHighlight"
                placeholder="Summary Highlight in English"
                className={
                  "form-control py-2" +
                  (errors.summaryHighlight ? " has-error" : "")
                }
                readOnly={!isNewStory && !editables.summaryHighlight}
              ></textarea>
              {this.renderPencilIcon("summaryHighlight")}
            </div>
            <span className="help-block">{errors.summaryHighlight}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="summaryHighlightArabic">
              Summary Highlight (Arabic)*
            </label>
            <div className="input-group">
              <textarea
                value={story.summaryHighlightArabic}
                onChange={this.handleChange}
                id="summaryHighlightArabic"
                name="summaryHighlightArabic"
                placeholder="Summary Highlight in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.summaryHighlightArabic ? " has-error" : "")
                }
                readOnly={!isNewStory && !editables.summaryHighlightArabic}
              ></textarea>
              {this.renderPencilIcon("summaryHighlightArabic")}
            </div>
            <span className="help-block">{errors.summaryHighlightArabic}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Stories</p>
          <DataTable
            data={filteredStories}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredStories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              type="button"
              onClick={(event) =>
                this.setState({ isSaving: true }, this.handleSave)
              }
              disabled={!isEditable || isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Stories);
