import React, { useMemo, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

function PdfPreview({
  pdfFile,
  pdfFileUrl,
  maxPaxLimit = 2,
  maxHeight = "200px",
}) {
  const [numPages, setNumPages] = useState(null);
  const file = pdfFile ?? useMemo(() => ({ url: pdfFileUrl }), [pdfFileUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  const pages = Array.apply(null, Array(numPages))
    .map((x, i) => i + 1)
    .slice(0, maxPaxLimit)
    .map((page) => <Page pageNumber={page} />);

  return (
    <div className="overflow-auto" style={{ maxHeight }}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options>
        {pages}
      </Document>
    </div>
  );
}

export default PdfPreview;
