import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  ArrowDownward,
  LocalConvenienceStoreOutlined,
} from "@material-ui/icons";
import { Card, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import SelectAgent from "../SelectAgent";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { fetchOrders, saveOrder } from "./services/OrderService";
import { getUserId } from "../../../services/UserService";
import { Page } from "utils/constant";
import { fetchAdminUsers } from "../../../services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "../../../services/aclService";
import AccessDenied from "../../../components/Common/AccessDenied";
import CreateDraftOrderViewModal from "./components/CreateDraftOrderViewModal";

import * as XLSX from 'xlsx';


import EditCustomerShippingAddress from "../EditCustomerShippingAddress";
 
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_CITIES = "cities/";
const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_COUPONS = "coupon-code/summary";
const BASE_URL_MOVE_TO_CART_ORDERS = "/draft-order-to-cart-order";
const BASE_URL_UPDATE_CART_ORDER_ADDRESS="/admin/update-cart-order-address";
class PaymentPendingOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      agents: [],
      orders: [],
      cities: [],
      countries: [],
      coupons: [],
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      isCreateOrderViewModalVisible: false,

      downloadOption:false,
      csvFormat: false,
      xlsFormat: false,

      isEditCustomerShippingAddressViewModalVisible:false,
      order:{},
      shippingAddressModal:null

    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortField: "id",
        sortable: true,
        wrap: true,
      },
      {
        name: "Order ID",
        selector: "orderUUID",
        sortField: "order_uuid",
        sortable: true,
        wrap: true,
      },
      {
        name: "Purchased On",
        selector: "createdAt",
        sortField: "created_at",
        wrap: true,
        sortable: true,
        format: (order) =>
          order.createdAt
            ? this.getFormattedPurchasedDate(order.createdAt)
            : "-",
      },
      {
        name: "Client Full Name",
        selector: "userDetails.firstName",
        sortField: "userDetails.firstName",
        wrap: true,
        sortable: false,
        format: (order) => this.getUserName(order.userDetails),
      },
      {
        name: "Total Price",
        selector: "finalPrice",
        sortField: "final_price",
        wrap: true,
        sortable: true,
        width: "150px",
        format: (order) => this.renderTotalPrice(order),
      },
      {
        name: "Payment Status",
        selector: "paymentStatus",
        wrap: true,
        sortable: false,
        width: "150px",
        format: (order) => this.renderPaymentStatus(order),
      },
      {
        name: "Payment Gateway",
        selector: "transactionalDetails.paymentGateway",
        wrap: true,
        sortable: false,
        width: "200px",
        format: (order) => order.transactionDetails?.paymentGateway ?? "",
      },
      {
        name: "Items",
        selector: "itemsCount",
        sortField: "itemsCount",
        wrap: true,
        sortable: false,
        format: (order) => this.renderNumberOfItems(order.itemsCount),
      },
      {
        name: "Action",
        minWidth: "300px",
        center: true,
        cell: (order) => this.renderViewButton(order),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchAgents();
    this.fetchOrders();
    this.fetchCities();
    this.fetchCountries();
    this.fetchCoupons();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CART_ORDERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchAgents = async () => {
    try {
      const agents = (await fetchAdminUsers()) || [];
      this.setState({ agents });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  

  fetchOrders = async (page = 1) => {
    console.log("OrdersDashboard: fetchOrders");
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const config = {
      page,
      rowsPerPage,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm: searchTerm ? searchTerm.trim() : searchTerm,
    };
    this.showLoading();
    try {
      const { count: totalRows, results: orders } =
        (await fetchOrders(config)) || [];
      this.setState({ totalRows, orders });
    } catch (error) {
      this.showErrorNotification("Error on fetching orders: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };
  fetchCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      this.setState({ cities });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching cities",
        error
      );
    }
  };

  fetchCountries = async () => {
    try {
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching countries",
        error
      );
    }
  };

  fetchCoupons = async () => {
    try {
      const { data: coupons = [] } = await PhoenixAPI.get(BASE_URL_COUPONS);
      this.setState({ coupons });
    } catch (error) {
      console.log(
        "CreateOrderViewModal:: fetchCoupons: error on fetching coupons",
        error
      );
    }
  };

  handleSort = (column, sortDirection) => {
    console.log("OrdersDashboard:: handleSort:", { column, sortDirection });
    this.setState(
      {
        sortField: column.sortField || column.selector,
        sortDirection: sortDirection,
      },
      () => this.fetchOrders()
    );
  };

  handlePageChange = (page) => {
    console.log("OrdersDashboard:: handlePageChange:", { page });
    this.fetchOrders(page);
  };

  handleRowsPerPageChange = (newRowsPerPage, page) => {
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      newRowsPerPage,
      page,
    });
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchOrders(page)
    );
  };

  handleCreateOrder = () => {
    this.showCreateOrderViewModal();
  };

  handleSaveOrder = () => {
    console.log("OrdersDashboard: handleSaveOrder");
    this.setState({ isCreateOrderViewModalVisible: false }, () =>
      window.location.reload(false)
    );
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  getUserName = (user) => {
    if (!user) return "";
    const { username, fullName, firstName, lastName, email } = user;
    const name =
      username || fullName || `${firstName ?? ""}  ${lastName ?? ""}`;
    return `${name} (${email ?? ""})`;
  };

  renderTotalPrice = (order) => {
    const { finalPrice } = order ?? {};
    const { currency, finalAmount } = order?.transactionDetails ?? {};
    return `${currency ?? ""} ${finalAmount ?? finalPrice ?? ""}`;
  };
  renderPaymentStatus = (order) => {
    const { transactionDetails } = order ?? {};
    const { paymentStatus } = transactionDetails ?? {};
    return (
      <span
        className={`ui small ${"red"} label`}
        style={{ fontWeight: "normal" }}
      >
        {paymentStatus ?? ""}
      </span>
    );
  };

  renderPaymentStatusForDownload=(order)=>{
    const { transactionDetails } = order ?? {};
    const { paymentStatus } = transactionDetails ?? {};
    return paymentStatus ?? "" 
  }
  renderNumberOfItems = (itemsCount) => {
    if (itemsCount === null || itemsCount === undefined) return "";
    return `${itemsCount} ${itemsCount > 1 ? "items" : "item"}`;
  };

  handleMoveToCart = async (order) => {
    const { id = "" } = order ?? {};
    const { transactionDetails ,addressId=null} = order ?? {};
    const { paymentGateway, paymentMethod } = transactionDetails ?? {};
    const { userName, firstName, lastName, email, phoneNumber } =
      order?.userDetails ?? {};
    const {
      addressLine1,
      addressLine2,
      buildingApt,
      city,
      country,
      phoneNo,
      mapUrl,
      lat,
      lng,
    } = order?.addressDetails ?? {};
    
    if(paymentGateway==="COD"){  // this condtion is changed from one condition paymentMethod=="COD" to paymentGateway=="COD"
      if(addressId ==null)    {
        const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${order.userId}/${order.cityId}`)
        this.setState({isEditCustomerShippingAddressViewModalVisible:true,shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null},order, userCityAddress:userCityAddress})
       }


    }
    else{
    this.showLoading();
    const payload = {
      paymentGateway: paymentGateway,
      paymentMethod: paymentMethod,
    };
    try {
      const response = await PhoenixAPI.patch(
        `${BASE_URL_MOVE_TO_CART_ORDERS}/${id}`,
        payload
      );
      console.log("response is",response);
      
      this.showSuccessNotification("Successfully Moved in Cart Order");
     await this.fetchOrders();
    } catch (error) {
        const {message:errorMessage=""}= error.response.data || {}
        console.log("Message :", errorMessage)
        console.log("Error on Moving to orders: ", error);
        this.showErrorNotification(`Error on Moving to cart orders: ${errorMessage}`);
    } finally {
      this.hideLoading();
    }
  }
  };

  renderViewButton = (order) => {
    const { transactionDetails } = order ?? {};
    const { paymentGateway } = transactionDetails ?? {};
    return (
      <div style={{ width: "100%" }}>
        <div className="row" style={{ width: "100%" }}>
          <Link
            className="btn px-5"
            style={{ width: "100%", backgroundColor: "#CEE741" }}
            to={`/dashboard/payment-pending-orders/all/${order.id}`}
          >
            View
          </Link>
        </div>
        {paymentGateway === "MAMOPAY" ? (
          ""
        ) : (
          <div
            className="row mt-2"
            style={{ width: "100%", cursor: "pointer" }}
            onClick={(e) => this.handleMoveToCart(order)}
          >
            <div
              className="btn px-5"
              style={{ width: "100%", backgroundColor: "#CEE741" }}
            >
              <p>Move to Active/Cart Orders</p>
            </div>
          </div>
        )}
      </div>
    );
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    // this.setState({ searchApplied: true, searchTerm });
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    this.setState({ searchApplied: true }, () => this.fetchOrders());
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false }, () =>
      this.fetchOrders()
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

 

  showCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: true });
  };

  hideCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: false });
  };



  handleHideEditCustomerShippingAddressViewModal=()=>{
    this.setState({
      isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:null,
    })
  }

  handleSaveShippingAddress=async(shippingAddress)=>{
    const { order } = this.state;
    if (!order) {
      console.log("saveCustomerShipping Address:: Invalid order:", order);
      return;
    }
    const {id, userId}=order ?? {}
    const { transactionDetails } = order ?? {};
    const { paymentGateway, paymentMethod } = transactionDetails ?? {};
    console.log("saveShipping Address::", { order, shippingAddress })
    const {addressLine1,addressLine2,buildingApt,cityId,countryId, mapUrl, addressId}=shippingAddress ?? {};
    const address={
      userId:userId,
      addressLine1:addressLine1,
      addressLine2:addressLine2,
      buildingApt:buildingApt,
      cityId:cityId,
      countryId:countryId,
      defaultAddress:false,
      mapUrl:mapUrl,
      id:addressId
    }

    try {
      
     
      
    
    this.showLoading();
    const payload = {
      paymentGateway: paymentGateway,
      paymentMethod: paymentMethod,
      address:address
    };
    
      const response = await PhoenixAPI.patch(
        `${BASE_URL_MOVE_TO_CART_ORDERS}/${id}`,
        payload
      );
      console.log("response is",response);
      this.handleHideEditCustomerShippingAddressViewModal();
      this.showSuccessNotification("Shipping Address saved successfully.");
      this.showSuccessNotification("Successfully Moved in Cart Order");
     await this.fetchOrders();
    } catch (error) {
        const {message:errorMessage=""}= error.response.data || {}
        console.log("Message :", errorMessage)
        console.log("Error on Moving to orders: ", error);
        this.showErrorNotification(`Error on Moving to cart orders: ${errorMessage}`);
    } finally {
      this.hideLoading();
    }
   
      

    
     
  }



  downloadCsv = async () => {
    this.setState({
      downloadOption: true, csvFormat: true,
      xlsFormat: false,
    })
  }
  hideDownloadOptionModal=()=>{
    this.setState({ downloadOption: false, csvFormat: false,
      xlsFormat: false,})
  }

  downloadXls = async () => {
    this.setState({
      downloadOption: true, csvFormat: false,
      xlsFormat: true
    })
  }

  fetchAllOrders=async(page = 1)=>{
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;
    const params = {
      page,
      rowsPerPage,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm: searchTerm ? searchTerm.trim() : searchTerm,
    };
    this.showLoading();
    try {
      const {data}=
     await PhoenixAPI.get("/admin/draft-cart-orders/download", {params});;
  
      return data
    } catch (error) {
      this.showErrorNotification("Error on fetching orders: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  }

  mappingOrdersForDownload=(orders)=>{
    const mappedOrders = orders.map((order)=>{
      const item={
         "ID":order.id,
         "Order ID":order.orderUUID,
         "Purchased On":this.getFormattedPurchasedDate(order.createdAt),
         "Client Full Name":this.getUserName(order.userDetails),
         "Total Price":this.renderTotalPrice(order),
         "Payment Status":this.renderPaymentStatusForDownload(order),
         "Payment Gateway": order.transactionDetails?.paymentGateway ?? "",
         "Items":this.renderNumberOfItems(order.itemsCount),
      }
      return item
    })
    return mappedOrders
  }

  jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]);
    csvRows.push(headers.join(','));
  
    // Map over each row
    json.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
  
    // Join rows with newline character
    return csvRows.join('\n');
  };
  
  downloadAllRecords = async() => {
    const { csvFormat, xlsFormat } = this.state || {}
    const allOrders=await this.fetchAllOrders()
    console.log("all Orders:", allOrders);
    const mappedOrders = this.mappingOrdersForDownload(allOrders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }

  downloadSelectedRows = () => {
    const {orders}= this.state;
    const { csvFormat, xlsFormat } = this.state || {}
    const mappedOrders = this.mappingOrdersForDownload(orders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }

  handleHideEditCustomerShippingAddressViewModal=()=>{
    this.setState({
      isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:null,
    })
  }

  // handleSaveShippingAddress=async(shippingAddress)=>{
  //   const { order } = this.state;
  //   if (!order) {
  //     console.log("saveCustomerShipping Address:: Invalid order:", order);
  //     return;
  //   }
  //   const {id, userId}=order ?? {}
  //   const { transactionDetails ,addressId} = order ?? {};
  //   const { paymentGateway, paymentMethod } = transactionDetails ?? {};
  //   console.log("saveShipping Address::", { order, shippingAddress })
  //   const {addressLine1,addressLine2,buildingApt,cityId,countryId}=shippingAddress ?? {};
  //   const address={
  //     userId:userId,
  //     addressLine1:addressLine1,
  //     addressLine2:addressLine2,
  //     buildingApt:buildingApt,
  //     cityId:cityId,
  //     countryId:countryId,
  //     defaultAddress:false,
  //   }
  //   try {
      
  //     this.handleHideEditCustomerShippingAddressViewModal();
  //     this.showSuccessNotification("Shipping Address saved successfully.");
      
    
  //   this.showLoading();
  //   const payload = {
  //     paymentGateway: paymentGateway,
  //     paymentMethod: paymentMethod,
  //     address:address
  //   };
    
  //     const response = await PhoenixAPI.patch(
  //       `${BASE_URL_MOVE_TO_CART_ORDERS}/${id}`,
  //       payload
  //     );
  //     console.log("response is",response);
  //     this.handleHideEditCustomerShippingAddressViewModal();
  //     this.showSuccessNotification("Shipping Address saved successfully.");
  //     this.showSuccessNotification("Successfully Moved in Cart Order");
  //    await this.fetchOrders();
  //   } catch (error) {
  //       const {message:errorMessage=""}= error.response.data || {}
  //       console.log("Message :", errorMessage)
  //       console.log("Error on Moving to orders: ", error);
  //       this.showErrorNotification(`Error on Moving to cart orders: ${errorMessage}`);
  //   } finally {
  //     this.hideLoading();
  //   }
   
      

  //    // this.fetchOrder();
     
  // }



  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      orders,
      cities,
      countries,
      coupons,
      totalRows,
      rowsPerPage,
      searchTerm,
      searchApplied,
      loadError,
      isCreateOrderViewModalVisible,
      isEditable,
      isEditCustomerShippingAddressViewModalVisible,
      order,
      shippingAddressModal,
      userCityAddress
    } = this.state;
    const filteredOrders = orders ?? [];

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div style={{display:"flex", gap:"20px", justifyContent:"flex-end",marginLeft:"60px"}}>
          <div>
          <button
              onClick={(e) => this.downloadCsv()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download CSV
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadXls()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download Excel
            </button>
          </div>
          </div>
       
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleCreateOrder}
                disabled={!isEditable}
                style={{ backgroundColor: "#0CA789", color: "white" }}
                className="btn btn-md new-user"
              >
                Create Order
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredOrders || []}
            columns={this.columns}
            sortServer
            onSort={this.handleSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={totalRows ?? 0}
            paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + totalRows + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <CreateDraftOrderViewModal
          cities={cities ?? []}
          countries={countries ?? []}
          coupons={coupons ?? []}
          isVisible={isCreateOrderViewModalVisible}
          onHide={this.hideCreateOrderViewModal}
          onSave={this.handleSaveOrder}
        />

        <EditCustomerShippingAddress
        title={
          "Please add Shipping Address to proceed"
        }
         shippingAddress={shippingAddressModal ?? null}
         onHide={this.handleHideEditCustomerShippingAddressViewModal}
         onSave={this.handleSaveShippingAddress}
         isVisible={isEditCustomerShippingAddressViewModalVisible}
         order={order}
         userCityAddress={userCityAddress??[]}
        />

         <Modal
          size="sm"
          show={this.state.downloadOption}
          onHide={this.hideDownloadOptionModal}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select an option from below</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{display:"flex", flexDirection:"column", gap:"25px",margin:"auto", alignItems:"center"}}>
          <div>
          <button
              onClick={(e) => this.downloadAllRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download all records
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadSelectedRows()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download selected records
            </button>
          </div>
          </div>
          </Modal.Body>
        </Modal>

       


      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPendingOrders);
