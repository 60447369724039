import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import SelectSuggestedSupplement from "./SelectSuggestedSupplement";
import moment from "moment";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";
import SelectPeriod from "./SelectPeriod";

class CoachSuggestionsDetails extends Component {
  constructor(props) {
    super(props);
    const{ orderId,suggestions}  = this.props;
    this.state = {
      suggestions: [],
      suggestion: {},

      isSuggestionViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
      searchTerm: null,
      searchApplied: false,
      periods: [],
      supplements: [],
      isEditable: true,
      orderId:null,
    };
    this.columns = [
      {
        name: "Supplement Name",
        selector: "supplementName",
        wrap: true,
        sortable: true,
        cell:(p)=>this.renderSupplementName(p)
      },
      {
        name: "Suggested Date",
        selector: "firstSuggestionDate",
        wrap: true,
        sortable: true,
        format: (row) =>
          row.firstSuggestionDate ? formatDate(row.firstSuggestionDate) : "-",
      },
      {
        name: "Doses",
        selector: "dosesEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Period",
        selector: "periodEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Update",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
      {
        name: "Delete",
        center: true,
        cell: (p) => this.renderDeleteButton(p.id),
      },
    ];
  }

  componentDidMount() {
    const { customerId = null, countryId = null,dependentId=null } = this.props || {};

    this.fetchSuggestions();
    this.fetchSupplements(countryId);
    this.fetchPeriods();
  }

  fetchSuggestions = async () => {
    const { customerId: userId = null, countryId = null,dependentId=null } = this.props || {};
    if (!userId) {
      return;
    }
    this.showLoading();
    try {
     const url= dependentId? `coach-supplement-suggestion-latest-order/${userId}?countryId=${countryId}&dependentId=${dependentId}`:
     `coach-supplement-suggestion-latest-order/${userId}?countryId=${countryId}`;
      const response = await PhoenixAPI.get(url);
      const {orderId=null,coachSupplementSuggested=[]}= response && response.data;
        
        console.log("repsonseR:", response)
      this.setState({orderId, suggestions:coachSupplementSuggested });
    } catch (error) {
      console.log("Error in fetching coach suggestion supplement details");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement details",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchSupplements = async (countryId) => {
    if (!countryId) {
      return;
    }

    try {
      const { data } = await PhoenixAPI.get(
        `suggested-packages-and-supplements?country=${countryId}`
      );
      const { supplements = [] } = data || {};
      this.setState({ supplements });
    } catch (error) {
      console.log("Error in fetching coach suggestion supplement details");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement details",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchPeriods = async () => {
    this.showLoading();
    try {
      const { data: periods = [] } = await PhoenixAPI.get(`supplement-periods`);
      console.log("periods:", periods);
      this.setState({ periods });
    } catch (error) {
      console.log("Error in fetching periods");

      this.showErrorNotification(
        "Error in fetching coach suggestion supplement periods",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      suggestion: {},
      errors: {},
      editables: {},
    });
  };

  renderSupplementName=(supplement)=>{
    if(supplement.unicommerceAvailableQuantity<=0 || supplement.unicommerceAvailableQuantity==null || supplement.unicommerceAvailableQuantity==undefined){
      return `${supplement?.supplementName??""} (OOS)`
    }
    else{
      return supplement?.supplementName ??""
    }
  }
  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewSuggestion(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderDeleteButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleDeleteSuggestion(id);
        }}
      >
        Delete
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (!this.state.isEditable || this.state.isNewSuggestion) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  validate = () => {
    const {suggestion}= this.state;
    const errors = {};
   const {supplementId, periodId}=suggestion || {}
   console.log("suggestion:", suggestion)

     if (supplementId == null) errors.supplementId = "Supplement cannot be blank";
     if(periodId==null) errors.periodId = "Period cannot be blank"

    return Object.keys(errors).length === 0 ? null : errors;
  };


  handleAddSuggestion = () => {
    this.setState({ isNewSuggestion: true }, () =>
      this.showSuggestionViewModal()
    );
  };

  updateSuggestion = async (suggestion) => {
    const payload = { ...suggestion };
    delete payload.unicommerceAvailableQuantity
    try {
      const response = await PhoenixAPI.patch(
        `/coach-supplement-suggestion/${suggestion.id}`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error on creating suggestion", error);
      throw error;
    }
  };

  saveSuggestion = async (suggestion) => {
    const { customerId: userId = null, countryId = null } = this.props || {};
    const {orderId=null}= this.state || {}; 
    if (!userId) {
      return;
    }
    const payload = { ...suggestion };
    payload["orderId"]=orderId;
    payload["userId"] = parseInt(userId);
    delete payload.unicommerceAvailableQuantity
    try {
      const response = await PhoenixAPI.post(
        `/coach-supplement-suggestion`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error on creating suggestion", error);
      throw error;
    }
  };

  handleSaveSuggestion = async () => {
    const { suggestion } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload={...suggestion};
    const today = moment().format("YYYY-MM-DD");
    payload["updatedDate"]=today
    try {
      this.showLoading();
      const isValidId = suggestion.id !== null && suggestion.id !== undefined;

      const response = isValidId
        ? await this.updateSuggestion(payload)
        : await this.saveSuggestion(payload);
      this.fetchSuggestions();
      this.hideSuggestionViewModal();
    } catch (error) {
      console.log("error occurred on saving suggestion", error.message);
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleDeleteSuggestion=async(id)=>{
   
     try{
      this.showLoading();
       const response= await PhoenixAPI.delete(`/coach-supplement-suggestion/${id}`)
       console.log("Response", response)
       this.fetchSuggestions()
     }
     catch(error){
      console.log('Error in Deleting Suggestion', error.message)
      this.showErrorNotification("Error in Deleting Supplement Suggestion")
     }
     finally{
      this.hideLoading()
     }

  }

  handleViewSuggestion = (suggestionId) => {
    const suggestions = this.state.suggestions ?? [];
    const suggestion = suggestions.find((it) => it.id === suggestionId);
    if (!suggestion) {
      console.log(`Suggestion id ${suggestionId} is not found!`);
      return;
    }
    this.setState({ suggestion, isNewSuggestion: false }, () =>
      this.showSuggestionViewModal()
    );
  };

  showSuggestionViewModal = () => {
    const { isNewSuggestion } = this.state;
      const today = moment().format("YYYY-MM-DD");
    if (isNewSuggestion) {
      const suggestion = { updatedDate:today, firstSuggestionDate:today};
      this.setState({
        suggestion,

        isSuggestionViewModalVisible: true,
      });
    } else {
      this.setState({ isSuggestionViewModalVisible: true });
    }
  };

  hideSuggestionViewModal = () => {
    this.setState({ isSuggestionViewModalVisible: false });
    this.resetForm();
  };

  handleChange = ({ currentTarget: input }) => {
    const suggestion = { ...this.state.suggestion };
    suggestion[input.name] = input.value;
    this.setState({ suggestion });
  };

  handleDateSelect = ({ target: input }) => {
    const { suggestion } = this.state || {};
    if (input.name === "updatedDate") {
      const newSuggestion = { ...suggestion, updatedDate: input.value };
      this.setState({
        suggestion: newSuggestion,
      });
    }
    if (input.name === "firstSuggestionDate") {
      const newSuggestion = { ...suggestion, firstSuggestionDate: input.value };
      this.setState({
        suggestion: newSuggestion,
      });
    }
  };


  getFilteredSuggestions=(suggestions)=>{
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return suggestions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return suggestions.filter((suggestion) => {
      const {
       
        supplementName,
        periodEng,
        dosesEng,
       
      } = suggestion;
      return (
        (supplementName && supplementName.toLowerCase().indexOf(newValue) !== -1) ||
        (periodEng &&
          periodEng.toLowerCase().indexOf(newValue) !== -1) ||
        (dosesEng && dosesEng.toLowerCase().indexOf(newValue) !== -1) 
      );
    });
  }

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      suggestions,
      suggestion,
      isSuggestionViewModalVisible,
      errors,
      editables,
      loadError,
      searchTerm,
      searchApplied,
      isNewSuggestion,
      supplements,
      periods,
    } = this.state;

    const filteredSuggestions=this.getFilteredSuggestions(suggestions) ?? [];
    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cityId">Select Supplement *</label>
            <div className="input-group">
              <SelectSuggestedSupplement
                id="supplementId"
                supplements={supplements}
                selectedSuggestedSupplement={suggestion.supplementId}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      supplementId: value.value,
                      unicommerceAvailableQuantity:value.unicommerceAvailableQuantity
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.supplementId}
                className={
                  "form-control" + (errors.supplementId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("supplementId")}
            </div>
            <span className="help-block">{errors.supplementId}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="updatedDate">Updated Date</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="date"
                  id="updatedDate"
                  name="updatedDate"
                  className={`form-control py-2 ${
                    editables.updatedDate || isNewSuggestion
                      ? `non-editable`
                      : `editable`
                  } `}
                  value={suggestion.updatedDate}
                  onChange={this.handleDateSelect}
                  disabled={!isNewSuggestion}
                />
                {/* {this.renderPencilIcon("updatedDate")} */}
              </div>
              {/* <span className="error">{this.state.updatedDate}</span> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (English)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesEng) || ""}
                onChange={this.handleChange}
                id="dosesEng"
                name="dosesEng"
                type="text"
                placeholder="Enter Doses in English"
                className={
                  "form-control input py-2" +
                  (errors.dosesEng ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.dosesEng}
              />
              {this.renderPencilIcon("dosesEng")}
            </div>
            <span className="help-block">{errors.dosesEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="dosesEng">Doses (Arabic)</label>
            <div className="input-group">
              <input
                value={(suggestion && suggestion.dosesAr) || ""}
                onChange={this.handleChange}
                id="dosesAr"
                name="dosesAr"
                type="text"
                placeholder="Enter Doses in Arabic"
                className={
                  "form-control input py-2 input-arabic" +
                  (errors.dosesAr ? " has-error" : "")
                }
                disabled={!isNewSuggestion && !editables.dosesAr}
              />
              {this.renderPencilIcon("dosesAr")}
            </div>
            <span className="help-block">{errors.dosesEng}</span>
          </div>

          
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="firstSuggestionDate">First Suggestion Date</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="date"
                  id="firstSuggestionDate"
                  name="firstSuggestionDate"
                  className={`form-control py-2 ${
                    editables.firstSuggestionDate || isNewSuggestion
                      ? `non-editable`
                      : `editable`
                  } `}
                  value={suggestion.firstSuggestionDate}
                  onChange={this.handleDateSelect}
                  disabled={!isNewSuggestion}
                />
                {/* {this.renderPencilIcon("firstSuggestionDate")} */}
              </div>
              {/* <span className="error">{this.state.updatedDate}</span> */}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="cityId">Select Period *</label>
            <div className="input-group">
              <SelectPeriod
                id="periodId"
                periods={periods}
                selectedPeriod={suggestion.periodId}
                onSelect={(value) =>
                  this.setState({
                    suggestion: {
                      ...suggestion,
                      periodId: value.value,
                    },
                  })
                }
                isDisabled={!isNewSuggestion && !editables.periodId}
                className={
                  "form-control" + (errors.periodId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("periodId")}
            </div>
            <span className="help-block">{errors.periodId}</span>
          </div>
        </div>
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddSuggestion}
                // disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <h4 className="p-head">Coach Supplement Suggestion</h4>

          <DataTable
            data={filteredSuggestions ?? []}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            // pagination
            responsive
            // noHeader={this.state.searchApplied ? false : true}

            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isSuggestionViewModalVisible}
          onHide={this.hideSuggestionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>UserName: </span>
                {this.props.customerName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveSuggestion}
              disabled={false}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form">{detailsForm}</Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachSuggestionsDetails);
