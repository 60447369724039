import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import SupplementCategoriesDropdown from "./SupplementCategoriesDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import FAQForm from "components/Common/FAQForm";
import SubscriptionForm from "components/Common/SubscriptionForm";
import RichTextEditor from "react-rte";
import PhoenixAPI from "utils/PhoenixAPI";
import Logs from "components/Logs/Logs";
import axios from "axios";
import SelectSupplements from "../CouponCode/SelectSupplements"

import {
  fetchSupplements,
  fetchSupplementsSummaryByCountry,
  fetchSupplementById,
  saveSupplement,
  updateSupplement,
  saveSupplementDeliveryFee,
  saveSearchTagDocument,
  updateSearchTagDocument,
  fetchSupplementsDeliveryFeeByCountry,
  fetchSupplementIdOrders,
  fetchSupplementCategories
} from "services/SupplementService";
import OtherImages from "./OtherImages";
import ToggleSwitch from "components/Common/ToggleSwitch";
import H2TagsForm from "components/Common/H2TagsForm";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select";
import PackageOrders from "components/Common/PackageOrders";
import SelectSecondaryCategory from "components/Common/SelectSecondaryCategory";
import { errorOnDev } from "react-pdf/dist/esm/shared/utils";
import { style } from "variables/Variables";
import color from "@material-ui/core/colors/amber";
import internalCategoryOptions from "utils/InterenalCategory";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const validTypes = ['video/mp4', 'video/webm'];


const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

const STATUS_OPTIONS_DELIVERY = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];

class Supplements extends Component {
  constructor(props) {
    super(props);
    this.videoUrlInput = React.createRef();
    this.imageInput = React.createRef();
    this.state = {
      supplements: [],
      rankOptions: [],
      supplement: {},
      searchTerm: null,
      searchTermBrand: null,
      searchApplied: false,
      searchAppliedBrand : false,
      errors: {},
      editables: {},
      isSupplementViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
      morningRoutineRichTextValue: RichTextEditor.createEmptyValue(),
      eveningRoutineRichTextValue: RichTextEditor.createEmptyValue(),
      morningRoutineArabicRichTextValue: RichTextEditor.createEmptyValue(),
      eveningRoutineArabicRichTextValue: RichTextEditor.createEmptyValue(),
      pricingTypeDropdown: "",
      deliveryFee: {},
      errorsDelivery: {},
      valeoPartners: [],
      valeoPartnerOptions: [],
      keyIngredientsRichTextValue: RichTextEditor.createEmptyValue(),
      keyIngredientsRichTextArabicValue: RichTextEditor.createEmptyValue(),
      suggestedDosageRichTextValue: RichTextEditor.createEmptyValue(),
      suggestedDosageRichTextArabicValue: RichTextEditor.createEmptyValue(),
      supplementIdOrders: [],
      supplementIdOrdersRow: 0,
      supplementIdOrdersDetails: {},
      bestSellerOptions: [
        {
          text: "YES",
          value: true,
          key: "yes",
        },
        {
          text: "NO",
          value: false,
          key: "no",
        },

      ],
      supplementCategories: [],
      isGenerate: false,
      supplimentBrands: [],
      supplimentInActiveBrands: [],
      rawBrandData : [],
      supplimentBrandsData: [],
      supplimentBrandsSelected: {},
      brandsOption : [],
      isBrandViewModalVisible: false,
      isBrandNew: false,
      toEditBrand: {},
      selectedToEditBrand : {},
      pageIdForLogs:null,
    };

    this.columns = [

      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "UID",
        selector: "supplementUid",
        width: "150px",
        wrap: true,
        sortable: true
      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (supplement) => (
          <p style={{ textAlign: "right" }}>{supplement.externalNameArabic}</p>
        ),
      },
      // {
      //   name: "Brand name",
      //   selector: "BrandName",
      //   wrap: true,
      //   sortable: true,
      //   right: true,
      //   format: (supplement) => (
      //     <p style={{ textAlign: "right" }}>{supplement.supplementBrandNames}</p>
      //   ),
      // },
      {
        name: "Brand",
        selector: "supplementBrandNames",
        wrap: true,
        sortable: true,
      },
      {
        name: "Category",
        selector: "conditionCategoryName",
        wrap: true,
        sortable: true,
        center: true,
      },
      {
        name: "Secondry Category",
        selector: "secondaryCategoriesName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (supplement) => (
          <Status isActive={supplement.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "Unicommerce Quantity",
        selector: "unicommerceAvailableQuantity",
        wrap: true,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (supplement) => this.renderViewButton(supplement.id),
        width: "170px",
      },
    ];

    this.brandColumn = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name (English)",
        selector: "name",
        width: "600px",
        wrap: true,
        sortable: true
      },



      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      // {
      //   name: "Status",
      //   selector: "status",
      //   maxWidth: "150px",
      //   center: true,
      //   cell: (supplement) => (
      //     <Status isActive={supplement.status === "Active"} />
      //   ),
      //   sortable: true,
      // },
      {
        name: "Status",
        selector: brand => brand.isActive? "Active" : "InActive" ,
        maxWidth: "150px",
        center: true,
        sortable: true,
        cell: (brand) => (
          <Status isActive={brand.isActive??false} />
        ),
      },

      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (brand) => this.renderViewButton(brand.id, brand),
        width: "170px",
      },
    ]

  }

  componentDidMount() {
    this.fetchSupplements();
    // this.fetchDeliveryFee();
    this.fetchValeoPartners()
    this.fetchSupplimentBrands();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.props;
    if (selectedCountry && selectedCountry !== prevProps.selectedCountry) {
      this.fetchSupplements();
      this.fetchDeliveryFee();
      this.fetchSupplimentBrands();
    }
  }

  sortBrandByIsActive = (boolean)=>{
    if(boolean){
      return "Active"
    }
    else{
      return "InActive"
    }
  }

  fetchSupplimentBrands = async () => {
    const { selectedCountry } = this.props;
    const selectedCountryId = selectedCountry.id;
    console.log("this is fetchsupplimentbrands calling",selectedCountryId)
    try {
      // const response = await axios.get("https://phoenix.bevaleo.dev/supplement-brands");
      const api = `supplement-brands/?countryId=${selectedCountryId}`;
      const response = await PhoenixAPI.get(api)
      this.setState({
        rawBrandData : response.data
      })
      response.data.sort((a, b) => a.rank - b.rank);
      console.log("this is brand data", response.data)
      const filterOnlyActiveBrand = response.data.filter((elem)=>{
        return elem.isActive == true
      })
      const filterOnlyInActiveBrand = response.data.filter((elem)=>{
        return elem.isActive == false
      })
      const brandsObj = {};
      const brandObjArr = [];
      const inActiveBrandObjArr = [];
      filterOnlyActiveBrand.map((elem, id) => {
        brandObjArr.push({
          id: elem.id,
          key: elem.name,
          value: elem.name,
          text: elem.name
        })
      })
      filterOnlyInActiveBrand.map((elem,id)=>{
        inActiveBrandObjArr.push({
          id: elem.id,
          key: elem.name,
          value: elem.name,
          text: elem.name
        })
      })
      // console.log("this is brandobjarr : ", brandObjArr)
      // console.log("this is inactive brands", inActiveBrandObjArr)
      const options = [
        {
          key: "Active",
          text: `Active Brands`,
          value : "",
          disabled : true,
          style: {marginBottom:"6px",color:"green",}
        },
        ...brandObjArr,
        {
          key: "Inactive",
          text: "In-Active Brands",
          value : "",
          disabled : true,
          style: {marginBottom:"6px",color:"red"}
        },
        ...inActiveBrandObjArr
      ];
      // console.log(options)
      this.setState({
        supplimentBrands: brandObjArr,
        supplimentInActiveBrands : inActiveBrandObjArr,
        brandsOption : options,
        supplimentBrandsData: response.data
      })
      console.log("this is state of suppliment brand", this.state.supplimentBrands)
    }
    catch (error) {
      console.log("we got an error :", error)
    }
  }

  fetchSupplements = async () => {
    const { selectedCountry } = this.props;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }

    try {
      this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
      const supplementsWithCategoryName = supplements.map((supplement) => {
        const category = this.findCategory(supplement.supplementCategoryId);
        supplement.conditionCategoryName = category?.nameEnglish ?? "";
        return supplement;
      });
      this.setState({ supplements: supplementsWithCategoryName });
      console.log("this is suppliment data",supplementsWithCategoryName);
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchDeliveryFee = async () => {
    const { selectedCountry } = this.props;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      this.showLoading();
      const response = await fetchSupplementsDeliveryFeeByCountry(countryId);
      const data = response && response.data;
      this.setState({ deliveryFee: data });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements delivery charges by country: ",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };


  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
      const partnerOptions = partners.map((it) => {
        return { key: it.id, value: it.id, label: it.name }
      })
      partnerOptions.push({ key: "select", value: "select", label: "Select" })
      this.setState({ valeoPartners: partners, valeoPartnerOptions: partnerOptions })
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchingSupplementCategories = async () => {
    const { selectedCountry } = this.props;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      const supplementCategories = await fetchSupplementCategories();
      console.log("supplement", supplementCategories);
      const supplementCategoriesForCountry = supplementCategories.filter((item) => item.country === countryId && item.status === "Active")
      console.log("supplementCategoriesForCountry", supplementCategoriesForCountry);
      const mappedCategories = supplementCategoriesForCountry.map((item) => {
        return { ...item, name: item.nameEnglish }
      })
      return mappedCategories
    }
    catch (error) {
      console.log("Error in fethcing supplement categories", error.message);
    }
  }

  findCountry = (id) => {
    const { countries = [] } = this.props;
    return countries.find((country) => country.id === id);
  };

  findCategory = (categoryId) => {
    const { categories = [] } = this.props;
    return categories.find((it) => it.id === categoryId);
  };

  findSupplement = (supplementId) => {
    const { supplements = [] } = this.state;
    return supplements.find((it) => it.id === supplementId);
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchBrand = (queryString) => {
    const searchTermBrand = queryString ? queryString : "";
    this.setState({ searchAppliedBrand: true, searchTermBrand });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchClearBrand = () => {
    this.setState({ searchTermBrand: "", searchAppliedBrand: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSearchExitBrand = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTermBrand: "", searchAppliedBrand: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const supplement = { ...this.state.supplement, image };
      this.setState({ supplement });

      this.readImage(image);
    }
  };

  handleIconChange = (e, mediaText) => {
    e.stopPropagation();
    const file = e.target.files[0];
    console.log("change file", e.target.files[0]);
    if (mediaText == "Video URL" && !validTypes.includes(file.type)) {
      this.showErrorNotification("please upload videos ")
    }
    else {
      if (file) {
        // setIcon(file);

        this.uploadIcon(file, mediaText);
      }
    }
  }
  uploadFiles = async (formData) => {

    const response = await PhoenixAPI.post('/upload-media', formData, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        'Content-Type': 'multipart/form-data'
      }
    });

    return response;
  }

  uploadIcon = async (iconFile, mediaText) => {
    if (iconFile.type === "application/pdf") {
      this.showErrorNotification("PDF file is not supported");
      return;
    }
    else{
      try {
        const formData = new FormData();
        formData.append("media", iconFile)
        const response = await this.uploadFiles(formData)
        //setDeals({ ...deals, tagIconUrl: response.data.s3Url })
        console.log("response is ", response.data.message);
        if (mediaText == "Primary Image") {
          const supplement = { ...this.state.supplement, image: response.data.message }
          this.setState({ supplement })
          this.showSuccessNotification("Image Uploaded Successfully");
        }
        if(mediaText == "Brand Image"){
          // this.setState({toEditBrand: { ...this.state.toEditBrand, brandImage: response.data.message } })
          this.setState({toEditBrand: { ...this.state.toEditBrand, image: response.data.message } })
          this.showSuccessNotification("Image Uploaded Successfully");
        }
        if (mediaText == "Video URL") {
          const updateSupplement = { ...this.state.supplement, video: response.data.message }
          this.setState({ supplement: updateSupplement }, () => {
            // Optional: Notify user of successful upload
            this.showSuccessNotification("Video Uploaded Successfully");
          });
          console.log("updateSupplement", updateSupplement, this.state.supplement);

        }
        if (mediaText == "Video Thumbnail") {
          const supplement = { ...this.state.supplement, image: response.data.message }
          this.setState({ supplement });
          this.showSuccessNotification("Video Thumbnail  Uploaded Successfully");
        }



      } catch (error) {
        console.log("Error uploading image:", error);
      }
    }
  }






  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const supplement = { ...this.state.supplement };
    if (numberInputs.includes(input.name))
      supplement[input.name] = input.value ? Number(input.value) : "";
    else supplement[input.name] = input.value;
    this.setState({ supplement });
  };

  generateSearchTags = async () => {
    const { externalNameEnglish, shortDescriptionEnglish, longDescriptionEnglish, searchTags } = this.state.supplement;
    const payload = [
      externalNameEnglish,
      shortDescriptionEnglish || null,
      longDescriptionEnglish || null,
      searchTags || null,

    ]
      .filter(item => item !== null)
      .join(',');
    this.setState({ isGenerate: true });
    try {
      const prevSearchTags = searchTags ? searchTags.split(',') : [];
      const response = await PhoenixAPI.post("generate-keywords", payload);
      const newSearchTags = response.data.message.split(',');
      const combinedSearchTags = Array.from(new Set([...prevSearchTags, ...newSearchTags]));
      console.log("response is", response);
      if (response.data.statusCode == 200) {
        const supplement = {
          ...this.state.supplement,
          searchTags: combinedSearchTags.join(','),
        };
        this.setState({ supplement, isGenerate: false });
        this.showSuccessNotification("Generated Successfully");
      }
    }
    catch (error) {
      console.log("Error in generating search tags", error.message);
      this.showErrorNotification("Error in generating search tags");
    }
  }

  handleRoutineValue = (value, routine) => {
    this.setState({ [routine]: value })
  }
  handleChangeDelivery = ({ currentTarget: input }) => {
    const deliveryFee = { ...this.state.deliveryFee };
    deliveryFee[input.name] = input.value ? Number(input.value) : "";
    this.setState({ deliveryFee });
  };

  handleQuestionsUpdated = (questions = []) => {
    const supplement = { ...this.state.supplement, faqs: questions };
    this.setState({ supplement });
  };

  handleTagsUpdated = (tags = []) => {
    const supplement = { ...this.state.supplement, h2Tags: tags };
    this.setState({ supplement });
  }

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    const supplement = {
      ...this.state.supplement,
      subscriptionItems: subscriptionItems,
    };
    this.setState({ supplement });
  };

  handleOtherImagesUpdated = (otherImages = []) => {
    const supplement = {
      ...this.state.supplement,
      supplementsImages: otherImages,
    };
    this.setState({ supplement });
  };

  handleAddNewSupplement = async () => {
    const supplementCategories = await this.fetchingSupplementCategories()
    this.setState({supplimentBrandsSelected:{}, isNewSupplement: true, supplementCategories }, () =>
      this.showSupplementViewModal()
    );
  };

  // this function will be called when you are adding a new brand
  handleAddNewBrand = () => {
    // this.setState({ isBrandViewModalVisible: true, isbrandnew: true , toEditBrand: {
    //   ...this.state.toEditBrand,
    //   isActive: true,
    // }, });
    this.setState({
      isBrandNew : true,
      isBrandViewModalVisible : true,
      toEditBrand : {
        ...this.state.toEditBrand,
        isActive : true,
      }
    })
    const rankOptions = this.createBrandRankOptions(true) || [];
    console.log("this is rankoptions",rankOptions);
    this.setState({
      rankOptions,
    })
  }

  handleDeliveryFee = () => {
    // const { deliveryFee = {} } = this.state;
    this.fetchDeliveryFee();
    this.setState({ errorsDelivery: {} }, () =>
      this.showDeliveryFeeModal()
    );
  };



  // this is the copy of original handleViewSupplement function
  handleViewSupplementBrand = async (supplementId, brand) => {
    const { valeoPartners } = this.state || {}
    console.log("handle view suppliment brand is calling")
    this.setState({
      isBrandNew:false,
    })

    try {
      this.showLoading();

      const supplement = brand;

      if (!supplement) {
        console.log(`Supplement with id ${supplementId} is not found!`);
        return;
      }
      else {
        console.log(supplement)
      }


      supplement.image_src = brand.image;


      if (supplement.currency === null || supplement.currency === undefined) {
        supplement.currency = this.state.selectedCountry?.currency;
      }
      const monthlyPricing = supplement.subscriptionItems ?? [];

      const partnerId = supplement.partnerId ?? null;
      const selectedPartner = valeoPartners.find((it) => it.id === partnerId);
      const newSupplement = {
        ...supplement, valeoPartnerId: selectedPartner?.id || null,
        valeoPartnerName: selectedPartner?.name || ""
      }

      const keyIngredientsRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.keyIngredientsRichText ?? "",
          "html"
        );
      const keyIngredientsRichTextArabicValue =
        RichTextEditor.createValueFromString(
          supplement.keyIngredientsRichTextArabic ?? "",
          "html"
        );
      const suggestedDosageRichTextValue =
        RichTextEditor.createValueFromString(supplement.suggestedDosageRichText ?? "", "html");
      const suggestedDosageRichTextArabicValue =
        RichTextEditor.createValueFromString(supplement.suggestedDosageRichTextArabic ?? "", "html");


      const morningRoutineRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.morningRoutine ?? "",
          "html"
        );
      const eveningRoutineRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.eveningRoutine ?? "",
          "html"
        );
      const morningRoutineArabicRichTextValue = RichTextEditor.createValueFromString(
        supplement.morningRoutineArabic ?? "",
        "html"
      );
      const eveningRoutineArabicRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.eveningRoutineArabic ?? "",
          "html"
        );

      const { data: supplementIdOrdersDetails } = await fetchSupplementIdOrders(supplementId);

      const supplementCategories = await this.fetchingSupplementCategories()
      const supplementCategoryId = supplement?.supplementCategoryId
      const filteredSupplementCategories = supplementCategories.filter((item) => item.id !== supplementCategoryId)

      this.setState(
        {
          supplement: newSupplement,
          monthlyPricing,
          keyIngredientsRichTextValue,
          keyIngredientsRichTextArabicValue,
          suggestedDosageRichTextValue,
          suggestedDosageRichTextArabicValue,
          morningRoutineRichTextValue,
          eveningRoutineRichTextValue,
          morningRoutineArabicRichTextValue,
          eveningRoutineArabicRichTextValue,
          isNewSupplement: false,
          supplementIdOrders: supplementIdOrdersDetails.content,
          supplementIdOrdersRow: supplementIdOrdersDetails.totalElements,
          supplementCategories: filteredSupplementCategories,
          toEditBrand : brand,
        },

        () => this.showBrandViewModal()
      );

    } catch (error) {
      console.log(
        "handleViewSupplement:: Error on fetching supplement by id!",
        error
      );
      this.showErrorNotification(
        "Error on fetching supplement by id! " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };


  // this is original handlViewSupplement function
  handleViewSupplement = async (supplementId) => {
    const { valeoPartners } = this.state || {}

    this.setState({
      pageIdForLogs : supplementId
    })

    try {
      this.showLoading();

      const { data: supplement } = await fetchSupplementById(supplementId);

      if (!supplement) {
        console.log(`Supplement with id ${supplementId} is not found!`);
        return;
      }

      // this is for showing the brand on view page
      const brandOfThisSupplement = this.state.supplimentBrandsData?.filter((elem)=>{
        return elem.id == supplement.supplementBrands[0];
      })
      
      //this is selected brand id
      this.setState({
        supplimentBrandsSelected : {
          ...this.state.supplimentBrandsSelected,
          selected : brandOfThisSupplement[0]?.name,
          selectedId : supplement.supplementBrands[0]
        }
      })

      supplement.image_src = supplement.image;

      if (supplement.currency === null || supplement.currency === undefined) {
        supplement.currency = this.state.selectedCountry?.currency;
      }
      const monthlyPricing = supplement.subscriptionItems ?? [];

      const partnerId = supplement.partnerId ?? null;
      const selectedPartner = valeoPartners.find((it) => it.id === partnerId);
      const newSupplement = {
        ...supplement, valeoPartnerId: selectedPartner?.id || null,
        valeoPartnerName: selectedPartner?.name || ""
      }

      const keyIngredientsRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.keyIngredientsRichText ?? "",
          "html"
        );
      const keyIngredientsRichTextArabicValue =
        RichTextEditor.createValueFromString(
          supplement.keyIngredientsRichTextArabic ?? "",
          "html"
        );
      const suggestedDosageRichTextValue =
        RichTextEditor.createValueFromString(supplement.suggestedDosageRichText ?? "", "html");
      const suggestedDosageRichTextArabicValue =
        RichTextEditor.createValueFromString(supplement.suggestedDosageRichTextArabic ?? "", "html");


      const morningRoutineRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.morningRoutine ?? "",
          "html"
        );
      const eveningRoutineRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.eveningRoutine ?? "",
          "html"
        );
      const morningRoutineArabicRichTextValue = RichTextEditor.createValueFromString(
        supplement.morningRoutineArabic ?? "",
        "html"
      );
      const eveningRoutineArabicRichTextValue =
        RichTextEditor.createValueFromString(
          supplement.eveningRoutineArabic ?? "",
          "html"
        );

      const { data: supplementIdOrdersDetails } = await fetchSupplementIdOrders(supplementId);

      const supplementCategories = await this.fetchingSupplementCategories()
      const supplementCategoryId = supplement?.supplementCategoryId
      const filteredSupplementCategories = supplementCategories.filter((item) => item.id !== supplementCategoryId)

      this.setState(
        {
          supplement: newSupplement,
          monthlyPricing,
          keyIngredientsRichTextValue,
          keyIngredientsRichTextArabicValue,
          suggestedDosageRichTextValue,
          suggestedDosageRichTextArabicValue,
          morningRoutineRichTextValue,
          eveningRoutineRichTextValue,
          morningRoutineArabicRichTextValue,
          eveningRoutineArabicRichTextValue,
          isNewSupplement: false,
          supplementIdOrders: supplementIdOrdersDetails.content,
          supplementIdOrdersRow: supplementIdOrdersDetails.totalElements,
          supplementCategories: filteredSupplementCategories,
        },

        () => this.showSupplementViewModal()
      );

    } catch (error) {
      console.log(
        "handleViewSupplement:: Error on fetching supplement by id!",
        error
      );
      this.showErrorNotification(
        "Error on fetching supplement by id! " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  showBrandViewModal = () => {
    console.log("this is showBrandModal",this.state.toEditBrand)
    const rankOptions = this.createBrandRankOptions(false) || [];
    console.log("this is rankoptions",rankOptions);
    this.setState({
      isBrandViewModalVisible: true,
      rankOptions,
    })
  }

  showSupplementViewModal = () => {
    const { isNewSupplement } = this.state;
    const { selectedCountry } = this.props;
    const rankOptions = this.createRankOptions() || [];

    if (isNewSupplement) {
      console.log(this.state.supplimentBrandsSelected);
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const supplement = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        isBestseller: false,
        rank,
        searchDocId:Date.now(),
        partnerId:null,
        internalCategory: "None",
        primaryMediaType: "Image",
        isEligibleCod: true
      };
      this.setState({
        supplement,
        rankOptions,
        isSupplementViewModalVisible: true,
      });
    } else {
      if (this.state.supplement.searchDocId == null) {
        const supplement = {
          ...this.state.supplement,
          searchDocId: Date.now()
        }
        this.setState({ supplement, rankOptions, isSupplementViewModalVisible: true })
      }
      else {
        this.setState({ rankOptions, isSupplementViewModalVisible: true });
      }
    }
  };

  showDeliveryFeeModal = () => {
    const { deliveryFee = {} } = this.state;
    const isNewDeliveryFee =
      deliveryFee.id === null || deliveryFee.id === undefined;
    const { selectedCountry } = this.props;
    if (isNewDeliveryFee) {
      const deliveryFee = {
        countryId: selectedCountry?.id,
        isActive: false,
      };
      this.setState({ deliveryFee, isDeliveryFeeViewModalVisible: true });
    } else {
      this.setState({ isDeliveryFeeViewModalVisible: true });
    }
  };

  resetDeliveryForm = () => {
    this.setState({
      deliveryFee: {},
      isNewDeliveryFee: false,
      errorsDelivery: {},
      editables: {},
    })

  }
  hideDeliveryFeeModal = () => {
    this.setState({
      isDeliveryFeeViewModalVisible: false,
    }, () => this.resetDeliveryForm());

  };

  validateDeliveryFee = () => {
    const { deliveryFee = {} } = this.state;
    const errorsDelivery = {};

    const { deliveryCharge, thresholdValue, status } = deliveryFee;

    if (status === null) errorsDelivery.status = "Status is a required field!";

    if (
      deliveryCharge === null ||
      deliveryCharge === undefined ||
      deliveryCharge.toString().trim() === ""
    )
      errorsDelivery.deliveryCharge = "Delivery Charge cannot be blank";
    else if (Number.isNaN(Number(deliveryCharge)))
      errorsDelivery.deliveryCharge = "Delivery Charge should be valid number";
    else if (!this.isInBetween(Number(deliveryCharge), 0, 99999999))
      errorsDelivery.deliveryCharge =
        "Delivery Charge be between 0 to 99999999";

    if (
      thresholdValue === null ||
      thresholdValue === undefined ||
      thresholdValue.toString().trim() === ""
    )
      errorsDelivery.thresholdValue = "Threshold Value cannot be blank";
    else if (Number.isNaN(Number(thresholdValue)))
      errorsDelivery.thresholdValue = "Threshold Value should be valid number";
    else if (!this.isInBetween(Number(thresholdValue), 0, 99999999))
      errorsDelivery.thresholdValue =
        "Threshold Value be between 0 to 99999999";

    return Object.keys(errorsDelivery).length === 0 ? null : errorsDelivery;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const errorsDelivery = this.validateDeliveryFee();
    this.setState({ errorsDelivery: errorsDelivery || {} });
    if (errorsDelivery) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const { deliveryFee = {} } = this.state;
    const { selectedCountry } = this.props;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      console.log("Error in Fetching Country:", selectedCountry)
      return;
    }
    const updatedDelieveryFee = { ...deliveryFee, countryId: selectedCountry.id ?? null }


    try {
      this.showLoading();
      const isValidId = updatedDelieveryFee.id === null || updatedDelieveryFee.id === undefined;
      const response = await saveSupplementDeliveryFee(updatedDelieveryFee);
      this.hideDeliveryFeeModal();
      console.log("Response:isValidId ", isValidId);
      this.showSuccessNotification(
        isValidId
          ? "Delivery Fee created Successfully."
          : "Delivery Fee Updated Successfully."
      );

      this.fetchDeliveryFee();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
      return;
    } finally {
      this.hideLoading();
    }
  };

  hideSupplementViewModal = () => {
    this.setState({ isSupplementViewModalVisible: false, pageIdForLogs:null });
    this.resetForm();
  };

  hideBrandViewModal = () => {
    this.resetForm();
    this.setState({ isBrandViewModalVisible: false, toEditBrand : {}});
  }

  handleSaveBrands = async () => {
    if (this.state.isBrandNew) { // this will be called if we are adding a new brand data
      const errors = this.validateBrand();
      this.setState({ errors: errors || {} });
      if (errors) {
        this.showErrorNotification("There are some invalid form fields!");
        return;
      }

      try {
        console.log("this is new brand")
        const { selectedCountry } = this.props;
        const countryId = selectedCountry?.id ?? null;
        // const api = `https://phoenix.bevaleo.dev/supplement-brands`;
        const api = `supplement-brands`
        let data = this.state.toEditBrand;
        data = { ...data, countryId: countryId};
        const response = await PhoenixAPI.post(api, data);
        console.log("thisis the response after post request", response);
        this.setState({ isBrandNew: false, supplimentBrandsSelected:{} });
        this.hideBrandViewModal();
        this.showSuccessNotification("New brand created successfully");
      }
      catch (error) {
        console.log("we got error in handleSaveBrands", error);
        if (error.response && error.response.status === 400) {
          this.showErrorNotification(error.response.data.message);
        } else {
            this.showErrorNotification("Something went wrong");
        }
      }
      finally{
        this.setState({supplimentBrandsSelected:{}, isbrandnew:false })
        this.hideBrandViewModal();
        this.fetchSupplimentBrands();
      }

    }
    else {   // this will be called if we want to change existing brand data
      try {
        const id = this.state.toEditBrand?.id;
        // const api = `https://phoenix.bevaleo.dev/supplement-brands/${id}`
        const api = `supplement-brands/${id}`
        console.log(this.state.toEditBrand)
        const response = await PhoenixAPI.patch(api, this.state.toEditBrand);
        console.log("this is the response after patch request", response);
        this.hideBrandViewModal();
        this.showSuccessNotification("Brand updated");
      }
      catch(error){
        console.log("we got error in handleSaveBrands",error.message);
        if (error.response && error.response.status === 400) {
            this.showErrorNotification(error.response.data.message);
        } else {
            this.showErrorNotification("Something went wrong");
        }
      }
      finally{
        this.hideBrandViewModal();
        this.fetchSupplimentBrands();
      }
    }

  }

  validateBrand = () => {
    const { supplement = {} } = this.state;
    const errors = {};
    const brandData = this.state.toEditBrand;
    console.log("this is from validatebrand",brandData);

    const {
      name,
      image,
      seoUrl
    } = brandData;

    if (!name || name.trim() === "")
      errors.internalName = "Name cannot be blank";
    else if (name.length > 100)
      errors.internalName = "Name is limited to a 100 characters";


    if (image === null || image === undefined)
      errors.country = "Image cannot be blank";


    if (!seoUrl || seoUrl.trim() === "")
      errors.seoUrl = "Seo URL cannot be blank";
    else if (seoUrl.length > 100)
      errors.seoUrl = "URL can not contain more than 100 characters!";


    return Object.keys(errors).length === 0 ? null : errors;
  };


  handleSaveSupplement = async () => {
    const { supplement } = this.state;
    const { selectedId } = this.state.supplimentBrandsSelected;
    console.log("this is selected brand id",selectedId);
    supplement.supplementBrands = [selectedId];
    supplement.meta_title_arabic = supplement.meta_title_arabic || supplement.metaTitle
    supplement.meta_description_arabic = supplement.meta_description_arabic || supplement.metaDescription
    supplement.keywords_arabic = supplement.keywords_arabic || supplement.keywords

    const { selectedCountry } = this.props;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    if (supplement) {
      const {
        keyIngredientsRichTextValue,
        keyIngredientsRichTextArabicValue,
        suggestedDosageRichTextValue,
        suggestedDosageRichTextArabicValue

      } = this.state;
      try {
        supplement.keyIngredientsRichText =
          keyIngredientsRichTextValue?.toString("html");
        supplement.keyIngredientsRichTextArabic =
          keyIngredientsRichTextArabicValue?.toString("html");
        supplement.suggestedDosageRichText =
          suggestedDosageRichTextValue?.toString("html");
        supplement.suggestedDosageRichTextArabic =
          suggestedDosageRichTextArabicValue?.toString("html");

      } catch (error) {
        console.log("handleSave:: Error on setting content", error);
        supplement.keyIngredientsRichText = "";
        supplement.keyIngredientsRichTextArabic = "";
        supplement.suggestedDosageRichText = "";
        supplement.suggestedDosageRichTextArabic = "";
      }
    }

    if (supplement) {
      const {
        morningRoutineRichTextValue,
        eveningRoutineRichTextValue,
        morningRoutineArabicRichTextValue,
        eveningRoutineArabicRichTextValue,
      } = this.state;
      try {
        if (supplement.isRoutineRequired) {
          supplement.morningRoutine =
            morningRoutineRichTextValue?.toString("html");
          supplement.eveningRoutine =
            eveningRoutineRichTextValue?.toString("html");
          supplement.morningRoutineArabic =
            morningRoutineArabicRichTextValue?.toString("html");
          supplement.eveningRoutineArabic =
            eveningRoutineArabicRichTextValue?.toString("html");
        }
        else {
          supplement.isRoutineRequired = false;
          supplement.morningRoutine = "";
          supplement.eveningRoutine = "";
          supplement.morningRoutineArabic = "";
          supplement.eveningRoutineArabic = "";
        }
      } catch (error) {
        console.log("handleSave:: Error on setting content", error);
        supplement.isRoutineRequired = false;
        supplement.morningRoutine = "";
        supplement.eveningRoutine = "";
        supplement.morningRoutineArabic = "";
        supplement.eveningRoutineArabic = "";
      }

    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    } else if (!supplement.enableOneTime && !supplement.enableSubscription) {
      this.showErrorNotification(
        "At least one type of pricing should be enabled!"
      );
      return;
    }




    try {
      this.showLoading();
      const isValidId = supplement.id !== null && supplement.id !== undefined;
      console.log("supplees_doc", supplement)
      const response = isValidId
        ? await updateSupplement(supplement)
        : await saveSupplement(supplement);

      this.hideSupplementViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Supplement Updated Successfully."
          : "Supplement Created Successfully."
      );


      await this.updateDocument(response, supplement, isValidId);
      this.fetchSupplements();
    } catch (error) {
      let seoExceptionCode = error.response.status ?? "";

      this.showErrorNotification("some error occured:" + error.message);

    } finally {
      this.hideLoading();
      this.setState({supplimentBrandsSelected:{}})
    }
  };

  updateDocument = async (response, supplement, isValidId) => {
    try {
      const searchResponse = await saveSearchTagDocument(response, supplement)

    }
    catch (error) {
      console.log("Error on update or create supplement search doc ", error);
      //this.showErrorNotification("Some error occured supplement document"+error);  
    }
  }






  validate = () => {
    const { supplement = {} } = this.state;
    const errors = {};

    const {

      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      country,

      shortDescriptionEnglish,
      shortDescriptionArabic,
      longDescriptionEnglish,
      longDescriptionArabic,
      keyHighlightsEnglish,
      keyHighlightsArabic,
      disclaimerEnglish,
      disclaimerArabic,
      keyIngredients,
      keyIngredientsArabic,
      suggestedDosage,
      suggestedDosageArabic,
      image,
      numberOfCapsules,
      numberOfCapsulesArabic,
      costPerCapsule,
      costPerCapsuleArabic,
      deliveryTime,
      deliveryTimeArabic,
      retailPrice,
      finalPrice,
      discountType,
      discountValue,
      seoUrl,
      metaTitle,
      metaDescription,
      subscriptionItems,
      keywords,
      isRoutineRequired,
      morningRoutine,
      eveningRoutine,
      morningRoutineArabic,
      eveningRoutineArabic,
      searchTags,
      zohoAccountId,
      itemCode,
      itemSku,
      shelfCode,
      dosage,
      internalCategory,
      video
    } = supplement;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";
    if (isRoutineRequired) {
      if (!morningRoutine || morningRoutine.trim() === "")
        errors.morningRoutine = "Morning Routine(English) cannot be blank";

      if (!eveningRoutine || eveningRoutine.trim() === "")
        errors.eveningRoutine = "Evening Routine (English) cannot be  blank";
      if (!morningRoutineArabic || morningRoutineArabic.trim() === "")
        errors.morningRoutineArabic = "Morning Routine(Arabic) cannot be blank";

      if (!eveningRoutineArabic || eveningRoutineArabic.trim() === "")
        errors.eveningRoutineArabic = "Evening Routine (Arabic) cannot be  blank";
    }

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";
    if (zohoAccountId && zohoAccountId.length > 45) {
      errors.zohoAccountId = "Zoho Account Id is limited to 45 characters";
    }
    else if (!zohoAccountId) {
      errors.zohoAccountId = "Zoho  Account Id is a required field!";
    }

    // Validate Descriptions Section




    if (!keyIngredients || keyIngredients.trim() === "")
      errors.keyIngredients = "Key Ingredients cannot be blank";


    if (!keyIngredientsArabic || keyIngredientsArabic.trim() === "")
      errors.keyIngredientsArabic = "Key Ingredients Arabic cannot be blank";

    if (!suggestedDosage || suggestedDosage.trim() === "")
      errors.suggestedDosage = "Suggested Usage cannot be blank";

    if (!suggestedDosageArabic || suggestedDosageArabic.trim() === "")
      errors.suggestedDosageArabic = "Suggested Usage Arabic cannot be blank";

    if (supplement.primaryMediaType == "Video") {
      if (!video) errors.videoUrl = "video cannot be blank";
      if (!image) errors.videoThumbnailImage = "video Thumbnail cannot be blank";
    }
    if (supplement.primaryMediaType == "Image") {
      if (!image) errors.imageInputPrimary = "Image cannot be blank";
      else if (image && image.size && image.size > TWO_MEGA_BYTE)
        errors.image = "Image should be less than 2 MB!";
    }

    // Validate Pricing Section
    if (numberOfCapsules && numberOfCapsules.length > 100)
      errors.numberOfCapsules =
        "No. of Capsules in Bottle (English) is limited to a 100 characters";

    if (numberOfCapsulesArabic && numberOfCapsulesArabic.length > 100)
      errors.numberOfCapsulesArabic =
        "No. of Capsules in Bottle (Arabic) is limited to a 100 characters";

    if (costPerCapsule && costPerCapsule.length > 100)
      errors.costPerCapsule =
        "Cost Per Pill (English) is limited to a 100 characters";

    if (costPerCapsuleArabic && costPerCapsuleArabic.length > 100)
      errors.costPerCapsuleArabic =
        "Cost Per Pill (Arabic) is limited to a 100 characters";

    if (!deliveryTime || deliveryTime.trim() === "")
      errors.deliveryTime = "Delivery Time (English) cannot be blank";
    else if (deliveryTime.length > 400)
      errors.deliveryTime =
        "Delivery Time (English) is limited to a 400 characters";

    if (!deliveryTimeArabic || deliveryTimeArabic.trim() === "")
      errors.deliveryTimeArabic = "Delivery Time (Arabic) cannot be blank";
    else if (deliveryTimeArabic.length > 400)
      errors.deliveryTimeArabic =
        "Delivery Time (Arabic) is limited to a 400 characters";

    if (supplement.enableOneTime) {
      if (
        retailPrice === null ||
        retailPrice === undefined ||
        retailPrice.toString().trim() === ""
      )
        errors.retailPrice = "Retail Price cannot be blank";
      else if (Number.isNaN(Number(retailPrice)))
        errors.retailPrice = "Retail Price should be a valid number";
      else if (!this.isInBetween(Number(retailPrice), 0, 99999999))
        errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";

      if (
        finalPrice === null ||
        finalPrice === undefined ||
        finalPrice.toString().trim() === ""
      )
        errors.finalPrice = "Final Price cannot be blank";
      else if (Number.isNaN(Number(finalPrice)))
        errors.finalPrice = "Final Price should be a valid number";
      else if (!this.isInBetween(Number(finalPrice), 0, 99999999))
        errors.finalPrice = "Final Price should be bewteen 0 to 99999999";

      if (!discountType) errors.discountType = "Discount Type cannot be blank";

      if (
        discountValue === null ||
        discountValue === undefined ||
        discountValue.toString().trim() === ""
      )
        errors.discountValue = "Discount Value cannot be blank";
      else if (Number.isNaN(Number(discountValue)))
        errors.discountValue = "Discount Value should be a valid number";
      else if (!this.isInBetween(Number(discountValue), 0, 99999999))
        errors.discountValue = "Discount Value should be bewteen 0 to 99999999";
    }

    if (!seoUrl || seoUrl.trim() === "")
      errors.seoUrl = "Seo URL cannot be blank";
    else if (seoUrl.length > 100)
      errors.seoUrl = "URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > 100)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > 200)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.keywords = "keywords can not contain more than 500 characters!";

   

    if (searchTags && searchTags.length > 2000)
      errors.searchTags = "tags cannot contain more than 2000 characters!";
    if (supplement.enableSubscription) {
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscriptionItems = "At least one active item is required!";
      }
    }

    if (itemCode && itemCode.length > 100)
      errors.itemCode = "Item Code can not contain more than 100 characters!";

    if (itemSku && itemSku.length > 100)
      errors.itemSku = "Item SKU can not contain more than 100 characters!";

    if (shelfCode && shelfCode.length > 100)
      errors.shelfCode = "Shelf Code can not contain more than 100 characters!";


    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createBrandRankOptions = (isbrandnew)=>{
    // supplimentBrandsData
    const brands = this.state.supplimentBrandsData;
    const ranks = brands.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isbrandnew ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    // console.log("Rank Options: ", rankOptions);
    return rankOptions;
  }

  createRankOptions = () => {
    const { isNewSupplement } = this.state;
    const supplements = this.getSupplementsForCurrentCountry();
    const ranks = supplements.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewSupplement ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    // console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      supplement: {},
      errors: {},
      editables: {},
      pricingTypeDropdown: "",
      monthlyPricing: [],
      keyIngredientsRichTextValue: RichTextEditor.createEmptyValue(),
      keyIngredientsRichTextArabicValue: RichTextEditor.createEmptyValue(),
      suggestedDosageRichTextValue:RichTextEditor.createEmptyValue(),
      suggestedDosageRichTextArabicValue:RichTextEditor.createEmptyValue(),
      morningRoutineRichTextValue:RichTextEditor.createEmptyValue(),
      eveningRoutineRichTextValue:RichTextEditor.createEmptyValue(),
      morningRoutineArabicRichTextValue:RichTextEditor.createEmptyValue(),
      eveningRoutineArabicRichTextValue:RichTextEditor.createEmptyValue(),
    });
  };

  renderViewButton = (id, brand) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          brand ? this.handleViewSupplementBrand(id, brand) : this.handleViewSupplement(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.props.isEditable && !this.state.isNewSupplement ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  renderPencilIconDelivery = (fieldName) =>
    this.props.isEditable && !this.state.isNewDeliveryFee ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  handleSelectPartner = (partnerId) => {

    const { supplement, valeoPartnerOptions, valeoPartners } = this.state || {}
    if (partnerId === "select") {
      const newSupplement = { ...supplement, partnerId: null, valeoPartnerId: null, valeoPartnerName: "SELECT" }
      this.setState({ supplement: newSupplement })
    }
    else {
      const selectedId = valeoPartners.find(
        (it) => it.id === partnerId
      ) ?? {};

      const newSupplement = { ...supplement, partnerId: selectedId.id ?? null, valeoPartnerId: selectedId.id ?? null, valeoPartnerName: selectedId.name ?? "" }
      this.setState({ supplement: newSupplement })
    }
  }

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  }


  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredSupplements = () => {
    const supplements = this.getSupplementsForCurrentCountry();
    return this.getSearchedSupplements(supplements);
  };

  getFilteredBrands = () => {
    console.log("getfilterdbrands is calling")
    const brands = this.state.supplimentBrandsData;
    return this.getSearchedBrands(brands);
  };

  getSupplementsForCurrentCountry = () => {
    const { supplements } = this.state;
    const { selectedCountry } = this.props;
    return supplements && selectedCountry
      ? supplements.filter((p) => p.country === selectedCountry.id)
      : [];
  };

  handleMediaChange = (event, text) => {



    const videoInput = document.getElementById("Video URL");
    if (videoInput) videoInput.value = "";
    if (text == "Video" && this.videoUrlInput.current) {
      this.videoUrlInput.current.value = null;
    }
    const supplement = {
      ...this.state.supplement,
      primaryMediaType: text,
      video: text == "Video" ? this.state.video : null,
      image: null,

    };
    this.setState({ supplement });
  };

  getSearchedBrands = (supplements) => {
    const { searchAppliedBrand, searchTermBrand } = this.state;

    if (!searchAppliedBrand || !searchTermBrand) return supplements;

    const newValue = searchTermBrand.slice(0).trim().toLowerCase();
    return supplements.filter((supplement) => {
      const {
        id,
        name,
        rank,

      } = supplement;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  getSearchedSupplements = (supplements) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return supplements;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return supplements.filter((supplement) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,

      } = supplement;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  createPartnerOption = (supplement) => {
    return {
      key: supplement.valeoPartnerId,
      value: supplement.valeoPartnerId,
      label: supplement.valeoPartnerName,
    }
  }

  render() {
    const {
      supplements,
      supplement,
      isNewSupplement,
      searchTerm,
      searchTermBrand,
      searchApplied,
      searchAppliedBrand,
      loadError,
      errors,
      editables,
      deliveryFee,
      isNewDeliveryFee,
      errorsDelivery,
      valeoPartnerOptions,
      supplementIdOrders,
      supplementIdOrdersRow,
      supplementCategories,
      supplimentBrands,
      supplimentBrandsSelected
    } = this.state;
    // console.log("suppleement in render", supplement);
    // console.log("suppleement input", supplement.video, supplement.image);
    // console.log(supplimentBrandsSelected)

    const filteredSupplements = this.getFilteredSupplements(supplements) ?? [];
    const filteredBrands = this.getFilteredBrands() || []
    const noOfFilteredBrands = filteredBrands.length;
    // console.log("this is what is main thing passing", filteredSupplements);
    const selectedCountry = this.props.selectedCountry;
    const isEditable = this.props.isEditable ?? false;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    // this is copy of formBasicInformation for brands
    const brandBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Name*</label>
            <div className="input-group">
              <input
                value={this.state.toEditBrand?.name || ""}
                onChange={(e) => {this.setState({ toEditBrand: { ...this.state.toEditBrand, name: e.target.value } }) }}
                id="Name"
                name="Name"
                type="text"
                placeholder="Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={this.state.toEditBrand.isActive==true? "Active" : "Inactive" }
                onChange={(event, value) => {
                  this.setState({
                    toEditBrand: {
                      ...this.state.toEditBrand,
                      isActive: value.value==="Active"? true : false,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                // disabled={!isNewSupplement && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={ this.state.isBrandNew? this.state.rankOptions.length : this.state.toEditBrand?.rank || ""}
                onChange={(event, value) => {
                  this.setState({
                    toEditBrand: { ...this.state.toEditBrand, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewSupplement && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col"></div>
          
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Brand Image*</label>
            <input
              type="file"
              id="country"
              name="country"
              accept="image/png, image/jpeg"
              disabled={false}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
              onChange={(e) => {
                this.setState({
                  toEditBrand:  {...this.state.toEditBrand,brandImage:this.uploadIcon( e.target.files[0] ,"Brand Image")}
                })
                console.log(this.state.toEditBrand)
              }}
            />
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col" >
              {this.state.toEditBrand?.image && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Brand Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={this.state.toEditBrand.image}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>)}
            </div>
        </div>

      </div>
    );


    // this is original form basic information
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={supplement?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={supplement?.status}
                onChange={(event, value) => {
                  this.setState({
                    supplement: {
                      ...supplement,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSupplement && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={supplement?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={supplement?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={supplement?.rank}
                onChange={(event, value) => {
                  this.setState({
                    supplement: { ...supplement, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewSupplement && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewSupplement
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(supplement?.country)?.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="category">Category</label>
            <div className="input-group">
              <SupplementCategoriesDropdown
                id="category"
                name="category"
                categories={
                  this.props.categories
                    ? this.props.categories.filter(
                      (it) => it.country === this.props.selectedCountry?.id
                    )
                    : []
                }
                selectedCategory={
                  supplement &&
                  this.findCategory(supplement.supplementCategoryId)
                }
                onSelect={(event) => {
                  const newSupplement = {
                    ...supplement,
                    supplementCategoryId: event.value,
                  };
                  this.setState({ supplement: newSupplement });
                }}
                isDisabled={!isNewSupplement && !editables.category}
                className={
                  "form-control" + (errors.category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("category")}
            </div>
            <span className="help-block">{errors.category}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="Zoho Id">Zoho Account ID *</label>
            <div className="input-group">
              <input
                value={supplement?.zohoAccountId || ""}
                onChange={this.handleChange}
                id="zohoAccountId"
                name="zohoAccountId"
                type="number"
                maxLength="45"
                placeholder="zoho AccountId"
                className={
                  "form-control py-2" +
                  (errors.zohoAccountId ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewSupplement && !editables.zohoAccountId}
              />
              {this.renderPencilIcon("zohoAccountId")}
            </div>
            <span className="help-block">{errors.zohoAccountId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
              key={`my_unique_select_key__${supplement && supplement.partnerId}`}
              value={supplement && this.createPartnerOption(supplement)}
              onChange={(event) => {
                this.handleSelectPartner(event.value);
              }}
              options={valeoPartnerOptions || []}
              style={selectStyles}
              placeholder="Select Parnter"
              searchable
              lazyLoad
            />
            {/* {this.renderPencilIcon("partner_id")} */}

            <span className="help-block">{errors.partner_id}</span>
          </div>
          {isNewSupplement ? <></> : <div className="form-group col-6">
            <label htmlFor="unicommerce_available_quantity">Unicommerce Quantity</label>
            <div className="input-group">
              <input
                value={supplement.unicommerceAvailableQuantity ?? ""}

                id="unicommerce_available_quantity"
                name="unicommerce_available_quantity"
                type="number"
                placeholder="unicommerce quantity"
                className={
                  "form-control py-2"}
                readOnly={true}
              />

            </div>
          </div>}

        </div>
        <div className="row">
          <div className="form-group col-6">

            <label htmlFor="is_best_seller"> Is Bestseller</label>
            <div className="input-group">
              <Dropdown
                value={supplement?.isBestseller}

                onChange={(event, value) => {
                  this.setState({
                    supplement: {
                      ...supplement,
                      isBestseller: value.value,
                    },
                  });
                }}

                id="is_best_seller"
                placeholder="Best Seller"
                search
                selection
                options={this.state.bestSellerOptions}
                disabled={!isNewSupplement && !editables.isBestseller}
                className={
                  "form-control" + (errors.isBestseller ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isBestseller")}
            </div>


            <span className="help-block">{errors.isBestseller}</span>
          </div>


          {/* this is the internal categories section  */}
          <div className="form-group col">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={supplement?.internalCategory || "None"}
                onChange={(event, value) => {
                  this.setState({
                    supplement: {
                      ...supplement,
                      internalCategory: value.value,
                    },
                  });
                }}
                id="internalCategory"
                name="internalCategory"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewSupplement && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col-6">
            <label>Select Secondary Category</label>
            <div className="input-group">
              <SelectSecondaryCategory
                categories={supplementCategories}
                selectedCategories={supplement?.secondaryCategories ?? []}
                onSelect={(values) => {
                  this.setState({
                    supplement: {
                      ...supplement,
                      secondaryCategories: values.map((it) => it.value),
                    },
                  });
                }}
                isDisabled={!isNewSupplement && !editables.secondaryCategories}
                className={
                  "form-control" + (errors.secondaryCategories ? " has-error" : "")
                }
                menuPlacement={"top"}
              />
              {this.renderPencilIcon("secondaryCategories")}
            </div>
            <span className="help-block">{errors.secondaryCategories}</span>
          </div>


          {/* <div id="this-is-the-space" >this is exact space</div> */}
          <div className="form-group col" >
            <label htmlFor="internalCategory">Brand</label>
            <div className="input-group">
              <Dropdown
                value={this.state.supplimentBrandsSelected?.selected || "None"}
                onChange={(event, value) => {
                  const selectedOption = this.state.brandsOption.find(option => option.value === value.value);
                  const selectedId = selectedOption ? selectedOption.id : null;
                  console.log("this is brand arr we are creating", [selectedId]);
                  this.setState({
                    supplimentBrandsSelected: {
                      ...this.state.supplimentBrandsSelected,
                      selected: value.value,
                      selectedId : selectedId,
                    },
                  });
                }}
                onSelect={(e)=>{
                  console.log("this is selected",e)
                }}
                id="internalCategory"
                name="internalCategory"
                placeholder="Internal Category"
                search
                selection
                options={this.state.brandsOption}
                disabled={!isNewSupplement && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>



        </div>
        <div className="row">
          <div className="form-group col">
            <div className="input-group">
              <input
                value={true}
                checked={supplement.isBundleTypeSku || false}
                onChange={(event, value) => {
                  this.setState({
                    supplement: { ...supplement, isBundleTypeSku: event.target.checked }
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
              &nbsp;&nbsp; Is this SKU Bundle type ?
            </div>

          </div>

        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="shortDescriptionEnglish">
              Short Description (English)
            </label>
            <div className="input-group">
              <input
                value={supplement.shortDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="shortDescriptionEnglish"
                name="shortDescriptionEnglish"
                type="text"
                placeholder="Short Description in English"
                className={
                  "form-control py-2" +
                  (errors.shortDescriptionEnglish ? " has-error" : "")
                }
                disabled={
                  !isNewSupplement && !editables.shortDescriptionEnglish
                }
              />
              {this.renderPencilIcon("shortDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.shortDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="shortDescriptionArabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <input
                value={supplement.shortDescriptionArabic || ""}
                onChange={this.handleChange}
                id="shortDescriptionArabic"
                name="shortDescriptionArabic"
                type="text"
                placeholder="Short Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.shortDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.shortDescriptionArabic}
              />
              {this.renderPencilIcon("shortDescriptionArabic")}
            </div>
            <span className="help-block">{errors.shortDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="longDescriptionEnglish">
              Long Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={supplement.longDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="longDescriptionEnglish"
                name="longDescriptionEnglish"
                placeholder="Long Description in English"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.longDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("longDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.longDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="longDescriptionArabic">
              Long Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={supplement.longDescriptionArabic || ""}
                onChange={this.handleChange}
                id="longDescriptionArabic"
                name="longDescriptionArabic"
                placeholder="Long Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.longDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("longDescriptionArabic")}
            </div>
            <span className="help-block">{errors.longDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keyHighlightsEnglish">
              Key Highlights (English)
            </label>
            <div className="input-group">
              <textarea
                value={supplement.keyHighlightsEnglish || ""}
                onChange={this.handleChange}
                id="keyHighlightsEnglish"
                name="keyHighlightsEnglish"
                placeholder="Key Highlights in English"
                className={
                  "form-control py-2" +
                  (errors.keyHighlightsEnglish ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keyHighlightsEnglish}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsEnglish")}
            </div>
            <span className="help-block">{errors.keyHighlightsEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyHighlightsArabic">Key Highlights (Arabic)</label>
            <div className="input-group">
              <textarea
                value={supplement.keyHighlightsArabic || ""}
                onChange={this.handleChange}
                id="keyHighlightsArabic"
                name="keyHighlightsArabic"
                placeholder="Key Highlights in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keyHighlightsArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keyHighlightsArabic}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsArabic")}
            </div>
            <span className="help-block">{errors.keyHighlightsArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="disclaimerEnglish">Disclaimer (English)</label>
            <div className="input-group">
              <textarea
                value={supplement.disclaimerEnglish || ""}
                onChange={this.handleChange}
                id="disclaimerEnglish"
                name="disclaimerEnglish"
                placeholder="Disclaimer in English"
                className={
                  "form-control py-2" +
                  (errors.disclaimerEnglish ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.disclaimerEnglish}
              ></textarea>
              {this.renderPencilIcon("disclaimerEnglish")}
            </div>
            <span className="help-block">{errors.disclaimerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="disclaimerArabic">Disclaimer (Arabic)</label>
            <div className="input-group">
              <textarea
                value={supplement.disclaimerArabic || ""}
                onChange={this.handleChange}
                id="disclaimerArabic"
                name="disclaimerArabic"
                placeholder="Disclaimer in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.disclaimerArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.disclaimerArabic}
              ></textarea>
              {this.renderPencilIcon("disclaimerArabic")}
            </div>
            <span className="help-block">{errors.disclaimerArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keyIngredients">Key Ingredients (without RichText) *</label>
            <div className="input-group">
              <textarea
                value={supplement?.keyIngredients || ""}
                onChange={this.handleChange}
                id="keyIngredients"
                name="keyIngredients"
                placeholder="Key Ingredients"
                className={
                  "form-control py-2" +
                  (errors.keyIngredients ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keyIngredients}
              ></textarea>
              {this.renderPencilIcon("keyIngredients")}
            </div>
            <span className="help-block">{errors.keyIngredients}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyIngredientsArabic">
              Key Ingredients (without RichText Arabic) *
            </label>
            <div className="input-group">
              <textarea
                value={supplement?.keyIngredientsArabic || ""}
                onChange={this.handleChange}
                id="keyIngredientsArabic"
                name="keyIngredientsArabic"
                placeholder="Key Ingredients in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.keyIngredientsArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keyIngredientsArabic}
              ></textarea>
              {this.renderPencilIcon("keyIngredientsArabic")}
            </div>
            <span className="help-block">{errors.keyIngredientsArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="suggestedDosage">Suggested Usage (without RichText) *</label>
            <div className="input-group">
              <textarea
                value={supplement?.suggestedDosage || ""}
                onChange={this.handleChange}
                id="suggestedDosage"
                name="suggestedDosage"
                placeholder="Suggested Dosage"
                className={
                  "form-control py-2" +
                  (errors.suggestedDosage ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.suggestedDosage}
              />
              {this.renderPencilIcon("suggestedDosage")}
            </div>
            <span className="help-block">{errors.suggestedDosage}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="suggestedDosageArabic">
              Suggested Usage (without RichText Arabic) *
            </label>
            <div className="input-group">
              <textarea
                value={supplement?.suggestedDosageArabic || ""}
                onChange={this.handleChange}
                id="suggestedDosageArabic"
                name="suggestedDosageArabic"
                placeholder="Suggested Dosage in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.suggestedDosageArabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.suggestedDosageArabic}
              />
              {this.renderPencilIcon("suggestedDosageArabic")}
            </div>
            <span className="help-block">{errors.suggestedDosageArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Key Ingrediants (with RichText)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.keyIngredientsRichTextValue}
                onChange={(value) => this.handleRichText(value, "keyIngredientsRichTextValue")}
                disabled={!isNewSupplement && !editables.keyIngredientsRichText}
              />
              {this.renderPencilIcon("keyIngredientsRichText")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Key Ingrediants (with RichText Arabic)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.keyIngredientsRichTextArabicValue}
                onChange={(value) => this.handleRichText(value, "keyIngredientsRichTextArabicValue")}
                disabled={!isNewSupplement && !editables.keyIngredientsRichTextArabic}
              />
              {this.renderPencilIcon("keyIngredientsRichTextArabic")}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Suggested Usage (with RichText)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.suggestedDosageRichTextValue}
                onChange={(value) => this.handleRichText(value, "suggestedDosageRichTextValue")}
                disabled={!isNewSupplement && !editables.suggestedDosageRichText}
              />
              {this.renderPencilIcon("suggestedDosageRichText")}
            </div>
          </div>


          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label >
              Suggested Usage (with RichText Arabic)
            </label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.suggestedDosageRichTextArabicValue}
                onChange={(value) => this.handleRichText(value, "suggestedDosageRichTextArabicValue")}
                disabled={!isNewSupplement && !editables.suggestedDosageRichTextArabic}
              />
              {this.renderPencilIcon("suggestedDosageRichTextArabic")}
            </div>
          </div>
        </div>



        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <h6>Primary Image/Video:</h6>

            <div className="row">
              <div className="col-3">
                <label>

                  <input
                    type="radio"
                    name="media"
                    value="Video"
                    checked={supplement?.primaryMediaType === "Video"}
                    onChange={(e) => this.handleMediaChange(e, "Video")}
                  />  Video
                </label>  </div>
              <div className="col-3">
                <label>

                  <input
                    type="radio"
                    name="media"
                    value="Image"
                    checked={supplement?.primaryMediaType === "Image"}
                    onChange={(e) => this.handleMediaChange(e, "Image")}
                  /> Image
                </label> </div>
            </div>



          </div></div>

        {supplement.primaryMediaType == "Image" ? (<div className="row">
          <div className="form-group col">
            <label htmlFor="imageInputPrimary">Primary Image*</label>
            <div className="input-group">
              <input
                ref={this.imageInput}
                onChange={(e) => this.handleIconChange(e, 'Primary Image')}
                id="imageInputPrimary"
                name="imageInputPrimary"
                type="file"
                accept="image/*"
                disabled={!isNewSupplement && !editables.imageInputPrimary}
                className={"form-control" + (errors.imageInputPrimary ? " has-error" : "")}
              />
              {this.renderPencilIcon("imageInputPrimary")}
            </div>
            <span className="help-block">{errors.imageInputPrimary}</span>
          </div>
          <div className="form-group col">
            {supplement?.image && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={supplement.image}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>) :
          (<div className="row">
            <div className="form-group col">
              <label htmlFor="Video URL">Video File*</label>
              <div className="input-group">
                <input
                  ref={this.videoUrlInput}
                  onChange={(e) => this.handleIconChange(e, 'Video URL')}
                  id="videoUrl"
                  name="videoUrl"
                  type="file"
                  accept="video/*"
                  disabled={!isNewSupplement && !editables.videoUrl}
                  className={"form-control" + (errors.videoUrl ? " has-error" : "")}
                />
                {this.renderPencilIcon("videoUrl")}
              </div>
              <span className="help-block">{errors.videoUrl}</span>
              <br />
              <label htmlFor="videoThumbnailImage">Video Thumbnail*</label>
              <div className="input-group">
                <input
                  onChange={(e) => this.handleIconChange(e, 'Video Thumbnail')}
                  id="videoThumbnailImage"
                  name="videoThumbnailImage"
                  type="file"
                  accept="image/*"
                  disabled={!isNewSupplement && !editables.videoThumbnailImage}
                  className={"form-control" + (errors.videoThumbnailImage ? " has-error" : "")}
                />
                {this.renderPencilIcon("videoThumbnailImage")}
              </div>
              <span className="help-block">{errors.videoThumbnailImage}</span>

            </div>


            <div className="form-group col">
              {supplement?.video && (<>

                <label htmlFor="selected_package_video">
                  Selected Video
                </label><div>
                  <video controls width="350" height="150" key={supplement.video}>
                    <source src={supplement.video} type="video/mp4" />
                  </video></div></>)}
              {supplement?.image && (
                <>
                  <label htmlFor="selected_package_image">
                    Selected Thumbnail Image
                  </label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={supplement.image}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>)}

            </div>
          </div>

          )}
        <div className="row">
          <div className="col">
            <OtherImages
              id="english"
              label="Other Images"
              images={supplement?.supplementsImages ?? []}
              onImagesUpdated={this.handleOtherImagesUpdated}
              isEditable={isEditable}
              selectedMedia={"Image"}
            />
          </div>
        </div>
      </div>
    );

    const formPricingOneTimeContent = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Retail Price*</label>
            <div className="input-group">
              <input
                value={supplement.retailPrice ?? ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder="Retail Price"
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.retailPrice}
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="finalPrice">Final Price*</label>
            <div className="input-group">
              <input
                value={supplement.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="number"
                placeholder="Final Price"
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.finalPrice}
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={supplement?.discountType}
                onChange={(event, value) => {
                  this.setState({
                    supplement: {
                      ...supplement,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewSupplement && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountValue">Discount Value*</label>
            <div className="input-group">
              <input
                value={supplement.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                className={
                  "form-control py-2" +
                  (errors.discountValue ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.discountValue}
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
      </>
    );

    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={supplement.enableOneTime ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  supplement: { ...supplement, enableOneTime: isToggled },
                })
              }
            />
          </div>
        </div>
        {supplement.enableOneTime && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscriptionItems}</span>
        <SubscriptionForm
          subscriptions={this.state.monthlyPricing || []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableSubscription"
              toggled={supplement.enableSubscription ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  supplement: { ...supplement, enableSubscription: isToggled },
                })
              }
            />
          </div>
        </div>
        {supplement.enableSubscription && formPricingSubscriptionContent}
      </>
    );

    const formPricing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={!supplement?.isEligibleCod || false}
                onChange={(event, value) => {
                  this.setState({
                    supplement: { ...supplement, isEligibleCod: !event.target.checked }
                  })
                }}
                id="isCodRemove"
                name="isCodRemove"
                type="checkbox"
                className="larger-checkbox"
              />
            </div>
               &nbsp;&nbsp; Remove COD for this product
               </div> 
            <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={supplement?.isVatExcluded || false}
                onChange={(event, value) => {
                  this.setState({
                    supplement: { ...supplement, isVatExcluded:event.target.checked}
                  })
                }}
                id="isVatExcluded"
                name="isVatExcluded"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; No VAT for this product
               </div> 

            </div>
            </div>
            
         
     
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="currency">Currency</label>
            <div className="input-group">
              <input
                value={supplement?.currency || ""}
                id="currency"
                name="currency"
                type="text"
                className={
                  "form-control py-2" + (errors.currency ? " has-error" : "")
                }
                disabled={true}
              />
            </div>
            <span className="help-block">{errors.currency}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="numberOfCapsules">
              No. of Capsules in Bottle (English)
            </label>
            <div className="input-group">
              <input
                value={supplement?.numberOfCapsules || ""}
                onChange={this.handleChange}
                id="numberOfCapsules"
                name="numberOfCapsules"
                type="text"
                placeholder="No. of Capsules in Bottle in English."
                className={
                  "form-control py-2" +
                  (errors.numberOfCapsules ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.numberOfCapsules}
              />
              {this.renderPencilIcon("numberOfCapsules")}
            </div>
            <span className="help-block">{errors.numberOfCapsules}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="numberOfCapsulesArabic">
              No. of Capsules in Bottle (Arabic)
            </label>
            <div className="input-group">
              <input
                value={supplement?.numberOfCapsulesArabic || ""}
                onChange={this.handleChange}
                id="numberOfCapsulesArabic"
                name="numberOfCapsulesArabic"
                type="text"
                placeholder="No. of Capsules in Bottle in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.numberOfCapsulesArabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.numberOfCapsulesArabic}
              />
              {this.renderPencilIcon("numberOfCapsulesArabic")}
            </div>
            <span className="help-block">{errors.numberOfCapsulesArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="deliveryTime">Delivery Time (English)*</label>
            <div className="input-group">
              <input
                value={supplement?.deliveryTime || ""}
                onChange={this.handleChange}
                id="deliveryTime"
                name="deliveryTime"
                type="text"
                placeholder="Delivery Time in English."
                className={
                  "form-control py-2" +
                  (errors.deliveryTime ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.deliveryTime}
              />
              {this.renderPencilIcon("deliveryTime")}
            </div>
            <span className="help-block">{errors.deliveryTime}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="deliveryTimeArabic">Delivery Time (Arabic)*</label>
            <div className="input-group">
              <input
                value={supplement?.deliveryTimeArabic || ""}
                onChange={this.handleChange}
                id="deliveryTimeArabic"
                name="deliveryTimeArabic"
                type="text"
                placeholder="Delivery Time in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.deliveryTimeArabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.deliveryTimeArabic}
              />
              {this.renderPencilIcon("deliveryTimeArabic")}
            </div>
            <span className="help-block">{errors.deliveryTimeArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="costPerCapsule">Cost Per Pill (English)</label>
            <div className="input-group">
              <input
                value={supplement?.costPerCapsule || ""}
                onChange={this.handleChange}
                id="costPerCapsule"
                name="costPerCapsule"
                type="text"
                placeholder="Cost Per Pill in English"
                className={
                  "form-control py-2" +
                  (errors.costPerCapsule ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.costPerCapsule}
              />
              {this.renderPencilIcon("costPerCapsule")}
            </div>
            <span className="help-block">{errors.costPerCapsule}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="costPerCapsuleArabic">Cost Per Pill (Arabic)</label>
            <div className="input-group">
              <input
                value={supplement?.costPerCapsuleArabic || ""}
                onChange={this.handleChange}
                id="costPerCapsuleArabic"
                name="costPerCapsuleArabic"
                type="text"
                placeholder="Cost Per Pill in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.costPerCapsuleArabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.costPerCapsuleArabic}
              />
              {this.renderPencilIcon("costPerCapsuleArabic")}
            </div>
            <span className="help-block">{errors.costPerCapsuleArabic}</span>
          </div>
        </div>
        {formPricingOneTime}
        {formPricingSubscription}
      </div>
    );


    // this is copy of prewritten original formSeoMarketing
    const brandSeoForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL*</label>
            <div className="input-group">
              <input
                // value={(supplement && supplement.seoUrl) || ""}
                value={this.state.toEditBrand?.seoUrl || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, seoUrl: e.target.value } }) }}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                // value={(supplement && supplement.metaTitle) || ""}
                value={this.state.toEditBrand?.metaTitle || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, metaTitle: e.target.value } }) }}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                // value={(supplement && supplement.meta_title_arabic) || ""}
                value={this.state.toEditBrand?.metaTitleArabic || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, metaTitleArabic: e.target.value } }) }}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                // value={(supplement && supplement.metaDescription) || ""}
                value={this.state.toEditBrand?.metaDescription || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, metaDescription: e.target.value } }) }}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                // value={(supplement && supplement.meta_description_arabic) || ""}
                value={this.state.toEditBrand?.metaDescriptionArabic || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, metaDescriptionArabic: e.target.value } }) }}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                // value={(supplement && supplement.keywords) || ""}
                value={this.state.toEditBrand?.keywords || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, keywords: e.target.value } }) }}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>    <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                // value={(supplement && supplement.keywords_arabic) || ""}
                value={this.state.toEditBrand?.keywordsArabic || ""}
                onChange={(e) => { this.setState({ toEditBrand: { ...this.state.toEditBrand, keywordsArabic: e.target.value } }) }}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>


      </div>
    );


    // this is original formSeoMarketing 
    const formSeoMarketing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL*</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.seoUrl) || ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.url_name_arabic) || ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.meta_title_arabic) || ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.meta_description_arabic) || ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(supplement && supplement.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>    <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(supplement && supplement.keywords_arabic) || ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>H2 Tags</span>
          </div>
        </div>
        <div className="row">
          <H2TagsForm
            tags={supplement?.h2Tags || []}
            onTagUpdated={this.handleTagsUpdated}
            isEditable={isEditable}
          />
        </div>
      </div>
    );

    const formFaq = (
      <div className="container mt-4">
        <FAQForm
          questions={supplement?.faqs || []}
          onQuestionUpdated={this.handleQuestionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formSearchEngine = (
      <div className="container mt-4">
        <div className="row">

          <div className="form-group col-6">
            <label htmlFor="searchTags">Tags</label>
            <div className="input-group">
              <textarea
                value={(supplement && supplement.searchTags) || ""}
                onChange={this.handleChange}
                id="searchTags"
                name="searchTags"

                placeholder="Tags"
                className={
                  "form-control py-2" + (errors.searchTags ? " has-error" : "")
                }
                readOnly={!isNewSupplement && !editables.searchTags}
              ></textarea>
              {this.renderPencilIcon("searchTags")}
            </div>
            <span className="help-block">{errors.searchTags}</span>
          </div>
          <div className="form-group col">
            <label></label><br />
            <button
              type="button"
              onClick={this.generateSearchTags}
              className={
                "form-control btn btn-info mt-2 py-1" +
                (this.state.searchTags ? "non-editable " : "editable ")
              }
              disabled={this.state.isGenerate}
            >
              Generate through AI
            </button>
          </div>

        </div>


      </div>


    );
    const formRoutine = (<div className="container mt-4">
      <div className="row">
        <div className="form-group col-4">Is Routine required ?</div>
        <div className="form-group col-2">
          <ToggleSwitch
            id="routine"
            toggled={this.state.supplement.isRoutineRequired ?? false}
            //disabled={!this.state.is_tests_are_final ? true : !isEditable}
            onToggle={(isToggled) => {
              const newSupplement = {
                ...supplement,
                isRoutineRequired: isToggled,
              };
              this.setState({ supplement: newSupplement });
            }
            }
          />
        </div>
      </div>
      {this.state.supplement.isRoutineRequired ? <><div className="row">
        <div className="form-group col" style={{ maxWidth: '50%' }}>
          <label htmlFor="morningRoutine">Morning Routine (English) *</label>
          <div className="input-group">
            <RichTextEditor
              rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
              editorStyle={{ height: '90px', overflow: 'hidden' }}
              value={this.state.morningRoutineRichTextValue}
              onChange={(value) => this.handleRoutineValue(value, "morningRoutineRichTextValue")}
              disabled={!isNewSupplement && !editables.morningRoutine}
            />
            {this.renderPencilIcon("morningRoutine")}
          </div>
          <span className="help-block">{errors.morningRoutine}</span>
        </div>
        <div className="form-group col" style={{ maxWidth: '50%' }}>
          <label htmlFor="morningRoutineArabic">Morning Routine (Arabic) *</label>
          <div className="input-group">
            <RichTextEditor
              rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
              editorStyle={{ height: '90px', overflow: 'hidden' }}
              value={this.state.morningRoutineArabicRichTextValue}
              onChange={(value) => this.handleRoutineValue(value, "morningRoutineArabicRichTextValue")}
              disabled={!isNewSupplement && !editables.morningRoutineArabic}
            />
            {this.renderPencilIcon("morningRoutineArabic")}
          </div>
          <span className="help-block">{errors.morningRoutineArabic}</span>
        </div>

      </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label htmlFor="eveningRoutine">Evening Routine (English) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.eveningRoutineRichTextValue}
                onChange={(value) => this.handleRoutineValue(value, "eveningRoutineRichTextValue")}
                disabled={!isNewSupplement && !editables.eveningRoutine}
              />
              {this.renderPencilIcon("eveningRoutine")}
            </div>
            <span className="help-block">{errors.eveningRoutine}</span>
          </div>
          <div className="form-group col" style={{ maxWidth: '50%' }}>
            <label htmlFor="eveningRoutineArabic">Evening Routine (Arabic) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{ position: 'relative', flex: "1 1 auto", width: '1%', marginBottom: 0 }}
                editorStyle={{ height: '90px', overflow: 'hidden' }}
                value={this.state.eveningRoutineArabicRichTextValue}
                onChange={(value) => this.handleRoutineValue(value, "eveningRoutineArabicRichTextValue")}
                disabled={!isNewSupplement && !editables.eveningRoutineArabic}
              />
              {this.renderPencilIcon("eveningRoutineArabic")}
            </div>
            <span className="help-block">{errors.eveningRoutineArabic}</span>
          </div>
        </div>

      </> : <></>}



    </div>


    );


    const DeliveryForm = (
      <div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="deliveryCharge">Delivery Charge*</label>
            <div className="input-group">
              <input
                value={deliveryFee.deliveryCharge ?? ""}
                onChange={this.handleChangeDelivery}
                id="deliveryCharge"
                name="deliveryCharge"
                type="number"
                placeholder="Delivery Charge"
                className={
                  "form-control py-2" +
                  (errorsDelivery.deliveryCharge ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewDeliveryFee && !editables.deliveryCharge}
              />
              {this.renderPencilIconDelivery("deliveryCharge")}
            </div>
            <span className="help-block">{errorsDelivery.deliveryCharge}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="thresholdValue">Threshold Value*</label>
            <div className="input-group">
              <input
                value={deliveryFee.thresholdValue || ""}
                onChange={this.handleChangeDelivery}
                id="thresholdValue"
                name="thresholdValue"
                type="number"
                placeholder="Threshold Value"
                className={
                  "form-control py-2" +
                  (errorsDelivery.thresholdValue ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewDeliveryFee && !editables.thresholdValue}
              />
              {this.renderPencilIconDelivery("thresholdValue")}
            </div>
            <span className="help-block">{errorsDelivery.thresholdValue}</span>
            </div>
            </div>
            <div className="row">
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={deliveryFee?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    deliveryFee: { ...deliveryFee, isActive: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS_DELIVERY}
                disabled={!isNewDeliveryFee && !editables.status}
                className={"form-control" + (errorsDelivery.status ? " has-error" : "")}
              />
              {this.renderPencilIconDelivery("status")}
            </div>
            <span className="help-block">{errorsDelivery.status}</span>
          </div>
          </div>
        
       </div>
        )
        


    const formUniCommerce = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="itemCode">Item Code</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.itemCode) || ""}
                onChange={this.handleChange}
                id="itemCode"
                name="itemCode"
                type="text"
                placeholder="Item Code"
                className={
                  "form-control py-2" + (errors.itemCode ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.itemCode}
              />
              {this.renderPencilIcon("itemCode")}
            </div>
            <span className="help-block">{errors.itemCode}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="itemSku">Item SKU</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.itemSku) || ""}
                onChange={this.handleChange}
                id="itemSku"
                name="itemSku"
                type="text"
                placeholder="Item SKU"
                className={
                  "form-control py-2" + (errors.itemSku ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.itemSku}
              />
              {this.renderPencilIcon("itemSku")}
            </div>
            <span className="help-block">{errors.itemSku}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="shelfCode">Shelf Code</label>
            <div className="input-group">
              <input
                value={(supplement && supplement.shelfCode) || ""}
                onChange={this.handleChange}
                id="shelfCode"
                name="shelfCode"
                type="text"
                placeholder="Shelf Code"
                className={
                  "form-control py-2" + (errors.shelfCode ? " has-error" : "")
                }
                disabled={!isNewSupplement && !editables.shelfCode}
              />
              {this.renderPencilIcon("shelfCode")}
            </div>
            <span className="help-block">{errors.shelfCode}</span>
          </div>
        </div>
      </div>
    )
    const formSupplementOrders = (<>
      <PackageOrders
        packageOrderDetails={supplementIdOrders}
        totalRows={supplementIdOrdersRow}
        packageType={"Supplement"}
        packageId={supplement?.id}
      />
    </>)
    const formFrequentlyBought = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label>Select Products</label>
            <div className="input-group">
              <SelectSupplements
                id="suggestedSupplements"
                name="suggestedSupplements"
                countryId={selectedCountry?.id}
                selectedSupplementIds={supplement.freqBoughtSuplementsId ?? []}
                onSupplementSelect={(options) => {
                  const newSupplement={...supplement,freqBoughtSuplementsId: options.map((it) => it.value)}
                  this.setState({
                     supplement: newSupplement ,
                  });
                }}
                menuPlacement={"bottom"}
                showOnlyActive={true}
                isDisabled={!isNewSupplement && !editables.freqBoughtSuplementsId}
                className={
                  "form-control" + (errors.freqBoughtSuplementsId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("freqBoughtSuplementsId")}
            </div>
            <span className="help-block">{errors.freqBoughtSuplementsId}</span>
          </div>
        </div>
      </div>
    )


    return (
      <div>

        {/* this is wild guess but a nother section will be created by this */}
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTermBrand}
                  handleSearch={this.handleSearchBrand}
                  clearSearch={this.handleSearchClearBrand}
                  handleSearchExit={this.handleSearchExitBrand}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>
              <div className="action-item">
                <button
                  // handleAddNewBrand
                  onClick={this.handleAddNewBrand}
                  disabled={!isEditable}
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
              {/* <div className="action-item">
                <button
                  onClick={this.handleDeliveryFee}
                  disabled={!isEditable}
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                >
                  Manage Delivery Fee
                </button>
              </div> */}
            </div>
          </div>
          <Card body className="mt-2">
            <p className="font-weight-bold">Brands</p>
            <DataTable
              data={filteredBrands || []}
              columns={this.brandColumn}
              defaultSortField="status"
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noHeader={searchAppliedBrand ? false : true}
              title={
                searchAppliedBrand ? (
                  <p className="table-header">
                    {"Found " + noOfFilteredBrands + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.isBrandViewModalVisible}
            onHide={this.hideBrandViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Brands: </span>
                  {supplement?.internalName || ""}
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSaveBrands}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_supplements" justify>
                <Tab
                  eventKey="key_form_basic_information"
                  title="Basic Information"
                >
                  {brandBasicInformation}
                </Tab>
                <Tab eventKey="key_seo_form" title="SEO Marketing">
                  {brandSeoForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={this.state.isDeliveryFeeViewModalVisible}
            onHide={this.hideDeliveryFeeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {deliveryFee.id ? "Edit Delivery Fee" : "Add Delivery Fee"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{DeliveryForm}</Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary rounded-pill px-4 py-2"
                onClick={this.hideDeliveryFeeModal}
              >
                Cancel
              </button>
              <button
                onClick={this.handleSubmit}
                className="btn btn-lg btn-success rounded-pill px-4 py-2"
              >
                {deliveryFee.id ? "Add" : "Save"}
              </button>
            </Modal.Footer>
          </Modal>
        </div>



        {/* this is original section  */}
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>
              <div className="action-item">
                <button
                  onClick={this.handleAddNewSupplement}
                  disabled={!isEditable}
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
              <div className="action-item">
                <button
                  onClick={this.handleDeliveryFee}
                  disabled={!isEditable}
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                >
                  Manage Delivery Fee
                </button>
              </div>
            </div>
          </div>
          <Card body className="mt-2">
            <p className="font-weight-bold">Supplements</p>
            <DataTable
              data={filteredSupplements || []}
              columns={this.columns}
              defaultSortField="status"
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + filteredSupplements.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.isSupplementViewModalVisible}
            onHide={this.hideSupplementViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Supplement: </span>
                  {supplement?.internalName || ""}
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSaveSupplement}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_supplements" justify>
                <Tab
                  eventKey="key_form_basic_information"
                  title="Basic Information"
                >
                  {formBasicInformation}
                </Tab>
                <Tab eventKey="key_form_descriptions" title="Descriptions">
                  {formDescriptions}
                </Tab>
                <Tab eventKey="key_form_pricing" title="Pricing">
                  {formPricing}
                </Tab>
                <Tab eventKey="key_seo_form" title="SEO Marketing">
                  {formSeoMarketing}
                </Tab>
                <Tab eventKey="key_form_faq" title="FAQ">
                  {formFaq}
                </Tab>
                <Tab eventKey="key_form_unicommerce" title="Uni-Commerce">
                  {formUniCommerce}
                </Tab>
                <Tab
                  eventKey="key_search_engine"
                  title="Search Engine"
                >

                  {formSearchEngine}
                </Tab>
                <Tab
                  eventKey="key_routine"
                  title="Routine"
                >

                  {formRoutine}
                </Tab>
                {!isNewSupplement && <Tab
                  eventKey="key_suplementOrders"
                  title="Orders">
                  {formSupplementOrders}

                </Tab>}
                <Tab   eventKey="key_frequently_bought"
                  title="Frequently Bought">
                  {formFrequentlyBought}

                </Tab>
                {this.state.pageIdForLogs!=null? 
                  <Tab eventKey="logs-supplement" title="Logs" >
                    <Logs pageId={this.state.pageIdForLogs} pageType="SUPPLEMENT" />
                  </Tab>
                :""}
              </Tabs>
            </Modal.Body>
          </Modal>
          <Modal
            size="lg"
            show={this.state.isDeliveryFeeViewModalVisible}
            onHide={this.hideDeliveryFeeModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {deliveryFee.id ? "Edit Delivery Fee" : "Add Delivery Fee"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{DeliveryForm}</Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary rounded-pill px-4 py-2"
                onClick={this.hideDeliveryFeeModal}
              >
                Cancel
              </button>
              <button
                onClick={this.handleSubmit}
                className="btn btn-lg btn-success rounded-pill px-4 py-2"
              >
                {deliveryFee.id ? "Add" : "Save"}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Supplements);
