import React, { useEffect, useState } from "react";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { connect } from "react-redux";
import OrdersCards from "./OrdersCards";
import { fetchProfessionals } from "./Services";
import moment from "moment";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import ManageProfessionals from "./ManageProfessionals";
import SearchOrders from "./Search";
import FilterOrders from "./FilterOrders";
import "./tabShow.scss";
import ProfileDetails from "./ProfileDetails";
import { wrap } from "lodash";
import UpcomingCards from "./UpcomingCards";
import { getUserId } from 'services/UserService';
import Notification from "pages/HomecareMwebPortal/Notification";
import UpcomingFilterOrders from "./UpcomingFilterOrders";
import CustomLoader from "components/CustomLoader/CustomLoader";
import {  trackBookingsSearchKeyword,trackAcceptingOrderClick, trackDenyingOrderClick  } from "analytics";

export default function TabsShow(props){
      const{selectedMainTab,setSelectedMainTab,homecareServiceId,orders,setOrders,user,searchOrders,setSearchOrders,setClientDetail,setIsShowOrder,setIsShowClient,setSelectedStartDate,setSelectedEndDate,
      selectedStartDate,selectedEndDate,selectedTab,setSelectedTab,setProfessionals,professionals,
      isProfessionalFormVisible, setIsProfessionalFormVisible, professionalEdit,isNewProfessional,setIsNewProfessional ,setProfessionalEdit, upcomingBookings,setUpcomingBookings}=props;

    const[startDate,setStartdate]=useState("");
    const[endDate,setEndDate]=useState("");
    
    const BASE_URL_PROFESSIONALS = "/home-care-professional";
    const BASE_URL_BOOKINGS = "/list-home-care-order-details/homecare";
    const BASE_URL_UPCOMING_BOOKINGS = "/homecare-waiting-broadcast/"
      const today=moment().format("YYYY-MM-DD");
      const tomorrow = moment().add(1, 'day').format('YYYY-MM-DD');
      const weekEndDate= moment().add(6, 'day').format('YYYY-MM-DD');
     const [message, setMessage] = useState("");
     const [isError, setIsError] = useState(false);
     const [isLoading, setIsLoading] = useState(false)
     const [searchFlag, setSearchFlag] = useState(false);
     const [searchValue, setSearchValue] = useState("");
useEffect(()=>{
  if(selectedMainTab ==="key_upcoming_orders_form"){
    const today=moment().format("YYYY-MM-DD");
    getUpcomingBookings(today,today)
  }
  else{
    const today=moment().format("YYYY-MM-DD");
    getOrders(today,today);
  }
  setSelectedTab("key_order_today")
},[selectedMainTab])
 
useEffect(()=>{
  
  if(searchFlag){
    
     if(selectedMainTab ==="key_upcoming_orders_form"){
   const newValue =searchValue&&  searchValue.slice(0).trim().toLowerCase();
    const filter= upcomingBookings && upcomingBookings.filter((order)=>{
      const {packageName, orderId}= order || {}
    return(
      (packageName && packageName.toLowerCase().indexOf(newValue) !== -1) ||
      (orderId && orderId.toString() === newValue) 
    )
    })
     setSearchOrders(filter)
     }
     else if(selectedMainTab==="key_order_form"){
      const newValue =searchValue&&  searchValue.slice(0).trim().toLowerCase();
      const filter= orders && orders.filter((order)=>{
        const {package_name, user_name, id}= order || {}
      return(
        (package_name && package_name.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)  || 
        (user_name && user_name.toLowerCase().indexOf(newValue) !== -1)
      )
      })
       setSearchOrders(filter)
     }
     else if (selectedMainTab==="key_manage_professional"){
      
     }
  }
 
},[searchFlag, searchValue,selectedMainTab, selectedTab])
  const ProfessionForm=()=>{ 
     return(selectedMainTab=="key_manage_professional"?<>{Object.keys(user).length>0 ?
     <ManageProfessionals homecareServiceId={user.user?.homecare_service_id} setIsShowOrder={setIsShowOrder} isProfessionalFormVisible={isProfessionalFormVisible} setIsProfessionalFormVisible={setIsProfessionalFormVisible} professionalEdit={professionalEdit} setProfessionalEdit={setProfessionalEdit} isNewProfessional={isNewProfessional} setIsNewProfessional ={setIsNewProfessional} searchOrders={searchOrders} searchFlag={searchFlag} searchValue={searchValue}/>:<></>}
     </>:<></>)}
     const ProfileForm=(<>
    {Object.keys(user).length>0? <ProfileDetails user={user} />:<></>}
     </>)

  const handleAccept = async (order) => {
    
    const { selectedTab, item } = order || {};
    const loginUserId= getUserId();
    const deviceId= sessionStorage.getItem("device_id") ??""
    const userDetails = user.user ??{}
    const trackingData={
      "timeLeft":item.timer ?? "00:00:00",
      "city":item.city,
      "country":item.country,
      "packageName":item.packageName,
       "homecareId":userDetails?.homecare_service_id??"",
       "deviceId":deviceId,
       "homecare":userDetails?.email??"",
       "homecareName":userDetails?.username??"",
    }
   trackAcceptingOrderClick(trackingData)
    const payload = {
      broadcastBasketId: item.broadcastBasketId,
      homecareId: item.homecareId,
      orderId: item.orderId,
      isAccepted: true,
      isRejected: false,
      isWaiting: false,
      homecareLoginId:parseInt(loginUserId)
    };
    try{
      const url = `broadcast-basket-homecare-response`;
      const repsponse = await PhoenixAPI.patch(url, payload);
      setIsError(false);
      setMessage("Order Accepted Successfully")
      if (selectedTab == "key_order_today") {
      setStartdate(today);
      setSelectedStartDate(today);
      setSelectedEndDate(today);
      setEndDate(today);
      getUpcomingBookings(today, today);
    } else if (selectedTab == "key_order_tomorrow") {
      setStartdate(tomorrow);
      setEndDate(tomorrow);
      setSelectedStartDate(tomorrow);
      setSelectedEndDate(tomorrow);
      getUpcomingBookings(tomorrow, tomorrow);
    } else if (selectedTab == "key_order_thisweek") {
      setStartdate(today);
      setEndDate(weekEndDate);
      setSelectedStartDate(today);
      setSelectedEndDate(weekEndDate);
      getUpcomingBookings(today, weekEndDate);
    } else if (selectedTab == "key_order_all") {
      setSelectedStartDate("");
      setSelectedEndDate("");
      getUpcomingBookings("", "");
    }
    }
    catch(error){
      setIsError(true);
      setMessage("Error occurred in Accepting Order", error.message)
      console.log("Error occurred in Accepting Order", error.message)
    }
    
  };
  const handleReject = async (order) => {
   
    const { selectedTab, item } = order || {};
    const loginUserId= getUserId();
    const deviceId= sessionStorage.getItem("device_id") ??"";
    const userDetails = user.user ??{}
    const trackingData={
      "timeLeft":item.timer ?? "00:00:00",
      "city":item.city,
      "country":item.country,
      "packageName":item.packageName,
       "homecareId":userDetails?.homecare_service_id??"",
       "deviceId":deviceId,
       "homecare":userDetails?.email??"",
       "homecareName":userDetails?.username??"",
    }
    trackDenyingOrderClick(trackingData)
    const payload = {
      broadcastBasketId: item.broadcastBasketId,
      homecareId: item.homecareId,
      orderId: item.orderId,
      isAccepted: false,
      isRejected: true,
      isWaiting: false,
      homecareLoginId:parseInt(loginUserId)
    };
    try{
      const url = `broadcast-basket-homecare-response`;
      const response = await PhoenixAPI.patch(url, payload);
      console.log("Response", response)
      setIsError(false);
      setMessage("Order Rejected Successfully")
       if (selectedTab == "key_order_today") {
      setStartdate(today);
      setSelectedStartDate(today);
      setSelectedEndDate(today);
      setEndDate(today);
      getUpcomingBookings(today, today);
    } else if (selectedTab == "key_order_tomorrow") {
      setStartdate(tomorrow);
      setEndDate(tomorrow);
      setSelectedStartDate(tomorrow);
      setSelectedEndDate(tomorrow);
      getUpcomingBookings(tomorrow, tomorrow);
    } else if (selectedTab == "key_order_thisweek") {
      setStartdate(today);
      setEndDate(weekEndDate);
      setSelectedStartDate(today);
      setSelectedEndDate(weekEndDate);
      getUpcomingBookings(today, weekEndDate);
    } else if (selectedTab == "key_order_all") {
      setSelectedStartDate("");
      setSelectedEndDate("");
      getUpcomingBookings("", "");
    }
    }
    catch(error){
      setIsError(true);
      setMessage("Error occurred in Saving Response", error.message)
      console.log(("Error occurred in Saving Response", error.message))
    }
   
  };

  const UpcomingOrders = ({searchFlag=false, searchValue=""}) => {
   
    const TodayOrdersForm = (homecareServiceId) => {
      setStartdate(today);
      setEndDate(today);
      return (
        <>
          {" "}
          {Object.keys(user).length > 0 &&
          homecareServiceId &&
          selectedTab == "key_order_today" ? (
            <>
              <UpcomingCards
                startDate={today}
                endDate={today}
                homecareServiceId={homecareServiceId}
                orders={upcomingBookings}
                setOrders={setUpcomingBookings}
                searchOrders={searchOrders}
                searchFlag={searchFlag}
                searchValue={searchValue}
                selectedTab={"key_order_today"}
                handleAcceptOrder={(item) => handleAccept(item)}
                handleRejectOrder={(item) => handleReject(item)}
                user={user}
              />
            </>
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };

    const TomorrowOrderForm = (homecareServiceId) => {
      setStartdate(tomorrow);
      setEndDate(tomorrow);
      return (
        <>
          {" "}
          {Object.keys(user).length > 0 &&
          homecareServiceId &&
          selectedTab == "key_order_tomorrow" ? (
            <UpcomingCards
              startDate={tomorrow}
              endDate={tomorrow}
              homecareServiceId={homecareServiceId}
              orders={upcomingBookings}
              setOrders={setUpcomingBookings}
              searchOrders={searchOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              user={user}
              selectedTab={"key_order_tomorrow"}
              handleAcceptOrder={(item) => handleAccept(item)}
              handleRejectOrder={(item) => handleReject(item)}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };

    const WeekForm = (homecareServiceId) => {
      setStartdate(today);
      setEndDate(weekEndDate);

      return (
        <>
          {Object.keys(user).length > 0 &&
          homecareServiceId &&
          selectedTab == "key_order_thisweek" ? (
            <UpcomingCards
              startDate={today}
              endDate={weekEndDate}
              homecareServiceId={homecareServiceId}
              searchOrders={searchOrders}
              orders={upcomingBookings}
              setOrders={setUpcomingBookings}
              setSearchOrders={setSearchOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              user={user}
              selectedtab={"key_order_thisweek"}
              handleAcceptOrder={(item) => handleAccept(item)}
              handleRejectOrder={(item) => handleReject(item)}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };

    const AllOrdersForm = (homecareServiceId) => {
      return (
        <>
          {Object.keys(user).length > 0 && selectedTab == "key_order_all" ? (
            <UpcomingCards
              homecareServiceId={homecareServiceId}
              searchOrders={searchOrders}
              setSearchOrders={setSearchOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              orders={upcomingBookings}
              setOrders={setUpcomingBookings}
              user={user}
              selectedTab={"key_order_all"}
              handleAcceptOrder={(item) => handleAccept(item)}
              handleRejectOrder={(item) => handleReject(item)}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };

    return (
      <div className="orders-form-tabs">
        {user && Object.keys(user).length > 0 && (
          <>
            <Tabs
              id="orders_days"
              defaultActiveKey="key_order_today"
              activeKey={selectedTab}
              style={{
                backgroundColor: "#ffffff",
                width: "90%",
                display: "flex",
                padding: "0px",
                width: "90%",
                margin: "0 auto",
                justifyContent: "space-between",
                zIndex: 1000,
              }}
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="key_order_today"
                title={<div className="book-all-tabs">Today</div>}
              >
                {Object.keys(user).length > 0 &&
                selectedTab == "key_order_today" ? (
                  <TodayOrdersForm
                    homecareServiceId={user.user?.homecare_service_id}
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                style={{ fontSize: "12px" }}
                eventKey="key_order_tomorrow"
                title={<div className="book-all-tabs">Tomorrow</div>}
              >
                {Object.keys(user).length > 0 &&
                selectedTab == "key_order_tomorrow" ? (
                  <TomorrowOrderForm
                    homecareServiceId={user.user?.homecare_service_id}
                   
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                eventKey="key_order_thisweek"
                title={<div className="book-all-tabs">This week</div>}
              >
                {Object.keys(user).length > 0 &&
                selectedTab == "key_order_thisweek" ? (
                  <WeekForm
                    homecareServiceId={user.user?.homecare_service_id}
                  
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                eventKey="key_order_all"
                title={<div className="book-all-tabs">All</div>}
              >
                {Object.keys(user).length > 0 &&
                selectedTab == "key_order_all" ? (
                  <AllOrdersForm
                    homecareServiceId={user.user?.homecare_service_id}
                    
                  />
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    );
  };

 



  const OrdersForm = ({searchFlag=false, searchValue=""}) => {
    const TodayOrdersForm = (homecareServiceId) => {
      setStartdate(today);
      setEndDate(today);
      return (
        <div>
         {" "}
          {Object.keys(user).length > 0 &&
            homecareServiceId &&
            selectedTab == "key_order_today" ? (
            <>
              <OrdersCards
                startDate={today}
                endDate={today}
                homecareServiceId={homecareServiceId}
                setIsShowClient={setIsShowClient}
                setIsShowOrder={setIsShowOrder}
                setClientDetail={setClientDetail}
                setSearchOrders={setSearchOrders}
                searchOrders={searchOrders}
                setOrders={setOrders}
                searchFlag={searchFlag}
                searchValue={searchValue}
                orders={orders}
                user={user}
                professionals={professionals}
              />
            </>
          ) : (
            <>"No Orders Available"</>
          )}
        </div>
      );
    };

    const TomorrowOrderForm = (homecareServiceId) => {
      setStartdate(tomorrow);
      setEndDate(tomorrow);
      return (
        <>
          {" "}
          {Object.keys(user).length > 0 &&
            homecareServiceId &&
            selectedTab == "key_order_tomorrow" ? (
            <OrdersCards
              startDate={tomorrow}
              endDate={tomorrow}
              homecareServiceId={homecareServiceId}
              setIsShowClient={setIsShowClient}
              setIsShowOrder={setIsShowOrder}
              setClientDetail={setClientDetail}
              setSearchOrders={setSearchOrders}
              orders={orders}
              searchOrders={searchOrders}
              setOrders={setOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              user={user}
              professionals={professionals}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };
    const WeekForm = (homecareServiceId) => {
      setStartdate(today);
      setEndDate(weekEndDate);
      return (
        <>
          {Object.keys(user).length > 0 &&
            homecareServiceId &&
            selectedTab == "key_order_thisweek" ? (
            <OrdersCards
              startDate={today}
              endDate={weekEndDate}
              homecareServiceId={homecareServiceId}
              orders={orders}
              setIsShowClient={setIsShowClient}
              setIsShowOrder={setIsShowOrder}
              setClientDetail={setClientDetail}
              searchOrders={searchOrders}
              setSearchOrders={setSearchOrders}
              setOrders={setOrders}
              searchFlag={searchFlag}
              searchValue={searchValue}
              user={user}
              professionals={professionals}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    }
    const AllOrdersForm = (homecareServiceId) => {
      return (
        <>
          {Object.keys(user).length > 0 && selectedTab == "key_order_all" ? (
            <OrdersCards
              homecareServiceId={homecareServiceId}
              setIsShowClient={setIsShowClient}
              setIsShowOrder={setIsShowOrder}
              setClientDetail={setClientDetail}
              setSearchOrders={setSearchOrders}
              searchOrders={searchOrders}
              searchFlag={searchFlag}
              setOrders={setOrders}
              searchValue={searchValue}
              orders={orders}
              user={user}
              professionals={professionals}
            />
          ) : (
            <>"No Orders Available"</>
          )}
        </>
      );
    };
    return (
      <>
        {user && Object.keys(user).length > 0 && (
          <div className="orders-form-tabs">
            <Tabs
              id="orders_days"
              defaultActiveKey="key_order_today"
              activeKey={selectedTab}
              style={{
                backgroundColor: "#ffffff",
                width: "90%",
                display: "flex",
                padding: "0px",
                width: "90%",
                margin: "0 auto",
                justifyContent: "space-between",
               
              }}
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey="key_order_today"
                title={<div className="book-all-tabs">Today</div>}
              >
                {Object.keys(user).length > 0 &&
                  selectedTab == "key_order_today" ? (
                  <TodayOrdersForm
                    homecareServiceId={user.user?.homecare_service_id}
                    searchFlag={searchFlag} searchValue={searchValue}
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                style={{ fontSize: "12px" }}
                eventKey="key_order_tomorrow"
                title={<div className="book-all-tabs">Tomorrow</div>}
              >
                {Object.keys(user).length > 0 &&
                  selectedTab == "key_order_tomorrow" ? (
                  <TomorrowOrderForm
                    homecareServiceId={user.user?.homecare_service_id}
                    searchFlag={searchFlag} searchValue={searchValue}
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                eventKey="key_order_thisweek"
                title={<div className="book-all-tabs">This week</div>}
              >
                {Object.keys(user).length > 0 &&
                  selectedTab == "key_order_thisweek" ? (
                  <WeekForm
                    homecareServiceId={user.user?.homecare_service_id}
                  />
                ) : (
                  <></>
                )}
              </Tab>
              <Tab
                eventKey="key_order_all"
                title={<div className="book-all-tabs">All</div>}
              >
                {Object.keys(user).length > 0 &&
                  selectedTab == "key_order_all" ? (
                  <AllOrdersForm
                    homecareServiceId={user.user?.homecare_service_id}
                  />
                ) : (
                  <></>
                )}
              </Tab>
            </Tabs>
          </div>
        )}
      </>
    );

  };


  const handleTabSelect = (key) => {
  
    if(selectedMainTab==="key_upcoming_orders_form"){
    if (key == "key_order_today") {

      setStartdate(today);
      setSelectedStartDate(today);
      setSelectedEndDate(today);
      setEndDate(today);

       getUpcomingBookings(today,today);
      }
    else  if(key=="key_order_tomorrow"){
        setStartdate(tomorrow);
        setEndDate(tomorrow);
        setSelectedStartDate(tomorrow);
        setSelectedEndDate(tomorrow);
        getUpcomingBookings(tomorrow,tomorrow);
        }

      else  if(key=="key_order_thisweek"){
          setStartdate(today);
          setEndDate(weekEndDate);
          setSelectedStartDate(today);
          setSelectedEndDate(weekEndDate);
          getUpcomingBookings(today,weekEndDate);
          }
         else if(key=="key_order_all"){
            setSelectedStartDate("");
            setSelectedEndDate("");
            getUpcomingBookings("","");
            }              
   }
  else if(key=="key_order_today"){
  setStartdate(today);
  setSelectedStartDate(today);
  setSelectedEndDate(today);
  setEndDate(today);
   getOrders(today,today);
  }
  else if(key=="key_order_tomorrow"){
  setStartdate(tomorrow);
  setEndDate(tomorrow);
  setSelectedStartDate(tomorrow);
  setSelectedEndDate(tomorrow);
  getOrders(tomorrow,tomorrow);
  }
  else if(key=="key_order_thisweek"){
  setStartdate(today);
  setEndDate(weekEndDate);
  setSelectedStartDate(today);
  setSelectedEndDate(weekEndDate);
  getOrders(today,weekEndDate);
  }
  else if(key=="key_order_all"){
  setSelectedStartDate("");
  setSelectedEndDate("");
  getOrders("","");
  }
  localStorage.setItem("selectedTab",key)
  setSelectedTab(key);
}

  const handleMainTab = (key) => {

    setSelectedMainTab(key);
  };

const getOrders=async(startDate,endDate)=>{

  const url = `${BASE_URL_BOOKINGS}/${homecareServiceId}`;
  const params = {};

    if (startDate) params.fromDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) params.toDate = moment(endDate).format("YYYY-MM-DD");
     try{
      setIsLoading(true)
      const { data: bookings = [] } = await PhoenixAPI.get(url, { params });
      setOrders([...bookings]);
      // console.log("bookings are", bookings)
     }
     catch(error){
       console.log("Error occurred in fetcching orders", error.message)
     }
     finally{
      setIsLoading(false)
     }
  ;

    /*bookings.forEach((booking) => {
      const { lab_1_id, lab_2_id, nurse_appointment_status } = booking;
      if (!nurse_appointment_status) {
        booking.nurse_appointment_status = bookingStatus.CREATED.value;
      }
       
      const lab1 = findLabById(lab_1_id);
      if (lab1) {
        const { name, contact_number, location } = lab1;
        booking.lab_1_name = [name, contact_number, location]
          .filter((it) => it ?? false)
          .join(", ");
      }

      const lab2 = findLabById(lab_2_id);
      if (lab2) {
        const { name, contact_number, location } = lab2;
        booking.lab_2_name = [name, contact_number, location]
          .filter((it) => it ?? false)
          .join(", ");
      }
    });*/
  };



const getUpcomingBookings=async(startDate,endDate)=>{
  const url = `${BASE_URL_UPCOMING_BOOKINGS}/${homecareServiceId}`;
  const params = {};

  if (startDate)
  params.fromDate = moment(startDate).format("YYYY-MM-DD");
if (endDate)
  params.toDate = moment(endDate).format("YYYY-MM-DD");
  try{
    setIsLoading(true)
    const { data: bookings = [] } =await PhoenixAPI.get(url,{params});
    setUpcomingBookings([...bookings]);
    // console.log("upcoming bookings are",bookings);
  }
  catch(error){
    console.log("Error in fetching upcoming orders",error.message);

  }
  finally{
    setIsLoading(false)
  }
 
}


const handleSearchFlag=(item)=>{
  setSearchFlag(item);
}

const handleSearchValue=(value)=>{
  const userDetails= user.user ?? {}
  const deviceId= sessionStorage.getItem("device_id") ??"";
  
   const  trackingData={
   "searchKeyword":value,
   "homecareId":userDetails?.homecare_service_id ??"",
   "deviceId":deviceId,
   "homecare":userDetails?.email ??"",
   "homecareName":userDetails?.username??"",
    
  }
  if(user){
    trackBookingsSearchKeyword(trackingData)
  }
  
  setSearchValue(value);
}


  return (<div className={selectedMainTab == "key_order_form" ? "" : "orders-container"} >
    <SearchOrders searchFlag={(item)=>handleSearchFlag(item)} searchValue={(value)=>handleSearchValue(value)} />

    {selectedMainTab == "key_order_form" ? <FilterOrders setSelectedTab={setSelectedTab} homecareServiceId={user.user?.homecare_service_id} orders={orders} setOrders={setOrders} /> : <></>}
    {selectedMainTab =="key_upcoming_orders_form"? <UpcomingFilterOrders setSelectedTab={setSelectedTab} homecareServiceId={user.user?.homecare_service_id} orders={upcomingBookings} setOrders={setUpcomingBookings}/> : <></>}
    <div className="main-form-tabs">
    <Tabs id="tabs_bookings" activeKey={selectedMainTab} defaultActiveKey="key_order_form" onSelect={handleMainTab}
      style={{
        position: 'fixed',
        bottom: '0px',
        textAlign: 'center',
        backgroundColor: '#ffffff',
        width: '100%',
        zIndex: 1000,
        display: 'flex',
        left: '0px',
        boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
        borderTop: '1px solid #e5e5e5',
        borderBottom: '1px solid #e5e5e5',
        padding: '0px',
        justifyContent: "space-between"

      }}  >

      <Tab
        eventKey="key_upcoming_orders_form"
        title={<div style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="key_upcoming_orders_form"?"https://d25uasl7utydze.cloudfront.net/assets/upcomingorders.svg":"https://d25uasl7utydze.cloudfront.net/assets/Grey%20upcoming%20orders.svg"}`} alt="Tab Image" />  Upcoming</div>}

      >
        <UpcomingOrders searchFlag={searchFlag} searchValue={searchValue}/>
      </Tab>
      <Tab
        eventKey="key_order_form"
        title={<div style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="key_order_form"?"https://d25uasl7utydze.cloudfront.net/assets/Group%20627730.svg":"https://d25uasl7utydze.cloudfront.net/assets/Grey%20New%20orders.svg"}`} alt="Tab Image" /> Bookings</div>}

      >
        <OrdersForm searchFlag={searchFlag} searchValue={searchValue}/>
      </Tab>
      <Tab
        eventKey="key_manage_professional"
        title={<div style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="key_manage_professional"?"https://d25uasl7utydze.cloudfront.net/assets/Blue%20community.svg":"https://d25uasl7utydze.cloudfront.net/assets/community.svg"}`} alt="Tab Image"/>Professionals</div>}
        className="tab-name"
      ><ProfessionForm /></Tab>
      <Tab
        eventKey="key_manage_profile"
        title={<div style={{ fontSize: "12px" }}><img src={`${selectedMainTab==="key_manage_profile"?"https://d25uasl7utydze.cloudfront.net/assets/Blue%20profile.svg":"https://d25uasl7utydze.cloudfront.net/assets/mobile_profile.svg"}`} alt="Tab Image" />Profile</div>}

        className="tab-name"
      >{ProfileForm}</Tab>
    </Tabs>
    </div>
   
    {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
    <CustomLoader show={isLoading}/>
    </div>)

}

