import React, { Component } from "react"
import Search from "components/Search/Search";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";


const STATUS_OPTIONS = [
    {
        key: true,
        value: true,
        text: "Active",
    },
    {
        key: false,
        value: false,
        text: "Inactive",
    },
]
class CustomTestCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            category: {},
            errors: {},
            editables: {},
            showModal: false,
            loadError: null,
            searchTerm: null,
      
        };

        this.columns = [
            {
                name: "ID",
                selector: "id",
                width: "100px",
                sortable: true,
            },
            {
                name: "Name",
                selector: "name",
                wrap: true,
                sortable: true,
            }, 
            {
                name: "Status",
                selector: "is_active",
                maxWidth: "150px",
                center: true,
                cell: (p) => <Status isActive={p.isActive} />,
                sortable: true,
            },
            {
                name: "View",
                center: true,
                cell: (p) => this.renderViewButton(p.id),
            },
        ]
    }

    renderViewButton = (id) => (
        <div>
            <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={() => {
                    this.handleView(id);
                }}
            >
                View
            </button>
        </div>
    );

    componentDidMount() {
      this.fetchCustomTestCategories();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedCountry !== nextProps.selectedCountry) {
            this.setState({
                countries: nextProps.countries,
                selectedCountry: nextProps.selectedCountry
            })
        }
    }

    fetchCustomTestCategories=async()=>{
     try{
        this.showLoading();
        const {data:categories=[]}= await  PhoenixAPI.get("custom-addon/category");
        this.setState({ categories });
     }
     catch(error){
       console.log("Error in fetching custom test categories")
       this.showErrorNotification("Error in fetching custom test categories", error.message)
     }
     finally{
        this.hideLoading();
     }
    }

    handleChange = ({ currentTarget: input }) => {
        const category = { ...this.state.category };
        category[input.name] = input.value;
        this.setState({ category });
    };

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : "",
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                searchApplied: false,
            });
    };

    handleAddNew = () => {
       
        this.setState({  isNewCategory: true }, () =>
            this.showModal()
        );
    };

    handleView = (categoryId) => {
        const categories = this.state.categories || [];
        const category = categories.find((it) => it.id === categoryId);
        if (!category) {
            console.log(`Other Item with id ${categoryId} is not found!`);
            return;
          }
          this.setState(
            { category,  isNewCategory: false },
            () => this.showModal()
          );
    };

    showModal = () => {
        const { selectedCountry, isNewCategory} = this.state;
        if(isNewCategory) {
            const category = {
                countryId: selectedCountry?.id,
               
                isActive: false,
              
              };

              this.setState({
                category,
               
                showModal: true
              });
        }
        else {
            this.setState({ showModal: true });
        }
       
    };

    hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            editables: {},
            category: {},
            isNewCategory: false,
       
        });
    };

   

    findCountry = (id) => {
        const countries = this.state.countries || [];
        return countries.find((country) => country.id === id);
    };

    updateCategory=async(category)=>{
       const payload={
            "id": category.id,
            "name": category.name,
            "isActive": category.isActive,
            "countryId": category.countryId,
       }
        try{
            this.showLoading();
            const response= await  PhoenixAPI.patch(`custom-addon/category/${category.id}`,payload);
            return response
        }
        catch(error){
            console.log("Error in updating custom test category")
            this.showErrorNotification("Error in updating custom test category", error.message)
        }
        finally{
            this.hideLoading();
        }
    }

    saveCategory=async(category)=>{
       
        try{
            this.showLoading();
            const response= await  PhoenixAPI.post(`custom-addon/category`, category);
            return response
        }
        catch(error){
            console.log("Error in updating custom test category")
            this.showErrorNotification("Error in updating custom test category", error.message)
        }
        finally{
            this.hideLoading();
        }  
    }

    handleSave = async () => {
        if (!this.state.selectedCountry) {
            this.showErrorNotification("Please select a country!");
            return;
        }
        const errors = this.validate();
        console.log("errors:", errors)
        this.setState({ errors: errors || {} });
        if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            return;
        }
        const { category } = this.state;
        const isValidId =
            category.id !== null && category.id !== undefined;
            const response = isValidId
            ? await this.updateCategory(category)
            : await this.saveCategory(category);
        try {
           
            this.hideLoading();
            this.hideModal();
            this.showSuccessNotification(
                isValidId ? "Updated Successfully." : "Created Successfully."
            );
            this.fetchCustomTestCategories();
        }
        catch (error) {
            this.hideLoading();
            this.showErrorNotification("Some error occurred: " + error.message);
            console.error("Some error occurred", error.message);
        }
    };

    validate = () => {
        const {category={}}=this.state; 
        const errors = {};
        const {name}= category;
        if (!name || name.trim() === "")
            errors.name = "Category Name cannot be blank";
        

        return Object.keys(errors).length === 0 ? null : errors;
    }


  

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) => {
        const isEditable = this.props.isEditable ?? true;
        return isEditable && !this.state.isNewCategory ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );
    }

    getFilteredbanners = () => {
        const { categories, selectedCountry } = this.state;
        const categoriesForCurrentCountry =
            categories && selectedCountry
                ? categories.filter((p) => p.countryId === selectedCountry.id)
                : [];
        return this.getSearchedCategories(categoriesForCurrentCountry);
    };

    getSearchedCategories = (categories) => {
        const { searchApplied, searchTerm } = this.state;
        if (!searchApplied || !searchTerm) return categories;
        const newValue = searchTerm.slice(0).trim().toLowerCase();
        return categories.filter((category) => {
            const { id, name, } = category;
            return (
                (name && name.toLowerCase().indexOf(newValue) !== -1) ||
               
                (id && id.toString() === newValue)
               
            );
        });
    };

    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });


 
   

   

    render() {
        const { searchTerm, editables, errors, isNewCategory, category, countries, selectedCountry } = this.state;
        const finalList = this.getFilteredbanners();
        const isEditable = this.props.isEditable ?? true;
        const detailsForm = (
            <div className="container mt-4">
           <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Category Name *</label>
            <div className="input-group">
              <input
                value={category?.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                className={
                  "form-control py-2" +
                  (errors.name ? " has-error" : "")
                }
                disabled={!isNewCategory && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={category?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    category: {
                      ...category,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
            </div>
        );

        return (
            <>
                <div className="manage-package">
                    <div className="page-header">
                        <div className="actions">
                            <div className="action-item">
                                <Search
                                    searchExpanded={true}
                                    searchTerm={searchTerm}
                                    handleSearch={this.handleSearch}
                                    clearSearch={this.handleSearchClear}
                                    handleSearchExit={this.handleSearchExit}
                                    ref={(input) => {
                                        this.search = input;
                                    }}
                                />
                            </div>
                            <div className="action-item">
                                <button
                                    style={{ backgroundColor: "#8FD14F" }}
                                    className="btn btn-md new-user"
                                    onClick={this.handleAddNew}
                                    disabled={!isEditable}
                                >
                                    <i className="fa fa-plus-circle"></i>Add New
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card body>
                        <h3 className="p-head">Addons Categories</h3>
                        <DataTable
                            data={finalList}
                            columns={this.columns}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                        
                            highlightOnHover
                         
                            responsive
                            noHeader={this.state.searchApplied ? false : true}
                            title={
                                this.state.searchApplied ? (
                                    <p className="table-header">
                                        {"Found " + finalList.length + " results"}
                                    </p>
                                ) : (
                                    ""
                                )
                            }
                            noDataComponent={
                                this.state.loadError ? (
                                    <AlertBox
                                        message={this.state.loadError}
                                        error={true}
                                    ></AlertBox>
                                ) : (
                                    <AlertBox message="There's nothing here."></AlertBox>
                                )
                            }
                        />
                    </Card>

                    <Modal
                        size="lg"
                        show={this.state.showModal}
                        onHide={this.hideModal}
                        className="user-data-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            <div className="user-title"></div>
                            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                        {detailsForm}
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTestCategories)