import React, { Component } from "react"
import Search from "components/Search/Search";
import CountryDropdown from "components/Common/CountryDropdown";
import API from "utils/API";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
// import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select";

const BASE_URL_MOBILE_PROMOTIONS = "admin-promotional-banner/";
const BASE_URL_COUNTRIES = "countries/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
// const TARGET=
// [
//   {
//   key:"Mobile",
//   value:"Mobile",
//   text:"Mobile",
//   },
//   {
//    key:"Website",
//    value:"Website",
//    text:"Website",
//   }
// ]
class MobilePromotions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      mobilePromotions: [],
      statusOptions: [
        {
          key: true,
          value: true,
          text: "Active",
        },
        {
          key: false,
          value: false,
          text: "Inactive",
        },
      ],
      // target: [
      //   {
      //     key: "Mobile",
      //     value: "Mobile",
      //     text: "Mobile",
      //   },
      //   {
      //     key: "Website",
      //     value: "Website",
      //     text: "Website",
      //   }
      // ],
      rankOptions: [],
      mobilePromotion: {},
      errors: {},
      editables: {},
      showModal: false,
      loadError: null,
      selectedCountry: null,
      searchTerm: null,
      minRank: 1,
      maxRank: 0,
      imgSrc_english: "",
      imgSrc_arabic: "",
      valeoPartners:[],
      valeoPartnerOptions:[],

    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Link",
        selector: "link",
        wrap: true,
        sortable: true
      },
      {
        name: "Rank",
        selector: "position_value",
        width: "100px",
        sortable: true,
        center: true
      },

      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.is_active} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
      // {
      //   name: "Target",
      //   selector: "target",
      //   center: true,
      //   wrap: true,
      //   format: (row) => (
      //     <select
      //       className="form-control"

      //       style={{ width: '130px' }}
      //       onChange={(e) => this.assignTarget(e.target.value, row)}
      //     >

      //       {this.state.target.map((status, index) => (
      //         <option key={index} value={status.value}>
      //           {status.text}
      //         </option>
      //       ))
      //       }

      //     </select>
      //   )

      // }
    ]

    this.selectPromotionalEnglishImage = this.selectPromotionalEnglishImage.bind(this);
    this.selectPromotionalArabicImage = this.selectPromotionalArabicImage.bind(this)
  }
  // assignTarget = (target, row) => {
  //   row = { ...row, target }
  //   return row
  // }
  // renderViewTarget=(id)=>(
  //   <div>
  //   {/* <Dropdown

  //         fluid
  //         selection
  //        className="targetFeild"
  //        defaultValue={this.state.target[0].value}
  //         options={
  //           this.state.target
  //         }
  //         onChange={(event, value) => {
  //           this.setState({ target: value.value });
  //         }}
  //       /> */}

  //       </div>
  // )

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleView(id);
        }}
      >
        View
      </button>
    </div>
  );

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchMobilePromotions()
    this.fetchValeoPartners()
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.MOBILE_PROMOTIONS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ accessDenied, isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(BASE_URL_COUNTRIES);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      });
    }
    catch (error) {
      console.error(
        "Error on fetching countries from this URL: ",
        BASE_URL_COUNTRIES
      );
      console.error("Error on fetching countries", error);
    }
  };

  fetchMobilePromotions = async () => {
    try {
      this.showLoading();
      const response = await API.get(BASE_URL_MOBILE_PROMOTIONS)

      const mobilePromotions = response.data || []

      const ranks = mobilePromotions.filter((it) => it.position_value).map((it) => it.position_value);
      const minRank = Math.min(...ranks) || 0;
      const maxRank = Math.max(...ranks) || 0;

      this.setState({ mobilePromotions, minRank, maxRank })
      this.hideLoading()
    }
    catch (error) {
      console.error(
        "Error on fetching mobile promotions from this URL: ",
        BASE_URL_MOBILE_PROMOTIONS
      );
      console.error("Error on fetching mobile promotions", error);

      this.hideLoading();
      this.showErrorNotification(
        "Error on loading mobile promotions: ",
        error.message
      );
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
    }
  }

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
        const partnerOptions=partners.map((it)=>{
          return {key:it.id, value:it.id, label:it.name}
        })
        partnerOptions.push({key:"select", value:"select", label:"Select"})
        this.setState({valeoPartners:partners,valeoPartnerOptions:partnerOptions})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const mobilePromotion = { ...this.state.mobilePromotion };
    mobilePromotion[input.name] = input.value;
    this.setState({ mobilePromotion });
  };

  handleCountrySelection = (selectedCountry) => this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  handleAddNew = () => {
    const mobilePromotion = {
      is_active: false,
      position_value: this.state.maxRank + 1,
      partner_id:null
    };

    this.setState({ mobilePromotion, isNewmobilePromotion: true }, () =>
      this.showModal()
    );
    console.log("mobilePromotion:", mobilePromotion)
  };

  handleView = (id) => {
    const mobilePromotions = this.state.mobilePromotions || [];
    const mobilePromotion = mobilePromotions.find((it) => it.id === id);

    const {valeoPartners}= this.state || {}
    const partnerId= mobilePromotion.partner_id ?? null;
    const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
    
    if (mobilePromotion) {
      const country = this.findCountry(mobilePromotion.country);
      this.setState(
        {
          isNewmobilePromotion: false,
          mobilePromotion: {
            ...mobilePromotion,
            country_name: country ? country.country_name : "",
            currency: country ? country.currency : "",
            valeoPartnerId: selectedPartner?.id || null,
            valeoPartnerName:selectedPartner?.name || "",
          },
          imgSrc_english: mobilePromotion.image,
          imgSrc_arabic: mobilePromotion.image_arabic
        },
        () => this.showModal()
      );
    }

  };

  showModal = () => {
    const rankOptions = this.createRankOptions();
    this.setState({ rankOptions, showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      mobilePromotion: {},
      isNewmobilePromotion: false,
      imgSrc_english: "",
      imgSrc_arabic: ""
    });
  };

  createRankOptions = () => {
    const { minRank, maxRank, isNewmobilePromotion } = this.state;

    if (
      minRank === null ||
      minRank === undefined ||
      minRank === "" ||
      maxRank === null ||
      maxRank === "" ||
      maxRank === undefined
    )
      return;

    const until = isNewmobilePromotion ? maxRank + 1 : maxRank;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });

    return rankOptions;
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleSave = async () => {
    if (!this.state.selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    console.log("errors:", errors)
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { mobilePromotion } = this.state;

    const isValidId =
      mobilePromotion.id !== null && mobilePromotion.id !== undefined;

    const apiOptions = isValidId
      ? this.createApiOptions(
        "patch",
        `${BASE_URL_MOBILE_PROMOTIONS}${mobilePromotion.id}/`
      )
      : this.createApiOptions("post", BASE_URL_MOBILE_PROMOTIONS);

    try {
      await API.request(apiOptions);
      this.hideLoading();
      this.hideModal();
      this.showSuccessNotification(
        isValidId ? "Updated Successfully." : "Created Successfully."
      );
      this.fetchMobilePromotions();
    } 
    catch (error) {
      console.log("Error on saving mobile promotions", error);
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  };

  validate = () => {
    const errors = {};
    const TWO_MEGA_BYTE = 2048000;
    const { name, link, link_arabic, rank, is_active, image, image_arabic } = this.state.mobilePromotion
    const { imgSrc_english, imgSrc_arabic } = this.state;

    if (!name || name.trim() === "")
      errors.name = "Name (English) is required field!";
    else if (name.length > 100)
      errors.name = "Name (English) can not take more than 100 characters!";

    if (!link || link.trim() === "")
      errors.link = "Link (English) is required field";
    else if (link.length > 500)
      errors.link = "Link (English) can not take more than 500 characters"

    if (!link_arabic || link_arabic.trim() === "")
      errors.link_arabic = "Link (Arabic) is required field";
    else if (link_arabic.length > 500)
      errors.link_arabic = "Link (Arabic) can not take more than 500 characters"

    // if(!description || description.trim()==="")
    // errors.description="Description (English) is required field"

    // else if(description.length>500)
    // errors.description="Description (English) can not take more than 500 characters"

    // if(!description_arabic || description_arabic.trim()==="")
    // errors.description_arabic="Description (Arabic) is required field"

    // else if(description_arabic.length>500)
    // errors.description_arabic="Description (Arabic) can not take more than 500 characters"

    if (!image && !imgSrc_english)
      errors.image = "Promotional Graphic (English) is required field"

    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2MB"

    if (!image_arabic && !imgSrc_arabic)
      errors.image_arabic = "Promotional Graphic (Arabic) is required field"
    else if (image_arabic && image_arabic.size && image_arabic.size > TWO_MEGA_BYTE)
      errors.image_arabic = "Image should be less than 2MB"

    //   if (rank === null || rank === undefined)
    //   errors.rank = "Rank is a required field!";

    // if (is_active === null || is_active === undefined)
    //   errors.status = "Status is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  }


  createApiOptions = (method, url) => {
    const formData = this.createFormData();
    return {
      method,
      url,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
  };

  createFormData = () => {
    const { isNewmobilePromotion, mobilePromotion, selectedCountry } = this.state;
    const { image, image_arabic } = this.state.mobilePromotion
    // const image= mobilePromotion.;
    // const image_arabic=imgSrc_arabic;
    //  console.log("line no 464:",image,image_arabic)
    const formData = new FormData();

    if (isNewmobilePromotion)
      formData.append("country", selectedCountry ? selectedCountry.id : null);

    formData.append("name", mobilePromotion.name);
    formData.append("link", mobilePromotion.link);
    formData.append("link_arabic", mobilePromotion.link_arabic);
    formData.append("description", mobilePromotion.description);
    formData.append("description_arabic", mobilePromotion.description_arabic);
    if (image && image.name) {
      formData.append("image", image, image.name);
      formData.append("image_s3", image, image.name);
    }

    if (image_arabic && image_arabic.name) {
      formData.append("image_arabic", image_arabic, image_arabic.name);
      formData.append("image_arabic_s3", image_arabic, image_arabic.name);
    }

    formData.append("position_value", mobilePromotion.position_value);
    formData.append("is_active", mobilePromotion.is_active);
    if(mobilePromotion.partner_id !== null || undefined){
      formData.append("partner_id", mobilePromotion.partner_id);
    }
 
    // formData.append("target", mobilePromotion.target)

    return formData;
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };

    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewmobilePromotion ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );


  getFilteredmobilePromotions = () => {
    const { mobilePromotions, selectedCountry } = this.state;
    const packagesForCurrentCountry =
      mobilePromotions && selectedCountry
        ? mobilePromotions.filter((p) => p.country === selectedCountry.id)
        : [];
    return this.getSearchedmobilePromotions(packagesForCurrentCountry);
  };

  getSearchedmobilePromotions = (mobilePromotions) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return mobilePromotions;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return mobilePromotions.filter((mobilePromotion) => {
      const { id, name, link, position_value } = mobilePromotion;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (link && link.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (position_value && position_value.toString() === newValue)
      );
    });
  };

  handleSelectPartner=(partnerId)=>{
  
    const {mobilePromotion, valeoPartnerOptions, valeoPartners}=this.state || {}
    if(partnerId==="select"){
      const newMobilePromotion={...mobilePromotion, partner_id:null, valeoPartnerId: null,valeoPartnerName:"SELECT"}
      this.setState({mobilePromotion:newMobilePromotion})
    }
    else{
      const selectedId = valeoPartners.find(
        (it) => it.id === partnerId 
      ) ?? {};
    
      const newMobilePromotion={...mobilePromotion, partner_id:selectedId.id ?? null, valeoPartnerId:selectedId.id ?? null,valeoPartnerName:selectedId.name ?? ""}
      this.setState({mobilePromotion:newMobilePromotion})
    }
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });


  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  selectPromotionalEnglishImage(e) {

    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];

      // this.setState({imageEngFile: image})
      const newMobilePromotion = { ...this.state.mobilePromotion, image }
      this.setState({ mobilePromotion: newMobilePromotion });
      const reader = new FileReader();

      reader.onloadend = function () {
        this.setState({
          imgSrc_english: [reader.result],
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  }


  selectPromotionalArabicImage(e) {
    if (e.target.files && e.target.files.length) {

      const image_arabic = e.target.files[0];
      // this.setState({imageArbFile: image_arabic})
      const newMobilePromotion = { ...this.state.mobilePromotion, image_arabic }
      this.setState({ mobilePromotion: newMobilePromotion });
      const reader = new FileReader();

      reader.onloadend = function () {
        this.setState({
          imgSrc_arabic: [reader.result]
        });
      }.bind(this);
      reader.readAsDataURL(image_arabic);
    }
  }

  createPartnerOption=(mobilePromotion)=>{
   return {
    key:mobilePromotion.valeoPartnerId,
    value:mobilePromotion.valeoPartnerId ,
    label:mobilePromotion.valeoPartnerName ,
   }
  }
  render() {
    const { countries, selectedCountry, searchTerm, editables, errors, isNewmobilePromotion, mobilePromotion, isEditable,valeoPartners, valeoPartnerOptions} = this.state;
    const finalList = this.getFilteredmobilePromotions();

    const  selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name (Admin Panel Only)*</label>
            <div className="input-group">
              <input
                value={mobilePromotion.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"

                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewmobilePromotion && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>

          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewmobilePromotion
                  ? selectedCountry && selectedCountry.country_name
                  : mobilePromotion.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={mobilePromotion.is_active}
                onChange={(event, value) => {
                  this.setState({
                    mobilePromotion: {
                      ...mobilePromotion,
                      is_active: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewmobilePromotion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={mobilePromotion.position_value}
                onChange={(event, value) => {
                  const category = { ...mobilePromotion };
                  category.position_value = value.value;
                  this.setState({ mobilePromotion: category });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewmobilePromotion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>

        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Link in (English)*</label>
            <div className="input-group">
              <textarea
                value={mobilePromotion.link}
                onChange={this.handleChange}
                type="text"
                id="link"
                name="link"
                disabled={!isNewmobilePromotion && !editables.link}
                className={
                  "form-control py-2" + (errors.link ? " has-error" : "")
                }
              ></textarea>

              {this.renderPencilIcon("link")}
            </div>
            <span className="help-block">{errors.link}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Link in (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={mobilePromotion.link_arabic}
                type="text"
                id="link_arabic"
                name="link_arabic"
                onChange={this.handleChange}
                disabled={!isNewmobilePromotion && !editables.link_arabic}
                className="form-control py-2"
              />
              {this.renderPencilIcon("link_arabic")}
            </div>
            <span className="help-block">{errors.link_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Description in (English)*</label>
            <div className="input-group">
              <textarea
                value={mobilePromotion.description}
                type="text"
                id="description"
                name="description"
                onChange={this.handleChange}
                disabled={!isNewmobilePromotion && !editables.description}
                className={
                  "form-control py-2" + (errors.description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Description in (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={mobilePromotion.description_arabic}
                type="text"
                id="description_arabic"
                name="description_arabic"
                onChange={this.handleChange}
                disabled={!isNewmobilePromotion && !editables.description_arabic}
                className="form-control py-2"
              />
              {this.renderPencilIcon("description_arabic")}
            </div>
            <span className="help-block">{errors.description_arabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
                key={`my_unique_select_key__${mobilePromotion && mobilePromotion.partner_id}`}
                value={mobilePromotion && this.createPartnerOption(mobilePromotion)}
                onChange={(event) => {
                  this.handleSelectPartner(event.value);
                }}
                options={valeoPartnerOptions || []}
                style={selectStyles}
                placeholder="Select Parnter"
                searchable
                lazyLoad
              />
              {/* {this.renderPencilIcon("partner_id")} */}
            
            <span className="help-block">{errors.partner_id}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Promotional Graphic (English)*</label>
            <div className="input-group">
              <input
                onChange={this.selectPromotionalEnglishImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewmobilePromotion && !editables.image}
                className={
                  "form-control" +
                  (errors.image ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc_english && (
              <>

                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc_english}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>

        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Promotional Graphic (Arabic)*</label>
            <div className="input-group">
              <input
                onChange={this.selectPromotionalArabicImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewmobilePromotion && !editables.image_arabic}
                className={
                  "form-control" +
                  (errors.image_arabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("image_arabic")}
            </div>
            <span className="help-block">{errors.image_arabic}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc_arabic && (
              <>

                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc_arabic}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {/* <div className="row" style={{ width: "190px" }}>
          <div className="form-group col">
            <label htmlFor="status">Target</label>
            <div className="input-group">
              <Dropdown
                value={mobilePromotion.target}
                onChange={(event, value) => {
                  this.setState({
                    mobilePromotion: {
                      ...mobilePromotion,
                      target: value.value,
                    },
                  });
                }}
                id="target"
                name="target"
                search
                selection
                options={this.state.target}
                // disabled={!isNewmobilePromotion && !editables.target}
                className={"form-control" + (errors.target ? " has-error" : "")}

              />

            </div>
            <span className="help-block">{errors.target}</span>
          </div>
        </div> */}
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
        <div className="manage-package">

          <CountryDropdown
            countries={countries || []}
            selectedCountry={selectedCountry}
            onSelect={this.handleCountrySelection}
          />

          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNew}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>
          
          <Card body>
            <DataTable
              data={finalList}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + finalList.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>

          <Modal
            size="lg"
            show={this.state.showModal}
            onHide={this.hideModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
              <div className="user-title"></div>
              <button 
                onClick={this.handleSave}
                disabled={!isEditable}
                className="btn modal-save-btn">
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_mobile_promotions">
                <Tab eventKey="key_details_form" title="Details">
                  {detailsForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobilePromotions)