import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import SelectCoach from "./SelectCoach";
import SelectLab from "./SelectLab";
import SelectOrderStatus from "./SelectOrderStatus";
import SelectTimeslot from "./SelectTimeslot";
import SelectHomeCareService from "./SelectHomeCareService";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";
import moment from "moment";
import { SettingsOutlined } from "@material-ui/icons";
import SelectFamilyMember from "./SelectMember";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { showNotification } from "services/NotificationService";
import { updateChildOrder } from "services/OrderService";
import ToggleSwitch from "components/Common/ToggleSwitch";

const BASE_URL_UPDATE_CONFIRMTIME = "order-reschedule";
const BASE_URL_SAMPLE_DETAILS_VALIDITY="/sample-details-validity";
const BASE_URL_ReSample_COllection="/recollection";
function EditCartOrderViewModal(props) {
  const {
    order,
    orderDetails,
    labs,
    homeCareServices,
    nurses=[],
    wellbeingCoaches,
    countryId,
    cityId,
    onHide,
    onSave,
    isVisible,
    operationsUsers,
    paymentGateways,
    isPaymentPendingOrder,
    familyMembers=[],
    userDetails,
    onSavingFamilyMember={},
    onCancelFamilyMember={},
    onCancelReschedule={},
    transactionDetails={},
    externalCoaches,
    onSaveReschedule={},
    isDraftOrder=false,
    
  } = props;

  const { itemType, itemId , orderId} = order ?? {};
  const { transactionReference, paymentMethod } = transactionDetails ?? {};
   
  const {
    itemOrderId,
    itemNameEnglish,
    orderStatusList,
    orderStatus: status,
    bookedSlotDate,
    bookedSlotTime,
    specimenCollectionDate,
    specimenCollectionTime,
    confirmedDateTime,
    confirmDate,
    confirmTime,
    nutritionistId,
    laboratoryId,
    operationType,
    isResultUploaded,
    isAttachmentUploaded,
    doctorsNote,
    doctorsNoteAr,
    labCost: orderLabCost,
    homeCareCost: orderHomeCareCost,
    homeCareId: orderHomeCareId,
    couponAmount: orderCouponAmount,
    customTestBiomarkers: orderCustomTestBiomarkers,
    customTestPriceAdditional: orderCustomTestPriceAdditional,
    handledById: orderHandledById,
    lab1Id: orderLab1Id,
    lab2Id: orderLab2Id,
    lab1CustomCost: orderLab1CustomCost,
    lab2CustomCost: orderLab2CustomCost,
    notes: orderNotes,
    referenceNumber1: orderReferenceNumber1,
    referenceNumber2: orderReferenceNumber2,
    refundAmount: orderRefundAmount,
    revisedPrice: orderRevisedPrice,
    revisedPriceAfterCoupon: orderRevisedPriceAfterCoupon,
    paymentGateway1Id: orderPaymentGateway1Id,
    paymentGateway2Id: orderPaymentGateway2Id,
    couponCodeValue: orderCouponCodeValue,
    miniPackages,
    
    sampleDetailsLab1: orderSampleDetailsLab1,
    sampleDetailsLab2: orderSampleDetailsLab2,
    specialInstructions: orderSpecialInstructions,
    paymentToBeCollected: orderPaymentToBeCollected,
    secondaryCoachId,
    isConfirmed, 
    rescheduledReason,
    
    labCostNotes:orderLabCostNotes,
    professionalId:orderProfessionalId,
    homecareStatus:orderHomecareConfirmationStatus,
    homecareCostNotes:orderHomecareCostsNotes,
    isRequireSampleCollection,

    isSampleValidated:orderIsSampleValidated,
    isLabCostUpdateRequired,
    recollectionCount

  } = orderDetails ?? {};

  const homeCareStatus = [
    { text: "None", value: "", key: "" ,status:""},
    {
      text: "Not Assigned",
      value:  "NOT ASSIGNED",
      key:  "NOT ASSIGNED",
      status: "NOT ASSIGNED",
    },
    {
      text: "Assigned",
      value: "ASSIGNED",
      key: "ASSIGNED",
      status: "ASSIGNED",
    },
    {
      text: "Confirmed",
      value: "CONFIRMED",
      key:"CONFIRMED" ,
      status: "CONFIRMED",
    },
    {
      text: "Rescheduled",
      value: "RESCHEDULED",
      key: "RESCHEDULED",
      status: "RESCHEDULED",
    },
  ];
  
  const homeCareServicesForCountry = homeCareServices
    ? homeCareServices.filter((it) => it?.country?.id === countryId)
    : [];


  const [confirmedDatee, confirmedTimee] = confirmedDateTime 
  ? confirmedDateTime.split(" "):isPaymentPendingOrder?[confirmDate,confirmTime]
    : [null, null];
  
  const [orderStatus, setOrderStatus] = useState(status);
  const [appointmentDate, setAppointmentDate] = useState(
    bookedSlotDate ?? specimenCollectionDate
  );
  const [appointmentTime, setAppintmentTime] = useState(
    bookedSlotTime ?? specimenCollectionTime
  );
  const[oldConfirmDate,setOldConfirmDate]=useState(confirmedDatee);
  const[confirmDatePopup,setConfirmDatePopup]=useState(false);
  const[confirmDateEdit,setConfirmDateEdit]=useState(false);
  const [confirmedDate, setConfirmedDate] = useState(confirmedDatee);
  const [confirmedTime, setConfirmedTime] = useState(confirmedTimee);
  const [nutritionist, setNutritionist] = useState(nutritionistId);
  const [laboratory, setLaboratory] = useState(laboratoryId);
  const [labCost, setLabCost] = useState(orderLabCost);
  const [homeCareCost, setHomeCareCost] = useState(orderHomeCareCost);
  const [homeCareId, setHomeCareId] = useState(orderHomeCareId);
  const [errors, setErrors] = useState({});
  const [handledById, setHandledById] = useState(orderHandledById);
  const[homecareStatus,setHomeCareStatus]=useState(orderHomecareConfirmationStatus);
  const [customTestPriceAdditional, setCustomTestPriceAdditional] = useState(
    orderCustomTestPriceAdditional
  );
  

  const homecareNurses=props.nurses!=null && homeCareId? nurses.filter((nurse) => nurse.homeCareServiceId==homeCareId):[];
 


  const [lab1Id, setLab1Id] = useState(orderLab1Id);
  const [lab1CustomCost, setLab1CustomCost] = useState(orderLab1CustomCost);
  const [paymentGateway1Id, setPaymentGateway1Id] = useState(
    orderPaymentGateway1Id
  );
  const [referenceNumber1, setReferenceNumber1] = useState(
    orderReferenceNumber1
  );
  const [lab2Id, setLab2Id] = useState(orderLab2Id);
  const [lab2CustomCost, setLab2CustomCost] = useState(orderLab2CustomCost);
  const [paymentGateway2Id, setPaymentGateway2Id] = useState(
    orderPaymentGateway2Id
  );
  const [referenceNumber2, setReferenceNumber2] = useState(
    orderReferenceNumber2
  );
  const [revisedPrice, setRevisedPrice] = useState(orderRevisedPrice);
  const [couponAmount, setCouponAmount] = useState(orderCouponAmount);
  const [revisedPriceAfterCoupon, setRevisedPriceAfterCoupon] = useState(
    orderRevisedPriceAfterCoupon
  );
  const [refundAmount, setRefundAmount] = useState(orderRefundAmount);
  const [customTestBiomarkers, setCustomTestBiomarkers] = useState(
    orderCustomTestBiomarkers
  );
  const [notes, setNotes] = useState(orderNotes);
  const [couponCodeValue, setCouponCodeValue] = useState(orderCouponCodeValue);
  const [sampleDetailsLab1, setSampleDetailsLab1] = useState(orderSampleDetailsLab1);
  const [sampleDetailsLab2, setSampleDetailsLab2] = useState(orderSampleDetailsLab2);
  const [specialInstructions, setSpecialInstructions] = useState(orderSpecialInstructions);
  const [paymentToBeCollected, setPaymentToBeCollected] = useState(orderPaymentToBeCollected);
  const[isrescheduleDateConfirmPopup,setIsRescheduleDateConfirmPopup]=useState(false);
  const [familyMemberId, setFamilyMemberId]=useState(null)
  const [showSelectMemberPopup, setShowSelectMemberPopup]=useState(false)

  const [externalCoach,setExternalCoach]=useState(secondaryCoachId);

  const [editConfirmTime, setEditConfirmTime] = useState(false);
  const [rescheduleReason, setRescheduleReason] = useState(rescheduledReason ?? "");
  const [confirmTimeModalError,setConfirmTimeModalError] = useState("")
  const [clientConfirm,setClientConfirm] = useState(isConfirmed);
  const [oldConfirmTime,setOldConfirmTime] = useState(confirmedTimee)
  const [submitEnableConfirmTime,setSubmitEnableTime] = useState(false);


  const [confirmDateModalError,setConfirmDateModalError] = useState("")
  const [isShowErrorModal, setIsisShowErrorModal] = useState(false);
console.log("confirmDate and confrima RTImefdew", confirmDate, confirmTime);
  const[manuallyAssign,setManuallyAssign] = useState(false);

  const [labCostNotes,setLabCostNotes]=useState(orderLabCostNotes);
  const [professionalId,setProfessionalId]=useState(orderProfessionalId);

  const [homecareCostNotes,setHomecareCostNotes]=useState(orderHomecareCostsNotes);
  const[sampleDetailModalData,setSampleDetailModalData]=useState("");
  const[showSampleDetailWarning,setShowSampleDetailWarning]=useState(false);
  const[isSampleValidated,setIsSampleValidated]=useState(orderIsSampleValidated);
  const[sampleDetails,setSampleDetails]=useState({});
  const [selectedOrderStatusState,setSelectedOrderStatusState] = useState("");
  
  const[isRequireSampleReCollection,setIsRequireSampleReCollection]=useState(false);

  const[confirmReCollection,setConfirmReCollection]=useState(false);

 const getDependentMember=()=>{
  const {dependent=null}= orderDetails || {};
   const {id:familyMemberId= null}= dependent || {};
   if(familyMemberId){
    setFamilyMemberId(familyMemberId)
   }
   else{
    const selfUser= familyMembers.length>0 &&  familyMembers.filter((it)=>it.relation=="self");
    if(selfUser.length>0){
      setFamilyMemberId(selfUser[0].id)
    }
    
   }
 }

  useEffect(() => {
    setOrderStatus(null);
    setAppointmentDate(null);
    setAppintmentTime(null);
    setConfirmedDate(null);
    setConfirmedTime(null);
   setOldConfirmDate(null);
    setNutritionist(null);
    setLaboratory(null);
    setLabCost(null);
    setHomeCareCost(null);
    setHomeCareId(null);
    setHandledById(null);
    setCustomTestPriceAdditional(null);
    setLab1Id(null);
    setLab1CustomCost(null);
    setPaymentGateway1Id(null);
    setReferenceNumber1(null);
    setLab2Id(null);
    setLab2CustomCost(null);
    setPaymentGateway2Id(null);
    setReferenceNumber2(null);
    setRevisedPrice(null);
    setCouponAmount(null);
    setRevisedPriceAfterCoupon(null);
    setRefundAmount(null);
    setCustomTestBiomarkers(null);
    setNotes(null);
    setCouponCodeValue(null);
    setErrors({});
    setSampleDetailsLab1(null);
    setSampleDetailsLab2(null);
    setSpecialInstructions(null);
    setPaymentToBeCollected(null);
    getDependentMember();
    setHomeCareStatus(null);
    setProfessionalId(null);
    setManuallyAssign(false);
    setSampleDetailModalData(null);
    setShowSampleDetailWarning(false);
    setIsSampleValidated(null);
    
  }, [order, orderDetails]);

  
  useEffect(() => setOrderStatus(status), [status]);
  useEffect(
    () => setAppointmentDate(bookedSlotDate ?? specimenCollectionDate),
    [bookedSlotDate, specimenCollectionDate]
  );
  useEffect(
    () => setAppintmentTime(bookedSlotTime ?? specimenCollectionTime),
    [bookedSlotTime, specimenCollectionTime]
  );
  useEffect(() => setConfirmedDate(confirmedDatee), [confirmedDatee]);
  
  useEffect(() => setConfirmedTime(confirmedTimee), [confirmedTimee]);
  useEffect(() => setNutritionist(nutritionistId), [nutritionistId]);
  useEffect(() => setLaboratory(laboratoryId), [laboratoryId]);
  useEffect(() => setHomeCareId(orderHomeCareId), [orderHomeCareId]);
  useEffect(() => setHomeCareCost(orderHomeCareCost), [orderHomeCareCost]);
  useEffect(() => setLabCost(orderLabCost), [orderLabCost]);
  useEffect(() => setHandledById(orderHandledById), [orderHandledById]);
  useEffect(
    () => setCustomTestPriceAdditional(orderCustomTestPriceAdditional),
    [orderCustomTestPriceAdditional]
  );
  useEffect(() => setLab1Id(orderLab1Id), [orderLab1Id]);
  useEffect(
    () => setLab1CustomCost(orderLab1CustomCost),
    [orderLab1CustomCost]
  );
  useEffect(
    () => setPaymentGateway1Id(orderPaymentGateway1Id),
    [orderPaymentGateway1Id]
  );
  useEffect(
    () => setReferenceNumber1(orderReferenceNumber1),
    [orderReferenceNumber1]
  );
  useEffect(() => setLab2Id(orderLab2Id), [orderLab2Id]);
  useEffect(
    () => setLab2CustomCost(orderLab2CustomCost),
    [orderLab2CustomCost]
  );
  useEffect(
    () => setPaymentGateway2Id(orderPaymentGateway2Id),
    [orderPaymentGateway2Id]
  );
  useEffect(
    () => setReferenceNumber2(orderReferenceNumber2),
    [orderReferenceNumber2]
  );
  useEffect(() => setRevisedPrice(orderRevisedPrice), [orderRevisedPrice]);
  useEffect(() => setCouponAmount(orderCouponAmount), [orderCouponAmount]);
  useEffect(
    () => setRevisedPriceAfterCoupon(orderRevisedPriceAfterCoupon),
    [orderRevisedPriceAfterCoupon]
  );
  useEffect(() => setRefundAmount(orderRefundAmount), [orderRefundAmount]);
  useEffect(
    () => setCustomTestBiomarkers(orderCustomTestBiomarkers),
    [orderCustomTestBiomarkers]
  );
  useEffect(() => setNotes(orderNotes), [orderNotes]);
  useEffect(()=>setConfirmDatePopup(confirmDatePopup),[confirmDatePopup]);
  useEffect(
    () => setCouponCodeValue(orderCouponCodeValue),
    [orderCouponCodeValue]
  );
  useEffect(() => setSampleDetailsLab1(orderSampleDetailsLab1), [orderSampleDetailsLab1]);
  useEffect(() => setSampleDetailsLab2(orderSampleDetailsLab2), [orderSampleDetailsLab2]);
  useEffect(() => setSpecialInstructions(orderSpecialInstructions), [orderSpecialInstructions]);
  useEffect(() => setPaymentToBeCollected(orderPaymentToBeCollected), [orderPaymentToBeCollected]);
  useEffect(()=>
       setIsRescheduleDateConfirmPopup(isrescheduleDateConfirmPopup)
  ,[isrescheduleDateConfirmPopup]);
  useEffect(()=>setConfirmDateEdit(confirmDateEdit),[confirmDateEdit]);
  useEffect(()=>setOldConfirmDate(confirmedDatee),[confirmedDatee]);
  useEffect(() => setExternalCoach(secondaryCoachId), [secondaryCoachId]);
  useEffect(()=>setClientConfirm(isConfirmed),[isConfirmed]);
  useEffect(()=>setRescheduleReason(rescheduledReason),[rescheduledReason]);


 
  useEffect(()=> setProfessionalId(orderProfessionalId),[orderProfessionalId]);
  useEffect(()=>setHomeCareStatus(orderHomecareConfirmationStatus),[orderHomecareConfirmationStatus]);
  

  useEffect(()=>setIsSampleValidated(orderIsSampleValidated),[orderIsSampleValidated]);
    
    useEffect(()=>{
      setLabCostNotes(orderLabCostNotes)
    },[orderLabCostNotes])
   

    useEffect(()=>{
      setHomecareCostNotes(orderHomecareCostsNotes)
    },[orderHomecareCostsNotes])
    const isCustomPackage = itemType === "Custom_Package";


    useEffect(() => {
   
      const fetchSampleDetails = async () => {
        try {
          if(itemOrderId!=null){
          const response = await PhoenixAPI.get(`${BASE_URL_SAMPLE_DETAILS_VALIDITY}/${itemOrderId}`);
          setSampleDetails(response.data);
          console.log("sample details",response.data);
          console.log("isSampleValidate",isSampleValidated);
          if(!orderIsSampleValidated){
            if(response.data.statusCode==400)
            {
              const result ="Warning : "+"please add sample details for below\n"+ response.data.message;
              console.log("result",result);
              setSampleDetailModalData(result);
            }
          }
         
        }
        } catch (error) {
          props.showNotificationMessage({
            notificationMessage: error.message,
            successMessage: false,
            showNotification: true,
          });
         
        } 
      };
  
      // Call the fetch function
      fetchSampleDetails();

      
    }, [itemOrderId]);





  const isCoachReviewRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("Requires Coach Note"));

    const sampleNotRequire=  ((!(isRequireSampleCollection ))&& isCustomPackage);
        const isHomeAppointmentRequired =
        isCustomPackage &&
        operationType &&
        operationType.includes("Home Appointment");
       

  // console.log("EditCartOrderViewModal: orderStatus:", orderStatus);

  const validate = () => {
   
    const errors = {};
    // if (!appointmentDate)
    //   errors.appointmentDate = "Appointment Date can not be blank!";
    // if (!appointmentTime)
    //   errors.appointmentTime = "Appointment Time can not be blank!";
    if (confirmedDate && !confirmedTime)
      errors.confirmedTime = "Confirmed Time can not be blank!";
    if (confirmedTime && !confirmedDate)
      errors.confirmedDate = "Confirmed Date can not be blank!";
     if(isCustomPackage && isLabCostUpdateRequired){
      if(!labCost){
        errors.labCost = "Lab Costs can not be blank";
      }
     }
    if (labCost && Number.isNaN(Number(labCost)))
      errors.labCost = "Lab Costs should be a valid number";
    else if (labCost && Number(labCost) > 9999)
      errors.labCost = "Lab Costs can not be greater then 9999";

    if (homeCareCost && Number.isNaN(Number(homeCareCost)))
      errors.homeCareCost = "Homecare Costs should be a valid number";
    else if (homeCareCost && Number(homeCareCost) > 9999)
      errors.homeCareCost = "Homecare Costs can not be greater then 9999";

    if (
      customTestPriceAdditional &&
      Number.isNaN(Number(customTestPriceAdditional))
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be a valid number";
    } else if (
      customTestPriceAdditional &&
      Number(customTestPriceAdditional) > 9999
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be bewteen 0 to 9999";
    }

    if (selectedOrderStatusState=="COMPLETED" && (!lab1Id || lab1Id === "") && (itemType=="Blood_Package" || itemType=="Mini_Package"  || (itemType=="Custom_Package"  && isRequireSampleCollection) )){
      errors.lab1Id = "Lab 1 cannot be blank or none!";
    }

    if(selectedOrderStatusState=="COMPLETED" && (!homeCareId || homeCareId === "") && (itemType=="Blood_Package" || itemType=="Mini_Package" || (itemType=="Custom_Package"  && isRequireSampleCollection) )){
      errors.homecareId = "Home care cannot be blank or none!"
    }

    if(professionalId && (!homeCareId || homeCareId === "")){
      errors.professionalId = "cannot assign nurse without homecare!"
    }
      
    if (lab1CustomCost && Number.isNaN(Number(lab1CustomCost))) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be a valid number";
    } else if (lab1CustomCost && Number(lab1CustomCost) > 9999) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber1 && referenceNumber1.length > 500) {
      errors.referenceNumber1 =
        "Reference Number 1 is limited to a 500 characters";
    }

    if (lab2CustomCost && Number.isNaN(Number(lab2CustomCost))) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be a valid number";
    } else if (lab2CustomCost && Number(lab2CustomCost) > 9999) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber2 && referenceNumber2.length > 500) {
      errors.reference_number_2 =
        "Reference Number 2 is limited to a 500 characters";
    }

    if (revisedPrice && Number.isNaN(Number(revisedPrice))) {
      errors.revisedPrice = "Revised Price should be a valid number";
    } else if (revisedPrice && Number(revisedPrice) > 9999) {
      errors.revisedPrice = " Revised Price Cost should be bewteen 0 to 9999";
    }

    if (couponAmount && Number.isNaN(Number(couponAmount))) {
      errors.couponAmount = "Coupon Amount should be a valid number";
    } else if (couponAmount && Number(couponAmount) > 9999) {
      errors.couponAmount = "Coupon Amount should be bewteen 0 to 9999";
    }

    if (
      revisedPriceAfterCoupon &&
      Number.isNaN(Number(revisedPriceAfterCoupon))
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be a valid number";
    } else if (
      revisedPriceAfterCoupon &&
      Number(revisedPriceAfterCoupon) > 9999
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be bewteen 0 to 9999";
    }

    if (refundAmount && Number.isNaN(Number(refundAmount))) {
      errors.refund_amount = "Refund Amount should be a valid number";
    } else if (refundAmount && refundAmount && Number(refundAmount) > 9999) {
      errors.refundAmount = "Refund Amount should be bewteen 0 to 9999";
    }

    if (customTestBiomarkers && customTestBiomarkers.length > 3000) {
      errors.customTestBiomarkers =
        "Custom Test Biomarkers is limited to a 3000 characters";
    }

    if (notes && notes.length > 3000) {
      errors.notes = "Notes is limited to a 3000 characters";
    }

    if (couponCodeValue && couponCodeValue.length > 100) {
      errors.couponCodeValue = "Coupon Code Value limited  to a 100 characters";
    }

    if (!sampleDetailsLab1 || sampleDetailsLab1.trim() === "")
      errors.sampleDetailsLab1 = "Sample Details Lab 1 cannot be blank!";
    else if (sampleDetailsLab1.length > 500)
      errors.sampleDetailsLab1 = "Sample Details Lab 1 is limited to a 500 characters";

    if (sampleDetailsLab2 && sampleDetailsLab2.length > 500)
      errors.sampleDetailsLab2 = "Sample Details Lab 2 is limited to a 500 characters";

    if (specialInstructions && specialInstructions.length > 500)
      errors.specialInstructions = "Special Instructions is limited to a 500 characters";

    if (paymentToBeCollected && paymentToBeCollected.length > 500)
      errors.paymentToBeCollected = "Payment to be collected is limited to a 500 characters";

    if (labCostNotes && labCostNotes.length > 3000) {
      errors.labCostNotes = "LabCost Notes is limited to a 3000 characters";
    }
   /* if(!isSampleValidated ){
      errors.sampleValidity="please check sample validity";
    }*/
    return Object.keys(errors).length === 0 ? null : errors;
  };

  const validateOrderStatus = () => {
    const areResultsRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("PDF Download"));
    const isCoachReviewRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("Requires Coach Note"));
    if (["COMPLETED", "Order Completed","COACH_REVIEWED","COACH REVIEWED", "Coach Reviewed"].includes(orderStatus)) {
      if (areResultsRequired && !isResultUploaded)
        return "Upload results to mark as completed!";

      if (
        isCoachReviewRequired &&
        (nutritionist === null || nutritionist === undefined)
      )
        return "Assign a Wellbeing coach to mark as completed!";

      if (
        isCoachReviewRequired &&
        nutritionist &&
        (!doctorsNote || !doctorsNoteAr)
      )
        return "Add Coach review's to mark as completed!";
    } 
    
    // else if (["COACH_REVIEWED", "Coach Reviewed"].includes(orderStatus)) {
    //   if (
    //     isCoachReviewRequired &&
    //     (nutritionist === null || nutritionist === undefined)
    //   )
    //     return "Assign a Wellbeing coach to mark as coach reviewed!";
    //     if (
    //       isCoachReviewRequired &&
    //       nutritionist &&
    //       (!doctorsNote || !doctorsNoteAr)
    //     )
    //       return "Add Coach review's to mark as completed!";
    // }

    if (orderStatus && ["RESULTS UPLOADED", "RESULTS UPLOADED TO APP", "RESULTS_UPLOADED_TO_APP", "PARTIAL RESULTS UPLOADED TO APP"].includes(orderStatus.toUpperCase())) {
      // console.log("validateOrderStatus::", { orderStatus, isAttachmentUploaded });
      if (!isAttachmentUploaded) return "Kindly upload lab reports first";
    }
    return null;
  };
  const validatePendingOrders=()=>{
    const errors = {};
    if (!appointmentDate)
      errors.appointmentDate = "Appointment Date can not be blank!";
    if (!appointmentTime)
      errors.appointmentTime = "Appointment Time can not be blank!";
      if(!confirmedDate && !confirmedTime){
        errors.confirmedDate = "Confirmed Date can not be blank!";
        errors.confirmedTime = "Confirmed Time can not be blank!";
      }
    if (confirmedDate && !confirmedTime)
      errors.confirmedTime = "Confirmed Time can not be blank!";
    if (confirmedTime && !confirmedDate)
      errors.confirmedDate = "Confirmed Date can not be blank!";
      return Object.keys(errors).length === 0 ? null : errors;
  }

  const validateConfirmDate = (confirmDate) => {
    setConfirmedDate(confirmDate);
    const errors = {};
    // console.log("confirm date",confirmDate)
    const formattedDate = moment(confirmDate, "YYYY-MM-DD", true);
    const today = moment().format("YYYY-MM-DD");
    if (!formattedDate.isSameOrAfter(today)) {
      errors.confirmedDate = "confirm Date cannot be past";
    } else {
      setConfirmedDate(confirmDate);
    }
    const isDateToday = formattedDate.isSame(today);
    if (isDateToday) {
      const currentTime = moment();
      if (!moment(confirmedTime, "HH:mm").isAfter(currentTime)) {
        errors.confirmedTime = "Time should be after present time ";
      }
    }
     setErrors(errors);
    return errors;
  };
  const validateConfirmTime = (confirmTime) => {
    setConfirmedTime(confirmTime);
    const errors = {};
   
    const today = moment().format("YYYY-MM-DD");
    console.log("confirm time", confirmDate, confirmTime);

    const formattedDate = moment(confirmedDate, "YYYY-MM-DD", true);
    if (!formattedDate.isSameOrAfter(today)) {
      errors.confirmedDate = "confirm Date cannot be past";
    }
    const isDateToday = formattedDate.isSame(today);
    if (isDateToday) {
      const currentTime = moment();
      if (!moment(confirmTime, "HH:mm").isAfter(currentTime)) {
        errors.confirmedTime = "Time should be after present time ";
      } else {
        setConfirmedTime(confirmTime);
      }
    } else {
      setConfirmedTime(confirmTime);
    }

    setErrors(errors);

    return errors;
  };  


  const validateSampleDetails=()=>{
    const{itemOrderId}=orderDetails;
    
      console.log("sampleDetailLab1 order sampleDetails",sampleDetailsLab1,orderSampleDetailsLab1);
       const isInvalidSample = sampleDetailsLab1 !=null ? (sampleDetails.statusCode!=200 && sampleDetailsLab1 == orderSampleDetailsLab1): sampleDetails.statusCode==400;
       console.log("isInvalidSample",isInvalidSample);
       if(isInvalidSample){
        if(isSampleValidated==null || !isSampleValidated){
          console.log("in if not valid sample")
        
        const result ="Warning :"+"please add sample details for below\n"+ sampleDetails.message;
        console.log("result",result);
        setSampleDetailModalData(result);
        //setIsSampleValidated(false);
      //  setShowSampleDetailWarning(true);
        
        }
        }
        
        
     
      }

 



 

 const handleSave = (event) => {
    

   if(errors.confirmedDate=="confirm Date cannot be past"|| errors.confirmedTime=="Time should be after present time "){
    props.showNotificationMessage({
      notificationMessage: "Invalid fields are there",
      successMessage: false,
      showNotification: true,
    });
    return;
   }
       
   if(!isPaymentPendingOrder ){
   validateSampleDetails();
    
    }
   
 
    const errorsFinal =isPaymentPendingOrder?validatePendingOrders(): validate();
    console.log("errosFinal",errorsFinal);
    console.log("isSampleValidated");
    if(isSampleValidated==null || !isSampleValidated){
      if (errorsFinal ){
      setErrors(errorsFinal);
      props.showNotificationMessage({
        notificationMessage: "Invalid fields are there",
        successMessage: false,
        showNotification: true,
      });
    }
 
      // return;
    }
    
    
    
    if (errorsFinal ) {
      setErrors(errorsFinal);
      props.showNotificationMessage({
        notificationMessage: "Invalid fields are there",
        successMessage: false,
        showNotification: true,
      });

      return;
    }
     
    
    
   
    const orderStatusErrorMessage = validateOrderStatus();

       if(isDraftOrder && (orderStatus==="LAB CONFIRMED" || orderStatus==="SAMPLE COLLECTED BY HOMECARE" 
       || orderStatus==="SAMPLE RECEIVED" || orderStatus==="REPORT RECEIVED" || 
       orderStatus==="PARTIAL RESULTS UPLOADED TO APP" || orderStatus==="RESULTS UPLOADED TO APP"
       || orderStatus==="COACH REVIEWED" || orderStatus==="COMPLETED") && !transactionReference){
        setIsisShowErrorModal(true);
        return;
       }

    if (!isPaymentPendingOrder && orderStatusErrorMessage) {
      props.showNotificationMessage({
        notificationMessage: orderStatusErrorMessage,
        successMessage: false,
        showNotification: true,
      });
      return;
    }
   if(!isPaymentPendingOrder && confirmDateEdit){
    setIsRescheduleDateConfirmPopup(true);
  
   }
    
  
  if(isPaymentPendingOrder)
  {
  onSave(orderDetails,{
  confirmedDate,
  confirmedTime,
  bookedSlotDate: appointmentDate,
  bookedSlotTime: appointmentTime})
  }
   
  else if(!confirmDateEdit)
  confirmDatePopupConfirmed();

    };

  const confirmDatePopupConfirmed =()=>{
    const onSaveParams={
      orderStatus,
      confirmedDate,
      confirmedTime,
      bookedSlotDate: appointmentDate,
      bookedSlotTime: appointmentTime,
      laboratoryId: laboratory,
      nutritionistId: nutritionist,
      labCost,
      homeCareCost,
      homeCareId,
      handledById,
      customTestPriceAdditional,
      lab1Id,
      lab1CustomCost,
      paymentGateway1Id,
      referenceNumber1,
      lab2Id,
      lab2CustomCost,
      paymentGateway2Id,
      referenceNumber2,
      revisedPrice,
      couponAmount,
      revisedPriceAfterCoupon,
      refundAmount,
      customTestBiomarkers,
      notes,
      couponCodeValue,
      sampleDetailsLab1,
      sampleDetailsLab2,
      specialInstructions,
      paymentToBeCollected,
      secondaryCoachId:externalCoach,
      labCostNotes,
      homecareStatus,
      professionalId,
      isSampleValidated
    }
    onSave(orderDetails,onSaveParams);
    setConfirmDateEdit(false);
    setConfirmDatePopup(false);
  }
   
 const  calculateDifference = (endDate) => {
    const startDateMoment = moment();
    const endDateMoment = moment(endDate);
    const today=moment().format("YYYY-MM-DD");
    // console.log("confirmDate",confirmDate);
   
    const endDateFormat=moment(endDate,"YYYY-MM-DD",true);
   
    if(endDateFormat.isSame(today)){

    return "same";
    }
      // console.log("end  and start Date", startDateMoment,endDateMoment);
    // Calculate the difference in days
  
    const differenceInDays = endDateMoment.diff(startDateMoment, 'days');
    //  console.log("difference in days",differenceInDays);
    return differenceInDays;
  
  };

 const handleConfirmDateEditPopup=async()=>{
  console.log("handleConfirmDateEditPopup",confirmReCollection);
  const modifiedBy = getUserId();
  const originalDate=confirmedDate + " " + confirmedTime;
  const formattedDate = originalDate.replace(" ", "T") + ":00";
     const payload=confirmReCollection?{
      "orderStatus": "RECOLLECTION",
      "recollectionnTime": formattedDate}
      :{
      rescheduledDateTime: confirmedDate + " " + confirmedTime,
      modified_by: modifiedBy,
      orderId: orderId,
      rescheduleReason:rescheduleReason
     }
    

    
    
     try{
       const response = confirmReCollection?await updateResampleCollection(payload):await updateOrderConfirmTime(payload);
       hideRescheduleDateModal();
       hideConfirmDatePopUp();
       if(!confirmReCollection){

       onSaveReschedule(true);
       }
       else{

       hideConfirmReCollectin();
       window.location.reload();
       
       }

       
     }
     catch(error){
      console.log("Error in saving rescheduled date and time")
     }

     
  }

  const hideConfirmReCollectin=()=>{
    setConfirmReCollection(false);
  }

 const  hideRescheduleDateModal=()=>{
  setIsRescheduleDateConfirmPopup(false); 
  setConfirmDatePopup(true);
// confirmDatePopupConfirmed();
  }

  const hideSampleRecollectionConfirmation=()=>{
    setIsRequireSampleReCollection(false);
  }
const  cancelRescheduleDate=()=>{
  setConfirmedDate(oldConfirmDate);
      setIsRescheduleDateConfirmPopup(false);
  
  }
 const  showRescheduleDateModal=()=>{
  setIsRescheduleDateConfirmPopup(true);
  }



const renderFamilyMemberChange=()=>{
     const {firstName,lastName}= userDetails || {}
    const findMember=familyMembers.length>0 && familyMembers.find((it)=>it.id===familyMemberId)
    const fullUserName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
    const {dependent}= orderDetails || {}
    
    if(!dependent){
       return `Are you sure want to update the member for this order from "Self" to ${findMember?.fullName || ""}`
    }
    else{
      const {firstName, lastName}= dependent
      const dependentFullName= `${firstName ?? ""} ${lastName ?? ""}`.trim();
      if(dependent.id == (findMember && findMember.id)){
        return "Order is Assigned already to this Member "
        
      }
      else{
        return `Are you sure want to update the member for this order from ${dependentFullName} to ${findMember?.fullName || ""}`
      }
    }
     

  }
  const handleSaveSelectMemberModal=async()=>{
     const {itemOrderId}= orderDetails || {} 
     const modifiedBy= getUserId();
     if(!familyMemberId || !itemOrderId){
      return 
     }
  
     
    
     const payload={
      "dependentId":familyMemberId=="self"?null:familyMemberId,
      "modifiedBy":modifiedBy
     }
     try{
       const res= await PhoenixAPI.patch(`/assign-dependent/child-order-id/${itemOrderId}`, payload)
       console.log("res", res)
       setShowSelectMemberPopup(false)
       onSavingFamilyMember(true)
     }
     catch(error){
      console.log("Error in updating member for this order");
      
     }

   }
 
   const hideSelectMemberModal=()=>{
    onCancelFamilyMember(true)
    setShowSelectMemberPopup(false)
   }

   const handleBothFunctions = async () => {
    await handleManuallyAssign();
    
  };

   const handleManuallyAssign=async()=>{
    handleSave();
    setManuallyAssign(false);
  }
  const handleOrderStatus = (selectedOrderStatus) => {
    setSelectedOrderStatusState(selectedOrderStatus);
    console.log("this is new status",selectedOrderStatusState)
    if ((status === "ORDER CONFIRMED" || status === "CREATED" || status === "ASSIGNED") && paymentMethod === "COD_MAMOPAY" && !transactionReference) {
      if (selectedOrderStatus === "CREATED" || selectedOrderStatus === "ASSIGNED" || selectedOrderStatus === "ORDER CONFIRMED") {
        setOrderStatus(selectedOrderStatus);
      } else {
        setIsisShowErrorModal(true);
      }
    } else {
      setOrderStatus(selectedOrderStatus);
    }
     if(isDraftOrder){
      if(status==="ORDER CONFIRMED"){
       if((selectedOrderStatus==="LAB CONFIRMED" || selectedOrderStatus==="SAMPLE COLLECTED BY HOMECARE" 
       || selectedOrderStatus==="SAMPLE RECEIVED" || selectedOrderStatus==="REPORT RECEIVED" || 
       selectedOrderStatus==="PARTIAL RESULTS UPLOADED TO APP" || selectedOrderStatus==="RESULTS UPLOADED TO APP"
       || selectedOrderStatus==="COACH REVIEWED" || selectedOrderStatus==="COMPLETED") && !transactionReference
       ){
        setIsisShowErrorModal(true);
       }
      }
      
     }
   }
const showConfirmTime=()=>{
  
  setClientConfirm(isConfirmed)
  setRescheduleReason(rescheduledReason)
  setConfirmDatePopup(true)
}
const hideConfirmDatePopUp=()=>{
  setConfirmDatePopup(false)
  setRescheduleReason("");
  setEditConfirmTime(false)
  setClientConfirm(false)
  // setErrors(null)
  setConfirmTimeModalError("")
  onCancelReschedule(true)
  
}

  const form = (
    <>
    {isPaymentPendingOrder?<></> :<> 
    <div className="row">
        <div className="form-group col">
          <label>Order Status</label>
          <SelectOrderStatus
            options={orderStatusList}
            value={orderStatus}
            onSelect={handleOrderStatus}
            // onSelect={(selectedOrderStatus) =>
            //   setOrderStatus(selectedOrderStatus)
            // }
          />
        </div>
        <div className="form-group col">
        <label>Select Family Member</label>
        <div className="input-group">
              <SelectFamilyMember
                id="familyMemberId"
                name="familyMemberId"
                familyMembers={familyMembers ?? []}
                selectedFamilyMemberId={familyMemberId}
                onSelect={(option) => {
                  setFamilyMemberId(option.value);setShowSelectMemberPopup(true)
                }
                }
                showOnlyActive={true}
                // isDisabled={!isNewCartItem && !editables.familyMemberId}
              />
           
            </div>
        </div>
      </div></>}
      <div className="row">
        <div className="form-group col">
          <label htmlFor="appointmentDate">Appointment Date</label>
          <input
            value={appointmentDate ?? ""}
            onChange={(e) => {
              setAppointmentDate(e.target.value);
              setAppintmentTime(null);
            }}
            type="date"
            id="appointmentDate"
            name="appointmentDate"
            disabled={true}
            className={
              "form-control" + (errors.appointmentDate ? " has-error" : "")
            }
          />
          <span className="help-block">{errors.appointmentDate}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="appointmentTime">Appointment Time</label>
          <SelectTimeslot
            cityId={cityId}
            packageType={itemType ? itemType.toLowerCase() : ""}
            packageId={itemId}
            miniPackages={miniPackages ?? null}
            date={appointmentDate}
            selectedTimeslot={appointmentTime}
            onTimeslotSelect={(timeslot) => setAppintmentTime(timeslot)}
            isDisabled={true}
            className={
              "form-control" + (errors.appointmentTime ? " has-error" : "")
            }
          />
          <span className="help-block">{errors.appointmentTime}</span>
        </div>
      </div>
      {!isPaymentPendingOrder && <div className="row">
        <div className="form-group col">
          <label>Confirmed Date</label>
          <input
            value={confirmedDate ?? ""}
            // onChange={(e) => {
            //                      const errorconfirmDate=validateConfirmDate(e.target.value);
            //                      setConfirmDateEdit(true);
            //                      setErrors(errorconfirmDate);
                                 
            //                     }}
            type="date"
            id="confirmedDate"
            name="confirmedDate"
            className={
              "form-control" + (errors.confirmedDate ? " has-error" : "")
            }
            disabled={true}
          />
          
          <span className="help-block">{errors.confirmedDate}</span>
        </div>
        <div className="form-group col">
          <label>Confirmed Time</label>
          <input
            value={confirmedTime ?? ""}
          //   onChange={(e) => {
          //   const errorconfirmTime=  validateConfirmTime(e.target.value);
        
          //   setErrors(errorconfirmTime);
          // }}
            disabled={true}
            type="time"
            id="confirmedTime"
            name="confirmedTime"
            className={
              "form-control" + (errors.confirmedTime ? " has-error" : "")
            }
          />
          <span className="help-block">{errors.confirmedTime}</span>
        </div>
      </div>}
      {isPaymentPendingOrder &&
      
      <div className="row">
        <div className="form-group col">
          <label>Confirmed Date</label>
          <input
            value={confirmedDate ?? ""}
            onChange={(e) => {
                                 const errorconfirmDate=validateConfirmDate(e.target.value);
                                 setConfirmDateEdit(true);
                                 setErrors(errorconfirmDate);
                                 
                                }}
            type="date"
            id="confirmedDate"
            name="confirmedDate"
            className={
              "form-control" + (errors.confirmedDate ? " has-error" : "")
            }
            disabled={false}
          />
          
          <span className="help-block">{errors.confirmedDate}</span>
        </div>
        <div className="form-group col">
          <label>Confirmed Time</label>
          <input
            value={confirmedTime ?? ""}
            onChange={(e) => {
            const errorconfirmTime=  validateConfirmTime(e.target.value);
        
            setErrors(errorconfirmTime);
          }}
            disabled={false}
            type="time"
            id="confirmedTime"
            name="confirmedTime"
            className={
              "form-control" + (errors.confirmedTime ? " has-error" : "")
            }
          />
          <span className="help-block">{errors.confirmedTime}</span>
        </div>
      </div>}
     { isPaymentPendingOrder?<></>:<div className="row">
        <div className="form-group col">
        <button
              className="btn button button-green btn-sm"
              onClick={() => showConfirmTime()}
            >
             Confirm Date & Time
            </button>
        </div>
      </div>}
   { isPaymentPendingOrder? <></>:<>{isCoachReviewRequired? <div className="row">
        <div className="form-group col-6">
          <label>Coach</label>
          <SelectCoach
            coaches={wellbeingCoaches ?? []}
            selectedCoachId={nutritionist}
            onCoachSelect={(coachId) => setNutritionist(coachId)}
          />
        </div>
      
       
      </div>:<></>}
      {(!isPaymentPendingOrder && isCoachReviewRequired)? <div className="row">
        <div className="form-group col-6">
          <label>Secondary Coach</label>
          <SelectCoach
            coaches={externalCoaches ?? []}
            selectedCoachId={externalCoach}
            onCoachSelect={(coachId) => setExternalCoach(coachId)}
          />
        </div>
      
       
      </div>:<></>}

      {isPaymentPendingOrder? <></>:<div className="row">
        <div className="form-group col-4">Recollection Required?</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="is_bundle"
              toggled={isRequireSampleReCollection?? false}
              onToggle={(isToggled) =>
                setIsRequireSampleReCollection(isToggled)
              }
              disabled={(!(["SAMPLE COLLECTED BY HOMECARE","SAMPLE RECEIVED","REPORT RECEIVED","PARTIAL RESULTS UPLOADED TO APP"].includes(orderStatus?.toUpperCase()))) || recollectionCount>3 }
            />
          </div>
        </div>}
      
      <div className="row">
        {/* <div className="form-group col-6">
          <label>Lab</label>
          <SelectLab
            labs={labs ?? []}
            selectedLabId={laboratory}
            onLabSelect={(labId) => setLaboratory(labId)}
          />
        </div> */}
        {/* <div className="form-group col">
          <label>Homecare Service</label>
          <SelectHomeCareService
            homeCareServices={homeCareServicesForCountry ?? []}
            selectedHomeCareServiceId={homeCareId}
            onHomeCareServiceSelect={(homeCareId) => setHomeCareId(homeCareId)}
          />
        </div> */}
      </div>
      <div className="row">
        {/* <div className="form-group col-6">
          <label htmlFor="labCost">Lab Costs</label>
          <input
            value={labCost ?? ""}
            onChange={(e) => setLabCost(e.target.valueAsNumber)}
            type="number"
            name="labCost"
            id="labCost"
            className="form-control py-2"
          />
          <span className="help-block">{errors.labCost}</span>
        </div> */}
        {/* <div className="form-group col">
          <label htmlFor="homeCareCost">Homecare Costs</label>
          <input
            value={homeCareCost ?? ""}
            onChange={(e) => setHomeCareCost(e.target.valueAsNumber)}
            type="number"
            name="homeCareCost"
            id="homeCareCost"
            className="form-control py-2"
          />
          <span className="help-block">{errors.homeCareCost}</span>
        </div> */}
      </div>
      <div className="form-content">
        <span>Operations Internal</span>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Handled By</label>
            <select
              name="handled_by"
              className={`form-control user-data-input`}
              onChange={(e) => setHandledById(e.target.value)}
              value={handledById}
            >
              {!handledById && (
                <option key={-1} value={-1} hidden={true}>
                  Select Handled By
                </option>
              )}
              {operationsUsers &&
                operationsUsers.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.userName}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">
              Custom Test Price Additional
            </label>
            <div className="input-group">
              <input
                value={customTestPriceAdditional ?? ""}
                onChange={(e) =>
                  setCustomTestPriceAdditional(e.target.valueAsNumber)
                }
                id="custom_test_price_additional"
                name="custom_test_price_additional"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Custom Test Price Additional"
                className={"form-control py-2"}
              />
              <span className="help-block">
                {errors.customTestPriceAdditional}
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab1"> {(itemType=="Blood_Package" || itemType=="Mini_Package"  || (itemType=="Custom_Package"  && isRequireSampleCollection) )? "Lab 1 *" : "Lab 1"}</label>
            <div className="input-group user-input-container">
              <select
                name="lab_1_id"
                className={`form-control user-data-input`}
                onChange={(e) => {setLab1Id(e.target.value); if(homeCareId!=null &&  homeCareId !=""  &&  (((!(isRequireSampleCollection ))&& isHomeAppointmentRequired))){setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value);setManuallyAssign(true);}else if(homeCareId!=null &&  homeCareId !="" && e.target.value !=null && e.target.value!=""  && ( (!(isCustomPackage) )|| (isRequireSampleCollection && isHomeAppointmentRequired)) ) {setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value); setManuallyAssign(true);} else{setManuallyAssign(false); setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="")[0].value)}}}
                value={lab1Id}
              >
                {!lab1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 1
                  </option>
                )}
                {<option key={""} value={""}>{"NONE"}</option>}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
            <span className="help-block">{errors.lab1Id ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 1 custom cost</label>
            <div className="input-group">
              <input
                value={lab1CustomCost ?? ""}
                onChange={(e) => setLab1CustomCost(e.target.value)}
                id="lab_1_custom_cost"
                name="lab_1_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Lab 1 custom cost"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.lab1CustomCost}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label> {(itemType=="Blood_Package" || itemType=="Mini_Package"  || (itemType=="Custom_Package"  && isRequireSampleCollection) )? "Homecare *" : "Homecare"} </label>
            {/* <SelectHomeCareService
            homeCareServices={homeCareServicesForCountry ?? []}
            selectedHomeCareServiceId={homeCareId}
            onHomeCareServiceSelect={(homeCareId) => setHomeCareId(homeCareId)}
            /> */}
            <select
              name="home_care"
              className={`form-control user-data-input`}
              onChange={(e) => { setHomeCareId(e.target.value); if (e.target.value != null && e.target.value != "" && lab1Id != null && lab1Id != "" && ((!(isCustomPackage)) || (isRequireSampleCollection && isHomeAppointmentRequired))) { setHomeCareStatus(homeCareStatus.filter((homecareStatus) => homecareStatus.status == "ASSIGNED")[0].value); setManuallyAssign(true); } else if (e.target.value != null && e.target.value != "" && ((!(isRequireSampleCollection)) && isHomeAppointmentRequired)) { setHomeCareStatus(homeCareStatus.filter((homecareStatus) => homecareStatus.status == "ASSIGNED")[0].value); setManuallyAssign(true); } else { setManuallyAssign(false); setHomeCareStatus(homeCareStatus.filter((homecareStatus) => homecareStatus.status == "")[0].value) } }}
              value={homeCareId}
              disabled={false}
            >
              {!homeCareId && (
                <option key={-1} value={-1} hidden={true}>
                  Select Home Care
                </option>
              )}
              {<option key={""} value={""}>{"NONE"}</option>}
              {homeCareServicesForCountry &&
                homeCareServicesForCountry.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.name}
                  </option>
                ))}
            </select>
            <span className="help-block">{errors.homecareId ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="homeCareCost">Homecare Costs</label>
            <input
              value={homeCareCost ?? ""}
              onChange={(e) => setHomeCareCost(e.target.valueAsNumber)}
              type="number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
              name="homeCareCost"
              id="homeCareCost"
              className="form-control py-2"
              placeholder="Homecare Costs"
            />
            <span className="help-block">{errors.homeCareCost}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor=""> Assign Nurse</label>
            <select
       className={
        "form-control py-2" +
        (errors.nurse ? " has-error" : "")
      }
      onChange={(e) => {console.log("prof Id",e.target.value);setProfessionalId(e.target.value);if(e.target.value !=null  && e.target.value!="" && lab1Id !=null && homeCareId!=null && homeCareId!="") setManuallyAssign(true); }}
      value={professionalId?professionalId:""}
    >
      {homeCareId?(<><option key={""} value={""} text={"NONE"} >{"NONE"}</option>
       { homecareNurses.length>0 && homecareNurses.map(({professionalId,fullName}, index) => (
          <option key={index} value={professionalId} text={fullName}>
            {fullName}
          </option>
        ))}</>):(props.nurses &&  <><option key={""} value={""} text={"NONE"} >{"NONE"}</option>
       {props.nurses.map(({professionalId,fullName}, index) => (
          <option key={index} value={professionalId} text={fullName}>
            {fullName}
          </option>
        ))}</>)
       
        }
    </select>
    <span className="help-block">{errors.professionalId ?? ""}</span>
    </div>

    <div className="form-group col">
    <label htmlFor=""> HomeCare Confirmation Status</label>
    <select
            className={
              "form-control py-2" +
              (errors.homecarestatus ? " has-error" : "")
            }
            onChange={(e) =>{ setHomeCareStatus(e.target.value); if(e.target.value=="ASSIGNED" && homeCareId!=null && homeCareId !="" && lab1Id !=null && lab1Id!="") setManuallyAssign(true)}}
            value={homecareStatus?homecareStatus:""}
          >
            {homeCareStatus &&
              homeCareStatus.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>

      </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="paymentGateway1">Payment Gateway 1</label>

            {/* <Dropdown
                value={paymentGateway1Id}
                onChange={(e)=>setPaymentGateway1Id(e.target.value)}
                id="payment_gateway_1"
                name="payment_gateway_1"
                placeholder="Payment Gateway 1"
                search
                selection
                options={paymentGateways ?? []}

                className={`form-control user-data-input`}
              /> */}
            <div className="input-group user-input-container">
              <select
                value={paymentGateway1Id}
                onChange={(e) => setPaymentGateway1Id(e.target.value)}
                id="payment_gateway_1"
                name="payment_gateway_1"
                placeholder="Payment Gateway 1"
                className={`form-control user-data-input`}
              >
                {!paymentGateway1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 1
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="referenceNumber1">Reference Number 1</label>
            <div className="input-group">
              <input
                value={referenceNumber1}
                onChange={(e) => setReferenceNumber1(e.target.value)}
                id="reference_number_1"
                name="reference_number_1"
                type="text"
                placeholder="Reference Number 1"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.referenceNumber1}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab2">Lab 2</label>
            <div className="input-group user-input-container">
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                onChange={(e) => setLab2Id(e.target.value)}
                value={lab2Id}
              >
                {!lab2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 2
                  </option>
                )}
                {<option key={""} value={""}>{"NONE"}</option>}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="lab2CustomCost">Lab 2 custom cost</label>
            <div className="input-group">
              <input
                value={lab2CustomCost}
                onChange={(e) => setLab2CustomCost(e.target.value)}
                id="lab_2_custom_cost"
                name="lab_2_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Lab 2 custom cost"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.lab2CustomCost}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 2</label>
            <div className="input-group user-input-container">
              {/* <Dropdown
                value={paymentGateway2Id}
                onChange={(e) => setPaymentGateway2Id(e.target.value) }
                id="payment_gateway_2"
                name="payment_gateway_2"
                placeholder="Payment Gateway 2"
                search
                selection
                options={paymentGateways ?? []}
        
                className={`form-control user-data-input`}
              /> */}
              <select
                name="payment_gateway_2"
                className={`form-control user-data-input`}
                value={paymentGateway2Id}
                onChange={(e) => setPaymentGateway2Id(e.target.value)}
                id="payment_gateway_2"
              >
                {!paymentGateway2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 2
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="referenceNumber2">Reference Number 2</label>
            <div className="input-group">
              <input
                value={referenceNumber2}
                onChange={(e) => setReferenceNumber2(e.target.value)}
                id="reference_number_2"
                name="reference_number_2"
                type="text"
                placeholder="Reference Number 2"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.referenceNumber2}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="coupon">Coupon Code</label>
            <div className="input-group user-input-container">
              <input
                value={couponCodeValue}
                onChange={(e) => setCouponCodeValue(e.target.value)}
                id="coupon_code"
                name="coupon_code"
                type="text"
                placeholder="Coupon Code"
                className={"form-control py-2"}
              />

              <span className="help-block">{errors.couponCodeValue}</span>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="coupon">Coupon Amount</label>
            <div className="input-group user-input-container">
              <input
                value={couponAmount}
                onChange={(e) => setCouponAmount(e.target.value)}
                id="coupon_amount"
                name="coupon_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Coupon Amount"
                className={"form-control py-2"}
              />

              <span className="help-block">{errors.couponAmount}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="revisedPrice">Revised Price</label>
            <div className="input-group">
              <input
                value={revisedPrice}
                onChange={(e) => setRevisedPrice(e.target.value)}
                id="revised_price"
                name="revised_price"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Revised Price"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.revisedPrice}</span>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="revisedPriceAfterCoupon">
              Revised Price after Coupon
            </label>
            <div className="input-group">
              <input
                value={revisedPriceAfterCoupon}
                onChange={(e) => setRevisedPriceAfterCoupon(e.target.value)}
                id="revised_price_after_coupon"
                name="revised_price_after_coupon"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Revised Price after Coupon"
                className={"form-control py-2"}
              />
              <span className="help-block">
                {errors.revisedPriceAfterCoupon}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="refundAmount">Refund Amount</label>
            <div className="input-group">
              <input
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                id="refund_amount"
                name="refund_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Refund Amount"
                className={"form-control py-2"}
              />
            </div>
            <span className="help-block">{errors.refundAmount}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestBiomarkers">
              Custom Test - Biomarkers
            </label>
            <div className="input-group">
              <textarea
                value={customTestBiomarkers}
                onChange={(e) => setCustomTestBiomarkers(e.target.value)}
                id="custom_test_biomarkers"
                name="custom_test_biomarkers"
                type="text"
                placeholder="Custom Test - Biomarkers"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.customTestBiomarkers}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="notes">Notes</label>
            <div className="input-group">
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.notes}</span>
            </div>
          </div>
        
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab1">Sample Details Lab 1*</label>
            <textarea
              value={sampleDetailsLab1 ?? ""}
              onChange={(e) => {setSampleDetailsLab1(e.target.value) }}
              id="sampleDetailsLab1"
              name="sampleDetailsLab1"
              placeholder="Sample Details Lab 1"
              className="form-control"
            />
           <span className="help-block">{sampleDetailModalData?sampleDetailModalData:""}</span><br/>
            <span className="help-block">{errors.sampleDetailsLab1 ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab2">Sample Details Lab 2</label>
            <textarea
              value={sampleDetailsLab2 ?? ""}
              onChange={(e) => setSampleDetailsLab2(e.target.value)}
              id="sampleDetailsLab2"
              name="sampleDetailsLab2"
              placeholder="Sample Details Lab 2"
              className="form-control"
            />
            
            <span className="help-block">{errors.sampleDetailsLab2 ?? ""}</span>
          </div>
        </div>
        {/*<div className="row">
          <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={isSampleValidated|| false}
                onChange={(event, value) => {

                 handleChange(event,value)
                  
                    
                  
                }}
                id="sampleValid"
                name="sampleValid"
                type="checkbox"
                className="larger-checkbox"
              />
              
               &nbsp;&nbsp;All  Sample Details Added*
               </div> 
               <span className="help-block">{errors.sampleValidity ?? ""}</span>
            </div>
            </div>*/}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specialInstructions">Special Instructions</label>
            <textarea
              value={specialInstructions ?? ""}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              id="specialInstructions"
              name="specialInstructions"
              placeholder="Special Instructions"
              className="form-control"
            />
            <span className="help-block">{errors.specialInstructions ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="paymentToBeCollected">Payment to be collected</label>
            <input
              value={paymentToBeCollected ?? ""}
              onChange={(e) => setPaymentToBeCollected(e.target.value)}
              id="paymentToBeCollected"
              name="paymentToBeCollected"
              placeholder="Payment to be collected"
              className="form-control"
             type="number"
            />
            <span className="help-block">{errors.paymentToBeCollected ?? ""}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
            <label htmlFor="labCost">Lab Cost</label>
            <div className="input-group">
              <input
                value={labCost ?? ""}
                onChange={(e) => setLabCost(e.target.value)}
                id="labCost"
                name="labCost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Lab cost"
                className={"form-control py-2"}
                disabled={!(isCustomPackage && isLabCostUpdateRequired)}
              />
            </div>
            <span className="help-block">{errors.labCost}</span>
          </div>
        <div className="form-group col">
          <label htmlFor="labCostNotes">Lab Costs Notes</label>
          <textarea
            value={labCostNotes ?? ""}
            onChange={(e) => setLabCostNotes(e.target.value)}
            type="text"
            name="labCostNotes"
            id="labCostNotes"
            className="form-control py-2"
          />
          <span className="help-block">{errors.labCostNotes}</span>
        </div>
        </div>
        <div className="row">
        <div className="form-group col-6">
          <label htmlFor="homecareCostNotes">Homecare Costs Notes</label>
          <textarea
            value={homecareCostNotes ?? ""}
            onChange={(e) => setHomecareCostNotes(e.target.value)}
            type="text"
            name="homecareCostNotes"
            id="homecareCostNotes"
            className="form-control py-2"
          />
          <span className="help-block">{errors.homecareCostNotes}</span>
        </div>
        </div>
      </div></>}
         
      <div className="row">

        {!isPaymentPendingOrder && <div className="form-group col text-left">
          <button
            onClick={()=>handleBothFunctions()}
            type="button"
          style={{ backgroundColor: "#CEE741", marginTop:"10px" }}
          className="btn px-5"
          disabled={!manuallyAssign}
          >
            Manually Assign
          </button>
        </div>}
        <div className="form-group col text-right">
          <button
            onClick={onHide}
            type="button"
            className="btn btn-outline-secondary"
          >
            Cancel
          </button>
         <button
            onClick={manuallyAssign?handleBothFunctions:handleSave}
            type="button"
            disable={props.isSaving ?? false}
            className="btn btn-outline-success ml-2"

          > 
            Save
          </button>
        </div>
      </div>
    </>
  );

  const confirmDateform = (
    <>
      {calculateDifference(confirmedDate) == "same" ? (
        <>Is it okay the Reschedule date is today?</>
      ) : calculateDifference(confirmedDate) > 0 ? (
        <>
          Are you sure you want to update to this date? This reschedule is{" "}
          {calculateDifference(confirmedDate) + 1} days from now!
        </>
      ) : calculateDifference(confirmedDate) == 0 ? (
        <>
          Are you sure you want to update to this date? This reschedule is{" "}
          {calculateDifference(confirmedDate) + 1} day from now!
        </>
      ) : calculateDifference(confirmedDate) == -1 ? (
        <>
          Are you sure you want to update to this date? This reschedule is{" "}
          {Math.abs(calculateDifference(confirmedDate))} day past from now !
        </>
      ) : (
        <>
          Are you sure you want to update to this date? This reschedule is{" "}
          {Math.abs(calculateDifference(confirmedDate))} days past from now !
        </>
      )}
    </>
  );

 const  updateOrderConfirmTime = async (payload) => {
    const logged_in_user_id = getUserId()
    const config = {
      method: "POST",
      url: `${BASE_URL_UPDATE_CONFIRMTIME}/?logged_in_user_id=${logged_in_user_id}`,
      data: payload,
    };
    return await PhoenixAPI.request(config);
  };
  const updateResampleCollection=async(payload)=>{
    const config = {
      method: "PATCH",
      url: `${BASE_URL_ReSample_COllection}/${itemOrderId}`,
      data: payload,
    };
    return await PhoenixAPI.request(config);
  }

 const validateCnfirmDate = (
  confirmDate,
   phase,
   oldConfirmDate,
   oldConfirmTime,
   confirmedTime
 ) => {
   const today = moment().format("YYYY-MM-DD");
   const formattedDate = moment(confirmDate, "YYYY-MM-DD", true);
   const oldFormattedDate = moment(oldConfirmDate, "YYYY-MM-DD", true);

   if (phase == "Confirm") {
     if (formattedDate.isValid()) {
      errors.confirmedDate=""
       return true;
     } else {
        errors.confirmedDate="Invalid date."
       return false;
     }
   } else if (phase == "Edit") {
     if (formattedDate.isValid()) {
       if (formattedDate.isSameOrAfter(today)) {
         const isSameDate = formattedDate.isSame(oldFormattedDate, "day");
         const isDateToday = formattedDate.isSame(today);
         const currentTime = moment();
         if (isSameDate) {
           const isSameTime = moment(confirmedTime, "HH:mm").isSame(
             moment(oldConfirmTime, "HH:mm"),
             "minute"
           );
           if (isSameTime) {
             errors.confirmedDate=
               "Date & Time should differ from previous"
             

             return false;
           }
           if (isDateToday) {
             if (moment(confirmedTime, "HH:mm").isAfter(currentTime)) {
              
               return true;
             } else {
               errors.confirmedTime="Time should greater from present";
               return false;
             }
           }
         }

         if (isDateToday) {
           const currentTime = moment();
           if (moment(confirmedTime, "HH:mm").isAfter(currentTime)) {
            
             return true;
           } else {
            errors.confirmedTime="Time should greater from present";

             return false;
           }
         }

         errors.confirmedDate=""
         return true;
       } else {
        errors.confirmedDate="Date should not be past date";
         return false;
       }
     } else {
       errors.confirmedDate="Invalid date."
       return false;
     }
   }
 };

 const validateConfirmValidation=()=>{
     let confirmValidation=true;
      console.log("confrimed date and time:", {confirmedDate, confirmedTime});
      if(!confirmedDate){
        errors.confirmedDate = "Date is Required";
        confirmValidation=false
      }
      if(!confirmedTime){
        errors.confirmedTime = "Time is Required";
        confirmValidation=false
      }


      setErrors(errors)

      return confirmValidation
 }
const handleConfirmDateTime = async (phase) => {
  
  let isValidDate = false;
  let isValidClientConfirm = true;

  if (
    oldConfirmDate == null ||
    oldConfirmDate == "" ||
    oldConfirmDate == undefined
  ) {
    const formattedDate = moment(confirmedDate, "YYYY-MM-DD", true);
    if (formattedDate.isValid()) {
       setConfirmDateModalError("")
      isValidDate = true;
    } else {
      setConfirmDateModalError("selected date not proper");
      isValidDate = false;
    }
  } else if (oldConfirmDate !== null) {
    isValidDate =
      phase == "Confirm"
        ? validateCnfirmDate(confirmedDate, phase)
        : validateCnfirmDate(
          confirmedDate,
            phase,
            oldConfirmDate,
            oldConfirmTime,
            confirmedTime,
            oldConfirmTime
          );
  }
 

  if (phase == "Confirm") {
    if (rescheduleReason || (clientConfirm && !submitEnableConfirmTime)) {
      isValidClientConfirm = false;

      setConfirmTimeModalError("Please do changes before submit");
    } else if (!clientConfirm) {
      setConfirmTimeModalError("Please check confirm client")
      isValidClientConfirm = false;
    } else {
      setConfirmTimeModalError("");
    }
  }
  if (phase == "Edit") {
    if (
      rescheduleReason == null ||
      rescheduleReason == undefined ||
      rescheduleReason == ""
    ) {
      if(confirmReCollection){
        setConfirmTimeModalError("");
      }
      else{
      isValidClientConfirm = false;
      setConfirmTimeModalError("Please enter reason");
      }
    } else {
      setConfirmTimeModalError("");
    }
  }

  const confirmValidation=validateConfirmValidation()
  if(confirmValidation && isValidDate && isValidClientConfirm){
    let payload = {};
    if (phase == "Confirm") {
      payload = {
        modified_by: getUserId(),
        isCheckBoxEnabled: clientConfirm,
        orderId: orderId,
      };
      try{
        const response = await updateOrderConfirmTime(payload);
        console.log("response:", response)
        hideConfirmDatePopUp()
        onSaveReschedule(true)
        
      }
      catch(error){
        console.log("Error on saving confirmed time", error);
        
      }
    } else if (phase == "Edit") {
      setIsRescheduleDateConfirmPopup(true);
    }
  }
  else{
    if (!submitEnableConfirmTime)
        showErrorNotification("Please do changes before submit");
      else showErrorNotification("There are some invalid fields")
  }
  
};

const handleEditReschedule=()=>{

  setOldConfirmDate(confirmedDatee)
  setOldConfirmTime(confirmedTimee)
  setEditConfirmTime(true)
  setSubmitEnableTime(true)
}

const hideSampleDetailWarning=()=>{
  setShowSampleDetailWarning(false);
  setSampleDetailModalData("");
}

  const rescheduleDateTimePopForm = (
    <>
      <div className="form-row">
        <div className="col-6 form-group">
          <label>Confirm Date *</label>
          <input
            name="confirmedDate"
            type="date"
            placeholder="Rollout Date"
            // min={startDate}
            disabled={!editConfirmTime}
            className={
              "form-control" + (errors.confirmedDate ? " has-error" : "")
            }
            onChange={(e) => {
              const errorconfirmDate=validateConfirmDate(e.target.value);
              // setConfirmDateEdit(true);
              setErrors(errorconfirmDate);
              
             }}
            value={confirmedDate ??""}
          ></input>
          <span className="help-block">{errors.confirmedDate}</span>
        </div>
        <div className="col-6 form-group">
          <label>Confirm Time *</label>

          <input
            name="confirmedTime"
            type="time"
            placeholder="Rollout TIme"
            className={
              "form-control" + (errors.confirmedTime ? " has-error" : "")
            }
            onChange={(e) => {
              const errorconfirmTime=  validateConfirmTime(e.target.value);
          
              setErrors(errorconfirmTime);
            }}
            value={confirmedTime}
            disabled={!editConfirmTime}
          ></input>
          <span className="help-block">{errors.confirmedTime}</span>
        </div>
      </div>
      <div className="form-row ">
        {(editConfirmTime || rescheduleReason) && !confirmReCollection ? (
          <div className="form-group col">
            Reschedule Reason *
            <textarea
              value={rescheduleReason ?? ""}
              onChange={(event) => {
                setRescheduleReason(event.target.value);
              }}
              id="reScheduleNotes"
              name="reScheduleNotes"
              placeholder="Reschedule Notes"
              className="form-control"
            ></textarea>
          </div>
        ) : !editConfirmTime ? (
          <div className="form-group ml-2">
            <input
              checked={clientConfirm}
              onChange={(event) => {
               setClientConfirm(event.target.checked)
               setSubmitEnableTime(true)
              }}
              type="checkbox"
            />{" "}
            &nbsp;
            <label style={{ textAlign: "center" }}>Confirmed with Client</label>
          </div>
        ) : (
          <></>
        )}
        {!editConfirmTime ? (
          <div className="form-group col text-right">
            <span
               
              onClick={()=>
                handleEditReschedule()
                }
              style={{ cursor: "pointer", color: "#2D9BF0" }}
            >
              Edit
            </span>{" "}
          </div>
        ) : (
          <></>
        )}
      </div>
      <span className="help-block">{confirmTimeModalError}</span>
    </>
  );

const showNotification = (notificationMessage, isSuccessMessage) =>{
 return props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true
  })
}


  const showSuccessNotification=(notificationMessage)=>{
    return showNotification(notificationMessage, true)
  }

  const showErrorNotification=(notificationMessage)=>{
    return showNotification(notificationMessage,false)
  }

  const handleChange = (event, value) => {
    if (sampleDetailsLab1 !== null && sampleDetailsLab1 !== "") {
      if (orderIsSampleValidated == null || !orderIsSampleValidated) {
        if (sampleDetails.statusCode == 200) {
          setIsSampleValidated(event.target.checked);
        }
        else if (sampleDetails.statusCode == 400 && sampleDetailsLab1 !== orderSampleDetailsLab1) {
          setShowSampleDetailWarning("");
          setIsSampleValidated(event.target.checked);
        }
        else {
          validateSampleDetails();
          setIsSampleValidated(event.target.checked);
        }
      }
      else {
        setIsSampleValidated(event.target.checked);
      }
    }
    else {
      validateSampleDetails();
      setIsSampleValidated(event.target.checked);
    }
  }
  return (<>
    <Modal
      size={isPaymentPendingOrder?"md":"xl"}
      show={isVisible}
      onHide={onHide}
      className={!isPaymentPendingOrder?"user-data-modal":""}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{`Edit ${itemNameEnglish}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>

<Modal
size="sm"
show={isrescheduleDateConfirmPopup}

>
<Modal.Header >
  <Modal.Title>Confirm Reschedule Date</Modal.Title>
</Modal.Header>
<Modal.Body>
{confirmDateform}
</Modal.Body>
<Modal.Footer>
<button
    onClick={() => {
    //  this.rescheduleDateConfirm(this.state.confirmDate);
        //  hideRescheduleDateModal(); // hiding the modal to proceed save button
       handleConfirmDateEditPopup()
      
    }}
    className="btn btn-danger rounded-pill px-4 py-1"
  >
    Confirm
  </button>
  <button
    className="btn btn-secondary rounded-pill px-4 py-1"
    onClick={cancelRescheduleDate}
  >
    Cancel
  </button>
  
</Modal.Footer>
</Modal>

    <Modal
      size="sm"
      show={showSelectMemberPopup}

    >

      <Modal.Body>
      
          {renderFamilyMemberChange()}
        
      
      </Modal.Body>
      <Modal.Footer>
      <button
            onClick={() => handleSaveSelectMemberModal()}
            className="btn btn-outline-success rounded-pill px-4 py-1"
          >
            Yes
          </button>
          <button
            className="btn btn-secondary rounded-pill px-4 py-1"
            onClick={() => hideSelectMemberModal()}
          >
            Cancel
          </button>
      </Modal.Footer>
    </Modal>
    <Modal
      size="sm"
      show={isShowErrorModal}
    >
      <Modal.Body>
        Please fill in the Service Reference field to complete the order!
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => setIsisShowErrorModal(false)}
          className="btn btn-outline-success rounded-pill px-4 py-1"
        >
          Okay
          </button>
      </Modal.Footer>
    </Modal>

    <Modal
      size="md"
      show={confirmDatePopup}
      onHide={hideConfirmDatePopUp}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Reschedule Date  </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rescheduleDateTimePopForm}
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {
            if(editConfirmTime){
              handleConfirmDateTime("Edit")
            }
            else{
              handleConfirmDateTime("Confirm")
            }
          }}
          className="btn btn-danger rounded-pill px-4 py-1"
        >
          Submit
        </button>
        <button
          className="btn btn-secondary rounded-pill px-4 py-1"
          onClick={()=>hideConfirmDatePopUp()}
        >
          Cancel
        </button>

      </Modal.Footer>
    </Modal>
    <Modal
          show={showSampleDetailWarning}
          onHide={hideSampleDetailWarning}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning :</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Sample Detail Warning</label>

                <textarea
                  type="text"
                  name="special_instructions"
                  className={"form-control "}
                  value={sampleDetailModalData??""}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={sampleDetailModalData ? false : true}
              variant="primary"
              onClick={hideSampleDetailWarning}
            >
              OK
            </button>
            
          </Modal.Footer>
        </Modal>

        <Modal
        size="sm"
        show={isRequireSampleReCollection}
        onHide={hideSampleRecollectionConfirmation}>
          <Modal.Body>
        Do you want to go ahead with Sample Recollection?
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => {setConfirmReCollection(true);setConfirmDatePopup(true);hideSampleRecollectionConfirmation();}}
          className="btn btn-outline-success rounded-pill px-4 py-1"
        >
          Yes
          </button>
          <button
            className="btn btn-secondary rounded-pill px-4 py-1"
            onClick={() => hideSampleRecollectionConfirmation()}
          > No</button>

        </Modal.Footer>
        </Modal>


</> );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCartOrderViewModal);
