import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import { savePopupBanner, updatedPopupBanner } from "services/BannerService";

const BASE_URL_POPUP_BANNERS = "/popup-banner";
const ONE_FIFTY_KILO_BYTE = 153600;

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class PopUpBanners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banners: [],
      banner: {},
      searchTerm: null,
      searchApplied: false,
      isBannerViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Banner Name",
        selector: "bannerName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirection Link (English)",
        selector: "redirectionLinkEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Redirection Link (Arabic)",
        selector: "redirectionLinkArabic",
        wrap: true,
        sortable: true,
      },
      {
        name: "Banner Type",
        selector: "bannerType",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.status === "Active" ? true : false} />,
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchPopupBanners();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedCountry !== nextProps.selectedCountry) {
      this.setState({
        countries: nextProps.countries,
        selectedCountry: nextProps.selectedCountry,
      });
    }
  }

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  fetchPopupBanners = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(`${BASE_URL_POPUP_BANNERS}`);

      const banners = response.data || [];
      this.setState({ banners });
    } catch (error) {
      console.log("Error in Fetching Popup Banners", error.message);
      this.showErrorNotification("Error in Fetching Popup Banners");
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({
      banner: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewBanner(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  handleViewBanner = (bannerId) => {
    const banners = this.state.banners ?? [];
    const banner = banners.find((it) => it.id === bannerId);
    if (!banner) {
      console.log(`Popup Banner with ID ${bannerId} is not found!`);
      return;
    }

    banner.imageSrc_english = banner.imageEnglish;
    banner.imageSrc_arabic = banner.imageArabic;

    this.setState({ banner, isNewBanner: false }, () =>
      this.showBannerViewModal()
    );
  };

  handleSaveBanner = async () => {
    const { banner } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = banner.id !== null && banner.id !== undefined;
      const response = isValidId
        ? await updatedPopupBanner(banner)
        : await savePopupBanner(banner);

      this.hideBannerViewModal();
      console.log("Response", response);
      this.showSuccessNotification(
        isValidId
          ? "Popup Banner Updated Successfully."
          : "Popup Banner Created Successfully."
      );
      this.fetchPopupBanners();
    } catch (error) {
      console.log("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { banner = {} } = this.state;
    const errors = {};

    const {
      bannerName,
      bannerType,
      bannerTitleEnglish,
      bannerTitleArabic,
      bannerDescriptionEnglish,
      bannerDescriptionArabic,
      ctaTextEnglish,
      ctaTextArabic,
      videoLinkEnglish,
      videoLinkArabic,
      redirectionLinkEnglish,
      redirectionLinkArabic,
      status,
      imageEnglish,
      imageArabic,
    } = banner;

    if (!bannerName || bannerName.trim() === "")
      errors.bannerName = "Banner Name cannot be blank";
    else if (bannerName.length > 100)
      errors.bannerName = "Banner Name is limited to a 100 characters";

      if(bannerType==="Static"){
        if (!bannerTitleEnglish || bannerTitleEnglish.trim() === "")
        errors.bannerTitleEnglish = "Banner Title English cannot be blank";
      else if (bannerTitleEnglish.length > 100)
        errors.bannerTitleEnglish =
          "Banner Title English is limited to a 100 characters";
  
      if (!bannerTitleArabic || bannerTitleArabic.trim() === "")
        errors.bannerTitleArabic = "Banner Title Arabic cannot be blank";
      else if (bannerTitleArabic.length > 100)
        errors.bannerTitleArabic =
          "Banner Title Arabic is limited to a 100 characters";
      }

    // if (!bannerDescriptionEnglish || bannerDescriptionEnglish.trim() === "")
    //   errors.bannerDescriptionEnglish =
    //     "Banner Description English cannot be blank";
    // else if (bannerDescriptionEnglish.length > 500)
    //   errors.bannerDescriptionEnglish =
    //     "Banner Description English is limited to a 500 characters";

    // if (!bannerDescriptionArabic || bannerDescriptionArabic.trim() === "")
    //   errors.bannerDescriptionArabic =
    //     "Banner Description Arabic cannot be blank";
    // else if (bannerDescriptionArabic.length > 500)
    //   errors.bannerDescriptionArabic =
    //     "Banner Description Arabic is limited to a 500 characters";

    if (!ctaTextEnglish || ctaTextEnglish.trim() === "")
      errors.ctaTextEnglish = "CTA Text English cannot be blank";
    else if (ctaTextEnglish.length > 100)
      errors.ctaTextEnglish = "CTA Text English is limited to a 100 characters";

    if (!ctaTextArabic || ctaTextArabic.trim() === "")
      errors.ctaTextArabic = "CTA Text Arabic cannot be blank";
    else if (ctaTextArabic.length > 100)
      errors.ctaTextArabic = "CTA Text Arabic is limited to a 100 characters";

    if (!redirectionLinkEnglish || redirectionLinkEnglish.trim() === "")
      errors.redirectionLinkEnglish =
        "Redirection Link English cannot be blank";
    else if (redirectionLinkEnglish.length > 500)
      errors.redirectionLinkEnglish =
        "Redirection Link English is limited to a 500 characters";

    if (!redirectionLinkArabic || redirectionLinkArabic.trim() === "")
      errors.redirectionLinkArabic = "Redirection Link Arabic cannot be blank";
    else if (redirectionLinkArabic.length > 500)
      errors.redirectionLinkArabic =
        "Redirection Link Arabic is limited to a 500 characters";

        if(bannerType==="Video"){
          if (!videoLinkEnglish || videoLinkEnglish.trim() === "")
          errors.videoLinkEnglish = "Video Link English cannot be blank";
        else if (videoLinkEnglish.length > 255)
          errors.videoLinkEnglish =
            "Video Link English is limited to a 255 characters";
    
        if (!videoLinkArabic || videoLinkArabic.trim() === "")
          errors.videoLinkArabic = "Video Link Arabic cannot be blank";
        else if (videoLinkArabic.length > 255)
          errors.videoLinkArabic =
            "Video Link Arabic is limited to a 255 characters";
        }
   
    if(bannerType==="Static"){
      if (!imageEnglish)
      errors.imageEnglish = "Banner Image English cannot be blank";
    else if (
      imageEnglish &&
      imageEnglish.size &&
      imageEnglish.size > ONE_FIFTY_KILO_BYTE
    )
      errors.imageEnglish = "Banner Image English should be less than 150 KB!";

    if (!imageArabic)
      errors.imageArabic = "Banner Image Arabic cannot be blank";
    else if (
      imageArabic &&
      imageArabic.size &&
      imageArabic.size > ONE_FIFTY_KILO_BYTE
    )
      errors.imageArabic = "Banner Image Arabic should be less than 150 KB!";
    }
   

    if (status === null) errors.status = "Status is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewBanner = () => {
    // const { banners, selectedCountry } = this.state;
    // const filteredBanners=banners.filter((p)=>p.bannerType==="Static" || "Video")
    // console.log("banners:", banners.filter((p)=>p.bannerType==="Static" || "Video"))
    // const bannerForSelectedCountry =
    // filteredBanners && selectedCountry
    //   ? filteredBanners.find((p) => p.country === selectedCountry.id)
    //   : null;
    //   console.log("bannerForSelectedCountry:",bannerForSelectedCountry)
    //   if(bannerForSelectedCountry){
    //     const message = bannerForSelectedCountry.status==="Active"?"There is already an active banner available.":"Please activate banner.";
    //     this.showErrorNotification(message);
    //   }
    //   else{
    //     this.setState({ isNewBanner: true }, () => this.showBannerViewModal());
    //   }
    this.setState({ isNewBanner: true }, () => this.showBannerViewModal());
  };

  showBannerViewModal = () => {
    const { selectedCountry, isNewBanner } = this.state;

    if (isNewBanner) {
      const banner = {
        country: selectedCountry?.id,
        status: "Inactive",
      };
      this.setState({
        banner,
        isBannerViewModalVisible: true,
      });
    } else {
      this.setState({
        isBannerViewModalVisible: true,
      });
    }
  };

  hideBannerViewModal = () => {
    this.setState({ isBannerViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewBanner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  handleChange = ({ currentTarget: input }) => {
    const banner = { ...this.state.banner };
    banner[input.name] = input.value;
    this.setState({ banner });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  selectDesktopEnglishImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        imageEnglish: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          imageSrc_english: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  selectDesktopArabicImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const newbanner = {
        ...this.state.banner,
        imageArabic: image,
      };
      this.setState({ banner: newbanner });
      const reader = new FileReader();
      reader.onloadend = function () {
        const banner = {
          ...this.state.banner,
          imageSrc_arabic: [reader.result],
        };
        this.setState({
          banner,
        });
      }.bind(this);
      reader.readAsDataURL(image);
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredBanners = () => {
    const banners = this.getBannersForCurrentCountry();
    return this.getSearchedBanners(banners);
  };

  getBannersForCurrentCountry = () => {
    const { banners, selectedCountry } = this.state;
    return banners && selectedCountry
      ? banners.filter((banner) => banner.country === selectedCountry.id)
      : [];
  };

  getSearchedBanners = (banners) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return banners;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return banners.filter((banner) => {
      const { id, bannerName, redirectionLink, rank } = banner;
      return (
        (bannerName && bannerName.toLowerCase().indexOf(newValue) !== -1) ||
        (redirectionLink &&
          redirectionLink.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      banners,
      searchTerm,
      banner,
      searchApplied,
      countries,
      selectedCountry,
      errors,
      editables,
      loadError,
      isNewBanner,
    } = this.state;

    const isEditable = this.props.isEditable ?? true;
    const filteredBanners = this.getFilteredBanners(banners) ?? [];

    const staticForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Banner Title(English) *</label>
            <div className="input-group">
              <input
                value={banner?.bannerTitleEnglish || ""}
                onChange={this.handleChange}
                id="bannerTitleEnglish"
                name="bannerTitleEnglish"
                type="text"
                placeholder="Enter Banner Title English"
                className={
                  "form-control py-2" +
                  (errors.bannerTitleEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerTitleEnglish}
              />
              {this.renderPencilIcon("bannerTitleEnglish")}
            </div>
            <span className="help-block">{errors.bannerTitleEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">Banner Title (Arabic) *</label>
            <div className="input-group">
              <input
                value={banner?.bannerTitleArabic || ""}
                onChange={this.handleChange}
                id="bannerTitleArabic"
                name="bannerTitleArabic"
                type="text"
                placeholder="Enter Banner Title Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.bannerTitleArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerTitleArabic}
              />
              {this.renderPencilIcon("bannerTitleArabic")}
            </div>
            <span className="help-block">{errors.bannerTitleArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Banner Description(English) </label>
            <div className="input-group">
              <input
                value={banner?.bannerDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="bannerDescriptionEnglish"
                name="bannerDescriptionEnglish"
                type="text"
                placeholder="Enter Banner Description English"
                className={
                  "form-control py-2" +
                  (errors.bannerDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerDescriptionEnglish}
              />
              {this.renderPencilIcon("bannerDescriptionEnglish")}
            </div>
            <span className="help-block">
              {errors.bannerDescriptionEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">Banner Description (Arabic)</label>
            <div className="input-group">
              <input
                value={banner?.bannerDescriptionArabic || ""}
                onChange={this.handleChange}
                id="bannerDescriptionArabic"
                name="bannerDescriptionArabic"
                type="text"
                placeholder="Enter Banner Description Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.bannerDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerDescriptionArabic}
              />
              {this.renderPencilIcon("bannerDescriptionArabic")}
            </div>
            <span className="help-block">{errors.bannerDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">CTA Text (English) *</label>
            <div className="input-group">
              <input
                value={banner?.ctaTextEnglish || ""}
                onChange={this.handleChange}
                id="ctaTextEnglish"
                name="ctaTextEnglish"
                type="text"
                placeholder="Enter CTA Text English"
                className={
                  "form-control py-2" +
                  (errors.ctaTextEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.ctaTextEnglish}
              />
              {this.renderPencilIcon("ctaTextEnglish")}
            </div>
            <span className="help-block">{errors.ctaTextEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">CTA Text (Arabic) *</label>
            <div className="input-group">
              <input
                value={banner?.ctaTextArabic || ""}
                onChange={this.handleChange}
                id="ctaTextArabic"
                name="ctaTextArabic"
                type="text"
                placeholder="Enter CTA Text Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.ctaTextArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.ctaTextArabic}
              />
              {this.renderPencilIcon("ctaTextArabic")}
            </div>
            <span className="help-block">{errors.ctaTextArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="redirectionLinkEnglish">
              Redirection Link (English) *
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkEnglish || ""}
                onChange={this.handleChange}
                id="redirectionLinkEnglish"
                name="redirectionLinkEnglish"
                type="text"
                placeholder="Enter Redirection Link"
                className={
                  "form-control py-2" +
                  (errors.redirectionLinkEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkEnglish}
              />
              {this.renderPencilIcon("redirectionLinkEnglish")}
            </div>
            <span className="help-block">{errors.redirectionLinkEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="redirectionLinkArabic">
              Redirection Link (Arabic) *
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkArabic || ""}
                onChange={this.handleChange}
                id="redirectionLinkArabic"
                name="redirectionLinkArabic"
                type="text"
                placeholder="Enter Redirection Link Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.redirectionLinkArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkArabic}
              />
              {this.renderPencilIcon("redirectionLinkArabic")}
            </div>
            <span className="help-block">{errors.redirectionLinkArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Banner Image (English) *</label>
            <div className="input-group">
              <input
                onChange={this.selectDesktopEnglishImage}
                id="imageInput"
                name="imageEnglish"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.imageEnglish}
                className={
                  "form-control" + (errors.imageEnglish ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("imageEnglish")}
            </div>
            <span className="help-block">{errors.imageEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="imageInput">Banner Image (Arabic) *</label>
            <div className="input-group">
              <input
                onChange={this.selectDesktopArabicImage}
                id="imageInput"
                name="imageArabic"
                type="file"
                accept="image/*"
                disabled={!isNewBanner && !editables.imageArabic}
                className={
                  "form-control" + (errors.imageArabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("imageArabic")}
            </div>
            <span className="help-block">{errors.imageArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
          <label htmlFor="imageInput">Banner Preview English</label>
            {banner?.imageSrc_english && (
              <>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={banner.imageSrc_english}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
          <div className="form-group col">
          <label htmlFor="imageInput">Banner Preview Arabic</label>
            {banner?.imageSrc_arabic && (
              <>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={banner.imageSrc_arabic}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );

    const videoForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Banner Description(English) </label>
            <div className="input-group">
              <input
                value={banner?.bannerDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="bannerDescriptionEnglish"
                name="bannerDescriptionEnglish"
                type="text"
                placeholder="Enter Banner Description English"
                className={
                  "form-control py-2" +
                  (errors.bannerDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerDescriptionEnglish}
              />
              {this.renderPencilIcon("bannerDescriptionEnglish")}
            </div>
            <span className="help-block">
              {errors.bannerDescriptionEnglish}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">Banner Description (Arabic) </label>
            <div className="input-group">
              <input
                value={banner?.bannerDescriptionArabic || ""}
                onChange={this.handleChange}
                id="bannerDescriptionArabic"
                name="bannerDescriptionArabic"
                type="text"
                placeholder="Enter Banner Description Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.bannerDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerDescriptionArabic}
              />
              {this.renderPencilIcon("bannerDescriptionArabic")}
            </div>
            <span className="help-block">{errors.bannerDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">CTA Text (English) *</label>
            <div className="input-group">
              <input
                value={banner?.ctaTextEnglish || ""}
                onChange={this.handleChange}
                id="ctaTextEnglish"
                name="ctaTextEnglish"
                type="text"
                placeholder="Enter CTA Text English"
                className={
                  "form-control py-2" +
                  (errors.ctaTextEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.ctaTextEnglish}
              />
              {this.renderPencilIcon("ctaTextEnglish")}
            </div>
            <span className="help-block">{errors.ctaTextEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="internalName">CTA Text (Arabic) *</label>
            <div className="input-group">
              <input
                value={banner?.ctaTextArabic || ""}
                onChange={this.handleChange}
                id="ctaTextArabic"
                name="ctaTextArabic"
                type="text"
                placeholder="Enter CTA Text Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.ctaTextArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.ctaTextArabic}
              />
              {this.renderPencilIcon("ctaTextArabic")}
            </div>
            <span className="help-block">{errors.ctaTextArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="redirectionLinkEnglish">
              Redirection Link (English) *
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkEnglish || ""}
                onChange={this.handleChange}
                id="redirectionLinkEnglish"
                name="redirectionLinkEnglish"
                type="text"
                placeholder="Enter Redirection Link"
                className={
                  "form-control py-2" +
                  (errors.redirectionLinkEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkEnglish}
              />
              {this.renderPencilIcon("redirectionLinkEnglish")}
            </div>
            <span className="help-block">{errors.redirectionLinkEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="redirectionLinkArabic">
              Redirection Link (Arabic) *
            </label>
            <div className="input-group">
              <input
                value={banner?.redirectionLinkArabic || ""}
                onChange={this.handleChange}
                id="redirectionLinkArabic"
                name="redirectionLinkArabic"
                type="text"
                placeholder="Enter Redirection Link Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.redirectionLinkArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.redirectionLinkArabic}
              />
              {this.renderPencilIcon("redirectionLinkArabic")}
            </div>
            <span className="help-block">{errors.redirectionLinkArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="VideoLinkEnglish">Video Link (English) *</label>
            <div className="input-group">
              <input
                value={banner?.videoLinkEnglish || ""}
                onChange={this.handleChange}
                id="videoLinkEnglish"
                name="videoLinkEnglish"
                type="text"
                placeholder="Enter Video Link"
                className={
                  "form-control py-2" +
                  (errors.videoLinkEnglish ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.videoLinkEnglish}
              />
              {this.renderPencilIcon("videoLinkEnglish")}
            </div>
            <span className="help-block">{errors.videoLinkEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="videoLinkArabic">Video Link (Arabic) *</label>
            <div className="input-group">
              <input
                value={banner?.videoLinkArabic || ""}
                onChange={this.handleChange}
                id="videoLinkArabic"
                name="videoLinkArabic"
                type="text"
                placeholder="Enter video Link Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.videoLinkArabic ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.videoLinkArabic}
              />
              {this.renderPencilIcon("videoLinkArabic")}
            </div>
            <span className="help-block">{errors.videoLinkArabic}</span>
          </div>
        </div>
      </>
    );

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          {!isNewBanner && (
            <div className="form-group col">
              <label htmlFor="ID">ID</label>
              <div className="input-group">
                <input
                  value={banner?.id || ""}
                  onChange={this.handleChange}
                  id="ID"
                  name="ID"
                  type="text"
                  placeholder="ID"
                  className={"form-control py-2"}
                  disabled={true}
                />
              </div>
            </div>
          )}
          <div
            className={`form-group col ${
              isNewBanner ? "form-group col-6" : ""
            }`}
          >
            <label htmlFor="internalName">Banner Name*</label>
            <div className="input-group">
              <input
                value={banner?.bannerName || ""}
                onChange={this.handleChange}
                id="bannerName"
                name="bannerName"
                type="text"
                placeholder="Enter Banner Name"
                className={
                  "form-control py-2" + (errors.bannerName ? " has-error" : "")
                }
                disabled={!isNewBanner && !editables.bannerName}
              />
              {this.renderPencilIcon("bannerName")}
            </div>
            <span className="help-block">{errors.bannerName}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <div className="" style={{ display: "flex", marginTop: "32px" }}>
              <div className="input-group">
                <input
                  style={{ height: "18px" }}
                  value={"Static"}
                  checked={banner.bannerType === "Static" || ""}
                  onChange={(event, value) => {
                    this.setState({
                      banner: {
                        ...banner,
                        bannerType: event.target.value,
                      },
                    });
                  }}
                  id="static"
                  name="static"
                  type="radio"
                  className={
                    "form-control" + (errors.bannerType ? " has-error" : "")
                  }
                  disabled={!isNewBanner && !editables.bannerType}
                />
                <label htmlFor="static">Static</label>
              </div>
              <div className="input-group">
                <input
                  style={{ height: "18px" }}
                  value={"Video"}
                  checked={banner.bannerType === "Video" || ""}
                  onChange={(event, value) => {
                    this.setState({
                      banner: {
                        ...banner,
                        bannerType: event.target.value,
                      },
                    });
                  }}
                  id="video"
                  name="video"
                  type="radio"
                  className={
                    "form-control" + (errors.bannerType ? " has-error" : "")
                  }
                  disabled={!isNewBanner && !editables.bannerType}
                />
                <label htmlFor="video">Video</label>
              </div>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status *</label>
            <div className="input-group">
              <Dropdown
                value={banner?.status}
                onChange={(event, value) => {
                  this.setState({
                    banner: {
                      ...banner,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewBanner && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        {banner?.bannerType === "Static" && staticForm}
        {banner?.bannerType === "Video" && videoForm}
      </div>
    );
    return (
      <>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm || ""}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNewBanner}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>

          <Card body>
            <h3 className="p-head">Popup Banners</h3>
            <DataTable
              data={filteredBanners}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              // pagination
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + filteredBanners.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.isBannerViewModalVisible}
            onHide={this.hideBannerViewModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Banner Name: </span>
                  {banner?.bannerName || ""}
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                onClick={this.handleSaveBanner}
                disabled={!isEditable}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_website_categories">
                <Tab eventKey="key_details_form" title="Details">
                  {detailsForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PopUpBanners);
