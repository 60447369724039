import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_WEBSITE_BANNERS = "/website/banner";
const BASE_URL_POPUP_BANNERS = "/popup-banner";
const CREATE_BANNER = "create";
const UPDATE_BANNER = "update"
export const saveBanner = async (banner) => {
  try {
    const payload = { ...banner };
    delete payload["desktopBannerImageEnglish"];
    delete payload["desktopImgSrc_english"];
    delete payload["desktopBannerImageArabic"];
    delete payload["desktopImgSrc_arabic"];
    delete payload["mobileBannerImageEnglish"];
    delete payload["mobileImgSrc_english"];
    delete payload["mobileBannerImageArabic"];
    delete payload["mobileImgSrc_arabic"];
   
    const formData = new FormData();
    formData.append("details", JSON.stringify(payload));
    formData.append("desktopImageEnglish", banner.desktopBannerImageEnglish);
    formData.append("desktopImageArabic", banner.desktopBannerImageArabic);
    formData.append("mobileImageEnglish", banner.mobileBannerImageEnglish);
    formData.append("mobileImageArabic", banner.mobileBannerImageArabic);

    const apiOptions = {
      method: "post",
      url: `${BASE_URL_WEBSITE_BANNERS}/${CREATE_BANNER}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating other-item", error);
    throw error;
  }
};

export const updateBanner = async (banner) => {
  try {
    const payload = { ...banner };
    delete payload["desktopImgSrc_english"];
    delete payload["desktopImgSrc_arabic"];
    delete payload["mobileImgSrc_english"];
    delete payload["mobileImgSrc_arabic"];
    delete payload["createdAt"];
    delete payload["modifiedAt"];

    if (typeof banner.desktopBannerImageEnglish !== "string") delete payload["desktopBannerImageEnglish"];

    if (typeof banner.desktopBannerImageArabic !== "string") delete payload["desktopBannerImageArabic"];

    if (typeof banner.mobileBannerImageEnglish !== "string") delete payload["mobileBannerImageEnglish"];

    if (typeof banner.mobileBannerImageArabic !== "string") delete payload["mobileBannerImageArabic"];

    const formData = new FormData();
    formData.append("details", JSON.stringify(payload));
    if (typeof banner.desktopBannerImageEnglish !== "string")
      formData.append("desktopImageEnglish", banner.desktopBannerImageEnglish);

      if (typeof banner.desktopBannerImageArabic !== "string")
      formData.append("desktopImageArabic", banner.desktopBannerImageArabic);

      if (typeof banner.mobileBannerImageEnglish !== "string")
      formData.append("mobileImageEnglish", banner.mobileBannerImageEnglish);

      if (typeof banner.mobileBannerImageArabic !== "string")
      formData.append("mobileImageArabic", banner.mobileBannerImageArabic);

    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_WEBSITE_BANNERS}/${UPDATE_BANNER}/${banner.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating other-item", error);
    throw error;
  }
};

export const savePopupBanner = async (banner) => {
  try {
    const payload = { ...banner };
    delete payload["imageEnglish"];
    delete payload["imageSrc_english"];
    delete payload["imageArabic"];
    delete payload["imageSrc_arabic"];

    const formData = new FormData();
    formData.append("popupBanner", JSON.stringify(payload));
    if (payload.bannerType === "Static") {
      formData.append("imageEnglish", banner.imageEnglish);
      formData.append("imageArabic", banner.imageArabic);
    }

    const apiOptions = {
      method: "post",
      url: `${BASE_URL_POPUP_BANNERS}/`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating popup banner", error);
    throw error;
  }
};

export const updatedPopupBanner = async (banner) => {
  try {
    const payload = { ...banner };
    delete payload["imageEnglish"];
    delete payload["imageSrc_english"];
    delete payload["imageArabic"];
    delete payload["imageSrc_arabic"];

    const formData = new FormData();
    formData.append("popupBanner", JSON.stringify(payload));
    if (payload.bannerType === "Static") {
      if (typeof banner.imageEnglish !== "string")
        formData.append("imageEnglish", banner.imageEnglish);
      if (typeof banner.imageArabic !== "string")
        formData.append("imageArabic", banner.imageArabic);
    }
    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_POPUP_BANNERS}/${banner.id}`,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on updating pop up banner", error);
    throw error;
  }
};
const BannerService = {
  saveBanner,
  updateBanner,
  savePopupBanner,
  updatedPopupBanner,
};

export default BannerService;
