import React, { Component } from "react";
import { connect } from "react-redux";
import DragIndicatorIcon from "@material-ui/icons/DragIndicatorOutlined";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import "./HowItWorksImages.css";

class HowItWorksImages extends Component {
  constructor(props) {
    super(props);

    const { id, label, images = [], onImagesUpdated = (f) => f } = props;
    this.onImagesUpdated = onImagesUpdated;

    this.state = {
      id,
      label,
      images,
    };

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".media-element",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  updateRank = (fromIndex, toIndex) => {
    console.log("updateRank", fromIndex, toIndex);
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { images = [] } = this.state;
    const sortedImages = _.sortBy(images, "rank", "asc");
    const fromImage = { ...sortedImages[fromIndex] };
    const toImage = { ...sortedImages[toIndex] };

    if (fromImage && toImage) {
      const tempRank = fromImage.rank;
      fromImage.rank = toImage.rank;
      toImage.rank = tempRank;

      const newImages = images.map((it) => {
        if (it.id === fromImage.id) return fromImage;
        else if (it.id === toImage.id) return toImage;
        else return it;
      });
      this.setState({ images: newImages }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onImagesUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
      const image = this.createNewImage();
      image.image = imageFile;
      this.readImage(image, imageFile);
      e.target.value = null;
    }
  };

  createNewImage = () => {
    const { images = [] } = this.state;
    const image = {};
    const ids = images.length > 0 ? images.map((it) => it.id) : null;
    image.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      images.length > 0
        ? images.filter((it) => it.rank).map((it) => it.rank)
        : null;
    image.rank = ranks ? Math.max(...ranks) + 1 : 1;
    image.is_new = true;
    return image;
  };

  readImage = (image, imageFile) => {
    if (!imageFile) return;
    const reader = new FileReader();
    reader.onloadend = function () {
      const newImage = {
        ...image,
        image_src: [reader.result],
      };
      this.setState({ images: [...this.state.images, newImage] }, () => {
        this.showSuccessNotification("Image Added Successfully!");
        this.onImagesUpdatedLocally();
      });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

  handleDeleteImage = (image) => {
    this.deleteImage(image.id);
  };

  deleteImage = (id) => {
    const { images = [] } = this.state;
    const imageToDelete = images.find((it) => it.id === id) || {};
    const newImages = images
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > imageToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ images: newImages }, () => {
      this.showSuccessNotification("Image Deleted Successfully!");
      this.onImagesUpdatedLocally();
    });
  };

  onImagesUpdatedLocally = () => {
    this.onImagesUpdated(
      this.state.images.map((image) => {
        const i = { ...image };
        if (i.is_new) {
          delete i.id;
          delete i.is_new;
        }
        return i;
      })
    );
  };

  renderClosableImage = (image) => {
    return (
      <div className="image-area">
        <img
          key={image?.id}
          src={image?.image_src ?? image?.image}
          alt="Selected"
        />
        <button
          onClick={(e) => this.handleDeleteImage(image)}
          disabled={!this.props.isEditable ?? true}
          className="remove-image"
          style={{ display: "inline" }}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    );
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { id, label, images = [] } = this.state;
    images.sort((first, second) => {
      if (first.rank < second.rank) return -1;
      if (first.rank > second.rank) return 1;
      return 0;
    });
    const imageList = images.map((image) => {
      return (
        <div key={image.id} className="media-element">
          {this.renderClosableImage(image)}
          <DragIndicatorIcon className="drag-handle" />
        </div>
      );
    });
    return (
      <>
        <div className="row">
          <div className="form-group col-10">
            <label className="mt-2">{label ?? ""}</label>
          </div>
          <div className="form-group col">
            <input
              type="file"
              onChange={this.handleSelectImage}
              id={`${id}ImageInput`}
              name="image"
              accept="image/*"
              disabled={!this.props.isEditable ?? true}
              className="form-control custom-file-input"
            />
            {this.props.isEditable &&
              <label
                htmlFor={`${id}ImageInput`}
                className="btn btn-success px-2 py-1"
                style={{ cursor: "pointer" }}
              >
                Add New
              </label>
            }
          </div>
        </div>
        <div className="row">
          <div className="col">
            <ReactDragListView {...this.dragProps}>
              <div className="media-scroller snaps-inline">{imageList}</div>
            </ReactDragListView>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorksImages);
