import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_NUTRITIONIST_CALENDLY_LINK = "calendly-link";

const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
const DEFAULT_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
class CoachCalendly extends Component {
  constructor(props) {
    super(props);
    const {nutritionistId,onUpdateCalendly=(f)=>f}=this.props;
    this.onUpdateCalendly=onUpdateCalendly;
    this.state = {
      nutritionist: {},
      coachLinks: [],
      errors: {},
      editables: {},
      newLink: false,
    };
  }

  componentDidMount() {
    if (this.props.nutritionistId) {
      this.fetchNutritionist();
    } else {
      this.setState({ newLink: true });
    }
  }

  fetchNutritionist = async () => {
    const { coachLinks = [] } = this.state;
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(
        BASE_URL_NUTRITIONIST_CALENDLY_LINK,
        {
          params: { userId: this.props.nutritionistId },
        }
      );
      const data = response && response.data;

      this.setState({ nutritionist: data },()=>this.onUpdateCalendly1());
      this.hideLoading();
    } catch (error) {
      console.log(
        "Error in fetching Nutritionist from url:",
        BASE_URL_NUTRITIONIST_CALENDLY_LINK
      );
      console.log("Error in fetching  Coach Calendly Links", error.message);
      this.showErrorNotification(
        "Error in fetching Coach Calendly Links",
        error.message
      );
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const nutritionist = { ...this.state.nutritionist };
    nutritionist[input.name] = input.value;
    this.setState({ nutritionist });
  };

  updateLink=async(nutritionist)=>{

     try{
        const payload={...nutritionist};

        payload["userId"]=this.props.nutritionistId;
        const apiOptions = {
            method: "patch",
            url: `${BASE_URL_NUTRITIONIST_CALENDLY_LINK}/${nutritionist.id}`,
            data: payload,
          };
      
          const response = await PhoenixAPI.request(apiOptions);
          return response;
     }
     catch(error){
        this.showErrorNotification("Error in Updating Nutritionist Calendly Link!");
     }
  }

  
 saveLink=async()=>{
    const { nutritionist } = this.state;
    const {nutritionistId}=this.props;
    if(!nutritionistId){
      this.setState({...nutritionist,nutritionist},()=>{
        this.onUpdateCalendly1()
      })
    }
    else{
        const payload={...nutritionist};
        payload["userId"]=nutritionistId;
        try{
            const apiOptions = {
                method: "post",
                url: `${BASE_URL_NUTRITIONIST_CALENDLY_LINK}`,
                data: payload,
              };
              const response = await PhoenixAPI.request(apiOptions);
              return response;
        }
        catch(error){
            this.showErrorNotification("Error in Saving Nutritionist Calendly Link!");
        }
      
    }

 }

 
  handleSave =async()=>{
    const nutritionist=this.state.nutritionist && this.state.nutritionist || {}
    console.log("nutritionist", nutritionist)
    const errors = this.validate();
    this.setState({errors: errors || {}});
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try{
        this.showLoading();
        const isValidId = nutritionist.id !== null && nutritionist.id !== undefined;
        const response = isValidId
        ? await this.updateLink(nutritionist)
        : await this.saveLink(nutritionist);
        if(response && response.status===200){
            this.fetchNutritionist();
            this.showSuccessNotification(
                isValidId
                  ? "Nutritionist Calendly Link Updated Successfully."
                  : "Nutritionist Calendly Link Created Successfully."
              );
        }
        else{
            return;
        }
         
    }
    catch(error){
        this.showErrorNotification("Error on Saving Nutritionist Calendly Link  !" + error.message);
    }
    finally {
        this.hideLoading();
      }
    
  }

  validate=()=>{
    const {calendlyLink='', isActive, isDefault,isNonCalendlyLink} = this.state.nutritionist && this.state.nutritionist || {} ;
    const errors = {};
    // if (!isActive) {
    //     errors.isActive = "Is Active is a required field!";
    // }
    // if(!isDefault){
    //     errors.isDefault = "Is Default is a required field!";
    // }
    if(!calendlyLink  || calendlyLink.trim() === ""){
        errors.calendlyLink= "Calendly Link is a required field";
    }
    else if(calendlyLink & calendlyLink.length>255){
        errors.calendlyLink="Calendly Link can not be more than 255 characters"
    }
    if(isNonCalendlyLink){
      if(calendlyLink.toLowerCase().includes("calendly.com"))
      {  console.log("check includes");
        errors.calendlyLink="Calendly Link should not contain calendly.com";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return (
      isEditable && !this.state.newLink ?
        <div
          onClick={() => this.setEditable(fieldName)}
          style={{ cursor: "pointer" }}
          className="input-group-append"
        >
          <i className="input-group-text fa fa-pencil"></i>
        </div> : ""
    );
  };

  onUpdateCalendly1=()=>{
   this.onUpdateCalendly(
    this.state.nutritionist || {}
   )
 }



  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { nutritionist, errors, newLink, editables } = this.state;
    const nutritionistId= nutritionist && nutritionist.id;
    const isEditable = this.props.isEditable ?? true;
    const coachCalendlyLinkForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="calendlyLink">Calendly Link *</label>
            <div className="input-group">
              <textarea
                value={nutritionist?.calendlyLink || ""}
                onChange={this.handleChange}
                id="calendlyLink"
                name="calendlyLink"
                type="text"
                placeholder="Enter Calendly Link"
                className={
                  "form-control py-2" +
                  (errors.calendlyLink ? " has-error" : "")
                }
                disabled={!newLink && !editables.calendlyLink}
              ></textarea>
              {this.renderPencilIcon("calendlyLink")}
            </div>
            <span className="help-block">{errors.calendlyLink}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="isActive">Is Active*</label>
            <div className="input-group">
              <Dropdown
                value={nutritionist?.isActive}
                onChange={(event, value) =>
                  this.setState({
                    nutritionist: { ...nutritionist, isActive: value.value },
                  })
                }
                id="isActive"
                name="isActive"
                placeholder="Is Active"
                search
                selection
                options={STATUS_OPTIONS || []}
                disabled={!newLink && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="isDefault">Is Default *</label>
            <div className="input-group">
              <Dropdown
                value={nutritionist?.isDefault}
                onChange={(event, value) =>
                  this.setState({
                    nutritionist: { ...nutritionist, isDefault: value.value },
                  })
                }
                id="isDefault"
                name="isDefault"
                placeholder="isDefault"
                search
                selection
                options={DEFAULT_OPTIONS || []}
                disabled={!newLink && !editables.isDefault}
                className={
                  "form-control" + (errors.isDefault ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isDefault")}
            </div>
            <span className="help-block">{errors.isDefault}</span>
          </div>
          </div>
          <div className="row">
        <div className="form-group col" >
              
              <div className="input-group">
              <input
                value={true}
                checked={nutritionist.isNonCalendlyLink && nutritionist.isNonCalendlyLink || false}
                onChange={(event, value) => {
                  this.setState({
                    nutritionist: { ...nutritionist, isNonCalendlyLink:event.target.checked}
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; is this non calendly link?
               </div> 

        </div>

        <div className="form-group col">
          <label>Coach CalendlyMail</label>
        <div className="input-group">
              <input
                
                value={nutritionist.calendlyCoachMail }
                onChange={(event, value) => {
                  this.setState({
                    nutritionist: { ...nutritionist,calendlyCoachMail:event.target.value}
                  });
                }}
                id="calendlyCoachMail"
                name="calendlyCoachMail"
                type="input"
                disabled={!newLink && !editables.calendlyCoachMail}
                className={
                  "form-control" + (errors.calendlyCoachMail ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("calendlyCoachMail")}
              </div>
              <span className="help-block">{errors.calendlyCoachMail}</span>
              </div>
        

      
         </div>


          <div className="row">
            {nutritionistId ? <div className="form-group col" style={{marginLeft:"15px"}}>
                <button
                  className="btn px-5"
                  style={{ backgroundColor: "#CEE741" }}
                  onClick={() => {
                    this.handleSave();
                  }}
                  disabled={!isEditable}
                >
                  Update Calendly Link
                </button>
            </div>: <div className="form-group col" style={{marginLeft:"15px"}}>
                <button
                  className="btn px-5"
                  style={{ backgroundColor: "#CEE741" }}
                  onClick={() => {
                    this.handleSave();
                  }}
                  disabled={!isEditable}
                >
                  Save Calendly Link
                </button>
            </div>}
            </div>
          
        
        
      </>
    );

    return (
      <>
        <div className="container mt-4">{coachCalendlyLinkForm}</div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CoachCalendly);
