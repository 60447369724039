import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectCoachPackages.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectCoachPackages.defaultTypes = {
  categories: [],
  selectedCategories: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectCoachPackages(props) {
  const { categories, selectedCategories, onSelect, menuPlacement, isDisabled, placeholder} = props;
  const findTest = (id) => categories.find((test) => test.id === id);



  categories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const testOptions = categories.map(createOption);
  const selectedTests = selectedCategories
    ? selectedCategories.map(findTest).map(createOption)
    : [];

  return (
    <Select
      key={`my_unique_select_key__${selectedTests && selectedTests.join(",")}`}
      value={selectedTests}
      onChange={onSelect}
      options={testOptions}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
}

const createOption = (test) => {
  if (!test) return;
  return {
    key: test.id,
    value: test.id,
    label:test.externalName,
  };
};

export default SelectCoachPackages;
