import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { formatDate } from "utils/commons";
import API from "utils/API";

const BASE_URL_PAST_ORDERS = "client-past-order/";

function OrderDetailsCard({ order, customerTransactions = [], isCustomerTransactionsLoaded = false, clientPastOrders=[] }) {
  const {isMiniPackageAdded=false, order_package}= order || {};
  const packageName= isMiniPackageAdded ? `${order_package} + Add on biomarkers` : order_package;
  const [previousOrders, setPreviousOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    field: "itemOrderId",
    direction: "desc",
  });
  customerTransactions = _.orderBy(
    customerTransactions,
    [sortConfig.field],
    [sortConfig.direction]
  );

  useEffect(() => {
    if (order && order.user && order.user.id){
      // fetchPreviousOrdersOfUser(order.user.id);   // new change not making a API call
      if(clientPastOrders && clientPastOrders.length>0){
        const filteredPreviousOrders = (order && order.dependent_id && clientPastOrders && clientPastOrders.length>0)
        ? clientPastOrders.filter(it => it.dependent_id === order.dependent_id)
                : clientPastOrders;
                setPreviousOrders(filteredPreviousOrders);
      }
    
    }
  }, [order]);

  const fetchPreviousOrdersOfUser = async (userId) => {
    if (!userId) {
      console.log("Can't fetch previous orders, user id is not valid:");
      return;
    }

    const url = `${BASE_URL_PAST_ORDERS}?user=${userId}`;
    try {
      const { data: previousOrders = [] } = await API.get(url);
      const filteredPreviousOrders = (order && order.dependent_id && previousOrders && previousOrders.length)
        ? previousOrders.filter(it => it.dependent_id === order.dependent_id)
        : previousOrders;
      console.log("OrderDetailsCard, Previous Orders: ", previousOrders);
      console.log("OrderDetailsCard, Filtered Previous Orders: ", filteredPreviousOrders);
      setPreviousOrders(filteredPreviousOrders);
    } catch (error) {
      console.log("Error on fetching previous order from: ", url);
    }
  };

  const requestSort = (newSortField) => {
    const { field, direction } = sortConfig || {};
    let newDirection = "asc";
    if (field === newSortField && direction === "asc") {
      newDirection = "desc";
    }
    setSortConfig({ field: newSortField, direction: newDirection });
  };

  const getClassNamesFor = (field) => {
    if (!sortConfig) return;
    return sortConfig.field === field
      ? sortConfig.direction === "asc"
        ? "ascending"
        : "descending"
      : undefined;
  };

  const getFormattedPurchasedDate = (purchasedDate) => {
    if (!purchasedDate) return "";
    try {
      const date = moment(purchasedDate).format("MMM DD, YYYY");
      const time = moment(purchasedDate).format("hh:mm A");
      return `${date} at ${time}`;
    } catch (error) {
      console.log(
        "getFormattedPurchasedDate:: Error on formatting date",
        error
      );
    }
  };

  const renderPreviousOrders = () => {
    // We are not showing more then 5 previous orders
    const { dependent_id, member } = order;
    let gender;
    if (dependent_id) {
      gender = member?.gender ?? "";
    } else {
      gender = order?.user?.userprofile?.gender;
    }
    return previousOrders.slice(0, 5).map((order) => {
      const url = `/dashboard/orders/past/results/${order.id}/${gender}`;
      return (
        <>
          <NavLink id={order.id} to={url} target="_blank">
            {order.package_name}
          </NavLink>{" "}
          <br />
        </>
      );
    });
  };
  const { dependent_id, member } = order;
  let gender;
  if (dependent_id) {
    gender = member?.gender ?? "";
  } else {
    gender = order?.user?.userprofile?.gender;
  }
  const renderCustomerTransactions = () => {
    if (!isCustomerTransactionsLoaded) return "Loading...";
    return (
      <div
        className="description table-responsive"
        style={{
          maxHeight: "70vh",
          overflowY: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {customerTransactions && customerTransactions.length ? (
          <table className="result-table">
            <thead className="result-table-head">
              <tr>
                <th
                  onClick={() => requestSort("itemOrderId")}
                  className={getClassNamesFor("itemOrderId")}
                >
                  Id
                </th>
                <th
                  onClick={() => requestSort("itemNameEnglish")}
                  className={getClassNamesFor("itemNameEnglish")}
                >
                  Name
                </th>
                <th
                  onClick={() => requestSort("createdAt")}
                  className={getClassNamesFor("createdAt")}
                >
                  Purchased On
                </th>
              </tr>
            </thead>
            <tbody className="result-table-body">
              {customerTransactions.map((order) =>
              {
                let url;
                if(order.orderStatus==="COMPLETED"){
                  url=`/dashboard/orders/past/results/${order.itemOrderId}/${gender}`
                }
                else{
                  url =`/dashboard/home/view/${order.itemOrderId}`
                }
            return (
               <>
                  <tr key={order.itemOrderId} className="result-table-row">
                    <td data-label="itemOrderId" className="text-wrap" >
                    {/* {order.orderStatus==="CANCELLED"? order.itemOrderId ?? "":<NavLink id={order.itemOrderId} to={`/dashboard/orders/${order.orderStatus==="COMPLETED"?"past":"active"}/results/${order.itemOrderId}/${gender}`} target="_blank">
                         {order.itemOrderId ?? ""}
                      </NavLink>}  */}
                      {order.orderStatus==="CANCELLED"? order.itemOrderId ?? "":<NavLink id={order.itemOrderId} to={url} target="_blank">
                         {order.itemOrderId ?? ""}
                      </NavLink>} 
                    </td>
                    <td data-label="itemNameEnglish" className="text-wrap">
                    {/* {order.orderStatus==="CANCELLED"? order?.itemNameEnglish ?? "":<NavLink id={order.itemOrderId} to={`/dashboard/orders/${order.orderStatus==="COMPLETED"?"past":"active"}/results/${order.itemOrderId}/${gender}`} target="_blank">
                    {order?.itemNameEnglish ?? ""}
                      </NavLink>}  */}
                      {order.orderStatus==="CANCELLED"? order?.itemNameEnglish ?? "":<NavLink id={order.itemOrderId} to={url} target="_blank">
                      {order?.itemNameEnglish ?? ""}
                      </NavLink>} 
                    </td>
                    <td data-label="createdAt" className="text-center">
                      {getFormattedPurchasedDate(order?.createdAt)}
                    </td>
                  </tr>
                </>
              )
               })}
            </tbody>
          </table>
        ) : (
          <span>Transactions are not avaibale.</span>
        )}
      </div>
    );
  };

  return (
    <div className="ui raised card" style={{ width: "100%", maxWidth: "100%" }}>
      <div className="content">
        <div className="font-weight-bold mb-2">Order Details</div>
        <div className="description">
          <table className="ui celled table">
            <tbody>
              <tr>
                <td data-label="Name">Order ID</td>
                <td data-label="order_id">{order.id || ""}</td>
              </tr>
              <tr>
                <td data-label="Name">Purchased Date</td>
                <td data-label="purchased_date">
                  {order.created_at ? formatDate(order.created_at) : ""}
                </td>
              </tr>
              <tr>
                <td data-label="Name">Package Name</td>
                {/* <td data-label="package_name">{order.order_package || ""}</td> */}
                <td data-label="package_name">{packageName || ""}</td>
              </tr>
              <tr>
                <td data-label="Name">Previous Orders</td>
                <td data-label="previos_orders">{renderPreviousOrders()}</td>
              </tr>
              <tr>
                <td data-label="Name" colSpan={2} className="text-center">
                  Customer Transactions
                </td>
              </tr>
              <tr>
                <td data-label="customer_transactions" colSpan={2}>
                  {renderCustomerTransactions()}
                </td>
              </tr>
              {/* <tr>
                <td data-label="Name">Hyperlink</td>
                <td data-label="hyperlink">{"Hyperlink"}</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailsCard;
