import React, { Component } from 'react';
import { Card, Modal } from 'react-bootstrap';
import { connect } from "react-redux";
import picture from "assets/img/image.svg";
import FormValidator from "utils/FormValidator";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import DataTable from "react-data-table-component";
import EditIcon from "@material-ui/icons/EditOutlined"
import API from "utils/API";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class HowItWorks extends Component {
    constructor(props) {
        super(props);
        this.validator = new FormValidator([
            {
                field: "imgSrc",
                method: "isEmpty",
                validWhen: false,
                message: "Image is required!",
            },
            {
                field: "title",
                method: "isEmpty",
                validWhen: false,
                message: "Title (English) is required!",
            },
            {
                field: "title",
                method: "isLength",
                validWhen: true,
                min: 1,
                max: 50,
                message: "Title (English) should have more than 1 characters and less than 50 characters!",
            },
            {
                field: "title_ar",
                method: "isEmpty",
                validWhen: false,
                message: "Title (Arabic) is required!",
            },
            {
                field: "title_ar",
                method: "isLength",
                validWhen: true,
                min: 1,
                max: 50,
                message: "Title (Arabic) should have more than 1 characters and less than 50 characters!",
            },
            {
                field: "desc",
                method: "isEmpty",
                validWhen: false,
                message: "Description (English) is required!",
            },
            {
                field: "desc",
                method: "isLength",
                validWhen: true,
                min: 10,
                max: 200,
                message: "Description (English) should have more than 10 characters and less than 200 characters!",
            },
            {
                field: "desc_ar",
                method: "isEmpty",
                validWhen: false,
                message: "Description (Arabic) is required!",
            },
            {
                field: "desc_ar",
                method: "isLength",
                validWhen: true,
                min: 10,
                max: 200,
                message: "Description (Arabic) should have more than 10 characters and less than 200 characters!",
            },
        ]);

        this.state = {
            title: "",
            title_ar: "",
            desc: "",
            desc_ar: "",
            imgSrc: "",
            image: "",
            validation: this.validator.valid(),
            showModal: false,
            data: [],
            disabled: false,
            loading: false,
            editId: null,
            editables: {},
        };
        this.columns = [
            {
                name: "Id",
                selector: "id",
                width: "100px"
            },
            {
                name: "Title",
                selector: "heading",

            },
            {
                name: "Description",
                cell: (row) => {
                    return (
                        <p>{row.description}</p>
                    )
                },
                width: "250px"

            },
            {
                name: "Image",
                cell: (row) => {
                    return (
                        <img className="table-img" src={row.image}></img>
                    )
                },
                center: true

            },
            {
                name: "Actions",
                cell: (row) => {
                    return (
                        <div>
                            <button className="delete-btn" onClick={() => { this.handleDeleteData(row.id) }}><DeleteIcon></DeleteIcon></button><button className="delete-btn" onClick={() => { this.handleEditData(row) }}><EditIcon></EditIcon></button>
                        </div>
                    )
                },
                center: true,
                width: "200px"
            }
        ]
        this.selectImage = this.selectImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.fetchData = this.fetchData.bind(this);
        this.handleDeleteData = this.handleDeleteData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEditData = this.handleEditData.bind(this);
        this.submitted = false;
    }

    componentDidUpdate(prevProps) {
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        API.get("how-it-works/")
            .then(response => {
                let data = response.data;
                if (data) {
                    this.setState({
                        data: data
                    })
                }
                if (data.length === 4) {
                    this.setState({
                        disabled: true
                    })
                }
                else if (data.length < 4) {
                    this.setState({
                        disabled: false
                    })
                }
            })
            .catch(error => {
                console.log("Error on fetching how it works", error);
            })
    }

    selectImage(e) {
        e.preventDefault();
        let image = e.target.files[0];
        if (image.type.includes("image/")) {
            this.setState({ image });
            let reader = new FileReader();
            reader.onloadend = () => {
                this.setState({ imgSrc: reader.result })
            };
            reader.readAsDataURL(image)
        }
        else {
            let validation = this.state.validation;
            validation.imgSrc.isInvalid = true;
            validation.imgSrc.isValid = false;
            validation.imgSrc.message = "File should be of image type"
            this.setState({
                validation
            })
        }
    }

    deleteImage(e) {
        e.preventDefault();
        this.setState({ imgSrc: "", image: "" })
    }

    handleEditData(row) {
        this.showModal();
        this.setState({
            imgSrc: row.image,
            editId: row.id,
            title: row.heading,
            title_ar: row.heading_ar,
            desc: row.description,
            desc_ar: row.description_ar,
        })
    }

    handleSubmit(e) {
        // e.preventDefault();
        const { editId, image, title, title_ar, desc, desc_ar } = this.state;
        const validation = this.validator.validate(this.state);
        this.submitted = true;
        this.setState({ validation });
        if (validation.isValid) {
            var apiOptions = {};
            this.setState({ loading: true });
            if (editId !== null) {
                let formData = new FormData();
                formData.append("heading", title);
                formData.append("heading_ar", title_ar);
                formData.append("description", desc);
                formData.append("description_ar", desc_ar);
                if (image.name) { formData.append("image", image); }
                apiOptions = {
                    method: "patch",
                    url: "how-it-works/" + editId + "/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            else {
                let formData = new FormData();
                formData.append("image", image);
                formData.append("heading", title);
                formData.append("heading_ar", title_ar);
                formData.append("description", desc);
                formData.append("description_ar", desc_ar);
                apiOptions = {
                    method: "post",
                    url: "how-it-works/",
                    data: formData,
                    headers: {
                        accept: "application/json",
                        "Accept-Language": "en-US,en;q=0.8",
                        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
            }
            API.request(apiOptions)
                .then(response => {
                    this.props.showNotificationMessage({
                        notificationMessage: "Section Added successfully.",
                        successMessage: true,
                        showNotification: true,
                        isLoading: false,
                    });
                    this.hideModal();
                    this.fetchData();
                    this.setState({
                        loading: false
                    });
                })
                .catch(error => {
                    console.log("Error on adding section", error);
                    this.setState({
                        error: "Some error occured. Please try again",
                        loading: false
                    });
                })
        }
        else {
            this.props.showNotificationMessage({
                notificationMessage: "There are some invalid form fields!",
                successMessage: false,
                showNotification: true,
                isLoading: false,
            });
        }
    }

    handleDeleteData(id) {
        API.delete("how-it-works/" + id + "/")
            .then(response => {
                this.fetchData();
                this.props.showNotificationMessage({
                    notificationMessage: "Section Deleted successfully.",
                    successMessage: true,
                    showNotification: true,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log("Error on deleting section", error);
            })
    }

    formReset() {
        this.submitted = false;
        this.setState({
            image: "",
            imgSrc: "",
            title: "",
            title_ar: "",
            desc: "",
            desc_ar: "",
            validation: this.validator.valid(),
            editables: {},
            editId: null,
        });
        if (document.getElementById("img-upload")) {
            document.getElementById("img-upload").setAttribute("src", picture)
        }
    }

    hideModal() {
        this.formReset();
        this.setState({ showModal: false });
    }
    showModal() {
        this.setState({
            showModal: true,
        });
    }
    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) =>
        this.state.editId ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );

    render() {
        let validation = this.submitted
            ? this.validator.validate(this.state)
            : this.state.validation;

        const { editId, editables } = this.state;

        const detailsForm =
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="title">Title (English)*</label>
                        <div className="input-group">
                            <input
                                value={this.state.title}
                                onChange={this.handleChange}
                                type="text"
                                id="title"
                                name="title"
                                placeholder="Title in English"
                                disabled={editId && !editables.title}
                                className={"form-control py-2 " + (validation.title.isInvalid && "has-error")} />
                            {this.renderPencilIcon("title")}
                        </div>
                        <span className="help-block">{validation.title.message}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="title_ar">Title (Arabic)*</label>
                        <div className="input-group">
                            <input
                                value={this.state.title_ar}
                                onChange={this.handleChange}
                                type="text"
                                id="title_ar"
                                name="title_ar"
                                placeholder="Title in Arabic"
                                disabled={editId && !editables.title_ar}
                                className={"form-control input-arabic py-2 " + (validation.title_ar.isInvalid && "has-error")} />
                            {this.renderPencilIcon("title_ar")}
                        </div>
                        <span className="help-block">{validation.title_ar.message}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="desc1">Description (English)*</label>
                        <div className="input-group">
                            <textarea
                                value={this.state.desc}
                                onChange={this.handleChange}
                                id="desc1"
                                name="desc"
                                placeholder="Description in English"
                                readOnly={editId && !editables.desc}
                                className={"form-control " + (validation.desc.isInvalid && "has-error")}
                            >
                            </textarea>
                            {this.renderPencilIcon("desc")}
                        </div>
                        <span className="help-block">{validation.desc.message}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="desc_ar">Description (Arabic)*</label>
                        <div className="input-group">
                            <textarea
                                value={this.state.desc_ar}
                                onChange={this.handleChange}
                                id="desc_ar"
                                name="desc_ar"
                                placeholder="Description in Arabic"
                                readOnly={editId && !editables.desc_ar}
                                className={"form-control input-arabic " + (validation.desc_ar.isInvalid && "has-error")}
                            >
                            </textarea>
                            {this.renderPencilIcon("desc_ar")}
                        </div>
                        <span className="help-block">{validation.desc_ar.message}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label>Image*</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectImage}
                                type="file"
                                id="imageInput"
                                name="image"
                                accept="image/*"
                                disabled={editId && !editables.image}
                                className={"form-control py-2 " + (validation.imgSrc.isInvalid && "has-error")}
                            />
                            {this.renderPencilIcon("image")}
                        </div>
                        <span className="help-block">{validation.imgSrc.message}</span>
                    </div>
                    <div className="form-group col">
                        {this.state.imgSrc && (
                            <>
                                <label>Selected Image</label>
                                <div className="form-image-container">
                                    <img
                                        src={this.state.imgSrc}
                                        className="img-fluid"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>;

        return (
            <div className="how-it-works">
                <div className="page-header">
                    {/* <h5 className="mb-4">How It Works Section</h5> */}
                    <div className="actions">
                        <div className="action-item">
                            <button disabled={this.state.disabled} className="btn btn-sm button text-button" onClick={this.showModal}>
                                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>add new
                            </button>
                        </div>
                    </div>
                </div>
                <Card body>
                    {this.state.data.length < 4 && <p className="p-head">You can add only 4 cards to the Section</p>}
                    {this.state.data.length === 4 && <p className="p-head">You have already added 4 cards to the Section</p>}
                    <DataTable
                        highlightOnHover
                        columns={this.columns}
                        data={this.state.data}
                        defaultSortField="id"
                        defaultSortAsc={false}
                        sortIcon={<ArrowDownward></ArrowDownward>}
                        pagination
                        paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                        paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        responsive
                    />
                </Card>
                <Modal size="lg" show={this.state.showModal} onHide={this.hideModal} className="user-data-modal">
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                        <div className="user-title"></div>
                        <button
                            onClick={this.handleSubmit}
                            disabled={this.state.loading && this.state.validation.isInvalid}
                            className="btn modal-save-btn">
                            Save
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        {detailsForm}
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HowItWorks);
