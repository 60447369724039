import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: "NONE", value: null, label: "NONE" };

SelectPageCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategoryId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectPageCategory.defaultProps = {
  categories: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectPageCategory(props) {
  const {
    categories,
    selectedCategoryId,
    onSelect,
    menuPlacement,
    isDisabled,
    showOnlyActive,
  } = props;

  const findCategory = (id) => categories.find((it) => it.id === id);

  const options = createOptions(categories, showOnlyActive);
  const selectedCategory = selectedCategoryId
    ? findCategory(selectedCategoryId)
    : null;
  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedCategoryId}`}
      value={createSelectedOption(selectedCategory)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Category"
      isDisabled={isDisabled}
      menuPlacement={menuPlacement}
    />
  );
}

function createOptions(pageCategories, showOnlyActive) {
  if (!pageCategories || !pageCategories.length) return [];

  const [activePageCategories, inactivePageCategories] = _.partition(
    pageCategories,
    (it) => it.isActive ?? false
  );
  activePageCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  if (showOnlyActive) return activePageCategories.map(createOption);

  inactivePageCategories.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );
  return [
    {
      label: "NONE",
      options: [OPTION_NONE],
    },
    {
      label: "Active",
      options: activePageCategories.map(createOption),
    },
    {
      label: "Inactive",
      options: inactivePageCategories.map(createOption),
    },
  ];
}

function createOption(category) {
  if (!category) return;
  return {
    key: category.id,
    value: category.id,
    label: category.name,
  };
}

function createSelectedOption(category) {
  return category
    ? {
        key: category.id,
        value: category.id,
        label: category.name,
      }
    : OPTION_NONE;
}

export default SelectPageCategory;
