import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import { fetchCities } from "services/RegionService";

SelectCity.propTypes = {
  countryId: PropTypes.number,
  selectedCity: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectCity.defaultTypes = {
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectCity(props) {
  const {
    countryId,
    selectedCity,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;
  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetchCities()
      .then((cities) => setCities(cities))
      .catch((error) => console.log("error on fetcing cities: ", error));
  }, []);

  const findCity = (cityId) => cities.find((city) => city.id === cityId);

  const citiesForCountry = cities && countryId 
    ? cities?.filter((city) => city.country?.id === countryId)
    : cities ?? []; 
  const options = createOptions(citiesForCountry, showOnlyActive);

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedCityObject = findCity(selectedCity);
  const selectedCityOption = selectedCityObject
    ? createOption(selectedCityObject)
    : null;

  return (
    <Select
      key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
      value={selectedCityOption}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder="Select City..."
      isDisabled={isDisabled}
    />
  );
}

function createOptions(cities, showOnlyActive) {
  if (!cities || !cities.length) return [];

  const [activeCities, inactiveCities] = _.partition(
    cities,
    (it) => it.is_active ?? false
  );
  activeCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );

  if (showOnlyActive) return activeCities.map(createOption);

  inactiveCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );
  return [
    {
      label: "Active",
      options: activeCities.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCities.map(createOption),
    },
  ];
}

const createOption = (city) => {
  return city
    ? {
        key: city.id,
        value: city.id,
        label: city.city_name,
      }
    : null;
};

export default SelectCity;
