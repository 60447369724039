import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { formatDateTime } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
// import PagePermissionsForm from "./PagePermissionsForm";
import RolePagePermissionsForm from "./RolePagePermissionsForm";
import CoachConsultationPackageService from "services/CoachConsultationPackageService";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_ROLES = "/api/v1/acl/roles";

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      role: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isSaving: false,
      accessRights: [],
      pageCategories: [],
      pages: [],
      pagePermissions:[],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Created On",
        selector: "createdOn",
        wrap: true,
        sortable: true,
        format: (role) =>
          role.createdOn ? formatDateTime(role.createdOn) : "",
      },
      {
        name: "Updated On",
        selector: "updatedOn",
        wrap: true,
        sortable: true,
        format: (role) =>
          role.updatedOn ? formatDateTime(role.updatedOn) : "",
      },
      {
        name: "View",
        center: true,
        cell: (role) => this.renderViewButton(role.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchRoles();
    this.fetchAccessRights();
    this.fetchPageCategories();
    this.fetchPages();
  }

  fetchRoles = async () => {
    try {
      this.showLoading();
      const { data: roles = [] } = await PhoenixAPI.get(BASE_URL_ROLES);
      this.setState({ roles });
    } catch (error) {
      this.showErrorNotification("Error on fetching roles: " + error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchAccessRights = async () => {
    try {
      const { data: accessRights = [] } = await PhoenixAPI.get(
        "/api/v1/acl/access-rights"
      );
      this.setState({ accessRights });
    } catch (error) {
      console.log("Error on fetching access rights!", error);
    }
  };

  fetchPageCategories = async () => {
    try {
      const { data: pageCategories = [] } = await PhoenixAPI.get(
        "/api/v1/acl/pages/categories"
      );
      this.setState({ pageCategories });
    } catch (error) {
      console.log("Error on fetching page categories!", error);
    }
  };

  fetchPages = async () => {
    try {
      const { data: pages = [] } = await PhoenixAPI.get("/api/v1/acl/pages");
      this.setState({ pages });
    } catch (error) {
      console.log("Error on fetching pages!", error);
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const role = { ...this.state.role };
    role[input.name] = input.value;
    this.setState({ role });
  };

  handlePagePermissionsUpdated=async()=>{
   const {role}= this.state || {}

   const {id}= role || {};

    // const updatedPagePermissions=pagePermissions.length>0 && pagePermissions.map((item)=>{
    //   const itCopy = { ...item };
    //   if (itCopy.is_new) {
    //     delete itCopy.id;
    //     delete itCopy.is_new;
    //   }
    //   return itCopy;
    // })
  //   const updatedArray=pagePermissions && pagePermissions.length>0?pagePermissions[0]:null
 
   if (id) {
    const pagePermissions= await this.handleViewPagePermissions(id)
   this.setState({ pagePermissions});
 }
  }

  saveRolePagePermissions= async(updatedArray)=>{
   console.log("updatedArray", updatedArray)

    const payload={...updatedArray};
     if(payload.is_new){
      delete payload["is_new"]
      delete payload["id"]
     }
    console.log("payload",payload);
    try{
     const response= await PhoenixAPI.patch("api/v2/edit-role-permisssions",payload)
     console.log("response", response)

    }
    catch(error){
     console.log("Error in Saving Role Permissions", error.message)
    }
    
    
  }
  handleSaveRole = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    const { role } = this.state;
    try {
      const config = {
        method: role.id ? "PATCH" : "POST",
        url: role.id ? `${BASE_URL_ROLES}/${role.id}` : BASE_URL_ROLES,
        data: role,
      };
      await PhoenixAPI.request(config);
      // this.saveRolePagePermissions()
      this.hideRoleViewModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchRoles();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  validate = () => {
    const errors = {};
    const { name } = this.state.role;
    if (!name || name.trim() === "") {
      errors.name = "Role Name is a required field!";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleViewPagePermissions=async(roleId)=>{
     if(!roleId){
      return
     }
    try{
    const {data:pagePermissions=[]}=await PhoenixAPI.get(`/api/v2/role-permissions-by-id/${roleId}`);
    console.log(" handle Edit pagePermissions", pagePermissions)
     this.setState({pagePermissions})
     return pagePermissions
    }
    catch(error){
    console.log("error:",error.message)
    }
  }

  handleViewRole = async (id) => {
    const roles = this.state.roles ?? [];
    const role = roles.find((role) => role.id === id);
    if (role) {
       const pagePermissions= await this.handleViewPagePermissions(id)
      this.setState({ role, isNewRole: false,pagePermissions  }, () => {this.showRoleViewModal()});
    }
  };

  handleAddNewRole = () => {
    this.setState({ isNewRole: true }, () => this.showRoleViewModal());
  };

  showRoleViewModal = () => {
    this.setState({ isRoleViewModalVisible: true });
  };

  hideRoleViewModal = () => {
    this.setState({ isRoleViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({ role: {}, errors: {}, editables: {}, isSaving: false, pagePermissions:[] });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => this.handleViewRole(id)}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (this.state.isNewRole) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  getFilteredRoles = () => {
    const roles = this.state.roles ?? [];
    return this.getSearchedRoles(roles);
  };

  getSearchedRoles = (roles) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return roles;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return roles.filter((role) => {
      const { id, name } = role;
      return (
        (id && id.toString() === newValue) ||
        (name && name.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  render() {
    const {
      role = {},
      errors,
      editables,
      searchApplied,
      searchTerm,
      loadError,
      isNewRole,
      isRoleViewModalVisible,
      accessRights,
      pageCategories,
      pages,
      pagePermissions,
    } = this.state;

    const filteredRoles = this.getFilteredRoles();

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="name">Role Name*</label>
            <div className="input-group">
              <input
                value={role?.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Role Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewRole && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
        </div>
      </div>
    );

    const pagePermissionsForm = 
      <div>
        <RolePagePermissionsForm
          accessRights={accessRights}
          pageCategories={pageCategories}
          pages={pages}
          pagePermissions={pagePermissions}
          onPagePermissionsUpdated={this.handlePagePermissionsUpdated}
          roleId={role.id}
        />
      </div>
    ;

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewRole}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <p className="font-weight-bold">Roles</p>
          <DataTable
            data={filteredRoles || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredRoles.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={isRoleViewModalVisible}
          onHide={this.hideRoleViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Role: </span>
                {role.name ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={(e) => this.setState({ isSaving: true }, this.handleSaveRole)}
              className="btn modal-save-btn"
              disabled={this.state.isSaving}
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_supplement_categories" justify>
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
             {isNewRole?"" :<Tab eventKey="page_permissions_form" title="Page Permissions">
                {pagePermissionsForm}
              </Tab>}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
