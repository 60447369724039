import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Range from "components/BiomarkerRange/Range";
import TrendingAnalysisGraph from "./TrendingAnalysisGraph";
import API from "utils/API";

const BASE_URL_TEST = "tests/";

function TestResultDetails({ userDetails, testResult }) {
  const [testDetails, setTestDetails] = useState(null);

  useEffect(() => {
    const fetchTestDetails = async () => {
      try {
        const { data: testDetails } = await API.get(
          `${BASE_URL_TEST}${testResult.id}`
        );
        console.log("testDetails:", testDetails)
        setTestDetails(testDetails);
      } catch (error) {
        console.log("Error on fetching test details", error);
      }
    };
    if (testResult != null) fetchTestDetails();
  }, [testResult]);

  const renderParagraph = (title = "", description = "") => {
    return (
      <>
        <span style={{ fontWeight: "bold", fontSize: "1rem" }}>{title}</span>
        <p>{description}</p>
      </>
    );
  };

  const renderRange = () => {
    const { biomarkers_status = {}, result_details = {} } = testResult;
    const biomarkerStatus = biomarkers_status.biomarker_status;
    const result = result_details.result;
    if (biomarkerStatus && result) {
      return (
        <Range
          result={Number(result)}
          biomarker_list={biomarkerStatus}
          val={1}
        />
      );
    } else {
      return (
        <p style={{ fontWeight: "bold", textAlign: "center" }}>
          Test Result is not Uploaded!
        </p>
      );
    }
  };

  const renderTestResultCard = () => {
    const {
      test_name = "",
      result_details = {},
      biomarkers_status = {},
      categories = [],
    } = testResult;
    const { result = "", flag = "" } = result_details;
    const category = categories.length ? categories[0] : "";
    return (
      <Card>
        <Card.Body>
          <div className="row">
            <Card.Title className="col">{test_name}</Card.Title>
            <Card.Title className="col text-right">
              <p>
                {result}
                <span style={{ fontWeight: "normal" }}>
                  {biomarkers_status.unit ? " " + biomarkers_status.unit : ""}
                </span>
              </p>
            </Card.Title>
          </div>
          <div className="row">
            <Card.Text className="col">{category}</Card.Text>
            <Card.Text className="col text-right">{flag}</Card.Text>
          </div>
          {renderRange()}
        </Card.Body>
      </Card>
    );
  };

  const renderTrendingAnalysisCard = () => {
    return (
      <Card>
        <Card.Title className="mt-4 ml-4">Trending Analysis</Card.Title>
        <Card.Body className="pt-0">
          <TrendingAnalysisGraph
            userDetails={userDetails}
            testResult={testResult}
          />
        </Card.Body>
      </Card>
    );
  };

  const testName = testResult?.test_name ?? "";
  const biomarker = testDetails?.biomarkers[0];

  const aboutTest = renderParagraph(
    `About ${testName}`,
    biomarker?.about_test ?? ""
  );
  const whatCauseIt = renderParagraph(
    "What causes it?",
    biomarker?.cause_test ?? ""
  );
  const howToControlIt = renderParagraph(
    "How to control it?",
    biomarker?.control_test ?? ""
  );

  return (
    <>
      {renderTestResultCard()}
      {renderTrendingAnalysisCard()}
      {aboutTest}
      {whatCauseIt}
      {howToControlIt}
    </>
  );
}

export default TestResultDetails;
