/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";

function GoogleMap(props) {
  const { coordinates, setSelectedAddress = (f) => f } = props;
  const [map, setMap] = useState(false);
  const mapRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://polyfill.io/v3/polyfill.min.js?features=default";
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement("script");
    script2.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBVHdUwoQBuSvxaKu6z4xO-P2KcKe1CigQ&callback=initMap&libraries=places";
    script2.defer = true;
    document.body.appendChild(script2);
    let map;
    const poitionTracker = () => {
      new window.google.maps.Marker({
        position: coordinates,
        map: map,
        title: "Your location",
      });
    };
    function initMap() {
      map = new google.maps.Map(mapRef.current, {
        center: {
          lat: coordinates.lat,
          lng: coordinates.lng,
        },
        zoom: 15,
      });
      poitionTracker();
      const input = document.getElementById("pac-input");
      const searchBox = new window.google.maps.places.SearchBox(input);
      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);
      map.addListener("bounds_changed", () => {
        searchBox.setBounds(map.getBounds());
      });
      let markers = [];
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        if (places.length === 0) {
          return;
        }
        markers.forEach((marker) => {
          marker.setMap(null);
        });
        markers = [];
        const bounds = new window.google.maps.LatLngBounds();
        places.forEach((place) => {
          if (!place.geometry || !place.geometry.location) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25),
          };
          const { geometry, name, vicinity, url, place_id, formatted_address } =
            place || {};
          const pos = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
          };
          new window.google.maps.Marker({
            position: pos,
            map: map,
            title: name,
          });
          const selectedAdress = {
            lat: geometry.location.lat(),
            lng: geometry.location.lng(),
            map_url: url,
          };
          setSelectedAddress(place);
          setSelectedPoint(selectedAdress);
          markers.push(
            new window.google.maps.Marker({
              map,
              icon,
              title: place.name,
              position: place.geometry.location,
            })
          );

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
        map.fitBounds(bounds);
      });
    }
    window.initMap = initMap;
    setMap(true);
  }, []);

  return <div ref={mapRef} style={{ height: "500px", width: "100%" }} />;
}

export default GoogleMap;
