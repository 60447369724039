import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { fetchCountries } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
class ManageNonBloodTests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      testName: "",
      testNameAr: "",
      descriptionAr: "",
      description: "",
      price: "",

      testList: [],
      activeOrders: [],
      edit_id: null,
      formHeading: "Add Non Blood Biomarker Details",
      testCategortList: null,

      arrTestTypeList: [
        {
          text: "Biomarker Based Test",
          value: "Biomarker Based Test",
          key: "B",
        },
        {
          text: "Food Intolerance Test",
          value: "Food Intolerance Test",
          key: "F",
        },
      ],
      test_type: "",

      showModal: false,

      showAlert: true,
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isLoaded: false,
      loadError: "",
      categoryFilter: "All categories",
      countries: [],
      selectedCountry: null,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "testName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Name (Arabic)",
        sortable: true,
        selector: "testNameAr",
        right: true,
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "isActive",
        format: (row) => (
          <>
            {row.isActive ? (
              <div className="badge badge-success">
                <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                Active
              </div>
            ) : (
              <div className="badge badge-warning">
                <ArchiveOutlinedIcon></ArchiveOutlinedIcon>Archived
              </div>
            )}
          </>
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (row) => (
          <ActionBar alignRight className="action-menu valeo-dropdown">
            <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
              <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
            </ActionBar.Toggle>
            <ActionBar.Menu>
              <ActionBar.Item onClick={() => this.editTest(row.index)}>
                <EditIcon className="icon-small"></EditIcon>Edit
              </ActionBar.Item>
              {row.isActive ? (
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row)}
                >
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  Archive
                </ActionBar.Item>
              ) : (
                <ActionBar.Item
                  className="success"
                  onClick={() => this.showConfirm(row)}
                >
                  <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
                  Restore
                </ActionBar.Item>
              )}
            </ActionBar.Menu>
          </ActionBar>
        ),
      },
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.saveTest = this.saveTest.bind(this);
    this.updateTest = this.updateTest.bind(this);
    this.formReset = this.formReset.bind(this);
    this.editTest = this.editTest.bind(this);

    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);

    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.NON_BLOOD_BIOMARKER_LIST;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  validate = () => {
    const errors = {};
    const {
      testName,
      testNameAr,
      internalName,
      description,
      descriptionAr,
      testCategoryId,
      test_type,
    } = this.state;

    if (!testName || testName.trim() === "")
      errors.testName = "Test Name in English cannot be blank";

    if (!testNameAr || testNameAr.trim() === "")
      errors.testNameAr = "Test Name in Arabic cannot be blank";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal name cannot be blank";

    if (!description || description.trim() === "")
      errors.description = "Description in English cannot be blank";

    if (!descriptionAr || descriptionAr.trim() === "")
      errors.descriptionAr = "Description in Arabic cannot be blank";

    if (!test_type || test_type.trim() === "")
      errors.test_type = "Test Type cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveTest() {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const {
      testName,
      testNameAr,
      description,
      descriptionAr,
      test_type,
      testCategoryId,
      isActive,
      internalName,
      selectedCountry,
    } = this.state;

    this.hideModal();
    this.showLoading();

    PhoenixAPI.post("non-blood-biomarkers", {
      testName,
      testNameAr,
      descriptionAr,
      description,
      type: test_type,
      testCategoryId: testCategoryId,
      isActive,
      internalName,
      countryId: selectedCountry.id,
    })
      .then((response) => {
        this.loadData();
        this.props.showNotificationMessage({
          notificationMessage: "Test successfully created.",
          successMessage: true,
          showNotification: true,
        });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on creating test", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }
  updateTest() {
    const {
      testName,
      testNameAr,
      descriptionAr,
      description,
      test_type,
      testCategoryId,
      isActive,
      edit_id,
      internalName,
    } = this.state;

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    this.hideModal();
    this.props.toggleLoading({
      isLoading: true,
    });

    PhoenixAPI.patch("non-blood-biomarkers/" + edit_id, {
      testName,
      testNameAr,
      descriptionAr,
      description,
      type: test_type,
      testCategoryId,
      isActive,
      internalName,
    })
      .then((response) => {
        this.loadData();
        this.props.showNotificationMessage({
          notificationMessage: "Test successfully updated.",
          successMessage: true,
          showNotification: true,
        });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on updating test", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });

        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }

  hideModal() {
    this.setState({ showModal: false });
    this.formReset();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.isActive) {
      confirmTitle = "Archive Test";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Test successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Test";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Test successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  formReset() {
    this.submitted = false;
    this.setState({
      testName: "",
      testNameAr: "",
      descriptionAr: "",
      description: "",
      errors: {},
      internalName: "",
      edit_id: null,
      test_type: "",
      formHeading: "Add Non Blood Biomarker Details",
      showAlert: true,
      errors: {},
      test_category: [],
      testCategoryId: null,
    });
    this.props.showNotificationMessage({
      notificationMessage: "",
      successMessage: true,
      showNotification: false,
    });
  }

  editTest(index) {
    const { testList } = this.state;
    this.setState({
      testName: testList[index].testName,
      testNameAr: testList[index].testNameAr,
      description: testList[index].description,
      descriptionAr: testList[index].descriptionAr,
      internalName: testList[index].internalName,
      edit_id: testList[index].id,
      test_type: testList[index].type,
      testCategoryId: testList[index].testCategoryId,
      formHeading: "Edit Non Blood Biomarker Test Details",
      showAlert: false,
      isActive: testList[index].isActive,
    });

    this.showModal();
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });

    PhoenixAPI.patch("non-blood-biomarkers/" + item.id, {
      isActive: !item.isActive,
      testName: item.testName,
      testNameAr: item.testNameAr,
      descriptionAr: item.descriptionAr,
      description: item.description,
      type: item.test_type,
      testCategoryId: item.testCategoryId,
      internalName: item.internalName,
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on toggling status of test", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  editAllowed(orders, testId, testType) {
    for (let order of orders) {
      for (let item of order.order_items) {
        if (item.test === testId && item.test_type === testType) {
          return false;
        }
      }
    }

    return true;
  }

  getTestCategories() {
    API.get("test-category/")
      .then((response3) => {
        let categoryList = response3.data;
        categoryList = filterList(categoryList, "active");
        let temp = [];
        categoryList.map((item) => {
          temp.push({
            text: item.name,
            value: item.id,
            key: item.id,
          });
        });
        this.setState({
          testCategortList: temp,
        });
      })
      .catch((err) => {
        console.error("Error fetching test categories!", err);
      });
  }

  loadData() {
    let activeOrders = [];
    const { searchApplied, searchTerm } = this.state;

    this.getTestCategories();
    this.showLoading();
    PhoenixAPI.get("non-blood-biomarkers")
      .then((response2) => {
        let testList = response2.data;

        for (let test of testList) {
          test.editAllowed = true;
        }

        const activeList = filterList(testList, "active");
        const archivedList = filterList(testList, "archived");

        this.setState({
          testList: testList,
          activeList,
          archivedList,
          isLoaded: true,
          // nextURL: orderData.next
        });

        if (searchApplied) this.handleSearch(searchTerm);
        this.hideLoading();
      })
      .catch((error) => {
        console.log("Error fetching tests from backend!", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
        this.props.toggleLoading({
          isLoading: false,
        });
      });
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();

    this.loadData();
  }

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  filterCategory(filter) {
    this.setState({
      categoryFilter: filter,
    });
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  getFilteredNonBloodTests = () => {
    const testLists = this.getNonBloodListForCurrentCountry();
    return this.getSearchedNonBlooLists(testLists);
  };

  getNonBloodListForCurrentCountry = () => {
    const { testList, selectedCountry } = this.state;
    return testList && selectedCountry
      ? testList.filter((testList) => testList.countryId === selectedCountry.id)
      : [];
  };

  getSearchedNonBlooLists = (testList) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return testList;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return testList.filter((item) => {
      const { id, testName, testNameAr } = item;
      return (
        (testName && testName.toLowerCase().indexOf(newValue) !== -1) ||
        (testNameAr && testNameAr.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  render() {
    const {
      showAlert,
      loadError,
      searchApplied,
      errors = {},
      isEditable,
      countries,
      selectedCountry,
      testList,
    } = this.state;
    console.log("testListtestList", testList);
    const filteredNonBloodTests = this.getFilteredNonBloodTests();

    const form = (
      <>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Test Name (English) *</label>

            <input
              type="text"
              name="testName"
              className={
                "form-control py-2" + (errors.testName ? " has-error" : "")
              }
              placeholder="Test Name in English"
              onChange={this.handleChange}
              value={this.state.testName}
            ></input>
            <span className="help-block">{errors.testName}</span>
          </div>
          <div className="form-group col">
            <label>Test Name (Arabic) *</label>
            <input
              type="text"
              name="testNameAr"
              className={
                "form-control py-2 input-arabic" +
                (errors.testNameAr ? " has-error" : "")
              }
              placeholder="Test Name in Arabic"
              onChange={this.handleChange}
              value={this.state.testNameAr}
            ></input>
            <span className="help-block">{errors.testNameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Internal Name *</label>
            <textarea
              type="text"
              name="internalName"
              className={
                "form-control py-2" + (errors.internalName ? " has-error" : "")
              }
              placeholder="Internal Name"
              onChange={this.handleChange}
              value={this.state.internalName}
            ></textarea>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label>Type *</label>
            <Dropdown
              value={this.state.test_type}
              // disabled={this.state.edit_id ? true : false}
              className={
                "form-control py-2" + (errors.test_type ? " has-error" : "")
              }
              placeholder="Select type"
              onChange={(event, value) => {
                this.setState({ test_type: value.value });
              }}
              search
              options={this.state.arrTestTypeList}
            />
            <span className="help-block">{errors.test_type}</span>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col">
            <label>Test Description (English) *</label>
            <textarea
              type="text"
              name="description"
              className={
                "form-control py-2" + (errors.description ? " has-error" : "")
              }
              placeholder="Description in English"
              onChange={this.handleChange}
              value={this.state.description}
            ></textarea>
            <span className="help-block">{errors.description}</span>
          </div>
          <div className="form-group col">
            <label>Test Description (Arabic) *</label>
            <textarea
              type="text"
              name="descriptionAr"
              className={
                "form-control py-2 input-arabic" +
                (errors.descriptionAr ? " has-error" : "")
              }
              placeholder="Description in Arabic"
              onChange={this.handleChange}
              value={this.state.descriptionAr}
            ></textarea>
            <span className="help-block">{errors.descriptionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={this.state.isActive}
                onChange={(event, value) => {
                  this.setState({
                    isActive: value.value,
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {/* {this.renderPencilIcon("status")} */}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label>Category</label>
            <Dropdown
              value={this.state.testCategoryId}
              className={
                "form-control py-2 input-arabic" +
                (errors.testCategoryId ? " has-error" : "")
              }
              placeholder="Select type"
              onChange={(event, value) => {
                this.setState({ testCategoryId: value.value });
              }}
              search
              selection
              options={this.state.testCategortList}
            />
          </div>
        </div>

        {showAlert ? (
          <Alert
            variant="warning"
            onClose={() => {
              this.setState({ showAlert: false });
            }}
            dismissible
            className="mt-2"
          >
            You can't change the type once the test is created.
          </Alert>
        ) : null}
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <>
        <div className="manage-test">
          <CountryDropdown
            countries={countries || []}
            selectedCountry={selectedCountry}
            onSelect={this.handleCountrySelection}
          />
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={this.state.searchTerm}
                  clearSearch={this.handleSearchClear}
                  handleSearch={this.handleSearch}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>

              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.showModal}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>
          <Card>
            {this.state.testCategortList && (
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={filteredNonBloodTests}
                // defaultSortField="name"
                defaultSortField="id"
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
                pagination
                paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                responsive
                noHeader={searchApplied ? false : true}
                title={
                  searchApplied ? (
                    <p className="table-header">
                      {"Found " + filteredNonBloodTests.length + " results"}
                    </p>
                  ) : (
                    ""
                  )
                }
                noDataComponent={
                  loadError ? (
                    <AlertBox message={loadError} error={true}></AlertBox>
                  ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
                }
              />
            )}
          </Card>

          <Modal
            size="lg"
            show={this.state.showModal}
            onHide={this.hideModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.formHeading}</Modal.Title>
              <button
                onClick={this.state.edit_id ? this.updateTest : this.saveTest}
                className="btn button button-green"
                style={{
                  background: "#cee741",
                  color: "black",
                  width: "100px",
                  border: "none",
                  fontWeight: "700",
                }}
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>
          </Modal>
          <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.confirmTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.confirmMessage}</Modal.Body>
            <Modal.Footer>
              {this.state.confirmType === "danger" ? (
                <button
                  onClick={() =>
                    this.toggleActiveStatus(this.state.confirmItem)
                  }
                  className="btn button btn-danger"
                >
                  Archive
                </button>
              ) : (
                <button
                  onClick={() =>
                    this.toggleActiveStatus(this.state.confirmItem)
                  }
                  className="btn button btn-success"
                >
                  Restore
                </button>
              )}
              <button
                className="btn btn-secondary button"
                onClick={this.hideConfirm}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageNonBloodTests);
