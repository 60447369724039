import API from "utils/API";

const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_CITIES = "cities/";

const countryMapper = (country) => ({
  id: country.id,
  country_name: country.country_name,
  country_name_ar: country.country_name_ar,
  currency: country.currency,
  is_active: country.is_active,
  is_default: country.is_default,
  country_code: country.country_code,
  vat_percentage:country.vat_percentage,
});

export const fetchCountries = async () => {
  try {
    const { data: allCountries = [] } = await API.get(BASE_URL_COUNTRIES);
    return allCountries.map(countryMapper);
  } catch (error) {
    console.error("fetchCountries:: Error on fetching countries", error);
    throw error;
  }
};

export const fetchCities = async () => {
  try {
    const { data: cities = [] } = await API.get(BASE_URL_CITIES);
    return cities;
  } catch (error) {
    console.log("fetchCities:: Error on fetching cities:", error.message);
    throw error;
  }
};

export const fetchCountry = async (countryId) => {
  try {
    const { data: country = [] } = await API.get(
      BASE_URL_COUNTRIES + countryId
    );
    return country;
  } catch (error) {
    console.error(
      `Error on fetching country with countryId: ${countryId}`,
      error
    );
    throw error;
  }
};

export const fetchCity = async (cityId) => {
  try {
    const { data: city = [] } = await API.get(
      BASE_URL_CITIES + cityId
    );
    return city;
  } catch (error) {
    console.error(
      `Error on fetching country with countryId: ${cityId}`,
      error
    );
    throw error;
  }
};

const RegionService = { fetchCountries, fetchCountry, fetchCity };

export default RegionService;
