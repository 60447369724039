import { showNotification } from "./NotificationService";
import store from "store/index";
import moment from "moment";
import { isEmpty } from "../utils/FormValidator";
import { databaseRef } from "../config/firebase";
import { getUserId } from "../services/UserService";
import API from "utils/API";

export function connectDB() {
  //fetch all the clients of nutritionist
  const clients = [...store.getState().clients];
  const nutritionistId = getUserId(); //fetch id of nutritionist to find out channel
  //for each of clients fetch the messages from db
  for (let client of clients) {
    // each chat channel have a format of 1234_5678(where 1234 is client id & 5678 is nutritionist id)
    databaseRef
      .child(client.user_id + "_" + nutritionistId)
      .on("value", (snapshot) => {
        client.messages = [];
        if (snapshot.val()) {
          let messagesObj = snapshot.val();
          let messages = [];
          Object.keys(messagesObj).forEach((key) => {
            messages.push(messagesObj[key]);
          });
          //formating
          messages = messages.map((message) => {
            return {
              text: message.text,
              user: message.user._id,
              time: moment(message.timestamp).format("hh:mm A"),
              date: moment(message.timestamp).format("DD-MMMM-YYYY"),
            };
          });
          //pushing to state
          store.dispatch({
            type: "SHOW_MESSAGES",
            value: {
              messages: messages,
            },
          });
          //saving inside each clients
          for (let message of store.getState().messages) {
            client.messages.push({
              text: message.text,
              time: moment(message.timestamp).format("hh:mm A"),
              date: moment(message.timestamp).format("DD-MMMM-YYYY"),
              user: message.user,
            });
          }
          // debugger;
          loadClients(clients);
        }
      });
  }
}

//load clients and their messages to the redux store
export function loadClients(clients) {
  store.dispatch({
    type: "ADD_MESSAGE",
    value: {
      clients: clients,
    },
  });
}

export function sendMessage(id, message) {
  const clients = [...store.getState().clients];
  for (let client of clients) {
    if (client.user_id === id) {
      if (!client.messages) client.messages = [];

      sendChatNotification(id);
    }
  }
}

//add sent message (for adding message history only)
//message should contain the timestamp
//message is marked as delivered on success
export function addMessage(id, message) {
  const clients = [...store.getState().clients];

  if (isEmpty(message) || !id || !clients.length) return;

  for (let client of clients) {
    if (client.id === id) {
      if (!client.messages) client.messages = [];

      const time = moment(message.timestamp).format("hh:mm A");
      const date = moment(message.timestamp).format("DD-MMMM-YYYY");
      client.messages.push({
        text: message.text,
        time: time,
        date: date,
        delivered: true,
      });
      loadClients(clients);
      return;
    }
  }
}

//mark the sent message as delivered
//use the index received while sending message using sendMessage()
export function markAsDelivered(id, index) {
  const clients = [...store.getState().clients];

  for (let client of clients) {
    if (client.id === id) {
      client.messages[index].pending = false;
      client.messages[index].delivered = true;
      loadClients(clients);
      return;
    }
  }
}

//mark the sent message as failed
//use the index received while sending message using sendMessage()
export function markAsFailed(id, index) {
  const clients = [...store.getState().clients];

  for (let client of clients) {
    if (client.id === id) {
      client.messages[index].pending = false;
      client.messages[index].failed = true;
      loadClients(clients);
      return;
    }
  }
}

//add response message to the redux store (for realtime response only)
//message should contain the timestamp
//will send a notification on the sidebar
export function sendResponse(id, message) {
  const clients = [...store.getState().clients];

  if (!message || isEmpty(message.text) || !id || !clients.length) return;

  for (let client of clients) {
    if (client.id === id) {
      if (!client.messages) client.messages = [];

      const time = moment.unix(message.timestamp).format("hh:mm A");
      const date = moment.unix(message.timestamp).format("DD-MMMM-YYYY");

      client.messages.push({
        text: message.text,
        time: time,
        date: date,
        isResponse: true,
      });

      sendChatNotification(client.id);
      loadClients(clients);
      return;
    }
  }
}

//add response message to the redux store ((for adding message history only))
//message should contain the timestamp
//will not send notification
export function addResponse(id, message) {
  const clients = [...store.getState().clients];

  if (!message || isEmpty(message.text) || !id || !clients.length) return;

  for (let client of clients) {
    if (client.user_id === id) {
      // client.messages.push({
      //   text: message.text,
      //   time: message.time,
      //   date: message.date,
      //   isResponse: true
      // });
      client.messages.push({
        text: message.text,
        user: id,
        time: Date.now(),
      });
      //firebase push reply to db
      const nutritionistId = getUserId(); //fetch id of nutritionist to find out channel
      databaseRef.child(id + "_" + nutritionistId).push({
        text: message.text,
        user: { _id: nutritionistId },
        timestamp: Date.now(),
      });

      loadClients(clients);
      return;
    }
  }
}

//send notification for a particular chat
//notification count is incremented everytime
export function sendChatNotification(id) {
  // const clients = [...store.getState().clients];
  // const activeChatId = store.getState().activeChatId;

  // for (let client of clients) {
  //   if (client.id === id) {
  //     if (!client.messages) client.messages = [];

  //     if (activeChatId !== client.id) {
  //       client.notifications = client.notifications
  //         ? client.notifications + 1
  //         : 1;
  //     }

  //     showNotification("chat");
  //     return;
  //   }
  // }
  API.post("send-notifications/", {
    user: id,
    notification_title: "Message",
    notification_text: "New message recieved",
    notification_data: {
      sender_id: getUserId(),
      notification_type: "chat",
    },
  })
    .then((response) => {
      console.log("Notification send success");
    })
    .catch((error) => {
      console.log("Notification send failed");
    });
}

export function disableChat(id) {
  const clients = [...store.getState().clients];

  for (let client of clients) {
    if (client.id === id) {
      client.disabled = true;

      loadClients(clients);
      return;
    }
  }
}

export function enableChat(id) {
  const clients = [...store.getState().clients];

  for (let client of clients) {
    if (client.id === id) {
      client.disabled = false;

      loadClients(clients);
      return;
    }
  }
}
