import React, { useEffect, useState } from "react";
import Pdf from "@material-ui/icons/PictureAsPdf";
import API from "utils/API";

const BASE_URL_LAB_RESULT = "lab-result/";

function DownloadLabReportCard({ orderId, labResultFiles }) {
  const [labResult, setLabResult] = useState(null);

  // useEffect(() => fetchLabResults(orderId), [orderId]);  

 useEffect(()=>{    //new function for deprecating the API Call
    if(labResultFiles && labResultFiles.length>0){
      const labResult = labResultFiles.find(
        (labResult) => labResult.order === orderId
      );
      setLabResult(labResult);
    }
 },[orderId])

  const fetchLabResults = (orderId) => {
    if (!orderId) {
      console.log("Can not fetch lab results, invalid order id: ", orderId);
      return;
    }

    const url = `${BASE_URL_LAB_RESULT}?order=${orderId}`;
    API.get(url)
      .then((response) => {
        const { data: labResults = [] } = response;
        const labResult = labResults.find(
          (labResult) => labResult.order === orderId
        );
        console.log("Lab Result: ", labResult);
        setLabResult(labResult);
      })
      .catch((error) => {
        console.log("Error on fetching lab results!", error);
      });
  };

  const renderDownloadReportView = () => {
    if (!labResult || !labResult.lab_result)
      return (
        <div>
          <p>Lab Results not uploaded</p>
        </div>
      );

    return (
      <form>
        <div className="form-row">
          <div className="col-12">
            <a className="view-pdf" href={labResult.lab_result_s3_url} target="_blank">
              <label>
                <Pdf></Pdf> View Result document
              </label>
            </a>
          </div>
        </div>
      </form>
    );
  };

  return (
    <div className="ui raised card">
      <div className="content">
        <div className="font-weight-bold mb-2">View/Download Report</div>
        <div className="description">{renderDownloadReportView()}</div>
      </div>
    </div>
  );
}

export default DownloadLabReportCard;
