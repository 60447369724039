import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";

const BASE_URL_CUSTOMER_TRANSACTIONS = "admin/customer-orders";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class ClientTransactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactionDetails: [],
    };

    this.columns = [
      {
        name: "Date",
        selector: "createdAt",
        sortable: true,
        width: "200px",
        wrap: true,
        format: (transaction) =>
          transaction.createdAt
            ? this.getFormattedTransactionDate(transaction.createdAt)
            : "-",
      },
      {
        name: "Order ID",
        selector: "itemOrderId",
        sortable: true,
        width: "120px",
      },
      {
        name: "Parent Order ID",
        selector: "orderUUID",
        sortable: true,
        wrap: true,
        width: "180px",
      },
      {
        name: "Package",
        selector: "itemNameEnglish",
        sortable: true,
        width: "300px",
      },
      {
        name: "Price",
        selector: "finalPrice",
        sortable: true,
        width: "120px",
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortable: true,
      },
    ];
  }

  componentDidMount() {
    if (this.props.clientId) {
      this.fetchCustomerTransactions();
    }
  }

  getFormattedTransactionDate = (transactionDate) => {
    const date = moment(transactionDate).format("MMM DD, YYYY");
    const time = moment(transactionDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  fetchCustomerTransactions = async () => {
    try {
      const response = await PhoenixAPI.get(BASE_URL_CUSTOMER_TRANSACTIONS, {
        params: { userId: this.props.clientId },
      });
      const { cartOrders: data = [] } = (response && response.data) || [];
      let transactionDetails = [];
      let items = data.forEach((it, index) => {
        transactionDetails.push(...it.items);
      });
      this.setState({ transactionDetails });
    } catch (error) {
      console.log(
        "Error in fetching customer orders from url:",
        BASE_URL_CUSTOMER_TRANSACTIONS
      );
      console.log("Error in fetching admin customer orders", error.message);
      this.showErrorNotification(
        "Error in fetching admin customer orders",
        error.message
      );
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { transactionDetails } = this.state;
    return (
      <>
        <Card>
          <DataTable
            columns={this.columns}
            data={transactionDetails || []}
            responsive
            highlightOnHover
            sortIcon={<ArrowDownward></ArrowDownward>}
            defaultSortField="createdAt"
            defaultSortAsc={false}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            title={
              <p className="table-header">
                {"Found " + transactionDetails.length + " results"}
              </p>
            }
          />
        </Card>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientTransactions);
