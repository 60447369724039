import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectPackage.propTypes = {
  selectedPackage: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectPackage.defaultTypes = {
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectPackage(props) {
  const {
    packages,
    selectedPackage,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;
 

  const findPackage = (bloodPackageId) =>
  packages.find((bloodPackage) => bloodPackage.id === bloodPackageId);

  const options = createOptions(packages, showOnlyActive);

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selectedPackageObject = findPackage(selectedPackage);
  const selectedPackageOption = selectedPackageObject
    ? createOption(selectedPackageObject)
    : null;

  return (
    <Select
      key={`my_unique_select_key__${selectedPackage && selectedPackage.id}`}
      value={selectedPackageOption}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      placeholder="Select Package..."
      isDisabled={isDisabled}
    />
  );
}

function createOptions(packages) {
  if (!packages || !packages.length) return [];

  return [
    {
      options: packages.map(createOption),
    },
  ];
}

const createOption = (packageOption) => {
  return packageOption
    ? {
        key: packageOption.id,
        value: packageOption.id,
        label: packageOption.internal_name,
      }
    : null;
};

export default SelectPackage;
