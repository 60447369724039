
import React, { Component } from 'react';
import { connect } from "react-redux";

import { Card, Button, Modal, Form,Tabs,Tab } from 'react-bootstrap';

import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import AccessDenied from "components/Common/AccessDenied";
import { fetchCountries } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AlertBox from "components/AlertBox/AlertBox";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const sampleDetails_url="admin/sample-details";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class SampleDetails extends Component {

    constructor(props) {
        super(props);
       this.state={
        sampleDetails:[],
        sampleDetail:{},
        errors: {},
        editables: {},
        isSaving:false,
        isNewSample:false,
        isEditable:true,
        sampleUnitOptions:[
          { key:"grams",
            value: "grams",
            text: "grams"
          },
          {
            key:"ml",
            value: "ml",
            text: "ml"
          },
          {
            key:"other",
            value: "other",
            text: "other"
          }
        ],


        
       }

       

        this.columns = [
            {
                name: "ID",
                selector: "id",
                sortable: true,
            },
            {
                name:"sampleType",
                selector: "sampleName",
                sortable: true,
            },
            {
                name: "status",
                selector:"isActive",
                sortable:true,
                cell: (sampling) => this.renderStatus(sampling.isActive),
  
            },
            {
                name: "Action",
                center: true,
                minWidth: "170px",
                cell: (sampling) => this.renderViewButton(sampling.id),
              }
            
        ];

    }


    renderStatus = (status) => {
      if (status)
        return (
          <div className="badge badge-success pt-1">
            <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
            Active
          </div>
        );
      else
        return (
          <div className="badge badge-warning pt-1">
            <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
            Inactive
          </div>
        );
    };

    hasPageAccessRight = (accessRightName) => {
        const { aclUser, pageAccessRights } = this.state;
        // If user is not configured as an acl user then he has all the rights as previous.
        if (!aclUser || isUserSuperAdmin(aclUser)) return true;
        if(!aclUser || isUserAdmin(aclUser)) return true;
        if (pageAccessRights && pageAccessRights.length && accessRightName)
          return pageAccessRights.includes(accessRightName.toLowerCase());
        return false;
      };
    
      fetchAclUser = async () => {
        const userId = getUserId();
        if (!userId) {
          console.log("fetchAclUser:: Invalid user id!", { userId });
          return;
        }
    
        try {
          const pageName = Page.VALEO_AMBASSADORS;
          const { data: aclUser } = await PhoenixAPI.get(
            `/api/v1/acl/users/userId/${userId}`
          );
          const pagePermission =
            aclUser?.pagePermissions?.find(
              (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
            ) ?? null;
          const pageAccessRights =
            pagePermission?.pageAccessRights
              ?.filter((it) => it.isActive ?? false)
              ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
          const accessDenied = isAccessDenied(aclUser, pageAccessRights);
          this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
            const isEditable = this.hasPageAccessRight("edit");
            this.setState({ isEditable });
          });
        } catch (error) {
          console.log("fetchAclUser:: Error on fetching acl user!", error);
        }
      };
      
    
      handleCountrySelection = (selectedCountry) =>
        this.setState({ selectedCountry });
    
      showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);
    
      showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);
    
      showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });
    
      showLoading = () => this.props.toggleLoading({ isLoading: true });
    
      hideLoading = () => this.props.toggleLoading({ isLoading: false });



    renderViewButton = (sampleId) => (
        <div>
          <button
            className="btn px-5"
            style={{ backgroundColor: "#CEE741" }}
            onClick={() => {
                this.handleView(sampleId);
            }}
          >
            View
          </button>
        </div>
      );

      showLoading = () => this.props.toggleLoading({ isLoading: true });
    
      hideLoading = () => this.props.toggleLoading({ isLoading: false });
      
      handleView = (id) => {
        const sampleDetails = this.state.sampleDetails || [];
        const sampleDetail= sampleDetails.find((it) => it.id === id);
        if (sampleDetail) {
        //  corporatePartner.partnerLogoSrc = corporatePartner.partnerLogo ?? null;
          this.setState({ sampleDetail, isNewSample: false }, () =>
            this.showSampleViewModal()
          );
        }
      };

      async componentDidMount() {
        this.fetchSamples();
    }

    validate(){
      const errors = {};
      const{sampleDetail,sampleDetails}=this.state;
      if(!sampleDetail.sampleName){
        errors.sampleName = "Sample Type is required";
      }
      if(sampleDetail.sampleName && sampleDetail.sampleName.trim() !=="")
      {
        if(sampleDetails.length>0){
        const existingSample = sampleDetails.find((it) => it.isActive && it.sampleName.toLowerCase() === sampleDetail.sampleName.toLowerCase());
        if (existingSample && existingSample.id!== sampleDetail.id) {
          errors.sampleName = "Sample Type already exists";
        }
        }
      }
      
      
      if(!sampleDetail.units){
        errors.sampleUnit = "Unit is required";
      }
      if(sampleDetail.units!=="other"){
      if(!sampleDetail.unitSize){
        errors.unitSize = "Unit Size is required";
      }
    }
      if(sampleDetail.units=="other"){
        if(!sampleDetail.othersUnit){
          errors.othersUnit = "Other Unit is required";
        }
      }
      

      return Object.keys(errors).length === 0 ? null : errors;
    }

    handleSave=async()=>{
      const errors = this.validate();
      this.setState({ errors: errors || {} });
      if (errors) {
        this.showErrorNotification("There are some invalid form fields!");
        this.setState({ isSaving: false });
        return;
      }
  
      const { sampleDetail } = this.state;
    
      
     
      try {
        this.showLoading();
        
        const payload = { ...sampleDetail };
        const  url=sampleDetail.id
            ? `/admin/sample-details/${sampleDetail.id}`
            : "/admin/sample-details";
          
      const response=sampleDetail.id? await PhoenixAPI.patch(url,payload): await PhoenixAPI.post(url,payload);
        this.hideModal();
        this.showSuccessNotification("Saved Successfully.");
        this.fetchSamples();
      } catch (error) {
        this.showErrorNotification("Some error occurred: " + error.message);
      } finally {
        this.setState({ isSaving: false }, this.hideLoading);
      }
    }

     fetchSamples=async()=>{
      try {
        this.showLoading();
        const { data: sampleDetails = [] } = await PhoenixAPI.get(`${sampleDetails_url}`);
        this.setState({ sampleDetails });
      } catch (error) {
        console.log(
          "fetchCorporatePartners:: Error on fetching corporate partners!",
          error
        );
        this.showErrorNotification(
          "Error on fetching corporate partners! " + error.message ?? ""
        );
        this.setState({ loadError: "Some error has occured. Please try again" });
      } finally {
        this.hideLoading();
      }
     }
      showSampleViewModal = () => {
        
        const { isNewSample } = this.state;
        
    
        if (isNewSample) {
          
          const sampleDetail = {
            
            
            isActive: false,
          };
          this.setState({ sampleDetail }, () => this.showModal());
        } else {
          this.showModal();
        }
      };
      showModal = () => {
        this.setState({ showModal: true });
      };
    
      hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
      };


      handleSearch = (queryString) => {
        this.setState({
          searchApplied: true,
          searchTerm: queryString ?? "",
        });
      };
    
      handleSearchClear = () => {
        this.setState({ searchTerm: "", searchApplied: false });
        this.search.searchInput.focus();
      };
    
      handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
          this.setState({ searchTerm: "", searchApplied: false });
      };
    
      handleAddNew = () => {
        this.setState({ isNewSample: true }, () =>
          this.showSampleViewModal()
        );
      };

      handleChange = ({ currentTarget: input }) => {
        const numberInputs=["unitSize"];
        const sampleDetail= { ...this.state.sampleDetail };
        if(numberInputs.includes(input.name)){
          sampleDetail[input.name]= input.value ? Number(input.value) : null;
        }
        else{
        sampleDetail[input.name] = input.value;
        }
        this.setState({ sampleDetail });
      };
      
      resetForm = () => {
        this.setState({
          errors: {},
          editables: {},
          sampleDetail: {},
        
          isSaving: false,
          isNewSample: false,
        });
      };

      getSamplesForCurrentCountry = () => {
        const { sampleDetails } = this.state;
     
    
       return sampleDetails;
      };
      renderPencilIcon = (fieldName) => {
        const setEditable = (field) => {
          const editables = { ...this.state.editables };
          editables[field] = true;
          this.setState({ editables });
        };
    
        if ( this.state.isNewSample) return null;
        return (
          <div
            onClick={() => setEditable(fieldName)}
            style={{ cursor: "pointer" }}
            className="input-group-append"
          >
            <i className="input-group-text fa fa-pencil"></i>
          </div>
        );
      };
      
      

      getFilteredSamples=()=>{
        return  this.getSearchedSample(this.getSamplesForCurrentCountry())
      }
  
      
          getSearchedSample = (samples) => {
              const { searchApplied, searchTerm } = this.state;
          
              if (!searchApplied || !searchTerm) return samples;
          
              const newValue = searchTerm.slice(0).trim().toLowerCase();
              return samples.filter((sample) => {
                const { id, sampleName } =sample;
                return (
                  (id && id.toString().includes(newValue)) ||
                  (sampleName && sampleName.toLowerCase().indexOf(newValue) !== -1) 
                  
                );
              });
            };
      

    render() {
       
      const{
       sampleDetail,
        errors,
        isNewSample,
        editables,
        sampleDetails,
        isEditable,
        searchTerm,
        isSaving
    }=this.state;
    const filteredSamples=this.getFilteredSamples(sampleDetails)
    
      const sampleDetailForm=(
        <div className="container mt-4">
                <div className="row">
          <div className="form-group col">
          <label htmlFor="sampleName">Sample Details Name*</label>
            <div className="input-group">
              <input
                value={sampleDetail?.sampleName || ""}
                onChange={this.handleChange}
                id="sampleName"
                name="sampleName"
                type="text"
                placeholder="Enter Sample Name"
                className={
                  "form-control py-2" + (errors.sampleName ? " has-error" : "")
                }
                disabled={!isNewSample && !editables.sampleName}
              />
              {this.renderPencilIcon("sampleName")}
            </div>
            <span className="help-block">{errors.sampleName}</span>
          </div>
          <div className='form-group col'>
          <label htmlFor="sampleUnit">Unit*</label>
          <div className="input-group">
            
            <Dropdown
            value={sampleDetail?.units}
            onChange={(event, value) => {
              this.setState({sampleDetail:{ ...this.state.sampleDetail,units: value.value }});
            }}
            id="units"
            placeholder="units"
            search
            selection
            options={this.state.sampleUnitOptions}
            disabled={!isNewSample && !editables.sampleUnit}
            className={
              "form-control" + (errors.sampleUnit ? " has-error" : "")
            }
          />
          {this.renderPencilIcon("sampleUnit")}
            
          </div>
          <span className="help-block">{errors.sampleUnit}</span>
         </div>
        </div>
        {sampleDetail.units=="other"?
        <div className="row">
          <div className="form-group col-6">
          <label htmlFor="sampleUnit">Specify Others*</label>
          <div className="input-group">
              <input
                value={sampleDetail?.othersUnit || ""}
                onChange={this.handleChange}
                id="othersUnit"
                name="othersUnit"
                type="text"
                placeholder="Enter Other Unit"
                className={
                  "form-control py-2" + (errors.othersUnit ? " has-error" : "")
                }
                disabled={!isNewSample && !editables.othersUnit}
              />
              {this.renderPencilIcon("othersUnit")}
            </div>
            <span className="help-block">{errors.othersUnit}</span>
            </div>
          </div>
        :<></>}

       <div className="row">
       {sampleDetail.units=="other"?<></>:<div className="form-group col">
       <label htmlFor="Unit Size">Unit Size*</label>
       <div className="input-group">

        <input
              type="number"
              name="unitSize"
              className={
                "form-control py-2" + (errors.unitSize ? " has-error" : "")
              }
              placeholder="unit size"
              onChange={this.handleChange}
              value={sampleDetail?.unitSize??null} 
              onKeyPress={(event) => {
                if (!/[0-9 || .]/.test(event.key)) event.preventDefault();
              }}
              disabled={!isNewSample && !editables.unitSize}
            ></input>
            {this.renderPencilIcon("unitSize")}
            </div>
            <span className="help-block">{errors.unitSize}</span>
            </div>}
             <div className="form-group col-6">
             <label htmlFor="status">status*</label>
             <Dropdown
                value={sampleDetail?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    sampleDetail: {
                      ...this.state.sampleDetail,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSample && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />              
              {this.renderPencilIcon("status")}
             </div>
            </div>
          {sampleDetail.units=="other"?<></> : <div className="row">
           <div className="form-group col-6">
           <label htmlFor="calculations">Calculations :</label>
           <div className="input-group">

           <input
              type="number"
              name="unitSizeVariance"
              className={
                "form-control py-2" + (errors.unitSizeVariant ? " has-error" : "")
              }
              placeholder="unit size variance"
              onChange={this.handleChange}
              value={sampleDetail?.unitSizeVariance??null}
              onKeyPress={(event) => {
                if (!/[0-9|| .]/.test(event.key)) event.preventDefault();
              }}
              disabled={!isNewSample && !editables.unitSizeVariant}
            ></input>
            {this.renderPencilIcon("unitSizeVariant")}
            </div>
            <span className="help-block">{errors.costs}</span>
            </div>


           </div>}

            
          </div>
      )
    

        return(
          <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Sample Details</p>
          <DataTable
            data={filteredSamples}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredSamples.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Details: </span>
                {sampleDetail?.sampleName ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              type="button"
              onClick={(event) =>
                this.setState({ isSaving: true }, this.handleSave)
              }
              disabled={!isEditable || isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_sample_form">
              <Tab eventKey="key_details_form" title="Details">
                {sampleDetailForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
            
        )

    }
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SampleDetails);
