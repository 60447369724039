import React, { useState, useEffect } from 'react';

const BroadcastTimer = (props) => {
    // console.log("props",props.timer)

  
  const [timer, setTimer] = useState(0); // 600 seconds = 10 minutes


  useEffect(()=>{
    if(props.timer){
        let data = props.timer ? props.timer.split(':').map(Number):[];
        // console.log("data",data)
        let [ hours, minutes, seconds] = (data && data.length > 0)?data:[0,0,0]
        let totalSeconds = hours * 3600 + minutes * 60 + seconds;
        // console.log("totalSeconds",totalSeconds)
        setTimer(totalSeconds)
    }else{
        setTimer(0)
    }
  },[props.timer])

  useEffect(() => {
    const intervalId = setInterval(() => {
        if(timer > 0) {
            setTimer(prevTimer => prevTimer - 1);
        }
    
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  const formatTime = (timeInSeconds) => {
    // const minutes = Math.floor(timeInSeconds / 60);
    // const seconds = timeInSeconds % 60;
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, '0')}hr:${minutes.toString().padStart(2, '0')}min:${seconds.toString().padStart(2, '0')}sec`;
  };

  return (
    <div>
      {/* <h2>10 Minutes Timer</h2> */}
      <div style={{color:"#F77077"}}>{formatTime(timer)}</div>
    </div>
  );
};

export default BroadcastTimer;
