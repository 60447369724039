import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectHomecareCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategoryIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectHomecareCategories.defaultTypes = {
 categories: [],
 selectedCategoryIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectHomecareCategories(props) {
  const { categories, selectedCategoryIds, onSelect, menuPlacement, isDisabled, placeholder } = props;
  const findTest = (id) => categories.find((test) => test.id === id);
 console.log("Categroriess drop down",categories, selectedCategoryIds)


  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const testOptions = categories.map(createOption);
  const selectedTests = selectedCategoryIds
    ? selectedCategoryIds.map(findTest).map(createOption)
    : [];

  return (
    <Select
      key={`my_unique_select_key__${selectedTests && selectedTests.join(",")}`}
      value={selectedTests}
      onChange={onSelect}
      options={testOptions}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
}

const createOption = (test) => {
  if (!test) return;
  return {
    key: test.id,
    value: test.id,
    label: test.label,
  };
};

export default SelectHomecareCategories;
