import React, { Component } from 'react';
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PropTypes from "prop-types";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Card from "react-bootstrap/Card";
import PhoenixAPI from "utils/PhoenixAPI";
import { ChangeHistory, ClassRounded } from '@material-ui/icons';

const imageValidTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp']
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class SubCategoryForm extends Component {

  constructor(props) {
    super(props);
    const { subCategories = [], onSubCategoryUpdated = (f) => f, type = "" } = this.props;
    this.onSubCategoryUpdated = onSubCategoryUpdated;
    this.state = {
      subCategories,
      subCategory: {},
      errors: {},
      editables: {},
      isConfirmDeleteModalVisible: false,
      rankOptions: []
    };


    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Sub Category Name",
        selector: "nameEnglish",
        sortable: true,
        wrap: true,
        format: (service) => (
          <div className="text-overflow-ellipsis">
            {service.nameEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        sortable: true,
        width: "100px",
        maxWidth: "100px",
        center: true,
      },

      {
        name: "Status",
        selector: "isActive",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (subCategory) => <Status isActive={subCategory.isActive ?? false} />,
      },

      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (subCategory) => this.renderActionBar(subCategory),
      },
    ]

  }

  renderActionBar = (subCategory) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(subCategory.id);
        }}
      >
        Edit
      </button>
    </div>
  );



  renderPencilIcon = (fieldName) =>
    this.state.subCategory.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  handleEdit = (id) => {

    const { subCategories = [] } = this.state;
    const subCategory = subCategories.find((it) => it.id === id);
    if (!subCategory) return;

    this.setState({ subCategory, isNewSubCategory: false }, () => this.showAddEditModal());
  };

  validate = () => {
    const { type } = this.props;
    const { subCategory } = this.state;
    console.log("subCategory is", subCategory);
    const errors = {};
    if (!subCategory.nameEnglish) errors.subCategoryName = "Subcategory Name is required";
    if (!subCategory.nameArabic) errors.subCategoryNameArabic = "Subcategory Name Arabic is required";
    if (!subCategory.redirectionLinkEnglish) errors.redirectionLinkEnglish = "Subcategory Redirection link English is required";
    if (!subCategory.redirectionLinkArabic) errors.redirectionLinkArabic = "Subcategory Redirection link Arabic is required";

    if (!subCategory.rank) errors.rank = "Rank is required";

    if (type == "DESKTOP") {
      if (!subCategory.image) {

        errors.image = "Image is required";
      }
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }

  createRankOptions = () => {
    const { isNewSubCategory, subCategories } = this.state;

    const ranks = subCategories.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewSubCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  showAddEditModal = () => {
    const { isNewSubCategory } = this.state;
    const rankOptions = this.createRankOptions() || [];
    console.log("rank options in subcategory", rankOptions);
    if (isNewSubCategory) {

      const subCategory = {
        isActive: false,
        rank: Math.max(...rankOptions.map((it) => it.value)),
      };
      console.log("subcategory  is", subCategory);
      this.setState({ subCategory, rankOptions, isAddEditModalVisible: true });
    }
    else {
      this.setState({ rankOptions, isAddEditModalVisible: true });
    }
  };

  handleAddNewSubCategory = () => {
    this.setState({ isNewSubCategory: true }, () => this.showAddEditModal());
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };
  handleIconChange = async (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    console.log("change file", e.target.files[0]);

    if (!imageValidTypes.includes(file.type)) {
      this.showErrorNotification("please upload images");
    }
    else {
      if (file) {

        const formData = new FormData();
        formData.append("media", file)
        const response = await this.uploadFiles(formData);
        const subCategory = { ...this.state.subCategory };
        subCategory.image = response.data.message;
        this.setState({ subCategory }, () => this.showSuccessNotification("Image uploaded successfully"));

      }
    }

  }
  uploadFiles = async (formData) => {
    let response = null;
    try {
      response = await PhoenixAPI.post('/upload-media', formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          'Content-Type': 'multipart/form-data'
        }
      });
    }
    catch (error) {
      this.showErrorNotification("Some error occurred while upload: " + error.message);
    }

    return response;
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      subCategory: {},
    });
  };

  createSubCategory = () => {
    const { subCategories = [], subCategory } = this.state;
    const ids = subCategories.length > 0 ? subCategories.map((it) => it.id) : null;
    subCategory.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      subCategories.length > 0
        ? subCategories.filter((it) => it.rank).map((it) => it.rank)
        : null;
    subCategory.rank = ranks ? Math.max(...ranks) + 1 : 1;
    subCategory.is_new = true;
    console.log("subcategories in create subcategory", subCategories);

    this.setState({ subCategories: [...subCategories, subCategory] }, () => {
      this.showSuccessNotification("SubCategory Created Successfully!");
      this.onSubCategoryUpdatedLocally();
    });
  };

  updateSubCategory = (id) => {
    const { subCategories = [], subCategory } = this.state;
    const newSubCategories = subCategories.map((it) => (it.id === id ? subCategory : it));

    this.setState({ subCategories: newSubCategories }, () => {
      this.showSuccessNotification("Subcategory Updated Successfully!");
      this.onSubCategoryUpdatedLocally();
    });

  };


  onSubCategoryUpdatedLocally = () => {
    this.clearForm();
    this.onSubCategoryUpdated(
      this.state.subCategories.map((subcategory) => {
        const q = { ...subcategory };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }


        return q;
      })
    );
  };




  handleChange = ({ currentTarget: input }) => {
    const subCategory = { ...this.state.subCategory };
    subCategory[input.name] = input.value;
    this.setState({ subCategory });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    console.log("errors are", errors);
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { subCategory = {}, subCategories = [] } = this.state;


    if (subCategory.id === null || subCategory.id === undefined)
      this.createSubCategory();
    else this.updateSubCategory(subCategory.id);
    this.hideAddEditModal();
  };


  render() {
    const { type } = this.props;
    const { editables, subCategory, errors, subCategories } = this.state;
    const isNewSubCategory = subCategory.id === null || subCategory.id === undefined;
    console.log("type is", type)

    const serviceForm = (<><div className="row">
      <div className="form-group col">
        <label htmlFor="serviceName english">Sub Category Name (English) *</label>
        <div className="input-group">
          <input
            value={subCategory?.nameEnglish}
            onChange={this.handleChange}
            id="nameEnglish"
            name="nameEnglish"
            placeholder="subCategory Name  English"
            readOnly={!isNewSubCategory && !editables.subCategoryName}
            className={
              "form-control py-2" +
              (errors.subCategoryName ? " has-error" : "")
            }
          ></input>
          {this.renderPencilIcon("subCategoryName")}
        </div>
        <span className="help-block">{errors.subCategoryName}</span>
      </div>

      <div className="form-group col">
        <label htmlFor="serviceName">Sub Category Name (Arabic) *</label>
        <div className="input-group">
          <input
            value={subCategory?.nameArabic}
            onChange={this.handleChange}
            id="nameArabic"
            name="nameArabic"
            placeholder="subCategory Name  Arabic"
            readOnly={!isNewSubCategory && !editables.subCategoryNameArabic}
            className={
              "form-control py-2 input-arabic" +
              (errors.subCategoryNameArabic ? " has-error" : "")
            }
          ></input>
          {this.renderPencilIcon("subCategoryNameArabic")}
        </div>
        <span className="help-block">{errors.subCategoryNameArabic}</span>
      </div>

    </div>

      <div className="row">
        <div className="form-group col">
          <label htmlFor="rank">Rank *</label>
          <div className="input-group">
            <Dropdown
              value={subCategory?.rank}
              onChange={(event, value) => {
                this.setState({
                  subCategory: {
                    ...subCategory,
                    rank: value.value,
                  },
                })
              }}
              id="rank"
              placeholder="Rank"
              search
              selection
              options={this.state.rankOptions}
              disabled={!isNewSubCategory && !editables.rank}
              className={
                "form-control py-2" +
                (errors.rank ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("rank")}
          </div>
          <span className="help-block">{errors.rank}</span>
        </div>



        <div className="form-group col">
          <label htmlFor="status">Status*</label>
          <div className="input-group">
            <Dropdown
              value={subCategory?.isActive}
              onChange={(event, value) => {
                this.setState({
                  subCategory: {
                    ...subCategory,
                    isActive: value.value,
                  },
                });
              }}
              id="status"
              name="status"
              placeholder="Status"
              search
              selection
              options={STATUS_OPTIONS}
              disabled={!isNewSubCategory && !editables.status}
              className={"form-control" + (errors.status ? " has-error" : "")}
            />
            {this.renderPencilIcon("status")}
          </div>
          <span className="help-block">{errors.status}</span>
        </div>
      </div>

      <div className="row">
        <div className="form-group col">

          <label htmlFor="redirection link english">Redirection Link English*</label>
          <div className="input-group">
            <input
              type="text"
              className={
                "form-control py-2" +
                (errors.redirectionLinkEnglish ? " has-error" : "")
              }
              value={subCategory.redirectionLinkEnglish}
              onChange={this.handleChange}
              id="redirectionLinkEnglish"
              name="redirectionLinkEnglish"
              placeholder="redirectionLinkEnglish"
              readOnly={!isNewSubCategory && !editables.redirectionLinkEnglish}
            ></input>
            {this.renderPencilIcon("redirectionLinkEnglish")}
          </div>
          <span className="help-block">{errors.redirectionLinkEnglish}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="redirection link arabic">Redirection Link Arabic*</label>
          <div className="input-group">
            <input
              type="text"
              className={
                "form-control py-2 input-arabic" +
                (errors.redirectionLinkArabic ? " has-error" : "")
              }
              value={subCategory.redirectionLinkArabic}
              onChange={this.handleChange}
              id="redirectionLinkArabic"
              name="redirectionLinkArabic"
              placeholder="redirectionLinkArabic"
              readOnly={!isNewSubCategory && !editables.redirectionLinkArabic}
            ></input>
            {this.renderPencilIcon("redirectionLinkArabic")}
          </div>
          <span className="help-block">{errors.redirectionLinkArabic}</span>
        </div>

      </div>
      {
        type == "DESKTOP" ?
          <div className="row">
            <div className="form-group col">
              <label htmlFor="partnerName">categoryImage*</label>


              <div className="input-group">
                <input

                  onChange={this.handleIconChange}
                  id="categoryImage"
                  name="categoryImage"
                  type="file"
                  accept="image/*"
                  disabled={!isNewSubCategory && !editables.categoryImage}
                  className={"form-control" + (errors.companyLogo ? " has-error" : "")}
                />
                {this.renderPencilIcon("categoryImage")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>

            <div className="form-group col">
              {subCategory?.image && (
                <>
                  <label htmlFor="selected_package_image">
                    Image Preview
                  </label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={subCategory.image}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>)}
            </div>
          </div>
          : <></>}
    </>);

    return (<div className="container mt-4 px-0">
      <div className="row">
        <div className="form-group col">
          <Card>
            <p className="font-weight-bold mt-4 px-4">SubCategories</p>
            <DataTable
              highlightOnHover
              columns={this.columns}
              data={subCategories || []}
              /*onSort={(sortColumn, sortDirection) =>
                this.setState({
                  sortColumn: sortColumn.selector,
                  sortDirection,
                })
              }*/
              pagination
              responsive
              // defaultSortField={sortColumn}
              sortIcon={<ArrowDownward></ArrowDownward>}
            />
            <button
              onClick={this.handleAddNewSubCategory}
              disabled={!this.props.isEditable}
              className="btn btn-success mt-1"
            >
              Add New
            </button>

          </Card>
        </div>
      </div>
      <Modal
        size="lg"
        show={this.state.isAddEditModalVisible}
        onHide={this.hideAddEditModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {subCategory.id === null || subCategory.id === undefined
              ? "Add Service"
              : "Edit Service"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{serviceForm}</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary rounded-pill px-4 py-2"
            onClick={this.hideAddEditModal}
          >
            Cancel
          </button>
          <button
            onClick={this.handleSubmit}
            className="btn btn-lg btn-success rounded-pill px-4 py-2"
          >
            {subCategory.id ? "Save" : "Add"}
          </button>
        </Modal.Footer>
      </Modal>


    </div>);





  }

}


SubCategoryForm.propTypes = {
  subCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubCategoryUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

SubCategoryForm.defaultProps = {
  subCategories: [],
  onSubCategoryUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryForm);
