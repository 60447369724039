import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectArticleCategory.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategory: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SelectArticleCategory(props) {
  const {
    categories = [],
    selectedCategory,
    onSelect = (f) => f,
    isDisabled = false,
  } = props;

  const [activeCategories, inactiveCategories] = _.partition(
    categories,
    (it) => it.status === "Active"
  );
  activeCategories.sort((first, second) =>
    compareStrings(first.categoryNameEnglish, second.categoryNameEnglish, true)
  );
  inactiveCategories.sort((first, second) =>
    compareStrings(first.categoryNameEnglish, second.categoryNameEnglish, true)
  );
  const options = [
    {
      label: "Active",
      options: activeCategories.map(createCategoryOption),
    },
    {
      label: "Inactive",
      options: inactiveCategories.map(createCategoryOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedCategory && selectedCategory.id}`}
      value={createCategoryOption(selectedCategory)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      isDisabled={isDisabled}
    />
  );
}

const createCategoryOption = (category) => {
  return category
    ? {
        key: category.id,
        value: category.id,
        label: category.categoryNameEnglish,
      }
    : null;
};

export default SelectArticleCategory;
