import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CancelIcon from "@material-ui/icons/Cancel";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { Card, Modal } from "react-bootstrap";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDate } from "utils/commons";

function ClientSubscriptions(props) {
  const columns = [
    {
      name: "ID",
      selector: "subscriptionId",
      sortable: true,
      width: "100px",
      // maxWidth: "100px",
      
    },
    {
      name: "Package",
      selector: "packageName",
      sortable: true,
      wrap: true,
    },
    {
      name: "Package Type",
      selector: "packageType",
      sortable: true,
      wrap: true,
    },
    {
      name: "Start Date",
      selector: "subscriptionStartDate",
      sortable: true,
      wrap: true,
      format: (item) =>
        item.subscriptionStartDate
          ? formatDate(item.subscriptionStartDate)
          : "",
    },
    {
      name: "Frequency",
      selector: "noOfMonths",
      sortable: true,
      wrap: true,
      format: (item) =>
        item.noOfMonths > 1
          ? `${item.noOfMonths} Months`
          : `${item.noOfMonths} Month`,
    },
    {
      name: "Dicount Type",
      selector: "discountType",
      sortable: true,
      wrap: true,
    },
    {
      name: "Dicount Value",
      selector: "discountValue",
      sortable: true,
      wrap: true,
    },
    {
      name: "Actions",
      maxWidth: "100px",
      center: true,
      allowOverflow: true,
      cell: (item) => renderActionBar(item),
    },
  ];
  const { clientId } = props;
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
  const [isConfirmDeleteModalVisible, setConfirmDeleteModalVisible] =
    useState(false);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const { data } = await PhoenixAPI.get(
          `/user/${clientId}/active-subscriptions`
        );
        setSubscriptions(data);
      } catch (error) {
        console.log(
          "fetchSubscriptions:: Error on fetching subscriptions for client: ",
          clientId
        );
        showErrorNotification(
          "Error on fetching subscriptions for client: " + error.message
        );
      }
    };

    if (clientId) fetchSubscriptions();
  }, [clientId]);

  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });

  const showNotification = (notificationMessage, isSuccessMessage) => {
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
  };

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const showConfirmDeleteModal = () => {
    setConfirmDeleteModalVisible((prevValue) => true);
  };

  const hideConfirmDeleteModal = () => {
    setConfirmDeleteModalVisible((prevValue) => false);
  };

  const cancelSubscription = async () => {
    if (!subscriptionToCancel || !clientId) {
      console.log("cancelSubscription:: Invalid data: ", {
        clientId,
        subscriptionToCancel,
      });
      return;
    }
    try {
      showLoading();
      const url = `/cancel-subscription/${subscriptionToCancel}`;
      await PhoenixAPI.patch(url, {}, { params: { userId: clientId } });
      setSubscriptions((prevSubscriptions) =>
        prevSubscriptions.filter(
          (it) => it.subscriptionId !== subscriptionToCancel
        )
      );
      setSubscriptionToCancel((prevValue) => null);
      showSuccessNotification("Subscription cancelled successfully!");
    } catch (error) {
      console.log("Error on cancelling subscription!", error);
      showErrorNotification(
        "Error on cancelling subscription!" + error.message
      );
    } finally {
      hideLoading();
    }
  };

  const renderActionBar = (item) => {
    const isEditable = props.isEditable ?? true;
    return (
      <ActionBar alignRight className="action-menu valeo-dropdown">
        <ActionBar.Toggle variant="" disabled={!isEditable}>
          <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
        </ActionBar.Toggle>
        <ActionBar.Menu>
          <ActionBar.Item
            onClick={() => {
              setSubscriptionToCancel((prevValue) => item.subscriptionId);
              showConfirmDeleteModal();
            }}
          >
            <CancelIcon className="icon-small" style={{ fill: "red" }} />
            <span style={{ color: "red" }}>Cancel</span>
          </ActionBar.Item>
        </ActionBar.Menu>
      </ActionBar>
    );
  }

  return (
    <div className="container mt-4 px-0">
      <Card>
        <DataTable
          highlightOnHover
          columns={columns}
          data={subscriptions || []}
          pagination
          responsive
          defaultSortField="id"
          sortIcon={<ArrowDownward></ArrowDownward>}
        />
      </Card>
      <Modal show={isConfirmDeleteModalVisible} onHide={hideConfirmDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Subscription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel the subscription?
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary rounded-pill px-4 py-1"
            onClick={hideConfirmDeleteModal}
          >
            Cancel
          </button>
          <button
            onClick={() => {
              cancelSubscription();
              hideConfirmDeleteModal();
            }}
            className="btn btn-danger rounded-pill px-4 py-1"
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientSubscriptions);
