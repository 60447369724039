import React, { Component } from "react";
import PropTypes from "prop-types";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AlertBox from "components/AlertBox/AlertBox";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { connect } from "react-redux";
import API from "utils/API";
import { isEmpty } from "utils/FormValidator";
import StandardPackagesDropdown from "./StandardPackagesDrodown";
import _ from "lodash";

class AddPackagesForm extends Component {
  constructor(props) {
    super(props);

    const {
      standardPackage,
      customPackageVariants,
      bloodPackageVariants,
      customPackages = [],
      bloodBiomarkerPackages = [],
      onVariantsChange = (f) => f,
    } = this.props;

    this.onVariantsChange = onVariantsChange;

    this.state = {
      standardPackage,
      customPackageVariants,
      bloodPackageVariants,
      customPackages,
      bloodBiomarkerPackages,
      selectedPackage: {},
      errors: {},
      editables: {},
      loadError: "",
      searchTerm: "",
      showModal: false,
      searchApplied: false,
      sortColumn: "position_value",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "Index",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      // {
      //   name: "Package ID",
      //   selector: "package_id",
      //   sortable: true,
      //   maxWidth: "100px",
      // },
      {
        name: "Packages",
        selector: "package",
        sortable: true,
        wrap: true,
      },
      {
        name: "Result Time",
        selector: "result_time",
        sortable: true,
        wrap: true,
        format: (p) =>(
          <p >{p.result_time!==undefined && p.result_time!==null && p.result_time!=="undefined" ?p.result_time:""}</p>
        ),
      },
      {
        name: "Result Time (Arabic)",
        selector: "result_time_arabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (p) => (
          <p style={{ textAlign: "right" }}>{p.result_time_arabic!==undefined && p.result_time_arabic!==null && p.result_time_arabic!=="undefined"?p.result_time_arabic: ""}</p>
        ),
      },
      /*
       { name: "Special Tag",
        selector: "special_tag",
        sortable: true,
        wrap: true,
        format: (p) => p.special_tag ?? "",
    },
      {
        name: "Special Tag (Arabic)",
        selector: "special_tag_ar",
        sortable: true,
        wrap: true,
        right: true,
        format: (p) => (
          <p style={{ textAlign: "right" }}>{p.special_tag_ar ?? ""}</p>
        ),
         },
      {
        name: "Display Name",
        selector: "display_name",
        sortable: true,
        wrap: true,
        format: (p) => p.display_name ?? "",
      },
      {
        name: "Display Name (Arabic)",
        selector: "display_name_ar",
        sortable: true,
        wrap: true,
        right: true,
        format: (p) => (
          <p style={{ textAlign: "right" }}>{p.display_name_ar ?? ""}</p>
        ),
         } */ 
      {
        name: "Rank",
        selector: "position_value",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (p) => this.renderActionBar(p),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    // document.getElementByName("standard_packages_dropdown").clearForm();
    this.setState({
      errors: {},
      editables: {},
      selectedPackage: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const selectedPackage = { ...this.state.selectedPackage };
    selectedPackage[input.name] = input.value;
    this.setState({ selectedPackage });
  };

  handleEdit = (id, type) => {
    this.clearForm();
    const { customPackageVariants = [], bloodPackageVariants = [] } =
      this.state;

    const selectedPackage =
      type === "custom"
        ? customPackageVariants.find((it) => it.id === id)
        : bloodPackageVariants.find((it) => it.id === id);

    if (!selectedPackage) return;

    this.setState({ selectedPackage });
  };

  handleDelete = (id, type) => this.deletePackageVariant(id, type);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
   
    const { selectedPackage } = this.state;
    console.log("check add",selectedPackage);
    if (selectedPackage.id === null || selectedPackage.id === undefined)
      this.createPackageVariant();
    else
      this.updatePackageVariant(
        selectedPackage.id,
        selectedPackage.custom_package ? "custom" : "blood"
      );
  };

  validate = () => {
    const errors = {};
    const { custom_package, blood_package, result_time, result_time_arabic } =
      this.state.selectedPackage;

    if (
      (custom_package === null || custom_package === undefined) &&
      (blood_package === null || blood_package === undefined)
    )
      errors.package = "Package is a required field!";

    if (result_time && result_time.length > 100)
      errors.result_time =
        "Result Time (English) can not contain more than 100 characters!";

    if (result_time_arabic && result_time_arabic.length > 100)
      errors.result_time_arabic =
        "Result Time (Arabic) can not contain more than 100 characters!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createPackageVariant = () => {
    const {
      customPackageVariants = [],
      bloodPackageVariants = [],
      selectedPackage,
    } = this.state;

    const variants = [...customPackageVariants, ...bloodPackageVariants];
    const ids = variants.length > 0 ? variants.map((it) => it.id) : null;
    selectedPackage.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      variants && variants.length > 0
        ? variants.filter((it) => it.position_value).map((it) => it.position_value)
        : null;
    const rank = ranks && ranks.length > 0 ? Math.max(...ranks) + 1 : 1;
    selectedPackage.position_value = rank;
    selectedPackage.id =
      variants.length === 0 ? 1 : Math.max(...variants.map((it) => it.id)) + 1;

    selectedPackage.is_new = true;

    if (selectedPackage.custom_package) {
      this.setState(
        { customPackageVariants: [...customPackageVariants, selectedPackage] },
        () => {
          this.showSuccessNotification("Package Created Successfully!");
          this.onVariantsChangedLocally();
        }
      );
    } else {
      this.setState(
        { bloodPackageVariants: [...bloodPackageVariants, selectedPackage] },
        () => {
          this.showSuccessNotification("Package Created Successfully!");
          this.onVariantsChangedLocally();
        }
      );
    }
  };

  updatePackageVariant = (id, type) => {
    const {
      customPackageVariants = [],
      bloodPackageVariants = [],
      selectedPackage,
    } = this.state;

    // Handle Variant Changed
    if (type === "custom") {
      const v = bloodPackageVariants.find((it) => it.id === selectedPackage.id);
      if (v) {
        this.setState(
          {
            bloodPackageVariants: bloodPackageVariants.filter(
              (it) => it.id !== selectedPackage.id
            ),
            customPackageVariants: [
              ...customPackageVariants,
              { ...selectedPackage, is_new: true },
            ],
          },
          () => {
            this.showSuccessNotification("Package Updated Successfully!");
            this.onVariantsChangedLocally();
          }
        );
        return;
      }
    } else {
      const v = customPackageVariants.find(
        (it) => it.id === selectedPackage.id
      );
      if (v) {
        this.setState(
          {
            customPackageVariants: customPackageVariants.filter(
              (it) => it.id !== selectedPackage.id
            ),
            bloodPackageVariants: [
              ...bloodPackageVariants,
              { ...selectedPackage, is_new: true },
            ],
          },
          () => {
            this.showSuccessNotification("Package Updated Successfully!");
            this.onVariantsChangedLocally();
          }
        );
        return;
      }
    }

    // If variant not changed
    if (type === "custom") {
      const newCustomPackageVariants = customPackageVariants.map((it) =>
        it.id === id ? selectedPackage : it
      );
      this.setState({ customPackageVariants: newCustomPackageVariants }, () => {
        this.showSuccessNotification("Package Updated Successfully!");
        this.onVariantsChangedLocally();
      });
    } else {
      const newBloodPackageVariants = bloodPackageVariants.map((it) =>
        it.id === id ? selectedPackage : it
      );
      this.setState({ bloodPackageVariants: newBloodPackageVariants }, () => {
        this.showSuccessNotification("Package Updated Successfully!");
        this.onVariantsChangedLocally();
      });
    }
  };

  deletePackageVariant = (id, type) => {
    const { customPackageVariants = [], bloodPackageVariants = [] } =
      this.state;
    const variantToDelete =
      type === "custom"
        ? customPackageVariants.find((it) => it.id === id) || {}
        : bloodPackageVariants.find((it) => it.id === id) || {};

    if (type === "custom") {
      this.setState(
        {
          customPackageVariants: customPackageVariants.filter(
            (it) => it.id !== id
          ),
        },
        () => shiftRank()
      );
    } else {
      this.setState(
        {
          bloodPackageVariants: bloodPackageVariants.filter(
            (it) => it.id !== id
          ),
        },
        () => shiftRank()
      );
    }

    const shiftRank = () => {
      const { customPackageVariants = [], bloodPackageVariants = [] } =
        this.state;
      const newCustomPackageVariants = customPackageVariants.map((it) =>
        it.position_value > variantToDelete.position_value ? { ...it, position_value: it.position_value - 1 } : it
      );
      const newBloodPackageVariants = bloodPackageVariants.map((it) =>
        it.position_value > variantToDelete.position_value ? { ...it, position_value: it.position_value - 1 } : it
      );
      this.setState(
        {
          customPackageVariants: newCustomPackageVariants,
          bloodPackageVariants: newBloodPackageVariants,
        },
        () => {
          this.showSuccessNotification("Package Deleted Successfully!");
          this.onVariantsChangedLocally();
        }
      );
    };
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const {
      customPackageVariants = [],
      bloodPackageVariants,
      sortColumn,
      sortDirection,
    } = this.state;
    const variants = [...customPackageVariants, ...bloodPackageVariants].map(
      (it, index) => {
        const p =
          (it.custom_package
            ? this.findCustomPackage(it.custom_package)
            : this.findBloodBiomarkerPackage(it.blood_package)) || {};
        return {
          ...it,
          id: index + 1,
          package_id: it.id,
          package: p.package_type === "custom" ? p.name : p.subscription_name,
          package_type: p.package_type,
        };
      }
    );

    const sortedVariants = _.sortBy(variants, sortColumn, sortDirection);

    const fromVariant = sortedVariants[fromIndex];
    const toVariant = sortedVariants[toIndex];

    if (fromVariant && toVariant) {
      const tempRank = fromVariant.position_value;
      fromVariant.position_value = toVariant.position_value;
      toVariant.position_value = tempRank;

      const newCustomPackageVariants = customPackageVariants.map((it) => {
        if (
          it.id === fromVariant.package_id &&
          fromVariant.package_type === "custom"
        )
          return { ...it, position_value: fromVariant.position_value};
        if (
          it.id === toVariant.package_id &&
          toVariant.package_type === "custom"
        )
          return { ...it, position_value: toVariant.position_value };
        return it;
      });

      const newBloodPackageVariants = bloodPackageVariants.map((it) => {
        if (
          it.id === fromVariant.package_id &&
          fromVariant.package_type !== "custom"
        )
          return { ...it, position_value: fromVariant.position_value};
        if (
          it.id === toVariant.package_id &&
          toVariant.package_type !== "custom"
        )
          return { ...it, position_value: toVariant.position_value };
        return it;
      });
      this.setState(
        {
          customPackageVariants: newCustomPackageVariants,
          bloodPackageVariants: newBloodPackageVariants,
        },
        () => {
          this.showSuccessNotification("Rank Updated Successfully!");
          this.onVariantsChangedLocally();
        }
      );
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onVariantsChangedLocally = () => {
    const { customPackageVariants = [], bloodPackageVariants = [] } =
      this.state;
    const mapper = (it) => {
      const v = { ...it };
      if (v.is_new) {
        delete v.id;
        delete v.is_new;
      }
      return v;
    };

    this.clearForm();
    this.onVariantsChange(
      customPackageVariants.map(mapper),
      bloodPackageVariants.map(mapper)
    );
  };

  renderActionBar = (selectedPackage) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item
          onClick={() =>
            this.handleEdit(
              selectedPackage.package_id,
              selectedPackage.package_type
            )
          }
        >
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.handleDelete(
              selectedPackage.package_id,
              selectedPackage.package_type
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.selectedPackage.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handlePackageSelection = ({ value }) => {
    console.log("Value: ", value);
    const [packageId, packageType] = value.split(":");
    console.log("Selected Standard Package: ", packageId, packageType);

    const newSelectedPackage = { ...this.state.selectedPackage };
    if (packageType === "custom") {
      newSelectedPackage.custom_package = Number(packageId);
      delete newSelectedPackage.blood_package;
    } else {
      newSelectedPackage.blood_package = Number(packageId);
      delete newSelectedPackage.custom_package;
    }

    this.setState({
      selectedPackage: newSelectedPackage,
    });
  };

  findCustomPackage = (id) => {
    const { customPackages = [] } = this.state;
    return customPackages.find((it) => it.id === id);
  };

  findBloodBiomarkerPackage = (id) => {
    const { bloodBiomarkerPackages = [] } = this.state;
    return bloodBiomarkerPackages.find((it) => it.id === id);
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      customPackageVariants = [],
      bloodPackageVariants = [],
      customPackages = [],
      bloodBiomarkerPackages = [],
      selectedPackage,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      errors,
      editables,
      sortColumn,
    } = this.state;

    const { standardPackage = {} } = this.props;

    const isNewPackage =
      selectedPackage.id === null || selectedPackage.id === undefined;

    if (isLoaded) {
      this.setFinalList();
    }

    const finalList = [...customPackageVariants, ...bloodPackageVariants].map(
      (it, index) => {
        const p =
          (it.custom_package
            ? this.findCustomPackage(it.custom_package)
            : this.findBloodBiomarkerPackage(it.blood_package)) || {};
        return {
          ...it,
          id: index + 1,
          package_id: it.id,
          package: p.package_type === "custom" ? p.name : p.subscription_name,
          package_type: p.package_type,
        };
      }
    );

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="package">Add a Package</label>
            <div className="input-group">
              <StandardPackagesDropdown
                id="standard_packages_dropdown"
                name="standard_packages_dropdown"
                type="dropdown"
                customPackages={
                  customPackages
                    ? standardPackage.package_type === "custom"
                      ? customPackages.filter(
                          (it) => it.id !== standardPackage.id
                        )
                      : // .filter(
                        //   (it) =>
                        //     !customPackageVariants
                        //       .map((it) => it.custom_package)
                        //       .includes(it.id)
                        //)
                        customPackages
                    : []
                }
                bloodBiomarkerPackages={
                  bloodBiomarkerPackages
                    ? standardPackage.package_type === "blood_biomarker"
                      ? bloodBiomarkerPackages.filter(
                          (it) => it.id !== standardPackage.id
                        )
                      : // .filter(
                        //   (it) =>
                        //     !bloodPackageVariants
                        //       .map((it) => it.blood_package)
                        //       .includes(it.id)
                        //)
                        bloodBiomarkerPackages
                    : []
                }
                selectedPackage={
                  selectedPackage
                    ? selectedPackage.custom_package
                      ? this.findCustomPackage(selectedPackage.custom_package)
                      : this.findBloodBiomarkerPackage(
                          selectedPackage.blood_package
                        )
                    : null
                }
                // selectedPackage={selectedPackage.addon_package}
                onSelect={this.handlePackageSelection}
                disabled={!isNewPackage && !editables.package}
                placeholder="Select Associated Package..."
                className={
                  "form-control" +
                  (errors.standard_package_association ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("package")}
            </div>
            <span className="help-block">{errors.package}</span>
          </div>
        </div>
     {/*   <div className="row">
          <div className="form-group col">
            <label htmlFor="special_tag">Special Tag (English)</label>
            <div className="input-group">
              <input
                value={selectedPackage.special_tag}
                onChange={this.handleChange}
                id="special_tag"
                name="special_tag"
                type="text"
                placeholder="Special Tag in English"
                className={
                  "form-control py-2" + (errors.special_tag ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag}
              />
              {this.renderPencilIcon("special_tag")}
            </div>
            <span className="help-block">{errors.special_tag}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="special_tag_ar">Special Tag (Arabic)</label>
            <div className="input-group">
              <input
                value={selectedPackage.special_tag_ar}
                onChange={this.handleChange}
                id="special_tag_ar"
                name="special_tag_ar"
                type="text"
                placeholder="Special Tag in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.special_tag_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag_ar}
              />
              {this.renderPencilIcon("special_tag_ar")}
            </div>
            <span className="help-block">{errors.special_tag_ar}</span>
          </div>
        </div>*/}
        <div className="row">
          <div className="form-group col">
            <label htmlFor="result_time">Result Time (English)</label>
            <div className="input-group">
              <input
                value={selectedPackage.result_time}
                onChange={this.handleChange}
                id="result_time"
                name="result_time"
                type="text"
                placeholder="Result Time in English"
                className={
                  "form-control py-2" + (errors.result_time ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.result_time}
              />
              {this.renderPencilIcon("result_time")}
            </div>
            <span className="help-block">{errors.result_time}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="result_time_arabic">Result Time (Arabic)</label>
            <div className="input-group">
              <input
                value={selectedPackage.result_time_arabic}
                onChange={this.handleChange}
                id="result_time_arabic"
                name="result_time_arabic"
                type="text"
                placeholder="Result Time in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.result_time_arabic ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.result_time_arabic}
              />
              {this.renderPencilIcon("result_time_arabic")}
            </div>
            <span className="help-block">{errors.result_time_arabic}</span>
          </div>
        </div>
       {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="display_name">Display Name (English)</label>
            <div className="input-group">
              <input
                value={selectedPackage.display_name}
                onChange={this.handleChange}
                id="display_name"
                name="display_name"
                type="text"
                placeholder="Display in English"
                className={
                  "form-control py-2" + (errors.display_name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name}
              />
              {this.renderPencilIcon("display_name")}
            </div>
            <span className="help-block">{errors.display_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="display_name_ar">Display Name (Arabic)</label>
            <div className="input-group">
              <input
                value={selectedPackage.display_name_ar}
                onChange={this.handleChange}
                id="display_name_ar"
                name="display_name_ar"
                type="text"
                placeholder="Display Name in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.display_name_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name_ar}
              />
              {this.renderPencilIcon("display_name_ar")}
            </div>
            <span className="help-block">{errors.display_name_ar}</span>
          </div>
              </div>*/}
        <div className="row mt-2">
          {selectedPackage.id && (
            <div className="form-group col-2  mr-4">
              <button
                onClick={this.clearForm}
                disabled={!selectedPackage.id}
                className="btn btn-lg btn-outline-secondary px-5"
                style={{ color: "black" }}
              >
                Cancel
              </button>
            </div>
          )}
          <div className="form-group col-2">
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-outline-secondary px-5"
              style={{ backgroundColor: "#8FD14F", color: "black" }}
            >
              {selectedPackage.id ? "Update" : "Add"}
            </button>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4">
        {form}
        <div className="row">
          <div className="form-group col mt-4">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={finalList || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                  noHeader={searchApplied ? false : true}
                />
              </ReactDragListView>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

AddPackagesForm.propTypes = {
  standardPackage: PropTypes.object,
  customPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  customPackageVariants: PropTypes.arrayOf(PropTypes.object).isRequired,
  bloodBiomarkerPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  bloodPackageVariants: PropTypes.arrayOf(PropTypes.object).isRequired,
  onVariantsChange: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPackagesForm);
