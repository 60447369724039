import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { formatDateTime } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";
import Pages from "./Pages";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_PAGE_CATEGORIES = "/api/v1/acl/pages/categories";
const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

class PageCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageCategories: [],
      pageCategory: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isNewPageCategory: false,
      isPageCategoriesFetched: false,
      isPageCategoryViewModalVisible: false,
      isSaving: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Created On",
        selector: "createdOn",
        wrap: true,
        sortable: true,
        format: (pageCategory) =>
          pageCategory.createdOn ? formatDateTime(pageCategory.createdOn) : "",
      },
      {
        name: "Updated On",
        selector: "updatedOn",
        wrap: true,
        sortable: true,
        format: (pageCategory) =>
          pageCategory.updatedOn ? formatDateTime(pageCategory.updatedOn) : "",
      },
      {
        name: "Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (pageCategory) => (
          <Status isActive={pageCategory.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (pageCategory) => this.renderViewButton(pageCategory.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchPageCategories();
  }

  fetchPageCategories = async () => {
    try {
      this.showLoading();
      const { data: pageCategories = [] } = await PhoenixAPI.get(
        BASE_URL_PAGE_CATEGORIES
      );
      this.setState({ pageCategories });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching page categories: " + error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.setState({ isPageCategoriesFetched: true });
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const pageCategory = { ...this.state.pageCategory };
    pageCategory[input.name] = input.value;
    this.setState({ pageCategory });
  };

  handleSavePageCategory = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }

    const { pageCategory } = this.state;
    try {
      const config = {
        method: pageCategory.id ? "PATCH" : "POST",
        url: pageCategory.id
          ? `${BASE_URL_PAGE_CATEGORIES}/${pageCategory.id}`
          : BASE_URL_PAGE_CATEGORIES,
        data: pageCategory,
      };
      await PhoenixAPI.request(config);
      this.hidePageCategoryViewModal();
      this.showSuccessNotification("Saved Successfully.");
      this.fetchPageCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }
  };

  validate = () => {
    const errors = {};
    const { name } = this.state.pageCategory;
    if (!name || name.trim() === "") {
      errors.name = "Name is a required field!";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleViewPageCategory = (id) => {
    const pageCategories = this.state.pageCategories ?? [];
    const pageCategory = pageCategories.find((it) => it.id === id);
    if (pageCategory) {
      this.setState({ pageCategory, isNewPageCategory: false }, () =>
        this.showPageCategoryViewModal()
      );
    }
  };

  handleAddNewPageCategory = () => {
    const pageCategory = { isActive: false };
    this.setState({ pageCategory, isNewPageCategory: true }, () =>
      this.showPageCategoryViewModal()
    );
  };

  showPageCategoryViewModal = () => {
    this.setState({ isPageCategoryViewModalVisible: true });
  };

  hidePageCategoryViewModal = () => {
    this.setState({ isPageCategoryViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({ pageCategory: {}, errors: {}, editables: {}, isSaving: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => this.handleViewPageCategory(id)}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    if (this.state.isNewPageCategory) return "";
    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  getFilteredPageCategories = () => {
    const pageCategories = this.state.pageCategories ?? [];
    return this.getSearchedPageCategories(pageCategories);
  };

  getSearchedPageCategories = (pageCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return pageCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return pageCategories.filter((pageCategory) => {
      const { id, name } = pageCategory;
      return (
        (id && id.toString() === newValue) ||
        (name && name.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };

  render() {
    const {
      pageCategories = [],
      pageCategory = {},
      errors,
      editables,
      searchApplied,
      searchTerm,
      loadError,
      isNewPageCategory,
      isPageCategoriesFetched,
      isPageCategoryViewModalVisible,
      isSaving,
    } = this.state;

    const filteredPageCategories = this.getFilteredPageCategories();

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Name*</label>
            <div className="input-group">
              <input
                value={pageCategory?.name || ""}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewPageCategory && !editables.name}
              />
              {this.renderPencilIcon("name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={pageCategory.isActive ?? false}
                onChange={(event, value) => {
                  this.setState({
                    pageCategory: {
                      ...pageCategory,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewPageCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </div>
    );
    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewPageCategory}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <p className="font-weight-bold">Page Categories</p>
          <DataTable
            data={filteredPageCategories || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredPageCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={isPageCategoryViewModalVisible}
          onHide={this.hidePageCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Page Category: </span>
                {pageCategory.name ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={ (event) => this.setState({ isSaving: true }, this.handleSavePageCategory) }
              disabled={isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_page_categories">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        {isPageCategoriesFetched && <Pages categories={pageCategories ?? []} />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageCategories);
