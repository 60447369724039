import React, { Component } from "react"
import Search from "components/Search/Search";
import API from "utils/API";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";

const BASE_URL_WEBSITE_FAQ_CATEGORIES = "faq-category/";
const STATUS_OPTIONS = [
    {
        key: true,
        value: true,
        text: "Active",
    },
    {
        key: false,
        value: false,
        text: "Inactive",
    },
]
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class FAQCategories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faq_categories: [],
            rankOptions: [],
            faq_category: {},
            errors: {},
            editables: {},
            showModal: false,
            loadError: null,
            searchTerm: null,
            minRank: 1,
            maxRank: 0,
            primary_imgSrc: "",
        };

        this.columns = [
            {
                name: "ID",
                selector: "id",
                width: "100px",
                sortable: true,
            },
            {
                name: "Name",
                selector: "name",
                wrap: true,
                sortable: true,
            },
            {
                name: "Name (Arabic)",
                selector: "name_arabic",
                wrap: true,
                sortable: true
            },
            {
                name: "Status",
                selector: "is_active",
                maxWidth: "150px",
                center: true,
                cell: (p) => <Status isActive={p.is_active} />,
                sortable: true,
            },
            {
                name: "Rank",
                selector: "position_value",
                width: "100px",
                sortable: true,
                center: true
            },
            {
                name: "Image",
                cell: (row) => {
                    return (
                        <div className="form-image-container">
                            <img
                                src={row.image}
                                className="form-image"
                            />
                        </div>
                    )
                },
                center: true
            },
            {
                name: "View",
                center: true,
                cell: (p) => this.renderViewButton(p.id),
            },
        ]
    }

    renderViewButton = (id) => (
        <div>
            <button
                className="btn px-5"
                style={{ backgroundColor: "#CEE741" }}
                onClick={() => {
                    this.handleView(id);
                }}
            >
                View
            </button>
        </div>
    );

    componentDidMount() {
        this.fetchfaq_categories()
    }

    fetchfaq_categories = async () => {
        try {
            this.showLoading();
            const response = await API.get(BASE_URL_WEBSITE_FAQ_CATEGORIES)
            const faq_categories = response.data || []
            const ranks = faq_categories.filter((it) => it.position_value).map((it) => it.position_value);
            const minRank = Math.min(...ranks) || 0;
            const maxRank = Math.max(...ranks) || 0;
            this.setState({ faq_categories, minRank, maxRank })
            this.hideLoading()
        }
        catch (error) {
            console.error(
                "Error on fetching promotional faq_categories from this URL: ",
                BASE_URL_WEBSITE_FAQ_CATEGORIES
            );
            console.error("Error on fetching promotional faq_categories", error);
            this.hideLoading();
            this.showErrorNotification(
                "Error on loading promotional faq_categories: ",
                error.message
            );
            this.setState({
                loadError: "Some error has occured. Please try again",
            });
        }
    }

    handleChange = ({ currentTarget: input }) => {
        const faq_category = { ...this.state.faq_category };
        faq_category[input.name] = input.value;
        this.setState({ faq_category });
    };

    handleSearch = (queryString) => {
        this.setState({
            searchApplied: true,
            searchTerm: queryString ? queryString : "",
        });
    };

    handleSearchClear = () => {
        this.setState({
            searchTerm: "",
            searchApplied: false,
        });
        this.search.searchInput.focus();
    };

    handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
            this.setState({
                searchTerm: "",
                searchApplied: false,
            });
    };

    handleAddNew = () => {
        const faq_category = {
            is_active: false,
            position_value: this.state.maxRank + 1,
        };
        this.setState({ faq_category, isNewfaq_category: true }, () =>
            this.showModal()
        );
    };

    handleView = (id) => {
        const faq_categories = this.state.faq_categories || [];
        const faq_category = faq_categories.find((it) => it.id === id);
        if (faq_category) {
            this.setState(
                {
                    isNewfaq_category: false,
                    faq_category: {
                        ...faq_category
                    },
                    primary_imgSrc: faq_category.image,
                },
                () => this.showModal()
            );
        }
    };

    showModal = () => {
        const rankOptions = this.createRankOptions();
        this.setState({ rankOptions, showModal: true });
    };

    hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
    };

    resetForm = () => {
        this.setState({
            errors: {},
            editables: {},
            faq_category: {},
            isNewfaq_category: false,
            primary_imgSrc: "",
        });
    };

    createRankOptions = () => {
        let minRank = this.state.minRank;
        const { maxRank, isNewfaq_category } = this.state;
        if (
            minRank === null ||
            minRank === undefined ||
            minRank === "" ||
            maxRank === null ||
            maxRank === "" ||
            maxRank === undefined
        )
            return;
        minRank = Math.min(1, minRank);
        const until = isNewfaq_category ? maxRank + 1 : maxRank;
        let rankOptions = [];
        for (let i = minRank; i <= until; i++)
            rankOptions.push({ key: i, value: i, text: i });

        if (rankOptions.length === 0)
            rankOptions.push({ key: 1, value: 1, text: 1 });

        return rankOptions;
    };

    handleSave = async () => {
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            return;
        }
        const { faq_category } = this.state;
        const isValidId =
            faq_category.id !== null && faq_category.id !== undefined;
        const apiOptions = isValidId
            ? this.createApiOptions(
                "patch",
                `${BASE_URL_WEBSITE_FAQ_CATEGORIES}${faq_category.id}/`
            )
            : this.createApiOptions("post", BASE_URL_WEBSITE_FAQ_CATEGORIES);

        try {
            this.showLoading();
            await API.request(apiOptions);
            this.hideLoading();
            this.hideModal();
            this.showSuccessNotification(
                isValidId ? "Updated Successfully." : "Created Successfully."
            );
            this.fetchfaq_categories();
        }
        catch (error) {
            this.hideLoading();
            this.showErrorNotification("Some error occurred: " + error.message);
            console.log("Some error occurred: ", error.message)
        }
    };

    validate = () => {
        const errors = {};
        const TWO_MEGA_BYTE = 2048000;
        const { name, name_arabic, image } = this.state.faq_category
        const { primary_imgSrc } = this.state;

        if (!name || name.trim() === "")
            errors.name = "Name is required field!";
        else if (name.length > 100)
            errors.name = "Name can not take more than 100 characters!";


        if (!name_arabic || name_arabic.trim() === "")
            errors.name_arabic = "Name is required field!"
        else if (name_arabic.length > 100)
            errors.name_arabic = "Name can not take more than 100 characters!"

        if (!image && !primary_imgSrc)
            errors.image = "Image is required is required field"

        else if (image && image.size && image.size > TWO_MEGA_BYTE)
            errors.image = "Image should be less than 2MB"

        return Object.keys(errors).length === 0 ? null : errors;
    }

    createApiOptions = (method, url) => {
        const formData = this.createFormData();
        return {
            method,
            url,
            data: formData,
            headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
            },
        };
    };

    createFormData = () => {
        const { isNewfaq_category, faq_category } = this.state;
        const { image } = this.state.faq_category
        const formData = new FormData();

        // if (isNewfaq_category)

        formData.append("name", faq_category.name);
        formData.append("name_arabic", faq_category.name_arabic);
        if (image && image.name) {
            formData.append("image", image, image.name);
            formData.append("image_s3", image, image.name);  
        }
        formData.append("position_value", faq_category.position_value);
        formData.append("is_active", faq_category.is_active);

        return formData;
    };

    setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
    };

    renderPencilIcon = (fieldName) => {
        const isEditable = this.props.isEditable ?? true;
        return isEditable && !this.state.isNewfaq_category ? (
            <div
                onClick={() => this.setEditable(fieldName)}
                style={{ cursor: "pointer" }}
                className="input-group-append"
            >
                <i className="input-group-text fa fa-pencil"></i>
            </div>
        ) : (
            ""
        );
    }

    getFilteredfaq_categories = () => {
        const { faq_categories } = this.state;
        return this.getSearchedfaq_categories(faq_categories);
    };

    getSearchedfaq_categories = (faq_categories) => {
        const { searchApplied, searchTerm } = this.state;
        if (!searchApplied || !searchTerm) return faq_categories;
        const newValue = searchTerm.slice(0).trim().toLowerCase();
        return faq_categories.filter((faq_category) => {
            const { id, name, link, position_value } = faq_category;
            return (
                (name && name.toLowerCase().indexOf(newValue) !== -1) ||
                (link && link.toLowerCase().indexOf(newValue) !== -1) ||
                (id && id.toString() === newValue) ||
                (position_value && position_value.toString() === newValue)
            );
        });
    };

    showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);

    showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);

    showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
            notificationMessage,
            successMessage: isSuccessMessage,
            showNotification: true,
        });

    showLoading = () => this.props.toggleLoading({ isLoading: true });

    hideLoading = () => this.props.toggleLoading({ isLoading: false });

    selectImage = (e) => {
        if (e.target.files && e.target.files.length) {
            const image = e.target.files[0];
            const newfaq_category = { ...this.state.faq_category, image }
            this.setState({ faq_category: newfaq_category });
            const reader = new FileReader();
            reader.onloadend = function () {
                this.setState({
                    primary_imgSrc: [reader.result],
                });
            }.bind(this);
            reader.readAsDataURL(image);
        }
    }

    render() {
        const { searchTerm, editables, errors, isNewfaq_category, faq_category } = this.state;
        const isEditable = this.props.isEditable ?? true;
        const finalList = this.getFilteredfaq_categories();
        const detailsForm = (
            <div className="container mt-4">
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="name">Name (English)*</label>
                        <div className="input-group">
                            <input
                                value={faq_category && faq_category.name || ""}
                                onChange={this.handleChange}
                                id="name"
                                name="name"
                                type="text"
                                className={
                                    "form-control py-2" + (errors.name ? " has-error" : "")
                                }
                                disabled={!isNewfaq_category && !editables.name}
                            />
                            {this.renderPencilIcon("name")}
                        </div>
                        <span className="help-block">{errors.name}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="name_arabic">Name (Arabic)*</label>
                        <div className="input-group">
                            <input
                                value={faq_category && faq_category.name_arabic || ""}
                                onChange={this.handleChange}
                                id="name_arabic"
                                name="name_arabic"
                                type="text"
                                className={
                                    "form-control py-2" + (errors.name_arabic ? " has-error" : "")
                                }
                                disabled={!isNewfaq_category && !editables.name_arabic}
                            />
                            {this.renderPencilIcon("name_arabic")}
                        </div>
                        <span className="help-block">{errors.name_arabic}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="rank">Rank*</label>
                        <div className="input-group">
                            <Dropdown
                                value={faq_category.position_value}
                                onChange={(event, value) => {
                                    const category = { ...faq_category };
                                    category.position_value = value.value;
                                    this.setState({ faq_category: category });
                                }}
                                id="rank"
                                placeholder="Rank"
                                search
                                selection
                                options={this.state.rankOptions}
                                disabled={!isNewfaq_category && !editables.rank}
                                className={"form-control" + (errors.rank ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("rank")}
                        </div>
                        <span className="help-block">{errors.rank}</span>
                    </div>
                    <div className="form-group col">
                        <label htmlFor="status">Status*</label>
                        <div className="input-group">
                            <Dropdown
                                value={faq_category.is_active}
                                onChange={(event, value) => {
                                    this.setState({
                                        faq_category: {
                                            ...faq_category,
                                            is_active: value.value,
                                        },
                                    });
                                }}
                                id="status"
                                name="status"
                                placeholder="Status"
                                search
                                selection
                                options={STATUS_OPTIONS}
                                disabled={!isNewfaq_category && !editables.status}
                                className={"form-control" + (errors.status ? " has-error" : "")}
                            />
                            {this.renderPencilIcon("status")}
                        </div>
                        <span className="help-block">{errors.status}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group col">
                        <label htmlFor="imageInput">Image*</label>
                        <div className="input-group">
                            <input
                                onChange={this.selectImage}
                                id="imageInput"
                                name="image"
                                type="file"
                                accept="image/*"
                                disabled={!isNewfaq_category && !editables.image}
                                className={
                                    "form-control" +
                                    (errors.image ? " has-error" : "")
                                }
                            />
                            {this.renderPencilIcon("image")}
                        </div>
                        <span className="help-block">{errors.image}</span>
                    </div>
                    <div className="form-group col">
                        {this.state.primary_imgSrc && (
                            <>
                                <div className="form-image-container">
                                    <img
                                        src={this.state.primary_imgSrc}
                                        className="form-image"
                                        alt="selected"
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        );

        return (
            <>
                <div className="manage-faq">
                    <div className="page-header">
                        <div className="actions">
                            <div className="action-item">
                                <Search
                                    searchExpanded={true}
                                    searchTerm={searchTerm}
                                    handleSearch={this.handleSearch}
                                    clearSearch={this.handleSearchClear}
                                    handleSearchExit={this.handleSearchExit}
                                    ref={(input) => {
                                        this.search = input;
                                    }}
                                />
                            </div>
                            <div className="action-item">
                                <button
                                    style={{ backgroundColor: "#8FD14F" }}
                                    className="btn btn-md new-user"
                                    onClick={this.handleAddNew}
                                    disabled={!isEditable}
                                >
                                    <i className="fa fa-plus-circle"></i>Add New
                                </button>
                            </div>
                        </div>
                    </div>

                    <Card body>
                        <h3 className="p-head">FAQ Categories</h3>
                        <DataTable
                            data={finalList}
                            columns={this.columns}
                            defaultSortField="id"
                            defaultSortAsc={false}
                            sortIcon={<ArrowDownward></ArrowDownward>}
                            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                            highlightOnHover
                            pagination
                            responsive
                            noHeader={this.state.searchApplied ? false : true}
                            title={
                                this.state.searchApplied ? (
                                    <p className="table-header">
                                        {"Found " + finalList.length + " results"}
                                    </p>
                                ) : (
                                    ""
                                )
                            }
                            noDataComponent={
                                this.state.loadError ? (
                                    <AlertBox
                                        message={this.state.loadError}
                                        error={true}
                                    ></AlertBox>
                                ) : (
                                    <AlertBox message="There's nothing here."></AlertBox>
                                )
                            }
                        />
                    </Card>

                    <Modal
                        size="lg"
                        show={this.state.showModal}
                        onHide={this.hideModal}
                        className="user-data-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                            <div className="user-title"></div>
                            <button onClick={this.handleSave} disabled={!isEditable} className="btn modal-save-btn">
                                Save
                            </button>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs id="tabs_website_categories">
                                <Tab eventKey="key_details_form" title="Details">
                                    {detailsForm}
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return {
        showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
        toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQCategories)