import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, Modal, Nav, Row, Tab } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import DataTable from "react-data-table-component";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import API from "utils/API";
import AddonForm from "./AddonForm";
import CountryDropdown from "components/Common/CountryDropdown";
import AlertBox from "components/AlertBox/AlertBox";
import { compareStrings } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
class HealthCoachPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      packages: [],
      countries: [],
      statusOptions: [
        {
          text: "Active",
          value: true,
          key: "active",
        },
        {
          text: "Inactive",
          value: false,
          key: "inactive",
        },
      ],
      genderOptions: [
        {
          text: "Both",
          value: "B",
          key: "B",
        },
        {
          text: "Male",
          value: "M",
          key: "M",
        },
        {
          text: "Female",
          value: "F",
          key: "F",
        },
      ],
      
      productOptions: [],
      errors: {},
      editables: {},
      selectedCountry: null,
      healthCoachPackage: this.getEmptyPackage(),
      isNewPackage: false,
      loadError: null,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
      },
      {
        name: "Name",
        selector: "addon_name",
      },
      {
        name: "Product Category",
        selector: "product",
        format: (coachPackage) => this.renderProduct(coachPackage.product),
      },
      {
        name: "Price",
        selector: "addon_price",
        width: "125px",
        center: true,
      },
      {
        name: "Status",
        selector: "is_active",
        maxWidth: "150px",
        center: true,
        cell: (coachPackage) => this.renderStatus(coachPackage.is_active),
      },
      {
        name: "View",
        center: true,
        cell: (coachPackage) => this.renderViewButton(coachPackage.id),
      },
    ];
  }

  async componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    await this.fetchProducts();
    this.fetchHealtCoachPackages();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry !== prevState.selectedCountry)
      this.createProductOptionsList();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HEALTH_COACH_PACKAGES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchHealtCoachPackages = async () => {
    // const url = "add-on-packages/";
    const url = "/coach/packages/";
    const isHealthCoachPackage = (p) => p.is_parent;

    this.showLoading();
    try {
      const { data: packages } = await PhoenixAPI.get(url);
      const healthCoachPackages =
        packages && packages.filter(isHealthCoachPackage);

      this.setState(
        { loadError: null, packages: healthCoachPackages || [] },
        () => this.hideLoading()
      );
    } catch (error) {
      this.hideLoading();
      this.showErrorNotification("Error on loading packages: " + error.message);
      console.error("Error on fetching packages from this URL: ", url);
      console.error("Error on fetching packages", error);
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  };

  fetchCountries = async () => {
    const url = "countries/";
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(url);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      });
    } catch (error) {
      console.error("Error on fetching countries from this URL: ", url);
      console.error("Error on fetching countries", error);
    }
  };

  fetchProducts = async () => {
    const url = "products/";
    const productMapper = (product) => ({
      id: product.id,
      name: product.name,
      title: product.title,
      status: product.status,
      country: product.country,
    });

    try {
      const { data: allProducts } = await API.get(url);
      const products = allProducts ? allProducts.map(productMapper) : [];

      this.setState({ products }, () => this.createProductOptionsList());
    } catch (error) {
      console.error("Error on fetching products from this URL: ", url);
      console.error("Error on fetching products", error);
    }
  };

  handleCountrySelection = (country) =>
    this.setState({ selectedCountry: country });

  getEmptyPackage = () => ({
    addon_name: "",
    addon_name_ar: "",
    addon_description: "",
    addon_description_ar: "",
    internal_name: "",
    addon_image: "",
    addon_validity: "",
    addon_price: "",
    is_active: false,
    is_parent: false,
    created_at: "",
    modified_at: "",
    country: null,
    product: null,
  });

  findProduct = (id) =>
    id && this.state.products
      ? this.state.products.find((product) => product.id === id)
      : null;

  createProductOptionsList = () => {
    const { products, selectedCountry, healthCoachPackage, isNewPackage } =
      this.state;
    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    if (!isNewPackage && healthCoachPackage.product) {
      const product = this.findProduct(healthCoachPackage.product);
      if (product && !productsForCountry.find(product))
        productsForCountry.push(product);
    }

    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const noneOption = {
      key: "none",
      value: "none",
      text: "NONE",
    };
    const productOptions = [...activeProducts, ...inactiveProducts].map(
      (product) => ({
        key: product.id,
        value: product.id,
        text: `${product.name}${
          product.status === "Inactive" ? " (Inactive)" : ""
        }`,
      })
    );
    this.setState({ productOptions: [noneOption, ...productOptions] });
  };

  getCountry = (id) => {
    const { countries } = this.state;
    return countries && countries.find((country) => country.id === id);
  };

  renderStatus = (isPackageActive) => {
    if (isPackageActive)
      return (
        <div className="badge badge-success pt-1">
          <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
          Active
        </div>
      );
    else
      return (
        <div className="badge badge-warning pt-1">
          <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
          Inactive
        </div>
      );
  };

  renderProduct = (productId) => {
    const product = this.findProduct(productId);
    return product ? product.title : "";
  };

  renderViewButton = (packageId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewPackage(packageId);
        }}
      >
        View
      </button>
    </div>
  );

  handleAddNewPackage = () => {
    // Check if there is already a wellbeing package for the current country,
    // If there is an active package found, show a prompt than we cannot create another one for current country.
    // If there is an inactive package found, show we cannot create another one for the current country, user can make it active.
    const { packages, selectedCountry } = this.state;
    const packageForSelectedCountry =
      packages && selectedCountry
        ? packages.find((p) => p.country === selectedCountry.id)
        : null;

    if (packageForSelectedCountry) {
      const message = packageForSelectedCountry.is_active
        ? "There is already an active Wellbeing package available."
        : "Please activate Wellbeing package.";
      this.showErrorNotification(message);
    } else {
      this.setState({ isNewPackage: true }, () => this.showModal());
    }
  };

  handleViewPackage = async (id) => {
    try {
      this.showLoading();
      const { data: healthCoachPackage } = await API.get(`/add-on-packages/${id}/`);
      if (!healthCoachPackage) return;

      this.setState(
        {
          isNewPackage: false,
          healthCoachPackage,
          imgSrc: healthCoachPackage.addon_image,
        },
        () => this.showModal()
      );
    } catch (error) {
      console.log("handleViewPackage:: Error on fetching details!", error);
    } finally {
      this.hideLoading();
    }
  };

  validateName = (name, label) => {
    const MAX_LENGTH = 200;

    if (!name || name.trim() === "") return `${label} is a required field!`;
    else if (!/^[a-zA-Z0-9!|@#$%^&*)(+=._-\s]*$/g.test(name))
      return `${label} can only take letters, numbers, symbols, and spaces!`;
    else if (name.length > MAX_LENGTH)
      return `${label} can not take more than ${MAX_LENGTH} characters!`;

    return null;
  };

  validateNameArabic = (name, label) => {
    const MAX_LENGTH = 200;

    if (!name || name.trim() === "") return `${label} is a required field!`;
    else if (name.length > MAX_LENGTH)
      return `${label} can not take more than ${MAX_LENGTH} characters!`;

    return null;
  };

  validateInternalName = (name, label) => {
    const MAX_LENGTH = 200;

    if (!name || name.trim() === "") return `${label} is a required field!`;
    else if (name.length > MAX_LENGTH)
      return `${label} can not take more than ${MAX_LENGTH} characters!`;

    return null;
  };

  validateDescription = (description, label) => {
    const MAX_LENGTH = 10000;

    if (!description || description.trim() === "")
      return `${label} is a required field!`;
    else if (description.length > MAX_LENGTH)
      return `${label} can not take more than ${MAX_LENGTH} characters!`;

    return null;
  };

  validate = () => {
    const errors = {};
    const {
      addon_name,
      addon_name_ar,
      addon_description,
      addon_description_ar,
      is_active,
      gender,
      internal_name,
    } = this.state.healthCoachPackage;

    const nameErrorMessage = this.validateName(addon_name, "External Name (English)");
    if (nameErrorMessage) errors.name = nameErrorMessage;

    const nameArabicErrorMessage = this.validateNameArabic(
      addon_name_ar,
      "External Name (Arabic)"
    );
    if (nameArabicErrorMessage) errors.nameArabic = nameArabicErrorMessage;

    const descriptionErrorMessage = this.validateDescription(
      addon_description,
      "Description (English)"
    );
    if (descriptionErrorMessage) errors.description = descriptionErrorMessage;

    const descriptionArabicErrorMessage = this.validateDescription(
      addon_description_ar,
      "Description (Arabic)"
    );
    if (descriptionArabicErrorMessage)
      errors.descriptionArabic = descriptionArabicErrorMessage;

    if (is_active === null || is_active === undefined)
      errors.status = "Status is a required field!";

    if (!gender) errors.gender = "Gender is a required field!";

    if (!this.state.imgSrc) errors.addon_image = "Image is a required field!";

    const internalNameErrorMessage = this.validateInternalName(internal_name, "Internal Name");
    if (internalNameErrorMessage) errors.internal_name = internalNameErrorMessage;

    return Object.keys(errors).length === 0 ? null : errors;
  };

  savePackage = () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    if (this.state.isNewPackage) this.createPackage();
    else this.updatePackage(this.state.healthCoachPackage.id);
  };

  createPackage = async () => {
    this.hideModal();
    this.showLoading();

    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "post",
      "add-on-packages/",
      formData
    );

    try {
      const response = await API.request(apiOptions);
      console.log("New Package Response: ", response);
      this.hideLoading();
      this.showSuccessNotification("Package successfully created.");
      this.fetchHealtCoachPackages();
    } catch (error) {
      console.log("Error on creating package", error);
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  };

  updatePackage = async (id) => {
    this.hideModal();
    this.showLoading();

    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "patch",
      `add-on-packages/${id}/`,
      formData
    );

    try {
      const response = await API.request(apiOptions);
      this.hideLoading();
      this.showSuccessNotification("Package successfully updated.");
      console.log("Post Response: ", response);
      this.fetchHealtCoachPackages();
    } catch (error) {
      console.log("Error on updating package", error);
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
    }
  };

  createFormData = () => {
    const { healthCoachPackage, isNewPackage, image, selectedCountry } =
      this.state;
    const formData = new FormData();

    if (isNewPackage) {
      formData.append("country", selectedCountry ? selectedCountry.id : null);
      formData.append("is_parent", true); // parent data
      formData.append("addon_price", 999); // parent data (hardcoded) (not shown anywhere)
      formData.append("addon_validity", 99); // parent data (hardcoded) (not shown anywhere)
    }

    if (image && image.name) {
      formData.append("image", image, image.name);
      formData.append("addon_image", image, image.name);
      formData.append("image_s3", image, image.name);
      formData.append("addon_image_s3", image, image.name);
    }

    formData.append("product", healthCoachPackage.product);
    formData.append("is_active", healthCoachPackage.is_active);
    formData.append("addon_name", healthCoachPackage.addon_name);
    formData.append("addon_name_ar", healthCoachPackage.addon_name_ar);
    formData.append("addon_description", healthCoachPackage.addon_description);
    formData.append(
      "addon_description_ar",
      healthCoachPackage.addon_description_ar
    );
    formData.append("gender", healthCoachPackage.gender);
    formData.append("internal_name", healthCoachPackage.internal_name)
    if (healthCoachPackage.internal_category=="None"){
      formData.append("internal_category", "");
    }else{
      formData.append("internal_category", healthCoachPackage.internal_category);
    }

    return formData;
  };

  createApiOptions = (method, url, formData) => ({
    method,
    url,
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });

  showModal = () => this.setState({ showModal: true });

  hideModal = () => {
    this.formReset();
    this.setState({ showModal: false });
  };

  formReset() {
    this.submitted = false;
    this.setState({
      errors: {},
      editables: {},
      healthCoachPackage: this.getEmptyPackage(),
      isNewPackage: false,
      imgage: null,
      imgSrc: null,
    });
    document.getElementById("image").value = "";
  }

  handleSelectImage = (e) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const image = files[0];
    this.setState({ image });

    const reader = new FileReader();
    reader.onloadend = function () {
      this.setState({ imgSrc: [reader.result] });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const healthCoachPackage = { ...this.state.healthCoachPackage };
    healthCoachPackage[input.name] = input.value;
    this.setState({ healthCoachPackage });
  };

  getFilteredPackages = () => {
    const { packages, selectedCountry } = this.state;
    const packagesByCountry = selectedCountry
      ? this.getPackagesByCountry(packages, selectedCountry.id)
      : packages;
    return packagesByCountry;
  };

  getPackagesByCountry = (packages, countryId) =>
    packages && countryId
      ? packages.filter((p) => p.country === countryId)
      : [];

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPackage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  render() {
    const {
      countries,
      selectedCountry,
      healthCoachPackage,
      editables,
      errors,
      isNewPackage,
      isEditable,
    } = this.state;

    const packageCountry = this.getCountry(healthCoachPackage.country);
    const filteredPackages = this.getFilteredPackages();

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="addon_name">Internal Name *</label>
            <div className="input-group">
              <input
                value={healthCoachPackage.internal_name}
                onChange={this.handleChange}
                id="internal_name"
                name="internal_name"
                type="text"
                placeholder="Internal Name "
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.internal_name}
              />
              {this.renderPencilIcon("internal_name")}
            </div>
            <span className="help-block">{errors.internal_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="is_active">Status *</label>
            <div className="input-group">
              <Dropdown
                value={healthCoachPackage.is_active}
                onChange={(event, value) => {
                  const p = { ...healthCoachPackage };
                  p.is_active = value.value;
                  this.setState({ healthCoachPackage: p });
                }}
                id="is_active"
                name="is_active"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!isNewPackage && !editables.is_active}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="addon_name">External Name (English) *</label>
            <div className="input-group">
              <input
                value={healthCoachPackage.addon_name}
                onChange={this.handleChange}
                id="addon_name"
                name="addon_name"
                type="text"
                placeholder="Title in English"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.addon_name}
              />
              {this.renderPencilIcon("addon_name")}
            </div>
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="addon_name_ar">External Name (Arabic) *</label>
            <div className="input-group">
              <input
                value={healthCoachPackage.addon_name_ar}
                onChange={this.handleChange}
                id="addon_name_ar"
                name="addon_name_ar"
                type="text"
                placeholder="Title in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.nameArabic ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.addon_name_ar}
              />
              {this.renderPencilIcon("addon_name_ar")}
            </div>
            <span className="help-block">{errors.nameArabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="product">Product Category</label>
            <div className="input-group">
              <Dropdown
                value={healthCoachPackage.product || "none"}
                onChange={(event, value) => {
                  const p = { ...healthCoachPackage };
                  p.product = value.value === "none" ? null : value.value;
                  this.setState({ healthCoachPackage: p });
                }}
                id="product"
                name="product"
                placeholder="Product"
                search
                selection
                options={this.state.productOptions}
                disabled={!isNewPackage && !editables.product}
                className={
                  "form-control" + (errors.product ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("product")}
            </div>
            <span className="help-block">{errors.product}</span>
          </div>
          <div className="form-group col">
            <label>Gender *</label>
            <div className="input-group">
              <Dropdown
                value={healthCoachPackage.gender}
                onChange={(event, value) => {
                  this.setState({ gender: value.value });
                  const p = { ...healthCoachPackage };
                  p.gender = value.value;
                  this.setState({ healthCoachPackage: p });
                }}
                placeholder="Gender"
                id="product"
                name="product"
                search
                selection
                options={this.state.genderOptions}
                disabled={!isNewPackage && !editables.gender}
                className={"form-control" + (errors.gender ? " has-error" : "")}
              />
              {this.renderPencilIcon("gender")}
            </div>
            <span className="help-block">{errors.gender}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="internal_category">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={healthCoachPackage.internal_category || "None"}
                onChange={(event, value) => {
                  const p = { ...healthCoachPackage };
                  p.internal_category = value.value ;
                  this.setState({ healthCoachPackage: p });
                }}
                id="internal_category"
                name="internal_category"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewPackage && !editables.internal_category}
                className={
                  "form-control" + (errors.internal_category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("internal_category")}
            </div>
            <span className="help-block">{errors.internal_category}</span>
          </div>
        </div>
      </div>
    );

    const descriptionForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="addon_description">Description (English) *</label>
            <div className="input-group">
              <textarea
                value={healthCoachPackage.addon_description}
                onChange={this.handleChange}
                id="addon_description"
                name="addon_description"
                type="text"
                placeholder="Description in English"
                disabled={!isNewPackage && !editables.addon_description}
                className={
                  "form-control" + (errors.description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("addon_description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="addon_description_ar">Description (Arabic) *</label>
            <div className="input-group">
              <textarea
                value={healthCoachPackage.addon_description_ar}
                onChange={this.handleChange}
                id="addon_description_ar"
                name="addon_description_ar"
                type="text"
                placeholder="Description in Arabic"
                disabled={!isNewPackage && !editables.addon_description_ar}
                className={
                  "form-control input-arabic" +
                  (errors.descriptionArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("addon_description_ar")}
            </div>
            <span className="help-block">{errors.descriptionArabic}</span>
          </div>
        </div>
        <div className="row mt-3 mb-3">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="image">Image *</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="image"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.addon_image}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc && (
              <>
                <label htmlFor="addon_image">Selected Thumbnail Image</label>
                <div className="form-image-container">
                  <img
                    id="addon_image"
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    const addOnsForm = (
      <div className="container mt-4">
        {healthCoachPackage.id === null ||
        healthCoachPackage.id === undefined ? (
          <span style={{ fontWeight: "bold" }}>
            This section will be enabled after saving the parent Health Coach
            Package. You will be able to add addon packages only after pressing
            Save button.
          </span>
        ) : (
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className=" addon-plans-box ">
                <AddonForm
                  editId={healthCoachPackage.id}
                  countryId={selectedCountry ? selectedCountry.id : null}
                  viewMode={false}
                  isEditable={isEditable}
                ></AddonForm>
              </div>
              <span className="help-block">{this.markerMessage}</span>
            </div>
          </div>
        )}
      </div>
    );

    const locationAndPricingForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                healthCoachPackage.id
                  ? packageCountry && packageCountry.country_name
                  : selectedCountry && selectedCountry.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={"form-control " + (errors.country && "has-error")}
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={
                healthCoachPackage.id
                  ? packageCountry && packageCountry.currency
                  : selectedCountry && selectedCountry.currency
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control"
            />
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewPackage}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            columns={this.columns}
            data={filteredPackages}
            responsive
            highlightOnHover
            noHeader={!filteredPackages || filteredPackages.length > 0}
            title={
              !this.state.loadError &&
              filteredPackages &&
              filteredPackages.length === 0 ? (
                <p className="table-header">
                  There is no package for the country!
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button onClick={this.savePackage} disabled={!isEditable} className="btn modal-save-btn">
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="key_details_form"
            >
              <Nav variant="tabs" className="justify-content-left">
                <Nav.Item>
                  <Nav.Link eventKey="key_details_form">Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_description_form">
                    Description
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_addon_form">Test/Add On</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_location_pricing_form">
                    Location
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="key_details_form">
                      {detailsForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_description_form">
                      {descriptionForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_addon_form">{addOnsForm}</Tab.Pane>
                    <Tab.Pane eventKey="key_location_pricing_form">
                      {locationAndPricingForm}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthCoachPackages);
