import React,{Component} from 'react';
import PhoenixAPI from "utils/PhoenixAPI";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import Search from "components/Search/Search";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AlertBox from "components/AlertBox/AlertBox";
import Status from "components/Common/Status";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
    {
      key: "Active",
      value: true,
      text: "Active",
    },
    {
      key: "Inactive",
      value: false,
      text: "Inactive",
    },
  ];
class CorporatePage extends Component{
    constructor(props){
      super(props);
      this.state = {
        rankOptions: [],
        corporates:[],
        corporate: {},
        errors: {},
        editables: {},
        isSaving:false,
        isNewCorporate:false
      }
      this.columns = [
        {
          name: "ID",
          selector: "id",
          width: "100px",
          sortable: true,
        },
        {
          name: "Corporate Name",
          selector: "companyName",
          wrap: true,
          sortable: true,
        },
        {
          name: "Rank",
          selector: "rank",
          wrap: true,
          sortable: true,
        },
        {
          name: "Status",
          selector: "status",
          maxWidth: "150px",
          center: true,
          cell: (row) => <Status isActive={row.isActive ?? false} />,
          sortable: true,
        },
        {
          name: "View",
          center: true,
          cell: (row) => this.renderViewButton(row.id),
        },
      ]

    }

    renderViewButton = (id) => (
      <div>
        <button
          onClick={() => this.handleView(id)}
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
        >
          View
        </button>
      </div>
    );

    async componentDidMount() {
        this.fetchCorporates();
    }

    handleSearch = (queryString) => {
        this.setState({
          searchApplied: true,
          searchTerm: queryString ?? "",
        });
      };
    
      handleSearchClear = () => {
        this.setState({ searchTerm: "", searchApplied: false });
        this.search.searchInput.focus();
      };
    
      handleSearchExit = (e) => {
        const value = e.target.value;
        if (!value || !value.length)
          this.setState({ searchTerm: "", searchApplied: false });
      };
    
      handleAddNew = () => {
        this.setState({ isNewCorporate: true }, () =>
          this.showCorporateViewModal()
        );
      };
      handleView = (id) => {
        const corporates = this.state.corporates || [];
        const corporate= corporates.find((it) => it.id === id);
        if (corporates) {
        //  corporatePartner.partnerLogoSrc = corporatePartner.partnerLogo ?? null;
          this.setState({ corporate, isNewCorporatePartner: false }, () =>
            this.showCorporateViewModal()
          );
        }
      };
      
      showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
      handleChange = ({ currentTarget: input }) => {
        const corporate= { ...this.state.corporate };
        corporate[input.name] = input.value;
        this.setState({ corporate });
      };
      renderPencilIcon = (fieldName) => {
        const setEditable = (field) => {
          const editables = { ...this.state.editables };
          editables[field] = true;
          this.setState({ editables });
        };
    
        if (!this.props.isEditable || this.state.isNewCorporate) return null;
        return (
          <div
            onClick={() => setEditable(fieldName)}
            style={{ cursor: "pointer" }}
            className="input-group-append"
          >
            <i className="input-group-text fa fa-pencil"></i>
          </div>
        );
      };
    
      showCorporateViewModal = () => {
        const { country } = this.props;
        const { isNewCorporate } = this.state;
        const rankOptions = this.getRankOptions() || [];
    
        if (isNewCorporate) {
          const rank = Math.max(...rankOptions.map((it) => it.value));
          const corporate = {
            country: country?.id,
            rank,
            isActive: false,
          };
          this.setState({ corporate, rankOptions }, () => this.showModal());
        } else {
          this.setState({ rankOptions }, () => this.showModal());
        }
      };
      showModal = () => {
        this.setState({ showModal: true });
      };
    
      hideModal = () => {
        this.setState({ showModal: false });
        this.resetForm();
      };

      validate=()=>{
        const errors = {};
    const {
    companyName,
      companyLogo,
      rank
      }=this.state.corporate
      if(!companyName || companyName.trim() === "")
      errors.companyName = "Company Name is required";
     if(!companyLogo )
      errors.companyLogo = "Company Logo is required";
      return Object.keys(errors).length === 0 ? null : errors;

    }
      resetForm = () => {
        this.setState({
          errors: {},
          editables: {},
          corporate: {},
          rankOptions: [],
          isSaving: false,
          isNewCorporate: false,
        });
      };

      showLoading = () => this.props.toggleLoading({ isLoading: true });

      hideLoading = () => this.props.toggleLoading({ isLoading: false });

      handleSave = async () => {
        if (!this.props.country) {
          this.showErrorNotification("Please select a country!");
          this.setState({ isSaving: false });
          return;
        }
    
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
          this.showErrorNotification("There are some invalid form fields!");
          this.setState({ isSaving: false });
          return;
        }
    
        const { corporate } = this.state;
      
        
       
        try {
          this.showLoading();
          
          const payload = { ...corporate };
          const  url=corporate.id
              ? `/corporate-lead-generation/${corporate.id}`
              : "/corporate-lead-generation";
            
        const response= corporate.id? await PhoenixAPI.patch(url,payload): await PhoenixAPI.post(url,payload);
          this.hideModal();
          this.showSuccessNotification("Saved Successfully.");
          this.fetchCorporates();
        } catch (error) {
          this.showErrorNotification("Some error occurred: " + error.message);
        } finally {
          this.setState({ isSaving: false }, this.hideLoading);
        }
      };

      fetchCorporates = async () => {
        try {
          this.showLoading();
          const { data: corporates = [] } = await PhoenixAPI.get(`/corporate-lead-generation/`);
          this.setState({ corporates });
        } catch (error) {
          console.log(
            "fetchCorporatePartners:: Error on fetching corporate partners!",
            error
          );
          this.showErrorNotification(
            "Error on fetching corporate partners! " + error.message ?? ""
          );
          this.setState({ loadError: "Some error has occured. Please try again" });
        } finally {
          this.hideLoading();
        }
      };

    getFilteredCorporates=()=>{
      return  this.getSearchedCorporates(this.getCorporatesForCurrentCountry())
    }

    
        getSearchedCorporates = (corporates) => {
            const { searchApplied, searchTerm } = this.state;
        
            if (!searchApplied || !searchTerm) return corporates;
        
            const newValue = searchTerm.slice(0).trim().toLowerCase();
            return corporates.filter((corporate) => {
              const { id, companyName } =corporate;
              return (
                (id && id.toString().includes(newValue)) ||
                (companyName && companyName.toLowerCase().indexOf(newValue) !== -1) 
                
              );
            });
          };
    handleIconChange=async(e)=>{
        e.stopPropagation();
    const file = e.target.files[0];
    console.log("change file",e.target.files[0]);
    if (file) {
      
      const formData=new FormData();
      formData.append("media",file)
     const response= await  this.uploadFiles(formData);
     const corporate= { ...this.state.corporate };
        corporate.companyLogo = response.data.message;
        this.setState({ corporate },()=>this.showSuccessNotification("company Logo uploaded successfully"));
     
    }
  
    }
    uploadFiles=async(formData)=>{
      let response = null;
      try{
         response = await PhoenixAPI.post('/upload-media',formData, {
          headers: {
            accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
            'Content-Type': 'multipart/form-data'
          }
        });
      }
        catch (error) {
          this.showErrorNotification("Some error occurred while upload: " + error.message);
        }
    
        return response;
       }

       getCorporatesForCurrentCountry = () => {
        const { corporates } = this.state;
        const { country } = this.props;
    
        if (country && corporates && corporates.length) {
          const corporatesForCountry = corporates.filter(
            (it) => it.country === country.id
          );
          return corporatesForCountry;
        }
    
        return [];
      };
    
    
    getRankOptions = () => {
        const { isNewCorporate } = this.state;
        const corporatePartnersForCurrentCountry =
          this.getCorporatesForCurrentCountry();
        const ranks = corporatePartnersForCurrentCountry
          .filter((it) => it.rank)
          .map((it) => it.rank);
        const minRank = Math.min(1, ...ranks);
        const maxRank = Math.max(
          corporatePartnersForCurrentCountry.length,
          ...ranks
        );
        const until = isNewCorporate ? maxRank + 1 : maxRank;
        const rankOptions = [];
        for (let i = minRank; i <= until; i++)
          rankOptions.push({ key: i, value: i, text: i });
    
        if (rankOptions.length === 0)
          rankOptions.push({ key: 1, value: 1, text: 1 });
        return rankOptions;
      };
    render() {
        const{
            corporate,
            errors,
            isNewCorporate,
            editables,
            corporates,
            searchTerm,
            isSaving
        }=this.state;

       const{isEditable}=this.props;
        const filteredCorporates=this.getFilteredCorporates(corporates)

        const corporateForm=(
            <div className="container mt-4">
                <div className="row">
          <div className="form-group col">
            <label htmlFor="partnerName">Corporate Name*</label>
            <div className="input-group">
              <input
                value={corporate?.companyName || ""}
                onChange={this.handleChange}
                id="companyName"
                name="companyName"
                type="text"
                placeholder="Enter Corporate Name"
                className={
                  "form-control py-2" + (errors.companyName ? " has-error" : "")
                }
                disabled={!isNewCorporate && !editables.corporateName}
              />
              {this.renderPencilIcon("corporateName")}
            </div>
            <span className="help-block">{errors.companyName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="imageInputPrimary">Company Logo*</label>
            <div className="input-group">
              <input
               
                onChange={ this.handleIconChange}
                id="companyLogo"
                name="companyLogo"
                type="file"
                accept="image/*"
                disabled={!isNewCorporate && !editables.companyLogo}
                className={"form-control" + (errors.companyLogo ? " has-error" : "")}
              />
              {this.renderPencilIcon("companyLogo")}
            </div>
            <span className="help-block">{errors.companyLogo}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={corporate?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    corporate: {
                      ...corporate,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCorporate && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
            <br/>
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={corporate?.rank}
                onChange={(event, value) => {
                  this.setState({
                    corporate: {
                      ...corporate,
                      rank: value.value,
                    },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewCorporate && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>

          
          <div className="form-group col">
          {corporate?.companyLogo && (
              <>
                <label htmlFor="selected_package_image">
                   Logo Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={corporate.companyLogo}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>)}
          </div>

            
             
       </div>   
        
    </div>

        )
        return(
        <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Corporate Partners</p>
          <DataTable
            data={filteredCorporates}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCorporates.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Details: </span>
                {corporate?.companyName ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              type="button"
              onClick={(event) =>
                this.setState({ isSaving: true }, this.handleSave)
              }
              disabled={!isEditable || isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form" title="Details">
                {corporateForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
      
    );
  



        
    }

}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CorporatePage);