import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { filterList, getActiveCount } from "utils/List";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Alert from "react-bootstrap/Alert";
import { Tabs, Tab } from "react-bootstrap";
import SurveyOptionsForm from "components/Common/SurveyOptionsForm";
import QuestionType from "components/Common/QuestionType";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class MedicalSurvey extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "questionEn",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "questionAr",
        method: "isEmpty",
        validWhen: false,
        message: "Question is required.",
      },
      {
        field: "answerType",
        method: "isEmpty",
        validWhen: false,
        message: "Answer type is required.",
      },
    ]);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      questions: null,
      isLoaded: false,
      loadError: "",
      error: "",
      questionEn: "",
      questionAr: "",
      answerType: "",
      question_description: "",
      question_description_ar: "",
      position_value: "",
      statusOptions: [
        {
          text: "Active",
          value: true,
          key: "active",
        },
        {
          text: "Inactive",
          value: false,
          key: "inactive",
        },
      ],
      rankOptions: [],
      formHeading: "Create Question",
      editId: null,
      validation: this.validator.valid(),
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      showAlert: true,
      answerTypes: [],
      errors: {},
      editables: {},
      is_active: false,
      keyActiveTab: "key_details_form",
    };

    this.columns = [
      // This is commented, because in new design we are not showing drag option to update rank.
      // {
      //   name: "",
      //   cell: (row) =>
      //     // <a className="drag-handle" href="#">Drag</a>
      //     <Drag className="drag-handle"></Drag>,
      //   width: "50px"
      // },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true,
      },
      {
        name: "Question (Arabic)",
        selector: "question_ar",
        sortable: true,
        wrap: true,
        right: true,
      },
      {
        name: "Answer Type",
        selector: "survey_answer_type.answer_type",
        sortable: true,
        center: true,
        // wrap: true,
        cell: (question) => <QuestionType questionType={question.survey_answer_type.id} />,
      },
      {
        name: "Options",
        maxWidth: "100px",
        center: true,
        cell: (row) => {
          const count = getActiveCount(row.option_question);
          return count ? count : "None";
        },
      },
      {
        name: "Rank",
        selector: "position_value",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        cell: (question) => <Status isActive={question.is_active} />,
      },
      {
        name: "View",
        center: true,
        cell: (question) => this.renderViewButton(question.id),
      },
      // This part is commented because now we are showing "View" button instead of this action menu.
      // {
      //   name: "Actions",
      //   maxWidth: "100px",
      //   center: true,
      //   allowOverflow: true,
      //   cell: (row) => (
      //     <ActionBar alignRight className="action-menu valeo-dropdown">
      //       <ActionBar.Toggle variant="">
      //         <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      //       </ActionBar.Toggle>
      //       <ActionBar.Menu>
      //         {row.survey_answer_type.id === 1 ||
      //         row.survey_answer_type.id === 2 ? (
      //           <ActionBar.Item
      //             as={NavLink}
      //             to={
      //               "/dashboard/medical-profile/survey/options/" + row.id + "/M"
      //             }
      //           >
      //             <PostAddOutlinedIcon></PostAddOutlinedIcon>
      //             Manage Options
      //           </ActionBar.Item>
      //         ) : (
      //           ""
      //         )}
      //         <ActionBar.Item
      //           onClick={() => this.editQuestion(row.index, row.id)}
      //         >
      //           <EditIcon className="icon-small"></EditIcon>Edit
      //         </ActionBar.Item>
      //         {row.is_active ? (
      //           <ActionBar.Item
      //             className="danger"
      //             onClick={() => this.showConfirm(row)}
      //           >
      //             <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
      //             Archive
      //           </ActionBar.Item>
      //         ) : (
      //           <ActionBar.Item
      //             className="success"
      //             onClick={() => this.showConfirm(row)}
      //           >
      //             <UnarchiveOutlinedIcon></UnarchiveOutlinedIcon>
      //             Restore
      //           </ActionBar.Item>
      //         )}
      //       </ActionBar.Menu>
      //     </ActionBar>
      //   ),
      // },
    ];

    this.finalList = [];
    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.updateRank = this.updateRank.bind(this);
    this.setFinalList = this.setFinalList.bind(this);

    const that = this;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const results = [...that.finalList];
        const item = results.splice(fromIndex, 1)[0];
        results.splice(toIndex, 0, item);
        if (that.state.searchApplied)
          that.setState({
            results: results,
          });
        else {
          if (that.state.showItems === "Active")
            that.setState({
              activeList: results,
            });
          else if (that.state.showItems === "Archived")
            that.setState({
              archivedList: results,
            });
          else
            that.setState({
              questions: results,
            });
        }
        that.updateRank();
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadData();
    let url = "medlife-answer-types/";
    API.get(url)
      .then((res) => {
        this.setState({
          answerTypes: res?.data?.map((a, i) => {
            return {
              text: a.answer_type,
              value: i + 1,
              key: i + 1,
            };
          }),
        });
      })
      .catch((error) => {
        console.log("Error on fetching medlife answer types", error);
      })
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HEALTH_PROFILE_QUESTIONS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadData() {
    this.loadQuestions();
  }

  loadQuestions() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("medlife-medical-profile-questions/")
      .then((response) => {
        const allQuestions = response.data || [];
        allQuestions.sort((a, b) => (a.position_value > b.position_value ? 1 : -1));

        const rankedQuestions = allQuestions.filter(
          (question) => question.position_value
        );
        const ranks = rankedQuestions.map((question) => question.position_value);
        const minRank = Math.min(...ranks) || 0;

        // Currently we are getting some duplicate ranks
        // That's why added this logic so we can change a duplicate rank.
        const maxRank =
          Math.max(Math.max(...ranks), rankedQuestions.length) || 0;

        const activeList = filterList(allQuestions, "active");
        const archivedList = filterList(allQuestions, "archived");

        // Commented because sorting is repeated.
        // activeList.sort((a, b) => (a.rank > b.rank) ? 1 : -1)
        // archivedList.sort((a, b) => (a.rank > b.rank) ? 1 : -1)

        this.setState({
          questions: allQuestions,
          activeList,
          archivedList,
          minRank,
          maxRank,
          position_value: maxRank + 1,
          isLoaded: true,
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching questions", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  editQuestion(index, id) {
    const question = this.state.questions.find(
      (question) => question.id === id
    );
    console.log("Id: ", id, " Question: ", question);
    if (!question) return;

    this.setState(
      {
        formHeading: "Edit Question",
        questionEn: question.question,
        questionAr: question.question_ar,
        question_description: question.question_description,
        question_description_ar: question.question_description_ar,
        is_active: question.is_active,
        position_value: question.position_value,
        answerType: question.survey_answer_type.id,
        answerTypeName: question.survey_answer_type.answer_type,
        editId: id,
        showAlert: false,
      },
      () => this.showModal()
    );
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    const rankOptions = this.createRankOptionsList();
    this.setState({
      rankOptions,
      showModal: true,
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("medlife-question/" + item.id + "/", {
      is_active: !item.is_active,
    })
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true,
        });
        this.loadData();
      })
      .catch((error) => {
        console.log("Error on updating medlife question", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      formHeading: "Create Question",
      questionEn: "",
      questionAr: "",
      question_description: "",
      question_description_ar: "",
      position_value: this.state.maxRank + 1,
      answerType: "",
      answerTypeName: "",
      editId: null,
      validation: this.validator.valid(),
      showAlert: true,
      errors: {},
      editables: {},
      keyActiveTab: "key_details_form",
      is_active: false,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Question";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Question successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Question";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Question successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle,
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  validate = () => {
    const errors = {};
    const {
      questionEn,
      questionAr,
      question_description,
      question_description_ar,
      answerType,
      position_value,
    } = this.state;
    if (!questionEn || questionEn.trim() === "")
      errors.questionEn = "Question (English) is a required field!";
    else if (questionEn.length > 500)
      errors.questionEn =
        "Question (English) can not contain more than 500 characters!";

    if (!questionAr || questionAr.trim() === "")
      errors.questionAr = "Question (Arabic) is a required field!";
    else if (questionAr.length > 500)
      errors.questionAr =
        "Question (Arabic) can not contain more than 500 characters!";

    if (question_description && question_description.length > 500)
      errors.question_description =
        "Description (English) can not contain more than 500 characters!";

    if (question_description_ar && question_description_ar.length > 500)
      errors.question_description_ar =
        "Description (Arabic) can not contain more than 500 characters!";

    if (
      answerType === null ||
      answerType === undefined ||
      answerType.toString() === ""
    )
      errors.answerType = "Answer Type is a required field!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleSubmit(event) {
    event.preventDefault();

    const {
      questionEn,
      questionAr,
      question_description,
      question_description_ar,
      answerType,
      position_value,
      is_active,
      editId,
    } = this.state;

    const errors = this.validate();
    this.setState({ errors: errors || {} });

    if (errors) {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
      return;
    }

    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });

      if (editId !== null) {
        //edit
        API.patch("medlife-question/" + editId + "/", {
          question: questionEn,
          question_ar: questionAr,
          question_description: question_description || "",
          question_description_ar: question_description_ar || "",
          is_active,
          position_value,
          survey_answer_type: answerType,
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully updated.",
              successMessage: true,
              showNotification: true,
            });
            this.loadData();
          })
          .catch((error) => {
            console.log("Error on updating question", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      } else {
        API.post("medlife-question/", {
          question: questionEn,
          question_ar: questionAr,
          question_description: question_description || "",
          question_description_ar: question_description_ar || "",
          is_active,
          position_value,
          survey_answer_type: answerType,
          survey_type: "M",
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Question successfully created.",
              successMessage: true,
              showNotification: true,
            });
            this.loadData();
          })
          .catch((error) => {
            console.log("Error on creating question", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      }
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Archived") list = this.state.archivedList;
    else list = this.state.questions;

    let newList = [];

    if (!list) return;

    for (let item of list) {
      if (
        item.question.toLowerCase().indexOf(newValue) !== -1 ||
        item.question_ar.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "archived") showItems = "Archived";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  updateRank() {
    const {
      searchApplied,
      results,
      activeList,
      archivedList,
      questions,
      showItems,
    } = this.state;
    var data = [];
    if (searchApplied) {
      data = results;
    } else {
      if (showItems === "Active") data = activeList;
      else if (showItems === "Archived") data = archivedList;
      else data = questions;
    }
    var rank = {};
    data.map((val, index) => {
      rank[val.id] = index + 1;
    });
    API.post("set-medical-questions-rank/", rank)
      .then((response) => {
        this.props.showNotificationMessage({
          notificationMessage: "Slider Updated successfully.",
          successMessage: true,
          showNotification: true,
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on updating rank", error);
        this.setState({
          error: "Some error occured. Please try again",
          loading: false,
        });
      });
  }

  renderViewButton = (questionId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.editQuestion(questionId, questionId);
        }}
      >
        View
      </button>
    </div>
  );

  setFinalList() {
    const {
      searchApplied,
      showItems,
      activeList,
      archivedList,
      questions,
      results,
    } = this.state;
    if (searchApplied) this.finalList = results;
    else {
      this.finalList = questions;
      // if (showItems === "Active") this.finalList = activeList;
      // else if (showItems === "Archived") this.finalList = archivedList;
      // else this.finalList = questions;
    }
  }

  createRankOptionsList = () => {
    const { minRank, maxRank, editId } = this.state;
    if (!minRank || !maxRank) return;

    const until = editId ? maxRank : maxRank + 1;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && this.state.editId ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  render() {
    const {
      questions,
      editId,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      answerTypes,
      showAlert,
      errors,
      editables,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      this.setFinalList();
    }

    const detailsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="questionEn">Question (English)*</label>
            <div className="input-group">
              <input
                value={this.state.questionEn}
                onChange={this.handleChange}
                id="questionEn"
                name="questionEn"
                type="text"
                placeholder="Question in English"
                disabled={editId && !editables.questionEn}
                className={
                  "form-control py-2" + (errors.questionEn ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("questionEn")}
            </div>
            <span className="help-block">{errors.questionEn}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="questionAr">Question (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.questionAr}
                onChange={this.handleChange}
                id="questionAr"
                name="questionAr"
                type="text"
                placeholder="Question in Arabic"
                disabled={editId && !editables.questionAr}
                className={
                  "form-control input-arabic py-2" +
                  (errors.questionAr ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("questionAr")}
            </div>
            <span className="help-block">{errors.questionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="question_description">Description (English)</label>
            <div className="input-group">
              <textarea
                value={this.state.question_description}
                onChange={this.handleChange}
                id="question_description"
                name="question_description"
                type="text"
                placeholder="Description in English"
                readOnly={editId && !editables.question_description}
                className={
                  "form-control" +
                  (errors.question_description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("question_description")}
            </div>
            <span className="help-block">{errors.question_description}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="question_description_ar">
              Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={this.state.question_description_ar}
                onChange={this.handleChange}
                id="question_description_ar"
                name="question_description_ar"
                type="text"
                placeholder="Description in Arabic"
                readOnly={editId && !editables.question_description_ar}
                className={
                  "form-control input-arabic" +
                  (errors.question_description_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("question_description_ar")}
            </div>
            <span className="help-block">{errors.question_description_ar}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="is_active">Status*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_active}
                onChange={(event, value) => {
                  this.setState({ is_active: value.value });
                }}
                id="is_active"
                name="is_active"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={editId && !editables.is_active}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={this.state.position_value}
                onChange={(event, value) => {
                  this.setState({ position_value: value.value });
                }}
                id="rank"
                placeholder="rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={editId && !editables.rank}
                className={"form-control " + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="survey_answer_type">Answer Type*</label>
            <Dropdown
              value={this.state.answerType}
              onChange={(event, value) => {
                this.setState({ answerType: value.value });
              }}
              id="survey_answer_type"
              placeholder="Select type"
              search
              selection
              options={answerTypes}
              disabled={editId ? true : false}
              className={
                "form-control" + (errors.answerType ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.answerType}</span>
          </div>
          <div className="form-group col">
            {showAlert ? (
              <Alert
                variant="warning"
                onClose={() => {
                  this.setState({ showAlert: false });
                }}
                dismissible
                className="mt-4"
              >
                You can't change the type once the question is created.
              </Alert>
            ) : null}
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="questions">
        <div className="page-header">
          {/* <h5>Health Profile Questions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.showModal}
                disabled={!isEditable}
                className="btn btn-md new-user"
                style={{ backgroundColor: "#8FD14F" }}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            responsive
            columns={this.columns}
            data={this.finalList}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            sortIcon={<ArrowDownward></ArrowDownward>}
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results in '" + showItems + "'"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
          {/*
          This is commented because now we are not updating rank by dragging up or down. 
          <ReactDragListView {...this.dragProps}></ReactDragListView> 
          */}
        </Card>
        <Modal
          show={this.state.showModal}
          onHide={this.hideModal}
          size="lg"
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={(e) => this.handleSubmit(e)}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              id="left-tabs-example"
              activeKey={this.state.keyActiveTab}
              onSelect={(key) => this.setState({ keyActiveTab: key })}
              fill
              justify
            >
              <Tab eventKey="key_details_form" title="Details">
                {detailsForm}
              </Tab>
              <Tab
                eventKey="key_manage_options_form"
                title="Manage Options"
                disabled={
                  !editId ||
                  (this.state.answerType === 3 &&
                    this.state.answerTypeName === "Plain Text")
                }
              >
                {editId && (
                  <SurveyOptionsForm questionId={editId} surveyType="M" isEditable={isEditable} />
                )}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>

        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicalSurvey);
