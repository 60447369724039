import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import Card from "react-bootstrap/Card";
import { CircularProgress } from "@material-ui/core";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { filterList } from "utils/List";
import SearchBar from "components/Common/SearchBar";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class GeneralSurveyResults extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "userId",
        method: "isEmpty",
        validWhen: false,
        message: "Client is required."
      }
    ]);

    this.columns = [
      {
        name: "Question",
        selector: "question",
        sortable: true,
        wrap: true
      },
      {
        name: "Category",
        maxWidth: "250px",
        sortable: true,
        selector: "category_name"
      },
      {
        name: "Answer",
        selector: "answer",
        sortable: true,
        maxWidth: "300px",
        format: row => row.answer === '-' ? 'No Symptoms' : row.answer
      },
      {
        name: "Point Scale",
        selector: "point_scale",
        sortable: true,
        maxWidth: "120px",
        center: true,
      }
    ];

    this.listColumns = [
      {
        name: "Survey Name",
        selector: "name",
        sortable: true,
        wrap: true
      },
      {
        name: "Survey Type",
        maxWidth: "250px",
        sortable: true,
        selector: "survey_type",
        format: row => row.survey_type === 'GE' ? 'General' : "Custom"
      },
      {
        name: "Attached with order",
        selector: "order",
        sortable: true,
        maxWidth: "300px"
      },
      {
        name: "Health Assessment Results",
        selector: "id",
        sortable: true,
        center: true,
        cell: (row, index) => (
          <button className="btn button button-green btn-green" onClick={() => this.showAnswers(row.id, row.survey_type, row.order, row.user)}>View</button>
        )
      },
    ];

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      answers: [],
      isLoaded: false,
      loadError: "",
      answerLoading: false,
      answersLoaded: false,
      answersLoadError: "",
      userId: this.props.match.params.userId != undefined && this.props.match.params.userId !== ":userId" ? this.props.match.params.userId : "",
      users: [],
      userListFormatted: [],
      generalSurveyId: null,
      validation: this.validator.valid(),
      surveyParam: this.props.match.params.survey,
      surveyId: this.props.match.params.surveyId != undefined && this.props.match.params.surveyId !== ":surveyId" ? this.props.match.params.surveyId : "",
      orderId: this.props.match.params.orderId != undefined && this.props.match.params.orderId !== ":orderId" ? this.props.match.params.orderId : "",
      surveyType: this.props.match.params.type != undefined && this.props.match.params.type !== "" ? this.props.match.params.type : "",
      answersScreen: this.props.match.params.survey ? true : false,
      disableDropdown: false
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
    const { userId, surveyId, orderId, surveyType } = this.state;
    if (this.state.userId !== "" && this.state.surveyId !== "") {
      this.setState({
        answersScreen: true,
        disableDropdown: true
      });
      this.showAnswers(surveyId, surveyType, orderId, userId)
    }
    // this.loadUsers();
    this.getGeneralSurveyId();
    this.loadDirect(); // opens survey answers page directly if params are provided
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HEALTH_ASSESSMENTS_RESULTS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadDirect() {
    let { surveyParam } = this.state;
    if (surveyParam !== ':survey') {
      surveyParam = JSON.parse(surveyParam)
      this.showAnswers(surveyParam.id, surveyParam.survey_type, surveyParam.order, surveyParam.user);
    } else {
      this.setState({
        answersScreen: false
      })
    }
  }

  // loadUsers() {
  //   this.props.toggleLoading({
  //     isLoading: true
  //   });

  //   API.get("list-users/")
  //     .then(response => {
  //       let userListFormatted = response.data.map(user => {
  //         return {
  //           text: user.username + " (" + user.email + ")",
  //           value: user.id,
  //           key: user.id
  //         };
  //       });

  //       this.props.toggleLoading({
  //         isLoading: false
  //       });

  //       this.setState({
  //         users: response.data,
  //         userListFormatted: userListFormatted,
  //         isLoaded: true
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again."
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true
  //       });
  //     });
  // }

  getGeneralSurveyId() {
    API.get("generic-survey-list/")
      .then(response => {
        this.setState({ generalSurveyId: response.data[0].id });
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
        this.setState({ generalSurveyId: null });
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  fetchAllSurveys(event, userId) {
    event.preventDefault();
    API.get(`list-all-survey-admin/?user=${userId}`)
      .then((response) => {
        this.setState({ surveyList: response.data });
      })
      .catch((error) => {
        console.log("Error on fetching data", error);
      })
  }

  showAnswers(id, type, orderId, userId) {
    // let generalSurveyId = this.state.generalSurveyId;

    // const validation = this.validator.validate(this.state);
    // this.setState({ validation });
    // this.submitted = true;

    if (true) {
      if (id) {
        let url = 'generic-survey-answer-admin/'
        this.setState({
          answersLoading: true,
          answers: [],
          answersLoaded: false,
          answersLoadError: "",
          answersScreen: true
        });
        if (type === 'CU') {
          url = 'customised-survey-answer-admin/'
        }

        API.get(url, {
          params: {
            user: userId,
            survey: id,
            order: orderId
          }
        })
          .then(response => {
            let activeList = filterList(response.data, "active");

            for (let item of activeList) {
              if (!item.answer) item.answer = "-";
              if (!item.point_scale) item.point_scale = "-";
            }

            this.setState({
              answers: activeList,
              isLoaded: true,
              answersLoading: false,
              answersLoaded: true
            });
            this.submitted = false;
          })
          .catch(error => {
            console.log("Error on fetching data", error);
            this.setState({
              answersLoading: false,
              answersLoadError: "Some error has occured. Please try again"
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true
            });
          });
      } else {
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      }
    }
  }

  goBackToList() {
    if (this.state.surveyParam != ':survey') {

      this.props.history.goBack();

    } else {

      this.setState({
        answersLoaded: false,
        answersLoadError: false,
        answersScreen: false
      })
    }
  }

  searchClients = (searchTerm) => {
    if (!searchTerm || searchTerm.trim() === "")
      return;

    this.props.toggleLoading({ isLoading: true, });

    API.get(`list-active-user/?search=${searchTerm ? encodeURIComponent(searchTerm.trim()) : searchTerm.trim()}`)
      .then((response) => {
        const users = response.data ? response.data.results || [] : []; 
        const userListFormatted = users
        .filter((user) => user.email && user.email.includes(searchTerm.trim()))
        .map((user) => {
          return {
            key: user.id,
            value: user.id,
            text: user.email + " (" + user.username + ")",
          };
        });

        this.setState({
          users,
          userListFormatted: userListFormatted,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log("Error on fetching data", error);
        this.setState({ loadError: "Some error has occured. Please try again." });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      })
      .finally(() => this.props.toggleLoading({ isLoading: false }));
  }

  render() {
    let answerList, form;
    const answers = this.state.answers;
    const surveyList = this.state.surveyList;
    const isLoaded = this.state.isLoaded;
    const answersLoading = this.state.answersLoading;
    const answersLoaded = this.state.answersLoaded;
    const answersLoadError = this.state.answersLoadError;
    const loadError = this.state.loadError;
    const answersScreen = this.state.answersScreen;
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    if (isLoaded && !answersScreen) {
      form = (
        <form onSubmit={(event) => this.fetchAllSurveys(event, this.state.userId)}>
          <div className="form-row">
            <div className="form-group input-section input-section-long">
              <Dropdown
                value={parseInt(this.state.userId)}
                className={
                  "form-control " + (validation.userId.isInvalid && "has-error")
                }
                placeholder="Select client"
                onChange={(event, value) => {
                  this.setState({ userId: value.value });
                }}
                search
                selection
                options={this.state.userListFormatted}
                disabled={this.state.disableDropdown ? true : false}
              />
              <span className="help-block">{validation.userId.message}</span>
            </div>
            <div className="form-group">
              <button type="submit" className="btn button button-green">
                View all surveys
              </button>
            </div>
          </div>
        </form>
      );
    } else {
      if (loadError) form = <p>{loadError}</p>;
      else form = null;
    }


    if (answersLoaded || answersLoadError) {
      answerList = (
        <Card className="answer-list">
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={answers}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={true}
            title=""
            noDataComponent={
              answersLoadError ? (
                <AlertBox message={answersLoadError} error={true}></AlertBox>
              ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
            }
          />
        </Card>
      );
    } else if (answersLoading) {
      answerList = (
        <CircularProgress size={30} className="valeo-loader"></CircularProgress>
      );
    } else if (surveyList) {
      answerList =
        <Card className="answer-list">
          <DataTable
            highlightOnHover
            columns={this.listColumns}
            data={surveyList}
            defaultSortField="question"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={true}
            title=""
            noDataComponent={
              answersLoadError ? (
                <AlertBox message={answersLoadError} error={true}></AlertBox>
              ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
            }
          />
        </Card>;
    } else {
      answerList = <p>Select a client to view the results.</p>;
    }

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="answers">
        <h6 className="mb-3">Search Client *</h6>
        <div className="row">
          <div className="form-group col-5">
            <SearchBar
              onSubmit={this.searchClients}
              placeholder="Search by Email" />
          </div>
        </div>
        {!answersScreen &&
          <div>
            {/* <h5 className="mb-4">Health Assessment Results</h5> */}
            <h6 className="mb-3">Select Client *</h6>
          </div>

        }
        {form}
        <h6 className="mb-3 mt-4">
          {(answersLoaded || answersLoadError)
            &&
            <button disabled={this.state.disableDropdown ? true : false} onClick={() => this.goBackToList()} className="btn button btn-sm"> <ChevronLeft style={{ color: 'black' }} /> </button>
          }
          Assessment Results</h6>
        {answerList}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralSurveyResults);
