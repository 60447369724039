import React, { Component } from "react";
import { connect } from "react-redux";
import API from "utils/API";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { formatDate } from "utils/commons";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      subscriptions: [],
      isLoaded: false,
      loadError: "",
      columns: this.createColumns(),
    };
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleActive({ target }) {
    console.log(target.checked);
    let params = {};
    this.props.toggleLoading({
      isLoading: true,
    });
    params['id'] = target.id;
    params['is_active'] = target.checked;
    API.patch("nutritionist-subscription/" + target.id + '/', params)
      .then((response) => {
        this.loadSubscriptions();
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Coach Subscription has been successfully cancelled.",
          successMessage: true,
          showNotification: true,
        });

      })
      .catch((error) => {
        console.log("Error on toggling status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "AddOnSubscription does not exist for id " + target.id,
          successMessage: false,
          showNotification: true,
        });
      });
  }

  loadSubscriptions() {
    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("nutritionist-subscription/")
      .then((response) => {
        this.setState({ subscriptions: response.data, isLoaded: true });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching nutritionist subscription", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadSubscriptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isEditable !== prevState.isEditable) {
      this.setState({ columns: this.createColumns() })
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.WELLBEING_COACH_SUBSCRIPTIONS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    list = this.state.subscriptions;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      let client = "";
      if (item.user) {
        const { username = "", email } = item.user;
        client = `${username} ${email ? " (" + email + ")" : ""}`;
      }
      if (
        (client.toLowerCase().indexOf(newValue) !== -1) ||
        (item.user && item.user.email && item.user.email.toLowerCase().indexOf(newValue) !== -1) ||
        (item.nutritionist && item.nutritionist.email &&
          item.nutritionist.email.toLowerCase().indexOf(newValue) !== -1) ||
        (item.addon_subscription_start_date &&
          item.addon_subscription_start_date.toLowerCase().indexOf(newValue) !==
          -1) ||
        (item.created_at &&
          moment(item.created_at).format("DD/MM/YYYY").indexOf(newValue) !==
          -1) ||
        (item.addon_subscription_end_date &&
          item.addon_subscription_end_date.indexOf(newValue) !== -1) ||
        (item.created_at &&
          moment(item.created_at).format("DD/MM/YYYY").indexOf(newValue) !== -1) ||
         formatDate(item.addon_subscription_start_date).toLowerCase().indexOf(newValue) !== -1 || 
         formatDate(item.addon_subscription_end_date).toLowerCase().indexOf(newValue) !== -1  
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  compareStrings = (first, second, ignoreCase = false) => {
    const f = (first && ignoreCase) ? first.toLowerCase() : first;
    const s = (second && ignoreCase) ? second.toLowerCase() : second;
    if (f > s) return 1;
    if (f < s) return -1;
    return 0;
  }

  sortColumnClient = (rowA, rowB) => {
    console.log("Sorting client column");
    const usernameA = rowA.user && rowA.user.username;
    const usernameB = rowB.user && rowB.user.username;
    return this.compareStrings(usernameA, usernameB, true);
  }

  sortColumnWellbeingCoach = (rowA, rowB) => {
    console.log("Sorting Wellbeing Coach column");
    const usernameA = rowA.nutritionist ? rowA.nutritionist.username : "-";
    const usernameB = rowB.nutritionist ? rowB.nutritionist.username : "-";
    return this.compareStrings(usernameA, usernameB, true);
  }

  createColumns = () => {
    return [
      {
        name: "Client",
        selector: "user.username",
        wrap: true,
        sortable: true,
        sortFunction: this.sortColumnClient,
        format: (row) => {
          if (!row.user) return "";
          const { username = "", email } = row.user;
          return `${username} ${email ? " (" + email + ")" : ""}`;
        },
      },
      {
        name: "Wellbeing Coach",
        selector: "nutritionist.username",
        sortable: true,
        sortFunction: this.sortColumnWellbeingCoach,
        format: (row) => (row.nutritionist ? row.nutritionist.username : "-"),
      },
      {
        name: "Start Date",
        selector: "addon_subscription_start_date",
        sortable: true,
        maxWidth: "150px",
        format: (row) => row.addon_subscription_start_date ? formatDate(row.addon_subscription_start_date) : "-",
      },
      {
        name: "End Date",
        selector: "addon_subscription_end_date",
        sortable: true,
        maxWidth: "150px",
        format: (row) => row.addon_subscription_end_date ? formatDate(row.addon_subscription_end_date) : "-",
      },
      {
        name: "Status",
        maxWidth: "150px",
        center: true,
        sortable: true,
        selector: "is_active",
        format: (row) =>
          <div className="toggle-switch">
            <input
              type="checkbox"
              className="toggle-switch-checkbox"
              name={row.id}
              checked={row.is_active}
              defaultChecked={row.is_active}
              value={row.is_active}
              onClick={this.toggleActive}
              disabled={!this.state.isEditable}
              // onchange={this.toggleActive(row)}
              id={row.id}
            />
            <label className="toggle-switch-label" htmlFor={row.id}>
              <span className="toggle-switch-inner" data-yes="Active" data-no="Deactive" />
              <span className="toggle-switch-switch" />
            </label>
          </div>
      },
    ];
  }

  render() {
    let finalList = [];

    const {
      subscriptions,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results,
    } = this.state;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else finalList = subscriptions;
    }

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div>
        <div className="page-header">
          {/* <h5>Wellbeing Coach Subscriptions</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.state.columns ?? []}
            data={finalList}
            defaultSortField="user.username"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
