import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import SelectOrderStatus from "./SelectOrderStatus";

function EditCartOrderBasicViewModal(props) {
  const {
    order,
    orderDetails,
    isVisible,
    onHide,
    onSave,
    labs,
    homeCareServices,
    wellbeingCoaches,
    countryId,
    cityId,
    operationsUsers,
    paymentGateways,
  } = props;

  const {
    itemNameEnglish,
    orderStatusList,
    orderStatus: status,
    isReviewed,
    homeCareCost: orderHomeCareCost,
    homeCareId: orderHomeCareId,
    couponAmount: orderCouponAmount,
    customTestBiomarkers: orderCustomTestBiomarkers,
    customTestPriceAdditional: orderCustomTestPriceAdditional,
    handledById: orderHandledById,
    lab1Id: orderLab1Id,
    lab2Id: orderLab2Id,
    lab1CustomCost: orderLab1CustomCost,
    lab2CustomCost: orderLab2CustomCost,
    notes: orderNotes,
    referenceNumber1: orderReferenceNumber1,
    referenceNumber2: orderReferenceNumber2,
    refundAmount: orderRefundAmount,
    revisedPrice: orderRevisedPrice,
    revisedPriceAfterCoupon: orderRevisedPriceAfterCoupon,
    paymentGateway1Id: orderPaymentGateway1Id,
    paymentGateway2Id: orderPaymentGateway2Id,
    couponCodeValue: orderCouponCodeValue,
    sampleDetailsLab1: orderSampleDetailsLab1,
    sampleDetailsLab2: orderSampleDetailsLab2,
    specialInstructions: orderSpecialInstructions,
    paymentToBeCollected: orderPaymentToBeCollected,
  } = orderDetails ?? {};
  const { itemType } = order ?? {};

  const isBundlePackage = itemType === "Bundle";
  const homeCareServicesForCountry = homeCareServices
    ? homeCareServices.filter((it) => it?.country?.id === countryId)
    : [];
  const [orderStatus, setOrderStatus] = useState(status);

  const [homeCareCost, setHomeCareCost] = useState(orderHomeCareCost);
  const [homeCareId, setHomeCareId] = useState(orderHomeCareId);
  const [errors, setErrors] = useState({});
  const [handledById, setHandledById] = useState(orderHandledById);
  const [customTestPriceAdditional, setCustomTestPriceAdditional] = useState(
    orderCustomTestPriceAdditional
  );
  const [lab1Id, setLab1Id] = useState(orderLab1Id);
  const [lab1CustomCost, setLab1CustomCost] = useState(orderLab1CustomCost);
  const [paymentGateway1Id, setPaymentGateway1Id] = useState(
    orderPaymentGateway1Id
  );
  const [referenceNumber1, setReferenceNumber1] = useState(
    orderReferenceNumber1
  );
  const [lab2Id, setLab2Id] = useState(orderLab2Id);
  const [lab2CustomCost, setLab2CustomCost] = useState(orderLab2CustomCost);
  const [paymentGateway2Id, setPaymentGateway2Id] = useState(
    orderPaymentGateway2Id
  );
  const [referenceNumber2, setReferenceNumber2] = useState(
    orderReferenceNumber2
  );
  const [revisedPrice, setRevisedPrice] = useState(orderRevisedPrice);
  const [couponAmount, setCouponAmount] = useState(orderCouponAmount);
  const [revisedPriceAfterCoupon, setRevisedPriceAfterCoupon] = useState(
    orderRevisedPriceAfterCoupon
  );
  const [refundAmount, setRefundAmount] = useState(orderRefundAmount);
  const [customTestBiomarkers, setCustomTestBiomarkers] = useState(
    orderCustomTestBiomarkers
  );
  const [notes, setNotes] = useState(orderNotes);
  const [couponCodeValue, setCouponCodeValue] = useState(orderCouponCodeValue);
  const [sampleDetailsLab1, setSampleDetailsLab1] = useState(orderSampleDetailsLab1);
  const [sampleDetailsLab2, setSampleDetailsLab2] = useState(orderSampleDetailsLab2);
  const [specialInstructions, setSpecialInstructions] = useState(orderSpecialInstructions);
  const [paymentToBeCollected, setPaymentToBeCollected] = useState(orderPaymentToBeCollected)

  useEffect(() => {
    setOrderStatus(null);
    setHomeCareCost(null);
    setHomeCareId(null);
    setHandledById(null);
    setCustomTestPriceAdditional(null);
    setLab1Id(null);
    setLab1CustomCost(null);
    setPaymentGateway1Id(null);
    setReferenceNumber1(null);
    setLab2Id(null);
    setLab2CustomCost(null);
    setPaymentGateway2Id(null);
    setReferenceNumber2(null);
    setRevisedPrice(null);
    setCouponAmount(null);
    setRevisedPriceAfterCoupon(null);
    setRefundAmount(null);
    setCustomTestBiomarkers(null);
    setNotes(null);
    setCouponCodeValue(null);
    setErrors({});
    setSampleDetailsLab1(null);
    setSampleDetailsLab2(null);
    setSpecialInstructions(null);
    setPaymentToBeCollected(null);
  }, [order, orderDetails]);

  useEffect(() => {
    setOrderStatus(status);
  }, [status]);

  useEffect(() => setHomeCareId(orderHomeCareId), [orderHomeCareId]);
  useEffect(() => setHomeCareCost(orderHomeCareCost), [orderHomeCareCost]);
  useEffect(() => setHandledById(orderHandledById), [orderHandledById]);
  useEffect(
    () => setCustomTestPriceAdditional(orderCustomTestPriceAdditional),
    [orderCustomTestPriceAdditional]
  );
  useEffect(() => setLab1Id(orderLab1Id), [orderLab1Id]);
  useEffect(
    () => setLab1CustomCost(orderLab1CustomCost),
    [orderLab1CustomCost]
  );
  useEffect(
    () => setPaymentGateway1Id(orderPaymentGateway1Id),
    [orderPaymentGateway1Id]
  );
  useEffect(
    () => setReferenceNumber1(orderReferenceNumber1),
    [orderReferenceNumber1]
  );
  useEffect(() => setLab2Id(orderLab2Id), [orderLab2Id]);
  useEffect(
    () => setLab2CustomCost(orderLab2CustomCost),
    [orderLab2CustomCost]
  );
  useEffect(
    () => setPaymentGateway2Id(orderPaymentGateway2Id),
    [orderPaymentGateway2Id]
  );
  useEffect(
    () => setReferenceNumber2(orderReferenceNumber2),
    [orderReferenceNumber2]
  );
  useEffect(() => setRevisedPrice(orderRevisedPrice), [orderRevisedPrice]);
  useEffect(() => setCouponAmount(orderCouponAmount), [orderCouponAmount]);
  useEffect(
    () => setRevisedPriceAfterCoupon(orderRevisedPriceAfterCoupon),
    [orderRevisedPriceAfterCoupon]
  );
  useEffect(() => setRefundAmount(orderRefundAmount), [orderRefundAmount]);
  useEffect(
    () => setCustomTestBiomarkers(orderCustomTestBiomarkers),
    [orderCustomTestBiomarkers]
  );
  useEffect(() => setNotes(orderNotes), [orderNotes]);
  useEffect(
    () => setCouponCodeValue(orderCouponCodeValue),
    [orderCouponCodeValue]
  );
  useEffect(() => setSampleDetailsLab1(orderSampleDetailsLab1), [orderSampleDetailsLab1]);
  useEffect(() => setSampleDetailsLab2(orderSampleDetailsLab2), [orderSampleDetailsLab2]);
  useEffect(() => setSpecialInstructions(orderSpecialInstructions), [orderSpecialInstructions]);
  useEffect(() => setPaymentToBeCollected(orderPaymentToBeCollected), [orderPaymentToBeCollected]);

  const validate = () => {
    const errors = {};

    if (homeCareCost && Number.isNaN(Number(homeCareCost)))
      errors.homeCareCost = "Homecare Costs should be a valid number";
    else if (homeCareCost && Number(homeCareCost) > 9999)
      errors.homeCareCost = "Homecare Costs can not be greater then 9999";

    if (
      customTestPriceAdditional &&
      Number.isNaN(Number(customTestPriceAdditional))
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be a valid number";
    } else if (
      customTestPriceAdditional &&
      Number(customTestPriceAdditional) > 9999
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be bewteen 0 to 9999";
    }

    if (lab1CustomCost && Number.isNaN(Number(lab1CustomCost))) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be a valid number";
    } else if (lab1CustomCost && Number(lab1CustomCost) > 9999) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber1 && referenceNumber1.length > 500) {
      errors.referenceNumber1 =
        "Reference Number 1 is limited to a 500 characters";
    }

    if (lab2CustomCost && Number.isNaN(Number(lab2CustomCost))) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be a valid number";
    } else if (lab2CustomCost && Number(lab2CustomCost) > 9999) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber2 && referenceNumber2.length > 500) {
      errors.reference_number_2 =
        "Reference Number 2 is limited to a 500 characters";
    }

    if (revisedPrice && Number.isNaN(Number(revisedPrice))) {
      errors.revisedPrice = "Revised Price should be a valid number";
    } else if (revisedPrice && Number(revisedPrice) > 9999) {
      errors.revisedPrice = " Revised Price Cost should be bewteen 0 to 9999";
    }

    if (couponAmount && Number.isNaN(Number(couponAmount))) {
      errors.couponAmount = "Coupon Amount should be a valid number";
    } else if (couponAmount && Number(couponAmount) > 9999) {
      errors.couponAmount = "Coupon Amount should be bewteen 0 to 9999";
    }

    if (
      revisedPriceAfterCoupon &&
      Number.isNaN(Number(revisedPriceAfterCoupon))
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be a valid number";
    } else if (
      revisedPriceAfterCoupon &&
      Number(revisedPriceAfterCoupon) > 9999
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be bewteen 0 to 9999";
    }

    if (refundAmount && Number.isNaN(Number(refundAmount))) {
      errors.refund_amount = "Refund Amount should be a valid number";
    } else if (refundAmount && refundAmount && Number(refundAmount) > 9999) {
      errors.refundAmount = "Refund Amount should be bewteen 0 to 9999";
    }

    if (customTestBiomarkers && customTestBiomarkers.length > 3000) {
      errors.customTestBiomarkers =
        "Custom Test Biomarkers is limited to a 3000 characters";
    }

    if (notes && notes.length > 3000) {
      errors.notes = "Notes is limited to a 3000 characters";
    }

    if (couponCodeValue && couponCodeValue.length > 100) {
      errors.couponCodeValue = "Coupon Code Value limited  to a 100 characters";
    }

    
    
       if (sampleDetailsLab1 && sampleDetailsLab1.length > 500)
        errors.sampleDetailsLab1 = "Sample Details Lab 1 is limited to a 500 characters";
    

    if (sampleDetailsLab2 && sampleDetailsLab2.length > 500)
      errors.sampleDetailsLab2 = "Sample Details Lab 2 is limited to a 500 characters";

    if (specialInstructions && specialInstructions.length > 500)
      errors.specialInstructions = "Special Instructions is limited to a 500 characters";

    if (paymentToBeCollected && paymentToBeCollected.length > 500)
      errors.paymentToBeCollected = "Payment to be collected is limited to a 500 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  const handleSave = () => {
    const errors = validate();
    if (errors) {
      setErrors(errors);
      return;
    }

    if (
      itemType === "Coach_Package" &&
      orderStatus === "COMPLETED" &&
      !isReviewed
    ) {
      props.showNotificationMessage({
        notificationMessage: "Upload results to mark as completed.",
        successMessage: false,
        showNotification: true,
      });
      return;
    }
    onSave(orderDetails, {
      orderStatus,
      homeCareCost,
      homeCareId,
      handledById,
      customTestPriceAdditional,
      lab1Id,
      lab1CustomCost,
      paymentGateway1Id,
      referenceNumber1,
      lab2Id,
      lab2CustomCost,
      paymentGateway2Id,
      referenceNumber2,
      revisedPrice,
      couponAmount,
      revisedPriceAfterCoupon,
      refundAmount,
      customTestBiomarkers,
      notes,
      couponCodeValue,
      sampleDetailsLab1,
      sampleDetailsLab2,
      specialInstructions,
      paymentToBeCollected,
    });
  };

  const form = (
    <>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="orderStatus">Order Status</label>
          <SelectOrderStatus
            id="orderStatus"
            name="orderStatus"
            options={orderStatusList}
            value={orderStatus}
            onSelect={(selectedOrderStatus) =>
              setOrderStatus(selectedOrderStatus)
            }
          />
        </div>
      </div>

     {/* {isBundlePackage && (
        <>
          <div className="form-content">
            <span>Operations Internal</span>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="form-group col">
                <label htmlFor="handledBy">Handled By</label>
                <select
                  name="handled_by"
                  className={`form-control user-data-input`}
                  onChange={(e) => setHandledById(e.target.value)}
                  value={handledById}
                >
                  {!handledById && (
                    <option key={-1} value={-1} hidden={true}>
                      Select Handled By
                    </option>
                  )}
                  {operationsUsers &&
                    operationsUsers.map((status, index) => (
                      <option key={index} value={status.id}>
                        {status.userName}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="customTestPrice">
                  Custom Test Price Additional
                </label>
                <div className="input-group">
                  <input
                    value={customTestPriceAdditional ?? ""}
                    onChange={(e) =>
                      setCustomTestPriceAdditional(e.target.valueAsNumber)
                    }
                    id="custom_test_price_additional"
                    name="custom_test_price_additional"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Custom Test Price Additional"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">
                    {errors.customTestPriceAdditional}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <label htmlFor="lab1">Lab 1</label>
                <div className="input-group user-input-container">
                  <select
                    name="lab_1_id"
                    className={`form-control user-data-input`}
                    onChange={(e) => setLab1Id(e.target.value)}
                    value={lab1Id}
                  >
                    {!lab1Id && (
                      <option key={-1} value={-1} hidden={true}>
                        Select Lab 1
                      </option>
                    )}
                    {<option key={""} value={""}>{"NONE"}</option>}
                    {labs &&
                      labs.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="customTestPrice">Lab 1 custom cost</label>
                <div className="input-group">
                  <input
                    value={lab1CustomCost ?? ""}
                    onChange={(e) => setLab1CustomCost(e.target.value)}
                    id="lab_1_custom_cost"
                    name="lab_1_custom_cost"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Lab 1 custom cost"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.lab1CustomCost}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label>Homecare</label>
                <select
                  name="home_care"
                  className={`form-control user-data-input`}
                  onChange={(e) => setHomeCareId(e.target.value)}
                  value={homeCareId}
                  disabled={false}
                >
                  {!homeCareId && (
                    <option key={-1} value={-1} hidden={true}>
                      Select Home Care
                    </option>
                  )}
                  {<option key={""} value={""}>{"NONE"}</option>}
                  {homeCareServicesForCountry &&
                    homeCareServicesForCountry.map((status, index) => (
                      <option key={index} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="form-group col">
                <label htmlFor="homeCareCost">Homecare Costs</label>
                <input
                  value={homeCareCost ?? ""}
                  onChange={(e) => setHomeCareCost(e.target.valueAsNumber)}
                  type="number"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                  name="homeCareCost"
                  id="homeCareCost"
                  className="form-control py-2"
                  placeholder="Homecar cost"
                />
                <span className="help-block">{errors.homeCareCost}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="paymentGateway1">Payment Gateway 1</label>

                <div className="input-group user-input-container">
                  <select
                    value={paymentGateway1Id}
                    onChange={(e) => setPaymentGateway1Id(e.target.value)}
                    id="payment_gateway_1"
                    name="payment_gateway_1"
                    placeholder="Payment Gateway 1"
                    className={`form-control user-data-input`}
                  >
                    {!paymentGateway1Id && (
                      <option key={-1} value={-1} hidden={true}>
                        Select Payment Gateway 1
                      </option>
                    )}
                    {paymentGateways &&
                      paymentGateways.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="referenceNumber1">Reference Number 1</label>
                <div className="input-group">
                  <input
                    value={referenceNumber1}
                    onChange={(e) => setReferenceNumber1(e.target.value)}
                    id="reference_number_1"
                    name="reference_number_1"
                    type="text"
                    placeholder="Reference Number 1"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.referenceNumber1}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="lab2">Lab 2</label>
                <div className="input-group user-input-container">
                  <select
                    name="lab_2"
                    className={`form-control user-data-input`}
                    onChange={(e) => setLab2Id(e.target.value)}
                    value={lab2Id}
                  >
                    {!lab2Id && (
                      <option key={-1} value={-1} hidden={true}>
                        Select Lab 2
                      </option>
                    )}
                    {<option key={""} value={""}>{"NONE"}</option>}
                    {labs &&
                      labs.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="lab2CustomCost">Lab 2 custom cost</label>
                <div className="input-group">
                  <input
                    value={lab2CustomCost}
                    onChange={(e) => setLab2CustomCost(e.target.value)}
                    id="lab_2_custom_cost"
                    name="lab_2_custom_cost"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Lab 2 custom cost"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.lab2CustomCost}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="handledBy">Payment Gateway 2</label>
                <div className="input-group user-input-container">
                  <select
                    name="payment_gateway_2"
                    className={`form-control user-data-input`}
                    value={paymentGateway2Id}
                    onChange={(e) => setPaymentGateway2Id(e.target.value)}
                    id="payment_gateway_2"
                  >
                    {!paymentGateway2Id && (
                      <option key={-1} value={-1} hidden={true}>
                        Select Payment Gateway 2
                      </option>
                    )}
                    {paymentGateways &&
                      paymentGateways.map((status, index) => (
                        <option key={index} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="referenceNumber2">Reference Number 2</label>
                <div className="input-group">
                  <input
                    value={referenceNumber2}
                    onChange={(e) => setReferenceNumber2(e.target.value)}
                    id="reference_number_2"
                    name="reference_number_2"
                    type="text"
                    placeholder="Reference Number 2"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.referenceNumber2}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="coupon">Coupon Code</label>
                <div className="input-group user-input-container">
                  <input
                    value={couponCodeValue}
                    onChange={(e) => setCouponCodeValue(e.target.value)}
                    id="coupon_code"
                    name="coupon_code"
                    type="text"
                    placeholder="Coupon Code"
                    className={"form-control py-2"}
                  />

                  <span className="help-block">{errors.couponCodeValue}</span>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="coupon">Coupon Amount</label>
                <div className="input-group user-input-container">
                  <input
                    value={couponAmount}
                    onChange={(e) => setCouponAmount(e.target.value)}
                    id="coupon_amount"
                    name="coupon_amount"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Coupon Amount"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.couponAmount}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="revisedPrice">Revised Price</label>
                <div className="input-group">
                  <input
                    value={revisedPrice}
                    onChange={(e) => setRevisedPrice(e.target.value)}
                    id="revised_price"
                    name="revised_price"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Revised Price"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.revisedPrice}</span>
                </div>
              </div>
              <div className="form-group col">
                <label htmlFor="revisedPriceAfterCoupon">
                  Revised Price after Coupon
                </label>
                <div className="input-group">
                  <input
                    value={revisedPriceAfterCoupon}
                    onChange={(e) => setRevisedPriceAfterCoupon(e.target.value)}
                    id="revised_price_after_coupon"
                    name="revised_price_after_coupon"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Revised Price after Coupon"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">
                    {errors.revisedPriceAfterCoupon}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="refundAmount">Refund Amount</label>
                <div className="input-group">
                  <input
                    value={refundAmount}
                    onChange={(e) => setRefundAmount(e.target.value)}
                    id="refund_amount"
                    name="refund_amount"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) event.preventDefault();
                    }}
                    placeholder="Refund Amount"
                    className={"form-control py-2"}
                  />
                </div>
                <span className="help-block">{errors.refundAmount}</span>
              </div>
              <div className="form-group col">
                <label htmlFor="customTestBiomarkers">
                  Custom Test - Biomarkers
                </label>
                <div className="input-group">
                  <textarea
                    value={customTestBiomarkers}
                    onChange={(e) => setCustomTestBiomarkers(e.target.value)}
                    id="custom_test_biomarkers"
                    name="custom_test_biomarkers"
                    type="text"
                    placeholder="Custom Test - Biomarkers"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">
                    {errors.customTestBiomarkers}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label htmlFor="notes">Notes</label>
                <div className="input-group">
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    id="notes"
                    name="notes"
                    type="text"
                    placeholder="Notes"
                    className={"form-control py-2"}
                  />
                  <span className="help-block">{errors.notes}</span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="sampleDetailsLab1">Sample Details Lab 1*</label>
                <textarea
                  value={sampleDetailsLab1 ?? ""}
                  onChange={(e) => setSampleDetailsLab1(e.target.value)}
                  id="sampleDetailsLab1"
                  name="sampleDetailsLab1"
                  placeholder="Sample Details Lab 1"
                  className="form-control"
                />
                <span className="help-block">{errors.sampleDetailsLab1 ?? ""}</span>
              </div>
              <div className="form-group col">
                <label htmlFor="sampleDetailsLab2">Sample Details Lab 2</label>
                <textarea
                  value={sampleDetailsLab2 ?? ""}
                  onChange={(e) => setSampleDetailsLab2(e.target.value)}
                  id="sampleDetailsLab2"
                  name="sampleDetailsLab2"
                  placeholder="Sample Details Lab 2"
                  className="form-control"
                />
                <span className="help-block">{errors.sampleDetailsLab2 ?? ""}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="specialInstructions">Special Instructions</label>
                <textarea
                  value={specialInstructions ?? ""}
                  onChange={(e) => setSpecialInstructions(e.target.value)}
                  id="specialInstructions"
                  name="specialInstructions"
                  placeholder="Special Instructions"
                  className="form-control"
                />
                <span className="help-block">{errors.specialInstructions ?? ""}</span>
              </div>
              <div className="form-group col">
                <label htmlFor="paymentToBeCollected">Payment to be collected</label>
                <textarea
                  value={paymentToBeCollected ?? ""}
                  onChange={(e) => setPaymentToBeCollected(e.target.value)}
                  id="paymentToBeCollected"
                  name="paymentToBeCollected"
                  placeholder="Payment to be collected"
                  className="form-control"
                />
                <span className="help-block">{errors.paymentToBeCollected ?? ""}</span>
              </div>
            </div>
          </div>
        </>
                  )}*/}

     
        <div className="row">
          <div className="form-group col">
            <label htmlFor="refundAmount">Refund Amount</label>
            <div className="input-group">
              <input
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                id="refund_amount"
                name="refund_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Refund Amount"
                className={"form-control py-2"}
              />
            </div>
            <span className="help-block">{errors.refundAmount}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="notes">Notes</label>
            <div className="input-group">
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.notes}</span>
            </div>
          </div>
        </div>
      

      <div className="row">
        <div className="form-group col text-right">
          <button
            onClick={onHide}
            type="button"
            className="btn btn-outline-secondary"
          >
            Cancel
          </button>
          <button
            onClick={(e) => handleSave()}
            type="button"
            className="btn btn-outline-success ml-2"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
  return (
    <Modal
      size="lg"
      show={isVisible}
      onHide={onHide}
      className="user-data-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{`Edit ${itemNameEnglish}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>
  );
}

EditCartOrderBasicViewModal.propTypes = {
  orderDetails: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

EditCartOrderBasicViewModal.defaultProps = {
  orderDetails: {},
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCartOrderBasicViewModal);
