import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectHomeCareService.propTypes = {
  homeCareServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedHomeCareServiceId: PropTypes.number,
  isDisabled: PropTypes.bool,
  onHomeCareServiceSelect: PropTypes.func.isRequired,
};

SelectHomeCareService.defaultProps = {
  homeCareServices: [],
  isDisabled: false,
  onHomeCareServiceSelect: (f) => f,
};

function SelectHomeCareService(props) {
  const {
    homeCareServices,
    selectedHomeCareServiceId,
    isDisabled,
    onHomeCareServiceSelect,
  } = props;
  homeCareServices.sort((first, second) =>
    compareStrings(first.name, second.name, true)
  );

  const findHomeCareService = (homeCareServiceId) =>
    homeCareServices.find(
      (homeCareService) => homeCareService.id === homeCareServiceId
    );

  const createOption = (homeCareService) => {
    if (!homeCareService) return;
    return {
      key: homeCareService.id,
      value: homeCareService.id,
      label: homeCareService.name,
    };
  };

  const homeCareServiceOptions = homeCareServices.map(createOption);
  const selectedHomeCareService = findHomeCareService(
    selectedHomeCareServiceId
  );
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`my_unique_select_key__${selectedHomeCareServiceId}`}
      value={createOption(selectedHomeCareService)}
      onChange={(option) => onHomeCareServiceSelect(option.value)}
      options={homeCareServiceOptions}
      styles={selectStyles}
      isDisabled={isDisabled}
      placeholder="Select Homecare Service"
    />
  );
}

export default SelectHomeCareService;
