import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import PhoenixAPI from "utils/PhoenixAPI";
import { compareStrings } from "utils/commons";

SelectQuestionnaire.propTypes = {
  selectedQuestionnaire: PropTypes.number.isRequired,
  onQuestionnaireSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SelectQuestionnaire(props) {
  const {
    selectedQuestionnaire,
    onQuestionnaireSelect = (f) => f,
    isDisabled = false,
  } = props;

  const [questionnaires, setQuestionnaires] = useState([]);
  const questionnaireMapper = (questionnaire) => ({
    id: questionnaire.id,
    internalName: questionnaire.internalName,
    status: questionnaire.status,
  });

  useEffect(() => {
    const url = "questionnaires/";
    const fetchQuestionnaires = async () => {
      try {
        const { data: questionnaires } = await PhoenixAPI.get(url);
        setQuestionnaires(questionnaires.map(questionnaireMapper));
      } catch (error) {
        console.log("Error on fetching questionnaires", error);
      }
    };
    fetchQuestionnaires();
  }, []);

  const findQuestionnaire = (questionnaireId) => {
    return questionnaires.find(
      (questionnaire) => questionnaire.id === questionnaireId
    );
  };

  const [activeQuestionnaires, inactiveQuestionnaires] = _.partition(
    questionnaires,
    (it) => it.status === "Active"
  );
  activeQuestionnaires.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  inactiveQuestionnaires.sort((first, second) =>
    compareStrings(first.internalName, second.internalName, true)
  );
  const options = [
    {
      label: "Active",
      options: activeQuestionnaires.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveQuestionnaires.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selected = findQuestionnaire(selectedQuestionnaire);

  return (
    <Select
      key={`my_unique_select_key__${
        selectedQuestionnaire && selectedQuestionnaire.id
      }`}
      value={selectedQuestionnaire && createOption(selected)}
      onChange={onQuestionnaireSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Questionnaire"
      isDisabled={isDisabled}
    />
  );
}

const createOption = (questionnaire) => {
  if (!questionnaire) return;
  return {
    key: questionnaire.id,
    value: questionnaire.id,
    label: questionnaire.internalName,
  };
};

export default SelectQuestionnaire;
