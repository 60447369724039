import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectPageActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedActionIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectPageActions.defaultProps = {
  actions: [],
  selectedActionIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectPageActions(props) {
  const {
    actions,
    selectedActionIds,
    onSelect,
    menuPlacement,
    isDisabled,
  } = props;

  const findAction = (id) => actions.find((it) => it.id === id);

  const options = createOptions(actions);

  const selectedActions = selectedActionIds.map(findAction).map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedActionIds && selectedActionIds.join(",")
      }`}
      value={selectedActions}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(pageAction) {
  if (!pageAction || !pageAction.length) return [];
  pageAction.sort((first, second) => compareStrings(first.name, second.name, true));
  return pageAction.map(createOption);
}

function createOption(pageAction) {
  if (!pageAction) return;
  return {
    key: pageAction.id,
    value: pageAction.id,
    label: pageAction.name,
  };
}

export default SelectPageActions;
