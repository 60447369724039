import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import SelectCoach from "./SelectCoach";
import SelectOrderStatus from "./SelectOrderStatus";
import SelectFamilyMember from "./SelectMember";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { updateChildOrder } from "services/OrderService";

function EditCartOrderNoHomeAppointmentViewModal(props) {
  const {
    order,
    orderDetails,
    labs,
    homeCareServices,
    nurses,
    wellbeingCoaches,
    countryId,
    onHide,
    onSave,
    isVisible,
    agents,
    paymentGateways,
    operationsUsers,
    familyMembers,
    userDetails,
    onSavingFamilyMember={},
    onCancelFamilyMember={}
  } = props;
 
  const { itemType,orderId } = order ?? {};
  const {
    itemNameEnglish,
    orderStatusList,
    orderStatus: status,
    bookedSlotDate,
    bookedSlotTime,
    specimenCollectionDate,
    specimenCollectionTime,
    confirmedDateTime,
    nutritionistId,
    laboratoryId,
    operationType,
    isResultUploaded,
    isAttachmentUploaded,
    doctorsNote,
    doctorsNoteAr,
    labCost: orderLabCost,
    homeCareCost: orderHomeCareCost,
    homeCareId: orderHomeCareId,
    couponAmount: orderCouponAmount,
    customTestBiomarkers: orderCustomTestBiomarkers,
    customTestPriceAdditional: orderCustomTestPriceAdditional,
    handledById: orderHandledById,
    lab1Id: orderLab1Id,
    lab2Id: orderLab2Id,
    lab1CustomCost: orderLab1CustomCost,
    lab2CustomCost: orderLab2CustomCost,
    notes: orderNotes,
    referenceNumber1: orderReferenceNumber1,
    referenceNumber2: orderReferenceNumber2,
    refundAmount: orderRefundAmount,
    revisedPrice: orderRevisedPrice,
    revisedPriceAfterCoupon: orderRevisedPriceAfterCoupon,
    paymentGateway1Id: orderPaymentGateway1Id,
    paymentGateway2Id: orderPaymentGateway2Id,
    couponCodeValue: orderCouponCodeValue,
    sampleDetailsLab1: orderSampleDetailsLab1,
    sampleDetailsLab2: orderSampleDetailsLab2,
    specialInstructions: orderSpecialInstructions,
    paymentToBeCollected: orderPaymentToBeCollected,
    professionalId:orderProfessionalId,
    homecareStatus:orderHomecareConfirmationStatus,
    isRequireSampleCollection

  } = orderDetails ?? {};

  const homeCareStatus = [
    { text: "None", value: "", key: "",status:""},
    {
      text: "Not Assigned",
      value:  "NOT ASSIGNED",
      key:  "NOT ASSIGNED",
      status: "NOT ASSIGNED",
    },
    {
      text: "Assigned",
      value: "ASSIGNED",
      key: "ASSIGNED",
      status: "ASSIGNED",
    },
    {
      text: "Confirmed",
      value: "CONFIRMED",
      key:"CONFIRMED" ,
      status: "CONFIRMED",
    },
    {
      text: "Rescheduled",
      value: "RESCHEDULED",
      key: "RESCHEDULED",
      status: "RESCHEDULED",
    },
  ];

  const homeCareServicesForCountry = homeCareServices
    ? homeCareServices.filter((it) => it?.country?.id === countryId)
    : [];
  const [confirmedDatee, confirmedTimee] = confirmedDateTime
    ? confirmedDateTime.split(" ")
    : [null, null];

  const [orderStatus, setOrderStatus] = useState(status);
  const [appointmentDate, setAppointmentDate] = useState(
    bookedSlotDate ?? specimenCollectionDate
  );
  const [appointmentTime, setAppintmentTime] = useState(
    bookedSlotTime ?? specimenCollectionTime
  );
  const [confirmedDate, setConfirmedDate] = useState(confirmedDatee);
  const [confirmedTime, setConfirmedTime] = useState(confirmedTimee);
  const [nutritionist, setNutritionist] = useState(nutritionistId);
  const [laboratory, setLaboratory] = useState(laboratoryId);
  const [labCost, setLabCost] = useState(orderLabCost);
  const [homeCareCost, setHomeCareCost] = useState(orderHomeCareCost);
  const [homeCareId, setHomeCareId] = useState(orderHomeCareId);
  const [errors, setErrors] = useState({});
  const [handledById, setHandledById] = useState(orderHandledById);
  const [customTestPriceAdditional, setCustomTestPriceAdditional] = useState(
    orderCustomTestPriceAdditional
  );
  const [lab1Id, setLab1Id] = useState(orderLab1Id);
  const [lab1CustomCost, setLab1CustomCost] = useState(orderLab1CustomCost);
  const [paymentGateway1Id, setPaymentGateway1Id] = useState(
    orderPaymentGateway1Id
  );
  const [referenceNumber1, setReferenceNumber1] = useState(
    orderReferenceNumber1
  );
  const [lab2Id, setLab2Id] = useState(orderLab2Id);
  const [lab2CustomCost, setLab2CustomCost] = useState(orderLab2CustomCost);
  const [paymentGateway2Id, setPaymentGateway2Id] = useState(
    orderPaymentGateway2Id
  );
  const [referenceNumber2, setReferenceNumber2] = useState(
    orderReferenceNumber2
  );
  const [revisedPrice, setRevisedPrice] = useState(orderRevisedPrice);
  const [couponAmount, setCouponAmount] = useState(orderCouponAmount);
  const [revisedPriceAfterCoupon, setRevisedPriceAfterCoupon] = useState(
    orderRevisedPriceAfterCoupon
  );
  const [refundAmount, setRefundAmount] = useState(orderRefundAmount);
  const [customTestBiomarkers, setCustomTestBiomarkers] = useState(
    orderCustomTestBiomarkers
  );
  const [notes, setNotes] = useState(orderNotes);
  const [couponCodeValue, setCouponCodeValue] = useState(orderCouponCodeValue);

  const [sampleDetailsLab1, setSampleDetailsLab1] = useState(orderSampleDetailsLab1);
  const [sampleDetailsLab2, setSampleDetailsLab2] = useState(orderSampleDetailsLab2);
  const [specialInstructions, setSpecialInstructions] = useState(orderSpecialInstructions);
  const [paymentToBeCollected, setPaymentToBeCollected] = useState(orderPaymentToBeCollected)
  const [familyMemberId, setFamilyMemberId]=useState(null)
  const [showSelectMemberPopup, setShowSelectMemberPopup]=useState(false);
  const [professionalId,setProfessionalId]=useState(orderProfessionalId);
  const[homecareStatus,setHomeCareStatus]=useState(orderHomecareConfirmationStatus);
  const[manuallyAssign,setManuallyAssign] = useState(false);

  const getDependentMember=()=>{
    const {dependent=null}= orderDetails || {};
     const {id:familyMemberId= null}= dependent || {};
     if(familyMemberId){
      setFamilyMemberId(familyMemberId)
     }
     else{
      const selfUser= familyMembers.length>0 && familyMembers.filter((it)=>it.relation=="self");
      if(selfUser)
      setFamilyMemberId(selfUser[0].id)
     }
   }

  useEffect(() => {
    setOrderStatus(null);
    setAppointmentDate(null);
    setAppintmentTime(null);
    setConfirmedDate(null);
    setConfirmedTime(null);
    setNutritionist(null);
    setLaboratory(null);
    setLabCost(null);
    setHomeCareCost(null);
    setHomeCareId(null);
    setHandledById(null);
    setCustomTestPriceAdditional(null);
    setLab1Id(null);
    setLab1CustomCost(null);
    setPaymentGateway1Id(null);
    setReferenceNumber1(null);
    setLab2Id(null);
    setLab2CustomCost(null);
    setPaymentGateway2Id(null);
    setReferenceNumber2(null);
    setRevisedPrice(null);
    setCouponAmount(null);
    setRevisedPriceAfterCoupon(null);
    setRefundAmount(null);
    setCustomTestBiomarkers(null);
    setNotes(null);
    setErrors({});
    setCouponCodeValue(null);
    setSampleDetailsLab1(null);
    setSampleDetailsLab2(null);
    setSpecialInstructions(null);
    setPaymentToBeCollected(null);
    getDependentMember();
    setHomeCareStatus(null);
    setProfessionalId(null);
    setManuallyAssign(false);
  }, [order, orderDetails]);
  useEffect(() => setOrderStatus(status), [status]);
  useEffect(
    () => setAppointmentDate(bookedSlotDate ?? specimenCollectionDate),
    [bookedSlotDate, specimenCollectionDate]
  );
  useEffect(
    () => setAppintmentTime(bookedSlotTime ?? specimenCollectionTime),
    [bookedSlotTime, specimenCollectionTime]
  );
  useEffect(() => setConfirmedDate(confirmedDatee), [confirmedDatee]);
  useEffect(() => setConfirmedTime(confirmedTimee), [confirmedTimee]);
  useEffect(() => setNutritionist(nutritionistId), [nutritionistId]);
  useEffect(() => setLaboratory(laboratoryId), [laboratoryId]);
  useEffect(() => setHomeCareId(orderHomeCareId), [orderHomeCareId]);
  useEffect(() => setHomeCareCost(orderHomeCareCost), [orderHomeCareCost]);
  useEffect(() => setLabCost(orderLabCost), [orderLabCost]);
  useEffect(() => setHandledById(orderHandledById), [orderHandledById]);
  useEffect(
    () => setCustomTestPriceAdditional(orderCustomTestPriceAdditional),
    [orderCustomTestPriceAdditional]
  );
  useEffect(() => setLab1Id(orderLab1Id), [orderLab1Id]);
  useEffect(
    () => setLab1CustomCost(orderLab1CustomCost),
    [orderLab1CustomCost]
  );
  useEffect(
    () => setPaymentGateway1Id(orderPaymentGateway1Id),
    [orderPaymentGateway1Id]
  );
  useEffect(
    () => setReferenceNumber1(orderReferenceNumber1),
    [orderReferenceNumber1]
  );
  useEffect(() => setLab2Id(orderLab2Id), [orderLab2Id]);
  useEffect(
    () => setLab2CustomCost(orderLab2CustomCost),
    [orderLab2CustomCost]
  );
  useEffect(
    () => setPaymentGateway2Id(orderPaymentGateway2Id),
    [orderPaymentGateway2Id]
  );
  useEffect(
    () => setReferenceNumber2(orderReferenceNumber2),
    [orderReferenceNumber2]
  );
  useEffect(() => setRevisedPrice(orderRevisedPrice), [orderRevisedPrice]);
  useEffect(() => setCouponAmount(orderCouponAmount), [orderCouponAmount]);
  useEffect(
    () => setRevisedPriceAfterCoupon(orderRevisedPriceAfterCoupon),
    [orderRevisedPriceAfterCoupon]
  );
  useEffect(() => setRefundAmount(orderRefundAmount), [orderRefundAmount]);
  useEffect(
    () => setCustomTestBiomarkers(orderCustomTestBiomarkers),
    [orderCustomTestBiomarkers]
  );
  useEffect(() => setNotes(orderNotes), [orderNotes]);
  useEffect(
    () => setCouponCodeValue(orderCouponCodeValue),
    [orderCouponCodeValue]
  );
  useEffect(() => setSampleDetailsLab1(orderSampleDetailsLab1), [orderSampleDetailsLab1]);
  useEffect(() => setSampleDetailsLab2(orderSampleDetailsLab2), [orderSampleDetailsLab2]);
  useEffect(() => setSpecialInstructions(orderSpecialInstructions), [orderSpecialInstructions]);
  useEffect(() => setPaymentToBeCollected(orderPaymentToBeCollected), [orderPaymentToBeCollected]);
  useEffect(()=> setProfessionalId(orderProfessionalId),[orderProfessionalId]);
  useEffect(()=>setHomeCareStatus(orderHomecareConfirmationStatus),[orderHomecareConfirmationStatus]);
  const homecareNurses=props.nurses!=null && homeCareId? nurses.filter((nurse) => nurse.homeCareServiceId==homeCareId):[];

  const isCustomPackage = itemType === "Custom_Package";
  const isHomeAppointmentRequired =
  isCustomPackage &&
  operationType &&
  operationType.includes("Home Appointment");
  const sampleNotRequire=  ((!(isRequireSampleCollection ))&& isHomeAppointmentRequired);

  const validate = () => {
    const errors = {};
    if (labCost && Number.isNaN(Number(labCost)))
      errors.labCost = "Lab Costs should be a valid number";
    else if (labCost && Number(labCost) > 9999)
      errors.labCost = "Lab Costs can not be greater then 9999";

    if (homeCareCost && Number.isNaN(Number(homeCareCost)))
      errors.homeCareCost = "Homecare Costs should be a valid number";
    else if (homeCareCost && Number(homeCareCost) > 9999)
      errors.homeCareCost = "Homecare Costs can not be greater then 9999";

    if (
      customTestPriceAdditional &&
      Number.isNaN(Number(customTestPriceAdditional))
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be a valid number";
    } else if (
      customTestPriceAdditional &&
      Number(customTestPriceAdditional) > 9999
    ) {
      errors.customTestPriceAdditional =
        "Custom Test Price Additional should be bewteen 0 to 9999";
    }

    if (lab1CustomCost && Number.isNaN(Number(lab1CustomCost))) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be a valid number";
    } else if (lab1CustomCost && Number(lab1CustomCost) > 9999) {
      errors.lab1CustomCost = "Lab 1 Custom Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber1 && referenceNumber1.length > 500) {
      errors.referenceNumber1 =
        "Reference Number 1 is limited to a 500 characters";
    }

    if (lab2CustomCost && Number.isNaN(Number(lab2CustomCost))) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be a valid number";
    } else if (lab2CustomCost && Number(lab2CustomCost) > 9999) {
      errors.lab2CustomCost = "Lab 2 Custom  Cost should be bewteen 0 to 9999";
    }

    if (referenceNumber2 && referenceNumber2.length > 500) {
      errors.reference_number_2 =
        "Reference Number 2 is limited to a 500 characters";
    }

    if (revisedPrice && Number.isNaN(Number(revisedPrice))) {
      errors.revisedPrice = "Revised Price should be a valid number";
    } else if (revisedPrice && Number(revisedPrice) > 9999) {
      errors.revisedPrice = " Revised Price Cost should be bewteen 0 to 9999";
    }

    if (couponAmount && Number.isNaN(Number(couponAmount))) {
      errors.couponAmount = "Coupon Amount should be a valid number";
    } else if (couponAmount && Number(couponAmount) > 9999) {
      errors.couponAmount = "Coupon Amount should be bewteen 0 to 9999";
    }

    if (
      revisedPriceAfterCoupon &&
      Number.isNaN(Number(revisedPriceAfterCoupon))
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be a valid number";
    } else if (
      revisedPriceAfterCoupon &&
      Number(revisedPriceAfterCoupon) > 9999
    ) {
      errors.revisedPriceAfterCoupon =
        "Revised Price After Coupon should be bewteen 0 to 9999";
    }

    if (refundAmount && Number.isNaN(Number(refundAmount))) {
      errors.refund_amount = "Refund Amount should be a valid number";
    } else if (refundAmount && refundAmount && Number(refundAmount) > 9999) {
      errors.refundAmount = "Refund Amount should be bewteen 0 to 9999";
    }

    if (customTestBiomarkers && customTestBiomarkers.length > 3000) {
      errors.customTestBiomarkers =
        "Custom Test Biomarkers is limited to a 3000 characters";
    }

    if (notes && notes.length > 3000) {
      errors.notes = "Notes is limited to a 3000 characters";
    }

    if (couponCodeValue && couponCodeValue.length > 100) {
      errors.couponCodeValue = "Coupon Code Value limited  to a 100 characters";
    }

    if (!sampleDetailsLab1 || sampleDetailsLab1.trim() === "")
      errors.sampleDetailsLab1 = "Sample Details Lab 1 cannot be blank!";
    else if (sampleDetailsLab1.length > 500)
      errors.sampleDetailsLab1 = "Sample Details Lab 1 is limited to a 500 characters";

    if (sampleDetailsLab2 && sampleDetailsLab2.length > 500)
      errors.sampleDetailsLab2 = "Sample Details Lab 2 is limited to a 500 characters";

    if (specialInstructions && specialInstructions.length > 500)
      errors.specialInstructions = "Special Instructions is limited to a 500 characters";

    if (paymentToBeCollected && paymentToBeCollected.length > 500)
      errors.paymentToBeCollected = "Payment to be collected is limited to a 500 characters";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  const handleManuallyAssign=async()=>{
    
    handleSave();
    
   setManuallyAssign(false);
  }

  const handleBothFunctions = async () => {
    await handleManuallyAssign();
    handleSave();
  };

  const validateOrderStatus = () => {
    const isOnlyPDFDownload =
    (itemType === "Blood_Package") ||
    (itemType === "Custom_Package" &&
     operationType &&
     operationType.includes("PDF Download"));
    const isCoachReviewRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("Requires Coach Note"));
        const isOrderStatusUploaded= ("RESULTS UPLOADED TO APP" == orderStatus.toUpperCase());
     const isAttachmentUploaded = ( isResultUploaded === true || isOrderStatusUploaded===true );  
    if (["COMPLETED", "Order Completed","COACH_REVIEWED", "Coach Reviewed", "COACH REVIEWED"].includes(orderStatus)) {
      if (isOnlyPDFDownload && !isAttachmentUploaded)
        return "Upload Report PDF to mark as completed!";
      if (
        isCoachReviewRequired &&
        (nutritionist === null || nutritionist === undefined)
      )
        return "Assign a Wellbeing coach to mark as completed!";

      if (
        isCoachReviewRequired &&
        nutritionist &&
        (!doctorsNote || !doctorsNoteAr)
      )
        return "Add Coach review's to mark as completed!";
    } 
    
    // else if (["COACH_REVIEWED", "Coach Reviewed"].includes(orderStatus)) {
    //   if (isOnlyPDFDownload && !isAttachmentUploaded)
    //   return "Upload Report PDF to mark as completed!";
    //   if (
    //     isCoachReviewRequired &&
    //     (nutritionist === null || nutritionist === undefined)
    //   )
    //     return "Assign a Wellbeing coach to mark as coach reviewed!";
    //     if (
    //       isCoachReviewRequired &&
    //       nutritionist &&
    //       (!doctorsNote || !doctorsNoteAr)
    //     )
    //       return "Add Coach review's to mark as completed!";
     
    // }

   else if (orderStatus && ["RESULTS UPLOADED", "RESULTS UPLOADED TO APP", "RESULTS_UPLOADED_TO_APP"].includes(orderStatus.toUpperCase())) {
      console.log("validateOrderStatus::", { orderStatus, isAttachmentUploaded });
      // if (!isAttachmentUploaded) return "Kindly upload lab reports first";
      if (isOnlyPDFDownload && !isAttachmentUploaded) return "Kindly upload lab reports first";
    }
    return null;
  };

  const handleSave = (event) => {
    const errors = validate();
    if (errors) {
      setErrors(errors);
      return;
    }

    const orderStatusErrorMessage = validateOrderStatus();

    if (orderStatusErrorMessage) {
      props.showNotificationMessage({
        notificationMessage: orderStatusErrorMessage,
        successMessage: false,
        showNotification: true,
      });
      return;
    }

    onSave(orderDetails, {
      orderStatus,
      confirmedDate,
      confirmedTime,
      bookedSlotDate: appointmentDate,
      bookedSlotTime: appointmentTime,
      laboratoryId: laboratory,
      nutritionistId: nutritionist,
      labCost,
      homeCareCost,
      homeCareId,
      handledById,
      customTestPriceAdditional,
      lab1Id,
      lab1CustomCost,
      paymentGateway1Id,
      referenceNumber1,
      lab2Id,
      lab2CustomCost,
      paymentGateway2Id,
      referenceNumber2,
      revisedPrice,
      couponAmount,
      revisedPriceAfterCoupon,
      refundAmount,
      customTestBiomarkers,
      notes,
      couponCodeValue,
      sampleDetailsLab1,
      sampleDetailsLab2,
      specialInstructions,
      paymentToBeCollected,
      homecareStatus,
      professionalId
    });
  };

  const isCoachReviewRequired =
    itemType === "Blood_Package" ||
    (itemType === "Custom_Package" &&
      operationType &&
      operationType.includes("Requires Coach Note"));

      const renderFamilyMemberChange=()=>{
        const {firstName,lastName}= userDetails || {}
       const findMember=familyMembers.find((it)=>it.id===familyMemberId)
       const fullUserName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
       const {dependent}= orderDetails || {}
       
       if(!dependent){
          return `Are you sure want to update the member for this order from "Self" to ${findMember?.fullName || ""}`
       }
       else{
         const {firstName, lastName}= dependent
         const dependentFullName= `${firstName ?? ""} ${lastName ?? ""}`.trim();
         if(dependent.id == (findMember && findMember.id)){
           return "Order is Assigned already to this Member "
           
         }
         else{
           return `Are you sure want to update the member for this order from ${dependentFullName} to ${findMember?.fullName || ""}`
         }
       }
        
   
     }
     const handleSaveSelectMemberModal=async()=>{
        const {itemOrderId}= orderDetails || {} 
        const modifiedBy= getUserId();
        if(!familyMemberId || !itemOrderId){
         return 
        }
     
        
       
        const payload={
         "dependentId":familyMemberId=="self"?null:familyMemberId,
         "modifiedBy":modifiedBy
        }
        try{
          const res= await PhoenixAPI.patch(`/assign-dependent/child-order-id/${itemOrderId}`, payload)
          console.log("res", res)
          setShowSelectMemberPopup(false)
          onSavingFamilyMember(true)
        }
        catch(error){
         console.log("Error in updating member for this order");
         
        }
   
      }
    
      const hideSelectMemberModal=()=>{
        onCancelFamilyMember(true)
       setShowSelectMemberPopup(false)
      }

  const form = (
    <>
      <div className="row">
        <div className="form-group col">
          <label>Order Status</label>
          <SelectOrderStatus
            options={orderStatusList}
            value={orderStatus}
            onSelect={(selectedOrderStatus) =>
              setOrderStatus(selectedOrderStatus)
            }
          />
        </div>
        <div className="form-group col">
        <label>Select Family Member</label>
        <div className="input-group">
              <SelectFamilyMember
                id="familyMemberId"
                name="familyMemberId"
                familyMembers={familyMembers ?? []}
                selectedFamilyMemberId={familyMemberId}
                onSelect={(option) => {
                  setFamilyMemberId(option.value);setShowSelectMemberPopup(true)
                }
                }
                showOnlyActive={true}
                // isDisabled={!isNewCartItem && !editables.familyMemberId}
              />
           
            </div>
        </div>
      </div>
      {isCoachReviewRequired && (
        <div className="row">
          <div className="form-group col-6">
            <label>Coach</label>
            <SelectCoach
              coaches={wellbeingCoaches ?? []}
              selectedCoachId={nutritionist}
              onCoachSelect={(coachId) => setNutritionist(coachId)}
            />
          </div>
        </div>
      )}
      <div className="form-content">
        <span>Operations Internal</span>
      </div>
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy"> Handled By</label>
            <select
              name="handled_by"
              className={`form-control user-data-input`}
              onChange={(e) => setHandledById(e.target.value)}
              value={handledById}
              disabled={false}
            >
              {!handledById && (
                <option key={-1} value={-1} hidden={true}>
                  Select Handled By
                </option>
              )}
              {operationsUsers &&
                operationsUsers.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.userName}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">
              Custom Test Price Additional
            </label>
            <div className="input-group">
              <input
                value={customTestPriceAdditional ?? ""}
                onChange={(e) =>
                  setCustomTestPriceAdditional(e.target.valueAsNumber)
                }
                id="custom_test_price_additional"
                name="custom_test_price_additional"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Custom Test Price Additional"
                className={"form-control py-2"}
              />
              <span className="help-block">
                {errors.customTestPriceAdditional}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab1"> Lab 1</label>
            <div className="input-group user-input-container">
              <select
                name="lab_1_id"
                className={`form-control user-data-input`}
                onChange={(e) => {setLab1Id(e.target.value); if(homeCareId!=null &&  homeCareId !=""  &&  (((!(isRequireSampleCollection ))&& isHomeAppointmentRequired))){setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value);setManuallyAssign(true);}  else if(homeCareId!=null &&  homeCareId !="" && e.target.value !=null && e.target.value!="" && ( (!(isCustomPackage) )|| (isRequireSampleCollection && isHomeAppointmentRequired))) {setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value);setManuallyAssign(true);}else{setManuallyAssign(false); setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="")[0].value)} }}
                value={lab1Id}
              >
                {!lab1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 1
                  </option>
                )}
                {<option key={""} value={""}>{"NONE"}</option>}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
            {/*<span className="help-block"> {sampleNotRequire?"Lab is not required":""}</span>*/}
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 1 custom cost</label>
            <div className="input-group">
              <input
                value={lab1CustomCost ?? ""}
                onChange={(e) => setLab1CustomCost(e.target.value)}
                id="lab_1_custom_cost"
                name="lab_1_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Lab 1 custom cost"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.lab1CustomCost}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>Homecare </label>
            <select
              name="home_care"
              className={`form-control user-data-input`}
              onChange={(e) => {setHomeCareId(e.target.value);if(e.target.value !=null  && e.target.value!="" && lab1Id !=null && lab1Id!="" &&( (!(isCustomPackage) )|| (isRequireSampleCollection && isHomeAppointmentRequired))) {setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value);setManuallyAssign(true);} else if( e.target.value !=null  && e.target.value!="" && ((!(isRequireSampleCollection)) && isHomeAppointmentRequired )){setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="ASSIGNED")[0].value); setManuallyAssign(true);}else{setManuallyAssign(false); setHomeCareStatus(homeCareStatus.filter((homecareStatus)=> homecareStatus.status=="")[0].value)}}}
              value={homeCareId}
              disabled={false}
            >
              {!homeCareId && (
                <option key={-1} value={-1} hidden={true}>
                  Select Home Care
                </option>
              )}
              {<option key={""} value={""}>{"NONE"}</option>}
              {homeCareServicesForCountry &&
                homeCareServicesForCountry.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="form-group col">
            <label htmlFor="homeCareCost">Homecare Costs</label>
            <input
              value={homeCareCost ?? ""}
              onChange={(e) => setHomeCareCost(e.target.valueAsNumber)}
              type="number"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
              name="homeCareCost"
              id="homeCareCost"
              className="form-control py-2"
              placeholder="Homecare Costs"
            />
            <span className="help-block">{errors.homeCareCost}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor=""> Assign Nurse</label>
            <select
       className={
        "form-control py-2" +
        (errors.nurse ? " has-error" : "")
      }
      onChange={(e) => {console.log("prof Id",e.target.value);setProfessionalId(e.target.value);if(e.target.value !=null  && e.target.value!="" && lab1Id!="" && lab1Id !=null && homeCareId!=null && homeCareId!="") setManuallyAssign(true); }}
      value={professionalId?professionalId:""}
    >
      {homeCareId?(<><option key={""} value={""} text={"NONE"} >{"NONE"}</option>
       { homecareNurses.length>0 && homecareNurses.map(({professionalId,fullName}, index) => (
          <option key={index} value={professionalId} text={fullName}>
            {fullName}
          </option>
        ))}</>):(props.nurses &&  <><option key={""} value={""} text={"NONE"} >{"NONE"}</option>
       {props.nurses.map(({professionalId,fullName}, index) => (
          <option key={index} value={professionalId} text={fullName}>
            {fullName}
          </option>
        ))}</>)
       
        }
    </select>
    </div>

    <div className="form-group col">
    <label htmlFor=""> HomeCare Confirmation Status</label>
    <select
            className={
              "form-control py-2" +
              (errors.homecarestatus ? " has-error" : "")
            }
            onChange={(e) => {setHomeCareStatus(e.target.value); if(e.target.value=="ASSIGNED" && homeCareId!=null && homeCareId !="" && lab1Id !=null && lab1Id!="") setManuallyAssign(true) }}
            value={homecareStatus?homecareStatus:""}
          >
            {homeCareStatus &&
              homeCareStatus.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>

      </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="paymentGateway1">Payment Gateway 1</label>
            <div className="input-group user-input-container">
              <select
                value={paymentGateway1Id}
                onChange={(e) => setPaymentGateway1Id(e.target.value)}
                id="payment_gateway_1"
                name="payment_gateway_1"
                placeholder="Payment Gateway 1"
                className={`form-control user-data-input`}
              >
                {!paymentGateway1Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 1
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="referenceNumber1">Reference Number 1</label>
            <div className="input-group">
              <input
                value={referenceNumber1}
                onChange={(e) => setReferenceNumber1(e.target.value)}
                id="reference_number_1"
                name="reference_number_1"
                type="text"
                placeholder="Reference Number 1"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.referenceNumber1}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab2">Lab 2</label>
            <div className="input-group user-input-container">
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                onChange={(e) => setLab2Id(e.target.value)}
                value={lab2Id}
              >
                {!lab2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Lab 2
                  </option>
                )}
                {labs &&
                  labs.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="lab2CustomCost">Lab 2 custom cost</label>
            <div className="input-group">
              <input
                value={lab2CustomCost}
                onChange={(e) => setLab2CustomCost(e.target.value)}
                id="lab_2_custom_cost"
                name="lab_2_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Lab 2 custom cost"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.lab2CustomCost}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 2</label>
            <div className="input-group user-input-container">
              <select
                name="payment_gateway_2"
                className={`form-control user-data-input`}
                value={paymentGateway2Id}
                onChange={(e) => setPaymentGateway2Id(e.target.value)}
                id="payment_gateway_2"
              >
                {!paymentGateway2Id && (
                  <option key={-1} value={-1} hidden={true}>
                    Select Payment Gateway 2
                  </option>
                )}
                {paymentGateways &&
                  paymentGateways.map((status, index) => (
                    <option key={index} value={status.id}>
                      {status.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="referenceNumber2">Reference Number 2</label>
            <div className="input-group">
              <input
                value={referenceNumber2}
                onChange={(e) => setReferenceNumber2(e.target.value)}
                id="reference_number_2"
                name="reference_number_2"
                type="text"
                placeholder="Reference Number 2"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.referenceNumber2}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="coupon">Coupon Code</label>
            <div className="input-group user-input-container">
              <input
                value={couponCodeValue}
                onChange={(e) => setCouponCodeValue(e.target.value)}
                id="coupon_code"
                name="coupon_code"
                type="text"
                placeholder="Coupon Code"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.couponCodeValue}</span>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="coupon">Coupon Amount</label>
            <div className="input-group user-input-container">
              <input
                value={couponAmount}
                onChange={(e) => setCouponAmount(e.target.value)}
                id="coupon_amount"
                name="coupon_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Coupon Amount"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.couponAmount}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="revisedPrice">Revised Price</label>
            <div className="input-group">
              <input
                value={revisedPrice}
                onChange={(e) => setRevisedPrice(e.target.value)}
                id="revised_price"
                name="revised_price"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Revised Price"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.revisedPrice}</span>
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="revisedPriceAfterCoupon">
              Revised Price after Coupon
            </label>
            <div className="input-group">
              <input
                value={revisedPriceAfterCoupon}
                onChange={(e) => setRevisedPriceAfterCoupon(e.target.value)}
                id="revised_price_after_coupon"
                name="revised_price_after_coupon"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Revised Price after Coupon"
                className={"form-control py-2"}
              />
              <span className="help-block">
                {errors.revisedPriceAfterCoupon}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="refundAmount">Refund Amount</label>
            <div className="input-group">
              <input
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                id="refund_amount"
                name="refund_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                placeholder="Refund Amount"
                className={"form-control py-2"}
              />
            </div>
            <span className="help-block">{errors.refundAmount}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestBiomarkers">
              Custom Test - Biomarkers
            </label>
            <div className="input-group">
              <textarea
                value={customTestBiomarkers}
                onChange={(e) => setCustomTestBiomarkers(e.target.value)}
                id="custom_test_biomarkers"
                name="custom_test_biomarkers"
                type="text"
                placeholder="Custom Test - Biomarkers"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.customTestBiomarkers}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="notes">Notes</label>
            <div className="input-group">
              <textarea
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={"form-control py-2"}
              />
              <span className="help-block">{errors.notes}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab1">Sample Details Lab 1*</label>
            <textarea
              value={sampleDetailsLab1 ?? ""}
              onChange={(e) => setSampleDetailsLab1(e.target.value)}
              id="sampleDetailsLab1"
              name="sampleDetailsLab1"
              placeholder="Sample Details Lab 1"
              className="form-control"
            />
            <span className="help-block">{errors.sampleDetailsLab1 ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="sampleDetailsLab2">Sample Details Lab 2</label>
            <textarea
              value={sampleDetailsLab2 ?? ""}
              onChange={(e) => setSampleDetailsLab2(e.target.value)}
              id="sampleDetailsLab2"
              name="sampleDetailsLab2"
              placeholder="Sample Details Lab 2"
              className="form-control"
            />
            <span className="help-block">{errors.sampleDetailsLab2 ?? ""}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="specialInstructions">Special Instructions</label>
            <textarea
              value={specialInstructions ?? ""}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              id="specialInstructions"
              name="specialInstructions"
              placeholder="Special Instructions"
              className="form-control"
            />
            <span className="help-block">{errors.specialInstructions ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="paymentToBeCollected">Payment to be collected</label>
            <input
              value={paymentToBeCollected ?? ""}
              onChange={(e) => setPaymentToBeCollected(e.target.value)}
              id="paymentToBeCollected"
              name="paymentToBeCollected"
              placeholder="Payment to be collected"
              className="form-control"
              type="number"
            />
            <span className="help-block">{errors.paymentToBeCollected ?? ""}</span>
          </div>
        </div>
      </div>
      <div className="row">

      <div className="form-group col text-left">
          <button
            onClick={handleBothFunctions}
            type="button"
          style={{ backgroundColor: "#CEE741", marginTop:"10px" }}
          className="btn px-5"
          disabled={!manuallyAssign}
          >
            Manually Assign
          </button>
        </div>
        <div className="form-group col text-right">
          <button
            onClick={onHide}
            type="button"
            className="btn btn-outline-secondary"
          >
            Cancel
          </button>
         { <button
            onClick={manuallyAssign?handleBothFunctions: handleSave}
            type="button"
            className="btn btn-outline-success ml-2"
            
          >
            Save
          </button>}
        </div>
      </div>
    </>
  );
  return (<>
  <Modal
      size="xl"
      show={isVisible}
      onHide={onHide}
      className="user-data-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{`Edit ${itemNameEnglish}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>
    <Modal
      size="xl"
      show={isVisible}
      onHide={onHide}
      className="user-data-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{`Edit ${itemNameEnglish}`}</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>
    <Modal
      size="sm"
      show={showSelectMemberPopup}

    >

      <Modal.Body>
      
          {renderFamilyMemberChange()}
        
      
      </Modal.Body>
      <Modal.Footer>
      <button
            onClick={() => handleSaveSelectMemberModal()}
            className="btn btn-outline-success rounded-pill px-4 py-1"
          >
            Yes
          </button>
          <button
            className="btn btn-secondary rounded-pill px-4 py-1"
            onClick={() => hideSelectMemberModal()}
          >
            Cancel
          </button>
      </Modal.Footer>
    </Modal>
  </>
    
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCartOrderNoHomeAppointmentViewModal);
