import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import SendIcon from "@material-ui/icons/Send";
import CheckIcon from "@material-ui/icons/Check";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import { addResponse, sendMessage } from "services/MessagingService";
import { isEmpty } from "../utils/FormValidator";
import Tooltip from "@material-ui/core/Tooltip";
import { getUserId } from "../services/UserService";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChat: null,
      chatLoading: false,
      showGotoBottom: false,
      loadError: "",
      clients: this.props.clients,
      clientHeight: null,
      scrollHeight: null
    };

    this.chatBox = React.createRef();
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleReply = this.handleReply.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    this.props.setActiveChat({ activeChat: null });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clients !== this.props.clients) {
      this.setState({ clients: this.props.clients }, () =>
        this.setScrollHeight()
      );
    }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  setActiveChat(client) {
    let { activeChat } = this.state;

    if (activeChat && activeChat.id === client.id) return;

    if (activeChat) this.chatInput.value = "";
    this.setState({ showGotoBottom: false });
    this.props.setActiveChat({ activeChatId: client.id });

    this.setState({ chatLoading: true }, () => {
      this.setState({ activeChat: client, chatLoading: false }, () => {
        this.chatInput.focus();
        this.scrollToBottom();

        var chatBox = document.querySelector(".chat-box");
        var observer = new MutationObserver(() => {
          this.scrollToBottom();
        });
        var config = { childList: true };
        observer.observe(chatBox, config);
        this.setScrollHeight();
      });
      this.hideNotification(client);
    });
  }

  handleScroll(e) {
    const { showGotoBottom, scrollHeight, clientHeight } = this.state;
    const scrollTop = this.chatBox.scrollTop;

    if (scrollHeight - scrollTop === clientHeight && showGotoBottom) {
      this.setState({ showGotoBottom: false });
    } else if (
      scrollHeight - scrollTop > clientHeight + 500 &&
      !showGotoBottom
    ) {
      this.setState({ showGotoBottom: true });
    }
  }

  setScrollHeight() {
    const scrollHeight = this.chatBox.scrollHeight;
    const clientHeight = this.chatBox.clientHeight;

    this.setState({ scrollHeight, clientHeight });
  }

  scrollToBottom(speed) {
    if (this.chatBox) this.chatBox.scrollTop = this.chatBox.scrollHeight;
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      const value = e.target.value;
      this.handleReply(value);
    }
  }

  handleReply(message) {
    if (isEmpty(message)) return;

    const { activeChat } = this.state;
    this.chatInput.focus();
    sendMessage(activeChat.user_id, message);
    this.chatInput.value = "";

    addResponse(activeChat.user_id, {
      text: message
    });
  }

  hideNotification(client) {
    if (!client) return;
    client.notifications = 0;
  }

  getDate(date) {
    const formattedDate = moment(date, "DD-MMMM-YYYY").calendar(null, {
      sameDay: "[Today]",
      lastDay: "[Yesterday]",
      lastWeek: "dddd",
      sameElse: "MMMM DD, YYYY"
    });

    return formattedDate;
  }

  getClients() {
    const { clients, activeChat } = this.state;
    const clientList = clients.length ? (
      clients.map((client, index) => {
        return (
          <div
            key={index}
            className={
              "contact " +
              (activeChat && activeChat.id === client.id ? "active" : "")
            }
            key={index}
            onClick={() => this.setActiveChat(client)}
          >
            <img className="dp" src={client.dp} alt="dp" />
            <span className="name">{client.name}</span>
            {client.notifications ? (
              <div className="notification">{client.notifications}</div>
            ) : (
                ""
              )}
          </div>
        );
      })
    ) : (
        <p className="chat-alert">No clients found.</p>
      );

    return clientList;
  }

  getMessages() {
    const { activeChat, chatLoading, showGotoBottom } = this.state;
    const userID = getUserId();
    const messageList = !chatLoading ? (
      <div className="chat-box-container">
        <div
          className="chat-box"
          id="chat-box"
          onScroll={this.handleScroll}
          ref={el => (this.chatBox = el)}
        >
          {activeChat && activeChat.messages.length ? (
            activeChat.messages.map((message, index) => {
              return (
                <React.Fragment key={index}>
                  {message.date &&
                    (index === 0 ||
                      (index > 0 &&
                        message.date !== activeChat.messages[index - 1].date)) ? (
                      <h6 className="date">{this.getDate(message.date)}</h6>
                    ) : (
                      ""
                    )}
                  <div
                    className={
                      "message " + (message.user === userID ? "right " : "left")
                    }
                  >
                    <div className="text-container">
                      <p className="text">
                        <span className="arrow"></span>
                        {message.text}
                      </p>
                      <span className="timestamp">
                        {message.pending ? (
                          <Tooltip title="Sending">
                            <QueryBuilderIcon className="status-icon"></QueryBuilderIcon>
                          </Tooltip>
                        ) : message.failed ? (
                          <Tooltip title="Failed to send">
                            <ErrorOutlineIcon className="status-icon failed"></ErrorOutlineIcon>
                          </Tooltip>
                        ) : (
                              <Tooltip title="Sent">
                                <CheckIcon className="status-icon"></CheckIcon>
                              </Tooltip>
                            )}
                        <span className="status-text">{message.time}</span>
                      </span>
                    </div>
                  </div>
                </React.Fragment>
              );
            })
          ) : (
              <p className="chat-alert">No messages found.</p>
            )}
        </div>
        {showGotoBottom ? (
          <div className="goto-bottom" onClick={() => this.scrollToBottom()}>
            <ExpandMoreIcon className="icon-arrow"></ExpandMoreIcon>
          </div>
        ) : (
            ""
          )}
      </div>
    ) : (
        ""
      );

    return messageList;
  }

  render() {
    const { activeChat, loadError } = this.state;

    return (
      <div className="chat">
        <div className="chat-container">
          <div className="contacts-container">
            <div className="contacts-header">
              <h6 className="heading">Clients</h6>
            </div>
            <div className="contacts">{this.getClients()}</div>
          </div>
          {activeChat ? (
            <div className="chat-window">
              <div className="chat-header">
                <h6 className="heading">{activeChat ? activeChat.name : ""}</h6>
              </div>
              {this.getMessages()}
              <div
                className={
                  "chat-footer " + (activeChat.disabled ? "disabled" : "")
                }
              >
                {activeChat.disabled ? (
                  <span className="chat-disabled-text">
                    You can't send messages to this client.
                  </span>
                ) : (
                    ""
                  )}
                <textarea
                  className="chat-input"
                  placeholder="Type something here..."
                  onKeyPress={this.handleKeyPress}
                  ref={el => (this.chatInput = el)}
                ></textarea>
                <div
                  className="send-button"
                  onClick={() => {
                    this.handleReply(this.chatInput.value);
                  }}
                >
                  <SendIcon className="send-icon"></SendIcon>
                </div>
              </div>
            </div>
          ) : loadError ? (
            <p className="chat-alert window-alert error">{loadError}</p>
          ) : (
                <p className="chat-alert window-alert">
                  Select a client to start messaging.
                </p>
              )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    messages: state.messages,
    activeChatId: state.activeChatId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value }),
    setActiveChat: value => dispatch({ type: "SET_ACTIVE_CHAT", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
