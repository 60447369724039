import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import AccessDenied from "components/Common/AccessDenied";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import Search from "components/Search/Search";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import AlertBox from "components/AlertBox/AlertBox";
import { ArrowDownward } from "@material-ui/icons";
import { fetchCountries, fetchCities } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";
import CityDropdown from "components/Common/CityDropdown";
import Packages from "pages/CustomPackageCreation/Packages";
import {
  fetchValeoPartners,
  fetchPartnersBloodPackages,
  fetchPartnersCustomPackages,
  fetchPartnerSupplements,
  fetchPartnerTreatmentPlans,
  fetchPartnerMobileBanners,
  fetchPartnerCoachConsultationPackages,
} from "services/ValeoPartnersService";
import DEWA_PARTNER_PACKAGES from "utils/DewaPackageTypes";
import {
  fetchBloodPackages,
  fetchCustomPackages,
} from "services/productService";
import DewaPackagesForm from "./DewaPackagesForm";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class DewaPartnerPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      partnerPackages: [],
      partner: {},
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isPartnerViewModalVisible: false,
      countries: [],
      selectedCountry: null,
      isPackageViewModalVisible: false,
      partnerPackageDetails: [],
      packages: [],
      packagesOptions: [],
      serviceType: "",
      partnerPackageName:""
    };
    this.columns = [
      {
        name: "Packages",
        selector: "packageName",
        // width:"100px",
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (item) => this.renderViewButton(item.packageName),
      },
    ];
  }

  componentDidMount() {
    // Promise.all([this.fetchAclUser()]);
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchValeoPartners();
  }

  componentWillUnmount() {
    this.hideLoading();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.DEWA_PARTNERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountries = async () => {
    try {
      this.showLoading();
      const countries = (await fetchCountries()) ?? [];
      const selectedCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching countries!" + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
      const dewaPackages =
        partners.length > 0 && partners.filter((it) => it.name == "Dewa");
      const dewaConfig = { ...dewaPackages[0] };
      // const {id:partnerId=""}=dewaConfig || {};
      // console.log("dewaConfig:", partnerId)
      const partnerPackages = DEWA_PARTNER_PACKAGES || [];
      this.setState({ partnerPackages, partner: dewaConfig });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleAddNewPartner = () => {
    this.setState({ isNewPartner: true }, () => this.showPartnerViewModal());
  };

  fetchBloodPackages = async () => {
    const { selectedCountry } = this.state;
    this.showLoading();
    try {
      const bloodPackages = await fetchBloodPackages();

      const bloodPackagesForSelectedCountry =
        bloodPackages && selectedCountry
          ? bloodPackages.filter((it) => it.country === selectedCountry.id)
          : [];

      console.log(
        "bloodPackagesbloodPackages",
        bloodPackagesForSelectedCountry
      );
      const activeBloodPackages =
        bloodPackagesForSelectedCountry.length > 0 &&
        bloodPackagesForSelectedCountry.filter((it) => it.is_active === true);
      const bloodPackagesOptions =
        activeBloodPackages.length > 0 &&
        activeBloodPackages.map((obj) => {
          return {
            ...obj,
            key: obj.subscription_name,
            value: obj.subscription_name,
            label: obj.subscription_name,
          };
        });

      this.setState({
        packagesOptions: bloodPackagesOptions,
        packages: bloodPackages,
      });
    } catch (error) {
      console.log(
        "Error in Fetching in Blood Biomarker Packages",
        error.message
      );
      this.showErrorNotification(
        "Error in Fetching in Blood Biomarker Packages",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchCustomPackages = async () => {
    const { selectedCountry } = this.state;
    this.showLoading();
    try {
      const customPackages = await fetchCustomPackages();

      const customPackagesForSelectedCountry =
        customPackages && selectedCountry
          ? customPackages.filter((it) => it.country === selectedCountry.id)
          : [];

      // console.log(
      //   "customPackagescustomPackages:",
      //   customPackagesForSelectedCountry
      // );

      const activeCustomPackages = customPackagesForSelectedCountry.filter(
        (it) => it.status === "Active"
      );
      const customPackagesOptions =
        activeCustomPackages.length > 0 &&
        activeCustomPackages.map((obj) => {
          return {
            ...obj,
            key: obj.name,
            value: obj.name,
            label: obj.name,
          };
        });
      // console.log("activeCustomPackages:", customPackagesOptions);
      this.setState({
        packagesOptions: customPackagesOptions,
        packages: customPackages,
      });
    } catch (error) {
      console.log("Error in Fetching in Custom Packages", error.message);
      this.showErrorNotification(
        "Error in Fetching in Custom Packages",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchTreatmentPlans = async () => {
    const { selectedCountry } = this.state;
    try {
      const { data: conditions = [] } = await PhoenixAPI.get("conditions/");
      const conditionsForSelectedCountry =
        conditions && selectedCountry
          ? conditions.filter((it) => it.country === selectedCountry.id)
          : [];

      const activeConditions = conditionsForSelectedCountry.filter(
        (it) => it.status === "Active"
      );
      const conditionsOptions =
        activeConditions.length > 0 &&
        activeConditions.map((obj) => {
          return {
            ...obj,
            key: obj.internalName,
            value: obj.internalName,
            label: obj.internalName,
          };
        });

      console.log("conditionsOptions", conditionsOptions);
      this.setState({
        packagesOptions: conditionsOptions,
        packages: conditions,
      });
      // console.log("conditionsconditions:", conditionsOptions);
    } catch (error) {
      console.log("Error in Fetching in conditions", error.message);
      this.showErrorNotification(
        "Error in Fetching in conditions",
        error.message
      );
    }
  };

  fetchSupplements = async () => {
    const { selectedCountry } = this.state;
    if (!selectedCountry.id) {
      return;
    }
    const url = `supplement/admin/v2/country/${selectedCountry.id}`;
    try {
      const { data: supplements = [] } = await PhoenixAPI.get(url);
      const supplementsForSelectedCountry =
        supplements && selectedCountry
          ? supplements.filter((it) => it.country === selectedCountry.id)
          : [];
      const activeSupplements = supplementsForSelectedCountry.filter(
        (it) => it.status === "Active"
      );
      const supplementOptions =
        activeSupplements.length > 0 &&
        activeSupplements.map((obj) => {
          return {
            ...obj,
            key: obj.internalName,
            value: obj.internalName,
            label: obj.internalName,
          };
        });
      this.setState({
        packagesOptions: supplementOptions,
        packages: supplements,
      });
      // console.log("supplements:", supplementOptions);
    } catch (error) {
      console.log("Error in Fetching in Supplments", error.message);
      this.showErrorNotification(
        "Error in Fetching in Supplements",
        error.message
      );
    }
  };

  fetchMobileBanners=async()=>{
    const {selectedCountry}= this.state;
    if(!selectedCountry.id){
      return
    }
    const url = `mobile-banner?countryId=${selectedCountry.id}`;
    try{
      const { data: banners = [] } = await PhoenixAPI.get(url);
      console.log("Banners:", banners)

      const bannerOptions=banners.map((obj)=>{
        return {
          ...obj,
          key:obj.name,
          value:obj.name,
          label:obj.name,
        }
      })
      this.setState({
        packagesOptions: bannerOptions,
        packages: banners,
      })
    }
    catch(error){
      console.log("Error in Fetching in Banner Options", error.message);
      this.showErrorNotification(
        "Error in Fetching in Banners",
        error.message
      );
    }
  }

  fetchCoachConsultationPackages=async()=>{
    const {selectedCountry}= this.state;
    if(!selectedCountry.id){
      return
    }
    const url=`coach-consultation-package?countryId=${selectedCountry.id}`;
    try{
      const { data: coachPackages = [] } = await PhoenixAPI.get(url);
      console.log("coachPackages:", coachPackages)
      const coachPackageOptions=coachPackages.map((obj)=>{
        return {
          ...obj,
          key:obj.externalName,
          value:obj.externalName,
          label:obj.externalName,
        }
      })
      this.setState({
        packagesOptions: coachPackageOptions,
        packages: coachPackages,
      })
    }
    catch(error){
      console.log("Error in Fetching in Banner Options", error.message);
      this.showErrorNotification(
        "Error in Fetching in Banners",
        error.message
      );
    }
  }

  handleViewPackage = async (name) => {
    const {
      selectedCountry = {},
      partner = {},
      packagesOptions = [],
    } = this.state;

    if(!partner.id){ 
      this.showErrorNotification("Please Add Valeo Partner")
      return;
    }
    const payload = { ...partner, countryId: selectedCountry.id };
    if (name === "Blood Biomarker Packages") {
      const { data: partnerPackages = [] } = await (fetchPartnersBloodPackages(
        payload
      ) ?? []);
      this.fetchBloodPackages();

      this.setState({
        partnerPackageDetails: partnerPackages,
        serviceType: "bloodPackage",
        partnerPackageName:name
      });
    } else if (name === "Custom Packages") {
      const { data: partnerPackages = [] } = await (fetchPartnersCustomPackages(
        payload
      ) ?? []);
      this.fetchCustomPackages();
      this.setState({
        partnerPackageDetails: partnerPackages,
        serviceType: "customPackage",
        partnerPackageName:name
      });
    } else if (name === "Treatment Plans") {
      const { data: partnerPackages = [] } = await (fetchPartnerTreatmentPlans(
        payload
      ) ?? []);
      this.fetchTreatmentPlans();
      this.setState({
        partnerPackageDetails: partnerPackages,
        serviceType: "treatmentPlan",
        partnerPackageName:name
      });
    } else if (name === "Supplements") {
      const { data: partnerPackages = [] } = await (fetchPartnerSupplements(
        payload
      ) ?? []);
      this.fetchSupplements();

      this.setState({
        partnerPackageDetails: partnerPackages,
        serviceType: "supplement",
        partnerPackageName:name
      });
    }
    else if (name === "Banners") {
      const { data: partnerPackages = [] } = await (fetchPartnerMobileBanners(
        payload
      ) ?? []);
      this.fetchMobileBanners();
      this.setState({
        partnerPackageDetails: partnerPackages,
        serviceType: "banners",
        partnerPackageName:name
      });
    }
    else if(name==="Coach Consultation Package"){
      const { data: coachPackages = [] } = await (fetchPartnerCoachConsultationPackages(
        payload
      ) ?? []);
      this.fetchCoachConsultationPackages();
      this.setState({
        partnerPackageDetails: coachPackages,
        serviceType: "coachConsultationPackages",
        partnerPackageName:name
      });
    }
    this.showPackageViewModal();
  };

  showPackageViewModal = () => {
    this.setState({ isPackageViewModalVisible: true });
  };

  hidePackageViewModal = () => {
    this.setState({ isPackageViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      partnerPackageDetails: [],
      packages: [],
      packagesOptions: [],
      serviceType: "",
      partnerPackageName:""
    });
  };

  renderViewButton = (name) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewPackage(name);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPartner ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredPartners = () => {
    const { partners } = this.state;
    return this.getSearchedOtherItems(partners);
  };

  handleChange = ({ currentTarget: input }) => {
    const partner = { ...this.state.partner };
    partner[input.name] = input.value;
    this.setState({ partner });
  };

  getSearchedOtherItems = (partners) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return partners;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return partners.filter((partner) => {
      const { id, name, domainEmail } = partner;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (domainEmail && domainEmail.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  getOptions = (partnerPackageDetails, packagesOptions) => {
    let updatedOptions = packagesOptions.filter(
      (itemA) =>
        !partnerPackageDetails.some((itemB) => itemB.itemId === itemA.id)
    );
    return updatedOptions;
  };

  render() {
    const {
      accessDenied,

      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      countries,
      selectedCountry,
      partnerPackages,
      packages,
      packagesOptions,
      partnerPackageDetails,
      partner,
      serviceType,
    } = this.state;

    // const updatedOptions=this.getOptions(partnerPackageDetails,packagesOptions)

    const addPackagesForm = (
      <div className="container mt-4">
        <DewaPackagesForm
          partnerPackageDetails={partnerPackageDetails ?? []}
          packages={packages}
          packagesOptions={packagesOptions ?? []}
          isEditable={isEditable}
          selectedCountry={selectedCountry}
          valeoPartner={partner}
          serviceType={serviceType}
        />
      </div>
    );
    if (accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries ?? []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={partnerPackages || []}
            columns={this.columns}
            defaultSortField="packageName"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + partnerPackages.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isPackageViewModalVisible}
          onHide={this.hidePackageViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>{`${this.state.partnerPackageName ?? ""}`}</span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            {/* <button
              onClick={this.handlesaveOtherItem}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button> */}
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_dewa_packages">
              <Tab
                eventKey="key_form_package_information"
                title={`Partner ${this.state.partnerPackageName ?? ""}`}
              >
                {addPackagesForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DewaPartnerPackages);
