import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

const OPTION_NONE = { key: -1, value: "NONE", label: "NONE" };

FAQCategoriesDropdown.propTypes = {
    FAQCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedFAQCategory: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
};

function FAQCategoriesDropdown(props) {
    const {
        FAQCategories = [],
        selectedFAQCategory,
        onSelect = (f) => f,
    } = props;

    const activeFAQCategories = FAQCategories.filter(
        (it) => it.is_active
    );

    activeFAQCategories.sort((first, second) =>
        compareStrings(first.name, second.name, true)
    );

    const inactiveFAQCategories = FAQCategories.filter(
        (it) => !it.is_active
    );

    inactiveFAQCategories.sort((first, second) =>
        compareStrings(first.name, second.name, true)
    );

    const options = [
        OPTION_NONE,
        {
            label: "Active",
            options: activeFAQCategories.map(createOption),
        },
        {
            label: "Inactive",
            options: inactiveFAQCategories.map(createOption),
        },
    ];
    const selectStyles = {
        container: (base) => ({
            ...base,
            flex: 1,
        }),
    };

    const FAQCategory =
        FAQCategories.find((it) => it.id === selectedFAQCategory);


    return (
        <Select
            key={"faq_categories" + FAQCategories.map(it => it.id).join()}
            value={FAQCategory ? createOption(FAQCategory) : OPTION_NONE}
            onChange={onSelect}
            options={options}
            styles={selectStyles}
            placeholder="Select FAQ Category..."
            isDisabled={props.disabled}
        />
    );
}

const createOption = (FAQCategory) => ({
    key: FAQCategory.id,
    value: FAQCategory.id,
    label: FAQCategory.category_name,
});

export default FAQCategoriesDropdown;