import React, { useEffect, useState } from "react";
import Order from "./Order";
import ClientLabDetails from "./ClientLabDetails";
import UploadSampleDetails from "./UploadSampleDetails";
import { connect } from "react-redux";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "../../utils/PhoenixAPI";
import { isAccessDenied } from "services/aclService";
import API from "utils/API";
import NursePanelTabs from "./NursePanelTabs";
import Notification from "./Notification";
import {trackSharingLivelocation} from "analytics";
import "./index.scss";

function HomecarePortal(props) {
  const [isShowClientLab, setIsShowClientLab] = useState(false);
  const [isShowUploadSample, setIsShowUploadSample] = useState(false);
  const [isShowOrder, setIsShowOrder] = useState(true);
  const [isCompletedOrder, setSsCompletedOrder] = useState(false);
  const [clientDetails, setClientDetails] = useState({});
  const [orders, setOrders] = useState([]);
  const [searchOrders, setSearchOrders] = useState([]);
  const [selectedTab, setSelectedTab] = useState("key_order_today");
  const [selectedMainTab, setSelectedMainTab] = useState("open_orders_form");
  const [user, setUser] = useState({});
  const [nurseLiveLocation,setNurseLiveLocation]=useState(null);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const userId = getUserId();
  useEffect(() => {
    fetchAclUser();
    fetchUser();
  }, []);

  useEffect(()=>{
    getLocationData()
  },[])

  const creatingCleverTapProfileUser=()=>{
   const userId= localStorage.getItem("userId");
   const email = localStorage.getItem("userEmail");
   return (window.clevertap.onUserLogin.push({
    "Site": {
      "Identity": userId,
      "Email": email,
      "MSG-email": false,
      "MSG-push": false,
      "MSG-sms": false,
      "MSG-whatsapp": false,
    },
    "cart_data": []
  }))
  }
  useEffect(()=>{
    creatingCleverTapProfileUser()
  },[])

  useEffect(()=>{
   const tabSelected= localStorage.getItem("selectedTab")
   if(tabSelected){
     setSelectedTab(tabSelected)
   }
   else{
    setSelectedTab("key_order_today")
   }
    
  },[selectedTab])
  function getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }

async function main() {
    try {
      const position = await getLocation();
      console.log("position", position)
      const currentLocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      return (currentLocation);
    } catch (error) {
      console.error(error);
    }
  }

const getLocationData = async()=>{
 
    const locationData = await main()
    setNurseLiveLocation(locationData)
    // locationData ? setIsLocation(true) : setIsLocation(false);
   
  }

  const fetchAclUser = async () => {
    const userId = getUserId();
    //     if (!userId) {
    //         console.log("fetchAclUser:: Invalid user id!", { userId });
    //         return;
    //     }
    //    console.log("userId", userId, Page.CLIENTS)
    //     try {
    //         // const pageName = Page.CLIENTS;
    //         const pageName = "Homecare Portal";
    //         const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
    //         console.log("pageName", pageName, aclUser)
    //         const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
    //         const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
    //         const accessDenied = isAccessDenied(aclUser, pageAccessRights);
    //         console.log("pagePermission", pagePermission, pageAccessRights, accessDenied)
    //         console.log("accessDenied", accessDenied)
    //         this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
    //             const isEditable = this.hasPageAccessRight("edit");
    //             this.setState({ isEditable });
    //         });
    //     } catch (error) {
    //         console.log("fetchAclUser:: Error on fetching acl user!", error);
    //     }
  };
  const fetchUser = async () => {
    try {
      const response = await API.get("/users/");
      const { data: users = [] } = response;

      setUser({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
      //  showErrorNotification("Error on fetching user!" + error.message);
    } finally {
    }
  };

  const shareLiveLocation=async()=>{
   
    const deviceId= sessionStorage.getItem("device_id")
    const userDetails= user.user ??{}
    const trackingData={
      "nurseName":userDetails?.username??"",
        "nurseUserId":userDetails?.id??"",
      "deviceId":deviceId
    }
    const userId= getUserId()
    if(!nurseLiveLocation){
      trackingData["shareSuccessful"]=false;
        trackSharingLivelocation(trackingData)
        setIsError(true)
        setMessage("Please enable your location from Settings")
        return;
    }
       const {lat,lng}= nurseLiveLocation || {}
       console.log("lat,lan", lat, lng)
       let url=`https://maps.google.com/maps?q=${lat},${lng}`
       try{
          const res= await PhoenixAPI.patch(`nurse/location/${userId}/true?liveLocation=${url}`)
          console.log("res", res)
          setIsError(false)
          setMessage("Location is shared successfully")
          trackingData["shareSuccessful"]=true;
          trackSharingLivelocation(trackingData)
          
       }
       catch(error){
        console.log("Error in saving live location", error.message)
        setIsError(true)
        setMessage("Error in sharing live location", error.message)
        trackingData["shareSuccessful"]=false;
        trackSharingLivelocation(trackingData)
       }
    }
  return (
    <>
      <div className="nurse-panel-main">
        <NursePanelTabs
          user={user}
          selectedMainTab={selectedMainTab}
          setSelectedMainTab={setSelectedMainTab}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          setOrders={setOrders}
          orders={orders}
          searchOrders={searchOrders}
          setSearchOrders={setSearchOrders}
          nurseUserId={userId}
        />
         {(selectedMainTab!=="key_manage_profile") && <div className="live-sharing-bar">
        <div className="click-to" onClick={()=>shareLiveLocation()}>Click to</div>
        <div className="share-live-txt">Share live location</div>
        </div>}
        {/* {isShowOrder ? <Order userId={userId} isShowClientLab={isShowClientLab} setClientDetails={setClientDetails} isCompletedOrder={isCompletedOrder} setIsShowClientLab={setIsShowClientLab} setIsShowUploadSample={setIsShowUploadSample} setIsShowOrder={setIsShowOrder} />:null} */}
        {isShowClientLab ? (
          <ClientLabDetails
            props={props}
            clientDetails={clientDetails}
            setIsShowClientLab={setIsShowClientLab}
            setIsShowUploadSample={setIsShowUploadSample}
            setIsShowOrder={setIsShowOrder}
            nurseUserId={userId}
          />
        ) : null}
        {isShowUploadSample ? (
          <UploadSampleDetails
            userId={userId}
            props={props}
            clientDetails={clientDetails}
            setSsCompletedOrder={setSsCompletedOrder}
            setIsShowClientLab={setIsShowClientLab}
            setIsShowUploadSample={setIsShowUploadSample}
            setIsShowOrder={setIsShowOrder}
          />
        ) : null}
         {message && <Notification setMessage={setMessage} message={message} isError={isError}  />}
      </div>
    </>
  );
}
const mapStateToProps = (state, ownProps) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomecarePortal);
