import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedArticleIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectArticles.defaultProps = {
  articles: [],
  selectedArticleIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectArticles(props) {
  const {
    articles,
    selectedArticleIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findArticleById = (id) => articles.find((it) => it.id === id);

  const options = createOptions(articles, showOnlyActive);

  const selectedArticles = selectedArticleIds
    .map(findArticleById)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedArticleIds && selectedArticleIds.join(",")
      }`}
      value={selectedArticles}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(articles, showOnlyActive) {
  if (!articles || !articles.length) return [];

  const [activeArticles, inactiveArticles] = _.partition(
    articles,
    (it) => it.status === "Active"
  );
  activeArticles.sort((first, second) =>
    compareStrings(first.articleNameEnglish, second.articleNameEnglish, true)
  );

  if (showOnlyActive) return activeArticles.map(createOption);

  inactiveArticles.sort((first, second) =>
    compareStrings(first.articleNameEnglish, second.articleNameEnglish, true)
  );
  return [
    {
      label: "Active",
      options: activeArticles.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveArticles.map(createOption),
    },
  ];
}

function createOption(article) {
  if (!article) return;
  return {
    key: article.id,
    value: article.id,
    label: article.articleNameEnglish,
  };
}

export default SelectArticles;
