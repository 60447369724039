import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_BUNDLE_MAPPING_COMBINATIONS="admin/get-all-suggestions";


export const fetchBundleMapping= async ()=>{
    try{
        const { data: bundleCartItems = [] } = await PhoenixAPI.get(
            BASE_URL_BUNDLE_MAPPING_COMBINATIONS
          );
          return bundleCartItems
    }
    catch(error){
        console.log("Error on fetching Mapped Question Combinations", error);
        throw error;
    }
}


const BundleMappingService = {
    fetchBundleMapping,
  };
  
  export default BundleMappingService;

