import React, { Component } from "react";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import { fetchMiniPackageBiomarkers } from "services/LongevityService";
import Select from "react-select";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import ActionBar from "react-bootstrap/Dropdown";
import Status from "components/Common/Status";
import SelectMiniPackages from "../../../components/Common/SelectMiniPackages";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class BiomarkerDetailsForm extends Component {
  constructor(props) {
    super(props);
    const {
      onBiomarkersUpdated = (f) => f,
      biomarkerOptions,
      parameterBiomarkers,
      biomarkers,
      selectedCountry,
      isNewParameter,
      longevityCategoryId,
    } = this.props;
    this.onBiomarkersUpdated = onBiomarkersUpdated;
    this.state = {
      parameterBiomarkers,
      biomarkerDetail: {},
      errors: {},
      editables: {},
      biomarkerOptions,
      biomarkers,
      selectedCountry,
      assosciateMiniPackages: [],
      assosciateMiniPackagesOptions: [],
      isNewParameter,
      sortColumn: "id",
      longevityCategoryId,
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        wrap: true,
      },
      {
        name: "Biomarker Name",
        selector: "biomarkerName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Units",
        selector: "biomarkerUnit",
        sortable: true,
        wrap: true,
      },
      {
        name: "Weightage %",
        selector: "weightage",
        sortable: true,
        wrap: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "isRequired",
        selector: "isRequired",
        sortable: true,
        wrap: true,
        cell: (biomarker) => {
          return (
            <>
              {biomarker.isRequired ? (
                <span className="badge badge-success pt-1">
                  <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                  True
                </span>
              ) : (
                <span className="badge badge-warning pt-1">
                  <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
                  False
                </span>
              )}
            </>
          );
        },
      },
      {
        name: "Actions",
        // maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (biomarker) => this.renderActionBar(biomarker),
      },
    ];
  }

  fetchAssociateMinpackages = async () => {
    const { biomarkerDetail, selectedCountry } = this.state;
    const { biomarkerId } = biomarkerDetail || {};
    const assosciateMiniPackages = await fetchMiniPackageBiomarkers(
      biomarkerId,
      selectedCountry.id
    );
    this.setState({ assosciateMiniPackages });
  };

  handleAddNewBiomarkerDetail = () => {
    const biomarkerDetail = { isActive: false };
    this.setState({ biomarkerDetail: {} }, () => this.showEditModal());
  };

  clearForm = () => {
    // Array.from(document.querySelectorAll("input")).forEach(
    //   (it) => (it.value = "")
    // );
    this.setState({
      errors: {},
      editables: {},
      biomarkerDetail: {},
    });
  };

  showEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  findBiomarker = (biomarkerId) => {
    const { biomarkers } = this.state;
    return biomarkers.find((it) => it.id === biomarkerId);
  };
  createBiomarkerOption = (biomarker) => {
    return {
      key: biomarker.biomarkerId,
      value: biomarker.biomarkerId,
      label: biomarker.biomarkerName,
    };
  };

  createMiniPackageBiomarker = (biomarker) => {
    return {
      key: biomarker.minipackageId,
      value: biomarker.minipackageId,
      label: biomarker.miniPackageName,
    };
  };

  handleBiomarkerChange = (biomarker) => {
    const { biomarkerOptions, biomarkerDetail, selectedCountry } = this.state;
    const selectedId = biomarkerOptions.find((it) => it.name === biomarker);
    this.setState(
      {
        biomarkerDetail: {
          ...biomarkerDetail,
          biomarkerId: selectedId.id,
          biomarkerName: selectedId.name,
          biomarkerUnit: selectedId.unit,
        },
      },
      () => this.fetchAssociateMinpackages()
    );
  };

  // handleMiniPackageChange=(miniPacakge)=>{
  //   const { assosciateMiniPackages, biomarkerDetail, selectedCountry } = this.state;

  //   const selectedIdMiniPackage = assosciateMiniPackages.find((it) => it.name === miniPacakge);
  // this.setState({
  //     biomarkerDetail: {
  //       ...biomarkerDetail,
  //       minipackageId: selectedIdMiniPackage.id,
  //       miniPackageName: selectedIdMiniPackage.name,
  //     },
  //   })

  // }

  handleChange = ({ currentTarget: input }) => {
    const biomarkerDetail = { ...this.state.biomarkerDetail };
    biomarkerDetail[input.name] = input.value;
    this.setState({ biomarkerDetail });
  };

  handleEdit = async (id) => {
    this.clearForm();

    const { parameterBiomarkers = [], selectedCountry } = this.state;
    const biomarkerDetail = parameterBiomarkers.find((it) => it.id === id);
    if (!biomarkerDetail) return;
    const { biomarkerId } = biomarkerDetail || {};
    const assosciateMiniPackages = await fetchMiniPackageBiomarkers(
      biomarkerId,
      selectedCountry.id
    );

    this.setState({ biomarkerDetail, assosciateMiniPackages }, () =>
      this.showEditModal()
    );
  };

  createBiomarker = () => {
    const {
      parameterBiomarkers = [],
      biomarkerDetail,
      longevityCategoryId,
    } = this.state;
    const ids =
      parameterBiomarkers.length > 0
        ? parameterBiomarkers.map((it) => it.id)
        : null;
    biomarkerDetail.id = ids ? Math.max(...ids) + 1 : 1;
    biomarkerDetail.longevityCategoryId = Number(longevityCategoryId);
    biomarkerDetail.is_new = true;
    this.setState(
      { parameterBiomarkers: [...parameterBiomarkers, biomarkerDetail] },
      () => {
        this.showSuccessNotification("Biomarker Created Successfully!");
        this.onBiomarkersUpdatedLocally();
      }
    );
  };

  updatedBiomarker = (id) => {
    const { parameterBiomarkers = [], biomarkerDetail } = this.state;
    const newBiomarkers = parameterBiomarkers.map((it) =>
      it.id === id ? biomarkerDetail : it
    );
    this.setState({ parameterBiomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Biomarker Updated Successfully!");
      this.onBiomarkersUpdatedLocally();
    });
  };

  onBiomarkersUpdatedLocally = () => {
    this.clearForm();
    this.onBiomarkersUpdated(
      this.state.parameterBiomarkers.map((biomarker) => {
        const q = { ...biomarker };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  validate=()=>{
    const { biomarkerDetail = {} } = this.state;
    const errors = {};
    console.log("check values",biomarkerDetail,errors);
     const{biomarkerName,weightage,normalScore,endNormalScore,optimalScore,improvementScore}=biomarkerDetail;
    if(biomarkerName == null || biomarkerName ==undefined){
      errors.biomarkerName="Biomarker is mandatory ";
     }
     if(weightage == null || weightage ==undefined){
      errors.weightage="weightage is mandatory ";
     }
     
     if(normalScore && (normalScore<0  || normalScore>1))
    {
      errors.normalScore="StartNormalScore range is 0-1 ";
    }
    else if(endNormalScore && (endNormalScore<0 || endNormalScore>1)){
      errors.normalScore="EndNormalScore range is 0-1 ";
    }
   else if(normalScore && endNormalScore && normalScore>endNormalScore){
    errors.normalScore="StartNormalScore  should  lessthan EndNormalScore";
    }

    if(optimalScore && (optimalScore<0 || optimalScore>1) ){
      errors.optimalScore="OptimalScore range is 0-1";
    }
    if(improvementScore && (improvementScore<0 || improvementScore>1)){
       errors.improvementScore="ImprovementScore range is 0-1";
    }
   
 return Object.keys(errors).length === 0 ? null : errors;;
  }
  handleSubmit = (event) => {
    event.preventDefault();
     const errors=this.validate();
     this.setState({errors: errors || {}});
     if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
       
       return;
     }
    const { biomarkerDetail = {} } = this.state;
    if (biomarkerDetail.id === null || biomarkerDetail.id === undefined)
      this.createBiomarker();
    else this.updatedBiomarker(biomarkerDetail.id);
    this.hideAddEditModal();
  };

  renderActionBar = (biomarker) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(biomarker.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.biomarkerDetail.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      parameterBiomarkers = [],
      biomarkerDetail = {},
      errors,
      editables,
      isNewParameter,
      sortColumn,
    } = this.state;
    const isNewBiomarkerDetail =
      biomarkerDetail.id === null || biomarkerDetail.id === undefined;
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const form = (
      <>
        <div className="row" style={{ padding: "12px" }}>
          <div className="form-group col-6">
            <label htmlFor="biomarker">Biomarker</label>
            <Select
              key={`my_unique_select_key__${
                biomarkerDetail && biomarkerDetail.id
              }`}
              value={
                biomarkerDetail && this.createBiomarkerOption(biomarkerDetail)
              }
              onChange={(event) => {
                this.handleBiomarkerChange(event.value);
              }}
              options={this.state.biomarkerOptions || []}
              style={selectStyles}
              placeholder="Select Biomarker"
              disabled={!isNewBiomarkerDetail && !editables.biomarkerName}
              searchable
              lazyLoad
            />
            <span className="help-block">{errors.biomarkerName}</span>
          </div>
          
          <div className="form-group col">
            <label htmlFor="biomarkerUnit">Unit</label>
            <div className="input-group">
              <input
                value={biomarkerDetail?.biomarkerUnit || ""}
                onChange={this.handleChange}
                id="biomarkerUnit"
                name="biomarkerUnit"
                type="text"
                placeholder="Unit"
                className={
                  "form-control py-2" + (errors.cityId ? " has-error" : "")
                }
                disabled={biomarkerDetail && biomarkerDetail.biomarkerUnit}
                // disabled={!isNewBiomarkerDetail && !editables.biomarkerUnit}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="weightage">Weightage %</label>
            <div className="input-group">
              <input
                value={biomarkerDetail?.weightage || ""}
                onChange={this.handleChange}
                id="cityName"
                name="weightage"
                type="text"
                placeholder="weightage"
                className={
                  "form-control py-2" + (errors.weightage ? " has-error" : "")
                }
                // disabled={!isNewBiomarkerDetail && !editables.weightage}
              />
            </div>
            <span className="help-block">{errors.weightage}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="required">Required</label>
            <div className="input-group">
              <input
                value={true}
                checked={(biomarkerDetail && biomarkerDetail.isRequired) || ""}
                onChange={(event, value) => {
                  this.setState({
                    biomarkerDetail: {
                      ...biomarkerDetail,
                      isRequired: event.target.checked,
                    },
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
              />
            </div>
          </div>
        </div>
        <div className="row" style={{ padding: "12px" }}>
          <div className="form-group col">
            <label htmlFor="biomarker">Assosciate MiniPackage</label>

            <SelectMiniPackages
              id="itemName"
              name="itemName"
              miniPackages={this.state.assosciateMiniPackages || []}
              selectedMiniPackageIds={biomarkerDetail.miniPackages || []}
              onSelect={(options) => {
                this.setState({
                  biomarkerDetail: {
                    ...biomarkerDetail,
                    miniPackages: options.map((it) => it.value),
                  },
                });
              }}
              showOnlyActive={true}
              placeholder="Select Assosciate MiniPackage"
              isDisabled={!isNewBiomarkerDetail && !editables.minipackages}
            />

            {this.renderPencilIcon("minipackages")}
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={biomarkerDetail?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    biomarkerDetail: {
                      ...biomarkerDetail,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewBiomarkerDetail && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="great">Optimal Range</label>
            <div className="input-group">
              <input
                value={biomarkerDetail && biomarkerDetail.startOptimalRange}
                onChange={this.handleChange}
                id="startOptimalRange"
                name="startOptimalRange"
                type="number"
                placeholder="From"
                className={"form-control"}
              />
              <div className="range-select">-</div>
              <input
                value={biomarkerDetail && biomarkerDetail.endOptimalRange}
                onChange={this.handleChange}
                id="endOptimalRange"
                name="endOptimalRange"
                type="number"
                placeholder="To"
                className={"form-control"}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="optimalScore"> Optimal Score</label>
            <div className="input-group">
              <input
                value={biomarkerDetail && biomarkerDetail.optimalScore}
                onChange={this.handleChange}
                id="optimalScore"
                name="optimalScore"
                type="number"
                placeholder="Enter Score"
                className={"form-control"}
                min={0}
                max={1}
              />
            </div>
            <span className="help-block">{errors.optimalScore}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="startFairScore">Normal Range</label>
            <div className="input-group">
              <input
                value={biomarkerDetail && biomarkerDetail.startNormalRange}
                onChange={this.handleChange}
                id="startNormalRange"
                name="startNormalRange"
                type="number"
                placeholder="From"
                className={"form-control"}
              />
              <div className="range-select">-</div>
              <input
                value={biomarkerDetail && biomarkerDetail.endNormalRange}
                onChange={this.handleChange}
                id="endNormalRange"
                name="endNormalRange"
                type="number"
                placeholder="To"
                className={"form-control"}
              />
            </div>
          </div>
          <div className="form-group col-4">
            <label htmlFor="normalScore">Normal Score</label>
            <div className="input-group">
              <input
                value={biomarkerDetail && biomarkerDetail.normalScore}
                onChange={this.handleChange}
                id="normalScore"
                name="normalScore"
                type="number"
                placeholder="From"
                className={"form-control"}
                min={0}
                max={1}
              />
              <div className="range-select">-</div>
              <input
                value={biomarkerDetail && biomarkerDetail.endNormalScore}
                onChange={this.handleChange}
                id="endNormalScore"
                name="endNormalScore"
                type="number"
                placeholder="To"
                min={0}
                max={1}
                className={"form-control"}
              />
              </div>
               <span className="help-block">{errors.normalScore}</span>

              </div>
              <div className="form-group col">
            <label htmlFor="InvertedRelationship">Inverted Relationship</label>
            <div className="input-group">
              <input
                value={true}
                checked={(biomarkerDetail && biomarkerDetail.isInverted) || ""}
                onChange={(event, value) => {
                  this.setState({
                    biomarkerDetail: {
                      ...biomarkerDetail,
                      isInverted: event.target.checked,
                    },
                  });
                }}
                id="invertedRelation"
                name="invertedRelation"
                type="checkbox"
              />
            </div>
            </div>
          


            </div>
        
        <div className="row">
          <div className="form-group col">
            <label htmlFor="greenRange">Need Improvement 1 (Low)</label>
            <div className="input-group">
              <input
                value={
                  biomarkerDetail && biomarkerDetail.startImprovementRange1
                }
                onChange={this.handleChange}
                id="startImprovementRange1"
                name="startImprovementRange1"
                type="number"
                placeholder="From"
                className={"form-control"}
              />
              <div className="range-select">-</div>
              <input
                value={biomarkerDetail && biomarkerDetail.endImprovementRange1}
                onChange={this.handleChange}
                id="endImprovementRange1"
                name="endImprovementRange1"
                type="number"
                placeholder="To"
                className={"form-control "}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="startFairScore">Need Improvement Score </label>
            <div className="input-group">
              <input
                value={biomarkerDetail && biomarkerDetail.improvementScore}
                onChange={this.handleChange}
                id="improvementScore"
                name="improvementScore"
                type="number"
                placeholder="Enter Score"
                className={"form-control"}
                min={0}
                max={1}
              />
            </div>
            <span className="help-block">{errors.improvementScore}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="greenRange">Need Improvement 2 (High)</label>
            <div className="input-group">
              <input
                value={
                  biomarkerDetail && biomarkerDetail.startImprovementRange2
                }
                onChange={this.handleChange}
                id="startImprovementRange2"
                name="startImprovementRange2"
                type="number"
                placeholder="From"
                className={"form-control"}
              />
              <div className="range-select">-</div>
              <input
                value={biomarkerDetail && biomarkerDetail.endImprovementRange2}
                onChange={this.handleChange}
                id="endImprovementRange2"
                name="endImprovementRange2"
                type="number"
                placeholder="To"
                className={"form-control "}
              />
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={parameterBiomarkers || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />

              <button
                onClick={this.handleAddNewBiomarkerDetail}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {biomarkerDetail.id === null || biomarkerDetail.id === undefined
                ? "Add Biomarker"
                : "Edit Biomarker"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {biomarkerDetail.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BiomarkerDetailsForm);
