import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectCities.propTypes = {
  cities: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCityIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  countryId: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectCities.defaultProps = {
  cities: [],
  selectedCityIds: [],
  countryId: null,
  onSelect: (f) => f,
  menuPlacement: "bottom",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectCities(props) {
  const {
    cities,
    selectedCityIds,
    countryId,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findCity = (id) => cities.find((it) => it.id === id);

  const options = createOptions(cities, countryId, showOnlyActive);

  const selectedCities = selectedCityIds.map(findCity).map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedCityIds && selectedCityIds.join(",")
      }`}
      value={selectedCities}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(cities, countryId, showOnlyActive) {
  if (!cities || !cities.length) return [];

  const cititesForCountry = countryId
    ? cities.filter((city) => city.country?.id === countryId)
    : cities;

  const [activeCities, inactiveCities] = _.partition(
    cititesForCountry,
    (city) => city.is_active ?? false
  );
  activeCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );

  if (showOnlyActive) return activeCities.map(createOption);

  inactiveCities.sort((first, second) =>
    compareStrings(first.city_name, second.city_name, true)
  );
  return [
    {
      label: "Active",
      options: activeCities.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCities.map(createOption),
    },
  ];
}

function createOption(city) {
  if (!city) return;
  return {
    key: city.id,
    value: city.id,
    label: city.city_name,
  };
}

export default SelectCities;
