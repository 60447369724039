import React ,{Component}from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import {fetchNewLongevityCategories} from "services/LongevityService";
const BASE_URL_COACH_PLANS="coach-view-longevity-score-page/user-id/";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class CoachSuggestedArticles extends Component {

    constructor(props) {
        super(props);
        const { suggestedDefaultArticles,isEditable,heading ,country,customerId,dependentId,articleOptions,longevityCategoryId,adminSuggestedArticles} = this.props;
        
        
        this.state = {
            suggestedDefaultArticles,
            suggestedDefaultArticle: {},
            articleOptions,
            errors: {},
            editables: {},
            isArticleViewModal: false,
            articles:[],
            isEditable,
            heading,
            customerId,
            dependentId,
            country,
            articles:[],
           adminSuggestedArticles,

        };
    
        this.columns = [
            {
              name: "ID",
              selector: "id",
              sortable: true,
              width: "50px",
              maxWidth: "100px",
            },
            {
              name: "Suggested Article Name",
              selector: "articleNameEnglish",
              sortable: true,
              wrap: true,
              format: (defaultPackage) => (
                <div className="text-overflow-ellipsis">
                  {defaultPackage.articleNameEnglish ?? ""}
                </div>
              ),
            },
            {
                name: "Status",
                selector: "status",
                sortable: true,
                center: true,
                maxWidth: "150px",
                cell: (defaultPackage) => <Status isActive={defaultPackage.isActive ?? false} />,
              },
              {
                name: "Edit",
                center: true,
                cell: (defaultPackage) => this.renderEditButton(defaultPackage.id),
              }
        
        
        ]

    }

    showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

    clearForm = () => {
        this.setState({
          errors: {},
          editables: {},
          suggestedDefaultArticle: {},
          
        });
      };
      showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });


      setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
      };

      
     

      componentDidUpdate(prevProps) {
        if (this.props.suggestedDefaultArticles !== prevProps.suggestedDefaultArticles) {
          this.setState({ suggestedDefaultArticles: this.props.suggestedDefaultArticles });
          
        }
        if(this.props.country !== prevProps.country){
          this.setState({country:this.props.country});
        }
        if(this.props.articleOptions !== prevProps.articleOptions){
          this.setState({articleOptions:this.props.articleOptions});
        }

  
      }

      fetchCoachSuggestedPlans=async()=>{
        const { customerId, country,dependentId ,heading,longevityCategoryId} = this.props;
        this.showLoading();
        try {
          const { data: AllDetails = {} } = await PhoenixAPI.get(
            BASE_URL_COACH_PLANS + customerId,{params:{countryId:country,longevityCategoryId:longevityCategoryId,dependentId:dependentId?dependentId:null}}
          );
          const{parameterScore,coachSuggestedSupplementPlanArticles,coachSuggestedSleepPlanArticles,
            coachSuggestedFoodPlanArticles,coachSuggestedFitnessPlanArticles,mealPlan,suggestedSupplement}=AllDetails;
        
          
          this.setState({
           suggestedDefaultArticles:heading=="Supplement"?coachSuggestedSupplementPlanArticles:
            heading=="Sleep"?coachSuggestedSleepPlanArticles:
           heading=="Food" ?coachSuggestedFoodPlanArticles:
          coachSuggestedFitnessPlanArticles
            
          });
    
          }
          catch (error) {
            this.showErrorNotification(
              "Error on fetching customer longevity score: ",
              error.message
            );
            this.setState({ loadError: "Some error has occured. Please try again" });
          } finally {
            this.hideLoading();
          }
    
    
      }

      
  

      handleAddNewArticle = () => {
        const{suggestedDefaultArticles,articleOptions,heading}=this.state;
        const{adminSuggestedArticles}=this.props;
        console.log("adminSuggestedArticles",adminSuggestedArticles);
        const filteredArticles = articleOptions.filter(article => 
          !suggestedDefaultArticles.some(suggestedArticle => suggestedArticle.articleId === article.value) && 
          !adminSuggestedArticles.some(suggestedArticle => suggestedArticle.articleId === article.value)
        );
        this.setState({ isNewDefaultArticle: true,articles:filteredArticles }, () => this.showArticleViewModal());
      };
      handleViewArticle= (packageId) => {
        const{suggestedDefaultArticles,articleOptions}=this.state;
        const{adminSuggestedArticles}=this.props;
        const defaultArticles = this.state.suggestedDefaultArticles ?? [];
         console.log("suggested default articles",suggestedDefaultArticles,articleOptions)
        const suggestedDefaultArticle= defaultArticles.find((it) => it.id === packageId);
        const filteredSuggestedDefaultArticles=suggestedDefaultArticles.filter((it) => it.id !== packageId)
       
        const filteredArticles = articleOptions.filter(article => !filteredSuggestedDefaultArticles.some(suggestedArticle => suggestedArticle.articleId === article.value) &&
        !adminSuggestedArticles.some(suggestedArticle => suggestedArticle.articleId === article.value) );
        console.log("suggested Default", suggestedDefaultArticle);
        if (!suggestedDefaultArticle) {
          console.log(`Article with id ${packageId} is not found!`);
          return;
        }
        this.setState(
          {
            suggestedDefaultArticle,
            isNewDefaultArticle: false,
            articles:filteredArticles,
          },
          () => this.showArticleViewModal()
        );
      };

      renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewDefaultArticle ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    renderEditButton = (id) => (
      <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => {
            this.handleViewArticle(id);
          }}
        >
          Edit
        </button>
      </div>
    );
    
      showArticleViewModal = () => {
        const {country, isNewDefaultArticle,dependentId ,customerId} = this.state;
       const coach =getUserId();
        if (isNewDefaultArticle) {
          const suggestedDefaultArticle= {
            countryId: country,
            isActive: false,
            userId:customerId,
            dependentId:dependentId,
           coachId:coach
          };
          this.setState({
            suggestedDefaultArticle,
            isArticleViewModal: true,
          });
        } else {
          this.setState({ isArticleViewModal: true });
        }
      };

      createSuggestedDefaultArticle=async(suggestedDefaultArticle)=>{
       const{heading,country}=this.state;
       let postUrl="";
       if(heading=="Food"){
        postUrl="longevity-coach-food-plan-articles";
        
       }
       else if(heading=="Fitness"){
        postUrl="longevity-coach-fitness-plan-articles";

       }
       else if(heading=="Supplement"){
        postUrl="longevity-coach-supplement-plan-articles";
       }
        else{
          postUrl="longevity-coach-sleep-plan-articles"
        } 
        const isValidId = suggestedDefaultArticle.id !== null && suggestedDefaultArticle.id !== undefined;
        
        console.log("article",suggestedDefaultArticle);
        isValidId? await PhoenixAPI.patch(`${postUrl}/${suggestedDefaultArticle.id}`, suggestedDefaultArticle)
        : await PhoenixAPI.post(postUrl, this.state.suggestedDefaultArticle);
       // this.fetchCoachSuggestedPlans();
       this.fetchCoachSuggestedPlans();
      }
      validate = () => {
        const {suggestedDefaultArticle}= this.state;
        const errors = {};
      
       const{articleId}=suggestedDefaultArticle;
    
         if (!articleId) errors.articleSuggested= "article cannot be blank";
         
    
        return Object.keys(errors).length === 0 ? null : errors;
      };
     
    

      handleSaveDefaultArticle = async () => {
        const {  suggestedDefaultArticle} = this.state;
        const errors = this.validate();
        this.setState({ errors: errors || {} });
        if (errors) {
          this.showErrorNotification("There are some invalid form fields!");
          return;
        }
        try {
          this.showLoading();
          const isValidId = suggestedDefaultArticle.id !== null && suggestedDefaultArticle.id !== undefined;
          const response = await this.createSuggestedDefaultArticle(suggestedDefaultArticle);
          this.hideArticleViewModal();
          this.showSuccessNotification(
            isValidId
              ? " Updated Successfully."
              : " Created Successfully."
          );
          
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching Longevity Categories:",
            error.message
          );
        } finally {
          this.hideLoading();
        }
      };
    
      resetForm = () => {
        this.setState({
          suggestedDefaultArticle: {},
          errors: {},
          editables: {},
        });
      };
    
      
    
      hideArticleViewModal = () => {
        this.setState({ isArticleViewModal: false });
        this.resetForm();
      };
    
      handleChange = ({ currentTarget: input }) => {
        const suggestedDefaultArticle = { ...this.state.suggestedDefaultArticle};
        suggestedDefaultArticle[input.name] = input.value;
        this.setState({ suggestedDefaultArticle });
      };

      
    
      

      render() {
    const{suggestedDefaultArticles,suggestedDefaultArticle={},articleOptions,articles,heading,isEditable,isNewDefaultArticle,wellbeingCoaches,errors,editables,customPackages}=this.state;
     console.log("default package",suggestedDefaultArticle,articleOptions);
    const packageForm=(<>
     <div className="container mt-4">
    <div className="row">
      <div className="form-group col-6">
        <label htmlFor="default package">suggested Article</label>
        <div className="input-group">
         <Dropdown 
         value={ suggestedDefaultArticle.articleId||null}
         onChange={(event, value) => {
           console.log("value",value);
            const suggestedDefaultArticle = { ...this.state.suggestedDefaultArticle };
            suggestedDefaultArticle.articleId= value.value;
            
            this.setState({ suggestedDefaultArticle});
            }
           
         }
         id={`articleSuggested`}
         name="articleSuggested"
         placeholder="None"
         search
         selection
         options={articles}
         disabled={!isNewDefaultArticle && !editables.articleSuggested}
         className={
           "form-control" + (errors.articleSuggested ? " has-error" : "")
         }
         
         />
        {this.renderPencilIcon("articleSuggested")}
        </div>
         
        <span className="help-block">{errors.articleSuggested}</span>
  
    </div>
    <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={suggestedDefaultArticle.isActive}
                onChange={(event, value) => {
                  this.setState({
                    suggestedDefaultArticle: {
                      ...suggestedDefaultArticle,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewDefaultArticle && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>


       
    </div>
        
    </div>     
      
      
                  
         
    </>

    );

        return(
            <div>
              <div className="page-header">
          <div className="actions">
              <div className="action-item">
              <button
                onClick={this.handleAddNewArticle}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Article
              </button>
            </div>
            </div>
            </div>
                <Card>
                    <Card.Header>Suggested {heading} Plan Articles</Card.Header>
                    <Card.Body>
                    <DataTable
                highlightOnHover
                columns={this.columns}
                data={suggestedDefaultArticles || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }/>
                 
                    </Card.Body>
                </Card>
                <Modal
          size="lg"
          show={this.state.isArticleViewModal}
          onHide={this.hideArticleViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  { suggestedDefaultArticle.id ? "Edit Plan" : "Add Plan"}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveDefaultArticle}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{packageForm}</Modal.Body>
        </Modal>

            </div>
        )
    }
}
      CoachSuggestedArticles.propTypes = {
        suggestedDefaultArticles: PropTypes.arrayOf(PropTypes.object).isRequired,
        articleOptions:PropTypes.arrayOf(PropTypes.object).isRequired,
        country:PropTypes.number.isRequired,
        isEditable: PropTypes.bool.isRequired,
      };
      
      CoachSuggestedArticles.defaultProps = {
        suggestedDefaultArticles: [],
        articleOptions:[],
        
        isEditable: true,
      };
      
      function mapStateToProps(state) {
        return {};
      }
      
      function mapDispatchToProps(dispatch) {
        return {
          showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
          toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
        };
      }
      
      export default connect(mapStateToProps, mapDispatchToProps)(CoachSuggestedArticles);
      



