import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import API from "utils/API";
import { getUserName } from "services/UserService";
import { formatDate } from "utils/commons";
import Search from "components/Search/Search";
import { Link } from "react-router-dom";
import { getUserId, getUserType } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import PhoenixAPI from "utils/PhoenixAPI";
import { fetchCountries } from "services/RegionService";
import CountryDropdown from "components/Common/CountryDropdown";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class CustomerLongevityScore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      customerLongevityScores: [],
      isLoaded: false,
      loadError: null,
      countries: [],
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
    };

    this.columns = [
      {
        name: "Client ID",
        selector: "userId",
        sortable: true,
        maxWidth: "150px",
      },
      { name: "Dependent ID",
        selector: "dependentId",
        sortable: true,
        wrap:true,
        format: (row) => row.dependentId?? "",

      },
      {
        name: "Username",
        selector: "userName",
        sortable: true,
        wrap: true,
        format: (row) => row.userName ?? "",
      },
      {
        name: "Email ID",
        selector: "email",
        sortable: true,
        wrap: true,
        format: (row) => row.email ?? "",
      },
      { name:"RelationShip",
        selector:"relation",
        wrap:true,
        format:(row)=>row.relation??"",

      },
      {
        name: "Longevity Score",
        selector: "longevityScore",
        center: true,
        sortable: true,
        wrap: true,
        format:(row)=>row.isExpired? row.longevityScore+" "+"(E)"  :row.longevityScore,
      },
      
      
      {
        name: "View",
        center: true,
        cell: (order) => this.renderViewButton(order),
      },
    ];
  }

  async componentDidMount() {
    await this.fetchAclUser();
    this.fetchCountries();
    this.fetchCustomerLongevityScores();
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOME;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };
  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  fetchCustomerLongevityScores = async () => {
    this.showLoading();
    let url=`/customer-longevity-score`;
    const userId = getUserId();
    const userType= getUserType();
    if(userType==="externalNutritionist"){
     url +=`?coachId=${userId}`;
    }  
    try {
        const {data:customerLongevityScores=[]} = await PhoenixAPI.get(
          url
        );
      
      this.setState({ customerLongevityScores });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching customer longevity scores: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (row) =>
    row && (
      <div>
      { row.dependentId ==null? (<Link
          className="btn px-4"
          style={{ backgroundColor: "#CEE741" }}
          to={`/dashboard/customer-longevity-score/${row.userId}/${row.countryId}/${row.longevityCategoryId}`}
          target="_blank"
        > View Detail
        </Link>)
          
          :(<Link
        className="btn px-4"
        style={{ backgroundColor: "#CEE741" }}
        to={`/dashboard/dependent-longevity-score/${row.userId}/${row.countryId}/${row.longevityCategoryId}/${row.dependentId}/`}
        target="_blank"
      >
          View Detail
        </Link>)}
      </div>
    );

  getFilteredCustomerLongevityScores = () => {
    const  customerLongevityScores  = this.getLongevityScoresForCurrentCountry();
    console.log("getSearchedCustomerLongevityScores", this.getSearchedCustomerLongevityScores(customerLongevityScores));
    return this.getSearchedCustomerLongevityScores(customerLongevityScores);
  };

  getLongevityScoresForCurrentCountry = () => {
    const { customerLongevityScores, selectedCountry } = this.state;
    return customerLongevityScores && selectedCountry
      ? customerLongevityScores.filter(
          (customerLongevityScore) => customerLongevityScore.countryId === selectedCountry.id
        )
      : [];
  };

  getSearchedCustomerLongevityScores = (customerLongevityScores) => {
    
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return customerLongevityScores;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return customerLongevityScores.filter((customerLongevityScore) => {
      const { id, userName, email, longevityScore, dependentId , userId} = customerLongevityScore;
      return (
        (id && id.toString()===newValue) ||
        (userName && userName.toLowerCase().indexOf(newValue) !== -1) ||
        (email && email.toLowerCase().indexOf(newValue) !== -1) ||
        (longevityScore && longevityScore.toString()===newValue) ||
        (dependentId && dependentId.toString()===newValue) ||
        (userId && userId.toString()===newValue)
      );
    });
  };

  render() {
    const { loadError, searchApplied, searchTerm,  selectedCountry, countries, customerLongevityScores} = this.state;
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    const finalList = this.getFilteredCustomerLongevityScores(customerLongevityScores);

    return (
      <div className="manage-package">
          <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                clearSearch={this.handleSearchClear}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            data={finalList || []}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerLongevityScore);
