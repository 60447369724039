import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import BioMarkForm from "./BioMarkForm";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

class ProblematicBiomarkerForm extends Component {
  constructor(props) {
    super(props);

    const {
      biomarkers = [],
      onBiomarkersUpdated = (f) => f,
      country,
      unit = "",
      problematicQuestionId = "",
      biomarkerId = "",
    } = this.props;

    this.onBiomarkersUpdated = onBiomarkersUpdated;

    this.state = {
      biomarkers,
      biomarker: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
      arrMaleBioMarkRange: [],
      arrFemaleBioMarkRange: [],
      unit: unit,
      country: country,
      problematicQuestionId: problematicQuestionId,
      biomarkerId: biomarkerId,
    };

    this.columns = [
      // {
      //   name: "",
      //   width: "50px",
      //   cell: () => <Drag className="drag-handle"></Drag>,
      // },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Answer (English)",
        selector: "answerEnglish",
        sortable: true,
        wrap: true,
        format: (biomarker) => (
          <div className="text-overflow-ellipsis">
            {biomarker.answerEnglish ?? ""}
          </div>
        ),
      },
      {
        name: "Answer (Arabic)",
        selector: "answerArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (biomarker) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {biomarker.answerArabic ?? ""}
          </div>
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (biomarker) => this.renderActionBar(biomarker),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    // Array.from(document.querySelectorAll("input")).forEach(
    //   (it) => (it.value = "")
    // );
    // Array.from(document.querySelectorAll("textarea")).forEach(
    //   (it) => (it.value = "")
    // );
    this.setState({
      errors: {},
      editables: {},
      biomarker: {},
      arrMaleBioMarkRange: [],
      arrFemaleBioMarkRange: [],
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const biomarker = { ...this.state.biomarker };
    biomarker[input.name] = input.value;
    this.setState({ biomarker });
  };

  handleEdit = (id) => {
    this.clearForm();
    const { biomarkers = [] } = this.state;
    const biomarker = biomarkers.find((it) => it.id === id);
    if (!biomarker) return;
    const { problematicBiomarkerStatus = [] } = biomarker || {};
    let arrMaleBioMarkRange =
      problematicBiomarkerStatus.length > 0 &&
      problematicBiomarkerStatus.filter((value) => value.gender === "M");
    let arrFemaleBioMarkRange =
      problematicBiomarkerStatus.length > 0 &&
      problematicBiomarkerStatus.filter((value) => value.gender === "F");

    this.setState(
      {
        biomarker,
        arrMaleBioMarkRange,
        arrFemaleBioMarkRange,
      },
      () => this.showAddEditModal()
    );
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteQuestion(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { biomarker = {} } = this.state;
    // if (biomarker.id === null || biomarker.id === undefined)
    this.createAnswer(biomarker.problematicBiomarkerAnswerId);
    // else this.updateAnswer(biomarker.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};

    const { arrMaleBioMarkRange, arrFemaleBioMarkRange } = this.state;

    if (!arrMaleBioMarkRange.length) {
      errors.maleMessage = "Markers are required.";
    }
    if (!arrFemaleBioMarkRange.length) {
      errors.femaleMessage = "Markers are required.";
    }
    // if (!status) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createAnswer = async (id) => {
    const {
      biomarkers = [],
      biomarker,
      arrMaleBioMarkRange,
      arrFemaleBioMarkRange,
      biomarkerId,
    } = this.state;
    const ids = biomarkers.length > 0 ? biomarkers.map((it) => it.id) : null;
    // biomarker.id = ids ? Math.max(...ids) + 1 : 1;

    // biomarker.is_new = true;

    let biomarker_status =
      arrMaleBioMarkRange.length > 0 && arrMaleBioMarkRange;

    biomarker_status =
      arrFemaleBioMarkRange.length &&
      biomarker_status.concat(arrFemaleBioMarkRange);

    biomarker.problematicBiomarkerStatus = biomarker_status;

    const newBiomarkers = biomarkers.map((item) => ({
      ...item,
      problematicBiomarkerStatus:
        item.id === biomarker.problematicBiomarkerAnswerId
          ? biomarker_status
          : item.problematicBiomarkerStatus ?? [],
    }));

    this.setState({ biomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Biomarker Ranges Saved Successfully!");
      this.onBiomarkerUpdatedLocally();
    });
  };

  updateAnswer = (id) => {
    const {
      biomarkers = [],
      biomarker,
      arrMaleBioMarkRange,
      arrFemaleBioMarkRange,
    } = this.state;
    let biomarker_status = arrMaleBioMarkRange;

    biomarker_status = biomarker_status.concat(arrFemaleBioMarkRange);
    biomarker.problematicBiomarkerStatus = biomarker_status;
    const newBiomarkers = biomarkers.map((it) =>
      it.id === id ? biomarker : it
    );
    this.setState({ biomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Biomarker Answer Updated Successfully!");
      this.onBiomarkerUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { biomarkers = [] } = this.state;
    const newBiomarkers = biomarkers.map((it) =>
      it.id === id
        ? { ...it, isActive: it.isActive === true ? false : true }
        : it
    );
    this.setState({ biomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Answer Updated Successfully!");
      this.onBiomarkerUpdatedLocally();
    });
  };

  deleteQuestion = (id) => {
    const { biomarkers = [] } = this.state;
    const biomarkerToDelete = biomarkers.find((it) => it.id === id) || {};
    const newBiomarkers = biomarkers
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > biomarkerToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ biomarkers: newBiomarkers }, () => {
      this.showSuccessNotification("Answer Deleted Successfully!");
      this.onBiomarkerUpdatedLocally();
    });
  };

  setBioMark = (arrBioMark, strGender) => {
    const {
      problematicBiomarkerQuestionId = "",
      biomarker = {},
      biomarkerId = "",
    } = this.state;
    const questionId = biomarker.problematicBiomarkerQuestionId ?? "";
    const answerId = biomarker.problematicBiomarkerAnswerId ?? "";

    const newArrBioMark = arrBioMark.map((item) => ({
      ...item,
      problematicBiomarkerQuestionId: questionId,
      problematicBiomarkerAnswerId: answerId,
      biomarkerId: biomarkerId,
    }));
    if (strGender === "M") {
      this.setState({
        arrMaleBioMarkRange: newArrBioMark,
      });
    } else {
      if (strGender === "F") {
        this.setState({
          arrFemaleBioMarkRange: newArrBioMark,
        });
      }
    }

    console.log(
      this.state.arrFemaleBioMarkRange,
      this.state.arrMaleBioMarkRange
    );
  };

  onBiomarkerUpdatedLocally = () => {
    this.clearForm();
    this.onBiomarkersUpdated(
      this.state.biomarkers.map((biomarker) => {
        const q = { ...biomarker };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewRanges = (answerId) => {
    const { problematicQuestionId, biomarkers } = this.state;
    const biomarker = biomarkers.find((it) => it.id === answerId);

    biomarker.problematicBiomarkerAnswerId = answerId;
    const arrMaleBioMarkRange = biomarker.problematicBiomarkerStatus.filter(
      (it) => it.gender === "M"
    );
    const arrFemaleBioMarkRange = biomarker.problematicBiomarkerStatus.filter(
      (it) => it.gender === "F"
    );

    this.setState(
      { biomarker, arrMaleBioMarkRange, arrFemaleBioMarkRange },
      () => this.showAddEditModal()
    );
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (biomarker) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleAddNewRanges(biomarker.id)}>
          <EditIcon className="icon-small" />
          Edit Ranges
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.biomarker.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      biomarkers = [],
      biomarker = {},
      errors,
      editables,
      sortColumn,
      arrMaleBioMarkRange,
      arrFemaleBioMarkRange,
      unit,
      country,
    } = this.state;

    const isNewBiomarker = biomarker.id === null || biomarker.id === undefined;

    const form = (
      <>
        <div className="manage-bio-markers">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className={
                  " bio-markers-box " +
                  (errors.maleMessage ? "marker-error" : "")
                }
              >
                <h6>Markers for Male</h6>
                <BioMarkForm
                  key={biomarker.id + "M"}
                  gender={"M"}
                  arrBioMark={this.state.arrMaleBioMarkRange}
                  setBioMark={(arrBioMark) => this.setBioMark(arrBioMark, "M")}
                  unit={unit || ""}
                  country={this.state.country}
                  viewMode={false}
                ></BioMarkForm>
              </div>
              <span className="help-block">{errors.maleMessage}</span>
            </div>
            {
              <div className="col-lg-6 col-md-6">
                <div
                  className={
                    " bio-markers-box " +
                    (errors.femaleMessage ? "marker-error" : "")
                  }
                >
                  <h6>Markers for Female</h6>
                  <BioMarkForm
                    key={biomarker.id + "F"}
                    gender={"F"}
                    arrBioMark={this.state.arrFemaleBioMarkRange}
                    setBioMark={(arrBioMark) =>
                      this.setBioMark(arrBioMark, "F")
                    }
                    unit={unit || ""}
                    viewMode={false}
                    country={this.state.country}
                  ></BioMarkForm>
                </div>
                <span className="help-block">{errors.femaleMessage}</span>
              </div>
            }
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={biomarkers || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              {/* <button
                  onClick={this.handleAddNewQuestion}
                  disabled={!this.props.isEditable}
                  className="btn btn-success mt-1"
                >
                  Add New Answer
                </button> */}
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {biomarker.id === null || biomarker.id === undefined
                ? "Add Ranges"
                : "Edit Ranges"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {biomarker.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove biomarker</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the biomarker?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteQuestion(this.state.biomarkerToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblematicBiomarkerForm);
