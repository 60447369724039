import React from 'react'
import { useState, useEffect } from "react"
import PhoenixAPI from "utils/PhoenixAPI";
import "./logs.scss"

// const handleFetchLogs = async(pageId,pageType,setLogsData)=>{
//     console.log("hello world it is new function",pageId)
//     const url = `content-logs?pageId=${pageId}&pageType=${pageType}`;
//     const response = await PhoenixAPI.get(url);
//     const data = await response.data;
//     const sortedData = data.sort((a, b) => {
//         const dateA = new Date(a.createdAt);
//         const dateB = new Date(b.createdAt);
//         return dateB- dateA;
//     });
//     setLogsData(sortedData);
// }

const Logs = (props)=>{
    // const {logsData} = props
    const {pageId,pageType} = props;
    const [logsData,setLogsData] = useState([]);

    useEffect(()=>{
        handleFetchLogs(pageId,pageType)
    },[pageId])

    const handleFetchLogs = async(pageId,pageType)=>{
        // console.log("hello world it is new function",pageId)
        const url = `content-logs?pageId=${pageId}&pageType=${pageType}`;
        try{
            const response = await PhoenixAPI.get(url);
            const data = await response.data;
            const sortedData = data.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return dateB- dateA;
            });
            setLogsData(sortedData);
            // console.log("this is response of handleFetchLogs",data);
        }
        catch(error){
            console.log(error)
        }
    }

    return(
        <>
            <div style={{margin : '12px 0px', width: '100%', overflowX:'auto'}} >
                <table className="log-table" >
                    <thead>
                        <tr className="log-table-tr" >
                        <th className="log-table-th" >Date</th>
                        <th className="log-table-th" >By whom</th>
                        <th className="log-table-th" >Status</th>
                        <th className='log-table-th' >What</th>
                        <th className='log-table-th' >City</th>
                        <th className="log-table-th" >From</th>
                        <th className="log-table-th" >To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logsData.map((elem,index)=>{
                        const originalDateString = elem.createdAt;
                        // Convert the string to a Date object
                        const dateObject = new Date(originalDateString);
                        // Format the date to "YYYY-MM-DD HH:MM"

                        // const formattedDateString = dateObject.toISOString().slice(0, 16).replace('T', ' ');
                        const formattedDateString = dateObject.toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: undefined,
                        }).replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1');

                        return <tr key={index} className="log-table-tr" >
                            <td className="log-table-td" >{formattedDateString}</td>
                            <td className="log-table-td" >{elem.createdByName}</td>
                            <td className="log-table-td" >{elem.type}</td>
                            <td className='log-table-td' >{elem.contentName}</td>
                            <td className='log-table-td' >{elem.cityName}</td>
                            <td className="log-table-td" >{elem.previousValue}</td>
                            <td className="log-table-td" >{elem.newValue}</td>
                        </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default Logs
// export {handleFetchLogs}