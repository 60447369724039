import React, { Component } from "react";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import PhoenixAPI from "utils/PhoenixAPI";
import {
  saveQuestion,
  updateQuestion,
} from "../../services/ProblematicQuestionService";
import ProblematicAnswersForm from "./ProblematicAnswersForm";

const BASE_URL_QUESTIONS = "/problematic-biomarker-questions-answers";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: "false",
    text: "Inactive",
  },
];

const SUBJECT_OPTIONS = [
  {
    key: "Ovulation",
    value: "Ovulation",
    text: "Ovulation",
  },
  {
    key: "Menstrual Cycle",
    value: "Menstrual Cycle",
    text: "Menstrual Cycle",
  },
  {
    key: "Pregnancy",
    value: "Pregnancy",
    text: "Pregnancy",
  },
  {
    key: "Heriditary Disease",
    value: "Heriditary Disease",
    text: "Heriditary Disease",
  },
];

const GENDER_OPTIONS = [
  {
    key: "M",
    text: "Male",
    value: "M",
  },
  {
    key: "F",
    text: "Female",
    value: "F",
  },
];

class ProblematicQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      question: {},
      searchTerm: null,
      searchApplied: false,
      isQuestionViewModalVisible: false,
      errors: {},
      editables: {},
      loadError: "",
      isEditable: this.props.isEditable,
    };
    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Question English",
        selector: "questionEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Question Arabic",
        selector: "questionArabic",
        wrap: true,
        sortable: true,
      },
      {
        name: "Subject",
        selector: "subject",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (p) => <Status isActive={p.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "Actions",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchQuestions();
  }

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  fetchQuestions = async () => {
    PhoenixAPI.get(`${BASE_URL_QUESTIONS}`)
      .then((response) => {
        const questions = response.data || [];
        this.setState({ questions });
      })
      .catch((error) => {
        console.log("Error on fetching problematic questions", error.message);
        this.showErrorNotification(
          "Some error has occured. Please try again.",
          error.message
        );
      });
  };

  resetForm = () => {
    this.setState({
      question: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewQuestion(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  handleViewQuestion = (questionId) => {
    const questions = this.state.questions ?? [];
    const question = questions.find((it) => it.id === questionId);
    if (!question) {
      console.log(`Problematic Question with ID ${questionId} is not found!`);
      return;
    }

    this.setState(
      {
        question,
        isNewQuestion: false,
      },
      () => this.showQuestionViewModal()
    );
  };

  handleSaveQuestion = async () => {
    const { question } = this.state;
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    try {
      this.showLoading();
      const isValidId = question.id !== null && question.id !== undefined;
      const response = isValidId
        ? await updateQuestion(question)
        : await saveQuestion(question);

      this.hideQuestionViewModal();
      console.log("Response", response);
      this.showSuccessNotification(
        isValidId
          ? "Problematic Question Updated Successfully."
          : " Problematic Question Created Successfully."
      );
      this.fetchQuestions();
    } catch (error) {
      console.log("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { question = {} } = this.state;

    const errors = {};

    const {
      questionEnglish,
      questionArabic,
      subject,
      gender,
      isBiomarkerGenderSegregation,
    } = question;

    if (!questionEnglish || questionEnglish.trim() === "")
      errors.questionEnglish = "Question (English) cannot be blank";
    else if (questionEnglish && questionEnglish.length > 200)
      errors.questionEnglish =
        "Question (English) is limited to a 200 characters";

    if (!questionArabic || questionArabic.trim() === "")
      errors.questionArabic = "Quetsion (Arabic) cannot be blank";
    else if (questionArabic && questionArabic.length > 200)
      errors.questionArabic =
        "Question (Arabic) is limited to a 200 characters";

    if (subject == null || undefined)
      errors.subject = "Subject is a required field!";

    if (isBiomarkerGenderSegregation) {
      if (gender == null || undefined) {
        errors.gender = "Gender is a required field!";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewQuestion = () => {
    this.setState({ isNewQuestion: true, isActive: false }, () =>
      this.showQuestionViewModal()
    );
  };

  showQuestionViewModal = () => {
    const { isNewQuestion } = this.state;

    if (isNewQuestion) {
      const question = {
        status: false,
      };
      this.setState({
        question,
        isQuestionViewModalVisible: true,
      });
    } else {
      this.setState({
        isQuestionViewModalVisible: true,
      });
    }
  };

  hideQuestionViewModal = () => {
    this.setState({ isQuestionViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => {
    const isEditable = this.props.isEditable ?? true;
    return isEditable && !this.state.isNewQuestion ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  handleAnswersUpdated = (answers = []) => {
  
    const question = {
      ...this.state.question,
      problematicBiomarkerAnswers: answers,
    };
    this.setState({ question });
  };

  handleChange = ({ currentTarget: input }) => {
    const question = { ...this.state.question };
    question[input.name] = input.value;
    this.setState({ question });
  };

  handleSearch = (queryString) => {
    this.setState({
      searchApplied: true,
      searchTerm: queryString ? queryString : "",
    });
  };

  handleSearchClear = () => {
    this.setState({
      searchTerm: "",
      searchApplied: false,
    });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({
        searchTerm: "",
        searchApplied: false,
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredQuestions = (questions) => {
    // const banners = this.getBannersForCurrentCountry();
    return this.getSearchedQuestions(questions);
  };

  getBannersForCurrentCountry = () => {
    const { banners, selectedCountry } = this.state;
    return banners && selectedCountry
      ? banners.filter((banner) => banner.country === selectedCountry.id)
      : [];
  };

  getSearchedQuestions = (questions) => {
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm) return questions;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return questions.filter((question) => {
      const {
        id,
        questionEnglish,
        questionArabic,
        subject,
      } = question;
      return (
        (questionEnglish && questionEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (questionArabic &&
          questionArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (subject &&
          subject.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) 
      );
    });
  };

  render() {
    const {
      questions,
      searchTerm,
      question,
      searchApplied,
      countries,
      selectedCountry,
      errors,
      editables,
      loadError,
      isNewQuestion,
    } = this.state;

    const isEditable = this.props.isEditable ?? true;
    const filteredQuestions = this.getFilteredQuestions(questions) ?? [];

    const questionsForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="bannerName">Question (English) *</label>
            <div className="input-group">
              <input
                value={question?.questionEnglish || ""}
                onChange={this.handleChange}
                id="questionEnglish"
                name="questionEnglish"
                type="text"
                placeholder="Enter Question in English"
                className={
                  "form-control py-2" +
                  (errors.questionEnglish ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.questionEnglish}
              />
              {this.renderPencilIcon("questionEnglish")}
            </div>
            <span className="help-block">{errors.questionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="questionArabic">Question (Arabic) *</label>
            <div className="input-group">
              <input
                value={question?.questionArabic || ""}
                onChange={this.handleChange}
                id="questionArabic"
                name="questionArabic"
                type="text"
                placeholder="Enter Question in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.questionArabic ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.questionArabic}
              />
              {this.renderPencilIcon("questionArabic")}
            </div>
            <span className="help-block">{errors.questionArabic}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Subject *</label>
            <div className="input-group">
              <Dropdown
                value={question?.subject}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      subject: value.value,
                    },
                  });
                }}
                id="subject"
                name="subject"
                placeholder="Select Subject"
                search
                selection
                options={SUBJECT_OPTIONS}
                disabled={!isNewQuestion && !editables.subject}
                className={
                  "form-control" + (errors.subject ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("subject")}
            </div>
            <span className="help-block">{errors.subject}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={question?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <div className="form-check custom-control custom-checkbox ml-2">
              <input
                value={true}
                checked={question.isBiomarkerGenderSegregation ?? false}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      isBiomarkerGenderSegregation: event.target.checked,
                    },
                  });
                }}
                id="isBiomarkerGenderSegregation"
                name="isBiomarkerGenderSegregation"
                type="checkbox"
                className="custom-control-input"
                // disabled={!isEditable}
              />
              <label
                className="custom-control-label"
                htmlFor="isBiomarkerGenderSegregation"
              >
                Does this biomarker require Gender segregation ?
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {question.isBiomarkerGenderSegregation ? (
            <div className="form-group col-6">
              <label htmlFor="status">Gender *</label>
              <div className="input-group">
                <Dropdown
                  value={question?.gender}
                  onChange={(event, value) => {
                    this.setState({
                      question: {
                        ...question,
                        gender: value.value,
                      },
                    });
                  }}
                  id="gender"
                  name="gender"
                  placeholder="Select Subject"
                  search
                  selection
                  options={GENDER_OPTIONS}
                  disabled={!isNewQuestion && !editables.gender}
                  className={
                    "form-control" + (errors.gender ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("gender")}
              </div>
              <span className="help-block">{errors.gender}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="form-group col">
            <div className="form-check custom-control custom-checkbox ml-2">
              <input
                value={true}
                checked={(question && question.isShowEveryOrder) || ""}
                onChange={(event, value) => {
                  this.setState({
                    question: {
                      ...question,
                      isShowEveryOrder: event.target.checked,
                    },
                  });
                }}
                id="isShowEveryOrder"
                name="isShowEveryOrder"
                type="checkbox"
                className="custom-control-input"
                // disabled={!isEditable}
              />
              <label
                className="custom-control-label"
                htmlFor="isShowEveryOrder"
              >
                should this question pop-up with every order ?
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    const answersForm = (
      <div className="container mt-4">
        <ProblematicAnswersForm
          answers={question.problematicBiomarkerAnswers}
          onAnswersUpdated={(answers) => this.handleAnswersUpdated(answers)}
          isEditable={isEditable}
        />
      </div>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm || ""}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              />
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewQuestion}
                // disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>

        <Card body>
          <h4 className="p-head">Problematic Question and Answers</h4>
          <DataTable
            data={filteredQuestions ?? []}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            // pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}
            title={
              this.state.searchApplied ? (
                <p className="table-header">
                  {"Found " + questions.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isQuestionViewModalVisible}
          onHide={this.hideQuestionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Question: </span>
                {question?.questionEnglish || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveQuestion}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_problematic_form" justify>
              <Tab eventKey="key_details_form" title="Questions">
                {questionsForm}
              </Tab>
              <Tab eventKey="key_answers_form" title="Answers">
                {answersForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProblematicQuestions);
