import React from "react";
import PropTypes from "prop-types";
import { bookingStatusList, findBookingStatusByValue, bookingStatusUpdatedlist } from "./bookingStatus";

SelectBookingStatus.propTypes = {
  id: PropTypes.string.isRequired,
  selectedStatus: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectBookingStatus.defaultProps = {
  id: "select_booking_status",
  selectedStatus: null,
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectBookingStatus(props) {
  const { id, selectedStatus, onSelect, isDisabled, orderItemType, isRequiredSampleCollection } = props;
  const selectedStatusObject = findBookingStatusByValue(selectedStatus);

  const titleOption = (
    <option key={-1} value={-1} hidden={true}>
      Select Status
    </option>
  );
  let bookingStatusOptions;
  if(orderItemType==="Custom_Package" && !isRequiredSampleCollection){
    bookingStatusOptions = bookingStatusUpdatedlist.map((it) => (
      <option
        key={it.value}
        value={it.value}
        disabled={
          selectedStatusObject
            ? it.position < selectedStatusObject.position
            : false
        }
      >
        {it.name}
      </option>
    ));
  }
  else{
    bookingStatusOptions = bookingStatusList.map((it) => (
      <option
        key={it.value}
        value={it.value}
        disabled={
          selectedStatusObject
            ? it.position < selectedStatusObject.position
            : false
        }
      >
        {it.name}
      </option>
    ));
  }
  

  return (
    <select
      value={selectedStatus ?? ""}
      onChange={(event) => onSelect(event.target.value)}
      id={id}
      disabled={isDisabled}
      className="form-control"
    >
      {!selectedStatus && titleOption}
      {bookingStatusOptions}
    </select>
  );
}

export default SelectBookingStatus;
