import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import API from "utils/API";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import Select from "react-select";
import PackageLabCost from "./PackageLabCost";
import * as XLSX from "xlsx";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const PackageListOptions = [
 
  {
    Key: "Blood Package",
    value: 1,
    label: "Blood Package",
  },
  {
    Key: "Custom Package",
    value: 2,
    label: "Custom Package",
  },
  {
    Key: "Mini Package",
    value: 3,
    label: "Mini Package",
  },
];

class LabCosts extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      labs: [],

      lab: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isLabViewViewModalVisible: false,
      allLabCosts: {},
      selectedItems: [],
      uploadedData: [],
      packageType:"",

    };

    this.columns = [
      {
        name: "Id",
        selector: "id",
        wrap: true,
        sortable: true,
      },
      {
        name: "Lab",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (lab) => <Status isActive={lab?.is_active ?? false} />,
        sortable: true,
      },
      {
        name: "Add Cost",
        center: true,
       
        minWidth: "170px",
        cell: (lab) => this.renderViewButton(lab.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchLabs();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.LAB_COSTS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchLabs = async () => {
    this.showLoading();
    try {
      const { data: labs = [] } = (await API.get("create-laboratory/")) || [];
      this.setState({ labs });
    } catch (error) {
      this.showErrorNotification("Error on fetching labs: ", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      this.setState({ countyData: countries }, () =>
        this.createCountryOptionsList()
      );
    } catch (error) {
      console.log("Error in fetchCountries", error.message);
    }
  };

  createCountryOptionsList = () => {
    const { countyData: countries = [] } = this.state;
    const [activeCountries, inactiveCountries] = _.partition(
      countries,
      (country) => country.is_active
    );
    const compareByName = (firstCountry = {}, secondCountry = {}) =>
      compareStrings(
        firstCountry.county_name,
        secondCountry.country_name,
        true
      );
    activeCountries.sort(compareByName);
    inactiveCountries.sort(compareByName);

    const countryOptionsList = [];
    activeCountries.length > 0 &&
      countryOptionsList.push({
        label: "Active",
        options: activeCountries.map(this.createCountryOption),
      });
    inactiveCountries.length > 0 &&
      countryOptionsList.push({
        label: "Inactive",
        options: inactiveCountries.map(this.createCountryOption),
      });

    this.setState({ countryOptionsList });
  };

  createCountryOption = (country) =>
    country && {
      key: country.id,
      value: country.id,
      label: country.country_name,
    };

  createPackageOption = (lab) => {
    return {
      key: lab.value,
      value: lab.value,
      label: lab.label,
    };
  };

  handleFetchAllLabCost = async (fromHide=false) => {
    const lab = this.state.lab;
    console.log("lab: handleFetchAllLabCost", fromHide);
    if (lab.country) {
      try {
        const { data: allLabCosts = {} } = await PhoenixAPI.get(
          `all-lab-cost?labId=${lab.id}&countryId=${lab.country}`
        );
        if(fromHide){
          this.setState({ allLabCosts }, ()=>this.findSelectedPackage(lab.packageId));
        }
        else{
          this.setState({ allLabCosts });
        }
        
      } catch (error) {
        console.log("Error in fetching all lab costs", error.message);
      }
    }
  };

  handleCountyChange = (selectedCountryId) => {
    const lab = this.state.lab;
    const selectedCountry=this.findCountry(selectedCountryId);
    this.handleReset()
    this.setState({ lab: { ...lab, country: selectedCountryId, packageId: null, selectedPackageCosts:[],packageType:"" }, selectedCountry , selectedItems:[], packageType:""}, () =>
      this.handleFetchAllLabCost()
    );
  };

  handlePackageChange = (packageId) => {
    const lab = this.state.lab;
    const selectedCountry = this.state.selectedCountry;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country !");
      return;
    }
    this.setState({ lab: { ...lab, packageId: packageId }, selectedItems:[]}, () =>
      this.findSelectedPackage(packageId)
    );
  };
  mapBloodPackageCosts=(bloodPackageCosts)=>{
    return  bloodPackageCosts.map(item => ({
      ...item,
      packageType: "Blood_Package"
    }));
  }

  mapCustomPackageCosts=(customPackageCosts)=>{
    return  customPackageCosts.map(item => ({
      ...item,
      packageType: "Custom_Package"
    }));
  }

  mapMiniPackageCosts=(miniPackageCosts)=>{
    return  miniPackageCosts.map(item => ({
      ...item,
      packageType: "Mini_Package"
    }));
  }
  findSelectedPackage = (packageId) => {
    if(!packageId){
      console.log("Please select a package")
      return;
    }
    const selectedPackage = PackageListOptions.find(
      (item) => item.value === packageId
    );
    const allLabCosts = this.state.allLabCosts ?? {};
    const {bloodPackageCosts=[], miniPackageCosts=[],customPackageCosts=[]}= allLabCosts ??{}
    const lab = this.state.lab;
    if (selectedPackage.label === "Blood Package") {
      this.setState({
        lab: {
          ...lab,
          selectedPackageCosts: bloodPackageCosts.length>0 ?this.mapBloodPackageCosts(bloodPackageCosts):[],
          packageType: "Blood_Package",
        },
        packageType:"Blood_Package"
      });
    } else if (selectedPackage.label === "Mini Package") {
      this.setState({
        lab: {
          ...lab,
          selectedPackageCosts: miniPackageCosts.length>0 ?this.mapMiniPackageCosts(miniPackageCosts):[],
          packageType: "Mini_Package",
        },
        packageType:"Mini_Package"
      });
    } else if (selectedPackage.label === "Custom Package") {
      this.setState({
        lab: {
          ...lab,
          selectedPackageCosts: customPackageCosts.length>0?this.mapCustomPackageCosts(customPackageCosts):[],
          packageType: "Custom_Package",
        },
        packageType: "Custom_Package",
      });
    }
  };

  findCountry = (id) => {
    const { countyData: countries = [] } = this.state;
    return countries.find((country) => country.id === id);
  };

  findPackage = (id) => {
    return PackageListOptions.find((item) => item.value === id);
  };

 

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };



  handleViewLab = (labId) => {
    const labs = this.state.labs ?? [];
    const lab = labs.find((it) => it.id === labId);
    if (!lab) {
      console.log(`Lab with id ${labId} is not found!`);
      return;
    }
    this.setState({ lab }, () => this.showLabViewModal());
  };

  showLabViewModal = () => {
    const { selectedCountry, isNewOtherItem } = this.state;

    this.setState({ isLabViewModalVisible: true });
  };

  hideLabViewModal = () => {
    this.setState({ isLabViewModalVisible: false });
    this.resetForm();
  };

 

  isInBetween = (number, start, end) => number >= start && number <= end;

  resetForm = () => {
    this.setState({
      lab: {},
      errors: {},
      editables: {},
      selectedCountry:null,
      allLabCosts:{},
      packageType:""
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewLab(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewOtherItem ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );



  getFilteredOtherItems = () => {
    const { labs } = this.state;
    return this.getSearchedOtherItems(labs);
  };



  getSearchedOtherItems = (labs) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return labs;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return labs.filter((lab) => {
      const { id, name } = lab;
      return (
        (name && name.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  mappingPackages = (packages) => {
    const mappingCosts = packages.map((packages) => {
      const item = {
        uuid: packages.uuid,
        packageId: packages.packageId,
        PackageName: packages.packageName,
        PackageType:packages.packageType,
        Status: packages.status,
        Cost: packages.cost,
        UpdatedCosts: "",
        StartDate: "",
      };
      return item;
    });
    return mappingCosts;
  };
  downloadLabCostFile = () => {
    const { selectedPackageCosts = [], packageType="" } = this.state.lab;
    const {selectedCountry=null, allLabCosts=null}= this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country !");
      return;
    }
    else if(!packageType){
      this.showErrorNotification("Please select package !");
      return; 
    }
    
    const selectedItems = this.state.selectedItems;
    if (selectedItems.length > 0) {
      const mappedPackages = this.mappingPackages(selectedItems);
      if (mappedPackages.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedPackages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");

        this.showSuccessNotification("File is Downloaded successfully");
      } else {
        return;
      }
    } else if (selectedPackageCosts.length > 0) {
      const mappedPackages = this.mappingPackages(selectedPackageCosts);
      if (mappedPackages.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedPackages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");

        this.showSuccessNotification("File is Downloaded successfully");
      } else {
        return;
      }
    }
  };

  UploadLabCostFile = async () => {
    const { uploadedData, lab ,selectedCountry=null, packageType=""} = this.state;
    console.log("Uploaded ", uploadedData, lab);
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    else if(!packageType){
      this.showErrorNotification("Please select package !");
      return; 
    }
    else if(uploadedData && uploadedData.length===0){
      this.showErrorNotification("Please choose File to upload!");
      return;
    }
   
    const filtereduploadedData=uploadedData   && uploadedData.length>0?uploadedData.filter((item)=>(item.updatedCosts !=="") &&(item.startDate !=="")):[];
    console.log("filtereduploadedDatafiltereduploadedData", filtereduploadedData);
    if(filtereduploadedData && filtereduploadedData.length===0){
      this.showErrorNotification("Please update costs and start date for packages!");
      return;
    }
    const mappedFormattedData =
    filtereduploadedData.length > 0 &&
    filtereduploadedData.map((item) => {
        const sample = {
          packageId: parseInt(item.packageId),
          cost: parseInt(item.updatedCosts),
          fromDate: item.startDate,
          packageType: lab.packageType,
        };
        return sample;
      });
    console.log("Mapped ", mappedFormattedData);
    const createdBy = getUserId();

    const payload = {
      labId: lab.id,
      countryId: lab.country,
      createdById: parseInt(createdBy),
      labCosts: mappedFormattedData,
    };

    try {
      const response = await PhoenixAPI.post("lab-cost-save-update", payload);
      this.showSuccessNotification("Costs saved successfully")
      this.hideLabViewModal()
    } catch (error) {
      console.log("Error in Saving Package Cost", error.message);
      this.showErrorNotification("Error in Saving Package Cost")
    }
  };

  handleHideAll = () => {
    this.handleFetchAllLabCost(true)
  };
  
  handleReset = () => {
    // Reset the file input value
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
    }
  };
  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
   
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const formattedData = jsonData.map((packages) => ({
        uuId: packages.uuId,
        packageId: packages.packageId,
        packageName: packages.PackageName,
        cost: packages.Cost,
        updatedCosts: packages.UpdatedCosts || "", // assuming UpdatedCosts is in the Excel sheet
        startDate: packages.StartDate
          ? this.formatDate(packages.StartDate)
          : "", // Convert Excel date to proper date stri
      }));
      this.setState({ uploadedData: formattedData });
    };
    reader.readAsArrayBuffer(file);
  };

  formatDate = (dateString) => {
    const jsDate = new Date(dateString);
    const day = String(jsDate.getDate()).padStart(2, "0");
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const year = jsDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  handleAllSelectedItems = (selectedItems = []) => {
    this.setState({ selectedItems: selectedItems });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      labs,
      lab,

      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredLabs = this.getFilteredOtherItems(labs) ?? [];
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const labPackageForm = (
      <div className="container mt-4">
        <div className="row">
          <div
            className=""
            style={{ marginLeft: "200px", display: "flex", gap: "20px" }}
          >
            <button
              onClick={(e) => this.downloadLabCostFile()}
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#0CA789", color: "white" }}
            >
              Download
            </button>

            <div className="" style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={(e) => this.UploadLabCostFile()}
                id="show_orders"
                className="btn px-2"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Upload File
              </button>
              <input
                type="file"
                accept=".xlsx, .xls"
                placeholder="Upload File"
                onChange={(e) => this.handleFileUpload(e)}
                ref={this.fileInputRef}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <div>Select Country</div>
            <div className="">
              <Select
                key={`country_select${lab.country}`}
                value={
                  lab.country
                    ? this.createCountryOption(this.findCountry(lab.country))
                    : ""
                }
                onChange={(event) => this.handleCountyChange(event.value)}
                options={this.state.countryOptionsList || []}
                styles={selectStyles}
                placeholder="Select Country"
              />

              <span className="help-block">{errors.countryMessage}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <div>Select Package Type</div>
            <div className="">
              <Select
                key={`country_select${lab.packageId}`}
                value={
                  lab.packageId
                    ? this.createPackageOption(this.findPackage(lab.packageId))
                    : ""
                }
                onChange={(event) => this.handlePackageChange(event.value)}
                options={PackageListOptions || []}
                styles={selectStyles}
                placeholder="Select Package Type"
              />

              <span className="help-block">{errors.packageMessage}</span>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <PackageLabCost
            selectedPackageCosts={lab?.selectedPackageCosts ?? []}
            packageType={lab?.packageType ?? ""}
            handleHideAll={() => this.handleHideAll()}
            handleAllSelected={this.handleAllSelectedItems}
          />
        </div>
      </div>
    );
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredLabs || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredLabs.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isLabViewModalVisible}
          onHide={this.hideLabViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <div className="user-title">Lab Name: {lab?.name ?? ""}</div>
          </Modal.Header>
          <Modal.Body>{labPackageForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LabCosts);
