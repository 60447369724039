
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function EditCustomerNotes(props) {
  const { customerNotes: notes, title, isVisible, onHide, onSave } = props;
  const [customerNotes, setCustomerNotes] = useState(notes);

  useEffect(() => setCustomerNotes(notes), [notes, isVisible]);

  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>{title}</p>
        </Modal.Title>
        <div className="user-title"></div>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="row">
            <div className="form-group col">
              <textarea
                value={customerNotes ?? ""}
                onChange={(event) => setCustomerNotes(event.target.value)}
                id="customerNotes"
                name="customerNotes"
                placeholder="Customer Notes"
                className="form-control"
              ></textarea>
            </div>
          </div>
          <div className="row">
            <div className="form-group col text-right">
              <button
                onClick={onHide}
                type="button"
                className="btn btn-outline-secondary"
              >
                Cancel
              </button>
              <button
                onClick={(event) => onSave(customerNotes)}
                type="button"
                className="btn btn-outline-success ml-2"
              >
                Save
              </button>
            </div>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
}

EditCustomerNotes.propTypes = {
  customerNotes: PropTypes.string,
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

EditCustomerNotes.defaultProps = {
  customerNotes: null,
  title: "",
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

export default EditCustomerNotes;
