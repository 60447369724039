import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { getUserEmail, getUserId, getUserName } from "services/UserService";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Notification from "../../pages/HomecareMwebPortal/Notification";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import "./homecareTicketManagement.scss";
import "../../pages/HomecareMwebPortal/OrderCards/orderCards.scss";
const REQUEST_OPTIONS = [
  {
    key: "Open",
    value: "open",
    label: "Open",
  },
  {
    key: "In Progress",
    value: "in progress",
    label: "In Progress",
  },
  {
    key: "Resolved",
    value: "resolved",
    label: "Resolved",
  },
];
export default function HomecareTicketMangement(props) {
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homecareUser, setHomecareUser] = useState("");
  const [homecareEmail, setHomecareEmail] = useState("");
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [tickets, setTickets] = useState([]);
  const [homecareDetails, setHomecareDetails] = useState({})

  const handleBackButton = () => {
    return (window.location.href = "/dashboard/homecare-mobile-portal");
  };

  const getHomeCareTickets = async () => {
    const { ticketCategoryOption } = inputs ?? {};

    const params = {};
    const homeCareId = getUserId();
    const homecareDetails = await getHomeCareDetails();
    console.log("homecareDetailshomecareDetails", homecareDetails)
    if (ticketCategoryOption) {
      params.ticketStatus = ticketCategoryOption.value ?? "";
    }
    try {
      setIsLoading(true);
      const { data: tickets = [] } = await PhoenixAPI.get(
        `homecare/all-tickets/${homecareDetails.homecare_id}`,
        { params }
      );
      setTickets(tickets);
    } catch (error) {
      console.log("Error in fetching tickets", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const getHomeCareDetails=async()=>{
    try{
     const  {data:homecareUsers=[]}= await PhoenixAPI.get("homecare-details");
     const userId = getUserId();
     const homecareDetails=homecareUsers.find((item)=>item.homecare_userid==userId) 
     setHomecareDetails(homecareDetails)
     return homecareDetails
     console.log("Response: homecareUsers", homecareDetails)
    }
    catch(error){
      console.log("Error:", error.message)
    }
  }

 
  useEffect(() => {
    getHomeCareTickets(); 
  }, [inputs]);



  const handleCardClick = (ticket) => {
    return (window.location.href = `/dashboard/homecare-mobile-portal/ticket-management/${ticket.id}`);
  };
  const getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };
  const renderTickets = (tickets) => {
    return (
      <>
        {tickets && tickets.length > 0 ? (
          tickets.map((ticket, index) => {
            return (
              <div className="clients-card" key={index}>
                <div>
                  <div>
                    Ticket Id :{" "}
                    <span style={{ color: "#2185d0" }}>
                      {ticket.ticket_uuid ?? ""}
                    </span>
                  </div>
                  {(ticket.ticket_status==="Open" || ticket.ticket_status==="Reopen") &&  <div className="ticket-status-bar">Status : <span className="openStatus">{ticket.ticket_status}</span></div>}
          {ticket.ticket_status==="In Progress" &&  <div className="ticket-status-bar">Status : <span className="inProgressStatus">{ticket.ticket_status}</span></div>}
          {ticket.ticket_status==="Resolved" &&  <div className="ticket-status-bar">Status : <span className="resolvedStatus">{ticket.ticket_status}</span></div>}
                </div>

                <div>
                  Subject :{" "}
                  <span style={{ color: "#F77077" }}>{ticket.title ?? ""}</span>
                </div>

                <div>
                  Created At : {getFormattedPurchasedDate(ticket?.createdAt)}
                </div>

                <div
                  className="view-btn"
                  onClick={() => handleCardClick(ticket)}
                >
                  {" "}
                  View{" "}
                </div>
              </div>
            );
          })
        ) : (
          <></>
        )}
      </>
    );
  };
  return (
    <div>
      {window.innerWidth < 768 && (
        <div className="top-header">
          <div className="header-content">
            <div className="content" onClick={() => handleBackButton()}>
              <div className="lt">&lt;</div>
              <div className="header-title"> Ticketing Management</div>
            </div>
          </div>
        </div>
      )}
      <div></div>
      <div className="ticket-request-option">
        <Select
          value={inputs?.ticketCategoryOption ?? null}
          placeholder="Select a category"
          search
          selection
          className={"ticketCategoryOption"}
          onChange={(selectedOption) => {
            setInputs((prev) =>
              setInputs({ ...prev, ticketCategoryOption: selectedOption })
            );
          }}
          options={REQUEST_OPTIONS}
        />
        {error.ticketCategoryOption && (
          <div className="error">{error.ticketCategoryOption}</div>
        )}
      </div>
      <div className="clients-wrapper-nurse-panel">
        {renderTickets(tickets)}
      </div>
      {message && (
        <Notification
          setMessage={setMessage}
          message={message}
          isError={isError}
        />
      )}
      <CustomLoader show={isLoading} />
    </div>
  );
}
