import React, { useState } from "react";
import { formatDate } from "utils/commons";
import EditOrderNotes from "./EditOrderNotes";
import PhoenixAPI from "utils/PhoenixAPI";
import { connect } from "react-redux";
function TransactionInformation(props) {
  const { transactionDetails,updateCODMamoPayementStatus } = props;
  const [isTransactionInfoVisible, setTransactionInfoVisible] = useState(true);
  const {
  
    paymentMethod,
    transactionDate,
    serviceReference,
    valeoRequestId,
    merchantRequestId,
    paymentDescription,
    paymentStatusDescription,
    paymentIP,
    transactionId,
    orderId,
    orderStatus,
    isDraftOrder,
    mamopayLink
  } = transactionDetails ?? {};
  console.log("transaction details: paymentMethod",paymentMethod, transactionDetails )
   const[isEditMamoPayServiceReference,setEditMamoPayServiceReference]=useState(false);
  const onEditCODMamoPayServiceReference=()=>{
    setEditMamoPayServiceReference(true)
  }
  const[codMamoPayServiceReference,setcodMamoPayServiceReference]=useState(serviceReference);
 

  const handleHideEditCODMamoPayServiceViewModal = () =>{

    setEditMamoPayServiceReference ( false);
   // setcodMamoPayServiceReference( null);
     
    }
    const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);


    const handleSaveCODMamopayService=async(codMamoPayReference)=>{
      if(codMamoPayReference !=="" && codMamoPayReference !==null && codMamoPayReference !==undefined )
      {
      setcodMamoPayServiceReference(codMamoPayReference);
      const url=`payment/update/${transactionId}`;
      const orderUrl=`admin/cart-orders/${orderId}`
    // serviceReference=codMamoPayReference;
      const payload={"transactionReference":codMamoPayReference};

   try{
     const response=await PhoenixAPI.patch(url, payload);
     
     handleHideEditCODMamoPayServiceViewModal();

     showSuccessNotification("COD_MAMOPAY service Reference saved");
  
    updateCODMamoPayementStatus();

   }
     catch (error) {
       console.log("error on saving", error);
       showErrorNotification("Error on saving COD_MAMOPAY Reference");
       
     }
    }
    else{
      handleHideEditCODMamoPayServiceViewModal();
    }
    }
  

  const transactionDetailsTable = (<>
    <table className="table table-responsive table-borderless">
      <tbody>
        <tr>
          <td>Transaction Date</td>
          <td>{transactionDate ? formatDate(transactionDate) : ""}</td>
        </tr>
        <tr>
          <td>Service Reference</td>
          <td>{(paymentMethod=="COD_MAMOPAY"  || (isDraftOrder) && paymentMethod=="COD" )?<> {codMamoPayServiceReference} {orderStatus !=="COMPLETED"?<span
                onClick={onEditCODMamoPayServiceReference}
                style={{ cursor: "pointer", color: "#2D9BF0" }}
              >
                Edit
              </span>:<></>}</>:serviceReference}</td>
        </tr>
        <tr>
          <td>Valeo Request ID</td>
          <td>{valeoRequestId ?? ""}</td>
        </tr>
        <tr>
          <td>Merchant Request ID</td>
          <td>{merchantRequestId ?? ""}</td>
        </tr>
        <tr>
          <td>Payment Description</td>
          <td>{paymentDescription ?? ""}</td>
        </tr>
        <tr>
          <td>Payment Status Description</td>
          <td>{paymentStatusDescription ?? ""}</td>
        </tr>
        <tr>
          <td>Payment IP</td>
          <td>{paymentIP ?? ""}</td>
        </tr>
        <tr>
          <td>Mamopay Payment URL</td>
          <td>{mamopayLink?? ""}</td>
        </tr>
      </tbody>
    </table>
    <EditOrderNotes
          title={"Add Service Reference"}
          orderNotes={codMamoPayServiceReference}
          onHide={handleHideEditCODMamoPayServiceViewModal}
          onSave={handleSaveCODMamopayService}
          isVisible={isEditMamoPayServiceReference}
        />
 </> );

  return (
    <div className="container px-0">
      <div className="row">
        <div className="col">
          <p className="h6">Transaction Information</p>
        </div>
        <div className="col text-right">
          <i
            onClick={() => setTransactionInfoVisible((prevValue) => !prevValue)}
            className={`caret square ${
              isTransactionInfoVisible ? "up" : "down"
            } icon big`}
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>
      {isTransactionInfoVisible && (
        <div className="row">
          <div className="col">
            {transactionDetails
              ? transactionDetailsTable
              : "Transaction information not available!"}
          </div>
        </div>
      )}
    </div>
  );
}


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransactionInformation);
