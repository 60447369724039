import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import AlertBox from "components/AlertBox/AlertBox";
import API from "utils/API";
import { getUserName } from "services/UserService";
import { formatDate } from "utils/commons";
import Search from "components/Search/Search";
import { Link } from "react-router-dom";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_ORDERS = "api/v2/order-nutritionist/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const ORDER_MAPPER = (order) =>({
  id: order.id,
  user: { full_name: order.user.full_name,email:order.user.email },
  order_package: order.order_package,
  created_at: order.created_at,
  is_result_uploaded: order.is_result_uploaded,
  is_reviewed: order.is_reviewed,
  country: order.country,
  dependent_id: order.dependent_id,
  member: order.member,
  is_family_member_order:order.is_family_member_order,
});

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      orders: [],
      isLoaded: false,
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
    };

    this.columns = [
      {
        name: "Order No.",
        selector: "id",
        sortField: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Coach Review Date",
        selector: "coach_review_date",
        sortField: 'coach_review_date',
        sortable: true,
        wrap: true,
        minWidth: "150px",
        format: (row) => row.coach_review_date ? formatDate(row.coach_review_date) : "-",
      },
      {
        name: "Primary User Name",
        selector: "user.full_name",
        wrap: true,
        width:"130px",
        format: (row) => this.getCustomerName(row),
      },
      {
        name: "Primary User Email",
        selector: "user.email",
        wrap: true,
        format: (row) => this.getCustomerEmail(row),
      },
      {
        name: "Dependent Name",
        selector: "user.email",
        wrap: true,
        format: (row) => this.getCustomerDependentName(row),
      },
      {
        name: "Dependent Relation",
        selector: "member.relation",
        wrap: true,
        format: (row) => this.getDependentRelation(row),
      },
      {
        name: "Package Name",
        selector: "order_package",
        sortField: "order_package",
        // sortable: true,
        wrap: true,
      },
      {
        name: "Purchased Date",
        selector: "created_at",
        wrap: true,
        sortField: "created_at",
        sortable: true,
        format: (row) => (row.created_at ? formatDate(row.created_at) : "-"),
      },
      {
        name: "Days Since Purchased",
        selector: "created_at",
        wrap: true,
        sortField: "created_at",
        sortable: true,
        format: (row) => {
          // console.log("created date",row)
          const days = row.created_at
            ? moment().diff(moment(row.created_at, "YYYY-MM-DD"), "days")
            : "";
          const daysFormatted =
            days !== null && days !== undefined
              ? `${days} ${days > 1 ? "Days" : "Day"}`
              : "";
          return daysFormatted;
        },
      },
      {
        name: "Country",
        selector: "country",
        wrap: true,
        sortField: "country_id",
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (order) => this.renderViewButton(order),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchActiveOrders();
  }

  getCustomerName = (row) => {
    if (!row) return "";

    const { dependent_id, member, user } = row;
    const { username, full_name, first_name, last_name } = user ?? {};
    return  username || full_name || `${first_name ?? ""}  ${last_name ?? ""}`.trim();
  }
  getCustomerEmail = (row) => {
    if (!row) return "";

    const { dependent_id, member, user} = row;
    const { username, full_name, first_name, last_name,email} = user ?? {};
    // console.log("email",row)
    return email;
    // return dependent_id
    //   ? `${member?.first_name ?? ""} ${member?.last_name ?? ""}`.trim()
    //   : username || full_name || `${first_name ?? ""}  ${last_name ?? ""}`.trim();
  }
  getCustomerDependentName = (row) => {
    if (!row) return "";

    const { dependent_id, member, user,is_family_member_order } = row;
    const { username, full_name, first_name, last_name } = user ?? {};
    return is_family_member_order? `${member?.first_name ?? ""} ${member?.last_name ?? ""}`.trim()
      :""
  }
 
  getDependentRelation = (row) => {
    if (!row) return "";
    const { dependent_id, member, user,is_family_member_order } = row;
    const { username, full_name, first_name, last_name } = user ?? {};
    
    return is_family_member_order?(member.relation).trim():""
      // ? `${member?.first_name ?? ""} ${member?.last_name ?? ""}`.trim()
      // : username || full_name || `${first_name ?? ""}  ${last_name ?? ""}`.trim();
  }
  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.HOME;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  handleSearch = (value) => {
    this.setState({ searchTerm: value });
  };

  handleSearchEnter = () => {
    this.setState({ searchApplied: true }, () => this.fetchActiveOrders());
  };

  handleSearchClear = () => {
    this.setState(
      { searchTerm: "", searchValue: "", searchApplied: false },
      () => this.fetchActiveOrders()
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value.length) this.setState({ searchApplied: false, searchValue: "" });
  };

  handlePageChange = (page) => this.fetchActiveOrders(page);

  handleRowsPerPageChange = (newRowsPerPage, page) =>
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchActiveOrders(page)
    );

  handleSort = (column, sortDirection) => {
    // console.log(column, sortDirection);
    this.setState(
      {
        sortField: column.sortField || column.selector,
        sortDirection: sortDirection,
      },
      () => this.fetchActiveOrders()
    );
  };

  fetchActiveOrders = (page = 1) => {
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;

    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    let url = `${BASE_URL_ORDERS}${getUserId()}?is_reviewed=False&page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;
    if (searchApplied) {
      url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
    }

    this.showLoading();
    PhoenixAPI.get(url)
      .then((response) => {
        if (response.data) {
          const { count: totalRows = 0, results: orders = [] } = response.data;
          this.setState({
            totalRows,
            orders: orders.map(ORDER_MAPPER),
            isLoaded: true,
          });
        }
      })
      .catch((error) => {
        console.log("Error on fetching data", error);
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.showErrorNotification("Some error has occured. Please try again.");
      })
      .finally(() => this.hideLoading());
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (order) =>
    order && (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          to={`/dashboard/home/view/${order.id}`}
          target="_blank"
        >
          {order.is_result_uploaded ? "Edit" : "View"}
        </Link>
      </div>
    );

  render() {
    const { isLoaded, loadError, searchApplied, orders } = this.state;
    const finalList = isLoaded ? orders : [];
    
    if (this.state.accessDenied) {
      return <AccessDenied />
    }
   
    const conditionalRowStyles = [
      {
        when: row =>row.dependent_id,
        style: {
          backgroundColor: "pink",
          userSelect: "email"
        }
      }
    ];
    return (
      <>
        <div className="ui cards mb-1">
          <div className="card">
            <div className="content">
              <div className="font-weight-bold">Hello {getUserName()},</div>
              <div className="meta">Have an amazing day!</div>
            </div>
          </div>
        </div>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={this.state.searchTerm}
                  clearSearch={this.handleSearchClear}
                  handleSearch={this.handleSearch}
                  handleSearchEnter={this.handleSearchEnter}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
              <div className="action-item">
                <button
                  onClick={this.handleSearchEnter}
                  className="btn"
                  style={{ backgroundColor: "#384760", color: "white" }}
                  // className="btn button-blue btn-md new-user"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <Card>
            <DataTable
            conditionalRowStyles={conditionalRowStyles}
              highlightOnHover
              columns={this.columns}
              data={finalList}
              sortServer
              onSort={this.handleSort}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationServer
              paginationTotalRows={this.state.totalRows || 0}
              paginationPerPage={
                this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE
              }
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + this.state.totalRows + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
