import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { UploadMediaFile } from "services/UploadMedia";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const RatingOptions = [
  {
    key: 1,
    value: 1,
    text: 1,
  },
  {
    key: 2,
    value: 2,
    text: 2,
  },
  {
    key: 3,
    value: 3,
    text: 3,
  },
  {
    key: 4,
    value: 4,
    text: 4,
  },
  {
    key: 5,
    value: 5,
    text: 5,
  },
];

class TestimonialsForm extends Component {
  constructor(props) {
    super(props);

    const { testimonials = [], onTestimonialsUpdated = (f) => f } = this.props;

    this.onTestimonialsUpdated = onTestimonialsUpdated;

    this.state = {
      testimonials,
      testimonial: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",

      userTestimonialEn: RichTextEditor.createEmptyValue(),
      userTestimonialAr: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "User City (English)",
        selector: "userNameCityEn",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.userNameCityEn ?? ""}
          </div>
        ),
      },
      {
        name: "User City (English)",
        selector: "userNameCityAr",
        sortable: true,
        wrap: true,
        // right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            // style={{ textAlign: "right" }}
          >
            {question.userNameCityAr ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",

        center: true,

        cell: (question) => this.renderActionBar(question),
      },
    ];
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      testimonial: {},
      isNewQuestion: false,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const testimonial = { ...this.state.testimonial };
    testimonial[input.name] = input.value;
    this.setState({ testimonial });
  };

  handleEdit = (id) => {
    const { testimonials = [] } = this.state;

    const testimonial = testimonials.find((it) => it.id === id);
    console.log("assass", testimonial);
    if (!testimonial) return;
    const userTestimonialEn = RichTextEditor.createValueFromString(
      testimonial.userTestimonialEn ?? "",
      "html"
    );
    const userTestimonialAr = RichTextEditor.createValueFromString(
      testimonial.userTestimonialAr ?? "",
      "html"
    );

    const newQuestion = {
      ...testimonial,
      userTestimonialEn: testimonial.userTestimonialEn,
      userTestimonialAr: testimonial.userTestimonialAr,
    };
    this.setState(
      {
        testimonial: newQuestion,
        userTestimonialEn: userTestimonialEn,
        userTestimonialAr: userTestimonialAr,
      },
      () => this.showAddEditModal()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { testimonial = {} } = this.state;
    if (testimonial.id === null || testimonial.id === undefined)
      this.createUsp();
    else this.updateUsp(testimonial.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { headerImage, uspTextEn, uspTextAr } = this.state.testimonial || {};

    // if (!headerImage) errors.headerImage = "Image cannot be blank";

    // if (!uspTextEn) errors.uspTextEn = "Main Text cannot be blank";

    // if (!uspTextAr) errors.uspTextAr = "Main Text cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createUsp = () => {
    const { testimonials = [], testimonial } = this.state;
    const ids =
      testimonials.length > 0 ? testimonials.map((it) => it.id) : null;
    testimonial.id = ids ? Math.max(...ids) + 1 : 1;

    testimonial.is_new = true;

    this.setState({ testimonials: [...testimonials, testimonial] }, () => {
      this.showSuccessNotification("Testimonial Created Successfully!");
      this.onUspsUpdatedLocally();
    });
  };

  updateUsp = (id) => {
    const { testimonials = [], testimonial } = this.state;
    const newQuestions = testimonials.map((it) =>
      it.id === id ? testimonial : it
    );
    this.setState({ testimonials: newQuestions }, () => {
      this.showSuccessNotification("Testimonial Updated Successfully!");
      this.onUspsUpdatedLocally();
    });
  };

  onUspsUpdatedLocally = () => {
    this.clearForm();
    this.onTestimonialsUpdated(
      this.state.testimonials.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  createRankOptions = () => {
    const { isNewQuestion, testimonials } = this.state;

    const ranks = testimonials.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  handleAddNewQuestion = () => {
    this.setState(
      {
        isNewQuestion: true,
        userTestimonialEn: RichTextEditor.createEmptyValue(),
        userTestimonialAr: RichTextEditor.createEmptyValue(),
      },
      () => this.showAddEditModal()
    );
  };

  handleRichText = (value, name) => {
    const { testimonial } = this.state;
    const newQuestion = { ...testimonial, [name]: value.toString("html") };
    this.setState({ testimonial: newQuestion, [name]: value });
  };

  showAddEditModal = () => {
    const { isNewQuestion } = this.state;
    const rankOptions = this.createRankOptions() || [];
    if (isNewQuestion) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const testimonial = {
        isActive: false,
        rank,
      };
      this.setState({ isAddEditModalVisible: true, testimonial, rankOptions });
    } else {
      this.setState({ isAddEditModalVisible: true, rankOptions });
    }
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({
      isAddEditModalVisible: false,
      userTestimonialEn: RichTextEditor.createEmptyValue(),
      userTestimonialAr: RichTextEditor.createEmptyValue(),
    });
  };

  renderActionBar = (question) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(question.id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.testimonial.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      testimonials = [],
      testimonial = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewQuestion =
      testimonial.id === null || testimonial.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              User Name & City (English)
            </label>
            <div className="input-group">
              <input
                value={testimonial?.userNameCityEn || ""}
                onChange={this.handleChange}
                id="userNameCityEn"
                name="userNameCityEn"
                type="text"
                placeholder="UserName &  City in English"
                className={
                  "form-control py-2" +
                  (errors.userNameCityEn ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.userNameCityEn}
              />
              {this.renderPencilIcon("userNameCityEn")}
            </div>
            <span className="help-block">{errors.userNameCityEn}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">
              User Name & City (Arabic)
            </label>
            <div className="input-group">
              <input
                value={testimonial?.userNameCityAr || ""}
                onChange={this.handleChange}
                id="userNameCityAr"
                name="userNameCityAr"
                type="text"
                placeholder="UserName &  City in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.userNameCityAr ? " has-error" : "")
                }
                disabled={!isNewQuestion && !editables.userNameCityAr}
              />
              {this.renderPencilIcon("userNameCityAr")}
            </div>
            <span className="help-block">{errors.userNameCityAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>User Testimonial English (RichText)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.userTestimonialEn}
                onChange={(value) =>
                  this.handleRichText(value, "userTestimonialEn")
                }
                disabled={!isNewQuestion && !editables.userTestimonialEn}
              />
              {this.renderPencilIcon("userTestimonialEn")}
            </div>
            <span className="help-block">{errors.userTestimonialEn}</span>
          </div>
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>User Testimonial Arabic (RichText)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.userTestimonialAr}
                onChange={(value) =>
                  this.handleRichText(value, "userTestimonialAr")
                }
                disabled={!isNewQuestion && !editables.userTestimonialAr}
              />
              {this.renderPencilIcon("userTestimonialAr")}
            </div>
            <span className="help-block">{errors.userTestimonialAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={testimonial.rank}
                onChange={(event, value) => {
                  this.setState({
                    testimonial: { ...testimonial, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={testimonial.isActive}
                onChange={(event, value) => {
                  this.setState({
                    testimonial: {
                      ...testimonial,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="userRating">Rating</label>
            <div className="input-group">
              <Dropdown
                value={testimonial?.userRating}
                onChange={(event, value) => {
                  this.setState({
                    testimonial: {
                      ...testimonial,
                      userRating: value.value,
                    },
                  });
                }}
                id="userRating"
                name="userRating"
                placeholder="userRating"
                search
                selection
                options={RatingOptions}
                disabled={!isNewQuestion && !editables.userRating}
                className={
                  "form-control" + (errors.userRating ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("userRating")}
            </div>
            <span className="help-block">{errors.userRating}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="font-weight-bold" style={{ padding: "5px" }}>
                Testiomonial Section
              </p>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={testimonials || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {testimonial.id === null || testimonial.id === undefined
                ? "Add Testimonial Section"
                : "Edit Testimonial Section"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {testimonial.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TestimonialsForm.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTestimonialsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

TestimonialsForm.defaultProps = {
  testimonials: [],
  onTestimonialsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialsForm);
