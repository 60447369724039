import React ,{useState,useEffect}from "react";
import { Modal } from "react-bootstrap";

function OrdersListCancel(props){
 const{isVisible,order,onHide,onSave}=props;
 const { items = [] } = order;

 const [checkedItems, setCheckedItems] = useState([]);
 const[checkedItemName,setCheckedItemName]=useState([]);
 const [cancelNotesFinal, setCancelNotesFinal] = useState("");
 const[confirmCancel,setConfirmCancel]=useState(false);
 const[error,setError]=useState("");
 const handleCheckboxChange = (itemOrderId,itemName) => {
   if (checkedItems.includes(itemOrderId)) {
     setCheckedItems(checkedItems.filter((id) => id !== itemOrderId));
     setCheckedItemName(checkedItemName.filter((id) => id !== itemName))
   } else {
     setCheckedItems([...checkedItems, itemOrderId]);
     setCheckedItemName([...checkedItemName, itemName]);
   }
 };
    
 const cancelNotesValidation=(refundvalid)=>{
    if(refundvalid=="" || refundvalid==null)
    {
     setError("please enter reason");
     return false;
   
    }
    else{
     setError("");
     return true;
    }
 
   }
 return(
    <Modal show={isVisible} onHide={() => { onHide(); setError("");setCancelNotesFinal("");setConfirmCancel(false);setCheckedItems([]);setCheckedItemName([]); }}>
        <Modal.Header closeButton>
        <Modal.Title style={{fontSize:"16px"}}>{!confirmCancel? "Select order you want to cancel" :"you are about to cancel the following order"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        { !confirmCancel? <><div className="row mt-1 ml-1">
      {items.map((item) => (
            <div key={item.itemOrderId} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={`checkbox-${item.itemOrderId}`}
                checked={checkedItems.includes(item.itemOrderId)}
                onChange={() => handleCheckboxChange(item.itemOrderId,item.itemNameEnglish)}
              />
              <label className="form-check-label" htmlFor={`checkbox-${item.itemOrderId}`}>
                {item.itemNameEnglish} - {item.itemOrderId}
              </label>
            </div>
          ))}
       
       </div>
       <span className="help-block">{error}</span>
       <div className="form-group  col text-right">
        <button onClick={(event)=>{if(checkedItems.length>0) {setConfirmCancel(true);setError("")} else{ setError("please select at least one item to cancel")}}}className="btn button btn-danger">
          Cancel 
        </button> 
        </div></>:<><div className="row mt-1 ml-1">
      {checkedItems.map((item,index) => (<div>{checkedItems[index]} -{checkedItemName[index]}</div>))}</div>
      <br/>
      <div className="row">
        <div className="form-group col">
          <div> Cancel Reason*</div>
              <textarea
                value={cancelNotesFinal ?? ""}
                onChange={(event) => {setCancelNotesFinal(event.target.value);setError("");}}
                id="cancelNotes"
                name="cancelNotes"
                placeholder="Cancel Notes"
                className="form-control py-2"
              ></textarea>
              </div>
            </div>
       
       <div className="form-group  col text-right">
        <button onClick={(event)=>{if(cancelNotesValidation(cancelNotesFinal))onSave(checkedItems,cancelNotesFinal)}}className="btn button btn-danger">
         Confirm Cancel 
        </button> 
        </div>
        </>
        }

       </Modal.Body>
    

      
   </Modal> )

}

export default OrdersListCancel;