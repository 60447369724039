import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { filterList } from "utils/List";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import { Dropdown } from "semantic-ui-react";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import { fetchBloodPackages, fetchMiniPackages } from "services/productService";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import PhoneInput from "react-phone-input-2";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class Nutritionist extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "lab_name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required."
      },
      // {
      //   field: "address_details",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Address is required."
      // },
      {
        field: "city",
        method: "isEmpty",
        validWhen: false,
        message: "City is required."
      },
      {
        field: "country",
        method: "isEmpty",
        validWhen: false,
        message: "Country is required."
      },
      // {
      //   field: "blood_analysis",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Product is required."
      // },
      // {
      //   field: "custom_package",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Custom Package is required."
      // },

    ]);

    this.labValidator = new FormValidator([
      {
        field: "name",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required."
      },
      {
        field: "contact_number",
        method: "isEmpty",
        validWhen: false,
        message: "Contact number is required."
      },
      {
        field: "location",
        method: "isEmpty",
        validWhen: false,
        message: "Location is required."
      },
    ]);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      lab_id: "",
      laboratory_id: "",
      lab_name: "",
      name: "",
      address_details: "",
      city: [],
      country: "",
      blood_analysis: [],
      custom_package: [],
      edit_id: null,
      validation: this.validator.valid(),
      labValidation: this.labValidator.valid(),
      list: [],
      labList: [],
      isLoaded: false,
      loadError: "",
      showModal: false,
      showDataTable: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      searchLabExpanded: false,
      searchLabTerm: "",
      searchLabApplied: false,
      results: [],
      bloodAnalysisList: [],
      customPackagesList: [],
      bloodWorkPackagesOptions: [],
      customPackagesOptions: [],
      profile: [],
      countryList: [],
      countyData: [],
      cityList: [],
      cityData: [],
      resultCount: 0,
      showItems: "Active",
      miniPackages:[],
      labColumns: [
        {
          name: "Lab ID",
          selector: "id",
          maxWidth: "70px",
          wrap: true,
          sortable: true
        },
        {
          name: "Name",
          selector: "name",
          wrap: true,
          sortable: true
        },
        {
          name: "Lab Profiles",
          selector: "lab_profile_name",
          wrap: true,
          sortable: true
        },
        {
          name: "Status",
          center: true,
          maxWidth: "100px",
          sortable: true,
          selector: "is_active",
          format: row => (
            <>
              {row.is_active ? (
                <div className="badge badge-success">
                  <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                  Enabled
                </div>
              ) : (
                <div className="badge badge-danger">
                  <BlockIcon></BlockIcon>Disabled
                </div>
              )}
            </>
          )
        },
        {
          name: "Actions",
          maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: row => {
            return (
              <ActionBar alignRight className="action-menu valeo-dropdown">
                <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
                  <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  {row.is_active ? (
                    <>
                      <ActionBar.Item
                        className="info"
                        onClick={() => this.showLabEditModal(row)}
                      // onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots/Edit')}
                      >
                        <EditIcon></EditIcon>
                        Edit
                      </ActionBar.Item>
                      <ActionBar.Item
                        className="danger"
                        onClick={() => this.showLabConfirm(row)}
                      >
                        <BlockIcon></BlockIcon>
                        Disabled
                      </ActionBar.Item>
                    </>
                  ) : (
                    <>
                      <ActionBar.Item
                        className="info"
                        onClick={() => this.showLabEditModal(row)}
                      // onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots/Edit')}
                      >
                        <EditIcon></EditIcon>
                        Edit
                      </ActionBar.Item>
                      <ActionBar.Item
                        className="success"
                        onClick={() => this.showLabConfirm(row)}
                      >
                        <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                        Enabled
                      </ActionBar.Item>
                    </>
                  )}
                </ActionBar.Menu>
              </ActionBar>
            )
          }
        },
      ],
      columns: [
        {
          name: "Profile ID",
          selector: "id",
          maxWidth: "70px",
          sortable: true
        },
        {
          name: "Name",
          selector: "name",
          maxWidth: "150px",
          wrap: true,
          sortable: true
        },
        // {
        //   name: "Address Details",
        //   selector: "address_details",
        //   maxWidth: "150px",
        //   wrap: true,
        //   sortable: true
        // },
        {
          name: "City",
          selector: "city_name",
          maxWidth: "120px",
          wrap: true,
          sortable: true
        },
        {
          name: "Country",
          selector: "county_name",
          maxWidth: "120px",
          wrap: true,
          sortable: true
        },
        {
          name: "Status",
          center: true,
          maxWidth: "100px",
          sortable: true,
          selector: "is_active",
          format: row => (
            <>
              {row.is_active ? (
                <div className="badge badge-success">
                  <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
                  Enabled
                </div>
              ) : (
                <div className="badge badge-danger">
                  <BlockIcon></BlockIcon>Disabled
                </div>
              )}
            </>
          )
        },
        // {
        //   name: "Country",
        //   selector: "date_joined",
        //   sortable: true,
        //   format: row => row.date_joined != null ? moment(row.date_joined).format("DD-MM-YYYY, hh:MM A") : '-'
        // },
        // {
        //   name: "Status",
        //   selector: "last_login",
        //   sortable: true,
        //   format: row => row.last_login != null ? moment(row.last_login).format("DD-MM-YYYY, hh:MM A") : "-"
        // },
        {
          name: "Products",
          selector: "products",
          sortable: true,
          wrap: true,
        },
        {
          name: "Custom Packages",
          selector: "custom_packages_name",
          sortable: true,
          wrap: true,
        },
        {
          name: "Mini Packages",
          selector: "mini_packages_name",
          sortable: true,
          wrap: true,
        },
        {
          name: "Actions",
          maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: row => {
            return (
              <ActionBar alignRight className="action-menu valeo-dropdown">
                <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
                  <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  {/* {row.profile_id &&
                    <ActionBar.Item
                      onClick={() => this.props.history.push(`/dashboard/users/nutritionists/details/${row.profile_id}`)}
                    >
                      <Visibility></Visibility>
                      View
                    </ActionBar.Item>
                  } */}
                  {row.is_active ? (
                    <>
                      <ActionBar.Item
                        className="info"
                        onClick={() => this.showEditModal(row)}
                      // onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots/Edit')}
                      >
                        <EditIcon></EditIcon>
                        Edit
                      </ActionBar.Item>
                      <ActionBar.Item
                        className="danger"
                        onClick={() => this.showConfirm(row)}
                      >
                        <BlockIcon></BlockIcon>
                        Disabled
                      </ActionBar.Item>
                    </>
                  ) : (
                    <>
                      <ActionBar.Item
                        className="info"
                        onClick={() => this.showEditModal(row)}
                      // onClick={() => this.props.history.push('/dashboard/LabManagment/LabSlots/Edit')}
                      >
                        <EditIcon></EditIcon>
                        Edit
                      </ActionBar.Item>
                      <ActionBar.Item
                        className="success"
                        onClick={() => this.showConfirm(row)}
                      >
                        <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                        Enabled
                      </ActionBar.Item>
                    </>
                  )}
                </ActionBar.Menu>
              </ActionBar>
            )
          }
        },
      ],
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getLabProfilesList = this.getLabProfilesList.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideLabModal = this.hideLabModal.bind(this);
    this.showLabModal = this.showLabModal.bind(this);
    this.showEditModal = this.showEditModal.bind(this);
    this.showLabEditModal = this.showLabEditModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.hideLabConfirm = this.hideLabConfirm.bind(this);
    this.showLabConfirm = this.showLabConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.toggleLabActiveStatus = this.toggleLabActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.clearLabSearch = this.clearLabSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleLabSearch = this.handleLabSearch.bind(this);
    this.handleLabSearchExit = this.handleLabSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.createLabProfile = this.createLabProfile.bind(this);
    this.createLab = this.createLab.bind(this);
    // this.handleCustomePackages = this.handleCustomePackages.bind(this);
  }

  async componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchCities();
    // this.getCountryCityList();
    this.getBloodAnalysis();
    
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.country !== prevState.country) {
      this.createCityOptionsList(this.state.country);
      this.createBloodBiomarkerPackagesOptionsList();
      this.createCustomPackagesOptionsList();
      this.createMiniPackagesOptionsList();
    }
   if (this.state.city !== prevState.city) {
     this.createMiniPackagesOptionsList();
   }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.LABS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  getLabs = async () => {
    const { searchLabApplied, searchLabTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });

    API.get("create-laboratory/")
      .then(response => {
        const activeLabList = filterList(response.data, "active");
        const deactivatedLabList = filterList(response.data, "archived");

        response.data.map((item) => {
          if (item && item.profile) {
            item["lab_profile_name"] = '';
            for (let i = 0; i < item.profile.length; i++) {
              const labData = this.state.dropDownList.filter(data => data.value == item.profile[i]);
              if (labData && labData.length > 0) {
                item["lab_profile_name"] = item["lab_profile_name"] + labData[0].text;
              }
              if (i < (item.profile.length - 1)) {
                item["lab_profile_name"] = item["lab_profile_name"] + ' , ';
              }
            }
          }
        })

        this.setState({
          labList: response.data,
          activeLabList,
          deactivatedLabList,
          isLoaded: true
        });

        if (searchLabApplied) this.handleSearch(searchLabTerm);

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on fetching data", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
          isLoading: false
        });
      });
  }

  fetchCountries = async () => {
    this.props.toggleLoading({ isLoading: true });
    try {
      const { data: countries = [] } = await API.get("countries/");
      this.setState({ countyData: countries }, () => this.createCountryOptionsList());
    } catch (error) {
      console.log("Error on fetching countries!", error);
    } finally {
      this.props.toggleLoading({ isLoading: false });
    }
  }

  fetchCities = async () => {
    this.props.toggleLoading({ isLoading: true });
    try {
      const {data: cities = []} = await API.get("cities/");
      this.setState({ cityData: cities });
    } catch (error) {
      console.log("Error on fetching cities!", error);
    } finally {
      this.props.toggleLoading({ isLoading: false });
    }
  }

  // getCountryCityList() {
  //   this.props.toggleLoading({
  //     isLoading: true
  //   });
  //   API.get("cities/")
  //     .then(response => {
  //       // response.data.map((item) => {
  //       //     if (item && item.country) {
  //       //         item["country_name"] = '';
  //       //         const countryData = this.state.countryList.filter(data => data.value == item.country);
  //       //         if (countryData && countryData.length) {
  //       //             item["country_name"] = item["country_name"] + countryData[0].text + ',';
  //       //         }
  //       //     }
  //       // });
  //       let array = response?.data || [];
  //       let list = [];
  //       for (let i = 0; i < array.length; i++) {
  //         if (array[i].is_active === true)
  //           list.push({ key: array[i].city_name, value: array[i].id, text: array[i].city_name });
  //       }

  //       this.setState({
  //         cityData: response.data,
  //         cityList: list,
  //         isLoaded: true
  //       });


  //       this.props.toggleLoading({
  //         isLoading: false
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again"
  //       });

  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //         isLoading: false
  //       });
  //     });
  // }

  getBloodAnalysis = () => {
    fetchBloodPackages()
      .then(bloodBiomarkerPackages => {
        // let list = [];
        // for (let i = 0; i < bloodBiomarkerPackages.length; i++) {
        //   const bloodBiomarkerPackage = bloodBiomarkerPackages[i];
        //   if (bloodBiomarkerPackage.is_active === true)
        //     list.push({ 
        //       key: bloodBiomarkerPackage.subscription_name, 
        //       value: bloodBiomarkerPackage.id, 
        //       text: bloodBiomarkerPackage.subscription_name
        //    });
        // }

        // API.get("packages/")
        PhoenixAPI.get("/custom/packages")
          .then(response => {
            const customPackages = response.data || [];
            // let products = [];
            // const data = response.data;
            // data.map(val => (
            //   products.push({ key: val.name, value: val.id, text: val.name })
            // ));
              this.fetchMiniPackages()
            this.setState({ bloodBiomarkerPackages, customPackages });
          })
          .catch(error => console.log("Error on fetching custom packages!", error));
      })
      .catch(error => console.log("Error on fetching blood biomarker packages!", error));
  }

  fetchMiniPackages = async() => {
    try {
      this.showLoading();
      const miniPackages = await fetchMiniPackages();
      this.setState({ miniPackages: miniPackages ?? [] },() => this.getLabProfilesList());
    } catch(error) {
      this.showErrorNotification("Error on fetching mini packages! " + error.message);
    } finally {
      this.hideLoading();
    }
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handlePhoneNumberChange = async (value, data) => {
    console.log("numberCheck:: ", { value, data });
    const { dialCode } = data;
    if (dialCode && value) {
      this.setState({ contact_number: `+${dialCode}${value.slice(dialCode.length)}` });
    }
  };

  createLabProfile() {
    const validation = this.validator.validate(this.state);
    const {miniPackages,city,selectedMiniPackages}=this.state;
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });
      let url = this.state.lab_id ? "lab-profile/" + this.state.lab_id + "/" : "lab-profile/";
      let method = this.state.lab_id ? 'patch' : 'post';
      console.log("selectedMiniPackages",selectedMiniPackages);
      const minipackageNames=miniPackages.length>0 && miniPackages.filter((item)=>selectedMiniPackages.includes(item.id)
      ).map((mini)=>mini.name);
      console.log("mini Package names",minipackageNames);
      console.log("cities ids",city);
      console.log("minipackages",miniPackages);
      const finalMiniPackageCities=miniPackages.length>0 && miniPackages.filter((item)=>minipackageNames.includes(item.name) && city.includes(item.cityId)
      );
console.log("citiesmini",finalMiniPackageCities.map((mini)=>mini.name));
const finalMiniPackageIds=finalMiniPackageCities.map((mini)=>mini.id) ??[];
      console.log("final minipackage",finalMiniPackageIds);
      API[method](url, {
        name: this.state.lab_name,
        address_details: this.state.address_details,
        city: this.state.city,
        country: this.state.country,
        blood_analysis: this.state.blood_analysis,
        custom_package: this.state.custom_package,
        coach_package: [],
        mini_packages: finalMiniPackageIds ?? [],
      })
        .then(response => {
          this.clearForm();
          this.getLabProfilesList();
          this.props.showNotificationMessage({
            notificationMessage: this.state.lab_id ? "Lab Profile updated successfully." : "Lab Profile created successfully.",
            successMessage: true,
            showNotification: true,
            isLoading: false
          });
        })
        .catch(error => {
          let message = "";
          if (error.response.status === 400) {
            message = "Account already exists.";
          } else message = "Some error has occured. Please try again.";

          this.props.showNotificationMessage({
            notificationMessage: message,
            successMessage: false,
            showNotification: true,
            isLoading: false
          });
        });
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
        isLoading: false
      });
    }
  }

  createLab() {
    const labValidation = this.labValidator.validate(this.state);
    this.submitted = true;
    this.setState({ labValidation });
    if (labValidation.isValid) {
      this.hideLabModal();
      this.props.toggleLoading({
        isLoading: true
      });
      let data = {};
      if (this.state.laboratory_id) {
        data['name'] = this.state.name;
        // if (this.state.editedLabData && this.state.editedLabData.profile && (this.state.editedLabData.profile != this.state.profile)) {
        data['profile'] = this.state.profile;
        data['is_active'] = true;
        // }
        data[`contact_number`] = this.state.contact_number ?? null;
        data[`location`] = this.state.location ?? null;
      } else {
        data['name'] = this.state.name;
        data['profile'] = this.state.profile;
        data['is_active'] = true;
        data[`contact_number`] = this.state.contact_number ?? null;
        data[`location`] = this.state.location ?? null;
      }
      let url = this.state.laboratory_id ? "create-laboratory/" + this.state.laboratory_id + "/" : "create-laboratory/";
      let method = this.state.laboratory_id ? 'put' : 'post';
      API[method](url, data)
        .then(response => {
          this.clearForm();
          this.getLabs();
          this.props.showNotificationMessage({
            notificationMessage: this.state.lab_id ? " Laboratory updated successfully." : " Laboratory created successfully.",
            successMessage: true,
            showNotification: true,
            isLoading: false
          });
        })
        .catch(error => {
          let message = "Some error has occured. Please try again.";
          if (error && error.response && error.response.status && error.response.status == 400) {
            message = "Profile has been already assigned to another Laboratory"
          }
          this.props.showNotificationMessage({
            notificationMessage: message,
            successMessage: false,
            showNotification: true,
            isLoading: false
          });
        });
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
        isLoading: false
      });
    }
  }

  clearForm() {
    this.submitted = false;
    // let list = [];
    // for (let i = 0; i < this.state.cityData.length; i++) {
    //   if (this.state.cityData[i].is_active === true)
    //     list.push({ key: this.state.cityData[i].city_name, value: this.state.cityData[i].id, text: this.state.cityData[i].city_name });
    // }

    this.setState({
      lab_id: "",
      lab_name: "",
      name: "",
      laboratory_id: "",
      profile: [],
      lab_profiles_name: '',
      address_details: "",
      city: [],
      country: "",
      edit_id: null,
      blood_analysis: [],
      custom_package: [],
      validation: this.validator.valid(),
      cityList: [],
      bloodBiomarkerPackagesOptions: [],
      customPackagesOptions: [],
      editedLabData: {},
      selectedMiniPackages: [],
      contact_number: "",
      location: "",
    });
  }

  hideModal() {
    this.setState({ 
      showModal: false, 
      bloodWorkPackagesOptions: [], 
      customPackagesOptions: [],
      miniPackagesOptions: [],
    });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true
    });
  }

  hideLabModal() {
    this.setState({ showLabModal: false });
    this.clearForm();
  }

  showLabModal() {
    this.setState({
      showLabModal: true
    });
  }

  showEditModal(data) {
    if (data.country)
      this.handleCountyChange(data.country)
    console.log("minipackage",data.mini_packages);

   const filteredMiniPackage= this.state.miniPackages.length>0 && this.state.miniPackages.filter((minipackage)=>data.mini_packages.includes(minipackage.id));
       var resArr = [];
    filteredMiniPackage.filter(function(item){
      var i = resArr.findIndex(x => x.name == item.name);
      if(i <= -1){
        resArr.push({...item});
      }
      return null;
    })
   const finalPackageIds= resArr.map((minipackage)=>minipackage.id);
    
     
    this.setState({
      lab_id: data.id,
      lab_name: data.name,
      address_details: data.address_details,
      city: data.city ? data.city : [],
      country: Number(data.country),
      blood_analysis: data.blood_analysis, //? data.lab_slot.blood_analysis : '',
      custom_package: data.custom_package ? data.custom_package : [],
      selectedMiniPackages: finalPackageIds ?? [],
      showModal: true
    });
  }

  showLabEditModal(data) {
    this.setState({
      laboratory_id: data.id,
      name: data.name,
      profile: data.profile, //? data.lab_slot.blood_analysis : '',
      contact_number: data.contact_number ?? "",
      location: data.location ?? "",
      showLabModal: true,
      editedLabData: data
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Deactivate Lab Profile";
      confirmMessage = "Are you sure you want to deactivate this Lab Profile?";
      successMessage = "Lab Profile successfully deactivated.";
      confirmType = "danger";
    } else {
      confirmTitle = "Activate Lab Profile";
      confirmMessage = "Are you sure you want to activate this Lab Profile?";
      successMessage = "Lab Profile successfully activated.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  hideLabConfirm() {
    this.setState({
      showLabConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showLabConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Deactivate Lab";
      confirmMessage = "Are you sure you want to deactivate this Lab?";
      successMessage = "Lab successfully deactivated.";
      confirmType = "danger";
    } else {
      confirmTitle = "Activate Lab";
      confirmMessage = "Are you sure you want to activate this Lab?";
      successMessage = "Lab successfully activated.";
      confirmType = "success";
    }

    this.setState({
      showLabConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("lab-profile/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });

        this.getLabProfilesList();
      })
      .catch(error => {
        console.log("Error on updating lab profile", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  toggleLabActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideLabConfirm();

    this.props.toggleLoading({
      isLoading: true
    });
    let data = {};
    data['name'] = item.name;
    data['profile'] = item.profile;
    data['is_active'] = !item.is_active;
    data["contact_number"]= item.contact_number;
    data["location"]=item.location;
    API.put("create-laboratory/" + item.id + "/", data)
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });

        this.getLabs();
      })
      .catch(error => {
        console.log("Error on creating lab", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  getLabProfilesList() {
    const { searchApplied, searchTerm , miniPackages=[]} = this.state;
    
    this.props.toggleLoading({
      isLoading: true
    });

    API.get("lab-profile/")
      .then(response => {
        const activeList = filterList(response.data, "active");
        const deactivatedList = filterList(response.data, "archived");
        let array = response?.data || [];
        let dropDownList = [];
        for (let i = 0; i < array.length; i++) {
          dropDownList.push({ key: array[i].name, value: array[i].id, text: array[i].name });
        }
        response.data.map((item) => {
          if (item && item.blood_analysis && item.blood_analysis.length > 0) {
            item["products"] = '';
            for (let i = 0; i < item.blood_analysis.length; i++) {
              const bloodData = this.state.bloodBiomarkerPackages.filter(data => data.id === item.blood_analysis[i]);
              if (bloodData && bloodData.length > 0) {
                item["products"] = item["products"] + bloodData[0].subscription_name;
                if (i < (item.blood_analysis.length - 1)) {
                  item["products"] = item["products"] + ' , ';
                }
              }

            }
          }

          if (item && item.custom_package && item.custom_package.length > 0) {
            item["custom_packages_name"] = '';
            for (let i = 0; i < item.custom_package.length; i++) {
              const bloodData = this.state.customPackages.filter(data => data.id === item.custom_package[i]);
              if (bloodData && bloodData.length) {
                item["custom_packages_name"] = item["custom_packages_name"] + bloodData[0].name;
                if (i < (item.custom_package.length - 1)) {
                  item["custom_packages_name"] = item["custom_packages_name"] + ' , ';
                }
              }

            }
          }
              
          if (item && item.mini_packages && item.mini_packages.length > 0) {
            
            item["mini_packages_name"] = '';
            for (let i = 0; i < item.mini_packages.length; i++) {
               
              const miniPackageData = (miniPackages.length>0 && miniPackages.filter(data => data.id === item.mini_packages[i]))?? [];

              if (miniPackageData && miniPackageData.length>0) {
                 if(!item["mini_packages_name"].includes(miniPackageData[0].name)){
                item["mini_packages_name"] = item["mini_packages_name"] + miniPackageData[0].name;

                if (i < (item.mini_packages.length - 1)) {
                  item["mini_packages_name"] = item["mini_packages_name"] + ' , ';
                }
                 }
            }

            }

          }



          if (item && item.country) {
            item["county_name"] = '';
            const countyData = this.state.countyData.filter(data => data.id === item.country);
            if (countyData && countyData.length) {
              item["county_name"] = countyData[0].country_name;
            }
          }
          if (item && item.city) {
            item["city_name"] = '';
            for (let i = 0; i < item.city.length; i++) {
              const cityData = this.state.cityData.filter(data => data.id === item.city[i]);
              if (cityData && cityData.length > 0) {
                item["city_name"] = item["city_name"] + cityData[0].city_name;
                if (i < (item.city.length - 1)) {
                  item["city_name"] = item["city_name"] + ' , ';
                }
              }

            }
          }

        });

        this.setState({
          list: response.data,
          activeList,
          dropDownList: dropDownList,
          deactivatedList,
          isLoaded: true
        }, () => {
          this.getLabs();

        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        console.log("Error on getting data", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
          isLoading: false
        });
      });
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeList;
    else if (showItems === "Deactivated") list = this.state.deactivatedList;
    else list = this.state.list;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        (item.name && item.name.toLowerCase().indexOf(newValue) !== -1) ||
        (item.address_details && item.address_details.toLowerCase().indexOf(newValue) !== -1) ||
        (item.country_name && item.country_name.toLowerCase().indexOf(newValue) !== -1) ||
        (item.city_name && item.city_name.toLowerCase().indexOf(newValue) !== -1) ||
        (item.products && item.products.toLowerCase().indexOf(newValue) !== -1) ||
        (item.custom_packages_name && item.custom_packages_name.toLowerCase().indexOf(newValue) !== -1)
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  handleLabSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchLabTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchLabApplied: false,
        searchLabExpanded: false
      });

      return;
    }

    const { showItems } = this.state;

    if (showItems === "Active") list = this.state.activeLabList;
    else if (showItems === "Deactivated") list = this.state.deactivatedLabList;
    else list = this.state.labList;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.name.toLowerCase().indexOf(newValue) !== -1 ||
        item.lab_profile_name.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchLabExpanded: true,
      searchLabApplied: true,
      resultsLab: newList,
      resultLabCount: newList.length
    });
  }

  clearLabSearch() {
    this.setState({
      searchLabTerm: "",
      searchLabExpanded: true,
      searchLabApplied: false
    });

    this.labSearch.searchInput.focus();
  }

  handleLabSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchLabExpanded: false,
        searchLabApplied: false
      });

      return;
    } else {
      this.setState({
        searchLabExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "deactivated") showItems = "Deactivated";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  handleBloodAnalysis = (value) => {
    this.setState({ blood_analysis: value })
  }

  handleLabProfiles = (value) => {
    this.setState({ profile: value })
  }

  handleCustomePackages = (value) => {
    this.setState({ custom_package: value })
  }

  handleCountyChange = (selectedCountryId) => {
    this.setState({ 
      country: selectedCountryId, 
      city: [],
      custom_package: [],
      blood_analysis: [],
      selectedMiniPackages: [],
    });
    // Not needed code, should be deleted.
    // let array = [...this.state.cityData];
    // let list = [];
    // for (let i = 0; i < array.length; i++) {
    //   if (array[i].country && array[i].is_active && array[i].country.id == selectedCountryId)
    //     list.push({ key: array[i].city_name, value: array[i].id, text: array[i].city_name });
    // }
    // this.setState({ cityList: list, city: [] });
  }

  handleCityChange = (selectedCityIds) => {
    const selectedMiniPackages = this.state.selectedMiniPackages ?? [];
    const filteredMiniPackages = selectedMiniPackages
      .map(this.findMiniPackageById)
      .filter(it => selectedCityIds.includes(it.cityId))
      .map(it => it.id);
    this.setState({ city: selectedCityIds, selectedMiniPackages: filteredMiniPackages });
  }

  findCountryById = (id) => {
    const { countyData: countries = [] } = this.state;
    return countries.find(country => country.id === id);
  }

  findCityById = (id) => {
    const { cityData: cities = [] } = this.state;
    return cities.find(city => city.id === id);
  }

  findMiniPackageById = (id) => {
    const { miniPackages = [] } = this.state;
    return miniPackages.find(p => p.id === id);
  }

  findCustomPackageById = (id) => {
    const { customPackages = [] } = this.state;
    return customPackages.find(p => p.id === id);
  }

  findBloodBiomarkerPackageById = (id) => {
    const { bloodBiomarkerPackages = [] } = this.state;
    return bloodBiomarkerPackages.find(p => p.id === id);
  }

  createCountryOptionsList = () => {
    const { countyData: countries = [] } = this.state;
    const [activeCountries, inactiveCountries] = _.partition(countries, country => country.is_active);
    const compareByName = (firstCountry = {}, secondCountry = {}) =>
      compareStrings(firstCountry.county_name, secondCountry.country_name, true);
    activeCountries.sort(compareByName);
    inactiveCountries.sort(compareByName);

    const countryOptionsList = [];
    activeCountries.length > 0 &&
      countryOptionsList.push({ 
        label: "Active", 
        options: activeCountries.map(this.createCountryOption),
      });
    inactiveCountries.length > 0 &&
      countryOptionsList.push({ 
        label: "Inactive",
        options: inactiveCountries.map(this.createCountryOption),
      });

    this.setState({ countryOptionsList });
  }

  createCountryOption = (country) => country && ({
    key: country.id,
    value: country.id,
    label: country.country_name,
  });

  createCityOptionsList = (countryId) => {
    const { cityData: cities = [] } = this.state;
    const citiesOfCountry = cities.filter(city => city.country ? city.country.id === countryId : false);
    const [activeCities, inactiveCities] = _.partition(citiesOfCountry, city => city.is_active);
    const sortByName = (firstCity = {}, secondCity = {}) =>
      compareStrings(firstCity.city_name, secondCity.city_name, true);
    activeCities.sort(sortByName);
    inactiveCities.sort(sortByName);

    const cityList = [];
    activeCities.length > 0 && cityList.push({ 
      label: "Active", 
      options: activeCities.map(this.createCityOption) 
    });
    inactiveCities.length > 0 && cityList.push({ 
      label: "Inactive", 
      options: inactiveCities.map(this.createCityOption) 
    });

    this.setState({ cityList });
  }

  createCityOption = (city) => city && ({
    key: city.id,
    value: city.id,
    label: city.city_name
  });
  
  createBloodBiomarkerPackagesOptionsList = () => {
    const { country, bloodBiomarkerPackages } = this.state;
    if (!country) return;

    const bloodPackagesForCountry = bloodBiomarkerPackages && bloodBiomarkerPackages.length
      ? bloodBiomarkerPackages.filter(it => it.country === country)
      : [];
    const [activePackages, inactivePackages] = _.partition(bloodPackagesForCountry, it => it.is_active);
    const sortByName = (p1, p2) => compareStrings(p1.subscription_name, p2.subscription_name, true);
    activePackages.sort(sortByName);
    inactivePackages.sort(sortByName);
    const bloodBiomarkerPackagesOptions = [
      {
        label: "Active",
        options: activePackages.map(this.createBloodBiomarkerPackageOption),
      },
      {
        label: "Inactive",
        options: inactivePackages.map(this.createBloodBiomarkerPackageOption),
      }
    ];
    this.setState({ bloodBiomarkerPackagesOptions });
    
    // No need to fetch blood packages again, we already have all the data.
    //const url = `subscription-packages/?country=${country}`;
    // API.get(url)
    //   .then(response => {
    //     const bloodBiomarkerPackages = response.data || [];
    //     const [activePackages, inactivePackages] = _.partition(bloodBiomarkerPackages, it => it.is_active);
    //     const sortByName = (p1, p2) => compareStrings(p1.subscription_name, p2.subscription_name, true);
    //     activePackages.sort(sortByName);
    //     inactivePackages.sort(sortByName);

    //     const bloodBiomarkerPackagesOptions = [];
    //     activePackages.length > 0 && bloodBiomarkerPackagesOptions.push({ 
    //       label: "Active",
    //       options: activePackages.map(this.createBloodBiomarkerPackageOption),
    //     });
    //     inactivePackages.length > 0 && bloodBiomarkerPackagesOptions.push({ 
    //       label: "Inactive", 
    //       options: inactivePackages.map(this.createBloodBiomarkerPackageOption),
    //     });

    //     this.setState({ bloodBiomarkerPackagesOptions } );
    //   })
    //   .catch(error => {
    //     console.log(`Error fetching blood biomarker packages from URL: ${url}`, error);
    //     this.setState({ bloodWorkPackagesOptions: [] } );
    //   });
  }

  createBloodBiomarkerPackageOption = (bloodBiomarkerPackage) => bloodBiomarkerPackage && ({
    key: bloodBiomarkerPackage.id,
    value: bloodBiomarkerPackage.id,
    label: bloodBiomarkerPackage.subscription_name,
  });

  createCustomPackagesOptionsList = () => {
    const { country } = this.state;

    if (!country) return;

    const url = `packages/?country=${country}`;
    API.get(url)
      .then(response => {
        const customPackages = response.data || [];
        const [activePackages, inactivePackages] = _.partition(customPackages, it => it.status === "Active");
        const sortByName = (p1, p2) => compareStrings(p1.name, p2.name, true);
        activePackages.sort(sortByName);
        inactivePackages.sort(sortByName);
        const customPackagesOptions = [];
        activePackages.length > 0 && customPackagesOptions.push({ 
          label: "Active", 
          options: activePackages.map(this.createCustomPackageOption) 
        });
        inactivePackages.length > 0 && customPackagesOptions.push({ 
          label: "Inactive", 
          options: inactivePackages.map(this.createCustomPackageOption) 
        });
        this.setState({ customPackagesOptions });
      })
      .catch(error => {
        console.log(`Error fetching custom packages from URL: ${url}`, error);
        this.setState({ customPackagesOptions: [] });
      });
  }

  createCustomPackageOption = (customPackage) => customPackage && ({
    key: customPackage.id,
    value: customPackage.id,
    label: customPackage.name
  });

  createMiniPackagesOptionsList = () => {
    const { country, miniPackages } = this.state;
    const selectedCityIds = this.state.city ?? [];
    if (!country) return;

    const filteredMiniPackage = miniPackages && miniPackages.length>0
      ? miniPackages.filter(it => it.countryId === country && selectedCityIds.includes(it.cityId))
      : [];
     // console.log("filtered pack",filteredMiniPackages);
     var resArr = [];
filteredMiniPackage.filter(function(item){
  var i = resArr.findIndex(x => x.name == item.name);
  if(i <= -1){
    resArr.push({...item});
  }
  return null;
});


    const [activePackages, inactivePackages] = _.partition(resArr, it => it.isActive ?? false);
    const sortByName = (p1, p2) => compareStrings(p1.name, p2.name, true);
    activePackages.sort(sortByName);
    inactivePackages.sort(sortByName);
    const miniPackagesOptions = [
      {
        label: "Active",
        options: activePackages.map(this.createMiniPackageOption),
      },
      {
        label: "Inactive",
        options: inactivePackages.map(this.createMiniPackageOption),
      }
    ];
    this.setState({ miniPackagesOptions });
  }

  createMiniPackageOption = (miniPackage) => miniPackage && ({
    key: miniPackage.id,
    value: miniPackage.id,
    label: miniPackage.name
  });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let finalList = [];
    let finalLabList = [];

    const nutritionists = this.state.list;
    const labLists = this.state.labList;
    const {
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      searchLabApplied,
      resultsLab,
      resultLabCount,
      showItems,
      activeList,
      bloodAnalysisList,
      customPackagesList,
      deactivatedList,
      countryList,
      cityList,
      city: selectedCities = [],
      bloodBiomarkerPackagesOptions = [],
      blood_analysis: selectedBloodBiomarkerPackages = [],
      customPackagesOptions = [],
      custom_package: selectedCustomPackages = [],
      miniPackagesOptions = [],
      selectedMiniPackages = [],
      isEditable,
    } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    let labValidation = this.submitted
      ? this.labValidator.validate(this.state)
      : this.state.labValidation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        finalList = nutritionists;
        // if (showItems === "Active") finalList = activeList;
        // else if (showItems === "Deactivated") finalList = deactivatedList;
        // else finalList = nutritionists;
      }
      if (searchLabApplied) finalLabList = resultsLab;
      else {
        finalLabList = labLists;
        // if (showItems === "Active") finalList = activeList;
        // else if (showItems === "Deactivated") finalList = deactivatedList;
        // else finalList = nutritionists;
      }
    }

    const form = (
      <>
        <div className="form-group mb-4">
          <label style={{ marginRight: "24px" }}>Name*</label>
          <input
            type="text"
            name="lab_name"
            className={
              "form-control py-2 "
              + (validation.lab_name.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.lab_name}
          ></input>
          <span className="help-block">{validation.lab_name.message}</span>
        </div>
        {/* <div className="form-group mb-4">
          <label style={{ marginRight: "10px" }}>Address</label>
          <input
            type="text"
            name="address_details"
            className={
              "form-control py-2 "
              // + (validation.address_details.isInvalid && "has-error")
            }
            placeholder="Address"
            onChange={this.handleChange}
            value={this.state.address_details}
          ></input>
          { <span className="help-block">{validation.address_details.message}</span> }
        </div>  */}
        {/* <div className="form-group mb-4">
          <label style={{ marginRight: "15px" }}>Country :</label>
          <input
            type="text"
            name="country"
            className={
              "form-control "
              +
              (validation.country.isInvalid && "has-error")
            }
            // autocomplete="new-password"
            placeholder="Country"
            onChange={this.handleChange}
            value={this.state.country}
          ></input>
          <span className="help-block">{validation.country.message}</span>
        </div> */}
        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Country*
            </div>
            <div className="">
              <Select
                key={`country_select${this.state.country}`}
                value={this.state.country ? this.createCountryOption(this.findCountryById(this.state.country)) : ""}
                onChange={(event) => this.handleCountyChange(event.value)}
                options={this.state.countryOptionsList || []}
                styles={selectStyles}
                placeholder="Select Country"
                className={validation.country.isInvalid ? "has-error" : ""}
              />
              {/* <Dropdown
                value={this.state.country}
                className={
                  "form-control "
                  +
                  (validation.country.isInvalid && "has-error")
                }
                placeholder="Select Country"
                onChange={(event, value) => {
                  this.handleCountyChange(value.value)
                }}
                search
                selection
                options={countryList}
              /> */}
              <span className="help-block">{validation.country.message}</span>
            </div>
          </div>
        </form>
        {/* <div className="form-group mb-4">
          <label style={{ marginRight: "40px", display: "initial" }}>City :</label>
          <input
            type="text"
            name="city"
            className={
              "form-control "
              +
              (validation.city.isInvalid && "has-error")
            }
            // autocomplete="new-password"
            placeholder="City"
            onChange={this.handleChange}
            value={this.state.city}
          ></input>
          <span className="help-block">{validation.city.message}</span>
        </div> */}
        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Cities*
            </div>
            <div className="">
            <Select
                key={`cities_select${selectedCities.join()}`}
                defaultValue={selectedCities.map(this.findCityById).map(this.createCityOption)}
                onChange={(event) => this.handleCityChange(event.map(it => it.value))}
                name="cities"
                isMulti
                options={this.state.cityList || []}
                styles={selectStyles}
                placeholder="Select Cities"
                className={validation.country.isInvalid ? "has-error" : ""}
              />
              {/* <Dropdown
                value={this.state.city}
                className={
                  "form-control "
                  +
                  (validation.city.isInvalid && "has-error")
                }
                placeholder="Select City"
                onChange={(event, value) => {
                  this.handleCityChange(value.value)
                }}
                search
                selection
                multiple
                options={cityList}
              /> */}
              <span className="help-block">{validation.city.message}</span>
            </div>
          </div>
        </form>
        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Mini Packages
            </div>
            <div className="">
            <Select
                isMulti
                key={`select_mini_packages${selectedMiniPackages.join()}`}
                defaultValue={selectedMiniPackages.map(this.findMiniPackageById).map(this.createCustomPackageOption)}
                onChange={(event) => this.setState({ selectedMiniPackages: event.map(it => it.value)})}
                name="mini_packages"
                options={miniPackagesOptions}
                styles={selectStyles}
                placeholder="Select Mini Packages"
              />
            </div>
          </div>
        </form>
        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Blood Biomarker Packages
            </div>
            <div className="">
            <Select
                isMulti
                key={`select_blood_biomarker_packages${selectedBloodBiomarkerPackages.join()}`}
                defaultValue={selectedBloodBiomarkerPackages.map(this.findBloodBiomarkerPackageById).map(this.createBloodBiomarkerPackageOption)}
                onChange={(event) => this.handleBloodAnalysis(event.map(it => it.value))}
                name="blood_biomarker_packages"
                options={bloodBiomarkerPackagesOptions}
                styles={selectStyles}
                placeholder="Select Blood Biomarker Packages"
              />
              {/* <Dropdown
                value={this.state.blood_analysis}
                className={
                  "form-control "
                  // +
                  // (validation.blood_analysis.isInvalid && "has-error")
                }
                placeholder="Select Blood Biomarker Package"
                onChange={(event, value) => {
                  this.handleBloodAnalysis(value.value)
                }}
                search
                selection
                options={this.state.country && this.state.bloodWorkPackagesOptions}
                multiple
                lazyLoad={true}
              // disabled={}
              /> */}
              {/* <span className="help-block">{validation.blood_analysis.message}</span> */}
            </div>
          </div>
        </form>

        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Custom Packages
            </div>
            <div className="">
            <Select
                isMulti
                key={`select_custom_packages${selectedCustomPackages.join()}`}
                defaultValue={selectedCustomPackages.map(this.findCustomPackageById).map(this.createCustomPackageOption)}
                onChange={(event) => this.handleCustomePackages(event.map(it => it.value))}
                name="custom_packages"
                options={customPackagesOptions}
                styles={selectStyles}
                placeholder="Select Custom Packages"
              />
              {/* <Dropdown
                value={this.state.custom_package}
                className={
                  "form-control "
                  // +
                  // (validation.custom_package.isInvalid && "has-error")
                }
                placeholder="Select Custom Package"
                onChange={(event, value) => {
                  this.handleCustomePackages(value.value)
                }}
                search
                selection
                options={this.state.country && this.state.customPackagesOptions}
                multiple
                lazyLoad={true}
              // disabled={}
              /> */}
              {/* <span className="help-block">{validation.custom_package.message}</span> */}
            </div>
          </div>
        </form>
      </>
    );


    const Labform = (
      <>
        <div className="form-group mb-4">
          <label style={{ marginRight: "24px" }}>Name : </label>
          <input
            type="text"
            name="name"
            className={
              "form-control "
              + (labValidation.name.isInvalid && "has-error")
            }
            placeholder="Name"
            onChange={this.handleChange}
            value={this.state.name}
          ></input>
          <span className="help-block">{labValidation.name.message}</span>
        </div>
        
        <div className="form-group-container">
          <div className="form-group pl-0">
            <label htmlFor="contact_number">Contact Number*</label>
            <div className="user-input-container non-editable-phone`">
              <div className="non-editable-phone">
              <PhoneInput
                value={this.state.contact_number ?? ""}
                onChange={(value, data) =>
                  this.handlePhoneNumberChange(value, data)
                }
                inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                className={"form-cotrol user-data-input contact-no non-editable"}
                autocompleteSearch
                autoFormat
              />
              </div>
              <span className="help-block">{labValidation?.contact_number?.message ?? ""}</span>
            </div>
          </div>
          <div className="form-group pr-0">
            <label htmlFor="location">Location*</label>
            <textarea
              value={this.state.location ?? ""}
              onChange={this.handleChange}
              id="location"
              name="location"
              placeholder="Lab Location"
              className={"form-control py-2"}
            ></textarea>
            <span className="help-block">{labValidation?.location?.message ?? ""}</span>
          </div>
        </div>

        <form onSubmit={(event) => console.log("yes-->")}>
          <div className="form-group">
            <div className="mt-2 mr-3">
              Lab Profiles:
            </div>
            <div className="">
              <Dropdown
                value={this.state.profile}
                className={
                  "form-control "
                  // +
                  // (validation.blood_analysis.isInvalid && "has-error")
                }
                placeholder="Select Lab Profiles"
                onChange={(event, value) => {
                  this.handleLabProfiles(value.value)
                }}
                search
                selection
                options={this.state.dropDownList}
                multiple
              // disabled={}
              />
              {/* <span className="help-block">{validation.blood_analysis.message}</span> */}
            </div>
          </div>
        </form>
      </>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <>
        <div className="nutritionist">
          <div className="page-header">
            {/* <h5>Lab Profiles</h5> */}
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={this.state.searchLabExpanded}
                  searchTerm={this.state.searchLabTerm}
                  clearSearch={this.clearLabSearch}
                  handleSearch={this.handleLabSearch}
                  handleSearchExit={this.handleLabSearchExit}
                  ref={input => {
                    this.labSearch = input;
                  }}
                ></Search>
              </div>
              {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Deactivated" ? "active" : ""}
                    onClick={() => this.setShowItems("deactivated")}
                  >
                    {showItems === "Deactivated" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Deactivated
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}

              <div className="action-item">
                <button
                  className="btn btn-sm button text-button "
                  onClick={this.showLabModal}
                  disabled={!isEditable}
                >
                  <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                  add new
                </button>
              </div>
            </div>
          </div>
          <Card>
            <DataTable
              highlightOnHover
              columns={this.state.labColumns ?? []}
              data={finalLabList}
              defaultSortField="lab_name"
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              responsive
              noHeader={searchLabApplied ? false : true}
              title={
                searchLabApplied ? (
                  <p className="table-header">
                    {"Found " + resultLabCount + " results in '" + showItems + "'"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal size="lg" show={this.state.showLabModal} onHide={this.hideLabModal} className="user-data-modal">
            <Modal.Header closeButton>
              <Modal.Title>Lab Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>{Labform}</Modal.Body>
            <Modal.Footer>
              <button
                onClick={this.createLab}
                className="btn button button-green"
              >
                Submit
              </button>
              <button
                className="btn btn-secondary button"
                onClick={this.hideLabModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showLabConfirm} onHide={this.hideLabConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.confirmTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.confirmMessage}</Modal.Body>
            <Modal.Footer>
              {this.state.confirmType === "danger" ? (
                <button
                  onClick={() => this.toggleLabActiveStatus(this.state.confirmItem)}
                  className="btn button btn-danger"
                >
                  Deactivate
                </button>
              ) : (
                <button
                  onClick={() => this.toggleLabActiveStatus(this.state.confirmItem)}
                  className="btn button btn-success"
                >
                  Activate
                </button>
              )}
              <button
                className="btn btn-secondary button"
                onClick={this.hideLabConfirm}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>


        <div className="nutritionist">
          <div className="page-header">
            <h5>Lab Profiles</h5>
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={this.state.searchExpanded}
                  searchTerm={this.state.searchTerm}
                  clearSearch={this.clearSearch}
                  handleSearch={this.handleSearch}
                  handleSearchExit={this.handleSearchExit}
                  ref={input => {
                    this.search = input;
                  }}
                ></Search>
              </div>
              {/* <div className="action-item select-menu">
      <ActionBar alignRight className="valeo-dropdown">
        <ActionBar.Toggle variant="">
          {this.state.showItems}
        </ActionBar.Toggle>
        <ActionBar.Menu>
          <ActionBar.Header>Show</ActionBar.Header>
          <ActionBar.Item
            className={showItems === "All" ? "active" : ""}
            onClick={() => this.setShowItems("all")}
          >
            {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
          </ActionBar.Item>
          <ActionBar.Item
            className={showItems === "Active" ? "active" : ""}
            onClick={() => this.setShowItems("active")}
          >
            {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
            Active
          </ActionBar.Item>
          <ActionBar.Item
            className={showItems === "Deactivated" ? "active" : ""}
            onClick={() => this.setShowItems("deactivated")}
          >
            {showItems === "Deactivated" ? (
              <CheckIcon></CheckIcon>
            ) : null}
            Deactivated
          </ActionBar.Item>
        </ActionBar.Menu>
      </ActionBar>
    </div> */}

              <div className="action-item">
                <button
                  className="btn btn-sm button text-button "
                  onClick={this.showModal}
                  disabled={!isEditable}
                >
                  <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                  add new
                </button>
              </div>
            </div>
          </div>
          <Card>
            <DataTable
              highlightOnHover
              columns={this.state.columns ?? []}
              data={finalList}
              defaultSortField="lab_name"
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + resultCount + " results in '" + showItems + "'"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>

          <Modal size="lg" show={this.state.showModal} onHide={this.hideModal} className="user-data-modal">
            <Modal.Header closeButton>
              <Modal.Title>Lab Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>{form}</Modal.Body>
            <Modal.Footer>
              <button
                onClick={this.createLabProfile}
                className="btn button button-green"
              >
                Submit
              </button>
              <button
                className="btn btn-secondary button"
                onClick={this.hideModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
            <Modal.Header closeButton>
              <Modal.Title>{this.state.confirmTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.confirmMessage}</Modal.Body>
            <Modal.Footer>
              {this.state.confirmType === "danger" ? (
                <button
                  onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                  className="btn button btn-danger"
                >
                  Deactivate
                </button>
              ) : (
                <button
                  onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                  className="btn button btn-success"
                >
                  Activate
                </button>
              )}
              <button
                className="btn btn-secondary button"
                onClick={this.hideConfirm}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nutritionist);
