import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

SelectBloodPackages.propTypes = {
  bloodPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedBloodPackageIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectBloodPackages.defaultProps = {
  bloodPackages: [],
  selectedBloodPackageIds: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectBloodPackages(props) {
  const {
    bloodPackages,
    selectedBloodPackageIds,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findBloodPackage = (id) => bloodPackages.find((it) => it.id === id);

  const options = createOptions(bloodPackages, showOnlyActive);

  const selectedBloodPackages = selectedBloodPackageIds
    .map(findBloodPackage)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedBloodPackageIds && selectedBloodPackageIds.join(",")
      }`}
      value={selectedBloodPackages}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(bloodPackages, showOnlyActive) {
  if (!bloodPackages || !bloodPackages.length) return [];

  const [activeBloodPackages, inactiveBloodPackages] = _.partition(
    bloodPackages,
    (it) => it.is_active ?? false
  );
  activeBloodPackages.sort((first, second) =>
    compareStrings(first.internal_name, second.internal_name, true)
  );

  if (showOnlyActive) return activeBloodPackages.map(createOption);

  inactiveBloodPackages.sort((first, second) =>
    compareStrings(first.internal_name, second.internal_name, true)
  );
  return [
    {
      label: "Active",
      options: activeBloodPackages.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveBloodPackages.map(createOption),
    },
  ];
}

function createOption(bloodPackage) {
  if (!bloodPackage) return;
  return {
    key: bloodPackage.id,
    value: bloodPackage.id,
    label: bloodPackage.internal_name,
  };
}

export default SelectBloodPackages;
