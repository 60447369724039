import PhoenixAPI from "utils/PhoenixAPI";

export const saveQuestion = async (question) => {
    try {
      const payload = { ...question };
      const response = await PhoenixAPI.post(`problematic-biomarker-questions-answers`, payload);
      return response;
    } catch (error) {
      console.log("Error on creating other-item", error);
      throw error;
    }
  };
  
  export const updateQuestion = async (question) => {
    try {
      const payload = { ...question };
      const response = await PhoenixAPI.patch(`problematic-biomarker-questions-answers/${question.id}`,payload);
      return response;
    } catch (error) {
      console.log("Error on creating other-item", error);
      throw error;
    }
  };