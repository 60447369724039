import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import AnswersForm from "./AnswersForm";
import { fetchCountries } from "services/RegionService";
import {
  fetchFitnessPlans,
  updateFitnessQuestion,
  saveFitnessQuestion,
} from "services/FitnessPlansService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import RichTextEditor from "react-rte";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const ANSWER_TYPE_OPTIONS = [
  {
    key: "Single Select",
    value: "Single Select",
    text: "Single Select",
  },
  {
    key: "Multi Select",
    value: "Multi Select",
    text: "Multi Select",
  },
  // {
  //   key: "Plain Text",
  //   value: "Plain Text",
  //   text: "Plain Text",
  // },
  {
    key: "List of Heights",
    value: "List of Heights",
    text: "List of Heights",
  },
  {
    key: "List of Weights",
    value: "List of Weights",
    text: "List of Weights",
  },
  {
    key: "List of Inches",
    value: "List of Inches",
    text: "List of Inches",
  },
];

class FitnessPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      fitnessQuestions: [],
      fitnessDefaultValues: [],
      rankOptions: [],
      fitnessQuestion: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isFitnessQuestionViewModalVisible: false,
      informationEngRichTextValue: RichTextEditor.createEmptyValue(),
      informationArRichTextValue: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "Questions ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Question English",
        selector: "questionEng",
        wrap: true,
        sortable: true,
      },
      {
        name: "Question Arabic",
        selector: "questionAr",
        wrap: true,
        sortable: true,
        right: true,
      },
      {
        name: "Rank",
        selector: "rank",

        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "isActive",

        center: true,
        cell: (fitnessQuestion) => (
          <Status isActive={fitnessQuestion.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (fitnessQuestion) => this.renderViewButton(fitnessQuestion.id),
      },
    ];

    this.fitnessDefaultValuesColumns = [
      {
        name: "Default Steps",
        selector: "defaultSteps",
        sortable: true,
      },
      {
        name: "Default Calories Burn",
        selector: "defaultCalorie",
        sortable: true,
      },
      {
        name: "Default Workout Time",
        selector: "defaultWorkoutTime",
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",

        center: true,
        cell: (fitnessDefaultValue) => (
          <Status isActive={fitnessDefaultValue.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (fitnessDefaultValue) =>
          this.renderFitnessDefaultView(fitnessDefaultValue.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchFitnessPlans();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.FITNESSPLAN;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchFitnessPlans = async () => {
    this.showLoading();
    try {
      const fitnessPlans = (await fetchFitnessPlans()) || [];
      const { fitnessQuestions, fitnessDefaultValues } = fitnessPlans;
      console.log("fitnessPlans", fitnessPlans);
      this.setState({ fitnessQuestions, fitnessDefaultValues });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching fitnessPlans: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const fitnessQuestion = { ...this.state.fitnessQuestion };

    fitnessQuestion[input.name] = input.value;
    this.setState({ fitnessQuestion });
  };

  handleChangeDefaultValue = ({ currentTarget: input }) => {
    const numberInputs = ["defaultSteps", "defaultCalorie"];
    const fitnessDefaultValue = { ...this.state.fitnessDefaultValue };
    if (numberInputs.includes(input.name))
      fitnessDefaultValue[input.name] = input.value ? Number(input.value) : "";
    else fitnessDefaultValue[input.name] = input.value;
    this.setState({ fitnessDefaultValue });
  };

  handleAnswersUpdated = (answers = []) => {
    const fitnessQuestion = {
      ...this.state.fitnessQuestion,
      fitnessAnswers: answers,
    };
    this.setState({ fitnessQuestion });
  };

  handleAddNewFitnessQuestion = () => {
    this.setState({ isNewFitnessQuestion: true }, () =>
      this.showFitnessQuestionViewModal()
    );
  };

  handleViewFitnessQuestion = (questionsId) => {
    const fitnessQuestions = this.state.fitnessQuestions ?? [];
    const fitnessQuestion = fitnessQuestions.find(
      (it) => it.id === questionsId
    );
    if (!fitnessQuestion) {
      console.log(`Fitness Question with id ${questionsId} is not found!`);
      return;
    }

    const informationEngRichTextValue = RichTextEditor.createValueFromString(
      fitnessQuestion.informationEng ?? "",
      "html"
    );
    const informationArRichTextValue = RichTextEditor.createValueFromString(
      fitnessQuestion.informationAr ?? "",
      "html"
    );
    this.setState(
      {
        fitnessQuestion,
        isNewFitnessQuestion: false,
        informationEngRichTextValue,
        informationArRichTextValue,
      },
      () => this.showFitnessQuestionViewModal()
    );
  };

  handleFitnessDefaultView = (defaultId) => {
    const fitnessDefaultValues = this.state.fitnessDefaultValues ?? [];
    const fitnessDefaultValue = fitnessDefaultValues.find(
      (it) => it.id === defaultId
    );
    if (!fitnessDefaultValue) {
      console.log(`Fitness Default Value with id ${defaultId} is not found!`);
      return;
    }

    this.setState({ fitnessDefaultValue, isNewDefaultValue: false }, () =>
      this.showFitnessDefaultViewModal()
    );
  };

  handleAddNewFitnessDefaultValues = () => {
    const { fitnessDefaultValues, selectedCountry } = this.state;
    const fitnessDefaultValueSelectedCountry =
      fitnessDefaultValues && selectedCountry
        ? fitnessDefaultValues.find((p) => p.countryId === selectedCountry.id)
        : null;

    if (fitnessDefaultValueSelectedCountry) {
      const message = fitnessDefaultValueSelectedCountry.isActive
        ? "There is already an active Default Fitness Value is available."
        : "Please activate Default Fitness Value.";
      this.showErrorNotification(message);
    } else {
      this.setState({ isNewDefaultValue: true }, () =>
        this.showFitnessDefaultViewModal()
      );
    }
  };

  showFitnessQuestionViewModal = () => {
    const { selectedCountry, isNewFitnessQuestion } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewFitnessQuestion) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const fitnessQuestion = {
        countryId: selectedCountry?.id,

        isActive: false,
        rank,
      };
      this.setState({
        fitnessQuestion,
        rankOptions,
        isFitnessQuestionViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isFitnessQuestionViewModalVisible: true });
    }
  };

  showFitnessDefaultViewModal = () => {
    const { selectedCountry, isNewDefaultValue } = this.state;
    if (isNewDefaultValue) {
      const fitnessDefaultValue = {
        countryId: selectedCountry?.id,
        isActive: false,
      };
      this.setState({
        fitnessDefaultValue,
        isFitnessDefaultViewModalVisible: true,
      });
    } else {
      this.setState({ isFitnessDefaultViewModalVisible: true });
    }
  };
  hideFitnessQuestionViewModal = () => {
    this.setState({ isFitnessQuestionViewModalVisible: false });
    this.resetForm();
  };

  hideFitnessDefaultViewModal = () => {
    this.setState({ isFitnessDefaultViewModalVisible: false });
  };

  handleSaveFitnessQuestion = async () => {
    const {
      selectedCountry,
      fitnessQuestion,
      informationEngRichTextValue,
      informationArRichTextValue,
    } = this.state;

    fitnessQuestion.informationEng =
      informationEngRichTextValue?.toString("html");
    fitnessQuestion.informationAr =
      informationArRichTextValue?.toString("html");
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        fitnessQuestion.id !== null && fitnessQuestion.id !== undefined;

      const response = isValidId
        ? await updateFitnessQuestion(fitnessQuestion)
        : await saveFitnessQuestion(fitnessQuestion);

      this.hideFitnessQuestionViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Fitness Question Updated Successfully."
          : "Fitness Question Created Successfully."
      );
      this.fetchFitnessPlans();
    } catch (error) {
      console.log(
        "Error occurred in saving fitness plan question",
        error.message
      );
      this.showErrorNotification(
        "Error occurred in saving fitness plan question",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleSaveFitnessDefaultValue = async () => {
    const { fitnessDefaultValue } = this.state;
    const { createdAt, modifiedAt, ...payload } = fitnessDefaultValue;
    const isValidId =
      fitnessDefaultValue.id !== null && fitnessDefaultValue.id !== undefined;
    if (!isValidId) {
      try {
        const response = await PhoenixAPI.post(
          "fitness-default-values",
          payload
        );
        console.log("response", response);
        this.hideFitnessDefaultViewModal();
        this.fetchFitnessPlans();
      } catch (error) {
        console.log("Error in saving fitness-default-values", error.message);
        this.showErrorNotification(
          "Error in saving fitness-default-values",
          error.message
        );
      }
    } else {
      try {
        const response = await PhoenixAPI.patch(
          `fitness-default-values/${fitnessDefaultValue.id}`,
          payload
        );
        console.log("response", response);
        this.hideFitnessDefaultViewModal();
        this.fetchFitnessPlans();
      } catch (error) {
        console.log("Error in saving fitness-default-values", error.message);
        this.showErrorNotification(
          "Error in saving fitness-default-values",
          error.message
        );
      }
    }
  };
  handleRoutineValue = (value, routine) => {
    this.setState({ [routine]: value });
  };

  validate = () => {
    const { fitnessQuestion } = this.state;
    const errors = {};

    const { status, questionEng, questionAr, answerType, rank } =
      fitnessQuestion;

    if (status === null) errors.status = "Status is a required field!";

    if (!questionEng || questionEng.trim() === "")
      errors.questionEng = "Question English cannot be blank";
    else if (questionEng.length > 100)
      errors.questionEng = "Question English limited to a 100 characters";

    if (!questionAr || questionAr.trim() === "")
      errors.questionAr = "Question Arabic cannot be blank";
    else if (questionAr.length > 100)
      errors.questionAr = "Question Arabic  is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (!answerType) errors.answerType = "Answer Type cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewFitnessQuestion } = this.state;
    const fitnessPlans = this.getFitnessQuestionsForCurrentCountry();
    const ranks = fitnessPlans.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewFitnessQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      fitnessQuestion: {},
      errors: {},
      editables: {},
      informationEngRichTextValue: RichTextEditor.createEmptyValue(),
      informationArRichTextValue: RichTextEditor.createEmptyValue(),
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewFitnessQuestion(id);
        }}
      >
        View
      </button>
    </div>
  );

  renderFitnessDefaultView = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleFitnessDefaultView(id);
        }}
      >
        Update
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable &&
    (!this.state.isNewFitnessQuestion || !this.state.isNewDefaultValue) ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  renderPencilIconForDefaultValue = (fieldName) =>
    this.state.isEditable && !this.state.isNewDefaultValue ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredFitnessQuestions = () => {
    const fitnessQuestions = this.getFitnessQuestionsForCurrentCountry();
    return this.getSearchedFitnessQuestions(fitnessQuestions);
  };

  getFilteredFitnessDefaultValues = () => {
    const fitnessDefaultValues =
      this.getFitnessDefaultValuesForCurrentCountry();
    return fitnessDefaultValues;
  };

  getFitnessQuestionsForCurrentCountry = () => {
    const { fitnessQuestions, selectedCountry } = this.state;
    return fitnessQuestions && selectedCountry
      ? fitnessQuestions.filter(
          (fitnessQuestion) => fitnessQuestion.countryId === selectedCountry.id
        )
      : [];
  };

  getFitnessDefaultValuesForCurrentCountry = () => {
    const { fitnessDefaultValues, selectedCountry } = this.state;
    return fitnessDefaultValues && selectedCountry
      ? fitnessDefaultValues.filter(
          (fitnessDefaultValue) =>
            fitnessDefaultValue.countryId === selectedCountry.id
        )
      : [];
  };

  getSearchedFitnessQuestions = (fitnessPlans) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return fitnessPlans;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return fitnessPlans.filter((fitnessQuestion) => {
      const { id, questionEng, questionAr, rank } = fitnessQuestion;
      return (
        (questionEng && questionEng.toLowerCase().indexOf(newValue) !== -1) ||
        (questionAr && questionAr.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      fitnessQuestion,
      isNewFitnessQuestion,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      fitnessQuestions,
      fitnessDefaultValues,
      fitnessDefaultValue,
      isNewDefaultValue,
    } = this.state;

    const filteredFitnessQuestions =
      this.getFilteredFitnessQuestions(fitnessQuestions) ?? [];

    const filteredFitnessDefaultValues =
      this.getFilteredFitnessDefaultValues(fitnessDefaultValues) ?? [];
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="questionEng">Question English*</label>
            <div className="input-group">
              <input
                value={fitnessQuestion?.questionEng || ""}
                onChange={this.handleChange}
                id="questionEng"
                name="questionEng"
                type="text"
                placeholder="Enter Question in English"
                className={
                  "form-control py-2" + (errors.questionEng ? " has-error" : "")
                }
                disabled={!isNewFitnessQuestion && !editables.questionEng}
              />
              {this.renderPencilIcon("questionEng")}
            </div>
            <span className="help-block">{errors.questionEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="questionAr">Question (Arabic)*</label>
            <div className="input-group">
              <input
                value={fitnessQuestion?.questionAr || ""}
                onChange={this.handleChange}
                id="questionAr"
                name="questionAr"
                type="text"
                placeholder="Enter Question in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.questionAr ? " has-error" : "")
                }
                disabled={!isNewFitnessQuestion && !editables.questionAr}
              />
              {this.renderPencilIcon("questionAr")}
            </div>
            <span className="help-block">{errors.questionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label htmlFor="morningRoutine">Information (English)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.informationEngRichTextValue}
                onChange={(value) =>
                  this.handleRoutineValue(value, "informationEngRichTextValue")
                }
                disabled={
                  !isNewFitnessQuestion &&
                  !editables.informationEngRichTextValue
                }
              />
              {this.renderPencilIcon("informationEngRichTextValue")}
            </div>
            <span className="help-block">
              {errors.informationEngRichTextValue}
            </span>
          </div>
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label htmlFor="morningRoutine">Information (Arabic)</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.informationArRichTextValue}
                onChange={(value) =>
                  this.handleRoutineValue(value, "informationArRichTextValue")
                }
                disabled={
                  !isNewFitnessQuestion && !editables.informationArRichTextValue
                }
              />
              {this.renderPencilIcon("informationArRichTextValue")}
            </div>
            <span className="help-block">
              {errors.informationArRichTextValue}
            </span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={fitnessQuestion?.rank}
                onChange={(event, value) => {
                  this.setState({
                    fitnessQuestion: { ...fitnessQuestion, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewFitnessQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={fitnessQuestion?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    fitnessQuestion: {
                      ...fitnessQuestion,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewFitnessQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="answerType">Answer Type*</label>
            <div className="input-group">
              <Dropdown
                value={fitnessQuestion?.answerType}
                onChange={(event, value) => {
                  this.setState({
                    fitnessQuestion: {
                      ...fitnessQuestion,
                      answerType: value.value,
                    },
                  });
                }}
                id="answerType"
                name="answerType"
                placeholder="Answer Type"
                search
                selection
                options={ANSWER_TYPE_OPTIONS}
                disabled={!isNewFitnessQuestion}
                className={
                  "form-control" + (errors.answerType ? " has-error" : "")
                }
              />
            </div>
            <span className="help-block">{errors.answerType}</span>
          </div>
          <div className="form-group col">
            <div className="input-group" style={{ marginTop: "30px" }}>
              <input
                value={true}
                checked={
                  (fitnessQuestion?.isMandatory &&
                    fitnessQuestion?.isMandatory) ??
                  false
                }
                onChange={(event, value) => {
                  this.setState({
                    fitnessQuestion: {
                      ...fitnessQuestion,
                      isMandatory: event.target.checked,
                    },
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
              <label style={{ marginLeft: "10px" }}>Is Mandatory</label>
            </div>
          </div>
        </div>
        {fitnessQuestion?.answerType == "Single Select" ||
        fitnessQuestion?.answerType == "Multi Select" ? (
          <AnswersForm
            answers={fitnessQuestion?.fitnessAnswers || []}
            onAnswerUpdated={this.handleAnswersUpdated}
            isEditable={isEditable}
          />
        ) : (
          ""
        )}
      </div>
    );

    const formFitnessDefaultValue = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="defaultSteps">Default Steps</label>
            <div className="input-group">
              <input
                value={fitnessDefaultValue?.defaultSteps || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultSteps"
                name="defaultSteps"
                type="number"
                placeholder="Enter Default Steps"
                className={
                  "form-control py-2" +
                  (errors.defaultSteps ? " has-error" : "")
                }
                disabled={!isNewDefaultValue && !editables.defaultSteps}
              />
              {this.renderPencilIconForDefaultValue("defaultSteps")}
            </div>
            <span className="help-block">{errors.defaultSteps}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={fitnessDefaultValue?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    fitnessDefaultValue: {
                      ...fitnessDefaultValue,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewDefaultValue && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIconForDefaultValue("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="defaultStepsPromotionTextEng">
              Default Steps Promotion Text (English)
            </label>
            <div className="input-group">
              <textarea
                value={fitnessDefaultValue?.defaultStepsPromotionTextEng || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultStepsPromotionTextEng"
                name="defaultStepsPromotionTextEng"
                type="text"
                placeholder="Enter DefaultSteps Promotion Text in English"
                className={
                  "form-control py-2" +
                  (errors.defaultStepsPromotionTextEng ? " has-error" : "")
                }
                disabled={
                  !isNewDefaultValue && !editables.defaultStepsPromotionTextEng
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultStepsPromotionTextEng"
              )}
            </div>
            <span className="help-block">
              {errors.defaultStepsPromotionTextEng}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="defaultStepsPromotionTextAr">
              Default Steps Promotion Text (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={fitnessDefaultValue?.defaultStepsPromotionTextAr || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultStepsPromotionTextAr"
                name="defaultStepsPromotionTextAr"
                type="text"
                placeholder="Enter Default Steps Promotion Text in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.defaultStepsPromotionTextAr ? " has-error" : "")
                }
                disabled={
                  !isNewDefaultValue && !editables.defaultStepsPromotionTextAr
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultStepsPromotionTextAr"
              )}
            </div>
            <span className="help-block">
              {errors.defaultStepsPromotionTextAr}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="defaultCalorie">Default Calorie</label>
            <div className="input-group">
              <input
                value={fitnessDefaultValue?.defaultCalorie || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultCalorie"
                name="defaultCalorie"
                type="number"
                placeholder="Enter Default Calorie"
                className={
                  "form-control py-2" +
                  (errors.defaultCalorie ? " has-error" : "")
                }
                disabled={!isNewDefaultValue && !editables.defaultCalorie}
              />
              {this.renderPencilIconForDefaultValue("defaultCalorie")}
            </div>
            <span className="help-block">{errors.defaultCalorie}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="defaultCaloriePromotionTextEng">
              Default Calorie Promotion Text (English)
            </label>
            <div className="input-group">
              <textarea
                value={
                  fitnessDefaultValue?.defaultCaloriePromotionTextEng || ""
                }
                onChange={this.handleChangeDefaultValue}
                id="defaultCaloriePromotionTextEng"
                name="defaultCaloriePromotionTextEng"
                type="text"
                placeholder="Enter Default Calorie Promotion Text in English"
                className={
                  "form-control py-2" +
                  (errors.defaultCaloriePromotionTextEng ? " has-error" : "")
                }
                disabled={
                  !isNewDefaultValue &&
                  !editables.defaultCaloriePromotionTextEng
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultCaloriePromotionTextEng"
              )}
            </div>
            <span className="help-block">
              {errors.defaultCaloriePromotionTextEng}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="defaultCaloriePromotionTextAr">
              Default Calorie Promotion Text (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={fitnessDefaultValue?.defaultCaloriePromotionTextAr || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultCaloriePromotionTextAr"
                name="defaultCaloriePromotionTextAr"
                type="text"
                placeholder="Enter Default Calorie Promotion Text in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.defaultCaloriePromotionTextAr ? " has-error" : "")
                }
                disabled={
                  !isNewDefaultValue && !editables.defaultCaloriePromotionTextAr
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultCaloriePromotionTextAr"
              )}
            </div>
            <span className="help-block">
              {errors.defaultCaloriePromotionTextAr}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="defaultWorkoutTime">Default Workout Time</label>
            <div className="input-group">
              <input
                value={fitnessDefaultValue?.defaultWorkoutTime || ""}
                onChange={this.handleChangeDefaultValue}
                id="defaultWorkoutTime"
                name="defaultWorkoutTime"
                type="text"
                placeholder="Enter Default Workout Time"
                className={
                  "form-control py-2" +
                  (errors.defaultWorkoutTime ? " has-error" : "")
                }
                disabled={!isNewDefaultValue && !editables.defaultWorkoutTime}
              />
              {this.renderPencilIconForDefaultValue("defaultWorkoutTime")}
            </div>
            <span className="help-block">{errors.defaultWorkoutTime}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="defaultWorkoutTimePromotionTextEng">
              Default Workout Time PromotionText (English)
            </label>
            <div className="input-group">
              <textarea
                value={
                  fitnessDefaultValue?.defaultWorkoutTimePromotionTextEng || ""
                }
                onChange={this.handleChangeDefaultValue}
                id="defaultWorkoutTimePromotionTextEng"
                name="defaultWorkoutTimePromotionTextEng"
                type="text"
                placeholder="Enter Default WorkoutTime Promotion Text English"
                className={
                  "form-control py-2" +
                  (errors.defaultWorkoutTimePromotionTextEng
                    ? " has-error"
                    : "")
                }
                disabled={
                  !isNewDefaultValue &&
                  !editables.defaultWorkoutTimePromotionTextEng
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultWorkoutTimePromotionTextEng"
              )}
            </div>
            <span className="help-block">
              {errors.defaultWorkoutTimePromotionTextEng}
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor="defaultWorkoutTimePromotionTextAr">
              Default Workout Time Promotion Text (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={
                  fitnessDefaultValue?.defaultWorkoutTimePromotionTextAr || ""
                }
                onChange={this.handleChangeDefaultValue}
                id="defaultWorkoutTimePromotionTextAr"
                name="defaultWorkoutTimePromotionTextAr"
                type="text"
                placeholder="Enter Default Workout Time Promotion Text in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.defaultWorkoutTimePromotionTextAr ? " has-error" : "")
                }
                disabled={
                  !isNewDefaultValue &&
                  !editables.defaultWorkoutTimePromotionTextAr
                }
              />
              {this.renderPencilIconForDefaultValue(
                "defaultWorkoutTimePromotionTextAr"
              )}
            </div>
            <span className="help-block">
              {errors.defaultWorkoutTimePromotionTextAr}
            </span>
          </div>
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewFitnessQuestion}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredFitnessQuestions ?? []}
            columns={this.columns}
            defaultSortField="id"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + fitnessQuestions.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.isFitnessQuestionViewModalVisible}
          onHide={this.hideFitnessQuestionViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>questionEng: </span>
                {fitnessQuestion?.questionEng || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveFitnessQuestion}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{formBasicInformation}</Modal.Body>
        </Modal>

        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <button
                onClick={this.handleAddNewFitnessDefaultValues}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredFitnessDefaultValues ?? []}
            columns={this.fitnessDefaultValuesColumns}
            defaultSortField="isActive"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + fitnessDefaultValues.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.isFitnessDefaultViewModalVisible}
          onHide={this.hideFitnessDefaultViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {/* <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>questionEng: </span>
                {fitnessQuestion?.questionEng || ""}
              </p> */}
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveFitnessDefaultValue}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{formFitnessDefaultValue}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FitnessPlan);
