import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";
import { Modal } from "react-bootstrap";

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];
function EditPaymentInfoModal(props) {
  const { paymentInfo, isVisible, onHide, onSave } = props;
  const [discount, setDiscount] = useState(paymentInfo.discount ?? null);
  const [subtotal, setSubtotal] = useState(paymentInfo.subtotal ?? null);
  const [total, setTotal] = useState(paymentInfo.total ?? null);

  const handleSave = (event) => {
    onSave({ discount, subtotal, total });
  };

  const form = (
    <>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="discount">Discount</label>
          <Dropdown
            value={discount}
            onChange={(event, value) => setDiscount(value.value)}
            id="discount"
            name="discount"
            placeholder="Discount"
            search
            selection
            options={DISCOUNT_TYPE_OPTIONS}
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="subtotal">Subtotal</label>
          <input
            value={subtotal ?? ""}
            onChange={(event) =>
              setSubtotal(event.target.valueAsNumber ?? null)
            }
            id="subtotal"
            name="subtotal"
            type="number"
            placeholder="Subtotal"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="total">Total</label>
          <input
            value={total ?? ""}
            onChange={(event) => setTotal(event.target.valueAsNumber ?? null)}
            id="total"
            name="total"
            type="number"
            placeholder="Total"
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col text-right">
          <button
            onClick={onHide}
            type="button"
            className="btn btn-outline-secondary"
          >
            Cancel
          </button>
          <button
            onClick={handleSave}
            type="button"
            className="btn btn-outline-success ml-2"
          >
            Save
          </button>
        </div>
      </div>
    </>
  );

  return (
    <Modal show={isVisible} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <p style={{ fontSize: "1rem" }}>Edit payment information</p>
        </Modal.Title>
        <div className="user-title"></div>
      </Modal.Header>
      <Modal.Body>{form}</Modal.Body>
    </Modal>
  );
}

EditPaymentInfoModal.propTypes = {
  paymentInfo: PropTypes.object.isRequired,
  isVisible: PropTypes.bool,
  onHide: PropTypes.func,
  onSave: PropTypes.func,
};

EditPaymentInfoModal.defaultProps = {
  paymentInfo: {},
  isVisible: false,
  onHide: (f) => f,
  onSave: (f) => f,
};

export default EditPaymentInfoModal;
