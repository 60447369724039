import React, { Component,useEffect,useState } from "react";
const bucketList=
[
  {bucketCount: 30, bucketName: "Sample Collected by Homecare",bucketStatus:"SAMPLE COLLECTED BY HOMECARE"},
  { bucketCount: 30, bucketName: "Report Upload Pending",bucketStatus:"pending" },
{ bucketCount: 40, bucketName: "Partial Report Upload" ,bucketStatus:"PARTIAL RESULTS UPLOADED TO APP"},
{ bucketCount: 100, bucketName: "Coach Review Pending",bucketStatus:"RESULTS UPLOADED TO APP" },

]



export function RenderBucketList(props){
  const{labOrderReportStatus,pendingOrdersCount,partialOrdersCount,UploadCompletedOrdersCount,updateOrdersLabReportStatus, sampleCollectedCount}=props;
  const[bucketStatus,setBucketStatus]=useState(labOrderReportStatus);
  const [selectedBucketIndex, setSelectedBucketIndex] = useState(1);


 const handleButtonClick = (index) => {
  // Update the selected button index when a button is clicked
  setSelectedBucketIndex(index);
  // Perform other actions here if needed
};

const renderCount=(index)=>{
  const{pendingOrdersCount,partialOrdersCount,UploadCompletedOrdersCount,sampleCollectedCount}=props;
   if(index===0){
    return sampleCollectedCount
   }
   if(index===1){
    return pendingOrdersCount
   }
   if(index===2){
    return partialOrdersCount
   }
   if(index===3){
    return UploadCompletedOrdersCount
   }
}
 
    return(
     <div style={{
       display: "flex",
     flexWrap: "wrap",
     marginTop:"40px",
     marginBottom: "100px",
     gap: "20px",
     }}>
      {bucketList.map((item,index)=>{
       return(
         <button className="" style={{ height:"125px", width:"120px" , border:"none", background:"none", padding: 0}}  onClick={(event)=>{setBucketStatus(item.bucketStatus);console.log("bucket status",item.bucketStatus);handleButtonClick(index);updateOrdersLabReportStatus(item.bucketStatus);}}>
         <div className="" style={{ display:"flex" , flexDirection:"column"}}>
           <div className="" style={{backgroundColor:"#A0EBEE", border:"2px solid black",padding:"20px",fontWeight:"bold", fontSize:"16px", textAlign:"center"}}>{renderCount(index)}</div>
           <div className="" style={{fontSize:"12px", textAlign:"center", border:"2px solid #DFDFDF", wordWrap:"break-word",fontWeight:"bold",backgroundColor:selectedBucketIndex==index?'#A0EBEE':'#FFFFFF', boxShadow:"0 5px 5px 0 rgba(224,228,238,.55);"}}>{item.bucketName}</div>
         </div>
        </button>
        )
      })}
     </div>
    )
    }