import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./search.scss";
export default function SearchOrders(props) {
  const {
    searchFlag,
    searchValue
  } = props;
  const [inputText, setInputText] = useState("");
  const inputRef = React.createRef();
  const [isShowTicketModal,setIsShowTicketModal]=useState(false)

  function handleChange(e) {
    setInputText(e.target.value)
    searchFlag(true);
    searchValue(e.target.value)
  }
  const handleSearch = async () => {
   
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
    
     
      handleSearch();
    }
  };
  const handleCrossBtn = () => {
    setInputText("");
    searchFlag(false);
    searchValue("")
  };
  const redirectToCreateTicket=()=>{
    return  window.location.href="/dashboard/home-care/create-ticket"
  }
  const redirectToTicketManagement=()=>{
    return window.location.href="/dashboard/homecare-mobile-portal/ticket-management"
  }
  const TicketModal=()=>{
    return(
      <div className="ticket-modal">
      <div
        className="ticket-overlay"
        style={{ overflow: isShowTicketModal ? "hidden" : "" }}
      >
        <div className="ticket-modal-content">
          <div className="ticket-modal-header">
           
          </div>
          <div className="close-modal" onClick={() => setIsShowTicketModal(false)}>
            <img
              src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg"
              alt="cross-icon"
            />
          </div>
          <div className="ticket-modal-body">
          
          <div className="create-ticket-btn"  onClick={()=>redirectToCreateTicket()}>Create Ticket</div>
          
          <div className="create-ticket-btn" onClick={()=>redirectToTicketManagement()}>Ticket Management</div>
         

           
          </div>
        </div>
      </div>
    </div>
    )
  }
  return (
    <>
     <div className="search-container">
      <div className="valeo-logo-img">
        {" "}
        <img
          src="https://d25uasl7utydze.cloudfront.net/assets/valeo_logo%20(1).svg"
          className="mob-image "
        ></img>{" "}
      </div>

      <div className="input-form">
        <input
          ref={inputRef}
          type="search"
          placeholder="Search"
          value={inputText}
          onKeyPress={handleKeyPress}
          onChange={(e) => handleChange(e)}
        />
        <img
          className="search-icon"
          src="https://d25uasl7utydze.cloudfront.net/assets/search.svg"
          alt=""
        />
        {inputText && (
          <img
            className="cross-icon"
            onClick={() => handleCrossBtn()}
            src="https://d25uasl7utydze.cloudfront.net/assets/cross_icon_search.svg"
            alt=""
          />
        )}
      </div>
      <div className="help-ticket" onClick={()=>setIsShowTicketModal(!isShowTicketModal)}>
       <img src="https://d25uasl7utydze.cloudfront.net/assets/question.png"/>
     </div>
    </div>
    {isShowTicketModal && <TicketModal/>}
    </>
   
  );
}
