import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import {
    fetchCoachPackages,
  } from "services/CoachConsultationPackageService";

SelectCoachConsultation.propTypes = {
  countryId: PropTypes.number.isRequired,
  selectedCoachConsultationIds: PropTypes.number.isRequired,
  onCoachConsultationSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectCoachConsultation.defaultProps = {
    selectedCoachConsultationIds: [],
    onCoachConsultationSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectCoachConsultation(props) {
  const {
    countryId,
    selectedCoachConsultationIds,
    onCoachConsultationSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const [coachConsultations, setCoachConsultations] = useState([]);

  useEffect(() => {
    if (!countryId) {
        console.log("Invalid country id!", { countryId });
        return;
      }
    const fetchCoachConsultations = async () => {
      try {
        const coachConsultationsPackages = (await fetchCoachPackages()) || [] ;
         const filteredPackagesForCountry =coachConsultationsPackages && coachConsultationsPackages.filter((item)=>item.countryId===countryId)
        setCoachConsultations(filteredPackagesForCountry);
      } catch (error) {
        console.log("Error on fetching Coach Consultations", error);
      }
    };
    fetchCoachConsultations();
  }, [countryId]);

  const findCoachConsultations = (coachConsultationId) => {
    return coachConsultations.find((coachConsultation) => coachConsultation.id === coachConsultationId);
  };

  const [activeCoachConsultations, inactiveCoachConsultations] = _.partition(
    coachConsultations,
    (it) => it.isActive == true
  );
  activeCoachConsultations.sort((first, second) =>
    compareStrings(first.externalName, second.externalName, true)
  );
  inactiveCoachConsultations.sort((first, second) =>
    compareStrings(first.externalName, second.externalName, true)
  );
  const options = showOnlyActive
    ? activeCoachConsultations.map(createOption)
    : [
        {
          label: "Active",
          options: activeCoachConsultations.map(createOption),
        },
        {
          label: "Inactive",
          options: inactiveCoachConsultations.map(createOption),
        },
      ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };


  const selected= findCoachConsultations(selectedCoachConsultationIds)

  return (
    <Select
      key={`my_unique_select_key__${
        selected && selected.id
      }`}
      value={selected && createOption(selected)}
      onChange={onCoachConsultationSelect}
      options={options}
      styles={selectStyles}
      // placeholder="Select Supplements"
      menuPlacement={menuPlacement}
      
      isDisabled={isDisabled}
    />
  );
}

const createOption = (coachConsultation) => {
  if (!coachConsultation) return;
  return {
    key: coachConsultation.id,
    value: coachConsultation.id,
    label: coachConsultation.externalName,
  };
};

export default SelectCoachConsultation;
