import React,{useEffect,useState} from "react";
import PhoenixAPI from "utils/PhoenixAPI";
const BASE_URL_COUNT_SUBSCRIPTION_ORDERS="/count-subscription-order";
function Analytics(props){
    const { orderId } = props;
    const [details,setDetails]=useState({})

    const getSubscriptionAnalytics=async(orderId)=>{
        const response= await PhoenixAPI.get(`${BASE_URL_COUNT_SUBSCRIPTION_ORDERS}/${orderId}`)
        const data= response && response.data || {};
        setDetails(data)
    } 
    useEffect(()=>{
     if(orderId){
        getSubscriptionAnalytics(orderId)
     }
    },[orderId])
    return (
        <div style={{width:"auto", marginTop:"10px"}}>
          <div>Total Subscription Call: {details.countTotalCall}</div>
          <div>Successful Call: {details.countSuccessfullCall}</div>
        </div>
    )
}

export default Analytics