import React ,{Component}from "react";
import PhoenixAPI from "utils/PhoenixAPI";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import Search from "components/Search/Search";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AlertBox from "components/AlertBox/AlertBox";
import Status from "components/Common/Status";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
    {
      key: "Active",
      value: true,
      text: "Active",
    },
    {
      key: "Inactive",
      value: false,
      text: "Inactive",
    },
  ];

class InsurancePage extends Component {
    constructor(props){
        super(props);
        this.state = {
          rankOptions: [],
          insurances:[],
          insurance: {},
          errors: {},
          editables: {},
          isSaving:false,
          isNewInsurance:false
        }
        this.columns = [
          {
            name: "ID",
            selector: "id",
            width: "100px",
            sortable: true,
          },
          {
            name: "Corporate Name",
            selector: "insuranceCompanyName",
            wrap: true,
            sortable: true,
          },
          {
            name: "Rank",
            selector: "rank",
            wrap: true,
            sortable: true,
          },
          {
            name: "Status",
            selector: "status",
            maxWidth: "150px",
            center: true,
            cell: (row) => <Status isActive={row.isActive ?? false} />,
            sortable: true,
          },
          {
            name: "View",
            center: true,
            cell: (row) => this.renderViewButton(row.id),
          },
        ]
  
      }
      renderViewButton = (id) => (
        <div>
          <button
            onClick={() => this.handleView(id)}
            className="btn px-5"
            style={{ backgroundColor: "#CEE741" }}
          >
            View
          </button>
        </div>
      );
  
  
      async componentDidMount() {
          this.fetchInsurances();
      }
  
      handleSearch = (queryString) => {
          this.setState({
            searchApplied: true,
            searchTerm: queryString ?? "",
          });
        };
      
        handleSearchClear = () => {
          this.setState({ searchTerm: "", searchApplied: false });
          this.search.searchInput.focus();
        };
      
        handleSearchExit = (e) => {
          const value = e.target.value;
          if (!value || !value.length)
            this.setState({ searchTerm: "", searchApplied: false });
        };
      
        handleAddNew = () => {
          this.setState({ isNewInsurance: true }, () =>
            this.showInsuranceViewModal()
          );
        };
        handleView = (id) => {
          const insurances = this.state.insurances || [];
          const insurance= insurances.find((it) => it.id === id);
          if (insurances) {
          //  insurancePartner.partnerLogoSrc = insurancePartner.partnerLogo ?? null;
            this.setState({ insurance, isNewInsurance: false }, () =>
              this.showInsuranceViewModal()
            );
          }
        };
     validate=()=>{
          const errors = {};
      const {
      insuranceCompanyName,
        insuranceCompanyLogo,
        rank
        }=this.state.insurance
        if(!insuranceCompanyName || insuranceCompanyName.trim() === "")
        errors.insuranceCompanyName = "Company Name is required";
       if(!insuranceCompanyLogo )
        errors.insuranceCompanyLogo = "Company Logo is required";
        return Object.keys(errors).length === 0 ? null : errors;
  
      }
        renderPencilIcon = (fieldName) => {
          const setEditable = (field) => {
            const editables = { ...this.state.editables };
            editables[field] = true;
            this.setState({ editables });
          };
      
          if (!this.props.isEditable || this.state.isNewInsurance) return null;
          return (
            <div
              onClick={() => setEditable(fieldName)}
              style={{ cursor: "pointer" }}
              className="input-group-append"
            >
              <i className="input-group-text fa fa-pencil"></i>
            </div>
          );
        };
      
        showInsuranceViewModal = () => {
          const { country } = this.props;
          const { isNewInsurance } = this.state;
          const rankOptions = this.getRankOptions() || [];
      
          if (isNewInsurance) {
            const rank = Math.max(...rankOptions.map((it) => it.value));
            const insurance = {
              country: country?.id,
              rank,
              isActive: false,
            };
            this.setState({ insurance, rankOptions }, () => this.showModal());
          } else {
            this.setState({ rankOptions }, () => this.showModal());
          }
        };
        showModal = () => {
          this.setState({ showModal: true });
        };
      
        hideModal = () => {
          this.setState({ showModal: false });
          this.resetForm();
        };
        resetForm = () => {
          this.setState({
            errors: {},
            editables: {},
            insurance: {},
            rankOptions: [],
            isSaving: false,
            isNewInsurance: false,
          });
        };
        handleSave = async () => {
          if (!this.props.country) {
            this.showErrorNotification("Please select a country!");
            this.setState({ isSaving: false });
            return;
          }
      
          const errors = this.validate();
          this.setState({ errors: errors || {} });
          if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            this.setState({ isSaving: false });
            return;
          }
      
          const { insurance } = this.state;
        
          
         
          try {
            this.showLoading();
            
            const payload = { ...insurance };
            const  url=insurance.id
                ? `/insurance-lead-generation/${insurance.id}`
                : "/insurance-lead-generation";
              
          const response= insurance.id? await PhoenixAPI.patch(url,payload): await PhoenixAPI.post(url,payload);
            this.hideModal();
            this.showSuccessNotification("Saved Successfully.");
            this.fetchInsurances();
          } catch (error) {
            this.showErrorNotification("Some error occurred: " + error.message);
          } finally {
            this.setState({ isSaving: false }, this.hideLoading);
          }
        };
  
        fetchInsurances = async () => {
          try {
            this.showLoading();
            const { data: insurances = [] } = await PhoenixAPI.get(`/insurance-lead-generation/`);
            this.setState({ insurances });
          } catch (error) {
            console.log(
              "fetchinsurancePartners:: Error on fetching insurance partners!",
              error
            );
            this.showErrorNotification(
              "Error on fetching insurance partners! " + error.message ?? ""
            );
            this.setState({ loadError: "Some error has occured. Please try again" });
          } finally {
            this.hideLoading();
          }
        };
        showSuccessNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, true);
    
      showErrorNotification = (notificationMessage) =>
        this.showNotification(notificationMessage, false);
    
      showNotification = (notificationMessage, isSuccessMessage) =>
        this.props.showNotificationMessage({
          notificationMessage,
          successMessage: isSuccessMessage,
          showNotification: true,
        });
  
      getFilteredinsurances=()=>{
        return  this.getSearchedinsurances(this.getInsurancesForCurrentCountry())
      }
  
      
          getSearchedinsurances = (insurances) => {
              const { searchApplied, searchTerm } = this.state;
          
              if (!searchApplied || !searchTerm) return insurances;
          
              const newValue = searchTerm.slice(0).trim().toLowerCase();
              return insurances.filter((insurance) => {
                const { id, insuranceCompanyName } =insurance;
                return (
                  (id && id.toString().includes(newValue)) ||
                  (insuranceCompanyName && insuranceCompanyName.toLowerCase().indexOf(newValue) !== -1) 
                  
                );
              });
            };
            handleChange = ({ currentTarget: input }) => {
              const insurance = { ...this.state.insurance };
              insurance[input.name] = input.value;
              this.setState({ insurance });
            };
            showLoading = () => this.props.toggleLoading({ isLoading: true });

            hideLoading = () => this.props.toggleLoading({ isLoading: false });
          
            handleIconChange=async(e)=>{
              e.stopPropagation();
          const file = e.target.files[0];
          console.log("change file",e.target.files[0]);
          if (file) {
            
            const formData=new FormData();
            formData.append("media",file)
           const response= await  this.uploadFiles(formData)
           const insurance= { ...this.state.insurance };
           insurance.insuranceCompanyLogo = response.data.message;
           this.setState({ insurance },()=>this.showSuccessNotification("company Logo uploaded successfully"));
          }
        
          }
          uploadFiles=async(formData)=>{
            let response = null;
              try{
                 response = await PhoenixAPI.post('/upload-media',formData, {
                headers: {
                  accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                  'Content-Type': 'multipart/form-data'
                }
              });
            }
            catch (error) {
              this.showErrorNotification("Some error occurred while upload: " + error.message);
            }
              return response;
             }
             getInsurancesForCurrentCountry = () => {
              const { insurances } = this.state;
              const { country } = this.props;
          
              if (country && insurances && insurances.length) {
                const insuranceForCountry = insurances.filter(
                  (it) => it.country === country.id
                );
                return insuranceForCountry;
              }
          
              return [];
            };
          
      getRankOptions = () => {
          const { isNewInsurance } = this.state;
          const insurancePartnersForCurrentCountry =
            this.getInsurancesForCurrentCountry();
          const ranks = insurancePartnersForCurrentCountry
            .filter((it) => it.rank)
            .map((it) => it.rank);
          const minRank = Math.min(1, ...ranks);
          const maxRank = Math.max(
            insurancePartnersForCurrentCountry.length,
            ...ranks
          );
          const until = isNewInsurance ? maxRank + 1 : maxRank;
          const rankOptions = [];
          for (let i = minRank; i <= until; i++)
            rankOptions.push({ key: i, value: i, text: i });
      
          if (rankOptions.length === 0)
            rankOptions.push({ key: 1, value: 1, text: 1 });
          return rankOptions;
        };
      render() {
          const{
              insurance,
              errors,
              isNewInsurance,
              editables,
              insurances,
              searchTerm,
              isSaving
          }=this.state;
  
         const{isEditable}=this.props;
          const filteredinsurances=this.getFilteredinsurances(insurances)
  
          const insuranceForm=(
              <div className="container mt-4">
                  <div className="row">
            <div className="form-group col">
              <label htmlFor="partnerName">Insurance Name *</label>
              <div className="input-group">
                <input
                  value={insurance?.insuranceCompanyName || ""}
                  onChange={this.handleChange}
                  id="insuranceCompanyName"
                  name="insuranceCompanyName"
                  type="text"
                  placeholder="Enter insurance Name"
                  className={
                    "form-control py-2" + (errors.insuranceComapnyName ? " has-error" : "")
                  }
                  disabled={!isNewInsurance && !editables.insuranceCompanyName}
                />
                {this.renderPencilIcon("insuranceCompanyName")}
              </div>
              <span className="help-block">{errors.insuranceCompanyName}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="imageInputPrimary">Insurance Company Logo*</label>
              <div className="input-group">
                <input
                 
                  onChange={ this.handleIconChange}
                  id="companyLogo"
                  name="companyLogo"
                  type="file"
                  accept="image/*"
                  disabled={!isNewInsurance && !editables.companyLogo}
                  className={"form-control" + (errors.companyLogo ? " has-error" : "")}
                />
                {this.renderPencilIcon("companyLogo")}
              </div>
              <span className="help-block">{errors.insuranceCompanyLogo}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              
              <label htmlFor="status">Status*</label>
              <div className="input-group">
                <Dropdown
                  value={insurance?.isActive}
                  onChange={(event, value) => {
                    this.setState({
                      insurance: {
                        ...insurance,
                        isActive: value.value,
                      },
                    });
                  }}
                  id="status"
                  name="status"
                  placeholder="Status"
                  search
                  selection
                  options={STATUS_OPTIONS}
                  disabled={!isNewInsurance && !editables.status}
                  className={"form-control" + (errors.status ? " has-error" : "")}
                />
                {this.renderPencilIcon("status")}
              </div>
              <span className="help-block">{errors.status}</span>
              <br/>
              <label htmlFor="rank">Rank*</label>
              <div className="input-group">
                <Dropdown
                  value={insurance?.rank}
                  onChange={(event, value) => {
                    this.setState({
                      insurance: {
                        ...insurance,
                        rank: value.value,
                      },
                    });
                  }}
                  id="rank"
                  placeholder="Rank"
                  search
                  selection
                  options={this.state.rankOptions}
                  disabled={!isNewInsurance && !editables.rank}
                  className={"form-control" + (errors.rank ? " has-error" : "")}
                />
                {this.renderPencilIcon("rank")}
              </div>
              <span className="help-block">{errors.rank}</span>
            </div>
            <div className="form-group col-6">
            {insurance?.insuranceCompanyLogo && (
                <>
                  <label htmlFor="selected_package_image">
                     Logo Image
                  </label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={insurance.insuranceCompanyLogo}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>)}
            </div>
  
              
               
         </div>   
          
      </div>
  
          )
          return(
          <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>
              <div className="action-item">
                <button
                  style={{ backgroundColor: "#8FD14F" }}
                  className="btn btn-md new-user"
                  onClick={this.handleAddNew}
                  disabled={!isEditable}
                >
                  <i className="fa fa-plus-circle"></i>Add New
                </button>
              </div>
            </div>
          </div>
          <Card body>
            <p className="font-weight-bold">Insurance Partners</p>
            <DataTable
              data={filteredinsurances}
              columns={this.columns}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              pagination
              responsive
              noHeader={this.state.searchApplied ? false : true}
              title={
                this.state.searchApplied ? (
                  <p className="table-header">
                    {"Found " + filteredinsurances.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox
                    message={this.state.loadError}
                    error={true}
                  ></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
          <Modal
            size="lg"
            show={this.state.showModal}
            onHide={this.hideModal}
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                  <span style={{ fontWeight: "bold" }}>Details: </span>
                  {insurance?.insuranceCompanyName ?? ""}
                </p>
              </Modal.Title>
              <div className="user-title"></div>
              <button
                type="button"
                onClick={(event) =>
                  this.setState({ isSaving: true }, this.handleSave)
                }
                disabled={!isEditable || isSaving}
                className="btn modal-save-btn"
              >
                Save
              </button>
            </Modal.Header>
            <Modal.Body>
              <Tabs id="tabs_website_categories">
                <Tab eventKey="key_details_form" title="Details">
                  {insuranceForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
        
      );
    
  
  
  
          
      }
  
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(InsurancePage);