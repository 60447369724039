import React, { Component } from "react";
import API from "utils/API";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Dropdown } from "semantic-ui-react";
import EditIcon from "@material-ui/icons/Edit";
import { getUserType } from "services/UserService";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { filterList } from "utils/List";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import moment from "moment-timezone";
import FormValidator from "utils/FormValidator";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import DoneIcon from "@material-ui/icons/Done";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormData from "form-data";
import CheckIcon from "@material-ui/icons/Check";
import { Modal, Tabs, Tab } from "react-bootstrap";
import $ from 'jquery';
import ModalPopup from "components/ModalPopup/ModalPopup";
import Select from "react-select";
import { formatDate, formatTime, formatDateTime, compareStrings } from "utils/commons";
import PhoneInput from "react-phone-input-2";
import PaymentDetailsForm from "./PaymentDetailsForm";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserEmail, getUserId } from "services/UserService";
import OrderStatusLogsTable from "./OrderStatusLogsTable"
import icon_notification_info from "../../assets/img/icon_notification_info.png";
import {fetchOperationsUsers} from "services/UserService";
import { checkAndUpdateParentOrderStatus } from "services/OrderService";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import BlockIcon from "@material-ui/icons/Block";
import SelectItemType from "components/Common/SelectItemType";
import ITEM_TYPE_OPTIONS from "utils/OrderItemType";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import PdfPreview from "components/Common/PdfPreview";
import { formatTimeslotTwentyFourHourFormat } from "utils/commons";
import Form from 'react-bootstrap/Form';
import cancelReasonOptions from "../../utils/CancelReason";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const ORDER_SOURCE = "ADMIN_PANEL";

const BASE_URL_AUDIT_TRAILS = "audit-trails/";
const EVENT_ORDER_STATUS_CHANGE="ORDER_STATUS_CHANGE";
const TWO_MEGA_BYTE = 2_097_152;
const TEN_MEGA_BYTE = 5 * TWO_MEGA_BYTE;

const CUSTOM_PAYMENT_GATEWAY_OPTIONS = [
  {
    key: "CHECKOUT",
    value: "CHECKOUT",
    text: "Checkout",
  },
  {
    key: "PAYTABS",
    value: "PAYTABS",
    text: "Paytab",
  },
  {
    key: "MAMOPAY",
    value: "MAMOPAY",
    text: "Mamopay",
  },
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
  {
    key: "TAMARA",
    value: "TAMARA",
    text: "Tamara",
  },
  {
    key: "OTHERS",
    value: "OTHERS",
    text: "Others",
  },
];
const BASE_URL_MINI_PACKAGES = "/api/v1/mini-packages";
const BASE_URL_COUPONS="coupon-code/summary";
class ActiveOrders extends Component {

  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "pickupDate",
        method: "isEmpty",
        validWhen: false,
        message: "Date is required.",
      },
      {
        field: "pickupTime",
        method: "isEmpty",
        validWhen: false,
        message: "Time is required.",
      },
    ]);

    this.confirmvalidator = new FormValidator([
      {
        field: "confirmDate",
        method: "isEmpty",
        validWhen: false,
        message: "Date is required.",
      },
      {
        field: "confirmTime",
        method: "isEmpty",
        validWhen: false,
        message: "Time is required.",
      },
    ]);

    this.customConfirmValidator = {
      confirmDate: {
        message: null,
      },
      confirmTime:{
        messsage:null,
      }
    };

    this.customValidator = {
      pickupDate: {
        message: null,
      },
      pickupTime:{
        message:null,
      }
    };

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      orders: [],
      upcomingOrders: [],
      nutritionists: [],
      nutritionistId: null,
      labs: [],
      order_notes: '',
      labId: null,
      orderId: null,
      isLoaded: false,
      loadError: "",
      userType: getUserType(),
      showAssignModal: false,
      showLabAssignModal: false,
      showNoteAssignModal: false,
      showStatusConfirm: false,
      showConfirmTime: false,
      showCancelConfirm: false,
      showCancelRefund:false,
      confirmItem: null,
      showReschedule: false,
      pickupDate: "",
      pickupTime: "",
      confirmDate: "",
      confirmTime: "",
      newStatus: null,
      timeSlots: [],
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      startDate: null,
      validation: this.validator.valid(),
      confirmvalidation: this.confirmvalidator.valid(),
      orderContentType: null,
      packageType: null,
      orderBatchId: null, // for susbcription orders, holds the parent order(used for cancelling purposes)
      showItems: "Active Orders",
      filterApplied: false,
      showModal: false,
      modalData: {},
      key: "orderInfo",
      userTransactions: [],
      userEmail: "",
      userName: "",
      firstname: "",
      editPackage: false,
      editOrderId: false,
      editOrderEmail: false,
      editClientNotes: false,
      editAppiontmentDate: false,
      editAppiontmentTime: false,
      editConfirmDate: false,
      editConfirmTime: false,
      editOrderStatus: false,
      editLab: false,
      editCoach: false,
      editAttachment: false,
      editNotes: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      editLabCosts: false,
      editHomecareCosts: false,
      editHomecareService: false,
      newUser: false,
      userListFormatted: [],
      packages: [],
      updatedOrder: {},
      subscription_packages: [],
      add_on_packages: [],
      custom_packages: [],
      confirmDateErrorMessage: '',
      confirmTimeErrorMessage:'',
      pickupDateErrorMessage: '',
      coupenList: [],
      allCoupenList: [],
      emailErrorMessage: '',
      packageErrorMessage: '',
      appointmentDateErrorMessage: '',
      appointmentTimeErrorMessage: '',
      isAppointmentDateLableRequired: false,
      countries: [],
      countryOptions: [],
      selectedCountry: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      selectedPackageData: {},
      selectedOrder:{},
      showOperatorAssignModal:false,
      editOperationsPerson:false,
      editFullNameId:false,
      editFrontId:false,
      editBackId:false,
      frontIdSrc:"",
      backIdSrc:"",
      fullNameIdErrorMessage:"",
      frontIdErrorMessage:"",
      backIdErrorMessage:"",
      labCostsErrorMessage: "",
      homecareCostsErrorMessage: "",
      editables:{},
      customTestPriceAddErrorMessage:"",
      lab1CustomCostErrorMessage:"",
      homeCareCostErrorMessage:"",
      referenceNumber1ErrorMessage:"",
      lab2CustomCostErrorMessage:"",
      referenceNumber2ErrorMessage:"",
      revisedPriceErrorMessage:"",
      couponAmountErrorMessage:"",
      revisedPriceCouponErrorMessage:"",
      refundAmountErrorMessage:"",
      customTestBiomarkerErrorMessage:"",
      is_refund_and_cancel_notify:false,
      notesErrorMessage:"",
      couponCodeValueErrorMessage:"",
      operationsUsers:[],
      selectedItemType:null,
      miniPackages: [],
      isSaving: false,
      filterItemType:"",
      refundNotes:null,
      refundError:"",
      isrescheduleDateConfirmPopup:false,
      oldConfirmDate:"",
      confirmDatePopup:false,
      cancelNotes:null,
      cancelError:"",
      cancelReason:null,
    };


    this.statusList = [
      {
        name: "Future Order",
        value: "UPCOMING",
      },
      {
        name: "Payment Pending",
        value: "PAYMENT PENDING",
      },
      {
        name: "Order Created",
        value: "CREATED",
      },
      {
        name: "Lab Appointment Request",
        value: "LAB REQUESTED",
      },
      {
        name: "Lab Appointment Confirmation",
        value: "LAB CONFIRMED",
      },
      {
        name: "Sample Received",
        value: "SAMPLE RECEIVED",
      },
      {
        name: "Report Received",
        value: "REPORT RECEIVED",
      },
      {
        name: "Coach Reviewed",
        value: "COACH REVIEWED",
      },
      {
        name: "Order Completed",
        value: "COMPLETED",
      },
    ];

    this.addonStatusList = [
      {
        name: "Coach Appointment Request",
        value: "COACH REQUEST",
      },
      {
        name: "Coach Assigned",
        value: "COACH CONFIRMED",
      },
      {
        name: "Order Created",
        value: "CREATED",
      },
      {
        name: "Completed",
        value: "COMPLETED",
      },
    ];

    this.columns = [
      {
        name: "Order ID",
        selector: "id",
        sortable: true,
        sortField: 'id',
        maxWidth: "100px",
      },
      {
        name: "Parent Order ID",
        selector: "parent_order_id",
        center: true,
        wrap: true,
        format: (order) => order.parent_order_id || order.order_uuid || "",
      },
      {
        name: "Purchased By",
        selector: "user.username",
        // sortable: true,
        // sortField: 'user__username',
        wrap: true,
        format: (row) => this.getUserName(row),
        // format: (row) => row.user.username + " (" + row.user.email + ")",
      },
      {
        name: "Purchased On",
        selector: "created_at",
        wrap: true,
        sortField: 'created_at',
        sortable: true,
        format: (row) => row.created_at ? formatDateTime(row.created_at) : "-",
      },
      {
        name: "Package",
        // selector: "package.subscription_name",
        selector: "package_internal_name",
        // sortable: true,
        // sortField: 'package__subscription_name,custompackage__name,add_on__addon_name',
        sortField: 'package_internal_name',
        wrap: true,
        format: (row) => this.getPackageName(row)
        // format: (row) =>
        // (
        //   !row.package_type ? (
        //     row.package !== null
        //       ? row.package.subscription_name
        //       : row.add_on && row.add_on !== null
        //         ? row.add_on.addon_name
        //         : "N/A"
        //   ) : (
        //     row.custom_package && row.package_type ? (
        //       row.custom_package.name
        //         ? row.custom_package.name
        //         : ""
        //     ) : ("")
        //   )
        // )
      },
      {
        name: "Item type",
        selector: "order_item_type",
        center: true,
      },
      // {
      //   name: "Price",
      //   selector: "order_price",
      //   sortField: "order_price",
      //   wrap: true,
      //   sortable: true,
      //   format: (order) => {
      //     const { currency, order_price } = order ?? {};
      //     return `${currency ?? ""} ${order_price ?? ""}`.trim();
      //   }
      // },
      {
        name: "Payment Mode",
        selector: "payment_method",
        wrap: true,
        sortable: false,
        format: (order) => order?.payment_method ?? "",
      },
      {
        name: "Item Quantity",
        selector: "quantity",
        sortable: true,
        center: true,
        sortField: "quantity",
        format: (order) => order.quantity ?? "",
      },
      {
        name: "Order Category",
        selector: "order_category",
        center: true,
      },
      {
        name: "Country",
        selector: "country",
        width: "100px",
        sortable: true,
        center: true,
        wrap: true,
        sortField: 'country_id',
        format: (order) => this.getCountryName(order),
      },
      {
        name: "City",
        selector: "city",
        width: "100px",
        sortable: true,
        center: true,
        wrap: true,
        sortField: 'city_id',
      },
      {
        name: "City Level Pricing",
        selector: "is_city_level_pricing",
        center: true,
        wrap: true,
        cell:(row)=>this.renderCityLevelPricing(row),
      },
      {
        name: "Coach",
        selector: "nutritionist.username",
        // sortable: true,
        // sortField: 'nutritionist__username',
        wrap: true,
        format: (row) => 
          (row.package_type && (row.package_type.indexOf('Home Appointment') > -1 || row.package_type.indexOf('Requires Coach Note') > -1) || !row.package_type) ? (
            row.nutritionist ? (
              <>
                {row.nutritionist.is_active
                  ? row.nutritionist.username
                  : row.nutritionist.username + " (Deactivated)"}

                <EditIcon
                  className="edit-icon"
                  onClick={() =>
                    this.showAssignModal(
                      row.id,
                      row.nutritionist.id,
                      row.nutritionist.email
                    )
                  }
                ></EditIcon>
              </>
            ) : (
              <button
                className="btn button button-green btn-sm"
                onClick={() => this.showAssignModal(row.id)}
              >
                Assign
              </button>
            )
          ) : (
            "NA"
          ),
      },
      {
        name: "Lab",
        // sortable: true,
        // sortField: 'laboratory',
        minWidth: "180px",
        selector: "laboratory",
        wrap: true,
        format: (row) => (
          <select
            className="form-control"
            onChange={(e) => this.assignLab(e.target.value, row)}
            value={row.laboratory}
          >
            {this.state.labtListFormatted&&this.state.labtListFormatted.map((status, index) => (
              <option key={index} value={status.value}>
                {status.text}
              </option>
            ))
            }

          </select>
        )
      },
      {
        name: "Notes",
        selector: "order_notes",
        sortable: true,
        sortField: 'order_notes',
        wrap: true,
        format: (row) =>
          row.order_notes ? (
            <>
              {row.order_notes}

              <EditIcon
                className="edit-icon"
                onClick={() =>
                  this.showNoteAssignModal(
                    row.id,
                    row.order_notes
                  )
                }
              ></EditIcon>
            </>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showNoteAssignModal(row.id)}
            >
              Add Note
            </button>
          )
      },
      {
        name: "Confirmed Time",
        selector: "confirmed_date_time",
        sortable: true,
        sortField: 'confirmed_date_time',
        wrap: true,
        format: (row) =>
          row.confirmed_date_time ? (
            <>
              {/* {moment(row.confirmed_date_time.split(' ')[0]).format("DD/MM/YYYY") +
                ", " +
                row.confirmed_date_time.split(' ')[1]} */}
              {formatDateTime(row.confirmed_date_time)}
           {  row.order_item_type!=="Supplement" && row.order_item_type !=="Bundle" && row.order_item_type!=="Medicine"? <EditIcon
                className="edit-icon"
                onClick={() =>
                  this.showConfirmTime(row)
                }
              ></EditIcon>:<></>}
            </>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showConfirmTime(row)}
              disabled={ row.order_item_type=="Supplement" || row.order_item_type =="Bundle" || row.order_item_type =="Medicine"}
              
            >
              Add Time
            </button>
          )
      },
      {
        name: "Attachment",
        selector: "attachment_uploaded",
        maxWidth: "100px",
        // sortable: true,
        sortField: 'attachment_uploaded',
        center: true,
        format: (row) => {
          if (row.attachment_uploaded)
            return (
              <span >
                Yes
              </span>
            );
          else
            return (
              <span> No
              </span>
            );
        },
      },
      {
        name: "Status",
        minWidth: "235px",
        center: true,
        sortable: true,
        sortField: 'order_status',
        selector: "order_status",
        format: (row) => {
         return(
          <select
            className="form-control"
            onChange={(e) => this.showStatusConfirm(e.target.value, row)}
            value={row.order_status}
            disabled={this.isCartOrder(row)}
            style={{width:"220px"}}
          >
            {/* {row.package_type && row.status_dropdown_list && row.package_type !== null ?
              row.status_dropdown_list.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.name}
                </option>
              ))
              : (
                row.contents === "A"
                  ? this.addonStatusList.map((status, index) => (
                    <option key={index} value={status.value}>
                      {status.name}
                    </option>
                  ))
                  : this.statusList.map((status, index) => (
                    <option key={index} value={status.value}>
                      {status.name}
                    </option>
                  ))
              )
              style={{minWidth:"260px"}} 
            
            } */}
         {row && row.status_dropdown_list !==null ?
          row.status_dropdown_list.map((status, index) => (
           <option key={index} value={status.value}   >
             {row.order_status==="CREATED"?"ORDER CREATED":row.order_status}
           </option>
         )) : ""
         }
          </select>
        )},
      },
      {
        name: "Coach's Review",
        minWidth: "160px",
        sortable: true,
        selector: "is_result_uploaded",
        sortField: 'is_result_uploaded',
        center: true,
        format: (row) =>
          // row.package_type == 'PDF Download' ? (
          row.contents === "A" ? (
            "NA"
          ) : row.is_result_uploaded ? (
            <div className="badge badge-green auto-width no-background">
              <DoneIcon></DoneIcon>
              Uploaded
            </div>
          ) : (
            <div className="badge badge-secondary auto-width no-background">
              <InfoOutlinedIcon></InfoOutlinedIcon>Pending
            </div>
          )
        //   ) : (
        //   "NA"
        // ),
      },
      // {
      //   name: "Actions",
      //   maxWidth: "100px",
      //   center: true,
      //   allowOverflow: true,
      //   cell: (row) => (
      //     <ActionBar alignRight className="action-menu valeo-dropdown">
      //       <ActionBar.Toggle variant="">
      //         <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      //       </ActionBar.Toggle>
      //       <ActionBar.Menu>
      //         <ActionBar.Item
      //           className="danger"
      //           onClick={() => this.showCancelConfirm(row)}
      //         >
      //           <CancelOutlinedIcon></CancelOutlinedIcon>
      //           Cancel Order
      //         </ActionBar.Item>
      //       </ActionBar.Menu>
      //     </ActionBar>
      //   ),
      // },
      {
        name: "View",
        selector: "id",
        sortable: false,
        format: (row) => <button
          className="btn button-blue btn-md"
          onClick={(e) => {
            console.log("roe", row.contents)
            this.showModal(row.id)
          }}
        >
          View
        </button>
      }
    ];

    this.staffColumns = [
      {
        name: "Order ID",
        selector: "id",
        sortField: 'id',
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Package",
        // selector: "package.subscription_name",
        selector: "order_package",
        wrap: true,
        // sortable: true,
        // sortField: 'package__subscription_name,custompackage__name,add_on__addon_name',
        sortField: 'order_package',
        // cell: (row) => {
        //   return row.package_type ? (
        //     row.custom_package && row.custom_package.name ? row.custom_package.name : 'NA'

        //   ) : (
        //     row.package !== null
        //       ? row.package.subscription_name
        //       : row.add_on && row.add_on !== null
        //         ? row.add_on.addon_name
        //         : "N/A"
        //   );
        // }
      },
      {
        name: "Purchased By",
        selector: "user.username",
        sortable: true,
        wrap: true,
        sortField: 'user__username',
        format: (row) => this.getUserName(row),
        // format: (row) => row.user.username + " (" + row.user.email + ")",
      },
      {
        name: "Confirmed Date",
        selector: "date_confirmed_date_time",
        sortable: true,
        wrap:true,
        sortField: 'date_confirmed_date_time',
        format: (row) => row.date_confirmed_date_time ? formatDateTime(row.date_confirmed_date_time) : "",
        // format: (row) => moment(row.created_at).format("DD/MM/YYYY, hh:MM A"),
      },
      {
        name: "Health Assessment Results",
        selector: "survey",
        sortable: true,
        sortField: 'survey',
        center: true,
        cell: (row, index) =>
          row.survey ? (
            <NavLink
              to={`/dashboard/health-profile/surveys/results/general/${JSON.stringify(
                row.survey
              )}/:userId/:surveyId/:orderId/:type`}
            >
              {row.survey.is_completed == true ? (
                <button className="btn button button-green btn-sm">View</button>
              ) : (
                <button className="btn btn-secondary btn-sm" disabled>
                  Not submitted
                </button>
              )}
            </NavLink>
          ) : (
            "N/A"
          ),
      },
      {
        name: "Results",
        maxWidth: "150px",
        center: true,
        cell: (row) => {
          let url = ""
          if (row.dependent_id) {
            url = "/dashboard/orders/active/results/" + row.id + "/" + (row.member?.gender ?? null);
          } else {
           
            url = "/dashboard/orders/active/results/" + row.id + "/" + (row.user && row.user.userprofile && row.user.userprofile.gender ? row.user.userprofile.gender : null);
          }  
          return (
            // (row.package_type == 'PDF Download') || row.package_type == 'Requires Coach Note' ? (
            <NavLink
              to={url}
              // to={
              //   "/dashboard/orders/active/results/" + row.id + "/" + (row.user && row.user.userprofile && row.user.userprofile.gender ? row.user.userprofile.gender : '')
              // }
              target={["lab", "nutritionist"].includes(getUserType()) ? "_blank" : "_self"}
            >

              {row.is_result_uploaded ? (
                row.is_result_uploaded && getUserType() === "lab" ? (
                  <button className="btn btn-secondary btn-sm">View</button>
                ) : (
                  <button className="btn btn-secondary btn-sm">Edit</button>
                )
              ) : getUserType() === "nutritionist" ? (
                <button className="btn button button-green btn-sm">Review</button>
              ) : !row.is_result_uploaded ? (
                <button className="btn button button-green btn-sm">Upload</button>
              ) : (
                <button className="btn button button-green btn-sm">
                  Result Uploaded
                </button>
              )}
            </NavLink>
            // ) : ("NA")
          );
        },
      },
    ];


    this.submitted = false;
    this.confirmsubmitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);

    this.assignNutritionist = this.assignNutritionist.bind(this);
    this.reschedulePickup = this.reschedulePickup.bind(this);
    this.hideAssignModal = this.hideAssignModal.bind(this);
    this.hideLabAssignModal = this.hideLabAssignModal.bind(this);
    this.showAssignModal = this.showAssignModal.bind(this);
    this.showLabAssignModal = this.showLabAssignModal.bind(this);
    this.showNoteAssignModal = this.showNoteAssignModal.bind(this);
    this.hideNoteAssignModal = this.hideNoteAssignModal.bind(this);
    this.hideStatusConfirm = this.hideStatusConfirm.bind(this);
    this.showStatusConfirm = this.showStatusConfirm.bind(this);
    this.showCancelConfirm = this.showCancelConfirm.bind(this);
    this.hideCancelConfirm = this.hideCancelConfirm.bind(this);
    this.showCancelRefund=this.showCancelRefund.bind(this);
    this.hideCancelRefund=this.hideCancelRefund.bind(this);
    this.hideReschedule = this.hideReschedule.bind(this);
    this.showReschedule = this.showReschedule.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.assignLab = this.assignLab.bind(this);
    this.handleNotifyClientChange=this.handleNotifyClientChange.bind(this);
    this.addNotes = this.addNotes.bind(this);
    this.confirmTime = this.confirmTime.bind(this);
    this.hideConfirmTime = this.hideConfirmTime.bind(this);
    this.showConfirmTime = this.showConfirmTime.bind(this);
    this.RefundNotesValidation=this.RefundNotesValidation.bind(this);
    this.cancelNotesValidation=this.cancelNotesValidation.bind(this);
    // this.customSort = this.customSort.bind(this);
    // this.onOrderPageChange = this.onOrderPageChange.bind(this);
  }

  getUserName = (row) => {
    if (!row) return "";

    const { dependent_id, member, user } = row;
    const { username, full_name, first_name, last_name, email } = user ?? {};
    const name = dependent_id
      ? `${member?.first_name ?? ""} ${member?.last_name ?? ""}`.trim()
      : username || full_name || `${first_name ?? ""}  ${last_name ?? ""}`.trim();
    return `${name} (${email})`;
  }
  getPackageName=(row)=>{
    const { order_package,package_internal_name, display_name, display_name_ar } = row || {};
   
      return <div style={{display:"flex",flexDirection:"column"}}>
            <p>{package_internal_name?package_internal_name:order_package}</p>
          </div> 
    
  }
  isHairLossJourneyItem = (orderItemType) => {
    const items = ["Supplement", "Bundle", "Medicine", "Cart", "Mini_Package"];
    return items.includes(orderItemType);
  }

  isCustomPackageOrderType = (packageType) =>{
    let isHomeAppointmentCheck= "Home Appointment"
    if(packageType){
      return packageType.includes(isHomeAppointmentCheck)
    }
    return false
  }

  isCartOrder = (order) => {
    return order?.order_category === "CART";
  }

  loadCoupons=async()=> {
    try{
      const{data: coupons=[]} = await PhoenixAPI.get(BASE_URL_COUPONS);
        
          this.setState({ coupenList:coupons, allCoupenList:coupons });
    }
        catch(error) {
          console.log("Error on fetching coupon codes", error);
        }
    }
  
  
  loadStatus() {
    API.get("operation-types/")
      .then((res) => {
        let data = res.data;
        this.setState({ operationList: data });
      })
      .catch((error) => {
        console.log("Error on fetching operation types", error);
      });
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchMiniPackages();
    this.loadCountries();
    this.loadStatus();
    this.loadData();
    this.loadCoupons();
    this.setState({ startDate: this.getCurrentDate() });
    navigator.serviceWorker.addEventListener("message", (event) => {
      console.log("loaded data again");
      console.log(`The client sent me a message: ${event.data}`);
      //on status changes update orders
      this.loadData();
    });
  }

  fetchMiniPackages = async () => {
    try {
      this.showLoading();
      const { data: miniPackages = [] } = await PhoenixAPI.get(
        BASE_URL_MINI_PACKAGES
      );
      this.setState({ miniPackages });
    } catch (error) {
      console.log("fetchMiniPackages:: Error on fetching mini packages!", error);
    } finally {
      this.hideLoading();
    }
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.ACTIVE_ORDERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadCountries = async () => {
    try {
      const { data: countries = [] } = await API.get("countries/");
      const activeCountries = countries.filter(country => country.is_active);
      const countryOptions = activeCountries.map(this.createCountryOption);
      const selectedCountry = activeCountries.length === 1 ? activeCountries[0] : null;
      this.setState({ countries, countryOptions, selectedCountry });
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }
  }

  findCountry = (id) => {
    const { countries = [] } = this.state;
    return countries.find(country => country.id === id);
  }

  findCountryByName = (name) => {
    const { countries = [] } = this.state;
    return countries.find(country => country.country_name === name || country.country_name_ar === name);
  }

  loadPackages() {
    let packages = [];
    let addons = [];
    // API.get("subscription-packages/")
    API.get("active-packages/")
      .then(response => {
        let products = [];
        const data = filterList(
          response.data,
          "active"
        );
        data.map(val => (
          products.push({
            "label": val.subscription_name,
            "value": 'packages' + val.id,
            "id": val.id,
            'type': 'packages'
          })
        ))
        let packages = [...this.state.packages, ...products]
        this.setState({
          // subscription_packages: products,
          packages: packages,
          subscription_package: response.data
        }, () => this.updatePackagesListForSelectedCountry());
      })
      .catch(error => {
        console.log("Error on fetching active packages", error);
      })
    // API.get("add-on-packages/")
    API.get("user-add-on-packages/")
      .then(response => {
        let products = [];
        const data = filterList(
          response.data ? response.data.addon_plans : [],
          "active"
        );
        addons = response.data ? response.data.addon_plans : [];
        data.map(val => (
          products.push({
            "label": val.addon_name,
            "value": 'add-ons' + val.id,
            "id": val.id,
            "type": 'add-ons'
          })
        ))
        let packages = [...this.state.packages, ...products]
        this.setState({
          // add_on_packages: products,
          packages: packages,
          addOn_packages: response.data ? response.data.addon_plans : []
        }, () => this.updatePackagesListForSelectedCountry());
      })
      .catch(error => {
        console.log("Error on fetching packages", error);
      })

    API.get("packages/")
      .then(response => {
        let products = [];
        const data = response.data;
        filterList(
          response.data,
          "active"
        );
        data.map(val => (
          products.push({
            "label": val.name,
            "value": 'custom' + val.id,
            "id": val.id,
            'type': 'custom'
          })
        ))
        let packages = [...this.state.packages, ...products]
        this.setState({
          // custom_packages: products,
          packages: packages,
          customPackages: response.data
        }, () => this.updatePackagesListForSelectedCountry());
      })
      .catch(error => {
        console.log("Error on fecthing packages", error);
      })
  }

  // loadUsers() {
  //   this.props.toggleLoading({
  //     isLoading: true,
  //   });

  //   API.get("list-active-user/")
  //     .then((response) => {
  //       let userListFormatted = response.data.results.map((user) => {
  //         return {
  //           text: user.email + " (" + user.username + ")",
  //           value: user.id,
  //           key: user.id,
  //         };
  //       });

  //       this.props.toggleLoading({
  //         isLoading: false,
  //       });

  //       this.setState({
  //         userListFormatted: userListFormatted,
  //         users: response.data.results,
  //         isLoaded: true,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again.",
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     });
  // }

  loadUsersByEmail = () => {
    const { searchTermEmail } = this.state;
    if (!searchTermEmail || searchTermEmail.trim() === "")
      return;

    this.props.toggleLoading({ isLoading: true, });

    API.get(`list-active-user/?search=${searchTermEmail.trim()}`)
      .then((response) => {
        let userListFormatted = ((response.data && response.data.results) || [])
        .filter((user) => user.email && user.email.includes(searchTermEmail.trim()))
        .map((user) => {
          return {
            text: user.email + " (" + user.username + ")",
            value: user.id,
            key: user.id,
          };
        });

        this.props.toggleLoading({
          isLoading: false,
        });

        this.setState({
          userListFormatted: userListFormatted,
          users: response.data.results,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log("Error on fetching active users", error);
        this.setState({
          loadError: "Some error has occured. Please try again.",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (prevProps.location.state !== this.props.location.state) {
        this.loadData();
      }
    }

    if (prevState.selectedCountry !== this.state.selectedCountry) {
      console.log("Selected Country Changed!");
      this.updatePackagesListForSelectedCountry();
    }

    if (prevState.selectedCountry && prevState.selectedCountry !== this.state.selectedCountry) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          // home_care_id: null
        },
        updatedOrder: {
          ...this.state.updatedOrder,
          // home_care_id: null
        }
      });
    }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  getCurrentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;

    return today;
  }

  loadData() {
    let userType = this.state.userType;
    // this.loadOrders();
    this.fetchActiveOrders();
    if (userType === "admin" || userType === "crm") this.loadNutritionists();
    if (userType === "admin") {
      this.loadLabList();
      this.fetchHomecareServices();
      // this.loadUsers();
      this.loadPackages();
      this.fetchPaymentGateways();
      this.fetchOperationsUsers();
    }
    // this.loadTimeSlots();
  }

  renderCityLevelPricing = (row) => {
    return (
      <div>
        {row.is_city_level_pricing ? (
          <div className="badge badge-success">

            <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
            Yes
          </div>
        ) : (
          <div className="badge badge-danger">
            <BlockIcon></BlockIcon>No
          </div>
        )}
      </div>
    )
  }
  /*
  Not needed now,  now we are using fetchActiveOrders() method.
  onOrderPageChange() {
    if (this.state.nextURL) {
      let url = this.state.nextURL;
      let orders = [];
      const userType = this.state.userType;

      this.props.toggleLoading({
        isLoading: true,
      });

      API.get(url)
        .then((response) => {
          //filter out upcoming orders for nutritionists
          const orderResonse = response.data
          if (userType === "nutritionist") {
            orderResonse.results = orderResonse.results.filter(
              (item) => ((item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("Requires Coach Note") > -1) || (item.order_status != "SAMPLE RECEIVED" && item.order_status != "LAB CONFIRMED" && item.order_status != "ORDER CREATED" && item.order_status != "CREATED")) || (!item.package_type && item.order_status != "UPCOMING"))
            );
          }

          if (userType === "lab") {
            // item.order_status != "REPORT RECEIVED" &&
            orderResonse.results = orderResonse.results.filter(
              (item) => (!item.package_type || (item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("PDF Download") > - 1) && (item.order_status != "COACH REVIEWED" && item.order_status != "COMPLETED")))
            );
          }
          for (let order of orderResonse.results) {
            if (order.specimen_collection_timeslot) {
              const time = order.specimen_collection_timeslot.split("-");
              const startTime = moment(time[0], "hh:mm A").format("hh:mm A");
              const endTime = moment(time[1], "hh:mm A").format("hh:mm A");
              order.specimen_collection_timeslot = startTime + " to " + endTime;
            }
            if (order.add_on && order.add_on.id && order.package) {
              order.contents = "PA";
            } else if (
              order.addonsubscription &&
              order.addonsubscription.id &&
              !order.package
            ) {
              order.contents = "A";
            } else {
              order.contents = "P";
            }

            orders.push(order);
          }
          let allorder = [...this.state.orders, ...orders];
          this.setState({ orders: allorder, isLoaded: true, nextURL: orderResonse.next, currentURL: url });
          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }
  */

  /*
  Not needed now,  now we are using fetchActiveOrders() method.
  loadOrders() {
    let url;
    let orders = [];
    let orderResonse = [];
    let nextURL;
    const userType = this.state.userType;
    // if (this.state.currentURL) {
    //   url = this.state.currentURL
    // } else {
    if (userType === "nutritionist") url = "order-nutritionist/";
    else if (userType === "lab") url = "order-lab/";
    else url = "order-list/";
    // }
    if (this.state.sortField) {
      let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
      if (this.state.sortField == 'package__subscription_name,custompackage__name,add_on__addon_name' && this.state.sortDirection == 'desc') {
        fieldOrder = "-package__subscription_name,-custompackage__name,-add_on__addon_name"
      }
      url = url + "?ordering=" + fieldOrder
    }
    if (this.state.searchValue && this.state.sortField) {
      url = url + "&search=" + this.state.searchValue
    }
    if (this.state.searchValue && !this.state.sortField) {
      url = url + "?search=" + this.state.searchValue
    }
    this.props.toggleLoading({
      isLoading: true,
    });

    API.get(url)
      .then((response) => {
        //filter out upcoming orders for nutritionists
        nextURL = response.data && response.data.next ? response.data.next : '';
        orderResonse = response.data && response.data.results ? response.data.results : [];
        if (userType === "nutritionist") {
          // orderResonse.results = orderResonse.results.filter(
          //   (item) => ((item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("Requires Coach Note") > -1) || (item.order_status != "SAMPLE RECEIVED" && item.order_status != "LAB CONFIRMED" && item.order_status != "ORDER CREATED" && item.order_status != "CREATED")) || (!item.package_type && item.order_status != "UPCOMING"))
          // );
          orderResonse = response.data && response.data.results ? response.data.results : [];
          orderResonse = orderResonse.filter(
            (item) => ((item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("Requires Coach Note") > -1) || (item.order_status != "SAMPLE RECEIVED" && item.order_status != "LAB CONFIRMED" && item.order_status != "ORDER CREATED" && item.order_status != "CREATED")) || (!item.package_type && item.order_status != "UPCOMING"))
          );
        }

        if (userType === "lab") {
          // item.order_status != "REPORT RECEIVED" &&
          // orderResonse.results = orderResonse.results.filter(
          //   (item) => (!item.package_type || (item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("PDF Download") > - 1) && (item.order_status != "COACH REVIEWED" && item.order_status != "COMPLETED")))
          // );
          orderResonse = response.data && response.data.results ? response.data.results : [];
          orderResonse = orderResonse.filter(
            (item) => (!item.package_type || (item.package_type && (item.package_type.indexOf("Home Appointment") > -1 || item.package_type.indexOf("PDF Download") > - 1) && (item.order_status != "COACH REVIEWED" && item.order_status != "COMPLETED")))
          );
        }

        // let order of orderResonse.results
        for (let order of orderResonse) {
          if (order.booked_slot_time) {
            const time = order.booked_slot_time.split("-");
            const startTime = moment(time[0], "hh:mm A").format("hh:mm A");
            const endTime = moment(time[1], "hh:mm A").format("hh:mm A");
            order.booked_slot_time = startTime + " to " + endTime;
          } else if (order.specimen_collection_timeslot) {
            const time = order.specimen_collection_timeslot.split("-");
            const startTime = moment(time[0], "hh:mm A").format("hh:mm A");
            const endTime = moment(time[1], "hh:mm A").format("hh:mm A");
            order.specimen_collection_timeslot = startTime + " to " + endTime;
          }

          if (order.add_on && order.add_on.id && order.package) {
            order.contents = "PA";
          } else if (
            order.addonsubscription &&
            order.addonsubscription.id &&
            !order.package
          ) {
            order.contents = "A";
          } else {
            order.contents = "P";
          }

          orders.push(order);
        }
        // let allorder = [...this.state.orders, ...orders];
        let totalPage = response.data && response.data.count ? response.data.count : 0;
        if (this.state.searchApplied) {
          this.setState({ results: orders, resultCount: totalPage, isLoaded: true, nextURL: nextURL, currentURL: url, totalPage: totalPage });
        } else {
          this.setState({ orders: orders, isLoaded: true, nextURL: nextURL, currentURL: url, totalPage: totalPage });
        }
        // this.setState({ orders: orders, isLoaded: true, nextURL: nextURL, currentURL: url, totalPage: totalPage });
        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }
  */
   handleNotifyClientChange=()=>{
    console.log("checking",this.state.notifyClient);
    this.setState({notifyClient:!this.state.notifyClient})
   }
  getCountryName = (order) => {
    let countryId = null;
    if (order.custom_package) countryId = order.custom_package.country;
    else if (order.package) countryId = order.package.country;
    else if (order.add_on) countryId = order.add_on.country;

    let selectedCountry;
    
    if (countryId !== null && countryId !== undefined)
      selectedCountry = this.findCountry(countryId);
    else if (order.address && order.address.country)
      selectedCountry = this.findCountryByName(order.address.country);

    return selectedCountry ? selectedCountry.country_name : order.country;
  }

  returnLabName(row) {
    if (this.state.labs && row.laboratory) {
      let temp = ''
      let labIndex = this.state.labs.findIndex(lab => lab.id === row.laboratory);
      if (labIndex > -1) {
        temp = this.state.labs[labIndex].lab_name;
      }
      return temp
    }
  }

  returnPackageTestNames(tests) {
    let testNames = '';
    if (tests && tests.length > 0) {
      for (let i = 0; i < tests.length; i++) {
        testNames = testNames + tests[i].name;
        if (i < (tests.length - 1)) {
          testNames = testNames + ' , ';
        }
      }
    }
    return testNames;
  }

  loadLabList() {
    API.get("create-laboratory/")
      .then((response) => {
        const activeList = response.data;//filterList(response.data, "active");
        let newList = activeList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        let labtListFormatted = newList.map((lab) => {
          return {
            text: lab.name,
            value: lab.id,
            key: lab.id,
          };
        });
        labtListFormatted.splice(0, 0, { 'text': 'None', 'value': '', 'key': '' })
        this.setState({
          labs: response.data,
          labtListFormatted: labtListFormatted,
        });
      })
      .catch((error) => {
        console.log("Error on fetching laboratories", error);
      });
  }

  fetchHomecareServices = async () => {
    try {
      const { data: homecareServices = [] } = await API.get("home-care-service/");
      this.setState({ homecareServices });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
    }
  }

  fetchPaymentGateways = async () => {
    try {
      const { data: paymentGateways = [] } = await API.get("payment-gateway/")
      this.setState({ paymentGateways });
    }
    catch (error) {
      console.log("Error on fetching payment gateways", error)
    }
  }

  fetchOperationsUsers = async ()=>{
    try{
      const data = (await fetchOperationsUsers()) || [];
      let  operationsUsers= data.sort((a, b) => {
        return a.userName.localeCompare(b.userName);
      });
      this.setState({operationsUsers})
    }
    catch(error){
      this.showErrorNotification(
        "Error on fetching Operations Users: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  }

  getHomecareServiceOptions = () => {
    const { selectedCountry, homecareServices = [] } = this.state;
    if (!selectedCountry) {
      console.log("getHomecareServiceOptions:: invalid country:", selectedCountry);
      return [];
    }
    const createHomecareServiceOption = (homecareService) => ({
      key: homecareService.id,
      value: homecareService.id,
      text: homecareService.name,
    })

    const noneOption = { key: "none", value: "none", text: "NONE" };
    const options = homecareServices
      .filter(homecareService => homecareService.country?.id === selectedCountry.id)
      .filter(homecareService => homecareService.is_active === true)
      .map(createHomecareServiceOption) ?? [];
    return [noneOption, ...options];
  }

  getPaymentGateways = () => {
    const { paymentGateways = [] } = this.state;

    const createPaymentGateways = (paymentGateway) => ({
      key: paymentGateway.id,
      value: paymentGateway.id,
      text: paymentGateway.name
    })
    return paymentGateways.map(createPaymentGateways) ?? [];
  }

  loadNutritionists() {
    const getNutritionistUsername = (nutritionist) => {
      const { userName = "", firstName = "", lastName = "" } = nutritionist;
      return userName || `${firstName} ${lastName}`; 
    };

    PhoenixAPI.get("coach/users")
      .then((response) => {
        const nutritionists = (response && response.data) ? response.data : [];
        if (nutritionists && nutritionists.length) {
          // const activeList = filterList(nutritionists, "active");
          const activeList=nutritionists.filter((it)=>it.active===true)
          let newList = activeList.sort((a, b) => {
            // return a.username.localeCompare(b.username);
            return compareStrings(getNutritionistUsername(a), getNutritionistUsername(b), true);
          });          
          let nutritionistListFormatted = newList.map((nutritionist) => {
            return {
              text: nutritionist.userName + " (" + nutritionist.email + ")",
              value: nutritionist.id,
              key: nutritionist.id,
            };
          });
          // This need to be checked as this line is not present in the master branch.
          // nutritionistListFormatted.splice(0, 0, { 'text': 'None', 'value': '', 'key': '' });
          this.setState({
            nutritionists: nutritionists,
            nutritionistListFormatted: nutritionistListFormatted,
          });
        }
      })
      .catch(error => console.log("Error fetching nutritionist data from backend!", error));
  }


  fetchCityId = async (cityName) => {
    const url = "cities/";
    try {
      const response = await API.get(url);
      const cities  = response && response.data;
      const city = cities && cities.find(city => city.city_name === cityName)
      return city && city.id;
    } catch (error) {
      console.log("Error on fetching cities from url: ", url);
      console.log("Error on fetching cities : ", error);
      return null;
    }
  }

  isPackageIsNoPackageType = (order) => {
    let noPackageType = order?.custom_package?.operation_type === null || "";
    return noPackageType
  }

  isPacakageIsBloodPackageType = (order) => order.package !== null && order.package !== undefined;

  isPacakageIsCustomPackageType = (order) => order.custom_package !== null && order.custom_package !== undefined;;

  isHomeAppointmentRequired = (order) => this.isPacakageIsCustomPackageType(order) && this.isCustomPackageOrderType(order.package_type);
  
  fetchTimeSlotsForDate = (date) => {
    if (!this.state.newUser) {
      this.fetchTimeSlotsForOrder(this.state.modalData, date);
      return;
    }
    const {package: p, custom_package } = this.state.modalData;
    if (p)
      this.fetchTimeSlotsForPackage(this.state.packageType, p, date);
    else if (custom_package) 
      this.fetchTimeSlotsForPackage(this.state.packageType, custom_package, moment(date).format("YYYY-MM-DD"));
    else
      this.setState({newTimeSlots: []});

    console.log("Date", date);
  }

  fetchTimeSlotsForOrder = async (order, selectedDate) => {
    if (!order) return;

    console.log("Order: ", order);
    console.log("OrderID", order.id);

    // if ((order.booked_slot_time || order.specimen_collection_timeslot)) {
    //   this.setState({newTimeSlots: [order.booked_slot_time || order.specimen_collection_timeslot]});
    // } 
    
    if (!selectedDate && (order.booked_slot_date || order.specimen_collection_date)) {
      const date = order.booked_slot_date || order.specimen_collection_date;
      if (date && moment(date).diff(moment().format("YYYY-MM-DD")) < 0) {
        this.setState({newTimeSlots: [order.booked_slot_time || order.specimen_collection_timeslot]});
        return;    
      }
    } 

    let packageId = null;
    let packageType = null;
    if (order.custom_package) {
      // Custom package so for calling new timeslot api we need to pass custom_package.
      packageType = "custom_package";
      packageId = order.custom_package.id;
    } else if (order.add_on) {
      // Add on package we dont need to call time slot API
      packageType = "null";
    } else if (order.package) {
      // Subscription package so for calling new timeslot api we need to pass blood_package.
      packageType = "blood_package";
      packageId = order.package.id;
    }
    console.log("PackageID", packageId);

    let cityId;
    let noPackageTypeOrder = this.isPackageIsNoPackageType(order)
    // console.log("noPackageTypeOrder:", noPackageTypeOrder)
    if (order.address && order.address.city){
      cityId = await this.fetchCityId(order.address.city);
      this.setState({pickupTimeErrorMessage: ""})
    } else if(noPackageTypeOrder){
      this.setState({pickupTimeErrorMessage: ""})
    } 
    else {
      this.setState({pickupTimeErrorMessage: "Timeslots can not be loaded! (No address found). Please update address for the User"})
    }
    
    console.log("Check: ", cityId, packageType, (order.booked_slot_date || order.specimen_collection_date));
    const d = selectedDate ? selectedDate : (order.booked_slot_date || order.specimen_collection_date);
    if (cityId && packageType && packageId && d)
      this.fetchTimeSlots(cityId, packageType, packageId, d);
  }

  fetchTimeSlotsForUser = (user) => 
    this.fetchTimeSlotsFor(user, this.state.packageType, this.state.modalData.package);

  fetchTimeSlotsForPackage = (type, selectedPackage, date = null) => {
    console.log("User:", this.state.modalData.user);
    this.fetchTimeSlotsFor(this.state.modalData.user, type, selectedPackage, date);
  }

  checkSelectedCustomPackage = () => {
    let selectedCustomPackage = this?.selectedPackageData?.operation_type === null || "" ? true : false;
    return selectedCustomPackage
  }

  fetchTimeSlotsFor = async (user, packageType, selectedPackage, date = null) => {
  //  console.log("selectedPackageData",this.selectedPackageData)
    let selectedPackageType = this.checkSelectedCustomPackage()

    this.setState({newTimeSlots : []});

    if (!user || !packageType || !selectedPackage)
      return;
    
    let cityId = null;
    console.log("user", user);
    console.log("user address", user.user_address);
    if (user.user_address) {
      const userAddresses = this.state.selectedCountry 
        ? user.user_address.filter(address => address.country === this.state.selectedCountry.country_name)
        : user.user_address;

      const cityName = userAddresses.length > 0 ? userAddresses[0].city : null;
      if (cityName) {
        cityId = await this.fetchCityId(cityName);
        console.log("CityID: ", cityId);
        this.setState({pickupTimeErrorMessage: ""});
       }
      else if (selectedPackageType) {
        this.setState({ pickupTimeErrorMessage: "" })
      }
      else {
        this.setState({pickupTimeErrorMessage: "Timeslots can not be loaded! (No city found). Please update address for the User"});
      }
    } else {
      this.setState({pickupTimeErrorMessage: "Timeslots can not be loaded! (No address found). Please update address for the User"});
    }

    let packageTypeParam = null;
    switch (packageType) {
      case "packages":
        packageTypeParam = "blood_package";
        break;
      case "custom":
        packageTypeParam = "custom_package";
        break;
      default:
        packageTypeParam = null;
        break;
    }

    if (cityId && packageTypeParam && selectedPackage.id) 
      this.fetchTimeSlots(cityId, packageTypeParam, selectedPackage.id, date || this.state.pickupDate);
    else 
      console.log("CityID: ", cityId, ", PackageID: ", selectedPackage.id, ", type: ", packageTypeParam);
  }

  fetchTimeSlots = async (cityId, packageType, packageId, date = null) => {
    const url = `get-lab-availability/?cities=${cityId}&${packageType}=${packageId}`;
    console.log("Fetching Timeslots from URL:", url);
    try {
      const today = moment().format("YYYY-MM-DD");
      const formattedDate = date ? date : today;
      console.log("Date:", formattedDate);
     
      const response = await API.get(url);
      console.log("Response(LabAvailability): ", response.data);
      console.log("Date", formattedDate);
      
      const { available_dates: availableDates } = response.data;
      if (availableDates) {
        const details = availableDates[formattedDate];
        if (details && details.is_holiday === false) {
          console.log("Slots For Date: ", formattedDate, details.slots);
          const newTimeSlots = (details.slots && details.slots.length > 0) 
            ? details.slots.map(slot => this.formatTimeSlot(slot))
            : [];
          this.setState({ newTimeSlots: this.appendTimeSlot(newTimeSlots) });
        }
      }
    } catch (error) {
      console.log("Error fetching timeslots from this URL: ", url);
      console.log("Error fetching timeslots error: ", error);
      this.setState({
        loadError: "Some error has occured. Please try again",
        newTimeSlots: this.getDefaultTimeSlots(),
      });
    }
  }

  appendTimeSlot = (timeSlots) => {
    const {newUser, modalData} = this.state;
    if (!newUser && modalData) {
      const bookedTimeSlot =  modalData.booked_slot_time || modalData.specimen_collection_timeslot;
      if (bookedTimeSlot && (timeSlots && (timeSlots.length ===0 || !timeSlots.find(timeSlot => timeSlot === bookedTimeSlot)))) {
        console.log("PreviousTimeslots: ", timeSlots);
        return [bookedTimeSlot, ...timeSlots]
      }
    }
    return timeSlots;
  }

  getDefaultTimeSlots = () => {
    const {newUser, modalData} = this.state;
    if (!newUser && modalData) {
      const timeslot = modalData.booked_slot_time || modalData.specimen_collection_timeslot;
      return timeslot ? [timeslot] : [];
    } else {
      return [];
    }
  }

  getFormattedBookTimeslot = (timeslot) => {
    if (!timeslot) return "";
    const [startTime, endTime] = timeslot.split("to");
    const startTime24 = moment(startTime, "hh:mm A").format("HH:mm");
    const endTime24 = moment(endTime, "hh:mm A").format("HH:mm");
    return `${startTime24}-${endTime24}`;
  } 

  formatTimeSlot = (timeSlot) => {
    const FORMAT_24_HOUR = "HH:mm";
    const FORMAT_12_HOUR = "hh:mm A";

    try {
      const [startTime24HourFormat, endTime24HourFormat] = timeSlot.split("-");
      const startTime12HourFormat = moment(startTime24HourFormat, FORMAT_24_HOUR).format(FORMAT_12_HOUR);
      const endTime12HourFormat = moment(endTime24HourFormat, FORMAT_24_HOUR).format(FORMAT_12_HOUR);
      return `${startTime12HourFormat} to ${endTime12HourFormat}`;
    } catch (error) {
      console.log("Error formatting timeslot: ", timeSlot, ", error", error);
      return null;
    }
  }

  // This function is commented because now we are fecthing timeslots from new API.
  // loadTimeSlots() {
  //   API.get("timeslots/")
  //     .then((response) => {
  //       let newList = response.data;

  //       for (let i = 0; i < newList.length; i++) {
  //         newList[i].index = i;
  //         newList[i].timeslot =
  //           moment(newList[i].slot_start_time, "HH:mm").format("hh:mm A") +
  //           " to " +
  //           moment(newList[i].slot_end_time, "HH:mm").format("hh:mm A");
  //       }

  //       newList.sort((a, b) => {
  //         let time1 = moment(a.slot_start_time, "HH:mm");
  //         let time2 = moment(b.slot_start_time, "HH:mm");

  //         if (time1.isBefore(time2)) return -1;
  //         else if (time1.isAfter(time2)) return 1;
  //         else return 0;
  //       });

  //       this.setState({ timeSlots: newList });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again",
  //       });
  //     });
  // }

  validateCnfirmDate(date,confirmTime) {
    const startDate = moment().format('YYYY-MM-DD');
    const formattedDate = moment(date, "YYYY-MM-DD", true);
    console.log("check values",date,confirmTime);

    if (formattedDate.isValid()) {
      if (formattedDate.isSameOrAfter(startDate)) {
        if(formattedDate.isSame(startDate)){
          const currentTime = moment();
           console.log("check value",currentTime,confirmTime);
               if(!(moment(confirmTime, "HH:mm").isAfter(currentTime))){
                this.customConfirmValidator.confirmTime.message ="Time should  be after present time";
                this.customConfirmValidator.confirmDate.message = null;
              return false;
              
              }
        }
        this.customConfirmValidator.confirmDate.message = null;
        this.customConfirmValidator.confirmTime.message =null;
        return true;
      } else {
        this.customConfirmValidator.confirmDate.message =
          "Date should not be in the past";
        return false;
      }
    } else {
      this.customConfirmValidator.confirmDate.message = "Invalid date.";
      return false;
    }
  }

  validateDate(date,pickuptime) {
    const startDate = moment().format('YYYY-MM-DD');
    const formattedDate = moment(date, "YYYY-MM-DD", true);

    if (formattedDate.isValid()) {
      if (formattedDate.isSameOrAfter(startDate)) {

        if(formattedDate.isSame(startDate)){
          const currentTime = moment();
          console.log("check value validate",pickuptime,date);
               if(pickuptime && !(moment(pickuptime, "HH:mm").isAfter(currentTime))){
                this.customValidator.pickupTime.message =
                "Time should  be after present time";
              return false;
              
              }
        }
        this.customValidator.pickupDate.message = null;
        return true;
      } else {
        this.customValidator.pickupDate.message =
          "Date should not be in the past";
        return false;
      }
    } else {
      this.customValidator.pickupDate.message = "Invalid date.";
      return false;
    }
  }
  calculateDifference = (endDate) => {
    const startDateMoment = moment();
    const endDateMoment = moment(endDate);
    const today=moment().format("YYYY-MM-DD");
  
   
    const endDateFormat=moment(endDate,"YYYY-MM-DD",true);
   
    if(endDateFormat.isSame(today)){

    return "same";
    }
      console.log("end  and start Date", startDateMoment,endDateMoment);
    // Calculate the difference in days
  
    const differenceInDays = endDateMoment.diff(startDateMoment, 'days');
     console.log("difference in days",differenceInDays);
    return differenceInDays;
  
  };

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
    
  }

  hideRescheduleDateModal=()=>{
    this.setState({
      confirmDatePopup:true,
      isrescheduleDateConfirmPopup:false,
    },()=>this.dateConfirmationPopupConfirmed())
  }
  cancelRescheduleDate=()=>{
    this.setState({
      confirmDate:this.state.oldConfirmDate,
      isrescheduleDateConfirmPopup:false,
    })
  }
  showRescheduleDateModal=()=>{
    this.setState({
      isrescheduleDateConfirmPopup:true
    })
  }

  handleOrderStatusLogs = (newValue, order) => { 
    const userEmail = getUserEmail();

    let payload = {
      "modifiedBy": userEmail ?? `Email not available. user id: ${getUserId()}`,
      "previousValue": order.order_status,
      "newValue": newValue,
      "orderId": order.id,
      "event": EVENT_ORDER_STATUS_CHANGE
    }

    PhoenixAPI.post(BASE_URL_AUDIT_TRAILS, payload)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log("Error on creating order status logs", error);
        this.showErrorNotification("Error on saving order status log", error.message);
      });
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  checkOrderStatus = (order, orderStatus) => {

    let noPackage = this.isPackageIsNoPackageType(order);
    const isHairLossJourneyItem = this.isHairLossJourneyItem(order?.order_item_type);

    if (orderStatus === 'COMPLETED' && !noPackage && !isHairLossJourneyItem) {
      if (!order.package_type && !order.is_result_uploaded && order.contents !== "A") {
        this.showErrorNotification("Upload results to mark as completed.")
        return false;
      } else if (!order.package_type && order.contents == "A" && !order.nutritionist) {
        this.showErrorNotification("Assign a Wellbeing coach to mark as completed");
        return false;
      } else if ((order.package_type && order.package_type.indexOf('Requires Coach Note') > -1) && (!order.nutritionist || !order.doctors_note || !order.doctors_note_ar)) {
        this.showErrorNotification("Assign a Coach / Add Coach review's to mark as completed");
        return false;
      } else if (order.package_type && order.package_type.indexOf('PDF Download') > - 1) {
        API.get("lab-result/?order=" + order.id)
          .then((response1) => {
            if (response1.data.length > 0) {
              return true;
            } else {
              this.showErrorNotification("Upload results to mark as completed.");
              return false;
            }
          })
          .catch((error) => {
            console.log("Error on fetching lab result", error);
            this.showErrorNotification("Upload results to mark as completed.");
            return false;
          });
      } else {
        return true;
      }
    } else if (orderStatus == 'COACH REVIEWED') {
      if (order.package_type && !order.nutritionist) {
        this.showErrorNotification("Assign a Coach mark as Coach Reviewd.");
        return false;
      } else {
        return true;
      }
    }
    else {
      return true;
    }
  }

  handleStatusChange(newValue, order) {
    let noPackage = this.isPackageIsNoPackageType(order);
    const isHairLossJourneyItem = this.isHairLossJourneyItem(order?.order_item_type);

    console.log("handleStatusChange", order?.order_item_type, isHairLossJourneyItem);

    this.hideStatusConfirm();
    // debugger;
    if (newValue === 'COMPLETED' && !noPackage && !isHairLossJourneyItem) {
      if (!order.package_type && !order.is_result_uploaded && order.contents !== "A") {
        this.props.showNotificationMessage({
          notificationMessage: "Upload results to mark as completed.",
          successMessage: false,
          showNotification: true,
        });
        return;
      } else if (!order.package_type && order.contents == "A" && !order.nutritionist) {
        this.props.showNotificationMessage({
          notificationMessage: "Assign a Wellbeing coach to mark as completed",
          successMessage: false,
          showNotification: true,
        });
        return;
      } else if ((order.package_type && order.package_type.indexOf('Requires Coach Note') > -1) && (!order.nutritionist || !order.doctors_note || !order.doctors_note_ar)) {
        this.props.showNotificationMessage({
          notificationMessage: "Assign a Coach / Add Coach review's to mark as completed",
          successMessage: false,
          showNotification: true,
        });
        return;
      } else if (order.package_type && order.package_type.indexOf('PDF Download') > - 1) {
        API.get("lab-result/?order=" + order.id)
          .then((response1) => {
            if (response1.data.length > 0) {
              // let order1 = this.state.orders.slice(-20);
              // this.setState({ orders: order1 })
              this.updateStatus(newValue, order);
            } else {
              this.props.showNotificationMessage({
                notificationMessage: "Upload results to mark as completed.",
                successMessage: false,
                showNotification: true,
              });
              return false;
            }
          })
          .catch((error) => {
            console.log("Error on fetching lab result", error);
            this.props.showNotificationMessage({
              notificationMessage: "Upload results to mark as completed.",
              successMessage: false,
              showNotification: true,
            });
            return false;
          });
      } else {
        // let order1 = this.state.orders.slice(-20);
        // this.setState({ orders: order1 })
        this.updateStatus(newValue, order);
      }
    } else if (newValue == 'COACH REVIEWED') {
      if (order.package_type && !order.nutritionist) {
        this.props.showNotificationMessage({
          notificationMessage: "Assign a Coach mark as Coach Reviewd.",
          successMessage: false,
          showNotification: true,
        });
        return;
      } else {
        // let order1 = this.state.orders.slice(-20);
        // this.setState({ orders: order1 })
        this.updateStatus(newValue, order);
      }
    }  
    else {
      // let order1 = this.state.orders.slice(-20);
      // this.setState({ orders: order1 })
      this.updateStatus(newValue, order);
    }
  }

  updateStatus(newValue, order) {
  
    this.props.toggleLoading({
      isLoading: true,
    });
    const userId=getUserId();
   
    API.patch("order-list/" + order.id + "/", { order_status: newValue, modified_by:userId })
      .then((response) => {
        let orderData = [...this.state.orders];
        let orderIndex = this.state.orders.findIndex(item => item.id === order.id);
        if (newValue !== 'COMPLETED') {
          orderData[orderIndex] = response.data;
        } else {
          orderData.splice(orderIndex, 1);
        }

        this.setState({ orders: orderData });
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Status successfully updated.",
          successMessage: true,
          showNotification: true,
        });
        // this.loadData();
        this.handleOrderStatusLogs(newValue,order)
      })
      .catch((error) => {
        console.log("Error on updating status", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  
  }
  
  cancelOrder(orderId, type) {
    this.hideCancelConfirm();
    this.state.refundNotes?this.hideCancelRefund():this.hideCancelConfirm();
    let params = {};
    this.props.toggleLoading({
      isLoading: true,
    });
    if (type == "addon") {
      //add on cancel
      params["event"] = "addon.cancelled";
    } else if (type == "subscription") {
      // check if order is one-time or subscription
      if (this.state.packageType == "subscription") {
        params["event"] = "subscription.cancelled";
      } else {
        //one-time
        params["event"] = "package.cancelled";
      }
    } else if (type == "both") {
      params["is_active"] = false;
      params["order_status"] = "CANCELLED";
    } else {
      if (
        this.state.orderBatchId != null &&
        this.state.orderBatchId != this.state.orderId
      ) {
        //cancelling a subscription order(not the first one)
        params["is_active"] = false;
        params["order_status"] = "CANCELLED";
        orderId = this.state.orderId;
      } else {
        params["is_active"] = false;
        params["order_status"] = "CANCELLED";
      }
    } 
    if(this.state.refundNotes){
    params["is_refund_and_cancel_order"]=true;
    params["refund_and_cancel_order_notes"]=this.state.refundNotes;
    params["is_refund_and_cancel_notify"]=this.state.is_refund_and_cancel_notify;
    params["cancellation_reason"]=this.state.cancelReason;
    }

    if(this.state.cancelNotes){
      params["cancellation_notes"]=this.state.cancelNotes;
      params["cancellation_reason"]=this.state.cancelReason;

    }
    const userId=getUserId();
    if(userId){
      params.modified_by=userId;
    }

    params.is_send_notification = this.state.isSendNotification ?? true;
    API.patch("order-list/" + orderId + "/", params)
      .then((response) => {
        let orderData = [...this.state.orders];
        let orderIndex = this.state.orders.findIndex(item => item.id === orderId);
        if (orderIndex > -1) {
          orderData.splice(orderIndex, 1);
        }
        this.hideModal();
        this.setState({ orders: orderData });
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Order successfully cancelled.",
          successMessage: true,
          showNotification: true,
        });
        this.handleOrderStatusLogs("CANCELLED",this.state.selectedOrder)
      })
      .then (response => {
        // checkAndUpdateParentOrderStatus(orderId, this.state.isSendNotification);
        this.showSuccessNotification("Parent order status is successfully updated.");
      })
      .catch((error) => {
        console.log("Error on cancelling order", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  addNotes() {
    const id = this.state.orderId;
    const order_notes = this.state.order_notes;
    const userId=getUserId()
    let formData = new FormData();
    formData.append("order_notes", this.state.order_notes);
    formData.append("modified_by",userId)
    if (!order_notes) return;

    this.hideNoteAssignModal();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("order-list/" + id + "/", formData)
      .then((response) => {
        // let orderData = [...this.state.orders];
        // let orderIndex = this.state.orders.findIndex(item => item.id == id);
        // if (orderIndex > -1) {
        //   orderData[orderIndex] = response.data;
        // }
        // this.setState({ orders: orderData });
        this.updateOrder(id, response.data);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Note successfully assigned.",
          successMessage: true,
          showNotification: true,
        });
        // this.loadData();
      })
      .catch((error) => {
        console.log("Error on adding notes", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  updateOrder(id, data) {
    let orderData = [...this.state.orders];
    let orderIndex = this.state.orders.findIndex(item => item.id == id);
    if (orderIndex > -1) {
      orderData[orderIndex] = data;
    }
    this.setState({ orders: orderData });
  }

  assignLab(labId, row) {
    const id = row.id;
    // const labId = this.state.labId;
    let formData = new FormData();
    const userId=getUserId()
    formData.append("laboratory", labId);
    formData.append("modified_by",userId)

    if (!labId) return;

    this.hideLabAssignModal();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("order-list/" + id + "/", formData)
      .then((response) => {

        this.updateOrder(id, response.data);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Lab successfully assigned.",
          successMessage: true,
          showNotification: true,
        });
        // this.loadData();
      })
      .catch((error) => {
        console.log("Error on assigning lab", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  assignNutritionist() {
    const id = this.state.orderId;
    const nutritionistId = this.state.nutritionistId;
    const userId=getUserId()
    let formData = new FormData();
    formData.append("nutritionist", this.state.nutritionistId);
     formData.append("modified_by",userId)
    if (!nutritionistId) return;

    this.hideAssignModal();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.patch("order-list/" + id + "/", formData)
      .then((response) => {
        this.updateOrder(id, response.data);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Wellbeing coach successfully assigned.",
          successMessage: true,
          showNotification: true,
        });
        // this.loadData();
      })
      .catch((error) => {
        console.log("Error on assigning nutritionist", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  dateConfirmationPopupConfirmed(){
    const { confirmDate, confirmTime, orderId } = this.state;
    this.hideConfirmTime();
      this.props.toggleLoading({
        isLoading: true,
      });
      const userId=getUserId()


      API.patch("order-list/" + orderId + "/", {
        confirmed_date_time: confirmDate + ' ' + confirmTime,
        modified_by:userId
      })
        .then((response) => {

          let orderData = [...this.state.orders];
          let orderIndex = this.state.orders.findIndex(item => item.id == orderId);
          if (orderIndex > -1) {
            orderData[orderIndex] = response.data;
          }

          this.setState({ orders: orderData });

          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Confirm time saved successfully.",
            successMessage: true,
            showNotification: true,
          });
          // this.loadData();
        })
        .catch((error) => {
          console.log("Error on saving confirmed time", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
  }

  confirmTime() {
    const { confirmDate, confirmTime, orderId } = this.state;
    // const newPickupTime = confirmTime.replace(" to ", "-");
    console.log("check values in confirm time",confirmDate,confirmTime);
    const isValidDate = this.validateCnfirmDate(confirmDate,confirmTime);
    const confirmvalidation = this.confirmvalidator.validate(this.state);
    this.setState({ confirmvalidation });
    this.confirmsubmitted = true;

    if (confirmvalidation.isValid && isValidDate) {
      this.setState({isrescheduleDateConfirmPopup:true});
    }
    else{
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields",
        successMessage: false,
        showNotification: true,
      });
    }
  
  }

  reschedulePickup() {
    const { pickupDate, pickupTime, orderId } = this.state;
    const newPickupTime = pickupTime.replace(" to ", "-");

    const isValidDate = this.validateDate(pickupDate,newPickupTime);
    const validation = this.validator.validate(this.state);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid && isValidDate) {
      this.hideReschedule();
      this.props.toggleLoading({
        isLoading: true,
      });
      const userId=getUserId()
      API.patch("order-list/" + orderId + "/", {
        booked_slot_date: pickupDate,
        booked_slot_time: newPickupTime,
        modified_by:userId
      })
        .then((response) => {

          let orderData = [...this.state.orders];
          let orderIndex = this.state.orders.findIndex(item => item.id === orderId);
          if (orderIndex > -1) {
            orderData[orderIndex] = response.data;
          }

          this.setState({ orders: orderData });

          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Pickup rescheduled successfully.",
            successMessage: true,
            showNotification: true,
          });
          // this.loadData();
        })
        .catch((error) => {
          console.log("Error on rescheduling pickup", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  hideAssignModal() {
    this.setState({ showAssignModal: false });
  }

  hideLabAssignModal() {
    this.setState({ showLabAssignModal: false });
  }

  hideNoteAssignModal() {
    this.setState({ showNoteAssignModal: false });
  }
  showAssignModal(orderId, nutritionistId, nutritionistName) {
    this.setState({
      orderId: orderId,
      nutritionistId: nutritionistId,
      nutritionistName: nutritionistName,
      showAssignModal: true,
    });
  }

  hideConfirmTime() {
    this.setState({ showConfirmTime: false,confirmDatePopup:false});
  }

  showConfirmTime(row) {
    this.confirmsubmitted = false;
    this.customConfirmValidator.confirmDate.message = null;
    this.customConfirmValidator.confirmTime.message=null;
    this.setState({
      orderId: row.id,
      showConfirmTime: true,
      oldConfirmDate:row.confirmed_date_time && row.confirmed_date_time.split(' ')[0] ? row.confirmed_date_time.split(' ')[0] : '',
      confirmDate: row.confirmed_date_time && row.confirmed_date_time.split(' ')[0] ? row.confirmed_date_time.split(' ')[0] : '',
      confirmTime: row.confirmed_date_time && row.confirmed_date_time.split(' ')[1] ? row.confirmed_date_time.split(' ')[1] : '',
      confirmvalidation: this.confirmvalidator.valid(),
    });
  }

  showLabAssignModal(orderId, labId) {
    this.setState({
      orderId: orderId,
      labId: labId,
      showLabAssignModal: true,
    });
  }

  showNoteAssignModal(orderId, order_notes) {
    this.setState({
      orderId: orderId,
      order_notes: order_notes,
      showNoteAssignModal: true,
    });
  }

  hideStatusConfirm() {
    this.setState({
      showStatusConfirm: false,
      confirmItem: null,
      newStatus: null,
    });
  }

  showStatusConfirm(newValue, confirmItem) {
    this.setState({
      showStatusConfirm: true,
      confirmItem,
      newValue,
    });
  }

  hideCancelConfirm() {
    this.setState({
      orderId: null,
      showCancelConfirm: false,
      cancelError:"",
      cancelReason: "",
      cancelReasonError: "",
      cancelNotes: "",
      refundError:""

    });
  }
  hideCancelRefund(){
    this.setState({
      orderId:null,
      showCancelRefund:false,

    })
  }

  showCancelConfirm(row, isSendNotification = false) {
    this.setState({
      orderId: row.id,
      showCancelConfirm: true,
      orderContentType: row.contents,
      packageType: row.order_type,
      orderBatchId: row.order_batch_id,
      isSendNotification
    });
  }

  showCancelRefund(row,isSendNotification=false){

    this.setState({
      orderId:row.id,
      showCancelRefund:true,
      orderContentType: row.contents,
      packageType: row.order_type,
      orderBatchId: row.order_batch_id,
      isSendNotification

    })
  }

  hideReschedule() {
    this.setState({
      showReschedule: false,
    });
  }

  showReschedule(orderId) {
    this.submitted = false;
    this.customValidator.pickupDate.message = null;
    this.customValidator.pickupTime.message=null;
    this.setState({
      orderId: orderId,
      showReschedule: true,
      pickupDate: "",
      pickupTime: "",
      validation: this.validator.valid(),
    });
  }

  getNutritionistName(id) {
    const nutritionists = this.state.nutritionists;

    if (nutritionists.length > 0) {
      let nutritionist = nutritionists.find((item) => {
        return item.id === id;
      });

      if (nutritionist) {
        if (nutritionist.is_active)
          return nutritionist.username + " (" + nutritionist.email + ")";
        else
          return (
            nutritionist.username +
            " (" +
            nutritionist.email +
            ")" +
            " (Deactivated)"
          );
      }
    }

    return null;
  }

  newFormattedList(list, id) {
    if (list && list.length > 0) {
      const newList = [...list];

      const item = {
        text: this.getNutritionistName(id),
        value: id,
        key: id,
      };

      if (newList.find((element) => element.key === id)) return newList;

      newList.push(item);
      return newList;
    }
  }

  getLabName(id) {
    const labs = this.state.labs;

    if (labs.length > 0) {
      let lab = labs.find((item) => {
        return item.id === id;
      });

      if (lab) {
        if (lab.is_active)
          return lab.lab_name;
        else
          return (
            lab.lab_name +
            " (Deactivated)"
          );
      }
    }

    return null;
  }

  newFormattedLabList(list, id) {
    if (list && list.length > 0) {
      const newList = [...list];

      const item = {
        text: this.getLabName(id),
        value: id,
        key: id,
      };

      if (newList.find((element) => element.key === id)) return newList;

      newList.push(item);
      return newList;
    }
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }
    list = this.state.orders;
    if (!list) return;
    let newList = [];
    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    const { filterItemType = "" } = this.state;
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      // this.loadOrders();
      if (filterItemType && filterItemType !== "All Orders") {
        this.fetchActiveOrders(1, filterItemType);
      }
      else {
        this.fetchActiveOrders()
      }
    });
  }
  cancelNotesValidation=(cancelReason,cancelNotes)=>{
    
    if((cancelReason=="" || cancelReason==null) && (cancelNotes==null || cancelNotes=="")){
      this.setState({cancelError:"please enter Reason ",refundError:"Please enter notes"});
      
    }
    else if(cancelReason=="" || cancelReason==null)
    {
     this.setState({cancelError:"please enter Reason"});
    }
    else if(cancelNotes==null || cancelNotes==""){
      this.setState({refundError:"please select Notes"});
      
    }

    else{
      this.setState({cancelError:"",refundError:""});
     return true;
    }
    return false;
  }


  RefundNotesValidation=(cancelReason,cancelNotes)=>{
    
    if((cancelReason=="" || cancelReason==null) && (cancelNotes==null || cancelNotes=="")){
      this.setState({cancelError:"please enter Reason ",refundError:"Please enter notes"});
      
    }
    else if(cancelReason=="" || cancelReason==null)
    {
     this.setState({cancelError:"please enter Reason"});
    }
    else if(cancelNotes==null || cancelNotes==""){
      this.setState({refundError:"please select Notes"});
      
    }

    else{
      this.setState({cancelError:"",refundError:""});
     return true;
    }
    return false;
 
   }

   
  clearSearch() {
    const { filterItemType = "" } = this.state;
    this.setState({
      searchTerm: "",
      searchValue: '',
      searchExpanded: true,
      searchApplied: false,
    }, () => {
      // this.loadOrders();
      if (filterItemType && filterItemType !== "All Orders") {
        this.fetchActiveOrders(1, filterItemType);
      }
      else {
        this.fetchActiveOrders()
      }
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }
  setShowItems(mode) {
    let showItems;
    const { orders } = this.state;
    var upcomingOrder = orders;
    var activeOrders = orders;
    if (mode === "upcoming") {
      showItems = "Upcoming Order";
      var order = upcomingOrder.filter(
        (val) => val.order_status === "UPCOMING"
      );
    } else if (mode === "all") {
      showItems = "Active Orders";
      var order = activeOrders;
    }
    this.setState({
      showItems: showItems,
      upcomingOrders: order,
      filterApplied: true,
    });
  }

  createNewUser = () => {
    this.setState({ 
      showModal: true, 
      newUser: true,
      pickupTimeErrorMessage: "",
      newTimeSlots: [],
      selectedPackageData:{}
    })
  }

  hideModal = () => {
    const {countries = []} = this.state;
    const activeCountries = countries.filter(it => it.is_active);

    this.setState({
      showModal: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editLabel: false,
      editStreet: false,
      editBuilding: false,
      editArea: false,
      editCity: false,
      editCountry: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      key: "orderInfo",
      newUser: false,
      modalData: {},
      updateOrder: {},
      editPackage: false,
      editOrderEmail: false,
      editClientNotes: false,
      editAppiontmentDate: false,
      editAppiontmentTime: false,
      editConfirmDate: false,
      editConfirmTime: false,
      editOrderStatus: false,
      editLab: false,
      editCoach: false,
      editAttachment: false,
      editNotes: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      newUser: false,
      editLabCosts: false,
      editHomecareCosts: false,
      editHomecareService: false,
      package_id: null,
      custom_package_id: null,
      addon_package_id: null,
      pickupDateErrorMessage: null,
      pickupTimeErrorMessage: "",
      confirmDateErrorMessage: null,
      confirmTimeErrorMessage:"",
      emailErrorMessage: null,
      newTimeSlots: [],
      selectedCountry: activeCountries.length === 1 ? activeCountries[0] : null,
      searchTermEmail: "",
      packageType: null,
      pickupDate: "",
      pickupTime: "",
      updatedOrder: {},
      selectedPackageData: {},
      editOperationsPerson:false,
      editFullNameId:false,
      editFrontId:false,
      editBackId:false,
      frontIdSrc:"",
      backIdSrc:"",
      user:{},
      fullNameIdErrorMessage:null,
      frontIdErrorMessage:null,
      backIdErrorMessage:null,
      labCostsErrorMessage: "",
      homecareCostsErrorMessage: "",
      editables:{},
      customTestPriceAddErrorMessage:"",
      lab1CustomCostErrorMessage:"",
      homeCareCostErrorMessage:"",
      referenceNumber1ErrorMessage:"",
      lab2CustomCostErrorMessage:"",
      referenceNumber2ErrorMessage:"",
      revisedPriceErrorMessage:"",
      couponAmountErrorMessage:"",
      revisedPriceCouponErrorMessage:"",
      refundAmountErrorMessage:"",
      customTestBiomarkerErrorMessage:"",
      notesErrorMessage:"",
      couponCodeValueErrorMessage:""
    });
    // this.clearForm();
  }

  showModal =async(orderId) => {
   try{
     const res = await API.get(`order-list/${orderId}/`)
     const row=res && res.data||[];

     if (row && !this.state.newUser)
       this.fetchTimeSlotsForOrder(row);

     let pickupDate = row.booked_slot_date
       ? row.booked_slot_date
       : row.specimen_collection_date
         ? row.specimen_collection_date
         : '';

     let pickupTime = row.booked_slot_time
       ? row.booked_slot_time
       : row.specimen_collection_timeslot
         ? row.specimen_collection_timeslot
         : '';

     let confirmDate = "";
     let confirmTime = "";
     if (row.confirmed_date_time) {
       const dateTimeArray = row.confirmed_date_time.split(' ')
       confirmDate = dateTimeArray && dateTimeArray.length > 0 ? dateTimeArray[0] : "";
       confirmTime = dateTimeArray && dateTimeArray.length > 1 ? dateTimeArray[1] : '';
     }

     // let package_id = !row.package_type ? (
     //   row.package && row.package !== null
     //     ? 'packages' + row.package.id
     //     : row.add_on && row.add_on !== null
     //       ? 'add-ons' + row.add_on.id
     //       : ""
     // ) : (
     //   row.custom_package && row.package_type ? (
     //     row.custom_package.id
     //       ? 'custom' + row.custom_package.id
     //       : ""
     //   ) : ("")
     // );

     let package_id = "";
     if (row && row.package) {
       package_id = 'packages' + row.package.id || "";
     } else if (row && row.custom_package) {
       package_id = 'custom' + row.custom_package.id || "";
     } else if (row && row.add_on) {
       package_id = 'add-ons' + row.add_on.id || "";
     }

     row['transaction_id'] = row.transaction && row.transaction.length > 0 ? row.transaction[0].transaction_id : ''
     row['package_id'] = package_id;
     row['user_id'] = row.user && row.user.id ? row.user.id : '';
     // if (row.user && row.user.username) {
     //   row['user']['first_name'] = row.user.username.split(' ')[0];
     //   row['user']['last_name'] = row.user.username.split(' ')[0];
     // }
     let isAppointmentDateLableRequired = !row.package_type ? (
       row.package && row.package !== null
         ? true
         : row.add_on && row.add_on !== null
           ? false
           : false
     ) : (
       row.custom_package && row.package_type ? (
         row.package_type.indexOf('Home Appointment') > -1
           ? true
           : false
       ) : (false)
     );

     isAppointmentDateLableRequired = !this.isPackageIsNoPackageType(row);

     const { custom_package } = row;
     let { user } = row;
     let front_id = user ? user.front_id : "";
     let back_id = user ? user.back_id : "";
     isAppointmentDateLableRequired = custom_package && custom_package.operation_type && custom_package.operation_type.includes("Home Appointment");

     this.setState({
       showModal: true,
       modalData: { ...row, client_note: row.user ? row.user.client_note : null },
       confirmDate: confirmDate,
       confirmTime: confirmTime,
       pickupDate: pickupDate,
       pickupTime: pickupTime,
       isAppointmentDateLableRequired: isAppointmentDateLableRequired ?? false,
       selectedOrder: { ...row },
       frontIdSrc: user?.front_id_s3_url ?? front_id,
       backIdSrc: user?.back_id_s3_url ?? back_id,
       dependent_id: row.dependent_id,
       member: row.member,
     }, () => {
       if (this.state.modalData && this.state.modalData.user && this.state.modalData.user.user_address) {
         this.state.modalData.user.user_address.map((item, i) => {
           let keysInObject = Object.keys(this.state.modalData.user.user_address);
           keysInObject.map(val => {
             this.setState({
               [val]: false
             })
           })
         })
       }
       if ($('.modal-backdrop').length > 0 && this.state.showModal) {
         setTimeout(function () {
           $('.modal-backdrop').addClass('modal-backdrop-updated');
         });
       }
       let countryId = null;
       if (this.state.modalData.custom_package) countryId = this.state.modalData.custom_package.country;
       else if (this.state.modalData.package) countryId = this.state.modalData.package.country;
       else if (this.state.modalData.add_on) countryId = this.state.modalData.add_on.country;

       if (countryId !== null && countryId !== undefined)
         this.setState({ selectedCountry: this.findCountry(countryId) });
       else if (this.state.modalData.address && this.state.modalData.address.country)
         this.setState({ selectedCountry: this.findCountryByName(this.state.modalData.address.country) });
     });
   }catch(error){
    console.log(error,"Error while fetching the active order data");
   }
   

    // if (row && !this.state.newUser)
    //   this.fetchTimeSlotsForOrder(row);
    
    // let pickupDate = row.booked_slot_date 
    //   ? row.booked_slot_date
    //   : row.specimen_collection_date 
    //     ? row.specimen_collection_date 
    //     : '';

    // let pickupTime = row.booked_slot_time 
    //   ? row.booked_slot_time 
    //   : row.specimen_collection_timeslot 
    //     ? row.specimen_collection_timeslot 
    //     : '';

    // let confirmDate = "";
    // let confirmTime = "";
    // if (row.confirmed_date_time) {
    //   const dateTimeArray = row.confirmed_date_time.split(' ')
    //   confirmDate = dateTimeArray && dateTimeArray.length > 0 ? dateTimeArray[0] : "";
    //   confirmTime = dateTimeArray && dateTimeArray.length > 1 ? dateTimeArray[1] : '';
    // }

    // // let package_id = !row.package_type ? (
    // //   row.package && row.package !== null
    // //     ? 'packages' + row.package.id
    // //     : row.add_on && row.add_on !== null
    // //       ? 'add-ons' + row.add_on.id
    // //       : ""
    // // ) : (
    // //   row.custom_package && row.package_type ? (
    // //     row.custom_package.id
    // //       ? 'custom' + row.custom_package.id
    // //       : ""
    // //   ) : ("")
    // // );

    // let package_id = "";
    // if (row && row.package) {
    //   package_id = 'packages' + row.package.id || "";
    // } else if (row && row.custom_package) {
    //   package_id = 'custom' + row.custom_package.id || "";
    // } else if (row && row.add_on) {
    //   package_id = 'add-ons' + row.add_on.id || "";
    // }

    // row['transaction_id'] = row.transaction && row.transaction.length > 0 ? row.transaction[0].transaction_id : ''
    // row['package_id'] = package_id;
    // row['user_id'] = row.user && row.user.id ? row.user.id : '';
    // // if (row.user && row.user.username) {
    // //   row['user']['first_name'] = row.user.username.split(' ')[0];
    // //   row['user']['last_name'] = row.user.username.split(' ')[0];
    // // }
    // let isAppointmentDateLableRequired = !row.package_type ? (
    //   row.package && row.package !== null
    //     ? true
    //     : row.add_on && row.add_on !== null
    //       ? false
    //       : false
    // ) : (
    //   row.custom_package && row.package_type ? (
    //     row.package_type.indexOf('Home Appointment') > -1
    //       ? true
    //       : false
    //   ) : (false)
    // );

    // isAppointmentDateLableRequired = !this.isPackageIsNoPackageType(row);

    // const { custom_package } = row;
    // let {user}= row;
    // let front_id= user?user.front_id:"";
    // let back_id = user?user.back_id:"";
    // isAppointmentDateLableRequired = custom_package && custom_package.operation_type && custom_package.operation_type.includes("Home Appointment");
   
    // this.setState({
    //   showModal: true,
    //   modalData: {...row, client_note: row.user ? row.user.client_note : null},
    //   confirmDate: confirmDate,
    //   confirmTime: confirmTime,
    //   pickupDate: pickupDate,
    //   pickupTime: pickupTime,
    //   isAppointmentDateLableRequired: isAppointmentDateLableRequired ?? false,
    //   selectedOrder:{...row},
    //   frontIdSrc: user?.front_id_s3_url ?? front_id,
    //   backIdSrc: user?.back_id_s3_url ?? back_id,
    //   dependent_id: row.dependent_id,
    //   member: row.member,
    // }, () => {
    //   if (this.state.modalData && this.state.modalData.user && this.state.modalData.user.user_address) {
    //     this.state.modalData.user.user_address.map((item, i) => {
    //       let keysInObject = Object.keys(this.state.modalData.user.user_address);
    //       keysInObject.map(val => {
    //         this.setState({
    //           [val]: false
    //         })
    //       })
    //     })
    //   }
    //   if ($('.modal-backdrop').length > 0 && this.state.showModal) {
    //     setTimeout(function () {
    //       $('.modal-backdrop').addClass('modal-backdrop-updated');
    //     });
    //   }
    //   let countryId = null;
    //   if (this.state.modalData.custom_package) countryId = this.state.modalData.custom_package.country;
    //   else if (this.state.modalData.package) countryId = this.state.modalData.package.country;
    //   else if (this.state.modalData.add_on) countryId = this.state.modalData.add_on.country;

    //   if (countryId !== null && countryId !== undefined)
    //     this.setState({selectedCountry: this.findCountry(countryId)});
    //   else if (this.state.modalData.address && this.state.modalData.address.country)
    //     this.setState({ selectedCountry: this.findCountryByName(this.state.modalData.address.country) });
    // });
  }

  makeEditable = (key) => {
    this.setState({
      [key]: true
    })
  }


  handleInput = (e, data) => {

    if (data === "pickupDate")
      this.fetchTimeSlotsForDate(e.target.value);

    if (data === "pickupDate" || data === "pickupTime" ) {
      
      this.setState({
        updatedOrder: {
          ...this.state.updatedOrder,
          [data]: e.target.value
        },
        [data]: e.target.value
      })
    }
    else if(data=="confirmDate"){
      
      
      const formattedDate = moment(e.target.value, "YYYY-MM-DD", true);
      const today = moment().format('YYYY-MM-DD');
       console.log("checkvalues",this.state.confirmDateErrorMessage,formattedDate,e.target.value);
      if(!(formattedDate.isSameOrAfter(today)))
     {  console.log("check values");
     this.setState({confirmDateErrorMessage:"confirm Date cannot be past"});
       
      }
      else{
        this.setState({
          updatedOrder: {
            ...this.state.updatedOrder,
            [data]: e.target.value
          },
          [data]: e.target.value
        })
        this.setState({confirmDateErrorMessage:""});
      }
     
   
    }
    else if(data=="confirmTime"){
      const today = moment().format('YYYY-MM-DD');
    
                  const formattedDate=moment(this.state.confirmDate,"YYYY-MM-DD", true)
                  const isDateToday= formattedDate.isSame(today);
                  console.log("confirm date",this.state.confirmDate);
                  if(isDateToday)
                  {
                    const currentTime = moment();
                   if(!(moment(e.target.value, "HH:mm").isAfter(currentTime))){
                  
                this.setState({confirmTimeErrorMessage:"Time should be after present time "});
                  }
                  else{
                    this.setState({
                      updatedOrder: {
                        ...this.state.updatedOrder,
                        [data]: e.target.value
                      },
                      [data]: e.target.value
                    })
                    this.setState({confirmTimeErrorMessage:""});
                  }
                }
                else{
                  this.setState({
                    updatedOrder: {
                      ...this.state.updatedOrder,
                      [data]: e.target.value
                    },
                    [data]: e.target.value
                  })
                  this.setState({confirmTimeErrorMessage:""});
                }
                }

    
    
    else if (data === "user") {
      let index = this.state.users.findIndex(user => user.id === Number(e.target.value));
      if (index > -1) {
        this.setState({
          emailErrorMessage: '',
          modalData: {
            ...this.state.modalData,
            user: this.state.users[index],
            user_id: e.target.value,
            address: this.state.users[index].user_address,
          },
          updatedOrder: {
            ...this.state.updatedOrder,
            user: this.state.users[index],
            user_id: e.target.value,
            address: this.state.users[index].user_address,
          }
        }, () => {
          if (this.state.modalData && this.state.modalData.address) {
            this.state.modalData.address.map((item, i) => {
              let keysInObject = Object.keys(this.state.modalData.address);
              keysInObject.map(val => {
                this.setState({
                  [val]: false
                })
              })
            })
          }
        });
        this.fetchTimeSlotsForUser(this.state.users[index]);
      }
    } else if (data === 'package') {
      let index = this.state.packages.findIndex(user => user.value === e.target.value);
      let packagess = this.state.packages[index]  //.split(' ')[0];
      // let id = e.target.value.split(' ')[1];
      let type = packagess.type;
      this.setState({ packageType: type });
      let id = packagess.id;
      if (type === 'packages') {
        let index = this.state.subscription_package.findIndex(user => user.id === id);
        let coupenList = this.state.allCoupenList.filter((x) => x.packages.indexOf(id) > -1);
        // if (index > -1) {
        this.setState({
          coupenList: coupenList,
          isAppointmentDateLableRequired: true,
          pickupDateErrorMessage: '',
          packageErrorMessage: '',
          modalData: {
            ...this.state.modalData,
            package: this.state.subscription_package[index],
            package_id: type + id,
            package_type: '',
            original_price: this.state.subscription_package[index].price,
            order_price: this.state.subscription_package[index].price,
            subscription_frequency: ''
          },
          updatedOrder: {
            ...this.state.updatedOrder,
            // package: this.state.subscription_package[index],
            custom_package_id: '',
            addon_package_id: '',
            package_id: id,
            package_type: type,
            original_price: this.state.subscription_package[index].price,
            order_price: this.state.subscription_package[index].price
          }
        })
        // }
      } else if (type == 'add-ons') {
        let index = this.state.addOn_packages.findIndex(user => user.id == id);
        let coupenList = this.state.allCoupenList.filter((x) => x.addons.indexOf(id) > -1);
        // if (index > -1) {
        this.setState({
          coupenList: coupenList,
          isAppointmentDateLableRequired: false,
          pickupDateErrorMessage: '',
          packageErrorMessage: '',
          modalData: {
            ...this.state.modalData,
            add_on: this.state.addOn_packages[index],
            package_id: type + id,
            package_type: '',
            original_price: this.state.addOn_packages[index].addon_price,
            order_price: this.state.addOn_packages[index].addon_price,
            subscription_frequency: this.state.addOn_packages[index].addon_validity
          },
          updatedOrder: {
            ...this.state.updatedOrder,
            // add_on: this.state.addOn_packages[index]
            addon_package_id: id,
            custom_package_id: '',
            package_id: '',
            package_type: type,
            original_price: this.state.addOn_packages[index].addon_price,
            order_price: this.state.addOn_packages[index].addon_price
          }
        })
        // }
      } else if (type === 'custom') {
        let index = this.state.customPackages.findIndex(user => user.id == id);
        const selectedPackageData = this.state.customPackages[index];
        let coupenList = this.state.allCoupenList.filter((x) => x.custom_package.indexOf(id) > -1);
        // if (index > -1) {
          this.selectedPackageData = selectedPackageData;
        this.setState({
          coupenList: coupenList,
          isAppointmentDateLableRequired: this.state.customPackages[index].operation_type ? this.state.customPackages[index].operation_type.indexOf('Home Appointment') > -1 ? true : false : false,
          pickupDateErrorMessage: '',
          packageErrorMessage: '',
          selectedPackageData:selectedPackageData,
          modalData: {
            ...this.state.modalData,
            custom_package: this.state.customPackages[index],
            package_id: type + id,
            package_type: 'custom_package',
            original_price: this.state.customPackages[index].final_price,
            order_price: this.state.customPackages[index].final_price,
            subscription_frequency: ''
          },
          updatedOrder: {
            ...this.state.updatedOrder,
            // custom_package: this.state.customPackages[index]
            addon_package_id: '',
            package_id: '',
            custom_package_id: id,
            package_type: type,
            original_price: this.state.customPackages[index].final_price,
            order_price: this.state.customPackages[index].final_price
          }
        })
        // }
      }

      this.fetchTimeSlotsForPackage(type, packagess);
    } else if (data.includes("coupen")) {
      let index = this.state.coupenList.findIndex((x) => x.id == e.target.value);
      if (index > -1) {
        let order_price = this.calculateCoupenPrice(this.state.coupenList[index]);
        this.setState({
          modalData: {
            ...this.state.modalData,
            coupon_amount: this.state.coupenList[index].coupon_amount,
            coupon_code: e.target.value,
            coupen_name: this.state.coupenList[index].coupon_code,
            order_price: order_price
          },
          updatedOrder: {
            ...this.state.updatedOrder,
            coupon_amount: this.state.coupenList[index].coupon_amount,
            coupon_code: e.target.value,
            coupen_name: this.state.coupenList[index].coupon_code,
            order_price: order_price
          }
        })
      }
    } else if (data.includes("label") || data.includes("address_line2") || data.includes("building_apt") || data.includes("address_line1") || data.includes("city")) {
      let dataKey = data;
      const stringLength = dataKey.length;
      let fetchIndex = dataKey.charAt(stringLength - 1);

      let getOnlyTheKey = data.replace(fetchIndex, "");
      let address = [...this.state.modalData.address]
      address[fetchIndex][getOnlyTheKey] = e.target.value;
      this.setState({
        modalData: {
          ...this.state.modalData,
          address: address
        }
      })
    } 
    else if(data==="full_name_id"){
      this.setState({
        modalData:{
          ...this.state.modalData,user:{
            ...this.state.modalData.user,[data]:e.target.value,
          }
        },
        updatedOrder: {
          user: { ...this.state.modalData.user }
        }
      })
    }
    // else if (numberInputs.includes(data)) {
    //   // this.setState({
    //   //   modalData: {
    //   //     ...this.state.modalData,
    //   //     [data]: Number(e.target.value)
    //   //   },
    //   //   updatedOrder: {
    //   //     ...this.state.updatedOrder,
    //   //     [data]: Number(e.target.value)
    //   //   }
    //   // })
    //   // const modalData ={...this.state.modalData};
    //   // modalData[]
    // }
    else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [data]: e.target.value
        },
        updatedOrder: {
          ...this.state.updatedOrder,
          [data]: e.target.value
        }
      })
    }
  }

  
  handleChangeOperations = ({ currentTarget: input }) => {
    const numberInputs = ["custom_test_price_additional", "lab_1_custom_cost", "lab_2_custom_cost", "revised_price", "revised_price_after_coupon", "refund_amount", "coupon_amount", "home_care_cost", "lab_cost"];
    const modalData = { ...this.state.modalData }
    let updatedOrder = { ...this.state.updatedOrder }
    if (numberInputs.includes(input.name)) {
      modalData[input.name] = input.value ? Number(input.value) : null;
      updatedOrder[input.name] = input.value ? Number(input.value) : null;
    }
    else {
      modalData[input.name] = input.value;
      updatedOrder[input.name] = input.value;
    }
    this.setState({ modalData, updatedOrder });
  }

  handleInputSearchEmail = (searchTermEmail) => {
    this.setState({searchTermEmail});
  }

  calculateCoupenPrice(coupen) {
    let order_price = this.state.modalData.original_price;
    let discounted_price = 0;
    if (order_price < coupen.min_amt_spend) {
      this.props.showNotificationMessage({
        notificationMessage: "Minimum Order price should greater than " + coupen.min_amt_spend,
        successMessage: false,
        showNotification: true,
      });
      return false;
    }
    if (coupen.discount_type === 'Fixed') {
      let coupen_price = coupen.coupon_amount.split(' ');
      discounted_price = coupen_price.length ? coupen_price[0] : '';
      if (coupen.max_amt_spend < coupen_price[0]) {
        discounted_price = coupen.max_amt_spend;
        this.props.showNotificationMessage({
          notificationMessage: "Max Discount applied " + coupen.max_amt_spend,
          successMessage: true,
          showNotification: true,
        });
      } else {
        this.props.showNotificationMessage({
          notificationMessage: "Max Discount applied " + coupen_price,
          successMessage: true,
          showNotification: true,
        });
      }
    } else if (coupen.discount_type === 'Percentage') {
      let coupen_price = coupen.coupon_amount.split('%');
      discounted_price = Math.abs((Number(coupen_price[0]) * order_price) / 100).toFixed(2);
      if (coupen.max_amt_spend < discounted_price) {
        discounted_price = coupen.max_amt_spend;
        this.props.showNotificationMessage({
          notificationMessage: "Max Discount applied " + discounted_price,
          successMessage: true,
          showNotification: true,
        });
      } else {
        this.props.showNotificationMessage({
          notificationMessage: "Max Discount applied " + discounted_price,
          successMessage: true,
          showNotification: true,
        });
      }
    }
    return (Number(order_price) - Number(discounted_price));
  }

  optionList(optionArray) {
    return (
      <>
        {optionArray && optionArray.map((status, index) => (
          <option key={index + 1} value={status.value}>
            {status.text}
          </option>
        ))}
      </>
    )
  }

  getOrderType = () => {
    switch (this.state.packageType) {
      case "add-ons": return "subscription";
      case "custom":
      case "packages": return "one-time";
      default: return null;
    }
  }

  validateFullNameId = (full_name_id) => {
    if (full_name_id && full_name_id.length > 100) {
      this.setState({ fullNameIdErrorMessage: "Full Name as per ID can not be more than 100 characters" });
      return false
    }
    else {
      this.setState({ fullNameIdErrorMessage: "" });
      return true;
    }
  }

  validateFrontId = (front_id) => {
    if (front_id && front_id.size) {
      if (front_id.type === "application/pdf" && front_id.size > TEN_MEGA_BYTE) {
        this.setState({ frontIdErrorMessage: "PDF/File should note be more than 10MB" });
        return false;
      } else if (front_id.size > TWO_MEGA_BYTE) {
        this.setState({ frontIdErrorMessage: "Image/File should not be more than 2MB" });
        return false;
      }
    }
    this.setState({ frontIdErrorMessage: "" })
    return true;
  }

  validateBackId = (back_id) => {
    if (back_id && back_id.size) {
      if (back_id.type === "application/pdf" && back_id.size > TEN_MEGA_BYTE) {
        this.setState({ backIdErrorMessage: "PDF/File should note be more than 10MB" });
        return false;
      } else if (back_id.size > TWO_MEGA_BYTE) {
        this.setState({ backIdErrorMessage: "Image/File should not be more than 2MB" });
        return false;
      }
    }
    this.setState({ backIdErrorMessage: "" })
    return true;
  }

  validateCosts = () => {
    const { lab_cost, home_care_cost } = this.state.updatedOrder ?? {};
    let areCostsValid = true;
    let labCostsErrorMessage;
    let homecareCostsErrorMessage;
    if (lab_cost && Number.isNaN(Number(lab_cost))) {
      labCostsErrorMessage = "Lab Costs should be a valid number";
      areCostsValid = false;
    } else if (lab_cost && Number(lab_cost) > 1000000) {
      labCostsErrorMessage = "Lab Costs can not be greater then 1000000";
      areCostsValid = false;
    }

    if (home_care_cost && Number.isNaN(Number(home_care_cost))) {
      homecareCostsErrorMessage = "Homecare Costs should be a valid number";
      areCostsValid = false;
    } else if (home_care_cost && Number(home_care_cost) > 1000000) {
      homecareCostsErrorMessage = "Homecare Costs can not be greater then 1000000";
      areCostsValid = false;
    }

    this.setState({ labCostsErrorMessage: labCostsErrorMessage ?? null, homecareCostsErrorMessage: homecareCostsErrorMessage ?? null });
    return areCostsValid;
  }


  validateOperationsInternal = () => {
    const {
      custom_test_price_additional,
      lab_1_custom_cost,
      home_care_cost,
      reference_number_1,
      lab_2_custom_cost,
      reference_number_2,
      revised_price,
      coupon_amount,
      revised_price_after_coupon,
      refund_amount,
      custom_test_biomarkers,
      notes, 
      coupon_code,
      coupon_code_value,
      sample_details_lab1,
      sample_details_lab2,
      special_instructions,
      payment_to_be_collected,
    } = this.state.updatedOrder ?? {};
    const{modalData}=this.state;

    let isAllValid = true;
    let customTestPriceAddErrorMessage;
    let lab1CustomCostErrorMessage;
    let homeCareCostErrorMessage;
    let referenceNumber1ErrorMessage;
    let lab2CustomCostErrorMessage;
    let referenceNumber2ErrorMessage;
    let revisedPriceErrorMessage;
    let couponAmountErrorMessage;
    let revisedPriceCouponErrorMessage;
    let refundAmountErrorMessage;
    let customTestBiomarkerErrorMessage;
    let notesErrorMessage;
    let couponCodeValueErrorMessage;
    let sampleDetailsLab1ErrorMessage = null;
    let sampleDetailsLab2ErrorMessage = null;
    let specialInstructionsErrorMessage = null;
    let paymentToBeCollectedErrorMessage = null;
  
    if (custom_test_price_additional && Number.isNaN(Number(custom_test_price_additional))) {
      customTestPriceAddErrorMessage = "Custom Test Price Additional should be a valid number";
      isAllValid = false;
    } else if (custom_test_price_additional && Number(custom_test_price_additional) >9999) {
      customTestPriceAddErrorMessage = "Custom Test Price Additional can not be greater than 9999";
      isAllValid = false;
    }

    if (lab_1_custom_cost && Number.isNaN(Number(lab_1_custom_cost))) {
      lab1CustomCostErrorMessage = "Lab 1 Custom Cost should be a valid number";
      isAllValid = false;
    } else if (lab_1_custom_cost && Number(lab_1_custom_cost) >9999) {
      lab1CustomCostErrorMessage = "Lab 1 Custom Cost cannot be greater than 9999";
      isAllValid = false;
    }

    if (home_care_cost && Number.isNaN(Number(home_care_cost))) {
      homeCareCostErrorMessage = "Home Care Cost should be a valid number";
      isAllValid = false;
    } else if (home_care_cost && Number(home_care_cost) >9999) {
      homeCareCostErrorMessage = "Home Care Cost cannot be greater than 9999";
      isAllValid = false;
    }

    if(reference_number_1 && reference_number_1.length> 500){
      referenceNumber1ErrorMessage = "Reference Number 1 is limited to a 500 characters";
      isAllValid=false;
    }
    
    if (lab_2_custom_cost && Number.isNaN(Number(lab_2_custom_cost))) {
      lab2CustomCostErrorMessage = "Lab 2 Custom Cost should be a valid number";
      isAllValid = false;
    } else if (lab_2_custom_cost && Number(lab_2_custom_cost) >9999) {
      lab2CustomCostErrorMessage = "Lab 2 Custom Cost cannot be greater than 9999";
      isAllValid = false;
    }

    if(reference_number_2 && reference_number_2.length> 500){
      referenceNumber2ErrorMessage = "Reference Number 2 is limited to a 500 characters";
      isAllValid=false;
    }
    

    if (revised_price && Number.isNaN(Number(revised_price))) {
      revisedPriceErrorMessage = "Revised Price should be a valid number";
      isAllValid = false;
    } else if (revised_price && Number(revised_price) >9999) {
      revisedPriceErrorMessage= "Revised Price Cost cannot be greater than 9999";
      isAllValid = false;
    }


    if (coupon_amount && Number.isNaN(Number(coupon_amount))) {
      couponAmountErrorMessage = "Coupon Amount should be a valid number";
      isAllValid = false;
    } else if (coupon_amount && Number(coupon_amount) >9999) {
      couponAmountErrorMessage = "Coupon Amount cannot be greater than 9999";
      isAllValid = false;
    }
    
    if (revised_price_after_coupon && Number.isNaN(Number(revised_price_after_coupon))) {
      revisedPriceCouponErrorMessage = "Revised Price After Coupon should be a valid number";
      isAllValid = false;
    } else if (revised_price_after_coupon && Number(revised_price_after_coupon) >9999) {
      revisedPriceCouponErrorMessage = "Revised Price After Coupon cannot be greater than 9999";
      isAllValid = false;
    }

    if (refund_amount && Number.isNaN(Number(refund_amount))) {
      refundAmountErrorMessage = "Refund Amount should be a valid number";
      isAllValid = false;
    } else if (refund_amount && Number(refund_amount) >9999) {
      refundAmountErrorMessage = "Refund Amount cannot be greater than 9999";
      isAllValid = false;
    }

    if(custom_test_biomarkers && custom_test_biomarkers.length> 3000){
      customTestBiomarkerErrorMessage = "Custom Test Biomarkers is limited to a 500 characters";
      isAllValid=false;
    }
    

    if(notes && notes.length> 3000){
      notesErrorMessage= "notes is limited to a 500 characters";
      isAllValid=false;
    }

    if(coupon_code_value && coupon_code_value.length > 100){
      couponCodeValueErrorMessage="Coupon Code is limited to a 100 characters";
      isAllValid=false;
    }
    if(modalData.order_item_type !=="Supplement" && modalData.order_item_type !=="Bundle"){
    if (!sample_details_lab1 || sample_details_lab1.trim() === "")
      sampleDetailsLab1ErrorMessage = "Sample Details Lab 1 cannot be blank!";
    }
    else if (sample_details_lab1.length > 500)
      sampleDetailsLab1ErrorMessage = "Sample Details Lab 1 is limited to a 500 characters";

    if (sample_details_lab2 && sample_details_lab2.length > 500)
      sampleDetailsLab2ErrorMessage = "Sample Details Lab 2 is limited to a 500 characters";

    if (special_instructions && special_instructions.length > 500)
      specialInstructionsErrorMessage = "Special Instructions is limited to a 500 characters";

    if (payment_to_be_collected && payment_to_be_collected.length > 500)
      paymentToBeCollectedErrorMessage = "Payment to be collected is limited to a 500 characters";
    
    this.setState({ 
      customTestPriceAddErrorMessage: customTestPriceAddErrorMessage ?? null,
      lab1CustomCostErrorMessage:lab1CustomCostErrorMessage ?? null,
      homeCareCostErrorMessage: homeCareCostErrorMessage?? null,
      referenceNumber1ErrorMessage: referenceNumber1ErrorMessage ?? null,
      lab2CustomCostErrorMessage:lab2CustomCostErrorMessage?? null,
      referenceNumber2ErrorMessage:referenceNumber2ErrorMessage?? null,
      revisedPriceErrorMessage:revisedPriceErrorMessage?? null,
      couponAmountErrorMessage:couponAmountErrorMessage??null,
      revisedPriceCouponErrorMessage:revisedPriceCouponErrorMessage??null,
      refundAmountErrorMessage:refundAmountErrorMessage??null,
      customTestBiomarkerErrorMessage:customTestBiomarkerErrorMessage??null,
      notesErrorMessage:notesErrorMessage??null,
      couponCodeValueErrorMessage:couponCodeValueErrorMessage?? null,
      sampleDetailsLab1ErrorMessage,
      sampleDetailsLab2ErrorMessage,
      specialInstructionsErrorMessage,
      paymentToBeCollectedErrorMessage,
    })
    return isAllValid;
  }

  saveUserData = () => {
    const { selectedCountry, modalData, newUser, updatedOrder, isAppointmentDateLableRequired } = this.state;
    console.log("ActiveOrders:: saveUserData: selectedCountry:", selectedCountry);

    if (modalData.user_id === '') {
      this.setState({
        emailErrorMessage: "Email is Required"
      }) 
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields",
        successMessage: false,
        showNotification: true,
      });
      return false
    }
    if (!this.isHairLossJourneyItem(modalData.order_item_type) && modalData.package_id === '') {
      this.setState({
        packageErrorMessage: "Package is Required"
      })
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid fields",
        successMessage: false,
        showNotification: true,
      });
      return false;
    }

    if (isAppointmentDateLableRequired) {
      const pickupDate = (this.state.updatedOrder && this.state.updatedOrder.pickupDate) || this.state.pickupDate;
      const pickupTime = (this.state.updatedOrder && this.state.updatedOrder.pickupTime) || this.state.pickupTime;
      console.log(pickupDate, pickupTime);
      if (!pickupDate || !pickupTime) {
        this.setState({ pickupDateErrorMessage: 'Please select both appointment date and appointment time' })
        this.props.showNotificationMessage({
          notificationMessage: "There are some invalid fields",
          successMessage: false,
          showNotification: true,
        });
        return false;
      }
      if (!modalData.address || modalData.address.length === 0) {
        this.props.showNotificationMessage({
          notificationMessage: "For any blood tests or at home appointment based test, address field is required. Please update the user’s address under their profile.",
          successMessage: false,
          showNotification: true,
        });
        return false;
      }
    }

    // if (!this.validateCosts()) return;
    
    if(!this.validateOperationsInternal()) return;
    

    if (newUser) {
      if (this.state.updatedOrder.confirmDate || this.state.updatedOrder.confirmTime) {
        let confirmDate = this.state.updatedOrder.confirmDate ? this.state.updatedOrder.confirmDate : this.state.confirmDate;
        let confirmTime = this.state.updatedOrder.confirmTime ? this.state.updatedOrder.confirmTime : this.state.confirmTime;
        if (!confirmDate || !confirmTime) {
          this.setState({ confirmDateErrorMessage: 'Please add date and time both' });
          this.props.showNotificationMessage({
            notificationMessage: "There are some invalid fields",
            successMessage: false,
            showNotification: true,
          });
          return false;
        }
        let confirmed_date_time = confirmDate + ' ' + confirmTime;
        this.setState({
          updatedOrder: {
            ...this.state.updatedOrder,
            confirmed_date_time: confirmed_date_time
          }
        })
      } else if (this.state.updatedOrder.pickupDate || this.state.updatedOrder.pickupTime) {
        if (!this.state.updatedOrder.pickupDate || !this.state.updatedOrder.pickupTime) {
          this.setState({ pickupDateErrorMessage: 'Please add date and time both' })
          this.props.showNotificationMessage({
            notificationMessage: "There are some invalid fields",
            successMessage: false,
            showNotification: true,
          });
          return false;
        }
        const newPickupTime = this.state.updatedOrder.pickupTime.replace(" to ", "-");
        this.setState({
          updatedOrder: {
            ...this.state.updatedOrder,
            specimen_collection_date: this.state.updatedOrder.pickupDate,
            specimen_collection_timeslot: newPickupTime
          }
        })
      }

      // According to SB-662:
      //   for blood work packages and custom packages "order_type":"one-time"
      //   for health coach packages "order_type":"subscription"
      //   for all packages, “frequency” : null
      let payload = {
        "package": updatedOrder.package_id,
        "add_on": updatedOrder.addon_package_id,
        "custom_package": updatedOrder.custom_package_id,
        "address": updatedOrder.address.length > 0 ? updatedOrder.address[0].id : null,
        "user": updatedOrder.user_id,
        // "specimen_collection_date": updatedOrder.pickupDate,
        // "specimen_collection_timeslot": updatedOrder.pickupTime ? updatedOrder.pickupTime.replace(" to ", "-") : '',
        "booked_slot_date": updatedOrder.pickupDate,
        // "booked_slot_time": updatedOrder.pickupTime ? updatedOrder.pickupTime.replace(" to ", "-") : '',
        "booked_slot_time": updatedOrder.pickupTime ? this.getFormattedBookTimeslot(updatedOrder.pickupTime) : '',
        "comments": "",
        "order_notes": updatedOrder.order_notes,
        // "frequency": 6,
        // "order_type": "subscription",
        "frequency": null,
        "order_type":  this.getOrderType(),
        "coupon_code": updatedOrder.coupen_name,
        "client_note": modalData.client_note,
        "order_source": ORDER_SOURCE,
        "country_id": selectedCountry?.id ?? null,
        "payment_gateway": updatedOrder.payment_gateway ?? null,
        "lab_cost": Number(updatedOrder.lab_cost) ?? null,
        "home_care_cost": Number(updatedOrder.home_care_cost) ?? null,
        "is_homecare_cost_manually_updated":Number(updatedOrder.home_care_cost)?true:false,
        "home_care_id": updatedOrder.home_care_id ?? null,
      }

      // This is commented because in case of new order creation we are not sending confirmed date and time.
      // if (updatedOrder.confirmDate && updatedOrder.confirmDate !== "dd/mm/yyyy")
      //   payload["booked_slot_date"] = updatedOrder.confirmDate;

      // if (updatedOrder.confirmTime && updatedOrder.confirmTime !== "--:--")
      //   payload["booked_slot_time"] = updatedOrder.confirmTime;
      
      API.post("create-order-admin/", payload)
        .then((response) => {
          console.log(response);
          this.hideModal();
          this.loadData();
          this.props.showNotificationMessage({
            notificationMessage: "Order Details Created successfully.",
            successMessage: true,
            showNotification: true,
          });
          // this.loadData();
        })
        .catch((error) => {
          console.log("Error on creating order details", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        }).finally(() => this.setState({ isSaving: false }));
    } else {
      const {updatedOrder} = this.state;
      if (updatedOrder && updatedOrder.order_status) {
        if (!this.checkOrderStatus(this.state.selectedOrder, updatedOrder.order_status)) {
          return;
        }
      }
      updatedOrder.client_note = modalData.client_note;
      if (updatedOrder.pickupTime) {
        updatedOrder.pickupTime = this.getFormattedBookTimeslot(updatedOrder.pickupTime);
      }
      if (updatedOrder.confirmDate || updatedOrder.confirmTime) {
        let confirmDate = (updatedOrder && updatedOrder.confirmDate) || this.state.confirmDate;
        let confirmTime = (updatedOrder && updatedOrder.confirmTime) || this.state.confirmTime;
        if (
          !confirmDate || confirmDate === "dd/mm/yyyy" ||
          !confirmTime || confirmTime === "--:--"
        ) {
          this.setState({ confirmDateErrorMessage: 'Please select both confirm date and confirm time' });
          this.props.showNotificationMessage({
            notificationMessage: "There are some invalid fields",
            successMessage: false,
            showNotification: true,
          });
          return false;
        }

        let confirmed_date_time = confirmDate + ' ' + confirmTime;
        this.setState({
          updatedOrder: {
            ...updatedOrder,
            confirmed_date_time: confirmed_date_time
          }
        }, () => {
          // Delete appoitment date and time values from payload, during update.
          const payload = {...this.state.updatedOrder};
          if (payload.client_note === null || payload.client_note === undefined) {
            delete payload.client_note;
          }
          delete payload.pickupDate;
          delete payload.pickupTime;
          delete payload.booked_slot_date;
          delete payload.booked_slot_time;
          const userId=getUserId();
          payload.modified_by=userId;
          API.patch("order-list/" + this.state.modalData.id + "/", payload)
            .then((response) => {
              console.log(response);
              this.hideModal();
              this.loadData();
              this.props.showNotificationMessage({
                notificationMessage: "Order Details updated successfully.",
                successMessage: true,
                showNotification: true,
              });
              this.loadData();
            })
            .catch((error) => {
              console.log("Error on updating order details", error);
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage: "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            }).finally(() => this.setState({ isSaving: false }));
        })
      } else if (this.state.updatedOrder.pickupDate || this.state.updatedOrder.pickupTime) {
        const pickupDate = this.state.updatedOrder.pickupDate || this.state.pickupDate;
        const pickupTime = this.state.updatedOrder.pickupTime || this.state.pickupTime;
        console.log("Pickup Date:", pickupDate);
        console.log("Pickup Time:", pickupTime);

        if (!pickupDate || !pickupTime) {
          // this.props.showNotificationMessage({
          //   notificationMessage: "Please add date and time both.",
          //   successMessage: false,
          //   showNotification: true,
          // });
          this.setState({ pickupDateErrorMessage: 'Please add date and time both' })
          this.props.showNotificationMessage({
            notificationMessage: "There are some invalid fields",
            successMessage: false,
            showNotification: true,
          });
          return false;
        }
        const newPickupTime = pickupTime.replace(" to ", "-");
        this.setState({
          updatedOrder: {
            ...this.state.updatedOrder,
            booked_slot_date: pickupDate,
            booked_slot_time: newPickupTime,
            // specimen_collection_date: this.state.updatedOrder.pickupDate,
            // specimen_collection_timeslot: newPickupTime
          }
        }, () => {
          // Delete appoitment date and time values from payload, during update.
          const payload = {...this.state.updatedOrder};
          if (payload.client_note === null || payload.client_note === undefined) {
            delete payload.client_note;
          }
          delete payload.pickupDate;
          delete payload.pickupTime;
          delete payload.booked_slot_date;
          delete payload.booked_slot_time;
          const userId=getUserId();
          payload.modified_by=userId;
          API.patch("order-list/" + this.state.modalData.id + "/", payload)
            .then((response) => {
              console.log(response);
              this.hideModal();
              this.loadData();
              this.props.showNotificationMessage({
                notificationMessage: "Order Details updated successfully.",
                successMessage: true,
                showNotification: true,
              });
              // this.loadData();
            })
            .catch((error) => {
              console.log("Error on updating order details", error);
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage: "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            }).finally(() => this.setState({ isSaving: false }));
        })
      }
      else {
        if (updatedOrder && updatedOrder.user) {
          let user = updatedOrder && updatedOrder.user;
          const allValidated = this.validateFullNameId(user.full_name_id) &
            this.validateFrontId(user.front_id) &
            this.validateBackId(user.back_id);
          if (!allValidated) {
            this.showErrorNotification("There are some Invalid form fields")
            return false;
          }

          if (allValidated) {
            this.updateUserInfo()
          }
        }
        if (updatedOrder && updatedOrder.user) {
          delete updatedOrder.user
          this.setState({ updatedOrder })
        }
        // Delete appoitment date and time values from payload, during update.
        const payload = {...this.state.updatedOrder};
        if (payload.client_note === null || payload.client_note === undefined) {
          delete payload.client_note;
        }
        delete payload.pickupDate;
        delete payload.pickupTime;
        delete payload.booked_slot_date;
        delete payload.booked_slot_time;
        const userId=getUserId();
        payload.modified_by=userId;
        API.patch("order-list/" + this.state.modalData.id + "/", payload)
          .then((response) => {
            console.log(response);
            this.hideModal();
            this.loadData();
            this.props.showNotificationMessage({
              notificationMessage: "Order Details updated successfully.",
              successMessage: true,
              showNotification: true,
            });
            // this.loadData();
            if (updatedOrder && updatedOrder.order_status) {
              this.handleOrderStatusLogs(updatedOrder.order_status, this.state.selectedOrder)
            }
          })
          .catch((error) => {
            console.log("Error on updating order details", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          }).finally(() => this.setState({ isSaving: false }));
      }
    }

  }

  updateUserInfo = async () => {
    const { modalData } = this.state;
    let user = modalData && modalData.user;
    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "patch", `list-active-user/${user.id}/`, formData
    )
    try {
      const response = await API.request(apiOptions);
      // this.loadData()
    }
    catch (error) {
      console.log("error on updating user", error.message)
      this.showErrorNotification("Error on updating user")
    }
  }

  createFormData = () => {
    const { modalData } = this.state;
    let user = modalData && modalData.user;
    const formData = new FormData();
    formData.append("first_name", user.first_name);
    formData.append("last_name", user.last_name);
    formData.append("email", user.email);
    if (user && user.full_name_id) {
      formData.append("full_name_id", user.full_name_id)
    }
    if (user && user.front_id) {
      if (user && typeof user.front_id !== "string") {
        formData.append("front_id", user.front_id);
        formData.append("front_id_s3", user.front_id);
      }
    }
    if (user && user.back_id) {
      if (user && typeof user.back_id !== "string") {
        formData.append("back_id", user.back_id);
        formData.append("back_id_s3", user.back_id);
      }
    }
    return formData;
  }

  createApiOptions = (method, url, formData) => ({
    method,
    url,
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });
  
  /*
  Not needed now, now we are using handleSort method.
  customSort(column, sortDirection) {
    let sortField = column.sortField;
    this.setState({ sortField: sortField, sortDirection: sortDirection }, () => {
      this.loadOrders();
    })
    // return orderBy(rows, selector, direction);
  };
  */

  assignPackage(value) {

  }

  createNewOrder = () => {
    this.setState({ showModal: true, newUser: true, updatedOrder: {}  }, () => {
      this.showModal({});
    });
  };

  createCountryOption = (country) => ({
    key: country.id,
    value: country.id,
    label: country.country_name,
  });

  updatePackagesListForSelectedCountry = () => {
      const {
      customPackages = [],
      addOn_packages = [],
      subscription_package = [],
      selectedCountry,
    } = this.state;

    const countryFilter = (p) => (p && selectedCountry) ? p.country === selectedCountry.id : false;

    const custom_packages = customPackages
      .filter(it => it.status === "Active")
      .filter(countryFilter)
      .map(it => ({
        "label": it.name,
        "value": 'custom' + it.id,
        "id": it.id,
        'type': 'custom'
      }));

    const add_on_packages = addOn_packages
      .filter(it => it.is_active)
      .filter(countryFilter)
      .map(it => ({
        "label": it.addon_name,
        "value": 'add-ons' + it.id,
        "id": it.id,
        "type": 'add-ons'
      }));

    const subscription_packages = subscription_package
      .filter(it => it.is_active)
      .filter(countryFilter)
      .map(it => ({
        // "label": it.subscription_name,
        "label": it.internal_name,
        "value": 'packages' + it.id,
        "id": it.id,
        'type': 'packages'
      }));

    this.setState({ custom_packages, add_on_packages, subscription_packages });
  }

  // Will create the coach list for dropdown and if for an existing order
  // the assigned coach is in inactive state, then we will add that also to the list.
  // So we can see the coach details on dropdown.
  createCoachOptions = () => {
    const {newUser, nutritionistListFormatted = [], modalData} = this.state;
    if (newUser) return;

    const coachList = [...nutritionistListFormatted];
    if (modalData.nutritionist && modalData.nutritionist.id) {
      if (!coachList.find(it => it.key === modalData.nutritionist.id))
        coachList.push({
          text: modalData.nutritionist.username + " (" + modalData.nutritionist.email + ")",
          value: modalData.nutritionist.id,
          key: modalData.nutritionist.id,
        });
    }
    return coachList.map((it, index) => <option key={index} value={it.value}>{it.text}</option>);
  }

  handlePageChange = (page) => {
    const {filterItemType=""}=this.state;
    if(filterItemType && filterItemType !== "All Orders"){
      this.fetchActiveOrders(page, filterItemType)
    }
    else{
      this.fetchActiveOrders(page)
    }
  };

  handleRowsPerPageChange = (newRowsPerPage, page) => {
    const { filterItemType = "" } = this.state;
    this.setState({ rowsPerPage: newRowsPerPage }, () => {
      if (filterItemType && filterItemType !== "All Orders") {
        this.fetchActiveOrders(page, filterItemType)
      }
      else {
        this.fetchActiveOrders(page)
      }
    }
    );
  }

  handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    const { filterItemType = "" } = this.state;
    this.setState(
      { sortField: column.sortField || column.selector, sortDirection: sortDirection },
      () => {
        if (filterItemType && filterItemType !== "All Orders") {
          this.fetchActiveOrders(1, filterItemType)
        }
        else {
          this.fetchActiveOrders()
        }
      }
    );
  };

  fetchActiveOrders = (page = 1, itemType = null) => {
    const { 
      rowsPerPage = DEFAULT_ROWS_PER_PAGE, 
      sortField, 
      sortDirection,
      searchApplied,
      searchTerm,
      userType
    } = this.state;

    const baseUrl = this.getBaseUrlFor(userType);
    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    console.log("Sf sofe", sf)
    let url = `${baseUrl}?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;
    if (searchApplied) {
      url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
    }
    if (itemType) {
      url += `&item_type=${itemType}`
    }    
    this.showLoading();
    if(userType==="admin"){
      PhoenixAPI.get(url)
        .then((response) => {
          if (response.data) {
            const { count: totalRows = 0, results: orders = [] } = response.data;
            const filteredOrders = this.getFilteredOrdersFor(orders, userType);
            filteredOrders.forEach(this.processOrder);
            this.setState({ totalRows, orders: filteredOrders, isLoaded: true });
          }
        })
        .catch((error) => {
          console.log("Error on fetching data", error);
          this.setState({ loadError: "Some error has occured. Please try again" });
          this.showErrorNotification("Some error has occured. Please try again.");
        })
        .finally(() => this.hideLoading())
    }
     else{
      API.get(url)
        .then((response) => {
          if (response.data) {
            const { count: totalRows = 0, results: orders = [] } = response.data;
            const filteredOrders = this.getFilteredOrdersFor(orders, userType);
            filteredOrders.forEach(this.processOrder);
            this.setState({ totalRows, orders: filteredOrders, isLoaded: true });
          }
        })
        .catch((error) => {
          console.log("Error on fetching data", error);
          this.setState({ loadError: "Some error has occured. Please try again" });
          this.showErrorNotification("Some error has occured. Please try again.");
        })
        .finally(() => this.hideLoading())
    }
    }
  //   PhoenixAPI.get(url)
  //   .then((response) => {
  //     if (response.data) {
  //       const { count: totalRows = 0, results: orders = [] } = response.data;
  //       const filteredOrders = this.getFilteredOrdersFor(orders, userType);
  //       filteredOrders.forEach(this.processOrder);
  //       this.setState({ totalRows, orders: filteredOrders, isLoaded: true });
  //     }
  //   })
  //   .catch((error) => {
  //     console.log("Error on fetching data", error);
  //     this.setState({ loadError: "Some error has occured. Please try again" });
  //     this.showErrorNotification("Some error has occured. Please try again.");
  //   })
  //   .finally(() => this.hideLoading());
  // };
  
   
  getBaseUrlFor = (userType) => {
    if (userType === "nutritionist") return "order-nutritionist/";
    if (userType === "lab") return "order-lab/";
    return "api/v2/order-list";
  }

  getFilteredOrdersFor = (orders = [], userType) => {
    // Because of the pagination API, client side filtering was giving some problems so we are filtering data from backend.
    // if (userType === "nutritionist") {
    //   const nutritionistId = getUserId();
    //   const isOrderForNutrionist = (order) => Number(order.nutritionist.id) === Number(nutritionistId);
    //   const isBloodBiomarkerPackage = (order) => order.package ?? false;
    //   const isHealthCoachPackage = (order) => order.add_on ?? false;
    //   const isCustomPackage = (order) => order.custom_package && order.custom_package.operation_type.includes("Requires Coach Note");
      
    //   const isCorrectPackage = (order) => isBloodBiomarkerPackage(order) || isHealthCoachPackage(order) || isCustomPackage(order);
    //   console.log("Total orders: ", orders.length);
    //   const filteredOrders = orders.filter(it => isOrderForNutrionist(it) && isCorrectPackage(it));
    //   console.log("Orders for nutritionist: ", filteredOrders.length);
    //   return filteredOrders;
    //   // return orders.filter((order) => ((order.package_type && (order.package_type.indexOf("Home Appointment") > -1 || order.package_type.indexOf("Requires Coach Note") > -1) || (order.order_status !== "SAMPLE RECEIVED" && order.order_status !== "LAB CONFIRMED" && order.order_status != "ORDER CREATED" && order.order_status !== "CREATED")) || (!order.package_type && order.order_status !== "UPCOMING")));
    // }

    // This filtering should also be done from backend.
    if (userType === "lab") {
      return orders.filter(
        (order) => (!order.package_type || (order.package_type && (order.package_type.indexOf("Home Appointment") > -1 || order.package_type.indexOf("PDF Download") > - 1) && (order.order_status !== "COACH REVIEWED" && order.order_status !== "COMPLETED")))
      );
    }
    return orders;
  }

  processOrder = (order = {}) => {
    if (order.booked_slot_time) {
      const time = order.booked_slot_time.split("-");
      const startTime = moment(time[0], "hh:mm A").format("hh:mm A");
      const endTime = moment(time[1], "hh:mm A").format("hh:mm A");
      order.booked_slot_time = startTime + " to " + endTime;
    } else if (order.specimen_collection_timeslot) {
      const time = order.specimen_collection_timeslot.split("-");
      const startTime = moment(time[0], "hh:mm A").format("hh:mm A");
      const endTime = moment(time[1], "hh:mm A").format("hh:mm A");
      order.specimen_collection_timeslot = startTime + " to " + endTime;
    }

    if (order.add_on && order.add_on.id && order.package)
      order.contents = "PA";
    else if (order.addonsubscription && order.addonsubscription.id && !order.package)
      order.contents = "A";
    else
      order.contents = "P";
  }

  generateLabInformation = (modalData) => {
    const user = modalData?.user || {};
    const userProfile = user?.userprofile || {};
    let clientName, clientGender, dateOfBirth, mobile;
    if (modalData.dependent_id) {
      const { dependent_id, member } = modalData;
      if (!member) {
        console.log("generateLabInformation:: Invalid member details!", { dependent_id, member });
      }

      const { first_name, last_name, gender, dob, mob } = member ?? {};
      const fullName = `${first_name ?? ""} ${last_name ?? ""}`.trim();
      clientName = `Client Name: ${fullName ?? ""}`;
      clientGender = `Gender: ${gender ?? ""}`;
      dateOfBirth = `Date of Birth: ${dob ? formatDate(dob) : ""}`;
      mobile = `Mobile: ${mob ?? ""}`;
    } else {
      clientName = "Client Name: " + user.full_name || `${user.first_name} ${user.last_name}` || "";
      clientGender = "Gender: " + userProfile.gender || "";
      dateOfBirth = "Date of Birth: " + (userProfile.dob ? formatDate(userProfile.dob) : "");
      mobile = "Mobile: " + user.phone_no || userProfile.phone_number || "";
    }
   console.log("package internal name",modalData?.internal_name,modalData);
    const email = this.isPCR(modalData) ? ("Email: " + user.email || "") : null;

    const packageName = "Package: " + (modalData?.internal_name? modalData?.internal_name :modalData?.order_package|| "");
    const address = "Address: " + this.getAddress(modalData);
    const dateTime = "Date & Time: " + 
      (this.state.pickupDate ? formatDate(this.state.pickupDate) + " & " : "") +
      (this.state.pickupTime ? this.state.pickupTime : "");
    return [clientName, clientGender, dateOfBirth, mobile, email, packageName, address, dateTime].filter(it => it !== null).join("\n");
  }

  isPCR = (modalData) => {
    return modalData?.custom_package?.name?.includes("PCR");
  }

  getAddress = (modalData) => {
    const address = modalData.address || modalData?.user?.user_address?.filter(it => it.default_address);
    if (address) {
      const { label, address_line2: streetName, building_apt, address_line1: areaDistrict, city, country, map_url, lat, lng } = address;
      const mapUrl = map_url ? `Map URL: ${map_url}` : null;
      const location = lat || lng ? `Location: Lat: ${lat ?? ""}, Lng: ${lng}` : null;
      const addressParts = [label, streetName, building_apt, areaDistrict, city, country];
      let addressString = addressParts.filter(it => it).join(", ");
      if (mapUrl) addressString = addressString + "\r\n" + mapUrl;
      if (location) addressString = addressString + "\r\n" + location;
      return addressString;
    }
    return ""; 
  }

  handleSelectFrontImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      this.setState({
        modalData:{
          ...this.state.modalData,
          user:{
            ...this.state.modalData.user,front_id:image
          }
        },
        updatedOrder: {
          user: { ...this.state.modalData.user, front_id: image }
        }
      })
      const reader = new FileReader();
      reader.onloadend = function () {
        this.setState({ frontIdSrc: reader.result });
      }.bind(this);

      if (image?.type?.startsWith("image/")) {
        reader.readAsDataURL(image);
      } else if (image?.type === "application/pdf") {
        reader.readAsArrayBuffer(image);
      }
    }
  };

  handleSelectBackImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      this.setState({
        modalData:{
          ...this.state.modalData,
          user:{
            ...this.state.modalData.user,back_id:image
          }
        },
        updatedOrder: {
          user: { ...this.state.modalData.user, back_id: image }
        }
      })
      const reader = new FileReader();
      reader.onloadend = function () {
        this.setState({ backIdSrc: reader.result });
      }.bind(this);

      if (image.type.startsWith("image/")) {
        reader.readAsDataURL(image);
      } else if (image.type === "application/pdf") {
        reader.readAsArrayBuffer(image);
      }
    }
  };

  showOperatorAssignModal = () => {
    this.setState({
      showOperatorAssignModal: true,
    })
  }

  hideOperatorAssignModal = () => {
    this.setState({ showOperatorAssignModal: false })
  }

  handleItemType = (selectedItemTypeId) => {

    const selectedItemType = ITEM_TYPE_OPTIONS.filter((it) => it.id === selectedItemTypeId).map((it) => it.value)
    const itemType = selectedItemType[0];

    this.setState({filterItemType:itemType})
    if (itemType && itemType !== "All Orders") {
      this.fetchActiveOrders(1, itemType)
    }
    else {
      this.setState({filterItemType:""})
      this.fetchActiveOrders()
    }
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) => 
    this.state.isEditable && !this.state.newUser ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  renderFrontIdPreview = () => {
    const { frontIdSrc: fileSource, modalData } = this.state;
    if (!fileSource) return null;

    const file = modalData?.user?.front_id;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <div>
          <img
            id="selected_front_image"
            name="selected_front_image"
            src={fileSource}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
      )

    return (
      <>
        <label htmlFor="selected_front_image">ID - Front Preview</label>
        {preview}
      </>
    )
  }

  renderBackIdPreview = () => {
    const { backIdSrc: fileSource, modalData } = this.state;
    if (!fileSource) return null;

    const file = modalData?.user?.back_id;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <div>
          <img
            id="selected_back_image"
            name="selected_back_image"
            src={fileSource}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
      )

    return (
      <>
        <label htmlFor="selected_back_image">ID - Back Preview</label>
        {preview}
      </>
    )
  }

  extractOrderIdFromRow = (row) => {
    try {
      return Number(row.id.split("-")[1]);
    } catch (error) {
      console.log("extractOrderIdFromRow:: Error on extracting orderId from row!", {row});
      return null;
    }
  }

  isTableRowColorSchemeApplicable = (order) => {
    const { order_status, booked_slot_date, specimen_collection_date, booked_slot_time, specimen_collection_timeslot } = order ?? {};
    const isValidStatus = "completed" !== order_status?.toLowerCase() && "cancelled" !== order_status?.toLowerCase();
    const isValidAppointmentDetails = (booked_slot_date || specimen_collection_date) && (booked_slot_time || specimen_collection_timeslot);
    const result = Boolean(isValidStatus && isValidAppointmentDetails);
    console.log("isTableRowColorSchemeApplicable:: Result", result);
    return result;
  }

  getTableRowColorScheme = (row) => {
    const orderId = this.extractOrderIdFromRow(row);
    if (!orderId) {
      console.log("getTableRowColorScheme:: Invalid orderId!", {orderId});
      return {
        backgroundColor: "inherit",
        "&:hover": {
          backgroundColor: "inherit",
        },
      };
    }

    const order = this.state.orders?.find(it => it.id === orderId);
    const { id, booked_slot_date, specimen_collection_date, booked_slot_time, specimen_collection_timeslot } = order ?? {};
    const appointmentDate = booked_slot_date
      ? booked_slot_date
      : specimen_collection_date
        ? specimen_collection_date
        : '';

    const appointmentTimeslot = booked_slot_time
      ? booked_slot_time
      : specimen_collection_timeslot
        ? specimen_collection_timeslot
        : '';

    console.log("getTableRowColorScheme::", {orderId, appointmentDate, appointmentTimeslot});
    if (!appointmentDate || !appointmentTimeslot) {
      console.log("getTableRowColorScheme:: Invalid appointment", { id, appointmentDate, appointmentTimeslot });
      return {
        backgroundColor: "inherit",
        "&:hover": {
          backgroundColor: "inherit",
        },
      }
    }

    const appointmentTimeslotTwentyFourHour = formatTimeslotTwentyFourHourFormat(appointmentTimeslot);
    console.log("getTableRowColorScheme:: Appointment Timeslot 24:", appointmentTimeslotTwentyFourHour);
    const [startTime, endTime] = appointmentTimeslotTwentyFourHour.split('-');
    const appointmentDateTime = moment.tz(appointmentDate, "Asia/Dubai").set({
      hour: parseInt(startTime.split(':')[0]),
      minute: parseInt(startTime.split(':')[1]),
      second: 0
    });
    console.log("getTableRowColorScheme:: Appointment Time: ", appointmentDateTime.format('MMMM Do YYYY, h:mm:ss a'));

    const currentTime = moment.tz("Asia/Dubai");
    console.log("getTableRowColorScheme:: Dubai current time: ", currentTime.format('MMMM Do YYYY, h:mm:ss a'))

    const duration = moment.duration(Math.abs(currentTime.diff(appointmentDateTime)));
    const hours = duration.asHours() * (currentTime.isAfter(appointmentDateTime) ? -1 : 1);
    console.log("getTableRowColorScheme:: Difference in hours: ", hours);

    let color = "";
    if (hours < 0) {
      color = "inherit"
    } else if (hours <= 6) {
      color = "#FFCCCB";
    } else if (hours <= 12) {
      color = "#ADD8E6";
    } else if (hours <= 18) {
      color = "#90EE90";
    } else {
      color = "inherit"
    }

    return {
      backgroundColor: color,
      "&:hover": {
        backgroundColor: color,
      },
    }
  }

  render() {
    let finalList = [];
    let transactionList = [];
    let addressArray = [];
    const {
      orders,
      timeSlots,
      pickupTime,
      confirmTime,
      isLoaded,
      loadError,
      userType,
      showAssignModal,
      showLabAssignModal,
      showNoteAssignModal,
      searchApplied,
      results,
      resultCount,
      showItems,
      upcomingOrders,
      filterApplied,
      modalData,
      userTransactions,
      newUser,
      subscription_packages,
      add_on_packages,
      custom_packages,
      countryOptions = [],
      selectedCountry,
      editables = {},
      operationsUsers,
      selectedItemType,
      isEditable,
      dependent_id,
      member,
    } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    transactionList = userTransactions;
    if (this.state.showModal) {
      if (!newUser && modalData.address != null) {
        addressArray = [modalData.address];
      } else {
        addressArray = modalData.address;
      }
    }
    // console.log("Address Array Is: ", addressArray);

    const filterAddressForSelectedCountry = (address) => {
      if (address === null || address === undefined) 
        return false;
      
      const { country: countryName } = address;
      const { country_name, country_name_ar } = selectedCountry ?? {};
      return [country_name, country_name_ar].includes(countryName);
    }

    const packageData = {
      data: [
        {
          packageName: "Blood Biomarker Packages",
          childerns: subscription_packages,
        },
        {
          packageName: "Custom packages",
          childerns: custom_packages,
        },
        {
          packageName: "Health Coach Packages",
          childerns: add_on_packages,
        },
      ]
    }

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    let confirmvalidation = this.confirmsubmitted
      ? this.confirmvalidator.validate(this.state)
      : this.state.confirmvalidation;
    if (this.submitted) this.validateDate(this.state.pickupDate);
    if (this.confirmsubmitted) this.validateCnfirmDate(this.state.confirmDate,this.state.confirmTime);
    if (isLoaded) {
      // if (searchApplied) finalList = results;
      // else if (filterApplied) finalList = upcomingOrders;
      // else finalList = orders;
      finalList = orders
    }

    const packageContents = modalData.package && modalData.package.tests
      ? modalData.package.tests || []
      : modalData.order_items || [];
    const packageContentOptions = packageContents.map(it => ({
      key: it.id, value: it.id, label: it.name,
    }));

    const paymentDetails = modalData.transaction && modalData.transaction.length > 0
      ? modalData.transaction[0] ?? {}
      : {};

    const memberDetails = member;

    const isMiniPackage = modalData?.order_item_type?.toLowerCase() === "mini_package";
    const isBloodMiniPackagesBundle = modalData?.package?.biomarker_mini_packages && modalData?.package?.biomarker_mini_packages.length;
    const miniPackageIds = isMiniPackage
      ? modalData.miniPackages ?? []
      : isBloodMiniPackagesBundle
        ? modalData?.package?.biomarker_mini_packages?.map((it) => it.id)
        : []

    const orderInfoForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label>Order ID </label>
            <div className="user-input-container">
              <input
                type="text"
                name="order-id"
                className={`form-control`}
                value={modalData.id || ""}
                onChange={(e) => this.handleInput(e, "orderId")}
                readOnly={true}
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label>Email {newUser && "*"}</label>
            {newUser &&
              <div class="input-group mb-3">
                <input
                  type="text"
                  value={this.state.searchTermEmail || ""}
                  onChange={(e) => this.handleInputSearchEmail(e.target.value)}
                  className="form-control"
                  placeholder="Search Email"
                />
                <div class="input-group-append">
                  <i className="fa fa-search input-group-text" onClick={this.loadUsersByEmail} />
                </div>
              </div>
            }
            <div className="user-input-container">
              {/* <SearchBar className="form-control" placeholder="Search Email" data={[]} /> */}
              <select
                name="email"
                className={`py-2 form-control user-data-input selectpicker ${(this.state.editOrderEmail || newUser) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "user")}
                value={modalData.user_id ? modalData.user_id : ''}
                disabled={(this.state.editOrderEmail || newUser) ? false : true}
              >
                <option key={-1} value={-1} hidden>Select</option> 
                {/* {this.optionList(this.state.userListFormatted)} */}
                {
                  !newUser && modalData.user
                    ? this.optionList([{
                      text: modalData.user.email + " (" + modalData.user.username + ")",
                      value: modalData.user.id,
                      key: modalData.user.id,
                    }])
                    : this.optionList(this.state.userListFormatted) 
                }
              </select>
              {/* {!newUser && (
                <div className="user-data-edit-icon" onClick={() => this.makeEditable("editOrderEmail")}>
                  <i className="fa fa-pencil"></i>
                </div>
              )}*/}
              <span className="error">{this.state.emailErrorMessage}</span>
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Order Item Type</label>
            <div className="user-input-container">
              <input
                type="text"
                name="order_item_type"
                className={`form-control`}
                value={modalData.order_item_type || ""}
                readOnly={true}
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="orderType">Order Type</label>
            <div className="user-input-container">
              <input
                type="text"
                name="order_type"
                className={`form-control`}
                value={modalData.order_type || ""}
                readOnly={true}
              />
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group col">
            <label htmlFor="country_select">Country</label>
            <div className="input-group user-input-container">
              <Select
                key={`country_select${selectedCountry ? selectedCountry.id : ""}`}
                value={selectedCountry ? this.createCountryOption(selectedCountry) : ""}
                onChange={(event) => this.setState({ selectedCountry: this.findCountry(event.value) })}
                options={countryOptions}
                styles={selectStyles}
                placeholder="Select Country..."
                isDisabled={!newUser}
              />
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="city">City</label>
            <div className="input-group user-input-container">
              <input
                type="text"
                name="city"
                className={`form-control`}
                value={modalData.city || ""}
                readOnly={true}
              ></input>
            </div>
          </div>
        </div>
        
        <div className="form-group-container">
          <div className="form-group">
            <label>Package {newUser && "*"}</label>
            <div className="user-input-container">
              <select
                name="packages"
                className={`py-2 form-control user-data-input ${(this.state.editPackage || newUser) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "package")}
                value={modalData.package_id ? modalData.package_id : ''}
                disabled={(this.state.editPackage || newUser) ? false : true}
              >
                <option key={-1} value={-1} hidden>Select</option> 
                {packageData.data && packageData.data.length && packageData.data.map(({ packageName, childerns }, index) => (
                  <optgroup label={packageName} key={index}>
                    {
                      childerns && childerns.length && childerns.map((status, id) => (
                        <option key={status.type + status.value} value={status.value} name={status.type}>
                          {status.label}
                        </option>
                      ))
                    }
                    {/* <option key={index} value={status.value} name={status.type}>
                      {status.label}
                    </option> */}
                  </optgroup>
                ))
                }
              </select>
              {/* {!newUser && (
                <div className="user-data-edit-icon" onClick={() => this.makeEditable("editPackage")}>
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
              <span className="error">{this.state.packageErrorMessage}</span>
            </div>
          </div>
          <div className="form-group">
            <label>Purchased On</label>
            <div className="user-input-container">
              <input
                type="text"
                name="purchased-on"
                className={`form-control`}
                value={moment(modalData.created_at).format("MMM DD YYYY, h:mm A") || ""}
                readOnly={true}
              ></input>
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Appointment Date</label>
            <div className="input-group user-input-container">
              <input
                type="date"
                name="appointmentDate"
                className={`form-control user-data-input ${(this.state.editAppiontmentDate || newUser) ? `non-editable` : `editable`} ` + (this.state.pickupDateErrorMessage && " has-error")}
                value={this.state.pickupDate}
                onChange={(e) => this.handleInput(e, "pickupDate")}
                readOnly={(this.state.editAppiontmentDate || newUser) ? false : true}
              ></input>
              {/*
              {isEditable && !newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editAppiontmentDate")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="help-block">{this.state.pickupDateErrorMessage}</span>
          </div>
          <div className="form-group">
            <label>Appointment Time</label>
            <div className="input-group user-input-container">
              <select
                name="pickupTime"
                className={`form-control user-data-input ${(this.state.editAppiontmentTime || newUser) ? `non-editable` : `editable`} ` + (this.state.pickupDateErrorMessage && " has-error")}
                value={pickupTime}
                readOnly={(this.state.editAppiontmentTime || newUser) ? false : true}
                disabled={(this.state.editAppiontmentTime || newUser) ? false : true}
                onChange={(e) => this.handleInput(e, "pickupTime")}
              >
                <option value="" disabled selected>
                  Select time
                </option>
                {
                  this.state.newTimeSlots && this.state.newTimeSlots.map((timeSlot, index) => (
                    <option key={index} value={timeSlot}>
                      {timeSlot}
                    </option>
                  ))
                }
                {/* {timeSlots.map((time, index) => (
                  <option key={index} value={time.timeslot}>
                    {time.timeslot}
                  </option>
                ))} */}
              </select>
              {/*
              {isEditable && !newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editAppiontmentTime")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="help-block">
              {(this.state.pickupDateErrorMessage || "") + " " + (this.state.pickupTimeErrorMessage || "")}
            </span>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Confirmed Date</label>
            <div className="input-group user-input-container">
              <input
                type="date"
                name="appointmentDate"
                className={`form-control user-data-input ${(this.state.editConfirmDate) ? `non-editable` : `editable`} ` + (this.state.confirmDateErrorMessage && " has-error")}
                value={this.state.confirmDate ? this.state.confirmDate : ""}
                onChange={(e) => this.handleInput(e, "confirmDate")}
                readOnly={(this.state.editConfirmDate ) ? false : true}
              ></input>
              <span className="help-block">{this.state.confirmDateErrorMessage}</span>
              {isEditable && !newUser  && !["Supplement","Medicine","Bundle"].includes(modalData.order_item_type) &&(
                <div className="input-group-append" onClick={() => this.makeEditable("editConfirmDate")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Confirmed Time</label>
            <div className="input-group user-input-container">
              <input
                type="time"
                name="confirm-time"
                className={`form-control user-data-input ${(this.state.editConfirmTime) ? `non-editable` : `editable`} ` + (this.state.confirmDateErrorMessage && " has-error")}
                value={confirmTime ? confirmTime : ''}
                onChange={(e) => this.handleInput(e, "confirmTime")}
                readOnly={(this.state.editConfirmTime  ) ? false : true}
              ></input>
              <span className="help-block">{this.state.confirmTimeErrorMessage}</span>
              {isEditable && !newUser && !["Supplement","Medicine","Bundle"].includes(modalData.order_item_type) &&(
                <div className="input-group-append" onClick={() => this.makeEditable("editConfirmTime")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group col-6">
            <label htmlFor="city">City Level Pricing</label>
            <div className="input-group user-input-container">
              <input
                type="text"
                name="city"
                className={`form-control`}
                value={modalData.is_city_level_pricing ? "Yes" : "No"}
                readOnly={true}
              ></input>
            </div>
          </div>
        </div>
        <div className="form-content">
          <span>
            Order Status
          </span>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Order Status</label>
            <div className="input-group user-input-container">
              <select
                name="order-status"
                className={`form-control user-data-input ${(this.state.editOrderStatus) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "order_status")}
                value={modalData.order_status}
                disabled={(this.state.editOrderStatus) ? false : true}
              >
                {modalData.status_dropdown_list && modalData.status_dropdown_list.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.name}
                  </option>
                ))
                }
              </select>
              {isEditable && !newUser && !this.isCartOrder(modalData) && (
                <div className="input-group-append" onClick={() => this.makeEditable("editOrderStatus")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="order_source">Order Source</label>
            <div className="user-input-container">
              <input
                // value={ORDER_SOURCE}
                value={newUser ? ORDER_SOURCE : modalData?.order_source ?? ""}
                id="order_source"
                name="order_source"
                type="text"
                readOnly={true}
                className={`form-control user-data-input`}
              />
            </div>
          </div>
          {/* <div className="form-group">
            <label>Lab</label>
            <div className="input-group user-input-container">
              <select
                name="order-status"
                className={`form-control user-data-input ${(this.state.editLab) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "laboratory")}
                value={modalData.laboratory}
                disabled={(this.state.editLab) ? false : true}
              >
                {this.state.labtListFormatted && this.state.labtListFormatted.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.text}
                  </option>
                ))
                }
              </select>
              {!newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editLab")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
          </div> */}
        </div>

        <div className="form-group-container">
          <div className="form-group">
            <label>Coach</label>
            <div className="input-group user-input-container">
              <select
                name="order-status"
                className={`form-control user-data-input ${(this.state.editCoach) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "nutritionist")}
                value={modalData.nutritionist ? modalData.nutritionist.id : ''}
                disabled={(this.state.editCoach) ? false : true}
              >
                {
                  (!modalData.nutritionist || !modalData.nutritionist.id) && <option key={-1} value={-1} hidden={true}>Select Wellbeing Coach</option>
                }
                {
                // !newUser && this.state.nutritionistListFormatted && this.state.nutritionistListFormatted.map((status, index) => (
                //   <option key={index} value={status.value}>
                //     {status.text}
                //   </option>
                // ))
                  // This method is created because in coach dropdown we are showing only active coaches.
                  // But for some old orders thers may be a inactive coach assigned to it, so in that case we have to add option for that coach so we can see the coach details on dropdown.
                  // And for new orders if we are automatically assigning a coach which is previously assigned so there may be the case that we can assign a inactive coach.
                  this.createCoachOptions()
                }
              </select>
              {isEditable && !newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editCoach")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Coach's Review</label>
            <div className="user-input-container">
              <input
                type="text"
                name="coach-review"
                className={`form-control user-data-input`}
                value={modalData.is_result_uploaded ? 'Uploaded' : 'Pending' || ""}
                readOnly={true}
              ></input>
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Attachment</label>
            <div className="user-input-container">
              <input
                type="text"
                name="attachment"
                className={`form-control user-data-input`}
                value={modalData.attachment_uploaded ? 'Yes' : 'No' || ""}
                readOnly={true}
              ></input>
            </div>
          </div>
          <div className="form-group">
            <label>Ops Order Notes</label>
            <div className="input-group user-input-container">
              <input
                type="text"
                name="notes"
                className={`form-control user-data-input ${(this.state.editNotes || newUser) ? `non-editable` : `editable`} `}
                value={modalData.order_notes || ""}
                onChange={(e) => this.handleInput(e, "order_notes")}
                readOnly={(this.state.editNotes || newUser) ? false : true}
              ></input>
              {isEditable && !newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editNotes")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>

          </div>
        </div>
      </>
    )

    const dependentInfoForm =
      <>
        <div className="row my-2">
          <div className="col separator">
            <span>Client Information</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="member_first_name">First Name</label>
            <input
              value={memberDetails?.first_name || ""}
              type="text"
              name="member_first_name"
              id="member_first_name"
              className="form-control"
              disabled />
          </div>
          <div className="form-group col">
            <label htmlFor="member_last_name">Last Name</label>
            <input
              value={memberDetails?.last_name || ""}
              type="text"
              name="member_last_name"
              id="member_last_name"
              className="form-control"
              disabled />
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="member_gender">Gender</label>
            <input
              value={memberDetails?.gender || ""}
              type="text"
              name="member_gender"
              id="member_gender"
              className="form-control"
              disabled />
          </div>
          <div className="form-group col">
            <label htmlFor="member_mob">Phone Number</label>
            <PhoneInput
              value={memberDetails?.mob || ""}
              id="member_mob"
              placeholder=""
              inputStyle={{ width: "100%", backgroundColor: "#E9ECEF" }}
              disabled
            />
          </div>
        </div>
        <div className="row">      
          <div className="form-group col-6">
            <label htmlFor="member_relation">Relation with Customer</label>
            <input
              value={memberDetails?.relation || ""}
              type="text"
              name="member_relation"
              id="member_relation"
              className="form-control"
              disabled />
          </div>
        </div>
      </>;

    const personalForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label>First Name</label>
            <div className="user-input-container">
              <input
                type="text"
                name="first-name"
                // className={`form-control user-data-input ${(this.state.editFn || newUser) ? `non-editable` : `editable`} `}
                className={`form-control`}
                value={modalData.user ? modalData.user.first_name : '' || ""}
                onChange={(e) => this.handleInput(e, "first_name")}
                readOnly={true}
              ></input>
              {/* {!newUser &&
                <div className="user-data-edit-icon" onClick={() => this.makeEditable("editFn")}>
                  <i className="fa fa-pencil"></i>
                </div>
              } */}
            </div>
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <div className="user-input-container">
              <input
                type="text"
                name="last-name"
                // className={`form-control user-data-input ${(this.state.editLn || newUser) ? `non-editable` : `editable`} `}
                className={`form-control`}
                value={modalData.user ? modalData.user.last_name : '' || ""}
                onChange={(e) => this.handleInput(e, "last_name")}
                readOnly={true}
              ></input>
              {/* {!newUser &&
                <div className="user-data-edit-icon" onClick={() => this.makeEditable("editLn")}>
                  <i className="fa fa-pencil"></i>
                </div>
              } */}
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Email</label>
            <div className="user-input-container">
              <input
                type="text"
                name="email"
                // className={`form-control user-data-input ${(this.state.editEmail || newUser) ? `non-editable` : `editable`} `}
                className={`form-control`}
                value={modalData.user ? modalData.user.email : '' || ""}
                onChange={(e) => this.handleInput(e, "email")}
                readOnly={true}
              ></input>
              {/* {!newUser &&
                <div className="user-data-edit-icon" onClick={() => this.makeEditable("editEmail")}>
                  <i className="fa fa-pencil"></i>
                </div>
              } */}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="phone_no">Phone Number</label>
            <PhoneInput
              value={modalData.user ? modalData.user.phone_no || ""  : ""}
              id="phone_no"
              placeholder=""
              inputStyle={{ width: "100%", backgroundColor: "#E9ECEF" }}
              disabled
            />
            {/* <div className="user-input-container">
              <input
                type="text"
                name="phone-number"
                // className={`form-control user-data-input ${(this.state.editPhoneNo || newUser) ? `non-editable` : `editable`} `}
                className={`form-control`}
                value={modalData.user ? modalData.user.phone_no : '' || ""}
                onChange={(e) => this.handleInput(e, "phone_no")}
                readOnly={true}
              ></input>
              {
              // !newUser &&
              //   <div className="user-data-edit-icon" onClick={() => this.makeEditable("editPhoneNo")}>
              //     <i className="fa fa-pencil"></i>
              //   </div>
              }
            </div> */}
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group col-12">
            <label htmlFor="clients_notes">Client’s Notes (Operational Related)</label>
            <div className="input-group user-input-container">
              <textarea
                value={modalData.client_note || ""}
                onChange={event => {
                  const newModalData = { ...modalData };
                  newModalData.client_note = event.target.value;
                  this.setState({ modalData: newModalData });
                }}
                id="clients_notes"
                name="clients_notes"
                type="text"
                placeholder="Client's Notes"
                className="form-control"
                readOnly={(this.state.editClientNotes || newUser) ? false : true}
              ></textarea>
              {isEditable && !newUser && (
                <div className="input-group-append" onClick={() => this.makeEditable("editClientNotes")}>
                  <i className="input-group-text fa fa-pencil"></i>
                </div>
              )}
            </div>
            <span className="error">{this.state.clientNoteErrorMessage || ""}</span>
          </div>
        </div>
        <div className="form-content">
          <span>Identification Information</span>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="fullName">Full Name as per ID</label>
              <div className="input-group">
                <input
                  value={modalData.user ? modalData.user.full_name_id : '' || ""}
                  onChange={(e) => this.handleInput(e, "full_name_id")}
                  id="full_name_id"
                  name="full_name_id"
                  type="text"
                  placeholder="Full Name"
                  className={
                    "form-control"
                  }
                  disabled={(this.state.editFullNameId) ? false : true}
                />
                {isEditable && !newUser && (
                  <div className="input-group-append" onClick={() => this.makeEditable("editFullNameId")}>
                    <i className="input-group-text fa fa-pencil"></i>
                  </div>
                )}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.fullNameIdErrorMessage}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">ID-Front</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectFrontImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*, application/pdf"
                  className={"form-control"}
                  disabled={(this.state.editFrontId) ? false : true}
                />
                {isEditable && !newUser && (
                  <div className="input-group-append" onClick={() => this.makeEditable("editFrontId")}>
                    <i className="input-group-text fa fa-pencil"></i>
                  </div>
                )}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.frontIdErrorMessage}</span>
            </div>
            <div className="form-group col-6">
              {this.renderFrontIdPreview()}
              {/* {this.state.frontIdSrc && (
                <>
                  <label htmlFor="selected_front_image">
                    ID - Front Preview
                  </label>
                  <div>
                    <img
                      id="selected_front_image"
                      name="selected_front_image"
                      src={this.state.frontIdSrc || ""}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )} */}
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">ID-Back</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectBackImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*, application/pdf"
                  className={"form-control"}
                  disabled={(this.state.editBackId) ? false : true}
                />
                {isEditable && !newUser && (
                  <div className="input-group-append" onClick={() => this.makeEditable("editBackId")}>
                    <i className="input-group-text fa fa-pencil"></i>
                  </div>
                )}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.backIdErrorMessage}</span>
            </div>
            <div className="form-group col-6">
              {this.renderBackIdPreview()}
              {/* {this.state.backIdSrc && (
                <>
                  <label htmlFor="selected_back_image">
                    ID - Back Preview
                  </label>
                  <div>
                    <img
                      id="selected_back_image"
                      name="selected_back_image"
                      src={this.state.backIdSrc || ""}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )} */}
            </div>
          </div>
        </div>
        {this.state.dependent_id && dependentInfoForm}
        {addressArray &&
          addressArray
            .filter(filterAddressForSelectedCountry)
            .map((item, i) => {
            return <>
              <div className="form-content">
                <span>
                  Address {i + 1}
                </span>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <label>Label</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="label"
                      className={`form-control user-data-input ${(this.state[`label${i}`] || newUser) ? `non-editable` : `editable`} `}
                      value={item && item.label ? item.label : ''}
                      onChange={(e) => this.handleInput(e, `label${i}`)}
                      readOnly={false}
                    ></input>
                    {/* {!newUser &&
                      <div className="user-data-edit-icon" onClick={() => this.makeEditable(`label${i}`)}>
                        <i className="fa fa-pencil"></i>
                      </div>
                    } */}
                  </div>
                </div>
                <div className="form-group">
                  <label>Street Name</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="street-name"
                      className={`form-control user-data-input`}
                      value={item && item.address_line2 ? item.address_line2 : ''}
                      onChange={(e) => this.handleInput(e, `address_line2${i}`)}
                      readOnly={true}
                    ></input>
                    {/* {!newUser &&
                      <div className="user-data-edit-icon" onClick={() => this.makeEditable(`address_line2${i}`)}>
                        <i className="fa fa-pencil"></i>
                      </div>
                    } */}
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <label>Building/Apt.</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="building-apt"
                      // className={`form-control user-data-input ${(this.state[`building_apt${i}`] || newUser) ? `non-editable` : `editable`} `}
                      className={`form-control user-data-input`}
                      value={item && item.building_apt ? item.building_apt : ''}
                      onChange={(e) => this.handleInput(e, `building_apt${i}`)}
                      readOnly={true}
                    ></input>
                    {/* {!newUser &&
                      <div className="user-data-edit-icon" onClick={() => this.makeEditable(`building_apt${i}`)}>
                        <i className="fa fa-pencil"></i>
                      </div>
                    } */}
                  </div>
                </div>
                <div className="form-group">
                  <label>Area/District</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="area-district"
                      // className={`form-control  user-data-input ${(this.state[`address_line1${i}`] || newUser) ? `non-editable` : `editable`} `}
                      className={`form-control user-data-input`}
                      value={item && item.address_line1 ? item.address_line1 : ''}
                      onChange={(e) => this.handleInput(e, `address_line1${i}`)}
                      readOnly={true}
                    ></input>
                    {/* {!newUser &&
                      <div className="user-data-edit-icon" onClick={() => this.makeEditable(`address_line1${i}`)}>
                        <i className="fa fa-pencil"></i>
                      </div>
                    } */}
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group">
                  <label>City</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="city"
                      // className={`form-control user-data-input ${(this.state[`city${i}`] || newUser) ? `non-editable` : `editable`} `}
                      className={`form-control user-data-input`}
                      value={item && item.city ? item.city : ''}
                      onChange={(e) => this.handleInput(e, `city${i}`)}
                      readOnly={true}
                    ></input>
                    {/* {!newUser &&
                      <div className="user-data-edit-icon" onClick={() => this.makeEditable(`city${i}`)}>
                        <i className="fa fa-pencil"></i>
                      </div>
                    } */}
                  </div>
                </div>
                <div className="form-group">
                  <label>Country</label>
                  <div className="user-input-container">
                    <input
                      type="text"
                      name="country"
                      className={`form-control user-data-input`}
                      value={item && item.country ? item.country : ''}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group col-6">
                  <label htmlFor={`phone_no${i}`}>Phone Number</label>
                  <PhoneInput
                    value={item ? item.phone_no || "" : ""}
                    id={`phone_no${i}`}
                    placeholder=""
                    inputStyle={{ width: "100%", backgroundColor: "#E9ECEF" }}
                    disabled
                  />
                </div>
                <div className="form-group col-6">
                  <label htmlFor={`map_url${i}`}>Pin Location</label>
                  <div className="user-input-container">
                    <input
                      value={item.map_url ?? ""}
                      type="text"
                      id={`map_url${i}`}
                      name={`map_url${i}`}
                      className={`form-control user-data-input ${(this.state[`map_url${i}`] || newUser) ? `non-editable` : `editable`} `}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group-container">
                <div className="form-group col-6">
                  <label htmlFor={`lat${i}`}>Latitude</label>
                  <div className="user-input-container">
                    <input
                      value={item.lat ?? ""}
                      type="text"
                      id={`lat${i}`}
                      name={`lat${i}`}
                      className={`form-control user-data-input ${(this.state[`lat${i}`] || newUser) ? `non-editable` : `editable`} `}
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label htmlFor={`lng${i}`}>Longitude</label>
                  <div className="user-input-container">
                    <input
                      value={item.lng ?? ""}
                      type="text"
                      id={`lng${i}`}
                      name={`lng${i}`}
                      className={`form-control user-data-input ${(this.state[`lng${i}`] || newUser) ? `non-editable` : `editable`} `}
                      readOnly={true}
                    />
                  </div>
                </div>
              </div>
            </>
          })
        }
      </>
    )

    const activityForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label>Package Name</label>
            <input
              type="text"
              name="app-version"
              className={"form-control user-data-input non-editable"}
              readOnly
              value={!modalData.package_type ? (
                modalData.package && modalData.package !== null
                  // ? modalData.package.subscription_name
                  ? modalData.package.internal_name
                  : modalData.add_on && modalData.add_on !== null
                    ? modalData.add_on.addon_name
                    : "N/A"
              ) : (
                modalData.custom_package && modalData.package_type ? (
                  modalData.custom_package.name
                    ? modalData.custom_package.name
                    : ""
                ) : ("")
              )}
            ></input>
          </div>
          <div className="form-group">
            <label htmlFor="package_content">Package Content</label>
            <Select
              key={"package_content" + packageContents ? packageContents.map(it => it.id).join() : ""}
              defaultValue={packageContentOptions || []}
              options={packageContentOptions || []}
              styles={selectStyles}
              id="package_content"
              placeholder=""
              isMulti
              isDisabled
            />
            {/* <input
              type="text"
              name="last-login"
              className={"form-control user-data-input non-editable"}
              readOnly
              value={
                modalData.package && modalData.package.tests 
                  ? this.returnPackageTestNames(modalData.package.tests) 
                  : this.returnPackageTestNames(modalData.order_items)
              }
            ></input> */}
          </div>
        </div>
        {(isMiniPackage || isBloodMiniPackagesBundle) ?
          (<div className="form-group-container">
            <div className="form-group col-6">
              <label>Mini Packages</label>
              <SelectMiniPackages
                miniPackages={this.state.miniPackages ?? []}
                selectedMiniPackageIds={miniPackageIds ?? []}
                isDisabled={true}
              />
            </div>
          </div>) : null
        }
        <div className="form-content">
          <span>
            Subscription
          </span>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Subscription Length</label>
            <input
              type="text"
              name="device-info"
              className={"form-control user-data-input non-editable"}
              readOnly
              value={modalData.subscription_frequency ? modalData.subscription_frequency : (modalData.addonsubscription ? modalData.addonsubscription.addon_subscription_validity : '')}
            ></input>
          </div>

        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Start Date </label>
            <input
              type="text"
              name="start-date"
              className={"form-control user-data-input non-editable"}
              value={modalData.addonsubscription ? moment(modalData.addonsubscription.addon_subscription_start_date).format('MM/DD/YYYY') : ''}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label>End Date</label>
            <input
              type="text"
              name="end-date"
              className={"form-control user-data-input non-editable"}
              value={modalData.addonsubscription ? moment(modalData.addonsubscription.addon_subscription_end_date).format('MM/DD/YYYY') : ''}
              readOnly
            ></input>
          </div>
        </div>

      </>
    )

    const costsForm = (
      <div className="form-group-container">
        <div className="form-group">
          <label htmlFor="lab_cost">Lab Costs</label>
          <div className="input-group user-input-container">
            <input
              value={modalData.lab_cost ?? ""}
              onChange={(e) => this.handleInput(e, "lab_cost")}
              type="number"
              id="lab_cost"
              name="lab_cost"
              placeholder="Lab Costs"
              className={`form-control user-data-input ${(this.state.editLabCosts || newUser) ? `non-editable` : `editable`} `}
              readOnly={(this.state.editLabCosts || newUser) ? false : true}
            />
            {!newUser && (
              <div className="input-group-append" onClick={() => this.makeEditable("editLabCosts")}>
                <i className="input-group-text fa fa-pencil"></i>
              </div>
            )}
          </div>
          <span className="error">{this.state.labCostsErrorMessage}</span>
        </div>
        <div className="form-group">
          <label htmlFor="home_care_cost">Homecare Costs</label>
          <div className="input-group user-input-container">
            <input
              value={modalData.home_care_cost ?? ""}
              onChange={(e) => this.handleInput(e, "home_care_cost")}
              type="number"
              id="home_care_cost"
              name="home_care_cost"
              placeholder="Homecare Costs"
              className={`form-control user-data-input ${(this.state.editHomecareCosts || newUser) ? `non-editable` : `editable`} `}
              readOnly={(this.state.editHomecareCosts || newUser) ? false : true}
            />
            {!newUser && (
              <div className="input-group-append" onClick={() => this.makeEditable("editHomecareCosts")}>
                <i className="input-group-text fa fa-pencil"></i>
              </div>
            )}
          </div>
          <span className="error">{this.state.homecareCostsErrorMessage}</span>
        </div>
      </div>
    );

    const pricingForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label>Total Amount*</label>
            <input
              type="text"
              name="Totalamount"
              className={"form-control user-data-input non-editable"}
              // value={modalData.order_price && `AED ${modalData.order_price}`}
              value = {modalData.order_price ?? ""}
              readOnly={!newUser}
            />
          </div>
          <div className="form-group">
            <label htmlFor="currency">Currency</label>
            <input
              type="text"
              id="currency"
              name="currency"
              className={"form-control user-data-input non-editable"}
              value = {paymentDetails.currency || modalData.currency || ""}
              readOnly={!newUser}
            />
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Coupon Name</label>
            {/* <input
              type="text"
              name="device-info"
              className={"form-control user-data-input non-editable"}
              readOnly
              value={modalData.transaction && modalData.transaction.length > 0 ? modalData.transaction[0].coupon_name : ''}

            ></input> */}
            <div className="user-input-container">
              <select
                name="coupen-name"
                className={`form-control user-data-input ${(newUser) ? `non-editable` : `editable`} `}
                onChange={(e) => this.handleInput(e, "coupen")}
                value={modalData.coupon_code ? modalData.coupon_code : (modalData.transaction && modalData.transaction.length > 0 ? modalData.transaction[0].coupon_name : '')}
                disabled={(newUser) ? false : true}
              >
                <option>{!newUser && modalData.coupon_code ? modalData.coupon_code : (modalData.transaction && modalData.transaction.length > 0 ? modalData.transaction[0].coupon_name : '')}</option>
                {this.state.coupenList && this.state.coupenList.map((coupon, index) => (
                  <option key={index} value={coupon.id}>
                    {coupon.coupon_code}
                  </option>
                ))
                }
              </select>
              {/* <div className="user-data-edit-icon" onClick={() => this.makeEditable("editOrderEmail")}>
                <i className="fa fa-pencil"></i>
              </div> */}
            </div>
          </div>
          <div className="form-group">
            <label>Coupon Amount/Percent </label>
            <input
              type="text"
              name="coupen-amount"
              className={"form-control user-data-input non-editable"}
              value={modalData.coupon_amount 
                  ? modalData.coupon_amount 
                  : (modalData.transaction && modalData.transaction.length > 0 
                    ? `${modalData.transaction[0].coupon_value ?? ""}${modalData.transaction[0].discount_type ? ` / ${modalData.transaction[0].discount_type}` : ""}` 
                    : '')}
              readOnly
            ></input>
          </div>

        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Cart ID </label>
            <input
              type="text"
              name="transaction_id"
              className={`form-control user-data-input ${newUser ? `non-editable` : `editable`} `}
              readOnly={true}
              value={modalData.transaction_id || ""}
              onChange={(e) => this.handleInput(e, "transaction_id")}
            ></input>
          </div>
          <div className="form-group">
            <label>Service Reference{newUser && "*"}</label>
            <input
              type="text"
              name="end-date"
              className={`form-control user-data-input ${newUser ? `non-editable` : `editable`} `}
              value={modalData.transaction && modalData.transaction.length > 0 ? modalData.transaction[0].transaction_reference : ''}
              readOnly={(newUser) ? false : true}
            ></input>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="payment_gateway">Payment Gateway (Custom)</label>
            <div className="input-group user-input-container">
              <Dropdown
                value={modalData?.payment_gateway}
                onChange={(e, value) => {
                  this.setState({
                    modalData: {
                      ...this.state.modalData,
                      payment_gateway: value.value
                    },
                    updatedOrder: {
                      ...this.state.updatedOrder,
                      payment_gateway: value.value
                    }
                  })
                }}
                id="payment_gateway"
                name="payment_gateway"
                placeholder="Payment Gateway (Custom)"
                search
                selection
                options={CUSTOM_PAYMENT_GATEWAY_OPTIONS}
                disabled={!newUser}
                className="form-control user-data-input"
              />
            </div>
          </div>
        </div>
        <div className="form-content">
          <span>Costs</span>
        </div>
        {/* {costsForm} */}
        { !newUser && <div className="mx-3"><PaymentDetailsForm paymentDetails={paymentDetails} /></div> }
      </>
    )

    const operationsInternalForm = <>
      <div className="form-content">
        <span>Operations Internal</span>
      </div>
      <div className="container mt-4">
      {modalData.order_item_type !=="Supplement" && modalData.order_item_type !=="Bundle" ? <><div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy"> Handled By</label>
            <div className="input-group user-input-container">
              <select
                name="handled_by"
                className={`form-control user-data-input`}
                onChange={this.handleChangeOperations}
                value={modalData.handled_by}
                disabled={!editables.handled_by}
              >
                {
                  (!modalData.handled_by) && <option key={-1} value={-1} hidden={true}>Select Handled By</option>
                }
                {operationsUsers && operationsUsers.map((status, index) => (
                  <option key={index} value={status.id}>
                    {status.userName}
                  </option>
                ))
                }
              </select>
              {this.renderPencilIcon("handled_by")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Custom Test Price Additional</label>
            <div className="input-group">
              <input
                value={modalData && modalData.custom_test_price_additional}
                onChange={this.handleChangeOperations}
                id="custom_test_price_additional"
                name="custom_test_price_additional"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Custom Test Price Additional"
                className={
                  "form-control py-2"
                }
                disabled={!newUser && !editables.custom_test_price_additional}
              />
              {this.renderPencilIcon("custom_test_price_additional")}
            </div>
            <span className="help-block">{this.state.customTestPriceAddErrorMessage}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab1"> Lab 1</label>
            <div className="input-group user-input-container">
              <select
                name="lab_1"
                className={`form-control user-data-input`}
                onChange={this.handleChangeOperations}
                value={modalData.lab_1}
                disabled={!editables.lab_1}
              >
                {this.state.labtListFormatted && this.state.labtListFormatted.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.text}
                  </option>
                ))
                }
              </select>
              {this.renderPencilIcon("lab_1")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 1 custom cost</label>
            <div className="input-group">
              <input
                value={modalData && modalData.lab_1_custom_cost}
                onChange={this.handleChangeOperations}
                id="lab_1_custom_cost"
                name="lab_1_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Lab 1 custom cost"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.lab_1_custom_cost}
              />
              {this.renderPencilIcon("lab_1_custom_cost")}
            </div>
            <span className="help-block">{this.state.lab1CustomCostErrorMessage}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="homeCare">Homecare</label>
            <div className="input-group user-input-container">
              <Dropdown
                value={modalData?.home_care_id ?? "none"}
                onChange={(e, value) => {
                  this.setState({
                    modalData: {
                      ...this.state.modalData,
                      home_care_id: value.value === "none" ? null : value.value
                    },
                    updatedOrder: {
                      ...this.state.updatedOrder,
                      home_care_id: value.value === "none" ? null : value.value
                    }
                  })
                }}
                id="home_care_id"
                name="home_care_id"
                placeholder="Homecare"
                search
                selection
                options={this.getHomecareServiceOptions() ?? []}
                disabled={!editables.home_care_id}
                className={`form-control user-data-input`}
              />
              {this.renderPencilIcon("home_care_id")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="home_care_cost">Homecare Costs</label>
            <div className="input-group user-input-container">
              <input
                value={modalData.home_care_cost ?? ""}
                onChange={this.handleChangeOperations}
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                id="home_care_cost"
                name="home_care_cost"
                placeholder="Homecare Costs"
                className={`form-control user-data-input`}
                disabled={!newUser && !editables.home_care_cost}
              />
              {this.renderPencilIcon("home_care_cost")}
            </div>
            <span className="help-block">{this.state.homeCareCostErrorMessage}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 1</label>
            <div className="input-group user-input-container">
              <Dropdown
                value={modalData?.payment_gateway_1}
                onChange={(e, value) => {
                  this.setState({
                    modalData: {
                      ...this.state.modalData,
                      payment_gateway_1: value.value
                    },
                    updatedOrder: {
                      ...this.state.updatedOrder,
                      payment_gateway_1: value.value
                    }
                  })
                }}
                id="payment_gateway_1"
                name="payment_gateway_1"
                placeholder="Payment Gateway 1"
                search
                selection
                options={this.getPaymentGateways() ?? []}
                disabled={!editables.payment_gateway_1}
                className={`form-control user-data-input`}
              />
              {this.renderPencilIcon("payment_gateway_1")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Reference Number 1</label>
            <div className="input-group">
              <input
                value={modalData && modalData.reference_number_1}
                onChange={this.handleChangeOperations}
                id="reference_number_1"
                name="reference_number_1"
                type="text"
                placeholder="Reference Number 1"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.reference_number_1}
              />
              {this.renderPencilIcon("reference_number_1")}
            </div>
            <span className="help-block">{this.state.referenceNumber1ErrorMessage}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lab2">Lab 2</label>
            <div className="input-group user-input-container">
              <select
                name="lab_2"
                className={`form-control user-data-input`}
                onChange={this.handleChangeOperations}
                value={modalData.lab_2}
                disabled={!editables.lab_2}
              >
                {this.state.labtListFormatted && this.state.labtListFormatted.map((status, index) => (
                  <option key={index} value={status.value}>
                    {status.text}
                  </option>
                ))
                }
              </select>
              {this.renderPencilIcon("lab_2")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Lab 2 custom cost</label>
            <div className="input-group">
              <input
                value={modalData && modalData.lab_2_custom_cost}
                onChange={this.handleChangeOperations}
                id="lab_2_custom_cost"
                name="lab_2_custom_cost"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Lab 2 custom cost"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.lab_2_custom_cost}
              />
              {this.renderPencilIcon("lab_2_custom_cost")}
            </div>
            <span className="help-block">{this.state.lab2CustomCostErrorMessage}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="handledBy">Payment Gateway 2</label>
            <div className="input-group user-input-container">
              <Dropdown
                value={modalData?.payment_gateway_2}
                onChange={(e, value) => {
                  this.setState({
                    modalData: {
                      ...this.state.modalData,
                      payment_gateway_2: value.value
                    },
                    updatedOrder: {
                      ...this.state.updatedOrder,
                      payment_gateway_2: value.value
                    }
                  })
                }}
                id="payment_gateway_2"
                name="payment_gateway_2"
                placeholder="Payment Gateway 2"
                search
                selection
                options={this.getPaymentGateways() ?? []}
                disabled={!editables.payment_gateway_2}
                className={`form-control user-data-input`}
              />
              {this.renderPencilIcon("payment_gateway_2")}
            </div>
          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Reference Number 2</label>
            <div className="input-group">
              <input
                value={modalData && modalData.reference_number_2}
                onChange={this.handleChangeOperations}
                id="reference_number_2"
                name="reference_number_2"
                type="text"
                placeholder="Reference Number 2"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.reference_number_2}
              />
              {this.renderPencilIcon("reference_number_2")}
            </div>
            <span className="help-block">{this.state.referenceNumber2ErrorMessage}</span>
          </div>
        </div>
        <div className="row">

        <div className="form-group col">
            <label htmlFor="handledBy">Coupon Code</label>
            <div className="input-group user-input-container">
            <div className="input-group">
              <input
                value={modalData && modalData.coupon_code_value}
                onChange={this.handleChangeOperations}
                id="coupon_code_value"
                name="coupon_code_value"
                type="text"
                placeholder="Coupon Code"
                className={
                  "form-control py-2"
                }
                disabled={!newUser && !editables.coupon_code_value}
              />
              {this.renderPencilIcon("coupon_code_value")}
            </div>
            <span className="help-block">{this.state.couponCodeValueErrorMessage}</span>
            </div>
          </div>

          <div className="form-group col">
            <label htmlFor="handledBy">Coupon Amount</label>
            <div className="input-group user-input-container">
            <div className="input-group">
              <input
                value={modalData && modalData.coupon_amount}
                onChange={this.handleChangeOperations}
                id="coupon_amount"
                name="coupon_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Coupon Amount"
                className={
                  "form-control py-2"
                }
                disabled={!newUser && !editables.coupon_amount}
              />
              {this.renderPencilIcon("coupon_amount")}
            </div>
            <span className="help-block">{this.state.couponAmountErrorMessage}</span>
            </div>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="customTestPrice">Revised Price</label>
            <div className="input-group">
              <input
                value={modalData && modalData.revised_price || ""}
                onChange={this.handleChangeOperations}
                id="revised_price"
                name="revised_price"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Revised Price"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.revised_price}
              />
              {this.renderPencilIcon("revised_price")}
            </div>
            <span className="help-block">{this.state.revisedPriceErrorMessage}</span>

          </div>
          <div className="form-group col">
            <label htmlFor="customTestPrice">Revised Price after Coupon</label>
            <div className="input-group">
              <input
                value={modalData && modalData.revised_price_after_coupon}
                onChange={this.handleChangeOperations}
                id="revised_price_after_coupon"
                name="revised_price_after_coupon"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Revised Price after Coupon"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.revised_price_after_coupon}
              />
              {this.renderPencilIcon("revised_price_after_coupon")}
            </div>
            <span className="help-block">{this.state.revisedPriceCouponErrorMessage}</span>
          </div>

        </div></>:<></>}
        <div className="row">
        <div className="form-group col">
            <label htmlFor="customTestPrice">Refund Amount</label>
            <div className="input-group">
              <input
                value={modalData && modalData.refund_amount}
                onChange={this.handleChangeOperations}
                id="refund_amount"
                name="refund_amount"
                type="number"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
              }}
                placeholder="Refund Amount"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.refund_amount}
              />
              {this.renderPencilIcon("refund_amount")}
            </div>
            <span className="help-block">{this.state.refundAmountErrorMessage}</span>
          </div>

        
          <div className="form-group col">
            <label htmlFor="customTestPrice">Notes</label>
            <div className="input-group">
              <textarea
                value={modalData && modalData.notes || ""}
                onChange={this.handleChangeOperations}
                id="notes"
                name="notes"
                type="text"
                placeholder="Notes"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.notes}
              />
              {this.renderPencilIcon("notes")}
            </div>
            <span className="help-block">{this.state.notesErrorMessage}</span>
          </div>
        </div>
        {modalData.order_item_type !=="Supplement" && modalData.order_item_type !=="Bundle" ? <div className="row"> <div className="form-group col-6">
            <label htmlFor="customTestPrice">Custom Test - Biomarkers</label>
            <div className="input-group">
              <textarea
                value={modalData && modalData.custom_test_biomarkers}
                onChange={this.handleChangeOperations}
                id="custom_test_biomarkers"
                name="custom_test_biomarkers"
                type="text"
                placeholder="Custom Test - Biomarkers"
                className={
                  "form-control"
                }
                disabled={!newUser && !editables.custom_test_biomarkers}
              />
              {this.renderPencilIcon("custom_test_biomarkers")}
            </div>
            <span className="help-block">{this.state.customTestBiomarkerErrorMessage}</span>
          </div></div>:<></>}
       
        
        
        {modalData.order_item_type !=="Supplement" && modalData.order_item_type !=="Bundle" ? <> <div className="row">
          <div className="form-group col">
            <label htmlFor="sample_details_lab1">Sample Details Lab 1*</label>
            <div className="input-group">
              <textarea
                value={modalData?.sample_details_lab1 ?? ""}
                onChange={this.handleChangeOperations}
                id="sample_details_lab1"
                name="sample_details_lab1"
                placeholder="Sample Details Lab 1"
                readOnly={!newUser && !editables.sample_details_lab1}
                className={"form-control"}
              />
              {this.renderPencilIcon("sample_details_lab1")}
            </div>
            <span className="help-block">{this.state.sampleDetailsLab1ErrorMessage ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="sample_details_lab2">Sample Details Lab 2</label>
            <div className="input-group">
              <textarea
                value={modalData?.sample_details_lab2 ?? ""}
                onChange={this.handleChangeOperations}
                id="sample_details_lab2"
                name="sample_details_lab2"
                placeholder="Sample Details Lab 2"
                readOnly={!newUser && !editables.sample_details_lab2}
                className={"form-control"}
              />
              {this.renderPencilIcon("sample_details_lab2")}
            </div>
            <span className="help-block">{this.state.sampleDetailsLab2ErrorMessage ?? ""}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="special_instructions">Special Instructions</label>
            <div className="input-group">
              <textarea
                value={modalData?.special_instructions ?? ""}
                onChange={this.handleChangeOperations}
                id="special_instructions"
                name="special_instructions"
                placeholder="Special Instructions"
                readOnly={!newUser && !editables.special_instructions}
                className={"form-control"}
              />
              {this.renderPencilIcon("special_instructions")}
            </div>
            <span className="help-block">{this.state.specialInstructionsErrorMessage ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="payment_to_be_collected">Payment to be collected</label>
            <div className="input-group">
              <input
                value={modalData?.payment_to_be_collected ?? ""}
                onChange={this.handleChangeOperations}
                id="payment_to_be_collected"
                name="payment_to_be_collected"
                placeholder="Payment to be collected"
                readOnly={!newUser && !editables.payment_to_be_collected}
                className={"form-control"}
                type="number"
              />
              {this.renderPencilIcon("payment_to_be_collected")}
            </div>
            <span className="help-block">{this.state.paymentToBeCollectedErrorMessage ?? ""}</span>
          </div>
        </div></>:<></>}

        {modalData.order_item_type !=="Supplement" && modalData.order_item_type !=="Bundle" ? <> 
        <div className="row">
          
          <div className="form-group col">
            <label htmlFor="lab_cost_notes">Lab Cost Notes</label>
            <div className="input-group">
              <textarea
                value={modalData?.lab_cost_notes ?? ""}
                onChange={this.handleChangeOperations}
                id="lab_cost_notes"
                name="lab_cost_notes"
                placeholder="lab Cost Notes"
                readOnly={!newUser && !editables.lab_cost_notes}
                className={"form-control"}
              />
              {this.renderPencilIcon("lab_cost_notes")}
            </div>
            <span className="help-block">{this.state.labCostNotesErrorMessage ?? ""}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="homecare_cost_notes">Homecare Cost Notes</label>
            <div className="input-group">
              <textarea
                value={modalData?.homecare_cost_notes ?? ""}
                onChange={this.handleChangeOperations}
                id="homecare_cost_notes"
                name="homecare_cost_notes"
                placeholder="Homecare Cost Notes"
                readOnly={!newUser && !editables.homecare_cost_notes}
                className={"form-control"}
              />
              {this.renderPencilIcon("homecare_cost_notes")}
            </div>
            <span className="help-block">{this.state.homecareCostNotesErrorMessage ?? ""}</span>
          </div>
        </div>
       </>:<></>}
      </div>
    </>

    const labInformationForm = <>
    <div className="form-group col-6">
        <div className="OperationsInput">
          <div>Operations Person</div>
          <div className="infoIcon" onClick={()=>this.showOperatorAssignModal()}> <img src={icon_notification_info} style={{width:"25px", height:"25px"}}></img></div>
        </div>
        <div className="input-group user-input-container">
          <select
            name="operations_person"
            className={`form-control user-data-input ${(this.state.editOperationsPerson) ? `non-editable` : `editable`} `}
            onChange={(e) => this.handleInput(e, "operations_person")}
            // value={modalData?.operations_person?.id}
            value={modalData.operations_person ? modalData.operations_person.id : ""}
            disabled={(this.state.editOperationsPerson) ? false : true}
          >
            {
              (!modalData.operations_person || !modalData.operations_person.id) && <option key={-1} value={-1} hidden={true}>Select Operator Person</option>
            }
            {modalData.operations_person_dropdown && modalData.operations_person_dropdown.map((status, index) => (
              <option key={index} value={status.value}>
                {status.name}
              </option>
            ))
            }
          </select>
          {isEditable && !newUser && (
            <div className="input-group-append" onClick={() => this.makeEditable("editOperationsPerson")}>
              <i className="input-group-text fa fa-pencil"></i>
            </div>
          )}
        </div>
      </div>
      <div className="form-group-container">
        <div className="form-group col-6">
          <label>Lab</label>
          <div className="input-group user-input-container">
            <select
              name="order-status"
              className={`form-control user-data-input ${(this.state.editLab) ? `non-editable` : `editable`} `}
              onChange={(e) => this.handleInput(e, "laboratory")}
              value={modalData.laboratory}
              disabled={(this.state.editLab) ? false : true}
            >
              {this.state.labtListFormatted && this.state.labtListFormatted.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))
              }
            </select>
            {isEditable && !newUser && (
              <div className="input-group-append" onClick={() => this.makeEditable("editLab")}>
                <i className="input-group-text fa fa-pencil"></i>
              </div>
            )}
          </div>
        </div>
        {/* <div className="form-group">
          <label htmlFor="home_care_id">Homecare Service</label>
          <div className="input-group user-input-container">
            <Dropdown
              value={modalData?.home_care_id}
              onChange={(e, value) => {
                this.setState({
                  modalData: {
                    ...this.state.modalData,
                    home_care_id: value.value
                  },
                  updatedOrder: {
                    ...this.state.updatedOrder,
                    home_care_id: value.value
                  }
                })
              }}
              id="home_care_id"
              name="home_care_id"
              placeholder="Homecare Service"
              search
              selection
              options={this.getHomecareServiceOptions() ?? []}
              disabled={(this.state.editHomecareService || newUser) ? false : true}
              className={`form-control user-data-input ${(this.state.editHomecareService || newUser) ? `non-editable` : `editable`} `}
            />
            {!newUser && (
              <div className="input-group-append" onClick={() => this.makeEditable("editHomecareService")}>
                <i className="input-group-text fa fa-pencil"></i>
              </div>
            )}
          </div>
        </div> */}
      </div>
      <div className="form-group mx-3">
        <label>Lab Information</label>
          <textarea
            value={this.generateLabInformation(modalData) || ""}
            id="lab_information"
            name="lab_information"
            className="form-control"
            rows="10"
            readOnly
          ></textarea>
        {!newUser && operationsInternalForm}
      </div>
    </>;

     const orderStatusInformation = <>
       <OrderStatusLogsTable orderId={modalData.id}/>
     </>
   
    const tabData = [
      {
        tabTitle: 'Order Info',
        tabForm: orderInfoForm,
        eventKey: 'orderInfo'
      },
      {
        tabTitle: 'User Info',
        tabForm: personalForm,
        eventKey: 'userInfo',
        // disabled: newUser ? true : false
      },
      {
        tabTitle: 'Package',
        tabForm: activityForm,
        eventKey: 'package',
        // disabled: newUser ? true : false
      },
      // {
      //   tabTitle: 'Pricing',
      //   tabForm: pricingForm,
      //   eventKey: 'pricing',
      //   // disabled: newUser ? true : false
      // },
      {
        tabTitle: "Lab Info & Operator",
        tabForm: labInformationForm,
        eventKey: "lab_information",
      },
      {
        tabTitle: "Order Status",
        tabForm: orderStatusInformation,
        eventKey: "order_status_logs",
      }
    ];

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div>
        <div className="page-header">

          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.handleSearchEnter}
              >
                Search
              </button>
            </div>
            <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "Active Orders" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Active Orders
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("upcoming")}
                  >
                    {showItems === "Upcoming Orders" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Upcoming Orders
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div>
            {/* userType === "nutritionist" ||
              <div className="action-item">
                <button
                  className="btn button-blue btn-md new-user"
                  onClick={this.createNewOrder}
                >
                  <i className="fa fa-plus-circle"></i>New Order
                </button>
              </div>
              */
            }
          </div>
        </div>
        <div style={{ width: "50%", marginBottom: "50px" }}>
          <div className="row">
            <div className="col-6">
              <SelectItemType
                ItemTypes={ITEM_TYPE_OPTIONS ?? []}
                onItemTypeSelect={(itemTypeId) => this.handleItemType(itemTypeId)}
                selectedItemType={selectedItemType}
              />
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={
              userType === "admin" || userType === "crm"
                ? this.columns
                : this.staffColumns
            }
            data={finalList}
            sortServer
            // onSort={this.customSort}
            onSort={this.handleSort}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={this.state.totalRows || 0}
            paginationPerPage={this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // paginationTotalRows={this.state.totalPage}
            // onChangePage={this.onOrderPageChange}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + this.state.totalRows + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          // expandableRows={
          //   userType === "admin" || userType === "crm" ? true : false
          // }
          // expandableRowsComponent={
          //   <DetailedView showReschedule={this.showReschedule} />
          // }
          // expandOnRowClicked
          conditionalRowStyles={[
           {
              when: this.isTableRowColorSchemeApplicable,
              style: this.getTableRowColorScheme,
            },
          ]}
          />
        </Card>
        <ModalPopup showModal={this.state.showModal} 
          cancelOrder={() => this.showCancelConfirm(this.state.modalData, true)} 
          cancelOrderWithoutNotification={() => this.showCancelConfirm(this.state.modalData)}
          cancelRefundOrder={()=>this.showCancelRefund(this.state.modalData)}
          tabData={tabData} key={this.state.key} 
          saveUserData={() => {
            this.setState({ isSaving: true }, () => this.saveUserData());
          }} 
          hideModal={this.hideModal} 
          title={this.modalData && this.modalData.id ? this.modalData.id : ''} 
          isEditable={isEditable}
          isSaving={this.state.isSaving ?? false}
          />
        {/* <div className="user-input-container">
          <Modal
            show={this.state.showModal}
            onHide={this.hideModal}
            id="userDataModal"
            size="lg"
            className="user-data-modal">
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="user-title">{this.state.userName}</div>
                <button className="btn button-grey btn-md user-data-save" onClick={this.saveUserData}>Save</button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={(k) => this.setState({ key: k })}
                className="mb-3"
              >
                <Tab eventKey="orderInfo" title="Order Info">
                  {orderInfoForm}
                </Tab>
                <Tab eventKey="userInfo" title="User Info">
                  {personalForm}
                </Tab>
                <Tab eventKey="package" title="Package">
                  {activityForm}
                </Tab>
                <Tab eventKey="pricing" title="Pricing">
                  {pricingForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div> */}
        <Modal show={this.state.showAssignModal} onHide={this.hideAssignModal}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Wellbeing coach</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Select Wellbeing coach *</label>
            <Dropdown
              value={this.state.nutritionistId}
              className="form-control "
              placeholder="Select wellbeing coach"
              onChange={(event, value) => {
                this.setState({ nutritionistId: value.value });
              }}
              search
              selection
              options={
                showAssignModal
                  ? this.newFormattedList(
                    this.state.nutritionistListFormatted,
                    this.state.nutritionistId
                  )
                  : this.state.categoryListFormatted
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.nutritionistId ? false : true}
              variant="primary"
              onClick={this.assignNutritionist}
            >
              Assign
            </button>
            <Button variant="secondary" onClick={this.hideAssignModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showStatusConfirm}
          onHide={this.hideStatusConfirm}
        >
          <Modal.Header closeButton>
            <Modal.Title>Change Order Status</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to change the status?</Modal.Body>
          <Modal.Footer>
            <button
              onClick={() =>{
                this.handleStatusChange(
                  this.state.newValue,
                  this.state.confirmItem
                )

              }}
              className="btn button button-green"
            >
              Change
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideStatusConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        
        <Modal
          show={this.state.showCancelConfirm || this.state.showCancelRefund}
          onHide={this.state.showCancelRefund?this.hideCancelRefund:this.hideCancelConfirm}
        >
          <Modal.Header closeButton  onClick={()=>{this.setState({"refundNotes":"","is_refund_and_cancel_notify":false,"cancelReason":"","cancelNotes":""})}} >
            <Modal.Title> {this.state.showCancelRefund?"Refund & Cancel order":"Cancel Order"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.orderContentType == "PA"
              ? "This is a clubbed order. Please choose which one you want to cancel."
              : this.state.orderBatchId != null &&
                this.state.orderBatchId != this.state.orderId
                ? "This order is part of a Susbcription package. Cancelling this order will cancel the whole subscription. Are you sure you want to cancel this subscription?"
                : this.state.packageType == "subscription" &&
                  this.state.orderContentType != "A"
                  ? "This order is part of a Susbcription package. Cancelling this order will cancel the whole subscription. Are you sure you want to cancel this subscription?"
                  : "Are you sure you want to cancel this order?"}
                  {this.state.showCancelRefund ?"Please mention the reason and  notes below *":"" }
               {this.state.showCancelRefund?<>
                <div className="row">
            <div className="form-group col">
            <div className="input-group">
              <Dropdown
                value={this.state.cancelReason ?? ""}
                onChange={(event,value)=>this.setState({cancelReason: value.value,dropdownOpen:false})}
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                name="cancelReason"
                onClick={() => this.setState({dropdownOpen:true})}
                open={this.state.dropdownOpen}
                className={
                  "form-control" + (this.state.cancelError ? " has-error" : "")
                }
              />
              
            </div>
            <span className="help-block">{this.state.cancelError}</span>
              </div>
              </div>
               
              <div classsName="row">
               <div className="form-group col">
              <textarea
                value={this.state.refundNotes ?? ""}
                onChange={(event) => {this.setState({refundNotes:event.target.value,refundError:""});}}
                id="refundNotes"
                name="refundNotes"
                placeholder="Refund Notes"
                className="form-control py-2"
              ></textarea>
             <div className="row mt-2 ml-1"> <span className="help-block">{this.state.refundError}</span></div>
             </div>
            </div></>:<><div className="row">
            <div className="form-group col">
            <div className="input-group">
              <Dropdown
                value={this.state.cancelReason ?? ""}
                onChange={(event,value)=>this.setState({cancelReason: value.value,dropdownOpen:false})}
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                name="cancelReason"
                onClick={() => this.setState({dropdownOpen:true})}
                open={this.state.dropdownOpen}
                className={
                  "form-control" + (this.state.cancelError ? " has-error" : "")
                }
              />
              
            </div>
            <span className="help-block">{this.state.cancelError}</span>
              </div>
              </div>

            <div classsName="row">
            <div className="form-group col">
            <textarea
                value={this.state.cancelNotes ?? ""}
                onChange={(event) => {this.setState({cancelNotes:event.target.value});}}
                id="cancelNotes"
                name="cancelNotes"
                placeholder="Cancel Notes"
                className="form-control py-2"
              ></textarea>
             <span className="help-block">{this.state.refundError}</span>
              </div>

              </div></>}
          </Modal.Body>

          {this.state.orderContentType == "PA" ? (
            <Modal.Footer>
              <button
                onClick={() => this.cancelOrder(this.state.orderId, "addon")}
                className="btn button btn-danger"
              >
              Cancel Addon only
              </button>
              <button
                onClick={() =>
                  this.cancelOrder(this.state.orderId, "subscription")
                }
                className="btn button btn-danger"
              >
                Cancel Package/Subscription only
              </button>
              <button
                onClick={() => this.cancelOrder(this.state.orderId, "both")}
                className="btn button btn-danger"
              >
                Cancel Both
              </button>
              <button
                className="btn btn-secondary button"
                onClick={this.hideCancelConfirm}
              >
                Exit
              </button>
            </Modal.Footer>
          ) : (
            <Modal.Footer>
              {this.state.showCancelRefund? 
                                 <div className="form-group-container-checkbox">
                            
                                   <input
                                    checked={this.state.is_refund_and_cancel_notify}
                                    onChange={(event)=>{this.setState({is_refund_and_cancel_notify:!this.state.is_refund_and_cancel_notify})}}
                                   
                                    type="checkbox"
                                    id="default-check"
                                     /> &nbsp; Notify Client?
                                     
                                     </div>
                                  
                                   
                            
                                 :<></>}
              <button
                onClick={() =>{if(this.state.showCancelRefund){
                   if(this.RefundNotesValidation(this.state.cancelReason,this.state.refundNotes))
                   {this.cancelOrder(this.state.orderId)}
                }
                else{
                  if(this.cancelNotesValidation(this.state.cancelReason,this.state.cancelNotes)){
                   this.cancelOrder(this.state.orderId)
                  }
                  }
                  }
                }
                className="btn button btn-danger"
              >
                {this.state.showCancelRefund? "Refund & Cancel" : "Cancel Order"}
              </button>
              <button
                className="btn btn-secondary button"
                onClick={()=>{if(this.state.showCancelRefund)
                  {
                    this.setState({"refundNotes":"","is_refund_and_cancel_notify":false});
                    
                  this.hideCancelRefund();
                  }
                  else{
                    
                    this.hideCancelConfirm();
                  }}
                  }
              >
                Exit
              </button>
            </Modal.Footer>
          )}
        </Modal>
        <Modal show={this.state.showReschedule} onHide={this.hideReschedule}>
          <Modal.Header closeButton>
            <Modal.Title>Reschedule Pickup</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row">
              <div className="col-6 form-group">
                <label>Pickup Date *</label>
                <input
                  name="pickupDate"
                  type="date"
                  placeholder="Rollout Date"
                  min={this.state.startDate}
                  className={
                    "form-control " +
                    ((validation.pickupDate.isInvalid ||
                      this.customValidator.pickupDate.message) &&
                      "has-error")
                  }
                  onChange={this.handleChange}
                  value={this.state.pickupDate}
                ></input>
                <span className="help-block">
                  {validation.pickupDate.message
                    ? validation.pickupDate.message
                    : this.customValidator.pickupDate.message}
                </span>
              </div>
              <div className="col-6 form-group">
                <label>Pickup Time *</label>
                <select
                  name="pickupTime"
                  className={
                    "form-control " +
                    ((validation.pickupTime.isInvalid ||  
                      this.customValidator.pickupTime.message) && "has-error")
                  }
                  value={pickupTime}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Select time
                  </option>
                  {timeSlots.map((time, index) => (
                    <option key={index} value={time.timeslot}>
                      {time.timeslot}
                    </option>
                  ))}
                </select>
                <span className="help-block">
                  {validation.pickupTime.message?validation.pickupTime.message 
                    : this.customValidator.pickupTime.message}
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              variant="primary"
              onClick={this.reschedulePickup}
            >
              Submit
            </button>
            <Button variant="secondary" onClick={this.hideReschedule}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showLabAssignModal} onHide={this.hideLabAssignModal}>
          <Modal.Header closeButton>
            <Modal.Title>Assign Lab</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Select Lab *</label>
            <Dropdown
              value={this.state.labId}
              className="form-control "
              placeholder="Select Lab"
              onChange={(event, value) => {
                this.setState({ labId: value.value });
              }}
              search
              selection
              options={
                showLabAssignModal ?
                  this.newFormattedLabList(
                    this.state.labtListFormatted,
                    this.state.labId
                  ) : this.state.categoryListFormatted
              }
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.labId ? false : true}
              variant="primary"
              onClick={this.assignLab}
            >
              Assign
            </button>
            <Button variant="secondary" onClick={this.hideLabAssignModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showNoteAssignModal} onHide={this.hideNoteAssignModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Note *</label>

                <input
                  type="text"
                  name="order_notes"
                  className={
                    "form-control "
                  }
                  placeholder="Add note"
                  onChange={this.handleChange}
                  value={this.state.order_notes}
                ></input>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.order_notes ? false : true}
              variant="primary"
              onClick={this.addNotes}
            >
              Add Note
            </button>
            <Button variant="secondary" onClick={this.hideNoteAssignModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirmTime} onHide={this.hideConfirmTime}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row">
              <div className="col-6 form-group">
                <label>Confirm Date *</label>
                <input
                  name="confirmDate"
                  type="date"
                  placeholder="Rollout Date"
                  min={this.state.startDate}
                  className={
                    "form-control " +
                    ((confirmvalidation.confirmDate.isInvalid ||
                      this.customConfirmValidator.confirmDate.message) &&
                      "has-error")
                  }
                  onChange={this.handleChange}
                  value={this.state.confirmDate}
                ></input>
                <span className="help-block">
                  {confirmvalidation.confirmDate.message
                    ? confirmvalidation.confirmDate.message
                    : this.customConfirmValidator.confirmDate.message}
                </span>
              </div>
              <div className="col-6 form-group">
                <label>Confirm Time *</label>
                {/* <select
                  name="confirmTime"
                  className={
                    "form-control " +
                    (confirmvalidation.confirmTime.isInvalid && "has-error")
                  }
                  value={confirmTime}
                  onChange={this.handleChange}
                >
                  <option value="" disabled selected>
                    Select time
                  </option>
                  {timeSlots.map((time, index) => (
                    <option key={index} value={time.timeslot}>
                      {time.timeslot}
                    </option>
                  ))}
                </select> */}
                <input
                  name="confirmTime"
                  type="time"
                  placeholder="Rollout TIme"
                  min={this.state.confirmTime}
                  className={
                    "form-control " +
                    ((confirmvalidation.confirmTime.isInvalid ||  this.customConfirmValidator.confirmTime.message)  &&
                      "has-error")
                  }
                  onChange={this.handleChange}
                  value={this.state.confirmTime}
                ></input>
                <span className="help-block">
                  {confirmvalidation.confirmTime.message? confirmvalidation.confirmTime.message
                    : this.customConfirmValidator.confirmTime.message}
                </span>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              variant="primary"
              onClick={this.confirmTime}
            >
              Submit
            </button>
            <Button variant="secondary" onClick={this.hideConfirmTime}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showOperatorAssignModal} onHide={this.hideOperatorAssignModal}>
          <Modal.Header closeButton style={{border:"unset"}}>
          </Modal.Header>
          <Modal.Body>
            <div className="InfoDiv" style={{textAlign:"center"}}>
              <div classsName="infoButton" style={{padding:"10px"}}><img src={icon_notification_info}/></div>
              <h6>Assign the Operator that is going to
                be working on this ticket </h6>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="sm"
          show={this.state.isrescheduleDateConfirmPopup}
         
        >
          <Modal.Header >
            <Modal.Title>Confirm Reschedule Date  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.calculateDifference(this.state.confirmDate)=="same"?<>Is it okay the Reschedule date is today?</>:this.calculateDifference(this.state.confirmDate)>0?<>Are you sure you want to update to this date? This reschedule is {this.calculateDifference(this.state.confirmDate)+1} days from now!</>
         :this.calculateDifference(this.state.confirmDate)==0?<>Are you sure you want to update to this date? This reschedule is {this.calculateDifference(this.state.confirmDate)+1} day from now!</>
         :this.calculateDifference(this.state.confirmDate)==-1?<>Are you sure you want to update to this date? This reschedule is  {Math.abs(this.calculateDifference(this.state.confirmDate))} day past from now !</>
         :<>Are you sure you want to update to this date? This reschedule is  {Math.abs(this.calculateDifference(this.state.confirmDate))} days past from now !</>
              }
          </Modal.Body>
          <Modal.Footer>
          <button
              onClick={() => {
              //  this.rescheduleDateConfirm(this.state.confirmDate);
                this.hideRescheduleDateModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Confirm
            </button>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.cancelRescheduleDate}
            >
              Cancel
            </button>
            
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

// function DetailedView(props) {
//   const data = props.data;
//   return (
//     <div className="detailed-view">
//       <h5>Order Details</h5>
//       {data.address && (
//         <p className="mb-1">
//           <b>Address : </b>
//           {data.address
//             ? (data.address.building_apt && data.address.building_apt !== ""
//               ? data.address.building_apt + ", "
//               : "") + (data.address.address_line1 && data.address.address_line1 !== ""
//                 ? data.address.address_line1 + ", "
//                 : "") +
//             (data.address.address_line2 && data.address.address_line2 !== ""
//               ? data.address.address_line2 + ", "
//               : "") +
//             (data.address.city && data.address.city !== "" ? data.address.city + ", " : "") +
//             (data.address.post_code && data.address.post_code !== "" ? data.address.post_code : "") +
//             " (" +
//             (data.address.label && data.address.label !== "" ? data.address.label : "") +
//             ")"
//             : null}
//         </p>
//       )}
//       {data.specimen_collection_date && (
//         <p className="mb-1">
//           <b>Pickup Date & Time : </b>
//           {moment(data.specimen_collection_date).format("DD/MM/YYYY") +
//             ", " +
//             data.specimen_collection_timeslot}

//           {data.order_status === "CREATED" ? (
//             <EditIcon
//               className="edit-icon"
//               onClick={() => props.showReschedule(data.id)}
//             ></EditIcon>
//           ) : (
//             ""
//           )}
//         </p>
//       )}

//       <p className="mb-1">
//         <b>Total Amount : </b> AED {data.order_price}
//       </p>

//       {data.package && !data.package_type ? (
//         <>
//           <p className="mb-1">
//             <b>Package : </b>{" "}
//             {data.package.is_active
//               ? data.package.subscription_name
//               : data.package.subscription_name + " (Archived)"}
//           </p>

//           <p className="mb-1">
//             <b>Purchased Items : </b>
//             {data.order_items.map((item, index) => (
//               <span key={index}>
//                 {item.name + (index < data.order_items.length - 1 ? ", " : "")}
//               </span>
//             ))}
//           </p>
//         </>
//       ) : (
//         data.custom_package && data.package_type ? (
//           <>
//             <p className="mb-1">
//               <b>Package : </b>{" "}
//               {data.custom_package.name
//                 ? data.custom_package.name
//                 : ""}
//             </p>
//           </>
//         ) : ("")
//       )}

//       <p className="mb-1">
//         <b>Order Type : </b>
//         {data.order_type === "one-time" ? "One Time" : "Subscription"}
//       </p>

//       {data.add_on_package && data.add_on_package.id ? (
//         <>
//           <h5 className="mt-4">Add-Ons</h5>
//           <div className="addon-box">
//             <h6 className="heading">Wellbeing coach subscription</h6>
//             <p className="mb-1">
//               <b>Validity : </b>{" "}
//               {data.add_on_package.addon_subscription_validity} Months
//             </p>
//             <p className="mb-1">
//               <b>Price : </b> AED {data.add_on_package.addon_subscription_price}
//             </p>
//           </div>
//         </>
//       ) : (
//         ""
//       )}
//       {data.transaction !== undefined && data.transaction.length > 0 ? (
//         data.transaction[0].transaction_id &&
//           data.transaction[0].transaction_reference ? (
//           <>
//             <p className="mb-1">
//               <b>Cart ID : </b>
//               {data.transaction[0].transaction_id}
//             </p>
//             <p className="mb-1">
//               <b>Service Reference : </b>
//               {data.transaction[0].transaction_reference}
//             </p>
//           </>
//         ) : (
//           ""
//         )
//       ) : (
//         ""
//       )}

//       {data.transaction && data.transaction.length > 0 && data.transaction[0].coupon_applied !== undefined ? (
//         data.transaction[0].coupon_name ? (
//           <>
//             <p className="mb-1">
//               <b>Coupon : </b>
//               {data.transaction[0].coupon_name} ({data.transaction[0].coupon_value} {data.transaction[0].discount_type == 'Percentage' ? '%' : ''})
//             </p>
//           </>
//         ) : (
//           ""
//         )
//       ) : (
//         ""
//       )}
//     </div>
//   );
// }

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrders);