import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import Card from "react-bootstrap/Card";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import BlockIcon from "@material-ui/icons/Block";
import Visibility from "@material-ui/icons/Visibility";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { filterList } from "utils/List";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import moment from "moment";
import $ from "jquery";
import ModalPopup from "components/ModalPopup/ModalPopup";
import PhoneInput from "react-phone-input-2";
import { Dropdown } from "semantic-ui-react";
import MUIRichTextEditor from "mui-rte";
import Status from "../../components/Common/Status";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState, convertToRaw } from "draft-js";
import { formatDateTime } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import CoachCalendly from "./CoachCalendly";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import RichTextEditor from "react-rte";
import CoachCalendlyLinks from "../../components/Common/CoachCalendlyLinks"

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_NUTRITIONIST_CALENDLY_LINK = "calendly-link";

const STATUS_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];
const DEFAULT_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Active",
  },
  {
    key: false,
    value: false,
    text: "Inactive",
  },
];

const testerOptions=[
  {
    text: "YES",
    value: true,
    key: "yes",
  },
  {
    text: "NO",
    value: false,
    key: "no",
  },

];

class Nutritionist extends Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: "username",
        method: "isEmpty",
        validWhen: false,
        message: "Name is required."
      },
      {
        field: "email",
        method: "isEmpty",
        validWhen: false,
        message: "Email is required."
      },
      {
        field: "email",
        method: "isEmail",
        validWhen: true,
        message: "Email not Valid."
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "password",
        method: "isLength",
        min: 8,
        validWhen: true,
        message: "At least 8 characters are required."
      },
      {
        field: "confirm_password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      },
      {
        field: "confirm_password",
        method: "equals",
        validWhen: true,
        equalsTo: "password",
        message: "Passwords do not match."
      },
      {
        field: "coach_image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Coach Profile is required."
      },
      {
        field: "coach_image",
        type: "image",
        method: "isEmpty",
        validWhen: false,
        args: [{ min: 0 }],
        message: "Coach Profile is required."
      }
    ]);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      username: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      validation: this.validator.valid(),
      list: [],
      isLoaded: false,
      loadError: "",
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      showModal: false,
      newUser: false,
      modalData: {},
      key: "coachInfo",
      userEmail: "",
      userName: "",
      userPassword: "",
      label: "",
      address_line1: "",
      address_line2: "",
      city: "",
      building_apt: "",
      firstname: "",
      editFn: false,
      editLn: false,
      editPassword: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      editExperience: false,
      editNationality: false,
      editSpecialization: false,
      editTitle: false,
      editGender: false,
      editShortBio: false,
      msg_en: '',
      msg_ar:"",
      // overview: '',
      // overview_ar:"",
      overview:RichTextEditor.createEmptyValue(),
      overview_ar:RichTextEditor.createEmptyValue(),
      imgSrc: '',
      coach_image: '',
      errors: {},
      editables: {},
      statusOptions: [
        {
          text: "Active",
          value: true,
          key: "active",
        },
        {
          text: "Inactive",
          value: false,
          key: "inactive",
        },
      ],
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      calendlyLinkDetail:{},
      emailErrorMessage:"",
      emailErrorNotify:false,
      is_test_coach:false,
      calendlyLinks:[]
    };

    this.columns = [
      {
        name: "Coach ID",
        selector: "id",
        maxWidth: "125px",
        sortable: true,
        sortField: "id",
      },
      {
        name: "Name",
        selector: "username",
        sortable: true,
        sortField: "username",
        wrap: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        sortField: "email",
        wrap: true,
      },
      {
        name: "Joining Date",
        selector: "date_joined",
        sortable: true,
        sortField: "date_joined",
        wrap: true,
        format: row => row.date_joined ? formatDateTime(row.date_joined) : '-'
      },
      {
        name: "Last Login",
        selector: "last_login",
        sortable: true,
        sortField: "last_login",
        wrap: true,
        format: row => row.last_login ? formatDateTime(row.last_login) : "-"
      },
      {
        name: "Status",
        center: true,
        selector: "is_active",
        sortable: true,
        sortField: "is_active",
        cell: row => <Status isActive={row.is_active} />
      },
      {
        name: "View",
        selector: 'id',
        format: (row) => (
          <button
            className="btn px-5"
            style={{ backgroundColor: "#CEE741" }}
            onClick={(e) => this.showModal(row)}
          >
            View
          </button>
          // <button
          //   className="btn button-blue btn-md"
          //   onClick={(e) => {
          //     this.showModal(row);
          //   }}
          // >
          //   View
          // </button>
        ),
      },
    ];

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.createNutrionist = this.createNutrionist.bind(this);
    this.clearForm = this.clearForm.bind(this);
    // this.getNutritionistList = this.getNutritionistList.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    // this.saveOverviewEng = this.saveOverviewEng.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.onUserPageChange = this.onUserPageChange.bind(this);
    this.selecteImage = this.selecteImage.bind(this);
    // this.customSort = this.customSort.bind(this);
  }

  componentDidMount() {
    // this.getNutritionistList();
    this.fetchAclUser();
    this.fetchNutritionists();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.WELLBEING_COACHES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  selecteImage(e) {
    if (e.target.files && e.target.files.length) {
      let coach_image = e.target.files[0];
      this.setState({ coach_image });
      let reader = new FileReader();

      reader.onloadend = function () {
        this.setState({
          imgSrc: [reader.result],
          coachImageErrorMessage: '',
          modelData: {
            ...this.state.modalData,
            imgSrc: [reader.result],
          }
        });
      }.bind(this);
      reader.readAsDataURL(coach_image);
    }
  }


  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });
  }

  handleCalendlyInput=({currentTarget:input})=>{
    const calendlyLinkDetail = {...this.state.calendlyLinkDetail};
    calendlyLinkDetail[input.name]= input.value;
    this.setState({calendlyLinkDetail});
  }
  

  createNutrionist() {
    const validation = this.validator.validate(this.state);

    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true
      });
      API.post("nutritionist/", {
        email: this.state.email,
        password: this.state.password,
        username: this.state.name,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
      })
        .then(response => {
          this.clearForm();
          // this.getNutritionistList();
          this.fetchNutritionists();
          this.props.showNotificationMessage({
            notificationMessage: "Wellbeing coach created successfully.",
            successMessage: true,
            showNotification: true,
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on saving nutritionist", error);
          let message = "";
          if (error.response.status === 400) {
            message = "Account already exists.";
          } else message = "Some error has occured. Please try again.Cause of the issue " + error;

          this.props.showNotificationMessage({
            notificationMessage: message,
            successMessage: false,
            showNotification: true,
            isLoading: false
          });
        });
    }
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      name: "",
      email: "",
      password: "",
      confirm_password: "",
      edit_id: null,
      editFn: false,
      editLn: false,
      editPassword: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      editExperience: false,
      editNationality: false,
      editSpecialization: false,
      editTitle: false,
      editShortBio: false,
      msg_en: '',
      msg_ar:"",
      // overview: '',
      // overview_ar:"",
      imgSrc: '',
      coach_image: '',
      calendlyLinkDetail:{},
      errors:{},
      emailErrorMessage:"",
      emailErrorNotify:false,
      overview:RichTextEditor.createEmptyValue(),
      overview_ar:RichTextEditor.createEmptyValue(),
      is_test_coach:false
    });
  }

  hideModal = () => {
    this.setState({
      showModal: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      editPassword: false,
      key: "personal",
      newUser: false,
      modalData: {},
      fnameErrorMessage: '',
      emailErrorMessage: '',
      lnameErrorMessage: '',
      specializationErrorMessage: '',
      genderErrorMessage: '',
      passwordErrorMsg: '',
      nationalityErrorMessage: '',
      expeienceErrorMessage: '',
      titleErrorMessage: '',
      shortBioErrorMessage: '',
      coachImageErrorMessage: '',
      contactNoErrorMessage: '',
      msg_en: '',
      msg_ar:"",
      // overview: '',
      // overview_ar:"",
      imgSrc: '',
      coach_image: '',
      errors: {},
      editables: {},
      calendlyLinkDetail:{},
      emailErrorMessage:"",
      emailErrorNotify:false,
      overview:RichTextEditor.createEmptyValue(),
      overview_ar:RichTextEditor.createEmptyValue(),
      is_test_coach:false
    });
    // this.clearForm();
  };

  createNewUser = () => {
    const modalData = {...this.state.modalData, is_active: false};
    this.setState({ showModal: true, newUser: true, modalData,  
      overview:RichTextEditor.createEmptyValue(),
      overview_ar:RichTextEditor.createEmptyValue(),calendlyLinks:[] }, () => {
      this.showModal({});
    });
  };

  

  showModal = (row) => {
    console.log({ row });
    if (this.state.newUser) {
      if ($(".modal-backdrop").length > 0 && this.state.showModal) {
        setTimeout(function () {
          $(".modal-backdrop").addClass("modal-backdrop-updated");
        });
      }
    } else {
      const name = row.username;
      const email = row.email;
      // const overview_obj =
      //   row.nutritionistprofile && row.nutritionistprofile.short_bio != null
      //     ? stateFromHTML(row.nutritionistprofile.short_bio)
      //     : stateFromHTML("");
      //     const overview_ar_obj =
      //     row.nutritionistprofile && row.nutritionistprofile.short_bio_ar != null
      //       ? stateFromHTML(row.nutritionistprofile.short_bio_ar)
      //       : stateFromHTML("");
      const overview_obj=    row.nutritionistprofile && row.nutritionistprofile.short_bio != null
          ?  RichTextEditor.createValueFromString(
            row.nutritionistprofile.short_bio?? "",
            "html"
          )
          : RichTextEditor.createValueFromString("","html");
          const overview_ar_obj=    row.nutritionistprofile && row.nutritionistprofile.short_bio != null
          ?  RichTextEditor.createValueFromString(
            row.nutritionistprofile.short_bio_ar?? "",
            "html"
          )
          : RichTextEditor.createValueFromString("","html");
      const imgSrc = row.nutritionistprofile && row.nutritionistprofile.profile_picture ? row.nutritionistprofile.profile_picture : '';
      row['imgSrc'] = imgSrc;
      if (!row.first_name && !row.last_name && name) {
        const nameArray = name.split(" ");
        row['first_name'] = nameArray && nameArray.length > 0 ? nameArray[0] : "";
        row['last_name'] = nameArray && nameArray.length > 1 ? nameArray[1] : "";
      }
     
      this.setState(
        {
          showModal: true,
          modalData: row,
          userName: name,
          userEmail: email,
          // overview: JSON.stringify(convertToRaw(overview_obj)),
          // overview_ar:JSON.stringify(convertToRaw(overview_ar_obj)),
          overview: overview_obj,
          overview_ar:overview_ar_obj,
          msg_en:
          row.nutritionistprofile && row.nutritionistprofile.short_bio != null
              ? row.nutritionistprofile.short_bio
              : stateToHTML(ContentState.createFromText("")),
          msg_ar:
          row.nutritionistprofile && row.nutritionistprofile.short_bio_ar != null
                  ? row.nutritionistprofile.short_bio_ar
                  : stateToHTML(ContentState.createFromText("")),
          imgSrc: imgSrc,
          is_test_coach:row.is_test_coach
        },
        () => {
          if ($(".modal-backdrop").length > 0 && this.state.showModal) {
            setTimeout(function () {
              $(".modal-backdrop").addClass("modal-backdrop-updated");
            });
          }
        }
      );
    }
  };

  makeEditable = (key) => {
    this.setState({
      [key]: true,
    });
  };


  numberCheck = async (value, data) => {
    if (value.length > 4) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          phone_no: `+${data.dialCode}` + value.slice(data.dialCode.length),
        },
        contactNoErrorMessage: ''
      });
    }
  };

  handleInput = (e, data) => {
    if (data === "gender") {
      let val;
      if (e.target.textContent === "Male") {
        val = "M";
      } else {
        val = "F";
      }
      this.setState({
        genderErrorMessage: '',
        modalData: {
          ...this.state.modalData,
          [data]: val,
          nutritionistprofile: {
            ...this.state.modalData.nutritionistprofile,
            [data]: val,
          },
        },
      }, () => {
        console.log(this.state.modalData, val, data)
      });
    } else if (data === "specialization" || data === "specialization_ar" || data === "job_title" || data === "job_title_ar"|| data === "nationality" || data === "nationality_ar"|| data === "short_bio"  || data ==="short_bio_ar" || data === "years_of_exp" || data==="language" || data==="language_ar" 
    || data==="degree" || data==="degree_ar" || data==="institution" || data==="institution_ar" || data==="training_and_certificate" || data==="training_and_certificate_ar") {

      this.setState({
        modalData: {
          ...this.state.modalData,
          nutritionistprofile: {
            ...this.state.modalData.nutritionistprofile,
            [data]: e.target.value,
          },
        },
        specializationErrorMessage: '',
        titleErrorMessage: '',
        nationalityErrorMessage: '',
        shortBioErrorMessage: '',
        expeienceErrorMessage: '',
      });
    } else if (data === "password") {
      this.setState({ passwordErrorMsg: "", userPassword: e.target.value });
    } else if (data === 'email') {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [data]: e.target.value,
        },
        emailErrorMessage: "",
        fnameErrorMessage: "",
        lnameErrorMessage: "",
        contactNoErrorMessage: "",
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [data]: e.target.value.replace(/[^\w\s]/gi, ""),
        },
        emailErrorMessage: "",
        fnameErrorMessage: "",
        lnameErrorMessage: "",
        contactNoErrorMessage: "",
      });
    }
  };

  saveOverviewEng=(data)=> {
    
    this.setState({
      overview: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
      short_bio: stateToHTML(this.event.getCurrentContent()),
      modalData: {
        ...this.state.modalData,
        nutritionistprofile: {
          ...this.state.modalData.nutritionistprofile,
          overview: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
          short_bio: stateToHTML(this.event.getCurrentContent()),
        },

      }
    });
  }

  saveOverviewArabic=(data)=>{
    this.setState({
      overview_ar: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
      short_bio_ar: stateToHTML(this.event.getCurrentContent()),
      modalData: {
        ...this.state.modalData,
        nutritionistprofile: {
          ...this.state.modalData.nutritionistprofile,
          overview_ar: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
          short_bio_ar: stateToHTML(this.event.getCurrentContent()),
        },

      }
    });
  }

  handleRichText=(value,name)=>{
    this.setState({[name]: value });
  }

  validate = () => {
    const errors = {};
    const { modalData = {}, newUser, userPassword } = this.state;
    const { first_name, last_name, email } = modalData;
    const nutritionistprofile = modalData.nutritionistprofile || {};
    const {calendlyLink='', isActive, isDefault,isNonCalendlyLink} = this.state.calendlyLinkDetail && this.state.calendlyLinkDetail || {} ;

    if (!first_name || first_name.trim() === "")
      errors.first_name = "First Name is a required field!";
    
    if (!last_name || last_name.trim() === "")
      errors.last_name = "Last Name is a required field!";

    const PATTERN_EMAIL = new RegExp(/^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/);
    if (!email || email.trim() === "")
      errors.email = "Email is a required field!";
    else if (!PATTERN_EMAIL.test(email))
      errors.email = "Invalid email address";

    if (newUser && !userPassword && userPassword.trim() === "")
      errors.password = "Password is a required field!";
    else if (newUser && userPassword && userPassword.length < 8)
      errors.password = "Invalid Password! has to be minimum 8 characters long.";

    if (nutritionistprofile.specialization && nutritionistprofile.specialization.length > 500)
      errors.specialization = "Specialization can not take more then 500 characters!";

    if (nutritionistprofile.job_title && nutritionistprofile.job_title.length > 50)
      errors.job_title = "Title can not take more then 50 characters!";
    
    if (nutritionistprofile.nationality && nutritionistprofile.nationality.length > 50)
      errors.nationality = "Nationality can not take more then 50 characters!";

    if (nutritionistprofile.years_of_exp && Number.isNaN(Number(nutritionistprofile.years_of_exp)))
      errors.years_of_exp = "Invalid Experience! should be a valid number.";
    else if (Number(nutritionistprofile.years_of_exp) < 0)
      errors.years_of_exp = "Invalid Experience! should be a positive number.";

      // if (!isActive) {
      //     errors.isActive = "Is Active is a required field!";
      // }
      // if(!isDefault){
      //     errors.isDefault = "Is Default is a required field!";
      // }
     

    return Object.keys(errors).length === 0 ? null : errors;
  }
  
  handleSaveCalendlyLink = async (data) => {
    const { id } = data;
    const { calendlyLinks = [] } = this.state;
    if (calendlyLinks && calendlyLinks.length > 0) {
      const updatedLinks = calendlyLinks.map((link) => {
        const updatedLink = { ...link, userId: id };
        return updatedLink
      })

      try {

        const apiOptions = {
          method: "post",
          url: `${BASE_URL_NUTRITIONIST_CALENDLY_LINK}`,
          data: updatedLinks,
        };
        const response = await PhoenixAPI.request(apiOptions);
        return response;
      }
      catch (error) {
        this.showErrorNotification("Error in Saving Nutritionist Calendly Link!");
      }
    }
  }
  saveUserData = async() => {
    const { modalData, newUser, userPassword , overview, overview_ar} = this.state;

    const errors = this.validate();
    this.setState({errors: errors || {}});

    if (errors) {
      this.setState({emailErrorMessage:"",emailErrorNotify:false})
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }


    if (newUser) {
      let payload;
      // let pattern = new RegExp(
      //   /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
      // );
      // if (modalData.first_name === undefined)
      //   this.setState({
      //     fnameErrorMessage: "First Name is a required field!",
      //   });
      // if (modalData.last_name === undefined)
      //   this.setState({
      //     lnameErrorMessage: "Last Name is a required field!",
      //   });
      // if (userPassword.length < 8)
      //   this.setState({
      //     passwordErrorMsg:
      //       "Password has to be 8 characters long and not empty",
      //   });
      // if (!pattern.test(modalData.email))
      //   this.setState({
      //     emailErrorMessage: "Invalid email address",
      //   });
  
      // if (!modalData.nutritionistprofile || modalData.nutritionistprofile.phone_no === undefined)
      //   this.setState({
      //     contactNoErrorMessage: "Invalid phone number",
      //   });

      // if (!modalData.phone_no)
      //   this.setState({
      //     contactNoErrorMessage: "Invalid phone number",
      //   });

      // if (!modalData.nutritionistprofile || !modalData.nutritionistprofile.years_of_exp)
      //   this.setState({
      //     expeienceErrorMessage: "Experience is a required field!",
      //   });
      // if (!modalData.nutritionistprofile || !modalData.nutritionistprofile.nationality)
      //   this.setState({
      //     nationalityErrorMessage: "Nationality is a required field!",
      //   });
      // if (!modalData.nutritionistprofile || !modalData.nutritionistprofile.specialization)
      //   this.setState({
      //     specializationErrorMessage: "Specialization is a required field!",
      //   });
      // if (!modalData.nutritionistprofile || !modalData.nutritionistprofile.job_title)
      //   this.setState({
      //     titleErrorMessage: "Title is a required field!",
      //   });
      // if (!modalData.nutritionistprofile || !modalData.nutritionistprofile.gender)
      //   this.setState({
      //     genderErrorMessage: "Gender is a required field!",
      //   });
      // if (!modalData.nutritionistprofile || (!modalData.nutritionistprofile.short_bio || modalData.msg_en === "<p><br></p>"))
      //   this.setState({
      //     shortBioErrorMessage: "Short Bio is a required field!",
      //   });
      // if ((!this.state.newUser && (!modalData.imgSrc || modalData.imgSrc === "")) || this.state.imgSrc === "")
      //   this.setState({
      //     coachImageErrorMessage: "Coach Image is a required field!",
      //   });
      if (
        // modalData.first_name &&
        // modalData.email &&
        // (this.state.userPassword && !this.state.passwordErrorMsg) && 
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.phone_no || (modalData.phone_no)) &&
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.nationality) &&
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.job_title) &&
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.gender) &&
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.specialization) &&
        // (modalData.nutritionistprofile && modalData.nutritionistprofile.years_of_exp) &&
        // ((modalData.imgSrc && modalData.imgSrc != '') || this.state.coach_image)
        true
      ) {
        payload = {
          first_name: modalData.first_name,
          last_name: modalData.last_name,
          username: modalData.first_name + ' ' + modalData.last_name,
          email: modalData.email,
          phone_no: modalData.phone_no 
            ? modalData.phone_no 
            : modalData.nutritionistprofile 
              ? modalData.nutritionistprofile.phone_no || null
              : null,
          password: this.state.userPassword,
          is_active: modalData.is_active,
          is_external_coach_user:modalData.is_external_coach_user?? false,
        };
        API.post("nutritionist/", payload)
          .then((response) => {
            const data= response && response.data;
            const {message, status}=data;
            if(status===400){
              this.setState({emailErrorMessage:message,emailErrorNotify:true})
              this.showErrorNotification(message);
              
            }
            else {
              this.handleSaveCalendlyLink(data);
              this.saveTestCoach(response.data.id);
              let formdata = new FormData();
              const nutritionistprofile = modalData.nutritionistprofile || {};
              formdata.append("username", modalData.first_name + ' ' + modalData.last_name);
              formdata.append("job_title", nutritionistprofile.job_title || "");
              formdata.append("job_title_ar", nutritionistprofile.job_title_ar || "");
              formdata.append("phone_no", modalData.phone_no ? modalData.phone_no : nutritionistprofile.phone_no || null);
              formdata.append("short_bio", overview?.toString("html"));
              formdata.append("short_bio_ar",overview_ar?.toString("html"));
              formdata.append("nationality", nutritionistprofile.nationality || "");
              formdata.append("nationality_ar", nutritionistprofile.nationality_ar || "");
              formdata.append("years_of_exp", nutritionistprofile.years_of_exp || "");
              
              formdata.append("specialization", nutritionistprofile.specialization || "");
              formdata.append("specialization_ar", nutritionistprofile.specialization_ar || "");
              formdata.append("gender", nutritionistprofile.gender || null);
              if (this.state.coach_image && this.state.coach_image.name) {
                formdata.append("profile_picture", this.state.coach_image, this.state.coach_image.name);
                formdata.append("profile_picture_s3", this.state.coach_image, this.state.coach_image.name);
              }
              formdata.append("language",nutritionistprofile.language || "")
              formdata.append("language_ar",nutritionistprofile.language_ar || "")
              formdata.append("degree",nutritionistprofile.degree || "")
              formdata.append("degree_ar",nutritionistprofile.degree_ar || "")
              formdata.append("institution",nutritionistprofile.institution || "")
              formdata.append("institution_ar",nutritionistprofile.institution_ar || "")
              formdata.append("training_and_certificate",nutritionistprofile.training_and_certificate || "")
              formdata.append("training_and_certificate_ar",nutritionistprofile.training_and_certificate_ar || "")

              let apiOptions = {
                method: "patch",
                url: `nutritionist-profile/${response.data.profile_id}/`,
                data: formdata,
                headers: {
                  accept: "application/json",
                  "Accept-Language": "en-US,en;q=0.8",
                  "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
                },
              };
              API.request(apiOptions)
                .then((response) => {
                  this.hideModal();
                  // this.getNutritionistList();
                  this.fetchNutritionists();
                  this.showSuccessNotification("User Created Successfully");
                })
                .catch((err) => {
                  console.log("Error on saving nutritionist", err);
                  this.showErrorNotification("Error saving.Cause of the error is" + err);
                });
                
            }
          })
          .catch((error) => {
            console.log("Error on saving nutritionist data", error);
            if (error.response && error.response.status !== 200) {
              if (error.response.data) {
                const errors = {};
                const { email, password } = error.response.data;
                if (email && email.length !== 0)
                  errors.email = email.join(", ");
                if (password && password.length !== 0)
                  errors.password = password.join(", ");

                this.setState({ errors });
              } 
              this.showErrorNotification("Some error has occured. Cause of the error is " + error);
            }
          });
      }
    } else {
      // let pattern = new RegExp(
      //   /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
      // );
      // if (!modalData.username) {
      //   this.setState({
      //     fnameErrorMessage: "Username is a required field!",
      //   });
      // }

      // if (this.state.editPassword && userPassword.length < 8)
      //   this.setState({
      //     passwordErrorMsg:
      //       "Password has to be 8 characters long and not empty",
      //   });

      // if (!pattern.test(modalData.email)) {
      //   this.setState({
      //     emailErrorMessage: "Invalid email address",
      //   });
      // }

      // if (!modalData.phone_no && !modalData.nutritionistprofile.phone_no) {
      //   this.setState({
      //     contactNoErrorMessage: "Invalid phone number",
      //   });
      // }

      // if (!modalData.nutritionistprofile.years_of_exp) {
      //   this.setState({
      //     expeienceErrorMessage: "Experience is a required field!",
      //   });
      // }

      // if (!modalData.nutritionistprofile.nationality) {
      //   this.setState({
      //     nationalityErrorMessage: "Nationality is a required field!",
      //   });
      // }

      // if (!modalData.nutritionistprofile.specialization) {
      //   this.setState({
      //     specializationErrorMessage: "Specialization is a required field!",
      //   });
      // }

      // if (!modalData.nutritionistprofile.job_title) {
      //   this.setState({
      //     titleErrorMessage: "Title is a required field!",
      //   });
      // }

      // if (!modalData.nutritionistprofile.gender) {
      //   this.setState({
      //     genderErrorMessage: "Gender is a required field!",
      //   });
      // }

      // if (modalData.nutritionistprofile.short_bio === undefined || modalData.msg_en === "<p><br></p>") {
      //   this.setState({
      //     shortBioErrorMessage: "Short Bio is a required field!",
      //   });
      // }
      // if (!modalData.imgSrc || modalData.imgSrc === "" || this.state.imgSrc === "") {
      //   this.setState({
      //     coachImageErrorMessage: "Coach Image is a required field!",
      //   });
      // }

      if (
        // modalData.username &&
        // modalData.email &&
        // // (this.state.editPassword && this.state.userPassword) && 
        // (modalData.nutritionistprofile.phone_no || modalData.phone_no) &&
        // modalData.nutritionistprofile.nationality &&
        // modalData.nutritionistprofile.job_title &&
        // modalData.nutritionistprofile.gender &&
        // modalData.nutritionistprofile.specialization &&
        // modalData.nutritionistprofile.years_of_exp &&
        // ((modalData.imgSrc && modalData.imgSrc != '') || this.state.coach_image)
        true
      ) {
        let payload = {
          first_name: modalData.first_name,
          last_name: modalData.last_name,
          username: modalData.username,
          email: modalData.email,
          phone_no: modalData.phone_no 
            ? modalData.phone_no 
            : modalData.nutritionistprofile 
              ? modalData.nutritionistprofile.phone_no || null
              : null,
          is_active: modalData.is_active,
          is_external_coach_user:modalData.is_external_coach_user?? false,
        };
        // if (this.state.editPassword) {
        //   payload['password'] = this.state.userPassword;
        // }

        API.patch(
          "nutritionist/" + this.state.modalData.id + "/",
          payload
        )
          .then((response) => {
            // if(response && response.data){
            //   const data=response && response.data;
            //   this.handleSaveCalendlyLink(data)
            // }
            const data= response && response.data;
            const {message, status}=data;
            if(status===400){
              this.setState({emailErrorMessage:message,emailErrorNotify:true})
              this.showErrorNotification(message)
            }

          else{
            this.saveTestCoach(response.data.id);
            let formdata = new FormData();
            const nutritionistprofile = modalData.nutritionistprofile || {};
            formdata.append("username", modalData.first_name + ' ' + modalData.last_name);
            formdata.append("job_title", nutritionistprofile.job_title || "");
            formdata.append("job_title_ar", nutritionistprofile.job_title_ar || "");
            formdata.append("phone_no", modalData.phone_no ? modalData.phone_no : nutritionistprofile.phone_no || null);
            formdata.append("short_bio", overview?.toString("html"));
            formdata.append("short_bio_ar",overview_ar?.toString("html"));
            formdata.append("nationality", nutritionistprofile.nationality || "");
            formdata.append("nationality_ar", nutritionistprofile.nationality_ar || "");
            formdata.append("years_of_exp", nutritionistprofile.years_of_exp || "");

            formdata.append("specialization", nutritionistprofile.specialization || "");
            formdata.append("specialization_ar", nutritionistprofile.specialization_ar || "");
            formdata.append("gender", nutritionistprofile.gender || null);
            if (this.state.coach_image && this.state.coach_image.name) {
              formdata.append("profile_picture", this.state.coach_image, this.state.coach_image.name);
              formdata.append("profile_picture_s3", this.state.coach_image, this.state.coach_image.name);
            } 
            formdata.append("language",nutritionistprofile.language || "")
            formdata.append("language_ar",nutritionistprofile.language_ar || "")
            formdata.append("degree",nutritionistprofile.degree || "")
            formdata.append("degree_ar",nutritionistprofile.degree_ar || "")
            formdata.append("institution",nutritionistprofile.institution || "")
            formdata.append("institution_ar",nutritionistprofile.institution_ar || "")
            formdata.append("training_and_certificate",nutritionistprofile.training_and_certificate || "")
            formdata.append("training_and_certificate_ar",nutritionistprofile.training_and_certificate_ar || "")
            let apiOptions = {
              method: "patch",
              url: `nutritionist-profile/${response.data.profile_id}/`,
              data: formdata,
              headers: {
                accept: "application/json",
                "Accept-Language": "en-US,en;q=0.8",
                "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
              },
            };
            API.request(apiOptions)
              .then((response) => {
                this.hideModal();
                // this.getNutritionistList();
                this.fetchNutritionists();
                this.props.showNotificationMessage({
                  notificationMessage: "User Updated Successfully",
                  successMessage: true,
                  showNotification: true,
                });
              })
              .catch((err) => {
                console.log("Error on updating nutritionist profile", err);
                this.props.showNotificationMessage({
                  notificationMessage: "Error saving,  Cause of the error is" + err,
                  successMessage: false,
                  showNotification: true,
                });
              });
              
            }
          })
        
          .catch((error) => {
            console.log("Error on saving nutritionist profile", error);
            this.setState({
              loadError: "Some error has occured. Please try again. Cause of the error is" + error,
            });
            this.props.showNotificationMessage({
              notificationMessage: error,
              successMessage: false,
              showNotification: true,
            });
          });
        
      }
    }
  };

  saveTestCoach= async(id)=>{
    const{newUser,modalData,is_test_coach=false}=this.state;
    const payload={
      "is_test_coach":is_test_coach
    }

    try{
    await PhoenixAPI.patch(`api/v1/acl/users/coach-user/${id}?is_test_coach=${is_test_coach}`  );
    }
  catch(error){
    console.log("Error on saving nutritionist profile", error);
    this.setState({
      loadError: "Some error has occured. Please try again. Cause of the error is" + error,
    });
    this.props.showNotificationMessage({
      notificationMessage: error,
      successMessage: false,
      showNotification: true,
    });
  }
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Deactivate Wellbeing coach";
      confirmMessage = "Are you sure you want to deactivate this Wellbeing coach?";
      successMessage = "Wellbeing coach successfully deactivated.";
      confirmType = "danger";
    } else {
      confirmTitle = "Activate Wellbeing coach";
      confirmMessage = "Are you sure you want to activate this Wellbeing coach?";
      successMessage = "Wellbeing coach successfully activated.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  toggleActiveStatus(item) {
    const { successMessage, modalData } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("nutritionist/" + modalData.id + "/", {


      is_active: (modalData.is_active !== null && modalData.is_active !== undefined) ? !modalData.is_active : false
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Coach Deactivated successfully",
          successMessage: true,
          showNotification: true
        });
        this.hideModal();
        // this.getNutritionistList();
        this.fetchNutritionists();
      })
      .catch(error => {
        console.log("Updating nutritionist status", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again. Cause of the error is " + error,
          successMessage: false,
          showNotification: true
        });
      });
  }

  onUserPageChange() {

    if (this.state.nextURL) {
      let url = this.state.nextURL;
      let nextURL = '';
      let orderResonse = [];

      this.props.toggleLoading({
        isLoading: true,
      });
      API.get(url)
        .then(response => {
          nextURL = response.data && response.data.next ? response.data.next : '';
          orderResonse = response.data && response.data.results ? response.data.results : [];
          const activeList = filterList(orderResonse, "active");
          const deactivatedList = filterList(orderResonse, "archived");

          let allClient = [...this.state.list, ...orderResonse];
          let activeallList = [...this.state.activeList, ...activeList];
          let deactiveallList = [...this.state.deactivatedList, ...deactivatedList]
          let totalPage = response.data && response.data.count ? response.data.count : 0;
          this.setState({ list: allClient, nextURL: nextURL, activeList: activeallList, deactivatedList: deactiveallList, isLoaded: true, totalPage: totalPage });
          this.props.toggleLoading({
            isLoading: false
          });
        })
        .catch(error => {
          console.log("Error on fetching nutritionist", error);
          this.setState({
            loadError: "Some error has occured. Please try again"
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again. Cause of the error is " + error,
            successMessage: false,
            showNotification: true
          });
        });

    }
  }

  /*
  This is commented because now we are using fetchNutritionists function.
  getNutritionistList() {
    const { searchApplied, searchTerm } = this.state;
    let orderResonse = [];
    let nextURL = '';
    this.props.toggleLoading({
      isLoading: true
    });
    let url = "nutritionist/";
    if (this.state.sortField) {
      let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
      url = url + "?ordering=" + fieldOrder
    }
    if (this.state.searchValue && this.state.sortField) {
      url = url + "&search=" + this.state.searchValue
    }
    if (this.state.searchValue && !this.state.sortField) {
      url = url + "?search=" + this.state.searchValue
    }
    API.get(url)
      .then(response => {
        nextURL = response.data && response.data.next ? response.data.next : '';
        orderResonse = response.data && response.data.results ? response.data.results : [];
        const activeList = filterList(orderResonse, "active");
        const deactivatedList = filterList(orderResonse, "archived");
        let totalPage = response.data && response.data.count ? response.data.count : 0;
        if (this.state.searchApplied) {
          this.setState({
            results: orderResonse,
            resultCount: totalPage,
            nextURL: nextURL,
            totalPage: totalPage,
            activeList,
            deactivatedList,
            isLoaded: true,
          });
        } else {
          this.setState({
            nextURL: nextURL,
            totalPage: totalPage,
            list: orderResonse,
            activeList: activeList,
            deactivatedList: deactivatedList,
            isLoaded: true
          });
        }
        this.props.toggleLoading({
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({
          loadError: "Some error has occured. Please try again. Cause of the issue" + error
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again. Cause of the error is" + error,
          successMessage: false,
          showNotification: true,
          isLoading: false
        });
      });
  }
  */

  handleSearch(value) {
    let newValue;

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      // this.getNutritionistList();
      this.fetchNutritionists();
    });
  }

  // This is commented because now we are using handleSort function.
  // customSort(column, sortDirection) {
  //   let sortField = column.sortField;
  //   this.setState({ sortField: sortField, sortDirection: sortDirection }, () => {
  //     this.getNutritionistList();
  //   })
  // };

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
      searchValue: '',
    }, () => {
      // this.getNutritionistList();
      this.fetchNutritionists();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;

    if (mode === "active") showItems = "Active";
    else if (mode === "deactivated") showItems = "Deactivated";
    else showItems = "All";

    this.setState({ showItems: showItems }, () => {
      if (searchApplied) this.handleSearch(searchTerm);
    });
  }

  handlePageChange = (page) => this.fetchNutritionists(page);

  handleRowsPerPageChange = (newRowsPerPage, page) =>
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchNutritionists(page)
    );

  handleSort = (column, sortDirection) => {
    console.log(column, sortDirection);
    this.setState(
      { sortField: column.sortField || column.selector, sortDirection: sortDirection },
      () => this.fetchNutritionists()
    );
  };

  handleUpdateCalendly=(nutritionist={})=>{
     this.setState({calendlyLinkDetail:nutritionist})
  }
  handleCreateLinkUpdate=(calendlyLinks=[])=>{
   this.setState({calendlyLinks})
  }
  fetchNutritionists = (page = 1) => {
    const { 
      rowsPerPage = DEFAULT_ROWS_PER_PAGE, 
      sortField, 
      sortDirection,
      searchApplied,
      searchTerm,
    } = this.state;

    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    let url = `nutritionist/?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;
    if (searchApplied) {
      url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
    }      

    this.showLoading();
    API.get(url)
      .then((response) => {
        console.log("Response: ", response);
        if (response.data) {
          const { count: totalRows = 0, results: nutritionists = [] } = response.data;
          this.setState({ totalRows, list: nutritionists, isLoaded: true });
        }
      })
      .catch((error) => {
        console.log("Error on fetching data", error);
        this.setState({ loadError: "Some error has occured. Please try again" });
        this.showErrorNotification("Some error has occured. Please try again.");
      })
      .finally(() => this.hideLoading());
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.newUser ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    let finalList = [];

    const nutritionists = this.state.list;
    const {
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      deactivatedList,
      modalData,
      newUser,
      editables,
      errors = {},
      calendlyLinkDetail,
      isEditable,
      emailErrorMessage,
      emailErrorNotify,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      // if (searchApplied) finalList = results;
      // else {
      //   // if (showItems === "Active") finalList = activeList;
      //   // else if (showItems === "Deactivated") finalList = deactivatedList;
      //   // else finalList = nutritionists;
      // }
      finalList = nutritionists
    }

    const newNutrionistFeilds=(
      <div className="container">
        <div className="row">
        <div className="form-group col">
            <label htmlFor="language">Language (English)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="language"
                name="language"
                className={`form-control user-data-input py-2 ${editables.language || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.language : ""}
                onChange={(e) => this.handleInput(e, "language")}
                maxLength="150"
             
                readOnly={!newUser && !editables.language}
              />
              {this.renderPencilIcon("language")}
            
            </div>
            <span className="error">{errors.language}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="language_ar">Language (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="language_ar"
                name="language_ar"
                className={`form-control user-data-input py-2  input-arabic ${editables.language_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.language_ar : ""}
                onChange={(e) => this.handleInput(e, "language_ar")}
                maxLength="150"
             
                readOnly={!newUser && !editables.language_ar}
              />
              {this.renderPencilIcon("language_ar")}
            
            </div>
            <span className="error">{errors.language_ar}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="degree">Degree (English)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="degree"
                name="degree"
                className={`form-control user-data-input py-2 ${editables.degree || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.degree :""}
                onChange={(e) => this.handleInput(e, "degree")}
                maxLength="150"
             
                readOnly={!newUser && !editables.degree}
              />
              {this.renderPencilIcon("degree")}
            
            </div>
            <span className="error">{errors.degree}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="degree_ar">Degree (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="degree_ar"
                name="degree_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.degree_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.degree_ar : ""}
                onChange={(e) => this.handleInput(e, "degree_ar")}
                maxLength="150"
             
                readOnly={!newUser && !editables.degree_ar}
              />
              {this.renderPencilIcon("degree_ar")}
            
            </div>
            <span className="error">{errors.degree_ar}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="institution">Institution (English)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="institution"
                name="institution"
                className={`form-control user-data-input py-2 ${editables.institution || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.institution : ""}
                onChange={(e) => this.handleInput(e, "institution")}
                maxLength="150"
             
                readOnly={!newUser && !editables.institution}
              />
              {this.renderPencilIcon("institution")}
            
            </div>
            <span className="error">{errors.institution}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="institution_ar">Institution (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="institution_ar"
                name="institution_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.institution_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.institution_ar : ""}
                onChange={(e) => this.handleInput(e, "institution_ar")}
                maxLength="150"
             
                readOnly={!newUser && !editables.institution_ar}
              />
              {this.renderPencilIcon("institution_ar")}
            
            </div>
            <span className="error">{errors.institution_ar}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="training_and_certificate">Traning & Certification (English)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="training_and_certificate"
                name="training_and_certificate"
                className={`form-control user-data-input py-2 ${editables.training_and_certificate || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.training_and_certificate : ""}
                onChange={(e) => this.handleInput(e, "training_and_certificate")}
                maxLength="150"
             
                readOnly={!newUser && !editables.training_and_certificate}
              />
              {this.renderPencilIcon("training_and_certificate")}
            
            </div>
            <span className="error">{errors.training_and_certificate}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="training_and_certificate_ar">Traning & Certification (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="training_and_certificate_ar"
                name="training_and_certificate_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.training_and_certificate_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile? modalData.nutritionistprofile.training_and_certificate_ar : ""}
                onChange={(e) => this.handleInput(e, "training_and_certificate_ar")}
                maxLength="150"
             
                readOnly={!newUser && !editables.training_and_certificate_ar}
              />
              {this.renderPencilIcon("training_and_certificate_ar")}
            
            </div>
            <span className="error">{errors.training_and_certificate_ar}</span>
          </div>
        </div>
      </div>
    )
    const personalForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            {/* <label>First Name {newUser && "*"}</label> */}
            <label htmlFor="first_name">First Name*</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="first_name"
                name="first_name"
                className={`form-control user-data-input py-2 ${editables.first_name || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.first_name || ""}
                onChange={(e) => this.handleInput(e, "first_name")}
                maxLength="150"
                // readOnly={this.state.editFn || newUser ? false : true}
                readOnly={!newUser && !editables.first_name}
              />
              {this.renderPencilIcon("first_name")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editFn")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.first_name}</span>
            {/* <span className="error">{this.state.fnameErrorMessage}</span> */}
          </div>
          <div className="form-group">
            {/* <label>Last Name {newUser && "*"}</label> */}
            <label htmlFor="last_name">Last Name*</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                name="last-name"
                className={`form-control user-data-input py-2 ${editables.last_name || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.last_name || ""}
                onChange={(e) => this.handleInput(e, "last_name")}
                maxLength="150"
                // readOnly={this.state.editLn || newUser ? false : true}
                readOnly={!newUser && !editables.last_name}
              />
              {this.renderPencilIcon("last_name")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editLn")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.last_name}</span>
            {/* <span className="error">{this.state.lnameErrorMessage}</span> */}
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            {/* <label>Phone Number {newUser && "*"}</label> */}
            <label htmlFor="phone_no">Phone Number</label>
            <div className="user-input-container">
              <div className={`${editables.phone_no || newUser
                ? `non-editable-phone`
                : `editable-phone`
                } `}>
                <PhoneInput
                  country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.phone_no || newUser ? `non-editable` : `editable`} `}
                  value={modalData.nutritionistprofile ? modalData.nutritionistprofile.phone_no || null : null}
                  onChange={(value, data) => {
                    this.numberCheck(value, data);
                  }}
                  id="phone_no"
                  // disabled={this.state.editPhoneNo || newUser ? false : true}
                  disabled={!newUser && !editables.phone_no}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("phone_no")}
                {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editPhoneNo")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
                {/* <span className="error">{this.state.contactNoErrorMessage}</span> */}
                <span className="error">{errors.phone_no}</span>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="is_active">Status</label>
            <div className="input-group">
              <Dropdown
                value={modalData.is_active}
                onChange={(event, value) => {
                  const p = { ...modalData };
                  p.is_active = value.value;
                  this.setState({ modalData: p });
                }}
                id="is_active"
                name="is_active"
                placeholder="Status"
                search
                selection
                options={this.state.statusOptions}
                disabled={!newUser && !editables.is_active}
                className="form-control"
              />
              {this.renderPencilIcon("is_active")}
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            {/* <label>Email Address{newUser && "*"}</label> */}
            <label htmlFor="email">Email Address*</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="email"
                name="email"
                className={`form-control user-data-input py-2 ${editables.email || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.email || null}
                maxLength="50"
                onChange={(e) => this.handleInput(e, "email")}
                // readOnly={this.state.editEmail || newUser ? false : true}
                readOnly={!newUser && !editables.email}
              />
              {this.renderPencilIcon("email")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editEmail")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.emailErrorMessage}</span> */}
            {emailErrorNotify?<span className="help-block">{emailErrorMessage}</span>:<span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            {/* <label>Password {newUser && "*"}</label> */}
            <label htmlFor="password">Password*</label>
            <div className="user-input-container">
              <input
                type="text"
                id="password"
                name="password"
                className={`form-control user-data-input py-2 ${this.state.editPassword || newUser ? `non-editable` : `editable`
                  } `}
                value={this.state.editPassword || newUser ? modalData.password : ""}
                onChange={(e) => this.handleInput(e, "password")}
                readOnly={!newUser}
                // readOnly={this.state.editPassword || newUser ? false : true}
              ></input>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editPassword")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.passwordErrorMsg}</span> */}
            <span className="error">{errors.password}</span>
          </div>
        </div>
       
        <div className="form-group-container">
          <div className="form-group mb-4">
            <label htmlFor="gender">Gender</label>
            <div className="user-input-container">
              <div
                // className={`ui dropdown user-data-input flex input-group ${!editables.gender || newUser ? `` : `disabled`
                //   }`}
                className={`ui dropdown user-data-input flex input-group`}
              >
                <Dropdown
                  id="gender"
                  className={`form-control ${editables.gender || newUser
                    ? `non-editable`
                    : `editable`
                    } `}
                  placeholder="Select Gender"
                  onChange={(e) => this.handleInput(e, "gender")}
                  defaultValue={
                    !newUser && modalData.nutritionistprofile
                      ? modalData.nutritionistprofile.gender
                      : ""
                  }
                  selection
                  options={[
                    {
                      key: "M",
                      text: "Male",
                      value: "M",
                    },
                    {
                      key: "F",
                      text: "Female",
                      value: "F",
                    },
                  ]}
                  disabled={!newUser && !editables.gender}
                />
                {this.renderPencilIcon("gender")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  style={{ lineHeight: "40px" }}
                  onClick={() => this.makeEditable("editGender")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.genderErrorMessage}</span> */}
          </div>
          <div className="form-group mb-4">
            <label htmlFor="gender">Coach Type</label>
            <div className="user-input-container">
              <div
                // className={`ui dropdown user-data-input flex input-group ${!editables.gender || newUser ? `` : `disabled`
                //   }`}
                className={`ui dropdown user-data-input flex input-group`}
              >
                <Dropdown
                  id="is_external_coach_user"
                  className={`form-control ${editables.is_external_coach_user || newUser
                    ? `non-editable`
                    : `editable`
                    } `}
                  placeholder="Select Coach Type"
                  onChange={(event, value) => {
                    this.setState({
        
                      modalData: {
                        ...this.state.modalData,
                        is_external_coach_user: value.value,
                      },
                    });
                  }}
                  value={
                     modalData.is_external_coach_user ?? false
                      
                  }
                  selection
                  options={[
                    {
                      key: "Internal",
                      text: "Internal",
                      value: false,
                    },
                    {
                      key: "External",
                      text: "External",
                      value: true,
                    },
                  ]}
                  disabled={!newUser && !editables.is_external_coach_user}
                />
                {this.renderPencilIcon("is_external_coach_user")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  style={{ lineHeight: "40px" }}
                  onClick={() => this.makeEditable("editGender")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.genderErrorMessage}</span> */}
          </div>
        </div>
        <div className="form-group-container">
        <div className="form-group">
            {/* <label>Nationality{newUser && "*"}</label> */}
            <label htmlFor="nationality">Nationality</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="nationality"
                name="nationality"
                className={`form-control user-data-input py-2 ${editables.nationality || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.nationality : ""}
                onChange={(e) => this.handleInput(e, "nationality")}
                maxLength="50"
                // readOnly={this.state.editNationality || newUser ? false : true}
                readOnly={!newUser && !editables.nationality}
              />
              {this.renderPencilIcon("nationality")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editNationality")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.nationality}</span>
            {/* <span className="error">{this.state.nationalityErrorMessage}</span> */}
          </div>
          <div className="form-group">
            {/* <label>Nationality{newUser && "*"}</label> */}
            <label htmlFor="nationality">Nationality (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="nationality_ar"
                name="nationality_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.nationality_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.nationality_ar : ""}
                onChange={(e) => this.handleInput(e, "nationality_ar")}
                maxLength="50"
                // readOnly={this.state.editNationality || newUser ? false : true}
                readOnly={!newUser && !editables.nationality_ar}
              />
              {this.renderPencilIcon("nationality_ar")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editNationality")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.nationality_ar}</span>
            {/* <span className="error">{this.state.nationalityErrorMessage}</span> */}
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            {/* <label>Title{newUser && "*"}</label> */}
            <label htmlFor="job_title">Title</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="job_title"
                name="job_title"
                className={`form-control user-data-input py-2 ${editables.job_title || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.job_title || "" : ""}
                onChange={(e) => this.handleInput(e, "job_title")}
                maxLength="50"
                // readOnly={this.state.editTitle || newUser ? false : true}
                readOnly={!newUser && !editables.job_title}
              />
              {this.renderPencilIcon("job_title")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editTitle")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.titleErrorMessage}</span> */}
            <span className="error">{errors.job_title}</span>
          </div>
          <div className="form-group">
            {/* <label>Title{newUser && "*"}</label> */}
            <label htmlFor="job_title_ar">Title (Arabic)</label>
            <div className="user-input-container input-group">
              <input
                type="text"
                id="job_title_ar"
                name="job_title_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.job_title_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.job_title_ar || "" : ""}
                onChange={(e) => this.handleInput(e, "job_title_ar")}
                maxLength="50"
                // readOnly={this.state.editTitle || newUser ? false : true}
                readOnly={!newUser && !editables.job_title_ar}
              />
              {this.renderPencilIcon("job_title_ar")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editTitle")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            {/* <span className="error">{this.state.titleErrorMessage}</span> */}
            <span className="error">{errors.job_title_ar}</span>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="years_of_exp">Experience</label>
            <div className="user-input-container input-group">
              <input
                type="number"
                id="years_of_exp"
                name="years_of_exp"
                className={`form-control user-data-input py-2 ${editables.years_of_exp || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.years_of_exp : ""}
                onChange={(e) => this.handleInput(e, "years_of_exp")}
                 maxLength="7"
                 onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                // readOnly={this.state.editExperience || newUser ? false : true}
                readOnly={!newUser && !editables.years_of_exp}
              ></input>
              {this.renderPencilIcon("years_of_exp")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editExperience")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.years_of_exp}</span>
            {/* <span className="error">{this.state.expeienceErrorMessage}</span> */}
          </div>
         
        </div>
        
        <div className="form-group-container">
        <div className="form-group">
            {/* <label>Specialization{newUser && "*"}</label> */}
            <label htmlFor="specialization">Specialization</label>
            <div className="user-input-container input-group">
              <textarea
                type="text"
                id="specialization"
                name="specialization"
                className={`form-control user-data-input py-2 ${editables.specialization || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.specialization || "" : ""}
                onChange={(e) => this.handleInput(e, "specialization")}
                // readOnly={this.state.editSpecialization || newUser ? false : true}
                readOnly={!newUser && !editables.specialization}
                // maxLength="50"
              />
              {this.renderPencilIcon("specialization")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editSpecialization")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.specialization}</span>
            {/* <span className="error">{this.state.specializationErrorMessage}</span> */}
          </div>
          <div className="form-group">
            {/* <label>Specialization{newUser && "*"}</label> */}
            <label htmlFor="specialization">Specialization (Arabic)</label>
            <div className="user-input-container input-group">
              <textarea
                type="text"
                id="specialization_ar"
                name="specialization_ar"
                className={`form-control user-data-input py-2 input-arabic ${editables.specialization_ar || newUser ? `non-editable` : `editable`
                  } `}
                value={modalData.nutritionistprofile ? modalData.nutritionistprofile.specialization_ar || "" : ""}
                onChange={(e) => this.handleInput(e, "specialization_ar")}
                // readOnly={this.state.editSpecialization || newUser ? false : true}
                readOnly={!newUser && !editables.specialization_ar}
                // maxLength="50"
              />
              {this.renderPencilIcon("specialization_ar")}
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editSpecialization")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
            </div>
            <span className="error">{errors.specialization_ar}</span>
            {/* <span className="error">{this.state.specializationErrorMessage}</span> */}
          </div>
        </div>
        {/* <div className="form-group-container">
          <div className="form-group col-12">
            <label htmlFor="overview">
              Short Bio
              <span> click on save icon to save your data</span>
            </label>
            <div className="user-input-container input-group" style={{flexWrap:"noWrap"}}>
              <MUIRichTextEditor
                readOnly={this.state.editShortBio || newUser ? false : true}
                readOnly={!newUser && !editables.overview}
                value={this.state.overview ? this.state.overview : ""}
                id="overview"
                name="overview"
                label="short Bio"
                onChange={(e) => {
                  this.event = e;
                }}
                onSave={this.saveOverviewEng}
                inlineToolbar={true}
                className={`form-control user-data-input ${editables.overview || newUser ? `non-editable` : `editable`
                  } `}
              />
              {this.renderPencilIcon("overview")}
              {!newUser && (
                <div
                  className="user-data-edit-icon"
                  style={{ maxHeight: '37px', bottom: '0px' }}
                  onClick={() => this.makeEditable("editShortBio")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )}
            </div>
            <p className="help-block rte-mui">{errors.overview}</p>
            <p className="help-block rte-mui">{this.state.shortBioErrorMessage}</p>
          </div>
        </div> */}
        {/* <div className="form-group-container">
          <div className="form-group col-12">
            <label htmlFor="overview_ar">
              Short Bio (Arabic)
              <span> click on save icon to save your data</span>
            </label>
            <div className="user-input-container input-group" style={{flexWrap:"noWrap"}}>
              <MUIRichTextEditor
                // readOnly={this.state.editShortBio || newUser ? false : true}
                readOnly={!newUser && !editables.overview_ar}
                value={this.state.overview_ar ? this.state.overview_ar : ""}
                id="overview_ar"
                name="overview_ar"
                label="short Bio (Arabic)"
                onChange={(e) => {
                  this.event = e;
                }}
                onSave={this.saveOverviewArabic}
                inlineToolbar={true}
                className={`form-control user-data-input ${editables.overview_ar || newUser ? `non-editable` : `editable`
                  } `}
              />
              {this.renderPencilIcon("overview_ar")}
             
            </div>
            <p className="help-block rte-mui">{errors.overview_ar}</p>
            <p className="help-block rte-mui">{this.state.shortBioErrorMessage}</p>
          </div>
        </div> */}
          <div className="form-group-container">
            <div className="form-group col">
            <label >
              Short Bio (English)
            </label>
            <div className="input-group">
              <RichTextEditor
               rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
               editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.overview}
                onChange={(value)=>this.handleRichText(value,"overview")}
                disabled={!newUser && !editables.overview}
              />
              {this.renderPencilIcon("overview")}
            </div>
            </div>
            <div className="form-group col">
              <label>
                Short Bio (Arabic)
              </label>
              <div className="input-group">
              <RichTextEditor
               rootStyle={{position:'relative',flex:"1 1 auto",width:'1%',marginBottom:0}}
               editorStyle={{height:'90px',overflow:'hidden'}}
                value={this.state.overview_ar}
                onChange={(value)=>this.handleRichText(value,"overview_ar")}
                disabled={!newUser && !editables.overview_ar}
              />
              {this.renderPencilIcon("overview_ar")}
            </div>
            </div>
          </div>
        <div className="form-group-container">
          <div className="form-group col ">
            <label htmlFor="imageInput">Coach Picture</label>
            <div className="input-group">
              <input
                type="file"
                id="imageInput"
                name="coach_image"
                accept="image/*"
                className={`form-control user-data-input py-2`}
                onChange={this.selecteImage}
                disabled={!newUser && !editables.coach_image}
              />
              {this.renderPencilIcon("coach_image")}
            </div>
            {/* <span className="help-block">{validation.coach_image.message}</span>
            <p className="help-block rte-mui">{this.state.coachImageErrorMessage}</p> */}
          </div>
          <div className="form-group col ">
            {this.state.imgSrc && (
              <>
                <label><b>Selected Image</b></label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        {newNutrionistFeilds}
        <div className="form-group-container ">
        <div className="form-group col-6">
            
            <label htmlFor="is_test_coach"> Is Test Coach</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_test_coach}
                onChange={(event, value) => {
                  this.setState({ is_test_coach: value.value });
                }}
                id="is_test_coach"
                placeholder="Test Coach"
                search
                selection
                options={testerOptions}
                disabled={!newUser && !editables.is_test_coach}
                className={
                  "form-control" + (errors.is_test_coach ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_test_coach")}
            </div>
              
            
            <span className="help-block">{errors.is_test_coach}</span>
          </div>
        </div>

      </>
    );

    const activityInformationForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="date_joined">Joining Date</label>
            <input
              value={modalData.date_joined ? formatDateTime(modalData.date_joined) : ""}
              type="text"
              id="date_joined"
              name="date_joined"
              className="form-control py-2"
              disabled
            />
          </div>
          <div className="form-group col">
            <label htmlFor="last_login">Last Login</label>
            <input
              value={modalData.last_login ? formatDateTime(modalData.last_login) : ""}
              type="text"
              id="last_login"
              name="last_login"
              className="form-control py-2"
              disabled
            />
          </div>
        </div>
      </div>;

    const coachCalendlyLinkForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="calendlyLink">Calendly Link *</label>
            <div className="input-group">
              <textarea
                value={calendlyLinkDetail?.calendlyLink || ""}
                onChange={this.handleCalendlyInput}
                id="calendlyLink"
                name="calendlyLink"
                type="text"
                placeholder="Enter Calendly Link"
                className={
                  "form-control py-2" +
                  (errors.calendlyLink ? " has-error" : "")
                }
                disabled={!newUser && !editables.calendlyLink}
              ></textarea>
              {this.renderPencilIcon("calendlyLink")}
            </div>
            <span className="help-block">{errors.calendlyLink}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="isActive">Is Active*</label>
            <div className="input-group">
              <Dropdown
                value={calendlyLinkDetail?.isActive}
                onChange={(event, value) =>
                  this.setState({
                    calendlyLinkDetail: { ...calendlyLinkDetail, isActive: value.value },
                  })
                }
                id="isActive"
                name="isActive"
                placeholder="Is Active"
                search
                selection
                options={STATUS_OPTIONS || []}
                disabled={!newUser && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="isDefault">Is Default *</label>
            <div className="input-group">
              <Dropdown
                value={calendlyLinkDetail?.isDefault}
                onChange={(event, value) =>
                  this.setState({
                    calendlyLinkDetail: { ...calendlyLinkDetail, isDefault: value.value },
                  })
                }
                id="isDefault"
                name="isDefault"
                placeholder="isDefault"
                search
                selection
                options={DEFAULT_OPTIONS || []}
                disabled={!newUser && !editables.isDefault}
                className={
                  "form-control" + (errors.isDefault ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isDefault")}
            </div>
            <span className="help-block">{errors.isDefault}</span>
          </div>

        </div>
        <div className="row">
        <div className="form-group col" >
              
              <div className="input-group">
              <input
                value={true}
                checked={calendlyLinkDetail.isNonCalendlyLink && calendlyLinkDetail.isNonCalendlyLink || false}
                onChange={(event, value) => {
                  this.setState({
                    calendlyLinkDetail: { ...calendlyLinkDetail, isNonCalendlyLink:event.target.checked}
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; is this non calendly link?
               </div> 

        </div>
        <div className="form-group col">
          <label>Coach CalendlyMail</label>
        <div className="input-group">
              <input
                
                value={calendlyLinkDetail.calendlyCoachMail }
                onChange={(event, value) => {
                  this.setState({
                    calendlyLinkDetail: { ...calendlyLinkDetail, calendlyCoachMail:event.target.value}
                  });
                }}
                id="calendlyCoachMail"
                name="calendlyCoachMail"
                type="input"
                disabled={!newUser && !editables.calendlyCoachMail}
                className={
                  "form-control" + (errors.calendlyCoachMail ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("calendlyCoachMail")}
              </div>
              <span className="help-block">{errors.calendlyCoachMail}</span>
              </div>
        </div>
      </>
    );
      const CalendlyForm = (
        <>
          {/* {!this.state.newUser &&
            <div>
              <CoachCalendly
                nutritionistId={modalData.id}
                onUpdateCalendly={this.handleUpdateCalendly}
                isEditable={isEditable}
              />
            </div>
          }
          {this.state.newUser && <div>
             {coachCalendlyLinkForm}
            </div>} */}
            <div>
            <div>
              <CoachCalendlyLinks
                nutritionistId={modalData.id}
                newUser={this.state.newUser??false}
                isEditable={isEditable}
                links={this.state.newUser?this.state.calendlyLinks:[]}
                onCreateCalendly={this.handleCreateLinkUpdate}
              />
            </div>
            </div>
            
        </>
      );
        
      

    const tabData = [
      {
        tabTitle: 'Coach Profile',
        tabForm: personalForm,
        eventKey: 'coachInfo'
      },
      {
        tabTitle: 'Activity Information',
        tabForm: activityInformationForm,
        eventKey: 'activityInfo'
      },
      {
        tabTitle: 'Coach Calendly Information',
        tabForm: CalendlyForm,
        eventKey: 'calendlyInfo'
      },
    ];

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="nutritionist">
        <div className="page-header">
          {/* <h5>Wellbeing coaches</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleSearchEnter}
                className="btn"
                style={{ backgroundColor: "#384760", color: "white" }}
                // className="btn button-blue btn-md new-user"
              >
                Search
              </button>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Deactivated" ? "active" : ""}
                    onClick={() => this.setShowItems("deactivated")}
                  >
                    {showItems === "Deactivated" ? (
                      <CheckIcon></CheckIcon>
                    ) : null}
                    Deactivated
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            <div className="action-item">
              <button
                onClick={this.createNewUser}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>New Coach
              </button>
              {/* <button
                className="btn button-blue btn-md new-user"
                onClick={this.createNewUser}
              >
                <i className="fa fa-plus-circle"></i>New User
              </button> */}
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            sortServer
            onSort={this.handleSort}
            // defaultSortField="username"
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={this.state.totalRows || 0}
            paginationPerPage={this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            // onSort={this.customSort}
            // paginationTotalRows={this.state.totalPage}
            // paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            // onChangePage={this.onUserPageChange}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {/* {"Found " + resultCount + " results in '" + showItems + "'"} */}
                  {`Found ${this.state.totalRows} ${this.state.totalRows <= 1 ? "result" : "results"}`}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
	      <ModalPopup 
          showModal={this.state.showModal} 
          tabData={tabData} key={this.state.key} 
          saveUserData={this.saveUserData} 
          isEditable={isEditable}
          // deactivateCoach={this.toggleActiveStatus} 
          hideModal={this.hideModal} 
          title={this.modalData && this.modalData.id ? this.modalData.id : ''} />

        {/* <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>Add Wellbeing Coach</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.createNutrionist}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal> */}
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Deactivate
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Activate
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nutritionist);
