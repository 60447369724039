import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

function AlertBox(props) {
  const { error, message } = props;

  return (
    <div className={"alert-box " + (error ? "error" : "")}>
      <InfoOutlinedIcon></InfoOutlinedIcon>
      {message}
    </div>
  );
}

export default AlertBox;
