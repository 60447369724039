import React, { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PhoenixAPI from "utils/PhoenixAPI";
import ProfessionalForm from "./ProfessionalForm";
import ToggleSwitch from "components/Common/ToggleSwitch";
import "./manageprofessionals.scss";
import { fetchCities } from "services/RegionService";
import Notification from "./Notification";
const BASE_URL_PROFESSIONALS = "/home-care-professional";

export default function ManageProfessionals(props) {
  const {
    homecareServiceId,
    isProfessionalFormVisible,
    setIsShowOrder,
    setIsProfessionalFormVisible,
    setProfessionalEdit,
    professionalEdit,
    searchFlag,
    searchOrders,
    setIsNewProfessional,
    isNewProfessional,
    searchValue,
  } = props;
 
  const [professionals, setProfessionals] = useState([]);
  const [cities, setCities] = useState([]);
  const [professionalStatus, setProfessionalStatus] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [filterOrders,setFilteredOrders]= useState([])
  const [enableFact,setEnableFact]= useState(false);
 

  useEffect(() => {
    
    fetchProfessionals();
    fetchCitiesData();
  }, []);

  useEffect(()=>{
    if(searchFlag){
      const newValue =searchValue&&  searchValue.slice(0).trim().toLowerCase();
      const filter= professionals && professionals.filter((order)=>{
        const {professionalName}= order || {}
      return(

        (professionalName && professionalName.toLowerCase().indexOf(newValue) !== -1) 
         
      )
      })
      setFilteredOrders(filter)
    }
  },[searchFlag, searchValue, professionals])

  const fetchCitiesData = async () => {
    try {
      //    this.showLoading();
      const cities = (await fetchCities()) ?? [];
      
      setCities(cities);
    } catch (error) {
      // this.showErrorNotification("Error on fetching cities!" + error.message);
    } finally {
      //this.hideLoading();
    }
  };


  const handleOnToggleStatus = async (professional, isToggled) => {
   
    try {
      //  showLoading();
      const payload = { ...professional };
      payload.isEnable = isToggled;

      const formData = new FormData();
      formData.append("professional", JSON.stringify(payload));

      const config = {
        method: "PATCH",
        url: `${BASE_URL_PROFESSIONALS}/${professional.id}`,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      //hideModal();
      /* this.showSuccessNotification(
      isToggled ? "Enabled Successfully" : "Disabled Successfully"
    );*/
      if (isToggled) setMessage("Enabled Successfully");
      if (!isToggled) setMessage("Disabled Successfully");
      fetchProfessionals();
    } catch (error) {
      //  this.showErrorNotification("Some error occurred: " + error.message);
      setMessage("error occur while updating ");
      setIsError(true);
    } finally {
      // hideLoading();
    }
  };

  const fetchProfessionals = async () => {
    // this.showLoading();
    // const { user } = this.state;
   
    if (!homecareServiceId) {
      //  this.showErrorNotification("Invalid user or homecare service id!");
      return;
    }

    try {
      const url = `${BASE_URL_PROFESSIONALS}/homecareId/${homecareServiceId}`;
      const { data: professionals = [] } = await PhoenixAPI.get(url);
      setProfessionals([...professionals]);
    } catch (error) {
      console.log("Error on fetching professionals!", error);
      // this.showErrorNotification(
      // "Error on fetching professionals: ",
      //error.message
      //);
    } finally {
      //this.hideLoading();
    }
  };

  const findCityById = (cityId) => {
    return cities.find((city) => city.id === cityId);
  };

  const getCityNameByCityId = (cityId) => {
    const city = findCityById(cityId);
    if (!city) {
      // console.log("getCityNameByCityId:: City not found with id=", cityId);
      return "";
    }
    return city?.city_name ?? "";
  };
  const handleAddProfessional = () => {
    setIsProfessionalFormVisible(true);
    setIsNewProfessional(true);
    setIsShowOrder(false);
    
  };

  const handleEditProfessional = (professional) => {
   
    setProfessionalEdit(professional);
    setIsProfessionalFormVisible(true);
    setIsShowOrder(false);
  };

  const renderProfessionals = (professionals) => {
    return (
      <>
        {professionals &&
          professionals.length > 0 &&
          professionals.map((professional, index) => {
            return (
              <div className="nurse-card">
                <div className="nurse-name-status">
                  <div className="name-txt">
                    {professional.professionalName}
                  </div>
                  <div className="active-txt">
                     {professional.isEnable ?"Active":"InActive"}
                    <ToggleSwitch
                      key={professional.id}
                      id={`toggle-${professional.id}`}
                      toggled={professional.isEnable ?? false}
                      onToggle={(isToggled) =>
                        handleOnToggleStatus(professional, isToggled)
                      }
                      
                    />
                  </div>
             {/* <div className="custom-control custom-switch question-fact">
            <input
              type="checkbox"
              role="switch"
              className="custom-control-input"
              id="enableFact"
              name="enableFact"
              checked={enableFact}
              value={enableFact}
              onChange={(e)=>handleOnToggle(professional, e)}
              
            />
            <label className="custom-control-label" htmlFor="enableFact">
              {enableFact ? "Active" : "InActive"}
            </label>
          </div> */}
          
                </div>
                
                <div className="category">
                  <div className="category-column">
                    <div className="category-txt">Cities:</div>
                    <div className="category-subtext">
                      {professional?.cities
                        .map((city) => getCityNameByCityId(city))
                        .join(", ")}
                    </div>
                  </div>
                  <div className="category-column">
                    <div className="category-txt">Category:</div>
                    <div className="category-subtext">
                      {professional?.category.join(", ")}
                    </div>
                  </div>
                </div>

                <div className="category">
                  <div className="category-column">
                    <div className="category-txt">WhatsApp No:</div>
                    <div className="category-subtext">
                      {professional.whatsappNumber}
                    </div>
                  </div>
                  <div className="category-column">
                    <div className="category-txt">Phone No:</div>
                    <div className="category-subtext">
                      {professional.phoneNumber}
                    </div>
                  </div>
                </div>
                <div className="edit-container">
                  <div
                    className="edit-button"
                    onClick={() => handleEditProfessional(professional)}
                  >
                    Edit
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
     <div className="add-profession-div">
        <div
          className="add-profession"
          onClick={() => handleAddProfessional()}
        >
          Add a Profession
        </div>
        </div>
      <div className="nurse-wrapper">
       

        {searchFlag
          ? renderProfessionals(filterOrders)
          : renderProfessionals(professionals)}
        {message && (
          <Notification
            setMessage={setMessage}
            message={message}
            isError={isError}
          />
        )}
      </div>
    </>
  );
}
