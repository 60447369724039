import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";

ConditionCategoriesDropdown.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCategory: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function ConditionCategoriesDropdown(props) {
  const {
    categories = [],
    selectedCategory,
    onSelect = (f) => f,
    isDisabled = false,
  } = props;

  const [activeCategories, inactiveCategories] = _.partition(
    categories,
    (it) => it.status === "Active"
  );
  console.log({ activeCategories }, { inactiveCategories });
  const options = [
    {
      label: "Active",
      options: activeCategories.map(createCategoryOption),
    },
    {
      label: "Inactive",
      options: inactiveCategories.map(createCategoryOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  return (
    <Select
      key={`my_unique_select_key__${selectedCategory && selectedCategory.id}`}
      value={selectedCategory && createCategoryOption(selectedCategory)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Category"
      isDisabled={isDisabled}
      menuPlacement="top"
    />
  );
}

const createCategoryOption = (category) => {
  if (!category) return;
  return {
    key: category.id,
    value: category.id,
    label: category.internalName,
  };
};

export default ConditionCategoriesDropdown;
