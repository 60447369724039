import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_COACH_CONSULTATION_PACKAGE = "coach-consultation-package";

export const fetchCoachPackages = async () => {
  try {
    const { data: coachPackages = [] } = await PhoenixAPI.get(
      BASE_URL_COACH_CONSULTATION_PACKAGE
    );
    return coachPackages;
  } catch (error) {
    console.log("Error on fetching coach consultation packages", error);
    throw error;
  }
};

export const saveCoachPackage = async (coachPackage) => {
  try {
    const payload = { ...coachPackage };

    const apiOptions = {
      method: "post",
      url: BASE_URL_COACH_CONSULTATION_PACKAGE,
      data: payload,
    };

    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on creating other-item", error);
    throw error;
  }
};

export const updateCoachPackage = async (coachPackage) => {
  try {
    const payload = { ...coachPackage };
    const apiOptions = {
      method: "patch",
      url: `${BASE_URL_COACH_CONSULTATION_PACKAGE}/${coachPackage.id}`,
      data: payload,
    };
    const response = await PhoenixAPI.request(apiOptions);
    return response;
  } catch (error) {
    console.log("Error on updating category", error);
    throw error;
  }
};

const CoachConsultationPackageService = {
  fetchCoachPackages,
  saveCoachPackage,
  updateCoachPackage,
};

export default CoachConsultationPackageService;
