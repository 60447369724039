import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectAuthor.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAuthorId: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectAuthor.defaultProps = {
  authors: [],
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectAuthor(props) {
  const { authors, selectedAuthorId, onSelect, isDisabled } = props;
  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };
  const findAuthor = (id) => authors.find((it) => it.id === id);

  const filteredAuthors = authors
    .filter((author) => author.first_name || author.last_name)
    .sort(
      (first, second) =>
        compareStrings(first.first_name, second.first_name, true) ||
        compareStrings(first.last_name, second.last_name, true)
    );
  const options = filteredAuthors.map(createOption);
  const selectedAuthor = findAuthor(selectedAuthorId);

  return (
    <Select
      key={`my_unique_select_key__${selectedAuthor && selectedAuthor.id}`}
      value={createOption(selectedAuthor)}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      isDisabled={isDisabled}
    />
  );
}

const getName = (author) => {
  const { first_name, last_name } = author;
  return `${first_name ?? ""} ${last_name ?? ""}`.trim();
};

const createOption = (author) => {
  return author
    ? {
        key: author.id,
        value: author.id,
        label: getName(author),
      }
    : null;
};

export default SelectAuthor;
