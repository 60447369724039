import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

CountryDropdown.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedCountry: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

function CountryDropdown(props) {
  const { countries, selectedCountry } = props;
  const isActiveCountry = (country) => (country ? country.is_active : false);
  const isInactiveCountry = (country) => (country ? !country.is_active : false);
  const activeCountries = countries && countries.filter(isActiveCountry);
  const inactiveCountries = countries && countries.filter(isInactiveCountry);

  return (
    <Dropdown as={ButtonGroup}>
      <Button variant="primary">
        {selectedCountry ? selectedCountry.country_name : "Select Country"}
      </Button>
      <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
      <Dropdown.Menu>
        <Dropdown.Header>Active Countries</Dropdown.Header>
        {activeCountries && renderCountryDropdownItems(activeCountries, props)}
        <Dropdown.Divider />
        <Dropdown.Header>Inactive Countries</Dropdown.Header>
        {inactiveCountries &&
          renderCountryDropdownItems(inactiveCountries, props)}
      </Dropdown.Menu>
    </Dropdown>
  );
}

const renderCountryDropdownItems = (countries, { onSelect, selectedCountry }) =>
  countries.map((country) => (
    <Dropdown.Item
      key={country.id}
      as="button"
      active={selectedCountry && selectedCountry.id === country.id}
      onClick={() => onSelect(country)}
    >
      {country.country_name}
    </Dropdown.Item>
  ));

export default CountryDropdown;
