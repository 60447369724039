import React,{useState} from "react";
import { round } from "utils/commons";
import EditOrderNotes from "./EditOrderNotes";
import { Dropdown } from "semantic-ui-react";

import PhoenixAPI from "utils/PhoenixAPI";
import { connect } from "react-redux";
function PaymentInformation(props) {
  const{paymentDetails,onEdit,updatePaymentMethod}=props;
  const {
    transactionId,
    currency,
    taxPercentage,
    taxAmount,
    subscriptionDiscount,
    couponApplied,
    couponDetails,
    couponCode,
    discountValue,
    totalAmount,
    numberOfItems,
    finalAmount,
    additionalDiscount,
    additionalDiscountNote,
    paymentMethod,
    paymentGateway,
    paymentStatus,
    isWalletBalanceUsed,
    walletAmount,
    deliveryCharges,
    nurseVisitFee,

    transId,
    codMamopayNotes,
    orderId,
    cashbackAmount,
    serviceReference,
    orderType,
    draftOrder,
    orderCreatedByName,
    mokafaRewards,
    mamopayTransactionComplete,
  } = paymentDetails ?? {};
  const PaymentGatewayOptions=[
    { 
        key:"COD",
        value:"COD",
        text:"COD"
    },
    {
        key:"mamopay",
        value:"MAMOPAY",
        text:"MAMOPAY"
    }
 ];
 const PaymentMethodOptions=[
    { 
        key:"COD",
        value:"COD",
        text:"COD"

    },
    {
       key:"card",
       value:"CARD",
       text:"CARD",
    }
 ]
  const[codMamopayNotesEdit,setCODMamopayNotesEdit]=useState(codMamopayNotes);
  const[isEditOrderCODMamopayNotesViewModalVisible,setisEditOrderCODMamopayNotesViewModalVisible]=useState(false);
   const[isEditOrderPaymentMethodModalVisible,setIsEditOrderPaymentMethodModalVisible]=useState(false);
   const[paymentMethodEdit,setPaymentMethodEdit]=useState(paymentMethod);
   const[paymentGatewayEdit,setPaymentGatewayEdit]=useState(paymentGateway);
   const[modalErrors,setModalErrors]=useState({});
  const showNotification = (notificationMessage, isSuccessMessage) =>
  props.showNotificationMessage({
    notificationMessage,
    successMessage: isSuccessMessage,
    showNotification: true,
  });

const getCodMamoPayNotes=(notes)=>{
  setCODMamopayNotesEdit(notes);
}
 const getPaymentMethod=(paymentMethod)=>{
  setPaymentMethodEdit(paymentMethod);
 }
const getPaymentGateway=(paymentGateway)=>{
  setPaymentGatewayEdit(paymentGateway);
}
const showSuccessNotification = (notificationMessage) =>
  showNotification(notificationMessage, true);

const showErrorNotification = (notificationMessage) =>
  showNotification(notificationMessage, false);


  const onEditCODMamoPayNotes=()=>{
    setisEditOrderCODMamopayNotesViewModalVisible(true);

  }
 const onEditPaymentMethod=()=>{
    setIsEditOrderPaymentMethodModalVisible(true);
  }
  const handleHideEditPaymentMethod=()=>{
    setIsEditOrderPaymentMethodModalVisible(false);
  }
 const handleHideEditCODViewModal = () =>{

 setisEditOrderCODMamopayNotesViewModalVisible ( false);
   // setCODMamopayNotesEdit( null);
  
 }
const handleSaveCODMamopayNotesNotes=async(codMamopayNotesRef)=>{
    if(codMamopayNotesRef !=="" && codMamopayNotesRef !==null && codMamopayNotesRef !==undefined ){
  setCODMamopayNotesEdit(codMamopayNotesRef);
  
   const url=`payment/update/${transId}`;


   const payload={"codMamopayNotes":codMamopayNotesRef};
   const orderUrl=`admin/cart-orders/${orderId}`;
try{
 const response=await PhoenixAPI.patch(url, payload);
  handleHideEditCODViewModal();
  showSuccessNotification("Notes Saved successfully");
  const responseOrder= await PhoenixAPI.get(orderUrl);
 console.log("response",responseOrder.data.transactionDetails.codMamopayNotes);
   getCodMamoPayNotes(responseOrder.data.transactionDetails.codMamopayNotes);
}
  catch (error) {
    console.log("error on saving", error);
    showErrorNotification("Error on saving notes");
  }
}
else{
  handleHideEditCODViewModal();
}

}
const handleSavePaymentMethodandGateway=async(paymentGatewayEdit,paymentMethodEdit)=>{
  if(paymentMethodEdit && paymentGatewayEdit){
     
    const urlpaymentmethod=`payment/update-method-and-gateway/${transId}`;
    const payload={
      "paymentGateway":paymentGatewayEdit,
      "paymentMethod":paymentMethodEdit,
    }
    const orderUrl=`admin/cart-orders/${orderId}`;
    try{
      const response=await PhoenixAPI.patch(urlpaymentmethod, payload);
      handleHideEditPaymentMethod();
  showSuccessNotification("payment method and gateway Saved successfully");
 updatePaymentMethod();
    }
    catch(error){
      console.log("error on saving", error);
      showErrorNotification("Error on saving payment gateway and method");
    }
  }
   else{
    handleHideEditPaymentMethod();
   }

}

const validateFields=()=>{
  const errors={};
  
  
      if(paymentMethodEdit =="" || paymentMethodEdit==null ){
       errors.paymentMethodEdit="payment method should not be empty";
       
      }
      if(paymentGatewayEdit=="" || paymentGatewayEdit==null)
      {
          errors.paymentGatewayEdit="payment gateway should not be empty";
      
      }
      setModalErrors(errors);
      return errors;
  }

  console.log("coupon details",couponDetails);


  const discountRow = (
    <tr>
      <td>Discount</td>
      <td>{couponCode ?? ""}</td>
      <td colspan={3} className="text-right text-nowrap">
        &#8722;{`${currency} ${discountValue?parseFloat(discountValue.toFixed(2)):0}`}
      </td>
    </tr>
  );


  const cashbackRow=(
    <tr>
      <td>Cashback</td>
      <td>{couponCode ?? ""}</td>
      <td colspan={3} className="text-right text-nowrap">
    {`${currency} ${cashbackAmount??0}`}
      </td>

    </tr>
  )

  const paymentGatewayandMethodForm=(<><div className="row">
  <div className="form-group col-6">
   <label htmlFor="paymentGateway">Payment Gateway*</label>
   <Dropdown
     value={paymentGatewayEdit}
     onChange={(event, value) => setPaymentGatewayEdit(value.value)}
     id="paymentGateway"
     name="paymentGateway"
     placeholder="Payment Gateway"
     search
     selection
     options={PaymentGatewayOptions}
     className={"form-control"}
   />
   <span className="help-block">{modalErrors.paymentGatewayEdit}</span>
 </div>
 <div className="form-group col-6">
   <label htmlFor="paymentMode">Payment Method*</label>
   <Dropdown
     value={paymentMethodEdit}
     onChange={(event, value) => setPaymentMethodEdit(value.value)}
     id="paymentMode"
     name="paymentMode"
     placeholder="Payment Mode"
     search
     selection
     options={PaymentMethodOptions}
     className={"form-control"}
   />
<span className="help-block">{modalErrors.paymentMethodEdit}</span>
 </div>



  </div>
  <br/>
  <div className="row">
        <div className="form-group col-6"></div>

        <div className="form-group col-6">
  
  <button className="btn button btn" style={{ border: " 1px solid #0CA789", color: "#0CA789",paddingLeft:"15px" }} onClick={(e)=>{if(Object.keys(validateFields()).length==0) handleSavePaymentMethodandGateway(paymentGatewayEdit,paymentMethodEdit)}}>
  Save 
</button>
</div>
</div></>
);
 console.log("additional discount",additionalDiscountNote);

  const paymentDetailsTable = (<>
    <table className="table table-responsive table-borderless">
      <tbody>
      <tr>
          <td>Subtotal</td>
          <td colSpan={2}>{`${numberOfItems} ${
            numberOfItems > 1 ? "items" : "item"
          }`}</td>
          <td className="text-right text-nowrap">{`${currency} ${totalAmount}`}</td>
        </tr>
      <tr>
          <td colSpan={2}>Subscription Discount (Already Considered in Pricing)</td>
          <td colspan={3} className="text-right text-nowrap">{`${currency} ${subscriptionDiscount==null||subscriptionDiscount==undefined?0:subscriptionDiscount}`}</td>
        </tr>
        {couponApplied &&   (couponDetails?.couponCategory== "Discount" || couponDetails?.couponCategory== "DISCOUNT") && discountRow}
        {couponApplied  && (couponDetails?.couponCategory== "Cashback" || couponDetails?.couponCategory== "CASHBACK") && cashbackRow}
         <tr>
          <td>Mokafa Rewards</td>
          <td colspan={3} className="text-right text-nowrap">{`-${currency} ${mokafaRewards ?? 0}`}</td>
        </tr> 
       <tr>
          <td>Delivery Charges</td>
          <td colspan={3} className="text-right text-nowrap">{`${currency} ${deliveryCharges ?? 0}`}</td>
        </tr>

        <tr>
          <td>Additional Discount </td>
          <td colspan={3} className="text-right text-nowrap">{`-${currency} ${additionalDiscount ?? 0}`}</td>
          
        </tr>

       { orderType=="Supplement" ?<></>:<tr>

          <td>Nurse Visit Fee</td>
          <td colspan={3} className="text-right text-nowrap">{`${currency} ${nurseVisitFee ?? 0}`}</td>
        </tr>}
      
        <tr>
          <td>Tax</td>
          <td colSpan={2}>{taxAmount?`VAT ${taxPercentage}% (included)`:``}</td>
          <td className="text-right text-nowrap">{`${currency} ${taxAmount? taxAmount:0}`}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Wallet Amount Used</td>
          <td colSpan={2}></td>
          <td className="text-right text-nowrap">
            &#8722;
            {`${currency} ${
              walletAmount === null || walletAmount === undefined
                ? 0
                :parseFloat( walletAmount.toFixed(2))
            }`}
          </td>
        </tr>
        

        <tr  className={walletAmount > 0 && walletAmount !== null && walletAmount !== undefined ? "" :"font-weight-bold"}>
          <td colSpan={3}>Total</td>
          <td className="text-right text-nowrap">
            {/* {`${currency} ${isWalletBalanceUsed && subscriptionDiscount ? round(finalAmount - walletAmount-subscriptionDiscount) :isWalletBalanceUsed? round(finalAmount-walletAmount):subscriptionDiscount?round(finalAmount-subscriptionDiscount):round(finalAmount)}`} */}
            {`${currency} ${round(finalAmount)}`}
          </td>
        </tr>
        {walletAmount>0 && walletAmount !== null && walletAmount !== undefined?paymentMethod=="COD"  ?
        <tr className="font-weight-bold" >
          <td colSpan={3}>Amount to be Collected </td>
          <td className="text-right text-nowrap">
           
            {`${currency} ${round(finalAmount)}`}
          </td>
        </tr>
        
        :<>
         <tr className="font-weight-bold">
          <td colSpan={3}>Amount  paid </td>
          <td className="text-right text-nowrap">
            
            {`${currency} ${round(finalAmount)}`}
          </td>
        </tr>
        </>:<></>}

        <tr>
          <td>Cart ID</td>
          <td>{transactionId ?? ""}</td>
          <td className="text-right text-nowrap">Payment Status</td>
          <td className="text-right">{paymentMethod=="COD_MAMOPAY"?(mamopayTransactionComplete?"Paid":"Pending"):paymentStatus ?? ""}</td>
        </tr>
        <tr>
        { isEditOrderPaymentMethodModalVisible?<> 
         
     </>
         :<> <td className="text-nowrap">Payment Gateway</td>
         <td >{paymentGateway ?? ""} </td>
         <td className="text-right text-nowrap">Payment Method</td>
          <td className="text-right">{paymentMethod ?? ""}
          
          {paymentGateway=="COD" && draftOrder && serviceReference &&<div>
          <button className="btn button btn" style={{ border: " 1px solid #0CA789", color: "#0CA789",paddingLeft:"15px" }}
                onClick={onEditPaymentMethod}
               
              >
                Edit
              </button>
                
              </div>}
          
          </td>
          </>
}

        </tr>
        {additionalDiscount>0?<tr>
        <td className="text-nowrap">Additional Discount<br/> Note</td>
         
          <td className="col-6">
         <textarea 
      value={additionalDiscountNote ?? ""}
      
      id="CODMamoNotes"
      name="CODMamoNotes"
      placeholder="COD MamoPay Notes"
      readOnly={true}
      className="form-control py-2" >
      </textarea>
      </td>
         <td className="text-right text-nowrap">Admin User</td>
          <td className="text-right">{orderCreatedByName ?? ""}</td>
        </tr>:<></>}

        
      </tbody>
    </table>
    {paymentMethod=="COD_MAMOPAY"?<> <div className="row ml-3 mt-2">
          
            <p className="h6">CodMamoPay Notes</p>
          
           
        <div className="col-6">
          
    <textarea 
      value={codMamopayNotesEdit}
      
      id="CODMamoNotes"
      name="CODMamoNotes"
      placeholder="COD MamoPay Notes"
      readOnly={true}
      className="form-control py-2" >
      </textarea>
           </div>
             <div>
              <span
                onClick={onEditCODMamoPayNotes}
                style={{ cursor: "pointer", color: "#2D9BF0" }}
              >
                Edit
              </span>
                
              </div>       
    </div></>:<></>}

    {paymentMethod=="COD_MAMOPAY"?
    <div className="row ml-3 mt-2">
    <div className="form-group col-6">
          <div className="input-group">
              <input
                value={true}
                checked={mamopayTransactionComplete || false}
                
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
                readOnly={true}
              />
               &nbsp;&nbsp; Is this Mamopay Transaction Complete
               </div> 
    </div>
    
    </div>
    :<></>}

    <EditOrderNotes
          title={"COD MamoPay  Note"}
          orderNotes={codMamopayNotesEdit}
          onHide={handleHideEditCODViewModal}
          onSave={handleSaveCODMamopayNotesNotes}
          isVisible={isEditOrderCODMamopayNotesViewModalVisible}
        />
        
    </>
  );
  return (
    <div className="container px-0">
      <div className="row">
        <div className="col">
          <p className="h6">Payment Information</p>
        </div>
        {/*
        Currently not showing edit option for payment details. 
        <div className="col text-right">
          <span
            onClick={onEdit}
            style={{ cursor: "pointer", color: "#2D9BF0" }}
          >
            Edit
          </span>
        </div> 
        */}
      </div>
      <div className="row">
        <div className="col">
          {paymentDetails ? (<>
           { paymentDetailsTable }
           {isEditOrderPaymentMethodModalVisible?paymentGatewayandMethodForm:<></>}
           </>
          ) : (
            <span className="placeholder">
              No payment information for the order!
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentInformation);
