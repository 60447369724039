import React from "react";
import PropTypes from "prop-types";
import { compareStrings } from "utils/commons";

function SelectAgent(props) {
  const { agents, selectedAgentId, onAgentSelect, isDisabled, isHomeCare=false } = props;

  const getAgentFullName = (agent) => {
    const { firstName, lastName, userName } = agent;
    return firstName && lastName ? `${firstName} ${lastName}` : userName;
  };

  agents.sort(
    (firstAgent, secondAgent) =>
      compareStrings(firstAgent.firstName, secondAgent.firstName, true) ||
      compareStrings(firstAgent.lastName, secondAgent.lastName, true)
  );

  const selectedAgent = agents.find((agent) => agent.id === selectedAgentId);
  // if (selectedAgent)
  //   console.log(
  //     `SelectAgent:: agent found with agentId: ${selectedAgentId} agent: ${selectedAgent.name}`
  //   );
  // else
  //   console.log(
  //     `SelectAgent:: agent not found with agentId: ${selectedAgentId}`
  //   );

  const titleOption = (
    <option key={-1} value={-1} hidden={true}>
      {isHomeCare?"Select Home Care":"Select Agent"}
    </option>
  );

  const agentOptions = agents.map((agent) => (
    <option key={agent.id} value={agent.id}>
      {getAgentFullName(agent)}
    </option>
  ));

  return (
    <select
      value={selectedAgentId ?? ""}
      onChange={(event) => onAgentSelect(Number(event.target.value))}
      name="select_agents"
      className="form-control"
      disabled={isDisabled}
    >
      {!selectedAgent && titleOption}
      {agentOptions}
    </select>
  );
}

SelectAgent.propTypes = {
  agents: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAgentId: PropTypes.number,
  onAgentSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectAgent.defaultProps = {
  agents: [],
  selectedAgentId: "",
  onAgentSelect: (f) => f,
  isDisabled: false,
};

export default SelectAgent;
