import React, { useState } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import PhoenixAPI from "utils/PhoenixAPI";
import logo_img from "assets/img/logo.png";

function ForgotPassword(props) {
  const [emailAddress, setEmailAddress] = useState("");
  const [isLoading, setLoading] = useState(false);

  const callBackend = async () => {
    try {
      setLoading(true);
      await PhoenixAPI.get("/forgot-password-email", {
        params: { email: emailAddress },
      });
      alert("Mail sent successfully.");
    } catch (error) {
      console.log("Error on calling the API", error);
      alert("Some error occurred!");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("handleSubmit:: ", emailAddress);
    callBackend();
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <img src={logo_img} alt="logo" className="valeo-logo"></img>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email address *</label>
            <input
              type="email"
              className={"form-control "}
              name="emailAddress"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              placeholder="Your email address"
            />
          </div>
          <button type="submit" className="form-control login-button button">
            {isLoading ? (
              <CircularProgress
                size={15}
                className="valeo-loader"
              ></CircularProgress>
            ) : (
              "Submit"
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
