import routes from "routes";

export function showNotification(id) {
  if (!id) return;

  const currentPath = window.location.href.split("/admin")[1];

  for (let route of routes) {
    if (
      route.notificationId === id &&
      route.path.localeCompare(currentPath) !== 0
    ) {
      route.hasNotification = true;
      return;
    }

    if (route.hasSubroute) {
      for (let subroute of route.subroutes) {
        if (subroute.notificationId === id) {
          subroute.hasNotification = true;
          return;
        }
      }
    }
  }
}

export function hideNotification(id) {
  if (!id) return;

  for (let route of routes) {
    if (route.notificationId === id) {
      route.hasNotification = false;
      return;
    }

    if (route.hasSubroute) {
      for (let subroute of route.subroutes) {
        if (subroute.notificationId === id) {
          subroute.hasNotification = false;
          return;
        }
      }
    }
  }
}
