import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectProfessional.propTypes = {
  professionals: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedProfessionalId: PropTypes.number,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectProfessional.defaultProps = {
  professionals: [],
  placeholder: "Select...",
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectProfessional(props) {
  const {
    professionals,
    selectedProfessionalId,
    placeholder,
    onSelect,
    isDisabled,
  } = props;
  professionals.sort((first, second) =>
    compareStrings(first.professionalName, second.professionalName, true)
  );

  const findProfessionalById = (id) => professionals.find((p) => p.id === id);

  const createOption = (professional) => {
    if (!professional) return;
    return {
      key: professional.id,
      value: professional.id,
      label: professional.professionalName,
    };
  };

  const options = professionals
    .filter((p) => p.isEnable ?? false)
    .map(createOption);
  const selectedProfessional = findProfessionalById(selectedProfessionalId);
  const selectStyles = { container: (base) => ({ ...base, flex: 1 }) };

  return (
    <Select
      key={`select_professional_${selectedProfessionalId}`}
      value={createOption(selectedProfessional)}
      onChange={(option) => onSelect(option.value)}
      options={options}
      styles={selectStyles}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
}

export default SelectProfessional;
