import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import FAQForm from "components/Common/FAQForm";
import { fetchCountries } from "services/RegionService";
import {
  fetchMedicinesSummaryByCountry,
  fetchMedicineById,
  saveMedicine,
  updateMedicine,
} from "services/MedicineService";
import SubscriptionForm from "components/Common/SubscriptionForm";
import ToggleSwitch from "components/Common/ToggleSwitch";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];
const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];

const MEDICINE_CATEGORY_OPTIONS = [
  {
    key: "Prescription",
    value: "Prescription",
    text: "Prescription",
  },
  {
    key: "Non-Prescription",
    value: "Non-Prescription",
    text: "Non-Prescription",
  },
];



class Medicines extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      medicines: [],
      rankOptions: [],
      medicine: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isMedicineViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
      pricingTypeDropdown: "",
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name:"UID",
        selector:"medicineUid",
        width: "150px",
        wrap:true,
        sortable: true,

      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "External Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (medicine) => (
          <p style={{ textAlign: "right" }}>{medicine.externalNameArabic}</p>
        ),
      },
      {
        name: "Category",
        selector: "medicineType",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (medicine) => <Status isActive={medicine.status === "Active"} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (medicine) => this.renderViewButton(medicine.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry && selectedCountry !== prevState.selectedCountry) {
      this.fetchMedicines();
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      this.showLoading();
      const pageName = Page.MEDICINES;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
      this.showErrorNotification(
        "Error on fetching access control information! " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchMedicines = async () => {
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchMedicines:: Invalid country!", { selectedCountry });
      return;
    }

    this.showLoading();
    try {
      const { data: medicines = [] } =
        (await fetchMedicinesSummaryByCountry(countryId)) || [];
      this.setState({ medicines });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching medicines: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      this.showLoading();
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("fetchCountries:: Error on fetching countries!");
      this.showErrorNotification(
        "Error on fetching countries!" + error?.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const medicine = { ...this.state.medicine, image };
      this.setState({ medicine });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const medicine = {
        ...this.state.medicine,
        image_src: [reader.result],
      };
      this.setState({ medicine });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const medicine = { ...this.state.medicine };
    if (numberInputs.includes(input.name))
      medicine[input.name] = input.value ? Number(input.value) : "";
    else medicine[input.name] = input.value;
    this.setState({ medicine });
  };

  handleQuestionsUpdated = (questions = []) => {
    const medicine = { ...this.state.medicine, faqs: questions };
    this.setState({ medicine });
  };

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    const medicine = {
      ...this.state.medicine,
      subscriptionItems: subscriptionItems,
    };
    this.setState({ medicine });
  };

  handleAddNewMedicine = () => {
    this.setState({ isNewMedicine: true }, () => this.showMedicineViewModal());
  };

  handleViewMedicine = async (medicineId) => {
    try {
      this.showLoading();
      const { data: medicine } = await fetchMedicineById(medicineId);
      if (!medicine) {
        console.log(`Medicine with id ${medicineId} is not found!`);
        return;
      }

      medicine.image_src = medicine.image;
      if (medicine.currency === null || medicine.currency === undefined) {
        medicine.currency = this.state.selectedCountry?.currency;
      }
      const monthlyPricing = medicine.subscriptionItems ?? [];
      this.setState({ medicine, monthlyPricing, isNewMedicine: false }, () =>
        this.showMedicineViewModal()
      );
    } catch (error) {
      console.log(
        "handleViewMedicine:: Error on fetching medicine by id!",
        error
      );
      this.showErrorNotification(
        "Error on fetching medicine by id! " + error.message ?? ""
      );
    } finally {
      this.hideLoading();
    }
  };

  showMedicineViewModal = () => {
    const { selectedCountry, isNewMedicine } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewMedicine) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const medicine = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        medicineType: "Non-Prescription",
        rank,
        internalCategory: "None",
      };
      this.setState({
        medicine,
        rankOptions,
        isMedicineViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isMedicineViewModalVisible: true });
    }
  };

  hideMedicineViewModal = () => {
    this.setState({ isMedicineViewModalVisible: false });
    this.resetForm();
  };

  handleSaveMedicine = async () => {
    const { selectedCountry, medicine } = this.state;
    medicine.meta_title_arabic=medicine.meta_title_arabic||medicine.metaTitle
    medicine.meta_description_arabic=medicine.meta_description_arabic||medicine.metaDescription
    medicine.keywords_arabic=medicine.keywords_arabic||medicine.keywords
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    } else if (!medicine.enableOneTime && !medicine.enableSubscription) {
      this.showErrorNotification(
        "At least one type of pricing should be enabled!"
      );
      return;
    }

    try {
      this.showLoading();
      const isValidId = medicine.id !== null && medicine.id !== undefined;
      if (medicine.internalCategory == "None") {
        medicine.internalCategory = null;
      }
      const response = isValidId
        ? await updateMedicine(medicine)
        : await saveMedicine(medicine);

      this.hideMedicineViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Medicine Updated Successfully."
          : "Medicine Created Successfully."
      );
      this.fetchMedicines();
    } catch (error) {
      let seoExceptionCode = error.response.status;
      if (seoExceptionCode === 500) {
        this.showErrorNotification("seo url already exists: " + error);
      } else {
        this.showErrorNotification("Some error occurred: " + error.message);
      }
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { medicine = {} } = this.state;
    const errors = {};

    const {
      status,
      internalName,
      externalNameEnglish,
      externalNameArabic,
      rank,
      medicineType,
      country,

      shortDescriptionEnglish,
      shortDescriptionArabic,
      longDescriptionEnglish,
      longDescriptionArabic,
      keyHighlightsEnglish,
      keyHighlightsArabic,
      disclaimerEnglish,
      disclaimerArabic,
      image,

      retailPrice,
      finalPrice,
      discountType,
      discountValue,
      seoUrl,
      metaTitle,
      metaDescription,
      subscriptionItems,
      keywords,
      zohoAccountId
    } = medicine;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (!medicineType) errors.medicineType = "Category cannot be blank";
    
     if(zohoAccountId  && zohoAccountId.length>45)
     errors.zohoAccountId="Zoho Account Id is limited to 45 characters";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    // Validate Descriptions Section
    if (shortDescriptionEnglish && shortDescriptionEnglish.length > 1000)
      errors.shortDescriptionEnglish =
        "Short Description (English) is limited to a 1000 characters";

    if (shortDescriptionArabic && shortDescriptionArabic.length > 1000)
      errors.shortDescriptionArabic =
        "Short Description (Arabic) is limited to a 1000 characters";

    if (longDescriptionEnglish && longDescriptionEnglish.length > 20000)
      errors.longDescriptionEnglish =
        "Long Description (English) is limited to a 20000 characters";

    if (longDescriptionArabic && longDescriptionArabic.length > 20000)
      errors.longDescriptionArabic =
        "Long Description (Arabic) is limited to a 20000 characters";

    if (keyHighlightsEnglish && keyHighlightsEnglish.length > 5000)
      errors.keyHighlightsEnglish =
        "Key Highlights (English) is limited to a 5000 characters";

    if (keyHighlightsArabic && keyHighlightsArabic.length > 5000)
      errors.keyHighlightsArabic =
        "Key Highlights (Arabic) is limited to a 5000 characters";

    if (disclaimerEnglish && disclaimerEnglish.length > 5000)
      errors.disclaimerEnglish =
        "Disclaimer (English) is limited to a 5000 characters";

    if (disclaimerArabic && disclaimerArabic.length > 5000)
      errors.disclaimerArabic =
        "Disclaimer (Arabic) is limited to a 5000 characters";

    if (!image) errors.image = "Image cannot be blank";
    else if (image && image.size && image.size > TWO_MEGA_BYTE)
      errors.image = "Image should be less than 2 MB!";

    // Validate Pricing Section
    if (medicine.enableOneTime) {
      if (
        retailPrice === null ||
        retailPrice === undefined ||
        retailPrice.toString().trim() === ""
      )
        errors.retailPrice = "Retail Price cannot be blank";
      else if (Number.isNaN(Number(retailPrice)))
        errors.retailPrice = "Retail Price should be a valid number";
      else if (!this.isInBetween(Number(retailPrice), 0, 99999999))
        errors.retailPrice = "Retail Price should be bewteen 0 to 99999999";

      if (
        finalPrice === null ||
        finalPrice === undefined ||
        finalPrice.toString().trim() === ""
      )
        errors.finalPrice = "Final Price cannot be blank";
      else if (Number.isNaN(Number(finalPrice)))
        errors.finalPrice = "Final Price should be a valid number";
      else if (!this.isInBetween(Number(finalPrice), 0, 99999999))
        errors.finalPrice = "Final Price should be bewteen 0 to 99999999";

      if (!discountType) errors.discountType = "Discount Type cannot be blank";

      if (
        discountValue === null ||
        discountValue === undefined ||
        discountValue.toString().trim() === ""
      )
        errors.discountValue = "Discount Value cannot be blank";
      else if (Number.isNaN(Number(discountValue)))
        errors.discountValue = "Discount Value should be a valid number";
      else if (!this.isInBetween(Number(discountValue), 0, 99999999))
        errors.discountValue = "Discount Value should be bewteen 0 to 99999999";
    }

    if (seoUrl && seoUrl.length > 100)
      errors.seoUrl = "URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > 100)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > 200)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > 500)
      errors.keywords = "keywords can not contain more than 500 characters!";

    if (medicine.enableSubscription) {
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscriptionItems = "At least one active item is required!";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewMedicine } = this.state;
    const medicines = this.state.medicines ?? [];
    const ranks = medicines.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewMedicine ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      medicine: {},
      errors: {},
      editables: {},
      pricingTypeDropdown: "",
      monthlyPricing: [],
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewMedicine(id);
        }}
      >
        View
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewMedicine ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredMedicines = () => {
    const medicines = this.state.medicines ?? [];
    return this.getSearchedMedicines(medicines);
  };

  getSearchedMedicines = (medicines) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return medicines;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return medicines.filter((medicine) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        medicineType,
        rank,
      } = medicine;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (medicineType && medicineType.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  render() {
    const {
      medicine,
      isNewMedicine,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredMedicines = this.getFilteredMedicines() ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={medicine?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={medicine?.status}
                onChange={(event, value) => {
                  this.setState({
                    medicine: {
                      ...medicine,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewMedicine && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={medicine?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.externalNameEnglish}
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={medicine?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.externalNameArabic}
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={medicine?.rank}
                onChange={(event, value) => {
                  this.setState({
                    medicine: { ...medicine, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewMedicine && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="medicineType">Category</label>
            <div className="input-group">
              <Dropdown
                value={medicine?.medicineType}
                onChange={(event, value) => {
                  this.setState({
                    medicine: { ...medicine, medicineType: value.value },
                  });
                }}
                id="medicineType"
                name="medicineType"
                placeholder="Category"
                search
                selection
                options={MEDICINE_CATEGORY_OPTIONS}
                disabled={!isNewMedicine && !editables.medicineType}
                className={
                  "form-control" + (errors.category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("medicineType")}
            </div>
            <span className="help-block">{errors.medicineType}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewMedicine
                  ? (selectedCountry && selectedCountry.country_name) || ""
                  : this.findCountry(medicine?.country)?.country_name || ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="zohoAccountId">
            Zoho Account ID 
            </label>
            <div className="input-group">
              <input
                value={medicine?.zohoAccountId || ""}
                onChange={this.handleChange}
                id="zohoAccountId"
                name="zohoAccountId"
                type="number"
                maxLength="45"
                placeholder="Zoho Account Id"
                className={
                  "form-control py-2" +
                  (errors.zohoAccountId ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewMedicine && !editables.zohoAccountId}
              />
              {this.renderPencilIcon("zohoAccountId")}
            </div>
            <span className="help-block">{errors.zohoAccountId}</span>
          </div>

        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={medicine?.internalCategory||"None"}
                onChange={(event, value) => {
                  this.setState({
                    medicine: { ...medicine, internalCategory: value.value },
                  });
                }}
                id="internalCategory"
                placeholder="internalCategory"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewMedicine && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
      </div>
    );

    const formDescriptions = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="shortDescriptionEnglish">
              Short Description (English)
            </label>
            <div className="input-group">
              <input
                value={medicine.shortDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="shortDescriptionEnglish"
                name="shortDescriptionEnglish"
                type="text"
                placeholder="Short Description in English"
                className={
                  "form-control py-2" +
                  (errors.shortDescriptionEnglish ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.shortDescriptionEnglish}
              />
              {this.renderPencilIcon("shortDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.shortDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="shortDescriptionArabic">
              Short Description (Arabic)
            </label>
            <div className="input-group">
              <input
                value={medicine.shortDescriptionArabic || ""}
                onChange={this.handleChange}
                id="shortDescriptionArabic"
                name="shortDescriptionArabic"
                type="text"
                placeholder="Short Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.shortDescriptionArabic ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.shortDescriptionArabic}
              />
              {this.renderPencilIcon("shortDescriptionArabic")}
            </div>
            <span className="help-block">{errors.shortDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="longDescriptionEnglish">
              Long Description (English)
            </label>
            <div className="input-group">
              <textarea
                value={medicine.longDescriptionEnglish || ""}
                onChange={this.handleChange}
                id="longDescriptionEnglish"
                name="longDescriptionEnglish"
                placeholder="Long Description in English"
                className={
                  "form-control py-2" +
                  (errors.longDescriptionEnglish ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.longDescriptionEnglish}
              ></textarea>
              {this.renderPencilIcon("longDescriptionEnglish")}
            </div>
            <span className="help-block">{errors.longDescriptionEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="longDescriptionArabic">
              Long Description (Arabic)
            </label>
            <div className="input-group">
              <textarea
                value={medicine.longDescriptionArabic || ""}
                onChange={this.handleChange}
                id="longDescriptionArabic"
                name="longDescriptionArabic"
                placeholder="Long Description in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.longDescriptionArabic ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.longDescriptionArabic}
              ></textarea>
              {this.renderPencilIcon("longDescriptionArabic")}
            </div>
            <span className="help-block">{errors.longDescriptionArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keyHighlightsEnglish">
              Key Highlights (English)
            </label>
            <div className="input-group">
              <textarea
                value={medicine.keyHighlightsEnglish || ""}
                onChange={this.handleChange}
                id="keyHighlightsEnglish"
                name="keyHighlightsEnglish"
                placeholder="Key Highlights in English"
                className={
                  "form-control py-2" +
                  (errors.keyHighlightsEnglish ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.keyHighlightsEnglish}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsEnglish")}
            </div>
            <span className="help-block">{errors.keyHighlightsEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keyHighlightsArabic">Key Highlights (Arabic)</label>
            <div className="input-group">
              <textarea
                value={medicine.keyHighlightsArabic || ""}
                onChange={this.handleChange}
                id="keyHighlightsArabic"
                name="keyHighlightsArabic"
                placeholder="Key Highlights in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.keyHighlightsArabic ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.keyHighlightsArabic}
              ></textarea>
              {this.renderPencilIcon("keyHighlightsArabic")}
            </div>
            <span className="help-block">{errors.keyHighlightsArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="disclaimerEnglish">Disclaimer (English)</label>
            <div className="input-group">
              <textarea
                value={medicine.disclaimerEnglish || ""}
                onChange={this.handleChange}
                id="disclaimerEnglish"
                name="disclaimerEnglish"
                placeholder="Disclaimer in English"
                className={
                  "form-control py-2" +
                  (errors.disclaimerEnglish ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.disclaimerEnglish}
              ></textarea>
              {this.renderPencilIcon("disclaimerEnglish")}
            </div>
            <span className="help-block">{errors.disclaimerEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="disclaimerArabic">Disclaimer (Arabic)</label>
            <div className="input-group">
              <textarea
                value={medicine.disclaimerArabic || ""}
                onChange={this.handleChange}
                id="disclaimerArabic"
                name="disclaimerArabic"
                placeholder="Disclaimer in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.disclaimerArabic ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.disclaimerArabic}
              ></textarea>
              {this.renderPencilIcon("disclaimerArabic")}
            </div>
            <span className="help-block">{errors.disclaimerArabic}</span>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Image*</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={!isNewMedicine && !editables.image}
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("image")}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {medicine?.image_src && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={medicine.image_src}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );

    const formPricingOneTimeContent = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Retail Price*</label>
            <div className="input-group">
              <input
                value={medicine.retailPrice ?? ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="number"
                placeholder="Retail Price"
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.retailPrice}
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="finalPrice">Final Price*</label>
            <div className="input-group">
              <input
                value={medicine.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="number"
                placeholder="Final Price"
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.finalPrice}
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={medicine?.discountType}
                onChange={(event, value) => {
                  this.setState({
                    medicine: {
                      ...medicine,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewMedicine && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountValue">Discount Value*</label>
            <div className="input-group">
              <input
                value={medicine.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                className={
                  "form-control py-2" +
                  (errors.discountValue ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.discountValue}
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
      </>
    );

    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={medicine.enableOneTime ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  medicine: { ...medicine, enableOneTime: isToggled },
                })
              }
            />
          </div>
        </div>
        {medicine.enableOneTime && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscriptionItems}</span>
        <SubscriptionForm
          subscriptions={this.state.monthlyPricing || []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableSubscription"
              toggled={medicine.enableSubscription ?? false}
              disabled={!isEditable}
              onToggle={(isToggled) =>
                this.setState({
                  medicine: { ...medicine, enableSubscription: isToggled },
                })
              }
            />
          </div>
        </div>
        {medicine.enableSubscription && formPricingSubscriptionContent}
      </>
    );

    const formPricing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="currency">Currency</label>
            <div className="input-group">
              <input
                value={medicine?.currency}
                id="currency"
                name="currency"
                type="text"
                className={
                  "form-control py-2" + (errors.currency ? " has-error" : "")
                }
                disabled={true}
              />
            </div>
            <span className="help-block">{errors.currency}</span>
          </div>
        </div>
        {formPricingOneTime}
        {formPricingSubscription}
      </div>
    );

    const formSeoMarketing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.seoUrl) || ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
          {/* <div className="form-group col">
            <label htmlFor="url_name_arabic">Seo URL Arabic</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.url_name_arabic) || ""}
                onChange={this.handleChange}
                id="url_name_arabic"
                name="url_name_arabic"
                type="text"
                placeholder="Seo URL Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.url_name_arabic ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.url_name_arabic}
              />
              {this.renderPencilIcon("url_name_arabic")}
            </div>
            <span className="help-block">{errors.url_name_arabic}</span>
          </div> */}
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="meta_title_arabic">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.meta_title_arabic) || ""}
                onChange={this.handleChange}
                id="meta_title_arabic"
                name="meta_title_arabic"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.meta_title_arabic ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.meta_title_arabic}
              />
              {this.renderPencilIcon("meta_title_arabic")}
            </div>
            <span className="help-block">{errors.meta_title_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="meta_description_arabic">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={(medicine && medicine.meta_description_arabic) || ""}
                onChange={this.handleChange}
                id="meta_description_arabic"
                name="meta_description_arabic"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.meta_description_arabic ? " has-error" : "")
                }
                disabled={!isNewMedicine && !editables.meta_description_arabic}
              />
              {this.renderPencilIcon("meta_description_arabic")}
            </div>
            <span className="help-block">{errors.meta_description_arabic}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(medicine && medicine.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywords_arabic">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(medicine && medicine.keywords_arabic) || ""}
                onChange={this.handleChange}
                id="keywords_arabic"
                name="keywords_arabic"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.keywords_arabic ? " has-error" : "")
                }
                readOnly={!isNewMedicine && !editables.keywords_arabic}
              ></textarea>
              {this.renderPencilIcon("keywords_arabic")}
            </div>
            <span className="help-block">{errors.keywords_arabic}</span>
          </div>
        </div>
      </div>
    );

    const formFaq = (
      <div className="container mt-4">
        <FAQForm
          questions={medicine?.faqs || []}
          onQuestionUpdated={this.handleQuestionsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewMedicine}
                disabled={!this.hasPageAccessRight("edit")}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredMedicines || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredMedicines.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isMedicineViewModalVisible}
          onHide={this.hideMedicineViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Medicine: </span>
                {medicine?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveMedicine}
              disabled={!this.hasPageAccessRight("edit")}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_medicines">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_form_descriptions" title="Descriptions">
                {formDescriptions}
              </Tab>
              <Tab eventKey="key_form_pricing" title="Pricing">
                {formPricing}
              </Tab>
              <Tab eventKey="key_seo_form" title="SEO Marketing">
                {formSeoMarketing}
              </Tab>
              <Tab eventKey="key_form_faq" title="FAQ">
                {formFaq}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Medicines);
