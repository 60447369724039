import React, { Component } from 'react';
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import Status from "components/Common/Status";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AlertBox from "components/AlertBox/AlertBox";
import { Dropdown } from "semantic-ui-react";
import SubCategoryForm from "./SubCategoryForm";
import { fetchAllCategories } from "services/CMSNavigationService"
import PropTypes from "prop-types";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const Highlight_Options = [
  {
    text: "YES",
    value: true,
    key: "YES",
  },
  {
    text: "NO",
    value: false,
    key: "NO",
  },
];

const MultiCategory_Type = [
  {
    key: "AT_HOME_TESTING",
    value: "AT_HOME_TESTING",
    text: "At Home Testing"
  },
  {
    key: "IV_AND_PHYSIO",
    value: "IV_AND_PHYSIO",
    text: "IV and Physio"
  },
  {
    key: "SUPPLEMENT",
    value: "SUPPLEMENT",
    text: "Supplements"
  },
  {
    key: "NEW_SUB_CATEGORY",
    value: "NEW_SUB_CATEGORY",
    text: "New Sub Category"
  }
];



const CATEGORY_TYPE = [
  {
    key: "MULTICATEGORY",
    value: "MULTICATEGORY",
    text: "Multi Category"
  },
  {
    key: "REDIRECTION",
    value: "REDIRECTION",
    text: "Redirect"
  }
]



class MobileWebsiteCategories extends Component {

  constructor(props) {
    super(props);
    const { categories = [], type, country, isEditable } = this.props;
    console.log('Constructor props:', props, categories);
    this.state = {
      categories: categories,
      category: {},
      rankOptions: [],
      errors: {},
      editables: {},
      isSaving: false,
      isNewCategory: false
    }


    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
      },
      {
        name: "Category Name",
        selector: "nameEnglish",
        width: "100px",
        sortable: true,
      },
      {
        name: "Category Type",
        selector: "categoryType",
        wrap: true,
        sortable: true,
      },
      {
        name: "Category Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
      },
      {
        name: "Category Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (row) => <Status isActive={row.isActive ?? false} />,
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (row) => this.renderViewButton(row.id),
      },
    ]

  }

  renderViewButton = (id) => (
    <div>
      <button
        onClick={() => this.handleView(id)}
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
      >
        View
      </button>
    </div>
  );

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      this.setState({ categories: this.props.categories });
    }


  }

  handleView = (id) => {
    const { categories } = this.state;
    console.log("categories", categories);
    const category = categories.find((it) => it.id === id);
    console.log("category", category);
    if (category) {
      //  corporatePartner.partnerLogoSrc = corporatePartner.partnerLogo ?? null;
      this.setState({ category, isNewCategory: false }, () =>
        this.showCategoryViewModal()
      );
    }
  }

  handleAddNew = () => {
    this.setState({ isNewCategory: true }, () =>
      this.showCategoryViewModal()
    );
  };



  renderPencilIcon = (fieldName) =>
    this.state.category.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  getCategoriesForCurrentCountry = () => {
    const { categories } = this.state;
    const { country } = this.props;

    if (country && categories && categories.length) {
      const categoriesForCountry = categories.filter(
        (it) => it.countryId === country.id
      );
      return categoriesForCountry;
    }

    return [];
  };


  getRankOptions = () => {
    const { isNewCategory } = this.state;
    const categoriesForCurrentCountry =
      this.getCategoriesForCurrentCountry();
    const ranks = categoriesForCurrentCountry
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks);
    const maxRank = Math.max(
      categoriesForCurrentCountry.length,
      ...ranks
    );
    const until = isNewCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    return rankOptions;
  };

  showCategoryViewModal = () => {
    const { country, type } = this.props;
    const { isNewCategory } = this.state;
    const rankOptions = this.getRankOptions() || [];
    console.log("rank options", rankOptions);

    if (isNewCategory) {

      const category = {
        countryId: country?.id,
        type: type,
        rank: Math.max(...rankOptions.map((it) => it.value)),
        isActive: false,
      };
      console.log("category is", category);
      this.setState({ category, rankOptions }, () => this.showModal());
    } else {
      console.log("show modal is", this.state.category);
      this.setState({ rankOptions }, () => this.showModal());
    }
  };
  showModal = () => {
    this.setState({ showModal: true });
  };

  hideModal = () => {
    this.setState({ showModal: false });
    this.resetForm();
  };



  resetForm = () => {
    this.setState({ category: {}, errors: {}, editables: {}, isSaving: false });
  }

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  validate = () => {
    const errors = {};
    const { category } = this.state;
    if (!category.nameEnglish)
      errors.nameEnglish = "category name should not be blank";
    if (!category.nameArabic)
      errors.nameArabic = "category name Arabic should not be blank";
    if (!category.categoryType)
      errors.categoryType = "category type should not be blank";
    if (!category.rank)
      errors.rank = "category rank should not be blank";
    if (category.categoryType && category.categoryType == "REDIRECTION") {
      if (!category.redirectionLinkEnglish) {
        errors.redirectionLinkEnglish = "redirection link English should not be blank";
      }
      if (!category.redirectionLinkArabic) {
        errors.redirectionLinkArabic = "redirection link Arabic should not be blank";
      }
    }
    if (category.categoryType && category.categoryType == "MULTICATEGORY") {
      if (!category.multiCategoryType) {
        errors.multiCategoryType = "multi category type should not be blank";
      }
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }
  handleSave = async () => {
    if (!this.props.country) {
      this.showErrorNotification("Please select a country!");
      this.setState({ isSaving: false });
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }
    const { category } = this.state;
    const { type, country } = this.props;
    try {
      this.showLoading();

      const payload = { ...category };
      console.log("payload is", payload)
      const url = category.id
        ? `/navigation-menu/${category.id}`
        : "/navigation-menu";

      const response = category.id ? await PhoenixAPI.patch(url, payload) : await PhoenixAPI.post(url, payload);
      this.hideModal();
      this.showSuccessNotification("Saved Successfully.");
      const allCategories = await fetchAllCategories();
      if (type == "MOBILE") {
        const mobileCategories = allCategories.filter((category) => category.type === "MOBILE" && category.countryId == country?.id) || [];
        this.setState({ categories: mobileCategories });
      }
      else {
        const desktopCategories = allCategories.filter((category) => category.type === "DESKTOP" && category.countryId == country?.id) || [];
        this.setState({ categories: desktopCategories });
      }

    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.setState({ isSaving: false }, this.hideLoading);
    }


  }


  handleCategoryUpdated = (allSubCategories = []) => {
    const category = {
      ...this.state.category,
      subCategories: allSubCategories,
    };

    this.setState({ category });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });




  render() {
    const { type, isEditable, country } = this.props;
    const { isSaving, category, isNewCategory, editables, errors, categories } = this.state;
    const targetTypes = ["IV_AND_PHYSIO", "AT_HOME_TESTING", "SUPPLEMENT"];
    // const filteredCategories=this.getFilteredCategories();
    const categoryForm = (<div className="container mt-4">

      <div className="row">

        <div className="form-group col">
          <label>Category Name English</label>
          <div className="input-group">
            <input
              type="text"
              className={
                "form-control py-2" + (errors.nameEnglish ? " has-error" : "")
              }
              value={category?.nameEnglish}
              readOnly={!isNewCategory && !editables.nameEnglish}
              onChange={(e) =>
                this.setState({
                  category: { ...category, nameEnglish: e.target.value }
                })
              }
            />
            {this.renderPencilIcon("nameEnglish")}
          </div>
          <span className="help-block">{errors.nameEnglish}</span>
        </div>
        <div className="form-group col">
          <label>Category Name Arabic</label>
          <div className="input-group">
            <input
              type="text"
              className={
                "form-control py-2 input-arabic" +
                (errors.nameArabic ? " has-error" : "")
              }
              value={category?.nameArabic}
              readOnly={!isNewCategory && !editables.nameArabic}
              onChange={(e) =>
                this.setState({
                  category: { ...category, nameArabic: e.target.value },
                })
              }
            />
            {this.renderPencilIcon("nameArabic")}
          </div>
          <span className="help-block">{errors.nameArabic}</span>
        </div>


      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="rank">Rank*</label>
          <div className="input-group">
            <Dropdown
              value={category?.rank}
              onChange={(event, value) => {
                this.setState({
                  category: {
                    ...category,
                    rank: value.value,
                  },
                });
              }}
              id="rank"
              placeholder="Rank"
              search
              selection
              options={this.state.rankOptions}
              disabled={!isNewCategory && !editables.rank}
              className={"form-control" + (errors.rank ? " has-error" : "")}
            />
            {this.renderPencilIcon("rank")}
          </div>
          <span className="help-block">{errors.rank}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="status">Status*</label>
          <div className="input-group">
            <Dropdown
              value={category?.isActive}
              onChange={(event, value) => {
                this.setState({
                  category: {
                    ...category,
                    isActive: value.value,
                  },
                });
              }}
              id="status"
              name="status"
              placeholder="Status"
              search
              selection
              options={STATUS_OPTIONS}
              disabled={!isNewCategory && !editables.status}
              className={"form-control" + (errors.status ? " has-error" : "")}
            />
            {this.renderPencilIcon("status")}
          </div>
          <span className="help-block">{errors.status}</span>
        </div>
      </div>

      <div className="row">
        <div className="form-group col">
          <label>Category Type</label>
          <div className="input-group">
            <Dropdown
              value={category?.categoryType}
              onChange={(event, value) => {
                this.setState({
                  category: {
                    ...category,
                    categoryType: value.value,
                  },
                });
              }}
              id="categoryType"
              name="categoryType"
              placeholder="categoryType"
              search
              selection
              options={CATEGORY_TYPE}
              disabled={!isNewCategory && !editables.categoryType}
              className={"form-control" + (errors.status ? " has-error" : "")}
            />
            {this.renderPencilIcon("categoryType")}
          </div>
          <span className="help-block">{errors.categoryType}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="highlight">Highlight Category</label>
          <div className="input-group">
            <Dropdown
              value={category?.isHighlightCategory ?? false}
              onChange={(event, value) => {
                this.setState({
                  category: {
                    ...category,
                    isHighlightCategory: value.value,
                  },
                });
              }}
              id="isHighlightCategory"
              name="isHighlightCategory"
              placeholder="isHighlightCategory"
              search
              selection
              options={Highlight_Options}
              disabled={!isNewCategory && !editables.isHighlightCategory}
              className={"form-control" + (errors.isHighlightCategory ? " has-error" : "")}
            />
            {this.renderPencilIcon("isHighlightCategory")}
          </div>
          <span className="help-block">{errors.isHighlightCategory}</span>
        </div>
      </div>
      {category?.categoryType == "REDIRECTION" ?
        (<div className="row">
          <div className="form-group col-6">

            <label>Redirection Link English</label>
            <div className="input-group">
              <input
                type="text"
                className={
                  "form-control py-2" +
                  (errors.redirectionLinkEnglish ? " has-error" : "")
                }
                value={category?.redirectionLinkEnglish}
                onChange={(e) =>
                  this.setState({
                    category: { ...category, redirectionLinkEnglish: e.target.value },
                  })
                }
                readOnly={!isNewCategory && !editables.redirectionLinkEnglish}
              />
              {this.renderPencilIcon("redirectionLinkEnglish")}
            </div>
            <span className="help-block">{errors.redirectionLinkEnglish}</span>
          </div>

          <div className="form-group col">
            <label>Redirection Link Arabic</label>
            <div className="input-group">
              <input
                type="text"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.redirectionLinkArabic ? " has-error" : "")
                }
                value={category?.redirectionLinkArabic}
                onChange={(e) =>
                  this.setState({
                    category: { ...category, redirectionLinkArabic: e.target.value },
                  })
                }
                readOnly={!isNewCategory && !editables.redirectionLinkArabic}
              />
              {this.renderPencilIcon("redirectionLinkArabic")}
            </div>
            <span className="help-block">{errors.redirectionLinkArabic}</span>
          </div>

        </div>) : (
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="highlight">Multi Category Type</label>
              <div className="input-group">
                <Dropdown
                  value={category?.multiCategoryType}
                  onChange={(event, value) => {
                    this.setState({
                      category: {
                        ...category,
                        multiCategoryType: value.value,
                      },
                    });
                  }}
                  id="multiCategoryType"
                  name="multiCategoryType"
                  placeholder="multiCategoryType"
                  search
                  selection
                  options={MultiCategory_Type}
                  disabled={!isNewCategory && !editables.multiCategoryType}
                  className={"form-control" + (errors.multiCategoryType ? " has-error" : "")}
                />
                {this.renderPencilIcon("multiCategoryType")}
              </div>
              <span className="help-block">{errors.multiCategoryType}</span>
            </div>

          </div>
        )}

      {(!targetTypes.some(type => category?.multiCategoryType == type)) && category?.categoryType == "MULTICATEGORY" ? <SubCategoryForm
        subCategories={category.subCategories}
        onSubCategoryUpdated={this.handleCategoryUpdated}
        type={this.props.type}

      /> : <></>}

    </div>);




    return (

      <div className="manage-package">
        <div className="page-header">
          <div className="actions">

            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNew}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>

          <p className="font-weight-bold">{type == "DESKTOP" ? "Desktop Website" : "Mobile Website"}</p>
          <DataTable
            data={categories}
            columns={this.columns}
            defaultSortField="id"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}

            highlightOnHover
            pagination
            responsive
            noHeader={this.state.searchApplied ? false : true}

            noDataComponent={
              this.state.loadError ? (
                <AlertBox
                  message={this.state.loadError}
                  error={true}
                ></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Details: </span>
                {category?.nameEnglish ?? ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              type="button"
              onClick={(event) =>
                this.setState({ isSaving: true }, this.handleSave)
              }
              disabled={!isEditable || isSaving}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_website_categories">
              <Tab eventKey="key_details_form" title="Details">
                {categoryForm}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>


    );
  }
}
MobileWebsiteCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  isEditable: PropTypes.bool.isRequired
};

MobileWebsiteCategories.defaultProps = {
  categories: [],
  type: "",
  isEditable: true
};


function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileWebsiteCategories);