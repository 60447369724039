import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import moment from "moment";
import { formatDateTime } from "utils/commons";
import { getUserId, getUserEmail, getUserName } from "services/UserService";
import PdfPreview from "components/Common/PdfPreview";
import CustomLoader from "components/CustomLoader/CustomLoader";
import "./viewMyTicket.scss";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const ticketCategoryOptions = [
  {
    name: "Order",
    value: "Order",
    label: "Order",
  },
  {
    name: "Location Issues",
    value: "Location Issues",
    label: "Location Issues",
  },
  {
    name: "Pricing",
    value: "Pricing",
    label: "Pricing",
  },
  {
    name: "Penalty",
    value: "Penalty",
    label: "Penalty",
  },
  {
    name: "Customer Issues",
    value: "Customer Issues",
    label: "Customer Issues",
  },
  {
    name: "COD orders",
    value: "COD orders",
    label: "COD orders",
  },
  {
    name: "Others",
    value: "Others",
    label: "Others",
  },
];
const REQUEST_OPTIONS = [
  {
    key: "Open",
    value: "open",
    label: "Open",
  },
  {
    key: "In Progress",
    value: "in progress",
    label: "In Progress",
  },
  {
    key: "Resolved",
    value: "resolved",
    label: "Resolved",
  },
];
class ViewMyTickets extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      selectedOption: null,
      tickets: [],
      ticket: {},
      errors: {},
      images: [],
      selectedCategoryOption: null,
      homecareDetails: null,
      ticketHistory: [],
      isCustomLoader:false
    };

    this.columns = [
      {
        name: "Title",
        selector: "title",
        wrap: true,
        sortable: true,
      },
      {
        name: "Ticket Id",
        selector: "ticket_uuid",
        wrap: true,
        sortable: true,
      },
      {
        name: "Created",
        selector: "created_at",
        wrap: true,
        sortable: true,
        format: (order) =>
          order.created_at
            ? this.getFormattedPurchasedDate(order.created_at)
            : "-",
      },
      {
        name: "Status",
        selector: "ticket_status",
        wrap: true,
        sortable: true,
      },
      {
        name: "Resolution Date",
        selector: "resolved_at",
        wrap: true,
        sortable: true,
        format: (order) =>
          order.resolved_at
            ? this.getFormattedPurchasedDate(order.resolved_at)
            : "-",
      },

      {
        name: "View",
        center: true,

        cell: (ticket) => this.renderViewButton(ticket.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchTickets();
  }
  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };
  getHomeCareDetails = async () => {
    try {
      const { data: homecareUsers = [] } = await PhoenixAPI.get(
        "homecare-details"
      );
      const userId = getUserId();
      const homecareDetails = homecareUsers.find(
        (item) => item.homecare_userid == userId
      );
      this.setState({ homecareDetails });
      return homecareDetails;
      console.log("Response: homecareUsers", homecareDetails);
    } catch (error) {
      console.log("Error:", error.message);
    }
  };
  fetchTickets = async () => {
    const selectedOption = this.state.selectedOption;
    let filterTicketStatus = "";
    const homecareDetails = await this.getHomeCareDetails();
    if (selectedOption) {
      filterTicketStatus = selectedOption?.value ?? "";
    }
    const params = {};
    const homeCareId = getUserId();
    if (filterTicketStatus) {
      params.ticketStatus = filterTicketStatus;
    }
    try {
      this.showLoading();
      const { data: tickets = [] } = await PhoenixAPI.get(
        `homecare/all-tickets/${homecareDetails.homecare_id}`,
        { params }
      );
      this.setState({ tickets });
    } catch (error) {
      console.log("Error in fetching tickets", error.message);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleViewTicketStatus = async (ticketId) => {
    try {
      this.showLoading();
      const { data: ticketDetails = [] } = await PhoenixAPI.get(
        `ticket/${ticketId}`
      );
      const { latestTicket, ticketHistory = [] } = ticketDetails ?? {};
      console.log("latestTicket: ", latestTicket);
      console.log("latestTicket: ", latestTicket);
      this.setState({ ticket: latestTicket, ticketHistory }, () =>
        this.showTicketViewModal()
      );
    } catch (error) {
      console.log("Error in view Ticket", error.message);
      this.showErrorNotification("Error in fetching Ticket", error.message);
    } finally {
      this.hideLoading();
    }
  };

  showTicketViewModal = () => {
    this.setState({ isTicketViewModalVisbile: true });
  };

  hideTicketViewModal = () => {
    this.setState({ isTicketViewModalVisbile: false });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewTicketStatus(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleReopenTicket = () => {
    const homecareUserEmail = getUserEmail();
    const userId = getUserId();
    const homecareUserName = getUserName();
    const ticket = this.state.ticket;
    const selectedCategoryOption = {
      key: ticket.ticketCategory,
      value: ticket.ticketCategory,
      label: ticket.ticketCategory,
    };
    this.setState({
      isTicketReopenModalVisbile: true,
      homecareUserEmail,
      homecareUserName,
      selectedCategoryOption,
    });
  };
  hideTicketReopenModal = () => {
    this.setState({ isTicketReopenModalVisbile: false, images: [], isCustomLoader: false  });
  };
  handleCancel = () => {
    this.hideTicketReopenModal();
  };
  handleChange = ({ currentTarget: input }) => {
    const ticket = { ...this.state.ticket };
    ticket[input.name] = input.value;
    this.setState({ ticket });
  };
  renderClosableImages = (image) => {
    const isPdfFile =
      image?.type === "application/pdf" ||
      (typeof image === "string" && image.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <div style={{ width: "150px" }}>
        <PdfPreview
          pdfFile={isPdfFile ? image : null}
          pdfFileUrl={isPdfFile ? null : image}
        />
      </div>
    ) : (
      <img src={image} alt="Selected" />
    );
    return <div className="image-area">{preview}</div>;
  };

  validate = () => {
    const { ticket } = this.state;
    const errors = {};
    const { ticketCategory, title, description } = ticket;
    if (!ticketCategory) {
      errors.ticketCategory = "Category is required";
    }
    if (!title || title.trim() === "") {
      errors.title = "Title is required";
    } else if (title && title.length > 100) {
      errors.title = "Title is limited to a 100 characters";
    }
    if (!description || description.trim() === "") {
      errors.description = "Description is required";
    } else if (description && description.length > 1000) {
      errors.description = "Description is limited to a 1000 characters";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  };
  handleUploadAttachments = async (file) => {
    try {
      this.setState({isCustomLoader: true});
      const formData = new FormData();
      formData.append("media", file);
      const response = await PhoenixAPI.post("/upload-media", formData, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": "multipart/form-data",
        },
      });
      const data = response && response.data;
      return data.message ?? "";
    } catch (error) {
      console.log("Error on uploading attachments", error.message);
    }
    finally {
      this.setState({isCustomLoader: false});
    }
  };
  handleSelectImage = async (e) => {
    const file = e.target.files[0];
    const { images = [] } = this.state;
    if (file) {
      const uploadedImage = await this.handleUploadAttachments(file);

      this.setState({ images: [...images, uploadedImage] });
      if (this.fileInputRef.current && this.fileInputRef.current) {
        this.fileInputRef.current.value = "";
      }
    }
  };
  handleCreateReopenTicket = async () => {
    console.log("Create Reopen Ticket");
    const errors = this.validate();
    const { ticket, homecareUserName, images, homecareDetails } = this.state;
    const userId = getUserId();

    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    const payload = {
      ticketCategory: ticket.ticketCategory,
      title: ticket.title,
      orderId: ticket.orderId,
      description: ticket.description,
      homecareId: homecareDetails.homecare_id,
      homecareName: homecareUserName,
      mediaS3Url: images ?? [],
      parentTicketId: ticket.isParentTicket ? ticket.id : ticket.parentTicketId,
      parentTicketUuid: ticket.isParentTicket
        ? ticket.ticketUUID
        : ticket.parentTicketUuid,
      reopenCount: ticket.reopenCount,
    };
    try {
      // this.showLoading();
       this.setState({isCustomLoader: true});
      const response = await PhoenixAPI.post(
        "homecare/ticket?isReopenTicket=true",
        payload
      );
      this.showSuccessNotification(
        "Thank you for creating ticket. Our team will be in touch with you shortly."
      );
      this.hideTicketReopenModal();
      this.hideTicketViewModal();
      this.fetchTickets();
    } catch (error) {
      console.log("Error on creating ticket", error);
      this.showErrorNotification("Error in creating ticket. Please try again.");
    } finally {
      // this.hideLoading();
      this.setState({isCustomLoader: false});
    }
  };
  downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
  render() {
    const {
      selectedOption,
      tickets,
      loadError,
      ticket,
      errors,
      images,
      homecareUserEmail,
      homecareUserName,
      selectedCategoryOption,
      ticketHistory,
    } = this.state;
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const ticketFormDetails = (
      <div style={{ marginTop: "10px" }}>
        {ticket.ticketStatus === "Reopen" && (
          <div className="ticket-status-bar">
            Status : <span className="openStatus">{ticket.ticketStatus}</span>
          </div>
        )}
        {ticket.ticketStatus === "Open" && (
          <div className="ticket-status-bar">
            Status : <span className="openStatus">{ticket.ticketStatus}</span>
          </div>
        )}
        {ticket.ticketStatus === "In Progress" && (
          <div className="ticket-status-bar">
            Status :{" "}
            <span className="inProgressStatus">{ticket.ticketStatus}</span>
          </div>
        )}
        {ticket.ticketStatus === "Resolved" && (
          <div className="ticket-status-bar">
            Status :{" "}
            <span className="resolvedStatus">{ticket.ticketStatus}</span>
          </div>
        )}

        <div className="ticket-title">
          <div>
            Title : <span style={{ fontWeight: "bold" }}>{ticket.title}</span>
          </div>
        </div>
        <div className="title-content">
          <div>
            Creation Date : {this.getFormattedPurchasedDate(ticket?.createdAt)}
          </div>
          <div>
            Resolution Date :
            {ticket.resolvedAt
              ? this.getFormattedPurchasedDate(ticket?.resolvedAt ?? "")
              : ""}
          </div>
          <div>Order Id : {ticket.orderId}</div>
        </div>
        <div className="title-content">
          <div className="">Category : {ticket.ticketCategory}</div>

          <div>Description : {ticket.description}</div>

          <div>Images/Files : </div>

          <div>
            <div className="media-scroller snaps-inline">
              {ticket.mediaS3Url &&
                ticket.mediaS3Url.map((item) => {
                  return (
                    <>
                      <div className="upload-file-box">
                        <div className="choose-fileand-icon">
                          <img
                            className="uplooad-icon"
                            src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                            alt=""
                          />
                          <span
                            className="choose-file"
                            onClick={() => this.downloadFile(item)}
                          >
                            Download File
                          </span>
                        </div>
                        {this.renderClosableImages(item)}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col separator"></div>
          </div>
          <div>Remarks by Valeo Team : {ticket.adminHomecareRemarks}</div>
          <div>Attachments by Valeo : </div>
          <div>
            <div className="media-scroller snaps-inline">
              {ticket.adminHomecareRemarksMediaS3Url &&
                ticket.adminHomecareRemarksMediaS3Url.map((item) => {
                  return (
                    <>
                      <div className="upload-file-box">
                        <div className="choose-fileand-icon">
                          <img
                            className="uplooad-icon"
                            src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                            alt=""
                          />
                          <span
                            className="choose-file"
                            onClick={() => this.downloadFile(item)}
                          >
                            Download File
                          </span>
                        </div>
                        {this.renderClosableImages(item)}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>

          {ticket.ticketStatus === "resolved" ||
          ticket.ticketStatus === "Resolved" ? (
            <div className="reopen-div">
              <div>Want to Follow Up ?</div>

              <div>
                <button
                  onClick={(e) => this.handleReopenTicket()}
                  id="show_orders"
                  className="btn px-4"
                  style={{ backgroundColor: "#CEE741" }}
                >
                  Reopen the Ticket
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );

    const formTicketHistory = (
      <div className="container mt-4">
        {ticketHistory.length > 0
          ? ticketHistory.map((ticket) => {
              return (
                <>
                  <div className="row">
                    <div className="form-group col">
                      Homecare : {ticket.homecareName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      Created At : {formatDateTime(ticket.created_at)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      Priority : {ticket?.resolutionPriority ?? ""}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      Category : {ticket.ticketCategory}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">Title : {ticket.title}</div>
                  </div>

                  <div className="row" style={{ marginTop: "5px" }}>
                    <div className="form-group col-6">
                      <label htmlFor="description">Description : </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.description || ""}
                          onChange={this.handleChange}
                          id="description"
                          name="description"
                          placeholder="Description"
                          className={
                            "form-control py-2" +
                            (errors.longDescriptionArabic ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="form-group col">
                      <label htmlFor="description" className="label">
                        Images :
                      </label>
                      <div className="media-scroller snaps-inline">
                        {ticket.mediaS3Url &&
                          ticket.mediaS3Url.map((item) => {
                            return (
                              <>
                                <div className="upload-file-box">
                                  <div className="choose-fileand-icon">
                                    <img
                                      className="uplooad-icon"
                                      src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                      alt=""
                                    />
                                    <span
                                      className="choose-file"
                                      onClick={() => this.downloadFile(item)}
                                    >
                                      Download File
                                    </span>
                                  </div>
                                  {this.renderClosableImages(item)}
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="adminHomecareRemarks">
                        Add Remarks{" "}
                        <span className="important-asterisk">*</span> :
                      </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.adminHomecareRemarks || ""}
                          onChange={this.handleChange}
                          id="adminHomecareRemarks"
                          name="adminHomecareRemarks"
                          placeholder="Add Remarks"
                          className={
                            "form-control py-2" +
                            (errors.adminHomecareRemarks ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                        {/* {this.renderPencilIcon("longDescriptionArabic")} */}
                      </div>
                      <span className="help-block">
                        {errors.adminHomecareRemarks}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="images" className="label">
                        Add Images
                      </label>
                    </div>
                  </div>
                  <div>
                    <div className="media-scroller snaps-inline">
                      {ticket.adminHomecareRemarksMediaS3Url &&
                        ticket.adminHomecareRemarksMediaS3Url.length > 0 &&
                        ticket.adminHomecareRemarksMediaS3Url.map(
                          (image, index) => {
                            return (
                              <div className="upload-file-box">
                                <div className="choose-fileand-icon">
                                  <img
                                    className="uplooad-icon"
                                    src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                    alt=""
                                  />
                                  <span
                                    className="choose-file"
                                    onClick={() => this.downloadFile(image)}
                                  >
                                    Download File
                                  </span>
                                </div>
                                {this.renderClosableImages(image)}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      <label htmlFor="adminInternalRemarks">
                        Internal Remarks{" "}
                        <span className="important-asterisk">*</span>
                      </label>
                      <div className="input-group">
                        <textarea
                          value={ticket.adminInternalRemarks || ""}
                          onChange={this.handleChange}
                          id="adminInternalRemarks"
                          name="adminInternalRemarks"
                          placeholder="Add Internal Remarks"
                          className={
                            "form-control py-2" +
                            (errors.adminInternalRemarks ? " has-error" : "")
                          }
                          readOnly={true}
                        ></textarea>
                        {/* {this.renderPencilIcon("longDescriptionArabic")} */}
                      </div>
                      <span className="help-block">
                        {errors.adminInternalRemarks}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3 mb-3">
                    <div className="col separator"></div>
                  </div>
                </>
              );
            })
          : ""}
      </div>
    );

    const reopenModalForm = (
      <>
        <div className="ticket-container">
          <div>Reopen Support Ticket</div>
          <div>{homecareUserEmail ?? ""}</div>
          <div>{homecareUserName ?? ""}</div>

          <div className="create-ticket-form">
            <div className="ticket-category-cont">
              <label className="label">
                What do you want to help with ? <span>*</span>
              </label>
              <Select
                value={selectedCategoryOption ?? null}
                placeholder="Select a category"
                search
                selection
                className={"ticketCategoryOption"}
                onChange={(option) => {
                  this.setState({
                    selectedCategoryOption: {
                      key: option.value,
                      value: option.value,
                      label: option.label,
                    },
                    ticket: { ...ticket, ticketCategory: option.value },
                  });
                }}
                options={ticketCategoryOptions}
              />
              <span className="help-block">{errors.ticketCategoryOption}</span>
            </div>

            <div className="row">
              <div className="form-group col">
                <label htmlFor="title" className="label">
                  Title <span>*</span>
                </label>
                <div className="input-group ticket">
                  <input
                    type="text"
                    placeholder="Enter Title"
                    value={ticket?.title || ""}
                    name="title"
                    onChange={this.handleChange}
                    className={
                      "form-control py-2" + (errors.title ? " has-error" : "")
                    }
                  />
                </div>
                <span className="help-block">{errors.title}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="orderId" className="label">
                  Order Id (if Applicable)
                </label>
                <div className="input-group ticket">
                  <input
                    type="number"
                    placeholder="Enter Order Id"
                    value={ticket?.orderId || ""}
                    name="orderId"
                    onChange={this.handleChange}
                  />
                </div>

                <span className="help-block">{errors.title}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="description" className="label">
                  Description <span>*</span>
                </label>
                <div className="input-group ticket">
                  <textarea
                    type="text"
                    placeholder="Enter Description"
                    value={ticket?.description || ""}
                    name="description"
                    onChange={this.handleChange}
                    className={
                      "form-control py-2" +
                      (errors.description ? " has-error" : "")
                    }
                  />
                </div>

                <span className="help-block">{errors.description}</span>
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label htmlFor="description" className="label">
                  Attach Files/Images (Optional)
                </label>
                <div className="input-group ticket">
                  <input
                    onChange={(e) => this.handleSelectImage(e)}
                    id="imageInput"
                    name="image"
                    type="file"
                    accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                    ref={this.fileInputRef}
                    className={
                      "form-control py-2" + (errors.image ? " has-error" : "")
                    }
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="media-scroller snaps-inline">
                {images &&
                  images.length > 0 &&
                  images.map((image, index) => {
                    return (
                      <div key={index} className="media-element">
                        {this.renderClosableImages(image, index)}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="button-save-div">
              <button
                onClick={(e) => this.handleCancel()}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#CEE741" }}
              >
                Cancel
              </button>

              <button
                onClick={(e) => this.handleCreateReopenTicket()}
                id="show_orders"
                className="btn px-4"
                style={{ backgroundColor: "#CEE741" }}
              >
                Create Support Ticket
              </button>
            </div>
          </div>
        </div>
      </>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="col-3 ml-0 pl-0">
            <label>My Requests</label>
            <Select
              key={`my_unique_select_key__${
                selectedOption && selectedOption.id
              }`}
              value={selectedOption ?? null}
              onChange={(option) =>
                this.setState(
                  {
                    selectedOption: {
                      key: option.value,
                      value: option.value,
                      label: option.label,
                    },
                  },
                  () => this.fetchTickets()
                )
              }
              options={REQUEST_OPTIONS}
              styles={selectStyles}
              menuPlacement={"bottom"}
              placeholder={"Filter by"}
            />
          </div>
        </div>
        <Card body style={{ marginTop: "50px" }}>
          <DataTable
            data={tickets || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>

        <Modal
          size="lg"
          show={this.state.isTicketViewModalVisbile}
          onHide={this.hideTicketViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  Ticket Id : {ticket.ticketUUID}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="key_form_ticket_information" justify>
              <Tab
                eventKey="key_form_ticket_information"
                title="Ticket Details"
              >
                {ticketFormDetails}
              </Tab>
              <Tab eventKey="key_form_ticket_history" title="Ticket History">
                {formTicketHistory}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={this.state.isTicketReopenModalVisbile}
          onHide={this.hideTicketReopenModal}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>{reopenModalForm}</Modal.Body>
        </Modal>
        <CustomLoader show={this.state.isCustomLoader}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMyTickets);
