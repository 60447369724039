import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import moment from "moment";
import Card from "react-bootstrap/Card";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class TimeSlots extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "startTime",
        method: "isEmpty",
        validWhen: false,
        message: "Start time is required.",
      },
    ]);

    this.customValidator = {
      startTime: {
        message: null,
      },
    };

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      timeSlots: [],
      isLoaded: false,
      loadError: "",
      submitText: "Add",
      formHeading: "Add New",
      startTime: "",
      endTime: "",
      editId: null,
      validation: this.validator.valid(),
      showModal: false,
      showConfirm: false,
      confirmItem: null,
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      columns: [
        {
          name: "Time Slot",
          selector: "timeslot",
        },
        {
          name: "Actions",
          maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: (row) => (
            <ActionBar alignRight className="action-menu valeo-dropdown">
              <ActionBar.Toggle variant="" disabled={!this.state.isEditable}>
                <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
              </ActionBar.Toggle>
              <ActionBar.Menu>
                <ActionBar.Item
                  onClick={() => this.editTimeSlot(row.index, row.id)}
                >
                  <EditIcon className="icon-small"></EditIcon>Edit
                </ActionBar.Item>
  
                <ActionBar.Item
                  className="danger"
                  onClick={() => this.showConfirm(row["id"])}
                >
                  <DeleteOutlineIcon></DeleteOutlineIcon>
                  Delete
                </ActionBar.Item>
              </ActionBar.Menu>
            </ActionBar>
          ),
        },
      ]
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editTimeSlot = this.editTimeSlot.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.deleteTimeSlot = this.deleteTimeSlot.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadTimeSlots();
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.TIME_SLOTS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  loadTimeSlots() {
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true,
    });

    API.get("timeslots/")
      .then((response) => {
        let newList = response.data;

        newList.sort((a, b) => {
          let time1 = moment(a.slot_start_time, "HH:mm");
          let time2 = moment(b.slot_start_time, "HH:mm");

          if (time1.isBefore(time2)) return -1;
          else if (time1.isAfter(time2)) return 1;
          else return 0;
        });

        for (let i = 0; i < newList.length; i++) {
          newList[i].index = i;
          newList[i].timeslot =
            moment(newList[i].slot_start_time, "HH:mm").format("hh:mm A") +
            " to " +
            moment(newList[i].slot_end_time, "HH:mm").format("hh:mm A");
        }

        this.setState({ timeSlots: newList, isLoaded: true });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false,
        });
      })
      .catch((error) => {
        console.log("Error on fetching timeslots", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.setState({
          loadError: "Some error has occured. Please try again",
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ startTime: target.value });
    this.setEndTime(target.value);
  }

  validateTime(time) {
    const timeSlots = this.state.timeSlots;
    const minTime = moment.utc("10:00", "HH:mm");
    const maxTime = moment.utc("16:00", "HH:mm");
    const startTime = moment.utc(time, "HH:mm");

    for (var t in timeSlots) {
      if (!t.includes(startTime)) {
        // if (
        //   startTime.isSameOrAfter(minTime) &&
        //   startTime.isSameOrBefore(maxTime)
        // ) {
        this.customValidator.startTime.message = null;
        return true;
        // } else {
        //   this.customValidator.startTime.message =
        //     "Start time should be between 10 AM & 4 PM";
        //   return false;
        // }
      } else {
        this.customValidator.startTime.message =
          "You cannot add same time slots";
        return false;
      }
    }
  }

  setEndTime(startTime) {
    let endTime = moment.utc(startTime, "HH:mm").add(2, "hour").format("HH:mm");
    this.setState({ endTime: endTime });
  }

  handleSubmit(event) {
    event.preventDefault();
    let startTime = this.state.startTime;
    let endTime = this.state.endTime;
    let editId = this.state.editId;

    const validation = this.validator.validate(this.state);
    const isValidTime = this.validateTime(startTime);
    this.setState({ validation });
    this.submitted = true;

    if (validation.isValid && isValidTime) {
      this.hideModal();
      this.props.toggleLoading({
        isLoading: true,
      });

      if (editId !== null) {
        //edit
        API.put("timeslots/" + editId + "/", {
          slot_start_time: startTime,
          slot_end_time: endTime,
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Time slot successfully updated.",
              successMessage: true,
              showNotification: true,
            });
            this.loadTimeSlots();
          })
          .catch((error) => {
            console.log("Error on updating time slot", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      } else {
        //add new
        API.post("timeslots/", {
          slot_start_time: startTime,
          slot_end_time: endTime,
        })
          .then((response) => {
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Time slot successfully created.",
              successMessage: true,
              showNotification: true,
            });
            this.loadTimeSlots();
          })
          .catch((error) => {
            console.log("Error on creating time slot", error);
            this.props.toggleLoading({
              isLoading: false,
            });
            this.props.showNotificationMessage({
              notificationMessage: "Some error has occured. Please try again.",
              successMessage: false,
              showNotification: true,
            });
          });
      }
    }
    else {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true
      });
    }
  }

  editTimeSlot(index, id) {
    let timeSlots = this.state.timeSlots;
    this.setState({
      submitText: "Update",
      formHeading: "Edit Slot",
      startTime: timeSlots[index]["slot_start_time"],
      endTime: timeSlots[index]["slot_end_time"],
      editId: id,
    });

    this.showModal();
  }

  hideModal() {
    this.setState({ showModal: false });
    this.clearForm();
  }

  showModal() {
    this.setState({
      showModal: true,
    });
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
    });
  }

  showConfirm(confirmItem) {
    this.setState({
      showConfirm: true,
      confirmItem,
    });
  }

  deleteTimeSlot(id) {
    this.hideConfirm();
    this.props.toggleLoading({
      isLoading: true,
    });

    API.delete("timeslots/" + id + "/")
      .then((response) => {
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Time slot successfully deleted.",
          successMessage: true,
          showNotification: true,
        });
        this.clearForm();
        this.loadTimeSlots();
      })
      .catch((error) => {
        console.log("Error on deleting timeslot", error);
        this.props.toggleLoading({
          isLoading: false,
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  clearForm() {
    this.submitted = false;
    this.setState({
      submitText: "Add",
      formHeading: "Add New",
      startTime: "",
      endTime: "",
      editId: null,
      validation: this.validator.valid(),
    });
    this.customValidator.startTime.message = null;
  }

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value,
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false,
      });

      return;
    }

    list = this.state.timeSlots;
    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (
        item.slot_start_time.toLowerCase().indexOf(newValue) !== -1 ||
        item.slot_end_time.toLowerCase().indexOf(newValue) !== -1
      ) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length,
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false,
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false,
      });

      return;
    } else {
      this.setState({
        searchExpanded: true,
      });
    }
  }

  render() {
    let finalList = [],
      form;
    const {
      timeSlots,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      isEditable,
    } = this.state;

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (this.submitted) this.validateTime(this.state.startTime);

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        finalList = timeSlots;
      }
    }

    form = (
      <div className="form-row">
        <div className="input-section form-group col-6">
          <label>Start Time *</label>
          <input
            type="time"
            className={
              "form-control " +
              ((validation.startTime.isInvalid ||
                this.customValidator.startTime.message) &&
                "has-error")
            }
            placeholder="Start Time"
            name="startTime"
            value={this.state.startTime}
            onChange={this.handleChange}
          ></input>
          <span className="help-block">
            {validation.startTime.message
              ? validation.startTime.message
              : this.customValidator.startTime.message}{" "}
          </span>
        </div>
        <div className="input-section form-group col-6">
          <label>End Time</label>
          <input
            type="time"
            className="form-control"
            placeholder="Start Time"
            name="endTime"
            value={this.state.endTime}
            readOnly
          ></input>
        </div>
      </div>
    );
    
    if (this.state.accessDenied) {
      return <AccessDenied />
    }
    
    return (
      <div className="time-slots">
        <div className="page-header">
          <h5>Time Slots</h5>
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                className="btn btn-sm button text-button "
                onClick={this.showModal}
                disabled={!isEditable}
              >
                <AddCircleOutlineOutlinedIcon></AddCircleOutlineOutlinedIcon>
                add new
              </button>
            </div>
          </div>
        </div>

        <Card>
          <DataTable
            highlightOnHover
            columns={this.state.columns ?? []}
            data={finalList}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + resultCount + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal show={this.state.showModal} onHide={this.hideModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.formHeading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={(e) => this.handleSubmit(e)}
              className="btn button button-green"
            >
              Submit
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Time Slot</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this?</Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.deleteTimeSlot(this.state.confirmItem)}
              className="btn button btn-danger"
            >
              Delete
            </button>
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSlots);
