import React from "react";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { formatDate, formatTimeslot } from "utils/commons";
import moment from "moment";
function LabInformationCard(props) {
  const { itemDetails, userDetails, addressDetails, isCodOrder=false, currency=""} = props;
  const confirmTimeFormat=(time24hr)=>{
    const [hours, minutes] = time24hr.split(':');
    const time = new Date(0, 0, 0, hours, minutes);
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };

    return time.toLocaleTimeString([], options);
  }

  const generateLabInfo = () => {
    const {
      itemType,
      packageInternalName,
      bookedSlotDate,
      bookedSlotTime,
      dependent,
      confirmedDateTime,
      quantity,
      displayName,
      paymentToBeCollected,

    } = itemDetails;
 
    const[confirmedDate,confirmTime]=confirmedDateTime?confirmedDateTime.split(" "):[null, null];
  
    const { phoneNumber, email } = userDetails;
    
    let clientName, clientGender, dateOfBirth, mobile;
    if (dependent) {
      const { firstName, lastName, gender, dob, mob } = dependent;
      const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
      clientName = `Client Name: ${fullName ?? ""}`;
      clientGender = `Gender: ${gender ?? ""}`;
      dateOfBirth = `Date of Birth: ${dob ? formatDate(dob) : ""}`;
      mobile = `Mobile: ${mob ?? ""}`;
    } else {
      const { userName, firstName, lastName, gender, dob } = userDetails;
      const fullName =
        firstName && lastName ? `${firstName} ${lastName}` : userName;
      clientName = `Client Name: ${fullName ?? ""}`;
      clientGender = `Gender: ${gender ?? ""}`;
      dateOfBirth = `Date of Birth: ${dob ? formatDate(dob) : ""}`;
      mobile = `Mobile: ${phoneNumber ?? ""}`;
    }
    
    const emailAddress = itemType === "PCR" ? `Email: ${email ?? ""}` : null;
    const packageName = `Package: ${packageInternalName ? `Valeo ${packageInternalName}` : ""}`;
    const address = "Address: " + generateAddressInfo();
    const formattedDate = confirmedDate ? formatDate(confirmedDate) : "";
    const formattedTime=confirmTime?confirmTimeFormat(confirmTime):"";
    const quantities=quantity>1?`Quantity: ${quantity}`:"";
    const dosage=`Dosage: ${displayName? displayName:""}`;
    
    const dateTime = `Date & Time: ${formattedDate} & ${formattedTime}`;
    const collectCOD=paymentToBeCollected!==null?`Collect COD Amount: ${paymentToBeCollected}`:"";
    
    
    const labInformation = [
      clientName,
      clientGender,
      dateOfBirth,
      mobile,
      emailAddress,
      packageName,
      displayName?dosage:"",
      quantities!==""?quantities:"",
      address,
      dateTime,
      collectCOD
    ]
      .filter((it) => it !== null && it !=="")
      .join("\r\n");
    return labInformation;
  };

  const generateAddressInfo = () => {
    if (addressDetails) {
      const {
        label,
        addressLine2: streetName,
        buildingApt,
        addressLine1: areaDistrict,
        city,
        country,
        mapUrl,
        lat,
        lng,
      } = addressDetails;
      const mapUrlString = mapUrl ? `Map URL: ${mapUrl}` : null;
      const locationString = lat || lng ? `Location: Lat: ${lat}, Lng: ${lng}` : "";
      const addressParts = [
        label,
        buildingApt,
        streetName,
        areaDistrict,
        city,
        country,
      ];
      let result = addressParts.filter((it) => it).join(", ");
      if (mapUrlString)
        result = result + "\r\n" + mapUrlString;
      if (locationString)
        result = result + "\r\n" + locationString;
      return result;
    }
    return "";
  };

  return (
    <Card className="px-0 py-0 mb-0">
      <Card.Body className="px-0 py-0">
        <div className="row ml-1 my-2">
          <div className="col">
            <p className="h6">Lab Information</p>
          </div>
        </div>
        <div className="row mx-2 pb-2">
          <div className="col">
            <span style={{ whiteSpace: "pre-wrap" }}>{generateLabInfo()}</span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

LabInformationCard.propTypes = {
  itemDetails: PropTypes.object.isRequired,
  userDetails: PropTypes.object.isRequired,
  addressDetails: PropTypes.object.isRequired,
};

LabInformationCard.defaultProps = {
  itemDetails: {},
  userDetails: {},
  addressDetails: {},
};

export default LabInformationCard;
