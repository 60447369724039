import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import SelectCity from "pages/PackageCreation/SelectCity";

class TestimonialsForm extends Component {
  constructor(props) {
    super(props);

    const { testimonials = [], onTestimonialsUpdated = (f) => f } = this.props;

    this.onTestimonialsUpdated = onTestimonialsUpdated;

    this.state = {
      testimonials,
      testimonial: {},
      errors: {},
      editables: {},
      loadError: "",
      sortColumn: "rank",
      sortDirection: "asc",
    };

    this.columns = [
      {
        name: "",
        cell: () => <Drag className="drag-handle"></Drag>,
        width: "50px",
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        maxWidth: "100px",
      },
      {
        name: "Testimonial (English)",
        selector: "textEng",
        sortable: true,
        wrap: true,
        format: (testimonial) => (
          <div className="text-overflow-ellipsis">
            {testimonial.textEng ?? ""}
          </div>
        ),
      },
      {
        name: "Testimonial (Arabic)",
        selector: "textArabic",
        sortable: true,
        wrap: true,
        right: true,
        format: (testimonial) => (
          <div
            className="text-overflow-ellipsis"
            style={{ textAlign: "right" }}
          >
            {testimonial.textArabic ?? ""}
          </div>
        ),
      },
      {
        name: "User (English)",
        selector: "userEng",
        sortable: true,
        wrap: true,
        format: (testimonial) => (
          <div className="text-overflow-ellipsis">
            {testimonial.userEng ?? ""}
          </div>
        ),
      },
      {
        name: "User (Arabic)",
        selector: "userArabic",
        sortable: true,
        wrap: true,
        format: (testimonial) => (
          <div className="text-overflow-ellipsis">
            {testimonial.userArabic ?? ""}
          </div>
        ),
      },
      {
        name: "City",
        selector: "cityName",
        sortable: true,
        wrap: true,
        format: (testimonial) => (
          <div className="text-overflow-ellipsis">
            {testimonial.cityName ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (testimonial) => this.renderActionBar(testimonial),
      },
    ];

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      testimonial: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const testimonial = { ...this.state.testimonial };
    testimonial[input.name] = input.value;
    this.setState({ testimonial });
  };

  handleAddNew = () => {
    const testimonial = {};
    this.setState({ testimonial }, () => this.showAddEditModal());
  };

  handleEdit = (id) => {
    this.clearForm();
    const testimonials = this.state.testimonials ?? [];
    const testimonial = testimonials.find((it) => it.id === id);
    if (!testimonial) return;

    this.setState({ testimonial }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  handleDelete = (id) => this.deleteTestimonial(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { testimonial = {} } = this.state;
    if (testimonial.id === null || testimonial.id === undefined)
      this.createTestimonial();
    else this.updateTestimonial(testimonial.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { testimonial = {} } = this.state;
    const { cityId, textEng, textArabic, userEng, userArabic } = testimonial;

    if (!cityId)
      errors.cityId = "City is a required field!";

    if (!textEng || textEng.trim() === "")
      errors.textEng = "Testimonial Text (English) is a required field!";
    else if (textEng.length > 1000)
      errors.textEng =
        "Testimonial Text (English) can not contain more than 1000 characters!";

    if (!textArabic || textArabic.trim() === "")
      errors.textArabic = "Testimonial Text (Arabic) is a required field!";
    else if (textArabic.length > 1000)
      errors.textArabic =
        "Testimonial Text (Arabic) can not contain more than 1000 characters!";

    if (!userEng || userEng.trim() === "")
      errors.userEng = "User (English) is a required field!";
    else if (userEng.length > 100)
      errors.userEng =
        "User (English) can not contain more than 100 characters!";

    if (!userArabic || userArabic.trim() === "")
      errors.userArabic = "User (Arabic) is a required field!";
    if (userArabic && userArabic.length > 100)
      errors.userArabic =
        "User (Arabic) can not contain more than 100 characters!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createTestimonial = () => {
    const { testimonials = [], testimonial } = this.state;
    const ids =
      testimonials.length > 0 ? testimonials.map((it) => it.id) : null;
    testimonial.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      testimonials.length > 0
        ? testimonials.filter((it) => it.rank).map((it) => it.rank)
        : null;
    testimonial.rank = ranks ? Math.max(...ranks) + 1 : 1;
    testimonial.is_new = true;

    this.setState({ testimonials: [...testimonials, testimonial] }, () => {
      this.showSuccessNotification("Testimonial Created Successfully!");
      this.onTestimonialsUpdatedLocally();
    });
  };

  updateTestimonial = (id) => {
    const { testimonials = [], testimonial } = this.state;
    const newTestimonials = testimonials.map((it) =>
      it.id === id ? testimonial : it
    );
    this.setState({ testimonials: newTestimonials }, () => {
      this.showSuccessNotification("Testimonial Updated Successfully!");
      this.onTestimonialsUpdatedLocally();
    });
  };

  deleteTestimonial = (id) => {
    const { testimonials = [] } = this.state;
    const testimonialToDelete = testimonials.find((it) => it.id === id) || {};
    if (!testimonialToDelete) {
      console.log("deleteTestimonial:: Testimonial not found with id=", id);
      this.showErrorNotification("Testimonial not found!");
      return;
    }

    const newTestimonials = testimonials
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > testimonialToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ testimonials: newTestimonials }, () => {
      this.showSuccessNotification("Testimonial Deleted Successfully!");
      this.onTestimonialsUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { testimonials = [], sortColumn, sortDirection } = this.state;
    const sortedTestimonials = _.sortBy(
      testimonials,
      sortColumn,
      sortDirection
    );
    const fromTestimonial = { ...sortedTestimonials[fromIndex] };
    const toTestimonial = { ...sortedTestimonials[toIndex] };

    if (fromTestimonial && toTestimonial) {
      const tempRank = fromTestimonial.rank;
      fromTestimonial.rank = toTestimonial.rank;
      toTestimonial.rank = tempRank;

      const newTestimonials = testimonials.map((it) => {
        if (it.id === fromTestimonial.id) return fromTestimonial;
        else if (it.id === toTestimonial.id) return toTestimonial;
        else return it;
      });
      this.setState({ testimonials: newTestimonials }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onTestimonialsUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onTestimonialsUpdatedLocally = () => {
    this.clearForm();
    this.onTestimonialsUpdated(
      this.state.testimonials.map((testimonial) => {
        const t = { ...testimonial };
        if (t.is_new) {
          delete t.id;
          delete t.is_new;
        }
        return t;
      })
    );
  };

  findCity = (id) => {
    const cities = this.props.cities || [];
    return cities.find((city) => city.id === Number(id));
  };

  renderActionBar = (testimonial) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(testimonial.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(testimonial.id)}
          onClick={() =>
            this.setState({ testimonialToDelete: testimonial.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };
    const isEditable = this.props.isEditable ?? true;
    return isEditable && this.state.testimonial.id ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      testimonials = [],
      testimonial = {},
      errors,
      editables,
      sortColumn,
    } = this.state;
    const { countryId } = this.props;

    if (testimonials && testimonials.length) {
      testimonials.forEach(testimonial => {
        if (!testimonial.cityName) 
          testimonial.cityName = this.findCity(testimonial.cityId)?.city_name;
      })
    }
    const isNewTestimonial =
      testimonial.id === null || testimonial.id === undefined;
    const isEditable = this.props.isEditable ?? true;

    const form = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="cityId">City*</label>
            <div className="input-group">
              <SelectCity
                id="cityId"
                countryId={countryId}
                selectedCity={testimonial.cityId}
                onSelect={(value) =>
                  this.setState({
                    testimonial: {
                      ...testimonial,
                      cityId: value.value,
                      cityName: this.findCity(value.value)?.city_name ?? ""
                    },
                  })
                }
                showOnlyActive={true}
                isDisabled={!isNewTestimonial && !editables.cityId}
                className={"form-control" + (errors.cityId ? " has-error" : "")}
              />
              {this.renderPencilIcon("cityId")}
            </div>
            <span className="help-block">{errors.cityId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="textEng">Testimonial Text (English)*</label>
            <div className="input-group">
              <textarea
                value={testimonial.textEng ?? ""}
                onChange={this.handleChange}
                id="textEng"
                name="textEng"
                placeholder="Testimonial Text in English"
                readOnly={!isNewTestimonial && !editables.textEng}
                className={
                  "form-control py-2" + (errors.textEng ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("textEng")}
            </div>
            <span className="help-block">{errors.textEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="textArabic">Testimonial Text (Arabic)*</label>
            <div className="input-group">
              <textarea
                value={testimonial.textArabic}
                onChange={this.handleChange}
                id="textArabic"
                name="textArabic"
                placeholder="Testimonial Text in Arabic"
                readOnly={!isNewTestimonial && !editables.textArabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.textArabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("textArabic")}
            </div>
            <span className="help-block">{errors.textArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="userEng">User (English)*</label>
            <div className="input-group">
              <input
                value={testimonial.userEng}
                onChange={this.handleChange}
                id="userEng"
                name="userEng"
                type="text"
                placeholder="User in English"
                className={
                  "form-control py-2" + (errors.userEng ? " has-error" : "")
                }
                disabled={!isNewTestimonial && !editables.userEng}
              />
              {this.renderPencilIcon("userEng")}
            </div>
            <span className="help-block">{errors.userEng}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="userArabic">User (Arabic)*</label>
            <div className="input-group">
              <input
                value={testimonial.userArabic}
                onChange={this.handleChange}
                id="userArabic"
                name="userArabic"
                type="text"
                placeholder="User in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.userArabic ? " has-error" : "")
                }
                disabled={!isNewTestimonial && !editables.userArabic}
              />
              {this.renderPencilIcon("userArabic")}
            </div>
            <span className="help-block">{errors.userArabic}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={testimonials ?? []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNew}
                disabled={!isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {testimonial.id === null || testimonial.id === undefined
                ? "Add Testimonial"
                : "Edit Testimonial"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {testimonial.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete?</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteTestimonial(this.state.testimonialToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TestimonialsForm.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTestimonialsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

TestimonialsForm.defaultProps = {
  testimonials: [],
  onTestimonialsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestimonialsForm);
