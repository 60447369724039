import React, { Component } from "react";
import { connect } from "react-redux";
import DragIndicatorIcon from "@material-ui/icons/DragIndicatorOutlined";
import ReactDragListView from "react-drag-listview/lib/index.js";
import _ from "lodash";
import "./OtherImages.css";
import PhoenixAPI from "utils/PhoenixAPI";
const validTypes = ['video/mp4','video/webm'];
const imageValidTypes = ['image/jpeg','image/png','image/gif','image/svg+xml','image/webp']
class OtherImages extends Component {
  constructor(props) {
    super(props);

    const { id, label, images = [], onImagesUpdated = (f) => f,selectedMedia } = props;
    this.onImagesUpdated = onImagesUpdated;
       
    this.state = {
      id,
      label,
      images,
      selectedMedia,
      image:{}
    };

    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".media-element",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  updateRank = (fromIndex, toIndex) => {
    console.log("updateRank", fromIndex, toIndex);
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { images = [] } = this.state;
    const sortedImages = _.sortBy(images, "rank", "asc");
    const fromImage = { ...sortedImages[fromIndex] };
    const toImage = { ...sortedImages[toIndex] };

    if (fromImage && toImage) {
      const tempRank = fromImage.rank;
      fromImage.rank = toImage.rank;
      toImage.rank = tempRank;

      const newImages = images.map((it) => {
        if (it.id === fromImage.id) return fromImage;
        else if (it.id === toImage.id) return toImage;
        else return it;
      });
      this.setState({ images: newImages }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onImagesUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const imageFile = e.target.files[0];
      const image = this.createNewImage();
      image.image = imageFile;
      this.readImage(image, imageFile);
      e.target.value = null;
    }
  };
   
  

  handleIconChange = (e,mediaText) => {
    
    const file = e.target.files[0];
    if (file) {
      // setIcon(file);
      
      if(mediaText=="Primary Image"  ) {
    
        if( !imageValidTypes.includes(file.type)){
          this.showErrorNotification("please upload images")
       }
       else{
    this.uploadIcon( file,mediaText);
    e.target.value = null;
       }
     

      }

       if(mediaText=="Video URL") {
        if( !validTypes.includes(file.type)){
          this.showErrorNotification("please upload videos")
       }
       
      
       else{
        this.uploadIcon( file,mediaText);
       }
       }  
       

       if(mediaText=="Video ThumbNail"){
        
            if( !imageValidTypes.includes(file.type)){
              this.showErrorNotification("please upload images")
           }
        else{
        this.uploadIcon( file,mediaText);
      }
               
       }


     
    }
  }

  uploadFiles=async(formData)=>{

    const response = await PhoenixAPI.post('/upload-media',formData, {
      headers: {
        accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
   }

   imageThumbNailFun=(imageThumbNail)=>{
    return imageThumbNail;
   }

  uploadIcon = async ( iconFile,mediaText) => {
  
    try {
   const formData=new FormData();
    formData.append("media",iconFile)
      const response = await this.uploadFiles(formData);
     console.log("response",response);
      if(mediaText=="Primary Image"){
        const image =this.createNewImage();
        const newImage = {
          ...image,
          image:response.data.message,
          mediaType:"Image"

        };

        this.setState({ images: [...this.state.images, newImage] }, () => {
          this.showSuccessNotification("Image Added Successfully!");
          this.onImagesUpdatedLocally();
        });
    
      }

      if(mediaText=="Video URL"){
        const image =this.createNewImage();
        const newImage = {
          ...image,
          video:response.data.message,
          mediaType:"Video"
        };
        //this.imageThumbNailFun(newImage);
        this.setState({image:newImage})
       
          this.showSuccessNotification("Video Added Successfully!");
         // this.onImagesUpdatedLocally();
        

      }
      if(mediaText=="Video ThumbNail"){
        console.log("image object",this.state.image)
       // const thumbnailUpdate=this.imageThumbNailFun();
       
       
        const newImage = {
          ...this.state.image,
          image:response.data.message,

        };

        this.setState({ images: [...this.state.images, newImage] }, () => {
          this.showSuccessNotification("Image Added Successfully!");
          this.onImagesUpdatedLocally();
        });
      }
      }


      
    catch (error) {
      console.log("Error uploading image:", error);
    }
  }
  



  handleSelectVideoImg=(e) => {
    if (e.target.files && e.target.files.length) {
      const videoThumbnailImage = e.target.files[0];
      const supplement = { ...this.state.supplement, videoThumbnailImage };
      this.setState({ supplement });

      this.readVideoImage(videoThumbnailImage);
    }
  };

  readVideoImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const supplement = {
        ...this.state.supplement,
        video_image_src: [reader.result],
      };
      this.setState({ supplement });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  createNewImage = () => {
    const { images = [] } = this.state;
    const image = {};
    const ids = images.length > 0 ? images.map((it) => it.id) : null;
    image.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      images.length > 0
        ? images.filter((it) => it.rank).map((it) => it.rank)
        : null;
    image.rank = ranks ? Math.max(...ranks) + 1 : 1;
    image.is_new = true;
    return image;
  };

  readImage = (image, imageFile) => {
    if (!imageFile) return;
    const reader = new FileReader();
    reader.onloadend = function () {
      const newImage = {
        ...image,
        image_src: [reader.result],
      };
      this.setState({ images: [...this.state.images, newImage] }, () => {
        this.showSuccessNotification("Image Added Successfully!");
        this.onImagesUpdatedLocally();
      });
    }.bind(this);
    reader.readAsDataURL(imageFile);
  };

  handleDeleteImage = (image) => {
    this.deleteImage(image.id);
  };

  deleteImage = (id) => {
    const { images = [] } = this.state;
    const imageToDelete = images.find((it) => it.id === id) || {};
    const newImages = images
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > imageToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ images: newImages }, () => {
      this.showSuccessNotification("Image Deleted Successfully!");
      this.onImagesUpdatedLocally();
    });
  };

  onImagesUpdatedLocally = () => {
    console.log("images are",this.state.images);
    this.onImagesUpdated(
      this.state.images.map((image) => {
        const i = { ...image };
        if (i.is_new) {
          delete i.id;
          delete i.is_new;
        }
        return i;
      })
    );
  };

  renderClosableImage = (image) => {
    return (
      <div className="image-area">
        <img
          key={image?.id}
          src={image?.image_src ?? image?.image}
          alt="Selected"
        />
        <button
          onClick={(e) => this.handleDeleteImage(image)}
          disabled={!this.props.isEditable ?? false}
          className="remove-image"
          style={{ display: "inline" }}
        >
          <i className="fa fa-times"></i>
        </button>
      </div>
    );
  };

  renderClosableVideo=(video)=>{
    return (
      <div className="image-area" key={video.id}>
        <video controls width="100%" height="150" key={video.video}>
          <source src={video.video} type="video/mp4" />
          </video>
        </div>);

  }

  handleMediaChange = (event) => {
    
    this.setState({ selectedMedia:event.target.value });
  };
  

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { id, label, images = [] } = this.state;
    const isEditable = this.props.isEditable ?? true;
    images.sort((first, second) => {
      if (first.rank < second.rank) return -1;
      if (first.rank > second.rank) return 1;
      return 0;
    });
    const imageList = images.map((image) => {
      return (
        <div key={image.id} className="media-element">
          {this.renderClosableImage(image)}
          <DragIndicatorIcon className="drag-handle" />
        </div>
      );
    });
    const videoList=images.map((image)=>
    image.mediaType === "Video" ? (
      <div key={image.id} className="media-element">
        {this.renderClosableVideo(image)}
        <DragIndicatorIcon className="drag-handle" />
      </div>
    ) : null
    )

    return (
      <>
        <label className="mt-2">{label ?? ""}</label>
         <div className="row">

          <div className="col-3">
            <label>
              
                <input
                    type="radio"
                    name="mediaOther"
                    value="Video"
                    checked={this.state.selectedMedia === 'Video'}
                    onChange={this.handleMediaChange}
                />  Video 
          </label>  </div>
          <div className="col-3">
            <label>
         
                <input
                    type="radio"
                    name="mediaOther"
                    value="Image"
                    checked={this.state.selectedMedia === 'Image'}
                    onChange={this.handleMediaChange}
                /> Image 
            </label> </div>
            </div>
        
       {this.state.selectedMedia=="Video"?<div  className="row">
           <div className="form-group col-6">
           <label htmlFor="imageInput">Video URL*</label>
           <div className="input-group">
              <input
                onChange={(e) => this.handleIconChange(e, 'Video URL')}
                id="videoUrl"
                name="videoUrl"
                type="file"
                accept="image/*"
                disabled={ !isEditable}
               // className={"form-control" + (errors.image ? " has-error" : "")}
              />
            { /* {this.renderPencilIcon("videoUrl")}*/}
            </div><br/>
            <label htmlFor="imageInput">Video Thumbnail*</label>
           <div className="input-group">
              <input
                onChange={(e) => this.handleIconChange(e, 'Video ThumbNail')}
                id="videoThumbnailInput"
                name="image"
                type="file"
                accept="image/*"
                disabled={ !isEditable}
               // className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {/*{this.renderPencilIcon("videoThumbnailImage")}*/}
            </div>


       </div>
      </div>
       
       :<div className="row">
          <div className="form-group col-6">
            
            <input
              type="file"
              onChange={(e) => this.handleIconChange(e, 'Primary Image')}
              disabled={!isEditable}
              id={`${id}ImageInput`}
              name="image"
              accept="image/*"
              className="form-control"
            />
          </div>
        </div>}
        <div className="row">
          <div className="col">
            {(images && images.length) ? <label>Selected Thumbnail Image</label> : ""}
            <ReactDragListView {...this.dragProps}>
              <div className="media-scroller snaps-inline">{imageList}</div>
            </ReactDragListView>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {(images && images.length) ? <label>Selected Video</label> : ""}
            
              <div className="media-scroller snaps-inline">{videoList}</div>
            
          </div>
        </div>

      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherImages);
