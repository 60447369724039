import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareNumbers } from "utils/commons";

SelectHowDoYouHearAboutUsOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  showOnlyActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SelectHowDoYouHearAboutUsOptions.defaultProps = {
  options: [],
  selectedOptions: [],
  onSelect: (f) => f,
  menuPlacement: "top",
  showOnlyActive: false,
  isDisabled: false,
};

function SelectHowDoYouHearAboutUsOptions(props) {
  const {
    options,
    selectedOptions,
    onSelect,
    menuPlacement,
    showOnlyActive,
    isDisabled,
  } = props;

  const findOption = (id) => options.find((option) => option.id === id);

  const dropdownOptions = createOptions(options, showOnlyActive);

  const selectedDropdownOptions = selectedOptions
    .map(findOption)
    .map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedOptions && selectedOptions.join(",")
      }`}
      value={selectedDropdownOptions}
      options={dropdownOptions}
      onChange={onSelect}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(options, showOnlyActive) {
  if (!options || !options.length) return [];

  const [activeOptions, inactiveOptions] = _.partition(
    options,
    (it) => it.status === "Active"
  );
  activeOptions.sort((first, second) =>
    compareNumbers(first.rank, second.rank)
  );

  if (showOnlyActive) return activeOptions.map(createOption);
  
  inactiveOptions.sort((first, second) =>
    compareNumbers(first.rank, second.rank)
  );
  return [
    {
      label: "Active",
      options: activeOptions.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveOptions.map(createOption),
    },
  ];
}

function createOption(option) {
  if (!option) return;
  return {
    key: option.id,
    value: option.id,
    label: option.nameEnglish,
  };
}

export default SelectHowDoYouHearAboutUsOptions;
