import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";

SelectSampleType.propTypes = {
  sampleTypes: PropTypes.arrayOf(PropTypes.object),
  selectedSampleType: PropTypes.number.isRequired,
  onSampleTypeSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

function SelectSampleType(props) {
  const {
    sampleTypes = [],
    selectedSampleType,
    onSampleTypeSelect = (f) => f,
    isDisabled = false,
  } = props;

  const findSampleType = (sampleTypeId) => {
    return sampleTypes?.find(
      (sampleType) => sampleType.id === sampleTypeId
    );
  };

  const [activeSampleTypes, inactiveSampleTypes] = _.partition(
    sampleTypes,
    (it) => it.is_active ?? false
  );
  activeSampleTypes.sort((first, second) => {
    if (first.id > second.id) return 1;
    else if (first.id < second.id) return -1;
    else return 0;
  });
  inactiveSampleTypes.sort((first, second) => {
    if (first.id > second.id) return 1;
    else if (first.id < second.id) return -1;
    else return 0;
  });
  const options = [
    {
      label: "Active",
      options: activeSampleTypes.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveSampleTypes.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const selected = findSampleType(selectedSampleType);

  return (
    <Select
      key={`my_unique_select_key__${
        selected && selected.id
      }`}
      value={selected && createOption(selected)}
      onChange={onSampleTypeSelect}
      options={options}
      styles={selectStyles}
      placeholder="Sample Type"
      isDisabled={isDisabled}
    />
  );
}

const createOption = (sampleType) => {
  if (!sampleType) return;
  return {
    key: sampleType.id,
    value: sampleType.id,
    label: sampleType.name,
  };
};

export default SelectSampleType;
