import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import PhoenixAPI from "utils/PhoenixAPI";
import QuestionType from "components/Common/QuestionType";
import { formatDate } from "utils/commons";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

class SurveyResultDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveyResults: [],
    };
    this.columns = [
      {
        name: "Question",
        selector: "questionEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Question (Arabic)",
        selector: "questionArabic",
        wrap: true,
        sortable: true,
        right: true,
      },
      {
        name: "AnswerType",
        selector: "answerType",
        sortable: true,
        center: true,
        cell: (question) => {
          const questionType = this.getQuestionType(question.answerType);
          return questionType ? (
            <QuestionType questionType={questionType} />
          ) : null;
        },
      },
      {
        name: "Answer",
        selector: "options",
        wrap: true,
        sortable: true,
        center: true,
        cell: (review) => this.renderReviewAnswers(review.options),
      },
      {
        name: "Answer (Arabic)",
        selector: "options",
        wrap: true,
        center: true,
        sortable: true,
        cell: (review) => this.renderReviewAnswersArabic(review.options),
      },
    ];
  }

  componentDidMount() {
    this.fetchSurveyResult();
  }

  renderReviewAnswers = (options = []) => {
    const results = options
      // .filter((option) => option.is_active ?? false)
      .sort((first, second) => first.rank - second.rank)
      .filter((option) => option.answerEnglish)
      .map((option, index) => `${option.answerEnglish}`);
    let plainText = options
      plainText=[...new Set(options.filter((option) => option.plainText).map(option => `${option.plainText} (Plain Text)`))]
    return (
      <ul className="my-auto" style={{ width: "100%" }}>
        {results &&
          results.map((result, index) => (
            <li key={index} style={{ listStylePosition: "inside" }}>
              {result}
            </li>
          ))}
        {plainText &&
          plainText
            .filter((it) => it)
            .map((it) => <li style={{ listStylePosition: "inside" }}>{it}</li>)}
      </ul>
    );
  };

  renderReviewAnswersArabic = (options = []) => {
    const results = options
      // .filter((option) => option.is_active ?? false)
      .sort((first, second) => first.rank - second.rank)
      .filter((option) => option.answerArabic)
      .map((option, index) => `${option.answerArabic}`);
    return (
      <ul className="my-auto" style={{ width: "100%" }}>
        {results &&
          results.map((result) => (
            <li style={{ listStylePosition: "inside" }}>{result}</li>
          ))}
      </ul>
    );
  };

  getQuestionType = (answerType) => {
    switch (answerType) {
      case "SINGLE_SELECT":
      case "Single Select":
        return 1;
      case "MULTI_SELECT":
      case "Multi Select":
        return 2;
      case "PLAIN_TEXT":
      case "Plain Text":
        return 3;
      case "SINGLE_SELECT_PLUS_PLAIN_TEXT":
      case "Single Select + Plain Text":
        return 4;
      case "MULTI_SELECT_PLUS_PLAIN_TEXT":
      case "Multi Select + Plain Text":
        return 5;
      default: {
        // console.log(" getQuestionType: Invalid answerType", answerType);
        return null;
      }
    }
  };

  fetchSurveyResult = async () => {
    let { userId, questionnaireId, questionaireType } = this.props.match.params;
    questionnaireId = parseInt(questionnaireId);
    this.showLoading();
    PhoenixAPI.get("survey-results", {
      params: {
        userId: userId,
      },
    })
      .then((response) => {
        const allSurveys = response.data || [];
        let { surveyResults } = { ...allSurveys };
        surveyResults = surveyResults.filter(
          (it) => it.surveyName === questionaireType
        );
        this.setState({
          surveyResults: surveyResults,
        });
        this.hideLoading();
      })
      .catch((error) => {
        console.log("Error on fetching surveys:", error);
        this.showErrorNotification("Some error has occured. Please try again.");
      });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderSurveyName = () => {
    const { questionaireType, lastUpdated } = this.props.match.params;
    return (
      <>
        <span className="font-weight-bold"> #{questionaireType}</span>
        <br />
        <span>
          {"Date Completed:"} {formatDate(lastUpdated)}
        </span>
      </>
    );
  };

  render() {
    const { surveyResults } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-1">
            <i
              onClick={() => this.props.history.goBack()}
              className="caret square left icon big"
              style={{ fontSize: "40px", cursor: "pointer" }}
            ></i>
          </div>
          <div className="col-4">{this.renderSurveyName()}</div>
        </div>
        <Card body style={{ marginTop: "25px" }}>
          {/* <p className="font-weight-bold">Surveys</p> */}
          <DataTable
            data={surveyResults || []}
            columns={this.columns}
            // defaultSortField="questionEnglish"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
          />
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SurveyResultDetails);
