import React from "react";
import PropTypes from "prop-types";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";

function Status({ isActive = false }) {
  if (isActive)
    return (
      <span className="badge badge-success pt-1">
        <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
        Active
      </span>
    );
  else
    return (
      <span className="badge badge-warning pt-1">
        <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
        Inactive
      </span>
    );
}

Status.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Status;
