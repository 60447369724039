import API from "utils/API";
import history from "utils/history";

export function clearToken() {
  localStorage.removeItem("ajb458s7c");
  localStorage.removeItem("854sbxdy");
}

export function setAccessToken(token) {
  localStorage.setItem("ajb458s7c", token);
}

export function setRefreshToken(token) {
  localStorage.setItem("854sbxdy", token);
}

export function getAccessToken() {
  const token = localStorage.getItem("ajb458s7c");

  if (token) return token;

  return null;
}

export function getRefreshToken() {
  const token = localStorage.getItem("854sbxdy");

  if (token) return token;

  return null;
}

export function hasToken() {
  const token = localStorage.getItem("ajb458s7c");

  if (token) return true;

  return false;
}

export function refreshAccessToken() {
  const token = getRefreshToken();

  API.post("token/refresh/", { refresh: token })
    .then((response) => {
      setAccessToken(response.data.access);
    })
    .catch((error) => {
      console.log("Error on refreshing access token", error);
      history.push("/login");
      clearToken();
    });
}
