import React from "react";
/*
   biomarkerStatusObject will be like this.
    {
        "id": 201,
        "status_name": "Non-Diabetic Range",
        "status_name_ar": "مستوى طبيعي",
        "gender": "F",
        "status_color_code": "#40C1AB",
        "range_start": 4,
        "range_end": 5.7,
        "created_at": "2021-06-09T17:14:09.663837",
        "modified_at": "2022-03-15T14:24:36.032867",
        "country": 1,
        "biomarker": 35
    },
*/
function RangeVertical({ biomarkerStatusList = [] }) {
  const biomarkerStatusColors = biomarkerStatusList
    .map((it) => it.status_color_code)
    .reverse();

  const rangeValues = [];
  biomarkerStatusList.forEach((item, index) => {
    if (index === biomarkerStatusList.length - 1) {
      rangeValues.push(item.range_start);
      rangeValues.push(item.range_end);
    } else {
      rangeValues.push(item.range_start);
    }
  });
  console.log("RangeVertical: rangeValues: ", rangeValues);
  rangeValues.reverse();
  console.log("RangeVertical: after reverse rangeValues: ", rangeValues);

  const getBackgroundColor = (index) => {
    return biomarkerStatusColors && biomarkerStatusColors.length > index
      ? biomarkerStatusColors[index]
      : "pink";
  };

  const getLastHeight = () => {
    var heights = rangeValues ?? [];
    var lastHeight = 0;
    biomarkerStatusList.forEach((status, index) => {
      const heightDiff =
        heights !== 0 ? heights[index] - heights[index + 1] : heights[index];
      var heightN =
        heights[index] !== 0 && heights[0] !== 0
          ? (heightDiff / heights[0]) * 100
          : 0;
      if (index !== 0) {
        lastHeight = lastHeight + heightN * 2.5;
      }
    });
    return lastHeight;
  };

  const heights = rangeValues ?? [];
  const lastHeight = getLastHeight();
  const foos = biomarkerStatusList.map((item, index) => {
    const heightDiff =
      heights !== 0 ? heights[index] - heights[index + 1] : heights[index];
    const height =
      heights[index] !== 0 && heights[0] !== 0
        ? (heightDiff / heights[0]) * 100
        : 0;
    const verticalViewHeight =
      index === 0 ? 260 - parseInt(lastHeight) : height * 2.6;

    const reverseBiomarkerStatusList = [...biomarkerStatusList].reverse() ?? [];
    return (
      <div
        key={item.id}
        style={{
          borderTopLeftRadius: index === 0 ? 10 : 0,
          borderTopRightRadius: index === 0 ? 10 : 0,
          borderBottomLeftRadius:
            index === biomarkerStatusList.length - 1 ? 10 : 0,
          borderBottomRightRadius:
            index === biomarkerStatusList.length - 1 ? 10 : 0,
          height: verticalViewHeight ? verticalViewHeight : 0,
          backgroundColor: getBackgroundColor(index),
          width: 10,
        }}
      >
        {index !== 0 ? (
          <>
            <p
              style={{
                marginLeft: -25,
                marginTop: -20,
                fontSize: 8,
                fontWeight: "bold",
              }}
            >
              {reverseBiomarkerStatusList &&
              reverseBiomarkerStatusList.length > index
                ? reverseBiomarkerStatusList[index].range_start ?? "0"
                : "0"}
            </p>
          </>
        ) : (
          <>
            <p
              style={{
                marginLeft: -20,
                fontSize: 8,
                fontWeight: "bold",
              }}
            >
              -
            </p>
          </>
        )}
      </div>
    );
  });
  return (
    <div style={{ position: "absolute", left: 33, top: 42, width: 10 }}>
      {foos}
    </div>
  );
}

export default RangeVertical;
