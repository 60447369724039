import React from "react";
import PropTypes from "prop-types";

ImagePreview.propTypes = {
  label: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  showDeleteIcon: PropTypes.bool,
  onDeleteImage: PropTypes.func,
};

ImagePreview.defaultProps = {
  labe: "Image Preview",
  showDeleteIcon: false,
  onDeleteImage: (f) => f,
};

function ImagePreview(props) {
  const { label, imageUrl, showDeleteIcon, onDeleteImage } = props;
  return (
    <>
      <label htmlFor="image_preview">{label}</label>
      <div className="image-area">
        <img
          src={imageUrl}
          id="image_preview"
          name="image_preview"
          alt="Selected"
          className="img-thumbnail mx-auto"
          style={{
            width: "100%",
            maxHeight: "150px",
            objectFit: "cover",
          }}
        />
        {showDeleteIcon && (
          <button
            onClick={onDeleteImage}
            className="remove-image"
            style={{ display: "inline" }}
          >
            <i className="fa fa-times"></i>
          </button>
        )}
      </div>
    </>
  );
}

export default ImagePreview;
