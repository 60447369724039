import React ,{Component}from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import PhoenixAPI from "utils/PhoenixAPI";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import {fetchNewLongevityCategories} from "services/LongevityService";
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
class SuggestedDefaultPackageForm extends Component {

    constructor(props) {
        super(props);
        const { suggestedDefaultPackages ,isEditable,country } = this.props;
        
        
        this.state = {
            suggestedDefaultPackages,
            suggestedDefaultPackage: {},
            errors: {},
            editables: {},
            isPackageViewModal: false,
            wellbeingCoaches:[],
            customPackages:[],
            country,
            isEditable,
        };
    
        this.columns = [
            {
              name: "ID",
              selector: "id",
              sortable: true,
              width: "50px",
              maxWidth: "100px",
            },
            {
              name: "Suggested Package Name",
              selector: "customPackageName",
              sortable: true,
              wrap: true,
              format: (defaultPackage) => (
                <div className="text-overflow-ellipsis">
                  {defaultPackage.customPackageName ?? ""}
                </div>
              ),
            },
            {
                name: "Status",
                selector: "status",
                sortable: true,
                center: true,
                maxWidth: "150px",
                cell: (defaultPackage) => <Status isActive={defaultPackage.isActive ?? false} />,
              },
              {
                name: "Edit",
                center: true,
                cell: (defaultPackage) => this.renderEditButton(defaultPackage.id),
              }
        
        
        ]

    }

    showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

    clearForm = () => {
        this.setState({
          errors: {},
          editables: {},
          suggestedDefaultPackage: {},
        });
      };
      componentDidMount() {
       this.fetchCustomPackages();
       this.fetchDefaultCoachDetails();
      }
      componentDidUpdate(prevProps) {
        if (this.props.suggestedDefaultPackages !== prevProps.suggestedDefaultPackages) {
          this.setState({ suggestedDefaultPackages: this.props.suggestedDefaultPackages });
        }
        if(this.props.country !== prevProps.country){
          this.setState({country:this.props.country});
          this.fetchCustomPackages();
          this.fetchDefaultCoachDetails();

        }

      }

      fetchCustomPackages=async()=>{
        const customPackageUrl="custom/packages";
        try {
          this.showLoading();
          const { data=[] } = await PhoenixAPI.get(customPackageUrl);
          
          let customPackage = [{ text: "None", value: null, key: "None"}];
          data.length>0 && data.filter((item)=>item.status=="Active" && item.country==this.props.country)
          .map((item) => {
            let packagedetail = {};
            packagedetail["text"] = item.name;
          packagedetail["value"] = item.id;
            packagedetail["key"] = item.id;
            
          customPackage.push(packagedetail);
          })
        console.log("packages",customPackage);
         this.setState({customPackages:customPackage})
  
        }
        catch(error){
          this.showErrorNotification("Error on fetching custom packages! " + error.message);
          return null;
        }
        finally {
          this.hideLoading();
        }
  
  
  
      }
  

      handleAddNewPackage = () => {
        this.setState({ isNewDefaultPackage: true }, () => this.showPackageViewModal());
      };
      handleViewPackage= (packageId) => {
        const defaultPackages = this.state.suggestedDefaultPackages ?? [];
        const suggestedDefaultPackage= defaultPackages.find((it) => it.id === packageId);
        if (!suggestedDefaultPackage) {
          console.log(`Category with id ${packageId} is not found!`);
          return;
        }
        this.setState(
          {
            suggestedDefaultPackage,
            isNewDefaultPackage: false,
          },
          () => this.showPackageViewModal()
        );
      };

      setEditable = (field) => {
        const editables = { ...this.state.editables };
        editables[field] = true;
        this.setState({ editables });
      };

      renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewDefaultPackage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

    resetForm = () => {
      this.setState({
        suggestedDefaultPackage: {},
        errors: {},
        editables: {},
      });
    };

    renderEditButton = (id) => (
      <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          onClick={() => {
            this.handleViewPackage(id);
          }}
        >
          Edit
        </button>
      </div>
    );
    
      showPackageViewModal = () => {
        const { country, isNewDefaultPackage } = this.state;
        if (isNewDefaultPackage) {
          const suggestedDefaultPackage= {
            countryId: country,
            isActive: false,
          };
          this.setState({
            suggestedDefaultPackage,
            isPackageViewModal: true,
          });
        } else {
          this.setState({ isPackageViewModal: true });
        }
      };

      createSuggestedPackage=async(suggestedDefaultPackage)=>{
       const{country}=this.state;
      try{
       const postUrl= "longevity-default-custom-package-suggestions";
        
        PhoenixAPI.post(postUrl, suggestedDefaultPackage);
        this.fetchLongevityCategories();
      }
      catch(error){
        this.showErrorNotification("Error on fetching suggested packages! " + error.message);
        return null;
      }
      }
    
      showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });
      updateSuggestedDefaultPackage=(suggestedDefaultPackage)=>{
        try{
        const postUrl= `longevity-default-custom-package-suggestions/${suggestedDefaultPackage.id}`;
        PhoenixAPI.patch(postUrl, suggestedDefaultPackage);
        this.fetchLongevityCategories();
        }
        catch(error){
          this.showErrorNotification("Error on fetching updated suggested packages! " + error.message);
          return null;
        }
      }
    

      fetchDefaultCoachDetails = async () => {
       
         const{country}    =this.state
        try {
          this.showLoading();
          const url = `nutritionist-user-name/`;
          const { data=[]} = await PhoenixAPI.get(url);
          let coachDetails = [{ text: "None", value: null, key: "None", default_coach_id: null, is_active:false}];
          data.length>0 && data.filter((item)=>item.is_active)
          .map((item) => {
            let coach = {};
            coach["text"] = item.full_name;
            coach["value"] = item.id;
            coach["key"] = item.full_name;
            coach["default_coach_id"] = item.id;
            coach["is_active"] = item.is_active;
          coachDetails.push(coach);
          })
          this.setState({wellbeingCoaches:coachDetails});
        }
        catch (error) {
          this.showErrorNotification("Error on fetching default coach details ! " + error.message);
          return null;
        }
        finally {
          this.hideLoading();
        }
      }
  
    
      fetchLongevityCategories = async () => {
        this.showLoading();
        try {
          const categories = (await fetchNewLongevityCategories()) || [];
         
         
          this.setState({ 
           suggestedDefaultPackages:categories.defaultCustomPackages,
           });
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching Longevity Categories:",
            error.message
          );
          this.setState({ loadError: "Some Error has occured. Please try again" });
        } finally {
          this.hideLoading();
        }
      };
      validate=()=>{
        const { suggestedDefaultPackage } = this.state;
        const errors = {};
        if (!suggestedDefaultPackage.customPackageId) {
          errors.suggestedPackage = "default package is required field";
        }
        
       
        if(!suggestedDefaultPackage.coachId){
          errors.defaultCoach="defaultCoach is required field";
        }
        
        return Object.keys(errors).length == 0? null : errors;
      }
      handleSaveDefaultPackage = async () => {
        const {  suggestedDefaultPackage} = this.state;
        try {
          this.showLoading();
          const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
          const isValidId = suggestedDefaultPackage.id !== null && suggestedDefaultPackage.id !== undefined;
          const response =isValidId
            ? await this.updateSuggestedDefaultPackage(suggestedDefaultPackage)
            : await this.createSuggestedPackage(suggestedDefaultPackage);
          this.hidePackageViewModal();
          this.showSuccessNotification(
            isValidId
              ? "Package Updated Successfully."
              : "Package Created Successfully."
          );
          this.fetchLongevityCategories();
        } catch (error) {
          this.showErrorNotification(
            "Error on fetching Longevity Categories:",
            error.message
          );
        } finally {
          this.hideLoading();
        }
      };
    
      
    
      
    
      hidePackageViewModal = () => {
        this.setState({ isPackageViewModal: false });
        this.resetForm();
      };
    
      handleChange = ({ currentTarget: input }) => {
        const category = { ...this.state.category };
        category[input.name] = input.value;
        this.setState({ category });
      };
      getFilteredSuggestedPackages=()=>{
        const{suggestedDefaultPackages, country } = this.state;
        console.log("getfiltered",suggestedDefaultPackages);
        return suggestedDefaultPackages && country
         ? suggestedDefaultPackages.filter((suggestedPackage) => suggestedPackage.countryId === country)
         : [];
         
       }
      
    
      

      render() {
    const{suggestedDefaultPackages,suggestedDefaultPackage={},isEditable,isNewDefaultPackage,wellbeingCoaches,errors,editables,customPackages}=this.state;
    const filteredSuggestedPackages = this.getFilteredSuggestedPackages(suggestedDefaultPackages) ?? [];
    console.log("default package",suggestedDefaultPackages,suggestedDefaultPackage,customPackages);
    const packageForm=(<>
    <div className="container mt-4">
    <div className="row">
      <div className="form-group col-6">
        <label htmlFor="default package">Default Package</label>
        <div className="input-group">
         <Dropdown 
         value={ suggestedDefaultPackage.customPackageId||null}
         onChange={(event, value) => {
           console.log("value",value);
            const suggestedDefaultPackage = { ...this.state.suggestedDefaultPackage };
            suggestedDefaultPackage.customPackageId= value.value;
            
            this.setState({ suggestedDefaultPackage});
            }
           
         }
         id={`suggestedPackage`}
         name="suggestedPackage"
         placeholder="None"
         search
         selection
         options={customPackages}
         disabled={!isNewDefaultPackage && !editables.suggestedPackage}
         className={
           "form-control" + (errors.default_coach_id ? " has-error" : "")
         }
         
         />
          {this.renderPencilIcon("suggestedPackage")}
        </div>
         
        <span className="help-block">{errors.suggestedPackage}</span>
  
    </div>
    <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={suggestedDefaultPackage.isActive}
                onChange={(event, value) => {
                  this.setState({
                    suggestedDefaultPackage: {
                      ...suggestedDefaultPackage,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="isActive"
                placeholder="status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewDefaultPackage && !editables.isActive}
                className={"form-control" + (errors.isActive ? " has-error" : "")}
              />
              {this.renderPencilIcon("isActive")}
            </div>
            <span className="help-block">{errors.isActive}</span>
          </div>


       
    </div>
        <div className="row">
            <div className="form-group col-6">
              <label htmlFor="is_active">Default Coach</label>
              <div className="input-group">
              <Dropdown
                value={suggestedDefaultPackage.coachId||null}
                onChange={(event, value) => {
                  const suggestedDefaultPackage = { ...this.state.suggestedDefaultPackage };
            suggestedDefaultPackage.coachId= value.value;
            
            this.setState({ suggestedDefaultPackage});
                  
                }}
                id={`defaultCoach`}
                name="defaultCoach"
                placeholder="None"
                search
                selection
                options={wellbeingCoaches}
                disabled={!isNewDefaultPackage && !editables.defaultCoach}
                className={
                  "form-control" + (errors.default_coach_id ? " has-error" : "")
                }
              />
               {this.renderPencilIcon("defaultCoach")}
              

              </div>
              <span className="help-block">{errors.defaultCoach}</span>
              </div>
              </div>
      
      
              </div>          
         
    </>

    );

        return(
            <div>
           {filteredSuggestedPackages?.length<1 ?   <div className="page-header">
          <div className="actions">
            
            <div className="action-item">
              <button
                onClick={this.handleAddNewPackage}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Package
              </button>
            </div>
          </div>
        </div>:<></>}
                <Card>
                    <Card.Header>Suggested Default Packages</Card.Header>
                    <Card.Body>
                    <DataTable
                highlightOnHover
                columns={this.columns}
                data={filteredSuggestedPackages || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }/>
                 
                    </Card.Body>
                </Card>
                <Modal
          size="lg"
          show={this.state.isPackageViewModal}
          onHide={this.hidePackageViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  { suggestedDefaultPackage.id ? "Edit Parent Category" : "Add Parent Category"}{" "}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveDefaultPackage}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{packageForm}</Modal.Body>
        </Modal>

            </div>
        )
    }
}
      SuggestedDefaultPackageForm.propTypes = {
        suggestedDefaultPackages: PropTypes.arrayOf(PropTypes.object).isRequired,
        country:PropTypes.number.isRequired,
        isEditable: PropTypes.bool.isRequired,
      };
      
      SuggestedDefaultPackageForm.defaultProps = {
        suggestedDefaultPackages: [],
        country:"",
        
        isEditable: true,
      };
      
      function mapStateToProps(state) {
        return {};
      }
      
      function mapDispatchToProps(dispatch) {
        return {
          showNotificationMessage: (value) =>
            dispatch({ type: "SHOW_NOTIFICATION", value }),
          toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
        };
      }
      
      export default connect(mapStateToProps, mapDispatchToProps)(SuggestedDefaultPackageForm);
      



