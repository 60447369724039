import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { UploadMediaFile } from "services/UploadMedia";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class UspSection extends Component {
  constructor(props) {
    super(props);

    const { usps = [], onUspsUpdated = (f) => f } = this.props;

    this.onUspsUpdated = onUspsUpdated;

    this.state = {
      usps,
      usp: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",

      uspTextEn: RichTextEditor.createEmptyValue(),
      uspTextAr: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "USP (English)",
        selector: "uspTextEn",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.uspTextEn ?? ""}
          </div>
        ),
      },
      {
        name: "USP (Arabic)",
        selector: "uspTextAr",
        sortable: true,
        wrap: true,
        // right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            // style={{ textAlign: "right" }}
          >
            {question.uspTextAr ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",

        center: true,

        cell: (question) => this.renderActionBar(question),
      },
    ];
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      usp: {},
      isNewQuestion: false,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const usp = { ...this.state.usp };
    usp[input.name] = input.value;
    this.setState({ usp });
  };

  handleEdit = (id) => {
    const { usps = [] } = this.state;

    const usp = usps.find((it) => it.id === id);

    if (!usp) return;
    const uspTextEn = RichTextEditor.createValueFromString(
      usp.uspTextEn ?? "",
      "html"
    );
    const uspTextAr = RichTextEditor.createValueFromString(
      usp.uspTextAr ?? "",
      "html"
    );

    const newQuestion = {
      ...usp,
      uspTextEn: usp.uspTextEn,
      uspTextAr: usp.uspTextAr,
    };
    this.setState(
      {
        usp: newQuestion,
        uspTextEn: uspTextEn,
        uspTextAr: uspTextAr,
      },
      () => this.showAddEditModal()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { usp = {} } = this.state;
    if (usp.id === null || usp.id === undefined) this.createUsp();
    else this.updateUsp(usp.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { headerImage } = this.state.usp || {};
    const { uspTextEn, uspTextAr } = this.state;
    // if (!headerImage) errors.headerImage = "Image cannot be blank";

    // if (!uspTextEn) errors.uspTextEn = "Main Text cannot be blank";

    // if (!uspTextAr) errors.uspTextAr = "Main Text cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createUsp = () => {
    const { usps = [], usp } = this.state;
    const ids = usps.length > 0 ? usps.map((it) => it.id) : null;
    usp.id = ids ? Math.max(...ids) + 1 : 1;

    usp.is_new = true;

    this.setState({ usps: [...usps, usp] }, () => {
      this.showSuccessNotification("Usp Created Successfully!");
      this.onUspsUpdatedLocally();
    });
  };

  updateUsp = (id) => {
    const { usps = [], usp } = this.state;
    const newQuestions = usps.map((it) => (it.id === id ? usp : it));
    this.setState({ usps: newQuestions }, () => {
      this.showSuccessNotification("Usp Updated Successfully!");
      this.onUspsUpdatedLocally();
    });
  };

  onUspsUpdatedLocally = () => {
    this.clearForm();
    this.onUspsUpdated(
      this.state.usps.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  createRankOptions = () => {
    const { isNewQuestion, usps } = this.state;

    const ranks = usps.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  handleAddNewQuestion = () => {
    this.setState(
      {
        isNewQuestion: true,
        uspTextEn: RichTextEditor.createEmptyValue(),
        uspTextAr: RichTextEditor.createEmptyValue(),
      },
      () => this.showAddEditModal()
    );
  };

  handleSelectImage = async (e) => {
    const usp = this.state.usp;
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      const { message: imageUrl = "", statusCode = "" } = await UploadMediaFile(
        file
      );
      const newQuestion = { ...usp, image: imageUrl };
      this.setState({ usp: newQuestion });
      console.log("Image", imageUrl);
    }
  };

  handleRichText = (value, name) => {
    const { usp } = this.state;
    const newQuestion = { ...usp, [name]: value.toString("html") };
    this.setState({ usp: newQuestion, [name]: value });
  };

  showAddEditModal = () => {
    const { isNewQuestion } = this.state;
    const rankOptions = this.createRankOptions() || [];
    if (isNewQuestion) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const usp = {
        isActive: false,
        rank,
      };
      this.setState({ isAddEditModalVisible: true, usp, rankOptions });
    } else {
      this.setState({ isAddEditModalVisible: true, rankOptions });
    }
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({
      isAddEditModalVisible: false,
      uspTextEn: RichTextEditor.createEmptyValue(),
      uspTextAr: RichTextEditor.createEmptyValue(),
    });
  };

  renderActionBar = (question) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(question.id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.usp.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { usps = [], usp = {}, errors, editables, sortColumn } = this.state;

    const isNewQuestion = usp.id === null || usp.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>USP (English) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.uspTextEn}
                onChange={(value) => this.handleRichText(value, "uspTextEn")}
                disabled={!isNewQuestion && !editables.uspTextEn}
              />
              {this.renderPencilIcon("uspTextEn")}
            </div>
            <span className="help-block">{errors.uspTextEn}</span>
          </div>
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>USP (Arabic) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.uspTextAr}
                onChange={(value) => this.handleRichText(value, "uspTextAr")}
                disabled={!isNewQuestion && !editables.uspTextAr}
              />
              {this.renderPencilIcon("uspTextAr")}
            </div>
            <span className="help-block">{errors.uspTextAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={usp.rank}
                onChange={(event, value) => {
                  this.setState({
                    usp: { ...usp, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={usp.isActive}
                onChange={(event, value) => {
                  this.setState({
                    usp: {
                      ...usp,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label htmlFor="imageInput">Image </label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="imageInput"
                name="image"
                type="file"
                accept="image/*"
              />
              {this.renderPencilIcon(`image`)}
            </div>
            <span className="help-block">{errors.image}</span>
          </div>
          <div className="form-group col">
            {usp.image && (
              <>
                <label htmlFor="selected_package_image">Preview</label>
                <div className="form-image-container">
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={usp.image}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={usps || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {usp.id === null || usp.id === undefined
                ? "Add Usp Section"
                : "Edit Usp Section"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {usp.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

UspSection.propTypes = {
  usps: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUspsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

UspSection.defaultProps = {
  usps: [],
  onUspsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UspSection);
