import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import _ from "lodash";
import { compareStrings } from "utils/commons";
import { fetchCountries } from "services/RegionService";

SelectCountry.propTypes = {
  selectedCountry: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

SelectCountry.defaultTypes = {
  onSelect: (f) => f,
  isDisabled: false,
};

function SelectCountry(props) {
  const { selectedCountry, onSelect, isDisabled } = props;
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetchCountries()
      .then((countries) => setCountries(countries))
      .catch((error) => console.log("error on fetcing countries: ", error));
  }, []);

  const defaultCountry =
    countries && countries.find((it) => it.is_default ?? false);
  const [activeCountries, inactiveCountries] = _.partition(
    countries,
    (it) => it.is_active ?? false
  );
  activeCountries.sort((first, second) =>
    compareStrings(first.country_name, second.country_name, true)
  );
  inactiveCountries.sort((first, second) =>
    compareStrings(first.country_name, second.country_name, true)
  );
  const options = [
    {
      label: "Active",
      options: activeCountries.map(createOption),
    },
    {
      label: "Inactive",
      options: inactiveCountries.map(createOption),
    },
  ];

  const selectStyles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  const defaultCountryOption = createOption(defaultCountry);

  return (
    <Select
      key={`my_unique_select_key__${selectedCountry && selectedCountry.id}`}
      value={selectedCountry}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      placeholder="Select Country..."
      isDisabled={isDisabled}
    />
  );
}

const createOption = (country) => {
  return country
    ? {
        key: country.id,
        value: country.id,
        label: country.country_name,
      }
    : null;
};

export default SelectCountry;
