import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import ServiceItemsForm from "./ServiceItemsForm";
import { fetchCountries } from "services/RegionService";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import PhoenixAPI from "utils/PhoenixAPI";
import AccessDenied from "components/Common/AccessDenied";
import internalCategoryOptions from "utils/InterenalCategory";
const BASE_URL_SERVICE_CATEGORIES = "service-category/";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];



class ServiceCategories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      serviceCategories: [],
      rankOptions: [],
      serviceCategory: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isServiceCategoryViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Internal Name",
        selector: "internalName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (English)",
        selector: "externalNameEnglish",
        wrap: true,
        sortable: true,
      },
      {
        name: "Name (Arabic)",
        selector: "externalNameArabic",
        wrap: true,
        sortable: true,
        right: true,
        format: (serviceCategory) => (
          <p style={{ textAlign: "right" }}>
            {serviceCategory.externalNameArabic}
          </p>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (serviceCategory) => (
          <Status isActive={serviceCategory.status === "Active"} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (serviceCategory) => this.renderViewButton(serviceCategory.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchServiceCategories();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.SERVICE_CATEGORY;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchCountries = async () => {
    this.showLoading();
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries", error);
    } finally {
      this.hideLoading();
    }
  };

  fetchServiceCategories = async () => {
    this.showLoading();
    try {
      const { data: serviceCategories = [] } = await PhoenixAPI.get(
        BASE_URL_SERVICE_CATEGORIES
      );
      this.setState({ serviceCategories });
    } catch (error) {
      console.log("Error on fetching service categories", error);
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const serviceCategory = { ...this.state.serviceCategory, image };
      this.setState({ serviceCategory });

      this.readImage(image);
    }
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const serviceCategory = {
        ...this.state.serviceCategory,
        image_src: [reader.result],
      };
      this.setState({ serviceCategory });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const serviceCategory = { ...this.state.serviceCategory };
    if (numberInputs.includes(input.name))
      serviceCategory[input.name] = Number(input.value);
    else serviceCategory[input.name] = input.value;
    this.setState({ serviceCategory });
  };

  handleItemsUpdated = (serviceItems = []) => {
    const serviceCategory = {
      ...this.state.serviceCategory,
      serviceItems,
    };
    this.setState({ serviceCategory });
  };

  handleAddNewServiceCategory = () => {
    this.setState({ isNewServiceCategory: true }, () =>
      this.showServiceCategoryViewModal()
    );
  };

  handleViewServiceCategory = (serviceCategoryId) => {
    const serviceCategories = this.state.serviceCategories ?? [];
    const serviceCategory = serviceCategories.find(
      (it) => it.id === serviceCategoryId
    );
    if (!serviceCategory) {
      console.log(
        `Service Category with id ${serviceCategoryId} is not found!`
      );
      return;
    }

    if (serviceCategory.currency === null || serviceCategory.currency === undefined) {
      serviceCategory.currency = this.state.selectedCountry?.currency;
    }

    this.setState({ serviceCategory, isNewServiceCategory: false }, () =>
      this.showServiceCategoryViewModal()
    );
  };

  showServiceCategoryViewModal = () => {
    const { selectedCountry, isNewServiceCategory } = this.state;
    const rankOptions = this.createRankOptions() || [];

    if (isNewServiceCategory) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const serviceCategory = {
        country: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        rank,
        internalCategory:"None",
      };
      this.setState({
        serviceCategory,
        rankOptions,
        isServiceCategoryViewModalVisible: true,
      });
    } else {
      this.setState({ rankOptions, isServiceCategoryViewModalVisible: true });
    }
  };

  hideServiceCategoryViewModal = () => {
    this.setState({ isServiceCategoryViewModalVisible: false });
    this.resetForm();
  };

  handleSaveServiceCategory = async () => {
    const { selectedCountry, serviceCategory } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        serviceCategory.id !== null && serviceCategory.id !== undefined;

      const response = await this.saveServiceCategory(serviceCategory);
      const message = isValidId
        ? "Service Category Updated Successfully."
        : "Service Category Created Successfully.";
      console.log("Response: ", response);
      this.hideServiceCategoryViewModal();
      this.showSuccessNotification(message);
      this.fetchServiceCategories();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { serviceCategory = {} } = this.state;
    const errors = {};

    const {
      internalName,
      externalNameEnglish,
      externalNameArabic,
      status,
      rank,
      country,
      internalCategory,
    } = serviceCategory;

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > 100)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalNameEnglish || externalNameEnglish.trim() === "")
      errors.externalNameEnglish = "External Name (English) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameArabic || externalNameArabic.trim() === "")
      errors.externalNameArabic = "External Name (Arabic) cannot be blank";
    else if (externalNameEnglish.length > 100)
      errors.externalNameEnglish =
        "External Name (Arabic) is limited to a 100 characters";

    if (status === null) errors.status = "Status cannot be blank!";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (country === null || country === undefined)
      errors.country = "Country cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  saveServiceCategory = async (serviceCategory) => {
    if (serviceCategory.internalCategory == "None"){
      serviceCategory.internalCategory = null
    }
    const request = {
      method: serviceCategory.id ? "PATCH" : "POST",
      url: serviceCategory.id
        ? `${BASE_URL_SERVICE_CATEGORIES}${serviceCategory.id}`
        : BASE_URL_SERVICE_CATEGORIES,
      data: serviceCategory,
    };
    return await PhoenixAPI(request);
  };

  createRankOptions = () => {
    const { isNewServiceCategory } = this.state;
    const serviceCategories = this.getServiceCategoriesForCurrentCountry();
    const ranks = serviceCategories
      .filter((it) => it.rank)
      .map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks, serviceCategories.length) || 0;
    const until = isNewServiceCategory ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({ serviceCategory: {}, errors: {}, editables: {} });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewServiceCategory(id);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewServiceCategory ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  getFilteredServiceCategories = () => {
    const serviceCategories = this.getServiceCategoriesForCurrentCountry();
    return this.getSearchedServiceCategories(serviceCategories);
  };

  getServiceCategoriesForCurrentCountry = () => {
    const { serviceCategories, selectedCountry } = this.state;
    return serviceCategories && selectedCountry
      ? serviceCategories.filter((p) => p.country === selectedCountry.id)
      : [];
  };

  getSearchedServiceCategories = (serviceCategories) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return serviceCategories;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return serviceCategories.filter((serviceCategory) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
      } = serviceCategory;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      serviceCategories,
      serviceCategory,
      isNewServiceCategory,
      isServiceCategoryViewModalVisible,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const filteredServiceCategories =
      this.getFilteredServiceCategories(serviceCategories) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={serviceCategory?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewServiceCategory && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={serviceCategory?.status}
                onChange={(event, value) => {
                  this.setState({
                    serviceCategory: {
                      ...serviceCategory,
                      status: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewServiceCategory && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalNameEnglish">
              External Name (English)*
            </label>
            <div className="input-group">
              <input
                value={serviceCategory?.externalNameEnglish || ""}
                onChange={this.handleChange}
                id="externalNameEnglish"
                name="externalNameEnglish"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalNameEnglish ? " has-error" : "")
                }
                disabled={
                  !isNewServiceCategory && !editables.externalNameEnglish
                }
              />
              {this.renderPencilIcon("externalNameEnglish")}
            </div>
            <span className="help-block">{errors.externalNameEnglish}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameArabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={serviceCategory?.externalNameArabic || ""}
                onChange={this.handleChange}
                id="externalNameArabic"
                name="externalNameArabic"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameArabic ? " has-error" : "")
                }
                disabled={
                  !isNewServiceCategory && !editables.externalNameArabic
                }
              />
              {this.renderPencilIcon("externalNameArabic")}
            </div>
            <span className="help-block">{errors.externalNameArabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={serviceCategory?.rank}
                onChange={(event, value) => {
                  this.setState({
                    serviceCategory: { ...serviceCategory, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewServiceCategory && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewServiceCategory
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(serviceCategory?.country)?.country_name
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="rank">internal Category</label>
            <div className="input-group">
              <Dropdown
                value={serviceCategory?.internalCategory||"None"}
                onChange={(event, value) => {
                  this.setState({
                    serviceCategory: { ...serviceCategory, internalCategory: value.value },
                  });
                }}
                id="internalCategory"
                placeholder="internalCategory"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewServiceCategory && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
        </div>
      </div>
    );

    const formServiceItems = (
      <div className="container mt-4">
        <ServiceItemsForm
          country={selectedCountry?.id}
          items={serviceCategory?.serviceItems || []}
          onItemsUpdated={this.handleItemsUpdated}
          isEditable={this.hasPageAccessRight("edit")}
        />
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={this.handleAddNewServiceCategory}
                disabled={!this.hasPageAccessRight("edit")}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredServiceCategories || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredServiceCategories.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isServiceCategoryViewModalVisible}
          onHide={this.hideServiceCategoryViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Service: </span>
                {serviceCategory?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveServiceCategory}
              disabled={!this.hasPageAccessRight("edit")}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_service_categories" variant="tabs" justify fill>
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_form_service_items" title="Items">
                {formServiceItems}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCategories);
