import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { compareStrings } from "utils/commons";

SelectAccessRights.propTypes = {
  accessRights: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedAccessRightIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSelect: PropTypes.func.isRequired,
  menuPlacement: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SelectAccessRights.defaultProps = {
  accessRights: [],
  selectedAccessRightIds: [],
  onSelect: (f) => f,
  menuPlacement: "bottom",
  isDisabled: false,
};

function SelectAccessRights(props) {
  const {
    accessRights,
    selectedAccessRightIds,
    onSelect,
    menuPlacement,
    isDisabled,
  } = props;

  const findAccessRight = (id) => accessRights.find((accessRight) => accessRight.id === id);

  const options = createOptions(accessRights);

  const selectedAccessRights = selectedAccessRightIds.map(findAccessRight).map(createOption);

  const selectStyles = {
    container: (base) => ({ ...base, flex: 1 }),
  };

  return (
    <Select
      key={`my_unique_select_key__${
        selectedAccessRightIds && selectedAccessRightIds.join(",")
      }`}
      value={selectedAccessRights}
      onChange={onSelect}
      options={options}
      styles={selectStyles}
      menuPlacement={menuPlacement}
      isMulti={true}
      isDisabled={isDisabled}
    />
  );
}

function createOptions(accessRights) {
  if (!accessRights || !accessRights.length) return [];
  accessRights.sort((first, second) => compareStrings(first.name, second.name, true));
  return accessRights.map(createOption);
}

function createOption(accessRight) {
  if (!accessRight) return;
  return {
    key: accessRight.id,
    value: accessRight.id,
    label: accessRight.name,
  };
}

export default SelectAccessRights;
