import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ActionBar from "react-bootstrap/Dropdown";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import _ from "lodash";
import Status from "components/Common/Status";
import { rangeOfNumbers } from "utils/commons";
import {  Tab, Tabs } from "react-bootstrap";
import Logs from "components/Logs/Logs";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

const DISCOUNT_TYPE_OPTIONS = [
  {
    key: "Fixed",
    value: "Fixed",
    text: "Fixed",
  },
  {
    key: "Percentage",
    value: "Percentage",
    text: "Percentage",
  },
];

const  FREQUENCY_TYPE_OPTIONS=[
  {
    key: "Months",
    value: "MONTHS",
    text: "Months",
  },
  {
    key: "Days",
    value: "DAYS",
    text: "Days",
  },
]

const SUBSCRIPTION_FREQUENCY_OPTIONS = rangeOfNumbers(1, 12, true).map(
  (number) => ({
    key: number,
    value: number,
    text: number > 1 ? `${number} Months` : `${number} Month`,
  })
);
const SUBSCRIPTION_DAYS_OPTIONS=rangeOfNumbers(1,90,true).map((number)=>({
  key:number,
  value:number,
  text:number>1?`${number} Days`:`${number} Day`,
}))
 

class SubscriptionForm extends Component {
  constructor(props) {
    super(props);

    const { subscriptions = [], onSubscriptionUpdated = (f) => f } = this.props;

    this.onSubscriptionUpdated = onSubscriptionUpdated;

    this.state = {
      subscriptions,
      subscription: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",
      isConfirmDeleteModalVisible: false,
      pageIdForLogs:null,
    };

    this.columns = [
      {
        name: "",
        width: "50px",
        cell: () => <Drag className="drag-handle"></Drag>,
      },
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Subscription Frequency",
        selector: "noOfMonths",
        sortable: true,
        wrap: true,
        format: (subscription) => (
          <div className="text-overflow-ellipsis">
            {subscription.noOfMonths 
              ? SUBSCRIPTION_FREQUENCY_OPTIONS.find(
                  (it) => it.value === subscription.noOfMonths
                )?.text
              
              : subscription.noOfDays?
              SUBSCRIPTION_DAYS_OPTIONS.find(
                (it) => it.value === subscription.noOfDays
              )?.text
               :""}
          </div>
        ),
      },
      {
        name: "Discount Type",
        selector: "discountType",
        sortable: true,
        wrap: true,
        format: (subscription) => (
          <div className="text-overflow-ellipsis">
            {subscription.discountType ?? ""}
          </div>
        ),
      },
      {
        name: "Discount Amount",
        selector: "discountAmount",
        sortable: true,
        wrap: true,
        format: (subscription) => (
          <div className="text-overflow-ellipsis">
            {subscription.discountAmount ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        width: "50px",
        maxWidth: "100px",
        center: true,
        sortable: "true",
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: (subscription) => (
          <Status isActive={subscription.status === "Active"} />
        ),
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (subscription) => this.renderActionBar(subscription),
      },
    ];
    const updateRank = this.updateRank;
    this.dragProps = {
      onDragEnd(fromIndex, toIndex) {
        updateRank(fromIndex, toIndex);
      },
      nodeSelector: ".rdt_TableRow",
      handleSelector: ".drag-handle",
      enableScroll: true,
    };
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      subscription: {},
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const subscription = { ...this.state.subscription };
    if (input.name === "discountAmount") {
      subscription[input.name] = input.value ? Number(input.value) : null;
    } else {
      subscription[input.name] = input.value;
    }
    this.setState({ subscription });
  };

  handleEdit = (id) => {

    this.setState({
      pageIdForLogs:id
    })

    this.clearForm();
    const { subscriptions = [] } = this.state;
    const subscription = subscriptions.find((it) => it.id === id);
  
    if (!subscription) return;

    this.setState({ subscription }, () => this.showAddEditModal());
  };

  handleStatusChange = (id) => this.toggleStatus(id);

  handleDelete = (id) => this.deleteSubscription(id);

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { subscription = {} } = this.state;
    if (subscription.id === null || subscription.id === undefined)
      this.createSubscription();
    else this.updateSubscription(subscription.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { noOfMonths,noOfDays, status, discountType, discountAmount, subscriptionFreqType } =
      this.state.subscription || {};
    if( subscriptionFreqType=="MONTHS"){
    if (
      noOfMonths === null ||
      noOfMonths === undefined ||
      noOfMonths.toString().trim() === ""
    )
      errors.noOfMonths = "Subscription Frequency cannot be blank";
    else if (Number.isNaN(Number(noOfMonths)))
      errors.noOfMonths = "Subscription Frequency should be a valid number";
    }

     if(subscriptionFreqType=="DAYS"){
      if (
        noOfDays === null ||
        noOfDays === undefined ||
        noOfDays.toString().trim() === ""
      )
        errors.noOfDays = "Subscription Frequency cannot be blank";
      else if (Number.isNaN(Number(noOfDays)))
        errors.noOfDays = "Subscription Frequency should be a valid number";

     }
    if (!discountType) errors.discountType = "Discount Type cannot be blank";

    if (
      discountAmount === null ||
      discountAmount === undefined ||
      discountAmount.toString().trim() === ""
    )
      errors.discountAmount = "Discount Value cannot be blank";
    else if (Number.isNaN(Number(discountAmount)))
      errors.discountAmount = "Discount Value should be a valid number";
    else if (!this.isInBetween(Number(discountAmount), 0, 99999999))
      errors.discountAmount = "Discount Value should be bewteen 0 to 99999999";

    if (!status) errors.status = "Status cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createSubscription = () => {
    const { subscriptions = [], subscription } = this.state;
    const ids =
      subscriptions.length > 0 ? subscriptions.map((it) => it.id) : null;
    subscription.id = ids ? Math.max(...ids) + 1 : 1;

    const ranks =
      subscriptions.length > 0
        ? subscriptions.filter((it) => it.rank).map((it) => it.rank)
        : null;
    subscription.rank = ranks ? Math.max(...ranks) + 1 : 1;
    subscription.is_new = true;

    this.setState({ subscriptions: [...subscriptions, subscription] }, () => {
      this.showSuccessNotification("Subscription Created Successfully!");
      this.onSubscriptionUpdatedLocally();
    });
  };

  updateSubscription = (id) => {
    const { subscriptions = [], subscription } = this.state;
    console.log("subscription",subscription);
    const newSubscriptions = subscriptions.map((it) =>
      it.id === id ? subscription : it
    );
    this.setState({ subscriptions: newSubscriptions }, () => {
      this.showSuccessNotification("Subscription Updated Successfully!");
      this.onSubscriptionUpdatedLocally();
    });
  };

  toggleStatus = (id) => {
    const { subscriptions = [] } = this.state;
    const newSubscriptions = subscriptions.map((it) =>
      it.id === id
        ? { ...it, status: it.status === "Active" ? "Inactive" : "Active" }
        : it
    );
    this.setState({ subscriptions: newSubscriptions }, () => {
      this.showSuccessNotification("Subscription Updated Successfully!");
      this.onSubscriptionUpdatedLocally();
    });
  };

  deleteSubscription = (id) => {
    const { subscriptions = [] } = this.state;
    const subscriptionToDelete = subscriptions.find((it) => it.id === id) || {};
    const newSubscriptions = subscriptions
      .filter((it) => it.id !== id)
      .map((it) =>
        it.rank > subscriptionToDelete.rank ? { ...it, rank: it.rank - 1 } : it
      );
    this.setState({ subscriptions: newSubscriptions }, () => {
      this.showSuccessNotification("Subscription Deleted Successfully!");
      this.onSubscriptionUpdatedLocally();
    });
  };

  updateRank = (fromIndex, toIndex) => {
    if (
      fromIndex === null ||
      fromIndex === undefined ||
      toIndex === null ||
      toIndex === undefined
    )
      return;

    const { subscriptions = [], sortColumn, sortDirection } = this.state;
    const sortedSubscriptions = _.sortBy(
      subscriptions,
      sortColumn,
      sortDirection
    );
    const fromSubscription = { ...sortedSubscriptions[fromIndex] };
    const toSubscription = { ...sortedSubscriptions[toIndex] };

    if (fromSubscription && toSubscription) {
      const tempRank = fromSubscription.rank;
      fromSubscription.rank = toSubscription.rank;
      toSubscription.rank = tempRank;

      const newSubscriptions = subscriptions.map((it) => {
        if (it.id === fromSubscription.id) return fromSubscription;
        else if (it.id === toSubscription.id) return toSubscription;
        else return it;
      });
      this.setState({ subscriptions: newSubscriptions }, () => {
        this.showSuccessNotification("Rank Updated Successfully!");
        this.onSubscriptionUpdatedLocally();
      });
    } else {
      this.showErrorNotification("Rank Updation Failed!");
    }
  };

  onSubscriptionUpdatedLocally = () => {
    this.clearForm();
    console.log("subscription update",)
    this.onSubscriptionUpdated(
      this.state.subscriptions.map((subscription) => {
        const q = { ...subscription };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  handleAddNewSubscription = () => {
    const subscription = { status: "Inactive",subscriptionFreqType:"MONTHS" };
    this.setState({ subscription }, () => this.showAddEditModal());
  };

  showAddEditModal = () => {
    this.setState({ isAddEditModalVisible: true });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false, pageIdForLogs:null });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  renderActionBar = (subscription) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle variant="" disabled={!this.props.isEditable}>
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item onClick={() => this.handleEdit(subscription.id)}>
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() => this.handleStatusChange(subscription.id)}
        >
          {subscription.status === "Active" ? (
            <ArchiveOutlinedIcon className="icon-small" />
          ) : (
            <CheckCircleOutlinedIcon className="icon-small" />
          )}
          {subscription.status === "Active" ? "Inactive" : "Active"}
        </ActionBar.Item>
        <ActionBar.Item
          // onClick={() => this.handleDelete(question.id)}
          onClick={() =>
            this.setState({ subscriptionToDelete: subscription.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Remove</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) =>
    this.state.subscription.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      subscriptions = [],
      subscription = {},
      errors,
      editables,
      sortColumn,
    } = this.state;
    const selectedSubscriptionFrequencies = subscriptions.map(
      (subscription) => subscription.noOfMonths
    );
    const selectedSubscriptionDays=subscriptions.map((subscription)=>subscription.noOfDays);
    const isNewSubscription =
      subscription.id === null || subscription.id === undefined;

    const form = (
      <>
        <div className="row">
        <div className="form-group col-6">
        <label htmlFor="frequencyType">Frequency Type*</label>
        <div className="input-group">
        <Dropdown
                value={subscription.subscriptionFreqType}
                onChange={(event, value) => {
                  this.setState({
                    subscription: {
                      ...subscription,
                      subscriptionFreqType : value.value,
                    },
                  });
                }}
                id="frequencyType"
                name="frequencyType"
                
                search
                selection
                options={FREQUENCY_TYPE_OPTIONS}
                disabled={!isNewSubscription && !editables.subscriptionFreqType}
                className={
                  "form-control" + (errors.subscriptionFreqType ? " has-error" : "")
                }
              />
         
        </div>
        </div>
        </div>


        <div className="row">
          <div className="form-group col">
            <label htmlFor="noOfMonths">Subscription Frequency*</label>
            <div className="input-group">
              <Dropdown
                value={subscription.subscriptionFreqType=="MONTHS"?subscription.noOfMonths:subscription.noOfDays}
                onChange={(event, value) => {
                  const newState = { ...this.state };
                  const { subscription } = newState;
                 
                  if (subscription.subscriptionFreqType === "MONTHS") {
                    newState.subscription = { ...subscription, noOfMonths: value.value,noOfDays:null };
                  } else {
                    newState.subscription = { ...subscription, noOfDays: value.value,noOfMonths:null };
                  }
                
                
                  
                  this.setState(newState);
                }}
                id="noOfMonths"
                name="noOfMonths"
                placeholder="Subscription Frequency"
                search
                selection
                options={subscription.subscriptionFreqType=="MONTHS"?SUBSCRIPTION_FREQUENCY_OPTIONS.filter((it) => {
                  if (subscription.noOfMonths === it.value) return true;
                  return !selectedSubscriptionFrequencies.includes(it.value)
                }):SUBSCRIPTION_DAYS_OPTIONS.filter((it) => {
                  if (subscription.noOfDays === it.value) return true;
                  return !selectedSubscriptionDays.includes(it.value)
                })}
                disabled={!isNewSubscription && (subscription.subscriptionFreqType=="MONTHS"?!editables.noOfMonths:!editables.noOfDays)}
                className={
                  "form-control" + (errors.noOfMonths ? " has-error" : "")
                }
              />
              {this.renderPencilIcon(subscription.subscriptionFreqType=="MONTHS"?"noOfMonths":"noOfDays")}
            </div>
            <span className="help-block">{subscription.subscriptionFreqType=="MONTHS"?errors.noOfMonths:errors.noOfDays}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={subscription?.status}
                onChange={(event, value) => {
                  this.setState({
                    subscription: { ...subscription, status: value.value },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewSubscription && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discountType">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={subscription.discountType}
                onChange={(event, value) => {
                  this.setState({
                    subscription: {
                      ...subscription,
                      discountType: value.value,
                    },
                  });
                }}
                id="discountType"
                name="discountType"
                placeholder="Discount Type"
                search
                selection
                options={DISCOUNT_TYPE_OPTIONS}
                disabled={!isNewSubscription && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountAmount">Discount Amount*</label>
            <div className="input-group">
              <input
                value={subscription.discountAmount ?? ""}
                onChange={this.handleChange}
                id="discountAmount"
                name="discountAmount"
                type="number"
                placeholder="Discount Amount"
                className={
                  "form-control py-2" +
                  (errors.discountAmount ? " has-error" : "")
                }
                disabled={!isNewSubscription && !editables.discountAmount}
              />
              {this.renderPencilIcon("discountAmount")}
            </div>
            <span className="help-block">{errors.discountAmount}</span>
          </div>

        </div>
        <div className="row" style={{direction: "rtl"}} >
          <div className="edit-subscription-buttons form-group"  style={{margin:"2px 6px"}}>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
              style={{ margin: "6px" }}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {subscription.id ? "Save" : "Add"}
            </button>
          </div>
        </div>
      </>
    );

    return (
      <>
        <div className="row">
          <div className="form-group col px-0">
            <Card>
              <ReactDragListView {...this.dragProps}>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={subscriptions || []}
                  onSort={(sortColumn, sortDirection) =>
                    this.setState({
                      sortColumn: sortColumn.selector,
                      sortDirection,
                    })
                  }
                  pagination
                  responsive
                  defaultSortField={sortColumn}
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
              </ReactDragListView>
              <button
                onClick={this.handleAddNewSubscription}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {subscription.id === null || subscription.id === undefined
                ? "Add Subscription"
                : "Edit Subscription"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {form} */}
            <Tabs>
              <Tab eventKey="subscription_basic_information" title="Details">
                {form}
                {/* <div className="edit-subscription-buttons" style={{position:"absolute", right:"10px"}} >
                  <button
                      className="btn btn-secondary rounded-pill px-4 py-2"
                      onClick={this.hideAddEditModal}
                      style={{margin:"6px"}}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={this.handleSubmit}
                      className="btn btn-lg btn-success rounded-pill px-4 py-2"
                    >
                      {subscription.id ? "Save" : "Add"}
                    </button>
                </div> */}
              </Tab>
              {this.state.pageIdForLogs!=null? 
                <Tab eventKey="logs-pricing-subscription" title="Logs" >
                  <Logs pageId={this.state.pageIdForLogs} pageType="SUBSCRIPTION_ITEM" />
                </Tab>
              : ""}
            </Tabs>
          </Modal.Body>
          {/* <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {subscription.id ? "Save" : "Add"}
            </button>
          </Modal.Footer> */}
        </Modal>
        <Modal
          show={this.state.isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Remove Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to remove the subcription ?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteSubscription(this.state.subscriptionToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

SubscriptionForm.propTypes = {
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubscriptionUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired
};

SubscriptionForm.defaultProps = {
  subscriptions: [],
  onSubscriptionUpdated: (f) => f,
  isEditable: true
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionForm);
