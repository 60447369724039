import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import _ from "lodash";
import RichTextEditor from "react-rte";
import { UploadMediaFile } from "services/UploadMedia";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class TrustSection extends Component {
  constructor(props) {
    super(props);

    const { trusts = [], onTrustsUpdated = (f) => f } = this.props;

    this.onTrustsUpdated = onTrustsUpdated;

    this.state = {
      trusts,
      trust: {},
      errors: {},
      editables: {},
      sortColumn: "rank",
      sortDirection: "asc",

      trustMainTextEn: RichTextEditor.createEmptyValue(),
      trustMainTextAr: RichTextEditor.createEmptyValue(),
      trustSubtextEn: RichTextEditor.createEmptyValue(),
      trustSubtextAr: RichTextEditor.createEmptyValue(),
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "50px",
        maxWidth: "100px",
      },
      {
        name: "Trust Main (English)",
        selector: "trustMainTextEn",
        sortable: true,
        wrap: true,
        format: (question) => (
          <div className="text-overflow-ellipsis">
            {question.trustMainTextEn ?? ""}
          </div>
        ),
      },
      {
        name: "Trust SubText (English)",
        selector: "trustSubtextEn",
        sortable: true,
        wrap: true,
        // right: true,
        format: (question) => (
          <div
            className="text-overflow-ellipsis"
            // style={{ textAlign: "right" }}
          >
            {question.trustSubtextEn ?? ""}
          </div>
        ),
      },
      {
        name: "Rank",
        selector: "rank",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",

        center: true,

        cell: (question) => this.renderActionBar(question),
      },
    ];
  }

  clearForm = () => {
    this.setState({
      errors: {},
      editables: {},
      trust: {},
      isNewQuestion: false,
    });
  };

  handleChange = ({ currentTarget: input }) => {
    const trust = { ...this.state.trust };
    trust[input.name] = input.value;
    this.setState({ trust });
  };

  handleEdit = (id) => {
    const { trusts = [] } = this.state;

    const trust = trusts.find((it) => it.id === id);

    if (!trust) return;
    const trustMainTextEn = RichTextEditor.createValueFromString(
      trust.trustMainTextEn ?? "",
      "html"
    );
    const trustMainTextAr = RichTextEditor.createValueFromString(
      trust.trustMainTextAr ?? "",
      "html"
    );

    const trustSubtextEn = RichTextEditor.createValueFromString(
      trust.trustSubtextEn ?? "",
      "html"
    );
    const trustSubtextAr = RichTextEditor.createValueFromString(
      trust.trustSubtextAr ?? "",
      "html"
    );

    const newQuestion = {
      ...trust,
      trustMainTextEn: trust.trustMainTextEn,
      trustMainTextAr: trust.trustMainTextAr,
      trustSubtextEn: trust.trustSubtextEn,
      trustSubtextAr: trust.trustSubtextAr,
    };
    this.setState(
      {
        trust: newQuestion,
        trustMainTextEn: trustMainTextEn,
        trustMainTextAr: trustMainTextAr,
        trustSubtextEn: trustSubtextEn,
        trustSubtextAr: trustSubtextAr,
      },
      () => this.showAddEditModal()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { trust = {} } = this.state;
    if (trust.id === null || trust.id === undefined) this.createUsp();
    else this.updateUsp(trust.id);
    this.hideAddEditModal();
  };

  validate = () => {
    const errors = {};
    const { headerImage } = this.state.trust || {};
    const { trustMainTextEn, trustMainTextAr } = this.state;

    // if (!headerImage) errors.headerImage = "Image cannot be blank";

    if (!trustMainTextEn) errors.trustMainTextEn = "Main Text cannot be blank";

    if (!trustMainTextAr) errors.trustMainTextAr = "Main Text cannot be blank";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  createUsp = () => {
    const { trusts = [], trust } = this.state;
    const ids = trusts.length > 0 ? trusts.map((it) => it.id) : null;
    trust.id = ids ? Math.max(...ids) + 1 : 1;

    trust.is_new = true;

    this.setState({ trusts: [...trusts, trust] }, () => {
      this.showSuccessNotification("Trust section Created Successfully!");
      this.onTrustsUpdatedLocally();
    });
  };

  updateUsp = (id) => {
    const { trusts = [], trust } = this.state;
    const newQuestions = trusts.map((it) => (it.id === id ? trust : it));
    this.setState({ trusts: newQuestions }, () => {
      this.showSuccessNotification("Trust section Updated Successfully!");
      this.onTrustsUpdatedLocally();
    });
  };

  onTrustsUpdatedLocally = () => {
    this.clearForm();
    this.onTrustsUpdated(
      this.state.trusts.map((question) => {
        const q = { ...question };
        if (q.is_new) {
          delete q.id;
          delete q.is_new;
        }
        return q;
      })
    );
  };

  createRankOptions = () => {
    const { isNewQuestion, trusts } = this.state;

    const ranks = trusts.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewQuestion ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  handleAddNewQuestion = () => {
    this.setState(
      {
        isNewQuestion: true,
        trustMainTextEn: RichTextEditor.createEmptyValue(),
        trustMainTextAr: RichTextEditor.createEmptyValue(),
        trustSubtextEn: RichTextEditor.createEmptyValue(),
        trustSubtextAr: RichTextEditor.createEmptyValue(),
      },
      () => this.showAddEditModal()
    );
  };

  handleRichText = (value, name) => {
    const { trust } = this.state;
    const newQuestion = { ...trust, [name]: value.toString("html") };
    this.setState({ trust: newQuestion, [name]: value });
  };

  showAddEditModal = () => {
    const { isNewQuestion } = this.state;
    const rankOptions = this.createRankOptions() || [];
    if (isNewQuestion) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const trust = {
        isActive: false,
        rank,
      };
      this.setState({ isAddEditModalVisible: true, trust, rankOptions });
    } else {
      this.setState({ isAddEditModalVisible: true, rankOptions });
    }
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({
      isAddEditModalVisible: false,
      trustMainTextEn: RichTextEditor.createEmptyValue(),
      trustMainTextAr: RichTextEditor.createEmptyValue(),
      trustSubtextEn: RichTextEditor.createEmptyValue(),
      trustSubtextAr: RichTextEditor.createEmptyValue(),
    });
  };

  renderActionBar = (question) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleEdit(question.id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.trust.id ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const {
      trusts = [],
      trust = {},
      errors,
      editables,
      sortColumn,
    } = this.state;

    const isNewQuestion = trust.id === null || trust.id === undefined;

    const form = (
      <>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Trust Main (English) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.trustMainTextEn}
                onChange={(value) =>
                  this.handleRichText(value, "trustMainTextEn")
                }
                disabled={!isNewQuestion && !editables.trustMainTextEn}
              />
              {this.renderPencilIcon("trustMainTextEn")}
            </div>
            <span className="help-block">{errors.trustMainTextEn}</span>
          </div>
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Trust Main (Arabic) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.trustMainTextAr}
                onChange={(value) =>
                  this.handleRichText(value, "trustMainTextAr")
                }
                disabled={!isNewQuestion && !editables.trustMainTextAr}
              />
              {this.renderPencilIcon("trustMainTextAr")}
            </div>
            <span className="help-block">{errors.trustMainTextAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Trust Sub Text (English) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.trustSubtextEn}
                onChange={(value) =>
                  this.handleRichText(value, "trustSubtextEn")
                }
                disabled={!isNewQuestion && !editables.trustSubtextEn}
              />
              {this.renderPencilIcon("trustSubtextEn")}
            </div>
            <span className="help-block">{errors.trustSubtextEn}</span>
          </div>
          <div className="form-group col" style={{ maxWidth: "50%" }}>
            <label>Trust Sub Text (Arabic) *</label>
            <div className="input-group">
              <RichTextEditor
                rootStyle={{
                  position: "relative",
                  flex: "1 1 auto",
                  width: "1%",
                  marginBottom: 0,
                }}
                editorStyle={{ height: "90px", overflow: "hidden" }}
                value={this.state.trustSubtextAr}
                onChange={(value) =>
                  this.handleRichText(value, "trustSubtextAr")
                }
                disabled={!isNewQuestion && !editables.trustSubtextAr}
              />
              {this.renderPencilIcon("trustSubtextAr")}
            </div>
            <span className="help-block">{errors.trustSubtextAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={trust.rank}
                onChange={(event, value) => {
                  this.setState({
                    trust: { ...trust, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewQuestion && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={trust.isActive}
                onChange={(event, value) => {
                  this.setState({
                    trust: {
                      ...trust,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewQuestion && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <p className="font-weight-bold" style={{ padding: "5px" }}>
                Trust Section
              </p>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={trusts || []}
                onSort={(sortColumn, sortDirection) =>
                  this.setState({
                    sortColumn: sortColumn.selector,
                    sortDirection,
                  })
                }
                pagination
                responsive
                defaultSortField={sortColumn}
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
              <button
                onClick={this.handleAddNewQuestion}
                disabled={!this.props.isEditable}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {trust.id === null || trust.id === undefined
                ? "Add Trust Section"
                : "Edit Trust Section"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {trust.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

TrustSection.propTypes = {
  trusts: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTrustsUpdated: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

TrustSection.defaultProps = {
  trusts: [],
  onTrustsUpdated: (f) => f,
  isEditable: true,
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TrustSection);
