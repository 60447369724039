import React, { useState, useEffect } from "react";
import { round } from "utils/commons";
import { Dropdown } from "semantic-ui-react";

const PAYMENT_MODE_OPTIONS = [
  {
    key: "CARD",
    value: "CARD",
    text: "Card",
  },
  {
    key: "APPLE_PAY",
    value: "APPLE_PAY",
    text: "Apple Pay",
  },
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
];
const PAYMENT_GATEWAY_OPTIONS = [
  {
    key: "MAMOPAY",
    value: "MAMOPAY",
    text: "Mamopay",
  },
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
];

function DraftOrderPaymentInfo(props) {
  const [paymentDetails, setPaymentDetails] = useState(props.paymentDetails);
  const [orderDetails,setOrderDetails]=useState(props.orderDetails)
  const [enableMamoPay,setEnableMamoPay]=useState(false)
  const mamoPayLink=props.mamoPaymentUrl || ""

  useEffect(()=>{
   if(orderDetails){
    const { transactionDetails } = orderDetails;
    const link=transactionDetails.mamopayPaymentUrl;
     if(link === null || link ===undefined){
      setEnableMamoPay(true)
     }
   }
  },[orderDetails])

  const onSave = props.onSave;
  const generateLink=props.generateLink;
  const onSaveMamo=props.onSaveMamo;

  const {
    transactionId,
    currency,
    taxPercentage,
    taxAmount,
    couponApplied,
    couponDetails,
    couponCode,
    discountValue,
    totalAmount,
    additionalDiscount,
    subscriptionDiscount,
    numberOfItems,
    finalAmount,
    paymentMethod,
    paymentGateway,
    paymentStatus,
    isWalletBalanceUsed,
    walletAmount,
    deliveryCharges,
    cashbackAmount,
    nurseVisitFee,
  } = paymentDetails ?? {};

  const handlePaymentGatewayChange = (paymentGateway) => {
    const newPaymentDetails = { ...paymentDetails };
    newPaymentDetails.paymentGateway = paymentGateway;
    setPaymentDetails(newPaymentDetails);
  };

  const handlePaymentModeChange = (paymentMethod) => {
    const newPaymentDetails = { ...paymentDetails };
    newPaymentDetails.paymentMethod = paymentMethod;
    setPaymentDetails(newPaymentDetails);
  };


  const handleSave = () => {
    onSave(paymentDetails);
  };

  const handleSaveMamoLink=()=>{
    onSaveMamo(paymentDetails)
  }

  const generateMamoPayLink=()=>{
    generateLink()
  }
  console.log("coupon details",couponDetails);
  
  const discountRow = (
    <tr>
      <td>Discount</td>
      <td>{couponCode ?? ""}</td>
      <td colSpan={3} className="text-right text-nowrap">
        &#8722;{`${currency} ${discountValue}`}
      </td>
    </tr>
  );

  const cashbackRow=(
    <tr>
      <td>Cashback</td>
      <td>{couponCode ?? ""}</td>
      <td colspan={3} className="text-right text-nowrap">
    {`${currency} ${cashbackAmount??0}`}
      </td>

    </tr>
  );

  const MamoPayForm=(
    enableMamoPay && (<div className="container">
      <div className="row">
                  <div className="form-group col" style={{ marginTop: "32px" }}>
                    <button
                      onClick={(e)=>generateMamoPayLink()}
                      style={{ backgroundColor: "#0CA789", color: "white" }}
                      className="btn btn-md new-user"

                    >
                      Generate MamoPay Link
                    </button>
                  </div>
                  <div className="form-group col">
                    <label htmlFor="mamoPayLink">MamoPay Link</label>
                    <textarea
                      value={mamoPayLink || ""}
                      type="text"
                      id="mamoPayLink"
                      name="mamoPayLink"
                      className="form-control py-2"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="row">
        <div className="form-group col-6"></div>
        <div className="form-group col-6">
        <button className="btn button btn" style={{ border: " 1px solid #0CA789", color: "#0CA789",paddingLeft:"15px" }} onClick={(e)=>handleSaveMamoLink()}>
        Save
    </button>
        </div>
      
      </div>
      </div>
     
      )
    
  )
  const paymentDetailsTable = (
    <table className="table table-responsive table-borderless">
      <tbody>
      <tr>
          <td>Subtotal</td>
          <td colSpan={2}>{`${numberOfItems} ${
            numberOfItems > 1 ? "items" : "item"
          }`}</td>
          <td className="text-right text-nowrap">{`${currency} ${totalAmount}`}</td>
        </tr>
      <tr>
          <td colSpan={2}>Subscription Discount (Already Considered in Pricing)</td>
          <td colspan={3} className="text-right text-nowrap">{`${currency} ${subscriptionDiscount==null||subscriptionDiscount==undefined?0:subscriptionDiscount}`}</td>
        </tr>
      
      {couponApplied  && discountValue>0 ?discountRow : cashbackRow}
     
        <tr>
          <td>Delivery Charges</td>
          <td colSpan={3} className="text-right text-nowrap">{`${currency} ${
            deliveryCharges ?? 0
          }`}</td>
        </tr>
        <tr>
          <td>Nurse Visit Fee</td>
          <td colSpan={3} className="text-right text-nowrap">{`${currency} ${
           nurseVisitFee ?? 0
          }`}</td>
        </tr>
        <tr>
          <td>Tax</td>
          <td colSpan={2}>{`VAT ${taxPercentage}% (included)`}</td>
          <td className="text-right text-nowrap">{`${currency} ${taxAmount}`}</td>
        </tr>
        <tr>
          <td>Additional Discount</td>
          <td colSpan={3} className="text-right text-nowrap">{`-${currency} ${additionalDiscount == null || additionalDiscount== undefined?0:additionalDiscount}`}</td>
        </tr>
        <tr>
          <td style={{ width: "50%" }}>Wallet Amount Used</td>
          <td colSpan={2}></td>
          <td className="text-right text-nowrap">
            &#8722;
            {`${currency} ${
              walletAmount === null || walletAmount === undefined
                ? 0
                : walletAmount
            }`}
          </td>
        </tr>
        {walletAmount>0 && walletAmount !== null && walletAmount !== undefined?paymentGateway=="COD"  ?
        <tr >
          <td colSpan={3}>Amount to be Collected </td>
          <td className="text-right text-nowrap">
            
            {`${currency} ${round(finalAmount-walletAmount)}`}
          </td>
        </tr>
        
        :paymentMethod=="COD"?<tr >
        <td colSpan={3}>Amount to be Collected </td>
        <td className="text-right text-nowrap">
          
          {`${currency} ${round(finalAmount-walletAmount)}`}
        </td>
      </tr>
        :<> 
         <tr >
          <td colSpan={3}>Amount to be paid </td>
          <td className="text-right text-nowrap">
           
            {`${currency} ${round(finalAmount-walletAmount)}`}
          </td>
        </tr>
        </>:<></>}
        <tr className="font-weight-bold">
          <td colSpan={3}>Total</td>
          <td className="text-right text-nowrap">
            {`${currency} ${round(finalAmount)}`}
          </td>
        </tr>
        <tr>
          <td>Cart ID</td>
          <td>{transactionId ?? ""}</td>
          <td className="text-right text-nowrap">Payment Status</td>
          <td className="text-right">{paymentStatus ?? ""}</td>
        </tr>
      </tbody>
    </table>
  );
  return (
    <div className="container px-0">
      <div className="row">
        <div className="col">
          <p className="h6">Payment Information</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {paymentDetails ? (
            paymentDetailsTable
          ) : (
            <span className="placeholder">
              No payment information for the order!
            </span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="paymentGateway">Payment Gateway*</label>
          <Dropdown
            value={paymentGateway}
            onChange={(event, value) => handlePaymentGatewayChange(value.value)}
            id="paymentGateway"
            name="paymentGateway"
            placeholder="Payment Gateway"
            search
            selection
            options={PAYMENT_GATEWAY_OPTIONS}
            className={"form-control"}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="paymentMode">Payment Mode*</label>
          <Dropdown
            value={paymentMethod}
            onChange={(event, value) => handlePaymentModeChange(value.value)}
            id="paymentMode"
            name="paymentMode"
            placeholder="Payment Mode"
            search
            selection
            options={PAYMENT_MODE_OPTIONS}
            className={"form-control"}
          />
        </div>
      </div>
                
     {paymentGateway==="COD" ? <div className="row">
        <div className="form-group col-6"></div>
        <div className="form-group col-6">
        <button className="btn button btn" style={{ border: " 1px solid #0CA789", color: "#0CA789",paddingLeft:"15px" }} onClick={(e)=>handleSave()}>
        Save & Move to Cart
    </button>
        </div>
      
      </div>:MamoPayForm}
    </div>
  );
}

export default DraftOrderPaymentInfo;
