import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { Card, Modal, Dropdown as ActionBar } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import PhoneInput from "react-phone-input-2";
import AlertBox from "components/AlertBox/AlertBox";
import PhoenixAPI from "utils/PhoenixAPI";
import PdfPreview from "components/Common/PdfPreview";

const BASE_URL_FAMILY_MEMBERS = "/multiple-profile/admin";
const BASE_URL_FAMILY_MEMBERS_FOR_GET = "/multiple-profile";
const GENDER_OPTIONS = [
  {
    key: "M",
    text: "Male",
    value: "M",
  },
  {
    key: "F",
    text: "Female",
    value: "F",
  },
];

class FamilyMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      familyMembers: {},
      familyMember: {},
      errors: {},
      editables: {},
      familyMemberToDelete: null,
      isNewFamilyMember: false,
      isAddEditModalVisible: false,
      isConfirmDeleteModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        wrap: true,
        maxWidth: "100px",
      },
      {
        name: "Member Name",
        selector: "fullName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Gender",
        selector: "gender",
        center: true,
        sortable: true,
        maxWidth: "80px",
      },
      {
        name: "Relation",
        selector: "relation",
        sortable: true,
        wrap: true,
      },
      {
        name: "Actions",
        maxWidth: "100px",
        center: true,
        allowOverflow: true,
        cell: (familyMember) => this.renderActionBar(familyMember),
      },
    ];
  }

  componentDidMount() {
    this.fetchFamilyMembers();
  }

  fetchFamilyMembers = async () => {
    const { clientId } = this.props;
    if (!clientId) {
      console.log(
        "fetchFamilyMembers:: Cannot fetch family members! Invalid client id",
        { clientId }
      );
      return;
    }
    try {
      this.showLoading();
      const { data: familyMembers = [] } = await PhoenixAPI.get(
        BASE_URL_FAMILY_MEMBERS_FOR_GET,
        {
          params: { id: clientId },
        }
      );
      if (familyMembers && familyMembers.length) {
        familyMembers.forEach((member) => {
          const { firstName, lastName } = member;
          const fullName = `${firstName ?? ""} ${lastName ?? ""}`.trim();
          member.fullName = fullName;
        });
      }
      this.setState({ familyMembers });
    } catch (error) {
      console.log("Error in fetching family members:", error);
      this.showErrorNotification(
        "Error in fetching family members!",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const familyMember = { ...this.state.familyMember };
    familyMember[input.name] = input.value;
    this.setState({ familyMember });
  };

  handlePhoneNumberChange = (fieldName, value, data) => {
    const { dialCode } = data;
    if (dialCode && value) {
      this.setState({
        familyMember: {
          ...this.state.familyMember,
          [fieldName]: `+${dialCode}${value.slice(dialCode.length)}`,
        },
      });
    }
  };

  handleSelectImage = ({ currentTarget: input }) => {
    const fieldName = input.name;
    if (input.files && input.files.length) {
      const imageFile = input.files[0];
      const familyMember = {
        ...this.state.familyMember,
        [fieldName]: imageFile,
      };
      this.setState({ familyMember });
      this.readImage(`${fieldName}Src`, imageFile);
    }
  };

  readImage = (fieldName, imageFile) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const familyMember = {
        ...this.state.familyMember,
        [fieldName]: [reader.result],
      };
      this.setState({ familyMember });
    }.bind(this);
    if (imageFile?.type?.startsWith("image/")) {
      reader.readAsDataURL(imageFile);
    } else if (imageFile?.type === "application/pdf") {
      reader.readAsArrayBuffer(imageFile);
    }
  };

  handleSaveFamilyMember = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    const { familyMember } = this.state;
    const payload = { ...familyMember };
    delete payload.frontIdSrc;
    delete payload.backIdSrc;
    if (!payload.userId) payload.userId = this.props.clientId;
    if (!payload.isActive) payload.isActive = true;
    if (typeof payload.frontId !== "string") delete payload.frontId;
    if (typeof payload.backId !== "string") delete payload.backId;
    
    try {
      this.showLoading();
      const formData = new FormData();
      formData.append("member", JSON.stringify(payload));
      if (typeof familyMember.frontId !== "string") {
        formData.append("front_id", familyMember.frontId);
      }
      if (typeof familyMember.backId !== "string") {
        formData.append("back_id", familyMember.backId);
      }
      formData.append("updated_by","admin");
      /* 
       Note: Backend has post method for both create and update. 
       It's differentating create or update request by checking "id" property.
      */
      const config = {
        method: "POST",
        url: BASE_URL_FAMILY_MEMBERS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.showSuccessNotification(
        familyMember.id ? "Updated Successfully" : "Created Successfully"
      );
      this.hideAddEditModal();
      this.fetchFamilyMembers();
    } catch (error) {
      this.showErrorNotification(
        "Some error occurred: " + error.message ?? error
      );
    } finally {
      this.hideLoading();
    }
  };

  deleteFamilyMember = async (familyMemberId) => {
    const familyMembers = this.state.familyMembers ?? [];
    const familyMemberToDelete = familyMembers.find(
      (it) => it.id === familyMemberId
    );
    if (!familyMemberToDelete) {
      console.log(
        "deleteFamilyMember:: Family member not found with id=",
        familyMemberId
      );
      return;
    }

    try {
      this.showLoading();
      const payload = { ...familyMemberToDelete };
      payload.isActive = false;
      const formData = new FormData();
      formData.append("member", JSON.stringify(payload));

      /* 
         Note: Backend has no method for delete. 
         We need to call POST api with isActive=false to delete a family member.
        */
      const config = {
        method: "POST",
        url: BASE_URL_FAMILY_MEMBERS,
        data: formData,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      };
      await PhoenixAPI.request(config);
      this.showSuccessNotification("Deleted Successfully");
      this.fetchFamilyMembers();
    } catch (error) {
      this.showErrorNotification(
        "Some error occurred: " + error.message ?? error
      );
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const errors = {};
    const { firstName, lastName, gender, dob, relation } =
      this.state.familyMember ?? {};

    if (!firstName || firstName.trim() === "")
      errors.firstName = "First Name cannot be blank!";

    if (!lastName || lastName.trim() === "")
      errors.lastName = "Last Name cannot be blank!";

    if (!gender) errors.gender = "Gender cannot be blank!";

    if (!dob || dob.trim() === "")
      errors.dob = "Date of Birth cannot be blank!";

    if (!relation || relation.trim() === "")
      errors.relation = "Relation cannot be blank!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  handleAddNewFamilyMember = () => {
    const familyMember = { isActive: true };
    this.setState({
      familyMember,
      isNewFamilyMember: true,
      isAddEditModalVisible: true,
    });
  };

  handleHeightWeightInput=(e,data)=>{
    if (data === "height" || data === "weight") {
      let { value, min, max } = e.target;
       const familyMember = { ...this.state.familyMember };
      familyMember[data] = value;
      // console.log("Target:", e.target);
      // value = value && Math.max(Number(min), Math.min(Number(max), Number(value)));
       this.setState({familyMember,
        heightErrorMessage: "",
        weightErrorMessage: "",
      });
    }
  }

  handleEditFamilyMember = (id) => {
    this.clearForm();
    const familyMembers = this.state.familyMembers ?? [];
    const familyMember = familyMembers.find((it) => it.id === id);
    if (!familyMember) return;

    familyMember.frontIdSrc = familyMember.frontId ?? null;
    familyMember.backIdSrc = familyMember.backId ?? null;
    this.setState({
      familyMember,
      isNewFamilyMember: false,
      isAddEditModalVisible: true,
    });
  };

  showConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: true });
  };

  hideConfirmDeleteModal = () => {
    this.setState({ isConfirmDeleteModalVisible: false });
  };

  hideAddEditModal = () => {
    this.clearForm();
    this.setState({ isAddEditModalVisible: false });
  };

  clearForm = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (it) => (it.value = "")
    );
    Array.from(document.querySelectorAll("textarea")).forEach(
      (it) => (it.value = "")
    );
    this.setState({
      errors: {},
      editables: {},
      familyMember: {},
      familyMemberToDelete: null,
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  renderActionBar = (familyMember) => (
    <ActionBar alignRight className="action-menu valeo-dropdown">
      <ActionBar.Toggle disabled={!this.props.isEditable} variant="">
        <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
      </ActionBar.Toggle>
      <ActionBar.Menu>
        <ActionBar.Item
          onClick={() => this.handleEditFamilyMember(familyMember.id)}
        >
          <EditIcon className="icon-small" />
          Edit
        </ActionBar.Item>
        <ActionBar.Item
          onClick={() =>
            this.setState({ familyMemberToDelete: familyMember.id }, () =>
              this.showConfirmDeleteModal()
            )
          }
        >
          <DeleteIcon className="icon-small" style={{ fill: "red" }} />
          <span style={{ color: "red" }}>Delete</span>
        </ActionBar.Item>
      </ActionBar.Menu>
    </ActionBar>
  );

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };

    const isEditable = this.props.isEditable ?? true;
    const { isNewFamilyMember } = this.state;

    return isEditable && !isNewFamilyMember ? (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : null;
  };

  renderFrontIdPreview = () => {
    const { familyMember } = this.state;
    const { frontId,frontIdSrc } = familyMember;
    if (!frontId) return null;

    const file = frontId;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof frontId === "string" && frontId.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? frontId : null}
        pdfFileUrl={isPdfFile ? null : frontId}
      />
    ) : (
      <div>
        <img
          id="selected_front_image"
          name="selected_front_image"
          src={frontIdSrc}
          alt="Selected"
          className="img-thumbnail mx-auto"
          style={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
          }}
        />
      </div>
    );

    return (
      <>
        <label htmlFor="selected_front_image">ID - Front Preview</label>
        {preview}
      </>
    );
  };

  renderBackIdPreview = () => {
    const { familyMember } = this.state;
    const { backId , backIdSrc} = familyMember;
    if (!backId) return null;

    const file = backId;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof backId === "string" && backId.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? backId : null}
        pdfFileUrl={isPdfFile ? null : backId}
      />
    ) : (
      <div>
        <div>
          <img
            id="selected_back_image"
            name="selected_back_image"
            src={backIdSrc}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
    );

    return (
      <>
        <label htmlFor="selected_back_image">ID - Back Preview</label>
        {preview}
      </>
    );
  };

  render() {
    const {
      familyMembers,
      familyMember,
      editables,
      errors,
      isNewFamilyMember,
      isConfirmDeleteModalVisible,
    } = this.state;
    const isEditable = this.props.isEditable ?? true;

    const identificationInformationForm = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Identification Information</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="fullNameId">Full Name as per ID</label>
            <div className="input-group">
              <input
                value={familyMember.fullNameId ?? ""}
                onChange={this.handleChange}
                id="fullNameId"
                name="fullNameId"
                type="text"
                placeholder="Full Name as per ID"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewFamilyMember && !editables.fullNameId}
              />
              {this.renderPencilIcon("fullNameId")}
            </div>
            <span className="help-block">{errors.fullNameId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="frontId">ID - Front</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="frontId"
                name="frontId"
                type="file"
                accept="image/*, application/pdf"
                disabled={!isNewFamilyMember && !editables.frontId}
                className={
                  "form-control" + (errors.frontId ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("frontId")}
            </div>
            <span className="help-block">{errors.frontId}</span>
          </div>
          <div className="form-group col-6">
            {/* {familyMember?.frontIdSrc && (
              <>
                <label htmlFor="frontIdSrc">ID - Front Preview</label>
                <div>
                  <img
                    id="frontIdSrc"
                    name="frontIdSrc"
                    src={familyMember.frontIdSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )} */}
            {this.renderFrontIdPreview()}
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="backId">ID - Back</label>
            <div className="input-group">
              <input
                onChange={this.handleSelectImage}
                id="backId"
                name="backId"
                type="file"
                accept="image/*"
                disabled={!isNewFamilyMember && !editables.backId}
                className={"form-control" + (errors.backId ? " has-error" : "")}
              />
              {this.renderPencilIcon("backId")}
            </div>
            <span className="help-block">{errors.backId}</span>
          </div>
          {/* <div className="form-group col">
            {familyMember?.backIdSrc && (
              <>
                <label htmlFor="backIdSrc">ID - Back Preview</label>
                <div>
                  <img
                    id="backIdSrc"
                    name="backIdSrc"
                    src={familyMember.backIdSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div> */}
          <div className="form-group col-6">{this.renderBackIdPreview()}</div>
        </div>
      </>
    );

    const familyMemberForm = (
      <>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="firstName">First Name*</label>
            <div className="input-group">
              <input
                value={familyMember.firstName ?? ""}
                onChange={this.handleChange}
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewFamilyMember && !editables.firstName}
              />
              {this.renderPencilIcon("firstName")}
            </div>
            <span className="help-block">{errors.firstName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="lastName">Last Name*</label>
            <div className="input-group">
              <input
                value={familyMember.lastName ?? ""}
                onChange={this.handleChange}
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewFamilyMember && !editables.lastName}
              />
              {this.renderPencilIcon("lastName")}
            </div>
            <span className="help-block">{errors.lastName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="gender">Gender*</label>
            <div className="input-group">
              <Dropdown
                value={familyMember.gender ?? null}
                onChange={(event, value) => {
                  this.setState({
                    familyMember: { ...familyMember, gender: value.value },
                  });
                }}
                id="gender"
                name="gender"
                placeholder="Gender"
                search
                selection
                options={GENDER_OPTIONS}
                disabled={!isNewFamilyMember && !editables.gender}
                className={"form-control" + (errors.gender ? " has-error" : "")}
              />
              {this.renderPencilIcon("gender")}
            </div>
            <span className="help-block">{errors.gender}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="dob">Date of Birth*</label>
            <div className="input-group">
              <input
                value={familyMember.dob ?? ""}
                onChange={this.handleChange}
                id="dob"
                name="dob"
                type="date"
                className={
                  "form-control py-2" + (errors.dob ? " has-error" : "")
                }
                disabled={!isNewFamilyMember && !editables.dob}
              />
              {this.renderPencilIcon("dob")}
            </div>
            <span className="help-block">{errors.dob}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
              <label>Height</label>
              <div className="user-input-container number-field">
                <input
                  type="text"
                  id="height"
                  name="height"
                  className={"form-control" + (errors.height ? " has-error" : "")}
                  value={familyMember.height}
                  onChange={(e) => this.handleHeightWeightInput(e, "height")}
                  disabled={!isNewFamilyMember && !editables.height}
                  // min="10"
                  max="300"
                  style={!isNewFamilyMember ? { width: "69%" } : { width: "100%" }}
                  autoComplete="off"
                ></input>
                <span style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none", // Prevent interaction with the span
                      color: "#888" // Adjust color for better visual appearance
                      }}>cms</span>
                  {this.renderPencilIcon("height")}
                
                
              </div>
              <span className="help-block">{errors.height}</span>
          </div>

          <div className="form-group col">
              <label>Weight</label>
              <div className="user-input-container number-field">
                <input
                  type="text"
                  id="weight"
                  name="weight"
                  className={"form-control" + (errors.height ? " has-error" : "")}
                  value={familyMember.weight}
                  onChange={(e) => this.handleHeightWeightInput(e, "weight")}
                  disabled={!isNewFamilyMember && !editables.weight}
                  // min="10"
                  max="300"
                  style={!isNewFamilyMember ? { width: "69%" } : { width: "100%" }}
                  autoComplete="off"
                ></input>
                <span style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      pointerEvents: "none", // Prevent interaction with the span
                      color: "#888" // Adjust color for better visual appearance
                      }}>kgs</span>
                  {this.renderPencilIcon("weight")}
              </div>
              </div>
              </div>
        <div className="form-group-container">
          <div className="form-group pl-0 pr-auto">
            <label htmlFor="relation">Relation*</label>
            <div className="input-group">
              <input
                value={familyMember.relation}
                onChange={this.handleChange}
                id="relation"
                name="relation"
                type="text"
                placeholder="Relation"
                className={
                  "form-control py-2" + (errors.name ? " has-error" : "")
                }
                disabled={!isNewFamilyMember && !editables.relation}
              />
              {this.renderPencilIcon("relation")}
            </div>
            <span className="help-block">{errors.relation}</span>
          </div>
          <div className="form-group pl-auto pr-0">
            <label htmlFor="mob">Phone Number</label>
            <div className="user-input-container">
              <div
                className={`${
                  editables.mob || isNewFamilyMember
                    ? `non-editable-phone`
                    : `editable-phone`
                } `}
              >
                <PhoneInput
                  value={familyMember.mob ?? ""}
                  onChange={(value, data) =>
                    this.handlePhoneNumberChange("mob", value, data)
                  }
                  disabled={!isNewFamilyMember && !editables.mob}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                  className={
                    "form-cotrol user-data-input contact-no" +
                      isNewFamilyMember || editables.mob
                      ? " non-editable"
                      : " editable" + errors.mob
                      ? " has-error"
                      : ""
                  }
                  autocompleteSearch
                  autoFormat
                />
                {this.renderPencilIcon("mob")}
                <span className="help-block">{errors.mob}</span>
              </div>
            </div>
          </div>
        </div>
        {identificationInformationForm}
      </>
    );

    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={familyMembers ?? []}
                defaultSortField="id"
                defaultSortAsc={false}
                sortIcon={<ArrowDownward></ArrowDownward>}
                pagination
                responsive
                noDataComponent={
                  this.state.loadError ? (
                    <AlertBox
                      message={this.state.loadError}
                      error={true}
                    ></AlertBox>
                  ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
                }
              />
              <button
                onClick={this.handleAddNewFamilyMember}
                className="btn btn-success mt-1"
                disabled={!isEditable}
              >
                Add New
              </button>
            </Card>
          </div>
        </div>
        <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {familyMember.id ? "Update Family Member" : "Add Family Member"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{familyMemberForm}</Modal.Body>
          <Modal.Footer>
            <button
              onClick={this.handleSaveFamilyMember}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isConfirmDeleteModalVisible}
          onHide={this.hideConfirmDeleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Family Member</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete the family member?
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.hideConfirmDeleteModal}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                this.deleteFamilyMember(this.state.familyMemberToDelete);
                this.hideConfirmDeleteModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

FamilyMembers.propTypes = {
  clientId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

FamilyMembers.defaultProps = {
  isEditable: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(FamilyMembers);
