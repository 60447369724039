import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Search from "components/Search/Search";
import { fetchCountries } from "services/RegionService";
import PhoenixAPI from "utils/PhoenixAPI";
import {
  fetchLongevityParameterDetails,
  fetchBiomarkerName,
  updateParameter,
  saveParameter,
  fetchAllParameters,
} from "services/LongevityService";
import BiomarkerDetailsForm from "./components/BiomarkerDetailsForm";
import Status from "components/Common/Status";
import { Dropdown } from "semantic-ui-react";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class ParameterDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parameterDetails: [],
      parameterDetail: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isEditable: true,
      isParameterViewModalVisible: false,
      biomarkers: [],
      biomarkerOptions: [],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Parameters",
        selector: "parameterName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Biomarkers",
        selector: "noOfBiomarkers",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        center: true,
        sortable: true,
        cell: (row) => <Status isActive={row.isActive} />,
      },
      {
        name: "Edit",
        center: true,
        cell: (category) => this.renderViewButton(category.id),
      },
    ];
  }

  componentDidMount() {
    // this.fetchCountries();
    this.fetchBiomarkers();
    // this.fetchMiniPackageBiomarkers();
  }

  async componentWillReceiveProps(nextProps) {
    const categoryId = nextProps.categoryId;
    const selectedCountry = nextProps.selectedCountry;
    this.fetchParameterDetails(categoryId);
    this.setState({ selectedCountry });
  }

  // fetchCountries = async () => {
  //   try {
  //     const countries = (await fetchCountries()) || [];
  //     const defaultCountry = countries.find((country) => country.is_default);
  //     this.setState({ countries, selectedCountry: defaultCountry });
  //   } catch (error) {}
  // };
  fetchBiomarkers = async () => {
    this.showLoading();
    try {
      const biomarkers = await fetchBiomarkerName();
      const biomarkerOptions = biomarkers.map((obj) => {
        // Add key elements to each object
        return {
          ...obj,
          key: obj.name,
          value: obj.name,
          label: obj.name,
          unit: obj.unit,
        };
      });
      this.setState({ biomarkers, biomarkerOptions });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching ParameterDetails: ",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  // fetchMiniPackageBiomarkers=async()=>{
  //   this.showLoading();
  //   try{
  //     const biomarkers= await fetchBiomarkerName();
  //     const biomarkerOptions = biomarkers.map(obj => {
  //       // Add key elements to each object
  //       return { ...obj, key: obj.name, value: obj.name, label:obj.name };
  //     });
  //     this.setState({biomarkers:biomarkerOptions})
  //   }
  //   catch(error){
  //     this.showErrorNotification(
  //       "Error on fetching ParameterDetails: ",
  //       error.message
  //     );
  //   }
  //   finally {
  //     this.hideLoading();
  //   }

  // }

  fetchParameterDetails = async (categoryId) => {
    this.showLoading();
    try {
      const parameterDetails = await fetchLongevityParameterDetails(categoryId);
      this.setState({ parameterDetails });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching ParameterDetails: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  fetchAllParameters = async () => {
    const { categoryId } = this.props.categoryId;
    this.showLoading();
    try {
      const parameterDetails = await fetchAllParameters();
      const updatedParameterDetails = parameterDetails.filter(
        (it) => it.longevityCategoryId === categoryId
      );
      this.setState({ parameterDetails: updatedParameterDetails });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching ParameterDetails: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSaveParameter = async () => {
    const { selectedCountry, parameterDetail } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    // const errors = this.validate();
    // this.setState({ errors: errors || {} });
    // if (errors) {
    //   this.showErrorNotification("There are some invalid form fields!");
    //   return;
    // }
    try {
      this.showLoading();
      const isValidId =
        parameterDetail.id !== null && parameterDetail.id !== undefined;
      const response = isValidId
        ? await updateParameter(parameterDetail)
        : await saveParameter(parameterDetail);
      this.hideParameterViewModal();
      this.showSuccessNotification(
        isValidId
          ? "Parameter Updated Successfully."
          : "Parameter Created Successfully."
      );

      // this.fetchAllParameters();
      this.props.onSave();
    } catch (error) {
      console.log(
        "Error occured in creating  or updating parameter",
        error.messsage
      );
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  handleViewCategory = (parameterId) => {
    const parameterDetails = this.state.parameterDetails ?? [];
    const parameterDetail = parameterDetails.find(
      (it) => it.id === parameterId
    );
    if (!parameterDetail) {
      console.log(`parameterDetail with id ${parameterDetail} is not found!`);
      return;
    }
    parameterDetail.image_src = parameterDetail.icon;
    this.setState(
      {
        parameterDetail,
        isNewParameter: false,
      },
      () => this.showParameterDetailViewModal()
    );
  };

  handleAddParameter = () => {
    this.setState({ isNewParameter: true }, () =>
      this.showParameterDetailViewModal()
    );
  };

  showParameterDetailViewModal = () => {
    const { isNewParameter, selectedCountry } = this.state;
    const { categoryId } = this.props;

    if (isNewParameter) {
      const parameterDetail = {
        country: selectedCountry.id,
        longevityCategoryId: categoryId,
        isActive: false,
      };
      this.setState({ parameterDetail, isParameterViewModalVisible: true });
    } else {
      this.setState({ isParameterViewModalVisible: true });
    }
  };

  hideParameterViewModal = () => {
    this.setState({ parameterDetail: {}, isParameterViewModalVisible: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const parameterDetail = { ...this.state.parameterDetail };
    parameterDetail[input.name] = input.value;
    this.setState({ parameterDetail });
  };

  handleBiomarkersUpdated = (parameterBiomarkers = []) => {
    const parameterDetail = {
      ...this.state.parameterDetail,
      parameterBiomarkers: parameterBiomarkers,
    };
    this.setState({ parameterDetail });
  };

  handleSelectImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const image = e.target.files[0];
      const parameterDetail = { ...this.state.parameterDetail, image };
      this.setState({ parameterDetail });

      this.readImage(image);
    }
  };
  getFilteredParameters = () => {
    const parameterDetails = this.getParametersForCurrentCountry();
    return this.getSearchedParameters(parameterDetails);
  };

  getParametersForCurrentCountry = () => {
    const { parameterDetails, selectedCountry } = this.state;
    return parameterDetails && selectedCountry
      ? parameterDetails.filter(
          (parameter) => parameter.country === selectedCountry.id
        )
      : [];
  };

  getSearchedParameters = (parameters) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return parameters;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return parameters.filter((parameter) => {
      const { id, parameterName } = parameter;
      return (
        (parameterName &&
          parameterName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  readImage = (image) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const parameterDetail = {
        ...this.state.parameterDetail,
        image_src: [reader.result],
      };
      this.setState({ parameterDetail });
    }.bind(this);
    reader.readAsDataURL(image);
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCategory(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewParameter ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      parameterDetails,
      parameterDetail,
      isNewParameter,
      biomarkers,
      biomarkerOptions,
    } = this.state;

    const filteredParameters =
      this.getFilteredParameters(parameterDetails) ?? [];
    const parameterDetailsForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="parameterName">Parameter Name</label>
              <div className="input-group">
                <input
                  value={parameterDetail?.parameterName || ""}
                  onChange={this.handleChange}
                  id="parameterName"
                  name="parameterName"
                  type="text"
                  placeholder="Parameter Name"
                  className={"form-control py-2"}
                  disabled={!isNewParameter && !editables.parameterName}
                />
                {this.renderPencilIcon("parameterName")}
              </div>
              <span className="help-block">{errors.parameterName}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="parameterDescription">
                Parameter Description
              </label>
              <div className="input-group">
                <textarea
                  value={parameterDetail?.description || ""}
                  onChange={this.handleChange}
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Parameter Description"
                  className={"form-control py-2"}
                  disabled={!isNewParameter && !editables.parameterDescription}
                />
                {this.renderPencilIcon("parameterDescription")}
              </div>
              <span className="help-block">{errors.parameterDescription}</span>
            </div>
            <div className="form-group col">
              <label htmlFor="status">Status</label>
              <div className="input-group">
                <Dropdown
                  value={parameterDetail.isActive}
                  onChange={(event, value) => {
                    this.setState({
                      parameterDetail: {
                        ...parameterDetail,
                        isActive: value.value,
                      },
                    });
                  }}
                  id="status"
                  name="status"
                  placeholder="Status"
                  search
                  selection
                  options={STATUS_OPTIONS}
                  disabled={!isNewParameter && !editables.status}
                  className={
                    "form-control" + (errors.status ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("status")}
              </div>
              <span className="help-block">{errors.status}</span>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="parameterScoring">Parameter Scoring</label>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="great">Great</label>
              <div className="input-group">
                <input
                  value={parameterDetail && parameterDetail.startGreatScore}
                  onChange={this.handleChange}
                  id="startGreatScore"
                  name="startGreatScore"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={parameterDetail && parameterDetail.endGreatScore}
                  onChange={this.handleChange}
                  id="endGreatScore"
                  name="endGreatScore"
                  type="number"
                  placeholder="To"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="startFairScore">Fair</label>
              <div className="input-group">
                <input
                  value={parameterDetail && parameterDetail.startFairScore}
                  onChange={this.handleChange}
                  id="startFairScore"
                  name="startFairScore"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={parameterDetail && parameterDetail.endFairScore}
                  onChange={this.handleChange}
                  id="endFairScore"
                  name="endFairScore"
                  type="number"
                  placeholder="To"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <label htmlFor="Poor">Poor</label>
              <div className="input-group">
                <input
                  value={parameterDetail && parameterDetail.startPoorScore}
                  onChange={this.handleChange}
                  id="startPoorScore"
                  name="startPoorScore"
                  type="number"
                  placeholder="From"
                  className={"form-control"}
                />
                <div className="range-select">-</div>
                <input
                  value={parameterDetail && parameterDetail.endPoorScore}
                  onChange={this.handleChange}
                  id="endPoorScore"
                  name="endPoorScore"
                  type="number"
                  placeholder="To"
                  className={"form-control "}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <div className="input-group">
                <textarea
                  value={parameterDetail?.greatDescription || ""}
                  onChange={this.handleChange}
                  id="greatDescription"
                  name="greatDescription"
                  type="text"
                  placeholder="Description"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <div className="input-group">
                <textarea
                  value={parameterDetail?.fairDescription || ""}
                  onChange={this.handleChange}
                  id="fairDescription"
                  name="fairDescription"
                  type="text"
                  placeholder="Description"
                  className={"form-control"}
                />
              </div>
            </div>
            <div className="form-group col">
              <div className="input-group">
                <textarea
                  value={parameterDetail?.poorDescription || ""}
                  onChange={this.handleChange}
                  id="poorDescription"
                  name="poorDescription"
                  type="text"
                  placeholder="Description"
                  className={"form-control"}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">Biomarker Icon*</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*"
                  disabled={!isNewParameter && !editables.image}
                  className={
                    "form-control" + (errors.image ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("image")}
              </div>
              <span className="help-block">{errors.image}</span>
            </div>
            <div className="form-group col">
              {parameterDetail?.image_src && (
                <>
                  <label htmlFor="selected_package_image">
                    Selected Icon Image
                  </label>
                  <div>
                    <img
                      id="selected_package_image"
                      name="selected_package_image"
                      src={parameterDetail.image_src}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <BiomarkerDetailsForm
            parameterBiomarkers={parameterDetail?.parameterBiomarkers || []}
            onBiomarkersUpdated={this.handleBiomarkersUpdated}
            isEditable={isEditable}
            biomarkerOptions={biomarkerOptions || []}
            biomarkers={biomarkers}
            selectedCountry={selectedCountry}
            isNewParameter={isNewParameter}
            longevityCategoryId={this.props.categoryId}
          />
        </div>
      </>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddParameter}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add Parameter
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Parameters</p>
          <DataTable data={filteredParameters || []} columns={this.columns} />
        </Card>
        <Modal
          size="lg"
          show={this.state.isParameterViewModalVisible}
          onHide={this.hideParameterViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>
                  {parameterDetail.id === null ||
                  parameterDetail.id === undefined
                    ? "Add Parameters"
                    : "Edit Parameters"}
                </span>
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handleSaveParameter}
              // disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>{parameterDetailsForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ParameterDetails);
