import PhoenixAPI from "utils/PhoenixAPI";

const BASE_URL_KEYWORDS_FOR_MOBILE = "/trending-search";
const BASE_URL_KEYWORDS_FOR_WEB = "/trending-search-web";

export const saveMobileKeyword = async (keyword) => {
  try {
    const payload = { ...keyword };
    const response = await PhoenixAPI.post(
      `${BASE_URL_KEYWORDS_FOR_MOBILE}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on creating popup banner", error);
    throw error;
  }
};

export const updatedMobileKeyword = async (keyword) => {
  try {
    const payload = { ...keyword };
    const response = await PhoenixAPI.patch(
      `${BASE_URL_KEYWORDS_FOR_MOBILE}/${keyword.id}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on updating pop up banner", error);
    throw error;
  }
};

export const saveWebKeyword = async (keyword) => {
  try {
    const payload = { ...keyword };
    const response = await PhoenixAPI.post(
      `${BASE_URL_KEYWORDS_FOR_WEB}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on creating popup banner", error);
    throw error;
  }
};

export const updatedWebKeyword = async (keyword) => {
  try {
    const payload = { ...keyword };

    const response = await PhoenixAPI.patch(
      `${BASE_URL_KEYWORDS_FOR_WEB}/${keyword.id}`,
      payload
    );
    return response;
  } catch (error) {
    console.log("Error on updating pop up banner", error);
    throw error;
  }
};

const SearchKeyWordService = {
  saveMobileKeyword,
  updatedMobileKeyword,
  saveWebKeyword,
  updatedWebKeyword,
};

export default SearchKeyWordService;
