
export const cancelReasonOptions=[
    {key:"",
     value:"",
     text:"Select the reason for cancellation"
    },
     {
        key:"Refund/Cancellation requested by client",
        value:"Refund/Cancellation requested by client",
        text:"Refund/Cancellation requested by client"
     },
     {
        key:"Client Unavailable /Client Not Responding",
        value:"Client Unavailable / Client Not Responding",
        text:"Client Unavailable / Client Not Responding"
     },
     {
        key:"Health Concerns/Doctor Consultation Needed",
        value:"Health Concerns/Doctor Consultation Needed",
        text:"Health Concerns/Doctor Consultation Needed"
     },
     {
        key:"Discount Request by Client",
        value:"Discount Request by Client",
        text:"Discount Request by Client"
     },
     {
        key:"Order Adjustment for Underage Client",
        value:"Order Adjustment for Underage Client",
        text:"Order Adjustment for Underage Client",
     },
     {
        key:"Order Entry Error/Modification Needed(Mention the exact reason in the notes below)",
        value:"Order Entry Error/Modification Needed(Mention the exact reason in the notes below)",
        text:"Order Entry Error/Modification Needed(Mention the exact reason in the notes below)",

     },
     {
        key:"Nurse Issues: Unprofessional Behavior/Multiple pricks/Late Collection(Mention the exact reason in the notes below)",
        value:"Nurse Issues: Unprofessional Behavior/Multiple pricks/Late Collection(Mention the exact reason in the notes below)",
        text:"Nurse Issues: Unprofessional Behavior/Multiple pricks/Late Collection(Mention the exact reason in the notes below)"
     },
     {
        key:"Test Order",
        value:"Test Order",
        text:"Test Order"
     },
     {
        key:"Other",
        value:"Other",
        text:"Other"
     }

]


export default cancelReasonOptions;