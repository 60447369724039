import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const SelectedQuestions = ({ question = {},isEditable=false, selectedQuestion={}, isEditView=false , selectedQuestionsFormapping=[]}) => {
  const { questionEnglish = "", id } = question || {};
  const [selectedId, setSelectedId] = useState([]);
  const [selectedCard, setSelectedCard] = useState(false);

  const isSelectedProfile = (question) => {
    let selected = [];

  selectedQuestionsFormapping.forEach((item) => {
      if (item === question.id) {
        selected.push(question)
      }
    });

    if(selected.length>0){
      for (const index in selected) {
        const {id} = selected[index] || {};
        if (id == question.id) {
          return true;
        }
        else{
          
        }
      }
    }
    else{
      return false
    }
   

  };

  const handleSelectedQuestion=(question)=>{
    setSelectedCard((prevState) => !prevState);
    selectedQuestion(question)
  }

  const CheckBox = ({ isChecked, isDisable }) => {
    return (
        <>
        <div className="form-check custom-control custom-checkbox ml-2">
          <input
            value={true}
            checked={isChecked}
            id="questionId"
            name="questionId"
            type="checkbox"
            className="custom-control-input"
            disabled={isDisable}
          />
          <label className="custom-control-label" htmlFor="questionId"></label>
        </div>
      </>
    );
  };


  return (
    <>
      <div style={{ display:"flex", width:"50%", padding:"10px"}}>
        <div className="">
          <p>{questionEnglish}</p>
        </div>
        <div className="" >
            <div onClick={() => isEditView?"":handleSelectedQuestion(id,question)}>
            
            {isSelectedProfile(question) ? (
                  <CheckBox isChecked={true}  isDisable={isEditView}/>
                ) : (
                  <CheckBox isChecked={false} isDisable={false}/>
                )}
              
            </div>
            
          
        </div>
      </div>
    </>
  );
};


export default SelectedQuestions;
