import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import PhoenixAPI from "utils/PhoenixAPI";
import {
  fetchParameterWeightages,
  saveParameterWeightage,
} from "services/LongevityService";

class ParameterWeightages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditable: true,
      countries: [],
      parametersWeightage: [],
      rankOptions: [],
      otheritem: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isParameterWeightageModalVisible: false,
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Parameters",
        selector: "parameterName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Weightage",
        selector: "weightage",
        wrap: true,
        sortable: true,
        center: true,
      },
    ];
  }

  async componentWillReceiveProps(nextProps) {
    const categoryId = nextProps.categoryId;
    const selectedCountry = nextProps.selectedCountry;
    this.fetchParameterWeightages(categoryId);
    this.setState({ selectedCountry });
  }

  handleUpdateWeightage = () => {
    this.setState({ isParameterWeightageModalVisible: true });
  };

  hideParameterViewModal = () => {
    this.setState({ isParameterWeightageModalVisible: false });
    this.resetForm();
  };

 

  fetchParameterWeightages = async (categoryId) => {
    this.showLoading();
    try {
      const parametersWeightage =
        (await fetchParameterWeightages(categoryId)) || [];
      this.setState({ parametersWeightage });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching Longevity Categories:",
        error.message
      );
      this.setState({ loadError: "Some Error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString.trim() : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = (event, value) => {
    const target = event.target;

    const { parametersWeightage } = this.state;
    const newArray = parametersWeightage.map((obj) => {
      if (obj.id === value.id) {
        return { ...obj, weightage: target.value };
      }
      return obj;
    });

    this.setState({ parametersWeightage: newArray });
  };

  getFilteredParameterWeightages = () => {
    const parameterWeightages = this.getWeightagesForCurrentCountry();
    return parameterWeightages;
  };

  getWeightagesForCurrentCountry = () => {
    const { parametersWeightage, selectedCountry } = this.state;
    return parametersWeightage && selectedCountry
      ? parametersWeightage.filter(
          (parameter) => parameter.country === selectedCountry.id
        )
      : [];
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleSaveParameterWeightage = async () => {
    const { parametersWeightage = [] } = this.state;
    const { categoryId = "" } = this.props;
    const totalWeightage = parametersWeightage
      .filter((obj) => obj.weightage !== 0)
      .reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.weightage);
      }, 0);

    if (totalWeightage === 100) {
      const payload = parametersWeightage.map((it) => {
        return {
          id: it.id,
          weightage: Number(it.weightage),
        };
      });

      try {
        const response = await saveParameterWeightage(payload);
        this.showSuccessNotification(
          "Parameter Weightage is Added Successfully"
        );
        this.hideParameterViewModal();
        this.fetchParameterWeightages(categoryId);
      } catch (error) {
        this.showErrorNotification("Error Occured in Saving weightage");
      } finally {
        this.hideLoading();
      }
    } else {
      this.showErrorNotification("Parameter Weightage should be equal to 100");
    }
  };

  resetForm = () => {
    window.location.reload(true);
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { errors, editables, isEditable, parametersWeightage } = this.state;
    const filteredParameterWeightages =
      this.getFilteredParameterWeightages(parametersWeightage) ?? [];
    const totalWeightage = parametersWeightage
      .filter((obj) => obj.weightage !== 0)
      .reduce((accumulator, currentObject) => {
        return accumulator + Number(currentObject.weightage);
      }, 0);

    const parameterWeightageForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
              <p style={{ color: "#757575" }}>Parameters</p>
            </div>
            <div className="form-group col">
              <p style={{ color: "#757575" }}>Weightage (%)</p>
            </div>
          </div>
          {parametersWeightage.length > 0 &&
            parametersWeightage.map((parameter) => {
              return (
                <>
                  <div className="row">
                    <div className="form-group col">
                      <p>{parameter.parameterName}</p>
                    </div>
                    <div className="form-group col">
                      <input
                        type="number"
                        name={`${parameter.parameterName}`}
                        className={"form-control"}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) event.preventDefault();
                        }}
                        placeholder="weightage"
                        onChange={(e) => this.handleChange(e, parameter)}
                        value={parameter.weightage}
                      ></input>
                    </div>
                  </div>
                </>
              );
            })}

          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <div style={{ fontWeight: "bold" }}>Total:{totalWeightage}</div>
            <button
              onClick={this.handleSaveParameterWeightage}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </div>
        </div>
      </>
    );

    return (
      <div className="manage-package">
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <button
                onClick={this.handleUpdateWeightage}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Update Weightage
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <p className="font-weight-bold">Parameter Weightage</p>
          <DataTable
            data={filteredParameterWeightages || []}
            columns={this.columns}
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isParameterWeightageModalVisible}
          onHide={this.hideParameterViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Parameter Weightage </span>
              </p>
            </Modal.Title>
            <div
              className="user-title"
              style={{ border: "1px solid teal" }}
            ></div>
          </Modal.Header>
          <Modal.Body>{parameterWeightageForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParameterWeightages);
