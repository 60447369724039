import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import moment from "moment";
import { getUserEmail, getUserId, getUserName } from "services/UserService";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Notification from "../../pages/HomecareMwebPortal/Notification";
import Select from "react-select";
import PhoenixAPI from "utils/PhoenixAPI";
import "./homecareTicketManagement.scss";
import "./viewMyTicket.scss";
import PdfPreview from "components/Common/PdfPreview";
import CreateTicket from "../../pages/HomeCarePortal/CreateTicket";
import "../../pages/HomecareMwebPortal/OrderCards/orderCards.scss";

export default function HomecareTicketView(props) {
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [homecareUser, setHomecareUser] = useState("");
  const [homecareEmail, setHomecareEmail] = useState("");
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [ticketDetails, setTicketDetails] = useState({});
  const [ticketDetailsHistory, setTicketDetailsHistory] = useState([]);
  const [ticketId, setTicketId] = useState(null);

  const handleBackButton = () => {
    return (window.location.href =
      "/dashboard/homecare-mobile-portal/ticket-management");
  };

  const getTicketDetails = async (ticketId) => {
    try {
      setIsLoading(true);
      const { data: ticketDetails = [] } = await PhoenixAPI.get(
        `ticket/${ticketId}`
      );
      const { latestTicket, ticketHistory = [] } = ticketDetails ?? {};
      console.log("latestTicket: ", latestTicket);
      setTicketDetails(latestTicket);
      setTicketDetailsHistory(ticketHistory);
    } catch (error) {
      console.log("Error in view Ticket", error.message);
      setMessage("Error in fetching Ticket", error.message);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const { ticketId } = props.match.params;

    if (ticketId) {
      getTicketDetails(ticketId);
      setTicketId(ticketId);
      const userEmail = getUserEmail();
      const userName = getUserName();
      setHomecareUser(userName);
      setHomecareEmail(userEmail);
    }
  }, []);

  const getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  const renderClosableImages = (image) => {
    const isPdfFile =
      image?.type === "application/pdf" ||
      (typeof image === "string" && image.toLowerCase().endsWith(".pdf"));
    const preview = isPdfFile ? (
      <div style={{ width: "150px" }}>
        <PdfPreview
          pdfFile={isPdfFile ? image : null}
          pdfFileUrl={isPdfFile ? null : image}
        />
      </div>
    ) : (
      <img src={image} alt="Selected" />
    );
    return <div className="image-area">{preview}</div>;
  };

  const handleReopenTicket = () => {
    return (window.location.href = `/dashboard/home-care/reopen-ticket/${ticketDetails.id}`);
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  return (
    <div>
      {window.innerWidth < 768 && (
        <div className="top-header">
          <div className="header-content">
            <div className="content" onClick={() => handleBackButton()}>
              <div className="lt">&lt;</div>
              <div className="header-title"> Ticketing Management</div>
            </div>
          </div>
        </div>
      )}

      <div className="view-ticket-form">
        <div>
          <div className="ticket-title">
            <div>Title : {ticketDetails.title}</div>
            <div>Ticket ID : {ticketDetails.ticketUUID}</div>
          </div>
          {(ticketDetails.ticketStatus === "Open" ||
            ticketDetails.ticketStatus === "Reopen") && (
            <div className="ticket-status-bar">
              Status :{" "}
              <span className="openStatus">{ticketDetails.ticketStatus}</span>
            </div>
          )}
          {ticketDetails.ticketStatus === "In Progress" && (
            <div className="ticket-status-bar">
              Status :{" "}
              <span className="inProgressStatus">
                {ticketDetails.ticketStatus}
              </span>
            </div>
          )}
          {ticketDetails.ticketStatus === "Resolved" && (
            <div className="ticket-status-bar">
              Status :{" "}
              <span className="resolvedStatus">
                {ticketDetails.ticketStatus}
              </span>
            </div>
          )}
          <div className="title-content">
            <div>
              Creation Date :{" "}
              {getFormattedPurchasedDate(ticketDetails?.createdAt)}
            </div>

            <div>
              Resolution Date :
              {ticketDetails.resolvedAt
                ? getFormattedPurchasedDate(ticketDetails?.resolvedAt ?? "")
                : ""}
            </div>
            <div>Order Id : {ticketDetails.orderId}</div>
            <div className="">Category : {ticketDetails.ticketCategory}</div>

            <div>Description : {ticketDetails.description}</div>

            <div>Images/Files : </div>

            <div className="row">
              <div className="media-scroller snaps-inline" style={{gridGap:"100px"}}>
                {ticketDetails.mediaS3Url &&
                  ticketDetails.mediaS3Url.map((item) => {
                    return (
                      <div className="upload-container">
                        <div className="upload-container">
                          <div className="upload-file-box">
                            <div className="choose-fileand-icon">
                              <img
                                className="uplooad-icon"
                                src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                alt=""
                              />
                              <span
                                className="choose-file"
                                onClick={() => downloadFile(item)}
                              >
                                Download File
                              </span>
                            </div>
                            {renderClosableImages(item)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div>
              Remarks by Valeo Team : {ticketDetails.adminHomecareRemarks}
            </div>

            <div>Attachments by Valeo : </div>
            <div className="row">
              <div className="media-scroller snaps-inline" style={{gridGap:"100px"}}>
                {ticketDetails.adminHomecareRemarksMediaS3Url &&
                  ticketDetails.adminHomecareRemarksMediaS3Url.map((item) => {
                    return (
                      <div className="upload-container">
                        <div className="upload-container">
                          <div className="upload-file-box">
                            <div className="choose-fileand-icon">
                              <img
                                className="uplooad-icon"
                                src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                alt=""
                              />
                              <span
                                className="choose-file"
                                onClick={() => downloadFile(item)}
                              >
                                Download File
                              </span>
                            </div>
                            {renderClosableImages(item)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {ticketDetails.ticketStatus === "resolved" ||
            ticketDetails.ticketStatus === "Resolved" ? (
              <div className="reopen-div">
                <div>Want to Follow Up ?</div>

                <div>
                  <button
                    onClick={(e) => handleReopenTicket()}
                    id="show_orders"
                    className="btn px-4"
                    style={{ backgroundColor: "#CEE741" }}
                  >
                    Reopen the Ticket
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {ticketDetailsHistory &&
          ticketDetailsHistory.length > 0 &&
          ticketDetailsHistory.map((ticketHistory) => {
            return (
              <div className="view-ticket-form">
                <div>
                  <div className="ticket-history-name">Ticket History</div>
                  <div className="ticket-title">
                    <div>Title : {ticketHistory.title}</div>
                    <div>Ticket ID : {ticketHistory.ticketUUID}</div>
                  </div>
                  {(ticketHistory.ticketStatus === "Open" ||
                    ticketHistory.ticketStatus === "Reopen") && (
                    <div className="ticket-status-bar">
                      Status :{" "}
                      <span className="openStatus">
                        {ticketHistory.ticketStatus}
                      </span>
                    </div>
                  )}
                  {ticketHistory.ticketStatus === "In Progress" && (
                    <div className="ticket-status-bar">
                      Status :{" "}
                      <span className="inProgressStatus">
                        {ticketHistory.ticketStatus}
                      </span>
                    </div>
                  )}
                  {ticketHistory.ticketStatus === "Resolved" && (
                    <div className="ticket-status-bar">
                      Status :{" "}
                      <span className="resolvedStatus">
                        {ticketHistory.ticketStatus}
                      </span>
                    </div>
                  )}
                  <div className="title-content">
                    <div>
                      Creation Date :{" "}
                      {getFormattedPurchasedDate(ticketHistory?.createdAt)}
                    </div>

                    <div>
                      Resolution Date :
                      {ticketHistory.resolvedAt
                        ? getFormattedPurchasedDate(
                            ticketHistory?.resolvedAt ?? ""
                          )
                        : ""}
                    </div>
                    <div>Order Id : {ticketHistory.orderId}</div>
                    <div className="">
                      Category : {ticketHistory.ticketCategory}
                    </div>

                    <div>Description : {ticketHistory.description}</div>

                    <div>Images/Files : </div>

                    <div className="row">
                      <div className="media-scroller snaps-inline" style={{gridGap:"100px"}}>
                        {ticketHistory.mediaS3Url &&
                          ticketHistory.mediaS3Url.map((item) => {
                            return (
                              <div className="upload-container">
                                <div className="upload-file-box">
                                  <div className="choose-fileand-icon">
                                    <img
                                      className="uplooad-icon"
                                      src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                      alt=""
                                    />
                                    <span
                                      className="choose-file"
                                      onClick={() => downloadFile(item)}
                                    >
                                      Download File
                                    </span>
                                  </div>
                                  {renderClosableImages(item)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div>
                      Remarks by Valeo Team :{" "}
                      {ticketHistory.adminHomecareRemarks}
                    </div>

                    <div>Attachments by Valeo : </div>
                    <div className="row">
                      <div className="media-scroller snaps-inline" style={{gridGap:"100px"}}>
                        {ticketHistory.adminHomecareRemarksMediaS3Url &&
                          ticketHistory.adminHomecareRemarksMediaS3Url.map(
                            (item) => {
                              return (
                                <div className="upload-container">
                                  <div className="upload-file-box">
                                    <div className="choose-fileand-icon">
                                      <img
                                        className="uplooad-icon"
                                        src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                                        alt=""
                                      />
                                      <span
                                        className="choose-file"
                                        onClick={() => downloadFile(item)}
                                      >
                                        Download File
                                      </span>
                                    </div>
                                    {renderClosableImages(item)}
                                  </div>
                                </div>
                              );
                            }
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {message && (
        <Notification
          setMessage={setMessage}
          message={message}
          isError={isError}
        />
      )}
      <CustomLoader show={isLoading} />
    </div>
  );
}
