import React, { Component } from "react";
import { connect } from "react-redux";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import BlockIcon from "@material-ui/icons/Block";
import PhoenixAPI from "utils/PhoenixAPI";
import { formatDateTime } from "utils/commons";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin,isUserAdmin } from "services/aclService";
import AccessDenied from "components/Common/AccessDenied";
import SelectItemType from "components/Common/SelectItemType";
import Subscription_TYPE_OPTIONS from "utils/SubscriptionType";
import OrderReviews from "pages/Reviews/OrderReviews";
import Analytics from "./Analytics"

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_SUBCRIPTION_ORDERS = "subscription-order-details";
const BASE_URL_COUNT_SUBSCRIPTION_ORDERS= "/count-subscription-order";

class SubscriptionOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      subscriptionOrders: [],
      loadError: null,
      searchTerm: null,
      searchApplied: false,
      selectSubscriptionType:null,
      selectedSubscriptionType:null,
      collapsibles: {},
      activeSubscriptionCall:0,
      countSuccessfullSubscription:0,
      countFailedSubscription:0,
      columns :this.subscriptionColumns()
    };
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchSubscriptionOrders();
  }
 
  componentDidUpdate(prevProps, prevState) {
    const { collapsibles } = this.state;
    if (collapsibles && collapsibles !== prevState.collapsibles) {
     
     this.setState({
      
    columns:this.subscriptionColumns()
  }) 
    }
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.SUBSCRIPTION_ORDERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchSubscriptionOrders = async () => {
    this.showLoading();
    try {
      const response = await PhoenixAPI.get(BASE_URL_SUBCRIPTION_ORDERS);
      const {subscriptionOrderDetails,subscriptionMonthCount} = response && response.data;
      const {countActiveSubscription,countFailedSubscription,countSuccessfullSubscription}=subscriptionMonthCount
      this.setState({ subscriptionOrders:subscriptionOrderDetails, activeSubscriptionCall:countActiveSubscription,
        countSuccessfullSubscription, countFailedSubscription
      });
      
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching subscription orders: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  handleCollapsibleChange = (key) => {
   
    const collapsibles = {};
   
    if (this.state.collapsibles[key] === undefined) {
      collapsibles[key] = true
    };
   
    this.setState({ collapsibles});
  };


  // renderOrderAnalytics=async(orderId)=>{
  //   this.showLoading();
  //   try{
  //     const response= await PhoenixAPI.get(`${BASE_URL_COUNT_SUBSCRIPTION_ORDERS}/${orderId}`)
      
  //     console.log("datat:", response)
  //   }
  //  catch(error){
  //   this.showErrorNotification(
  //     "Error on fetching count subscription orders: ",
  //     error.message
  //   );
  //   this.setState({ loadError: "Some error has occured. Please try again" });
  //  }
  //  finally {
  //   this.hideLoading();
  // }
  //   return(
  //     <div>
  //       {orderId}
  //     </div>
  //   )
  // }
  
  renderAnalyticsCall=(order)=>{
    return(
      <div style={{width:"100%"}}>
      <div>
        {order.user_id}
      </div>
        <div
          style={{ width:"100%",cursor: "pointer" }} >
          <div>
            <i
               onClick={() =>
                this.handleCollapsibleChange(order.id)
              }
              className={`caret square ${
                this.state.collapsibles[order.id]?"down":"right"
              } icon big`}
                style={{ cursor: "pointer" }}
              ></i>
          </div>
        </div>
    {this.state.collapsibles[order.id]? <Analytics orderId={order.id}/>:""}
    </div>
    )
  }

  renderIsSubscriptionActive = (row) => {
    return (
      <>
        {row.is_subscription_paused ? (
          <div className="badge badge-danger">
            <BlockIcon></BlockIcon>
            No
          </div>
        ) : (
          <div className="badge badge-success">
            <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>Yes
          </div>
        )}
      </>
    );
  };


  getSelectedOrders=(orders,subscriptiontype)=>{
    if(subscriptiontype=="Active" ) return orders.filter((suborder)=>suborder.is_subscription_paused==false);
    else if(subscriptiontype=="InActive" ) return orders.filter((suborder)=>suborder.is_subscription_paused==true);
    else if(subscriptiontype=="All" ) return orders;

  }

  getFilteredOrders = (subscriptionOrders) => {
    const { searchApplied, searchTerm , selectedSubscriptionType} = this.state;
    let filteredSubscriptionOrders=subscriptionOrders;
    // console.log(selectedSubscriptionType);
    if(selectedSubscriptionType){
      filteredSubscriptionOrders =this.getSelectedOrders(subscriptionOrders,selectedSubscriptionType);
      if(!searchApplied || !searchTerm)
      return filteredSubscriptionOrders;
    }
    else if(!selectedSubscriptionType){ 
      if(searchApplied)
       filteredSubscriptionOrders=subscriptionOrders;
       else if (!searchApplied || !searchTerm ) 
          return subscriptionOrders;
    }
    else if (!searchApplied || !searchTerm ) 
     return subscriptionOrders;
    

     
    if(searchApplied){

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    console.log(newValue);
    return filteredSubscriptionOrders.filter((order) => {
      const {
        user_id,
        email,
        full_name,
        package_id,
        package_type,
        initial_sub_order_id,
        initial_cart_order_id,
        
      } = order;
      
      return (
        (email && email.toLowerCase().indexOf(newValue) !== -1) ||
        (full_name && full_name.toLowerCase().indexOf(newValue) !== -1) ||
        (package_type && package_type.toLowerCase().indexOf(newValue) !== -1) ||
        (user_id && user_id.toString() === newValue) ||
        (package_id && package_id.toString() === newValue) ||
        (initial_sub_order_id &&
          initial_sub_order_id.toString() === newValue) ||
        (initial_cart_order_id && initial_cart_order_id.toString() === newValue)
      );
    });}
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    this.setState({ searchApplied: true });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });
 

  handleItemType = (selectedItemTypeId) => {

    const selectSubscriptionType = Subscription_TYPE_OPTIONS.filter((it) => it.id === selectedItemTypeId).map((it) => it.value)
    // this.setState({...this.state,selectSubscriptionType:selectSubscriptionType[0]})
    //this.setState({...this.state,selectSubscriptionType:selectSubscriptionType});
    const itemType = selectSubscriptionType[0];
    console.log("item"+itemType);
    this.setState({selectedSubscriptionType:itemType});
    console.log("In function"+this.state.selectedSubscriptionType);
  }

  subscriptionColumns=()=>{
    return [
      {
        name: "User Id",
        selector: "user_id",
        sortField: "user_id",
        sortable: true,
        center:true,
        minWidth:"150px",
        wrap:true,
        cell:(order)=>this.renderAnalyticsCall(order)
      },
      {
        name: "Email Id",
        selector: "email",
        sortField: "email",
        sortable: true,
        wrap: true,
      },
      {
        name: "Full Name",
        selector: "full_name",
        sortField: "full_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Cart Order Id",
        selector: "initial_cart_order_id",
        sortField: "initial_cart_order_id",
        sortable: true,
      },
      {
        name: "Child Order Id",
        selector: "initial_sub_order_id",
        sortField: "initial_sub_order_id",
        sortable: true,
      },
      {
        name: "Package Type",
        selector: "package_type",
        sortField: "package_type",
        sortable: true,
        width: "150px",
      },
      {
        name: "Package Name",
        selector: "package_name",
        sortField: "package_name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Subscription Start Date",
        selector: "start_date",
        wrap: true,
        sortable: true,
        width: "150px",
      },
      {
        name: "Payment Due Date",
        selector: "payment_due_date",
        wrap: true,
        sortable: true,
        width: "150px",
      },
      {
        name: "Quantity",
        selector: "quantity",
        sortField: "quantity",
        wrap: true,
        sortable: true,
        center: true,
      },
      {
        name: "Number Of Months",
        selector: "no_of_months",
        sortField: "no_of_months",
        wrap: true,
        sortable: true,
        center: true,
        width: "200px",
      },
      {
        name: "Subscription Status",
        selector: "is_subscription_paused",
        sortField: "is_subscription_paused",
        wrap: true,
        sortable: true,
        width: "200px",
        center: true,
        cell: (row) => this.renderIsSubscriptionActive(row),
      },
      {
        name: "Created At",
        selector: "created_at",
        wrap: true,
        sortable: true,
        width: "200px",
        center: true,
        format: (row) =>
          row.created_at ? formatDateTime(row.created_at) : "-",
      },
    ]
  }

  render() {
    const { subscriptionOrders,selectSubscriptionType,selectedSubscriptionType, searchTerm, 
      searchApplied, loadError, collapsibles,
       activeSubscriptionCall,
       countSuccessfullSubscription,countFailedSubscription } =
      this.state;
    const filteredOrders = this.getFilteredOrders(subscriptionOrders);
 
    
    if (this.state.accessDenied) {
      return <AccessDenied />
    }
    
    return (
      <>
        <div className="manage-package">
          <div className="page-header">
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  handleSearchEnter={this.handleSearchEnter}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{float:"right"}}>
              <div>{`Active Subscription Call this Month -> ${activeSubscriptionCall}`}</div>
              <div>{`Total subscription successfully for the month-> ${countSuccessfullSubscription}`}</div>
              <div>{`Total Failed subscription for the month -> ${countFailedSubscription}`}</div>
            </div>
          <div style={{ width: "50%", marginBottom: "50px" }}>
          <div className="row">
            <div className="col-6">
              <SelectItemType
                 ItemTypes={Subscription_TYPE_OPTIONS ?? []}
                onItemTypeSelect={(itemTypeId) => this.handleItemType(itemTypeId)}
                selectedItemType={selectSubscriptionType}
                filterText="Filter by Active/Inactive"
              />
            </div>
          </div>
        </div>
          <Card body>
            <DataTable
              data={filteredOrders || []}
              columns={this.state.columns || []}
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationPerPage={DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              highlightOnHover
              responsive
              noHeader={searchApplied ? false : true}
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + filteredOrders.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
            />
          </Card>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionOrders);
