import React, { Component } from "react"
import API from "utils/API";
import { connect } from "react-redux";

const BASE_URL_ADMIN_UPDATES = "admin-app-config/";
class ForceUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updates: [],
      update: {},
      editables: {},
      editIcon: false,
      errors: {}
    }
  }

  componentDidMount() {
    this.fetchupdates()
  }

  fetchupdates = async () => {
    try {
      this.showLoading();
      const response = await API.get(BASE_URL_ADMIN_UPDATES)
      const updates = response.data || []
      const update = updates.find((it) => it.id)
      this.setState({ updates, update })
      this.hideLoading()
    }
    catch (error) {
      console.log(
        "Error on fetching force/recommended updates from this URL: ",
        BASE_URL_ADMIN_UPDATES
      );
      console.log("Error on fetching force/recommended updates", error.message);
      this.hideLoading();
      this.showErrorNotification(
        "Error on loading force/recommended updates: ",
        error.message
      );
      this.hideLoading();
    }
  }

  handleChange = ({ currentTarget: input }) => {
    const update = { ...this.state.update }
    update[input.name] = input.value
    this.setState({ update })
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    !this.state.editIcon ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  handleSave = async () => {
    const errors = this.validate();
    this.setState({ errors: errors || {} })
    if (errors) {
      this.showErrorNotification("There are some invalid fields!");
      return;
    }
    const { update } = this.state;
    const isValidId = update.id !== null && update.id !== undefined;
    const apiOptions = isValidId
      ? this.createApiOptions(
        "patch",
        `${BASE_URL_ADMIN_UPDATES}${update.id}/`
      )
      : this.createApiOptions("post", BASE_URL_ADMIN_UPDATES);

    try {
      this.showLoading();
      await API.request(apiOptions);
      this.hideLoading();
      this.showSuccessNotification(
        isValidId ? "Updated Successfully." : "Created Successfully."
      );
      this.fetchupdates();
    } catch (error) {
      this.hideLoading();
      this.showErrorNotification("Some error occurred: " + error.message);
      console.log("Some error occurred", error.message);
    }
  }

  createApiOptions = (method, url) => {
    const formData = this.createFormData();
    return {
      method,
      url,
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };
  };

  validate = () => {
    const errors = {};
    const { min_recommended_version, min_forceupdate_version } = this.state.update;
    const UPDATE_VERSION_REGEX = new RegExp(/^(\d{1,3}\.)(\d{1,3}\.)(\d{1,3})$/)
    const recommended = UPDATE_VERSION_REGEX.test(min_recommended_version)
    const forced = UPDATE_VERSION_REGEX.test(min_forceupdate_version)

    if (!min_recommended_version || !recommended) {
      errors.min_recommended_version = "Enter a Valid Update"
    }

    if (!min_forceupdate_version || !forced) {
      errors.min_forceupdate_version = "Enter a Valid Update"
    }

    return Object.keys(errors).length === 0 ? null : errors;
  }

  createFormData = () => {
    const { update } = this.state;
    const formData = new FormData();
    formData.append("min_recommended_version", update.min_recommended_version)
    formData.append("min_forceupdate_version", update.min_forceupdate_version)

    return formData
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const { editables, update } = this.state
    return (
      <>
        <div className="form-group col" >
          <label htmlFor="min_recommended_version">Min. Recommended updated version</label>
          <div className="input-group">
            <input
              id="min_recommended_version"
              name="min_recommended_version"
              value={update && update.min_recommended_version || ""}
              onChange={this.handleChange}
              type="text"
              disabled={!editables.min_recommended_version}
            />
            {this.renderPencilIcon("min_recommended_version")}
          </div>
        </div>
        <div className="form-group col" >
          <label htmlFor="min_forceupdate_version">Min. Force updated version</label>
          <div className="input-group" >
            <input
              id="min_forceupdate_version"
              name="min_forceupdate_version"
              value={update && update.min_forceupdate_version || ""}
              onChange={this.handleChange}
              type="text"
              disabled={!editables.min_forceupdate_version}
            />
            {this.renderPencilIcon("min_forceupdate_version")}
          </div>
        </div>
        <button className="btn px-5"
          style={{ backgroundColor: "#CEE741", margin: "10px 0px 0px 15px" }}
          onClick={() => this.handleSave()}>Save</button>
      </>
    )
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForceUpdate)