import React, { Component } from "react";
import { connect } from "react-redux";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Card from "react-bootstrap/Card";
import DataTable from "react-data-table-component";
import { Modal } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import Status from "components/Common/Status";
import { filterList } from "utils/List";
import API from "utils/API";

const STATUS_OPTIONS = [
  {
    key: "Active",
    value: "Active",
    text: "Active",
  },
  {
    key: "Inactive",
    value: "Inactive",
    text: "Inactive",
  },
];

 class NonBiomarkerTestDetails extends Component{

 constructor(props){
    super(props);
    const { nonBloodBiomarkerTests = [], nonBloodBiomarkerTestUpdated= (f) => f } =
    this.props;
      this.nonBloodBiomarkerTestUpdated=nonBloodBiomarkerTestUpdated;
      this.state = {
        nonBloodBiomarkerTests,
        nonBloodBiomarkerTest: {},
        addNew:false,
        errors: {},
        editables: {},
        loadError: "",
        isConfirmDeleteModalVisible: false,
        biomarkerCategoriesList:[]
      };
      this.columns = [
       
        {
          name: "Test Name",
          selector: "test_name",
          sortable: true,
          wrap:true,
        
        },
        {
          name: "Test Category",
          selector: "test_category_name",
          sortable: true,
          wrap: true,
        },
        {
          name: "Status",
          selector: "is_active",
          sortable: true,
          center: true,
          maxWidth: "150px",
          cell: (test) => <Status isActive={test.is_active} />,
        },
       
        {
          name: "Edit",
          // maxWidth: "100px",
          center: true,
          allowOverflow: true,
          cell: (test) => this.renderEditButton(test.id),
        },
      ];

 }

 componentDidMount() {
  this.getBiomarkers();
}

getBiomarkers=async()=>{
 const{data: biomarkercategories = []} =await API.get("test-category/");

       let categoryList = filterList(biomarkercategories, 'active')
        let temp = []
        categoryList.map((item) => {
          temp.push({
            text: item.name,
            value: item.id,
            key: item.id
          })
        });
    this.setState({biomarkerCategoriesList:temp});



}

 showAddEditModal = () => {
  this.setState({ isAddEditModalVisible: true });
};
clearForm = () => {
  Array.from(document.querySelectorAll("input")).forEach(
    (it) => (it.value = "")
  );
  Array.from(document.querySelectorAll("textarea")).forEach(
    (it) => (it.value = "")
  );
  this.setState({
    errors: {},
    editables: {},
     nonBloodBiomarkerTest: {},
  });
};

showSuccessNotification = (notificationMessage) =>
this.showNotification(notificationMessage, true);

showErrorNotification = (notificationMessage) =>
this.showNotification(notificationMessage, false);

showNotification = (notificationMessage, isSuccessMessage) =>
this.props.showNotificationMessage({
  notificationMessage,
  successMessage: isSuccessMessage,
  showNotification: true,
});

renderEditButton = (testId) => (
  <div>
    <button
      className="btn px-5"
      style={{ backgroundColor: "#CEE741" }}
      onClick={() => {
        this.handleEdit(testId);
      }}
    >
      Edit
    </button>
  </div>
);
showConfirmDeleteModal = () => {
  this.setState({ isConfirmDeleteModalVisible: true });
};



hideConfirmDeleteModal = () => {
  this.setState({ isConfirmDeleteModalVisible: false });
};

handleEdit = (id) => {
  this.clearForm();
  const { nonBloodBiomarkerTests = [] } = this.state;
  const nonBloodBiomarkerTest = nonBloodBiomarkerTests.find((it) => it.id === id);
  if (!nonBloodBiomarkerTest) return;

  this.setState({ nonBloodBiomarkerTest }, () => this.showAddEditModal());
};

hideAddEditModal = () => {
  this.clearForm();
  this.setState({ isAddEditModalVisible: false });
};
 handleAddNewNonBloodBiomarker=()=>{
  const nonBloodBiomarkerTest = { is_active: "Inactive" };
  this.setState({nonBloodBiomarkerTest }, () => this.showAddEditModal());
}
handleChange = ({ currentTarget: input }) => {
 
  const nonBloodBiomarkerTest= { ...this.state.nonBloodBiomarkerTest };

    nonBloodBiomarkerTest[input.name] = input.value;
  
  this.setState({ nonBloodBiomarkerTest });
};

validate = () => {
  const errors = {};
  const {test_name_ar,  is_active,
  test_name,test_category_id,description,description_ar,
  } = this.state.nonBloodBiomarkerTest || {};

    if(!test_name) errors.test_name="Test Name (English) is required field";
    if (test_name === null || test_name === undefined || test_name ==="" )
    errors.test_name="Test Name (English) is required field";
    if(!test_name_ar) errors.test_name_ar="Test Name (Arabic) is required field";
    if (test_name_ar === null || test_name_ar === undefined || test_name_ar ==="" )
    errors.test_name_ar="Test Name (Arabic) is required field";
    if(!description) errors.description="Description (English) is required field";
    if (description === null || description === undefined || description==="" )
    errors.description="Description (English) is required field"
    if(!description_ar) errors.description_ar="Description (Arabic) is required field";
    if (description_ar === null || description_ar === undefined || description_ar==="" )
    errors.description_ar="Description (Arabic) is required field";
    if(!test_category_id) errors.test_category_name="Test Category is required field";
   
     if(test_name && test_name.length>255)
     errors.test_name="Test Name (English) is limited to 255 characters";
     if(test_name_ar && test_name_ar.length>255)
     errors.test_name_ar="Test Name (Arabic) is limited to 255 characters";
     if(description && description.lenth>1000)
     errors.description="description (English) is limited to 1000 characters";
     if(description_ar && description_ar.length>1000)
     errors.description_ar="description (Arabic) is limited to 1000 characters";
    return Object.keys(errors).length === 0 ? null : errors;
}

createNonBloodBiomarkerTest=()=>{
  const {nonBloodBiomarkerTests = [], nonBloodBiomarkerTest } = this.state;
  const ids =
  nonBloodBiomarkerTests.length > 0 ? nonBloodBiomarkerTests.map((it) => it.id) : null;
nonBloodBiomarkerTest.id = ids ? Math.max(...ids) + 1 : 1;
  nonBloodBiomarkerTest.is_new = true;
  this.setState(
    {nonBloodBiomarkerTests : [...nonBloodBiomarkerTests, nonBloodBiomarkerTest] },
    () => {
      this.showSuccessNotification("Created Successfully!");
      this.onNonBloodBiomarkerUpdateLocally();
    }
  );
}

updateNonBloodBiomarkerTest= (id) => {
  const { nonBloodBiomarkerTests = [], nonBloodBiomarkerTest } = this.state;
 const filterRemainingIds= nonBloodBiomarkerTests.filter(
    (it) => it.id !== nonBloodBiomarkerTest.id
  );  
  

   
  
  
  const newNonBloodBiomarkerTests = nonBloodBiomarkerTests.map((it) =>
    it.id === id ? nonBloodBiomarkerTest : it
  );
 

  this.setState({ nonBloodBiomarkerTests:  newNonBloodBiomarkerTests}, () => {
    this.showSuccessNotification("Updated Successfully!");
    this.onNonBloodBiomarkerUpdateLocally();
  });
};
 onNonBloodBiomarkerUpdateLocally = () => {
  this.clearForm();
  this.nonBloodBiomarkerTestUpdated(
    this.state.nonBloodBiomarkerTests.map((questionnaire) => {
      const q = { ...questionnaire };
      if (q.is_new) {
        delete q.id;
        delete q.is_new;
      }
      return q;
    })
  );
};


handleSubmit = (event) => {
  event.preventDefault();

  const errors = this.validate();
  this.setState({ errors: errors || {} });
  if (errors) {
    this.showErrorNotification("There are some invalid form fields!");
    return;
  }

  const { nonBloodBiomarkerTest = {},nonBloodBiomarkerTests } = this.state;
   


  if (nonBloodBiomarkerTest.id === null || nonBloodBiomarkerTest.id === undefined)
    this.createNonBloodBiomarkerTest();
  else this.updateNonBloodBiomarkerTest(nonBloodBiomarkerTest.id);
  this.hideAddEditModal();
};
renderPencilIcon = (fieldName) => {
  const isEditable = this.props.isEditable ?? true;
 
  return isEditable && this.state.nonBloodBiomarkerTest.id ? (
    <div
      onClick={() => this.setEditable(fieldName)}
      style={{ cursor: "pointer" }}
      className="input-group-append"
    >
      <i className="input-group-text fa fa-pencil"></i>
    </div>
  ) : (
    ""
  );
}

setEditable = (field) => {
  const editables = { ...this.state.editables };
  editables[field] = true;
  this.setState({ editables });
};

 render(){
    const {
        nonBloodBiomarkerTests = [],
        nonBloodBiomarkerTest= {},
        errors,
        editables,
        
      } = this.state;
      const isNewNonBloodBiomarkerTest =
    nonBloodBiomarkerTest.id === null || nonBloodBiomarkerTest.id === undefined;
    
    const form=(<>

    
   
    <div className="row">
    <div className="form-group col">
            <label htmlFor="testname">Test Name (English) *</label>
            <div className="input-group">
              <input
                value={nonBloodBiomarkerTest?.test_name??"" }
                onChange={this.handleChange}
                id="test_name"
                name="test_name"
                type="text"
                placeholder="Test Name in English"
                className={
                  "form-control py-2" +
                  (errors.test_name ? " has-error" : "")
                }
                disabled={!isNewNonBloodBiomarkerTest && !editables.test_name}
              />
              {this.renderPencilIcon("test_name")}
            </div>
            <span className="help-block">{errors.test_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="testnamearabic">Test Name (Arabic) *</label>
            <div className="input-group">
              <input
                value={nonBloodBiomarkerTest?.test_name_ar??"" }
                onChange={this.handleChange}
                id="test_name_ar"
                name="test_name_ar"
                type="text"
                placeholder="Test Name in Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.test_name_ar ? " has-error" : "")
                }
                disabled={!isNewNonBloodBiomarkerTest && !editables.test_name_ar}
              />
              {this.renderPencilIcon("test_name_ar")}
            </div>
            <span className="help-block">{errors.test_name_ar}</span>
          </div>


          </div>
          <div className="row">
          <div className="form-group col">
            <label htmlFor="testnamearabic">Test Description (English) * </label>
            <div className="input-group">
              <textarea
                value={nonBloodBiomarkerTest?.description ??""}
                onChange={this.handleChange}
                id="description"
                name="description"
                type="text"
                placeholder="Test description in English"
                className={
                  "form-control" +
                  (errors.description? " has-error" : "")
                }
                readOnly={!isNewNonBloodBiomarkerTest && !editables.description}
              ></textarea>
              {this.renderPencilIcon("description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
          
          <div className="form-group col">
            <label htmlFor="testnamearabic">Test Description (Arabic) * </label>
            <div className="input-group">
              <textarea
                value={nonBloodBiomarkerTest?.description_ar ?? ""}
                onChange={this.handleChange}
                id="description_ar"
                name="description_ar"
                type="text"
                placeholder="Test description in Arabic"
                className={
                  "form-control input-arabic" +
                  (errors.description_ar? " has-error" : "")
                }
                readOnly={!isNewNonBloodBiomarkerTest && !editables.description_ar}
              ></textarea>
              {this.renderPencilIcon("description_ar")}
            </div>
            <span className="help-block">{errors.description_ar}</span>
          </div>

          </div>

          <div className="row">

          <div className="form-group col">
          <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={ nonBloodBiomarkerTest.is_active?"Active":"Inactive" }
                onChange={(event, value) => {
                  this.setState({
                    nonBloodBiomarkerTest: {
                      ...nonBloodBiomarkerTest,
                      is_active: value.value =="Active",
                    },
                  });
                }}
                id="is_active"
                name="is_active"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewNonBloodBiomarkerTest && !editables.is_active}
                className={"form-control" + (errors.is_active ? " has-error" : "")}
              />
              {this.renderPencilIcon("is_active")}
            </div>
            <span className="help-block">{errors.is_active}</span>
          </div>
         
          <div className="form-group col">
            <label htmlFor="testCategory">Test Category *</label>
            <div className="input-group">
              <Dropdown
                value={nonBloodBiomarkerTest?.test_category_id}
                onChange={(event, value) => {
                  
                  this.setState({
                    nonBloodBiomarkerTest: {
                      ...nonBloodBiomarkerTest,
                      test_category_id:value.value,
                      test_category_name:value.options.filter((opt)=>opt.value==value.value)[0].text,
                    },
                  });
                }}
                id="Category name"
                name="Category name"
                placeholder="Category name"
                search
                selection
                options={this.state.biomarkerCategoriesList}
                disabled={!isNewNonBloodBiomarkerTest && !editables.test_category_name}
                className={
                  "form-control" + (errors.test_category_name? " has-error" : "")
                }
              />
              {this.renderPencilIcon("test_category_name")}
            </div>
            <span className="help-block">{errors.test_category_name}</span>
          </div>
          



    
    </div>
    </>)
    return (
        
          <div className="row">
            <div className="form-group col px-0">
              <Card>
                <DataTable
                  highlightOnHover
                  columns={this.columns}
                  data={nonBloodBiomarkerTests || []}
                 
                  pagination
                  responsive
                  
                  sortIcon={<ArrowDownward></ArrowDownward>}
                />
                <button
                  onClick={this.handleAddNewNonBloodBiomarker}
                  disabled={!this.props.isEditable}
                  className="btn btn-success mt-1"
                >
                  Add New
                </button>
              </Card>
            </div>
            <Modal
          size="lg"
          show={this.state.isAddEditModalVisible}
          onHide={this.hideAddEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {nonBloodBiomarkerTest.id === null || nonBloodBiomarkerTest.id === undefined
                ? "Add NonBloodBiomarker Test Details"
                : "Edit NonBloodBiomarker Test Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{form}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary rounded-pill px-4 py-2"
              onClick={this.hideAddEditModal}
            >
              Cancel
            </button>
            <button
              onClick={this.handleSubmit}
              disabled={!this.props.isEditable}
              className="btn btn-lg btn-success rounded-pill px-4 py-2"
            >
              {nonBloodBiomarkerTest.id ? "Save" : "Add"}
            </button>
          </Modal.Footer>
        </Modal>
          </div>
          
        );
                }
}

function mapStateToProps(state) {
    return {};
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      showNotificationMessage: (value) =>
        dispatch({ type: "SHOW_NOTIFICATION", value }),
      toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
    };
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(NonBiomarkerTestDetails);